// Encapsulate SDK under Plantronics object
/*exported Plantronics*/

/*jshint bitwise: false*/
/*eslint-disable no-bitwise*/

var Plantronics = {};

// eslint-disable-next-line max-lines-per-function
Plantronics.init = function () { // NOSONAR
    'use strict';

    if (Plantronics.Spokes) {
        // Already initialized
        return;
    }

    // Ensure jquery is loaded
    if (typeof jQuery === 'undefined') {
        throw new Error('Spokes Error: jQuery must be loaded before spokes Javascript');
    }

    /////////////////////////////////////////////////////////////////////////////
    // Logger
    /////////////////////////////////////////////////////////////////////////////
    // Default logger
    /* eslint-disable no-console */
    var logger = {
        debug: console.log.bind(console),
        info: console.info.bind(console),
        warn: console.warn.bind(console),
        error: console.error.bind(console)
    };
    /* eslint-enable no-console */

    // Allow application to define own logger
    function setLogger(appLogger) {
        appLogger = appLogger || {};
        var logDebug = appLogger.debug || appLogger.log || function () {};
        var logInfo = appLogger.info || logDebug;
        var logWarn = appLogger.warn || appLogger.warning || logInfo;
        var logError = appLogger.error || logInfo;

        logger.debug = function () {
            logDebug.apply(appLogger, Array.prototype.slice.apply(arguments));
        };
        logger.info = function () {
            logInfo.apply(appLogger, Array.prototype.slice.apply(arguments));
        };
        logger.warn = function () {
            logWarn.apply(appLogger, Array.prototype.slice.apply(arguments));
        };
        logger.error = function (error, obj) {
            var args = [(error && error.stack) || error];
            obj = (obj && obj.stack) || obj;
            if (obj) {
                args.push(obj);
            }
            logError.apply(appLogger, args);
        };
    }

    /////////////////////////////////////////////////////////////////////////////
    // Helper functions
    /////////////////////////////////////////////////////////////////////////////
    function createLookup(obj) {
        var keys = Object.keys(obj);
        obj.Lookup = [];
        keys.forEach(function (key) {
            obj.Lookup[obj[key]] = key;
        });
    }

    // Compresses an array of queue items down to a single number
    function compressQueueList(queue) {
        if (!Array.isArray(queue)) {
            return queue;
        }

        // We have an array, go through it and or everthing together
        return queue.reduce(function (accumulator, currentValue) {
            return typeof currentValue === 'number' ? accumulator | currentValue : accumulator;
        }, 0);
    }

    function sendRequest(requestUrl, responseType, callback, suppressLog) {
        !suppressLog && logger.debug('[Spokes]: Send REST request: ', requestUrl);
        $.getJSON(requestUrl, function (data) {
            // Create a nice object, and ensure its of the right type
            var resp = new SpokesResponse(data);
            if (resp.Type !== responseType) {
                resp.isValid = false;
            }
            !suppressLog && logger.debug('[Spokes]: Received REST response: ', resp);

            if (typeof callback === 'function') {
                callback(resp);
            }
        })
        .fail(function (err) {
            logger.warn('[Spokes] Failed to send request. ', err ? err.status + ' ' + err.statusText : '');

            if (typeof callback === 'function') {
                callback(new SpokesResponse({
                    Description: '',
                    Err: {
                        Description: 'No response. Server appears to be offline.',
                        Error_Code: 0,
                        Type: 4
                    },
                    Result: null,
                    Type: 0,
                    Type_Name: 'Unknown',
                    isError: true
                }));
            }
        });
        return true;
    }

    function isCallId(callID) {
        return !!(callID && typeof callID.getName === 'function' && callID.getName() === 'SpokesCallId');
    }

    function isContact(contact) {
        return !!(contact && typeof contact.getName === 'function' && contact.getName() === 'SpokesContact');
    }

    /////////////////////////////////////////////////////////////////////////////
    // Definitions
    /////////////////////////////////////////////////////////////////////////////
    var SessionCallState = {
        Unknown: 0,
        AcceptCall: 1,
        TerminateCall: 2,
        HoldCall: 3,
        Resumecall: 4,
        Flash: 5,
        CallInProgress: 6,
        CallRinging: 7,
        CallEnded: 8,
        TransferToHeadSet: 9,
        TransferToSpeaker: 10,
        MuteON: 11,
        MuteOFF: 12,
        MobileCallRinging: 13,
        MobileCallInProgress: 14,
        MobileCallEnded: 15,
        Don: 16,
        Doff: 17,
        CallIdle: 18,
        Play: 19,
        Pause: 20,
        Stop: 21,
        DTMFKey: 22,
        RejectCall: 23
    };
    createLookup(SessionCallState);

    var SpokesAudioType = {
        MonoOn: 1,
        MonoOff: 2,
        StereoOn: 3,
        StereoOff: 4,
        MonoOnWait: 5,
        StereoOnWait: 6
    };
    createLookup(SpokesAudioType);

    var SpokesResponseType = {
        Unknown: 0,
        Error: 1,
        Bool: 2,
        Integer: 3,
        DeviceInfo: 4,
        DeviceInfoArray: 5,
        DeviceEventArray: 6,
        SessionHash: 7,
        String: 8,
        CallManagerState: 9,
        CallStateArray: 10,
        ContactArray: 11,
        StringArray: 12
    };

    // Constructor for Response object
    function SpokesResponse(obj) {
        // Copy the JSON data into this object
        this.Type = obj.Type;
        this.Type_Name = obj.Type_Name;
        this.Description = obj.Description;
        this.isError = obj.isError;
        this.isValid = true;
        this.Err = null;
        this.Result = null;

        // If we have an error, null the result and populate error, else opposite
        if (this.isError) {
            this.Err = new SpokesError(obj.Err);
            return;
        }

        // Store a user result object
        var data;
        switch (this.Type) {
        // Object types that JS knows about
        case SpokesResponseType.Bool:
        case SpokesResponseType.Integer:
        case SpokesResponseType.SessionHash:
        case SpokesResponseType.String:
        case SpokesResponseType.CallManagerState:
            this.Result = obj.Result;
            break;

        // Create a device info object
        case SpokesResponseType.DeviceInfo:
            this.Result = new SpokesDeviceInfo(obj.Result);
            break;

        // Create an array of device info objects
        case SpokesResponseType.DeviceInfoArray:
            data = Array.isArray(obj.Result) ? obj.Result : [];
            this.Result = data.map(function (deviceInfo) {
                return new SpokesDeviceInfo(deviceInfo);
            });
            break;

        // Create an array of events
        case SpokesResponseType.DeviceEventArray:
            data = Array.isArray(obj.Result) ? obj.Result : [];
            this.Result = data.map(function (event) {
                return new SpokesEvent(event);
            });
            break;

        case SpokesResponseType.CallStateArray:
        case SpokesResponseType.StringArray:
            this.Result = Array.isArray(obj.Result) ? obj.Result : [];
            break;

        // Create an array of contacts
        case SpokesResponseType.ContactArray:
            data = Array.isArray(obj.Result) ? obj.Result : [];
            this.Result = data.map(function (contact) {
                return new SpokesContact(contact);
            });
            break;

        default:
            logger.error('[Spokes]: Invalid response type received: ', this.Type);
            break;
        }
    }
    SpokesResponse.prototype.getName = function () { return 'SpokesResponse'; };

    // Constructor for Device Info object
    function SpokesDeviceInfo(obj) {
        this.Uid = obj.Uid;
        this.DevicePath = obj.DevicePath;
        this.InternalName = obj.InternalName;
        this.IsAttached = obj.IsAttached;
        this.ManufacturerName = obj.ManufacturerName;
        this.ProductId = obj.ProductId;
        this.ProductName = obj.ProductName;
        this.SerialNumber = obj.SerialNumber;
        this.VendorId = obj.VendorId;
        this.VersionNumber = obj.VersionNumber;
        this.USBVersionNumber = obj.USBVersionNumber;
        this.BaseFirmwareVersion = obj.BaseFirmwareVersion;
        this.BluetoothFirmwareVersion = obj.BluetoothFirmwareVersion;
        this.RemoteFirmwareVersion = obj.RemoteFirmwareVersion;
        this.BaseSerialNumber = obj.BaseSerialNumber;
        this.HeadsetSerialNumber = obj.HeadsetSerialNumber;
    }
    SpokesDeviceInfo.prototype.getName = function () { return 'SpokesDeviceInfo'; };

    // Stores the spokes event types that can exist
    var SpokesEventType = {
        DeviceStateChange: 1,
        HeadsetStateChange: 2,
        HeadsetButtonPressed: 4,
        BaseStateChange: 8,
        BaseButtonPressed: 16,
        CallStateChange: 32,
        ATDStateChange: 64
    };

    // Constructor for Event object
    function SpokesEvent(obj) {
        this.Event_Log_Type_Name = obj.Event_Log_Type_Name;
        this.Event_Log_Type_Id = obj.Event_Log_Type_Id;
        this.Event_Name = obj.Event_Name;
        this.Event_Id = obj.Event_Id;
        this.Timestamp = obj.Timestamp;
        this.Age = obj.Age;
    }
    SpokesEvent.prototype.getName = function () { return 'SpokesEvent'; };

    // Defines a spokes error
    var SpokesErrorType = {
        Unknown: 0,
        Invalid_Uid: 1,
        Exception: 2,
        Invalid_Session: 3,
        Server_Offline: 4
    };

    // Constructor for Error object
    function SpokesError(obj) {
        this.Type = obj.Type;
        this.Description = obj.Description;
        this.Error_Code = obj.Error_Code;
    }
    SpokesError.prototype.getName = function () { return 'SpokesError'; };

    // Constructor for Contact object
    function SpokesContact(obj) {
        this.Id = obj.Id;
        this.Name = obj.Name;
        this.Email = obj.Email;
        this.Phone = obj.Phone;
        this.SipUri = obj.SipUri;
        this.WorkPhone = obj.WorkPhone;
        this.HomePhone = obj.HomePhone;
        this.MobilePhone = obj.MobilePhone;
        this.FriendlyName = obj.FriendlyName;
    }
    SpokesContact.prototype.getName = function () { return 'SpokesContact'; };

    // Constructor for CallId object
    function SpokesCallId(obj) {
        this.Id = obj.Id;
        this.InConference = obj.InConference;
        this.ConferenceId = obj.ConferenceId;
    }
    SpokesCallId.prototype.getName = function () { return 'SpokesCallId'; };

    /////////////////////////////////////////////////////////////////////////////
    // Device object
    /////////////////////////////////////////////////////////////////////////////
    function Device(path) {
        this.Path = path;
        this.Device_Id = '';
        this.Sess_Id = '';
        this.isAttached = false;
    }

    // Connect to a device
    Device.prototype.attach = function (uid, callback) {
        var url = this.Path + '/DeviceServices/Attach?uid=' + uid + '&callback=?';
        return sendRequest(url, SpokesResponseType.SessionHash, function (resp) {
            if (resp.isValid && !resp.isError) {
                // Store my session and set that we are attached
                this.Sess_Id = resp.Result;
                this.isAttached = true;
                logger.info('[Spokes]: Connected to Device ', this);
            }
            callback(resp);
        }.bind(this));
    };

    // Release my session from a device
    Device.prototype.release = function (callback) {
        // Can't release, we aren't attached
        if (!this.isAttached) {
            return false;
        }

        var url = this.Path + '/DeviceServices/Release?sess=' + this.Sess_Id + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, function (resp) {
            // Set that we aren't attached anymore, even if this failed
            this.Sess_Id = '';
            this.isAttached = false;
            logger.info('[Spokes]: Released from Device ', this);
            callback(resp);
        }.bind(this));
    };

    // Returns the info for our connected device
    Device.prototype.deviceInfo = function (callback) {
        if (typeof callback !== 'function') {
            return false;
        }

        var url = this.Path + '/DeviceServices/Info?callback=?';
        return sendRequest(url, SpokesResponseType.DeviceInfo, function (resp) {
            if (resp.isValid && !resp.isError) {
                // Store my device uid
                this.Device_Id = resp.Result.Uid;
            }
            callback(resp);
        }.bind(this));
    };

    Device.prototype.atdMobileCallerId = function (callback) {
        if (typeof callback !== 'function') {
            return false;
        }
        var url = this.Path + '/DeviceServices/ATDMobileCallerId?sess=' + this.Sess_Id + '&callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    Device.prototype.proximity = function (enabled, callback) {
        if (typeof enabled !== 'boolean') {
            return false;
        }
        var url = this.Path + '/DeviceServices/Proximity?sess=' + this.Sess_Id + '&enabled=' + enabled + '&callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    // Returns all the valid events that have happened since last call
    Device.prototype.events = function (queue, callback) {
        // Check if I was only given one argument
        if (typeof queue === 'function') {
            callback = queue;
            queue = 0;
        }

        if (typeof callback !== 'function') {
            return false;
        }

        // If they gave me an array of queue items, compress them down to an int
        queue = compressQueueList(queue);

        var url = this.Path + '/DeviceServices/Events?sess=' + this.Sess_Id + '&queue=' + queue + '&callback=?';
        return sendRequest(url, SpokesResponseType.DeviceEventArray, callback);
    };

    // Returns specific event queues
    Device.prototype.headsetEvents = function (callback) {
        var evt = SpokesEventType.HeadsetButtonPressed | SpokesEventType.HeadsetStateChange;
        return this.events(evt, callback);
    };

    // Returns specific event queues
    Device.prototype.baseEvents = function (callback) {
        var evt = SpokesEventType.BaseButtonPressed | SpokesEventType.BaseStateChange;
        return this.events(evt, callback);
    };

    // Returns specific event queues
    Device.prototype.atdEvents = function (callback) {
        return this.events(SpokesEventType.ATDStateChange, callback);
    };

    // Starts or stops the ringer in the headest
    Device.prototype.ring = function (enabled, callback) {
        if (typeof enabled !== 'boolean') {
            return false;
        }
        var url = this.Path + '/DeviceServices/Ring?enabled=' + enabled + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    // Sets the audio state, for wireless headsets MonoOn/Off turns them on and off
    Device.prototype.audioState = function (state, callback) {
        if (typeof state !== 'number') {
            return false;
        }
        // Convert the audio state into the textual name
        state = SpokesAudioType.Lookup[state];

        var url = this.Path + '/DeviceServices/AudioState?state=' + state + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };


    ///////////////////////////////////////////////////////////////////////////
    // Event Manager

    // Returns the queues we are registered for
    Device.prototype.getEventRegistry = function (callback) {
        if (typeof callback !== 'function') {
            return false;
        }
        var url = this.Path + '/EventManager/GetRegistry?sess=' + this.Sess_Id + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Defines a list of queues we are registered for
    Device.prototype.setEventRegistry = function (queue, callback) {
        // If they gave me an array of queue items, compress them down to an int
        queue = compressQueueList(queue) || 0;

        var url = this.Path + '/EventManager/SetRegistry?sess=' + this.Sess_Id + '&queue=' + queue + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Adds a list of queues from our registry
    Device.prototype.addEventRegistry = function (queue, callback) {
        // If they gave me an array of queue items, compress them down to an int
        queue = compressQueueList(queue) || 0;

        var url = this.Path + '/EventManager/AddRegistry?sess=' + this.Sess_Id + '&queue=' + queue + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Removes a list of queues from our registry
    Device.prototype.removeEventRegistry = function (queue, callback) {
        // If they gave me an array of queue items, compress them down to an int
        queue = compressQueueList(queue) || 0;

        var url = this.Path + '/EventManager/RemoveRegistry?sess=' + this.Sess_Id + '&queue=' + queue + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Sets the time to live for all event queue messages
    Device.prototype.setGlobalTTL = function (ttl, callback) {
        var url = this.Path + '/EventManager/GlobalTTL?sess=' + this.Sess_Id + '&ttl=' + ttl + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Sets the max number of events per queue, for all queues
    Device.prototype.setGlobalMaxEvents = function (max, callback) {
        var url = this.Path + '/EventManager/GlobalMaxCount?sess=' + this.Sess_Id + '&max=' + max + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Sets the time to live for a list of queues
    Device.prototype.setQueueTTL = function (queue, ttl, callback) {
        // If they gave me an array of queue items, compress them down to an int
        queue = compressQueueList(queue) || 0;

        var url = this.Path + '/EventManager/TTL?sess=' + this.Sess_Id + '&queue=' + queue + '&ttl=' + ttl + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };

    // Sets the max number of events per queue, for the list of queues given
    Device.prototype.setQueueMaxEvents = function (queue, max, callback) {
        // If they gave me an array of queue items, compress them down to an int
        queue = compressQueueList(queue) || 0;

        var url = this.Path + '/EventManager/MaxCount?sess=' + this.Sess_Id + '&queue=' + queue + '&max=' + max + '&callback=?';
        return sendRequest(url, SpokesResponseType.Integer, callback);
    };


    /////////////////////////////////////////////////////////////////////////////
    // Plugin object
    /////////////////////////////////////////////////////////////////////////////
    function Plugin(path) {
        this.Path = path;
        this.Sess_Id = '';
        this.isAttached = false;
    }

    /////////////////////////////////////////////////////////////////////////////
    // SessionManager

    // List out all plugins
    Plugin.prototype.pluginList = function (callback) {
        var url = this.Path + '/SessionManager/PluginList?callback=?';
        return sendRequest(url, SpokesResponseType.StringArray, callback);
    };

    // Register a plugin
    Plugin.prototype.register = function (name, callback) {
        var url = this.Path + '/SessionManager/Register?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    // Unregister a plugin
    Plugin.prototype.unRegister = function (name, callback) {
        var url = this.Path + '/SessionManager/UnRegister?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    // Set that the plugin is active
    Plugin.prototype.isActive = function (name, active, callback) {
        var url = this.Path + '/SessionManager/IsActive?name=' + name + '&active=' + active + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    /////////////////////////////////////////////////////////////////////////////
    // CallServices

    // Get the call manager state
    Plugin.prototype.callManagerState = function (callback) {
        var url = this.Path + '/CallServices/CallManagerState?callback=?';
        return sendRequest(url, SpokesResponseType.CallManagerState, callback);
    };

    // Session events
    Plugin.prototype.sessionEvents = function (callback, suppressLog) {
        var url = this.Path + '/CallServices/Events?callback=?';
        return sendRequest(url, SpokesResponseType.StringArray, callback, suppressLog);
    };

    // Session manager call events
    Plugin.prototype.sessionCallEvents = function (name, callback, suppressLog) {
        var url = this.Path + '/CallServices/SessionManagerCallEvents?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.StringArray, callback, suppressLog);
    };

    // Call Events
    Plugin.prototype.callEvents = function (name, callback, suppressLog) {
        var url = this.Path + '/CallServices/CallEvents?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.CallStateArray, callback, suppressLog);
    };

    // Call Requests
    Plugin.prototype.callRequests = function (name, callback) {
        var url = this.Path + '/CallServices/CallRequests?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.ContactArray, callback);
    };

    Plugin.prototype.invokeCallControl = function (api, name, data, callback) {
        var callID, contact;

        if (data.hasOwnProperty('callID')) {
            if (isCallId(data.callID)) {
                callID = JSON.stringify(data.callID);
            } else {
                logger.warn('[Spokes]: ' + api + ' API was invoked with invalid callID');
                return false;
            }
        }

        if (data.hasOwnProperty('contact')) {
            if (isContact(data.contact)) {
                contact = JSON.stringify(data.contact);
            } else {
                logger.warn('[Spokes]: ' + api + ' API was invoked with invalid contact');
                return false;
            }
        }

        var url = this.Path + '/CallServices/' + api + '?name=' + name +
            (callID ? '&callID=' + callID : '') +
            (contact ? '&contact=' + contact : '') +
            (data.hasOwnProperty('tones') ? '&tones=' + data.tones : '') +
            (data.hasOwnProperty('route') ? '&route=' + data.route : '') +
            (data.hasOwnProperty('muted') ? '&muted=' + data.muted : '') +
            '&callback=?';

        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    // Incoming Call
    Plugin.prototype.incomingCall = function (name, callID, contact, tones, route, callback) {
        var data = {
            callID: callID,
            contact: contact,
            tones: tones,
            route: route
        };
        return this.invokeCallControl('IncomingCall', name, data, callback);
    };

    // Outgoing Call
    Plugin.prototype.outgoingCall = function (name, callID, contact, route, callback) {
        var data = {
            callID: callID,
            contact: contact,
            route: route
        };
        return this.invokeCallControl('OutgoingCall', name, data, callback);
    };

    // Terminate Call
    Plugin.prototype.terminateCall = function (name, callID, callback) {
        return this.invokeCallControl('TerminateCall', name, {callID: callID}, callback);
    };

    // Answer Call
    Plugin.prototype.answerCall = function (name, callID, callback) {
        return this.invokeCallControl('AnswerCall', name, {callID: callID}, callback);
    };

    // Hold Call
    Plugin.prototype.holdCall = function (name, callID, callback) {
        return this.invokeCallControl('HoldCall', name, {callID: callID}, callback);
    };

    // Resume Call
    Plugin.prototype.resumeCall = function (name, callID, callback) {
        return this.invokeCallControl('ResumeCall', name, {callID: callID}, callback);
    };

    // Mute Call
    Plugin.prototype.muteCall = function (name, muted, callback) {
        return this.invokeCallControl('MuteCall', name, {muted: !!muted}, callback);
    };

    // Insert Call
    Plugin.prototype.insertCall = function (name, callID, contact, callback) {
        var data = {
            callID: callID,
            contact: contact
        };
        return this.invokeCallControl('InsertCall', name, data, callback);
    };

    // Set Audio Route
    Plugin.prototype.setAudioRoute = function (name, callID, route, callback) {
        var data = {
            callID: callID,
            route: route
        };
        return this.invokeCallControl('SetAudioRoute', name, data, callback);
    };

    // Set Conference Id
    Plugin.prototype.setConferenceId = function (name, callID, callback) {
        return this.invokeCallControl('SetConferenceId', name, {callID: callID}, callback);
    };

    // Make Call
    Plugin.prototype.makeCall = function (name, contact, callback) {
        return this.invokeCallControl('MakeCall', name, {contact: contact}, callback);
    };


    /////////////////////////////////////////////////////////////////////////////
    // UserPreference object
    /////////////////////////////////////////////////////////////////////////////
    function UserPreference(path) {
        this.Path = path;
    }

    UserPreference.prototype.getDefaultSoftphone = function (callback) {
        var url = this.Path + '/UserPreference/GetDefaultSoftPhone?callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    UserPreference.prototype.getEscalateToVoiceSoftPhone = function (callback) {
        var url = this.Path + '/UserPreference/GetEscalateToVoiceSoftPhone?callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    UserPreference.prototype.getMediaPlayerActionIncomingCall = function (callback) {
        var url = this.Path + '/UserPreference/GetMediaPlayerActionIncomingCall?callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    UserPreference.prototype.getMediaPlayerActionEndedCall = function (callback) {
        var url = this.Path + '/UserPreference/GetMediaPlayerActionEndedCall?callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    UserPreference.prototype.getAutoPresence = function (callback) {
        var url = this.Path + '/UserPreference/GetAutoPresence?callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.getDoffAction = function (callback) {
        var url = this.Path + '/UserPreference/GetDoffAction?callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    UserPreference.prototype.getDonAction = function (callback) {
        var url = this.Path + '/UserPreference/GetDonAction?callback=?';
        return sendRequest(url, SpokesResponseType.String, callback);
    };

    UserPreference.prototype.getKeepLinkUp = function (callback) {
        var url = this.Path + '/UserPreference/GetKeepLinkUp?callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.getRingPCAndHS = function (callback) {
        var url = this.Path + '/UserPreference/GetRingPCAndHS?callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setDefaultSoftphone = function (name, callback) {
        var url = this.Path + '/UserPreference/SetDefaultSoftPhone?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setEscalateToVoiceSoftPhone = function (name, callback) {
        var url = this.Path + '/UserPreference/SetEscalateToVoiceSoftPhone?name=' + name + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setMediaPlayerActionIncomingCall = function (actionIncoming, callback) {
        var url = this.Path + '/UserPreference/SetMediaPlayerActionIncomingCall?actionIncoming=' + actionIncoming + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setMediaPlayerActionEndedCall = function (actionEnded, callback) {
        var url = this.Path + '/UserPreference/SetMediaPlayerActionEndedCall?actionEnded=' + actionEnded + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setAutoPresence = function (autoPresence, callback) {
        var url = this.Path + '/UserPreference/SetAutoPresence?autoPresence=' + autoPresence + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setDoffAction = function (doffAction, callback) {
        var url = this.Path + '/UserPreference/SetDoffAction?doffAction=' + doffAction + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setDonAction = function (donAction, callback) {
        var url = this.Path + '/UserPreference/SetDonAction?donAction=' + donAction + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setKeepLinkUp = function (keepLinkUp, callback) {
        var url = this.Path + '/UserPreference/SetKeepLinkUp?keepLinkUp=' + keepLinkUp + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    UserPreference.prototype.setRingPCAndHS = function (ringPcAndHS, callback) {
        var url = this.Path + '/UserPreference/SetRingPCAndHS?ringPcAndHS=' + ringPcAndHS + '&callback=?';
        return sendRequest(url, SpokesResponseType.Bool, callback);
    };

    /////////////////////////////////////////////////////////////////////////////
    // Spokes object
    /////////////////////////////////////////////////////////////////////////////
    function Spokes(url) {
        this.Path = url || 'https://127.0.0.1:32018';
        this.Device = new Device(this.Path);
        this.Plugin = new Plugin(this.Path);
        this.UserPreference = new UserPreference(this.Path);
    }


    // Expose public interfaces
    Plantronics.setLogger = setLogger;

    Plantronics.Spokes = Spokes;
    Plantronics.SpokesCallId = SpokesCallId;
    Plantronics.SpokesContact = SpokesContact;
    Plantronics.SessionCallState = SessionCallState;
    Plantronics.SpokesErrorType = SpokesErrorType;

};
