
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var CallState = circuit.Enums.CallState;
    var ChromeExtension = circuit.ChromeExtension;
    var Constants = circuit.Constants;
    var CstaCallState = circuit.Enums.CstaCallState;
    var HeadsetIntegrationApp = circuit.HeadsetIntegrationApp;
    var IdleState = circuit.Enums.IdleState;
    var RtcSessionController = circuit.RtcSessionController;
    var Utils = circuit.Utils;
    var WebRTCAdapter = circuit.WebRTCAdapter;

    var HeadsetHidDeviceType = {
        // Headsets
        JABRA: 'JABRA',
        SENNHEISER: 'SENNHEISER',
        LOGITECH: 'LOGITECH',
        POLYCOM: 'POLYCOM',
        JPL: 'JPL',
        GIGASET_ION: 'GIGASET_ION',
        PLATHOSYS: 'PLATHOSYS',
        // Status Light
        EMBRAVA: 'EMBRAVA',
        KUANDO: 'KUANDO',
        PLANTRONICS: 'PLANTRONICS'
    };

    var StatusLightControl = Object.freeze({
        OFF: { off: true },
        AVAILABLE: { red: 0x00, green: 0xFF, blue: 0x00, flash: false, dim: true },
        AWAY: { red: 0xFF, green: 0x80, blue: 0x00, flash: false, dim: true },
        BUSY: { red: 0xFF, green: 0x00, blue: 0x00, flash: false, dim: true },
        BUSY_VIDEO: { red: 0xFF, green: 0x00, blue: 0x00, flash: true, dim: true },
        DND: { red: 0xFF, green: 0x00, blue: 0x00, flash: false, dim: true },
        ALERTING_CALL: { red: 0x00, green: 0xFF, blue: 0x00, flash: true, dim: true }
    });

    // eslint-disable-next-line max-params, max-lines-per-function
    function HeadsetHidControlSvcImpl( // NOSONAR
        $rootScope,
        $window,
        $timeout,
        $q,
        LogSvc,
        PubSubSvc,
        PopupSvc,
        LocalStoreSvc,
        UserProfileSvc,
        RegistrationSvc,
        CallControlSvc,
        ExtensionSvc) {

        LogSvc.debug('New Service: HeadsetHidControlSvc');

        var chrome = circuit.chrome || $window.chrome;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        // This defines the minimum Chrome extension required for the Headset integration
        var MIN_EXTENSION_VERSION_STR = '1.1.7400';
        var MIN_EXTENSION_VERSION = Utils.convertVersionToNumber(MIN_EXTENSION_VERSION_STR);

        var HEADSET_INTEGRATION_APP_ID = 'nbleppgjjifiggbhoeejbmdhfjjliain';
        var RESPONSE_TIMEOUT = 5000;
        var DEVICE_ADDED_TIMEOUT = 5000;
        var RETRY_DELAY = 1000;
        var MAX_CONNECT_ATTEMPTS = 20; // 20 attempts span ~15 minutes
        var MAX_INTERVAL = 60000;

        var HeadsetHidDeviceData = Object.freeze({
            JABRA: {
                type: 'JABRA',
                name: 'jabra',
                key: 'jabraHid',
                vendorIds: [0x0B0E],
                permission: 'jabraEnabled',
                headsetControl: true
            },
            SENNHEISER: {
                type: 'SENNHEISER',
                name: 'sennheiser',
                key: 'sennheiserHid',
                vendorIds: [0x1395, 0x1377],
                permission: 'sennheiserEnabled',
                headsetControl: true
            },
            LOGITECH: {
                type: 'LOGITECH',
                name: 'logitech',
                key: 'logitechHid',
                vendorIds: [0x046D],
                permission: 'logitechEnabled',
                headsetControl: true
            },
            POLYCOM: {
                type: 'POLYCOM',
                name: 'polycom',
                key: 'polycomHid',
                vendorIds: [0x095D],
                headsetControl: true
            },
            JPL: {
                type: 'JPL',
                name: 'jpl',
                key: 'jplHid',
                vendorIds: [0x2EA1],
                permission: 'jplEnabled',
                headsetControl: true
            },
            GIGASET_ION: {
                type: 'GIGASET_ION',
                name: 'gigaset',
                key: 'gigasetHid',
                vendorIds: [0x1E85],
                permission: 'gigasetIonEnabled',
                headsetControl: true
            },
            PLATHOSYS: {
                type: 'PLATHOSYS',
                name: 'plathosys',
                key: 'plathosysHid',
                vendorIds: [0x299D],
                permission: 'plathosysEnabled',
                headsetControl: true
            },
            EMBRAVA: {
                type: 'EMBRAVA',
                name: 'embrava',
                key: 'embravaHid',
                vendorIds: [0x0E53, 0x2C0D],
                permission: 'embravaEnabled',
                statusLightControl: true
            },
            KUANDO: {
                type: 'KUANDO',
                name: 'kuando',
                key: 'kuandoHid',
                vendorIds: [0x04D8, 0x27BB],
                permission: 'kuandoEnabled',
                statusLightControl: true
            },
            PLANTRONICS: {
                type: 'PLANTRONICS',
                name: 'plantronics',
                key: 'plantronicsHid',
                vendorIds: [0x047F],
                permission: 'plantronicsStatusEnabled',
                statusLightControl: true
            }
        });

        var HeadsetHidEvent = Object.freeze({
            IDLE: 'IDLE',
            HOOK_OFF: 'HOOK_OFF',
            HOOK_ON: 'HOOK_ON',
            BUSY_OFF: 'BUSY_OFF',
            BUSY_ON: 'BUSY_ON',
            MUTE_OFF: 'MUTE_OFF',
            MUTE_ON: 'MUTE_ON',
            REJECT: 'REJECT',
            RINGING: 'RINGING',
            DEVICE_ADDED: 'DEVICE_ADDED',
            DEVICE_REMOVED: 'DEVICE_REMOVED',
            DISCONNECTED: 'DISCONNECTED'
        });

        var ApplicationMessage = Object.freeze({
            CONNECT_DEVICE: 'connectDevice',
            DISCONNECT_DEVICE: 'disconnectDevice',
            GET_DEVICES: 'getDevices',
            GET_FILE: 'getFile',
            ON_HOOK: 'onHook',
            OFF_HOOK: 'offHook',
            MUTE: 'mute',
            UNMUTE: 'unMute',
            RING_ON: 'ringOn',
            RING_OFF: 'ringOff',
            CONNECT_STATUS_LIGHT: 'connectStatusLight',
            DISCONNECT_STATUS_LIGHT: 'disconnectStatusLight',
            SET_STATUS_LIGHT: 'setStatusLight'
        });

        var DeviceCallControlMessage = Object.freeze({
            ACCEPT_CALL: 'AcceptCall',
            END_CALL: 'EndCall',
            REJECT_CALL: 'RejectCall',
            MUTE: 'Mute',
            UNMUTE: 'UnMute'
        });

        var ChromeAppStatusCodes = Object.freeze({
            UNKNOWN: 'UNKNOWN',
            EXT_UNSUPPORTED_VERSION: 'EXT_UNSUPPORTED_VERSION',
            EXT_NO_MANAGEMENT_PERMISSION: 'EXT_NO_MANAGEMENT_PERMISSION',
            APP_NOT_INSTALLED: 'APP_NOT_INSTALLED',
            APP_INSTALLED: 'APP_INSTALLED',
            APP_DISABLED: 'APP_DISABLED',
            APP_CONNECTED: 'APP_CONNECTED', // This is returned based on port.headsetAppConnected
            DEVICE_CONNECTED: 'DEVICE_CONNECTED',
            DEVICE_CONNECT_FAILED: 'DEVICE_CONNECT_FAILED'
        });

        var ChromeAppConnectErrorCodes = Object.freeze({
            CONNECTION_IN_PROGRESS: 'Connection in progress',
            UNSUPPORTED_BROWSER: 'Unsupported browser',
            HEADSET_APP_NOT_INSTALLED: 'Circuit Headset App is not installed',
            COULD_NOT_CONNECT: 'Could not connect'
        });

        var StatusLightStates = Object.keys(StatusLightControl).reduce(function (obj, key) {
            obj[key] = key;
            return obj;
        }, {});

        var _port;
        var _chromeAppId = HEADSET_INTEGRATION_APP_ID;
        var _reqId = 0;
        var _reqCallbacks = {};

        var _chromeAppStatus = ChromeAppStatusCodes.UNKNOWN;
        var _connecting = false;
        var _integrationEnabled = false;
        var _connectTimeout = null;
        var _connectError = false;

        var _devices = [];
        var _activeHeadsetDevice;
        var _activeStatusLightDevices = {};
        var _deviceInfoHash = {};

        var _supportedIntegrations = [];
        var _enabledIntegrations = [];

        // Current alerting and active calls controlled by a headset
        var _alertingCall = null;
        var _activeCall = null;
        var _statusLightState = null;

        var _offHookPending = false;

        var _retryCounter = 0;
        var _retryInterval = RETRY_DELAY;

        var _that = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getChromeAppStatus(cb) {
            LogSvc.debug('[HeadsetHidControlSvc]: Get Circuit Headset App status');

            var setStatus = function (status) {
                LogSvc.debug('[HeadsetHidControlSvc]: Set Circuit Headset App status to ', status);
                _chromeAppStatus = status;
                cb && cb(status);
            };

            if (!ExtensionSvc.isExtensionRunning()) {
                LogSvc.debug('[HeadsetHidControlSvc]: Circuit extension is not running');
                setStatus(ChromeAppStatusCodes.UNKNOWN);
                return;
            }

            var extensionVersion = ExtensionSvc.getExtensionVersion();
            if (Utils.convertVersionToNumber(extensionVersion) < MIN_EXTENSION_VERSION) {
                LogSvc.warn('[HeadsetHidControlSvc]: The installed extension version (' + extensionVersion +
                    ') is not supported. Minimum version is ' + MIN_EXTENSION_VERSION_STR);
                setStatus(ChromeAppStatusCodes.EXT_UNSUPPORTED_VERSION);
                return;
            }

            if (isChromeAppConnected()) {
                // If we are connected then the App status must be INSTALLED
                setStatus(ChromeAppStatusCodes.APP_INSTALLED);
                return;
            }

            ExtensionSvc.getHeadsetIntegrationAppStatus(_chromeAppId, function (error, data) {
                if (error) {
                    LogSvc.error('[HeadsetHidControlSvc]: Failed to get Circuit Headset App status. ', error);
                    setStatus(ChromeAppStatusCodes.UNKNOWN);
                    return;
                }
                if (data) {
                    switch (data.status) {
                    case ChromeExtension.HeadsetAppStatus.INSTALLED:
                        setStatus(ChromeAppStatusCodes.APP_INSTALLED);
                        break;
                    case ChromeExtension.HeadsetAppStatus.NOT_INSTALLED:
                        setStatus(ChromeAppStatusCodes.APP_NOT_INSTALLED);
                        break;
                    case ChromeExtension.HeadsetAppStatus.NO_MANAGEMENT_PERMISSION:
                        setStatus(ChromeAppStatusCodes.EXT_NO_MANAGEMENT_PERMISSION);
                        break;
                    case ChromeExtension.HeadsetAppStatus.DISABLED:
                        setStatus(ChromeAppStatusCodes.APP_DISABLED);
                        break;
                    default:
                        setStatus(ChromeAppStatusCodes.UNKNOWN);
                        break;
                    }
                } else {
                    setStatus(ChromeAppStatusCodes.UNKNOWN);
                }
            });
        }

        function publishConnectFailed() {
            LogSvc.debug('[HeadsetHidControlSvc]: Publish /headset/headsetHid/connectFailed event');
            PubSubSvc.publish('/headset/headsetHid/connectFailed');
        }

        function publishConnectSucceded() {
            LogSvc.debug('[HeadsetHidControlSvc]: Publish /headset/headsetHid/connectSucceeded event');
            PubSubSvc.publish('/headset/headsetHid/connectSucceeded');
        }

        function getChromeAppStatusAndConnect(raiseEventOnFailure) {
            getChromeAppStatus(function (status) {
                // Check if we are supposed to automatically connect
                if (_integrationEnabled) {
                    var onConnectFailed = raiseEventOnFailure ? publishConnectFailed : function () {};

                    if (status === ChromeAppStatusCodes.APP_INSTALLED) {
                        LogSvc.info('[HeadsetHidControlSvc]: Circuit Headset App is installed. Try to connect automatically.');
                        connect(false)
                        .catch(function (err) {
                            onConnectFailed(err);
                        });
                    } else if (status !== ChromeAppStatusCodes.APP_NOT_INSTALLED) {
                        _connectError = true;
                        onConnectFailed();
                    }
                }
            });
        }

        function isHeadsetTypeEnabled(hidDeviceType) {
            return _enabledIntegrations.includes(HeadsetHidDeviceData[hidDeviceType]);
        }

        function setSupportedHeadsets() {
            var oldSupported = _supportedIntegrations;

            _supportedIntegrations = [];
            Object.values(HeadsetHidDeviceData).forEach(function (hidData) {
                if ((!hidData.labFeature || $rootScope.circuitLabs[hidData.labFeature]) &&
                    (!hidData.permission || $rootScope.localUser[hidData.permission])) {
                    _supportedIntegrations.push(hidData);
                }
            });

            var added = _supportedIntegrations.filter(function (provider) {
                return !oldSupported.includes(provider);
            });
            var removed = oldSupported.filter(function (provider) {
                return !_supportedIntegrations.includes(provider);
            });
            if (added.length > 0 || removed.length > 0) {
                LogSvc.info('[HeadsetHidControlSvc]: Set supported headsets: ', _supportedIntegrations);

                removed.forEach(function (hidData) {
                    disconnect(hidData.type);
                });
            }
        }

        function init() {
            _chromeAppId = LocalStoreSvc.getStringSync(LocalStoreSvc.keys.HEADSET_APP_ID) || HEADSET_INTEGRATION_APP_ID;
            LogSvc.info('[HeadsetHidControlSvc]: Set Chrome App ID as ', _chromeAppId);

            setSupportedHeadsets();

            var enabledTypes = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.HEADSET_TYPES_ENABLED) || [];
            _enabledIntegrations = _supportedIntegrations.filter(function (type) {
                return enabledTypes.includes(type.key);
            });
            LogSvc.info('[HeadsetHidControlSvc]: Enabled headset integrations: ', _enabledIntegrations);

            _integrationEnabled = _enabledIntegrations.length > 0;
        }

        function getNextReqId() {
            _reqId++;
            return _reqId;
        }

        function addReqCallback(cb) {
            var reqId = getNextReqId();
            if (typeof cb === 'function') {
                var responseTimer = $timeout(function () {
                    if (_reqCallbacks[reqId]) {
                        LogSvc.error('[HeadsetHidControlSvc]: Timeout waiting for response. reqId:', reqId);
                        delete _reqCallbacks[reqId];
                        cb('TIMEOUT');
                    }
                }, RESPONSE_TIMEOUT);

                _reqCallbacks[reqId] = {cb: cb, timer: responseTimer};
            }
            return reqId;
        }

        function sendRequest(msg, cb) {
            cb = cb || function () {};
            if (!msg) {
                cb('INVALID_MESSAGE');
                return;
            }
            msg.reqId = addReqCallback(cb);
            dispatchMessage(msg);
        }

        function sendCallControlRequest(msg, cb) {
            cb = cb || function () {};
            if (isCallControlAvailable()) {
                sendRequest(msg, cb);
            } else {
                // Send immediate response
                LogSvc.debug('[HeadsetHidControlSvc]: Headset call control is not available. Ignore request.');
                cb();
            }
        }

        function dispatchMessage(msg) {
            if (!isChromeAppConnected()) {
                return;
            }
            LogSvc.msgSend('[HeadsetHidControlSvc]: ', msg);
            try {
                _port.postMessage(msg);
            } catch (e) {
                LogSvc.error('[HeadsetHidControlSvc]: Lost connection to Circuit Headset App. Message could not be sent.');
                disconnectPort();
                getChromeAppStatus();
            }
        }

        function installAndLaunchChromeApp(cb) {
            cb = cb || function () {};

            if (!ExtensionSvc.isExtensionRunning()) {
                LogSvc.warn('[HeadsetHidControlSvc]: Cannot install the Circuit Heaset App. Circuit extension is not running.');
                cb('Circuit extension is not running');
                return;
            }

            if (_chromeAppStatus === ChromeAppStatusCodes.EXT_UNSUPPORTED_VERSION) {
                LogSvc.warn('[HeadsetHidControlSvc]: Cannot install the Circuit Heaset App. Unsupported Circuit extension version.');
                PopupSvc.error({message: 'res_UnsupportedExtensionVersionForHeadsetApp'});
                cb(_chromeAppStatus);
                return;
            }

            if (_chromeAppStatus === ChromeAppStatusCodes.APP_DISABLED) {
                LogSvc.warn('[HeadsetHidControlSvc]: Cannot launch the Circuit Heaset App because it is disabled.');
                PopupSvc.error({message: 'res_HeadsetAppDisabled'});
                cb(_chromeAppStatus);
                return;
            }

            LogSvc.info('[HeadsetHidControlSvc]: Install and launch the Circuit Heaset App');

            // Pass all the strings so the Extension can show a popup asking the user
            // for the management permission (if needed).
            var localizedStrings = {
                res_Yes: $rootScope.i18n.map.res_Proceed,
                res_No: $rootScope.i18n.map.res_Cancel,
                title: $rootScope.i18n.map.res_HeadsetAppInstallTitle,
                message: $rootScope.i18n.map.res_ExtensionAddManagementPermission
            };

            ExtensionSvc.launchHeadsetIntegrationApp(_chromeAppId, localizedStrings, function (err) {
                if (err) {
                    LogSvc.warn('[HeadsetHidControlSvc]: Failed to install/launch the Circuit Heaset App. ', err);
                    cb('Failed to launch app');
                } else {
                    _integrationEnabled = true; // Enable auto-connect so we'll connect to the app as soon as it's launched
                    cb();
                }
            });
        }

        function onAppConnected() {
            LogSvc.info('[HeadsetHidControlSvc]: Connected to Circuit Headset App for ', _enabledIntegrations);

            // Flag as connected
            _port.headsetAppConnected = true;
            _connecting = false;
            _connectError = false;
            _retryCounter = 0;
            _retryInterval = RETRY_DELAY;
            _chromeAppStatus = ChromeAppStatusCodes.APP_CONNECTED;

            // Save the connected state so it will be automatically reconnected in the next login
            saveIntegration();

            publishConnectSucceded();
            getDevices();
        }

        function connect(isManualConnect) {
            if (_connectTimeout) {
                $timeout.cancel(_connectTimeout);
                _connectTimeout = null;
            }
            if (isChromeAppConnected()) {
                return $q.resolve();
            }

            if (_connecting) {
                return $q.reject(ChromeAppConnectErrorCodes.CONNECTION_IN_PROGRESS);
            }

            // Check if the browser support audio output device selection
            if (!WebRTCAdapter.audioOutputSelectionSupported) {
                LogSvc.warn('[HeadsetHidControlSvc]: Cannot connect. Browser does not support enumerateDevices API.');
                _connectError = true;
                return $q.reject(ChromeAppConnectErrorCodes.UNSUPPORTED_BROWSER);
            }

            if (_chromeAppStatus !== ChromeAppStatusCodes.APP_INSTALLED) {
                LogSvc.warn('[HeadsetHidControlSvc]: Cannot connect. Invalid Circuit Headset App status - ', _chromeAppStatus);
                _connectError = true;
                return $q.reject(ChromeAppConnectErrorCodes.HEADSET_APP_NOT_INSTALLED);
            }

            var deferred = $q.defer();

            disconnectPort();

            LogSvc.info('[HeadsetHidControlSvc]: Connecting to Circuit Headset App');
            _connecting = true;

            var info = {
                name: 'CircuitHeadsetApp'
            };
            _port = chrome.runtime.connect(_chromeAppId, info);

            _port.onDisconnect.addListener(function () {
                var reconnect = false;
                if (isChromeAppConnected()) {
                    // Lost connection to Headset App
                    LogSvc.error('[HeadsetHidControlSvc]: Lost connection to Circuit Headset App');
                    _activeHeadsetDevice = null;
                    reconnect = true;
                } else {
                    $rootScope.$apply(function () {
                        LogSvc.warn('[HeadsetHidControlSvc]: Could not connect to Circuit Headset App');
                        _connecting = false;
                        _connectError = true;
                        deferred.reject(ChromeAppConnectErrorCodes.COULD_NOT_CONNECT);
                        if (!isManualConnect) {
                            // This is an automatic connection attempt, so keep retrying
                            if (_retryCounter < MAX_CONNECT_ATTEMPTS) {
                                _retryInterval = _retryInterval * 2;
                                _retryInterval = Math.min(_retryInterval, MAX_INTERVAL);
                                if (_connectTimeout) {
                                    $timeout.cancel(_connectTimeout);
                                }
                                // Sometimes Chrome Apps are started by Chrome in sandbox mode, so we
                                // can't connect to them. Restarting our Chrome App might clear this problem
                                ExtensionSvc.restartHeadsetApp(_chromeAppId, function () {
                                    _connectTimeout = $timeout(function () {
                                        connect(false)
                                        .catch(function () {});
                                    }, _retryInterval);
                                });
                            }
                            _retryCounter++;
                        }
                    });
                }
                _port = null;

                // Refresh the Chrome App status in case we are out of sync
                getChromeAppStatus(function (status) {
                    if (reconnect && _integrationEnabled && status === ChromeAppStatusCodes.APP_INSTALLED) {
                        LogSvc.warn('[HeadsetHidControlSvc]: Connection to Headset App has been lost. Attempt to reconnect in 1s.');
                        // For some reason we've lost connection. Try to reconnect.
                        // Give it 1s so the headset renderer can initialize
                        $timeout(function () {
                            connect(false);
                        }, 1000, false);
                    }
                });
            });

            _port.onMessage.addListener(function (msg) {
                try {
                    if (msg.target === HeadsetIntegrationApp.MsgTarget.INTERNAL) {
                        switch (msg.type) {
                        case HeadsetIntegrationApp.MsgInternal.INIT:
                            $rootScope.$apply(function () {
                                onAppConnected();
                                deferred.resolve();
                            });
                            break;
                        case HeadsetIntegrationApp.MsgInternal.LOG:
                            LogSvc.logMsg(msg.log.level, msg.log.messages);
                            break;
                        default:
                            LogSvc.warn('[HeadsetHidControlSvc]: Unknown internal message from Headset Integration App:', msg);
                            break;
                        }
                        return;
                    }
                    onMessage(msg);
                } catch (e) {
                    LogSvc.error('[HeadsetHidControlSvc]: Error processing message. ', e);
                }
            });

            return deferred.promise;
        }

        function disconnectPort() {
            _devices = [];
            _activeHeadsetDevice = null;
            _activeStatusLightDevices = {};
            _deviceInfoHash = {};

            if (_port) {
                _port.disconnect();
                _port = null;
            }
        }

        function disconnect(hidDeviceType) {
            var hidData = hidDeviceType ? HeadsetHidDeviceData[hidDeviceType] : null;
            if (hidDeviceType && !hidData) {
                return;
            }

            LogSvc.info('[HeadsetHidControlSvc]: Disconnect Hid Service for ', hidDeviceType || 'ALL');

            setActiveCall(null);
            if (_connectTimeout) {
                $timeout.cancel(_connectTimeout);
                _connectTimeout = null;
            }
            _connectError = false;

            if (hidData) {
                Utils.removeArrayElement(_enabledIntegrations, hidData);
            } else {
                _enabledIntegrations = [];
            }
            // Save the disconnected state so it won't be automatically reconnected in the next login
            saveIntegration();

            if (_integrationEnabled) {
                if (hidData) {
                    if (_activeHeadsetDevice && hidData.vendorIds.includes(_activeHeadsetDevice.vendorId)) {
                        // Disconnect active device since we disable the integration for this headset type
                        setActiveDevice(null);
                    }
                    if (hidData.statusLightControl) {
                        // Disconnect status light since we disable the integration for this device type
                        disconnectStatusLight(hidData.type);
                    }
                }
                resetDeviceInfo();
            } else {
                disconnectPort();
                _chromeAppStatus = ChromeAppStatusCodes.APP_INSTALLED;
            }
        }

        function isChromeAppConnected(hidDeviceType) {
            if (_port && _port.headsetAppConnected) {
                return !hidDeviceType || isHeadsetTypeEnabled(hidDeviceType);
            }
            return false;
        }

        function isCallControlAvailable() {
            if (!_activeHeadsetDevice || _chromeAppStatus !== ChromeAppStatusCodes.DEVICE_CONNECTED) {
                return false;
            }
            return true;
        }

        function isCallControlMessage(msg) {
            return Object.keys(DeviceCallControlMessage).some(function (key) {
                return DeviceCallControlMessage[key] === msg;
            });
        }

        function resetDeviceInfo() {
            _deviceInfoHash = {};
            setDeviceInfo(_devices);
        }

        function getDevices() {
            sendRequest({type: ApplicationMessage.GET_DEVICES}, function (err, devices) {
                if (!err) {
                    _devices = devices;
                    resetDeviceInfo();
                } else {
                    LogSvc.error('[HeadsetHidControlSvc]: Could not get list of devices. ', err);
                }
            });
        }

        function setStatusLight(state) {
            var data = StatusLightControl[state || _statusLightState];
            if (data && Object.keys(_activeStatusLightDevices).length > 0) {
                LogSvc.debug('[HeadsetHidControlSvc]: Set status light on connected devices: ', data);
                sendRequest({type: ApplicationMessage.SET_STATUS_LIGHT, data: data});
            }
        }

        function setStatusLightState() {
            var newState;
            if (!RegistrationSvc.isRegistered()) {
                newState = StatusLightStates.OFF;
            } else if (_activeCall && _activeCall.isEstablished() && _activeCall.localMediaType.video) {
                newState = StatusLightStates.BUSY_VIDEO;
            } else {
                switch ($rootScope.localUser.userPresenceState.state) {
                case Constants.PresenceState.BUSY:
                    newState = StatusLightStates.BUSY;
                    break;
                case Constants.PresenceState.DND:
                    newState = StatusLightStates.DND;
                    break;
                case Constants.PresenceState.AWAY:
                    newState = _alertingCall ? StatusLightStates.ALERTING_CALL : StatusLightStates.AWAY;
                    break;
                default:
                    if (_alertingCall) {
                        newState = StatusLightStates.ALERTING_CALL;
                    } else if (UserProfileSvc.getUserIdleState() === IdleState.Idle) {
                        newState = StatusLightStates.AWAY;
                    } else {
                        newState = StatusLightStates.AVAILABLE;
                    }
                    break;
                }
            }

            if (newState !== _statusLightState) {
                _statusLightState = newState;
                LogSvc.debug('[HeadsetHidControlSvc]: Set status light state to ', _statusLightState);
                setStatusLight();
            }
        }

        function connectStatusLight(device, hidData) {
            if (!device || !hidData || !_enabledIntegrations.includes(hidData)) {
                return;
            }

            LogSvc.info('[HeadsetHidControlSvc]: Connect to status light device: ', device.productName);
            sendRequest({type: ApplicationMessage.CONNECT_STATUS_LIGHT, data: device.deviceId}, function (err) {
                if (err) {
                    LogSvc.error('[HeadsetHidControlSvc]: Could not connect to device ' + device.productName + '. ', err);
                    if (_activeStatusLightDevices[hidData.type] === device) {
                        delete _activeStatusLightDevices[hidData.type];
                    }
                } else {
                    _activeStatusLightDevices[hidData.type] = device;
                    setStatusLight();
                }
            });
        }

        function disconnectStatusLight(hidDeviceType) {
            if (!hidDeviceType) {
                return;
            }
            var device = _activeStatusLightDevices[hidDeviceType];
            if (!device) {
                return;
            }
            delete _activeStatusLightDevices[hidDeviceType];

            LogSvc.info('[HeadsetHidControlSvc]: Disconnect status light device: ', device.productName);
            sendRequest({type: ApplicationMessage.DISCONNECT_STATUS_LIGHT, data: device.deviceId}, function (err) {
                if (err) {
                    LogSvc.error('[HeadsetHidControlSvc]: Could not disconnect from device ' + device.productName + '. ', err);
                }
            });
        }

        function setHeadsetDevices(devices) {
            if (!devices || !devices.length) {
                return;
            }

            $window.navigator.mediaDevices.enumerateDevices()
            .then(function (deviceInfos) {
                if (!deviceInfos || !deviceInfos.length) {
                    return;
                }
                var allDevices = deviceInfos.map(function (d) { return {id: d.deviceId}; });
                var rtcDevice = Utils.selectMediaDevice(allDevices, RtcSessionController.playbackDevices) || {};

                devices.forEach(function (device) {
                    var productName = device.productName;
                    if (productName.endsWith(' USB')) {
                        // Remove the ' USB' from the product name to address a Meeting Room issue where
                        // device.productName doesn't match info.label.
                        productName = productName.slice(0, productName.length - 4);
                    }

                    var matchedInfo = null;
                    deviceInfos.some(function (info) {
                        if (info.kind !== 'audiooutput' || info.deviceId === 'default' || info.deviceId === 'communications') {
                            return false;
                        }
                        if (info.label.indexOf(productName) !== -1) {
                            LogSvc.info('[HeadsetHidControlSvc]: Found matching deviceInfo ', Utils.flattenObj(info));
                            if (matchedInfo) {
                                // There is more than one audio output device which matches the
                                // given device name. Since we have no way to correlate the devices
                                // we cannot control it.
                                LogSvc.warn('[HeadsetHidControlSvc]: Found multiple output devices matching the device name. We cannot control this device.');
                                matchedInfo = null;
                                return true;
                            }
                            // Set the matched deviceInfo, but keep searching to ensure that we
                            // don't have multiple matches.
                            matchedInfo = info;
                        }
                        return false;
                    });
                    if (matchedInfo) {
                        _deviceInfoHash[device.deviceId] = matchedInfo;
                        if (matchedInfo.deviceId === rtcDevice.id) {
                            setActiveDevice(device);
                        }
                    }
                });
            })
            .catch(function (e) {
                LogSvc.error('[HeadsetHidControlSvc]: enumeratedDevices failed. ', e);
            });
        }

        function setDeviceInfo(devices) {
            var headsetDevices = devices.filter(function (device) {
                if (!device.productName) {
                    return false;
                }

                var hidData = _enabledIntegrations.find(function (integration) {
                    return integration.vendorIds.includes(device.vendorId);
                });

                if (!hidData) {
                    // Integration is not enabled for this device
                    return false;
                }
                if (hidData.statusLightControl && !_activeStatusLightDevices[hidData.type]) {
                    _activeStatusLightDevices[hidData.type] = device;
                    connectStatusLight(device, hidData);
                }

                return hidData.headsetControl;
            });

            setHeadsetDevices(headsetDevices);
        }

        function setActiveDevice(device, showErrorMsg) {
            if (_activeHeadsetDevice) {
                if (device && _activeHeadsetDevice.deviceId === device.deviceId) {
                    // Already connected
                    return;
                }
                // Send a disconnect to the previous active device
                LogSvc.debug('[HeadsetHidControlSvc]: Disconnect active device: ', _activeHeadsetDevice.productName);
                // ANS-88790: clear local reference as the callback will be executed after the HOOK_ON event is received
                // and it would not be possbile to ignore that event for disconnected device in an active call
                _activeHeadsetDevice = null;
                sendRequest({type: ApplicationMessage.DISCONNECT_DEVICE}, function (err) {
                    if (err && _activeHeadsetDevice) {
                        // Try to connect even if the disconnect failed
                        LogSvc.warn('[HeadsetHidControlSvc]: Previously connected device ' + _activeHeadsetDevice.deviceId +
                            ' could not be disconnected.', err);
                    }
                    _chromeAppStatus = ChromeAppStatusCodes.APP_CONNECTED;
                    device && connectDevice(device);
                });
            } else {
                connectDevice(device, showErrorMsg);
            }
        }

        function connectDevice(device, showErrorMsg) {
            if (device) {
                // Check if the the integration for the given headset type is enabled
                var isEnabled = _enabledIntegrations.some(function (hidData) {
                    return hidData.headsetControl && hidData.vendorIds.includes(device.vendorId);
                });

                if (!isEnabled) {
                    LogSvc.info('[HeadsetHidControlSvc]: Cannot connect because integration is not enabled for this headset vendor. ', device);
                    return;
                }

                LogSvc.info('[HeadsetHidControlSvc]: Set the active device to match the selected audio output device');
                sendRequest({type: ApplicationMessage.CONNECT_DEVICE, data: device.deviceId}, function (err) {
                    _activeHeadsetDevice = device;
                    if (!err) {
                        _chromeAppStatus = ChromeAppStatusCodes.DEVICE_CONNECTED;
                        if (_activeCall) {
                            sendOffhook();
                        } else if (_alertingCall) {
                            sendRingOnToDevice(_alertingCall);
                        }
                    } else {
                        LogSvc.error('[HeadsetHidControlSvc]: Could not connect to device ' + device.productName + '. ', err);
                        _chromeAppStatus = ChromeAppStatusCodes.DEVICE_CONNECT_FAILED;
                        if (err.code === HeadsetIntegrationApp.ErrorCodes.DEVICE_ALREADY_IN_USE) {
                            if (showErrorMsg) {
                                PopupSvc.error({message: 'res_HeadsetAlreadyInUse', messageParams: [device.productName, err.domain]});
                            } else {
                                LogSvc.debug('[HeadsetHidControlSvc]: Publish /headset/alreadyInUse event');
                                PubSubSvc.publish('/headset/alreadyInUse', {
                                    headsetName: device.productName,
                                    domain: err.domain
                                });
                            }
                        }
                    }
                });
            } else {
                _activeHeadsetDevice = null;
                if (isChromeAppConnected()) {
                    _chromeAppStatus = ChromeAppStatusCodes.APP_CONNECTED;
                }
            }
        }

        function saveIntegration() {
            var enabledTypes = _enabledIntegrations.map(function (type) {
                return type.key;
            });
            _integrationEnabled = _enabledIntegrations.length > 0;
            LogSvc.debug('[HeadsetHidControlSvc]: Update enabled headset types in local store: ', enabledTypes);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.HEADSET_TYPES_ENABLED, enabledTypes);
        }

        function sendOffhook() {
            _offHookPending = true;
            sendCallControlRequest({type: ApplicationMessage.OFF_HOOK}, function () {
                _offHookPending = false;
            });
        }

        function setAlertingCall(call) {
            _alertingCall = call;
            setStatusLightState();

            if (_alertingCall && !_activeCall) {
                LogSvc.debug('[HeadsetHidControlSvc]: There are no active calls. Send ring notification to device.');
                sendRingOnToDevice(_alertingCall);
            }
        }

        function setActiveCall(call) {
            if (!call) {
                LogSvc.debug('[HeadsetHidControlSvc]: Set active call to null');
                _activeCall = null;
                setStatusLightState();
                return;
            }
            LogSvc.debug('[HeadsetHidControlSvc]: Set active call to ', call.callId);
            if (_activeCall) {
                // If there's a current active call, assume that the device is busy. Just update its muted status
                sendCallControlRequest({type: call.isMuted() ? ApplicationMessage.MUTE : ApplicationMessage.UNMUTE});
            } else {
                // Device not busy, send OFF_HOOK message. The muted status will be set when BUSY_ON is received
                sendOffhook();
            }
            _activeCall = call;
            setStatusLightState();
        }

        function getActiveSvcCall() {
            var call = CallControlSvc.getActiveCall();
            if (call && (call.isEstablished() || call.isOutgoingState())) {
                return call;
            }
            return null;
        }

        function sendRingOnToDevice(call) {
            if ($rootScope.localUser.userPresenceState.state !== Constants.PresenceState.DND && !call.doNotRingOnHeadset) {
                sendCallControlRequest({type: ApplicationMessage.RING_ON});
            } else {
                LogSvc.debug('[HeadsetHidControlSvc]: Do not ring this call. Not sending RING_ON to device.');
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        function onMessage(msg) {
            if (!msg) {
                return;
            }

            LogSvc.msgRcvd('[HeadsetHidControlSvc]: ', msg);

            if (isCallControlMessage(msg) && !isCallControlAvailable()) {
                LogSvc.warn('[HeadsetHidControlSvc]: Received call control message from device, but call control is not available');
                return;
            }

            if (msg.reqId) {
                // Response message
                LogSvc.debug('[HeadsetHidControlSvc]: Received response for reqId:', msg.reqId);
                var cbInfo = _reqCallbacks[msg.reqId];
                if (cbInfo) {
                    delete _reqCallbacks[msg.reqId];
                    if (cbInfo.timer) {
                        $timeout.cancel(cbInfo.timer);
                    }
                    $rootScope.$apply(function () {
                        cbInfo.cb(msg.error, msg.data);
                    });
                } else {
                    LogSvc.warn('[HeadsetHidControlSvc]: No callback found for reqId:', msg.reqId);
                }
                return;
            }

            $rootScope.$apply(function () {
                // Process Hid event
                switch (msg.type) {
                case HeadsetHidEvent.HOOK_OFF:
                    if (_alertingCall) {
                        var mediaType = {audio: true};
                        LogSvc.info('[HeadsetHidControlSvc]: Answer alerting call');
                        CallControlSvc.answerCall(_alertingCall.callId, mediaType);
                    }
                    break;
                case HeadsetHidEvent.HOOK_ON:
                    if (_offHookPending) {
                        LogSvc.info('[HeadsetHidControlSvc]: Ignore HOOK_ON from previous call.');
                        return;
                    }

                    // ANS-88790: handle HOOK_ON event during call for disconnected device (due to changed audio device)
                    if (!_activeHeadsetDevice) {
                        LogSvc.info('[HeadsetHidControlSvc]: Ignore HOOK_ON as there is no active headset.');
                        return;
                    }

                    if (_activeCall) {
                        var callId = _activeCall.callId;
                        LogSvc.info('[HeadsetHidControlSvc]: End active call');
                        CallControlSvc.endCall(callId);
                        setActiveCall(null);
                    }
                    var newActiveCall = getActiveSvcCall();
                    if (newActiveCall) {
                        setActiveCall(newActiveCall);
                    } else if (_alertingCall && !_alertingCall.checkState([CallState.Waiting, CallState.Answering])) {
                        LogSvc.debug('[HeadsetHidControlSvc]: There is a pending alerting call. Send ring notification to device. Call id:', _alertingCall.callId);
                        sendRingOnToDevice(_alertingCall);
                    }
                    break;
                case HeadsetHidEvent.MUTE_OFF:
                    if (_activeCall) {
                        if (_activeCall.unmuteNotAllowed) {
                            LogSvc.info('[HeadsetHidControlSvc]: Unmute is not allowed. Send mute request back to device.');
                            sendCallControlRequest({type: ApplicationMessage.MUTE});
                        } else {
                            LogSvc.info('[HeadsetHidControlSvc]: Unmute active call');
                            CallControlSvc.unmute(_activeCall.callId);
                        }
                    }
                    break;
                case HeadsetHidEvent.MUTE_ON:
                    if (_activeCall) {
                        LogSvc.info('[HeadsetHidControlSvc]: Mute active call');
                        CallControlSvc.mute(_activeCall.callId);
                    }
                    break;
                case HeadsetHidEvent.REJECT:
                    if (_alertingCall) {
                        LogSvc.info('[HeadsetHidControlSvc]: Decline alerting call');
                        CallControlSvc.endCall(_alertingCall.callId);
                    }
                    break;
                case HeadsetHidEvent.DEVICE_ADDED:
                    onDeviceAdded(msg.data);
                    break;
                case HeadsetHidEvent.DEVICE_REMOVED:
                    onDeviceRemoved(msg.data);
                    break;
                case HeadsetHidEvent.BUSY_ON:
                    if (_activeCall && _activeCall.isMuted()) {
                        // In case the call is started in muted state, we can only send the mute request
                        // to the device after the BUSY_ON event
                        sendCallControlRequest({type: ApplicationMessage.MUTE});
                    }
                    break;
                case HeadsetHidEvent.DISCONNECTED:
                case HeadsetHidEvent.IDLE:
                case HeadsetHidEvent.BUSY_OFF:
                    // Unused events but consume them so they won't generate an 'unexpected message' log
                    break;
                default:
                    LogSvc.warn('[HeadsetHidControlSvc]: Unexpected message type: ', msg.type);
                    break;
                }
            });
        }

        function onDeviceAdded(addedDevice) {
            if (!addedDevice) {
                return;
            }
            // Make sure device is not already in devices list
            var found = _devices.some(function (device) {
                return device.deviceId === addedDevice.deviceId;
            });
            if (!found) {
                LogSvc.debug('[HeadsetHidControlSvc]: New device added. deviceId = ', addedDevice.deviceId);
                _devices.push(addedDevice);
                // It take a little longer for navigator.mediaDevices.enumerateDevices to include
                // the newly added device
                $timeout(function () {
                    // Make sure the new device is still available
                    _devices.some(function (device) {
                        if (device.deviceId === addedDevice.deviceId) {
                            delete _deviceInfoHash[addedDevice.deviceId];
                            setDeviceInfo([addedDevice]);
                            return true;
                        }
                        return false;
                    });
                }, DEVICE_ADDED_TIMEOUT);
                LogSvc.debug('[HeadsetHidControlSvc]: Updated devices list: ', _devices);
            }
        }

        function onDeviceRemoved(removedDeviceId) {
            LogSvc.debug('[HeadsetHidControlSvc]: Device has been removed. deviceId = ', removedDeviceId);

            delete _deviceInfoHash[removedDeviceId];

            _devices.some(function (device, idx) {
                if (device.deviceId === removedDeviceId) {
                    _devices.splice(idx, 1);
                    LogSvc.debug('[HeadsetHidControlSvc]: Updated devices list: ', _devices);
                    return true;
                }
                return false;
            });

            if (_activeHeadsetDevice && _activeHeadsetDevice.deviceId === removedDeviceId) {
                LogSvc.info('[HeadsetHidControlSvc]: Active device has been removed.');
                _activeHeadsetDevice = null;
                _chromeAppStatus = ChromeAppStatusCodes.APP_CONNECTED;
            }

            var hidDeviceType = Object.keys(_activeStatusLightDevices).find(function (deviceType) {
                var device = _activeStatusLightDevices[deviceType];
                if (device && device.deviceId === removedDeviceId) {
                    LogSvc.info('[HeadsetHidControlSvc]: Connected status light device has been removed: ', device.productName);
                    delete _activeStatusLightDevices[deviceType];
                    return true;
                }
                return false;
            });

            var hidData = hidDeviceType && HeadsetHidDeviceData[hidDeviceType];
            if (hidData) {
                // Check if there are other status light devices for the same integration
                var otherDevice = _devices.find(function (device) {
                    return hidData.vendorIds.includes(device.vendorId);
                });
                if (otherDevice) {
                    LogSvc.info('[HeadsetHidControlSvc]: Connect to the next ' + hidDeviceType + ' status light device: ', otherDevice.productName);
                    connectStatusLight(otherDevice, hidData);
                }
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/registration/state', function () {
            setStatusLightState();
        });

        PubSubSvc.subscribeOnce('/localUser/init', function () {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /localUser/init event');
            init();
            getChromeAppStatusAndConnect(true);
        });

        PubSubSvc.subscribe('/localUser/update', function () {
            setStatusLightState();
        });

        PubSubSvc.subscribe('/localUser/tenantSettingsUpdate', function () {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /localUser/tenantSettingsUpdate event');
            setSupportedHeadsets();
        });

        PubSubSvc.subscribe('/feature/state/changed', function () {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /feature/state/changed event');
            setSupportedHeadsets();
        });

        PubSubSvc.subscribe('/internal/svc/idle/state', function (state) {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /internal/svc/idle/state event. state = ', state);
            setStatusLightState();
        });

        PubSubSvc.subscribe('/chromeExt/initialized', function () {
            if ($rootScope.localUser) {
                LogSvc.debug('[HeadsetHidControlSvc]: Received /chromeExt/initialized event');
                _retryCounter = 0;
                getChromeAppStatusAndConnect(false);
            }
        });

        PubSubSvc.subscribe('/chromeExt/unregistered', function () {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /chromeExt/unregistered event');
            disconnectPort();
            _chromeAppStatus = ChromeAppStatusCodes.UNKNOWN;
        });

        PubSubSvc.subscribe('/chromeExt/headsetAppLaunched', function (data) {
            if (data.appId === _chromeAppId) {
                LogSvc.info('[HeadsetHidControlSvc]: Received /chromeExt/headsetAppLaunched event');
                _chromeAppStatus = ChromeAppStatusCodes.APP_INSTALLED;
                if (_integrationEnabled) {
                    LogSvc.info('[HeadsetHidControlSvc]: Circuit Headset App is installed. Connect automatically.');
                    connect(false)
                    .catch(function (err) {
                        LogSvc.warn('[HeadsetHidControlSvc]: Failed to connect. ', err);
                    });
                }
            }
        });

        PubSubSvc.subscribe('/chromeExt/headsetAppUninstalled', function (data) {
            if (data.appId === _chromeAppId) {
                LogSvc.info('[HeadsetHidControlSvc]: Received /chromeExt/headsetAppUninstalled event');
                _chromeAppStatus = ChromeAppStatusCodes.APP_NOT_INSTALLED;
            }
        });

        function onCallIncoming(call) {
            if (!call) {
                return;
            }
            LogSvc.debug('[HeadsetHidControlSvc]: Incoming call. Set alertingCallId to ', call.callId);
            setAlertingCall(call);
        }
        PubSubSvc.subscribe('/call/incoming', onCallIncoming);


        function onCallState(call) {
            if (!call) {
                return;
            }
            if (call.isRemote && call.checkCstaState([CstaCallState.Ringing, CstaCallState.ExtendedRinging])) {
                onCallIncoming(call);
                return;
            }
            if (!call.isOutgoingState() && !call.isEstablished()) {
                return;
            }
            LogSvc.debug('[HeadsetHidControlSvc]: Received /call/state event for established call');

            // Update status light state if applicable
            setStatusLightState();

            if (!call.isRemote && (!_activeCall || _activeCall.callId !== call.callId)) {
                if (_alertingCall && _alertingCall.callId === call.callId) {
                    LogSvc.debug('[HeadsetHidControlSvc]: Alerting call has been answered');
                    setAlertingCall(null);
                }
                if (!_activeCall || CallControlSvc.getActiveCall() === call) {
                    setActiveCall(call);
                }
            } else if (_alertingCall && _alertingCall.callId === call.callId) {
                LogSvc.debug('[HeadsetHidControlSvc]: Alerting call has been answered by another client');
                if (!_activeCall) {
                    sendCallControlRequest({type: ApplicationMessage.RING_OFF});
                }
                setAlertingCall(null);
            }
        }
        PubSubSvc.subscribe('/call/state', onCallState);

        function onCallEnded(call, replaced) {
            if (!call || call.isRemote) {
                return;
            }

            LogSvc.info('[HeadsetHidControlSvc]: Received /call/ended event. Replaced:', !!replaced);

            if (_alertingCall && _alertingCall.callId === call.callId) {
                LogSvc.debug('[HeadsetHidControlSvc]: Alerting call has been canceled');
                setAlertingCall(null);

                if (!_activeCall) {
                    LogSvc.debug('[HeadsetHidControlSvc]: Send onhook notification to device');
                    sendCallControlRequest({type: ApplicationMessage.RING_OFF});
                }
            } else if (_activeCall && _activeCall.callId === call.callId && !replaced) {
                var newActiveCall = getActiveSvcCall();
                if (newActiveCall) {
                    LogSvc.debug('[HeadsetHidControlSvc]: Another active call found. Keep device offhook. New active call: ', newActiveCall.callId);
                    setActiveCall(newActiveCall);
                    return;
                }

                LogSvc.debug('[HeadsetHidControlSvc]: Active call has ended. Send onhook notification to device.');
                setActiveCall(null);
                sendCallControlRequest({type: ApplicationMessage.ON_HOOK});
            }
        }
        PubSubSvc.subscribe('/call/ended', onCallEnded);

        function onCallLocalUserMuted(callId, remotelyMuted, locallyMuted) {
            if (_activeCall && _activeCall.callId === callId) {
                var muted = remotelyMuted || locallyMuted;
                LogSvc.debug('[HeadsetHidControlSvc]: Local user has been ' + (muted ? 'muted' : 'unmuted') + '. Send notification to device.');
                sendCallControlRequest(muted ? {type: ApplicationMessage.MUTE} : {type: ApplicationMessage.UNMUTE});
            }
        }
        PubSubSvc.subscribe('/call/localUser/muted', onCallLocalUserMuted);

        function onCallLocalUserMutedSelf(callId, muted) {
            if (_activeCall && _activeCall.callId === callId) {
                LogSvc.debug('[HeadsetHidControlSvc]: Local user has ' + (muted ? 'muted' : 'unmuted') + ' self. Send notification to device.');
                sendCallControlRequest(muted ? {type: ApplicationMessage.MUTE} : {type: ApplicationMessage.UNMUTE});
            }
        }
        PubSubSvc.subscribe('/call/localUser/mutedSelf', onCallLocalUserMutedSelf);

        function onAtcRemoteCallInfo(atcCall) {
            if (_alertingCall && _alertingCall.callId === atcCall.callId) {
                if (atcCall.isEstablished() || atcCall.checkCstaState([CstaCallState.Idle])) {
                    LogSvc.debug('[HeadsetHidControlSvc]: Remote call was answered or terminated. Stop ringing');
                    sendCallControlRequest({type: ApplicationMessage.RING_OFF});
                    setAlertingCall(null);
                }
            }
        }
        PubSubSvc.subscribe('/atccall/info', onAtcRemoteCallInfo);

        function onAtcCallReplace() {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /atccall/replace event');
            if (_alertingCall && _alertingCall.isHandoverInProgress) {
                LogSvc.debug('[HeadsetHidControlSvc]: Remote call was answered locally. Stop ringing');
                sendCallControlRequest({type: ApplicationMessage.RING_OFF});
                setAlertingCall(null);
            }
        }
        PubSubSvc.subscribe('/atccall/replace', onAtcCallReplace);

        function onMediaDeviceRemoved(removed, allDevices) {
            LogSvc.debug('[HeadsetHidControlSvc]: Received /media/device/removed event');
            if (removed.playback.length) {
                var newDevice = Utils.selectMediaDevice(allDevices.playback, RtcSessionController.playbackDevices);
                if (newDevice) {
                    _that.resetActiveDevice(newDevice);
                }
            }
        }
        PubSubSvc.subscribe('/media/device/removed', onMediaDeviceRemoved);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        Object.defineProperties(this, {
            ChromeAppStatusCodes: {
                value: ChromeAppStatusCodes,
                enumerable: true,
                configurable: false
            },
            chromeAppId: {
                get: function () { return _chromeAppId; },
                set: function (appId) {
                    appId = appId || HEADSET_INTEGRATION_APP_ID;
                    if (_chromeAppId !== appId) {
                        _chromeAppId = appId;
                        LogSvc.info('[HeadsetHidControlSvc]: Set Chrome App ID as ', _chromeAppId);
                        if (isChromeAppConnected()) {
                            disconnect();
                        }
                        getChromeAppStatus();
                    }
                },
                enumerable: true,
                configurable: false
            },
            chromeAppStatus: {
                get: function () {
                    return _chromeAppStatus;
                },
                enumerable: true,
                configurable: false
            },
            connectError: {
                get: function () {
                    if (isChromeAppConnected()) {
                        // Make sure connectError is false if we are connected
                        _connectError = false;
                    }
                    return _connectError;
                },
                enumerable: true,
                configurable: false
            },
            activeDevice: {
                get: function () { return _activeHeadsetDevice; },
                enumerable: true,
                configurable: false
            }
        });

        this.isConnecting = function () {
            return _connecting;
        };

        this.isIntegrationEnabled = function (hidDeviceType) {
            return hidDeviceType ? isHeadsetTypeEnabled(hidDeviceType) : _integrationEnabled;
        };

        this.isHeadsetIntegration = function (hidDeviceType) {
            var hidData = hidDeviceType && HeadsetHidDeviceData[hidDeviceType];
            return !!(hidData && hidData.headsetControl);
        };

        this.isStatusLightIntegration = function (hidDeviceType) {
            var hidData = hidDeviceType && HeadsetHidDeviceData[hidDeviceType];
            return !!(hidData && hidData.statusLightControl);
        };

        this.getActiveStatusLightDevice = function (hidDeviceType) {
            return _activeStatusLightDevices[hidDeviceType];
        };

        this.getIntegrationStatus = function (hidDeviceType) {
            var hidData = hidDeviceType && HeadsetHidDeviceData[hidDeviceType];
            if (!hidData) {
                return _chromeAppStatus;
            }

            if (hidData.headsetControl) {
                // Headset integration
                switch (_chromeAppStatus) {
                case ChromeAppStatusCodes.APP_CONNECTED:
                    return isHeadsetTypeEnabled(hidDeviceType) ? _chromeAppStatus : ChromeAppStatusCodes.APP_INSTALLED;
                case ChromeAppStatusCodes.DEVICE_CONNECTED:
                case ChromeAppStatusCodes.DEVICE_CONNECT_FAILED:
                    if (hidData.vendorIds.includes(_activeHeadsetDevice.vendorId)) {
                        return _chromeAppStatus;
                    }
                    return isHeadsetTypeEnabled(hidDeviceType) ? ChromeAppStatusCodes.APP_CONNECTED : ChromeAppStatusCodes.APP_INSTALLED;
                default:
                    return _chromeAppStatus;
                }
            } else {
                // Status light integration
                switch (_chromeAppStatus) {
                case ChromeAppStatusCodes.APP_CONNECTED:
                case ChromeAppStatusCodes.DEVICE_CONNECTED:
                case ChromeAppStatusCodes.DEVICE_CONNECT_FAILED:
                    if (_activeStatusLightDevices[hidDeviceType]) {
                        return ChromeAppStatusCodes.DEVICE_CONNECTED;
                    }
                    return isHeadsetTypeEnabled(hidDeviceType) ? ChromeAppStatusCodes.APP_CONNECTED : ChromeAppStatusCodes.APP_INSTALLED;
                default:
                    return _chromeAppStatus;
                }
            }
        };

        this.installAndLaunchChromeApp = installAndLaunchChromeApp.bind(this);

        this.connect = function (hidDeviceType) {
            var hidData = hidDeviceType ? HeadsetHidDeviceData[hidDeviceType] : null;

            if (hidDeviceType && !hidData) {
                return $q.reject('Invalid headset type');
            }

            if (_connecting) {
                return $q.reject('Connection in progress');
            }

            LogSvc.info('[HeadsetHidControlSvc]: Connect to Circuit Headset App for ', hidDeviceType || 'ALL');

            if (isChromeAppConnected()) {
                // We are already connected. Check if we need to enabled a new headset type.
                var hasChanged = false;
                if (hidDeviceType) {
                    if (!_enabledIntegrations.includes(hidData)) {
                        _enabledIntegrations.push(hidData);
                        hasChanged = true;
                    }
                } else if (_enabledIntegrations.length !== _supportedIntegrations.length) {
                    _enabledIntegrations = _supportedIntegrations.slice();
                    hasChanged = true;
                }
                if (hasChanged) {
                    LogSvc.info('[HeadsetHidControlSvc]: Connected to Circuit Headset App for ', _enabledIntegrations);
                    saveIntegration();

                    // Reset the device info
                    resetDeviceInfo();
                }
                return $q.resolve();
            }

            var deferred = $q.defer();

            _connecting = true;
            getChromeAppStatus(function (status) {
                // Reset _connecting to false because it will be set again to
                // true (if applicable) inside the connect function.
                _connecting = false;

                switch (status) {
                case ChromeAppStatusCodes.APP_INSTALLED:
                    LogSvc.debug('[HeadsetHidControlSvc]: Circuit Headset App is installed. Connect to it.');

                    var previouslyConnectedHeadsets;
                    if (hidData) {
                        if (!_enabledIntegrations.includes(hidData)) {
                            previouslyConnectedHeadsets = _enabledIntegrations.slice();
                            _enabledIntegrations.push(hidData);
                        }
                    } else {
                        previouslyConnectedHeadsets = _enabledIntegrations;
                        _enabledIntegrations = _supportedIntegrations.slice();
                    }

                    connect(true)
                    .then(function () {
                        deferred.resolve();
                    })
                    .catch(function (connectErr) {
                        var onError = function (err) {
                            if (previouslyConnectedHeadsets) {
                                _enabledIntegrations = previouslyConnectedHeadsets;
                            }
                            deferred.reject(err);
                        };
                        if (connectErr === ChromeAppConnectErrorCodes.COULD_NOT_CONNECT) {
                            // Sometimes Chrome Apps are started by Chrome in sandbox mode, so we
                            // can't connect to them. Restarting our Chrome App might clear this problem
                            ExtensionSvc.restartHeadsetApp(_chromeAppId, function () {
                                connect(true)
                                .then(function () {
                                    deferred.resolve();
                                })
                                .catch(function (err) {
                                    onError(err);
                                });
                            });
                        } else {
                            onError(connectErr);
                        }
                    });
                    break;

                case ChromeAppStatusCodes.APP_DISABLED:
                    LogSvc.warn('[HeadsetHidControlSvc]: Circuit Headset App is disabled. Cannot connect to it.');
                    PopupSvc.error({message: 'res_HeadsetAppDisabled'});
                    _connectError = true;
                    deferred.reject('Circuit Headset App is disabled');
                    break;

                default:
                    LogSvc.warn('[HeadsetHidControlSvc]: Circuit Headset App is not installed. Cannot connect to it.');
                    _connectError = true;
                    deferred.reject('Circuit Headset App is not installed');
                    break;
                }
            });

            return deferred.promise;
        };

        this.disconnect = disconnect.bind(this);

        this.disconnectPort = function () {
            disconnectPort();
            _chromeAppStatus = ChromeAppStatusCodes.APP_INSTALLED;
        };

        this.isChromeAppConnected = isChromeAppConnected.bind(this);

        this.resetActiveDevice = function (newDevice) {
            LogSvc.debug('[HeadsetHidControlSvc]: Reset the active device');
            if (!isChromeAppConnected() || !newDevice) {
                setActiveDevice(null);
                return;
            }

            var found = _devices.some(function (device) {
                var deviceInfo = _deviceInfoHash[device.deviceId];
                if (deviceInfo && deviceInfo.deviceId === newDevice.id) {
                    setActiveDevice(device, true);
                    return true;
                }
                return false;
            });
            if (!found && _activeHeadsetDevice) {
                LogSvc.debug('[HeadsetHidControlSvc]: Clear the active device');
                setActiveDevice(null);
            }
        };

        this.disableIntegration = disconnect.bind(this);

        this.getFileFromApp = function (name, lang) {
            var deferred = $q.defer();
            if (isChromeAppConnected()) {
                sendRequest({type: ApplicationMessage.GET_FILE, data: {name: name, lang: lang}}, function (err, file) {
                    err ? deferred.reject(err) : deferred.resolve(file);
                });
            } else {
                deferred.reject('Not connected');
            }
            return deferred.promise;
        };

        this.setStatusLight = setStatusLight;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initializations
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.HeadsetHidControlSvcImpl = HeadsetHidControlSvcImpl;
    circuit.StatusLightControl = StatusLightControl;
    circuit.Enums.HeadsetHidDeviceType = HeadsetHidDeviceType;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
