var Circuit = (function (circuit) {
    'use strict';

    /**
     * A Circuit error object derived from the JavaScript error object.
     *
     * @class Error
     * @constructor
     * @param {String} code Error code
     * @param {String|Object} err The error message, or a detailed error object.
     */
    circuit.Error = function (code, err) {
        this.code = code || 'UNEXPECTED_ERROR';

        if (typeof err === 'string') {
            this.message = err;
        } else if (typeof err === 'object') {
            this.errObj = err;
            if (err.info && !err.message) {
                err.message = err.info;
            }
        }
        this.stack = Error().stack;
    };
    circuit.Error.prototype = Object.create(Error.prototype);
    circuit.Error.prototype.name = 'Error';

    /**
     * Error message
     *
     * @property message
     * @type String
     */

    /**
     * Error code as defined in Circuit.Constants.ReturnCode or Circuit.Constants.ErrorCode.
     * E.g. `UNEXPECTED_ERROR`
     *
     * @property code
     * @type String
     */

    /**
     * Detailed error object containing detailed information that can be used when debugging. The object
     * is different depending on the type of error.
     *
     * @property errObj
     * @type Object
     */
    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define

