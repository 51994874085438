/* global log4javascript */
/* eslint-disable no-console */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var BaseLogger = circuit.BaseLogger;
    var storeManager = circuit.storeManager;

    // eslint-disable-next-line max-lines-per-function
    function Logger() { // NOSONAR
        // Singleton pattern
        if (Logger.prototype._singletonInstance) {
            return Logger.prototype._singletonInstance;
        }
        Logger.prototype._singletonInstance = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var ENABLE_POPUP_LOG_KEY = 'enablePopupLog';
        var REQUEST_FILE_SYSTEM_ERROR = 'REQUEST_FILE_SYSTEM_ERROR';

        ///////////////////////////////////////////////////////////////////////////////////////
        // Worker Thread
        ///////////////////////////////////////////////////////////////////////////////////////
        var LogFileWorkerMsgType = { // This must be in synch with the definition in logFileWorker.js
            START: 'start',
            LOG: 'log',
            GET_LOG_FILES: 'getLogFiles',
            REMOVE_LOG_FILES: 'removeLogFiles',
            USER_INFO: 'userInfo',
            EXTENSION_VERSION: 'extensionVersion',
            LOG_ERROR: 'logError'
        };

        var _logFileWorker = new Worker('./logFileWorker.js');

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _that = this;
        var _popAppender = null;
        var _isFileLogAvailable = true;

        ///////////////////////////////////////////////////////////////////////////////////////
        // File Appender
        ///////////////////////////////////////////////////////////////////////////////////////
        function FileAppender() {}
        FileAppender.prototype = new log4javascript.Appender();
        FileAppender.prototype.layout = new log4javascript.PatternLayout('%d{yy-MM-dd HH:mm:ss.SSS} %p %m');
        FileAppender.prototype.threshold = log4javascript.Level.TRACE;
        FileAppender.prototype.append = function (loggingEvent) {
            var appender = this;

            var getFormattedMessage = function () {
                var layout = appender.getLayout();
                var formattedMessage = layout.format(loggingEvent);
                if (layout.ignoresThrowable() && loggingEvent.exception) {
                    formattedMessage += loggingEvent.getThrowableStrRep();
                }
                return formattedMessage;
            };

            var formattedMessage = getFormattedMessage();
            appender.writeToFile(formattedMessage);
        };
        FileAppender.prototype.startLogger = function (isGuest, clientVersion, isC4O365) {
            _logFileWorker && _logFileWorker.postMessage({
                type: LogFileWorkerMsgType.START,
                isGuest: !!isGuest,
                isC4O365: !!isC4O365,
                clientVersion: clientVersion
            });
        };
        FileAppender.prototype.writeToFile = function (message) {
            // Send log message to the worker thread (asynchronously)
            _logFileWorker && _logFileWorker.postMessage({
                type: LogFileWorkerMsgType.LOG,
                log: message
            });
        };
        FileAppender.prototype.errorHandler = function (e) {
            console.log('File appender error: ', e.toString()); //NOSONAR
        };
        FileAppender.prototype.removeLogFiles = function (cb) {
            _logFileWorker && _logFileWorker.postMessage({
                type: LogFileWorkerMsgType.REMOVE_LOG_FILES
            });
            this.getLogFilesCb = cb;
        };
        FileAppender.prototype.getLogFiles = function (cb) {
            if (!_isFileLogAvailable) {
                cb && cb([]);
                return;
            }
            _logFileWorker && _logFileWorker.postMessage({
                type: LogFileWorkerMsgType.GET_LOG_FILES
            });
            this.getLogFilesCb = cb;
        };
        FileAppender.prototype.setUserInfo = function (userInfo, isCachingDisabled) {
            _logFileWorker && _logFileWorker.postMessage({
                type: LogFileWorkerMsgType.USER_INFO,
                userInfo: userInfo,
                isCachingDisabled: !!isCachingDisabled
            });
        };
        FileAppender.prototype.setExtensionVersion = function (extensionVersion) {
            _logFileWorker && _logFileWorker.postMessage({
                type: LogFileWorkerMsgType.EXTENSION_VERSION,
                extensionVersion: extensionVersion
            });
        };
        FileAppender.prototype.toString = function () {
            return 'FileAppender';
        };
        log4javascript.FileAppender = FileAppender;

        var _fileAppender = new log4javascript.FileAppender();

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function addPopupAppender() {
            if (!_popAppender) {
                storeManager.setItem(ENABLE_POPUP_LOG_KEY, JSON.stringify(true));

                _popAppender = new log4javascript.PopUpAppender();
                var popLayout = new log4javascript.PatternLayout('%d{yyyy MMM dd HH:mm:ss,SSS} %p %m');

                _popAppender.setLayout(popLayout);
                _popAppender.setInitiallyMinimized(true);
                _popAppender.setMaxMessages(5000);
                _popAppender.setUseOldPopUp(false);

                _that.addAppender(_popAppender);
            }
        }

        function removePopupAppender() {
            if (_popAppender) {
                storeManager.setItem(ENABLE_POPUP_LOG_KEY, JSON.stringify(false));

                _popAppender.close();
                _that.removeAppender(_popAppender);
                _popAppender = null;
            }
        }

        function initAppenders() {
            _that.removeAllAppenders();

            // Add file appender
            _that.addAppender(_fileAppender);

            // Check if we are supposed to enable the popup appender
            var enablePopup = storeManager.getItem(ENABLE_POPUP_LOG_KEY);
            if (enablePopup && JSON.parse(enablePopup)) {
                addPopupAppender();
            }
        }

        ///////////////////////////////////////////////////////////////////////////
        // Event handlers
        ///////////////////////////////////////////////////////////////////////////
        // Interface to receive asynchronous data from _logFileWorker
        _logFileWorker.addEventListener('message', function (e) {
            var data = e.data;

            if (data.logFiles) {
                if (_fileAppender.getLogFilesCb) {
                    _fileAppender.getLogFilesCb(data.logFiles);
                }
                _fileAppender.getLogFilesCb = null;
            }
            if (data.error) {
                console.log('logFileWorker: ', data.error); //NOSONAR
                if (data.error.indexOf(REQUEST_FILE_SYSTEM_ERROR) !== -1) {
                    _isFileLogAvailable = false;
                    _logFileWorker.terminate();
                    _logFileWorker = null;
                }
            }
        }, false);

        // Terminate the worker when user reloads the page.
        window.addEventListener('beforeunload', function () {
            _logFileWorker && _logFileWorker.terminate();
        });

        ///////////////////////////////////////////////////////////////////////////
        // Public interface
        ///////////////////////////////////////////////////////////////////////////
        this.show = function () {
            _popAppender && _popAppender.show();
        };

        this.hide = function () {
            _popAppender && _popAppender.hide();
        };

        this.enablePopup = function () {
            addPopupAppender();
        };

        this.disablePopup = function () {
            removePopupAppender();
        };

        this.isPopupEnabled = function () {
            return !!_popAppender;
        };

        this.isFileLogAvailable = function () {
            return _isFileLogAvailable;
        };

        this.startFileLogger = function (isGuest, clientVersion, isC4O365) {
            _fileAppender.startLogger(isGuest, clientVersion, isC4O365);
        };

        this.getLogFiles = function (cb) {
            _fileAppender.getLogFiles(cb);
        };

        this.removeLogFiles = function (cb) {
            _fileAppender.removeLogFiles(cb);
        };

        this.setUserInfo = function (userInfo, isCachingDisabled) {
            _fileAppender.setUserInfo(userInfo, isCachingDisabled);
        };

        this.setExtensionVersion = function (extensionVersion) {
            _fileAppender.setExtensionVersion(extensionVersion);
        };

        ///////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////
        initAppenders();

        return this;
    }
    Logger.prototype = new BaseLogger();
    Logger.prototype.constructor = Logger;

    // Exports
    // In case a logger is already defined keep that one
    circuit.Logger = circuit.Logger || Logger;
    circuit.logger = circuit.logger || new Logger();

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
