/*global ArrayBuffer, DataView, Float32Array, Float64Array*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Utils = circuit.Utils;
    var WebRTCAdapter = circuit.WebRTCAdapter;
    var RtcSessionController = circuit.RtcSessionController;

    var RecState = Object.freeze({
        REPLAYING: 'replaying',
        PRE_RECORDING: 'pre-recording',
        RECORDING: 'recording',
        SAVING: 'saving'
    });

    // eslint-disable-next-line max-params, max-lines-per-function
    function RecorderSvcImpl($window, $q, $timeout, LogSvc, DeviceHandlerSvc) { // NOSONAR
        LogSvc.debug('New Service: RecorderSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var BUFFER_SIZE = 4096;
        var NUMBER_OF_CHANNELS = 1;
        var MAX_RECORDING_DURATION = 30; // value of seconds

        var SUPPORTED_TYPES = [
            'audio/mp4',
            'audio/ogg;codecs=opus',
            'audio/wav',
            'audio/webm;codecs=opus',
            'video/mp4',
            'video/webm;codecs=vp8, opus',
            'video/webm;codecs=vp9, opus'
        ];

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _stream = null;
        var _activeRecording = null;
        var _recordingTimeout = null;

        // Media Recorder API properties
        var _recorder;
        var _chunks = [];

        // Web Audio API properties
        var _audioCtx = null;
        var _processor = null;
        var _source = null;
        var _config = null;
        var _channel = [];
        var _length = 0;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function isTypeSupported(type) {
            return ($window.MediaRecorder && $window.MediaRecorder.isTypeSupported(type)) ||
                (type === 'audio/wav' && $window.AudioContext);
        }

        function clearData() {
            _chunks = [];
            _channel = [];
            _length = 0;
        }

        function stopMediaRecorder() {
            if (_recorder) {
                try {
                    _recorder.ondataavailable = null;
                    _recorder.stop();
                } catch (e) {
                    LogSvc.error('[RecorderSvc]: Error stopping MediaRecorder. ', e);
                } finally {
                    _recorder = null;
                }
            }
        }

        function disconnectAudioContext() {
            if (_processor) {
                try {
                    _processor.audioDataAvailable = null;
                    _processor.disconnect();
                } catch (e) {
                    LogSvc.error('[RecorderSvc]: Error disconnecting processor. ', e);
                } finally {
                    _processor = null;
                }
            }
            if (_source) {
                try {
                    _source.disconnect();
                } catch (e) {
                    LogSvc.error('[RecorderSvc]: Error disconnecting source. ', e);
                } finally {
                    _source = null;
                }
            }
            if (_audioCtx) {
                try {
                    _audioCtx.close();
                } catch (e) {
                    LogSvc.error('[RecorderSvc]: Error closing AudioContext. ', e);
                } finally {
                    _audioCtx = null;
                }
            }
        }

        function audioDataAvailable(e) {
            if (!_stream) {
                // We shouldn't get here. Disconnect the audio context.
                disconnectAudioContext();
                return;
            }

            if (e.inputBuffer.numberOfChannels > 0) {
                if (_length === 0) {
                    LogSvc.debug('[RecorderSvc]: Audio data is available.');
                }
                var data = e.inputBuffer.getChannelData(0);
                _channel.push(new Float32Array(data));
                _length += _processor.bufferSize;
            }
        }


        function mergeBuffers(channelBuffer, length) {
            LogSvc.debug('[RecorderSvc]: Merging buffers: ', length);
            var result = new Float64Array(length);
            var offset = 0;
            var len = channelBuffer.length;

            for (var i = 0; i < len; i++) {
                var buffer = channelBuffer[i];
                result.set(buffer, offset);
                offset += buffer.length;
            }

            return result;
        }

        function linearInterpolate(before, after, atPoint) {
            return before + (after - before) * atPoint;
        }

        function interpolateArray(data, sampleRate, recordingSampleRate) {
            if (!sampleRate || recordingSampleRate === sampleRate) {
                return data;
            }
            var count = Math.round(data.length * (sampleRate / recordingSampleRate));
            var springFactor = Number((data.length - 1) / (count - 1));

            var result = [data[0]]; // for new allocation
            for (var i = 1; i < count - 1; i++) {
                var tmp = i * springFactor;
                var before = Number(Math.floor(tmp)).toFixed();
                var after = Number(Math.ceil(tmp)).toFixed();
                var atPoint = tmp - before;
                result[i] = linearInterpolate(data[before], data[after], atPoint);
            }
            result[count - 1] = data[data.length - 1]; // for new allocation
            return result;
        }

        function writeUTFBytes(view, offset, string) {
            var len = string.length;
            for (var i = 0; i < len; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        }

        function createWav() {
            try {
                var data = mergeBuffers(_channel, _length);
                var interleaved = interpolateArray(data, _config.sampleRate, _config.recordingSampleRate);
                var interleavedLength = interleaved.length;

                LogSvc.debug('[RecorderSvc]: Creating WAV file: ' + NUMBER_OF_CHANNELS + ' channels, ' + _config.sampleRate + 'Hz, ' + interleavedLength);

                var resultingBufferLength = 44 + interleavedLength * 2;

                var buffer = new ArrayBuffer(resultingBufferLength);

                var view = new DataView(buffer);

                // RIFF chunk descriptor/identifier
                writeUTFBytes(view, 0, 'RIFF');

                // RIFF chunk length
                view.setUint32(4, 44 + interleavedLength * 2, true);

                // RIFF type
                writeUTFBytes(view, 8, 'WAVE');

                // format chunk identifier
                // FMT sub-chunk
                writeUTFBytes(view, 12, 'fmt ');

                // format chunk length
                view.setUint32(16, 16, true);

                // sample format (raw)
                view.setUint16(20, 1, true);

                // stereo (2 channels)
                view.setUint16(22, NUMBER_OF_CHANNELS, true);

                // sample rate
                view.setUint32(24, _config.sampleRate, true);

                // byte rate (sample rate * block align)
                view.setUint32(28, _config.sampleRate * 2, true);

                // block align (channel count * bytes per sample)
                view.setUint16(32, NUMBER_OF_CHANNELS * 2, true);

                // bits per sample
                view.setUint16(34, 16, true);

                // data sub-chunk
                // data chunk identifier
                writeUTFBytes(view, 36, 'data');

                // data chunk length
                view.setUint32(40, interleavedLength * 2, true);

                // write the PCM samples
                var len = interleavedLength;
                var index = 44;
                var volume = 1;
                for (var i = 0; i < len; i++) {
                    view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
                    index += 2;
                }

                _chunks = [view];
            } catch (e) {
                LogSvc.error('[RecorderSvc]: Failed to create wav file. ', e);
                _chunks = [];
            }
        }

        function stopResources() {
            if (!_stream) {
                return;
            }

            try {
                $timeout.cancel(_recordingTimeout);
                _recordingTimeout = null;

                if (_recorder) {
                    stopMediaRecorder();
                } else if (_audioCtx) {
                    disconnectAudioContext();
                }
                WebRTCAdapter.stopMediaStream(_stream);
            } catch (e) {
                LogSvc.debug('[RecorderSvc]: Error freeing resources. ', e);
            }

            _stream = null;
        }

        function stopRecording() {
            if (!_activeRecording) {
                return $q.reject('Recorder was not started.');
            }

            var deferred = $q.defer();
            try {
                LogSvc.debug('[RecorderSvc]: Stopping active recording.');
                stopResources();

                if (!_chunks.length && _channel.length) {
                    createWav();
                }

                var blob = new Blob(_chunks, {type: _config.mimeType});
                clearData();

                _activeRecording.blob = blob;
                if (typeof _activeRecording.ondone === 'function') {
                    _activeRecording.ondone(blob);
                }

                deferred.resolve();
            } catch (error) {
                LogSvc.error('[RecorderSvc]: Failed to stop recording: ', error);
                deferred.reject(error);
            }

            _activeRecording = null;

            return deferred.promise;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal recording constructor
        ///////////////////////////////////////////////////////////////////////////////////////
        function Recording() {
            this.blob = null;

            this.ondone = null;
            this.oncanceled = null;

            this.isActive = function () {
                return this === _activeRecording;
            };

            this.stop = function () {
                if (this === _activeRecording) {
                    return stopRecording();
                }
                return $q.reject('Recording has already stopped');
            };
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Gets supported audio/video or audio-only recording types.
         *
         * @returns {Array} array of all supported types
         */
        this.getSupportedTypes = function () {
            return SUPPORTED_TYPES.filter(function (type) {
                return isTypeSupported(type);
            });
        };

        /**
         * Checks whether the specified recording type is supported.
         *
         * @param {String} type the media type to check
         * @returns {Boolean} true if supported, otherwise false
         */
        this.isTypeSupported = isTypeSupported;

        /**
         * Checks whether there is a recording in progress
         *
         * @returns {Boolean} true if thre is a recording in progress, otherwise false
         */
        this.isRecording = function () {
            return !!_activeRecording;
        };

        /**
         * Starts media recorder with optional config parameter. If this parameter is missing or does not contain
         * mimeType property, then 'audio/wav' with 22kHz is assumed.
         *
         * This method returns a promise, which when resolved will provide the recording object. The caller must set
         * 'ondone' listener to it in order to get the recorded blob.
         *
         * If recorder is already started or the specified recording type is not supported, returned promise will be
         * rejected with error description.
         *
         * @param {Object} config recorder configuration object
         * @returns {Promise<Blob>} promisse that will be resolved on success or rejected with error otherwise
         * @example
         * RecorderSvc.start().then(function (activeRecording) {
         *   activeRecording.ondone = function (blob) {
         *     audio.src = URL.createObjectURL(blob);
         *     audio.play();
         *   }
         * });
         * @example
         * var recording = null;
         * RecorderSvc.start().then(function (activeRecording) {
         *   recording = activeRecording;
         * });
         * // to stop recording later
         * if (recording) {
         *   recording.stop().then(function () {
         *     audio.src = URL.createObjectURL(recording.blob);
         *     audio.play();
         *   });
         * }
         */
        this.start = function (config) {
            if (_stream) {
                return $q.reject('Recording is already in progress.');
            }

            _config = config || {};
            if (!_config.mimeType) {
                _config.mimeType = 'audio/wav';
                _config.sampleRate = 22050;
            }

            if (!isTypeSupported(_config.mimeType)) {
                return $q.reject('Media type is not supported by this recorder: ' + _config.mimeType);
            }

            var deferred = $q.defer();
            WebRTCAdapter.getMediaSources(function (audioSources) {
                var source = Utils.selectMediaDevice(audioSources, RtcSessionController.recordingDevices);
                var audioConstraints = {
                    enableAudioAGC: false,
                    enableAudioEC: false,
                    sourceId: source && source.id
                };
                DeviceHandlerSvc.getUserMedia({audio: audioConstraints, video: false}, function (stream) {
                    _stream = stream;
                    clearData();
                    try {
                        _recorder = new $window.MediaRecorder(stream, _config);
                        _recorder.ondataavailable = function (e) {
                            _stream && _chunks.push(e.data);
                        };
                    } catch (e) {
                        LogSvc.warn('[RecorderSvc]: Failed to create MediaRecorder.');
                        _audioCtx = DeviceHandlerSvc.getAudioContext();
                        if (!_audioCtx) {
                            LogSvc.warn('[RecorderSvc]: Failed to create AudioContext.');
                            deferred.reject('Recorder cannot be started.');
                            return;
                        }
                        _source = _audioCtx.createMediaStreamSource(stream);
                        _processor = _audioCtx.createScriptProcessor(BUFFER_SIZE, NUMBER_OF_CHANNELS, NUMBER_OF_CHANNELS);
                        _processor.onaudioprocess = audioDataAvailable;
                        _processor.connect(_audioCtx.destination);
                        _source.connect(_processor);
                        _config.recordingSampleRate = _audioCtx.sampleRate;
                    }
                    LogSvc.info('[RecorderSvc]: Media stream has been successfully started.');
                    var recording = new Recording();

                    _recordingTimeout = $timeout(function () {
                        LogSvc.info('[RecorderSvc]: Max duration reached. Stop the recording.');
                        _recordingTimeout = null;
                        recording.stop();
                    }, (_config.maxDuration || MAX_RECORDING_DURATION) * 1000);

                    _activeRecording = recording;
                    deferred.resolve(_activeRecording);
                }, function (error) {
                    LogSvc.error('[RecorderSvc]: Failed to access user media: ', error);
                    deferred.reject(error);
                });
            });
            return deferred.promise;
        };

        /**
         * Cancels the active media recorder. This method stops all running resources and clears recording data.
         * If recorder was not started no operation is done.
         */
        this.cancelRecording = function () {
            if (!_activeRecording) {
                return;
            }

            try {
                LogSvc.debug('[RecorderSvc]: Cancel active recording');
                stopResources();
                clearData();

                if (typeof _activeRecording.oncanceled === 'function') {
                    _activeRecording.oncanceled();
                }
            } catch (e) {
            }
            _activeRecording = null;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.RecorderSvcImpl = RecorderSvcImpl;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.RecState = RecState;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
