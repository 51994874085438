var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var RtcSessionController = circuit.RtcSessionController;
    var WebRTCAdapter = circuit.WebRTCAdapter;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function DeviceHandlerSvcImpl($window, $q, LogSvc, PubSubSvc, LocalStoreSvc, HeadsetHidControlSvc) { // NOSONAR
        LogSvc.debug('New Service: DeviceHandlerSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_BEEP = {
            freq: 450,
            duration: 0.05
        };

        var _videoMediaStream = null;
        var _pendingGetVideoMedia = false;
        var _stopPendingGetVideoMedia = false;
        var _mediaPromise = $q.when(); // empty promise
        var _that = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function init() {
            !$window.AudioContext && LogSvc.warn('[DeviceHandlerSvc]: AudioContext not supported, no audio feedback will be played while sending DTMF digits');
        }

        function createNewAudioContext() {
            if ($window.AudioContext) {
                LogSvc.debug('[DeviceHandlerSvc]: Creating new AudioContext');
                return new $window.AudioContext();
            }
            return null;
        }

        function initRtcSettings() {
            var agcData = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.AUDIOAGC);
            var ecData = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.AUDIOEC);

            RtcSessionController.enableAudioAGC = agcData === null ? RtcSessionController.DEFAULT_ENABLE_AUDIO_AGC : !!agcData;
            RtcSessionController.enableAudioEC = ecData === null ? RtcSessionController.DEFAULT_ENABLE_AUDIO_EC : !!ecData;

            WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                RtcSessionController.recordingDevices = _that.getRecordingDevice(audioSources);
                RtcSessionController.videoDevices = _that.getVideoDevice(videoSources);
                if (WebRTCAdapter.audioOutputSelectionSupported) {
                    RtcSessionController.playbackDevices = _that.getPlaybackDevice(audioOutputDevices);
                    RtcSessionController.ringingDevices = _that.getRingingDevice(audioOutputDevices);
                }
            });
        }

        function stopMediaStream(stream) {
            WebRTCAdapter.stopMediaStream(stream);
        }

        function playBeep(param) {
            var audioCtx = createNewAudioContext();
            if (!audioCtx) {
                return;
            }
            param = param || DEFAULT_BEEP;

            // Oscillator setup
            var compressor = audioCtx.createDynamicsCompressor();
            compressor.connect(audioCtx.destination);
            var osc = audioCtx.createOscillator();
            osc.type = 'sine'; // sin wave
            osc.frequency.value = param.freq;
            osc.connect(compressor);

            // Play it
            var ctime = audioCtx.currentTime;
            osc.start(ctime);
            osc.stop(ctime + param.duration);
            osc.onended = function () {
                LogSvc.debug('[DeviceHandlerSvc]: Destroying current AudioContext');
                audioCtx.close();
                audioCtx = null;
            };
        }

        /**
         * This method is used to check if any device in deviceList had their IDs changed
         *
         * @param {Array} deviceList - List of devices to be checked
         * @param {Array} updatedDeviceList - List of devices that might have new IDs
         */
        function checkForChangedDeviceIds(deviceList, updatedDeviceList) {
            if (!deviceList || !deviceList.length || !updatedDeviceList || !updatedDeviceList.length) {
                return;
            }
            deviceList.forEach(function (device, idx) {
                // Look for this device in updatedDeviceList
                var found = Utils.selectMediaDevice(updatedDeviceList, [device], true);
                if (found && found.id !== device.id) {
                    // Device found and its ID has changed, update it
                    deviceList[idx] = found;
                }
            });
        }

        function savePlaybackDevice(device) {
            var deviceList = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST) || {};
            deviceList.playback = Utils.addDeviceToPreferredList(deviceList.playback, device);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST, deviceList);
            RtcSessionController.playbackDevices = deviceList.playback;

            LogSvc.info('[DeviceHandlerSvc]: savePlaybackDevice saved:', device);
        }

        function saveRecordingDevice(device) {
            var deviceList = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST) || {};
            deviceList.recording = Utils.addDeviceToPreferredList(deviceList.recording, device);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST, deviceList);
            RtcSessionController.recordingDevices = deviceList.recording;

            LogSvc.info('[DeviceHandlerSvc]: saveRecordingDevice saved:', device);
        }

        function saveVideoDevice(device) {
            var deviceList = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST) || {};
            deviceList.video = Utils.addDeviceToPreferredList(deviceList.video, device);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST, deviceList);
            RtcSessionController.videoDevices = deviceList.video;

            LogSvc.info('[DeviceHandlerSvc]: saveVideoDevice saved:', device);
        }

        function saveRingingDevice(device) {
            var deviceList = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST) || {};
            deviceList.ringing = Utils.addDeviceToPreferredList(deviceList.ringing, device);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST, deviceList);
            RtcSessionController.ringingDevices = deviceList.ringing;

            LogSvc.info('[DeviceHandlerSvc]: saveRingingDevice saved:', device);
        }

        function getDeviceList(deviceType, updatedDeviceList) {
            var deviceList = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST) || {};
            deviceList[deviceType] = deviceList[deviceType] || [];

            // Use the updated device list to update any saved device that had their ID changed
            checkForChangedDeviceIds(deviceList[deviceType], updatedDeviceList);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.MEDIA_DEVICE_LIST, deviceList);

            return deviceList[deviceType];
        }

        function selectDevice(constraints, sources, previouslySelected) {
            var dev = Utils.selectMediaDevice(sources, previouslySelected);
            if (dev) {
                constraints.sourceId = dev.id;
            }
        }

        function getSourceIds(constraints) {
            if (WebRTCAdapter.getMediaSourcesSupported) {
                var selectAudioSource = constraints.audio && constraints.audio.sourceId;
                var selectVideoSource = constraints.video && constraints.video.sourceId;
                // Get currently selected input and/or video device only if no sourceId is specified
                if (selectAudioSource || selectVideoSource) {
                    var defer = $q.defer();
                    WebRTCAdapter.getMediaSources(function (audioSources, videoSources) {
                        if (selectAudioSource) {
                            selectDevice(constraints.audio, audioSources, RtcSessionController.recordingDevices);
                        }
                        if (selectVideoSource) {
                            selectDevice(constraints.video, videoSources, RtcSessionController.videoDevices);
                        }
                        defer.resolve();
                    });
                    return defer.promise;
                }
            }
            return $q.resolve();
        }

        function getUserMedia(constraints, successCb, errorCb) {
            return new $q(function (resolve) {
                var updatedConstraints = {
                    audio: !!constraints.audio && WebRTCAdapter.getAudioOptions(constraints.audio),
                    video: !!constraints.video && WebRTCAdapter.getVideoOptions(constraints.video)
                };
                LogSvc.debug('[DeviceHandlerSvc]: getUserMedia with constraints ', updatedConstraints);
                WebRTCAdapter.getUserMedia(updatedConstraints, function (stream) {
                    try {
                        successCb(stream);
                    } catch (e) {
                    }
                    LogSvc.debug('[DeviceHandlerSvc]: Publish /getUserMedia/success event');
                    PubSubSvc.publish('/getUserMedia/success');
                    resolve();
                }, function (error) {
                    try {
                        errorCb(error);
                    } catch (e) {
                    }
                    resolve();
                });
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        function onLocalUserInit() {
            LogSvc.debug('[DeviceHandlerSvc]: Received /localUser/init event');
            initRtcSettings();
        }
        PubSubSvc.subscribeOnce('/localUser/init', onLocalUserInit);


        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * This method encapsulates the call to WebRTCAdapter.getUserMedia behind a promise
         * to ensure that the calls are processed one at a time.
         */
        this.getUserMedia = function (constraints, successCb, errorCb) {
            _mediaPromise = _mediaPromise.finally(function () {
                constraints = constraints || {};
                return getSourceIds(constraints).then(function () {
                    return getUserMedia(constraints, successCb, errorCb);
                });
            });
        };

        /**
         * This method returns the current browser's camera media URL
         * @param {function} cb Callback that will receive the media URL or an error object
         */
        this.startCameraStream = function (cb, sourceId) {
            cb = cb || function () {};

            if (_pendingGetVideoMedia) {
                LogSvc.debug('[DeviceHandlerSvc]: startCameraStream: There is a pending get user media');
                cb('ALREADY_IN_PROGRESS');
                return;
            }
            if (_videoMediaStream) {
                cb(null, _videoMediaStream);
                return;
            }

            _pendingGetVideoMedia = true;

            var constraints = {
                audio: false,
                video: {sourceId: sourceId}
            };

            this.getUserMedia(constraints, function (stream) {
                _pendingGetVideoMedia = false;
                LogSvc.debug('[DeviceHandlerSvc]: Got camera stream. ', stream);

                if (_stopPendingGetVideoMedia) {
                    // This get user media has been cancelled
                    LogSvc.debug('[DeviceHandlerSvc]: startCameraStream: Cancel pending get user media');
                    _stopPendingGetVideoMedia = false;
                    stopMediaStream(stream);
                    cb('CANCELLED_GET_USER_MEDIA');
                    return;
                }

                _videoMediaStream = stream;
                cb(null, stream);
            }, function (err) {
                _pendingGetVideoMedia = false;
                _stopPendingGetVideoMedia = false;
                cb(err);
            });
        };

        /**
         * Stop the current camera media stream.
         */
        this.stopCameraStream = function () {
            if (_pendingGetVideoMedia) {
                LogSvc.debug('[DeviceHandlerSvc]: stopCameraStream: Cancel pending get user media');
                _stopPendingGetVideoMedia = true;
                return;
            }
            stopMediaStream(_videoMediaStream);
            _videoMediaStream = null;
        };

        this.getAudioContext = function () {
            return createNewAudioContext();
        };

        this.playBeep = function () {
            playBeep(DEFAULT_BEEP);
        };

        this.setPlaybackDevice = function (playbackDevice) {
            if (playbackDevice && playbackDevice.id && WebRTCAdapter.audioOutputSelectionSupported) {
                LogSvc.info('[DeviceHandlerSvc]: User selected new audio output. deviceId = ', playbackDevice.id);
                savePlaybackDevice(playbackDevice);
                // Reset the Jabra active device to match the selected audio output device
                HeadsetHidControlSvc.resetActiveDevice(playbackDevice);
            }
        };

        this.setRecordingDevice = function (recordingDevice) {
            if (recordingDevice) {
                LogSvc.info('[DeviceHandlerSvc]: User selected new microphone. deviceId = ', recordingDevice.id);
                saveRecordingDevice(recordingDevice);
            }
        };

        this.setVideoDevice = function (videoDevice) {
            if (videoDevice) {
                LogSvc.info('[DeviceHandlerSvc]: User selected new camera. deviceId = ', videoDevice.id);
                saveVideoDevice(videoDevice);
            }
        };

        this.setRingingDevice = function (ringingDevice) {
            if (ringingDevice) {
                LogSvc.info('[DeviceHandlerSvc]: User selected new ringing device. deviceId = ', ringingDevice.id);
                saveRingingDevice(ringingDevice);
            }
        };

        this.getPlaybackDevice = getDeviceList.bind(this, 'playback');

        this.getRecordingDevice = getDeviceList.bind(this, 'recording');

        this.getVideoDevice = getDeviceList.bind(this, 'video');

        this.getRingingDevice = getDeviceList.bind(this, 'ringing');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initializations
        ///////////////////////////////////////////////////////////////////////////////////////
        init();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.DeviceHandlerSvcImpl = DeviceHandlerSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
