
/* global Promise */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var logger = circuit.logger;
    var RtpQualityLevel = circuit.Enums.RtpQualityLevel;
    var RtpStatsConfig = circuit.RtpStatsConfig;
    var Utils = circuit.Utils;
    var VideoResolutionLevel = circuit.Enums.VideoResolutionLevel;

    var THRESHOLD_RECURRENCE = Utils.isMobile() ? 2 : 3;

    var ThresholdType = Object.freeze({
        MAX: 'MAX',
        MIN: 'MIN'
    });

    var RTPAudioStatType = Object.freeze({
        GOOG_JITTER_RECEIVED: {
            id: Constants.AudioQualityParam.JITTER,
            StatName: 'jr',
            StatText: 'googJitterReceived',
            Threshold: [50, 300, 500],
            ThresholdType: ThresholdType.MAX
        },
        GOOG_RTT: {
            id: Constants.AudioQualityParam.RTT,
            StatName: 'rtt',
            StatText: 'googRtt',
            Threshold: [300, 600, 800],
            ThresholdType: ThresholdType.MAX
        },
        PACKET_LOSS_RECV: {
            id: Constants.AudioQualityParam.PL_RECV,
            StatName: 'plr',
            StatText: 'packetLossRecv',
            Threshold: [0.05, 0.2, 0.3],
            ThresholdType: ThresholdType.MAX
        },
        PACKET_LOSS_SEND: {
            id: Constants.AudioQualityParam.PL_SEND,
            StatName: 'pll',
            StatText: 'packetLossSend',
            Threshold: [0.05, 0.2, 0.3],
            ThresholdType: ThresholdType.MAX
        },
        NO_PACKETS_RECV: {
            id: Constants.AudioQualityParam.NO_PACKETS_RECV,
            StatName: 'npr',
            StatText: 'noPacketsReceived',
            Threshold: [0],
            reportIssues: true
        },
        NO_PACKETS_SEND: {
            id: Constants.AudioQualityParam.NO_PACKETS_SENT,
            StatName: 'nps',
            StatText: 'noPacketsSent',
            Threshold: [0],
            reportIssues: true
        },
        ECHO_LIKELIHOOD: { // Deprecated (will be removed once we move to promise based stats)
            id: Constants.AudioQualityParam.ECHO,
            StatName: 'ecmax',
            StatText: 'googResidualEchoLikelihoodRecentMax',
            Threshold: [0.5],
            ThresholdType: ThresholdType.MAX
        }
    });

    var RTPVideoStatType = Object.freeze({
        GOOG_RTT: {
            id: Constants.VideoQualityParam.RTT,
            StatName: 'rtt',
            StatText: 'googRtt',
            Threshold: [300, 600, 800],
            ThresholdType: ThresholdType.MAX
        },
        PACKET_LOSS_RECV: {
            id: Constants.VideoQualityParam.PL_RECV,
            StatName: 'plr',
            StatText: 'packetLossRecv',
            Threshold: [0.05, 0.2, 0.3],
            ThresholdType: ThresholdType.MAX,
            StreamQualityThreshold: 0.1
        },
        PACKET_LOSS_SEND: {
            id: Constants.VideoQualityParam.PL_SEND,
            StatName: 'pll',
            StatText: 'packetLossSend',
            Threshold: [0.05, 0.2, 0.3],
            ThresholdType: ThresholdType.MAX
        }
    });

    // Dynamic, depends on the "desired" resolution
    var RTPHDVideoStatType = JSON.stringify({
        FRAME_HEIGHT_SENT: {
            id: Constants.VideoQualityParam.FRAME_HEIGHT_SENT,
            StatName: 'fhs',
            StatText: 'googFrameHeightSent',
            Threshold: [],
            ThresholdType: ThresholdType.MIN
        },
        FRAME_WIDTH_SENT: {
            id: Constants.VideoQualityParam.FRAME_WIDTH_SENT,
            StatName: 'fws',
            StatText: 'googFrameWidthSent',
            Threshold: [],
            ThresholdType: ThresholdType.MIN
        },
        FRAME_RATE_SENT: {
            id: Constants.VideoQualityParam.FRAME_RATE_SENT,
            StatName: 'frs',
            StatText: 'googFrameRateSent',
            Threshold: [],
            ThresholdType: ThresholdType.MIN
        },
        AVAIL_SEND_BW: {
            id: Constants.VideoQualityParam.BW_SEND,
            StatName: 'abw',
            StatText: 'googAvailableSendBandwidth',
            Threshold: [],
            ThresholdType: ThresholdType.MIN
        },
        TRANSMIT_BIT_RATE: {
            id: Constants.VideoQualityParam.TRX_BITRATE,
            StatName: 'tbr',
            StatText: 'googTransmitBitrate',
            Threshold: [],
            ThresholdType: ThresholdType.MIN
        }
    });

    var SsrcContents = Object.freeze({
        AUDIO: 'audio',
        VIDEO: 'video',
        SCREEN: 'screen'
    });

    var CallStats = Object.freeze({
        audioOutputLevel: 'aol',
        bytesReceived: 'or',
        bytesSent: 'os',
        googAvailableSendBandwidth: 'abw',
        /*
        googAvgEncodeMs: 'ae',
        googBandwidthLimitedResolution: 'blr',
        googCaptureJitterMs: 'cj',
        googCaptureQueueDelayMsPerS: 'cqd',
        googCpuLimitedResolution: 'clr',
        googCurrentDelayMs: 'cdm',
        googDecodeMs: 'dm',
        googEchoCancellationEchoDelayMedian: 'ecedm',
        googEchoCancellationEchoDelayStdDev: 'ecedsd',
        googEchoCancellationQualityMin: 'ecqm',
        googEchoCancellationReturnLoss: 'ecrl',
        googEchoCancellationReturnLossEnhancement: 'ecrle',
        */
        googResidualEchoLikelihoodRecentMax: 'ecmax',
        /*
        googEncodeUsagePercent: 'eup',
        googExpandRate: 'ert',
        googFirsReceived: 'fr',
        googFirsSent: 'fs',
        */
        googFrameHeightReceived: 'fhr',
        googFrameHeightSent: 'fhs',
        googFrameWidthReceived: 'fwr',
        googFrameWidthSent: 'fws',
        googFrameRateDecoded: 'frd',
        googFrameRateSent: 'frs',
        googFrameRateReceived: 'frr',
        /*
        googFrameRateInput: 'fri',
        googFrameRateOutput: 'fro',
        googJitterBufferMs: 'jb',
        */
        googJitterReceived: 'jr',
        /*
        googMaxDecodeMs: 'mdm',
        googMinPlayoutDelayMs: 'mpd',
        googNacksReceived: 'nr',
        googNacksSent: 'ns',
        googPlisReceived: 'plr',
        googPlisSent: 'pls',
        googPreferredJitterBufferMs: 'pjb',
        googRenderDelayMs: 'rd',
        */
        googRtt: 'rtt',
        /*
        googTargetDelayMs: 'td',
        */
        googTransmitBitrate: 'tbr',
        /*
        googViewLimitedResolution: 'vlr',
        */
        packetsLost: 'pl',
        packetsReceived: 'pr',
        packetsSent: 'ps'
    });


    var browserInfo = Utils.getBrowserInfo();

    // eslint-disable-next-line max-lines-per-function
    function CallStatsHandlerUnified(peerConnections, isDirectCall) { // NOSONAR
        if (!Array.isArray(peerConnections)) {
            throw new Error('peerConnections must be an array');
        }

        /////////////////////////////////////////////////////////////////////////////
        // Constants
        /////////////////////////////////////////////////////////////////////////////
        var LOG_SKIP_COUNTER = 6; // If there's no issues, log stats every 30 seconds
        var MAX_VIOLATIONS = 20; // Only log the violations up to this limit
        var STREAM_QUALITY_SUMMARY_ITERATIONS = 6;

        /////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        /////////////////////////////////////////////////////////////////////////////
        var _peerConnections = peerConnections.filter(function (pc) { return pc; });
        if (!peerConnections.length) {
            throw new Error('Cannot create CallStatsHandler object without at least one peer connection');
        }

        var _nics = circuit.WebRTCAdapter.getNetmaskMap();

        var _isDirectCall = !!isDirectCall;
        var _options = {};

        var _rtpStatsDelayUponAnsTimer = null;
        var _rtpStatsCollectionTimer = null;
        var _rtpStatsCollectionCounter = 0;

        var _savedRTPStats = {};
        var _savedChannelInfo = {};
        var _violationsInProgress = {};
        var _videoIssues = {};
        var _channelIssues = {};
        var _qosCollected;
        var _consecutiveBadQualityCounter = 0;
        var _consecutiveGoodQualityCounter = 0;
        var _lowQualityVideoTracks = {};
        var _streamQualityData = null;
        var _tmpStreamQualityData = {
            audio: null
        };

        var _streamQualityStatsToCheck = [
            RTPAudioStatType.GOOG_JITTER_RECEIVED,
            RTPAudioStatType.PACKET_LOSS_RECV,
            RTPAudioStatType.GOOG_RTT
        ];

        // Create copy of HD stat object so we can modify thresholds depending on video resolution
        var _rtpHDVideoStatType = JSON.parse(RTPHDVideoStatType);

        var _that = this;

        /////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        /////////////////////////////////////////////////////////////////////////////
        function normalizeCandidateType(type) {
            switch (type) {
            case 'local':
                return 'host';
            case 'stun':
            case 'serverreflexive':
                return 'srflx';
            case 'peerreflexive':
                return 'prflx';
            case 'relayed':
                return 'relay';
            default:
                return type;
            }
        }

        function getAddrByNetworkIdForQosReport(parsedMLine, selectedCandidate, type) {
            var addr;
            // Find corresponding address based on the type and network-id
            parsedMLine.a.some(function (a) {
                if (a.field === 'candidate' && a.value) {
                    var candidate = new circuit.IceCandidate(a.value);
                    if (candidate.typ === type && candidate['network-id'] === selectedCandidate['network-id']) {
                        addr = candidate.address;
                        return true;
                    }
                }
                return false;
            });
            return addr;
        }

        /**
        * Get ICE candidate info (hAddr, sAddr and tt)
        *
        * @param {Object} res - Stat resource containing the candidate info
        * @param {Object} sdp - Local SDP (needed for relay candidates)
        * @returns {Object} - Object containing the candidate's hAddr, sAddr and tt (if applicable)
        */
        function getIceCandidateInfo(res, sdp) {
            var info = {};

            if (!res || !sdp) {
                return info;
            }

            var port, ip;
            var type = normalizeCandidateType(res.stat('candidateType'));
            if (type === 'prflx') {
                // Peer reflexive candidates are not in the SDP, but we still need to collect
                // their hAddr. For that we use their connection IP address (sAddr) to find
                // the corresponding srflx candidate, then the host candidate (hAddr)
                ip = res.stat('ipAddress');
            } else {
                port = String(res.stat('portNumber'));
            }

            var parsed = typeof sdp === 'string' ? circuit.sdpParser.parse(sdp) : sdp;
            // Find the selected candidate in the SDP
            parsed.m.some(function (m) {
                m.a.some(function (a) {
                    if (a.field !== 'candidate') {
                        return false;
                    }
                    // Only parse the ICE candidate if the port is found in it
                    if (a.value) {
                        var candidate;
                        if (port && a.value.indexOf(port) >= 0) {
                            candidate = new circuit.IceCandidate(a.value);
                            if (String(candidate.port) === port && type === candidate.typ) {
                                info.value = a.value;
                                if (type === 'host') {
                                    info.hAddr = res.stat('ipAddress');
                                    // ANS-51833: In some cases of VPN connected calls, selected candidate is host
                                    // but, backend needs srflx host address for the QoS bandwidth calculations
                                    info.sAddr = getAddrByNetworkIdForQosReport(m, candidate, 'srflx');
                                } else {
                                    if (type === 'srflx') {
                                        info.sAddr = candidate.address;
                                    } else {
                                        info.sAddr = candidate.raddr;
                                    }
                                    // Get the corresponding host address for srflx/relay candidate
                                    info.hAddr = getAddrByNetworkIdForQosReport(m, candidate, 'host');

                                    if (type === 'relay') {
                                        info.tt = candidate.getRelayClientTransportType();
                                    }
                                }
                                return true;
                            }
                        }
                        if (ip && a.value.indexOf('srflx') >= 0) {
                            // We're using the ip to search for the candidate, which must be srflx
                            candidate = candidate || new circuit.IceCandidate(a.value);
                            if (candidate.typ === 'srflx' && String(candidate.address) === ip) {
                                info.sAddr = ip;
                                // Now get the host address
                                info.hAddr = getAddrByNetworkIdForQosReport(m, candidate, 'host');
                                return true;
                            }
                        }
                    }
                    return false;
                });
                return false;
            });
            info.netmask = _nics[info.hAddr];
            return info;
        }

        function getChannelInfo(info, res) {
            // Get the local and remote IP addresses and port numbers
            var ipl = Utils.parseIpWithPort(res.stat('googLocalAddress')) || {};
            var ipr = Utils.parseIpWithPort(res.stat('googRemoteAddress')) || {};
            info.ipl = ipl.ipAddress;
            info.ptl = ipl.port;
            info.ipr = ipr.ipAddress;
            info.ptr = ipr.port;
            info.lct = normalizeCandidateType(res.stat('googLocalCandidateType'));
            info.rct = normalizeCandidateType(res.stat('googRemoteCandidateType'));
            info.tt = res.stat('googTransportType');
        }

        function getChannelInfoFromCandidates(info, local, remote) {
            info.ipl = local && local.stat('ipAddress');
            info.ptl = local && local.stat('portNumber');
            info.ipr = remote && remote.stat('ipAddress');
            info.ptr = remote && remote.stat('portNumber');
            info.lct = local && normalizeCandidateType(local.stat('candidateType'));
            info.rct = remote && normalizeCandidateType(remote.stat('candidateType'));
            info.tt = local && local.stat('protocol');
        }

        function processCandidatePair(res, formattedStats, candidateIds, includeQosData) {
            var channelId = res.stat('googChannelId');
            var channelInfo = {
                channelId: channelId
            };
            var rtt = parseInt(res.stat('googRtt'), 10);
            channelInfo.rtt = isNaN(rtt) ? -1 : rtt;
            if (includeQosData) {
                channelInfo.candidates = {
                    local: res.stat('localCandidateId'),
                    remote: res.stat('remoteCandidateId')
                };
                if (browserInfo.firefox || browserInfo.safari) {
                    // These browser checks won't be needed anymore once we fully support
                    // promise based getStats()
                    candidateIds.remote = res.stat('remoteCandidateId');
                } else {
                    getChannelInfo(channelInfo, res);
                }
            }
            formattedStats.channelInfo[channelId] = channelInfo;
        }

        function processSsrc(res, formattedStats, pc) {
            var statsPtr = null;
            var ssrc = res.stat('ssrc');
            var commonData = {
                id: res.id,
                ssrc: ssrc,
                content: res.stat('googContentType'), // 'realtime' or 'screen'
                channelId: res.stat('transportId'),
                trackId: res.stat('googTrackId')
            };
            // For lack of better method, we check for specific stats in each ssrc so we can
            // tell if it's audio or video (receive or transmit)
            if (res.stat('audioInputLevel')) {
                if (!formattedStats.audio.transmit[ssrc]) {
                    formattedStats.audio.transmit[ssrc] = commonData;
                }
                statsPtr = formattedStats.audio.transmit[ssrc];
                statsPtr.content = SsrcContents.AUDIO;
            } else if (res.stat('audioOutputLevel')) {
                if (!formattedStats.audio.receive[ssrc]) {
                    formattedStats.audio.receive[ssrc] = commonData;
                }
                statsPtr = formattedStats.audio.receive[ssrc];
                statsPtr.content = SsrcContents.AUDIO;
            } else if (res.stat('googFrameHeightSent')) {
                if (!formattedStats.video.transmit[ssrc]) {
                    formattedStats.video.transmit[ssrc] = commonData;
                }
                if (browserInfo.firefox) {
                    // In FF we don't have googContentType, but it provides the 'mid' attribute, which we can use to identify
                    // the screen ssrc
                    commonData.content = res.stat('mid') === pc.getScreenShareMediaId() ? SsrcContents.SCREEN : SsrcContents.VIDEO;
                } else if (commonData.content !== SsrcContents.SCREEN) {
                    // googContentType has 'screen' if screen is being transmitted. If not, mark it as video
                    commonData.content = SsrcContents.VIDEO;
                }
                statsPtr = formattedStats.video.transmit[ssrc];
            } else if (res.stat('googFrameHeightReceived')) {
                if (!formattedStats.video.receive[ssrc]) {
                    formattedStats.video.receive[ssrc] = commonData;
                }
                // Check if this ssrc is listed under the screenshare m-line, otherwise consider it a video ssrc
                commonData.content = pc.desktopRemoteSsrc.includes(commonData.ssrc) ? SsrcContents.SCREEN : SsrcContents.VIDEO;
                statsPtr = formattedStats.video.receive[ssrc];
            }

            return statsPtr;
        }

        function formatStatsForClientDiagnostics(startTime, stats, pc, pcType, includeQosData) {
            if (!stats || !pc) {
                return null;
            }

            var formattedStats = {
                startTime: startTime,
                pcType: pcType,
                audio: {
                    transmit: {},
                    receive: {}
                },
                video: {
                    transmit: {},
                    receive: {}
                },
                channelInfo: {},
                quality: { audio: {}, video: {} }
            };
            var candidateIds = {audio: {}, video: {}};
            var candidateList = {};
            var results = stats.result ? stats.result() : stats;
            if (!Array.isArray(results)) {
                return null;
            }

            results.forEach(function (res) {
                if (!res) {
                    return;
                }
                var statsPtr = null;
                switch (res.type) {
                case 'ssrc':
                    statsPtr = processSsrc(res, formattedStats, pc);
                    break;
                case 'VideoBwe':
                    if (!formattedStats.video.bw) {
                        formattedStats.video.bw = {id: res.id};
                    }
                    statsPtr = formattedStats.video.bw;
                    break;
                case 'localcandidate':
                case 'remotecandidate':
                    candidateList[res.id] = res;
                    break;
                }

                // Now get only the stats that we are interested in
                if (res.names && statsPtr) {
                    var resNames = res.names() || [];
                    resNames.forEach(function (name) {
                        if (CallStats[name]) {
                            statsPtr[CallStats[name]] = parseFloat(res.stat(name));
                        }
                    });
                    // The codec might change during the call and right now we're not keeping track of these changes...
                    statsPtr.en = res.stat('googCodecName');
                }
            });

            results.forEach(function (res) {
                if (res && res.type === 'googCandidatePair' && res.stat('googActiveConnection') === 'true') {
                    processCandidatePair(res, formattedStats, candidateIds, includeQosData);
                }
            });

            if (includeQosData) {
                var parsedLocalSdp;

                Object.keys(formattedStats.channelInfo).forEach(function (channelId) {
                    var info = formattedStats.channelInfo[channelId];
                    if (info) {
                        var local = candidateList[info.candidates.local];
                        if (local) {
                            if (browserInfo.firefox || browserInfo.safari) {
                                // These browser checks won't be needed anymore once we fully support promise based getStats()
                                var remote = candidateList[info.candidates.remote];
                                getChannelInfoFromCandidates(info, local, remote);
                            }
                            info.ni = local.stat('networkType');
                            parsedLocalSdp = parsedLocalSdp || circuit.sdpParser.parse(pc.localDescription && pc.localDescription.sdp);
                            info.candidate = getIceCandidateInfo(local, parsedLocalSdp);
                        }
                    }
                });
            }
            return formattedStats;
        }

        function getPcStats(pc, pcName, startTime, includeQosData) {
            if (!pc.getStats) {
                return Promise.resolve();
            }

            return pc.getStats()
            .catch(function (err) {
                // Log that getStats for this particular peer connection failed, but don't reject the promise
                logger.warn('[CallStatsHandlerUnified]: getStats[' + pcName + '] failed with err = ', err);
            })
            .then(function (stats) {
                if (!stats) {
                    logger.warn('[CallStatsHandlerUnified]: getStats[' + pcName + '] did not return a response');
                } else {
                    stats = formatStatsForClientDiagnostics(startTime, stats, pc, pcName, includeQosData);
                }
                return stats;
            });
        }

        function getRTPStatsForClientDiagnostics(force) {
            // If QoS has already been collected, we don't need to collect it again
            var includeQosData = !_qosCollected;
            _qosCollected = _qosCollected || includeQosData;

            var mainPc = _peerConnections.find(function (p) {
                if (!p) {
                    return false;
                }

                // Collect stats only if media is connected
                if (!force && p.iceConnectionState !== 'completed' && p.iceConnectionState !== 'connected') {
                    logger.warn('[CallStatsHandlerUnified]: Do not collect stats. pc.iceConnectionState = ', p.iceConnectionState);
                    return false;
                }

                return p.mainPeerConnection;
            });

            if (!mainPc) {
                return Promise.resolve();
            }

            return getPcStats(mainPc, 'AUDIO/VIDEO/SCREEN', mainPc.startTime, includeQosData)
                .then(function (stats) {
                    if (stats) {
                        // Run some pre-processing of the media channels
                        [SsrcContents.AUDIO, SsrcContents.VIDEO].forEach(function (media) {
                            var mediaStats = stats[media];
                            ['transmit', 'receive'].forEach(function (direction) {
                                var directionStats = mediaStats[direction];
                                Object.keys(directionStats).forEach(function (ssrc) {
                                    var trackInfo = directionStats[ssrc];
                                    if (!trackInfo.channelInfo) {
                                        // Associate each SSRC to their channels
                                        trackInfo.channelInfo = _savedChannelInfo[trackInfo.channelId] || stats.channelInfo[trackInfo.channelId];
                                    }
                                    // Mark the channels that are receiving audio/video and is sending video
                                    if (media === SsrcContents.AUDIO && direction === 'receive') {
                                        // Receiving audio (audio is also sent through this same channel, if applicable)
                                        trackInfo.channelInfo.isAudioChannel = true;
                                    } else if (trackInfo.content === SsrcContents.VIDEO) {
                                        if (direction === 'transmit') {
                                            if (!_lowQualityVideoTracks[ssrc.trackId]) {
                                                // Sending local video
                                                trackInfo.channelInfo.isVideoSendChannel = true;
                                            }
                                        } else {
                                            // Check if video packets are being received. This information is dynamic
                                            var saved = _savedRTPStats[ssrc];
                                            trackInfo.channelInfo.isVideoRecvChannel = saved ? trackInfo.pr > saved.pr : trackInfo.pr > 0;
                                        }
                                    }
                                });
                            });
                        });
                    }
                    return stats;
                });
        }

        function startCollectingRTPStats() {
            if (!_rtpStatsDelayUponAnsTimer && !_rtpStatsCollectionTimer) {
                logger.debug('[CallStatsHandlerUnified]: Waiting to start collecting RTPstats');

                _rtpStatsDelayUponAnsTimer = window.setTimeout(function () {
                    logger.info('[CallStatsHandlerUnified]: Start collecting RTPStats');
                    _rtpStatsDelayUponAnsTimer = null;
                    _rtpStatsCollectionCounter = 0;
                    getFormattedRTPStats();
                    _rtpStatsCollectionTimer = window.setInterval(function () {
                        getFormattedRTPStats();
                    }, RtpStatsConfig.COLLECTION_INTERVAL);
                }, RtpStatsConfig.DELAY_UPON_ANSWER);
            }
        }

        function calculatePLSent(current, savedStats) {
            var previousPL = 0, previousPS = 0;
            if (savedStats) {
                previousPL = savedStats.pl || 0;
                previousPS = savedStats.ps || 0;
            }
            var currentPL = (current.pl - previousPL) / ((current.ps - previousPS) || 1);
            return Math.round(currentPL * 100) / 100;
        }

        function calculatePLReceived(current, savedStats) {
            var previousPL = 0, previousPR = 0;
            if (savedStats) {
                previousPL = savedStats.pl || 0;
                previousPR = savedStats.pr || 0;
            }
            var currentPL = (current.pl - previousPL) / ((current.pr - previousPR + current.pl - previousPL) || 1);
            return Math.round(currentPL * 100) / 100;
        }

        function calculateAverageAndMax(current, savedStats, field) {
            var sum = field + 'Sum';
            var counter = field + 'Count';
            var max = field + 'Max';
            if (savedStats) {
                if (savedStats[sum] !== undefined) {
                    current[sum] = savedStats[sum] + (current[field] || 0);
                    ++savedStats[counter];
                    current[counter] = savedStats[counter];
                } else {
                    current[sum] = current[field] || 0;
                    current[counter] = 1;
                }
                if (savedStats[max] !== undefined) {
                    current[max] = Math.max(savedStats[max], current[field] || 0);
                } else {
                    current[max] = current[field] || 0;
                }
            } else {
                // First sample
                current[sum] = current[max] = current[field] || 0;
                current[counter] = 1;
            }
        }

        function logRawStats(s) {
            var log;

            function getValue(val) {
                if (val === undefined || val < 0) {
                    return 'n/a';
                }
                return val;
            }

            Object.keys(s.channelInfo).forEach(function (id) {
                var channel = s.channelInfo[id];
                log = '[CallStatsHandlerUnified]: statsReport: channelRtt=' + (channel.rtt >= 0 ? channel.rtt : 'n/a');
                Object.keys(s.audio.receive).forEach(function (ssrc) {
                    var r = s.audio.receive[ssrc];
                    if (r.channelId === id) {
                        log += '\n audioReceive:  id=' + r.id + ' pl=' + getValue(r.pl) + ' pr=' + getValue(r.pr) + ' or=' + getValue(r.or) + ' jr=' + getValue(r.jr);
                    }
                });
                Object.keys(s.audio.transmit).forEach(function (ssrc) {
                    var t = s.audio.transmit[ssrc];
                    if (t.channelId === id) {
                        log += '\n audioTransmit: id=' + t.id + ' pl=' + getValue(t.pl) + ' ps=' + getValue(t.ps) + ' os=' + getValue(t.os);
                    }
                });
                Object.keys(s.video.receive).forEach(function (ssrc) {
                    var r = s.video.receive[ssrc];
                    if (r.channelId === id) {
                        log += '\n videoReceive:  id=' + r.id + ' pl=' + getValue(r.pl) + ' pr=' + getValue(r.pr) + ' or=' + getValue(r.or)
                                + ' fwr=' + r.fwr + ' fhr=' + r.fhr + ' frd=' + r.frd + ' frr=' + r.frr;
                    }
                });
                Object.keys(s.video.transmit).forEach(function (ssrc) {
                    var t = s.video.transmit[ssrc];
                    if (t.channelId === id) {
                        log += '\n videoTransmit: id=' + t.id + ' pl=' + getValue(t.pl) + ' ps=' + getValue(t.ps) + ' os=' + getValue(t.os)
                                + ' fws=' + t.fws + ' fhs=' + t.fhs + ' frs=' + t.frs + ' abw=' + ((s.video.bw && s.video.bw.abw) || 'N/A');
                    }
                });
                logger.debug(log);
            });
        }

        function checkViolations(stat, value, startTime, currentStats, savedStats) {
            var viStart = stat.StatText + 'LastViStart';
            var vi = stat.StatText + 'Violations';
            var viCounter = stat.StatText + 'ViCounter';
            // Propagate the violation records from the savedStats to the current
            currentStats[viStart] = savedStats && savedStats[viStart];
            currentStats[vi] = savedStats && savedStats[vi];
            currentStats[viCounter] = savedStats && savedStats[viCounter] || 0;
            if (value === -1) {
                // -1 means stat value is below threshold
                if (currentStats[viStart] >= 0) {
                    // There's a violation in progress and now we should mark it as ended
                    var viRecords = currentStats[vi];
                    viRecords = viRecords ? viRecords + ',' : ''; // Add a coma
                    currentStats[vi] = viRecords + '(' + currentStats[viStart] + ',' + parseInt((Date.now() - startTime) / 1000, 10) + ')'; // Add end time
                    currentStats[viCounter]++;

                    _violationsInProgress[stat.StatText] = 0;
                    // Delete the start time, otherwise it will be recorded again
                    delete currentStats[viStart];
                    if (savedStats) {
                        delete savedStats[viStart];
                    }
                }
            } else if (currentStats[viStart] >= 0) {
                // Keep count of consecutive violations
                _violationsInProgress[stat.StatText]++;
            } else if (currentStats[viCounter] < MAX_VIOLATIONS) {
                // Start recording this violation
                currentStats[viStart] = parseInt((Date.now() - startTime) / 1000, 10);
                _violationsInProgress[stat.StatText] = 1;
            }
        }

        function compare(value1, value2, type) {
            return type === ThresholdType.MAX ? value1 >= value2 : value1 <= value2;
        }

        function getQualityLevel(statType, value) {
            if (compare(statType.Threshold[0], value, statType.ThresholdType)) {
                return RtpQualityLevel.RTP_QUALITY_HIGH;
            } else if (compare(statType.Threshold[1], value, statType.ThresholdType)) {
                return RtpQualityLevel.RTP_QUALITY_MEDIUM;
            } else if (compare(statType.Threshold[2], value, statType.ThresholdType)) {
                return RtpQualityLevel.RTP_QUALITY_LOW;
            } else {
                return RtpQualityLevel.RTP_QUALITY_POOR;
            }
        }

        function analyzeCallQuality(statsReport, statType, media, value) {
            if (value >= 0) {
                statsReport.quality.level = statsReport.quality.level || RtpQualityLevel.RTP_QUALITY_HIGH;
                // Only analyze if there are thresholds defined, otherwise just report HIGH and the stat value
                var level = statType.Threshold.length > 0 ? getQualityLevel(statType, value) : RtpQualityLevel.RTP_QUALITY_HIGH;
                statsReport.quality[media][statType.id] = {
                    qualityLevel: level,
                    value: value
                };

                // Group the stats by quality level, this makes it easier to calculate the overall quality later
                statsReport.quality[level.value] = statsReport.quality[level.value] || [];
                statsReport.quality[level.value].push(statType);

                if (level.value < statsReport.quality.level.value) {
                    statsReport.quality.level = level; // Save the lowest level
                }
            }
        }

        function processTransmitAudioRTPStats(statsReport) {
            Object.keys(statsReport.audio.transmit).forEach(function (trackId) {
                var savedStats = _savedRTPStats[trackId] || {};
                var send = statsReport.audio.transmit[trackId];

                if (send) {
                    send.media = SsrcContents.AUDIO;
                    // Packet loss
                    var statType = RTPAudioStatType.PACKET_LOSS_SEND;
                    var currentPL = calculatePLSent(send, savedStats);
                    if (send.pl && send.ps) {
                        checkViolations(statType, currentPL >= statType.Threshold[0] ? currentPL : -1, statsReport.startTime,
                            send, savedStats);
                    }
                    analyzeCallQuality(statsReport, statType, SsrcContents.AUDIO, currentPL);
                    var packetsSent = (send.ps || 0) - (savedStats.ps || 0);
                    checkViolations(RTPAudioStatType.NO_PACKETS_SEND, packetsSent > 0 ? -1 : true, statsReport.startTime,
                        send, savedStats);

                    // Echo likelihood
                    statType = RTPAudioStatType.ECHO_LIKELIHOOD;
                    calculateAverageAndMax(send, savedStats, RTPAudioStatType.ECHO_LIKELIHOOD.StatName);
                    var statValue = send[statType.StatName] || 0;
                    checkViolations(statType, statValue && statValue >= statType.Threshold[0] ? statValue : -1,
                        statsReport.startTime, send, savedStats);
                }

                _savedRTPStats[trackId] = send;
            });
        }

        function processReceiveAudioRTPStats(statsReport) {
            Object.keys(statsReport.audio.receive).forEach(function (trackId) {
                var savedStats = _savedRTPStats[trackId] || {};
                var rcv = statsReport.audio.receive[trackId];
                var statType = RTPAudioStatType.PACKET_LOSS_RECV;

                if (rcv) {
                    rcv.media = SsrcContents.AUDIO;
                    var currentPL = calculatePLReceived(rcv, savedStats);
                    if (rcv.pl && rcv.pr) {
                        checkViolations(statType, currentPL >= statType.Threshold[0] ? currentPL : -1, statsReport.startTime,
                            rcv, savedStats);
                    }
                    analyzeCallQuality(statsReport, statType, SsrcContents.AUDIO, currentPL);

                    if (!_options.sendOnlyStream) {
                        // Packets received - check if we're receiving packets. If not, assume that either we
                        // lost connection or the other peer stopped responding
                        checkViolations(RTPAudioStatType.NO_PACKETS_RECV, rcv.pr > savedStats.pr ? -1 : true,
                            statsReport.startTime, rcv, savedStats);
                    }

                    // Jitter received
                    statType = RTPAudioStatType.GOOG_JITTER_RECEIVED;
                    var statValue;
                    calculateAverageAndMax(rcv, savedStats, RTPAudioStatType.GOOG_JITTER_RECEIVED.StatName);
                    statValue = rcv[statType.StatName];
                    analyzeCallQuality(statsReport, statType, SsrcContents.AUDIO, statValue);
                    checkViolations(statType, statValue && statValue >= statType.Threshold[0] ? statValue : -1,
                        statsReport.startTime, rcv, savedStats);

                    statsReport.quality.audio.trackId = trackId;
                }
                _savedRTPStats[trackId] = rcv;
            });
        }

        function processAudioRTPStats(statsReport) {
            if (!statsReport || !statsReport.audio) {
                return;
            }
            processTransmitAudioRTPStats(statsReport);
            processReceiveAudioRTPStats(statsReport);
        }

        function getHDVideoResolutionInfo(statsReport, fhs, fws) {
            var videoInfo = {};
            if (fhs >= 0 && fws >= 0) {
                videoInfo.resolution = {
                    height: fhs,
                    width: fws
                };
                var height = Math.min(fhs, fws);
                switch (height) {
                case 1080:
                    videoInfo.hdVideo = VideoResolutionLevel.VIDEO_1080;
                    break;
                case 720:
                    videoInfo.hdVideo = VideoResolutionLevel.VIDEO_720;
                    break;
                case 480:
                    videoInfo.hdVideo = VideoResolutionLevel.VIDEO_480;
                    break;
                }
            }
            statsReport.quality.videoInfo = videoInfo;
        }

        function processTransmitVideoRTPStats(statsReport) {
            Object.keys(statsReport.video.transmit).forEach(function (trackId) {
                var savedStats = _savedRTPStats[trackId] || {};
                var videoIssues = _videoIssues[trackId] || {};
                var send = statsReport.video.transmit[trackId];
                send.media = SsrcContents.VIDEO;

                // Packet loss
                var statType = RTPVideoStatType.PACKET_LOSS_SEND;
                if (send && !_lowQualityVideoTracks[send.trackId]) {
                    var currentPL = calculatePLSent(send, savedStats);
                    if (send.pl && send.ps) {
                        checkViolations(statType, currentPL >= statType.Threshold[0] ? currentPL : -1, statsReport.startTime,
                            send, savedStats);
                    }
                    if (send.content === SsrcContents.VIDEO) {
                        // Only analyze quality if video (not screen) is being transmitted
                        analyzeCallQuality(statsReport, statType, SsrcContents.VIDEO, currentPL);

                        // Handle portrait and landscape videos
                        var width = Math.max(send.fws, send.fhs) || send.fws;
                        var height = Math.min(send.fws, send.fhs) || send.fhs;
                        analyzeCallQuality(statsReport, _rtpHDVideoStatType.FRAME_HEIGHT_SENT, SsrcContents.VIDEO, height);
                        analyzeCallQuality(statsReport, _rtpHDVideoStatType.FRAME_WIDTH_SENT, SsrcContents.VIDEO, width);
                        analyzeCallQuality(statsReport, _rtpHDVideoStatType.FRAME_RATE_SENT, SsrcContents.VIDEO, send.frs);
                        if (statsReport.video.bw) {
                            analyzeCallQuality(statsReport, _rtpHDVideoStatType.TRANSMIT_BIT_RATE, SsrcContents.VIDEO, statsReport.video.bw.tbr);
                            analyzeCallQuality(statsReport, _rtpHDVideoStatType.AVAIL_SEND_BW, SsrcContents.VIDEO, statsReport.video.bw.abw);
                        }
                        getHDVideoResolutionInfo(statsReport, send.fhs, send.fws);
                    }
                }
                _savedRTPStats[trackId] = send;
                _videoIssues[trackId] = videoIssues;
            });
        }

        function processReceiveVideoRTPStats(statsReport) {
            var maxPlRecv = -1;
            Object.keys(statsReport.video.receive).forEach(function (trackId) {
                var savedStats = _savedRTPStats[trackId] || {};
                var videoIssues = _videoIssues[trackId] || {};
                var rcv = statsReport.video.receive[trackId];
                rcv.media = SsrcContents.VIDEO;
                // Packet loss
                var statType = RTPVideoStatType.PACKET_LOSS_RECV;
                if (rcv && rcv.pr) {
                    var currentPL = calculatePLReceived(rcv, savedStats);
                    if (rcv.pl) {
                        checkViolations(statType, currentPL >= statType.Threshold[0] ? currentPL : -1, statsReport.startTime,
                            rcv, savedStats);
                    }
                    // Analyze PL quality level only if the content is video (not screen) and packets are being received
                    if (rcv.content === SsrcContents.VIDEO && (isNaN(savedStats.pr) || rcv.pr - savedStats.pr > 0)) {
                        // Save the highest PL_RECV
                        maxPlRecv = Math.max(maxPlRecv, currentPL);
                    }
                }
                _savedRTPStats[trackId] = rcv;
                _videoIssues[trackId] = videoIssues;
            });
            if (maxPlRecv >= 0) {
                analyzeCallQuality(statsReport, RTPVideoStatType.PACKET_LOSS_RECV, SsrcContents.VIDEO, maxPlRecv);
            }
        }

        function processVideoRTPStats(statsReport) {
            if (!statsReport || !statsReport.video) {
                return;
            }
            _savedRTPStats.bw = statsReport.video.bw || {};
            _savedRTPStats.bw.media = SsrcContents.VIDEO;

            processTransmitVideoRTPStats(statsReport);
            processReceiveVideoRTPStats(statsReport);
        }

        function processRttStats(statsReport) {
            Object.keys(statsReport.channelInfo).forEach(function (channelId) {
                var saved = _savedChannelInfo[channelId] || {};
                var channelInfo = statsReport.channelInfo[channelId];
                calculateAverageAndMax(channelInfo, saved, RTPVideoStatType.GOOG_RTT.StatName);
                var statType = RTPAudioStatType.GOOG_RTT;
                var issues = _channelIssues[channelId] || {};
                checkViolations(statType, channelInfo.rtt >= statType.Threshold[0] ?
                    channelInfo.rtt : -1, statsReport.startTime, channelInfo, saved);

                // Only analyze RTT if the channel has audio or is transmitting/receiving video.
                if (saved.isAudioChannel || channelInfo.isAudioChannel) {
                    statType = RTPAudioStatType.GOOG_RTT;
                    analyzeCallQuality(statsReport, statType, SsrcContents.AUDIO, channelInfo.rtt);
                } else if (saved.isVideoSendChannel || saved.isVideoRecvChannel ||
                    channelInfo.isVideoSendChannel || channelInfo.isVideoRecvChannel) {
                    statType = RTPVideoStatType.GOOG_RTT;
                    analyzeCallQuality(statsReport, statType, SsrcContents.VIDEO, channelInfo.rtt);
                }
                _channelIssues[channelId] = issues;
            });
        }

        function saveChannelInfo(statsReport) {
            Object.keys(statsReport.channelInfo).forEach(function (channelId) {
                var saved = _savedChannelInfo[channelId] || {};
                _savedChannelInfo[channelId] = Object.assign(saved, statsReport.channelInfo[channelId]);
            });
        }

        function logQualityStats(statsReport) {
            // Log the overall and quality level for all stats only when the overall quality has changed or
            // if any of the quality stats is (or was) not at HIGH
            var log = '[CallStatsHandlerUnified]: Network quality: overall=' + statsReport.quality.level.levelName;

            [SsrcContents.AUDIO, SsrcContents.VIDEO].forEach(function (media) {
                var logRef = statsReport.quality[media];
                var mediaLog = '';
                Object.keys(logRef).forEach(function (k) {
                    if (k !== 'trackId') {
                        mediaLog += ' ' + k + '=' + logRef[k].qualityLevel.levelName + '[' + logRef[k].value + ']';
                    }
                });
                if (mediaLog) {
                    log += '\n' + media + ':' + mediaLog;
                }
            });
            logger.debug(log);
        }

        function calculateOverallQuality(statsReport) {
            // Check how many stats we have in each quality level,
            // if a level has 2+ stats or 1+ PL (Packet Lost) stat, that's the overall level,
            statsReport.quality.level = RtpQualityLevel.RTP_QUALITY_HIGH;
            var singleStatCounter = 0;
            var singleStatScore = -1;
            var statsCounter = 0;
            var anythingLessThanHigh = false;
            // Analyze from POOR to HIGH
            Object.keys(RtpQualityLevel).some(function (k) {
                var level = RtpQualityLevel[k];
                var list = statsReport.quality[level.value] || [];
                statsCounter += list.length;
                if (level !== RtpQualityLevel.RTP_QUALITY_HIGH && list.length > 0) {
                    var isPl = list.some(function (l) {
                        return l.id === 'PL_SEND' || l.id === 'PL_RECV';
                    });
                    if (isPl || list.length > 1) {
                        // If this level has 2+ stats or 1+ PL, that's the overall level
                        statsReport.quality.level = level;
                        return true;
                    } else {
                        // This level has a single stat, which will be analyzed later
                        singleStatCounter++;
                        singleStatScore += level.value + 1;
                    }
                    anythingLessThanHigh = true;
                }
                return false;
            });

            if (statsReport.quality.level === RtpQualityLevel.RTP_QUALITY_HIGH) {
                // Now check the levels that have single stats in them
                if (singleStatCounter > 2) {
                    // Every level has a single stat: overall is LOW
                    statsReport.quality.level = RtpQualityLevel.RTP_QUALITY_LOW;
                } else if (singleStatCounter > 1) {
                    // 2 levels have single stats: overall is the highest of the 2 (either LOW or MEDIUM)
                    statsReport.quality.level = singleStatScore > 2 ? RtpQualityLevel.RTP_QUALITY_MEDIUM : RtpQualityLevel.RTP_QUALITY_LOW;
                } else if (singleStatCounter === 1 && singleStatScore === 0) {
                    // If there's only 1 poor stat and the rest is at HIGH, overall is MEDIUM
                    statsReport.quality.level = RtpQualityLevel.RTP_QUALITY_MEDIUM;
                }
            }

            var oldLevel = _savedRTPStats.quality && _savedRTPStats.quality.level;

            if (statsCounter) {
                var anythingWasLessThanHigh = _savedRTPStats.quality && _savedRTPStats.quality.anythingLessThanHigh;
                // Stats must be saved before raising onNetworkQuality event
                _savedRTPStats.quality = {
                    level: statsReport.quality.level,
                    audio: statsReport.quality.audio,
                    video: statsReport.quality.video,
                    anythingLessThanHigh: anythingLessThanHigh
                };
                if (statsReport.quality.videoInfo) {
                    _savedRTPStats.quality.videoInfo = statsReport.quality.videoInfo;
                }

                if (!oldLevel || oldLevel !== statsReport.quality.level) {
                    // Send event on the first processing and every time the overall quality changes
                    _that.onNetworkQuality && _that.onNetworkQuality(statsReport.quality);
                    logQualityStats(statsReport);
                    return true;
                } else if (anythingLessThanHigh || anythingWasLessThanHigh) {
                    logQualityStats(statsReport);
                }
            } else {
                logger.debug('[CallStatsHandlerUnified]: calculateOverallQuality: No media stats available');
                _savedRTPStats.quality = {};
                if (oldLevel) {
                    _that.onNetworkQuality && _that.onNetworkQuality(null);
                }
            }
            return false;
        }

        function analyzeThresholdRecurrences(statsReport) {
            // Trigger onThresholdExceeded event if we have consecutive good/bad quality readings
            if (statsReport.quality.level === RtpQualityLevel.RTP_QUALITY_POOR || statsReport.quality.level === RtpQualityLevel.RTP_QUALITY_LOW) {
                _consecutiveBadQualityCounter++;
                if (_consecutiveGoodQualityCounter < THRESHOLD_RECURRENCE) {
                    _consecutiveGoodQualityCounter = 0;
                }
            } else if (_consecutiveBadQualityCounter) {
                if (_consecutiveBadQualityCounter < THRESHOLD_RECURRENCE) {
                    _consecutiveBadQualityCounter = 0;
                    _consecutiveGoodQualityCounter = 0;
                } else {
                    _consecutiveGoodQualityCounter++;
                }
            }

            if (_consecutiveBadQualityCounter === THRESHOLD_RECURRENCE) {
                _that.onThresholdExceeded && _that.onThresholdExceeded();
            } else if (_consecutiveGoodQualityCounter >= THRESHOLD_RECURRENCE && _consecutiveBadQualityCounter > THRESHOLD_RECURRENCE) {
                // Reset everything and trigger a onThresholdExceeded cleared event
                _consecutiveBadQualityCounter = 0;
                _consecutiveGoodQualityCounter = 0;
                _that.onThresholdExceeded && _that.onThresholdExceeded(true);
            }

            if (_violationsInProgress.noPacketsSent >= THRESHOLD_RECURRENCE) {
                logger.warn('[CallStatsHandlerUnified]: No audio packets sent to remote peer');

                if (_violationsInProgress.noPacketsSent === THRESHOLD_RECURRENCE && _that.onNoOutgoingPackets) {
                    // Send onNoOutgoingPackets event only once for this streak
                    _that.onNoOutgoingPackets();
                }
            }
            if (_violationsInProgress.noPacketsReceived >= THRESHOLD_RECURRENCE) {
                // Create a log whenever we detect that audio packets were not received
                logger.debug('[CallStatsHandlerUnified]: No audio packets received from remote peer');
            }
        }

        function processRTPStats(statsReport) {
            if (!statsReport) {
                return;
            }
            processAudioRTPStats(statsReport);
            processVideoRTPStats(statsReport);
            processRttStats(statsReport);
            calculateOverallQuality(statsReport);
            analyzeThresholdRecurrences(statsReport);
            saveChannelInfo(statsReport);
            collectClvStats(statsReport.quality, SsrcContents.AUDIO);

            ++_rtpStatsCollectionCounter;
            if (_rtpStatsCollectionCounter % LOG_SKIP_COUNTER === 0) {
                // Log raw stats every 30 seconds
                logRawStats(statsReport);
            }
        }

        function getFormattedRTPStats() {
            getRTPStatsForClientDiagnostics()
            .then(function (stats) {
                if (stats) {
                    processRTPStats(stats);
                }
            });
        }

        function stopCollectingRTPStats() {
            logger.debug('[CallStatsHandlerUnified]: stopCollectingRTPStats ', _streamQualityData);

            var promise = getRTPStatsForClientDiagnostics(true);

            // Probably there are not yet averaged data so write them also
            writeClvStats(SsrcContents.AUDIO);
            if (_rtpStatsDelayUponAnsTimer) {
                logger.debug('[CallStatsHandlerUnified]: Cancel collecting RTP stats ');
                window.clearTimeout(_rtpStatsDelayUponAnsTimer);
                _rtpStatsDelayUponAnsTimer = null;
            } else if (_rtpStatsCollectionTimer) {
                logger.debug('[CallStatsHandlerUnified]: Stop collecting RTP stats');
                window.clearInterval(_rtpStatsCollectionTimer);
                _rtpStatsCollectionTimer = null;

                if (_savedRTPStats) {
                    logger.debug('[CallStatsHandlerUnified]: savedRTPStats =', _savedRTPStats);
                }
                _that.onThresholdExceeded && _that.onThresholdExceeded(true);
                _consecutiveBadQualityCounter = 0;
                _consecutiveGoodQualityCounter = 0;
            }
            return promise;
        }

        function getOldStreamQualityValue(media, stat) {
            var data = _streamQualityData[media].qualityData;
            return (data && data.length && data[data.length - 1][stat.StatName]) || 0;
        }

        function getStreamQualityDataAverage(media) {
            var qualityData = _tmpStreamQualityData[media];
            _tmpStreamQualityData[media] = null;

            if (!qualityData || !qualityData.count) {
                return null;
            }

            var count = qualityData.count;
            delete qualityData.count;

            _streamQualityStatsToCheck.forEach(function (stat) {
                var value = qualityData[stat.StatName];
                if (stat.StatName === RTPAudioStatType.PACKET_LOSS_RECV.StatName) {
                    value *= 100;
                }
                qualityData[stat.StatName] = Math.round(value / count);
            });
            qualityData.timestamp = Date.now();
            return qualityData;
        }

        function writeClvStats(media) {
            var data = getStreamQualityDataAverage(media);
            if (data) {
                _streamQualityStatsToCheck.some(function (stat) {
                    var threshold = stat.StreamQualityThreshold;
                    var newValue = data[stat.StatName] || 0;
                    var oldValue = getOldStreamQualityValue(media, stat);
                    // Write initial data or if threshold exceeded
                    if (_streamQualityData[media].qualityData.length === 0 || Math.abs(oldValue - newValue) > threshold) {
                        logger.debug('[CallStatsHandlerUnified]: Writing into clientStreamQualityData - ', data);
                        _streamQualityData[media].qualityData.push(data);
                        return true;
                    }
                    return false;
                });
            }
        }

        function writeTmpClvStats(quality, media) {
            var qualityData = _tmpStreamQualityData[media];
            if (!qualityData) {
                qualityData = {count: 0};
                _streamQualityStatsToCheck.forEach(function (stat) {
                    qualityData[stat.StatName] = 0;
                });
                _tmpStreamQualityData[media] = qualityData;
            }

            _streamQualityStatsToCheck.forEach(function (stat) {
                var value = (quality[media][stat.id] && quality[media][stat.id].value) || 0;
                qualityData[stat.StatName] += value;
            });
            qualityData.count++;

            if (_streamQualityData[media].qualityData.length === 0) {
                // write initial dataType
                writeClvStats(media);
            }
        }

        function collectClvStats(quality, media) {
            // Only collect for direct calls. In Group Calls CLV retrieves this information from the MediaServer
            if (!_isDirectCall) {
                return;
            }

            _streamQualityData = _streamQualityData || {};
            _streamQualityData[media] = _streamQualityData[media] || {qualityData: []};

            _streamQualityData[media].trackId = quality[media].trackId;

            if (_tmpStreamQualityData[media] && _tmpStreamQualityData[media].count >= STREAM_QUALITY_SUMMARY_ITERATIONS) {
                writeClvStats(media);
            } else {
                writeTmpClvStats(quality, media);
            }
        }

        /////////////////////////////////////////////////////////////////////////////
        // Public interfaces
        /////////////////////////////////////////////////////////////////////////////
        this.start = function () {
            startCollectingRTPStats();
        };

        this.stop = function () {
            return stopCollectingRTPStats();
        };

        this.onThresholdExceeded = null;
        this.onNoOutgoingPackets = null;
        this.onNetworkQuality = null;

        this.setOptions = function (options) {
            if (options) {
                _options = options;
            }
        };

        this.getLastSavedStats = function () {
            _savedRTPStats.channelInfo = _savedChannelInfo;
            return _savedRTPStats;
        };

        this.getStreamQualityData = function () {
            return _streamQualityData;
        };

        this.setTransmitVideoParams = function (params) {
            if (!params) {
                return;
            }

            if (params.mediaConstraints && params.mediaConstraints.hdVideo) {
                var resolution = params.mediaConstraints.videoResolution || VideoResolutionLevel.VIDEO_1080;
                var height, width;
                switch (resolution) {
                case VideoResolutionLevel.VIDEO_1080:
                    height = 1080;
                    width = 1920;
                    break;
                case VideoResolutionLevel.VIDEO_720:
                    height = 720;
                    width = 1280;
                    break;
                default:
                    height = 480;
                    width = 853;
                    break;
                }
                _rtpHDVideoStatType.FRAME_RATE_SENT.Threshold = [20, 8, 3];
                _rtpHDVideoStatType.FRAME_HEIGHT_SENT.Threshold = [
                    0.9 * height, 0.75 * height, 0.5 * height
                ];
                _rtpHDVideoStatType.FRAME_WIDTH_SENT.Threshold = [
                    0.9 * width, 0.75 * width, 0.5 * width
                ];

                var maxBitRate = (params.bitRates.maxBitRate || 0) * 1000;
                var minBitRate = (params.bitRates.minBitRate || 0) * 1000;
                var highThreshold = maxBitRate * 0.75;
                if (highThreshold < minBitRate) {
                    // Min and max are too close. Use maxBitRate as threshold.
                    highThreshold = maxBitRate;
                }
                _rtpHDVideoStatType.AVAIL_SEND_BW.Threshold = [highThreshold, minBitRate, 0.5 * minBitRate];
                _rtpHDVideoStatType.TRANSMIT_BIT_RATE.Threshold = _rtpHDVideoStatType.AVAIL_SEND_BW.Threshold;
            }

            if (params.trackConstraints) {
                // Find the low bandwidth/quality video sender(s). We don't report stats for them.
                Object.keys(params.trackConstraints).forEach(function (id) {
                    var track = params.trackConstraints[id];
                    if (track.quality === Constants.VideoQuality.LOW) {
                        _lowQualityVideoTracks[id] = true;
                    }
                });
            }
        };
    }

    // Exports
    circuit.CallStatsHandlerUnified = CallStatsHandlerUnified;
    circuit.CallStats = CallStats;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
