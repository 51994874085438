/*global Uint8Array*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;

    // eslint-disable-next-line max-params, max-lines-per-function
    function VoicemailSvcImpl($rootScope, $window, $q, $http, LogSvc, PubSubSvc, FileApiSvc, UserProfileSvc) { // NOSONAR
        LogSvc.debug('New Service: VoicemailSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_VM_TIMEOUT = 20;
        var VM_SETTINGS = [
            Constants.UserSettingKey.VOICEMAIL_ENABLED,
            Constants.UserSettingKey.VOICEMAIL_NO_RECORDING,
            Constants.UserSettingKey.VOICEMAIL_TIMEOUT,
            Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_ENABLED,
            Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_URI,
            Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETINGS
        ];

        var _self = this;
        var _vmSettings = {};

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getSettingValue(key, defaultValue) {
            var setting = _vmSettings[key];
            if (setting) {
                switch (setting.dataType) {
                case Constants.UserSettingDataType.BOOLEAN:
                    return setting.booleanValue;
                case Constants.UserSettingDataType.NUMBER:
                    return setting.numberValue;
                case Constants.UserSettingDataType.STRING:
                    return setting.stringValue;
                }
            }
            return defaultValue;
        }

        function assignSettingValue(setting, value) {
            switch (setting.dataType) {
            case Constants.UserSettingDataType.BOOLEAN:
                setting.booleanValue = value;
                break;
            case Constants.UserSettingDataType.NUMBER:
                setting.numberValue = value;
                break;
            case Constants.UserSettingDataType.STRING:
                setting.stringValue = value;
                break;
            }
            return setting;
        }

        function setSettingValue(key, value) {
            var setting = _vmSettings[key];
            if (setting) {
                assignSettingValue(setting, value);
            }
        }

        function saveSetting(name, value) {
            var deferred = $q.defer();
            UserProfileSvc.saveSetting(name, value, function (err) {
                if (err) {
                    deferred.reject(err);
                } else {
                    setSettingValue(name, value);
                    LogSvc.debug('[VoicemailSvc]: Successfully set ' + name + ' to ', value);
                    deferred.resolve(value);
                }
            });
            return deferred.promise;
        }

        function saveAllSettings(keyValues) {
            var settings = [];
            Object.keys(keyValues).forEach(function (key) {
                var vmSetting = _vmSettings[key];
                var value = keyValues[key];
                if (vmSetting && value !== undefined) {
                    settings.push(assignSettingValue(Object.assign({}, vmSetting), value));
                }
            });
            if (!settings.length) {
                return $q.reject('No settings provided');
            }
            return new $q(function (resolve, reject) {
                UserProfileSvc.saveSettings(settings, function (err) {
                    if (err) {
                        updateSettings(UserProfileSvc.getSettings());
                        reject(err);
                    } else {
                        resolve();
                    }
                });
            });
        }

        function updateSettings(settings) {
            VM_SETTINGS.forEach(function (name) {
                _vmSettings[name] = settings.find(function (setting) { return setting.key === name; });
            });
        }

        function dataURLtoBlob(dataURL, type) {
            var binary = $window.atob(dataURL.split(',')[1]);
            var array = [];
            var fileType = type || 'audio/wav';
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type: fileType});
        }

        function getGreetingId() {
            return getSettingValue(Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_URI, null);
        }

        function getCustomGreetingSettings() {
            var settings;
            try {
                var value = getSettingValue(Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETINGS, null);
                settings = value ? JSON.parse(value) : {};
            } catch (err) {
                LogSvc.error('[VoocemailSvc]: Failed to parse custom greetings. ', err);
                settings = {};
            }
            if (!settings.customGreetings) {
                settings.customGreetings = {};
            }
            return settings;
        }

        function setLegacySettings(settings, enabled, uri, noRecording) {
            settings[Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_ENABLED] = enabled;
            settings[Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_URI] = uri;
            settings[Constants.UserSettingKey.VOICEMAIL_NO_RECORDING] = noRecording;
        }

        function updateCustomGreetingSettings(fileId, name, enabled, noRecording, replacedFileId) {
            var settings = {};
            enabled = !!(fileId && enabled);
            noRecording = !!noRecording;

            var greetingSettings = getCustomGreetingSettings();
            if (replacedFileId) {
                delete greetingSettings.customGreetings[replacedFileId];
            }
            if (fileId) {
                greetingSettings.customGreetings[fileId] = {
                    fileId: fileId,
                    name: name,
                    noRecording: noRecording
                };
            }
            settings[Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETINGS] = JSON.stringify(greetingSettings);

            var greetingId = getGreetingId();
            if (enabled || (greetingId && (greetingId === fileId || greetingId === replacedFileId))) {
                setLegacySettings(settings, enabled, fileId || '', enabled && noRecording);
            }
            return saveAllSettings(settings);
        }

        function uploadGreetingBlob(blob, fileIdToReplace) {
            var deferred = $q.defer();
            LogSvc.debug('[VoicemailSvc]: Uploading greeting ', {blobName: blob.name, type: blob.type, size: blob.size});
            $http.post(circuit.__domain + '/fileapi', blob, {
                headers: {
                    'Content-Type': blob.type,
                    'Content-Disposition': 'attachment; filename="' + blob.name + '"'
                },
                transformRequest: angular.identity
            })
            .then(function (res) {
                var uploadedFileId = res.data[0].id;
                if (uploadedFileId) {
                    LogSvc.debug('[VoicemailSvc]: Uploaded greeting - new fileId = ', uploadedFileId);
                    if (fileIdToReplace) {
                        LogSvc.debug('[VoicemailSvc]: Removing previous greeting - fileId = ', fileIdToReplace);
                        FileApiSvc.deleteFile(fileIdToReplace)
                        .then(function () {
                            LogSvc.debug('[VoicemailSvc]: Previous greeting removed Successfully');
                            deferred.resolve(uploadedFileId);
                        })
                        .catch(function () {
                            FileApiSvc.deleteFile(uploadedFileId);
                            deferred.reject('Failed to remove file: ' + fileIdToReplace);
                        });
                    } else {
                        deferred.resolve(uploadedFileId);
                    }
                } else {
                    deferred.reject('Upload failed. Missing file ID.');
                }
            });
            return deferred.promise;
        }

        function uploadGreeting(file, fileIdToReplace) {
            var deferred = $q.defer();
            if (!file) {
                deferred.reject('Missing file parameter.');
            } else if (file.size <= 0) {
                deferred.reject('The file is empty.');
            } else {
                var blob;
                var upload = function () {
                    uploadGreetingBlob(blob, fileIdToReplace)
                    .then(function (uploadedFileId) {
                        deferred.resolve(uploadedFileId);
                    })
                    .catch(function (error) {
                        deferred.reject(error);
                    });
                };
                if (!file.name) {
                    blob = file;
                    blob.name = 'greeting-' + $rootScope.localUser.userId + '.wav';
                    upload();
                } else {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        blob = dataURLtoBlob(e.target.result, file.type);
                        blob.name = file.name;
                        upload();
                    };
                    reader.readAsDataURL(file);
                }
            }
            return deferred.promise;
        }

        function init() {
            if ($rootScope.localUser) {
                updateSettings(UserProfileSvc.getSettings());
            } else {
                PubSubSvc.subscribeOnce('/localUser/init', function () {
                    updateSettings(UserProfileSvc.getSettings());
                });
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/user/settings/update', function (settings) {
            LogSvc.debug('[VoicemailSvc]: Received /user/settings/update event');
            updateSettings(settings);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /*
         * Get setting for voicemail enabled flag.
         * @return {Boolean} The current state for voicemail enabled flag.
         */
        this.isVoicemailEnabled = function () {
            return getSettingValue(Constants.UserSettingKey.VOICEMAIL_ENABLED, false);
        };

        /*
         * Stores setting for voicemail enabled flag.
         * @param {Boolean} voicemailEnabled The new state for voicemail enabled flag
         * @return {Boolean} The new state for voicemail enabled flag.
         */
        this.setVoicemailEnabled = function (voicemailEnabled) {
            return saveSetting(Constants.UserSettingKey.VOICEMAIL_ENABLED, !!voicemailEnabled);
        };

        /*
         * Get setting for voicemail no recording flag.
         * @return {Boolean} The current state for voicemail no recording flag.
         */
        this.isVoicemailNoRecording = function () {
            return getSettingValue(Constants.UserSettingKey.VOICEMAIL_NO_RECORDING, false);
        };

        /*
         * Stores setting for voicemail no recording flag.
         * @param {Boolean} voicemailNoRecording The new state for voicemail no recording flag
         * @return {Boolean} The new state for voicemail no recording flag.
         */
        this.setVoicemailNoRecording = function (voicemailNoRecording) {
            return saveSetting(Constants.UserSettingKey.VOICEMAIL_NO_RECORDING, !!voicemailNoRecording);
        };

        /*
         * Get setting for voicemail timeout.
         * @return {Number} The current voicemail timeout.
         */
        this.getVoicemailTimeout = function () {
            return getSettingValue(Constants.UserSettingKey.VOICEMAIL_TIMEOUT, DEFAULT_VM_TIMEOUT);
        };

        /*
         * Stores setting for voicemail timeout.
         * @param {Number} voicemailTimeout The new voicemail timeout.
         * @return {String} The new voicemail timeout.
         */
        this.setVoicemailTimeout = function (voicemailTimeout) {
            if (!voicemailTimeout || typeof voicemailTimeout !== 'number') {
                return $q.reject('Timeout must be a number');
            }
            return saveSetting(Constants.UserSettingKey.VOICEMAIL_TIMEOUT, voicemailTimeout);
        };

        /*
         * Get setting for voicemail custom greeting enabled flag.
         * @return {Boolean} The current state for voicemail custom greeting enabled flag.
         */
        this.isVoicemailCustomGreetingEnabled = function () {
            return getSettingValue(Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_ENABLED, false);
        };

        /*
         * Stores setting for voicemail custom greeting enabled flag.
         * @param {Boolean} voicemailCustomGreetingEnabled The new state for voicemail custom greeting enabled flag
         * @return {Boolean} The new state for voicemail custom greeting enabled flag.
         */
        this.setVoicemailCustomGreetingEnabled = function (voicemailCustomGreetingEnabled) {
            return saveSetting(Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_ENABLED, voicemailCustomGreetingEnabled);
        };

        /*
         * Gets the URL of the current custom greeting on the back-end storage.
         *
         * @return {String} the URL of the custom greeting or null
         */
        this.getVoicemailCustomGreetingUri = function () {
            return _self.fileIdToUri(getGreetingId());
        };

        /**
         * Returns custom greeting file settings.
         *
         * @returns {Object} settings as key value entries: [fileId]: {fileId: ... name: ..., ...}
         */
        this.getVoicemailCustomGreetings = function () {
            var legacyFileId = getGreetingId();
            var settings = getCustomGreetingSettings();
            Object.values(settings.customGreetings).forEach(function (greeting) {
                greeting.uri = _self.fileIdToUri(greeting.fileId);
            });
            if (legacyFileId && !settings.customGreetings[legacyFileId]) {
                settings.customGreetings[legacyFileId] = {
                    fileId: legacyFileId,
                    name: $rootScope.i18n.map.res_VmCustomGreetingCustom,
                    noRecording: getSettingValue(Constants.UserSettingKey.VOICEMAIL_NO_RECORDING),
                    uri: _self.fileIdToUri(legacyFileId)
                };
            }
            return settings.customGreetings;
        };

        /**
         * Update custom greeting file settings.
         *
         * @param {String} fileId greeting file ID
         * @param {String} name name of the greeting
         * @param {Boolean} enabled enable/disable this greeting
         * @param {Boolean} noRecording enable/disable greeting only mode
         * @returns {Promise} promise that will be resolved on success or rejected with error otherwise
         */
        this.updateCustomGreeting = function (fileId, name, enabled, noRecording) {
            if (!fileId) {
                return $q.reject('fileId cannot be empty.');
            }
            if (!name) {
                return $q.reject('Name cannot be empty.');
            }
            return updateCustomGreetingSettings(fileId, name, enabled, noRecording);
        };

        /**
         * (legacy) Uploads custom greeting file or recorded blob to the back-end storage.
         *
         * @param {File|Blob} file the custom greeting file to be uploaded
         * @returns {Promise} promise that will be resolved on success (empty string) or rejected with error otherwise
         */
        this.uploadCustomGreeting = function (file) {
            return uploadGreeting(file, getGreetingId())
            .then(function (fileId) {
                var settings = {};
                setLegacySettings(settings, true, fileId);
                var greetingSettings = getCustomGreetingSettings();
                delete greetingSettings.customGreetings[getGreetingId()];
                greetingSettings.customGreetings[fileId] = {
                    fileId: fileId,
                    name: $rootScope.i18n.map.res_VmCustomGreetingCustom,
                    noRecording: _self.isVoicemailNoRecording()
                };
                settings[Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETINGS] = JSON.stringify(greetingSettings);
                return saveAllSettings(settings);
            })
            .then(function () {
                return $q.resolve('');
            });
        };

        /**
         * Uploads custom greeting file or recorded blob to the back-end storage.
         *
         * @param {File|Blob} file the custom greeting file to be uploaded
         * @param {String} name name of the greeting
         * @param {Boolean} enabled enable/disable this greeting
         * @param {Boolean} noRecording enable/disable greeting only mode
         * @param {String} fileIdToReplace file ID which should be replaced
         * @returns {Promise} promise that will be resolved on success (fileId) or rejected with error otherwise
         */
        this.uploadVoicemailCustomGreeting = function (file, name, enabled, noRecording, fileIdToReplace) {
            if (!name) {
                return $q.reject('Name cannot be empty.');
            }
            return uploadGreeting(file, fileIdToReplace)
            .then(function (fileId) {
                return updateCustomGreetingSettings(fileId, name, enabled, noRecording, fileIdToReplace)
                .then(function () {
                    return fileId;
                });
            });
        };

        /**
         * Deletes current custom greeting file from the back-end storage.
         *
         * @returns {Promise} promise that will be resolved on success or rejected with error otherwise
         */
        this.deleteCustomGreeting = function (id) {
            var fileId = id || getGreetingId();
            return FileApiSvc.deleteFile(fileId).then(function () {
                if (id) {
                    return updateCustomGreetingSettings(null, null, false, false, fileId);
                } else {
                    // legacy part
                    var settings = {};
                    setLegacySettings(settings, false, '');
                    var greetingSettings = getCustomGreetingSettings();
                    delete greetingSettings.customGreetings[fileId];
                    settings[Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETINGS] = JSON.stringify(greetingSettings);
                    return saveAllSettings(settings);
                }
            });
        };

        /**
         * Converts file ID into URI string.
         */
        this.fileIdToUri = function (fileId) {
            return fileId ? circuit.__domain + '/fileapi?fileid=' + fileId : null;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        init();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.VoicemailSvcImpl = VoicemailSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
