
var Circuit = (function (circuit) {
    'use strict';

    ///////////////////////////////////////////////////////////////////////////////////////
    /// Service to load and edit Pictures for editing.
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function PictureEditSvcImpl($window, $q) { // NOSONAR
        var $document = $window.document;

        this.getPixelRatio = function (context) {
            // Determine the 'device pixel ratio'
            // This takes care of canvas blurriness on high density displays
            //  see: http://www.html5rocks.com/en/tutorials/canvas/hidpi/
            var backingStore = context.backingStorePixelRatio ||
                  context.webkitBackingStorePixelRatio ||
                  context.mozBackingStorePixelRatio ||
                  context.msBackingStorePixelRatio ||
                  context.oBackingStorePixelRatio ||
                  context.backingStorePixelRatio || 1;

            return ($window.devicePixelRatio || 1) / backingStore;
        };

        this.rotateImage = function (image, orientation) {
            // Rotates the image according to the orientation
            // [0, 1, 2, 3] -> [0, 90, 180, 270]deg
            var canvas = $document.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.width = orientation % 2 ? image.height : image.width;
            canvas.height = orientation % 2 ? image.width : image.height;

            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(Math.PI * orientation / 2);
            context.drawImage(image,
                -image.width / 2,
                -image.height / 2,
                image.width,
                image.height);
            return canvas;
        };

        this.resizeImage = function (image, newSize) {
            var canvas = $document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            var diff = [0, 0];
            if (image.width > image.height) {
                diff[0] = image.width - image.height;
            } else {
                diff[1] = image.height - image.width;
            }

            canvas.width = newSize;
            canvas.height = newSize;
            // draw canvas image
            ctx.drawImage(image, diff[0] / 2, diff[1] / 2, image.width - diff[0], image.height - diff[1], 0, 0, newSize, newSize);
            // convert canvas to jpeg url
            return canvas.toDataURL('image/jpeg');
        };

        this.loadImageFromURL = function (url) {
            var deferred = $q.defer();
            var image = $document.createElement('img');
            image.onload = function () { // Bind load event
                deferred.resolve(this);
            };
            image.setAttribute('src', url); // Set content
            return deferred.promise;
        };

        this.loadUrlFromFile = function (file) {
            var deferred = $q.defer(),
                reader = new FileReader();

            function readerLoaded(e) {
                deferred.resolve(e.target.result);
            }

            if (!(/image/i).test(file.type)) {
                deferred.reject('Not an image file');
                return deferred.promise;
            }

            reader.onload = readerLoaded;
            // begin reader read operation
            reader.readAsDataURL(file);
            return deferred.promise;
        };

        this.loadImageFromFile = function (file) {
            return this.loadUrlFromFile(file)
                .then(this.loadImageFromURL);
        };

        function Vector(x, y) {
            this.x = x;
            this.y = y;
        }
        Vector.prototype.add = function (p2, p3, p4) {
            var newVector = new Vector(this.x + p2.x, this.y + p2.y);
            if (p3) {
                newVector.x += p3.x;
                newVector.y += p3.y;
            }
            if (p4) {
                newVector.x += p4.x;
                newVector.y += p4.y;
            }
            return newVector;
        };
        Vector.prototype.subtract = function (p2, p3, p4) {
            var newVector = new Vector(this.x - p2.x, this.y - p2.y);
            if (p3) {
                newVector.x -= p3.x;
                newVector.y -= p3.y;
            }
            if (p4) {
                newVector.x -= p4.x;
                newVector.y -= p4.y;
            }
            return newVector;
        };
        Vector.prototype.scale = function (factor) {
            return new Vector(factor * this.x, factor * this.y);
        };
        Vector.prototype.toString = function () {
            return '(' + this.x + ',' + this.y + ')';
        };

        this.helpers = {
            Vector: Vector,
            vector: function (x, y) { return new Vector(x, y); }
        };

    }

    // Exports
    circuit.PictureEditSvcImpl = PictureEditSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
