var Circuit = (function (circuit) {
    'use strict';

    var Constants = circuit.Constants;
    var RoutingOptions = circuit.RoutingOptions;
    var BusyHandlingOptions = circuit.BusyHandlingOptions;
    var CallForwardingTypes = circuit.Enums.CallForwardingTypes;
    var Targets = circuit.Enums.Targets;
    var Utils = circuit.Utils;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;

    // eslint-disable-next-line max-params, max-lines-per-function
    function TelephonySettingsSvcImpl($rootScope, $q, LogSvc, PubSubSvc, CstaSvc, UserProfileSvc, VoicemailSvc) { // NOSONAR
        LogSvc.debug('New Service: TelephonySettingsSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var VM_OFFSET = 3;              // The amount of extra seconds that the VM timer is set longer than the main ring duration

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _that = this;
        var _routingOptions = [];
        var _savedRoutingOption = null;
        var _savedCallForwardingNumber;
        var _savedCallForwardingNoAnswerNumber;
        var _savedCallForwardingStatus = false;
        var _savedCallForwardingNoAnswerStatus = false;
        var _savedCallForwardingVMStatus = false;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function isSelectedRouting(routingOption) {
            return !!(routingOption && $rootScope.localUser.selectedRoutingOption === routingOption.name);
        }

        function setRoutingOption(routingOption) {
            if (routingOption) {
                $rootScope.localUser.selectedRoutingOption = routingOption.name;
            }
        }

        function setRoutingOptions() {
            if (!$rootScope.localUser.isATC) {
                return;
            }
            $rootScope.localUser.isOsBizCTIEnabled ? setRoutingOptionsOSBiz() : setRoutingOptionsATC();
            _that.updateRoutingOption();
        }


        function setRoutingOptionsATC() {
            // Check 'Default Routing'
            _routingOptions = [RoutingOptions.DefaultRouting];

            // Check 'Desk phone'
            var answerDevices = CstaSvc.getCallDevices();
            var option = RoutingOptions.DeskPhone;
            option.disabled = !answerDevices.includes(Targets.Desk) || !!_that.isCallForwardingEnabled();
            if (option.disabled && isSelectedRouting(RoutingOptions.DeskPhone)) {
                LogSvc.warn('[TelephonySettingsSvc]: Invalid routing option. Change incoming routing from DeskPhone number to Default routing');
                setRoutingOption(RoutingOptions.DefaultRouting);
            }
            _routingOptions.push(option);


            // Check 'Alternative number'
            if ($rootScope.localUser.isOSV) {
                option = RoutingOptions.AlternativeNumber;
                option.disabled = !$rootScope.localUser.reroutingPhoneNumber || !!_that.isCallForwardingNoAnswerEnabled();
                _routingOptions.push(option);
                if (option.disabled && isSelectedRouting(option)) {
                    setRoutingOption(RoutingOptions.DefaultRouting);
                }
            } else if (isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                LogSvc.warn('[TelephonySettingsSvc]: Invalid routing option. Change incoming routing from Alternative number to Default routing');
                setRoutingOption(RoutingOptions.DefaultRouting);
            }

            // Check 'Voicemail'
            var vmOption = $rootScope.localUser.hasVoicemailPermission ? RoutingOptions.VM : null;
            if (vmOption) {
                setVoiceMailOptions(vmOption);
                _routingOptions.push(vmOption);
            }

            if ((!vmOption || vmOption.disabled) && isSelectedRouting(RoutingOptions.VM)) {
                LogSvc.warn('[TelephonySettingsSvc]: Invalid routing option. Change incoming routing from Voicemail to Default routing');
                setRoutingOption(RoutingOptions.DefaultRouting);
            }

            // Check 'Other'
            if (isSelectedRouting(RoutingOptions.Other)) {
                var other = RoutingOptions.Other;
                other.number = $rootScope.localUser.otherRoutingNumber;
                _routingOptions.push(other);
            }

            // Check ring duration option
            if ($rootScope.localUser.ringDurationEnabled && Utils.cleanPhoneNumber($rootScope.localUser.alternativeNumber) !==
                Utils.cleanPhoneNumber($rootScope.localUser.reroutingPhoneNumber)) {
                UserProfileSvc.setReroutingPhoneNumber($rootScope.localUser.alternativeNumber);
            }
        }


        function setRoutingOptionsOSBiz() {

            // Check 'Default Routing'
            _routingOptions = [RoutingOptions.DefaultRouting];

            // Check 'Desk phone'
            var answerDevices = CstaSvc.getCallDevices();
            var option = RoutingOptions.DeskPhone;
            option.disabled = !answerDevices.includes(Targets.Desk) || !!_that.isCallForwardingEnabled();
            if (option.disabled && isSelectedRouting(RoutingOptions.DeskPhone)) {
                LogSvc.warn('[TelephonySettingsSvc]: Invalid routing option. Change incoming routing from DeskPhone number to Default routing');
                setRoutingOption(RoutingOptions.DefaultRouting);
            }
            _routingOptions.push(option);

            // Check 'Circuit client'
            option = RoutingOptions.CircuitClient;
            option.disabled = !answerDevices.includes(Targets.Desk) || !!_that.isCallForwardingEnabled();
            if (option.disabled && isSelectedRouting(RoutingOptions.CircuitClient)) {
                LogSvc.warn('[TelephonySettingsSvc]: Invalid routing option. Change incoming routing from CircuitClient number to Default routing');
                setRoutingOption(RoutingOptions.DefaultRouting);
            }
            _routingOptions.push(option);

            // Check 'Alternative number'
            option = RoutingOptions.AlternativeNumber;
            option.disabled = !$rootScope.localUser.reroutingPhoneNumber;
            _routingOptions.push(option);
        }

        function initForwardingSettings() {
            var cfData = $rootScope.localUser.cfData;
            cfData.Immediate.cfwNumber = cfData.Immediate.cfwNumber || '';
            cfData.NoAnswer.cfwNumber = cfData.NoAnswer.cfwNumber || '';

            _savedCallForwardingNumber = cfData.Immediate.cfwNumber;
            _savedCallForwardingStatus = !!cfData.Immediate.cfwEnabled;

            _savedCallForwardingNoAnswerNumber = cfData.NoAnswer.cfwNumber;
            _savedCallForwardingNoAnswerStatus = !!cfData.NoAnswer.cfwEnabled;

            _savedCallForwardingVMStatus = !!cfData.VM.cfwEnabled;
        }

        function syncVMConfiguration() {
            if (!$rootScope.localUser.isATC || $rootScope.localUser.isOsBizCTIEnabled) {
                return;
            }
            if ($rootScope.localUser.voicemailPBXEnabled) {
                var vmTimeout = Math.max($rootScope.localUser.mainRingDuration + VM_OFFSET, VoicemailSvc.getVoicemailTimeout());
                _that.setVoicemail(VoicemailSvc.isVoicemailEnabled(), vmTimeout);
            }
        }

        function syncRoutingTimers() {
            if (!$rootScope.localUser.isATC || $rootScope.localUser.isOsBizCTIEnabled) {
                return;
            }
            var cellRingDurationTimer = Math.min(VoicemailSvc.getVoicemailTimeout(), 25);
            CstaSvc.setRoutingTimers({
                clientRingDuration: 1,
                mainRingDuration: $rootScope.localUser.mainRingDuration,
                cellRingDuration: cellRingDurationTimer
            });
        }

        function updateVoiceMailSettingOptions() {
            if (!$rootScope.localUser.isATC || $rootScope.localUser.isOsBizCTIEnabled) {
                return;
            }
            var routingOption = _routingOptions.find(function (option) {
                return option === RoutingOptions.VM;
            });
            setVoiceMailOptions(routingOption);
        }

        function setVoiceMailOptions(option) {
            if (option) {
                option.disabled = !VoicemailSvc.isVoicemailEnabled() || !$rootScope.localUser.vmNumber;
                option.ui = $rootScope.localUser.vmNumber ? 'res_BusyHandling_Voicemail' : 'res_BusyHandling_Voicemail_Not_Configured';

                if (VoicemailSvc.isVoicemailEnabled() && $rootScope.localUser.vmNumber && $rootScope.localUser.voicemailPBXEnabled) {
                    option.disabled = !!(_that.isCallForwardingEnabled() || _that.isCallForwardingNoAnswerEnabled());
                }
            }
        }

        function updateRoutingOptionInPBX() {
            if ($rootScope.localUser.isOsBizCTIEnabled) {
                return _savedRoutingOption !== null;
            }
            return _savedRoutingOption === RoutingOptions.AlternativeNumber.name ||
                    _savedRoutingOption === RoutingOptions.Other.name ||
                    _savedRoutingOption === RoutingOptions.VM.name ||
                    isSelectedRouting(RoutingOptions.AlternativeNumber) ||
                    isSelectedRouting(RoutingOptions.VM);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        function onCstaForwardingEvent() {
            setRoutingOptions();
            initForwardingSettings();
            syncVMConfiguration();
            if ($rootScope.localUser.ringDurationConfigurable) {
                syncRoutingTimers();
            }
            LogSvc.info('[TelephonySettingsSvc]: Publish /atc/routingForwardingEvent event.');
            PubSubSvc.publish('/atc/routingForwardingEvent', [_that.isAnyCallForwardingEnabled()]);
            updateVoiceMailSettingOptions();
        }

        function onUserSettingsUpdate() {
            LogSvc.debug('[TelephonySettingsSvc]: Received /user/settings/update event.');
            _savedRoutingOption = $rootScope.localUser.selectedRoutingOption;
            LogSvc.info('[TelephonySettingsSvc]: Publish /atc/routingForwardingEvent event.');
            PubSubSvc.publish('/atc/routingForwardingEvent', [_that.isAnyCallForwardingEnabled()]);
            syncVMConfiguration();
            syncRoutingTimers();
            if (!$rootScope.localUser.voicemailPBXEnabled) {
                updateVoiceMailSettingOptions();
            }
        }

        function onCstaGetForwardingResponse() {
            onCstaForwardingEvent();
            syncRoutingTimers();
        }

        function onCstaAgentStateUpdated() {
            // Reset stored call routing. Client needs to update circuit side only. PBX is already updated.
            _savedRoutingOption = null;
            setRoutingOptions();
        }

        function onDeviceChangedEvent() {
            setRoutingOptions();
        }

        PubSubSvc.subscribe('/csta/forwardingEvent', onCstaForwardingEvent);
        PubSubSvc.subscribe('/csta/getForwardingResponse', onCstaGetForwardingResponse);
        PubSubSvc.subscribe('/csta/agentStateUpdated', onCstaAgentStateUpdated);
        PubSubSvc.subscribe('/user/settings/update', onUserSettingsUpdate);
        PubSubSvc.subscribe('/csta/deviceChange', onDeviceChangedEvent);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Updates the user's routing option for telephony calls.
         * @param {Object} option The routing option object.
         */
        this.updateRoutingOption = function (option) {
            option = option || $rootScope.localUser.selectedRoutingOption;
            if (!$rootScope.localUser.isATC) {
                return $q.resolve();
            }
            if (option === _savedRoutingOption) {
                return $q.resolve();
            }

            return new $q(function (resolve, reject) {
                $rootScope.localUser.selectedRoutingOption = option;
                if (updateRoutingOptionInPBX()) {
                    LogSvc.debug('[TelephonySettingsSvc]: Update routing option on PBX to ', $rootScope.localUser.selectedRoutingOption);
                    CstaSvc.updateRoutingOption($rootScope.localUser.selectedRoutingOption, _savedRoutingOption, function (err) {
                        if (err) {
                            LogSvc.error('[TelephonySettingsSvc]: Failed to update routing on PBX. ', err);
                            $rootScope.localUser.selectedRoutingOption = _savedRoutingOption;
                            reject('res_FailedToSetRoutingOption');
                            return;
                        }
                        LogSvc.info('[TelephonySettingsSvc]: Successfully updated routing on PBX. Set routing option to ', $rootScope.localUser.selectedRoutingOption);
                        UserProfileSvc.saveSetting(Constants.UserSettingKey.ATC_ROUTING, $rootScope.localUser.selectedRoutingOption);
                        _savedRoutingOption = $rootScope.localUser.selectedRoutingOption;

                        // For OSBiz, changing routing option may affect the calling device list
                        if ($rootScope.localUser.isOsBizCTIEnabled) {
                            LogSvc.debug('[TelephonySettingsSvc]: Publish /csta/deviceChange event');
                            PubSubSvc.publish('/csta/deviceChange');
                        }
                        resolve();
                    });
                } else {
                    LogSvc.info('[TelephonySettingsSvc]: Set routing option to ', $rootScope.localUser.selectedRoutingOption);
                    UserProfileSvc.saveSetting(Constants.UserSettingKey.ATC_ROUTING, $rootScope.localUser.selectedRoutingOption);
                    _savedRoutingOption = $rootScope.localUser.selectedRoutingOption;
                    resolve();
                }
            });
        };

        /**
         * @deprecated
         * Use {@link setCallForwarding}
         */
        this.setForwardingImmediate = function (status, cb) {
            cb = cb || function () {};
            if (!_that.isCallForwardingAvailable()) {
                cb('res_FailedToSetForwardingNotAvailable');
                return;
            }
            status = !!status;

            var localUser = $rootScope.localUser;

            localUser.cfData.Immediate.cfwNumber = localUser.cfData.Immediate.cfwNumber || '';
            _savedCallForwardingNumber = _savedCallForwardingNumber || '';

            if (localUser.cfData.Immediate.cfwNumber && !Utils.isDialableNumber(localUser.cfData.Immediate.cfwNumber)) {
                // Not a valid number. Wait for user to correct the number.
                cb('Invalid phone number');
                return;
            }
            if (localUser.cfData.Immediate.cfwNumber !== _savedCallForwardingNumber || status !== _savedCallForwardingStatus) {
                CstaSvc.setForwardingImmediate(status, localUser.cfData.Immediate.cfwNumber, function (err) {
                    if (err) {
                        LogSvc.warn('[TelephonySettingsSvc]: Failed to set call forwarding immediate: ', err);
                        localUser.cfData.Immediate.cfwNumber = _savedCallForwardingNumber;
                        if (/privilegeViolationSpecifiedDevice/.test(err) || /invalidForwardingType/.test(err)) {
                            localUser.cfData.Immediate.cfwAvailable = !localUser.isOSV;
                            setRoutingOptions();
                            cb('res_FailedToSetForwardingNotAvailable');
                            return;
                        }
                        cb('res_FailedToSetForwarding');
                    } else {
                        _savedCallForwardingNumber = $rootScope.localUser.cfData.Immediate.cfwNumber;
                        _savedCallForwardingStatus = status;
                        if (status && localUser.selectedRoutingOption === RoutingOptions.VM.name) {
                            localUser.selectedRoutingOption = RoutingOptions.DefaultRouting.name;
                            LogSvc.info('[TelephonySettingsSvc]: Set routing option to ', $rootScope.localUser.selectedRoutingOption);
                            UserProfileSvc.saveSetting(Constants.UserSettingKey.ATC_ROUTING, $rootScope.localUser.selectedRoutingOption);
                            _savedRoutingOption = $rootScope.localUser.selectedRoutingOption;
                        }
                        cb(null);
                    }
                });
            } else {
                // No changes
                cb(null);
            }
        };

        /**
         * @deprecated
         * Use {@link setCallForwarding}
         */
        this.setForwardingToVM = function (status, cb) {
            // Set the callForwardingNumber as Voicemail number
            $rootScope.localUser.cfData.VM.cfwNumber = $rootScope.localUser.vmNumber;
            return CstaSvc.setForwardingToVM(status, function (err) {
                if (err) {
                    LogSvc.warn('[TelephonySettingsSvc]: Failed to set call forwarding to VM: ', err);
                    $rootScope.localUser.cfData.VM.cfwNumber = '';
                    $rootScope.localUser.cfData.VM.cfwEnabled = !status;
                    cb && cb('res_FailedToSetForwarding');
                } else {
                    $rootScope.localUser.cfData.VM.cfwEnabled = status;
                    cb && cb(null);
                }
            });
        };

        /**
         * Returns the available telephony devices.
         * @returns {Array} the list of available telephony devices.
         */
        this.getCallDevices = function () {
            return CstaSvc.getCallDevices();
        };

        /**
         * Returns the available routing options.
         * @returns {Array} the list of routing optins.
         */
        this.getRoutingOptions = function () {
            //If Routing options are empty, Populate it
            if (!_routingOptions.length) {
                setRoutingOptions();
            }
            return _routingOptions;
        };

        /**
         * @deprecated
         * Use {@link voicemailSvc.setVoicemailxxxx}
         */
        this.setVoicemail = function (status, duration, cb) {
            CstaSvc.setVoicemail(status, duration, cb);
        };

        /**
         * Set the autopilot routing timers.
         * @param {Array} timers List of timer objects.
         */
        this.setRoutingTimers = function (timers, cb) {
            if (!timers.hasOwnProperty('cellRingDuration')) {
                timers.cellRingDuration = Math.min(VoicemailSvc.getVoicemailTimeout(), 25);
            }
            if (!timers.hasOwnProperty('clientRingDuration')) {
                timers.clientRingDuration = 1;
            }
            CstaSvc.setRoutingTimers(timers, function (err) {
                if (!err) {
                    LogSvc.debug('[TelephonySettingsSvc]: Successfully set the routing timers: ', timers);
                    $rootScope.localUser.mainRingDuration = timers.mainRingDuration;
                    $rootScope.localUser.cellRingDuration = timers.cellRingDuration;
                    $rootScope.localUser.clientRingDuration = timers.clientRingDuration;
                    LogSvc.debug('[TelephonySettingsSvc]: Publish /localUser/update event');
                    PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                }
                cb && cb(err);
            });
        };

        /**
         * Set the rerouting phone number (alternative number) on the user's telephony configuration.
         * @param {String} number The rerouting phone number.
         * @returns {Promise} that resolves without parameters when the update was successful, it rejects with error string (resource string) otherwise.
         */
        this.setReroutingPhoneNumber = function (number, shouldPreformat) {
            number = number || '';

            number && shouldPreformat && (number = PhoneNumberFormatter.format(number));
            if (number === $rootScope.localUser.reroutingPhoneNumber) {
                return $q.resolve();
            }

            if (!number && $rootScope.localUser.selectedRoutingOption === RoutingOptions.AlternativeNumber.name) {
                // NGTC-1541: If the request times out we should catch the rejection
                _that.updateRoutingOption(RoutingOptions.DefaultRouting.name).catch();
            }

            return new $q(function (resolve, reject) {
                var validateAndResolve = function () {
                    if (!number && $rootScope.localUser.selectedBusyHandlingOption === BusyHandlingOptions.SendToAlternativeNumber.name) {
                        // The selected option is not allowed. Change it to Default Routing.
                        _that.saveBusyHandlingOption(BusyHandlingOptions.DefaultRouting.name);
                    }
                    resolve();
                };

                var lastReroutingPhoneNumber = $rootScope.localUser.reroutingPhoneNumber;

                UserProfileSvc.setReroutingPhoneNumber(number, function () {
                    // For OSBiz, alternative number is set using CSTA wihout re-registration.
                    // The number needs to be sent when disabling too.
                    if ($rootScope.localUser.isOsBizCTIEnabled) {
                        if ($rootScope.localUser.reroutingPhoneNumber !== number) {
                            return;
                        }
                        var reroutingPhoneNumber = $rootScope.localUser.reroutingPhoneNumber || lastReroutingPhoneNumber;
                        CstaSvc.setForwardingNoAnswer(reroutingPhoneNumber, !!$rootScope.localUser.reroutingPhoneNumber, function (err) {
                            if (err) {
                                LogSvc.warn('[TelephonySettingsSvc]: Failed to set alternative number: ', err);
                                $rootScope.localUser.reroutingPhoneNumber = '';
                                reject('res_FailedToSetAlternativeNumber');

                                LogSvc.debug('[TelephonySettingsSvc]: Publish /localUser/update event');
                                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                            } else {
                                validateAndResolve();
                            }
                        });
                    } else {
                        validateAndResolve();
                    }
                });
            });
        };

        /**
         * Returns the name of the current selected routing option.
         * @returns {String} the name of the selected routing option.
         */
        this.getSelectedRoutingOption = function () {
            return $rootScope.localUser.selectedRoutingOption || RoutingOptions.DefaultRouting.name;
        };

        /**
         * Returns whether the PBX exposes the ringing duration time.
         * @returns {Boolean} true if the PBX exposes the ringing duration time, false otherwise.
         */
        this.getRingDurationEnabled = function () {
            return !!$rootScope.localUser.ringDurationEnabled;
        };

        /**
         * Returns whether the ringing duration time is configurable.
         * @returns {Boolean} true if the duration time is configurable.
         */
        this.getRingDurationConfigurable = function () {
            return !!$rootScope.localUser.ringDurationConfigurable;
        };

        /**
         * Returns the routing timers.
         * @returns {Object} the routing times.
         */
        this.getRoutingTimers = function () {
            return {
                mainRingDuration: $rootScope.localUser.mainRingDuration,
                clientRingDuration: $rootScope.localUser.clientRingDuration,
                cellRingDuration: $rootScope.localUser.cellRingDuration
            };
        };

        /**
         * Returns the default routing option.
         * @returns {Object} the default routing option.
         */
        this.getDefaultRoutingOption = function () {
            return RoutingOptions.DefaultRouting;
        };

        /**
         * Returns whether call forwarding is available.
         * @returns {Boolean} true if call forwarding is available, false otherwise.
         */
        this.isCallForwardingAvailable = function () {
            return !!$rootScope.localUser.cfData.Immediate.cfwAvailable;
        };

        /**
         * Returns whether call forwarding is currently enabled.
         * @returns {Boolean} true if call forwarding is enabled.
         */
        this.isCallForwardingEnabled = function () {
            return !!$rootScope.localUser.cfData.Immediate.cfwEnabled;
        };

        /**
         * Returns whether call forwarding to voice mail is currently enabled.
         * @returns {Boolean} true if call forwarding to voice mail is enabled.
         */
        this.isCallForwardingToVMEnabled = function () {
            return !!$rootScope.localUser.cfData.VM.cfwEnabled;
        };

        /**
         * Returns whether call forwarding no answer is available.
         * @returns {Boolean} true if call forwarding no answer is available, false otherwise.
         */
        this.isCallForwardingNoAnswerAvailable = function () {
            return !!$rootScope.localUser.cfData.NoAnswer.cfwAvailable;
        };

        /**
         * Returns whether call forwarding no answer is currently enabled.
         * @returns {Boolean} true if call forwarding no answer is enabled.
         */
        this.isCallForwardingNoAnswerEnabled = function () {
            return !!$rootScope.localUser.cfData.NoAnswer.cfwEnabled;
        };

        /**
         * Returns whether call forwarding immediate or call forwarding no answer are currently available
         * @returns {boolean} true if call forwarding immediate or call forwarding no reply are available
         */
        this.isAnyCallForwardingAvailable = function () {
            return Object.values($rootScope.localUser.cfData).some(function (entry) {
                return entry.cfwAvailable;
            });
        };

        /**
         * Returns whether any call forwarding is enabled.
         * @returns {Boolean} true if any call forwarding is enabled.
         */
        this.isAnyCallForwardingEnabled = function () {
            return Object.values($rootScope.localUser.cfData).some(function (entry) {
                return entry.cfwAvailable && entry.cfwEnabled &&
                    (entry.cfwType === CallForwardingTypes.Immediate.name ||
                     entry.cfwType === CallForwardingTypes.NoAnswer.name ||
                     entry.cfwType === CallForwardingTypes.VM.name && $rootScope.localUser.isOsBizCTIEnabled);
            });
        };

        /**
         * Returns whether any call forwarding is enabled (OsBiz specific).
         * @returns {Boolean} true if any call forwarding is enabled.
         */
        this.isCallForwardingEnabledOsBiz = _that.isAnyCallForwardingEnabled;

        /**
         * Returns the call forwarding number.
         * @returns {String} the call forwarding number.
         */
        this.getCallForwardingNumber = function () {
            return $rootScope.localUser.cfData.Immediate.cfwNumber || '';
        };

        /**
         * Returns the call forwarding no answer number.
         * @returns {String} the call forwarding no answer number.
         */
        this.getCallForwardingNoAnswerNumber = function () {
            return $rootScope.localUser.cfData.NoAnswer.cfwNumber || '';
        };

        /**
         * Enable, disables or changes the user call forwarding.
         * @returns {Promise} that resolves with no parameters when call forwarding is successfully updated,
         * it is rejected with an error string (resource string) otherwise.
         */
        this.setCallForwarding = function (cfenabled, cftype, cfnumber) {
            if (cftype !== CallForwardingTypes.Immediate.name && cftype !== CallForwardingTypes.VM.name &&
                cftype !== CallForwardingTypes.NoAnswer.name) {
                LogSvc.err('[TelephonySettingsSvc]: Invalid call forwarding type ', cftype);
                return $q.reject('res_ErrorMsgInternal');
            }

            if (!_that.isAnyCallForwardingAvailable()) {
                return $q.reject('res_FailedToSetForwardingNotAvailable');
            }

            if (cftype === CallForwardingTypes.VM.name) {
                cfnumber = _that.getVoiceMailNumber();
            }

            if (cfnumber && !Utils.isDialableNumber(cfnumber)) {
                // Not a valid number. Wait for user to correct the number.
                return $q.reject('res_ErrorPhoneNumber');
            }

            cfenabled = !!cfenabled;

            if ((cftype === CallForwardingTypes.Immediate.name && cfenabled === _savedCallForwardingStatus && cfnumber === _savedCallForwardingNumber) ||
                (cftype === CallForwardingTypes.VM.name && cfenabled === _savedCallForwardingVMStatus) ||
                (cftype === CallForwardingTypes.NoAnswer.name && cfenabled === _savedCallForwardingNoAnswerStatus &&
                    cfnumber === _savedCallForwardingNoAnswerNumber)) {
                // Nothing changed
                return $q.resolve();
            }

            return new $q(function (resolve, reject) {
                var localUser = $rootScope.localUser;
                CstaSvc.setForwardingType(cftype, cfenabled, cfnumber, function (err) {
                    if (err) {
                        LogSvc.warn('[TelephonySettingsSvc]: Failed to set call forwarding ' + cftype + ':', err);
                        if (cftype === CallForwardingTypes.Immediate.name) {
                            localUser.cfData[cftype].cfwEnabled = _savedCallForwardingStatus;
                            localUser.cfData[cftype].cfwNumber = _savedCallForwardingNumber;
                        } else if (cftype === CallForwardingTypes.NoAnswer.name) {
                            localUser.cfData[cftype].cfwEnabled = _savedCallForwardingNoAnswerStatus;
                            localUser.cfData[cftype].cfwNumber = _savedCallForwardingNoAnswerNumber;
                        } else {
                            localUser.cfData[cftype].cfwEnabled = _savedCallForwardingVMStatus;
                        }
                        if (/privilegeViolationSpecifiedDevice/.test(err) || /invalidForwardingType/.test(err)) {
                            localUser.cfData[cftype].cfwAvailable = !localUser.isOSV;
                            if (cftype === CallForwardingTypes.Immediate.name) {
                                setRoutingOptions();
                            }
                            reject('res_FailedToSetForwardingNotAvailable');
                            return;
                        }
                        reject('res_FailedToSetForwarding');
                    } else {
                        localUser.cfData[cftype].cfwNumber = cfnumber;
                        localUser.cfData[cftype].cfwEnabled = cfenabled;
                        if (cftype === CallForwardingTypes.Immediate.name) {
                            _savedCallForwardingNumber = cfnumber;
                            _savedCallForwardingStatus = cfenabled;
                        } else if (cftype === CallForwardingTypes.NoAnswer.name) {
                            _savedCallForwardingNoAnswerNumber = cfnumber;
                            _savedCallForwardingNoAnswerStatus = cfenabled;
                        } else {
                            _savedCallForwardingVMStatus = cfenabled;
                        }
                        if (cfenabled && (localUser.selectedRoutingOption === RoutingOptions.VM.name ||
                                localUser.selectedRoutingOption === RoutingOptions.AlternativeNumber.name)) {
                            localUser.selectedRoutingOption = RoutingOptions.DefaultRouting.name;
                            LogSvc.info('[TelephonySettingsSvc]: Set routing option to ', $rootScope.localUser.selectedRoutingOption);
                            UserProfileSvc.saveSetting(Constants.UserSettingKey.ATC_ROUTING, $rootScope.localUser.selectedRoutingOption);
                            _savedRoutingOption = $rootScope.localUser.selectedRoutingOption;
                        }
                        resolve();
                    }
                });
            });
        };

        /**
         * Returns the call forwarding data object
         * @returns {{cfData}}
         */
        this.getCallForwardingData = function () {
            return JSON.parse(JSON.stringify($rootScope.localUser.cfData));
        };

        /**
         * Returns the call forwarding entry that is enabled
         * @returns {string}
         */
        this.getEnabledCallForwarding = function () {
            return Object.values($rootScope.localUser.cfData).find(function (cfEntry) {
                return cfEntry.cfwEnabled;
            });
        };

        /**
         * Returns the user's voicemail number.
         * @returns {String} the user's voicemail number.
         */
        this.getVoiceMailNumber = function () {
            return $rootScope.localUser.vmNumber || '';
        };

        /**
         * Returns whether busy handling options are available for the user.
         * @returns {Boolean} true if the user has busy handling options availabe, false otherwise.
         */
        this.canHandleBusyOptions = function () {
            return !!$rootScope.localUser.isATC;
        };

        /**
         * Returns the available busy handling options.
         * @returns {Array} list of available busy handling options.
         */
        this.getBusyHandlingOptions = function () {
            if (!_that.canHandleBusyOptions()) {
                return [];
            }

            var busyHandlingOptions = [BusyHandlingOptions.DefaultRouting, BusyHandlingOptions.BusySignal];

            // Add other options but disable them if not applicable
            var option = BusyHandlingOptions.SendToAlternativeNumber;
            option.disabled = !$rootScope.localUser.reroutingPhoneNumber;
            busyHandlingOptions.push(option);

            if ($rootScope.localUser.hasVoicemailPermission) {
                // If Voicemail is disabled or Voice Mail number is not received in Register Response, Disable VoiceMail
                var vmOption = Object.assign({}, BusyHandlingOptions.SendToVM);
                vmOption.disabled = !$rootScope.localUser.vmNumber || !VoicemailSvc.isVoicemailEnabled();
                vmOption.ui = $rootScope.localUser.vmNumber ? 'res_BusyHandling_Voicemail' : 'res_BusyHandling_Voicemail_Not_Configured';
                busyHandlingOptions.push(vmOption);
            }

            return busyHandlingOptions;
        };

        /**
         * Returns the current user's busy handling option.
         * @returns {String} the user's busy handling option name.
         */
        this.getSelectedBusyHandlingOption = function () {
            return $rootScope.localUser.selectedBusyHandlingOption;
        };

        /**
         * Saves the user's busy handling option.
         * @param {Object} option the user's busy handling option name.
         */
        this.saveBusyHandlingOption = function (option) {
            if (option && option !== $rootScope.localUser.selectedBusyHandlingOption) {
                $rootScope.localUser.selectedBusyHandlingOption = option;
                LogSvc.info('[TelephonySettingsSvc]: Set busy handling option to ', option);
                UserProfileSvc.saveSetting(Constants.UserSettingKey.SECOND_TELEPHONY_CALL_ROUTING, option);
            }
        };

        /**
         * Returns whether telephony is available to the user.
         * @returns {Boolean} true if telephony is available to the user, false otherwise.
         */
        this.isTelephonyAvailable = function () {
            return !!$rootScope.localUser.telephonyAvailable;
        };

        /**
         * Returns the user's caller id if it is different from the user's phone number
         * @returns {String} the user's called id.
         */
        this.getCircuitCallerId = function () {
            return $rootScope.localUser.phoneNumber !== $rootScope.localUser.callerId ? $rootScope.localUser.callerId : null;
        };

        /**
         * Returns the rerouting phone number (alternative number) from the user's telephony configutation.
         * @returns {String} the rerouting phone number.
         */
        this.getReroutingPhoneNumber = function () {
            return $rootScope.localUser.reroutingPhoneNumber;
        };

        /**
         * Returns whether rerouting is enabled.
         * @returns {Boolean} true if rerouting is enabled, false otherwise.
         */
        this.isReroutingEnabled = function () {
            return !!$rootScope.localUser.reroutingPhoneNumber;
        };

        /**
         * Returns the type of the telephony connector associated to the user.
         * @returns {Boolean} the telephony connector type.
         */
        this.getAssociatedTelephonyConnectorType = function () {
            return $rootScope.localUser.associatedTelephonyUserType;
        };

        /**
         * Returns whether the user's PBX has pick up group feature.
         * @returns {Boolean} true if the PBX has pick up group feature.
         */
        this.isPickupGroupAvailable = function () {
            return !!$rootScope.localUser.pbxCallPickupSupported;
        };

        /**
         * Returns whether the user's PBX voice mail service is enabled.
         * @returns {Boolean} true if the user PBX voice mail service is enabled, false otherwise.
         */
        this.isPBXVoiceMailEnabled = function () {
            return !!$rootScope.localUser.voicemailPBXEnabled;
        };

        /**
         * Returns whether the user has a telephony service that requires SIP registration.
         * @returns {Boolean} true if SIP registration is required.
         */
        this.isSIPRegistrationTelephony = function () {
            return !!$rootScope.localUser.isRegisterTC;
        };

        /**
         * Returns whether the uses has a CTI enabled OsBiz telephony service.
         * @returns {Boolean} true if the user has a CTI enabled OsBiz telephony service, false otherwise.
         */
        this.isOsBizCTIEnabled = function () {
            return !!$rootScope.localUser.isOsBizCTIEnabled;
        };
        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.TelephonySettingsSvcImpl = TelephonySettingsSvcImpl;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
