/*global require*/

var Circuit = (function (circuit) {
    'use strict';

    ///////////////////////////////////////////////////////////////////////////////////////
    // HttpSvc Implementation
    // This is a separate service to allow the mobile client to injet their own
    // implementation.
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function HttpSvcImpl($rootScope, $window, $document, $timeout, $http, $q, LogSvc) { // NOSONAR
        LogSvc.debug('New Service: HttpSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var URL_PREFIX = 'resources/i18n/resources-locale_';
        var LOCALE_URL = '/data/acceptLanguage'; // Retrieve supported browser configured language through server

        function handleDownload(blob, file, options) {
            var url = $window.URL.createObjectURL(blob);
            var a = $document[0].createElement('a');
            var name = file.fileName;
            try {
                a.style.display = 'none';
                a.href = url;
                a.download = name;
                // we use target 'blank' for the web ms teams microsoft app
                if (options && options.isTeamsApp) {
                    a.target = 'blank';
                }
                $document[0].body.appendChild(a);
                a.click();
            } catch (e) {
                LogSvc.error('[HttpSvc]: Error simulating anchor click. ', e);
            }

            // Do not revoke the object URL immediate as it causes download failure in some browsers.
            $timeout(function () {
                $window.URL.revokeObjectURL(url);
            }, 500, false);

            $document[0].body.removeChild(a);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.retrieveLanguageMap = function (lang, successCb, errorCb) {
            lang = lang || 'default';

            // Build the url to retrieve the localized resource file
            var url = URL_PREFIX + lang + '.json';

            successCb = successCb || function () {};
            errorCb = errorCb || function () {};

            // Request the resource file
            LogSvc.debug('[HttpSvc]: Retrieving language map for ', lang);
            $http.get(url)
            .then(function (response) {
                successCb(response.data);
            })
            .catch(function (response) {
                errorCb(response.data, response.status);
            });
        };

        this.getBrowserLocale = function () {
            var deferred = $q.defer();
            LogSvc.debug('[HttpSvc]: Retrieving supported browser locale');
            $http.get(LOCALE_URL)
            .then(function (response) {
                var locale = response.data && response.data.locale;
                LogSvc.info('[HttpSvc]: Retrieved browser locale: ', locale);
                deferred.resolve(locale);
            })
            .catch(function (response) {
                LogSvc.error('[HttpSvc]: Could not retrieve browser locale: ', response.status);
                deferred.resolve();
            });
            return deferred.promise;
        };

        this.downloadFile = function (file, doneCb, progressCb, options) {
            LogSvc.debug('[HttpSvc]: Download file ', file.uri);

            var xhr = new XMLHttpRequest();
            xhr.open('GET', file.uri, true);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState !== xhr.DONE) {
                    return;
                }

                if (xhr.status === 200) {
                    var blob;

                    // Since FF can't return reference to file, polyfill return blob object.
                    if ($rootScope.browser.firefox) {
                        blob = xhr.response;
                    } else {
                        blob = new Blob([xhr.response], {type: file.mimeType || 'text/plain'});
                    }

                    if (blob) {
                        handleDownload(blob, file, options);
                    }
                    if (doneCb) {
                        $rootScope.$apply(function () {
                            doneCb(null);
                        });
                    }
                } else {
                    LogSvc.error('[HttpSvc]: Download failed with response code ', xhr.status);
                    if (doneCb) {
                        $rootScope.$apply(function () {
                            doneCb('Download Failed');
                        });
                    }
                }
            };

            if (typeof progressCb === 'function') {
                xhr.addEventListener('progress', function (e) {
                    progressCb(e.loaded, xhr.readyState);
                }, false);
            }

            xhr.send();
        };

        this.downloadObjectDataAsFile = function (data, file, type) {
            var blob = new Blob([data], {type: type || 'text/plain'});
            handleDownload(blob, file);
        };

        this.downloadBlobAsFile = function (blob, file) {
            handleDownload(blob, file);
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.HttpSvcImpl = HttpSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
