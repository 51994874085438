/*global Promise*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var logger = circuit.logger;
    var RealtimeMediaType = circuit.Constants.RealtimeMediaType;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-lines-per-function
    function RtcPeerConnectionsUnified(pcConfig, options) { // NOSONAR
        options = options || {};

        // The following imports need to be defined inside RtcPeerConnections due to JS-SDK
        var WebRTCAdapter = circuit.WebRTCAdapter;

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _that = this;

        var _pcConfig = Utils.shallowCopy(pcConfig);

        var _pc = null;

        var _numOfVideoReceivers = 0;

        var _localDesktop = {};

        var _screenRemoteSsrc = [];

        /////////////////////////////////////////////////////////////////////////////
        // WebRTC 1.0: Real-time Communication Between Browsers
        /////////////////////////////////////////////////////////////////////////////
        function init() {
            _pcConfig.sdpSemantics = 'unified-plan';
            _pcConfig.bundlePolicy = 'max-compat';

            // Number of video receivers that we need
            _numOfVideoReceivers = options.extraVideoChannels +
                // options.createDesktopPc is used for backward compatibility. Once we completely move to Unified
                // Plan, only options.extraVideoChannels will be used
                (options.createDesktopPc ? 1 : 0) +
                // By default we have 1 video receiver. Do not include it if only screenshare is allowed from remote.
                (options.onlyRemoteScreenShare ? 0 : 1);

            _pcConfig.iceCandidatePoolSize = 1/*audio*/ + _numOfVideoReceivers;

            logger.debug('[RTCPeerConnectionsUnified]: Create RTCPeerConnection with config: ', _pcConfig);
            _pc = new WebRTCAdapter.PeerConnection(_pcConfig);
            registerEvtHandlers(_pc);
        }

        function registerEvtHandlers(pc) {
            pc.onnegotiationneeded = handleNegotiationNeeded.bind(null, pc);
            pc.onicecandidate = handleIceCandidate.bind(null, pc);
            pc.onsignalingstatechange = handleSignalingStateChange.bind(null, pc);
            pc.oniceconnectionstatechange = handleIceConnectionStateChange.bind(null, pc);
            pc.ontrack = handleOnTrack.bind(null, pc);
        }

        function unregisterEvtHandlers(pc) {
            pc.onnegotiationneeded = null;
            pc.onicecandidate = null;
            pc.onsignalingstatechange = null;
            pc.oniceconnectionstatechange = null;
            pc.ontrack = null;
        }

        function isPcCurrent(pc) {
            return !!pc && (pc === _pc);
        }

        function handleNegotiationNeeded(pc, event) {
            logger.debug('[RTCPeerConnectionsUnified]: onnegotiationneeded');
            if (!isPcCurrent(pc)) {
                logger.debug('[RTCPeerConnectionsUnified]: Event is from old Peer Connection');
                return;
            }
            sendNegotiationNeeded(event);
        }

        function handleIceCandidate(pc, event) {
            logger.debug('[RTCPeerConnectionsUnified]: onicecandidate: iceGatheringState = ', pc.iceGatheringState);
            if (!isPcCurrent(pc)) {
                logger.debug('[RTCPeerConnectionsUnified]: Event is from old Peer Connection');
                return;
            }
            if (!event.candidate) {
                logger.debug('[RTCPeerConnectionsUnified]: End of candidates');
            }
            sendIceCandidate(event);
        }

        function handleSignalingStateChange(pc, event) {
            logger.debug('[RTCPeerConnectionsUnified]: onsignalingstatechange: signalingState = ', pc.signalingState);
            if (!isPcCurrent(pc)) {
                logger.debug('[RTCPeerConnectionsUnified]:  Event is from old Peer Connection');
                return;
            }
            sendSignalingStateChange(event);
        }

        function handleIceConnectionStateChange(pc, event) {
            logger.debug('[RTCPeerConnectionsUnified]: oniceconnectionstatechange: iceConnectionState = ', pc.iceConnectionState);
            if (!isPcCurrent(pc)) {
                logger.debug('[RTCPeerConnectionsUnified]: Event is from old Peer Connection.');
                return;
            }
            sendIceConnectionStateChange(event);
        }

        function handleOnTrack(pc, event) {
            logger.debug('[RTCPeerConnectionsUnified]: ontrack:', event.track && event.track.label);
            if (!isPcCurrent(pc)) {
                logger.debug('[RTCPeerConnectionsUnified]: Event is from old Peer Connection.');
                return;
            }

            if (event.track) {
                // Find the mid attribute related to this track
                // There must be a better way of doing this... but for now we just
                // find the transceiver that contains this track and get the 'mid' from
                // the transceiver object
                var mid = null;
                var txrl = _pc.getTransceivers();
                txrl.some(function (t) {
                    if (t.receiver && compareTracks(t.receiver.track, event.track)) {
                        mid = t.mid;
                        return true;
                    }
                    return false;
                });
                if (mid) {
                    var stream = new WebRTCAdapter.MediaStream([event.track]);
                    stream.mid = mid;
                    logger.debug('[RTCPeerConnectionsUnified]: ontrack: mid: ', mid);
                    sendAddStream({
                        stream: stream
                    });
                } else {
                    logger.error('[RTCPeerConnectionsUnified]: ontrack: Could not find the mid attribute. This remote track won\'t be played: ', event.track.label);
                }
            }
        }

        /////////////////////////////////////////////////////////////////////////////
        // Internal functions
        /////////////////////////////////////////////////////////////////////////////
        function addTransceiversOffer(sdpConstraints, tOptions) {
            tOptions = tOptions || {};

            if (tOptions.audioInactive) {
                _pc.addTransceiver('audio', {
                    direction: 'inactive'
                });
            } else if (sdpConstraints.mandatory && sdpConstraints.mandatory.OfferToReceiveAudio) {
                // We should have 1 audio receiver, so add one if needed
                var found = _pc.getReceivers().some(function (r) {
                    return (r.track && r.track.kind) === 'audio';
                });
                if (!found) {
                    logger.debug('[RTCPeerConnectionsUnified]: create local SDP: Adding 1 audio receiver');
                    _pc.addTransceiver('audio', {
                        direction: 'recvonly'
                    });
                }
            }

            var receiveVideo = (sdpConstraints.mandatory && sdpConstraints.mandatory.OfferToReceiveVideo) || options.onlyRemoteScreenShare;
            var currNumOfRcvrs = 0;
            var toBeAdded = 0;
            if (receiveVideo) {
                // Count current number of receivers and add more if needed
                _pc.getReceivers().forEach(function (r) {
                    if ((r.track && r.track.kind) === 'video') {
                        currNumOfRcvrs++;
                    }
                });
                toBeAdded = _numOfVideoReceivers - currNumOfRcvrs;
                if (_localDesktop.track && toBeAdded > 0) {
                    // We'll be creating the screen receiver later
                    currNumOfRcvrs++;
                    toBeAdded = _numOfVideoReceivers - currNumOfRcvrs;
                } else {
                    // No desktop receiver should be created
                    receiveVideo = false;
                }
                if (toBeAdded >= 0) {
                    logger.debug('[RTCPeerConnectionsUnified]: create local SDP: Adding ' + toBeAdded + ' video receivers');
                    for (var i = 0; i < toBeAdded; i++) {
                        _pc.addTransceiver('video', {
                            direction: 'recvonly'
                        });
                    }
                } else {
                    logger.warn('[RTCPeerConnectionsUnified]: create local SDP: Could not calculate number of video receivers: ', toBeAdded);
                }
            }
            logger.debug('[RTCPeerConnectionsUnified]: Total number of video receivers: ', currNumOfRcvrs + toBeAdded);
            if (_localDesktop.track) {
                // Add desktop transceiver here, because we want it to be the last m-line
                // If only remote screen share is allowed, make sure direction is sendonly
                _pc.addTransceiver(_localDesktop.track, {
                    direction: receiveVideo && !options.onlyRemoteScreenShare ? 'sendrecv' : 'sendonly'
                });
            }
        }

        function addTransceiversAnswer(sdpConstraints, tOptions) {
            tOptions = tOptions || {};

            var receiveVideo = sdpConstraints.mandatory && sdpConstraints.mandatory.OfferToReceiveVideo;
            if (!receiveVideo || tOptions.audioInactive) {
                // In answer scenarios, the receivers are already created by the peer connection
                // so we need to disable them if needed
                _pc.getTransceivers().forEach(function (t) {
                    if (!t.stopped) {
                        var kind = t.receiver && t.receiver.track && t.receiver.track.kind;
                        if (!receiveVideo && kind === 'video') {
                            if (t.sender && t.sender.track) {
                                // We're sending video, set it to 'sendonly'
                                // If the remote SDP is also 'sendonly', then the peer connection will
                                // automatically change it to 'inactive'
                                t.direction = 'sendonly';
                            } else {
                                // We're not sending video, set to 'inactive'
                                t.direction = 'inactive';
                            }
                        } else if (tOptions.audioInactive && kind === 'audio') {
                            t.direction = 'inactive';
                        }
                    }
                });
            }

            if (_localDesktop.track) {
                // Add desktop transceiver here, because we want it to be the last m-line
                _pc.addTrack(_localDesktop.track, _localDesktop.stream);
            }
        }

        function compareTracks(t1, t2) {
            if (!t1 || !t2) {
                return false;
            }
            return WebRTCAdapter.getTrackId(t1) === WebRTCAdapter.getTrackId(t2);
        }

        function updateRemoteDescription(remoteSdp) {
            if (remoteSdp && remoteSdp.type === 'pranswer') {
                return new WebRTCAdapter.SessionDescription({
                    // 'pranswer' is not implemented yet, so convert it to 'answer'
                    type: 'answer',
                    sdp: remoteSdp.sdp
                });
            }
            return remoteSdp;
        }

        function isVideo(track) {
            return !!(track && track.kind === 'video');
        }

        function isMediaType(mediaType, mid, screenMid) {
            switch (mediaType) {
            case Constants.RealtimeMediaType.VIDEO:
                return mid !== screenMid;
            case Constants.RealtimeMediaType.DESKTOP_SHARING:
                return mid === screenMid;
            default:
                return false;
            }
        }

        /////////////////////////////////////////////////////////////////////////////
        // Event Senders
        /////////////////////////////////////////////////////////////////////////////
        function sendEvent(eventHandler, event) {
            if (typeof eventHandler === 'function') {
                try {
                    eventHandler(event);
                } catch (e) {
                    logger.error(e);
                }
            }
        }

        function sendNegotiationNeeded(event) {
            sendEvent(_that.onnegotiationneeded, event);
        }

        function sendIceCandidate(event) {
            sendEvent(_that.onicecandidate, event);
        }

        function sendSignalingStateChange(event) {
            sendEvent(_that.onsignalingstatechange, event);
        }

        function sendAddStream(event) {
            sendEvent(_that.onaddstream, event);
        }

        function sendIceConnectionStateChange(event) {
            sendEvent(_that.oniceconnectionstatechange, event);
        }

        /////////////////////////////////////////////////////////////////////////////
        // Event Handlers - same interfaces as peer connection
        /////////////////////////////////////////////////////////////////////////////
        this.onnegotiationneeded = null;
        this.onicecandidate = null;
        this.onsignalingstatechange = null;
        this.onaddstream = null;
        this.onremovestream = null;
        this.oniceconnectionstatechange = null;

        this.createOffer = function (successCb, errorCb, offerConstraints, tOptions) {
            function onOfferCreate(rtcSdp) {
                logger.debug('[RTCPeerConnectionsUnified]: createOffer succeeded');
                successCb(rtcSdp);
            }
            addTransceiversOffer(offerConstraints, tOptions);
            _pc.createOffer(onOfferCreate, errorCb);
        };


        this.createAnswer = function (successCb, errorCb, answerConstraints, tOptions) {
            function onAnswerCreate(rtcSdp) {
                logger.debug('[RTCPeerConnectionsUnified]: createAnswer succeeded');
                successCb(rtcSdp);
            }
            addTransceiversAnswer(answerConstraints, tOptions);
            _pc.createAnswer(onAnswerCreate, errorCb);
        };

        this.setMainMediaLines = function () {};

        this.setLocalDescription = function (rtcSdp, successCb, errorCb) {
            _pc.setLocalDescription(rtcSdp, successCb, errorCb);
        };

        this.setRemoteDescription = function (rtcSdp, successCb, errorCb) {
            _pc.setRemoteDescription(updateRemoteDescription(rtcSdp), successCb, errorCb);
        };

        this.removeStream = function (localStream) {
            if (!localStream) {
                return;
            }

            // removeTrack accepts RtcRtpSender only, so we need to find the local sender
            // that contains the track(s) from the localStream parameter
            var senders = _pc.getSenders();
            var tracks = localStream.getTracks();
            tracks.forEach(function (t) {
                logger.debug('[RTCPeerConnectionsUnified]: Attempting to remove local track: ', t.label);
                senders.some(function (s) {
                    if (compareTracks(s.track, t)) {
                        logger.debug('[RTCPeerConnectionsUnified]: Found sender, track removed');
                        if (compareTracks(_localDesktop.track, t)) {
                            logger.debug('[RTCPeerConnectionsUnified]: Set local desktop track to null');
                            _localDesktop = {};
                        }
                        _pc.removeTrack(s);
                        return true;
                    }
                    return false;
                });
            });
        };

        this.addStream = function (localStream, receiveVideo, trackConstraints) {
            if (!localStream) {
                return;
            }
            if (_pc.remoteDescription && _pc.remoteDescription.type) {
                // In local answer scenarios, the peer connection already created transceivers after
                // setting the remote description, so just add local tracks
                localStream.getTracks().forEach(function (t) {
                    if (localStream.isScreen) {
                        // This will be added in addTransceivers()
                        _localDesktop.track = t;
                        _localDesktop.stream = localStream;
                    } else {
                        _pc.addTrack(t, localStream);
                    }
                });
            } else {
                // Add audio track first so it will be the first m-line (backwards compatibility)
                localStream.getAudioTracks().forEach(function (t) {
                    _pc.addTransceiver(t, {
                        direction: 'sendrecv'
                    });
                });
                localStream.getVideoTracks().forEach(function (t) {
                    var trackId = WebRTCAdapter.getTrackId(t);
                    var constraints = trackId && trackConstraints[trackId];
                    var trackDirection = (constraints && constraints.direction) || 'sendrecv';
                    var direction = receiveVideo && (!options.onlyRemoteScreenShare || localStream.isScreen) ? trackDirection : 'sendonly';
                    if (localStream.isScreen) {
                        // Store the desktop track but don't add its transceiver yet as we want it
                        // to be the last m-line
                        _localDesktop.track = t;
                        _localDesktop.stream = localStream;
                    } else {
                        _pc.addTransceiver(t, {
                            direction: direction
                        });
                    }
                    logger.debug('[RTCPeerConnectionsUnified]: Added local ' + (localStream.isScreen ? 'screen' : 'video') +
                        ' track=' + t.label + ' direction=' + direction);
                });
            }
        };

        this.replaceTrack = function (oldTrack, newTrack) {
            if (!oldTrack || !newTrack) {
                return Promise.reject('Missing parameter(s)');
            }
            return new Promise(function (resolve, reject) {
                var found = _pc.getSenders().some(function (s) {
                    if (oldTrack === s.track) {
                        var oldLabel = s.track.label;
                        s.replaceTrack(newTrack)
                        .then(function () {
                            logger.debug('[RTCPeerConnectionsUnified]: Successfully replaced ' + oldLabel + ' track with ', newTrack.label);
                            resolve();
                        })
                        .catch(function (err) {
                            logger.error('[RTCPeerConnectionsUnified]: Error replacing ' + oldLabel + ' track with ' + newTrack.label + '. ', err);
                            reject(err);
                        });
                        return true;
                    }
                    return false;
                });
                if (!found) {
                    logger.error('[RTCPeerConnectionsUnified]: Could not find track to be replaced');
                    reject('Could not find track to be replaced');
                }
            });
        };

        this.getLocalStreams = function () {
            var localStreams = [];
            _pc.getSenders().forEach(function (s) {
                if (s.track) {
                    localStreams.push(new WebRTCAdapter.MediaStream([s.track]));
                }
            });
            return localStreams;
        };

        this.getRemoteStreams = function () {
            var streams = [];
            _pc.getTransceivers().forEach(function (t) {
                if (t.receiver && t.receiver.track) {
                    var s = new WebRTCAdapter.MediaStream([t.receiver.track]);
                    s.mid = t.mid;
                    streams.push(s);
                }
            });
            return streams;
        };

        this.addIceCandidate = function (iceCandidate) {
            if (!iceCandidate) {
                _pc.addIceCandidate();
                return;
            }
            _pc.addIceCandidate(new WebRTCAdapter.IceCandidate(iceCandidate));
        };

        this.close = function () {
            _pc.close();
            unregisterEvtHandlers(_pc);
        };

        this.createDTMFSender = function (audioTrack) {
            return _pc.createDTMFSender(audioTrack);
        };

        this.getMediaId = function (track) {
            if (track) {
                var trx = _pc.getTransceivers();
                if (trx.length) {
                    var found = trx.find(function (t) {
                        return t.sender && compareTracks(t.sender.track, track);
                    });
                    if (found) {
                        return found.mid;
                    }
                }
            }
            return null;
        };

        this.getScreenShareMediaId = function () {
            var trx = _pc.getTransceivers();
            if (trx.length) {
                if (_localDesktop.track) {
                    // We have a local desktop track in a RtcRtpSender object, so find which
                    // transceiver this track belongs to and get its mid attribute
                    var found = trx.find(function (t) {
                        if (t.sender && t.sender.track) {
                            return compareTracks(t.sender.track, _localDesktop.track);
                        }
                        return false;
                    });
                    if (found) {
                        return found.mid;
                    } else {
                        logger.warn('[RTCPeerConnectionsUnified]: Could not find desktop sender obj. Last video connection will be selected for screen share');
                    }
                }

                if (options.createDesktopPc) {
                    // Find last video receiver and return its Transceiver's mid attribute
                    for (var i = trx.length - 1; i >= 0; i--) {
                        var trans = trx[i];
                        if (trans.receiver && trans.receiver.track && trans.receiver.track.kind === 'video') {
                            return trans.mid;
                        }
                    }
                }
            }
            return null;
        };

        // We can't bind the 2 methods because _pc is instantiated at runtime
        this.getStats = function () {
            return _pc.getStats();
        };

        this.setMaxBitrate = function (trackConstraints) {
            _pc.getTransceivers().forEach(function (transceiver) {
                var track = transceiver.sender && transceiver.sender.track;
                var trackId = WebRTCAdapter.getTrackId(track);
                var constraints = trackId && trackConstraints[trackId];
                constraints && WebRTCAdapter.setEncodingParameters(transceiver, constraints.maxBitrate, constraints.degradationPreference, constraints.scaleResolutionDownBy);
            });
        };

        this.getAvailableVideoReceivers = function (mediaType) {
            var screenMid = _that.getScreenShareMediaId();
            var videoReceivers = [];
            _pc.getTransceivers().forEach(function (transceiver) {
                var mid = transceiver.mid;
                var receiver = transceiver.receiver;
                if (receiver && isVideo(receiver.track) && isMediaType(mediaType, mid, screenMid)) {
                    videoReceivers.push({id: mid});
                }
            });
            return videoReceivers;
        };

        this.getAvailableVideoSenders = function (trackConstraints) {
            var screenMid = _that.getScreenShareMediaId();
            var videoSenders = [];
            _pc.getTransceivers().forEach(function (transceiver) {
                var track = transceiver.sender && transceiver.sender.track;
                if (isVideo(track) && isMediaType(RealtimeMediaType.VIDEO, transceiver.mid, screenMid)) {
                    var trackId = WebRTCAdapter.getTrackId(track);
                    var constraints = trackId && trackConstraints[trackId];
                    constraints && videoSenders.push({
                        mid: transceiver.mid,
                        quality: constraints.quality,
                        mediaType: RealtimeMediaType.VIDEO
                    });
                }
            });
            logger.info('[RTCPeerConnectionsUnified] Video senders: ', videoSenders);
            return videoSenders;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            localDescription: {
                get: function () {
                    return _pc.localDescription;
                },
                enumerable: true,
                configurable: false
            },
            remoteDescription: {
                get: function () {
                    return _pc.remoteDescription;
                },
                enumerable: true,
                configurable: false
            },
            signalingState: {
                get: function () { return _pc.signalingState; },
                enumerable: true,
                configurable: false
            },
            iceConnectionState: {
                get: function () { return _pc.iceConnectionState; },
                enumerable: true,
                configurable: false
            },
            mainPeerConnection: {
                get: function () { return _pc; },
                enumerable: false,
                configurable: false
            },
            groupPeerConnections: {
                get: function () { return []; },
                enumerable: true,
                configurable: false
            },
            desktopPeerConnection: {
                get: function () { return options.createDesktopPc; },
                enumerable: true,
                configurable: false
            },
            desktopRemoteSsrc: {
                get: function () { return _screenRemoteSsrc; },
                set: function (value) {
                    _screenRemoteSsrc = value || [];
                },
                enumerable: true,
                configurable: false
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialize the RTCPeerConnections instances
        ///////////////////////////////////////////////////////////////////////////////////////
        init();
    }

    // Exports
    circuit.RtcPeerConnectionsUnified = RtcPeerConnectionsUnified;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define

