var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var logger = Circuit.logger;

    // eslint-disable-next-line max-lines-per-function
    function CstaParser() { // NOSONAR

        /////////////////////////////////////////////////////////////////////////////
        // Public interfaces
        /////////////////////////////////////////////////////////////////////////////
        // eslint-disable-next-line complexity
        this.genCstaRequest = function (data) { // NOSONAR
            try {
                switch (data.request) { // NOSONAR
                case 'AcceptCall':
                    return genAcceptCall(data);
                case 'AlternateCall':
                    return genAlternateRequest(data);
                case 'AnswerCall':
                    return genAnswerRequest(data);
                case 'CallBackCallRelated':
                    return genCallBackCallRelated(data);
                case 'CallBackNonCallRelated':
                    return genCallBackNonCallRelated(data);
                case 'CallLogSnapShot':
                    return genCallLogSnapShot(data);
                case 'CancelCallBack':
                    return genCancelCallBack(data);
                case 'ClearConnection':
                    return genClearConnectionRequest(data);
                case 'ConferenceCall':
                    return genConferenceRequest(data);
                case 'ConsultationCall':
                    return genConsultationCall(data);
                case 'DeflectCall':
                    return genDeflectRequest(data);
                case 'GenerateDigits':
                    return genGenerateDigitsRequest(data);
                case 'GenGetConfiguration':
                    return genGetConfiguration();
                case 'GetAgentState':
                    return genGetAgentState(data);
                case 'GetDoNotDisturb':
                    return genGetDoNotDisturb(data);
                case 'GetForwarding':
                    return genGetForwarding(data);
                case 'GetLogicalDeviceInformation':
                    return genGetLogicalDeviceInformation(data);
                case 'GetMessageWaitingIndicator':
                    return genGetMessageWaiting(data);
                case 'GetMicrophoneMute':
                    return genGetMicrophoneMute(data);
                case 'GroupPickupCall':
                    return genGroupPickupCall(data);
                case 'HoldCall':
                    return genHoldRequest(data);
                case 'MakeCall':
                    return genMakeCallRequest(data);
                case 'ReconnectCall':
                    return genReconnectRequest(data);
                case 'RetrieveCall':
                    return genRetrieveRequest(data);
                case 'SetAgentState':
                    return genSetAgentState(data);
                case 'SetBusy':
                    return genSetBusy(data);
                case 'SetDoNotDisturb':
                    return genSetDoNotDisturb(data);
                case 'SetForwarding':
                    return genSetForwarding(data);
                case 'SetMicrophoneMute':
                    return genSetMicrophoneMute(data);
                case 'SingleStepTransferCall':
                    return genSSTRequest(data);
                case 'SnapshotCall':
                    return genSnapshotCall(data);
                case 'SnapshotDevice':
                    return genSnapshotDevice(data);
                case 'TransferCall':
                    return genTransferCall(data);
                }
                logger.error('CSTA: Unsupported CSTA request: ' + data.request);
            } catch (e) {
                logger.error(e);
            }
            return null;
        };

        // eslint-disable-next-line complexity
        this.parseEvent = function (cstaEvt) { // NOSONAR
            try {
                if (!cstaEvt) {
                    logger.error('[CstaParser]: Invalid event');
                    return null;
                }

                var eventTag = Object.keys(cstaEvt)[0];
                switch (eventTag) {
                case 'ABE':
                    return parseAgentBusyEvent(cstaEvt);
                case 'ALOE':
                    return parseAgentLoggedOffEvent(cstaEvt);
                case 'ALOEt':
                    return parseAgentLoggedOnEvent(cstaEvt);
                case 'ANRE':
                    return parseAgentNotReadyEvent(cstaEvt);
                case 'ARE':
                    return parseAgentReadyEvent(cstaEvt);
                case 'AWAC':
                    return parseAgentWorkingAfterCallEvent(cstaEvt);
                case 'BISE':
                    return parseBackInServiceEvent(cstaEvt);
                case 'CBE':
                    return parseCallBackEvent(cstaEvt);
                case 'CCEt':
                    return parseConnectionClearedEvent(cstaEvt);
                case 'CIE':
                    return parseCallInformationEvent(cstaEvt);
                case 'CLE':
                    return parseCallLogEvent(cstaEvt);
                case 'CoE':
                    return parseConferencedEvent(cstaEvt);
                case 'DCCE':
                    return parseDeviceCapsChangedEvent(cstaEvt);
                case 'DE':
                    return parseDeliveredEvent(cstaEvt);
                case 'DEt':
                    return parseDivertedEvent(cstaEvt);
                case 'DGE':
                    return parseDigitsGeneratedEvent(cstaEvt);
                case 'DNDE':
                    return parseDoNotDisturbEvent(cstaEvt);
                case 'EEt':
                    return parseEstablishedEvent(cstaEvt);
                case 'FE':
                    return parseFailedEvent(cstaEvt);
                case 'FEt':
                    return parseForwardingEvent(cstaEvt);
                case 'HE':
                    return parseHeldEvent(cstaEvt);
                case 'MWE':
                    return parseMessageWaitingEvent(cstaEvt);
                case 'OE':
                    return parseOfferedEvent(cstaEvt);
                case 'OOSE':
                    return parseOutOfServiceEvent(cstaEvt);
                case 'QE':
                    return parseQueuedEvent(cstaEvt);
                case 'ReE':
                    return parseRetrievedEvent(cstaEvt);
                case 'SCFE':
                    return parseServiceCompletionFailureEvent(cstaEvt);
                case 'SIE':
                    return parseServiceInitiatedEvent(cstaEvt);
                case 'TE':
                    return parseTransferredEvent(cstaEvt);
                }
                logger.info('[CstaParser]: Unsupported CSTA event: ', eventTag);
            } catch (e) {
                logger.error('[CstaParser]: Error parsing CSTA event: ', e);
            }
            return null;
        };

        // eslint-disable-next-line complexity
        this.parseResponse = function (request, cstaRs) { // NOSONAR
            try {
                if (!request || !cstaRs) {
                    logger.error('[CstaParser]: Invalid response');
                    return null;
                }

                if (cstaRs.CSTA) {
                    cstaRs.CSTAErrorCode = cstaRs.CSTA;
                }

                if (cstaRs.CSTAErrorCode) {
                    logger.debug('[CstaParser]: Received error response for: ', request);
                    return parseErrorCode(cstaRs);
                } else if (cstaRs.ISEC) {
                    logger.debug('[CstaParser]: Received internal service error response for: ', request);
                    return parseInternalServiceErrorCode(cstaRs);
                }

                switch (request) { // NOSONAR
                case 'AcceptCall':
                    return parseAcceptCallResponse(cstaRs);
                case 'AlternateCall':
                    return parseAlternateResponse(cstaRs);
                case 'AnswerCall':
                    return parseAnswerResponse(cstaRs);
                case 'CallBackCallRelated':
                    return parseCallBackResponse(cstaRs);
                case 'CallBackNonCallRelated':
                    return parseCallBackNonCallReleatedResponse(cstaRs);
                case 'CallLogSnapShot':
                    return parseCallLogSnapShotResponse(cstaRs);
                case 'CancelCallBack':
                    return parseCancelCallBackResponse(cstaRs);
                case 'ClearConnection':
                    return parseClearConnectionResponse(cstaRs);
                case 'ConferenceCall':
                    return parseConferenceResponse(cstaRs);
                case 'ConsultationCall':
                    return parseConsultationCallResponse(cstaRs);
                case 'DeflectCall':
                    return parseDeflectResponse(cstaRs);
                case 'GenerateDigits':
                    return parseGenDigitsResponse(cstaRs);
                case 'GetAgentState':
                    return parseGetAgentStateResponse(cstaRs);
                case 'GetDoNotDisturb':
                    return parseGetDoNotDisturbResponse(cstaRs);
                case 'GetForwarding':
                    return parseGetForwardingResponse(cstaRs);
                case 'GetLogicalDeviceInformation':
                    return parseGetLogicalDeviceInformationResponse(cstaRs);
                case 'GetMessageWaitingIndicator':
                    return parseGetMessageWaitingResponse(cstaRs);
                case 'GetMicrophoneMute':
                    return parseGetMicrophoneMuteResponse(cstaRs);
                case 'GroupPickupCall':
                    return parseGroupPickupCallResponse(cstaRs);
                case 'HoldCall':
                    return parseHoldResponse(cstaRs);
                case 'MakeCall':
                    return parseMakeCallResponse(cstaRs);
                case 'ReconnectCall':
                    return parseReconnectResponse(cstaRs);
                case 'RetrieveCall':
                    return parseRetrieveResponse(cstaRs);
                case 'SetAgentState':
                    return parseSetAgentStateResponse(cstaRs);
                case 'SetBusy':
                    return parseSetBusyResponse(cstaRs);
                case 'SetDoNotDisturb':
                    return parseSetDoNotDisturbResponse(cstaRs);
                case 'SetForwarding':
                    return parseSetForwardingResponse(cstaRs);
                case 'SetMicrophoneMute':
                    return parseSetMicrophoneMuteResponse(cstaRs);
                case 'SingleStepTransferCall':
                    return parseSSTResponse(cstaRs);
                case 'SnapshotCall':
                    return parseSnapshotCallResponse(cstaRs);
                case 'SnapshotDevice':
                    return parseSnapshotDeviceResponse(cstaRs);
                case 'TransferCall':
                    return parseTransferCallResponse(cstaRs);
                }
                logger.error('CSTA: Unsupported CSTA response for request: ', request);
            } catch (e) {
                logger.error(e);
            }
            return null;
        };

        /////////////////////////////////////////////////////////////////////////////
        // Internal functions
        /////////////////////////////////////////////////////////////////////////////
        function parseCallData(callData) {
            return {
                connectionId: callData.cIr,
                localCallState: callData.lCS && callData.lCS.cCSe && callData.lCS.cCSe.lCSe
            };
        }

        function parseEndpointData(endpointData) {
            return {
                deviceOnCall: endpointData.dOC.dIr || '',
                callIdentifier: endpointData.cI,
                localConnectionState: endpointData.lCI,
                servicesPermitted: parseServicesPermitted(endpointData)

            };
        }

        function parseErrorCode(rs) {
            if (!rs.CSTAErrorCode) {
                logger.error('[CstaParser]: The given response is not an error code: ', rs);
                return null;
            }

            var category = null;

            var shortTag = Object.keys(rs.CSTAErrorCode)[0];
            switch (shortTag) {
            case 'oprt':
            case 'operation':
                category = 'Operation Error';
                break;
            case 'scrt':
            case 'security':
                category = 'Security Error';
                break;
            case 'sIy':
            case 'stateIncompatibility':
                category = 'State Incompatibility Error';
                break;
            case 'sRAy':
            case 'systemResourceAvailability':
                category = 'System Resource Availability Error';
                break;
            case 'sRA':
            case 'subscribedResourceAvailability':
                category = 'Subscribed Resource Availability Error';
                break;
            case 'pM':
            case 'performanceManagement':
                category = 'Performance Management Error';
                break;
            case 'pDa':
            case 'privateDataInformation':
                category = 'Private Data Information Error';
                break;
            }

            if (!category) {
                logger.error('[CstaParser]: Unrecognized Error Category');
                return { error: true, errorCategory: 'Unknown', errorValue: 'Unknown' };
            }

            return {
                error: true,
                errorCategory: category,
                errorValue: rs.CSTAErrorCode[shortTag]
            };
        }

        function parseInternalServiceErrorCode(rs) {
            if (!rs.ISEC) {
                logger.error('[CstaParser]: The given response is not an internal service error code: ', rs);
                return null;
            }

            var shortTag = Object.keys(rs.ISEC)[0];
            if (shortTag !== 'code') {
                logger.error('[CstaParser]: Unrecognized Internal Service Error Category');
                return { error: true, errorCategory: 'Unknown', errorValue: 'Unknown' };
            }

            return {
                error: true,
                errorCategory: 'Code',
                errorValue: rs.ISEC.code
            };
        }

        function parseEpid(data) {
            var epid = null;
            if (data && data.extn && data.extn.pDa && data.extn.pDa.prvt) {
                epid = data.extn.pDa.prvt.zEpid;
            }
            return epid;
        }

        function normalizeServicesPermitted(sp) {
            if (sp) {
                Object.keys(sp).forEach(function (key) {
                    sp[key] = sp[key] === '' || sp[key] === 'true';
                });
            }
        }

        function parseServicesPermitted(data) {
            if (!data) {
                return {};
            }
            var servicesPermitted = {};
            if (data.sP) {
                servicesPermitted.rSP = data.sP;
                normalizeServicesPermitted(servicesPermitted.rSP.cCSs);
            }
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt && data.extn.pDa.prvt.zxSP !== undefined) {
                servicesPermitted.eSP = data.extn.pDa.prvt.zxSP;
                normalizeServicesPermitted(servicesPermitted.eSP);
            }
            return servicesPermitted;
        }

        function parseCallingDevicePrivate(data) {
            var callingDevicePrivate = null;
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt && data.extn.pDa.prvt.pR1 && data.extn.pDa.prvt.pR1.dIr) {
                callingDevicePrivate = data.extn.pDa.prvt.pR1.dIr;
            }
            return callingDevicePrivate;
        }

        function parseCalledDevicePrivate(data) {
            var calledDevicePrivate = null;
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt && data.extn.pDa.prvt.pR2 && data.extn.pDa.prvt.pR2.dIr) {
                calledDevicePrivate = data.extn.pDa.prvt.pR2.dIr;
            }
            return calledDevicePrivate;
        }

        function parseOtherDevicePrivate(data) {
            var otherDevicePrivate = null;
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt && data.extn.pDa.prvt.pR13 && data.extn.pDa.prvt.pR13.dIr) {
                otherDevicePrivate = data.extn.pDa.prvt.pR13.dIr;
            }
            return otherDevicePrivate;
        }

        // Parse Responses
        function parseAlternateResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Alternate Call response: ', rs);
            return rs.ACRe;
        }

        function parseAnswerResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Answer Call response: ', rs);
            return rs.AnCR;
        }

        function parseAcceptCallResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Accept Call response: ', rs);
            return rs.ACR;
        }

        function parseClearConnectionResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Clear Connection response: ', rs);
            return rs.ClCR;
        }

        function parseDeflectResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Deflect Call response: ', rs);
            return rs.DCRe;
        }

        function parseGenDigitsResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Generate Digits response: ', rs);
            return rs.GDR;
        }

        function parseGetDoNotDisturbResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Get Do Not Disturb response: ', rs);
            var data = rs.GDNDe;
            return {
                doNotDisturbOn: data.dNDO === 'true'
            };
        }

        function parseGetLogicalDeviceInformationResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Get Logical Device Information response: ', rs);
            var data = rs.GLDIe;
            return {
                hasPhysicalDeviceInformation: data.hPE === 'true'
            };
        }

        function parseForwardListItemData(data) {
            return {
                forwardingType: data.fTe,
                forwardStatus: data.fS === 'true',
                forwardDN: data.fDNN
            };
        }

        function parseHuntGroupListEntry(entry) {
            return {
                name: entry.zgNM,
                pilotDn: entry.zgDN,
                available: entry.zMB === 'false'
            };
        }

        function parseHuntGroupList(data) {
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt) {
                var huntGroupList = [];
                if (data.extn.pDa.prvt.zeMBSL) {
                    if (Array.isArray(data.extn.pDa.prvt.zeMBSL.zeMBS)) {
                        data.extn.pDa.prvt.zeMBSL.zeMBS.forEach(function (entry) {
                            huntGroupList.push(parseHuntGroupListEntry(entry));
                        });
                    } else {
                        huntGroupList.push(parseHuntGroupListEntry(data.extn.pDa.prvt.zeMBSL.zeMBS));
                    }
                    return huntGroupList;
                }
            }
            return null;
        }

        function parseGetForwardingResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Get Forwarding response: ', rs);
            // OSV sends the GetForwardingResponse with long tags OSV-3432
            if (rs.GetForwardingResponse) {
                return parseGetForwardingResponseLongTags(rs.GetForwardingResponse);
            }
            var data = rs.GFR;
            var parsedResp = {
                forwardList: []
            };
            if (data.fL.fLI.fS) {
                parsedResp.forwardList.push(parseForwardListItemData(data.fL.fLI));
            } else {
                // Multiple forwarding items
                data.fL.fLI.forEach(function (item) {
                    parsedResp.forwardList.push(parseForwardListItemData(item));
                });
            }
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt) {
                if (data.extn.pDa.prvt.zsOA) {
                    parsedResp.staticOndActive = data.extn.pDa.prvt.zsOA === 'true';
                    parsedResp.staticOndDN = data.extn.pDa.prvt.zsOD;
                }
                parsedResp.voicemailActive = data.extn.pDa.prvt.zvMA === 'true';
                if (data.extn.pDa.prvt.zoP) {
                    parsedResp.overrideProfile = data.extn.pDa.prvt.zoP === 'true';
                }
                if (data.extn.pDa.prvt.zvMRD) {
                    parsedResp.voicemailRingDuration = parseInt(data.extn.pDa.prvt.zvMRD, 10);
                }
                if (data.extn.pDa.prvt.zmRD) {
                    parsedResp.mainRingDuration = parseInt(data.extn.pDa.prvt.zmRD, 10);
                }
                if (data.extn.pDa.prvt.zcRD) {
                    parsedResp.clientRingDuration = parseInt(data.extn.pDa.prvt.zcRD, 10);
                }
                if (data.extn.pDa.prvt.zcpRD) {
                    parsedResp.cellRingDuration = parseInt(data.extn.pDa.prvt.zcpRD, 10);
                }
                if (data.extn.pDa.prvt.hasOwnProperty('zcPN')) {
                    parsedResp.alternativeNumber = data.extn.pDa.prvt.zcPN;
                }
                if (data.extn.pDa.prvt.hasOwnProperty('zrTCN')) {
                    parsedResp.routeToCell = data.extn.pDa.prvt.zrTCN === 'true';
                }
            }
            return parsedResp;
        }

        function parseGetForwardingResponseLongTags(data) {
            var parsedResp = {
                forwardList: []
            };
            if (data.forwardingList.forwardListItem.forwardStatus) {
                data.forwardingList.forwardListItem.forwardStatus = data.forwardingList.forwardListItem.forwardStatus === 'true';
                parsedResp.forwardList.push(data.forwardingList.forwardListItem);
            } else {
                // Multiple forwarding items
                data.forwardingList.forwardListItem.forEach(function (item) {
                    item.forwardStatus = item.forwardStatus === 'true';
                    parsedResp.forwardList.push(item);
                });
            }
            if (data.extensions && data.extensions.privateData && data.extensions.privateData.private) {
                parsedResp.staticOndDN = data.extensions.privateData.private.staticOndDN;
                parsedResp.staticOndActive = data.extensions.privateData.private.staticOndActive === 'true';
            }
            return parsedResp;
        }

        function parseGetMessageWaitingResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Get Message Waiting response: ', rs);
            var data = rs.GMWIe;
            return {
                messageWaitingOn: data.mWO === 'true'
            };
        }

        function parseHoldResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Hold Call response: ', rs);
            return rs.HCR;
        }

        function parseReconnectResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Reconnect Call response: ', rs);
            return rs.RCR;
        }

        function parseRetrieveResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Retrieve Call response: ', rs);
            return rs.RCRe;
        }

        function parseSetBusyResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Set Busy response: ', rs);
            return rs.zSBNe;
        }

        function parseSetDoNotDisturbResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Set Do Not Disturb response: ', rs);
            return rs.SDNDe;
        }

        function parseSetForwardingResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Set Forwarding response: ', rs);
            return rs.hasOwnProperty('SFR') ? rs.SFR : rs.SetForwardingResponse;
        }

        function parseConsultationCallResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Consultation Call response: ', rs);
            return rs.CnCR;
        }

        function parseCallBackResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Call Back call related response: ', rs);
            return rs.CBR;
        }

        function parseCallBackNonCallReleatedResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Call Back non call related response: ', rs);
            return rs.CBNCe;
        }

        function parseCancelCallBackResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Cancel Call Back response: ', rs);
            return rs.CCBR;
        }

        function parseSnapshotCallResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Snapshot Call response: ', rs);
            var data = rs.SCR;
            var parsedResp = {
                endpoints: [],
                callingDevice: (data.caD && data.caD.dIr) || '',
                calledDevice: (data.cDe && data.cDe.dIr) || '',
                servicesPermitted: {}
            };

            var addParsedEp = function (endpointData) {
                var parsedEp = parseEndpointData(endpointData);
                parsedResp.endpoints.push(parsedEp);

                // OSbiz provides only calledDevice parameter
                if (!parsedResp.callingDevice && parsedResp.calledDevice && parsedEp.deviceOnCall !== parsedResp.calledDevice) {
                    parsedResp.callingDevice = parsedEp.deviceOnCall;
                }
            };

            if (data.cRID.sDa.sCRIo) {
                if (data.cRID.sDa.sCRIo.dOC) {
                    // There is only one endpoint in the call
                    addParsedEp(data.cRID.sDa.sCRIo);

                } else {
                    // There are multiple endpoints
                    data.cRID.sDa.sCRIo.forEach(function (endpointData) {
                        addParsedEp(endpointData);
                    });
                }
            }
            parsedResp.servicesPermitted = parseServicesPermitted(data);
            parsedResp.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedResp.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedResp.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            return parsedResp;
        }

        function parseSnapshotDeviceResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Snapshot Device response: ', rs);
            var data = rs.SnDR;
            var parsedResp = {
                activeCalls: []
            };

            function addToActiveCalls(callInfo) {
                var call = parseCallData(callInfo);
                if (call.connectionId && call.connectionId.cID) {
                    parsedResp.activeCalls.push(call);
                }
            }

            if (data.cRID.sDa.sDRI) {
                if (data.cRID.sDa.sDRI.cIr) {
                    // There is only one call
                    addToActiveCalls(data.cRID.sDa.sDRI);
                } else {
                    // There are multiple calls
                    data.cRID.sDa.sDRI.forEach(function (callData) {
                        addToActiveCalls(callData);
                    });
                }
            }
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt) {
                parsedResp.deskPhoneRegistered = data.extn.pDa.prvt.zdP === 'true';
            }
            return parsedResp;
        }

        function parseSSTResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA SST Call response: ', rs);
            var data = rs.SSTCe;
            var parsedResp = {transferredCall: data.trC};
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt) {
                parsedResp.seamlessHandover = data.extn.pDa.prvt.zseHo === 'true';
            }
            return parsedResp;
        }

        function parseConferenceResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Conference Call response: ', rs);
            var data = rs.CoCR;
            return {conferenceCall: data.coC};
        }

        function parseTransferCallResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA TransferCall response: ', rs);
            var data = rs.TCR;
            return {transferredCall: data.trC};
        }

        function parseSetMicrophoneMuteResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA SetMicrophoneMute response', rs);
            var data = rs.SMMR;
            return {mute: data.mML.mMI.mMO === 'true'};
        }

        function parseGetMicrophoneMuteResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA GetMicrophoneMute response', rs);
            var data = rs.GMMR;
            return {mute: data.mML.mMI.mMO === 'true'};
        }

        function parseMakeCallResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA MakeCall response', rs);
            return rs.MCR;
        }

        function parseCallLogSnapShotResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA Call Log Snap Shot response: ', rs);
            return rs.CLSR;
        }

        function parseGetAgentStateResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA GetAgentState response', rs);
            var data = rs.GASR;
            return {
                loggedOnState: data.aSL && data.aSL.aSE && data.aSL.aSE.lOS === 'true',
                agentState: data.aSL && data.aSL.aSE && data.aSL.aSE.aIo && data.aSL.aSE.aIo.aII.aS === 'agentReady' ? 'ready' : 'notReady',
                huntGroupList: parseHuntGroupList(data)
            };
        }

        function parseSetAgentStateResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA SetAgentState response', rs);
            return rs.SASR;
        }

        function parseGroupPickupCallResponse(rs) {
            logger.debug('[CstaParser]: Received CSTA GroupPickupCall response', rs);
            return rs.GPCR;
        }

        // Parse Events
        function parseCallInformationEvent(event) {
            var data = event.CIE;
            var parsedEvent = {
                category: 'CallAssociatedFeature',
                name: 'CallInformationEvent',
                connection: data.cnncn,
                device: data.dvc.dIr || '',
                callingDevice: (data.caD && data.caD.dIr) || '',
                servicesPermitted: {}
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            parsedEvent.pickupNotificationEarly = parsedEvent.device && parsedEvent.device.includes('Pickup Group Number');
            return parsedEvent;
        }

        function parseConferencedEvent(event) {
            var data = event.CoE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'ConferencedEvent',
                primaryOldCall: data.pOC,
                secondaryOldCall: data.sOC,
                conferencingDevice: data.coD.dIr || '',
                addedParty: data.aPy.dIr || '',
                conferenceConnections: [],
                localConnectionInfo: data.lCI,
                cause: data.cs,
                servicesPermitted: {}
            };
            data.cnC.cLI.forEach(function (item) {
                var connListItem = {
                    connection: item.nCn,
                    endpoint: item.endp && (item.endp.dID || item.endp.endp || item.endp)
                };
                parsedEvent.conferenceConnections.push(connListItem);
            });
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            return parsedEvent;
        }

        function parseConnectionClearedEvent(event) {
            var data = event.CCEt;
            var parsedEvent = {
                category: 'CallControl',
                name: 'ConnectionClearedEvent',
                droppedConnection: data.drC,
                releasingDevice: data.rD.dIr || '',
                localConnectionInfo: data.lCI,
                cause: data.cs,
                servicesPermitted: {}
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            parsedEvent.pickupNotificationTerminated = parsedEvent.releasingDevice && parsedEvent.releasingDevice.includes('Pickup Group Number');
            return parsedEvent;
        }

        function parseDeliveredEvent(event) {
            var data = event.DE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'DeliveredEvent',
                connection: data.cnncn,
                alertingDevice: data.aDe.dIr || '',
                callingDevice: data.caD.dIr || '',
                networkCallingDevice: (data.ntCD && data.ntCD.dIr) || '',
                calledDevice: data.cDe.dIr || '',
                localConnectionInfo: data.lCI,
                cause: data.cs
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            var presentationRestrictedDevice13 = parseOtherDevicePrivate(data);
            if (presentationRestrictedDevice13) {
                parsedEvent.presentationRestrictedDevice13 = presentationRestrictedDevice13;
            }

            return parsedEvent;
        }

        function parseDigitsGeneratedEvent(event) {
            var data = event.DGE;
            return {
                category: 'CallAssociatedFeature',
                name: 'DigitsGeneratedEvent',
                connection: data.cnncn,
                digitGeneratedList: data.dGL,
                keysToOmitFromLogging: ['digitGeneratedList']
            };
        }

        function parseDivertedEvent(event) {
            var data = event.DEt;
            var parsedEvent = {
                category: 'CallControl',
                name: 'DivertedEvent',
                connection: data.cnncn,
                calledDevice: (data.cDe && data.cDe.dIr) || '',
                divertingDevice: data.dvD.dIr || '',
                newDestination: data.nD.dIr || '',
                callingDevice: (data.caD && data.caD.dIr) || '',
                lastRedirectionDevice: (data.lRD && data.lRD.nDd) || '',
                localConnectionInfo: data.lCI,
                cause: data.cs
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            return parsedEvent;
        }

        function parseDoNotDisturbEvent(event) {
            var data = event.DNDE;
            return {
                category: 'LogicalDeviceFeature',
                name: 'DoNotDisturbEvent',
                device: data.dvc.dIr || '',
                doNotDisturbOn: data.dNDO === 'true'
            };
        }

        function parseEstablishedEvent(event) {
            var data = event.EEt;
            var parsedEvent = {
                category: 'CallControl',
                name: 'EstablishedEvent',
                establishedConnection: data.eCn,
                answeringDevice: data.anD.dIr || '',
                callingDevice: data.caD.dIr || '',
                networkCallingDevice: (data.ntCD && data.ntCD.dIr) || '',
                calledDevice: data.cDe.dIr || '',
                lastRedirectionDevice: (data.lRD && data.lRD.nDd) || '',
                localConnectionInfo: data.lCI,
                cause: data.cs,
                servicesPermitted: {}
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            var presentationRestrictedDevice13 = parseOtherDevicePrivate(data);
            if (presentationRestrictedDevice13) {
                parsedEvent.presentationRestrictedDevice13 = presentationRestrictedDevice13;
            }

            return parsedEvent;
        }

        function parseFailedEvent(event) {
            var data = event.FE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'FailedEvent',
                failedConnection: data.fC,
                failingDevice: data.fD.dIr || '',
                callingDevice: data.caD.dIr || '',
                localConnectionInfo: data.lCI,
                calledDevice: data.cDe.dIr,
                cause: data.cs,
                servicesPermitted: {}
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            return parsedEvent;
        }

        function parseForwardingEvent(event) {
            var data = event.FEt;
            var parsedEvent = {
                category: 'LogicalDeviceFeature',
                name: 'ForwardingEvent',
                device: data.dvc.dIr || '',
                forwardingType: data.fTe,
                forwardStatus: data.fS === 'true',
                forwardTo: data.fTo
            };
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt) {
                if (data.extn.pDa.prvt.zsOA) {
                    parsedEvent.staticOndActive = data.extn.pDa.prvt.zsOA === 'true';
                    parsedEvent.staticOndDN = data.extn.pDa.prvt.zsOD;
                }
                if (data.extn.pDa.prvt.zvMA) {
                    parsedEvent.voicemailActive = data.extn.pDa.prvt.zvMA === 'true';
                }
                if (data.extn.pDa.prvt.zoP) {
                    parsedEvent.overrideProfile = data.extn.pDa.prvt.zoP === 'true';
                }
                if (data.extn.pDa.prvt.zvMRD) {
                    parsedEvent.voicemailRingDuration = parseInt(data.extn.pDa.prvt.zvMRD, 10);
                }
                if (data.extn.pDa.prvt.zmRD) {
                    parsedEvent.mainRingDuration = parseInt(data.extn.pDa.prvt.zmRD, 10);
                }
                if (data.extn.pDa.prvt.zcRD) {
                    parsedEvent.clientRingDuration = parseInt(data.extn.pDa.prvt.zcRD, 10);
                }
                if (data.extn.pDa.prvt.zcpRD) {
                    parsedEvent.cellRingDuration = parseInt(data.extn.pDa.prvt.zcpRD, 10);
                }
                if (data.extn.pDa.prvt.hasOwnProperty('zcPN')) {
                    parsedEvent.alternativeNumber = data.extn.pDa.prvt.zcPN;
                }
                if (data.extn.pDa.prvt.hasOwnProperty('zrTCN')) {
                    parsedEvent.routeToCell = data.extn.pDa.prvt.zrTCN === 'true';
                }
                if (data.extn.pDa.prvt.hasOwnProperty('zfCC')) {
                    parsedEvent.forwardingCapsChanged = data.extn.pDa.prvt.zfCC === 'true';
                }
            }
            return parsedEvent;
        }

        function parseHeldEvent(event) {
            var data = event.HE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'HeldEvent',
                heldConnection: data.hCn,
                holdingDevice: data.hD.dIr || '',
                localConnectionInfo: data.lCI,
                servicesPermitted: {}
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);
            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }
            return parsedEvent;
        }

        function parseMessageWaitingEvent(event) {
            var data = event.MWE;
            return {
                category: 'PhysicalDeviceFeature',
                name: 'MessageWaitingEvent',
                device: data.tD.dIr || '',
                messageWaitingOn: data.mWO === 'true'
            };
        }

        function parseOfferedEvent(event) {
            var data = event.OE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'OfferedEvent',
                offeredConnection: data.oCn,
                offeredDevice: data.oDe.dIr || '',
                callingDevice: data.caD.dIr || '',
                calledDevice: data.cDe.dIr || '',
                localConnectionInfo: data.lCI,
                lastRedirectionDevice: (data.lRD && data.lRD.nDd) || '',
                cause: data.cs
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            return parsedEvent;
        }

        function parseQueuedEvent(event) {
            var data = event.QE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'QueuedEvent',
                queuedConnection: data.qC,
                queue: data.q.dIr || '',
                callingDevice: data.caD.dIr || '',
                localConnectionInfo: data.lCI,
                cause: data.cs
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            return parsedEvent;
        }

        function parseRetrievedEvent(event) {
            var data = event.ReE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'RetrievedEvent',
                retrievedConnection: data.rCn,
                retrievingDevice: data.reD.dIr || '',
                localConnectionInfo: data.lCI,
                servicesPermitted: {}
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);
            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }
            return parsedEvent;
        }

        function parseServiceCompletionFailureEvent(event) {
            var data = event.SCFE;
            var parsedEvent = {
                category: 'CallAssociatedFeature',
                name: 'ServiceCompletionFailureEvent',
                primaryCall: {
                    connectionID: data.prC.cIDD,
                    localConnectionState: data.prC.lCSe
                },
                cause: data.cs
            };
            if (data.sC) {
                parsedEvent.secondaryCall = {
                    connectionID: data.sC.cIDD,
                    localConnectionState: data.sC.lCSe
                };
            }
            return parsedEvent;
        }

        function parseServiceInitiatedEvent(event) {
            var data = event.SIE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'ServiceInitiatedEvent',
                initiatedConnection: data.iCn,
                initiatingDevice: data.iD.dIr || '',
                cause: data.cs
            };
            parsedEvent.servicesPermitted = parseServicesPermitted(data);
            parsedEvent.epid = parseEpid(data);
            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }
            return parsedEvent;
        }

        function parseCallBackEvent(event) {
            var data = event.CBE;
            return {
                category: 'LogicalDeviceFeature',
                name: 'CallBackEvent',
                originatingDevice: data.orD.dIr,
                targetDevice: data.tD.dIr,
                callBackSetCanceled: data.cBSC
            };
        }

        function parseTransferredEvent(event) {
            var data = event.TE;
            var parsedEvent = {
                category: 'CallControl',
                name: 'TransferredEvent',
                primaryOldCall: data.pOC,
                secondaryOldCall: data.sOC,
                transferringDevice: data.tDe.dIr || '',
                transferredToDevice: data.tTDe.dIr || '',
                transferredConnections: [],
                localConnectionInfo: data.lCI,
                cause: data.cs,
                servicesPermitted: {}
            };
            var connections = data.tCs.cLI;
            if (!Array.isArray(connections)) {
                connections = [connections];
            }
            parsedEvent.transferredConnections = connections.map(function (item) {
                return {
                    connection: item.nCn,
                    endpoint: item.endp && (item.endp.dID || item.endp.endp || item.endp)
                };
            });
            parsedEvent.servicesPermitted = parseServicesPermitted(data);

            var presentationRestrictedDevice1 = parseCallingDevicePrivate(data);
            if (presentationRestrictedDevice1) {
                parsedEvent.presentationRestrictedDevice1 = presentationRestrictedDevice1;
            }

            var presentationRestrictedDevice2 = parseCalledDevicePrivate(data);
            if (presentationRestrictedDevice2) {
                parsedEvent.presentationRestrictedDevice2 = presentationRestrictedDevice2;
            }

            parsedEvent.epid = parseEpid(data);
            return parsedEvent;
        }

        function parseCallLogEntryData(data) {
            return {
                recordId: data.rID,
                callID: data.cID,
                recordType: data.rTp,
                createdTime: data.cTm,
                result: data.res,
                callDuration: data.cDr,
                callingDevice: data.clDv || '',
                calledDevice: data.cldDv || '',
                answeringDevice: data.aDv || ''
            };
        }

        function parseCallLogEvent(event) {
            var data = event.CLE;
            var parsedEvent = {
                category: 'LogicalDeviceFeature',
                name: 'CallLogEvent',
                callLogList: []
            };
            if (data.cLL && data.cLL.cLE) {
                if (data.cLL.cLE.rID) {
                    //Only one call log entry in the callLog event
                    parsedEvent.callLogList.push(parseCallLogEntryData(data.cLL.cLE));
                } else {
                    //There are Multiple entries in the CallLog Event
                    data.cLL.cLE.forEach(function (entry) {
                        parsedEvent.callLogList.push(parseCallLogEntryData(entry));
                    });
                }
            }
            return parsedEvent;
        }

        function parseBackInServiceEvent(event) {
            var data = event.BISE;
            return {
                category: 'DeviceMaintenance',
                name: 'BackInServiceEvent',
                backInService: true,
                device: data.dvc.dIr || ''
            };
        }

        function parseOutOfServiceEvent(event) {
            var data = event.OOSE;
            return {
                category: 'DeviceMaintenance',
                name: 'OutOfServiceEvent',
                outOfService: true,
                device: data.dvc.dIr || ''
            };
        }

        function parseAgentReadyEvent(data) {
            return {
                category: 'LogicalDeviceFeature',
                name: 'AgentReadyEvent',
                agentReady: true,
                huntGroupList: parseHuntGroupList(data.ARE),
                agentDevice: data.ARE && data.ARE.aD && data.ARE.aD.dIr || '',
                agentGroup: data.ARE && data.ARE.aG || ''
            };
        }

        function parseAgentNotReadyEvent(data) {
            return {
                category: 'LogicalDeviceFeature',
                name: 'AgentNotReadyEvent',
                agentReady: false,
                huntGroupList: parseHuntGroupList(data.ANRE),
                agentDevice: data.ANRE && data.ANRE.aD && data.ANRE.aD.dIr || '',
                agentGroup: data.ANRE && data.ANRE.aG || ''
            };
        }

        function parseAgentBusyEvent(data) {
            return {
                category: 'LogicalDeviceFeature',
                name: 'AgentBusyEvent',
                agentReady: false,
                huntGroupList: parseHuntGroupList(data.ABE)
            };
        }

        function parseAgentWorkingAfterCallEvent(data) {
            return {
                category: 'LogicalDeviceFeature',
                name: 'AgentWorkingAfterCallEvent',
                agentReady: false,
                huntGroupList: parseHuntGroupList(data.AWAC)
            };
        }

        function parseDeviceCapsChangedEvent(event) {
            var data = event.DCCE;
            var parsedEvent = {
                category: 'DeviceMaintenance'
            };
            if (data.extn && data.extn.pDa && data.extn.pDa.prvt && data.extn.pDa.prvt.zdP) {
                parsedEvent.name = data.extn.pDa.prvt.zdP === 'true' ? 'BackInServiceEvent' : 'OutOfServiceEvent';
            }
            return parsedEvent;
        }

        function parseAgentLoggedOffEvent(data) {
            return {
                category: 'LogicalDeviceFeature',
                name: 'AgentLoggedOffEvent',
                huntGroupList: parseHuntGroupList(data.ALOE)
            };
        }

        function parseAgentLoggedOnEvent(data) {
            return {
                category: 'LogicalDeviceFeature',
                name: 'AgentLoggedOnEvent',
                huntGroupList: parseHuntGroupList(data.ALOEt)
            };
        }

        // Generate Requests
        function genAlternateRequest(data) {
            return {
                ACl: {
                    hC: {
                        cID: data.heldCall.cID,
                        dID: data.heldCall.dID
                    },
                    atC: {
                        cID: data.activeCall.cID,
                        dID: data.activeCall.dID
                    }
                }
            };
        }

        function genConferenceRequest(data) {
            return {
                CoC: {
                    hC: {
                        cID: data.heldCall.cID,
                        dID: data.heldCall.dID
                    },
                    atC: {
                        cID: data.activeCall.cID,
                        dID: data.activeCall.dID
                    }
                }
            };
        }

        function genAnswerRequest(data) {
            return {
                AnC: {
                    cTBAd: {
                        cID: data.callToBeAnswered.cID,
                        dID: data.callToBeAnswered.dID
                    }
                }
            };
        }

        function genAcceptCall(data) {
            return {
                AC: {
                    cTBA: {
                        cID: data.callToBeAccepted.cID,
                        dID: data.callToBeAccepted.dID
                    }
                }
            };
        }

        function genGroupPickupCall(data) {
            return {
                GPC: {
                    nD: data.newDestination
                }
            };
        }

        function genMakeCallRequest(data) {
            return {
                MC: {
                    caD: data.callingDevice,
                    cDN: data.calledDirectoryNumber,
                    aOe: data.autoOriginate
                }
            };
        }

        function genClearConnectionRequest(data) {
            return {
                CCn: {
                    coTBC: {
                        cID: data.connectionToBeCleared.cID,
                        dID: data.connectionToBeCleared.dID
                    },
                    rsn: data.reason
                }
            };
        }

        function genDeflectRequest(data) {
            var shortTagJson = {
                cTBD: {
                    cID: data.callToBeDiverted.cID,
                    dID: data.callToBeDiverted.dID
                },
                nD: data.newDestination
            };
            if (data.autoAnswer) {
                shortTagJson.extn = {
                    pDa: {
                        prvt: {
                            zdT: 'Auto-Answer'
                        }
                    }
                };
            }
            return {
                DCl: shortTagJson
            };
        }

        function genGenerateDigitsRequest(data) {
            return {
                GD: {
                    cTSD: {
                        cID: data.connectionToSendDigits.cID,
                        dID: data.connectionToSendDigits.dID
                    },
                    cTS: data.charactersToSend
                }
            };
        }

        function genGetDoNotDisturb(data) {
            return {
                GDND: {
                    dvc: data.device
                }
            };
        }

        function genGetForwarding(data) {
            return {
                GF: {
                    dvc: data.device
                }
            };
        }

        function genGetMessageWaiting(data) {
            return {
                GMWI: {
                    dvc: data.device
                }
            };
        }

        function genHoldRequest(data) {
            var shortTagJson = {
                cTBH: {
                    cID: data.callToBeHeld.cID,
                    dID: data.callToBeHeld.dID
                }
            };

            if (data.holdOptions) {
                shortTagJson.extn = {
                    zhOs: data.holdOptions
                };
            }

            return {
                HC: shortTagJson
            };
        }

        function genReconnectRequest(data) {
            return {
                RC: {
                    hC: {
                        cID: data.heldCall.cID,
                        dID: data.heldCall.dID
                    },
                    atC: {
                        cID: data.activeCall.cID || undefined,
                        dID: data.activeCall.dID || undefined
                    }
                }
            };
        }

        function genRetrieveRequest(data) {
            return {
                RCl: {
                    cTBRd: {
                        cID: data.callToBeRetrieved.cID,
                        dID: data.callToBeRetrieved.dID
                    }
                }
            };
        }

        function genSetBusy(data) {
            return {
                zSBN: {
                    dvc: data.device,
                    zbO: data.busyState
                }
            };
        }

        function genSetDoNotDisturb(data) {
            return {
                SDND: {
                    dvc: data.device,
                    dNDO: data.doNotDisturbOn
                }
            };
        }

        function genSetForwarding(data) {
            var shortTagJson = {
                SF: {
                    dvc: data.device,
                    aF: data.activateForward,
                    fDNN: data.forwardDN,
                    fTe: data.forwardingType,
                    riC: data.ringCount
                }
            };
            var prvt = {};
            if (data.staticOndActive !== undefined) {
                prvt = {
                    zsOA: data.staticOndActive === true ? 'true' : 'false',
                    zsOD: data.staticOndDN || ''
                };
            } else if (data.voicemailActive !== undefined || data.voicemailRingDuration !== undefined) {
                prvt = {
                    zvMA: typeof data.voicemailActive === 'boolean' ? data.voicemailActive.toString() : undefined,
                    zvMRD: data.voicemailRingDuration ? data.voicemailRingDuration.toString() : undefined
                };
            } else {
                if (data.mainRingDuration !== undefined) {
                    prvt.zmRD = data.mainRingDuration.toString() || '';
                }
                if (data.clientRingDuration !== undefined) {
                    prvt.zcRD = data.clientRingDuration.toString() || '';
                }
                if (data.cellRingDuration !== undefined) {
                    prvt.zcpRD = data.cellRingDuration.toString() || '';
                }
                if (data.alternativeNumber !== undefined) {
                    prvt.zcPN = data.alternativeNumber;
                }
                if (data.routeToCell !== undefined) {
                    prvt.zrTCN = data.routeToCell ? 'true' : 'false';
                }
            }
            if (Object.keys(prvt).length > 0) {
                shortTagJson.SF.extn = {
                    pDa: {
                        prvt: prvt
                    }
                };
            }
            return shortTagJson;
        }

        function genSetMicrophoneMute(data) {
            return {
                SMM: {
                    dvc: data.device,
                    mMO: data.mute
                }
            };
        }

        function genGetMicrophoneMute(data) {
            return {
                GMM: {
                    dvc: data.device
                }
            };
        }

        function genSnapshotCall(data) {
            return {
                SC: {
                    sO: {
                        cID: data.snapshotObject.cID,
                        dID: data.snapshotObject.dID
                    }
                }
            };
        }

        function genSnapshotDevice(data) {
            var shortTagJson = {
                sO: data.snapshotObject
            };
            if (data.osmo) {
                shortTagJson.extn = {
                    pDa: {
                        prvt: {
                            zxsm: data.osmo
                        }
                    }
                };
            }
            return {
                SDe: shortTagJson
            };
        }

        function genGetLogicalDeviceInformation(data) {
            var shortTagJson = {
                dvc: data.device
            };
            return {
                GLDI: shortTagJson
            };
        }

        function genSSTRequest(data) {
            var shortTagJson = {
                atC: {
                    cID: data.activeCall.cID,
                    dID: data.activeCall.dID
                },
                tTo: data.transferredTo
            };
            if (data.autoAnswer || data.seamlessHandover) {
                var prvt = {};
                if (data.autoAnswer) {
                    prvt.ztTgt = 'Auto-Answer';
                }
                if (data.seamlessHandover) {
                    prvt.zseHo = 'true';
                }
                shortTagJson.extn = {
                    pDa: {
                        prvt: prvt
                    }
                };
            }
            return {
                SSTC: shortTagJson
            };
        }

        function genConsultationCall(data) {
            return {
                CnC: {
                    cnD: data.consultedDevice || undefined,
                    eCl: {
                        cID: data.existingCall.cID,
                        dID: data.existingCall.dID
                    }
                }
            };
        }

        function genCallBackCallRelated(data) {
            return {
                CB: {
                    cC: {
                        cID: data.callBackConnection.cID,
                        dID: data.callBackConnection.dID
                    }
                }
            };
        }

        function genCallBackNonCallRelated(data) {
            return {
                CBNC: {
                    orD: data.orD,
                    tD: data.tD
                }
            };
        }

        function genCancelCallBack(data) {
            return {
                CCB: {
                    orD: data.orD,
                    tD: data.tD
                }
            };
        }

        function genTransferCall(data) {
            return {
                TC: {
                    hC: {
                        cID: data.heldCall.cID,
                        dID: data.heldCall.dID
                    },
                    atC: {
                        cID: data.activeCall.cID,
                        dID: data.activeCall.dID
                    }
                }
            };
        }

        function genGetConfiguration() {
            return {
                zGCD: {
                    zcCA: 'Internet',
                    zdMO: 'Chrome',
                    zdOS: 'WebRTC'
                }
            };
        }

        function genCallLogSnapShot(data) {
            return {
                CLS: {
                    sub: data.device
                }
            };
        }

        function genGetAgentState(data) {
            var shortTagJson = {
                dvc: data.device
            };

            if (data.group) {
                shortTagJson.aG = data.group;
            }

            return {
                GAS: shortTagJson
            };
        }

        function genSetAgentState(data) {
            var shortTagJson = {
                dvc: data.device,
                rAS: data.state
            };

            if (data.group && data.localUser) {
                if (data.localUser.isOsBizCTIEnabled) {
                    shortTagJson.grp = data.group;
                } else if (data.localUser.isOSV) {
                    shortTagJson.extn = {
                        pDa: {
                            prvt: {
                                zhG: data.group
                            }
                        }
                    };
                }
            }

            return {
                SAS: shortTagJson
            };
        }
    }

    // Exports
    circuit.CstaParser = CstaParser;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
