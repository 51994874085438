
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var logger = circuit.logger;
    var BaseCall = circuit.BaseCall;
    var CallState = circuit.Enums.CallState;
    var Constants = circuit.Constants;
    var CstaCallState = circuit.Enums.CstaCallState;
    var IncomingVideoContentType = circuit.Constants.IncomingVideoContentType;
    var ParticipantState = circuit.Enums.ParticipantState;
    var RealtimeMediaType = circuit.Constants.RealtimeMediaType;
    var Utils = circuit.Utils;

    var SERVER_ENDED_PRFX = 'SERVER_ENDED:';
    var CallServerTerminatedReason = Object.freeze({
        SERVER_ENDED_PRFX: SERVER_ENDED_PRFX
    });

    var CLIENT_ENDED_PRFX = 'CLIENT_ENDED:';
    var CallClientTerminatedReason = Object.freeze({
        CLIENT_ENDED_PRFX: CLIENT_ENDED_PRFX,
        ANOTHER_CLIENT_ANSWERED: 'ANOTHER_CLIENT_ANSWERED',
        ANOTHER_CLIENT_REJECTED: 'ANOTHER_CLIENT_REJECTED',
        ANOTHER_CLIENT_PULLED_CALL: 'ANOTHER_CLIENT_PULLED_CALL',
        CALL_MOVED_TO_ANOTHER_CONV: 'CALL_MOVED_TO_ANOTHER_CONV',
        CALLER_LEFT_CONFERENCE: 'CALLER_LEFT_CONFERENCE',
        ENDED_BY_ANOTHER_USER: 'ENDED_BY_ANOTHER_USER',
        LOST_WEBSOCKET_CONNECTION: 'LOST_WEBSOCKET_CONNECTION',
        NO_USERS_LEFT: 'NO_USERS_LEFT',
        REQUEST_TO_SERVER_FAILED: 'REQUEST_TO_SERVER_FAILED',
        RTC_SESSION_START_FAILED: 'RTC_SESSION_START_FAILED',
        SET_REMOTE_SDP_FAILED: 'SET_REMOTE_SDP_FAILED',
        USER_ENDED: 'USER_ENDED',
        LOST_MEDIA_STREAM: 'LOST_MEDIA_STREAM',
        ICE_TIMED_OUT: 'ICE_TIMED_OUT',
        USER_LOGGED_OUT: 'USER_LOGGED_OUT',
        PAGE_UNLOADED: 'PAGE_UNLOADED',
        DISCONNECTED: 'WS_DISCONNECTED',
        FAILED_TO_SEND: 'WS_FAILED_TO_SEND',
        REQUEST_TIMEOUT: 'WS_REQUEST_TIMEOUT',
        MEDIA_RENEGOTIATION: 'MEDIA_RENEGOTIATION'
    });

    var _isMobile = Utils.isMobile();

    // eslint-disable-next-line max-lines-per-function
    function LocalCall(conversation, options) { // NOSONAR

        // The following imports need to be defined inside LocalCall due to JS-SDK
        var WebRTCAdapter = circuit.WebRTCAdapter;

        options = options || {};

        // Import here due to Circular dependency
        var RtcSessionController = circuit.RtcSessionController;

        if (!conversation || !conversation.rtcSessionId) {
            throw new Error('Cannot create LocalCall object without a valid conversation');
        }

        // Call the base constructor
        LocalCall.parent.constructor.call(this, conversation, false);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        //
        // These variables can only be accessed via getters/setters.
        // Critical variables that cannot be changed directly must be defined here.
        ///////////////////////////////////////////////////////////////////////////////////////
        var _that = this;
        var _sessionCtrl = null;
        var _hasTerminated = false;
        var _ringingTimer = null;
        var _hasActiveRemoteVideo = false;
        var _hasActiveRemoteScreenShareOnly = false;
        var _remoteVideoDisabled = false;
        var _remoteVideoScreenOnlyAllowed = false;
        var _remoteAudioDisabled = false;
        var _hasUnmutedParticipants = false;
        var _isMocked = false;
        var _isMeetingPointInvited = false;
        var _playbackDevice = null;
        var _recordingDevice = null;
        var _videoDevice = null;
        var _atcAdvancing = false;
        var _playbackDeviceList = [];
        var _recordingDeviceList = [];
        var _videoDeviceList = [];

        // The blob URLs for local video and remote audio/video (these are being deprecated)
        var _localVideoUrl = '';
        var _remoteAudioUrl = '';

        // Remember the current view for the call.
        // true: call stage is collapsed and user is viewing the conversation feed or details
        // false: call stage is expanded and user is in the conference object view
        var _conversationFeedView = false;

        var _videoReceiverConfiguration = {};
        var _networkQuality = null;

        var DEFAULT_RINGING_TIME = 15000;
        var RING_ALL_MAX_NUM_OF_PEERS = 9;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function createSessionController() {
            if (_sessionCtrl) {
                return;
            }
            if (options.reuseSessionCtrl) {
                _sessionCtrl = options.reuseSessionCtrl;
                return;
            }
            _sessionCtrl = new RtcSessionController({
                callId: _that.callId,
                isDirectCall: _that.isDirect,
                isTelephonyCall: _that.isTelephonyCall,
                isAtcPullCall: options.isAtcPullCall,
                reuseDesktopStreamFrom: options.reuseDesktopStreamFrom,
                midMappingEnabled: options.midMappingEnabled,
                canReceiveHdVideo: options.canReceiveHdVideo,
                supportsVideoReceiverConfiguration: _that.supportsVideoReceiverConfiguration()
            });
            _sessionCtrl.onMediaUpdate = onMediaUpdate;
            _sessionCtrl.onLocalVideoStream = onLocalVideoStream;
            _sessionCtrl.onRemoteStreams = onRemoteStreams;
            _sessionCtrl.onScreenSharePointerStatus = onScreenSharePointerStatus;
        }

        function unregisterSessionController() {
            if (_sessionCtrl) {
                _sessionCtrl.onMediaUpdate = null;
                _sessionCtrl.onLocalVideo = null;
                _sessionCtrl.onRemoteStreams = null;
                _sessionCtrl.onScreenSharePointerStatus = null;
            }
        }

        function onMediaUpdate() {
            if (!_sessionCtrl) {
                logger.debug('[LocalCall]: session controller not available - onMediaUpdate: callId =', _that.callId);
                return;
            }

            logger.debug('[LocalCall]: RtcSessionController - onMediaUpdate: callId =', _that.callId);

            _that.activeMediaType = _sessionCtrl.getActiveMediaType();
            logger.debug('[LocalCall]: Set active media type to ', _that.activeMediaType);

            _that.localMediaType = _sessionCtrl.getMediaConstraints();
            logger.debug('[LocalCall]: Set local media type to ', _that.localMediaType);

            _that.updateMediaType();
            _that.updateCallState();

            setVideoReceivers(RealtimeMediaType.VIDEO);
            setVideoReceivers(RealtimeMediaType.DESKTOP_SHARING);
        }

        function onLocalVideoStream(event) {
            logger.debug('[LocalCall]: RtcSessionController - onLocalVideoStream: callId =', _that.callId);
            _that.localVideoStream = event.stream;
            _that.localStreams.desktop = event.desktopStream;
            _that.localStreams.video = event.videoStream;
            _localVideoUrl = '';
            logger.debug('[LocalCall]: Set local video stream to ', (event.videoStream && event.videoStream.id) || '<null>');
            logger.debug('[LocalCall]: Set local desktop stream to ', (event.desktopStream && event.desktopStream.id) || '<null>');
        }

        function onScreenSharePointerStatus(data) {
            logger.debug('[LocalCall]: RtcSessionController - onScreenSharePointerStatus', data);
            _that.pointer = {
                isSupported: data.isSupported,
                isEnabled: data.isEnabled
            };
        }

        function onRemoteStreams(event) {
            logger.debug('[LocalCall]: RtcSessionController - onRemoteStreams: callId =', _that.callId);

            _that.remoteAudioStream = (event.audio && event.audio.stream) || null;
            _remoteAudioUrl = '';
            logger.debug('[LocalCall]: Set remote audio stream to', (_that.remoteAudioStream && _that.remoteAudioStream.id) || '<empty>');

            _that.remoteVideoStreams = event.video || [];
            logger.debug('[LocalCall]: Set remote video streams to', event.video || '<empty>');

            // Set the remote video stream for all participants
            _that.participants.forEach(function (p) {
                _that.setParticipantRemoteVideoStream(p);
            });
            _that.checkForActiveRemoteVideo();
        }

        function setVideoReceivers(mediaType) {
            var videoReceivers = _sessionCtrl.getAvailableVideoReceivers(mediaType);
            if (videoReceivers) {
                var updatedConfigurations = [];
                var currentConfigurations = _videoReceiverConfiguration[mediaType] || [];
                videoReceivers.forEach(function (elem) {
                    var videoReceiver = currentConfigurations.find(function (cfg) {
                        return cfg.id === elem.id;
                    });
                    if (!videoReceiver) {
                        // create default
                        videoReceiver = elem;
                        videoReceiver.pinnedUser = null;
                        videoReceiver.quality = Constants.VideoQuality.NORMAL;
                        videoReceiver.disabled = false;
                    }
                    updatedConfigurations.push(videoReceiver);
                });
                _videoReceiverConfiguration[mediaType] = updatedConfigurations;
                logger.debug('[LocalCall] Updated video receiver configuration for media type ' + mediaType + ' is ', updatedConfigurations);
            }
        }

        /**
         * Return the video receiver configuration with current incoming video setting
         * (incoming VIDEO disabled or VIDEO and SCREEN disabled).
         *
         * @returns {Array} The modified list of receiver configurations
         */
        function getReceiverConfiguration() {
            var result = [];
            Object.keys(_videoReceiverConfiguration).forEach(function (mediaType) {
                var configuration = _videoReceiverConfiguration[mediaType];
                if (configuration) {
                    if (_remoteVideoDisabled || (mediaType === RealtimeMediaType.VIDEO && _remoteVideoScreenOnlyAllowed)) {
                        // Aadapt disabled setting based on remote video setting without changing actual configuration
                        configuration = JSON.parse(JSON.stringify(configuration)); // Deep copy
                        configuration.forEach(function (conf) {
                            conf.disabled = true;
                        });
                    }
                    Array.prototype.push.apply(result, configuration);
                }
            });
            return result;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            sessionCtrl: {
                get: function () { return _sessionCtrl; },
                enumerable: true,
                configurable: false
            },
            locallyMuted: {
                get: function () { return !!(_sessionCtrl && _sessionCtrl.isMuted()); },
                enumerable: true,
                configurable: false
            },
            remoteVideoDisabled: {
                get: function () { return _remoteVideoDisabled; },
                enumerable: true,
                configurable: false
            },
            remoteVideoScreenOnlyAllowed: {
                get: function () { return _remoteVideoScreenOnlyAllowed; },
                enumerable: true,
                configurable: false
            },
            remoteAudioDisabled: {
                get: function () { return _remoteAudioDisabled; },
                enumerable: true,
                configurable: false
            },
            isMocked: {
                get: function () { return _isMocked; },
                enumerable: true,
                configurable: false
            },
            isMeetingPointInvited: {
                get: function () { return _isMeetingPointInvited; },
                enumerable: true,
                configurable: false
            },
            hasActiveRemoteScreenShareOnly: {
                get: function () { return _hasActiveRemoteScreenShareOnly; },
                enumerable: true,
                configurable: false
            },
            ringAllAllowed: {
                get: function () {
                    // This property only verifies the number of users in the conversation, not the call state
                    return !!(this.peerUsers && this.peerUsers.length > 0 && this.peerUsers.length <= RING_ALL_MAX_NUM_OF_PEERS);
                },
                enumerable: true,
                configurable: false
            },
            ringAllMaxNumber: {
                get: function () { return RING_ALL_MAX_NUM_OF_PEERS; },
                enumerable: true,
                configurable: false
            },
            conversationFeedView: {
                get: function () { return _conversationFeedView || this.state === CallState.Ringing; },
                set: function (value) { _conversationFeedView = value; },
                enumerable: true,
                configurable: false
            },
            playbackDevice: {
                get: function () { return _playbackDevice; },
                enumerable: true,
                configurable: false
            },
            recordingDevice: {
                get: function () { return _recordingDevice; },
                enumerable: true,
                configurable: false
            },
            videoDevice: {
                get: function () { return _videoDevice; },
                enumerable: true,
                configurable: false
            },
            playbackDeviceList: {
                get: function () { return _playbackDeviceList; },
                enumerable: true,
                configurable: false
            },
            recordingDeviceList: {
                get: function () { return _recordingDeviceList; },
                enumerable: true,
                configurable: false
            },
            videoDeviceList: {
                get: function () { return _videoDeviceList; },
                enumerable: true,
                configurable: false
            },
            atcAdvancing: {
                get: function () { return _atcAdvancing; },
                set: function (value) { _atcAdvancing = !!value; },
                enumerable: true,
                configurable: false
            },
            savedRTPStats: {
                get: function () {
                    if (this.sessionCtrl) {
                        var stats = this.sessionCtrl.getLastSavedStats();
                        if (stats) {
                            // stats is an object with each ssrc stats as a property
                            // Here we are organizing the ssrc's into audio and video groups:
                            // {
                            //    audio: { ssrc1: {...}, ssrc2: {...}},
                            //    video: { ssrc3: {...}, ssrc4: {...}, bw: {...}}
                            // }
                            var organizedStats = {};
                            Object.keys(stats).forEach(function (ssrcId) {
                                var ssrc = stats[ssrcId];
                                var media = ssrc.media; // audio or video
                                if (media) {
                                    if (!organizedStats[media]) {
                                        organizedStats[media] = {};
                                    }
                                    organizedStats[media][ssrcId] = ssrc;
                                }
                            });
                            return organizedStats;
                        }
                        return stats;
                    }
                    return null;
                },
                enumerable: true,
                configurable: false
            },
            isDtmfAllowed: {
                get: function () {
                    if (this.checkState([CallState.Active, CallState.Delivered]) || this.isOsBizFirstCall) {
                        if (this.sessionCtrl && !this.sessionCtrl.canSendDTMFDigits()) {
                            if (this.checkState(CallState.Active)) {
                                return !!this.atcCallInfo; // For ATC calls we can use CSTA to generate digits (only in Active state)
                            }
                            return false;
                        }
                        return true;
                    }
                    return false;
                },
                enumerable: true,
                configurable: false
            },
            networkQuality: {
                get: function () {
                    return _networkQuality;
                },
                set: function (value) {
                    _networkQuality = value;
                },
                enumerable: true,
                configurable: false
            }
        });

        if (_isMobile) {
            // The following properties have been deprecated for Web/DA and must only be defined for mobile clients
            Object.defineProperties(this, {
                localVideoUrl: {
                    get: function () {
                        if (_that.localVideoStream) {
                            _localVideoUrl = _localVideoUrl || WebRTCAdapter.createObjectURL(_that.localVideoStream);
                        } else {
                            _localVideoUrl = '';
                        }
                        return _localVideoUrl;
                    },
                    enumerable: true,
                    configurable: false
                },
                remoteAudioUrl: {
                    get: function () {
                        if (_that.remoteAudioStream) {
                            _remoteAudioUrl = _remoteAudioUrl || WebRTCAdapter.createObjectURL(_that.remoteAudioStream);
                        } else {
                            _remoteAudioUrl = '';
                        }
                        return _remoteAudioUrl;
                    },
                    enumerable: true,
                    configurable: false
                },
                remoteVideoUrlStreams: {
                    get: function () {
                        return _that.remoteVideoStreams.map(function (stream) {
                            if (!stream.url) {
                                stream.url = WebRTCAdapter.createObjectURL(stream.stream);
                            }
                            return {
                                streamId: stream.streamId,
                                url: stream.url
                            };
                        });
                    },
                    enumerable: true,
                    configurable: false
                }
            });
        }

        // We should work with stream objects directly (as opposed to their URLs)
        this.localVideoStream = null;
        this.localStreams = {};
        this.remoteAudioStream = null;
        this.remoteVideoStreams = [];

        // Stores the media types negotiated at the RTC level.
        this.activeMediaType = {audio: false, video: false, desktop: false};
        this.ringingTimeout = DEFAULT_RINGING_TIME;

        // Is whiteboard enabled for the call
        this.whiteboardEnabled = false;
        // Object that contains whiteboard data
        this.whiteboard = null;

        // Display feature selected for CMR
        this.screenEnabledFeature = null;

        // Is conference poll enabled for the call
        this.pollEnabled = false;
        // Object that contains conference poll data
        this.poll = null;

        // Is screenshare pointing supported and enabled
        this.pointer = {
            isSupported: false,
            isEnabled: false
        };

        // Bad quality notification bar on call stage
        this.badQualityNotification = {
            detected: false,
            acted: false,
            dismissed: false
        };

        // Current client ID associated to this call
        this.clientId = options.clientId;

        // In case of OsBiz local call consultation or campON, these flags indicate the first and seconds calls
        this.isOsBizFirstCall = false;
        this.isOsBizSecondCall = false;

        this.holdInProgress = false;
        this.retrieveInProgress = false;

        this.isSTC = options.isSTC;
        this.stcCapabilities = options.stcCapabilities;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Members
        // (Only includes members which need to access private data)
        ///////////////////////////////////////////////////////////////////////////////////////
        this.terminate = function () {
            if (_hasTerminated) {
                return;
            }

            logger.debug('[LocalCall]: Terminating call with callId =', this.callId);
            if (this.isOsBizSecondCall) {
                _sessionCtrl = null;
            } else if (_sessionCtrl) {
                unregisterSessionController();
                _sessionCtrl.terminate();
            }

            _localVideoUrl = '';
            _remoteAudioUrl = '';
            this.localVideoStream = null;
            this.localStreams = {};
            this.remoteAudioStream = null;
            this.remoteVideoStreams = [];

            // Call the prototype's terminate() function
            LocalCall.prototype.terminate.call(this);

            this.stopRingingTimer();
            _hasTerminated = true;
        };

        this.setMockedCall = function () {
            logger.debug('[LocalCall]: Setting call as mocked');
            _isMocked = true;
            _sessionCtrl && _sessionCtrl.setMockedCall();

            this.participants.forEach(function (p) {
                p.streamId = p.mediaType.video ? 'mock' : '';
                p.screenStreamId = p.mediaType.desktop ? 'mock' : '';
            });

            // Initialize the remote stream after a 100 ms timeout to simulate
            // the delay in the real scenarios.
            window.setTimeout(function () {
                if (this.state !== CallState.Terminated) {
                    logger.debug('[LocalCall]: Initialize remote mocked streams');

                    onRemoteStreams({
                        audio: null,
                        video: [{
                            streamId: 'mock',
                            stream: 'content/images/mock/trailer.mp4'
                        }]
                    });
                    if (_sessionCtrl) {
                        _sessionCtrl.onRemoteStreams = null;
                    }
                }
            }, 100);
        };

        this.startRingingTimer = function (cb) {
            if (typeof cb !== 'function') {
                return;
            }

            logger.info('[LocalCall]: Starting ringing timer for callId =', _that.callId);
            _ringingTimer = window.setTimeout(function () {
                logger.info('[LocalCall]: Ringing timeout for callId =', _that.callId);
                _ringingTimer = null;
                cb();
            }, this.ringingTimeout);
        };

        this.stopRingingTimer = function () {
            if (_ringingTimer) {
                logger.info('[LocalCall]: Cancelling ringing timer for callId =', _that.callId);
                window.clearTimeout(_ringingTimer);
                _ringingTimer = null;
            }
        };

        // Override hasRemoteVideo to check if we have a valid remote video stream for at
        // least one participant
        this.hasRemoteVideo = function () {
            return _hasActiveRemoteVideo;
        };

        this.checkForActiveRemoteVideo = function () {
            if (_remoteVideoDisabled) {
                _hasActiveRemoteVideo = false;
                _hasActiveRemoteScreenShareOnly = false;
            } else {
                _hasActiveRemoteVideo = this.participants.some(function (p) {
                    return !!p.videoStream;
                });
                if (_hasActiveRemoteVideo) {
                    // Incoming video stream is active. Check if it is screen share only
                    _hasActiveRemoteScreenShareOnly = !this.participants.some(function (p) {
                        return p.mediaType.video;
                    });
                } else {
                    _hasActiveRemoteScreenShareOnly = false;
                }
            }
            logger.debug('[LocalCall]: Set hasActiveRemoteVideo to ', _hasActiveRemoteVideo);
        };

        this.enableRemoteVideoScreenOnly = function () {
            if (_remoteVideoDisabled) {
                return null;
            }
            _remoteVideoScreenOnlyAllowed = true;
            this.checkForActiveRemoteVideo();

            this.participants.forEach(function (p) {
                p.videoStream = null;
                p.streams = {};
                if (_that.isDirect) {
                    p.streamId = '';
                }
            });
            this.sessionCtrl && this.sessionCtrl.enableRemoteVideoScreenOnly();
            logger.debug('[LocalCall]: Enabled receiving remote screen share only');
            return getReceiverConfiguration();
        };

        this.disableRemoteVideoScreenOnly = function () {
            _remoteVideoScreenOnlyAllowed = false;
            this.sessionCtrl && this.sessionCtrl.disableRemoteVideoScreenOnly();
            logger.debug('[LocalCall]: Disabled receiving remote screen share only');
            return getReceiverConfiguration();
        };

        this.enableRemoteVideo = function () {
            if (!_remoteVideoDisabled && !_remoteVideoScreenOnlyAllowed) {
                return null;
            }
            _remoteVideoDisabled = false;
            this.disableRemoteVideoScreenOnly();
            this.checkForActiveRemoteVideo();
            this.sessionCtrl && this.sessionCtrl.enableRemoteVideo();
            logger.debug('[LocalCall]: Enabled remote video');
            return getReceiverConfiguration();
        };

        this.disableRemoteVideo = function () {
            if (_remoteVideoDisabled) {
                return null;
            }

            _remoteVideoDisabled = true;
            _hasActiveRemoteVideo = false;
            this.disableRemoteVideoScreenOnly();

            this.participants.forEach(function (p) {
                p.videoStream = null;
                p.streams = {};
                if (_that.isDirect) {
                    p.streamId = '';
                }
            });
            this.sessionCtrl && this.sessionCtrl.disableRemoteVideo();
            logger.debug('[LocalCall]: Disabled remote video');
            return getReceiverConfiguration();
        };

        this.enableRemoteAudio = function () {
            if (!_remoteAudioDisabled) {
                return;
            }
            _remoteAudioDisabled = false;
            logger.debug('[LocalCall]: Enabled remote audio.');
        };

        this.disableRemoteAudio = function () {
            if (_remoteAudioDisabled) {
                return;
            }
            _remoteAudioDisabled = true;
            logger.debug('[LocalCall]: Disabled remote audio.');
        };

        this.setMeetingPointInviteState = function (inviteState) {
            _isMeetingPointInvited = !!inviteState;
            logger.debug('[LocalCall]: Set Circuit Meeting Point state to ', inviteState);
        };

        this.setHasUnmutedParticipants = function () {
            _hasUnmutedParticipants = this.participants.some(function (p) {
                return !p.muted;
            });
        };

        this.hasUnmutedParticipants = function () {
            return _hasUnmutedParticipants;
        };

        this.getUnmutedParticipantIds = function () {
            var unmutedParticipants = [];
            this.participants.forEach(function (p) {
                if (!p.muted) {
                    unmutedParticipants.push(p.userId);
                }
            });
            return unmutedParticipants;
        };

        this.getMediaNode = function () {
            return this.isTestCall ? RtcSessionController.mediaNode : null;
        };

        this.updateMediaDevices = function () {

            var getDeviceLabels = function (devices) {
                if (!devices) {
                    return [];
                }
                return devices.filter(function (d) {
                    return d.id !== 'default' && d.id !== 'communications' && d.label;
                }).map(function (d) {
                    return d.label;
                });
            };

            WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                if (WebRTCAdapter.getMediaSourcesSupported) {
                    var dev = Utils.selectMediaDevice(audioOutputDevices, RtcSessionController.playbackDevices);
                    _playbackDevice = dev && dev.label;
                    _playbackDeviceList = getDeviceLabels(audioOutputDevices);
                    dev = Utils.selectMediaDevice(audioSources, RtcSessionController.recordingDevices);
                    _recordingDevice = dev && dev.label;
                    _recordingDeviceList = getDeviceLabels(audioSources);
                    dev = Utils.selectMediaDevice(videoSources, RtcSessionController.videoDevices);
                    _videoDevice = dev && dev.label;
                    _videoDeviceList = getDeviceLabels(videoSources);
                } else {
                    // Mobile devices don't support getMediaSources, so get these devices through Audio object
                    _playbackDevice = Audio.getPlaybackDevice && Audio.getPlaybackDevice();
                    _recordingDevice = Audio.getRecordingDevice && Audio.getRecordingDevice();
                    _videoDevice = Audio.getVideoDevice && Audio.getVideoDevice();
                }
                logger.debug('[LocalCall]: Updated media devices ', {
                    callId: _that.callId,
                    recording: _recordingDevice,
                    playback: _playbackDevice,
                    video: _videoDevice
                });
            });
        };

        this.setIncomingVideoStreams = function (configuration) {
            var videoConfiguration = _videoReceiverConfiguration[RealtimeMediaType.VIDEO];
            var screenConfiguration = _videoReceiverConfiguration[RealtimeMediaType.DESKTOP_SHARING];
            var preferredStreamIds = [];
            var videoContentArray = [];
            var showScreen = false;
            var disableVideoLines = true;
            if (!configuration) {
                // reset view so we just apply default configuration but with disabled flag to false
                disableVideoLines = false;
                showScreen = true;
            } else {
                configuration.forEach(function (c) {
                    switch (c.contentType) {
                    case IncomingVideoContentType.VIDEO:
                        var userId = c.userId;
                        var participant = userId && _that.participants.find(function (p) {
                            return p.userId === userId;
                        });
                        var preferredId;
                        if (participant && participant.streams.video) {
                            // If participant video is already received keep participant on same video line to avoid flickering
                            preferredId = participant.streams.video.mid;
                            preferredStreamIds.push(preferredId);
                        }
                        videoContentArray.push({
                            id: preferredId,
                            quality: c.quality || Constants.VideoQuality.NORMAL,
                            pinnedUser: userId || null
                        });
                        break;
                    case IncomingVideoContentType.SCREEN:
                        showScreen = true;
                        break;
                    }
                });
            }

            // reset all video lines
            if (videoConfiguration) {
                videoConfiguration.forEach(function (vc) {
                    vc.pinnedUser = null;
                    vc.quality = Constants.VideoQuality.NORMAL;
                    vc.disabled = disableVideoLines;
                });

                // apply updated configurations
                videoContentArray.forEach(function (videoContent) {
                    var id = videoContent.id;
                    videoConfiguration.some(function (vc) {
                        var vcId = vc.id;
                        if ((id && vcId === id) || (!id && preferredStreamIds.indexOf(vcId) === -1)) {
                            vc.pinnedUser = videoContent.pinnedUser;
                            vc.quality = videoContent.quality;
                            vc.disabled = false;
                            if (!id) {
                                preferredStreamIds.push(vcId);
                            }
                            return true;
                        }
                        return false;
                    });
                });
            }

            // enable/disable screen share line
            screenConfiguration && screenConfiguration.forEach(function (sc) {
                sc.quality = Constants.VideoQuality.NORMAL;
                sc.disabled = !showScreen;
            });

            return getReceiverConfiguration();
        };

        /**
         * Probe if video receiver configuration can be used.
         *
         * @returns {boolean} true if video receiver configuration can be used
         */
        this.supportsVideoReceiverConfiguration = function () {
            // disable video receiver API for direct calls and guests in Events
            return !_that.isDirect;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        createSessionController();
        _that.updateMediaDevices();

        logger.info('[LocalCall]: Created new WebRTC call with callID = ', this.callId);
    }

    Utils.inherit(LocalCall, BaseCall);

    function setMockStreamId(call, participant) {
        participant.streamId = '';
        participant.screenStreamId = '';
        if (participant.mediaType.desktop) {
            participant.screenStreamId = 'mock';
        }
        if (participant.mediaType.video) {
            participant.streamId = 'mock';
        }
    }

    LocalCall.prototype.hasRemoteMedia = function () {
        return !!(this.remoteAudioStream || this.remoteVideoStreams.length);
    };

    LocalCall.prototype.hasRemoteScreenShare = function () {
        return this.mediaType.desktop && !this.remoteVideoDisabled &&
            (!this.localMediaType.desktop || (this.isDirect && this.participants[0].mediaType.desktop));
    };

    LocalCall.prototype.addParticipant = function (participant) {
        if (this.isMocked && participant && participant.mediaType) {
            setMockStreamId(this, participant);
        }
        var addedParticipant = LocalCall.parent.addParticipant.apply(this, arguments);
        if (addedParticipant) {
            this.setParticipantRemoteVideoStream(addedParticipant);
            this.checkForActiveRemoteVideo();
            this.setHasUnmutedParticipants();
        }
        return addedParticipant;
    };

    LocalCall.prototype.updateParticipant = function (participant) {
        if (this.isMocked && participant && participant.mediaType) {
            setMockStreamId(this, participant);
        }
        var updatedParticipant = LocalCall.parent.updateParticipant.apply(this, arguments);
        if (updatedParticipant) {
            this.setParticipantRemoteVideoStream(updatedParticipant);
            this.checkForActiveRemoteVideo();
            this.setHasUnmutedParticipants();
        }
        return updatedParticipant;
    };

    LocalCall.prototype.removeParticipant = function () {
        var removedParticipant = LocalCall.parent.removeParticipant.apply(this, arguments);
        if (removedParticipant) {
            this.setHasUnmutedParticipants();
            this.checkForActiveRemoteVideo();
        }
        return removedParticipant;
    };

    LocalCall.prototype.setParticipantRemoteVideoStream = function (participant) {
        participant.videoStream = null;
        participant.streams = {};

        if (this.isDirect) {
            participant.streamId = '';
        }

        if (this.remoteVideoDisabled) {
            return;
        }

        if (participant.mediaType && !participant.mediaType.video && !participant.mediaType.desktop) {
            logger.debug('[LocalCall]: The participant does not have video or screen-share. Do not set video stream. userId = ', participant.userId);
            return;
        }

        if (this.isDirect) {
            if (this.remoteVideoStreams && this.remoteVideoStreams.length) {
                var remoteStream = this.remoteVideoStreams[this.remoteVideoStreams.length - 1];
                participant.streamId = remoteStream.streamId;
                participant.videoStream = remoteStream.stream;
            }
        } else {
            if (!participant.streamId && !participant.screenStreamId) {
                logger.info('[LocalCall]: The participant does not have a video streamId. userId = ', participant.userId);
                return;
            }

            if (this.remoteVideoStreams && this.remoteVideoStreams.length) {
                this.remoteVideoStreams.forEach(function (u) {
                    if (u.streamId === participant.streamId) {
                        participant.streams.video = u.stream;
                    } else if (u.streamId === participant.screenStreamId) {
                        participant.streams.desktop = u.stream;
                    }
                });
                participant.videoStream = participant.streams.desktop || participant.streams.video || null;
            }
        }

        if (!participant.videoStream) {
            logger.debug('[LocalCall]: We still do not have a video stream for the participant. ', participant);
        } else {
            // If the participant has a remote video stream, it must be Active
            participant.pcState = participant.muted ? ParticipantState.Muted : ParticipantState.Active;
            logger.debug('[LocalCall]: Set remote video stream for participant: ', participant);
        }
    };

    LocalCall.prototype.hasLocalScreenShare = function () {
        return this.sessionCtrl && this.sessionCtrl.hasScreenShare();
    };

    LocalCall.prototype.isMuted = function () {
        return this.remotelyMuted || this.locallyMuted;
    };

    LocalCall.prototype.mute = function (cb) {
        if (this.checkState(CallState.Terminated)) {
            logger.error('[LocalCall]: Cannot mute a call that has already been terminated');
            return false;
        }

        logger.debug('[LocalCall]: Mute call with callId =', this.callId);
        return this.sessionCtrl && this.sessionCtrl.mute(cb);
    };

    LocalCall.prototype.unmute = function (cb) {
        if (this.checkState(CallState.Terminated)) {
            logger.error('[LocalCall]: Cannot mute a call that has already been terminated');
            return false;
        }

        logger.debug('[LocalCall]: Unmute call with callId =', this.callId);
        return this.sessionCtrl && this.sessionCtrl.unmute(cb);
    };

    LocalCall.prototype.isLocalMuteAllowed = function () {
        return this.sessionCtrl && this.sessionCtrl.isLocalMuteAllowed();
    };

    LocalCall.prototype.toggleMute = function () {
        if (this.sessionCtrl && this.sessionCtrl.isMuted()) {
            return this.unmute();
        }
        return this.mute();
    };

    LocalCall.prototype.setActiveSpeakers = function (activeSpeakers) {
        if (!activeSpeakers) {
            return false;
        }
        if (!(activeSpeakers instanceof Array)) {
            activeSpeakers = [activeSpeakers];
        }
        var changed = false;
        this.participants.forEach(function (p) {
            var active = activeSpeakers.indexOf(p.userId) !== -1;
            if (p.activeSpeaker !== active) {
                p.activeSpeaker = active;
                changed = true;
            }
        });
        return changed;
    };

    LocalCall.prototype.updateCallState = function () {
        if (!this.state.established || !this.sessionCtrl) {
            // The call has not yet been established or sessionCtrl is not available
            return;
        }

        var newState;
        if (!this.sessionCtrl.isConnected() ||                                  // Media is not connected
            (!this.isDirect && !this.hasOtherParticipants()) ||                 // Group conference with no other participants
            (this.isATCCall && !this.getCstaState().established &&              // ATC call not established
            !this.checkCstaState([CstaCallState.Initiated, CstaCallState.Delivered]))) {
            newState = CallState.Waiting;
        } else if (this.sessionCtrl.isHolding() || this.checkCstaState(CstaCallState.Holding)) {
            newState = CallState.Holding;
        } else if (this.sessionCtrl.isHeld()) {
            newState = CallState.Held;
        } else {
            newState = CallState.Active;
        }
        this.setState(newState);
    };

    LocalCall.prototype.isHolding = function () {
        if (this.atcCallInfo) {
            return LocalCall.parent.isHolding.apply(this, arguments);
        }
        return this.checkState([CallState.Holding, CallState.HoldOnHold]);
    };

    LocalCall.prototype.isConsultAllowed = function () {
        if (this.atcCallInfo) {
            return this.atcCallInfo.isConsultAllowed();
        }
        return this.state.established;
    };

    LocalCall.prototype.isHoldInProgress = function () {
        if (this.atcCallInfo) {
            return this.atcCallInfo.isHoldInProgress();
        }
        return this.holdInProgress;
    };

    LocalCall.prototype.setHoldInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.setHoldInProgress();
        }
        this.holdInProgress = true;
    };

    LocalCall.prototype.clearHoldInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.clearHoldInProgress();
        }
        this.holdInProgress = false;
    };

    LocalCall.prototype.isRetrieveInProgress = function () {
        if (this.atcCallInfo) {
            return this.atcCallInfo.isRetrieveInProgress();
        }
        return this.retrieveInProgress;
    };

    LocalCall.prototype.setRetrieveInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.setRetrieveInProgress();
        }
        this.retrieveInProgress = true;
    };

    LocalCall.prototype.clearRetrieveInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.clearRetrieveInProgress();
        }
        this.retrieveInProgress = false;
    };

    LocalCall.prototype.isTransferCallAllowed = function () {
        if (this.atcCallInfo) {
            return this.atcCallInfo.isTransferCallAllowed();
        }
        return !!this.stcCapabilities && this.stcCapabilities.includes(Constants.StcCapabilities.STC_TRANSFER) &&
            this.checkState([CallState.Active, CallState.Delivered]);
    };

    LocalCall.prototype.isTransferAllowed = function () {
        if (this.atcCallInfo) {
            return this.atcCallInfo.isTransferAllowed();
        }
        return !!this.stcCapabilities && this.stcCapabilities.includes(Constants.StcCapabilities.STC_TRANSFER) &&
            this.state.established && !this.isDirectUpgradedToConf;
    };

    LocalCall.prototype.isConferenceCallAllowed = function () {
        if (this.atcCallInfo) {
            return this.atcCallInfo.isConferenceCallAllowed();
        }
        return !!this.stcCapabilities && this.stcCapabilities.includes(Constants.StcCapabilities.STC_MERGE) &&
            this.state.established;
    };


    // Exports
    circuit.LocalCall = LocalCall;
    circuit.Enums.CallServerTerminatedReason = CallServerTerminatedReason;
    circuit.Enums.CallClientTerminatedReason = CallClientTerminatedReason;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
