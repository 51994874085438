
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var Proto = circuit.Proto;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-lines-per-function
    function ClientApiHandler(config) { // NOSONAR
        var logger = circuit.logger;

        ///////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////
        var SP155_API_VERSION = 2090228; // 2.9.228-x

        var NOP = function () {};

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _connHandler = new circuit.ConnectionHandler(config);
        var _developmentMode = false; // Assume production mode by default
        var _lastError;
        var _hasLastRequestBeenSanitized = false;
        var _clientApiVersion = 0;
        var _clientId;
        var _userId;
        var _connectedBackend; // Used for guest connections

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function sendRequest(contentType, content, cb, keysToOmitFromRequest, keysToOmitFromResponse, tenantContext, respTimeout) {
            var reqMsg = Proto.Request(contentType, content, tenantContext);
            // Check if _developmentMode is ON to omit password for Request and Response
            if (_developmentMode) {
                logger.info('Development Mode is ON');
                keysToOmitFromResponse = null;
            } else if (keysToOmitFromRequest) {
                reqMsg.keysToOmitFromLogging = keysToOmitFromRequest;
            }

            _connHandler.sendMessage(reqMsg, function (err, respMsg) {
                try {
                    cb(err, respMsg);
                } catch (ex) {
                    logger.error('[ClientApiHandler]: Exception: ', ex);
                }
            }, keysToOmitFromResponse || null, respTimeout);

            return reqMsg.request.requestId;
        }

        function setError(code, errObj) {
            if (!Circuit.Error) {
                return;
            }
            _lastError = new Circuit.Error(code);
            if (!errObj) {
                return;
            }
            _lastError.errObj = errObj;
            _lastError.message = errObj.info;
        }

        function isResponseValid(err, rsp, cb, treatNoResultAsEmptyList) {
            _lastError = null;
            _hasLastRequestBeenSanitized = false;

            if (err) {
                setError(err);
                cb(err);
                return false;
            }

            if (rsp.sanitization) {
                // Server's antisamy stripped content.
                _hasLastRequestBeenSanitized = true;
                logger.info('[ClientApiHandler]: Sanitization stripped content: ', rsp.sanitization && rsp.sanitization.sanitizedContent);
            }

            if (rsp.code === Constants.ReturnCode.NO_RESULT) {
                if (treatNoResultAsEmptyList) {
                    cb(null, []);
                } else {
                    setError(Constants.ReturnCode.NO_RESULT);
                    cb(Constants.ReturnCode.NO_RESULT);
                }
                return false;
            }

            var res = rsp.code;
            var errObj;

            switch (rsp.code) {
            case Constants.ReturnCode.OK:
            case Constants.ReturnCode.ENTITY_ALREADY_EXISTS:
                return true;

            case Constants.ReturnCode.SERVICE_EXCEPTION:
                if (rsp.errorInfo && rsp.errorInfo.errorCode) {
                    res = rsp.errorInfo.errorCode;
                    if (res === Constants.ErrorCode.THIRDPARTY_ERROR && rsp.errorInfo.thirdpartyError) {
                        res = rsp.errorInfo.thirdpartyError.type;
                    } else if (res === Constants.ErrorCode.CIRCUIT_EXCEPTION && rsp.errorInfo.circuitException) {
                        res = rsp.errorInfo.circuitException;
                    }
                    errObj = rsp.errorInfo;
                }
                break;
            }

            res = res || Constants.ReturnCode.UNEXPECTED_RESPONSE;
            setError(res, errObj);
            cb(res);
            return false;
        }

        function sendAsyncResp(cb, error, data) {
            error && setError(error);
            if (cb && cb !== NOP) {
                window.setTimeout(function () {
                    cb(error, data);
                }, 0);
            }
        }

        function getBooleanValue(value) {
            return typeof value === 'boolean' ? value : undefined;
        }

        function getArrayValue(array) {
            if (!array) {
                return undefined;
            }
            if (!Array.isArray(array)) {
                return [array];
            }
            return array.length ? array : undefined;
        }

        function convertMediaType(mediaType) {
            var rtMediaType = [];
            if (mediaType) {
                if (mediaType.audio) {
                    rtMediaType.push(Constants.RealtimeMediaType.AUDIO);
                }
                if (mediaType.video) {
                    rtMediaType.push(Constants.RealtimeMediaType.VIDEO);
                }
                if (mediaType.desktop) {
                    rtMediaType.push(Constants.RealtimeMediaType.DESKTOP_SHARING);
                }
            } else {
                // Assume audio by default
                rtMediaType.push(Constants.RealtimeMediaType.AUDIO);
            }
            return rtMediaType;
        }

        function convertRecordingMediaType(mediaType) {
            var recMediaType = [];
            if (mediaType) {
                if (mediaType.audio) {
                    recMediaType.push(Constants.RecordingMediaType.AUDIO);
                }
                if (mediaType.video) {
                    recMediaType.push(Constants.RecordingMediaType.VIDEO);
                }
                if (mediaType.text) {
                    recMediaType.push(Constants.RecordingMediaType.TEXT);
                }
            } else {
                // Assume audio by default
                recMediaType.push(Constants.RealtimeMediaType.AUDIO);
            }
            return recMediaType;
        }

        function getAttachmentData(a) {
            // Return only applicable fields for Attachment object in conversation_action.proto
            return a && {
                fileId: a.fileId,
                fileName: a.fileName,
                mimeType: a.mimeType,
                size: a.size,
                itemId: a.itemId
            };
        }

        function getAttachmentsData(attachments) {
            return attachments && attachments.map(getAttachmentData);
        }

        function createJoinData(data) {
            return {
                convId: data.convId,
                rtcSessionId: data.rtcSessionId,
                ownerId: data.ownerId,
                sdp: data.sdp,
                mediaType: convertMediaType(data.mediaType),
                callOut: !!data.callOut,
                handover: !!data.handover,
                sendInviteCancel: !!data.sendInviteCancel,
                peerUserId: data.peerUserId || undefined,  // Direct Call Prototype - Not used by Client API
                replaces: data.replaces,
                from: data.fromDn || data.fromName ? {phoneNumber: data.fromDn, displayName: data.fromName, resolvedUserId: data.fromUserId} : undefined,
                to: data.dialedDn ? {phoneNumber: data.dialedDn, displayName: data.toName || undefined, resolvedUserId: data.toUserId || undefined} : undefined,
                isTelephonyConversation: !!data.isTelephonyConversation,
                displayName: data.displayName,
                transactionId: data.transactionId,
                screenSharePointerSupported: data.screenSharePointerSupported,
                asyncSupported: true,
                noCallLog: data.noCallLog || undefined,
                guestToken: data.guestToken || undefined,
                rtcSupportedFeatures: data.rtcSupportedFeatures || undefined,
                pickUpSession: data.pickUpSession || undefined
            };
        }

        function getApiVersion(cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getApiVersion...');

            var request = {
                type: Constants.VersionActionType.GET_VERSION
            };
            sendRequest(Constants.ContentType.VERSION, request, function (err, rsp) {
                if (!err && rsp.code === Constants.ReturnCode.OK) {
                    var apiVersion = rsp.version.getVersion.version;

                    // Save the Client API version that we are connected to
                    logger.info('[ClientApiHandler]: Setting client API version to ', apiVersion);
                    _clientApiVersion = Utils.convertVersionToNumber(apiVersion);
                } else {
                    // Something went wrong here
                    // The version will be initialized in the GET_STUFF response.
                    _clientApiVersion = 0;
                }
                cb();
            });
        }

        function getStuff(types, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getStuff...');

            // Set all if request is empty
            if (!types || !types.length) {
                types = Object.keys(Constants.GetStuffType);
            }

            var request = {
                type: Constants.UserActionType.GET_STUFF,
                getStuff: {
                    types: types
                }
            };

            var keysToOmitFromResponse = [
                'response.user.getStuff.accounts.[].telephonyConfiguration.onsSipAuthenticationHash',
                'response.user.getStuff.accounts.[].telephonyConfiguration.ondSipAuthenticationHash',
                'response.user.getStuff.pendingSystemNotifications.[].systemNotificationEvent.broadcastText',
                'response.user.getStuff.recentSearches',
                'response.user.getStuff.recentSpaceSearches',
                'response.user.getStuff.userPresenceState.longitude',
                'response.user.getStuff.userPresenceState.latitude',
                'response.user.getStuff.userPresenceState.locationText',
                'response.user.getStuff.userPresenceState.statusMessage'
            ];

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    var stuff = rsp.user.getStuff;
                    if (types.includes(Constants.GetStuffType.USER)) {
                        stuff.user.userPresenceState = stuff.userPresenceState;
                        stuff.user.accounts = stuff.accounts || [];

                        // Update with API version returned in GET_STUFF
                        _clientApiVersion = Utils.convertVersionToNumber(stuff.user.apiVersion);

                        // Save the connected clientId and userId
                        _clientId = stuff.user.clientId;
                        _userId = stuff.user.userId;
                    }

                    cb(null, stuff);
                }
            }, null, keysToOmitFromResponse);
        }

        function getRegions(token, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getRegions ...');

            var request = {
                type: Constants.GuestActionType.GET_REGIONS,
                getRegions: {
                    token: {token: token}
                }
            };

            var keysToOmitFromResponse = ['response.guest.getRegions.convTopic'];

            sendRequest(Constants.ContentType.GUEST, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.guest.getRegions);
                }
            }, null, keysToOmitFromResponse);
        }

        function getDefaultHandler(cb) {
            if (typeof cb !== 'function') {
                return NOP;
            }
            return function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null);
                }
            };
        }

        ///////////////////////////////////////////////////////////////////////////////
        // Public Interfaces
        ///////////////////////////////////////////////////////////////////////////////
        Object.defineProperties(this, {
            clientId: {
                enumerable: true,
                get: function () {
                    return _clientId;
                },
                set: function (clientId) {
                    // Only allow setting the clientId for Unit tests
                    if (clientId === 'simulatedDirect') {
                        _clientId = clientId;
                    }
                }
            },
            clientApiVersion: {
                enumerable: true,
                get: function () {
                    return _clientApiVersion;
                }
            },
            hasLastRequestBeenSanitized: {
                enumerable: true,
                get: function () {
                    return _hasLastRequestBeenSanitized;
                }
            }
        });

        this.getLastError = function () {
            return _lastError;
        };

        this.connect = function (successCallback, errorCallback, server) {
            server && _connHandler.setTarget(server);
            _connHandler.connect(successCallback, errorCallback);
        };

        this.getConnState = _connHandler.getState.bind(_connHandler);
        this.disconnect = _connHandler.disconnect.bind(_connHandler);
        this.reconnect = _connHandler.reconnect.bind(_connHandler);
        this.pingServer = _connHandler.pingServer.bind(_connHandler);
        this.setPingInterval = _connHandler.setPingInterval.bind(_connHandler);
        this.setTarget = _connHandler.setTarget.bind(_connHandler);
        this.setProxyTarget = _connHandler.setProxyTarget.bind(_connHandler);
        this.setClientInfo = _connHandler.setClientInfo.bind(_connHandler);
        this.setToken = _connHandler.setToken.bind(_connHandler);
        this.setRelativeUrl = _connHandler.setRelativeUrl.bind(_connHandler);
        this.addEventListener = _connHandler.addEventListener.bind(_connHandler);
        this.removeEventListener = _connHandler.removeEventListener.bind(_connHandler);
        this.onMessage = _connHandler.onMessage.bind(_connHandler);
        this.injectHasActiveCall = _connHandler.injectHasActiveCall.bind(_connHandler);

        this.setDevelopmentMode = function (developmentMode) {
            _developmentMode = !!developmentMode;
            _connHandler.ommitKeysFromLog = !developmentMode;
        };

        this.simulateEvent = function (event) {
            var msg = {
                clientId: _clientId,
                msgType: Constants.WSMessageType.EVENT,
                event: event
            };
            _connHandler.onMessage(msg);
        };

        this.on = function (msgType, cb) {
            var keysToOmitFromEvent;
            switch (msgType) {
            case 'Account.TELEPHONY_CONFIGURATION_UPDATED':
                keysToOmitFromEvent = [
                    'event.account.telephonyConfigurationUpdated.configuration.onsSipAuthenticationHash',
                    'event.account.telephonyConfigurationUpdated.configuration.ondSipAuthenticationHash'
                ];
                break;
            case 'Account.TELEPHONY_PREVIOUS_ALTERNATIVE_NUMBERS_UPDATED':
                keysToOmitFromEvent = '*';
                break;
            case 'RTCSession.ACTIVE_SPEAKER':
                keysToOmitFromEvent = '*';
                break;
            case 'RTCSession.LIVE_TRANSCRIPTION':
                keysToOmitFromEvent = [
                    'event.rtcSession.liveTranscriptionEvent.text'
                ];
                break;
            case 'Search.BASIC_SEARCH_RESULT':
                keysToOmitFromEvent = [
                    'event.search.basicSearchResult.searchResults'
                ];
                break;
            case 'System.BROADCAST':
                keysToOmitFromEvent = [
                    'event.system.systemNotificationEvent.broadcastText'
                ];
                break;
            case 'Team.TEAM_CREATED':
                keysToOmitFromEvent = [
                    'event.team.teamCreatedEvent.team.teamName'
                ];
                break;
            case 'Team.TEAM_UPDATED':
                keysToOmitFromEvent = [
                    'event.team.teamUpdatedEvent.team.teamName'
                ];
                break;
            case 'User.USER_PRESENCE_CHANGE':
                keysToOmitFromEvent = '*';
                break;
            case 'UserToUser.ATC':
                keysToOmitFromEvent = [
                    'event.userToUser.userToUser.data'
                ];
                break;
            }

            _connHandler.on(msgType, cb, keysToOmitFromEvent);
        };

        this.unsubscribeAll = _connHandler.unsubscribeAll;

        this.isGigasetIntegrationSupported = function () {
            // Feature introduced in SP155
            return _clientApiVersion >= SP155_API_VERSION;
        };

        this.isPlathosysIntegrationSupported = function () {
            // Feature introduced in SP155
            return _clientApiVersion >= SP155_API_VERSION;
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public User related Interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.getApiVersion = getApiVersion;

        this.logout = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: logout...');
            var request = {
                type: Constants.UserActionType.LOGOUT,
                logoff: {invalidate: true}
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getUser = function (userId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUser...');
            var request = {
                type: Constants.UserActionType.GET_USER_BY_ID,
                getById: {userId: userId}
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getById.user);
                }
            });
        };

        this.updateUser = function (userData, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: updateUser...');
            var request = {
                type: Constants.UserActionType.UPDATE,
                update: userData
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.updateResult.user);
                }
            });
        };

        this.getLoggedOnUser = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getLoggedOnUser...');
            var request = {
                type: Constants.UserActionType.GET_LOGGED_ON
            };

            var keysToOmitFromResponse = [
                'response.user.getLoggedOn.user.userPresenceState.longitude',
                'response.user.getLoggedOn.user.userPresenceState.latitude',
                'response.user.getLoggedOn.user.userPresenceState.locationText',
                'response.user.getLoggedOn.user.userPresenceState.statusMessage',
                'response.user.getLoggedOn.userPresenceState.longitude',
                'response.user.getLoggedOn.userPresenceState.latitude',
                'response.user.getLoggedOn.userPresenceState.locationText',
                'response.user.getLoggedOn.userPresenceState.statusMessage'
            ];

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                var user, getLoggedOn;

                if (isResponseValid(err, rsp, cb)) {
                    getLoggedOn = rsp.user.getLoggedOn;
                    user = getLoggedOn.user;
                    // Make sure userPresenceState is returned in both response and user object, for backwards compatibility and SDK
                    getLoggedOn.userPresenceState = getLoggedOn.userPresenceState || user.userPresenceState;
                    user.userPresenceState = getLoggedOn.userPresenceState;
                    user.accounts = getLoggedOn.accounts || [];

                    // Save the Client API version that we are connected to
                    _clientApiVersion = Utils.convertVersionToNumber(user.apiVersion);

                    // Save the connected clientId and userId
                    _clientId = user.clientId;
                    _userId = user.userId;

                    cb(null, getLoggedOn);
                }
            }, null, keysToOmitFromResponse);
        };

        this.getStuff = function (types, cb) {
            // IF we introduce new types on GET_STUFF which require backwards compatibility checks
            // THEN we need to enable the code below that sends a GET_VERSION request first.
            //getApiVersion(getStuff.bind(this, types, cb));
            getStuff(types, cb);
        };

        this.getSupportData = function (userId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getSupportData...');

            if (!userId) {
                logger.warn('[ClientApiHandler]: userId was not provided to getSupportData');
                sendAsyncResp(cb, Constants.ReturnCode.INVALID_MESSAGE);
                return;
            }

            var request = {
                type: Constants.UserActionType.GET_SUPPORT_DATA,
                getSupportData: {
                    userId: userId
                }
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getSupportData);
                }
            });
        };

        this.setPresence = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: setPresence...');
            var request = {
                type: Constants.UserActionType.SET_PRESENCE,
                presence: {
                    state: data.state,
                    inTransit: data.inTransit,
                    longitude: data.longitude,
                    latitude: data.latitude,
                    locationText: data.locationText,
                    isAccurate: data.isAccurate,
                    timeZoneOffset: data.timeZoneOffset,
                    mobile: data.mobile,
                    dndUntil: data.dndUntil,
                    statusMessage: data.statusMessage || '',
                    skipBackup: data.skipBackup,
                    isSystemDefined: data.isSystemDefined || false
                }
            };

            var keysToOmitFromRequest = [
                'request.user.presence.longitude',
                'request.user.presence.latitude',
                'request.user.presence.locationText',
                'request.user.presence.statusMessage'
            ];
            var keysToOmitFromResponse = [
                'response.user.setPresence.state.longitude',
                'response.user.setPresence.state.latitude',
                'response.user.setPresence.state.locationText',
                'response.user.setPresence.state.statusMessage'
            ];

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.setPresence.state);
                }
            }, keysToOmitFromRequest, keysToOmitFromResponse);
        };

        this.getPresence = function (userIds, full, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getPresence...');

            var keysToOmitFromResponse = [
                'response.user.getPresence.states'
            ];

            var request = {
                type: Constants.UserActionType.GET_PRESENCE,
                getPresence: {
                    userIds: userIds,
                    full: !!full
                }
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getPresence.states);
                }
            }, null, keysToOmitFromResponse);
        };

        this.setReadPointer = function (lastReadTimestamp, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: setReadPointer...');
            var request = {
                type: Constants.ConversationActionType.SET_READ_POINTER,
                setReadPointer: {
                    lastReadTimestamp: lastReadTimestamp
                }
            };
            sendRequest(Constants.ContentType.CONVERSATION, request, getDefaultHandler(cb));
        };

        this.subscribePresence = function (userIdsList, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: subscribePresence...');

            var keysToOmitFromResponse = [
                'response.user.subscribePresence.states'
            ];

            var request = {
                type: Constants.UserActionType.SUBSCRIBE_PRESENCE,
                subscribePresence: {userIds: userIdsList}
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    // The subscribe request also returns the presence state in the response
                    var presenceStates = rsp.user && rsp.user.subscribePresence && rsp.user.subscribePresence.states;
                    cb(null, presenceStates);
                }
            }, null, keysToOmitFromResponse);
        };

        this.unsubscribePresence = function (userIdsList, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: unsubscribePresence...');
            var request = {
                type: Constants.UserActionType.UNSUBSCRIBE_PRESENCE,
                unsubscribePresence: {userIds: userIdsList}
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.subscribeTenantPresence = function (tenantId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: subscribeTenantPresence...');

            var request = {
                type: Constants.UserActionType.SUBSCRIBE_TENANT_PRESENCE,
                subscribeTenantPresence: {tenantId: tenantId}
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.unsubscribeTenantPresence = function (tenantId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: unsubscribeTenantPresence...');

            var request = {
                type: Constants.UserActionType.UNSUBSCRIBE_TENANT_PRESENCE,
                unsubscribeTenantPresence: {tenantId: tenantId}
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.updateStatusSubscription = function (userIdsList, subscribe, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: updateStatusSubscription - subscribe = ', subscribe);

            var request;
            if (subscribe) {
                request = {
                    type: Constants.UserActionType.SUBSCRIBE_PRESENCE,
                    subscribePresence: {
                        userIds: userIdsList,
                        notificationSubscriptionType: Constants.NotificationSubscriptionType.ONLINE_STATUS
                    }
                };
            } else {
                request = {
                    type: Constants.UserActionType.UNSUBSCRIBE_PRESENCE,
                    unsubscribePresence: {
                        userIds: userIdsList,
                        notificationSubscriptionType: Constants.NotificationSubscriptionType.ONLINE_STATUS
                    }
                };
            }

            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.changePassword = function (oldPassword, newPassword, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: changePassword...');
            var request = {
                type: Constants.UserActionType.CHANGE_PASSWORD,
                changePassword: {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
            };

            var keysToOmitFromRequest = [
                'request.user.changePassword.oldPassword',
                'request.user.changePassword.newPassword'
            ];

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.changePasswordResult.changeResult);
                }
            }, keysToOmitFromRequest);
        };

        /////////////////////////////////////////////////////////////////////////////
        // FRN8499 - Email Change
        /////////////////////////////////////////////////////////////////////////////
        this.requestEmailChange = function (userId, newEmail, cb) {
            logger.debug('[ClientApiHandler]: requestEmailChange');
            var request = {
                type: Constants.UserActionType.GENERATE_EMAIL_UPDATE_TOKEN,
                generateEmailUpdateToken: {
                    userId: userId,
                    emailAddress: newEmail
                }
            };

            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getUsersByIds = function (userIds, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUsersByIds...');
            var request = {
                type: Constants.UserActionType.GET_USERS_BY_IDS,
                usersByIds: {
                    userIds: userIds,
                    complete: true
                }
            };

            var keysToOmitFromResponse = [
                'response.user.usersByIds.user'
            ];

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, null)) {
                    cb(null, rsp.user.usersByIds.user);
                }
            }, null, keysToOmitFromResponse);
        };

        this.getPartialUsersByIds = function (userIds, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getPartialUsersByIds...');
            var request = {
                type: Constants.UserActionType.GET_USERS_BY_IDS,
                usersByIds: {
                    userIds: userIds,
                    complete: false
                }
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.usersByIds.user);
                }
            });
        };

        this.renewToken = function (cb) {
            cb = cb || function (err) {
                if (err) {
                    logger.error('[ClientApiHandler]: Error renewing token. This client will be automatically logged out', err);
                } else {
                    logger.debug('[ClientApiHandler]: Successfully renewed token.');
                }
            };
            logger.debug('[ClientApiHandler]: renewToken');
            var request = {
                type: Constants.UserActionType.RENEW_TOKEN
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getUserSettings = function (areas, cb) {
            cb = cb || NOP;
            areas = areas || Constants.UserSettingArea.ALL;
            logger.debug('[ClientApiHandler]: getUserSettings...');
            var request = {
                type: Constants.UserActionType.GET_USER_SETTINGS,
                getUserSettings: {
                    areas: areas
                }
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getUserSettings.settings);
                }
            });
        };

        this.setUserSettings = function (settings, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: setUserSettings...');

            settings = getArrayValue(settings);
            if (!settings) {
                logger.warn('[ClientApiHandler]: No settings were provided');
                sendAsyncResp(cb, Constants.ReturnCode.INVALID_MESSAGE);
                return;
            }

            var request = {
                type: Constants.UserActionType.SET_USER_SETTINGS,
                setUserSettings: {
                    settings: settings
                }
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getUserByEmail = function (emailAddress, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUserByEmail...');

            if (!emailAddress || typeof emailAddress !== 'string') {
                logger.warn('[ClientApiHandler]: No emailAddress was provided');
                sendAsyncResp(cb, Constants.ReturnCode.INVALID_MESSAGE);
                return;
            }

            var request = {
                type: Constants.UserActionType.GET_USER_BY_MAIL,
                getUserByMail: {
                    emailAddress: emailAddress,
                    excludeRoles: [Constants.UserRole.SUPPORT, Constants.UserRole.SYSTEM_ADMIN]
                }
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getUserByMail.user);
                }
            });
        };

        this.getUserByEmail2 = function (filter, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUserByEmail2...');

            if (!filter || typeof filter !== 'object') {
                logger.warn('[ClientApiHandler]: No filter was provided');
                sendAsyncResp(cb, Constants.ReturnCode.INVALID_MESSAGE);
                return;
            }

            var request = {
                type: Constants.UserActionType.GET_USER_BY_MAIL,
                getUserByMail: {
                    emailAddress: filter.emailAddress,
                    excludeRoles: filter.excludeRoles || [Constants.UserRole.SUPPORT, Constants.UserRole.SYSTEM_ADMIN],
                    complete: getBooleanValue(filter.complete),
                    secondaryLookup: getBooleanValue(filter.secondaryLookup)
                }
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getUserByMail.user);
                }
            });
        };

        /**
         *  Retrieves users with email addresses passed as parameter
         *
         *  @param {Object} filter
         *  @param {string[]} filter.emailAddresses - Array of email addresses to search for
         *  @param {boolean} [filter.xmppLookup] - Set to true to include XMPP users in the result
         *  @param {boolean} [filter.complete] - Set to false to return limited set of attributes
         *  @param {boolean} [filter.secondaryLookup] - Set to true to also lookup by alternate emails
         *  @param {Function} cb Callback returning error and user parameters
         */
        this.getUsersByEmails = function (filter, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUsersByEmails...');

            var request = {
                type: Constants.UserActionType.GET_USERS_BY_MAILS,
                getUsersByMails: {
                    emailAddresses: filter.emailAddresses,
                    xmppLookup: getBooleanValue(filter.xmppLookup),
                    complete: getBooleanValue(filter.complete),
                    secondaryLookup: getBooleanValue(filter.secondaryLookup),
                    excludeRoles: [Constants.UserRole.SUPPORT, Constants.UserRole.SYSTEM_ADMIN]
                }
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.user.getUsersByMails.users);
                }
            });
        };

        this.changeUserEmail = function (userId, emailAddress, cb, tenantContext) {
            cb = cb || NOP;
            var request = {
                type: Constants.UserActionType.EMAIL_UPDATE,
                emailUpdate: {
                    userId: userId,
                    emailAddress: emailAddress
                }
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.emailUpdate);
                }
            }, null, null, tenantContext);
        };

        this.goToSleep = function (userId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: goToSleep...');

            var request = {
                type: Constants.UserActionType.GO_TO_SLEEP,
                userId: userId
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.wakeUp = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: wakeUp...');

            var request = {
                type: Constants.UserActionType.WAKE_UP
            };
            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getDevices = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getDevices...');

            var request = {
                type: Constants.UserActionType.GET_DEVICES
            };
            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getDevices.devices);
                }
            });
        };

        this.getTelephonyData = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getTelephonyData...');

            var request = {
                type: Constants.UserActionType.GET_TELEPHONY_DATA
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.getTelephonyData.data);
                }
            });
        };

        this.removeAlternativeNumberHandler = function (alternativeNumber, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: removeAlternativeNumberHandler...');

            var request = {
                type: Constants.AccountActionType.REMOVE_ALTERNATIVE_NUMBER,
                alternativeNumberToDelete: alternativeNumber
            };

            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb));
        };

        this.revokeAllManagedDevices = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: revokeAllManagedDevices...');

            var request = {
                type: Constants.UserActionType.REVOKE_MANAGED_DEVICES
            };

            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getSecurityTokenInfo = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getSecurityTokenInfo...');

            var request = {
                type: Constants.UserActionType.GET_SECURITY_TOKEN_INFO
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.user.getSecurityTokenInfoResult.securityTokenInfo);
                }
            });
        };

        this.revokeSecurityToken = function (tokenIdHash, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: revokeSecurityToken...');

            var request = {
                type: Constants.UserActionType.REVOKE_SECURITY_TOKEN,
                revokeSecurityToken: {
                    tokenIdHash: tokenIdHash
                }
            };

            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getUserTenantSettings = function (cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUserTenantSettings...');

            var request = {
                type: Constants.UserActionType.GET_TENANT_SETTINGS
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.user.getTenantSettings.tenantSettings);
                }
            }, null, null, tenantContext);
        };

        this.resetOpenScapeDevicePins = function (circuitUserId, directoryNumber, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: resetOpenScapeDevicePins...');

            var request = {
                type: Constants.UserActionType.RESET_OPENSCAPE_DEVICE_PINS,
                resetOpenScapeDevicePins: {
                    circuitUserId: circuitUserId,
                    directoryNumber: directoryNumber
                }
            };

            var keysToOmitFromResponse = ['response.user.resetOpenScapeDevicePins.openScapeDevicePins'];

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.user.resetOpenScapeDevicePins.openScapeDevicePins);
                }
            }, null, keysToOmitFromResponse);
        };

        this.setClientCapabilities = function (capabilities, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: setClientCapabilities...');

            var request = {
                type: Constants.UserActionType.SET_CLIENT_CAPABILITIES,
                setClientCapabilities: {
                    capabilities: capabilities
                }
            };

            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.getTenantIdToNameMap = function (secIds, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getTenantIdToNameMap...');

            var request = {
                type: Constants.UserActionType.GET_TENANT_ID_TO_NAME_MAP,
                getTenantIdToNameMap: {
                    secIds: secIds
                }
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.user.getTenantIdToNameMapResult.tenantIdToNameMap || []);
                }
            }, null, null, tenantContext);
        };

        this.getRecentUsedCMRs = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getRecentUsedCMRs...');

            var request = {
                type: Constants.UserActionType.GET_RECENT_USED_CMRS
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.user.getRecentUsedCMRsResult.cmrUser || []);
                }
            });
        };

        this.addRecentUsedCMR = function (cmrUserId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: addRecentUsedCMR...');

            var request = {
                type: Constants.UserActionType.ADD_RECENT_USED_CMR,
                addRecentUsedCMR: {cmrUserId: cmrUserId}
            };

            sendRequest(Constants.ContentType.USER, request, getDefaultHandler(cb));
        };

        this.isAllowedToAddCmr = function (inviterUserId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: isAllowedToAddCmr...');

            var request = {
                type: Constants.UserActionType.IS_ALLOWED_TO_ADD_CMR,
                isAllowedToAddCMR: {
                    inviterUserId: inviterUserId
                }
            };

            sendRequest(Constants.ContentType.USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, !!rsp.user.isAllowedToAddCmrResult.isAllowed);
                }
            });
        };


        ///////////////////////////////////////////////////////////////////////////////
        // Public Search related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.cancelSearch = function (searchId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: cancelSearch...');
            var request = {
                type: Constants.SearchActionType.CANCEL_SEARCH,
                cancelSearch: {
                    searchId: searchId
                }
            };
            sendRequest(Constants.ContentType.SEARCH, request, getDefaultHandler(cb));
        };

        this.startUserSearch = function (constraints, cb) {
            cb = cb || NOP;
            var request = {
                type: Constants.SearchActionType.START_USER_SEARCH,
                startUserSearch: {
                    query: constraints.query,
                    reversePhoneNumberLookup: !!constraints.reversePhoneNumberLookup,
                    skipExternalDirectorySearch: !!constraints.reversePhoneNumberLookup}
            };
            sendRequest(Constants.ContentType.SEARCH, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.search.startSearchResult.searchId);
                }
            });
        };

        this.startAdvancedUserSearch = function (constraints, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: startAdvancedUserSearch...');

            var request = {
                type: Constants.SearchActionType.START_ADVANCED_USER_SEARCH,
                startAdvancedUserSearch: {
                    searchContext: constraints.searchContext,
                    query: constraints.query,
                    searchExactAssignedPhoneNumber: constraints.searchExactAssignedPhoneNumber,
                    resultSetLimit: constraints.resultSetLimit
                }
            };
            sendRequest(Constants.ContentType.SEARCH, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.search.startSearchResult.searchId);
                }
            });
        };

        this.searchConversationParticipants = function (displayName, convId, cb) {
            cb = cb || NOP;
            var request = {
                type: Constants.SearchActionType.SEARCH_CONVERSATION_PARTICIPANTS,
                searchConversationParticipants: {displayName: displayName, convId: convId}
            };
            sendRequest(Constants.ContentType.SEARCH, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.search.searchConversationParticipantsResult.participants || []);
                }
            });
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public Conversation related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.updateRtcItemAttachments = function (rtcItemId, attachments, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: updateRtcItemAttachments...');

            var request = {
                type: Constants.ConversationActionType.UPDATE_RTC_ITEM_ATTACHMENTS,
                updateRtcItemAttachments: {
                    itemId: rtcItemId,
                    attachments: getAttachmentsData(attachments)
                }
            };

            sendRequest(Constants.ContentType.CONVERSATION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.conversation.updateRtcItemAttachmentsResult.item);
                }
            });
        };

        /**
         * Get telephony conversation ID on the server. Empty conversation ID will be returned if telephony conversation has not been created.
         *
         * @param {function} cb Callback function which will be invoked with telephony conversation ID retrieved from server.
         */
        this.getTelephonyConversationId = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getTelephonyConversationId...');
            var request = {
                type: Constants.ConversationActionType.GET_TELEPHONY_CONVERSATION_ID
            };

            sendRequest(Constants.ContentType.CONVERSATION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.conversation.getTelephonyConversationId.conversationId);
                }
            });
        };

        this.addJournalEntry = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: addJournalEntry...');

            var request = {
                type: Constants.ConversationActionType.ADD_JOURNAL_ENTRY,
                addJournalEntry: {
                    convId: data.convId,
                    starter: data.starter,
                    source: data.source,
                    destination: data.destination,
                    startTime: data.startTime,
                    duration: data.duration,
                    type: data.type,
                    participants: data.participants,
                    missedReason: data.missedReason
                }
            };
            sendRequest(Constants.ContentType.CONVERSATION, request, getDefaultHandler(cb));
        };

        this.deleteCallLog = function (itemId, cb) {
            var request = {
                type: Constants.ConversationActionType.DELETE_JOURNAL_ENTRY,
                deleteJournalEntry: {
                    journalEntryId: itemId
                }
            };
            sendRequest(Constants.ContentType.CONVERSATION, request, getDefaultHandler(cb));
        };

        this.getJournalEntries = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getJournalEntries...');

            var request = {
                type: Constants.ConversationActionType.GET_JOURNAL_ENTRIES,
                getJournalEntries: {
                    telephonyConversationId: data.convId,
                    timestamp: data.timestamp || undefined,
                    direction: data.direction || undefined,
                    numberOfEntries: data.numberOfEntries || undefined,
                    journalFilter: data.journalFilter || undefined
                }
            };

            // Set keysToOmitFromResponse to obfuscate the required fields, but also to reduce
            // the amount of information that is logged.
            var keysToOmitFromResponse = [
                'response.conversation.getJournalEntriesResult.entries.[].rtc.participants',
                'response.conversation.getJournalEntriesResult.entries.[].rtc.rtcParticipants',
                'response.conversation.getJournalEntriesResult.entries.[].rtc.ended',
                'response.conversation.getJournalEntriesResult.entries.[].rtc.missed.pickingUpParticipant'
            ];

            sendRequest(Constants.ContentType.CONVERSATION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.conversation.getJournalEntriesResult.entries);
                }
            }, null, keysToOmitFromResponse);
        };

        this.setVoicemailAsHeard = function (itemId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: setVoicemailAsHeard...');

            var request = {
                type: Constants.ConversationActionType.SET_VOICEMAIL_HEARD,
                setVoicemailHeard: {
                    journalEntryId: itemId
                }
            };
            sendRequest(Constants.ContentType.CONVERSATION, request, getDefaultHandler(cb));
        };

        this.getUnheardVoicemailsCount = function (convId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUnheardVoicemailCount...');

            var request = {
                type: Constants.ConversationActionType.GET_UNHEARD_VOICEMAILS_COUNT,
                getUnheardVoicemailsCount: {
                    conversationId: convId
                }
            };
            sendRequest(Constants.ContentType.CONVERSATION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.conversation.getUnheardVoicemailsCountResult.unheardVoicemailsCount || 0);
                }
            });
        };

        this.setRecordingDeletionDelay = function (rtcItemId, extendedDelay, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: setRecordingDeletionDelay...');

            var request = {
                type: Constants.ConversationActionType.SET_RECORDING_DELETION_DELAY,
                setRecordingDeletionDelay: {
                    itemId: rtcItemId,
                    extendedDelay: !!extendedDelay
                }
            };
            sendRequest(Constants.ContentType.CONVERSATION, request, getDefaultHandler(cb));
        };

        this.deleteAllCallLogs = function (convId, journalFilter, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: deleteAllCallLogs...');

            var request = {
                type: Constants.ConversationActionType.DELETE_ALL_JOURNAL_ENTRIES,
                deleteAllJournalEntries: {
                    telephonyConversationId: convId,
                    journalFilter: journalFilter
                }
            };

            sendRequest(Constants.ContentType.CONVERSATION, request, getDefaultHandler(cb));
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public RTCCall related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.joinRtcCall = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: joinRtcCall...');

            var request = {
                type: Constants.RTCCallActionType.JOIN,
                join: createJoinData(data)
            };

            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.answerRtcCall = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: answerRtcCall...');

            var request = {
                type: Constants.RTCCallActionType.ANSWER,
                answer: createJoinData(data)
            };

            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.sendIceCandidates = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: sendIceCandidates...');

            var request = {
                type: Constants.RTCCallActionType.ICE_CANDIDATES,
                iceCandidates: {
                    rtcSessionId: data.rtcSessionId,
                    userId: data.userId,
                    origin: data.origin,
                    candidates: data.candidates
                }
            };

            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.leaveRtcCall = function (rtcSessionId, disconnectCause, cb) {
            disconnectCause = disconnectCause || {};
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: leaveRtcCall...');
            var request = {
                type: Constants.RTCCallActionType.LEAVE,
                leave: {
                    rtcSessionId: rtcSessionId,
                    disconnectCause: disconnectCause.cause,
                    disconnectReason: disconnectCause.reason
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.terminateRtcCall = function (rtcSessionId, disconnectCause, cb, pickupUserId) {
            disconnectCause = disconnectCause || {};
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: terminateRtcCall...');
            var request = {
                type: Constants.RTCCallActionType.TERMINATE,
                terminate: {
                    rtcSessionId: rtcSessionId,
                    disconnectCause: disconnectCause.cause,
                    disconnectReason: disconnectCause.reason,
                    pickUpUser: pickupUserId || undefined,
                    cause: pickupUserId ? Constants.TerminateCause.PICKED_UP : undefined
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.declineRtcCall = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: declineRtcCall...');
            var request = {
                type: Constants.RTCCallActionType.INVITE_REJECT,
                inviteReject: {
                    convId: data.convId,
                    rtcSessionId: data.rtcSessionId,
                    cause: data.cause,
                    transactionId: data.transactionId
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.changeMediaType = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: changeMediaType...');

            var request = {
                type: Constants.RTCCallActionType.CHANGE_MEDIA_TYPE,
                changeMediaType: {
                    rtcSessionId: data.rtcSessionId,
                    sdp: data.sdp,
                    mediaTypes: convertMediaType(data.mediaType),
                    transactionId: data.transactionId,
                    screenSharePointerSupported: data.screenSharePointerSupported,
                    hosted: data.hosted,
                    replaces: data.replaces,
                    rtcSupportedFeatures: data.rtcSupportedFeatures
                }
            };

            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.changeMediaAccept = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: changeMediaAccept...');
            var request = {
                type: Constants.RTCCallActionType.CHANGE_MEDIA_ACCEPT,
                changeMediaAccept: {
                    rtcSessionId: data.rtcSessionId,
                    sdp: data.sdp,
                    transactionId: data.transactionId
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.changeMediaReject = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: changeMediaReject...');
            var request = {
                type: Constants.RTCCallActionType.CHANGE_MEDIA_REJECT,
                changeMediaReject: {
                    rtcSessionId: data.rtcSessionId,
                    transactionId: data.transactionId
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.prepareSession = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: prepareSession... ');
            var request = {
                type: Constants.RTCCallActionType.PREPARE,
                prepare: {
                    convId: data.convId,
                    rtcSessionId: data.rtcSessionId,
                    ownerId: data.ownerId,
                    mediaNode: data.mediaNode,
                    desiredRegion: data.desiredRegion || '',
                    isTelephonyConversation: data.isTelephonyConversation,
                    replaces: data.replaces
                }
            };

            var keysToOmitFromResponse = ['response.rtcCall.prepare.servers.[].username', 'response.rtcCall.prepare.servers.[].password'];

            sendRequest(Constants.ContentType.RTC_CALL, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.rtcCall.prepare.servers, rsp.rtcCall.prepare.newRtcSessionId);
                }
            }, null, keysToOmitFromResponse);
        };

        this.renewTurnCredentials = function (rtcSessionId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: renewTurnCredentials... ');
            var request = {
                type: Constants.RTCCallActionType.RENEW_TURN_CREDENTIALS,
                renewTurnCredentials: {
                    rtcSessionId: rtcSessionId
                }
            };

            var keysToOmitFromResponse = ['response.rtcCall.prepare.servers.[].username', 'response.rtcCall.prepare.servers.[].password'];

            sendRequest(Constants.ContentType.RTC_CALL, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.rtcCall.renewTurnCredentials.servers);
                }
            }, null, keysToOmitFromResponse);
        };

        this.sendProgress = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: sendProgress... ');
            var request = {
                type: Constants.RTCCallActionType.SEND_PROGRESS,
                sendProgress: {
                    rtcSessionId: data.rtcSessionId,
                    userId: data.localUserId,
                    invitingUser: data.invitingUserId || '',
                    type: data.type || Constants.RTCProgressType.ALERTING,
                    sdp: data.sdp || undefined
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.submitCallQualityRating = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: submitCallQualityRating...');

            var request = {
                type: Constants.RTCCallActionType.SUBMIT_RTC_QUALITY_RATING,
                submitRtcQualityRating: {
                    rating: data
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        this.mergeRtcCalls = function (callId1, callId2, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: mergeRtcCalls ...');

            var request = {
                type: Constants.RTCCallActionType.MERGE,
                merge: {
                    sourceRtcSessionId: callId1,
                    targetRtcSessionId: callId2
                }
            };
            sendRequest(Constants.ContentType.RTC_CALL, request, getDefaultHandler(cb));
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public RTCSession related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.lockRtcSession = function (rtcSessionId, locked, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: lockRtcSession...');
            var request = {
                type: Constants.RTCSessionActionType.LOCK,
                lock: {
                    rtcSessionId: rtcSessionId,
                    locked: !!locked
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, function (err, resp) {
                if (isResponseValid(err, resp, cb)) {
                    cb(null);
                }
            });
        };

        this.muteRtcSession = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: muteRtcSession...');
            var request = {
                type: Constants.RTCSessionActionType.MUTE_SESSION,
                muteSession: {
                    rtcSessionId: data.rtcSessionId,
                    muted: !!data.muted,
                    excludedUserIds: data.excludedUserIds || undefined
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.muteParticipant = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: muteParticipant...');
            var request = {
                type: Constants.RTCSessionActionType.MUTE,
                mute: {
                    rtcSessionId: data.rtcSessionId,
                    muted: !!data.muted,
                    userIds: data.usersIds || undefined
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.addParticipantToRtcSession = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: addParticipantToRtcCall...');
            var request = {
                type: Constants.RTCSessionActionType.ADD_PARTICIPANT,
                addParticipant: {
                    rtcSessionId: data.rtcSessionId,
                    userId: data.userId,
                    from: data.from,
                    to: data.to,
                    mediaType: convertMediaType(data.mediaType)
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.removeSessionParticipant = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: removeSessionParticipant...');
            var request = {
                type: Constants.RTCSessionActionType.REMOVE_PARTICIPANT,
                removeParticipant: {
                    rtcSessionId: data.rtcSessionId,
                    userId: data.userId
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.getActiveSessions = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getActiveSessions...');
            var request = {
                type: Constants.RTCSessionActionType.GET_ACTIVE_SESSIONS
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.rtcSession.activeSessions.session);
                }
            });
        };

        this.getSession = function (rtcSessionId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getSession...');
            var request = {
                type: Constants.RTCSessionActionType.GET_SESSION,
                getSession: {
                    rtcSessionId: rtcSessionId
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.rtcSession.getSession.session);
                }
            });
        };

        this.moveRtcSession = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: moveRtcSession...');
            var request = {
                type: Constants.RTCSessionActionType.MOVE,
                move: {
                    oldRtcSessionId: data.sessionId,
                    oldConversationId: data.conversationId,
                    newConversationId: data.newConversationId,
                    newRtcSessionId: data.newSessionId
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.startRecording = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: startRecording...');
            var request = {
                type: Constants.RTCSessionActionType.START_RECORDING,
                startRecording: {
                    convId: data.convId,
                    rtcSessionId: data.rtcSessionId,
                    recordingMediaTypes: convertRecordingMediaType(data.recordingMediaTypes)
                }
            };

            if (data.videoLayout) {
                request.startRecording.layout = data.videoLayout;
            }

            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.stopRecording = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: stopRecording...');
            var request = {
                type: Constants.RTCSessionActionType.STOP_RECORDING,
                stopRecording: {
                    convId: data.convId,
                    rtcSessionId: data.rtcSessionId,
                    recordingMediaTypes: convertRecordingMediaType(data.recordingMediaTypes)
                }
            };

            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.switchRecordingLayout = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: switchRecordingLayout...');

            var request = {
                type: Constants.RTCSessionActionType.SWITCH_RECORDING_LAYOUT,
                switchRecordingLayout: {
                    rtcSessionId: data.rtcSessionId,
                    layout: data.videoLayout
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        this.setVideoReceiverConfiguration = function (data, cb) {
            cb = cb || NOP;

            logger.debug('[ClientApiHandler]: setVideoReceiverConfiguration ...');
            var request = {
                type: Constants.RTCSessionActionType.SET_VIDEO_RECEIVER_CONFIGURATION,
                setVideoReceiverConfiguration: {
                    rtcSessionId: data.rtcSessionId,
                    configuration: data.configuration
                }
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Get Node State API
        ///////////////////////////////////////////////////////////////////////////////
        this.getNodeState = function (data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getNodeState...');

            var request = {
                type: Constants.RTCSessionActionType.GET_NODE_STATE,
                getNodeState: data
            };
            sendRequest(Constants.ContentType.RTC_SESSION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb, true)) {
                    cb(null, rsp.rtcSession.getNodeState.nodeData);
                }
            });
        };

        this.sendClientInfo = function (clientInfo, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: sendClientInfo...');

            var request = {
                type: Constants.RTCSessionActionType.SEND_CLIENT_INFO,
                sendClientInfo: {
                    clientInfo: clientInfo
                }
            };

            sendRequest(Constants.ContentType.RTC_SESSION, request, getDefaultHandler(cb));
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public Instrumentation related Interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.submitClientData = function (instrumentationData, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: submitClientData...');
            var request = {
                type: Constants.InstrumentationActionType.SUBMIT_CLIENT_DATA,
                submitClientData: {
                    instrumentationData: instrumentationData
                }
            };
            sendRequest(Constants.ContentType.INSTRUMENTATION, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    var res = rsp.instrumentation && rsp.instrumentation.submitClientData &&
                        rsp.instrumentation.submitClientData.submitDataResult;
                    cb(null, res || Constants.SubmitDataResult.OK);
                }
            });
        };

        this.submitQOSData = function (qosData, streamQualityData, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: submitQOSData...');
            var request = {
                type: Constants.InstrumentationActionType.SUBMIT_QOS_DATA,
                submitQOSData: {
                    clientTime: Date.now(), // Used by the Access Server to adjust the timestamps (if necessary)
                    dataSets: qosData
                }
            };
            if (streamQualityData) {
                request.submitQOSData.clientStreamQuality = streamQualityData;
            }
            sendRequest(Constants.ContentType.INSTRUMENTATION, request, getDefaultHandler(cb));
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public Third Party related Interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.getExchangeSettings = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getExchangeSettings...');

            var request = {
                type: Constants.ThirdPartyConnectorsActionType.GET_SETTINGS,
                getSettings: {
                    type: Constants.ThirdPartyConnectorType.EXCHANGE_CONNECTOR
                }
            };

            // Omit all Exchnage info at logging, when we are in production mode
            var keysToOmitFromResponse = ['response.thirdpartyconnectors.getSettings.exchange'];

            sendRequest(Constants.ContentType.THIRDPARTYCONNECTORS, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.thirdpartyconnectors.getSettings.exchange);
                }
            }, null, keysToOmitFromResponse);
        };

        this.saveExchangeSettings = function (settings, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: saveExchangeSettings...');
            var request = {
                type: Constants.ThirdPartyConnectorsActionType.SAVE_SETTINGS,
                saveSettings: {
                    type: Constants.ThirdPartyConnectorType.EXCHANGE_CONNECTOR,
                    exchange: settings
                }
            };

            // Omit all Exchnage info at logging, when we are in production mode
            var keysToOmitFromRequest = ['request.thirdpartyconnectors.saveSettings.exchange'];
            var keysToOmitFromResponse = ['response.thirdpartyconnectors.saveSettings.exchange'];

            sendRequest(Constants.ContentType.THIRDPARTYCONNECTORS, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.thirdpartyconnectors.saveSettings.exchange);
                }
            }, keysToOmitFromRequest, keysToOmitFromResponse);
        };

        this.getConnectionStatus = function (provider, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getConnectionStatus ' + provider + '...');

            var request = {
                type: Constants.ThirdPartyConnectorsActionType.GET_CONNECTION_STATUS,
                getConnectionStatus: {
                    type: provider
                }
            };

            sendRequest(Constants.ContentType.THIRDPARTYCONNECTORS, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.thirdpartyconnectors.getConnectionStatus.connected);
                }
            });
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public System related Interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.getGlobalProperty = function (name, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getGlobalProperty...');

            var request = {
                type: Constants.SystemActionType.GET_GLOBAL_PROPERTY,
                getGlobalProperty: {
                    globalPropertyName: name
                }
            };
            sendRequest(Constants.ContentType.SYSTEM, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.system.getGlobalPropertyResult.property);
                }
            });
        };

        this.getGlobalProperties = function (names, cb) {
            cb = cb || NOP;
            if (!(names instanceof Array)) {
                names = [names];
            }
            logger.debug('[ClientApiHandler]: getGlobalProperties...');

            var request = {
                type: Constants.SystemActionType.GET_GLOBAL_PROPERTIES,
                getGlobalProperties: {
                    globalPropertyNames: names
                }
            };
            sendRequest(Constants.ContentType.SYSTEM, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.system.getGlobalPropertiesResult.property);
                }
            });
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public Session Guest related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.validateSessionInvite = function (token, textLanguage, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: validateSessionInvite...');
            var request = {
                type: Constants.GuestActionType.VALIDATE_SESSION_INVITE_TOKEN,
                validateSessionInviteToken: {
                    token: {token: token},
                    configurableTextsLanguage: textLanguage || undefined
                }
            };

            var keysToOmitFromResponse = ['response.guest.validateSessionInviteToken.convTopic'];

            sendRequest(Constants.ContentType.GUEST, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.guest.validateSessionInviteToken);
                }
            }, null, keysToOmitFromResponse);
        };

        this.validateSessionPin = function (pin, textLanguage, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: validateSessionPin...');

            pin = pin && pin.replace(/[^\d]/g, '');
            if (!pin) {
                logger.warn('[ClientApiHandler]: Invalid PIN');
                sendAsyncResp(cb, Constants.ReturnCode.SERVICE_EXCEPTION);
                return;
            }

            var request = {
                type: Constants.GuestActionType.VALIDATE_SESSION_PIN,
                validateSessionPIN: {
                    pin: pin,
                    configurableTextLanguage: textLanguage || undefined
                }
            };

            var keysToOmitFromRequest = ['request.guest.validateSessionPIN.pin'];
            var keysToOmitFromResponse = ['response.guest.validateSessionInviteToken.convTopic'];

            sendRequest(Constants.ContentType.GUEST, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.guest.validateSessionInviteToken);
                }
            }, keysToOmitFromRequest, keysToOmitFromResponse);
        };

        this.getRegions = function (token, cb) {
            getApiVersion(getRegions.bind(this, token, cb));
        };

        this.getJoiningInstructions = function (token, language, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getJoiningInstructions ...');

            var request = {
                type: Constants.GuestActionType.GET_JOINING_INSTRUCTIONS,
                getJoiningInstructions: {
                    token: {token: token},
                    language: language
                }
            };

            var keysToOmitFromResponse = ['response.guest.getJoiningInstructionsResult'];

            sendRequest(Constants.ContentType.GUEST, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.guest.getJoiningInstructionsResult);
                }
            }, null, keysToOmitFromResponse);
        };

        this.registerSessionGuest = function (guestData, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: registerSessionGuest...');
            var request = {
                type: Constants.GuestActionType.REGISTER_SESSION_GUEST,
                registerSessionGuest: {
                    token: {token: guestData.token},
                    firstName: guestData.firstName,
                    lastName: guestData.lastName,
                    forTestCall: guestData.testCall,
                    locale: guestData.locale,
                    userId: _userId || undefined,
                    // The following fields are used by the access server and are not
                    // passed to the application node.
                    oldClientId: _clientId || undefined,
                    previousServer: _connectedBackend || undefined
                }
            };

            var keysToOmitFromResponse = ['response.guest.registerSessionGuestResult.guestContext'];

            sendRequest(Constants.ContentType.GUEST, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    var result = rsp.guest.registerSessionGuestResult;

                    // Save the Client API version that we are connected to
                    _clientApiVersion = Utils.convertVersionToNumber(result.apiVersion);
                    _connectedBackend = result.connectedBackend;

                    // Save the connected clientId and userId
                    _clientId = result.clientId;
                    _userId = result.userId;

                    cb(null, result);
                }
            }, null, keysToOmitFromResponse);
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public User to User related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.sendUserToUserRequest = function (type, data, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: sendUserToUserRequest, type: ' + type + ', data: ', data);

            var request = {
                type: Constants.ContentType.USER_TO_USER,
                userToUser: {
                    type: type,
                    routing: {
                        destUserId: data.destUserId,
                        srcUserId: _userId,
                        destClientId: data.destClientId,
                        srcClientId: _clientId
                    },
                    data: JSON.stringify(data.content)
                }
            };

            var keysToOmitFromRequest = ['request.userToUser.userToUser.data'];

            sendRequest(Constants.ContentType.USER_TO_USER, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.userToUser);
                }
            }, keysToOmitFromRequest);
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public Account related interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.getPackages = function (cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getPackages...');
            var request = {
                type: Constants.AccountActionType.GET_ASSIGNED_PACKAGES
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.account.getAssignedPackages.packages || []);
                }
            }, null, null, tenantContext);
        };

        /**
         * The replacement of getUsers
         * @param queryData
         * @param cb
         */
        this.getAccounts = function (queryData, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getAccounts...');

            var request = {
                type: Constants.AccountActionType.GET_ACCOUNTS,
                getAccounts: {
                    searchCriterias: queryData.searchCriterias,
                    sorting: queryData.sorting,
                    ordering: queryData.ordering,
                    searchPointer: queryData.searchPointer,
                    pageSize: queryData.pageSize,
                    excludedTags: queryData.excludedTags
                }
            };

            var keysToOmitFromResponse = [
                'response.account.getAccounts.accounts'
            ];

            sendRequest(Constants.ContentType.ACCOUNT, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.account.getAccounts);
                }
            }, null, keysToOmitFromResponse, tenantContext);
        };

        /**
         * Get account by user id
         * @param user id
         * @param cb
         */
        this.getAccountByUserId = function (userId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getAccountByUserId...');

            var request = {
                type: Constants.AccountActionType.GET_ACCOUNT_BY_USER_ID,
                getAccountByUserId: {
                    userId: userId
                }
            };

            sendRequest(Constants.ContentType.ACCOUNT, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.account.getAccountByUserId.account);
                }
            });
        };

        this.migrateAccount = function (params, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: migrateAccount...');

            var request = {
                type: Constants.AccountActionType.MIGRATE_ACCOUNT,
                migrateAccount: {
                    accountId: params.accountId,
                    accountTemplateId: params.accountTemplateId
                }
            };

            sendRequest(Constants.ContentType.ACCOUNT, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.account.migrateAccount);
                }
            }, null, null, tenantContext);
        };

        this.migrateMultipleUsers = function (params, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: migrateMultipleUsers...');

            var request = {
                type: Constants.AccountActionType.MIGRATE_MULTIPLE_USERS,
                migrateMultipleUsers: {
                    accountIds: params.accountIds,
                    accountTemplateId: params.accountTemplateId
                }
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp);
                }
            }, null, null, tenantContext);
        };

        this.suspendAccount = function (accountId, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: suspendAccount...');
            var request = {
                type: Constants.AccountActionType.SUSPEND_ACCOUNT,
                suspendAccount: {
                    accountId: accountId
                }
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb), null, null, tenantContext);
        };

        this.unsuspendAccount = function (accountId, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: unsuspendAccount...');
            var request = {
                type: Constants.AccountActionType.UNSUSPEND_ACCOUNT,
                unsuspendAccount: {
                    accountId: accountId
                }
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb), null, null, tenantContext);
        };

        this.assignTelephonyConfiguration = function (accountId, configuration, cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: assignTelephonyConfiguration...');

            var request = {
                type: Constants.AccountActionType.ASSIGN_TELEPHONY_CONFIGURATION,
                assignTelephonyConfiguration: {
                    accountId: accountId,
                    configuration: {
                        phoneNumber: Utils.cleanPhoneNumber(configuration.phoneNumber),
                        callerId: Utils.cleanPhoneNumber(configuration.callerId),
                        reroutingPhoneNumber: Utils.cleanPhoneNumber(configuration.reroutingPhoneNumber),
                        onsSipAuthenticationHash: configuration.onsSipAuthenticationHash || '',
                        ondSipAuthenticationHash: configuration.ondSipAuthenticationHash || '',
                        associatedTelephonyUserID: configuration.associatedTelephonyUserID || '',
                        associatedTelephonyTrunkGroupId: configuration.associatedTelephonyTrunkGroupId || '',
                        associatedTelephonyUserType: configuration.associatedTelephonyUserType || undefined
                    }
                }
            };

            var keysToOmitFromRequest = [
                'request.account.assignTelephonyConfiguration.configuration.onsSipAuthenticationHash',
                'request.account.assignTelephonyConfiguration.configuration.ondSipAuthenticationHash'
            ];

            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb), keysToOmitFromRequest, null, tenantContext);
        };

        this.renewAssociatedTelephonyUser = function (associatedTelephonyUserId, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: renewAssociatedTelephonyUser...');

            var request = {
                type: Constants.AccountActionType.RENEW_ASSOCIATED_TELEPHONY_USER,
                renewAssociatedTelephonyUser: {
                    currentAssociatedTelephonyUserId: associatedTelephonyUserId
                }
            };

            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb));
        };

        this.getTechnicalAdminUserId = function (cb, tenantContext) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getTechnicalAdminUserId...');

            var request = {
                type: Constants.AccountActionType.GET_TECHNICAL_ADMIN_USER_ID
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.account.getTechnicalAdminUserIdResult.technicalAdminUserId);
                }
            }, null, null, tenantContext);
        };

        this.addAccountPermission = function (accountId, permissionName, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: addAccountPermission...');

            var request = {
                type: Constants.AccountActionType.ADD_ACCOUNT_PERMISSION,
                addAccountPermission: {
                    accountId: accountId,
                    permissionName: permissionName
                }
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb));
        };

        this.removeAccountPermission = function (accountId, permissionName, cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: removeAccountPermission...');

            var request = {
                type: Constants.AccountActionType.REMOVE_ACCOUNT_PERMISSION,
                removeAccountPermission: {
                    accountId: accountId,
                    permissionName: permissionName
                }
            };
            sendRequest(Constants.ContentType.ACCOUNT, request, getDefaultHandler(cb));
        };

        ///////////////////////////////////////////////////////////////////////////////
        // Public UserData request
        ///////////////////////////////////////////////////////////////////////////////
        this.getUserData = function (cb) {
            cb = cb || NOP;
            logger.debug('[ClientApiHandler]: getUserData...');

            var request = {
                type: Constants.UserDataActionType.GET_USER_DATA
            };
            sendRequest(Constants.ContentType.USER_DATA, request, function (err, rsp) {
                if (isResponseValid(err, rsp, cb)) {
                    cb(null, rsp.userData.getUserData);
                }
            });
        };
    }

    ClientApiHandler.prototype.constructor = ClientApiHandler;
    ClientApiHandler.prototype.name = 'ClientApiHandler';

    // Exports
    circuit.ClientApiHandler = ClientApiHandler;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
