/*global Base64, Uint8Array*/

var Circuit = (function (circuit) {
    'use strict';

    var AudioRecorder = circuit.AudioRecorder;
    var RecordingState = circuit.Constants.RecordingState;
    var TranscriptionError = circuit.Constants.TranscriptionError;

    var TranscriptionProvider = {
        GOOGLE: 'Google'
    };

    // eslint-disable-next-line max-params, max-lines-per-function
    function TranscriptionSvcImpl($rootScope, $q, LogSvc, PubSubSvc, LocalStoreSvc, LocalizeSvc) { // NOSONAR
        LogSvc.debug('New Service: TranscriptionSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var WAV_HDR_LEN = 44; // Length of WAV header
        var SAMPLE_RATE_16KHZ = 16000;
        var DEFAULT_SILENCE_DETECTION_TIME = 3000;
        var REALTIME_SILENCE_DETECTION_TIME = 1000;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _config = {};
        var _recorder;
        var _audioSupported = AudioRecorder.isSupported();
        var _recordingState = RecordingState.IDLE;

        var _startTime;
        var _onStateChange;
        var _activeRecordingResolve;
        var _activeRecordingReject;

        var _transcriptionSettings = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.TRANSCRIPTION) || {
            selectedProvider: TranscriptionProvider.GOOGLE,
            apiKeys: {},
            language: 'en-US'
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setState(newState) {
            if (_recordingState !== newState) {
                LogSvc.debug('[TranscriptionSvc]: Changed state to ', newState);
                _recordingState = newState;
                try {
                    _onStateChange && _onStateChange(newState);
                } catch (e) {
                }
            }
        }

        function destroyRecorder() {
            if (_recorder) {
                _recorder.stop();
                _recorder = null;
            }
        }

        function resetData() {
            destroyRecorder();
            setState(RecordingState.IDLE);
            if (_config.call) {
                _config.call.localTranscriptionEnabled = false;
            }
            _config = {};
            _onStateChange = null;
            _activeRecordingReject = null;
            _activeRecordingResolve = null;
        }

        function transcribeAudioGoogle(audioInput, audioLength, apiKey) {
            var gReq = new XMLHttpRequest();
            gReq.open('POST', 'https://speech.googleapis.com/v1/speech:recognize?key=' + apiKey, true);

            gReq.addEventListener('load', function () {
                var data = JSON.parse(gReq.response);
                var transcript = (data.results && data.results[0] && data.results[0].alternatives[0].transcript) || '';
                if (transcript) {
                    transcript = transcript.substring(0, 1).toUpperCase() + transcript.substring(1);
                }
                var response = {
                    sampleRate: SAMPLE_RATE_16KHZ,
                    roundtrip: parseFloat((Date.now() - _startTime) / 1000).toFixed(3),
                    audioLength: audioLength,
                    transcript: transcript,
                    keysToOmitFromLogging: ['transcription']
                };
                LogSvc.debug('[TranscriptionSvc]: Google Speech response: ', response);
                if (!_config.keepRecording) {
                    _activeRecordingResolve && _activeRecordingResolve(response);
                    resetData();
                }
            });

            gReq.addEventListener('error', function (error) {
                LogSvc.error('[TranscriptionSvc]: Google Speech error: ', error);
                if (!_config.keepRecording) {
                    _activeRecordingReject && _activeRecordingReject(error);
                    resetData();
                }
            });

            var reader = new FileReader();
            reader.addEventListener('loadend', function () {
                // RUE convert WAV to raw LINEAR16 PCM base64 encoded
                // reader.result contains the contents of blob as a typed array
                var b64audio = Base64.fromByteArray(new Uint8Array(reader.result));

                LogSvc.debug('[TranscriptionSvc]: Send request to Google Speech API');
                gReq.send(JSON.stringify({
                    config: {
                        encoding: 'LINEAR16',
                        sampleRateHertz: SAMPLE_RATE_16KHZ,
                        languageCode: _transcriptionSettings.language || 'en-US',
                        enableAutomaticPunctuation: true,
                        profanityFilter: true,
                        speechContexts: [{
                            phrases: ['Unify', 'Atos', 'Circuit']
                        }]
                    },
                    audio: {
                        content: b64audio
                    }
                }));
            });
            reader.readAsArrayBuffer(audioInput.slice(WAV_HDR_LEN));
        }

        function transcribeAudio(audioInput) {
            var duration = Date.now() - _startTime;

            if (_config.keepRecording) {
                // If we need to keep recording restart the start time.
                _startTime = Date.now();
            } else {
                // If we don't need to keep recording, change state and destroy recorder.
                setState(RecordingState.PROCESSING);
                destroyRecorder();
            }

            // If duration is less than silence detection time it means the entire recording
            // is silence. Add 400 ms to silence detection time to consider short noises and delay.
            if (duration < (_config.silenceDetectionTime + 400)) {
                LogSvc.debug('[TranscriptionSvc]: No input detected');
                if (!_config.keepRecording) {
                    var response = {
                        audioLength: 0,
                        transcript: ''
                    };
                    _activeRecordingResolve && _activeRecordingResolve(response);
                    resetData();
                }
                return;
            }

            var audioLength = parseFloat(duration / 1000).toFixed(3);
            var provider = _transcriptionSettings.selectedProvider;
            var apiKey = _transcriptionSettings.apiKeys[provider];

            if (provider === TranscriptionProvider.GOOGLE) {
                transcribeAudioGoogle(audioInput, audioLength, apiKey);
            } else {
                LogSvc.warn('[TranscriptionSvc]: Unsupported transcription provider: ', provider);
                _activeRecordingReject && _activeRecordingReject(TranscriptionError.INVALID_PROVIDER);
                resetData();
            }
        }

        function exportWAV() {
            if (_recordingState === RecordingState.LISTENING && _recorder) {
                _recorder.exportWAV(function (blob) {
                    $rootScope.$apply(function () {
                        transcribeAudio(blob);
                    });
                }, _config.sampleRate || SAMPLE_RATE_16KHZ);
            }
        }

        function onSilenceDetected() {
            if (_recordingState === RecordingState.LISTENING && _recorder) {
                $rootScope.$apply(function () {
                    LogSvc.debug('[TranscriptionSvc]: Silence has been detected');
                    exportWAV();
                });
            }
        }

        function isRecordingSupported(reject) {
            if (!$rootScope.circuitLabs.TRANSCRIPTION_AND_TRANSLATION || !_audioSupported) {
                LogSvc.warn('[TranscriptionSvc]: Recording is not supported');
                reject && reject(TranscriptionError.RECORDING_NOT_SUPPORTED);
                return false;
            }
            return true;
        }

        function hasActiveRecording(reject) {
            if (_recordingState !== RecordingState.LISTENING || !_recorder) {
                LogSvc.warn('[TranscriptionSvc]: Recording has not been started');
                reject(TranscriptionError.RECORDING_NOT_STARTED);
                return false;
            }
            return true;
        }

        function isRecordingPossible(reject) {
            if (!isRecordingSupported(reject)) {
                return false;
            }
            var provider = _transcriptionSettings.selectedProvider;
            var apiKey = _transcriptionSettings.apiKeys[provider];
            if (!apiKey) {
                reject && reject('res_MissingTranscriptionApiKey');
                return false;
            }
            if (_recordingState !== RecordingState.IDLE || _recorder) {
                LogSvc.warn('[TranscriptionSvc]: Recording is already is progress');
                reject && reject(TranscriptionError.RECORDING_IN_PROGRESS);
                return false;
            }
            return true;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/call/ended', function (call) {
            if (_config.call && _config.call.callId === call.callId) {
                LogSvc.debug('[TranscriptionSvc]: Received /call/ended event. Terminate real time transcription.');
                resetData();
            }
        });

        PubSubSvc.subscribe('/conversation/item/add', function (item) {
            if (_config.call && _config.call.instanceId === item.rtcInstanceId && !_config.threadId) {
                LogSvc.debug('[TranscriptionSvc]: Received /conversation/item/add for transcription thread. Save threadId.');
                _config.threadId = item.parentId || item.itemId;
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.isRecordingPossible = function () {
            return isRecordingPossible();
        };

        this.getState = function () {
            return _recordingState;
        };

        this.startRecording = function (onStateChange, onAudioData, config) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[TranscriptionSvc]: Start recording request');
                if (!isRecordingPossible(reject)) {
                    return;
                }
                _config = config || {};
                _config.silenceDetectionTime = _config.silenceDetectionTime || DEFAULT_SILENCE_DETECTION_TIME;
                _config.keeRecording = false;
                _onStateChange = onStateChange || Function.prototype;

                LogSvc.debug('[TranscriptionSvc]: Create Recorder instance and start recording');

                _recorder = AudioRecorder.createRecorder(_config);
                _recorder.record(onSilenceDetected, onAudioData);
                _startTime = Date.now();
                setState(RecordingState.LISTENING);

                _activeRecordingResolve = resolve;
                _activeRecordingReject = reject;
            });
        };

        this.pauseRecording = function () {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[TranscriptionSvc]: Pause recording request');
                if (hasActiveRecording(reject)) {
                    _recorder.pause();
                    resolve();
                }
            });
        };

        this.stopRecording = function () {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[TranscriptionSvc]: Stop recording request');
                if (hasActiveRecording(reject)) {
                    exportWAV();
                    resolve();
                }
            });
        };

        this.abortRecording = function () {
            LogSvc.debug('[TranscriptionSvc]: Abort recording request');
            _activeRecordingReject && _activeRecordingReject(TranscriptionError.RECORDING_ABORTED);
            resetData();
        };

        this.enableCallTranscription = function (call, onAudioData) {
            return new $q(function (resolve, reject) {
                if (!call || call.isRemote || !call.isEstablished()) {
                    reject(TranscriptionError.INVALID_CALL);
                    return;
                }
                LogSvc.debug('[TranscriptionSvc]: Enable transcription for active call');

                if (!isRecordingPossible(reject)) {
                    return;
                }

                call.localTranscriptionEnabled = true;
                _config = {
                    call: call,
                    stream: call.sessionCtrl.getLocalStream(circuit.RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO),
                    silenceDetectionTime: REALTIME_SILENCE_DETECTION_TIME,
                    keepRecording: true
                };

                _onStateChange = Function.prototype;

                LogSvc.debug('[TranscriptionSvc]: Create Recorder instance and start recording');
                _recorder = AudioRecorder.createRecorder(_config);
                _recorder.record(onSilenceDetected, onAudioData);
                _startTime = Date.now();
                setState(RecordingState.LISTENING);
                resolve();
            });
        };

        this.disableCallTranscription = function (callId) {
            return new $q(function (resolve, reject) {
                if (!callId || !_config.call || callId !== _config.call.callId) {
                    reject(TranscriptionError.INVALID_CALL);
                } else {
                    resetData();
                    resolve();
                }
            });
        };

        this.setTranscriptionProvider = function (provider) {
            if (!Object.values(TranscriptionProvider).includes(provider)) {
                return null;
            }
            if (_transcriptionSettings.selectedProvider !== provider) {
                LogSvc.debug('[TranscriptionSvc]: Set translation provider to ', provider);
                _transcriptionSettings.selectedProvider = provider;
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.TRANSCRIPTION, _transcriptionSettings);
            }
            // Return API key for selected provider
            return _transcriptionSettings.apiKeys[_transcriptionSettings.selectedProvider];
        };

        this.setProviderApiKey = function (provider, apiKey) {
            if (!Object.values(TranscriptionProvider).includes(provider)) {
                return;
            }
            if (apiKey) {
                LogSvc.debug('[TranscriptionSvc]: Set translation API key for ', provider);
                _transcriptionSettings.apiKeys[provider] = apiKey;
            } else {
                LogSvc.debug('[TranscriptionSvc]: Clear translation API key for ', provider);
                delete _transcriptionSettings.apiKeys[provider];
            }
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.TRANSCRIPTION, _transcriptionSettings);
        };

        this.setTranscriptionLanguage = function (language) {
            if (!language || !LocalizeSvc.getBridgeLanguageByValue(language)) {
                return;
            }
            _transcriptionSettings.language = language;
            LogSvc.debug('[TranscriptionSvc]: Set transcription language to ', language);
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.TRANSCRIPTION, _transcriptionSettings);
        };

        this.getSettings = function () {
            return {
                provider: _transcriptionSettings.selectedProvider,
                apiKey: _transcriptionSettings.apiKeys[_transcriptionSettings.selectedProvider] || '',
                language: _transcriptionSettings.language || 'en-US'
            };
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.TranscriptionSvcImpl = TranscriptionSvcImpl;
    circuit.TranscriptionProvider = TranscriptionProvider;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
