/* global RegistrationState */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var AgentState = circuit.Enums.AgentState;
    var AtcCallInfo = circuit.AtcCallInfo;
    var AtcMessage = circuit.Enums.AtcMessage;
    var AtcRegistrationState = circuit.Enums.AtcRegistrationState;
    var AtcRemoteCall = circuit.AtcRemoteCall;
    var BusyHandlingOptions = circuit.BusyHandlingOptions;
    var CallDirection = circuit.Enums.CallDirection;
    var CallForwardingTypes = circuit.Enums.CallForwardingTypes;
    var CallState = circuit.Enums.CallState;
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var CstaCallState = circuit.Enums.CstaCallState;
    var CstaParser = circuit.CstaParser;
    var JournalEntryTypes = circuit.Enums.JournalEntryTypes;
    var LocalCall = circuit.LocalCall;
    var MissedReasonTypes = circuit.Enums.MissedReasonTypes;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;
    var RedirectionTypes = circuit.Enums.RedirectionTypes;
    var RoutingOptions = circuit.RoutingOptions;
    var Targets = circuit.Enums.Targets;
    var TransferCallFailedCauses = circuit.Enums.TransferCallFailedCauses;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;
    var Utils = circuit.Utils;

    var AgentUIState = Object.freeze({
        AVAILABLE: 'available',
        PARTIAL: 'partiallyUnavailable',
        UNAVAILABLE: 'unavailable'
    });

    // eslint-disable-next-line max-params, max-lines-per-function
    function CstaSvcImpl( // NOSONAR
        $rootScope,
        $timeout,
        $q,
        LogSvc,
        PubSubSvc,
        CircuitCallControlSvc,
        AtcRegistrationSvc,
        UserSvc,
        UserProfileSvc) {

        LogSvc.debug('New Service: CstaSvc');

        ///////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////
        var TRANSFER_TIMEOUT = 4000; // Transferred event should be received within 4s
        var REVERSE_LOOKUP_MAX_TIME = 2000; // 2s - Max time to wait for user search by phone number
        var MAX_ENDED_CALL_WAIT_TIME = 2000; // 2s - Max time to wait for a local ended call
        var VALID_LOCAL_STATES = ['connected', 'hold', 'queued'];
        var VALID_PARTNER_STATES = ['connected', 'hold'];

        var NUMBER_NOT_AVAILABLE = 'Number not available';

        var DEFAULT_TRANSFER_CALLID = '00000000000000000000000000000000';
        var DEFAULT_CALCULATED_TRANSFER_CALLID = '02000000000000000000000000000000';

        var FORWARD_EXPR = /Forward/;

        var MAX_NUMBER_OF_LOOPS = 3;
        var LOOP_DETECTION_WAIT_TIME = 60000; // Wait for 1 min before trying to set the timers again after loop detection
        var LOOP_DETECTION_INTERVAL = 2000; // 2s interval in which two subsequent Forwarding events indicate that there may be a loop
        var OSBIZ_RECONNECT_TIMEOUT = 2000; // 2s Wait for 2 sec before reconnecting the first call after a failed consulted call

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _that = this;

        var _regData = null;
        var _osmoData = {
            forwardList: []
        };

        var _userToUserHandler = UserToUserHandler.getInstance();
        var _clientApiHandler = ClientApiHandler.getInstance();
        var _cstaParser = new CstaParser();

        var _telephonyConversation = null;

        // The local WebRTC calls
        var _activeCall = null;
        var _heldCall = null;
        var _alertingCall = null;

        // List with all the remote calls
        var _atcRemoteCalls = {};

        var _webrtcRemoteCall = null;

        var _calls = [];

        // Track transferred calls
        var _trackTransferCallIds = {};

        // track the transferred calls
        var _transferredCalls = {};

        var _snapshotPerformed = false;

        var _onsUnregistered = true;

        var _primaryClient = true;

        var _incomingCallConnection = {};

        var _movingCall = {};

        var _lastTwoCallPartners = [];

        var _conferenceParticipants = [];

        var _resolving = false;

        // Last ended call. Save the last ended call for racing conditions
        var _lastEndedCall = null;
        var _endedCallWaitTimer = null;

        var _regState = null;
        var _savedStaticOnd = null;
        var _supportsStaticOndSync = false;

        var _userBusyState = false;

        var _savedVMRingDuration = null;

        var _savedMainRingDuration = null;

        var _savedClientRingDuration = null;

        var _savedCellRingDuration = null;

        var _receivedActiveSessions = false;

        var _lastSetForwardingTimestamp = 0;
        var _loopCounter = 0;
        var _loopDetectionTimer = null;
        var _pendingFwdRequest = null;
        var _numberNotAvailable = NUMBER_NOT_AVAILABLE;
        var _huntGroupList = null;
        //array of promises
        var deferredArr = [];
        var gPromise;

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function isSelectedRouting(routingOption) {
            return !!$rootScope.localUser && $rootScope.localUser.selectedRoutingOption === routingOption.name;
        }

        function setRoutingOption(routingOption) {
            if ($rootScope.localUser) {
                $rootScope.localUser.selectedRoutingOption = routingOption.name;
            }
        }

        function publishPeerUserUpdate(call, cb) {
            LogSvc.debug('[CstaSvc]: Publish /call/peerUser/update event. callId = ' + call.callId + ', peerUserId = ' + call.peerUser.userId);
            LogSvc.debug('[CstaSvc]: Publish /call/peerUser/update event. peerUser', call.peerUser);
            PubSubSvc.publish('/call/peerUser/update', [call]);
            cb && cb();
        }

        function chainExecute() {
            if (deferredArr.length > 0) {
                var fn = deferredArr.shift();
                return fn().then(chainExecute);
            }
            gPromise = null;
            return $q.resolve();
        }

        function enqueueChain() {
            if (!gPromise) {
                gPromise = chainExecute();
            } else {
                gPromise.then(chainExecute);
            }
        }

        function setCallPeerUser(call, phoneNumber, fqNumber, displayName, cb) {
            if (fqNumber && call.peerUser && call.peerUser.fqnLookedUp === fqNumber) {
                // This number has already been looked up
                cb && cb();
                return;
            }

            // Temporarily display whatever is available until user search is done
            call.setPeerUser(phoneNumber, displayName);
            if (call.peerUser.userId || !fqNumber || !Utils.PHONE_PATTERN.test(fqNumber)) {
                LogSvc.debug('[CstaSvc]: User already resolved or number is not fully qualified to do a reverse phone lookup');
                publishPeerUserUpdate(call, cb);
                return;
            }
            var maxWaitTime = $timeout(function () {
                maxWaitTime = null;
                if (call.isPresent()) {
                    publishPeerUserUpdate(call, cb);
                    cb = null;
                }
            }, REVERSE_LOOKUP_MAX_TIME, false);

            UserSvc.startReverseLookUp(fqNumber, function (user) {
                $timeout.cancel(maxWaitTime);
                if (call.isPresent()) {
                    var userId;
                    var data;
                    if (user) {
                        displayName = user.displayName;
                        userId = user.userId;
                        data = {
                            extAvatarUri: user.extAvatarUri,
                            smallImageUri: user.smallImageUri,
                            largeImageUri: user.largeImageUri
                        };
                    }
                    // Regardless if the reverse lookup succeeded, we should invoke setPeerUser so the fqNumber
                    // is recorded and we won't do another reverse lookup for the same number again (during this call)
                    call.setPeerUser(phoneNumber, displayName, userId, fqNumber, data);
                    publishPeerUserUpdate(call, cb);
                }
            });
        }

        function setCallPeerUserSync(call, phoneNumber, fqNumber, displayName, cb) {
            if (fqNumber && call.peerUser && call.peerUser.fqnLookedUp === fqNumber) {
                // This number has already been looked up
                cb && cb();
                return false;
            }

            // Temporarily display whatever is available until user search is done
            call.setPeerUser(phoneNumber, displayName);
            if (call.peerUser.userId || !fqNumber || !Utils.PHONE_PATTERN.test(fqNumber)) {
                LogSvc.debug('[CstaSvc]: User already resolved or number is not fully qualified to do a reverse phone lookup');
                cb && cb();
                return false;
            }

            return true;
        }

        function setRedirectingUser(call, phoneNumber, fqNumber, displayName, type, cb) {
            if (call.redirectingUser && call.redirectingUser.redirectionType) {
                return;
            }
            call.setRedirectingUser(phoneNumber, fqNumber, displayName, null, type);
            if (!fqNumber || !Utils.PHONE_PATTERN.test(fqNumber)) {
                LogSvc.debug('[CstaSvc]: number is not fully qualified to do a reverse phone lookup');
                cb && cb();
                return;
            }
            var maxWaitTime = $timeout(function () {
                maxWaitTime = null;
                if (call.isPresent()) {
                    cb && cb();
                    cb = null;
                }
            }, REVERSE_LOOKUP_MAX_TIME, false);

            UserSvc.startReverseLookUp(fqNumber, function (user) {
                $timeout.cancel(maxWaitTime);
                if (call.isPresent()) {
                    if (user) {
                        call.setRedirectingUser(phoneNumber, fqNumber, user.displayName, user.userId, type);
                    }
                    cb && cb();
                }
            });
        }

        function setRedirectingUserSync(call, phoneNumber, fqNumber, displayName, type, cb) {
            if (call.redirectingUser && call.redirectingUser.displayName && call.redirectingUser.fqNumber && call.redirectingUser.redirectionType) {
                return false;
            }
            call.setRedirectingUser(phoneNumber, fqNumber, displayName, null, type);
            if (!fqNumber || !Utils.PHONE_PATTERN.test(fqNumber)) {
                LogSvc.debug('[CstaSvc]: number is not fully qualified to do a reverse phone lookup');
                cb && cb();
                return false;
            }

            return true;
        }

        function reverseLookupAndSetUserData(call, fqNumber, isPeerUser, isRedirectingUser, peerUserDetails, redirectingUserDetails, cb) {
            var maxWaitTime = $timeout(function () {
                maxWaitTime = null;
                if (call.isPresent()) {
                    cb && cb();
                    cb = null;
                }
            }, REVERSE_LOOKUP_MAX_TIME, false);

            UserSvc.startReverseLookUp(fqNumber, function (user) {
                $timeout.cancel(maxWaitTime);
                if (call.isPresent()) {
                    var userId;
                    var data;

                    if (user) {
                        if (isPeerUser) {
                            peerUserDetails.displayName = user.displayName;
                            userId = user.userId;
                            data = {
                                extAvatarUri: user.extAvatarUri,
                                smallImageUri: user.smallImageUri,
                                largeImageUri: user.largeImageUri
                            };
                        } else if (isRedirectingUser) {
                            call.setRedirectingUser(redirectingUserDetails.phoneNumber, redirectingUserDetails.fqNumber, user.displayName,
                                user.userId, redirectingUserDetails.type);
                        }
                    }
                    // Regardless if the reverse lookup succeeded, we should invoke setPeerUser so the fqNumber
                    // is recorded and we won't do another reverse lookup for the same number again (during this call)
                    isPeerUser && call.setPeerUser(peerUserDetails.phoneNumber, peerUserDetails.displayName, userId, peerUserDetails.fqNumber, data);
                    cb && cb();
                }
            });
        }

        function resolvePeerAndRedirectingUser(call, peerUserDetails, redirectingUserDetails,
            setCallPeerUserCb, setCallRedirectingUserCb) {
            var deferred = $q.defer();
            if (peerUserDetails && peerUserDetails.fqNumber) {
                reverseLookupAndSetUserData(call, peerUserDetails.fqNumber, true, false, peerUserDetails, null, function () {
                    setCallPeerUserCb();
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }
            if (redirectingUserDetails && redirectingUserDetails.fqNumber) {
                deferred.promise.then(function () {
                    reverseLookupAndSetUserData(call, redirectingUserDetails.fqNumber, false, true, null, redirectingUserDetails, setCallRedirectingUserCb);
                });
            }
        }

        function resolveParticipants() {
            if (!_conferenceParticipants.length) {
                _resolving = false;
                return;
            }
            var participant = _conferenceParticipants.splice(0, 1);
            UserSvc.startReverseLookUp(participant[0].phoneNumber, function () {
                var users = UserSvc.getUserSearchList().slice(0);
                var call = participant[0].call;
                delete participant[0].call;
                var addedParticipant = null;
                if (users.length === 1) {
                    // Exact match
                    addedParticipant = call.addParticipant({
                        userId: users[0].userId || participant[0].userId,
                        phoneNumber: participant[0].phoneNumber,
                        displayName: users[0].displayName,
                        participantId: participant[0].participantId,
                        avatar: users[0].avatar,
                        hasAvatarPicture: users[0].hasAvatarPicture
                    });
                } else {
                    addedParticipant = call.addParticipant(participant[0]);
                }

                // Used by iOS client to update UI e.g., after a merge call action
                if (addedParticipant && _telephonyConversation) {
                    LogSvc.debug('[CstaSvc]: Publish /atccall/participant/added');
                    PubSubSvc.publish('/atccall/participant/added', [call.callId, addedParticipant]);
                }

                $timeout(resolveParticipants, 0);
            });
        }

        function refreshData() {
            if ($rootScope.localUser.isATC && _telephonyConversation) {
                Object.assign(_osmoData, AtcRegistrationSvc.getAtcRegistrationData());
                _activeCall = findActiveCall();
                _heldCall = findHeldCall();
                _alertingCall = findAlertingCall();
                _webrtcRemoteCall = findWebRTCRemoteCall();
                if (_calls.length > 0) {
                    _calls = _calls.filter(function (call) {
                        if (call.checkState(CallState.Terminated)) {
                            if (_activeCall === call) {
                                _activeCall = null;
                            }
                            if (_heldCall === call) {
                                _heldCall = null;
                            }
                            if (_alertingCall === call) {
                                _alertingCall = null;
                            }
                            if (_webrtcRemoteCall === call) {
                                _webrtcRemoteCall = null;
                            }
                            return false;
                        }
                        return true;
                    });
                }

                if (!_activeCall && !_alertingCall && !_heldCall && !_webrtcRemoteCall) {
                    Utils.emptyArray(_calls);
                }
            }
        }

        function updateExistingCall(newCall) {
            for (var idx = 0; idx < _calls.length; idx++) {
                if (_calls[idx].callId === newCall.callId) {
                    var atcCallInfo = _calls[idx].atcCallInfo;
                    if (newCall.peerUser.phoneNumber !== _calls[idx].peerUser.phoneNumber) {
                        atcCallInfo.clearServicesPermitted();
                    }
                    _calls[idx] = newCall;
                    if (!newCall.atcCallInfo || !newCall.atcCallInfo.cstaConn) {
                        _calls[idx].atcCallInfo = atcCallInfo;
                    }
                    return _calls[idx];
                }
            }
            return null;
        }

        function findActiveCall() {
            var activeCall = CircuitCallControlSvc.getActiveCall();
            if (!activeCall || !activeCall.isTelephonyCall) {
                return null;
            }
            var updatedCall = updateExistingCall(activeCall);
            if (updatedCall) {
                return updatedCall;
            }
            var atcActiveCall = Object.create(activeCall);
            atcActiveCall.atcCallInfo = activeCall.atcCallInfo || new AtcCallInfo();
            _calls.push(atcActiveCall);
            return atcActiveCall;
        }

        function findHeldCall() {
            var heldCall = CircuitCallControlSvc.findHeldPhoneCall();
            if (!heldCall) {
                return null;
            }
            var updatedCall = updateExistingCall(heldCall);
            if (updatedCall) {
                return updatedCall;
            }
            var atcHeldCall = Object.create(heldCall);
            atcHeldCall.atcCallInfo = heldCall.atcCallInfo || new AtcCallInfo();
            _calls.push(atcHeldCall);
            return atcHeldCall;
        }

        function findAlertingCall() {
            var alertingCall = CircuitCallControlSvc.getIncomingCall();
            if (!alertingCall || !alertingCall.isTelephonyCall) {
                return null;
            }
            var updatedCall = updateExistingCall(alertingCall);
            if (updatedCall) {
                return updatedCall;
            }
            var atcAlertingCall = Object.create(alertingCall);
            atcAlertingCall.atcCallInfo = alertingCall.atcCallInfo || new AtcCallInfo();
            _calls.push(atcAlertingCall);
            return atcAlertingCall;
        }

        function findWebRTCRemoteCall() {
            var webRTCRemoteCall = CircuitCallControlSvc.getActiveRemoteCall()[0];
            if (!webRTCRemoteCall || !webRTCRemoteCall.isTelephonyCall) {
                return null;
            }
            var updatedCall = updateExistingCall(webRTCRemoteCall);
            if (updatedCall) {
                return updatedCall;
            }
            webRTCRemoteCall.atcCallInfo = webRTCRemoteCall.atcCallInfo || new AtcCallInfo();
            _calls.push(webRTCRemoteCall);
            return webRTCRemoteCall;
        }

        function removeAtcRemoteCall(callId, noCallLog) {
            if (_atcRemoteCalls[callId]) {
                LogSvc.debug('[CstaSvc]: Remove remote call with Call Id = ' + callId);

                var remoteCall = _atcRemoteCalls[callId];
                !noCallLog && createJournalEntry(remoteCall);
                remoteCall.setCstaState(CstaCallState.Idle);

                // Update the UI
                publishAtcCallInfo(remoteCall);

                delete _atcRemoteCalls[callId];
            }
        }

        function setMissedReason(journalEntry, reason) {
            switch (reason) {
            case MissedReasonTypes.DEST_OUT_OF_ORDER:
                journalEntry.missedReason = Constants.RTCItemMissed.UNREACHABLE;
                break;
            case MissedReasonTypes.REORDER_TONE:
                journalEntry.missedReason = Constants.RTCItemMissed.INVALID_NUMBER;
                break;
            case MissedReasonTypes.BUSY:
                journalEntry.missedReason = Constants.RTCItemMissed.USER_BUSY;
                break;
            case MissedReasonTypes.CANCELLED:
                journalEntry.missedReason = Constants.RTCItemMissed.CANCELED;
                break;
            case MissedReasonTypes.DECLINED:
                journalEntry.missedReason = Constants.RTCItemMissed.DECLINED;
                break;
            case MissedReasonTypes.TRANSFERRED:
                journalEntry.type = JournalEntryTypes.REGULAR;
                break;
            default:
                journalEntry.missedReason = Constants.RTCItemMissed.TEMPORARILY_UNAVAILABLE;
            }
        }

        function createJournalEntry(remoteCall) {
            // If it's a pickupNotification call the $rootScope.localUser.noCallLog should always be true, but we need
            // to add an extra check in case the OSV call log monitoring fails to be initiated
            if (($rootScope.localUser.noCallLog && !$rootScope.localUser.isOS4K) || remoteCall.pickupNotification) {
                return;
            }
            if (!remoteCall) {
                LogSvc.error('[CstaSvc]: No remoteCall');
                return;
            }
            var atcCallInfo = remoteCall.atcCallInfo;
            if (!atcCallInfo.pickUp && !atcCallInfo.getIgnoreCall() &&
                (remoteCall.isAtcRemote || remoteCall.isOsBizSecondCall || remoteCall.pulled)) {
                if (!_telephonyConversation) {
                    LogSvc.error('[CstaSvc]: No telephony conversation');
                    return;
                }
                if (!remoteCall.peerUser.phoneNumber &&
                    remoteCall.peerUser.displayName === _telephonyConversation.peerUser.displayName) {
                    LogSvc.warn('[CstaSvc]: This call has no peer display information.');
                    return;
                }
                var originalPartnerChanged = atcCallInfo.originalPartnerDisplay && atcCallInfo.originalPartnerDisplay.fqn &&
                    (atcCallInfo.originalPartnerDisplay.fqn !== atcCallInfo.peerFQN || !remoteCall.peerUser.displayName) &&
                    !(remoteCall.pickedUp && remoteCall.direction === CallDirection.INCOMING);

                var partner = {
                    phoneNumber: originalPartnerChanged ? atcCallInfo.originalPartnerDisplay.dn : remoteCall.peerUser.phoneNumber,
                    displayName: originalPartnerChanged ? atcCallInfo.originalPartnerDisplay.name : remoteCall.peerUser.displayName,
                    userId: (!originalPartnerChanged && remoteCall.peerUser.userId) || _telephonyConversation.peerUser.userId,
                    resolvedUser: !!remoteCall.peerUser.userId && !originalPartnerChanged,
                    fqn: originalPartnerChanged ? atcCallInfo.originalPartnerDisplay.fqn : atcCallInfo.peerFQN
                };

                var journalEntry = {
                    convId: _telephonyConversation.convId,
                    starter: remoteCall.direction === CallDirection.INCOMING ? partner.userId : $rootScope.localUser.userId,
                    source: remoteCall.direction === CallDirection.INCOMING ? partner.fqn : $rootScope.localUser.phoneNumber,
                    destination: remoteCall.direction === CallDirection.OUTGOING ? partner.fqn : $rootScope.localUser.phoneNumber,
                    startTime: remoteCall.creationTime,
                    duration: remoteCall.establishedTime ? Date.now() - remoteCall.establishedTime : 0,
                    type: remoteCall.establishedTime === 0 ? JournalEntryTypes.MISSED : JournalEntryTypes.REGULAR,
                    participants: [
                        {
                            userId: $rootScope.localUser.userId,
                            type: 'USER',
                            phoneNumber: $rootScope.localUser.phoneNumber,
                            displayName: $rootScope.localUser.displayName
                        },
                        {
                            userId: partner.userId,
                            type: 'TELEPHONY',
                            phoneNumber: partner.phoneNumber,
                            displayName: partner.displayName,
                            resolvedUser: partner.resolvedUser
                        }
                    ]
                };
                if (journalEntry.type === JournalEntryTypes.MISSED) {
                    setMissedReason(journalEntry, atcCallInfo.getMissedReason());
                }

                addJournalEntry(journalEntry);
            }
        }

        function addJournalEntry(journalEntry) {
            if (_primaryClient) {
                _clientApiHandler.addJournalEntry(journalEntry);
            } else {
                // Start a timeout between 1 and 2 seconds
                var delay = 1000 + 1000 * Math.random();
                $timeout(function () {
                    if (!entryAlreadySent(journalEntry)) {
                        _clientApiHandler.addJournalEntry(journalEntry);
                    }
                }, delay, false);
            }
        }

        function entryAlreadySent(journalEntry) {
            var journalParticipant = journalEntry && journalEntry.participants && journalEntry.participants[1];
            if (journalParticipant) {
                return _lastTwoCallPartners.some(function (participant, idx) {
                    if ((Utils.cleanPhoneNumber(participant.phoneNumber) === Utils.cleanPhoneNumber(journalParticipant.phoneNumber)) &&
                        (participant.displayName === journalParticipant.displayName)) {
                        LogSvc.debug('[CstaSvc]: Call log entry already sent by another client');
                        _lastTwoCallPartners.splice(idx, 1);
                        return true;
                    }
                    return false;
                });
            }
            return false;
        }

        function clearAtcRemoteCalls() {
            for (var key in _atcRemoteCalls) {
                if (_atcRemoteCalls.hasOwnProperty(key)) {
                    removeAtcRemoteCall(key, true);
                }
            }
        }

        function findAtcRemoteCall(callId) {
            return Object.values(_atcRemoteCalls).find(function (call) {
                return call.callId === callId;
            }) || null;
        }

        function findAtcRemoteEstablishedCall() {
            return Object.values(_atcRemoteCalls).find(function (call) {
                return call.isEstablished;
            }) || null;
        }

        function findAtcRemoteQueuedCall() {
            return Object.values(_atcRemoteCalls).find(function (call) {
                return call.atcCallInfo && call.atcCallInfo.isQueuedIncomingCall();
            }) || null;
        }

        function findAtcRemoteHeldCall() {
            return Object.values(_atcRemoteCalls).find(function (call) {
                return call.isHolding();
            }) || null;
        }

        function findOtherAtcRemoteCall(callId) {
            return Object.values(_atcRemoteCalls).find(function (call) {
                return callId !== call.cstaCallId;
            }) || null;
        }

        function processQueuedCall(position) {
            if ($rootScope.localUser.isOsBizCTIEnabled) {
                // If there is a queued call, then allow it to ring now
                var queuedCall = findAtcRemoteQueuedCall();
                if (queuedCall) {
                    LogSvc.debug('[CstaSvc]: There is a call waiting. Allow it to ring');
                    queuedCall.atcCallInfo.setQueuedIncomingCall(false);
                    if (position === Targets.WebRTC) {
                        createCampOnLocalCall(queuedCall);
                    } else {
                        publishAtcCallInfo(queuedCall);
                    }
                }
            }
        }

        function createAtcRemoteCall(callId) {
            if (!_telephonyConversation) {
                return null;
            }
            var conversation = Object.create(_telephonyConversation);
            conversation.rtcSessionId = callId;
            return new AtcRemoteCall(conversation);
        }

        function getCallingDevice(event) {
            return $rootScope.localUser.isOsBizCTIEnabled && event.networkCallingDevice ? event.networkCallingDevice : event.callingDevice;
        }

        function peerUserResolveFn(nCall, peerDn, peerFqn, peerName) {
            var deferred = $q.defer();
            setCallPeerUser(nCall, peerDn, peerFqn, peerName, function () {
                deferred.resolve();
            });
            return deferred.promise;
        }

        function createCallFromSnapshot(snapshotCallResp) {
            var data = snapshotCallResp.endpoints;
            var callingDevice = snapshotCallResp.callingDevice;
            var calledDevice = snapshotCallResp.calledDevice;
            var direction = isMyDeviceId(callingDevice) ? CallDirection.OUTGOING : CallDirection.INCOMING;

            if (!data || data.length < 2) {
                return null;
            }

            var newCall = null;
            var callId = data[0].callIdentifier.cID;

            var callState = data.length > 2 ? CstaCallState.Conference : CstaCallState.Active;

            // First process our device
            data.forEach(function (ep) {
                if (!callState) {
                    return;
                }

                if (isMyDeviceId(ep.deviceOnCall)) {
                    if (VALID_LOCAL_STATES.indexOf(ep.localConnectionState) === -1) {
                        callState = null;
                        return;
                    }
                    var position = getCallPosition(ep.deviceOnCall, snapshotCallResp.epid);

                    if (position === Targets.WebRTC && (_activeCall || _webrtcRemoteCall)) {
                        if (_activeCall) {
                            newCall = _activeCall;
                        } else {
                            newCall = _webrtcRemoteCall;
                            position = Targets.Other;
                        }
                    } else {
                        newCall = createAtcRemoteCall(callId);
                    }

                    if (!newCall.direction) {
                        newCall.direction = direction;
                    }
                    newCall.setPosition(position);
                    newCall.atcCallInfo.setCstaConnection(ep.callIdentifier);

                    // Extend top level servicesPermitted (Private data) with my endpoint's service permitted.
                    Object.assign(snapshotCallResp.servicesPermitted, ep.servicesPermitted);

                    if (ep.localConnectionState === 'queued') {
                        callState = CstaCallState.Parked;
                    } else if (ep.localConnectionState === 'hold') {
                        if (data.length > 2) {
                            callState = CstaCallState.ConferenceHolding;
                        } else {
                            callState = callState === CstaCallState.Held ? CstaCallState.HoldOnHold : CstaCallState.Holding;
                        }
                    }
                }
            });

            // Now process other devices
            var shouldPublishAtc = false;
            if (newCall && data.length === 2) {
                data.forEach(function (ep) {
                    if (!isMyDeviceId(ep.deviceOnCall)) {
                        if (VALID_PARTNER_STATES.indexOf(ep.localConnectionState) === -1) {
                            callState = null;
                            return;
                        }
                        var display;
                        if (newCall.direction === CallDirection.INCOMING) {
                            display = snapshotCallResp.presentationRestrictedDevice1 ? getDisplayInfo() : getDisplayInfo(callingDevice);
                        } else if (newCall.direction === CallDirection.OUTGOING) {
                            display = getDisplayInfo(calledDevice);
                        }

                        newCall.atcCallInfo.setPartnerDisplay(display);
                        deferredArr.push(peerUserResolveFn.bind(null, newCall, newCall.atcCallInfo.peerDn, newCall.atcCallInfo.peerFQN, newCall.atcCallInfo.peerName));
                        shouldPublishAtc = true;

                        if (ep.localConnectionState === 'hold') {
                            callState = callState === CstaCallState.Holding ? CstaCallState.HoldOnHold : CstaCallState.Held;
                        }
                    }
                });
            }

            // Building conference participants
            if (newCall && data.length > 2) {
                data.forEach(function (ep) {
                    if (!isMyDeviceId(ep.deviceOnCall)) {
                        if (VALID_PARTNER_STATES.indexOf(ep.localConnectionState) === -1) {
                            callState = null;
                            return;
                        }
                        var participantDisplay = ep && getDisplayInfo(ep.deviceOnCall) || getDisplayInfo(ep.callIdentifier.dID);
                        _conferenceParticipants.push({
                            call: newCall,
                            userId: ep.callIdentifier.dID,
                            participantId: ep.callIdentifier.dID,
                            phoneNumber: participantDisplay.dn,
                            displayName: participantDisplay.name
                        });
                        if (!_resolving) {
                            _resolving = true;
                            resolveParticipants();
                        }
                    }
                });
            }
            if (callState) {
                newCall.atcCallInfo.setServicesPermitted(snapshotCallResp.servicesPermitted);
                newCall.setCstaState(callState);
                if (newCall.isAtPosition(Targets.WebRTC)) {
                    if (data.length <= 2) {
                        LogSvc.debug('[CstaSvc]: This is a webRTC call. The reconnection is being handled by CircuitCallControlSvc');
                        return null;
                    }
                    var remoteCall = findWebRTCRemoteCall();
                    if (!remoteCall) {
                        return null;
                    }
                    LogSvc.debug('[CstaSvc]: This is a webRTC conference call. Updating existing call properties.');

                    remoteCall.atcCallInfo = newCall.atcCallInfo;
                    remoteCall.peerUser = newCall.peerUser;
                    remoteCall.participants = newCall.participants;
                    remoteCall.direction = newCall.direction;

                    return remoteCall;
                }
                _atcRemoteCalls[callId] = newCall;
                // If peer user update took place, update UI
                if (shouldPublishAtc) {
                    var atcFn = function (c) {
                        return $q.resolve(publishAtcCallInfo(c));
                    };
                    deferredArr.push(atcFn.bind(null, newCall));
                    enqueueChain();
                }
                LogSvc.debug('[CstaSvc]: Created new Remote Call object: ', newCall);
                return newCall;
            }
            return null;
        }

        function isSecondaryLine(deviceId) {
            return deviceId.includes('keyId=');
        }

        function isMyDeviceId(deviceId) {
            if (!deviceId || (deviceId.rstr === '')) {
                return false;
            }
            // For OSbiz, trim display before comparison
            var device = getDeviceFromDeviceId(deviceId);

            if (!$rootScope.localUser.isOsBizCTIEnabled && !device.includes(_osmoData.onsFQN)) {
                return false;
            }

            if ($rootScope.localUser.isOsBizCTIEnabled && device !== _osmoData.onsFQN) {
                //  For OSBiz, check also device list if onsFQN does not match
                if (!_osmoData.osmoDeviceList || !_osmoData.osmoDeviceList.includes(device)) {
                    return false;
                }
            }
            if (device.includes('uid=')) {
                return false;
            }
            return !isSecondaryLine(device);
        }

        function isVMDeviceId(deviceId) {
            return !!deviceId && isVMNumber(getDisplayInfo(deviceId).dn);
        }

        function isOSBizOns(deviceId) {
            return $rootScope.localUser.isOsBizCTIEnabled && getDeviceFromDeviceId(deviceId) === _osmoData.onsFQN;
        }

        function extractOnd(deviceId) {
            if (!$rootScope.localUser.isOsBizCTIEnabled) {
                return /ond=(\+?\d+)/.exec(deviceId);
            } else {
                // If case of mulap configuration, format at this point is "N<dn/dn>display name"
                // In case of station configuration, deviceId is "N<dn>display name"
                return (_osmoData.osmoDeviceList && /\/(\+?\**.*)/.exec(deviceId)) || /<(\+?\**\d+)/.exec(deviceId) || [deviceId, deviceId];
            }
        }

        function getCallPosition(deviceId, epid, direction) {
            LogSvc.debug('[CstaSvc]: Get call position for ' + deviceId);

            if (!isMyDeviceId(deviceId)) {
                LogSvc.error('[CstaSvc]: Invalid device ID (' + deviceId + '). Cannot determine position.');
                return Targets.Other;
            }

            var position = Targets.Other;
            var tmp = extractOnd(deviceId);
            if (!tmp || !tmp[1]) {
                position = Targets.Desk;
            } else {
                var ond = tmp[1];
                if (ond === _osmoData.osmoFQN) {
                    if (epid && epid !== _osmoData.epid) {
                        // This is a call on another OSMO
                        position = Targets.Other;
                    } else if (isOSBizCallWithAternativeNumber(direction)) {
                        // OSBiz: DE/EE in call to alternative number is the same as for local call.
                        // Call cannot be picked up locally.
                        position = Targets.Cell;
                    } else {
                        // This is a call on WebRTC
                        position = Targets.WebRTC;
                    }

                } else if (ond === _osmoData.cell) {
                    position = Targets.Cell;
                } else if (ond === _osmoData.parkingDn) {
                    position = Targets.Queue;
                } else if (_osmoData.osmoDeviceList && _osmoData.osmoDeviceList.includes(ond)) {
                    position = Targets.Desk;
                }
            }

            LogSvc.debug('[CstaSvc]: Call position is:', position.name);
            return position;
        }

        function getDisplayInfo(deviceId) {
            var display = {
                fqn: '',                     // Fully Qualified Number
                dn: _numberNotAvailable,    // Display Number
                name: ''                     // Display Name
            };

            // deviceId is not valid if the CSTA device Id is <notKnown/> or <restricted/>
            if (deviceId) {
                // CSTA Device Id Pattern
                var pattern = /N<([^>]*)>([^;]*)((?:[^;]*;)*(?:displayNumber=(\d*)))?(?:;.*)*/;
                var tmp = pattern.exec(deviceId);
                if (tmp) {
                    if ($rootScope.localUser.isOsBizCTIEnabled && tmp[1]) {
                        // osbiz sends number as ons/ond if the peer is in the same osbiz and configured in a mulap. Use ons only
                        tmp[1] = tmp[1].replace(/\/.*/, '');
                    }

                    display.fqn = tmp[1] || '';
                    display.name = tmp[2] || '';
                    display.dn = tmp[4] || tmp[1] || _numberNotAvailable;
                } else {
                    //4K sends N+1122334455 if name is not available
                    pattern = /N([^;]*)/;
                    tmp = pattern.exec(deviceId);
                    if (tmp) {
                        display.fqn = tmp[1] || '';
                        display.dn = tmp[1] || _numberNotAvailable;
                    } else {
                        display.fqn = deviceId;
                        display.dn = deviceId;
                    }
                }
            }


            LogSvc.debug('[CstaSvc]: Retrieved partner display: ', display);
            return display;
        }

        function updateDisplayNameFromPeerUser(display, peerUser) {
            // If no display name is available from the CSTA event check if it is already available from peerUser
            if (!display.name && display.dn !== _numberNotAvailable && peerUser.displayName &&
                (peerUser.displayName !== 'anonymous' && peerUser.displayName !== _numberNotAvailable)) {
                display.name = peerUser.displayName;
            }
        }

        function checkSSTIsPrivateDevice2(event, direction) {
            var call;
            //In case of outgoing call when PR2 is present for OS4K
            if (direction === CallDirection.OUTGOING && (event.presentationRestrictedDevice2 || event.presentationRestrictedDevice13)) {
                //Blind transfer cases
                if (event.cause === 'singleStepTransfer' && event.localConnectionInfo === 'connected') {
                    call = findWebRTCCall(event.connection.cID) || _atcRemoteCalls[event.connection.cID];
                    if (call) {
                        return true;
                    }
                //NGTC-4099: Single step transfer established event
                } else if (event.name === 'EstablishedEvent' && event.cause === 'redirected' && event.localConnectionInfo === 'connected') {
                    call = findWebRTCCall(event.establishedConnection.cID) || _atcRemoteCalls[event.establishedConnection.cID];
                    if (call) {
                        return true;
                    }
                //NGTC-3750: DNIT with agent supress number
                } else if (event.presentationRestrictedDevice2 && event.calledDevice &&
                    getDisplayInfo(event.presentationRestrictedDevice2).dn !== getDisplayInfo(event.calledDevice).dn) {
                    return true;
                //NGTC-4278: OS4k - Number is displayed in CCW when the call is forwarded to external secret number
                //(2nd Delivered or Established Event has no info for pr1 or pr2 instead we are having pr13)
                } else if (event.presentationRestrictedDevice13 && event.calledDevice &&
                    getDisplayInfo(event.presentationRestrictedDevice13).dn !== getDisplayInfo(event.calledDevice).dn) {
                    return true;
                }
            }
            return false;
        }

        function findWebRTCCall(callId) {
            refreshData();
            // Find the local WebRTC call that matches the callId
            var calls = CircuitCallControlSvc.getPhoneCalls();
            var unassociatedCalls = [];
            for (var idx = 0; idx < calls.length; idx++) {
                if (!calls[idx].atcCallInfo || !calls[idx].atcCallInfo.getCstaCallId()) {
                    var redirectingUser = calls[idx].getRedirectingUser();
                    calls[idx].atcCallInfo = new AtcCallInfo();
                    if (redirectingUser) {
                        calls[idx].atcCallInfo.redirectingUser = redirectingUser;
                    }
                    unassociatedCalls.push(calls[idx]);
                } else if (calls[idx].atcCallInfo.getCstaCallId() === callId) {
                    _activeCall = calls[idx];
                    return _activeCall;
                }
            }
            if (_alertingCall && _alertingCall.atcCallInfo.getCstaCallId() === callId) {
                return _alertingCall;
            } else if (_alertingCall && !_alertingCall.atcCallInfo.getCstaCallId()) {
                unassociatedCalls.push(_alertingCall);
            }

            return unassociatedCalls[0];
        }

        function findLocalCallByCstaCID(localConnection) {
            var call = _calls.find(function (c) {
                return c.atcCallInfo && c.atcCallInfo.getCstaCallId() === localConnection.cID;
            });
            //If call is not found in the _calls array check if it is the last ended call.
            if (!call && _lastEndedCall && _lastEndedCall.atcCallInfo &&
                _lastEndedCall.atcCallInfo.getCstaCallId() === localConnection.cID) {
                call = _lastEndedCall;
            }
            //_lastEnded call is for local call, check also webRTC remote calls
            !call && (call = findWebRTCRemoteCall(localConnection.cID));
            return call;
        }

        function isForwardingImmediate(forwardingType) {
            return forwardingType === 'forwardImmediate' ||
                ($rootScope.localUser.isOsBizCTIEnabled && (forwardingType === 'forwardImmInt' || forwardingType === 'forwardImmExt'));
        }

        function isForwardingNoAnswer(forwardingType) {
            return forwardingType === 'forwardNoAns';
        }

        function isOSBizCallWithAternativeNumber(direction) {
            return $rootScope.localUser.isOsBizCTIEnabled && $rootScope.localUser.reroutingPhoneNumber &&
                isSelectedRouting(RoutingOptions.AlternativeNumber) && direction === CallDirection.INCOMING;
        }

        function snapshotRemoteCalls() {
            var sendGLDI = true;
            clearAtcRemoteCalls();
            refreshData();
            _that.snapshotDevice(function (snapshotDevErr, snapshotDevResp) {
                _snapshotPerformed = true;
                if (snapshotDevErr) {
                    if (snapshotDevErr.indexOf('deviceOutOfService') > -1) {
                        _onsUnregistered = true;
                        publishCstaDeviceChanged();
                    }
                    return;
                } else {
                    // With OSV-8235, OSV is enhanced to send a new field in the private data of the snapshot device
                    // response to indicate if the desk phone is registered or not
                    if (snapshotDevResp.hasOwnProperty('deskPhoneRegistered')) {
                        _onsUnregistered = !snapshotDevResp.deskPhoneRegistered;
                        sendGLDI = false;
                    } else {
                        _onsUnregistered = $rootScope.localUser.isOsBizCTIEnabled && !_osmoData.osmoDeviceList;
                    }
                    publishCstaDeviceChanged();
                }

                // If the ONS is a profile only OSV subscriber, the snapshot device response cannot be used to know
                // if there is a registered desk phone. We need to send GetLogicalDeviceInformation request.
                if (sendGLDI && $rootScope.localUser.isOSV && !_onsUnregistered) {
                    _that.getLogicalDeviceInformation();
                }

                if (!snapshotDevResp.activeCalls) {
                    return;
                }

                try {
                    snapshotDevResp.activeCalls.forEach(function (call) {
                        if (call.localCallState.length < 2) {
                            return;
                        }

                        if (call.localCallState.length === 2) {
                            if ((VALID_LOCAL_STATES.indexOf(call.localCallState[0]) === -1) ||
                                (VALID_PARTNER_STATES.indexOf(call.localCallState[1]) === -1)) {
                                return;
                            }
                        }

                        _that.snapshotCall(call.connectionId, function (snapshotCallErr, snapshotCallResp) {
                            if (!snapshotCallErr && snapshotCallResp) {
                                var newCall = createCallFromSnapshot(snapshotCallResp);
                                if (newCall) {
                                    // Notify the UI about the remote call
                                    publishAtcCallInfo(newCall);
                                }
                            }
                        });
                    });
                } catch (e) {
                    LogSvc.error(e);
                }
            });
        }

        function isDNDSupported() {
            return $rootScope.localUser.isOsBizCTIEnabled && $rootScope.localUser.pbxDNDSupported;
        }

        function isOSBizCallRoutingSupported() {
            return $rootScope.localUser.pbxCallRoutingOptionsSupported;
        }

        function getAgentStatePromise(device) {
            return new $q(function (resolve) {
                _that.getAgentState(device, function (err, data) {
                    if (err) {
                        LogSvc.error('[CstaSvc]: Failed to get Agent State. ', err);
                    }
                    resolve(data || null);
                });
            });
        }

        function getOSBizDeviceState() {
            // Check device state of client and desk phone if mulap user
            if (!isOSBizCallRoutingSupported() || !_osmoData || !_osmoData.osmoDeviceList || _osmoData.osmoDeviceList.length <= 1) {
                return;
            }

            var agentStatePromises = [
                getAgentStatePromise(_osmoData.osmoDeviceList[0]), // Desk
                getAgentStatePromise(_osmoData.osmoDeviceList[1])  // Client
            ];

            $q.all(agentStatePromises)
            .then(function (agentStates) {
                var oldRoutingOption = $rootScope.localUser.selectedRoutingOption;

                // Check desk
                var data = agentStates[0];
                if (data) {
                    if (data.agentState !== AgentState.Ready) {
                        setRoutingOption(RoutingOptions.CircuitClient);
                    } else if (isSelectedRouting(RoutingOptions.CircuitClient)) {
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    }
                }
                // Check client
                data = agentStates[1];
                if (data) {
                    if (data.agentState !== AgentState.Ready) {
                        setRoutingOption(RoutingOptions.DeskPhone);
                    } else if (isSelectedRouting(RoutingOptions.DeskPhone)) {
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    }
                }

                if ($rootScope.localUser.selectedRoutingOption !== oldRoutingOption) {
                    LogSvc.debug('[CstaSvc]: Publish /csta/agentStateUpdated');
                    PubSubSvc.publish('/csta/agentStateUpdated');
                }
            });
        }

        function snapshotRemoteCallsAndGetFeatureData() {
            snapshotRemoteCalls();
            _that.getForwarding(handleGetForwardingResp);

            if (!$rootScope.localUser.isOsBizCTIEnabled) {
                _that.getDoNotDisturb(handleGetDoNotDisturbResp);
                _that.getAgentState(null, handleGetAgentStateResponseATC);
            }
        }

        function buildNewDestination(target, destination, withoutEpid, withOSBizTargetDevice) {
            var newDestination = !$rootScope.localUser.isOsBizCTIEnabled ? _osmoData.onsFQN + ';ond=' : '';

            switch (target) {
            case Targets.WebRTC:
                if (withOSBizTargetDevice && _osmoData.osmoDeviceList && _osmoData.osmoDeviceList.length > 1) {
                    newDestination = 'N<' + _osmoData.onsFQN + '/' + _osmoData.osmoDeviceList[1] + '>';
                } else if (withoutEpid) {
                    newDestination += _osmoData.osmoFQN;
                } else {
                    newDestination += _osmoData.osmoFQN + ';epid=' + _osmoData.epid;
                }
                break;

            case Targets.Cell:
                if ($rootScope.localUser.isOsBizCTIEnabled && $rootScope.localUser.reroutingPhoneNumber) {
                    if (isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                        if (_osmoData.osmoDeviceList && _osmoData.osmoDeviceList.length > 1) {
                            newDestination = 'N<' + _osmoData.onsFQN + '/' + _osmoData.osmoDeviceList[1] + '>';
                        } else {
                            newDestination = _osmoData.onsFQN;
                        }
                    } else {
                        newDestination = $rootScope.localUser.reroutingPhoneNumber;
                    }
                } else if (_osmoData.cell) {
                    newDestination += _osmoData.cell;
                } else {
                    LogSvc.error('[CstaSvc]: Cell phone is not configured');
                    return null;
                }
                break;

            case Targets.Desk:
                newDestination += _osmoData.onsFQN;
                if (withOSBizTargetDevice && _osmoData.osmoDeviceList) {
                    newDestination = 'N<' + newDestination + '/' + _osmoData.osmoDeviceList[0] + '>';
                }
                break;

            case Targets.VM:
                newDestination = _osmoData.vm;
                break;

            case Targets.Other:
                if (destination) {
                    newDestination += destination;
                } else {
                    // If destination is not set, Targets.Other means deflect to Voice Mail in the UI
                    newDestination = _osmoData.vm;
                }
                break;

            default:
                LogSvc.error('[CstaSvc]: Invalid target: ' + target);
                return null;
            }
            return newDestination;
        }

        function isDssCallMove(localConnectionInfo, calling, called) {
            if ($rootScope.localUser.isOsBizCTIEnabled && localConnectionInfo === 'connected' &&
                getCallPosition(calling) === Targets.Desk && getCallPosition(called) === Targets.WebRTC) {
                LogSvc.debug('[CstaSvc]: DSS call move from desk to client');
                return true;
            }
            return false;
        }

        // Calculate transfer-failed CallId
        function calculateTransferFailCallId(newCallId) {
            // FailedCallId = transferredCallId's first 2 hex byte + 2
            var pre = (parseInt(newCallId.substring(0, 2), 16) + 2).toString(16).toUpperCase();
            var post = newCallId.substring(2);
            var failedCallId;
            if (pre.length < 2) {
                failedCallId = '0' + pre + post;
            } else {
                failedCallId = pre + post;
            }
            LogSvc.debug('[CstaSvc]: FailedCallId to be tracked: ' + failedCallId);
            return failedCallId;
        }

        function getRegistrationData(cb) {
            Object.assign(_osmoData, AtcRegistrationSvc.getAtcRegistrationData());
            _regData = {
                subscriber: $rootScope.localUser.cstaNumber
            };
            if (!_regData.subscriber || !_osmoData) {
                cb && cb('There is no registered user.');
                return false;
            }
            return true;
        }

        function sendCstaRequest(msg, cb, keysToOmitFromLogging) {
            var cstaRq = _cstaParser.genCstaRequest(msg);
            if (!cstaRq) {
                LogSvc.error('[CstaSvc]: Failed to build CSTA request: ' + msg.request);
                cb && cb('Failed to send the request');
                return;
            }

            if (_regState !== AtcRegistrationState.Registered) {
                cb && cb('Client is not registered with ATC');
                return;
            }

            var data = {
                content: {
                    type: AtcMessage.CSTA,
                    phoneNumber: $rootScope.localUser.cstaNumber,
                    CSTA: cstaRq
                },
                destUserId: $rootScope.localUser.associatedTelephonyUserID
            };

            if (keysToOmitFromLogging) {
                data.keysToOmitFromLogging = keysToOmitFromLogging.map(function (k) {
                    return 'content.CSTA.' + k;
                });
            }

            // Send request
            _userToUserHandler.sendAtcRequest(data, function (error, rs) {
                $rootScope.$apply(function () {
                    if (error) {
                        LogSvc.warn('[CstaSvc]: ', error);
                        cb && cb(error);
                    } else {
                        var parsedResp = _cstaParser.parseResponse(msg.request, rs.CSTA);
                        if (!parsedResp && parsedResp !== '') {
                            cb && cb('Failed to parse response from server');
                        } else if (parsedResp.error) {
                            if (parsedResp.errorCategory === 'Operation Error' && parsedResp.errorValue === 'invalidConnectionID') {
                                if (msg.reason !== 'busy' && msg.request !== 'AcceptCall' && msg.request !== 'DeflectCall') {
                                    snapshotRemoteCalls();
                                }
                            }
                            cb && cb(parsedResp.errorCategory + ' - ' + parsedResp.errorValue);
                        } else {
                            LogSvc.debug('[CstaSvc]: Parsed CSTA Response: ', parsedResp);
                            cb && cb(null, parsedResp);
                        }
                    }
                });

            });
        }

        // Store Transferred Calls initiated from the client
        // For SingleStepTransfer and SilentHandover, we need to track the transfer-failed CallId
        // To determine the transfer result
        function storeTransferredCall(callId, rs) {
            if (rs.transferredCall && !rs.seamlessHandover) {
                _transferredCalls[callId] = {
                    newCallId: rs.transferredCall.cID,
                    failedCallId: calculateTransferFailCallId(rs.transferredCall.cID),
                    timeout: $timeout(function () {
                        delete _transferredCalls[callId];
                    }, TRANSFER_TIMEOUT)
                };
            }
        }

        function isTargetAllowed(call, target) {
            if (!call || !getRegistrationData()) {
                return false;
            }

            if (target !== Targets.Other && call.isAtPosition(target)) {
                return false;
            }

            var myDevices = getMyDevices();
            if (myDevices.includes(target)) {
                return true;
            } else {
                LogSvc.error('[CstaSvc]: isTargetAllowed invoked with invalid target: ' + target);
                return false;
            }
        }

        function getDeviceFromDeviceId(deviceId) {
            var device = deviceId;
            if ($rootScope.localUser.isOsBizCTIEnabled) {
                var tmp = extractOnd(deviceId);
                if (tmp && tmp[1]) {
                    device = tmp[1];
                }
            }
            return device;
        }

        function syncRoutingOption() {
            if ($rootScope.localUser.isOsBizCTIEnabled) {
                return;
            }
            LogSvc.debug('[CstaSvc]: Synchronize the selected routing option');
            _that.updateRoutingOption($rootScope.localUser.selectedRoutingOption);
        }

        function publishAtcCallInfo(call) {
            if (!call) {
                return;
            }
            // Notify the UI about the remote call
            LogSvc.debug('[CstaSvc]: Publish /atccall/info event');
            PubSubSvc.publish('/atccall/info', [call]);
        }

        function publishCstaDeviceChanged() {
            var hasDesk = !_onsUnregistered;
            var hasCell = !!((_osmoData && _osmoData.cell) || $rootScope.localUser.reroutingPhoneNumber);

            LogSvc.debug('[CstaSvc]: Publish /csta/deviceChange event. desk=' + hasDesk + ', cell=' + hasCell);
            PubSubSvc.publish('/csta/deviceChange');
        }

        function getCstaDeviceId() {
            if (!$rootScope.localUser.isOsBizCTIEnabled) {
                return $rootScope.localUser.isOSV ? Utils.normalizeDn($rootScope.localUser.cstaNumber) :
                    $rootScope.localUser.cstaNumber;
            } else {
                return _osmoData.onsFQN;
            }
        }

        function moveOsBizCalls(callId) {
            var localCall = CircuitCallControlSvc.findOsBizFirstCall();
            var secondCall = CircuitCallControlSvc.findOsBizSecondCall();
            if (localCall && secondCall) {
                if (localCall.atcCallInfo.getCstaCallId() === callId) {
                    LogSvc.debug('[CstaSvc]: First local call with callId: ', callId);
                    LogSvc.debug('[CstaSvc]: Second local call with callId: ', secondCall.atcCallInfo.getCstaCallId());
                    CircuitCallControlSvc.moveOsBizCalls(localCall, secondCall);
                    return true;
                } else if (secondCall.atcCallInfo.getCstaCallId() === callId) {
                    LogSvc.debug('[CstaSvc]: Second local call with callId: ', callId);
                    LogSvc.debug('[CstaSvc]: First local call with callId: ', localCall.atcCallInfo.getCstaCallId());
                    CircuitCallControlSvc.moveOsBizCalls(secondCall, localCall);
                    return true;
                }
            } else if (!localCall && !secondCall) {
                var remoteCall = findWebRTCRemoteCall();
                if (remoteCall && remoteCall.atcCallInfo && remoteCall.atcCallInfo.getCstaCallId() === callId) {
                    LogSvc.debug('[CstaSvc]: Remote call with callId: ', callId);
                    var remoteHeldCall = findAtcRemoteHeldCall() || findOtherAtcRemoteCall(callId);
                    if (remoteHeldCall) {
                        LogSvc.debug('[CstaSvc]: Second remote call with callId: ', remoteHeldCall.atcCallInfo.getCstaCallId());
                        var origAtcCallInfo = remoteCall.atcCallInfo;
                        remoteCall.atcCallInfo = remoteHeldCall.atcCallInfo;
                        remoteCall.setPeerUser(remoteCall.atcCallInfo.peerDn, remoteCall.atcCallInfo.peerName);
                        remoteHeldCall.atcCallInfo = origAtcCallInfo;
                        delete _atcRemoteCalls[remoteCall.atcCallInfo.getCstaCallId()];
                        _atcRemoteCalls[origAtcCallInfo.getCstaCallId()] = remoteHeldCall;
                        removeAtcRemoteCall(remoteHeldCall.atcCallInfo.getCstaCallId());
                        return true;
                    }
                }
            }
            return false;
        }

        function isCallAlreadyNotified(callingFqn, callingName) {
            // Checks if there is a already a handled notification for this call. With 4K the team notifications don't
            // have the FQN, so we need to check if the name is the same
            return Object.values(_atcRemoteCalls).some(function (call) {
                return call.atcCallInfo.pickUp && ($rootScope.localUser.isOSV ?
                    call.atcCallInfo.peerFQN === callingFqn : call.atcCallInfo.peerName === callingName);
            });
        }

        function notifyCall(data) {
            LogSvc.debug('[CstaSvc]: New pickup call for: ', data.calledDisplay.displayName);
            var call = createAtcRemoteCall(data.callId);
            call.atcCallInfo.pickUp = true;
            call.setPosition(Targets.Other);
            call.atcCallInfo.setPartnerDisplay(data.callingDisplay);
            _atcRemoteCalls[data.callId] = call;
            if (data.event) {
                // Notification of OSV Group Pickup feature
                call.atcCallInfo.setServicesPermitted(data.event.servicesPermitted);
                call.atcCallInfo.setCstaConnection(data.event.connection);
            } else if (data.callingUserId) {
                // Notification of Team Pickup feature
                call.pickupSession = data.callId;
                call.peerGtcUserId = data.callingUserId;
                call.setRedirectingUser(data.calledDisplay.dn, data.calledDisplay.fqn, data.calledDisplay.name, data.calledUserId, RedirectionTypes.CallPickupNotification);
            }

            call.setCstaState(CstaCallState.Ringing);
            call.direction = CallDirection.INCOMING;

            call.atcCallInfo.setOriginalPartnerDisplay(data.calledDisplay);
            setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName, function () {
                if (!data.callingUserId) {
                    setRedirectingUser(call, data.calledDisplay.dn, data.calledDisplay.fqn, data.calledDisplay.name, RedirectionTypes.CallPickupNotification, function () {
                        showPickupNotification(call);
                    });
                } else {
                    showPickupNotification(call);
                }
            });
        }

        function isTwoPartyCall(call) {
            return call.isRemote && call.participants.length === 1 || !call.isRemote && call.participants.length === 2;
        }

        function checkTransferCallId(droppedConnection, cause) {
            var transferCallId = _trackTransferCallIds[droppedConnection.cID] || _trackTransferCallIds[DEFAULT_CALCULATED_TRANSFER_CALLID];
            if (transferCallId) {
                var call = createAtcRemoteCall(transferCallId);
                call.atcCallInfo.setCstaConnection(droppedConnection);
                // cause = normal indicate Transfer is successful
                // the other causes will reflect the failure reason of transfer - FRN5227 is a prerequisite to set cause correctly
                if (cause !== 'normalClearing') {
                    call.setCstaState(CstaCallState.TransferFailed);
                    call.atcCallInfo.setTransferCallFailedCause(cause);
                }
                publishAtcCallInfo(call);

                delete _trackTransferCallIds[droppedConnection.cID];
                delete _trackTransferCallIds[DEFAULT_CALCULATED_TRANSFER_CALLID];
            }
        }

        function isDeliveredForOsBizParallelRinging(cause, position, callId, direction) {
            if ($rootScope.localUser.isOsBizCTIEnabled && (cause === 'keyOperation' || cause === 'callForwardNoAnswer' || cause === 'singleStepTransfer') &&
                position === Targets.Desk && direction === CallDirection.INCOMING) {
                if (!_atcRemoteCalls[callId] && !isSelectedRouting(RoutingOptions.DeskPhone)) {
                    LogSvc.debug('[CstaSvc]: This event is received for parallel ringing on device.');
                    return true;
                }
            }
            return false;
        }

        function isForwardedOrOsBizSecondCall(call, event) {
            if (call.direction === CallDirection.OUTGOING && FORWARD_EXPR.test(event.cause)) {
                var divertingDeviceDisplay = getDisplayInfo(event.divertingDevice);
                setRedirectingUser(call, divertingDeviceDisplay.dn, divertingDeviceDisplay.fqn, divertingDeviceDisplay.name, RedirectionTypes.CallForward);
                var newDestinationDisplay = getDisplayInfo(event.newDestination);
                call.setPeerUser(newDestinationDisplay.dn, newDestinationDisplay.name);
                call.atcCallInfo.setOriginalPartnerDisplay(divertingDeviceDisplay);
                return true;
            } else if ($rootScope.localUser.isOsBizCTIEnabled && call.direction === CallDirection.INCOMING &&
                call.isOsBizSecondCall && isMyDeviceId(event.divertingDevice) && moveOsBizCalls(event.connection.cID)) {
                LogSvc.debug('[CstaSvc]: Second call was diverted and released');
                return true;
            }
            return false;
        }

        function isDistributed(event) {
            if (event.cause === 'distributed' && isMyDeviceId(event.divertingDevice) && getCallPosition(event.newDestination) === Targets.Desk) {
                LogSvc.debug('[CstaSvc]: Ignore Diverted event with cause = distributed and newDestination = desk');
                return true;
            }
            return false;
        }

        function setRedirected(divertedEvent, call) {
            if ((divertedEvent.cause === 'redirected') && divertedEvent.lastRedirectionDevice && !isMyDeviceId(divertedEvent.lastRedirectionDevice)) {
                var lastRedirectionDeviceDisplay = getDisplayInfo(divertedEvent.lastRedirectionDevice);
                setRedirectingUser(call, lastRedirectionDeviceDisplay.dn, lastRedirectionDeviceDisplay.fqn,
                    lastRedirectionDeviceDisplay.name, RedirectionTypes.CallForward);
                _atcRemoteCalls[divertedEvent.connection.cID] = call;
            }
            // else if (!$rootScope.localUser.isOsBizCTIEnabled && divertedEvent.calledDevice && !isMyDeviceId(divertedEvent.calledDevice) &&
            //         (FORWARD_EXPR.test(divertedEvent.cause) || divertedEvent.cause === 'redirected' || divertedEvent.cause === 'distributed')) {
            //     //NGTC-6280: Agent No forwarding info displayed when DNIT call is answered and phone was locked
            //     var redirectionDeviceId = divertedEvent.calledDevice;
            //     var redirectionDeviceDisplay = getDisplayInfo(redirectionDeviceId);
            //     setRedirectingUser(call, redirectionDeviceDisplay.dn, redirectionDeviceDisplay.fqn,
            //         redirectionDeviceDisplay.name, RedirectionTypes.CallForward);
            //     _atcRemoteCalls[divertedEvent.connection.cID] = call;
            // }
        }

        function determineCallCharacteristics(establishedEvent) {
            var callCharacteristics = {};
            if (isMyDeviceId(establishedEvent.callingDevice) && !isDssCallMove(establishedEvent.localConnectionInfo,
                establishedEvent.callingDevice, establishedEvent.answeringDevice)) {
                // We are the calling party
                callCharacteristics.localConnection = {
                    cID: establishedEvent.establishedConnection.cID,
                    dID: establishedEvent.callingDevice
                };
                // In case of OSV external anonymous user answeringDevice and calledDevice is empty
                callCharacteristics.partnerDeviceId = establishedEvent.answeringDevice ? establishedEvent.answeringDevice :
                    getDeviceFromDeviceId(establishedEvent.establishedConnection.dID);
                callCharacteristics.direction = CallDirection.OUTGOING;
                callCharacteristics.originalPartnerDisplay = getDisplayInfo(establishedEvent.calledDevice);

            } else if (isMyDeviceId(establishedEvent.answeringDevice)) {
                // We are the answering party
                callCharacteristics.localConnection = establishedEvent.establishedConnection;
                callCharacteristics.partnerDeviceId = getCallingDevice(establishedEvent);
                callCharacteristics.direction = CallDirection.INCOMING;
                callCharacteristics.originalPartnerDisplay = establishedEvent.presentationRestrictedDevice1 ?
                    getDisplayInfo() : getDisplayInfo(establishedEvent.callingDevice);
            } else {
                LogSvc.error('[CstaSvc]: ONS DN is neither in callingDevice nor in answeringDevice?!?');
            }
            return callCharacteristics;
        }

        function isMasterParallelHgCall(call) {
            return call && call.atcCallInfo && call.atcCallInfo.masterParallelHgCall;
        }

        function convertCstaCauseToReason(cause) {
            var reason = MissedReasonTypes.DEFAULT;
            switch (cause) {
            case 'busy':
                reason = MissedReasonTypes.BUSY;
                break;
            case 'reorderTone':
                reason = MissedReasonTypes.REORDER_TONE;
                break;
            case 'blocked':
                reason = MissedReasonTypes.DECLINED;
                break;
            case 'destOutOfOrder':
                reason = MissedReasonTypes.DEST_OUT_OF_ORDER;
                break;
            }
            return reason;
        }

        function checkForTransferCb(call) {
            if (call && call.atcCallInfo && call.atcCallInfo.transferCb) {
                call.atcCallInfo.transferCb && call.atcCallInfo.transferCb(null, null, call);
                delete call.atcCallInfo.transferCb;
            }
        }

        function setCstaStateFromLCO(call, event) {
            if (call) {
                if (event.localConnectionInfo === 'hold') {
                    call.setCstaState(CstaCallState.Holding);
                } else {
                    call.setCstaState(CstaCallState.Active);
                    call.clearRetrieveInProgress();
                }
            }
        }

        function setLocalCallAsPulled(callId, destination) {
            var localCall = findWebRTCCall(callId);
            if (localCall) {
                localCall.pulled = getCallPosition(destination) === Targets.WebRTC || isOSBizOns(destination);
                localCall.atcCallInfo.setIgnoreCall(false);
                LogSvc.debug('[CstaSvc]: Set localCall.pulled to ', localCall.pulled);
            }
        }
        ///////////////////////////////////////////////////////////////////////////
        // User to User event handlers
        ///////////////////////////////////////////////////////////////////////////
        _userToUserHandler.on('ATC.CSTA', function (data) {
            if (data.type === AtcMessage.CSTA && data.CSTA) {
                $rootScope.$apply(function () {
                    handleCstaEvent(data);
                });
            }
        });

        _userToUserHandler.on('ATC.PRIMARYCLIENT', function (data) {
            LogSvc.info('[CstaSvc]: Received PRIMARYCLIENT event:', data);
            if (data.type === AtcMessage.PRIMARYCLIENT && data.primaryClientId) {
                handlePrimaryClientEvent(data.primaryClientId);
            }
        });

        _userToUserHandler.on('ATC.ADVANCING', function (data) {
            if (data.type === AtcMessage.ADVANCING) {
                LogSvc.debug('[CstaSvc]: Received UserToUser ATC.ADVANCING');
                var call = CircuitCallControlSvc.getIncomingCall(data.rtcSessionId);
                if (call) {
                    _movingCall = {
                        newCallId: call.atcCallInfo.getCstaCallId(),
                        direction: call.direction,
                        redirectingUser: call.redirectingUser
                    };
                }
            }
        });

        _clientApiHandler.on('Team.NOTIFY', function (evt) {
            if (!evt.callingUser) {
                LogSvc.debug('[CstaSvc]: Ignore team notification for Circuit call');
                return;
            }
            if (!_telephonyConversation || !$rootScope.localUser.phoneNumber) {
                // If the user does not have telephony or does not have a DID number, it cannot pickup a team call
                LogSvc.error('[CstaSvc]: No telephony conversation or phoneNumber. Cannot handle team notification');
                return;
            }
            if ($rootScope.localUser.cfData.Immediate.cfwEnabled || $rootScope.localUser.doNotDisturbOn) {
                LogSvc.debug('[CstaSvc]: Ignore team notification as the user has call forwarding immediate or DND enabled');
                return;
            }
            if (isCallAlreadyNotified(evt.callingUser.fullyQualifiedNumber, evt.callingUser.displayName)) {
                LogSvc.debug('[CstaSvc]: There is already a pickup notification for this call. Ignore it.');
                return;
            }

            LogSvc.debug('[CstaSvc]: Handle the team notification for phone call');
            $rootScope.$apply(function () {
                UserSvc.getUserById(evt.calledUserId, function (err, calledUser) {
                    if (err || !calledUser) {
                        LogSvc.error('[CstaSvc]: Error getting called user');
                        return;
                    }
                    if (calledUser.userId === $rootScope.localUser.userId) {
                        LogSvc.debug('[CstaSvc]: Ignore team notification when called user is the local user');
                        return;
                    }
                    var callingDisplay = {
                        dn: evt.callingUser.phoneNumber,
                        fqn: evt.callingUser.fullyQualifiedNumber,
                        name: evt.callingUser.displayName
                    };
                    var calledDisplay = {
                        dn: calledUser.phoneNumber,
                        fqn: calledUser.fullyQualifiedNumber,
                        name: calledUser.displayName
                    };
                    notifyCall({
                        callingDisplay: callingDisplay,
                        calledDisplay: calledDisplay,
                        callId: evt.rtcSessionId,
                        callingUserId: evt.callingUserId
                    });
                });
            });
        });

        _clientApiHandler.on('Team.NOTIFY_CANCEL', function (evt) {
            var callToPickup = _atcRemoteCalls[evt.rtcSessionId];
            if (callToPickup && !callToPickup.isHandoverInProgress) {
                // If the call is being picked up, the event should be ignored
                $rootScope.$apply(function () {
                    removeAtcRemoteCall(evt.rtcSessionId);
                });
            }
        });

        function isVMNumber(number) {
            return number === _osmoData.vm || (!!_osmoData.vmGNF && number === _osmoData.vmGNF);
        }

        ///////////////////////////////////////////////////////////////////////////
        // Event handlers
        ///////////////////////////////////////////////////////////////////////////
        function handleCstaEvent(data) {
            if (!data.CSTA) {
                return;
            }
            var eventData = _cstaParser.parseEvent(data.CSTA);

            // Supress any private data from logs
            data.CSTA.keysToOmitFromLogging = ['DGE.dGL'];
            LogSvc.debug('[CstaSvc]: Received CSTA event: ', data.CSTA);

            if (!eventData) {
                return;
            }
            LogSvc.debug('[CstaSvc]: Parsed CSTA Event: ', eventData);
            delete eventData.keysToOmitFromLogging;
            switch (eventData.category) {
            case 'CallControl':
            case 'CallAssociatedFeature':
                _that.handleCallEvent(eventData);
                break;
            case 'LogicalDeviceFeature':
                _that.handleLogicalDeviceEvent(eventData);
                break;
            case 'PhysicalDeviceFeature':
                _that.handlePhysicalDeviceEvent(eventData);
                break;
            case 'DeviceMaintenance':
                _that.handleDeviceEvent(eventData);
                break;
            default:
                LogSvc.debug('[CstaSvc]: Unsupported CSTA Event category: ' + eventData.category);
                break;
            }
        }

        function handlePrimaryClientEvent(primaryClientId) {
            if ($rootScope.localUser.clientId !== primaryClientId) {
                LogSvc.debug('[CstaSvc]: This is not the Primary ATC client');
                _primaryClient = false;
            } else {
                LogSvc.debug('[CstaSvc]: This is the primary ATC client');
                _primaryClient = true;

                if ($rootScope.localUser.isOsBizCTIEnabled) {
                    setBusy(_userBusyState);
                    isDNDSupported() && _that.getDoNotDisturb(handleGetDoNotDisturbResp);
                    getOSBizDeviceState();
                }
            }
        }

        function handleCallInformationEvent(event) {
            // CallInformation usually updates the services permitted
            var callId = event.connection.cID;
            var call = null;
            if (event.pickupNotificationEarly) {
                // This a Group Pickup notification
                if (isMyDeviceId(event.connection.dID)) {
                    LogSvc.debug('[CstaSvc]: The pickup notification is for a call to this user. Ignore it');
                    return;
                }

                if (isMyDeviceId(event.callingDevice)) {
                    // OS4K in some scenarios sends call pickup notification to the calling party, which we need to ignore
                    LogSvc.debug('[CstaSvc]: The calling party of this pickup notification call is this user. Ignore it');
                    return;
                }
                call = _atcRemoteCalls[callId];
                var calledDisplay = getDisplayInfo(event.connection.dID);
                var callingDisplay;
                if (event.presentationRestrictedDevice1) {
                    LogSvc.debug('[CstaSvc]: Calling device is private.');
                    callingDisplay = getDisplayInfo();
                } else {
                    callingDisplay = getDisplayInfo(event.callingDevice);
                }
                if (!call) {
                    if (isCallAlreadyNotified(callingDisplay.fqn, callingDisplay.name)) {
                        LogSvc.debug('[CstaSvc]: There is already a pickup notification for this call. Ignore it');
                        return;
                    }

                    notifyCall({
                        callingDisplay: callingDisplay,
                        calledDisplay: calledDisplay,
                        callId: callId,
                        evt: event
                    });
                } else {
                    LogSvc.debug('[CstaSvc]: Existing pickup call for: ', calledDisplay.dn);
                    call.atcCallInfo.setCstaConnection(event.connection);
                }
                return;
            }
            var position = getCallPosition(event.connection.dID, event.epid);
            call = position === Targets.WebRTC ? findWebRTCCall(callId) : _atcRemoteCalls[callId];

            if (!call) {
                LogSvc.warn('[CstaSvc]: Could not find call corresponding to CallInformation Event.');
                return;
            }
            call.atcCallInfo.setCstaConnection(event.connection);
            if (event.callingDevice && !isMyDeviceId(event.callingDevice)) {
                var display;
                // callingDevice is the partner
                if (event.presentationRestrictedDevice1) {
                    LogSvc.debug('[CstaSvc]: Calling device is private.');
                    display = getDisplayInfo();
                } else {
                    display = getDisplayInfo(event.callingDevice);
                }
                call.atcCallInfo.setPartnerDisplay(display);
                setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName);
            }

            if (event.servicesPermitted) {
                // Update Services Permitted
                call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
                if (event.servicesPermitted.eSP && event.servicesPermitted.eSP.sST) {
                    // FRN5227 is a prerequisite and SST is not allowed if it's in a conference
                    // So when SST is allowed, it's not in a conference anymore
                    if (call.checkCstaState(CstaCallState.ConferenceHolding)) {
                        call.setCstaState(CstaCallState.Holding);
                    } else if (call.checkCstaState(CstaCallState.Conference)) {
                        call.setCstaState(CstaCallState.Active);
                    }
                }
            }

            // Update the UI
            if (call.checkCstaState([CstaCallState.Offered, CstaCallState.ExtendedRinging, CstaCallState.Idle])) {
                LogSvc.debug('[CstaSvc]: Do not publish /atccall/info event at Offered state');
            } else {
                publishAtcCallInfo(call);
            }
        }

        function handleConferencedEvent(event) {
            if (event.secondaryOldCall) {
                // At this point, OSBiz has a local call and 2 csta calls. Remove the secondaryOldCall csta call.
                if ($rootScope.localUser.isOsBizCTIEnabled && moveOsBizCalls(event.secondaryOldCall.cID)) {
                    LogSvc.debug('[CstaSvc]: Cleared secondary atc call with Call Id = ', event.secondaryOldCall.cID);
                } else {
                    // We are the conferencing party, remove the secondaryOldCall
                    removeAtcRemoteCall(event.secondaryOldCall.cID);
                }
            }

            // update the call in primaryOldCall with the one in conferenceConnections
            var call = null;
            var position = getCallPosition(event.primaryOldCall.dID, event.epid);
            // With OS4K the conference call is the second local call of the conference initiator
            var callId = $rootScope.localUser.isOSV || $rootScope.localUser.isOsBizCTIEnabled || position !== Targets.WebRTC
                || !event.secondaryOldCall ? event.primaryOldCall.cID : event.secondaryOldCall.cID;

            if (position === Targets.WebRTC) {
                call = findWebRTCCall(callId);
            } else {
                call = _atcRemoteCalls[callId];
            }

            if (!call) {
                LogSvc.debug('[CstaSvc]: Could not find a call with Call Id = ' + callId);
                return;
            }

            // Update the CSTA Connection ID for the call.
            event.conferenceConnections.forEach(function (confConn) {
                if ((confConn.endpoint && isMyDeviceId(confConn.endpoint)) || (confConn.connection && isMyDeviceId(confConn.connection.dID))) {
                    call.atcCallInfo.setCstaConnection(confConn.connection);
                    if (call.isAtcRemote) {
                        var atcCallInfo = call.atcCallInfo;
                        var peerUser = call.peerUser;
                        var participants = call.participants;
                        var direction = call.direction;
                        call.setCstaState(CstaCallState.Idle);

                        publishAtcCallInfo(call);
                        delete _atcRemoteCalls[callId];

                        call = createAtcRemoteCall(confConn.connection.cID);
                        call.atcCallInfo = atcCallInfo;
                        call.peerUser = peerUser;
                        call.participants = participants;
                        call.direction = direction;
                        _atcRemoteCalls[confConn.connection.cID] = call;
                    }
                    LogSvc.info('[CstaSvc]: Updated the CSTA connection ID for the call: ', confConn.connection);
                } else {
                    var participantDisplay;
                    if (confConn.endpoint && (confConn.endpoint.rstr === '')) {
                        //Handling for restricted number
                        participantDisplay = getDisplayInfo();
                    } else {
                        participantDisplay = confConn.endpoint && getDisplayInfo(confConn.endpoint) || getDisplayInfo(confConn.connection.dID);
                    }
                    _conferenceParticipants.push({
                        call: call,
                        userId: confConn.connection.dID,
                        participantId: confConn.connection.dID,
                        phoneNumber: participantDisplay.dn,
                        displayName: participantDisplay.name
                    });

                    if (!_resolving) {
                        _resolving = true;
                        resolveParticipants();
                    }
                }
            });

            if (event.localConnectionInfo === 'hold') {
                call.setCstaState(CstaCallState.ConferenceHolding);
            } else {
                call.setCstaState(CstaCallState.Conference);
                if (!call.isRemote && $rootScope.localUser.isOsBizCTIEnabled) {
                    call.setState(CallState.Active);
                }
            }

            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

            publishAtcCallInfo(call);
        }

        function handleConnectionClearedEvent(event) {
            var callId = event.droppedConnection.cID;
            var call = findWebRTCCall(callId) || _atcRemoteCalls[callId];
            _incomingCallConnection = {};

            if (call) {
                if (call.pickupNotification && call.isHandoverInProgress) {
                    return;
                }
                if (event.pickupNotificationTerminated && (!event.droppedConnection.dID || isMyDeviceId(event.droppedConnection.dID))) {
                    // This a Group Pickup notification terminated event
                    LogSvc.debug('[CstaSvc]: The pickup notification terminated event is for this user. Ignore it');
                    return;
                }

                if (event.localConnectionInfo !== 'null') {
                    if ($rootScope.localUser.isOsBizCTIEnabled && event.cause === 'multipleAlerting') {
                        LogSvc.info('[CstaSvc]: Discarding ConnectionClearEvent due to parallel ringing. Do not overwrite services permitted.');
                        return;
                    }

                    LogSvc.info('[CstaSvc]: Update services permitted.');
                    call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

                    if (call.participants.length > 1) {
                        call.participants.some(function (p) {
                            if (p.participantId === event.droppedConnection.dID) {
                                call.removeParticipant(p.userId);
                                return true;
                            }
                            return false;
                        });
                        if (isTwoPartyCall(call)) {
                            var lastParticipant = call.participants[call.isRemote ? 0 : 1];
                            call.removeParticipant(lastParticipant.userId);
                            setCallPeerUser(call, lastParticipant.phoneNumber, lastParticipant.phoneNumber, lastParticipant.displayName);
                            if (call.checkCstaState(CstaCallState.Conference)) {
                                call.setCstaState(CstaCallState.Active);
                            } else if (call.checkCstaState(CstaCallState.ConferenceHolding)) {
                                call.setCstaState(CstaCallState.Holding);
                            }
                        }
                    }
                    if (!call.atcCallInfo.getMissedReason() && !call.establishedTime) {
                        if (call.direction === CallDirection.INCOMING) {
                            call.atcCallInfo.setMissedReason(isMyDeviceId(event.releasingDevice) ? MissedReasonTypes.DECLINED : MissedReasonTypes.CANCELLED);
                        } else {
                            call.atcCallInfo.setMissedReason(isMyDeviceId(event.releasingDevice) ? MissedReasonTypes.CANCELLED : MissedReasonTypes.DECLINED);
                        }
                    }
                    publishAtcCallInfo(call);
                    return;
                } else {
                    LogSvc.info('[CstaSvc]: Local connection info is null.');

                    LogSvc.info('[CstaSvc]: Update services permitted.');
                    call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

                    if (!call.atcCallInfo.getMissedReason() && !call.establishedTime) {
                        call.atcCallInfo.setMissedReason(call.direction === CallDirection.INCOMING && event.cause === 'normalClearing' ?
                            MissedReasonTypes.DECLINED : MissedReasonTypes.CANCELLED);
                    }
                    call.clearAtcHandoverInProgress();
                    call.atcCallInfo.clearOutgoingCallCampedOn();

                    if ($rootScope.localUser.isOsBizCTIEnabled) {
                        if (call.atcCallInfo.getCstaReconnectId().cID) {
                            // Call is released but there is a pending consultation call (local call hold using consultationCall case). Release that too.
                            // OSbiz will improve holdCall support with V2R7
                            var data = {
                                request: 'ClearConnection',
                                connectionToBeCleared: call.atcCallInfo.getCstaReconnectId()
                            };
                            sendCstaRequest(data);
                            call.atcCallInfo.setCstaReconnectId({});
                        }

                        if (_webrtcRemoteCall && _webrtcRemoteCall.checkState(CallState.ActiveRemote)) {
                            _webrtcRemoteCall.pullBlocked = false;
                        }

                        if (moveOsBizCalls(callId)) {
                            return;
                        }
                    }
                }
            }

            checkTransferCallId(event.droppedConnection, event.cause);
            removeAtcRemoteCall(callId);
        }

        // eslint-disable-next-line complexity, max-lines-per-function
        function handleDeliveredEvent(event) {
            var localConnection;
            var partnerDeviceId;
            var calledDeviceId;
            var redirectionDeviceId;
            var redirectionDeviceDisplay;
            var callState;
            var direction;
            var call = null;
            var resolvePeerUser;
            var resolveRedirectingUser;
            var redirectingUserDetails;
            var peerUserDetails;
            var setCallRedirectingPeerUserCb = function () {
                LogSvc.debug('[CstaSvc]: Publish /call/peerUser/update event.',
                    ['callId = ' + call.callId, 'peerUserId = ' + call.peerUser.userId, 'peerUser = ', call.peerUser, 'redirectingUser = ', call.redirectingUser]);
                PubSubSvc.publish('/call/peerUser/update', [call]);
            };

            if (isMyDeviceId(event.callingDevice) && !isDssCallMove(event.localConnectionInfo, event.callingDevice, event.alertingDevice)) {
                // outgoing call - My device is the calling party
                callState = CstaCallState.Delivered;
                // In case of OSV external anonymous user alertingDevice and calledDevice is empty
                partnerDeviceId = event.alertingDevice ? event.alertingDevice : getDeviceFromDeviceId(event.connection.dID);
                calledDeviceId = event.calledDevice;
                localConnection = {
                    cID: event.connection.cID,
                    dID: event.callingDevice
                };
                direction = CallDirection.OUTGOING;
            } else if (isMyDeviceId(event.alertingDevice)) {
                // incoming call - My device is the alerting party
                callState = CstaCallState.Ringing;
                partnerDeviceId = getCallingDevice(event);
                localConnection = event.connection;
                direction = CallDirection.INCOMING;

                // NGTC-3658: Event cause is callForwardNoAnswer for OS4K and redirected for OSV
                // In case of OSBiz setting an internal number as alternative is not supported
                // NGTC-4261: OS4k - Inconsistency in Call Control when an Agent receives a call through ACD
                if (!$rootScope.localUser.isOsBizCTIEnabled && !isMyDeviceId(event.calledDevice) &&
                    (FORWARD_EXPR.test(event.cause) || event.cause === 'redirected' || event.cause === 'distributed')) {
                    redirectionDeviceId = event.calledDevice;
                    redirectionDeviceDisplay = getDisplayInfo(redirectionDeviceId);

                }
            } else {
                LogSvc.error('[CstaSvc]: ONS DN is neither in callingDevice nor in alertingDevice?!?');
                return;
            }

            var position = getCallPosition(localConnection.dID, event.epid, direction);

            var display;
            if (event.presentationRestrictedDevice1 && direction === CallDirection.INCOMING || checkSSTIsPrivateDevice2(event, direction)) {
                LogSvc.debug('[CstaSvc]: Calling device is private.');
                display = getDisplayInfo();
            } else {
                display = getDisplayInfo(partnerDeviceId);
            }

            if (event.cause === 'enteringDistribution') {
                LogSvc.debug('[CstaSvc]: This event is for the master of the parallel hunt group');
                call = _atcRemoteCalls[localConnection.cID];
                if (!call) {
                    call = createAtcRemoteCall(localConnection.cID);
                    _atcRemoteCalls[localConnection.cID] = call;
                    LogSvc.debug('[CstaSvc]: Created new Remote Call object for callID= ', localConnection.cID);
                }
                call.setCstaState(callState);
                call.setPosition(position);
                call.atcCallInfo.setCstaConnection(localConnection);
                call.atcCallInfo.setIgnoreCall(false);
                call.atcCallInfo.masterParallelHgCall = true;
                return;
            }

            if (isDeliveredForOsBizParallelRinging(event.cause, position, localConnection.cID, direction)) {
                // OSBiz supports parallel ringing. Avoid processing Delivered event for a new remote call. Local call will be used for UI.
                return;
            }

            if (position === Targets.WebRTC) {
                // Local WebRTC call, check only _alertingCall
                call = findWebRTCCall(localConnection.cID);
                if (call) {
                    call.atcCallInfo.setCstaConnection(localConnection);
                    call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
                    call.setCstaState(callState);
                    call.atcCallInfo.setQueuedIncomingCall(false);
                    publishOsBizCallState(call, CallState.Delivered);
                    position = call.isRemote ? Targets.Other : position;
                    call.setPosition(position);
                    if (display) {
                        call.peerUser && updateDisplayNameFromPeerUser(display, call.peerUser);
                        LogSvc.debug('[CstaSvc]: Update partner display to ', display);
                        call.atcCallInfo.setPartnerDisplay(display);
                        var setCallPeerUserCb = function () {
                            //NGTC-4263: Publishing call state instead of atccall/info because in anonymous scenarios when call state is Idle,
                            //this will trigger the /call/ended flow
                            LogSvc.debug('[CstaSvc]: Publish /call/state event. callId = ' + call.callId + ', state = ' + call.state.name);
                            PubSubSvc.publish('/call/state', [call]);
                        };
                        resolvePeerUser = setCallPeerUserSync(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName,
                            setCallPeerUserCb);

                        var remoteCall = findAtcRemoteCall(localConnection.cID);
                        if (remoteCall) {
                            if (remoteCall.forwarded) {
                                resolveRedirectingUser = setRedirectingUserSync(call, remoteCall.redirectingUser.phoneNumber, remoteCall.redirectingUser.fqNumber,
                                    remoteCall.redirectingUser.displayName, remoteCall.redirectingUser.redirectionType);
                                if (resolveRedirectingUser) {
                                    redirectingUserDetails = {
                                        fqNumber: remoteCall.redirectingUser.fqNumber,
                                        phoneNumber: remoteCall.redirectingUser.phoneNumber,
                                        displayName: remoteCall.redirectingUser.displayName,
                                        type: remoteCall.redirectingUser.redirectionType
                                    };
                                }
                            }
                            remoteCall.atcCallInfo.setIgnoreCall(true);
                            removeAtcRemoteCall(localConnection.cID);
                            // NGTC-4261: OS4k - Inconsistency in Call Control when an Agent receives a call through ACD
                        } else if (redirectionDeviceId && event.cause === 'distributed') {
                            resolveRedirectingUser = setRedirectingUserSync(call, redirectionDeviceDisplay.dn, redirectionDeviceDisplay.fqn,
                                redirectionDeviceDisplay.name, RedirectionTypes.CallForward);
                            if (resolveRedirectingUser) {
                                redirectingUserDetails = {
                                    fqNumber: redirectionDeviceDisplay.fqn,
                                    phoneNumber: redirectionDeviceDisplay.dn,
                                    displayName: redirectionDeviceDisplay.name,
                                    type: RedirectionTypes.CallForward
                                };
                            }
                        }

                        if (resolvePeerUser) {
                            peerUserDetails = {
                                fqNumber: call.atcCallInfo.peerFQN,
                                phoneNumber: call.atcCallInfo.peerDn,
                                displayName: call.atcCallInfo.peerName
                            };
                        }

                        resolvePeerAndRedirectingUser(call, peerUserDetails, redirectingUserDetails, setCallPeerUserCb, setCallRedirectingPeerUserCb);
                    }

                    // only clear the _incomingCallConnection if the Delivered event is for a local alerting webRTC call
                    // if the Delivered event is from the PROGRESS of another client, the _incomingCallConnection needs to be kept
                    // in case the call needs to be rejected later on
                    _incomingCallConnection = {};
                    // update UI
                    publishAtcCallInfo(call);
                    return;
                } else {
                    // This is the racing condition scenario where the Delivered event was received after the call was terminated
                    if (_lastEndedCall && _lastEndedCall.atcCallInfo && _lastEndedCall.atcCallInfo.getCstaCallId() === event.connection.cId) {
                        LogSvc.warn('[CstaSvc]: The Delivered event is for a local call that was just terminated. Ignore it');
                        return;
                    }
                    // This is most likely a scenario where the CSTA Delivered event arrives before
                    // the Circuit INVITE. See note under /call/incoming event handler for details.
                    LogSvc.warn('[CstaSvc]: There is no local call associated with the Delivered Event');
                    // Let the code proceed below so we create a remote ATC call, but keep the position
                    // set to WebRTC. This will be needed in case we get the /call/incoming event.
                }
            }

            // Remote call
            var callId = localConnection.cID;
            call = _atcRemoteCalls[callId];
            if (!call) {
                call = createAtcRemoteCall(callId);
                _atcRemoteCalls[callId] = call;
                LogSvc.debug('[CstaSvc]: Created new Remote Call object for callID= ', callId);
            }
            if (isMasterParallelHgCall(call)) {
                LogSvc.debug('[CstaSvc]: Ignore Delivered event for master parallel hunt group');
                return;
            }
            call.setCstaState(callState);
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
            call.setPosition(position);
            call.atcCallInfo.setCstaConnection(localConnection);
            call.atcCallInfo.setQueuedIncomingCall(false);
            call.clearAtcHandoverInProgress();
            call.direction = direction;
            call.receivedAlerting = true;

            if (position === Targets.Desk && direction === CallDirection.INCOMING) {
                if ($rootScope.localUser.isOsBizCTIEnabled && event.cause === 'recallNoAnswer') {
                    // For Mulap users, OSBiz does not send a SIP call in case of call recall. It also does not support csta deflect
                    // to the client. Allow only to answer the call on desk device in this case.
                    call.atcCallInfo.setRecalledCall(true);
                }
                if (isSelectedRouting(RoutingOptions.DeskPhone)) {
                    // Do not ring on the headset if the call is set to ring only on desk device
                    call.doNotRingOnHeadset = true;
                }
            }

            // NGTC-3658: When a forwarded call is redirected to the alternative device, and the alternative device is an NGTC user,
            // the redirecting user information is lost because the call is dropped
            if (redirectionDeviceId && (position === Targets.Cell || position === Targets.Other)) {
                resolveRedirectingUser = setRedirectingUserSync(call, redirectionDeviceDisplay.dn, redirectionDeviceDisplay.fqn, redirectionDeviceDisplay.name,
                    RedirectionTypes.CallForward);
                if (resolveRedirectingUser) {
                    redirectingUserDetails = {
                        fqNumber: redirectionDeviceDisplay.fqn,
                        phoneNumber: redirectionDeviceDisplay.dn,
                        displayName: redirectionDeviceDisplay.name,
                        type: RedirectionTypes.CallForward
                    };
                }
            }

            if (display) {
                // Update partner's display information if presented
                call.atcCallInfo.setPartnerDisplay(display);
                if (calledDeviceId) {
                    var calledDisplay = getDisplayInfo(calledDeviceId);
                    if (calledDisplay.fqn !== display.fqn) {
                        call.atcCallInfo.setOriginalPartnerDisplay(calledDisplay);
                    } else {
                        call.atcCallInfo.setOriginalPartnerDisplay(display);
                    }
                }
                var setCallPeerUserCb1 = function () {
                    if (_snapshotPerformed) {
                        if (call.direction === CallDirection.INCOMING) {
                            LogSvc.debug('[CstaSvc]: Publish /call/remote/incoming event');
                            PubSubSvc.publish('/call/remote/incoming', [call]);

                        }
                        publishAtcCallInfo(call);
                    }
                };
                resolvePeerUser = setCallPeerUserSync(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName, setCallPeerUserCb1);
                if (resolvePeerUser) {
                    peerUserDetails = {
                        fqNumber: call.atcCallInfo.peerDn,
                        phoneNumber: call.atcCallInfo.peerDn,
                        displayName: call.atcCallInfo.peerName
                    };
                }
                resolvePeerAndRedirectingUser(call, peerUserDetails, redirectingUserDetails, setCallPeerUserCb1, setCallRedirectingPeerUserCb);
                return;
            }

            if (call.direction === CallDirection.INCOMING) {
                LogSvc.debug('[CstaSvc]: Publish /call/remote/incoming event');
                PubSubSvc.publish('/call/remote/incoming', [call]);

            }

            publishAtcCallInfo(call);
        }

        function handleDigitsGeneratedEvent(event) {
            var callId = event.connection.cID;
            var call = findWebRTCCall(callId);
            if (!call) {
                // find again from _atcRemoteCalls
                call = _atcRemoteCalls[callId];
            }

            if (call && isMyDeviceId(event.connection.dID)) {
                // DTMF digits have been generated
                // Raise an event so we can request additional digits to be generated (if pending)
                LogSvc.info('[CstaSvc]: Number of DTMF digits played: ', event.digitGeneratedList && event.digitGeneratedList.length);
                PubSubSvc.publish('/call/singleDigitSent', [call, event.digitGeneratedList]);
            }
        }

        // eslint-disable-next-line complexity
        function handleDivertedEvent(event) {
            var divertingDeviceDisplay, newDestinationDisplay;
            var call = findWebRTCCall(event.connection.cID);
            if (call) {
                if (isForwardedOrOsBizSecondCall(call, event)) {
                    return;
                }
            }
            if (isDistributed(event)) {
                return;
            }
            call = _atcRemoteCalls[event.connection.cID];
            if (!call) {
                // Even though the remotecall hasn't been created yet, we need to
                // notify the CircuitCallControlSvc about this event
                call = createAtcRemoteCall(event.connection.cID);
            } else if (isMasterParallelHgCall(call)) {
                LogSvc.debug('[CstaSvc]: Ignore Diverted event for master parallel hunt group');
                return;
            }
            if (event.localConnectionInfo === 'null' && isMyDeviceId(event.divertingDevice)) {
                if (!isMyDeviceId(event.newDestination)) {
                    // If the call is diverted to VM or it's currently on the client and is
                    // diverted to another user (e.g. forwarding, pickup), the client should
                    // not create a journal entry.
                    // If OSBiz CTI user, do not generate journal now. It is added by backend when the call is fw/redirected.
                    // In 4K the call that is diverted to vm shouldn't be ignored since the backend won't create any journal
                    if ((isVMDeviceId(event.newDestination) && !$rootScope.localUser.isOS4K) || getCallPosition(event.connection.dID) === Targets.WebRTC
                        || $rootScope.localUser.isOsBizCTIEnabled) {
                        call.atcCallInfo.setIgnoreCall(true);
                    }
                    call.setCstaState(CstaCallState.Idle);
                    call.direction = CallDirection.INCOMING;
                    call.atcCallInfo.setMissedReason(MissedReasonTypes.CANCELLED);
                    if (FORWARD_EXPR.test(event.cause)) {
                        _atcRemoteCalls[event.connection.cID] = call;
                        var callingDisplay;
                        if (event.presentationRestrictedDevice1) {
                            LogSvc.debug('[CstaSvc]: Calling device is private.');
                            callingDisplay = getDisplayInfo();
                        } else {
                            callingDisplay = getDisplayInfo(event.callingDevice);
                        }
                        LogSvc.debug('[CstaSvc]: Update partner display to ', callingDisplay);
                        call.atcCallInfo.setPartnerDisplay(callingDisplay);
                        setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName);
                    }
                    publishAtcCallInfo(call);
                } else {
                    if (getCallPosition(event.newDestination) === Targets.WebRTC) {
                        _incomingCallConnection = { cID: event.connection.cID, dID: event.newDestination};
                    }
                    if (call) {
                        if (call.pickupNotification) {
                            return;
                        }

                        setRedirected(event, call);

                        if ($rootScope.localUser.isOsBizCTIEnabled && event.cause === 'park' && getCallPosition(event.newDestination) !== getCallPosition(event.divertingDevice)) {
                            // Call is moving between the user devices.
                            call.setRedirectionType(RedirectionTypes.Dss);
                            _atcRemoteCalls[event.connection.cID] = call;
                            return;
                        }
                        if (call.forwarded) {
                            return;
                        }
                        if (getCallPosition(event.newDestination) === Targets.Cell) {
                            // Call is moving to alternative device.
                            LogSvc.debug('[CstaSvc]: Diverting to altenative device');
                            return;
                        }
                        if (call.checkCstaState(CstaCallState.Ringing) && getCallPosition(event.newDestination) === call.getPosition()) {
                            LogSvc.debug('[CstaSvc]: new destination has the same position as the current alerting position. Ignore event');
                            _incomingCallConnection = {};
                            return;
                        }
                        // This is a deflect to another OND
                        call.atcCallInfo.setIgnoreCall(true);
                        publishAtcCallInfo(call);
                    }
                }
                if (call && !call.isHandoverInProgress) {
                    removeAtcRemoteCall(event.connection.cID);
                }

            } else if (!isMyDeviceId(event.divertingDevice)) {
                if (call) {
                    var display = getDisplayInfo(event.divertingDevice);
                    if (display) {
                        call.atcCallInfo.setOriginalPartnerDisplay(display);
                    }
                    if (call.direction === CallDirection.OUTGOING && FORWARD_EXPR.test(event.cause)) {
                        divertingDeviceDisplay = getDisplayInfo(event.divertingDevice);
                        setRedirectingUser(call, divertingDeviceDisplay.dn, divertingDeviceDisplay.fqn, divertingDeviceDisplay.name, RedirectionTypes.CallForward);
                        newDestinationDisplay = getDisplayInfo(event.newDestination);
                        call.setPeerUser(newDestinationDisplay.dn, newDestinationDisplay.name);
                        return;
                    }
                    call.atcCallInfo.setMissedReason(MissedReasonTypes.CANCELLED);
                    publishAtcCallInfo(call);
                }
            }
        }

        function copyDataFromMovingCall(call, callId) {
            if (_movingCall && _movingCall.newCallId === callId) {
                call.direction = _movingCall.direction;
                call.setRedirectingUser(_movingCall.redirectingUser.phoneNumber, _movingCall.redirectingUser.fqNumber,
                    _movingCall.redirectingUser.displayName, _movingCall.redirectingUser.userId, _movingCall.redirectingUser.redirectionType);
                call.atcCallInfo.transferCb = _movingCall.transferCb;
                delete _movingCall.transferCb;
                _movingCall = {};
            }
        }

        function setWebRTCCallForEstablishedEvent(call, localConnection, display, event) {
            LogSvc.info('[CstaSvc]: The Established Event is for a local WebRTC call');

            var position = Targets.WebRTC;
            var resolvePeerUser;
            var resolveRedirectingUser;
            var redirectingUserDetails;
            var peerUserDetails;
            var setCallRedirectingPeerUserCb = function () {
                LogSvc.debug('[CstaSvc]: Publish /call/peerUser/update event.',
                    ['callId = ' + call.callId, 'peerUserId = ' + call.peerUser.userId, 'peerUser = ', call.peerUser, 'redirectingUser = ', call.redirectingUser]);
                PubSubSvc.publish('/call/peerUser/update', [call]);
            };

            call.atcCallInfo.setCstaConnection(localConnection);
            LogSvc.info('[CstaSvc]: Set the CSTA connection ID for the active call: ', localConnection);

            if (call.isRemote) {
                LogSvc.warn('[CstaSvc]: There is no local call associated with the Established Event');
                position = Targets.Other;
            }
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
            setCstaStateFromLCO(call, event);
            publishOsBizCallState(call, CallState.Active);
            call.peerUser && updateDisplayNameFromPeerUser(display, call.peerUser);
            call.atcCallInfo.setPartnerDisplay(display);
            call.setPosition(position);
            call.clearAtcHandoverInProgress();
            call.atcCallInfo.clearOutgoingCallCampedOn();
            call.receivedAlerting = true;
            call.atcCallInfo.setQueuedIncomingCall(false);

            var setCallPeerUserCb = function () {
                LogSvc.debug('[CstaSvc]: Publish /call/state event. callId = ' + call.callId + ', state = ' + call.state.name);
                PubSubSvc.publish('/call/state', [call]);
            };
            resolvePeerUser = setCallPeerUserSync(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName,
                setCallPeerUserCb);
            copyDataFromMovingCall(call, localConnection.cID);

            var remoteCall = findAtcRemoteCall(localConnection.cID);
            if (remoteCall && (remoteCall.forwarded || remoteCall.pickupNotification)) {
                var redirectionType = remoteCall.redirectingUser.redirectionType === RedirectionTypes.CallForward ? RedirectionTypes.CallForward : RedirectionTypes.CallPickedUp;
                resolveRedirectingUser = setRedirectingUserSync(call,
                    remoteCall.redirectingUser.phoneNumber,
                    remoteCall.redirectingUser.fqNumber,
                    remoteCall.redirectingUser.displayName,
                    redirectionType);
                if (resolveRedirectingUser) {
                    redirectingUserDetails = {
                        phoneNumber: remoteCall.redirectingUser.phoneNumber,
                        fqNumber: remoteCall.redirectingUser.fqNumber,
                        displayName: remoteCall.redirectingUser.displayName,
                        type: redirectionType
                    };
                }
                remoteCall.atcCallInfo.setIgnoreCall(true);
                removeAtcRemoteCall(localConnection.cID);
            } else if (event.cause === 'callPickup') {
                if (call.pickupNotification) {
                    call.setRedirectionType(RedirectionTypes.CallPickedUp);
                } else if (event.lastRedirectionDevice) {
                    var lastRedirectionDeviceDisplay = getDisplayInfo(event.lastRedirectionDevice);
                    resolveRedirectingUser = setRedirectingUserSync(call, lastRedirectionDeviceDisplay.dn, lastRedirectionDeviceDisplay.fqn,
                        lastRedirectionDeviceDisplay.name, RedirectionTypes.CallPickedUp);
                    if (resolveRedirectingUser) {
                        redirectingUserDetails = {
                            phoneNumber: lastRedirectionDeviceDisplay.dn,
                            fqNumber: lastRedirectionDeviceDisplay.fqn,
                            displayName: lastRedirectionDeviceDisplay.name,
                            type: RedirectionTypes.CallPickedUp
                        };
                    }
                }
            } else if (event.cause === 'singleStepTransfer') {
                checkForTransferCb(call);
            } else if ($rootScope.localUser.isOS4K && event.cause === 'normal' && !(call.redirectingUser && call.redirectingUser.redirectionType) &&
                    isMyDeviceId(event.answeringDevice) && !isMyDeviceId(event.calledDevice)) {
                //NGTC-6280: this is a forwarded incoming call while in push notification.
                //In all other cases redirected user info is filled from delivered event
                var redirectionDeviceDisplay = getDisplayInfo(event.calledDevice);
                resolveRedirectingUser = setRedirectingUserSync(call, redirectionDeviceDisplay.dn, redirectionDeviceDisplay.fqn,
                    redirectionDeviceDisplay.name, RedirectionTypes.CallForward);
                if (resolveRedirectingUser) {
                    redirectingUserDetails = {
                        phoneNumber: redirectionDeviceDisplay.dn,
                        fqNumber: redirectionDeviceDisplay.fqn,
                        displayName: redirectionDeviceDisplay.name,
                        type: RedirectionTypes.CallForward
                    };
                }
            }

            if (resolvePeerUser) {
                peerUserDetails = {
                    fqNumber: call.atcCallInfo.peerDn,
                    phoneNumber: call.atcCallInfo.peerDn,
                    displayName: call.atcCallInfo.peerName
                };
            }

            resolvePeerAndRedirectingUser(call, peerUserDetails, redirectingUserDetails, setCallPeerUserCb, setCallRedirectingPeerUserCb);

            if (!call.isRemote && !call.isOsBizSecondCall) {
                call.updateCallState();
            }
            publishAtcCallInfo(call);

            processQueuedCall(position);
        }

        // eslint-disable-next-line complexity
        function handleEstablishedEvent(event) {
            var call;
            _incomingCallConnection = {};

            var callCharacteristics = determineCallCharacteristics(event);
            var localConnection = callCharacteristics.localConnection;
            var partnerDeviceId = callCharacteristics.partnerDeviceId;
            var direction = callCharacteristics.direction;
            var originalPartnerDisplay = callCharacteristics.originalPartnerDisplay;

            LogSvc.debug('[CstaSvc]: LocalConnection: ', localConnection);
            LogSvc.debug('[CstaSvc]: Partner Device ID: ', partnerDeviceId);

            var display;
            if (event.presentationRestrictedDevice1 && direction === CallDirection.INCOMING || checkSSTIsPrivateDevice2(event, direction)) {
                LogSvc.debug('[CstaSvc]: Calling device is private.');
                display = getDisplayInfo();
            } else {
                display = getDisplayInfo(partnerDeviceId);
            }

            // Determine call position
            var position = getCallPosition(localConnection.dID, event.epid, direction);
            if (position === Targets.WebRTC) {
                LogSvc.info('[CstaSvc]: The Established Event is for a local WebRTC call');

                // In some scenarios, like handover, the active call doesn't have a CSTA Connection ID until the Established Event
                // is received, so we must fallback to the active call in case we cannot find a local call with the received CSTA Call ID.
                call = findWebRTCCall(localConnection.cID);
                if (call) {
                    setWebRTCCallForEstablishedEvent(call, localConnection, display, event);
                    return;
                }
                if (event.cause === 'recall' || event.cause === 'callPickup') {
                    call = CircuitCallControlSvc.findActivePhoneCall();
                    LogSvc.info('[CstaSvc]: Original call callId:', call.atcCallInfo.getCstaCallId());
                    call.atcCallInfo.setCstaConnection(localConnection);
                    call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
                    if (event.localConnectionInfo === 'hold') {
                        call.setCstaState(CstaCallState.Holding);
                    } else {
                        call.setCstaState(CstaCallState.Active);
                    }
                    call.updateCallState();
                    publishAtcCallInfo(call);
                    return;
                }

                if ($rootScope.localUser.isOsBizCTIEnabled && $rootScope.localUser.reroutingPhoneNumber &&
                    isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                    // OSBiz calls on alternative number have the same DE/EE as a local call
                    position = Targets.Cell;
                } else {
                    // For some reason the Established event is for a local call that does not exist anymore
                    LogSvc.debug('[CstaSvc]: There is no local or remote call, change the position to Other');
                    position = Targets.Other;
                }

                // If the call is answered by the VM, the established event has no epid. This call should
                // not be shown
                if (isMyDeviceId(event.answeringDevice) && !event.epid && !$rootScope.localUser.isOsBizCTIEnabled) {
                    LogSvc.debug('[CstaSvc]: This is a call answered by VM. Ignore it');
                    return;
                }

                // This is the racing condition scenario where the Established event was received after the call was terminated
                if (_lastEndedCall && _lastEndedCall.atcCallInfo && _lastEndedCall.atcCallInfo.getCstaCallId() === localConnection.cId) {
                    LogSvc.warn('[CstaSvc]: The Established event is for a local call that was just terminated. Ignore it');
                    return;
                }
            }

            var newCall = false;
            call = _atcRemoteCalls[localConnection.cID];
            if (!call) {
                // This is a new call
                newCall = true;
                call = createAtcRemoteCall(localConnection.cID);
                //NGTC-5246: Try to retrieve redirecting user fromExisting WebRTCCall or lastEndedCall
                var localCallExists = findLocalCallByCstaCID(localConnection);
                if (localCallExists && localCallExists.redirectingUser) {
                    call.setRedirectingUser(
                        localCallExists.redirectingUser.phoneNumber, localCallExists.redirectingUser.fqNumber,
                        localCallExists.redirectingUser.displayName, localCallExists.redirectingUser.userId,
                        localCallExists.redirectingUser.redirectionType);
                }
                copyDataFromMovingCall(call, localConnection.cID);
            }

            if (isMasterParallelHgCall(call)) {
                LogSvc.debug('[CstaSvc]: Ignore Established event for master parallel hunt group');
                return;
            }
            call.setPosition(position);
            call.atcCallInfo.setPartnerDisplay(display);
            call.atcCallInfo.setOriginalPartnerDisplay(originalPartnerDisplay);
            call.direction = direction;
            setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName, function () {
                //NGTC-4263: Publishing call state instead of atccall/info because in anonymous scenarios when call state is Idle,
                //this will trigger the /call/ended flow
                LogSvc.debug('[CstaSvc]: Publish /call/state event. callId = ' + call.callId + ', state = ' + call.state.name);
                PubSubSvc.publish('/call/state', [call]);
            });
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
            call.atcCallInfo.setCstaConnection(localConnection);
            call.atcCallInfo.setRecalledCall(false);

            checkForTransferCb(call);
            call.clearAtcHandoverInProgress();
            call.atcCallInfo.clearOutgoingCallCampedOn();
            call.receivedAlerting = true;
            call.atcCallInfo.setQueuedIncomingCall(false);
            call.atcCallInfo.setIgnoreCall(false);

            if ($rootScope.localUser.isOsBizCTIEnabled && position === Targets.Cell) {
                call.atcCallInfo.setOsbizCallOnAlternativeNumber();
            }

            setCstaStateFromLCO(call, event);

            if (event.cause === 'callPickup') {
                if (call.pickupNotification) {
                    call.setRedirectionType(RedirectionTypes.CallPickedUp);
                    // Since the call is picked up, need to clear the pickUp indication, in order for the client
                    // to generate a call log entry (OS4K or OsBIZ)
                    call.atcCallInfo.pickUp = false;
                } else if (call.getRedirectionType() === RedirectionTypes.Dss) {
                    // When moving call between user's devices, do not show pickup.
                    call.setRedirectionType(null);
                } else if (event.lastRedirectionDevice) {
                    setRedirectingUser(call, getDisplayInfo(event.lastRedirectionDevice).dn, getDisplayInfo(event.lastRedirectionDevice).fqn,
                        getDisplayInfo(event.lastRedirectionDevice).name, RedirectionTypes.CallPickedUp);
                }
            }

            if (newCall) {
                LogSvc.debug('[CstaSvc]: Created new Remote Call object: ', call);
                // Add call to _atcRemoteCalls
                _atcRemoteCalls[localConnection.cID] = call;
            }

            if ($rootScope.localUser.isOsBizCTIEnabled && _webrtcRemoteCall) {
                // For OsBiz, pull is blocked if the user has more than one calls
                _webrtcRemoteCall.pullBlocked = true;
            }

            publishAtcCallInfo(call);

            processQueuedCall(position);
        }

        function handleHeldEvent(event) {
            var callId = event.heldConnection.cID;

            var call = findWebRTCCall(callId);
            if (!call) {
                // find again from _atcRemoteCalls
                call = _atcRemoteCalls[callId];

                if (!call) {
                    LogSvc.warn('[CstaSvc]: Could not find call corresponding to Held Event.');
                    return;
                }
            }

            // Update Call State
            LogSvc.debug('[CstaSvc]: Current csta call state: ', call.getCstaState());
            if (isMyDeviceId(event.holdingDevice)) {
                // The subscriber held the call
                call.atcCallInfo.setCstaConnection(event.heldConnection);
                switch (call.getCstaState()) {
                case CstaCallState.Held:
                    call.setCstaState(CstaCallState.HoldOnHold);
                    break;
                case CstaCallState.Conference:
                    call.setCstaState(CstaCallState.ConferenceHolding);
                    if ($rootScope.localUser.isOsBizCTIEnabled && !call.isRemote) {
                        call.setState(CallState.Holding);
                    }
                    break;
                default:
                    call.setCstaState(CstaCallState.Holding);
                    if (!call.isRemote) {
                        call.setState(CallState.Holding);
                    }
                    break;
                }
                call.clearHoldInProgress();
            } else if (event.localConnectionInfo === 'hold') {
                if (!call.checkCstaState(CstaCallState.ConferenceHolding)) {
                    call.setCstaState(CstaCallState.HoldOnHold);
                }
            } else if (!call.checkCstaState(CstaCallState.Conference)) {
                call.setCstaState(CstaCallState.Held);
            }

            LogSvc.debug('[CstaSvc]: New csta call state: ', call.getCstaState());

            // Update Services Permitted
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

            // Update the UI
            publishAtcCallInfo(call);
        }


        function getCstaConnectionForRequest(callData) {
            if (!$rootScope.localUser.isOsBizCTIEnabled) {
                return callData.getCstaConnection();
            } else {
                return { cID: callData.getCstaCallId(), dID: _osmoData.onsFQN };
            }
        }

        function handleOfferedEvent(event) {
            var localConnection;
            var partnerDeviceId;
            var data;

            if (isMyDeviceId(event.offeredDevice)) {
                // This is a call offered to the ONS user
                localConnection = event.offeredConnection;
                partnerDeviceId = event.callingDevice;
            } else {
                LogSvc.debug('[CstaSvc]: ONS DN is not the offered device. Ignore Offered event.');
                return;
            }

            LogSvc.debug('[CstaSvc]: LocalConnection: ', localConnection);
            LogSvc.debug('[CstaSvc]: Partner Device ID: ', partnerDeviceId);

            if ($rootScope.localUser.cfData.Immediate.cfwEnabled) {
                LogSvc.debug('[CstaSvc]: Immediate call forwarding is enabled. Accept call');
                data = {
                    request: 'AcceptCall',
                    callToBeAccepted: localConnection
                };
                sendCstaRequest(data);
                return;
            }

            // Get partner's display information
            var display;
            if (event.presentationRestrictedDevice1) {
                LogSvc.debug('[CstaSvc]: Calling device is private.');
                display = getDisplayInfo();
            } else {
                display = getDisplayInfo(partnerDeviceId);
            }

            var call = _atcRemoteCalls[localConnection.cID];
            if (call && call.pickupNotification) {
                return;
            }
            call = createAtcRemoteCall(localConnection.cID);
            call.setPosition(Targets.Desk);
            call.atcCallInfo.setPartnerDisplay(display);
            setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName);
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
            call.setCstaState(CstaCallState.Offered);
            call.direction = CallDirection.INCOMING;
            call.atcCallInfo.setCstaConnection(localConnection);
            if (FORWARD_EXPR.test(event.cause)) {
                // If in case of multiple forwarding we want to display the original called party, then we should use the calledDevice
                // The OpenStage phones show the last redirecting party
                var lastRedirectionDeviceDisplay = getDisplayInfo(event.lastRedirectionDevice);
                setRedirectingUser(call, lastRedirectionDeviceDisplay.dn, lastRedirectionDeviceDisplay.fqn, lastRedirectionDeviceDisplay.name, RedirectionTypes.CallForward);
            }

            LogSvc.debug('[CstaSvc]: Created new Remote Call object: ', call);

            // Add call to _atcRemoteCalls
            _atcRemoteCalls[localConnection.cID] = call;
            _incomingCallConnection = {cID: localConnection.cID, dID: buildNewDestination(Targets.WebRTC, null, true)};
            if (isSelectedRouting(RoutingOptions.DeskPhone)) {
                // If Desk is the selected incoming call routing, the call will be deflected to the desk phone by ATC
                return;
            }
            if (CircuitCallControlSvc.isSecondCall() || (_telephonyConversation.call && _telephonyConversation.call.isAtcRemote &&
                !_telephonyConversation.call.atcCallInfo.pickUp)) {
                handleSecondCall(call);
            } else {
                data = {
                    request: 'AcceptCall',
                    callToBeAccepted: getCstaConnectionForRequest(call.atcCallInfo)
                };
                sendCstaRequest(data);
                _incomingCallConnection = {};
            }

        }

        // eslint-disable-next-line complexity
        function handleQueuedEvent(event) {
            var localConnection;
            var partnerDeviceId;
            var position;
            var callId;
            var call;


            if (isMyDeviceId(event.queue)) {
                // This is a call parked to the user's private queue
                localConnection = event.queuedConnection;
                partnerDeviceId = event.callingDevice;

                LogSvc.debug('[CstaSvc]: LocalConnection: ', localConnection);
                LogSvc.debug('[CstaSvc]: Partner Device ID: ', partnerDeviceId);

                // Determine call position
                position = getCallPosition(localConnection.dID, event.epid);

                // Get partner's display information
                var display = getDisplayInfo(partnerDeviceId);
                callId = localConnection.cID;
                call = _atcRemoteCalls[callId];
                if (!call) {
                    if (event.cause === 'noAvailableAgents') {
                        // This event indicates that the MLHG that the ONS belongs to is busy. Needs to be ignored
                        LogSvc.debug('[CstaSvc]: Cause=noAvailableAgents. Ignore Queued event');
                        return;
                    }
                    if ($rootScope.localUser.isOsBizCTIEnabled) {
                        if (!$rootScope.localUser.pbxCampOnSupported &&
                            (event.cause === 'multipleQueuing' || event.cause === 'keyOperation' || event.cause === 'campOn')) {
                            // This event indicates that there is another call waiting but OSBiz does not support yet campOn feature
                            // with Circuit clients. Cannot not process this event in client.
                            LogSvc.debug('[CstaSvc]: CampOn is not supported. Ignore this event.');
                            return;
                        }
                        if ($rootScope.localUser.pbxCampOnSupported && (event.cause === 'multipleQueuing' || event.cause === 'keyOperation' || event.cause === 'campOn')) {
                            // This event indicates that there is another call waiting and OSBiz supports campOn feature
                            // with Circuit clients. We need to ignore this event in case we already have 2 local calls.
                            // For some reason when the second call is created through consultation _calls.length is not increased. We need to handle this case too
                            // eslint-disable-next-line max-len
                            if (_calls && !_calls[0].isRemote && (_calls.length === 2 || (_calls.length === 1 && _telephonyConversation.call.atcCallInfo.peerDn !== _calls[0].atcCallInfo.peerDn))) {
                                LogSvc.debug('[CstaSvc]: Three local calls are not supported. Sending ClearConnection request.');
                                //NGTC-2208: send ClearConnection with a reason, so third incoming call is dropped as it is not supported
                                if (event.queuedConnection.cID) {
                                    var data = {
                                        request: 'ClearConnection',
                                        connectionToBeCleared: { cID: event.queuedConnection.cID, dID: getDeviceFromDeviceId(event.callingDevice) },
                                        reason: 'busy'
                                    };
                                    sendCstaRequest(data);
                                }
                                return;
                            }
                        }
                    }

                    call = createAtcRemoteCall(callId);
                    _atcRemoteCalls[callId] = call;
                    LogSvc.debug('[CstaSvc]: Created new Remote Call object for callID= ', callId);
                }
                call.setPosition(position);
                call.atcCallInfo.setPartnerDisplay(display);
                setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName);
                call.setCstaState(CstaCallState.Parked);
                call.atcCallInfo.setCstaConnection(localConnection);

                if ($rootScope.localUser.isOsBizCTIEnabled && $rootScope.localUser.pbxCampOnSupported &&
                    (event.cause === 'campOn' || event.cause === 'keyOperation')) {
                    // This event indicates that there is a second call incoming
                    if (_activeCall && position === Targets.WebRTC) {
                        LogSvc.debug('[CstaSvc]: Cause=campOn. A local call is established and user receives new incoming call');
                        if (position === Targets.WebRTC) {
                            createCampOnLocalCall(call, event.servicesPermitted);
                            return;
                        }
                    } else if (findAtcRemoteEstablishedCall() && position === Targets.Desk) {
                        LogSvc.debug('[CstaSvc]: Cause=campOn. A remote call is established and user receives new incoming call');

                        // Publish conversation update so that second call is shown as ringing
                        _telephonyConversation.call = call;
                        LogSvc.debug('[CstaSvc]: Publish /conversation/update event. convId = ', _telephonyConversation.convId);
                        PubSubSvc.publish('/conversation/update', [_telephonyConversation]);

                        LogSvc.debug('[CstaSvc]: Publish /call/remote/incoming event');
                        PubSubSvc.publish('/call/remote/incoming', [call]);

                        call.direction = CallDirection.INCOMING;
                        call.setCstaState(CstaCallState.Ringing);
                    } else if (_alertingCall) {
                        LogSvc.debug('[CstaSvc]: Cause=campOn. A call is ringing and user receives new incoming call. Do not display yet.');
                        // Do not show call on the client until the first call is answered, rejected or cancelled.
                        call.setCstaState(CstaCallState.Ringing);
                        call.direction = CallDirection.INCOMING;
                        call.atcCallInfo.setQueuedIncomingCall(true);
                    }
                }


            } else if ($rootScope.localUser.isOsBizCTIEnabled && isMyDeviceId(event.callingDevice) && event.cause === 'campOn') {
                // This is an outgoing call camping On a busy party
                position = getCallPosition(event.callingDevice);
                callId = event.queuedConnection.cID;
                call = position === Targets.WebRTC ? findWebRTCCall(callId) : _atcRemoteCalls[callId];
                if (!call) {
                    LogSvc.debug('[CstaSvc]: Call not found for callID= ', callId);
                    return;
                }
                call.atcCallInfo.setOutgoingCallCampedOn();
                if (call.isRemote && call.checkCstaState(CstaCallState.Busy)) {
                    call.setCstaState(CstaCallState.Delivered);
                }
            } else {
                LogSvc.debug('[CstaSvc]: ONS DN is not the queue device. Ignore Queued event.');
                return;
            }

            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

            publishAtcCallInfo(call);
        }

        function handleRetrievedEvent(event) {
            var callId = event.retrievedConnection.cID;

            var call = findWebRTCCall(callId);
            if (!call) {
                // find again from _atcRemoteCalls
                call = _atcRemoteCalls[callId];

                if (!call) {
                    LogSvc.warn('[CstaSvc]: Could not find call corresponding to Retrieved Event.');
                    return;
                }
            }

            // Update Call State
            if (isMyDeviceId(event.retrievingDevice)) {
                call.atcCallInfo.setCstaConnection(event.retrievedConnection);
                // The subscriber retrieved the call
                if (call.checkCstaState(CstaCallState.HoldOnHold)) {
                    call.setCstaState(CstaCallState.Held);

                    // Prior to V7, the OSV has some problems with Hold-On-Hold scenarios and
                    // it doesn't report all the events as expected.
                    // Therefore, we should do a Snapshot Call to make sure we don't get stuck
                    // in Held state. For now, as a workaround, just check whether Silent Handover
                    // is allowed for this call. If it is, this means we are actually in Active state
                    if (event.servicesPermitted.eSP && event.servicesPermitted.eSP.zsiHo) {
                        call.setCstaState(CstaCallState.Active);
                    }
                } else if (call.checkCstaState(CstaCallState.ConferenceHolding)) {
                    // Still in a conference
                    call.setCstaState(CstaCallState.Conference);

                    // But if SST is allowed, Per FRN5227, it is not in a conference anymore
                    if (event.servicesPermitted.eSP && event.servicesPermitted.eSP.sST) {
                        call.setCstaState(CstaCallState.Active);
                    }
                } else {
                    call.setCstaState(CstaCallState.Active);
                }
                if (!call.isRemote) {
                    call.setState(CallState.Active);
                }
                call.clearRetrieveInProgress();
                call.atcCallInfo.setCstaReconnectId({});
            } else if (call.checkCstaState([CstaCallState.ConferenceHolding, CstaCallState.Conference])) {
                if (event.localConnectionInfo === 'hold') {
                    call.setCstaState(CstaCallState.ConferenceHolding);
                } else {
                    call.setCstaState(CstaCallState.Conference);
                }
            } else {
                if (event.localConnectionInfo === 'hold') {
                    call.setCstaState(CstaCallState.Holding);
                } else {
                    call.setCstaState(CstaCallState.Active);
                }

                if (call.isRemote) {
                    // Call may have been retrieved by different party. Update the display just in case.
                    var display;
                    if (event.presentationRestrictedDevice1 && call.direction === CallDirection.INCOMING) {
                        LogSvc.debug('[CstaSvc]: Calling device is private.');
                        display = getDisplayInfo();
                    } else {
                        display = getDisplayInfo(event.retrievingDevice);
                    }
                    call.atcCallInfo.setPartnerDisplay(display);
                    setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName);
                }
            }

            // Update Services Permitted
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

            // Update the UI
            publishAtcCallInfo(call);
        }

        function handleServiceInitiatedEvent(event) {
            /*
             * Process the Service Initiated Event to find out the CSTA Connection ID
             * for outgoing calls from the WebRTC client or Remote Device.
             */
            var call;
            var position = getCallPosition(event.initiatingDevice, event.epid);
            if (position === Targets.WebRTC) {
                call = findWebRTCCall(event.initiatedConnection.cID);
                // During Consultation, the 2nd new call becomes _activeCall
                // In 3PCC MakeCall/ConsultationCall scenarios, local state is Ringing when ServiceIniated event comes
                if (call) {
                    call.atcCallInfo.setCstaConnection(event.initiatedConnection);
                    call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
                    if (!call.isRemote && call.checkState([CallState.Initiated, CallState.Ringing, CallState.Delivered])) {
                        LogSvc.info('[CstaSvc]: Set the CSTA connection ID for the call: ', event.initiatedConnection);

                        call.setCstaState(CstaCallState.Initiated);
                        publishOsBizCallState(call, CallState.Delivered);
                        // update UI
                        publishAtcCallInfo(call);
                    } else if (call.isRemote) {
                        LogSvc.info('[CstaSvc]: Set the CSTA connection ID for the remote call: ', event.initiatedConnection);
                        return;
                    } else {
                        LogSvc.error('[CstaSvc]: Call Initiated for WebRTC client has no active call or not in Initiated/Ringing state');
                    }
                    return;
                }
            }

            call = _atcRemoteCalls[event.initiatedConnection.cID];
            if (!call) {
                // This is a new remote call
                call = createAtcRemoteCall(event.initiatedConnection.cID);
                call.setCstaState(CstaCallState.Initiated);
            }

            call.setPosition(position);
            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);

            call.direction = CallDirection.OUTGOING;
            call.atcCallInfo.setCstaConnection(event.initiatedConnection);
            _atcRemoteCalls[event.initiatedConnection.cID] = call;
            LogSvc.debug('[CstaSvc]: Created new Remote Call object: ', call);
        }

        function handleTransferredEvent(event) {
            if (event.localConnectionInfo === 'null') {
                // We are the transferring party
                if (event.secondaryOldCall) {
                    removeAtcRemoteCall(event.primaryOldCall.cID);
                    var secondaryCall = _atcRemoteCalls[event.secondaryOldCall.cID];
                    if (secondaryCall) {
                        secondaryCall.atcCallInfo.setMissedReason(MissedReasonTypes.TRANSFERRED);
                    }
                    $timeout(function () {
                        removeAtcRemoteCall(event.secondaryOldCall.cID);
                    }, 1000);
                } else {
                    if (isMyDeviceId(event.transferringDevice) && isMyDeviceId(event.transferredToDevice)) {
                        var remoteCall = _atcRemoteCalls[event.primaryOldCall.cID];
                        if (remoteCall) {
                            remoteCall.atcCallInfo.setIgnoreCall(true);
                        }

                        setLocalCallAsPulled(event.primaryOldCall.cID, event.transferredToDevice);
                    }
                    removeAtcRemoteCall(event.primaryOldCall.cID);
                }

                if (event.cause === 'singleStepTransfer') {
                    // For SingleStepTransfer and SilentHandover, TransferredEvent(null) does not indicate the transfer is successful or not
                    // We have to track the Failed (fail, blocked) + ConnectionCleared event in order to determine the result
                    // Only track the transfer operation that is initiated through EVO
                    var transferredCall = _that.getTransferredCall(event.primaryOldCall.cID);
                    if (transferredCall) {
                        // remove stored transferredCall from _cstaReqHandler and track the failedCallId locally
                        _that.clearTransferredCall(event.primaryOldCall.cID);
                        _trackTransferCallIds[transferredCall.failedCallId] = event.primaryOldCall.cID;
                    }
                } else if (event.cause === 'transfer') {
                    // To popup a success message after consult transfer
                    var tmpCall = createAtcRemoteCall(event.secondaryOldCall.cID);
                    publishAtcCallInfo(tmpCall);
                }

                return;
            }

            // We are the transferred or transferred-to party
            var display = null;
            var callId = event.primaryOldCall.cID;
            var call = findWebRTCCall(callId) || _atcRemoteCalls[callId];

            if (!call) {
                LogSvc.debug('[CstaSvc]: Could not find a call with Call Id = ' + callId);
                return;
            }


            // For OSBiz DSS call move, user may not press 'transfer' on desk device. In that case,
            // this transferEvent needs to update the local call and release the remote call
            if (call.isAtcRemote && event.secondaryOldCall && event.secondaryOldCall.cID && event.transferringDevice && event.transferredToDevice &&
                isDssCallMove(event.localConnectionInfo, event.transferringDevice, event.transferredToDevice)) {
                var localCall = findWebRTCCall(event.secondaryOldCall.cID);
                if (localCall) {
                    // release remote call
                    call.setCstaState(CstaCallState.Idle);
                    publishAtcCallInfo(call);
                    delete _atcRemoteCalls[callId];

                    // update local call now
                    call = localCall;
                    call.atcCallInfo.setCstaCallId(callId);
                }
            }

            var transferringDeviceDisplay = getDisplayInfo(event.transferringDevice);
            var transferredToDeviceDisplay = getDisplayInfo(event.transferredToDevice);

            // Update the CSTA Connection ID for the call.
            event.transferredConnections.forEach(function (xferConn) {
                if ((xferConn.endpoint && isMyDeviceId(xferConn.endpoint)) || (xferConn.connection && isMyDeviceId(xferConn.connection.dID))) {
                    call.atcCallInfo.setCstaConnection(xferConn.connection);
                    LogSvc.info('[CstaSvc]: Updated the CSTA connection ID for the call: ', xferConn.connection);
                    if (call.isAtcRemote) {
                        call.setCallIdForTelephony(xferConn.connection.cID);
                        delete _atcRemoteCalls[callId];
                        _atcRemoteCalls[call.callId] = call;
                        publishAtcCallInfo(call);
                    }
                } else {
                    if (call.isAtcRemote) {
                        var atcCallInfo = call.atcCallInfo;
                        call.setCstaState(CstaCallState.Idle);
                        var peerUser = call.peerUser;
                        var direction = call.direction;
                        var establishedTime = call.establishedTime;
                        publishAtcCallInfo(call);
                        delete _atcRemoteCalls[callId];

                        call = createAtcRemoteCall(xferConn.connection.cID);
                        call.atcCallInfo = atcCallInfo;

                        // NGTC-3728: In case other party is doing push pull and we are receiving this event for the existing connected
                        // remote call, atcCallInfo cstaConnection should remain as is
                        (transferringDeviceDisplay.dn !== transferredToDeviceDisplay.dn) && call.atcCallInfo.setCstaConnection(xferConn.connection);
                        call.setCstaState(event.localConnectionInfo === 'alerting' ? CstaCallState.Ringing : CstaCallState.Active);
                        LogSvc.info('[CstaSvc]: Updated the CSTA connection ID for the call: ', xferConn.connection);
                        setCallPeerUser(call, peerUser.phoneNumber, peerUser.phoneNumber, peerUser.displayName);
                        call.direction = direction;
                        call.establishedTime = establishedTime;
                        var originalPartnerDisplay = getDisplayInfo(event.transferringDevice);
                        call.atcCallInfo.setOriginalPartnerDisplay(originalPartnerDisplay);

                        _atcRemoteCalls[call.callId] = call;
                    }
                    // Update the partner display
                    if ((event.presentationRestrictedDevice1 && call.direction === CallDirection.INCOMING) ||
                        (event.presentationRestrictedDevice2 && checkOs4kPrivateDevice2(call.direction, event, transferringDeviceDisplay, transferredToDeviceDisplay, xferConn)) ||
                        (xferConn.endpoint && xferConn.endpoint.rstr === '')) {
                        LogSvc.debug('[CstaSvc]: Calling device is private.');
                        display = getDisplayInfo();
                    } else {
                        display = (xferConn.endpoint && getDisplayInfo(xferConn.endpoint)) || (xferConn.connection.dID && getDisplayInfo(xferConn.connection.dID));
                        //NGTC-3966: OS4K external number has display info in event.transferredToDeviceDisplay
                        if (transferredToDeviceDisplay.dn === display.dn && !display.name) {
                            display = transferredToDeviceDisplay;
                        }
                    }
                }
            });

            if (!call.isRemote && !call.isEstablished()) {
                LogSvc.debug('[CstaSvc]: This is a WebRTC call that has not been answered. Update the display');
                if (display) {
                    call.atcCallInfo.setPartnerDisplay(display);
                    // Indicate that the call was transferred on ringing so that the displays should not be updated by
                    // CircuitCallControlSvc when processing the incoming call
                    call.transferredOnRinging = true;
                    setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName, function () {
                        // Wait for reverse lookup of peer user display data to update the call object before publishing the event.
                        // Note, the callControlSvc listens for this event and will in this case publish a call state event used
                        // (at least by iOS) to update call display data.
                        if (_snapshotPerformed) {
                            publishAtcCallInfo(call);
                        }
                    });
                }
                return;
            }

            switch (event.localConnectionInfo) {
            case 'hold':
                call.setCstaState(CstaCallState.Holding);
                break;
            case 'connected':
                call.setCstaState(CstaCallState.Active);
                break;
            case 'alerting':
                call.setCstaState(CstaCallState.Ringing);
                break;
            default:
                LogSvc.debug('[CstaSvc]: Unexpected localConnectionInfo: ', event.localConnectionInfo);
                break;
            }

            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
            if (display) {
                call.atcCallInfo.setPartnerDisplay(display);
                setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName, function () {
                    // Wait for reverse lookup of peer user display data to update the call object before publishing the event.
                    // Note, the callControlSvc listens for this event and will in this case publish a call state event used
                    // (at least by iOS) to update call display data.
                    if (_snapshotPerformed) {
                        publishAtcCallInfo(call);
                    }
                });
            }

            publishAtcCallInfo(call);
        }

        function checkOs4kPrivateDevice2(direction, event, transferringDeviceDisplay, transferredToDeviceDisplay, xferConn) {
            var transferredConnection = xferConn.endpoint || (xferConn.connection && xferConn.connection.dID);
            var xferConnDisplay = getDisplayInfo(transferredConnection);
            var presentationRestrictedDevice2Display = getDisplayInfo(event.presentationRestrictedDevice2);
            if (event.cause === 'transfer' && direction === CallDirection.INCOMING && isMyDeviceId(event.transferredToDevice) &&
                xferConnDisplay.dn === presentationRestrictedDevice2Display.dn) {
                return true;
            } else if (direction === CallDirection.OUTGOING && transferringDeviceDisplay.dn !== transferredToDeviceDisplay.dn) {
                return true;
            }
            return false;
        }

        // eslint-disable-next-line complexity
        function handleFailedEvent(event) {
            var callId = event.failedConnection.cID;
            var localFailedConnection = event.failedConnection;
            //Special handling for failing event due to busy signal setting from other party
            if (event.localConnectionInfo === 'connected' && isMyDeviceId(event.callingDevice)) {
                // outgoing call - My device is the calling party
                localFailedConnection = {
                    cID: event.failedConnection.cID,
                    dID: event.callingDevice
                };
            }
            var position = getCallPosition(localFailedConnection.dID);
            var call = position === Targets.WebRTC ? findWebRTCCall(callId) : _atcRemoteCalls[callId];
            if (!call) {
                LogSvc.warn('[CstaSvc]: Could not find call corresponding to handle FailedEvent Event.');
                return;
            }
            var display;

            if (event.localConnectionInfo === 'fail' && isMyDeviceId(event.failingDevice)) {
                if (isMasterParallelHgCall(call)) {
                    LogSvc.debug('[CstaSvc]: Ignore Failed event for master parallel hunt group');
                    return;
                }
                if (isMyDeviceId(event.callingDevice)) {
                    display = getDisplayInfo(event.calledDevice);
                    if (display.dn !== _numberNotAvailable && call && !call.pickedUp &&
                        display.fqn !== Utils.cleanPhoneNumber(call.atcCallInfo.peerFQN)) {
                        setCallPeerUser(call, display.dn, display.fqn, display.name);
                        call.atcCallInfo.setPartnerDisplay(display);
                    }
                }
                switch (event.cause) {
                case 'blocked':
                    // track the transfer result
                    if (_trackTransferCallIds[callId]) {
                        LogSvc.debug('[CstaSvc]: Received Failed event for transfer, waiting for ConnectionCleared event');
                    }
                    if (!$rootScope.localUser.isOSV) {
                        if (call.isHandoverInProgress) {
                            LogSvc.debug('[CstaSvc]: Received Failed event from non OSV PBX while handover is in progress');
                            call.clearAtcHandoverInProgress();
                            LogSvc.debug('[CstaSvc]: Publish /atccall/moveFailed event');
                            PubSubSvc.publish('/atccall/moveFailed');
                        }
                        // If HFA device is off-hook after call is released, do not allow call services.
                        if ($rootScope.localUser.isOsBizCTIEnabled && call.isRemote && call.atcCallInfo) {
                            call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
                        }
                    }
                    break;

                case 'doNotDisturb':
                    // ONS has DND active, the incoming call is blocked
                    // Raise an event to inform the user about the blocked call
                    publishAtcCallInfo(call);

                    break;
                case 'reorderTone':
                    call.atcCallInfo.setMissedReason(MissedReasonTypes.REORDER_TONE);
                    publishAtcCallInfo(call);
                    break;
                case 'busy':
                    call.atcCallInfo.setMissedReason(MissedReasonTypes.BUSY);
                    // OS4K case where the call fails in the Offered state due to busy
                    if (call.checkCstaState(CstaCallState.Offered)) {
                        call.setCstaState(CstaCallState.Failed);
                    }
                    call.atcCallInfo.clearOutgoingCallCampedOn();
                    publishAtcCallInfo(call);
                    break;
                case 'destNotObtainable':
                    $timeout(function () {
                        if (call.isPresent()) {
                            hangupOsBizFailedSecondCall(call);
                        }
                    }, OSBIZ_RECONNECT_TIMEOUT);
                    break;
                default:
                    break;
                }
            } else if (event.localConnectionInfo === 'connected' && isMyDeviceId(event.callingDevice)) {
                call.atcCallInfo.setServicesPermitted(event.servicesPermitted);
                display = getDisplayInfo(event.failingDevice || event.calledDevice);
                if (display) {
                    // NGTC-4238: In case of failed event we need to update the partner display for the journal
                    var updateOriginalPartner = call.atcCallInfo.originalPartnerDisplay
                        && call.atcCallInfo.getPartnerDisplay().fqn === call.atcCallInfo.originalPartnerDisplay.fqn;

                    // Update partner's display information if presented
                    call.atcCallInfo.setPartnerDisplay(display);
                    updateOriginalPartner && call.atcCallInfo.setOriginalPartnerDisplay(display);
                    setCallPeerUser(call, call.atcCallInfo.peerDn, call.atcCallInfo.peerFQN, call.atcCallInfo.peerName);
                }
                if (call.isRemote && event.cause === 'busy') {
                    // Raise an event to inform the user about the busy call
                    call.setCstaState(CstaCallState.Busy);
                }

                call.atcCallInfo.setMissedReason(convertCstaCauseToReason(event.cause));
                publishAtcCallInfo(call);
            }
        }

        function handleServiceCompletionFailureEvent(event) {
            var callId = event.primaryCall.connectionID.cID;
            var position = getCallPosition(event.primaryCall.connectionID.dID, _osmoData.epid);
            var call = position === Targets.WebRTC ? findWebRTCCall(callId) : _atcRemoteCalls[callId];

            if (!call) {
                LogSvc.debug('[CstaSvc]: Could not find a call with Call Id = ' + callId);
                LogSvc.debug('[CstaSvc]: Publish /atccall/hangingcall event');
                PubSubSvc.publish('/atccall/hangingcall', callId);
                return;
            }

            LogSvc.debug('[CstaSvc]: Publish /atccall/moveFailed event');
            PubSubSvc.publish('/atccall/moveFailed');
            // If the original call is already in Terminated state, it needs to be recreated
            if (call.checkState([CallState.Terminated, CallState.Idle])) {
                var atcCallInfo = call.atcCallInfo;
                var peerUser = call.peerUser;
                var establishedTime = call.establishedTime;
                var direction = call.direction;
                delete _atcRemoteCalls[callId];

                call = createAtcRemoteCall(callId);
                call.atcCallInfo = atcCallInfo;
                call.peerUser = peerUser;
                call.establishedTime = establishedTime;
                call.direction = direction;
                _atcRemoteCalls[callId] = call;
            }
            call.clearAtcHandoverInProgress();
            publishAtcCallInfo(call);
        }

        function handleBackInServiceEvent() {
            _onsUnregistered = false;
            publishCstaDeviceChanged();
        }

        function handleOutOfServiceEvent() {
            _onsUnregistered = true;
            publishCstaDeviceChanged();
        }

        function checkSetForwardingLoop(cb) {
            if (_loopDetectionTimer) {
                LogSvc.warn('[CstaSvc]: Queue new forwarding request until loop detected timer expires');
                _pendingFwdRequest = cb;
                return;
            }

            if ((Date.now() - _lastSetForwardingTimestamp) < LOOP_DETECTION_INTERVAL) {
                // Increase the loop counter if the last SetForwarding was sent less than 2s from the previous
                _loopCounter++;
            } else {
                _loopCounter = 0;
            }
            _lastSetForwardingTimestamp = Date.now();

            LogSvc.debug('[CstaSvc]: Forward loop counter: ' + _loopCounter + ' timestamp: ' + _lastSetForwardingTimestamp);
            if (_loopCounter > MAX_NUMBER_OF_LOOPS) {
                // If the loop counter exceeds the maximum number of loops then a loop is detected
                LogSvc.error('[CstaSvc]: Loop detected while trying to update forwarding data.');
                _pendingFwdRequest = cb;
                _loopDetectionTimer = $timeout(function () {
                    LogSvc.debug('[CstaSvc]: Loop detection timeout has expired. Process queued request.');
                    _loopCounter = 0;
                    _loopDetectionTimer = null;
                    _pendingFwdRequest && _pendingFwdRequest();
                    _pendingFwdRequest = null;
                }, LOOP_DETECTION_WAIT_TIME, false);
            } else {
                cb && cb();
            }
        }

        function handleForwardingEvent(data) {
            if (!data) {
                return;
            }
            var found = _osmoData.forwardList.some(function (element) {
                if (element.forwardingType === data.forwardingType || (isForwardingImmediate(element.forwardingType) && isForwardingImmediate(data.forwardingType))) {
                    element.forwardStatus = data.forwardStatus;
                    element.forwardDN = data.forwardTo;
                    return true;
                }
                return false;
            });
            if (!found) {
                _osmoData.forwardList.push({
                    forwardDN: data.forwardTo,
                    forwardStatus: data.forwardStatus,
                    forwardingType: data.forwardingType
                });
            }

            var cfType = convertFromCstaFWType(data.forwardingType);
            switch (cfType) {
            case CallForwardingTypes.Immediate.name:
                $rootScope.localUser.cfData.Immediate.cfwAvailable = true;
                $rootScope.localUser.cfData.Immediate.cfwNumber = !isVMNumber(data.forwardTo) && PhoneNumberFormatter.format(data.forwardTo);
                $rootScope.localUser.cfData.Immediate.cfwEnabled = data.forwardStatus && !isVMNumber(data.forwardTo);

                $rootScope.localUser.cfData.VM.cfwAvailable = true;
                $rootScope.localUser.cfData.VM.cfwNumber = _osmoData.vm;
                $rootScope.localUser.cfData.VM.cfwEnabled = data.forwardStatus && isVMNumber(data.forwardTo);
                break;
            case CallForwardingTypes.NoAnswer.name:
                if ($rootScope.localUser.isOsBizCTIEnabled && isOSBizCallRoutingSupported() && !data.forwardStatus) {
                    $rootScope.localUser.reroutingPhoneNumber = '';
                } else if ($rootScope.circuitLabs.CALL_FORWARD_NO_REPLY && $rootScope.localUser.isOSV) {
                    $rootScope.localUser.cfData.NoAnswer.cfwAvailable = true;
                    $rootScope.localUser.cfData.NoAnswer.cfwNumber = PhoneNumberFormatter.format(data.forwardTo);
                    $rootScope.localUser.cfData.NoAnswer.cfwEnabled = data.forwardStatus;
                }
                break;
            case 'preferredDevice':
                // OSBiz routing option = alternative number
                if (isOSBizCallRoutingSupported()) {
                    if (!data.forwardStatus && isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    } else if (data.forwardStatus && $rootScope.localUser.reroutingPhoneNumber) {
                        setRoutingOption(RoutingOptions.AlternativeNumber);
                    }
                }
                break;
            default:
                break;
            }

            if (data.hasOwnProperty('staticOndActive')) {
                if (data.staticOndActive) {
                    _savedStaticOnd = data.staticOndDN;
                    if (data.staticOndDN === Utils.cleanPhoneNumber($rootScope.localUser.reroutingPhoneNumber)) {
                        setRoutingOption(RoutingOptions.AlternativeNumber);
                    } else {
                        setRoutingOption(RoutingOptions.Other);
                        $rootScope.localUser.otherRoutingNumber = PhoneNumberFormatter.format(data.staticOndDN);
                    }
                } else {
                    _savedStaticOnd = '';
                    if (isSelectedRouting(RoutingOptions.AlternativeNumber) || isSelectedRouting(RoutingOptions.Other)) {
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    }
                }

                LogSvc.debug('[CstaSvc]: Publish /csta/staticOndUpdated event');
                PubSubSvc.publish('/csta/staticOndUpdated');
            }

            if (data.hasOwnProperty('voicemailActive')) {
                $rootScope.localUser.voicemailPBXEnabled = true;
                $rootScope.localUser.voicemailActive = data.voicemailActive;
                $rootScope.localUser.voicemailRingDuration = data.voicemailRingDuration;
                _savedVMRingDuration = data.voicemailRingDuration;
            }

            if (data.hasOwnProperty('mainRingDuration')) {
                $rootScope.localUser.ringDurationEnabled = true;
                $rootScope.localUser.mainRingDuration = data.mainRingDuration;
                $rootScope.localUser.clientRingDuration = data.clientRingDuration;
                $rootScope.localUser.cellRingDuration = data.cellRingDuration;
                _savedMainRingDuration = data.mainRingDuration;
                _savedClientRingDuration = data.clientRingDuration;
                _savedCellRingDuration = data.cellRingDuration;
                $rootScope.localUser.alternativeNumber = data.alternativeNumber;
                $rootScope.localUser.routeToCell = data.routeToCell;
                $rootScope.localUser.ringDurationConfigurable = data.hasOwnProperty('overrideProfile') ? data.overrideProfile : !$rootScope.localUser.isOSV;
            }

            if ($rootScope.circuitLabs.CALL_FORWARD_NO_REPLY && data.forwardingCapsChanged) {
                $rootScope.localUser.cfData.Immediate.cfwAvailable =
                    $rootScope.localUser.cfData.NoAnswer.cfwAvailable = false;
                _that.getForwarding(handleGetForwardingResp);
            }
            LogSvc.debug('[CstaSvc]: Publish /localUser/update event');
            PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);

            LogSvc.debug('[CstaSvc]: Publish /csta/forwardingEvent');
            PubSubSvc.publish('/csta/forwardingEvent');
        }

        function handleAgentEvent(event) {
            if ($rootScope.localUser) {
                $rootScope.localUser.isOsBizCTIEnabled ? handleAgentEventOSBiz(event) : handleAgentEventATC(event);
            }
        }

        function handleAgentEventOSBiz(event) {
            if (!event || !event.agentDevice || !getRegistrationData() || event.agentGroup !== _osmoData.onsFQN) {
                return;
            }

            var agentTarget = getCallPosition(event.agentDevice);
            var oldRoutingOption = $rootScope.localUser.selectedRoutingOption;
            switch (event.name) {
            case 'AgentReadyEvent':
                if ((agentTarget === Targets.WebRTC && isSelectedRouting(RoutingOptions.DeskPhone)) ||
                    (agentTarget === Targets.Desk && isSelectedRouting(RoutingOptions.CircuitClient))) {
                    setRoutingOption(RoutingOptions.DefaultRouting);
                }
                break;
            case 'AgentNotReadyEvent':
                if (agentTarget === Targets.WebRTC) {
                    setRoutingOption(RoutingOptions.DeskPhone);
                } else if (agentTarget === Targets.Desk) {
                    setRoutingOption(RoutingOptions.CircuitClient);
                }
                break;
            }

            if ($rootScope.localUser.selectedRoutingOption !== oldRoutingOption) {
                LogSvc.debug('[CstaSvc]: Publish /csta/agentStateUpdated');
                PubSubSvc.publish('/csta/agentStateUpdated');
            }
        }

        function handleAgentEventATC(event) {
            switch (event.name) {
            case 'AgentReadyEvent':
                $rootScope.localUser.agentStateReady = true;
                break;
            case 'AgentNotReadyEvent':
            case 'AgentBusyEvent':
            case 'AgentWorkingAfterCallEvent':
                $rootScope.localUser.agentStateReady = false;
                break;
            case 'AgentLoggedOffEvent':
                $rootScope.localUser.isAgent = false;
                $rootScope.localUser.agentStateReady = false;
                break;
            case 'AgentLoggedOnEvent':
                $rootScope.localUser.isAgent = true;
                $rootScope.localUser.agentStateReady = false;
                break;
            default:
                return;
            }
            if ($rootScope.localUser.isOSV) {
                _huntGroupList = event.huntGroupList;
            }
            LogSvc.debug('[CstaSvc]: Publish /agent/state/ready event');
            PubSubSvc.publish('/agent/state/ready', [$rootScope.localUser.agentStateReady, $rootScope.localUser]);
        }

        function handover(call, target, destination, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            if (!call.atcCallInfo || (!call.checkCstaState([CstaCallState.Active]) && (!call.atcCallInfo.isHandoverAllowed() && !call.atcCallInfo.isDeflectAllowed()))) {
                cb && cb('res_MoveCallFailed');
                return;
            }
            if (!isTargetAllowed(call, target)) {
                cb && cb('The selected target is not available for this call.');
                return;
            }

            var newDestination = buildNewDestination(target, destination, $rootScope.localUser.isOsBizCTIEnabled, $rootScope.localUser.isOsBizCTIEnabled);
            if (!newDestination) {
                cb && cb('Failed to create new destination.');
                return;
            }

            var data = {
                autoAnswer: target === Targets.Desk || target === Targets.WebRTC
            };

            if (call.checkCstaState([CstaCallState.Ringing, CstaCallState.Parked])) {
                // Need to send a CSTA Deflect Call request instead of CSTA SST request
                data.request = 'DeflectCall';
                data.callToBeDiverted = getCstaConnectionForRequest(call.atcCallInfo);
                data.newDestination = newDestination;

                // 4K sends the DeflectCall response after sending the new INVITE to the destination, so we need to inform
                // circuitCallControlSvc earlier about the incoming handover call
                if (!$rootScope.localUser.isOSV) {
                    LogSvc.debug('[CstaSvc]: Publish /csta/handover event');
                    PubSubSvc.publish('/csta/handover', call.atcCallInfo.getCstaCallId());
                }
                call.setAtcHandoverInProgress();
                sendCstaRequest(data, function (err) {
                    if (err) {
                        cb && cb('res_MoveCallFailed');
                        call.clearAtcHandoverInProgress();
                        if (!$rootScope.localUser.isOSV) {
                            // Inform circuitCallControlSvc that the handover failed
                            LogSvc.debug('[CstaSvc]: Publish /atccall/moveFailed event');
                            PubSubSvc.publish('/atccall/moveFailed', [true]);
                        }
                    } else {
                        if ($rootScope.localUser.isOSV) {
                            LogSvc.debug('[CstaSvc]: Publish /csta/handover event');
                            PubSubSvc.publish('/csta/handover', call.atcCallInfo.getCstaCallId());
                        }
                        // 4K sends the Diverted after the call has been redirected, which is too late to set the
                        // ignoreCall flag
                        if (target === Targets.WebRTC) {
                            call.atcCallInfo.setIgnoreCall(true);
                        }
                        call.atcCallInfo.transferCb = cb;

                        setLocalCallAsPulled(call.atcCallInfo.getCstaCallId(), newDestination);
                    }
                });
            } else {
                data.request = 'SingleStepTransferCall';
                data.activeCall = getCstaConnectionForRequest(call.atcCallInfo);
                data.transferredTo = newDestination;
                data.seamlessHandover = call.atcCallInfo.isSeamlessHandoverAllowed();

                // 4K sends the SST response after sending the new INVITE to the destination, so we need to inform
                // circuitCallControlSvc earlier about the incoming handover call
                if (!$rootScope.localUser.isOSV) {
                    LogSvc.debug('[CstaSvc]: Publish /csta/handover event');
                    PubSubSvc.publish('/csta/handover', call.atcCallInfo.getCstaCallId());
                }
                call.setAtcHandoverInProgress();

                sendCstaRequest(data, function (err, rs) {
                    if (err || !rs) {
                        cb && cb('res_MoveCallFailed');
                        call.clearAtcHandoverInProgress();
                        if (!$rootScope.localUser.isOSV) {
                            // Inform circuitCallControlSvc that the handover failed
                            LogSvc.debug('[CstaSvc]: Publish /atccall/moveFailed event');
                            PubSubSvc.publish('/atccall/moveFailed', [true]);
                        }
                    } else {
                        storeTransferredCall(data.activeCall.cID, rs);
                        _movingCall = {
                            primaryCallId: data.activeCall.cID,
                            newCallId: rs.transferredCall.cID,
                            direction: call.direction,
                            redirectingUser: call.redirectingUser,
                            transferCb: cb
                        };

                        if ($rootScope.localUser.isOSV) {
                            LogSvc.debug('[CstaSvc]: Publish /csta/handover event');
                            PubSubSvc.publish('/csta/handover', call.atcCallInfo.getCstaCallId());
                        }
                        call.atcCallInfo.transferCb = cb;
                    }
                });
            }
        }

        function createEarlyAtcRemoteCall(destination, target) {
            var earlyAtcRemoteCall = createAtcRemoteCall(destination.dialedDn);
            setCallPeerUser(earlyAtcRemoteCall, destination.dialedDn, destination.dialedDn, destination.toName);
            earlyAtcRemoteCall.setCstaState(CstaCallState.Delivered);
            earlyAtcRemoteCall.direction = CallDirection.OUTGOING;
            earlyAtcRemoteCall.setPosition(target);
            var display = {
                dn: destination.dialedDn,
                fqn: destination.dialedDn,
                name: destination.toName || ''
            };
            earlyAtcRemoteCall.atcCallInfo.setPartnerDisplay(display);
            earlyAtcRemoteCall.atcCallInfo.setIgnoreCall(true);
            _atcRemoteCalls[destination.dialedDn] = earlyAtcRemoteCall;

            if ((target === Targets.WebRTC) && $rootScope.localUser.isOsBizCTIEnabled) {
                var options = {reuseSessionCtrl: _activeCall && _activeCall.sessionCtrl};
                var secondOsBizLocalCall = new LocalCall(_telephonyConversation, options);
                secondOsBizLocalCall.atcCallInfo = earlyAtcRemoteCall.atcCallInfo;
                setCallPeerUser(secondOsBizLocalCall, earlyAtcRemoteCall.peerUser.phoneNumber, null, earlyAtcRemoteCall.peerUser.displayName);
                secondOsBizLocalCall.isOsBizSecondCall = true;
                secondOsBizLocalCall.setState(CallState.Initiated);
                secondOsBizLocalCall.direction = CallDirection.OUTGOING;
                removeAtcRemoteCall(destination.dialedDn, true);
                _telephonyConversation.call = secondOsBizLocalCall;
                LogSvc.debug('[CstaSvc]: Publish /conversation/update event. convId = ', _telephonyConversation.convId);
                PubSubSvc.publish('/conversation/update', [_telephonyConversation]);

                CircuitCallControlSvc.addCallToList(secondOsBizLocalCall);
                LogSvc.debug('[CstaSvc]: Publish /call/state event. callId = ' + secondOsBizLocalCall.callId + ', state = ' + secondOsBizLocalCall.state.name);
                PubSubSvc.publish('/call/state', [secondOsBizLocalCall]);
            } else {
                publishAtcCallInfo(earlyAtcRemoteCall);
                LogSvc.debug('[CstaSvc]: Created new Remote Call object: ', earlyAtcRemoteCall);
            }
        }

        function updateEarlyAtcRemoteCall(connection, destination, target) {
            var call = findAtcRemoteCall(destination.dialedDn);
            if (!call) {
                call = createAtcRemoteCall(connection.cID);
                setCallPeerUser(call, destination.dialedDn, destination.dialedDn, destination.toName);
                call.setCstaState(CstaCallState.Delivered);
                call.direction = CallDirection.OUTGOING;
                call.atcCallInfo.setCstaConnection(connection);
                call.setPosition(target);
                var display = {
                    dn: destination.dialedDn,
                    fqn: destination.dialedDn,
                    name: destination.toName || ''
                };
                call.atcCallInfo.setPartnerDisplay(display);
            } else {
                call.atcCallInfo.setCstaConnection(connection);
                call.setCallIdForTelephony(connection.cID);
                call.setCstaState(CstaCallState.Delivered);
                call.atcCallInfo.setIgnoreCall(false);
                if (destination.dtmfDigits) {
                    call.dtmfDigits = destination.dtmfDigits;
                }
                delete _atcRemoteCalls[destination.dialedDn];
            }
            call.atcCallInfo.setOriginalPartnerDisplay(call.atcCallInfo.getPartnerDisplay());
            _atcRemoteCalls[connection.cID] = call;
            publishAtcCallInfo(call);
            LogSvc.debug('[CstaSvc]: Created new Remote Call object: ', call);
        }

        function createCampOnLocalCall(earlyRemoteAtcCall, servicesPermitted) {
            if (!earlyRemoteAtcCall || !_activeCall) {
                return;
            }

            LogSvc.debug('[CstaSvc]: Creating local waiting call');

            earlyRemoteAtcCall.direction = CallDirection.INCOMING;
            earlyRemoteAtcCall.setPosition(Targets.WebRTC);
            if (servicesPermitted) {
                earlyRemoteAtcCall.atcCallInfo.setServicesPermitted(servicesPermitted);
            }
            earlyRemoteAtcCall.atcCallInfo.setIgnoreCall(false);

            // Create new local incoming call in state ringing
            var options = {reuseSessionCtrl: _activeCall && _activeCall.sessionCtrl};
            var secondOsBizLocalCall = new LocalCall(_telephonyConversation, options);
            secondOsBizLocalCall.atcCallInfo = earlyRemoteAtcCall.atcCallInfo;
            setCallPeerUser(secondOsBizLocalCall, earlyRemoteAtcCall.peerUser.phoneNumber, null, earlyRemoteAtcCall.peerUser.displayName);
            secondOsBizLocalCall.isOsBizSecondCall = true;
            _activeCall.isOsBizFirstCall = true;
            secondOsBizLocalCall.direction = CallDirection.INCOMING;

            _telephonyConversation.call = secondOsBizLocalCall;
            LogSvc.debug('[CstaSvc]: Publish /conversation/update event. convId = ', _telephonyConversation.convId);
            PubSubSvc.publish('/conversation/update', [_telephonyConversation]);

            secondOsBizLocalCall.setState(CallState.Ringing);
            secondOsBizLocalCall.setCstaState(CstaCallState.Ringing);

            CircuitCallControlSvc.addCallToList(secondOsBizLocalCall);

            // Publish incoming call so that early remote atc call is released and second local call is displayed.
            // Play call waiting sound to user
            LogSvc.debug('[CstaSvc]: Publish /call/incoming event');
            PubSubSvc.publish('/call/incoming', [_telephonyConversation.call]);
        }

        function getCallDevices() {
            var status = {
                webRTCAdapterEnabled: circuit.WebRTCAdapter.enabled,
                browser: circuit.WebRTCAdapter.browser,
                _onsUnregistered: _onsUnregistered
            };
            LogSvc.debug('[CstaSvc]: getCallDevices: ', JSON.stringify(status));
            var devices = [];
            if (circuit.WebRTCAdapter.enabled) {
                devices.push(Targets.WebRTC);
            }
            if (!_onsUnregistered) {
                devices.push(Targets.Desk);
            }
            if ((_osmoData && _osmoData.cell) || ($rootScope.localUser.isOsBizCTIEnabled &&
                $rootScope.localUser.reroutingPhoneNumber && isSelectedRouting(RoutingOptions.AlternativeNumber))) {
                devices.push(Targets.Cell);
            }
            return devices;
        }

        function getPushDevices() {
            var devices = [];
            if (!_onsUnregistered) {
                devices.push(Targets.Desk);
            }
            if (_osmoData && _osmoData.cell) {
                devices.push(Targets.Cell);
            }
            return devices;
        }

        function getAnswerDevices(call) {
            if (!call) {
                return [];
            }
            var devices = [Targets.WebRTC];

            if (call.pickupSession) {
                return devices;
            }

            var hasVM = false;

            if (!_onsUnregistered && !call.isOsBizSecondCall) {
                // A second incoming call from OSBiz can only be answered in the same device as the first call
                devices.push(Targets.Desk);
            }
            if (_osmoData && _osmoData.cell && call.getPosition() !== Targets.Cell) {
                devices.push(Targets.Cell);
            }
            // Only add VM in the list if VM is active
            if (_osmoData && _osmoData.vm && !call.pickupNotification &&
                ($rootScope.localUser.voicemailActive || $rootScope.localUser.isOsBizCTIEnabled)) {
                devices.push(Targets.VM);
                hasVM = true;
            }

            if (!$rootScope.localUser.isOsBizCTIEnabled) {
                return devices;
            }

            // Additional checks for OSBiz
            if (call.atcCallInfo && call.atcCallInfo.isRecalledCall()) {
                // Recalled call on desk cannot be answered locally
                devices.shift();
            }

            switch ($rootScope.localUser.selectedRoutingOption) {
            case RoutingOptions.AlternativeNumber.name:
                if (call.getPosition() === Targets.Cell) {
                    // Call is on alternative number, so it cannot be answered locally or on desk.
                    // It can only be deflected to VM, if configured.
                    devices = hasVM ? [Targets.VM] : [];
                }
                break;
            case RoutingOptions.DeskPhone.name:
                if (call.getPosition() === Targets.Desk) {
                    // Call can only be answered in desk or deflected to VM, if configured.
                    devices = [Targets.Desk];
                    if (hasVM) {
                        devices.push(Targets.VM);
                    }
                }
                break;
            case RoutingOptions.CircuitClient.name:
                if (call.direction === CallDirection.INCOMING) {
                    // Call cannot be answered on desk
                    var idx = devices.indexOf(Targets.Desk);
                    if (idx !== -1) {
                        devices.splice(idx, 1);
                    }
                }
                break;
            }

            if (call.direction === CallDirection.INCOMING && $rootScope.localUser.reroutingPhoneNumber && !isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                // Call can be deflected to alternative number
                devices.push(Targets.Cell);
            }

            return devices;
        }

        function getMyDevices() {
            var devices = getCallDevices();
            if (_osmoData && _osmoData.vm) {
                devices.push(Targets.VM);
            }

            // For OSBiz, alternative number can be a deflect target device but not a makeCall device.
            if ($rootScope.localUser.isOsBizCTIEnabled && $rootScope.localUser.reroutingPhoneNumber && !devices.includes(Targets.Cell)) {
                devices.push(Targets.Cell);
            }

            devices.push(Targets.Other);
            return devices;
        }

        function handleFirstCall(call) {
            var connection = (call.atcCallInfo && call.atcCallInfo.getCstaConnection()) || _incomingCallConnection;
            if (_primaryClient) {
                var data = {
                    request: 'DeflectCall',
                    callToBeDiverted: connection,
                    newDestination: buildNewDestination(Targets.Desk)
                };
                sendCstaRequest(data);
            }
        }

        function handleSecondCall(call) {
            var data = null;
            LogSvc.debug('[CstaSvc]: handleSecondCall: BusyHandlingOption: ', $rootScope.localUser.selectedBusyHandlingOption);
            if ($rootScope.localUser.selectedBusyHandlingOption !== BusyHandlingOptions.DefaultRouting.name) {
                var connection = (call.atcCallInfo && call.atcCallInfo.getCstaConnection()) || _incomingCallConnection;
                LogSvc.debug('[CstaSvc]: handleSecondCall: connection: ', connection);
                if (connection && connection.cID && _primaryClient) {
                    switch ($rootScope.localUser.selectedBusyHandlingOption) {
                    case BusyHandlingOptions.SendToAlternativeNumber.name:
                        data = {
                            request: 'DeflectCall',
                            callToBeDiverted: connection,
                            newDestination: buildNewDestination(Targets.Cell)
                        };
                        break;
                    case BusyHandlingOptions.BusySignal.name:
                        data = {
                            request: 'ClearConnection',
                            connectionToBeCleared: connection,
                            reason: 'busy'
                        };
                        break;
                    case BusyHandlingOptions.SendToVM.name:
                        data = {
                            request: 'DeflectCall',
                            callToBeDiverted: connection,
                            newDestination: buildNewDestination(Targets.VM)
                        };
                        break;
                    default:
                        return;
                    }
                    sendCstaRequest(data, function (err) {
                        if (!err) {
                            if (data.request === 'ClearConnection') {
                                if (call.atcCallInfo) {
                                    call.atcCallInfo.setMissedReason(MissedReasonTypes.BUSY);
                                    // This is the case that the second call was rejected upon the Offered event. The CSTA
                                    // state needs to be changed
                                    if (call.checkCstaState(CstaCallState.Offered)) {
                                        call.setCstaState(CstaCallState.Failed);
                                    }
                                } else if (AtcRegistrationSvc.isClearConnectionBusySupported()) {
                                    var remoteCall = _atcRemoteCalls[connection.cID] || createAtcRemoteCall(connection.cID);
                                    remoteCall.peerUser = call.peerUser;
                                    remoteCall.direction = CallDirection.INCOMING;
                                    remoteCall.creationTime = call.creationTime;
                                    remoteCall.atcCallInfo.setMissedReason(MissedReasonTypes.BUSY);
                                    _atcRemoteCalls[connection.cID] = remoteCall;
                                    removeAtcRemoteCall(connection.cID);
                                }
                            }
                        }
                    });
                    _incomingCallConnection = {};
                }
            } else if (call.checkCstaState(CstaCallState.Offered)) {
                data = {
                    request: 'AcceptCall',
                    callToBeAccepted: call.atcCallInfo.getCstaConnection()
                };
                sendCstaRequest(data);
                _incomingCallConnection = {};
            }
        }

        function updateRoutingOptionATC(selectedOption, oldOption, cb) {
            cb = cb || function () {};
            switch (selectedOption) {
            case RoutingOptions.AlternativeNumber.name:
                _that.updateStaticOnd(true, function (err) {
                    if (!err) {
                        _that.setForwardingToVM(false, cb);
                    } else {
                        cb(err);
                    }
                });
                break;
            case RoutingOptions.VM.name:
                _that.setForwardingToVM(true, function (err) {
                    if (err) {
                        cb(err);
                    } else {
                        _that.updateStaticOnd(false, cb);
                    }
                });
                break;
            default:
                _that.setForwardingToVM(false, function (err) {
                    if (err) {
                        cb(err);
                    } else if (!_supportsStaticOndSync || oldOption === RoutingOptions.AlternativeNumber.name || oldOption === RoutingOptions.Other.name) {
                        _that.updateStaticOnd(false, cb);
                    } else {
                        cb();
                    }
                });
                break;
            }
        }

        function updateRoutingOptionOSBiz(selectedOption, oldOption, cb) {
            if (selectedOption === oldOption) {
                cb && cb();
                return;
            }
            switch (selectedOption) {
            case RoutingOptions.AlternativeNumber.name:
                disableRoutingOption(oldOption, function (err) {
                    if (err) {
                        cb && cb(err);
                    } else {
                        _that.setRoutingOptionReroutingNumber($rootScope.localUser.reroutingPhoneNumber, cb);
                    }
                });
                break;

            case RoutingOptions.DeskPhone.name:
                disableRoutingOption(oldOption, function (err) {
                    if (err) {
                        cb && cb(err);
                    } else {
                        _that.setAgentStateOSBiz(false, RoutingOptions.CircuitClient.name, cb);
                    }
                });
                break;

            case RoutingOptions.CircuitClient.name:
                disableRoutingOption(oldOption, function (err) {
                    if (err) {
                        cb && cb(err);
                    } else {
                        _that.setAgentStateOSBiz(false, RoutingOptions.DeskPhone.name, cb);
                    }
                });
                break;

            default:
                // Set default routing option
                disableRoutingOption(oldOption, cb);
                break;
            }
        }


        function evaluateDndState(newState) {
            // If snooze status does not match to OSBiz DND status, update Circuit client
            if ($rootScope.localUser.userPresenceState.state !== Constants.PresenceState.DND && newState) {
                LogSvc.debug('[CstaSvc]: DND is enabled on the switch. Set snooze on Circuit.');
                UserProfileSvc.snooze(-1);
            } else if ($rootScope.localUser.userPresenceState.state === Constants.PresenceState.DND && !newState) {
                LogSvc.debug('[CstaSvc]: DND is disabled on the switch. Resume snooze on Circuit.');
                UserProfileSvc.resume();
            }
        }

        function handleDoNotDisturbEvent(data) {
            if (isDNDSupported() && data && _primaryClient) {
                evaluateDndState(data.doNotDisturbOn);
            } else {
                $rootScope.localUser.doNotDisturbOn = data.doNotDisturbOn;
            }
        }

        function handleGetDoNotDisturbResp(err, data) {
            if (!err && data) {
                if ($rootScope.localUser.isOsBizCTIEnabled) {
                    evaluateDndState(data.doNotDisturbOn);
                } else {
                    $rootScope.localUser.doNotDisturbOn = data.doNotDisturbOn;
                }
            }
        }

        function convertToCstaFWType(cfType) {
            switch (cfType) {
            case CallForwardingTypes.Immediate.name:
            case CallForwardingTypes.VM.name:
                return 'forwardImmediate';
            case CallForwardingTypes.NoAnswer.name:
                return 'forwardNoAns';
            default:
                return '';
            }
        }

        function convertFromCstaFWType(cstaFWType) {
            switch (cstaFWType) {
            case 'forwardImmediate':
                return CallForwardingTypes.Immediate.name;
            case 'forwardImmInt':
            case 'forwardImmExt':
                return $rootScope.localUser.isOsBizCTIEnabled ? CallForwardingTypes.Immediate.name : '';
            case 'forwardNoAns':
                return CallForwardingTypes.NoAnswer.name;
            case 'preferredDevice':
                return $rootScope.localUser.isOsBizCTIEnabled ? 'preferredDevice' : '';
            default:
                return '';
            }
        }

        function processForwardList(forwardList, preferredDeviceOnly) {
            forwardList && forwardList.forEach(function (element) {
                var cfType = convertFromCstaFWType(element.forwardingType);
                if (preferredDeviceOnly && (cfType !== 'preferredDevice')) {
                    return;
                }
                switch (cfType) {
                case CallForwardingTypes.Immediate.name:
                    $rootScope.localUser.cfData.Immediate.cfwAvailable = true;
                    $rootScope.localUser.cfData.Immediate.cfwEnabled = element.forwardStatus && !isVMNumber(element.forwardDN);
                    $rootScope.localUser.cfData.Immediate.cfwNumber = !isVMNumber(element.forwardDN) && PhoneNumberFormatter.format(element.forwardDN);

                    $rootScope.localUser.cfData.VM.cfwAvailable = true;
                    $rootScope.localUser.cfData.VM.cfwEnabled = element.forwardStatus && isVMNumber(element.forwardDN);
                    $rootScope.localUser.cfData.VM.cfwNumber = _osmoData.vm;
                    break;
                case CallForwardingTypes.NoAnswer.name:
                    if ($rootScope.localUser.isOsBizCTIEnabled && isOSBizCallRoutingSupported()) {
                        if (!element.forwardStatus) {
                            $rootScope.localUser.reroutingPhoneNumber = '';
                            if (_osmoData && _osmoData.osmoDeviceList && isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                                // For mulap user, also make sure that routing option is not set to alternative number
                                setRoutingOption(RoutingOptions.DefaultRouting);
                            }
                        } else {
                            var target = element.forwardDN && extractOnd(element.forwardDN);
                            if (target && target[1] !== $rootScope.localUser.reroutingPhoneNumber) {
                                $rootScope.localUser.reroutingPhoneNumber = target[1];
                            }
                            if (_osmoData && _osmoData.osmoDeviceList && _osmoData.osmoDeviceList.length > 1) {
                                // For mulap user, it is also necessary to set GF using mobile number to get the state of the alternativeNumber Routing option
                                _that.getForwardingPreferredDevice(_osmoData.osmoDeviceList[1], function (err, data) {
                                    data && (data.preferredDeviceOnly = true);
                                    handleGetForwardingResp(err, data);
                                });
                            }
                        }
                    } else if ($rootScope.circuitLabs.CALL_FORWARD_NO_REPLY && $rootScope.localUser.isOSV) {
                        $rootScope.localUser.cfData.NoAnswer.cfwType = CallForwardingTypes.NoAnswer.name;
                        $rootScope.localUser.cfData.NoAnswer.cfwAvailable = true;
                        $rootScope.localUser.cfData.NoAnswer.cfwEnabled = element.forwardStatus;
                        $rootScope.localUser.cfData.NoAnswer.cfwNumber = PhoneNumberFormatter.format(element.forwardDN);
                    }
                    break;
                case 'preferredDevice':
                    // OSBiz routing option = alternative number
                    if (!element.forwardStatus && isSelectedRouting(RoutingOptions.AlternativeNumber)) {
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    } else if (element.forwardStatus && $rootScope.localUser.reroutingPhoneNumber) {
                        setRoutingOption(RoutingOptions.AlternativeNumber);
                    }
                    break;
                default:
                    break;
                }
            });
        }

        function initCfData() {
            Object.values(CallForwardingTypes).forEach(function (cfwType) {
                $rootScope.localUser.cfData[cfwType.name] = {
                    cfwType: cfwType.name,
                    cfwEnabled: false,
                    cfwAvailable: false,
                    cfwNumber: '',
                    label: cfwType.label,
                    description: cfwType.description,
                    message: cfwType.message
                };
            });
        }

        function handleGetForwardingResp(err, data) {
            // When handling Forward response for mulap (preferredDeviceOnly) coming as a secondary response do not init data again
            if (!data || !data.preferredDeviceOnly) {
                initCfData();
            }

            if (err || !data) {
                return;
            }

            $rootScope.localUser.cfData.Immediate.cfwAvailable = !$rootScope.localUser.isOSV;
            processForwardList(data.forwardList, data.preferredDeviceOnly);

            _osmoData.forwardList = data.forwardList || [];

            if (data.hasOwnProperty('voicemailActive')) {
                $rootScope.localUser.voicemailPBXEnabled = true;
                $rootScope.localUser.voicemailActive = data.voicemailActive;
                $rootScope.localUser.voicemailRingDuration = data.voicemailRingDuration;
                _savedVMRingDuration = data.voicemailRingDuration;
            }

            if (data.mainRingDuration) {
                $rootScope.localUser.ringDurationEnabled = true;
                $rootScope.localUser.mainRingDuration = data.mainRingDuration;
                $rootScope.localUser.clientRingDuration = data.clientRingDuration;
                $rootScope.localUser.cellRingDuration = data.cellRingDuration;
                _savedMainRingDuration = data.mainRingDuration;
                _savedClientRingDuration = data.clientRingDuration;
                _savedCellRingDuration = data.cellRingDuration;

                $rootScope.localUser.alternativeNumber = data.alternativeNumber || Utils.cleanPhoneNumber($rootScope.localUser.reroutingPhoneNumber);
                $rootScope.localUser.routeToCell = data.routeToCell;
                $rootScope.localUser.ringDurationConfigurable = data.hasOwnProperty('overrideProfile') ? data.overrideProfile : !$rootScope.localUser.isOSV;
            }

            if (data.hasOwnProperty('staticOndActive')) {
                _supportsStaticOndSync = true;
                if (data.staticOndActive) {
                    _savedStaticOnd = data.staticOndDN;
                    if (isSelectedRouting(RoutingOptions.AlternativeNumber) && !$rootScope.localUser.reroutingPhoneNumber) {
                        // reRegistration after removing alternative number
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    } else if (data.staticOndDN === Utils.cleanPhoneNumber($rootScope.localUser.reroutingPhoneNumber)) {
                        setRoutingOption(RoutingOptions.AlternativeNumber);
                    } else {
                        setRoutingOption(RoutingOptions.Other);
                        $rootScope.localUser.otherRoutingNumber = PhoneNumberFormatter.format(data.staticOndDN);
                    }
                } else {
                    _savedStaticOnd = '';
                    if (isSelectedRouting(RoutingOptions.AlternativeNumber) || isSelectedRouting(RoutingOptions.Other)) {
                        setRoutingOption(RoutingOptions.DefaultRouting);
                    }
                }

                LogSvc.debug('[CstaSvc]: Publish /csta/staticOndUpdated event');
                PubSubSvc.publish('/csta/staticOndUpdated');
            } else {
                // If OSV does not have OSV-3432 fixed, then StaticOnd status cannot be synchronized.
                _supportsStaticOndSync = false;
            }

            LogSvc.debug('[CstaSvc]: Publish /localUser/update event');
            PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);

            LogSvc.debug('[CstaSvc]: Publish /csta/getForwardingResponse');
            PubSubSvc.publish('/csta/getForwardingResponse');
        }

        function handleGetAgentStateResponseATC(err, data) {
            if (err || !data) {
                return;
            }
            $rootScope.localUser.isAgent = data.loggedOnState;
            if ($rootScope.localUser.isAgent) {
                $rootScope.localUser.agentStateReady = data.agentState === AgentState.Ready;
                if ($rootScope.localUser.isOSV) {
                    _huntGroupList = data.huntGroupList;
                }
                LogSvc.debug('[CstaSvc]: Publish /agent/state/ready event');
                PubSubSvc.publish('/agent/state/ready', [_that.getGlobalAgentState(), $rootScope.localUser]);
            }
        }

        function showPickupNotification(call) {
            if (!Utils.isMobile() && !CircuitCallControlSvc.getActiveCall()) {
                call = call || Object.values(_atcRemoteCalls).find(function (remoteCall) {
                    return remoteCall.pickupNotification;
                });
                if (call && call.isPresent() && !call.pickupNotificationShown) {
                    publishAtcCallInfo(call);
                    LogSvc.debug('[CstaSvc]: Publish /call/pickupNotification event');
                    PubSubSvc.publish('/call/pickupNotification', [call]);
                    call.pickupNotificationShown = true;
                }
            }
        }

        function setBusy(status, cb) {
            // Do not not send busy if not registration data or
            // if user is standalone (only phone calls exists then, so pbx already knows state)
            if (!getRegistrationData(cb) || $rootScope.localUser.isStandalone) {
                return;
            }
            var data = {
                request: 'SetBusy',
                device: getCstaDeviceId(),
                busyState: status
            };
            sendCstaRequest(data, cb);
        }

        function checkBusyState() {
            if ($rootScope.localUser.isStandalone || !$rootScope.localUser.isOsBizCTIEnabled) {
                // No Need to update Busy State
                return;
            }

            // Set busy when user is in a RC side call. Reset busy when user is not in a RC side call.
            var hasCall = $rootScope.localUser.userPresenceState.state === Constants.PresenceState.BUSY;
            var isAvailable = $rootScope.localUser.userPresenceState.state === Constants.PresenceState.AVAILABLE;

            LogSvc.debug('[CstaSvc]: Current busy state: ', _userBusyState);

            if ((_userBusyState && (!hasCall || isAvailable)) || (!_userBusyState && hasCall && !isAvailable)) {
                _userBusyState = !_userBusyState;
                if (_primaryClient) {
                    LogSvc.debug('[CstaSvc]: Update busy state. New State: ', _userBusyState);
                    setBusy(_userBusyState);
                }
            }
        }

        function publishOsBizCallState(call, state) {
            if (call.isOsBizSecondCall) {
                call.setState(state);
                LogSvc.debug('[CstaSvc]: Publish /conversation/update event. convId = ', _telephonyConversation.convId);
                PubSubSvc.publish('/conversation/update', [_telephonyConversation]);
            }
        }

        function hangupOsBizFailedSecondCall(call, cb) {
            var firstCall = call.isOsBizSecondCall ? CircuitCallControlSvc.findOsBizFirstCall() : findAtcRemoteHeldCall();
            if (call && firstCall && getRegistrationData(cb)) {
                var data = {
                    request: 'ReconnectCall',
                    heldCall: getCstaConnectionForRequest(firstCall.atcCallInfo),
                    activeCall: getCstaConnectionForRequest(call.atcCallInfo)
                };
                sendCstaRequest(data, cb);
                return;
            }
            cb && cb();
        }

        function disableRoutingOption(option, cb) {
            if ($rootScope.localUser.isOsBizCTIEnabled && option) {
                switch (option) {
                case RoutingOptions.AlternativeNumber.name:
                    _that.setRoutingOptionReroutingNumber(null, cb);
                    return;
                case RoutingOptions.DeskPhone.name:
                    _that.setAgentStateOSBiz(true, RoutingOptions.CircuitClient.name, cb);
                    return;
                case RoutingOptions.CircuitClient.name:
                    _that.setAgentStateOSBiz(true, RoutingOptions.DeskPhone.name, cb);
                    return;
                }
            }
            cb && cb();
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/conversations/showTelephonyConversation', function (show, conv) {
            LogSvc.debug('[CstaSvc]: Received /conversations/showTelephonyConversation event. show = ', show);
            if (show) {
                _telephonyConversation = conv;
            }
        });

        PubSubSvc.subscribe('/conversation/update', function (conversation) {
            if (_telephonyConversation && _telephonyConversation.convId === conversation.convId) {
                _telephonyConversation = conversation;
            }
        });

        PubSubSvc.subscribe('/atcRegistration/state', function (newState) {
            LogSvc.debug('[CstaSvc]: Received /atcRegistration/state event.');
            _regState = newState;
            if ($rootScope.localUser.isATC) {
                if (!_snapshotPerformed && newState === AtcRegistrationState.Registered && _receivedActiveSessions) {
                    snapshotRemoteCallsAndGetFeatureData();
                } else {
                    _snapshotPerformed = false;
                }
                if (newState !== AtcRegistrationState.Registered) {
                    clearAtcRemoteCalls();
                }
            }
        });

        PubSubSvc.subscribe('/activeSessions/received', function () {
            LogSvc.debug('[CstaSvc]: Received /activeSessions/received event');
            _receivedActiveSessions = true;
            if (!_snapshotPerformed) {
                if (_regState === AtcRegistrationState.Registered) {
                    snapshotRemoteCallsAndGetFeatureData();
                }
            }
        });

        PubSubSvc.subscribe('/registration/state', function (state) {
            LogSvc.debug('[CstaSvc]: Received /registration/state event');
            if (state !== RegistrationState.Registered) {
                _receivedActiveSessions = false;
            }
        });

        PubSubSvc.subscribe('/call/incoming', function (incomingCall) {
            if (incomingCall && _telephonyConversation && incomingCall.convId === _telephonyConversation.convId) {
                LogSvc.debug('[CstaSvc]: Received /call/incoming event.');
                // ANS-11246: Since we still don't support a PROGRESS(alerting) message, it is
                // possible for the CSTA Delivered event to arrive before the Circuit INVITE
                // message. This happens because the ATC immediately sends the SIP 180 Ringing
                // response back to the switch without waiting for the alerting indication from
                // the client. Let's check if this is the case here!
                Object.keys(_atcRemoteCalls).some(function (callId) {
                    var remoteCall = _atcRemoteCalls[callId];
                    if (remoteCall.checkCstaState(CstaCallState.Ringing) && remoteCall.isAtPosition(Targets.WebRTC)) {
                        // Found it
                        _alertingCall = findAlertingCall();
                        if (_alertingCall) {
                            LogSvc.debug('[CstaSvc]: Found a matching ATC remote call. Move call info to alerting call.');
                            _alertingCall.atcCallInfo = remoteCall.atcCallInfo;
                            remoteCall.atcCallInfo = new AtcCallInfo();
                            remoteCall.atcCallInfo.setIgnoreCall(true);
                            removeAtcRemoteCall(callId);
                        }
                        return true;
                    }
                    return false;
                });
            }
        });

        PubSubSvc.subscribe('/atccall/firstcall', function (call) {
            LogSvc.debug('[CstaSvc]: Received /atccall/firstcall event');
            handleFirstCall(call);
        });

        PubSubSvc.subscribe('/atccall/secondcall', function (call) {
            LogSvc.debug('[CstaSvc]: Received /atccall/secondcall event.');
            handleSecondCall(call);
        });

        PubSubSvc.subscribe('/atccall/replace', function (call, oldCallId) {
            LogSvc.debug('[CstaSvc]: Received /atccall/replace event');
            var remoteCall = _atcRemoteCalls[oldCallId];
            if (remoteCall) {
                call.establishedTime = remoteCall.establishedTime;
                var remoteCallAtcCallInfo = remoteCall.atcCallInfo;
                if (!call.atcCallInfo) {
                    call.atcCallInfo = new AtcCallInfo();
                }
                call.atcCallInfo.setOriginalPartnerDisplay(remoteCallAtcCallInfo.originalPartnerDisplay);
                removeAtcRemoteCall(oldCallId, true);
            }
        });

        PubSubSvc.subscribe('/callHistory/item/added', function (item) {
            if (item && item.rtc && item.rtc.rtcParticipants && item.rtc.rtcParticipants.length === 2) {
                _lastTwoCallPartners.unshift(item.rtc.rtcParticipants[0].type === 'TELEPHONY' ? item.rtc.rtcParticipants[0] : item.rtc.rtcParticipants[1]);
                if (_lastTwoCallPartners.length > 2) {
                    _lastTwoCallPartners.pop();
                }

            }
        });

        PubSubSvc.subscribe('/call/ended', function (call) {
            if (call && call.isTelephonyCall && !call.isRemote) {
                _lastEndedCall = call;
                $timeout.cancel(_endedCallWaitTimer);
                _endedCallWaitTimer = $timeout(function () {
                    _lastEndedCall = null;
                    _endedCallWaitTimer = null;
                }, MAX_ENDED_CALL_WAIT_TIME);
                if (call.isOsBizSecondCall || !(call.isHandoverInProgress || _atcRemoteCalls[call.cstaCallId])) {
                    createJournalEntry(call);
                }
            }
            if (!call.pickupNotification && !call.isRemote) {
                showPickupNotification();
            }
            refreshData();
        });

        PubSubSvc.subscribe('/routing/alternativeNumber/changed', function () {
            LogSvc.debug('[CstaSvc]: Received /routing/alternativeNumber/changed event');
            if ($rootScope.localUser.isOsBizCTIEnabled) {
                _that.setRoutingOptionReroutingNumber($rootScope.localUser.reroutingPhoneNumber);
                // For OSBiz, changing routing option may affect the calling device list
                LogSvc.debug('[TelephonySettingsSvc]: Publish /csta/deviceChange event');
                PubSubSvc.publish('/csta/deviceChange');
            }
            syncRoutingOption();
        });

        PubSubSvc.subscribe('/localUser/update', function () {
            if ($rootScope.localUser.isATC) {
                LogSvc.debug('[CstaSvc]: Received /localUser/update event');
                checkBusyState();
                syncRoutingOption();
            }
        });

        PubSubSvc.subscribe('/user/snooze/start', function () {
            if (isDNDSupported() && _primaryClient) {
                LogSvc.info('[CstaSvc]: Received /user/snooze/start event');
                _that.setDoNotDisturb(true);
            }
        });

        PubSubSvc.subscribe('/user/autoSnooze/start', function () {
            if (isDNDSupported() && _primaryClient) {
                LogSvc.info('[CstaSvc]: Received /user/autoSnooze/start event');
                _that.setDoNotDisturb(true);
            }
        });

        PubSubSvc.subscribe('/user/snooze/end', function () {
            if (isDNDSupported() && _primaryClient) {
                LogSvc.info('[CstaSvc]: Received /user/snooze/end event');
                _that.setDoNotDisturb(false);
            }
        });

        PubSubSvc.subscribe('/user/snooze/resume', function () {
            if (isDNDSupported() && _primaryClient) {
                LogSvc.info('[CstaSvc]: Received /user/snooze/resume event.');
                _that.setDoNotDisturb(false);
            }
        });

        PubSubSvc.subscribe('/language/update', function () {
            _numberNotAvailable = $rootScope.i18n.map.res_NumberNotAvailable;
        });

        PubSubSvc.subscribe('/feature/state/changed', function (featureName, value) {
            if (featureName === Constants.LabFeatureName.CALL_FORWARD_NO_REPLY) {
                LogSvc.debug('[CstaSvc]: Call Forward No Reply has changed status');
                if (value) {
                    _that.getForwarding(handleGetForwardingResp);
                } else {
                    $rootScope.localUser.cfData.NoAnswer.cfwAvailable = false;
                    LogSvc.debug('[CstaSvc]: Publish /csta/forwardingEvent');
                    PubSubSvc.publish('/csta/forwardingEvent');
                }
            }
        });

        ///////////////////////////////////////////////////////////////////////////
        // Public interfaces
        ///////////////////////////////////////////////////////////////////////////
        this.alternate = function (heldCall, activeCall, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }
            if (!heldCall || !activeCall) {
                cb && cb('Invalid calls to alternate');
                return;
            }
            heldCall.setRetrieveInProgress();
            activeCall.setHoldInProgress();

            var data = {
                request: 'AlternateCall',
                activeCall: getCstaConnectionForRequest(activeCall.atcCallInfo),
                heldCall: getCstaConnectionForRequest(heldCall.atcCallInfo)
            };
            sendCstaRequest(data, function (err) {
                if (err) {
                    // If for some reason the Alternate request failed, reset the inProgress indication
                    heldCall.clearRetrieveInProgress();
                    activeCall.clearHoldInProgress();
                    cb && cb('res_RetrieveCallFailed');
                } else {
                    if ($rootScope.localUser.isOsBizCTIEnabled) {
                        _telephonyConversation.call = heldCall;
                        LogSvc.debug('[CstaSvc]: Publish /conversation/update event. convId = ', _telephonyConversation.convId);
                        PubSubSvc.publish('/conversation/update', [_telephonyConversation]);
                    }
                    cb && cb(null);
                }
            });

        };

        this.conference = function (heldCall, activeCall, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }
            if (!heldCall || !activeCall) {
                cb && cb('Invalid calls to conference');
                return;
            }

            var data = {
                request: 'ConferenceCall',
                activeCall: getCstaConnectionForRequest(activeCall.atcCallInfo),
                heldCall: getCstaConnectionForRequest(heldCall.atcCallInfo)
            };
            sendCstaRequest(data, function (err, rs) {
                if (err || !rs) {
                    cb && cb('res_MergeCallFailed');
                } else {
                    cb && cb(null);
                }
            });
        };

        this.answer = function (callId, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            var call = findAtcRemoteCall(callId);
            if (!call) {
                // Check if there is an alerting call in advancing state
                if (_alertingCall && _alertingCall.atcAdvancing) {
                    call = _alertingCall;
                    var connection = getCstaConnectionForRequest(call.atcCallInfo);
                    connection.dID = _osmoData.onsFQN;
                    call.atcCallInfo.setCstaConnection(connection);
                } else {
                    cb && cb('There is no call to be answered');
                    return;
                }
            }
            var position = call.atcAdvancing ? Targets.Desk : call.getPosition();
            // Check if there is also an active call on the same device
            var activeCall = Object.values(_atcRemoteCalls).find(function (remoteCall) {
                return remoteCall.callId !== callId && remoteCall.isAtPosition(position);
            });

            // There is an active call on the same device. Send Alternate Call request instead of Answer Call
            if (activeCall && !$rootScope.localUser.isOsBizCTIEnabled) {
                _that.alternate(call, activeCall, cb);
                return;
            }

            var data = {
                request: 'AnswerCall',
                callToBeAnswered: getCstaConnectionForRequest(call.atcCallInfo)
            };
            sendCstaRequest(data, cb);
        };

        this.answerCstaCall = function (callId, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }
            var data = {
                request: 'AnswerCall',
                callToBeAnswered: { cID: callId, dID: _osmoData.onsFQN }
            };
            sendCstaRequest(data, cb);
        };

        this.pickupCall = function (callId, device, cb) {
            cb = cb || function () {};
            var call = findAtcRemoteCall(callId);
            if (!call || !call.pickupNotification) {
                cb('There is no call to be picked up');
                return;
            }
            if (!getRegistrationData(cb)) {
                return;
            }
            // PBX Group Pickup Notification
            device = device || Targets.WebRTC;
            var data = {
                request: 'GroupPickupCall',
                newDestination: buildNewDestination(device)
            };
            sendCstaRequest(data, function (err) {
                if (err) {
                    cb('res_PickUpCallFailed');
                    removeAtcRemoteCall(callId, true);
                    return;
                }
                cb();
            });
            LogSvc.debug('[CstaSvc]: Publish /atccall/pickUpInProgress');
            PubSubSvc.publish('/atccall/pickUpInProgress', [call]);
            call.setAtcHandoverInProgress();
        };

        this.clearTransferredCall = function (callID) {
            if (!_transferredCalls[callID]) {
                return;
            }
            $timeout.cancel(_transferredCalls[callID].timeout);
            delete _transferredCalls[callID];
        };

        this.consultationTransfer = function (activeCall, heldCall, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            var data = {
                request: 'TransferCall',
                heldCall: getCstaConnectionForRequest(heldCall.atcCallInfo),
                activeCall: getCstaConnectionForRequest(activeCall.atcCallInfo)
            };
            sendCstaRequest(data, function (err, rs) {
                if (err || !rs) {
                    cb && cb(TransferCallFailedCauses.Unreachable.ui);
                } else {
                    cb && cb(null);
                }
            });
        };

        this.deflect = function (callId, target, destination, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            var call = findWebRTCCall(callId) || CircuitCallControlSvc.getIncomingCall() || findAtcRemoteCall(callId);

            if (!call) {
                cb && cb('There is no call to be deflected');
                return;
            }

            if (!isTargetAllowed(call, target)) {
                cb && cb('The selected target is not available for this call.');
                return;
            }

            // If the call is advancing, it means that it's ringing on the Desk. Send Answer Call instead of Deflect Call
            if (call.atcAdvancing && target === Targets.Desk) {
                _that.answer(callId, cb);
                return;
            }

            var newDestination = buildNewDestination(target, destination);
            if (!newDestination) {
                cb && cb('Failed to create new destination.');
                return;
            }

            var data = {
                request: 'DeflectCall',
                callToBeDiverted: getCstaConnectionForRequest(call.atcCallInfo),
                newDestination: newDestination,
                autoAnswer: target === Targets.Desk || target === Targets.WebRTC
            };

            if ($rootScope.localUser.isOsBizCTIEnabled && target === Targets.Cell && isSelectedRouting(RoutingOptions.CircuitClient)) {
                data.callToBeDiverted.dID = _osmoData.osmoFQN;
            }

            sendCstaRequest(data, function (err) {
                if (err) {
                    cb && cb('res_MoveCallFailed');
                    return;
                }
                // OSBiz: deflect to alternative number has no binding.
                if (target !== Targets.VM && (!$rootScope.localUser.isOsBizCTIEnabled || target !== Targets.Cell)) {
                    call.setAtcHandoverInProgress();
                }
            });
        };

        this.generateDigits = function (call, digits, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GenerateDigits',
                    connectionToSendDigits: getCstaConnectionForRequest(call.atcCallInfo),
                    charactersToSend: digits
                };
                sendCstaRequest(data, function (err) {
                    cb && cb(err);
                    if ($rootScope.localUser.isOsBizCTIEnabled && !err) {
                        // OSBIZ does not support digitsGeneratedEvent. Use GDR instead.
                        LogSvc.info('[CstaSvc]: Number of DTMF digits played: ', digits && digits.length);
                        PubSubSvc.publish('/call/singleDigitSent', [call, digits]);
                    }
                }, ['GD.cTS']);
            }
        };

        this.getDoNotDisturb = function (cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GetDoNotDisturb',
                    device: getCstaDeviceId()
                };
                sendCstaRequest(data, cb);
            }
        };

        this.getForwarding = function (cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GetForwarding',
                    device: getCstaDeviceId()
                };
                sendCstaRequest(data, cb);
            }
        };

        this.getMessageWaiting = function (cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GetMessageWaitingIndicator',
                    device: getCstaDeviceId()
                };
                sendCstaRequest(data, cb);
            }
        };

        this.getTransferredCall = function (callID) {
            return _transferredCalls[callID];
        };

        this.pullRemoteCall = function (callId, cb) {
            var call = findAtcRemoteCall(callId);
            if (call) {
                if (call.pickupNotification) {
                    _that.pickupCall(callId, null, cb);
                } else {
                    handover(call, Targets[Targets.WebRTC.name], null, cb);
                }
            } else {
                LogSvc.warn('[CstaSvc]: pullRemoteCall invoked without a valid call');
                cb('No active remote call');
            }
        };

        this.pushLocalCall = function (callId, target, cb) {
            refreshData();
            var call;
            if (_activeCall && _activeCall.callId === callId) {
                call = _activeCall;
            } else if (_heldCall && _heldCall.callId === callId) {
                call = _heldCall;
            }
            if (call) {
                handover(call, Targets[target], null, cb);
            } else {
                LogSvc.warn('[CstaSvc]: pushLocalCall invoked without a valid call');
                cb('No active local call');
            }
        };

        this.endRemoteCall = function (callId, cb) {
            var call = findAtcRemoteCall(callId);
            if (call) {
                _that.hangupRemote(call, cb);
            } else {
                LogSvc.warn('[CstaSvc]: endRemoteCall invoked without a valid call');
                cb && cb('No active remote call');
            }
        };

        this.endCallOnRemoteDevices = function (cId) {
            if (getRegistrationData() && _osmoData.osmoDeviceList && cId) {
                var data = {
                    request: 'ClearConnection',
                    connectionToBeCleared: { cID: cId, dID: _osmoData.onsFQN}
                };
                sendCstaRequest(data);
            }
        };


        this.endCstaCall = function (cId) {
            if (getRegistrationData() && cId) {
                var data = {
                    request: 'ClearConnection',
                    connectionToBeCleared: { cID: cId, dID: _osmoData.onsFQN}
                };
                sendCstaRequest(data);
            }
        };

        this.hangupRemote = function (call, cb) {
            if (call.isRemote && getRegistrationData(cb)) {
                if (!getCstaConnectionForRequest(call.atcCallInfo).cID) {
                    LogSvc.warn('[CstaSvc]: hangupRemote invoked without a valid call');
                    cb && cb('No active remote call');
                    return;
                }
                var data = {
                    request: 'ClearConnection',
                    connectionToBeCleared: getCstaConnectionForRequest(call.atcCallInfo)
                };
                sendCstaRequest(data, function (err) {
                    if (err) {
                        cb && cb(err);
                    } else {
                        if (!call.getCstaState().established && !call.atcCallInfo.getMissedReason()) {
                            if (call.direction === CallDirection.INCOMING) {
                                call.atcCallInfo.setMissedReason(MissedReasonTypes.DECLINED);
                            } else {
                                call.atcCallInfo.setMissedReason(MissedReasonTypes.CANCELLED);
                            }
                            removeAtcRemoteCall(call.atcCallInfo.getCstaCallId());
                        }
                        cb && cb();
                    }
                });
            }
        };

        this.hold = function (call, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'HoldCall',
                    callToBeHeld: getCstaConnectionForRequest(call.atcCallInfo)
                };

                if ($rootScope.localUser.isOsBizCTIEnabled && call.isRemote &&
                    _osmoData.supportedFeatures && _osmoData.supportedFeatures.includes('zhOs')) {
                    data.holdOptions = 'exclusiveHold';
                }

                call.setHoldInProgress();
                sendCstaRequest(data, function (err) {
                    if (err) {
                        // If for some reason the Hold request failed, the holdInProgress indication needs to be reset
                        call.clearHoldInProgress();
                        cb && cb('res_HoldCallFailed');
                    } else {
                        cb && cb(null);
                    }
                });
            }
        };

        this.holdWithConsultationCall = function (call, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'ConsultationCall',
                    existingCall: getCstaConnectionForRequest(call.atcCallInfo)
                };
                call.setHoldInProgress();
                sendCstaRequest(data, function (err, rs) {
                    if (err) {
                        // If for some reason the ConsultationCall request failed, the holdInProgress indication needs to be reset
                        call.clearHoldInProgress();
                        cb && cb('res_HoldCallFailed');
                    } else {
                        rs && rs.iC && rs.iC.cID && call.atcCallInfo.setCstaReconnectId({cID: rs.iC.cID, dID: _osmoData.onsFQN});
                        cb && cb(null);
                    }
                });
            }
        };

        this.reconnect = function (activeCall, heldCall, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            var data = {
                request: 'ReconnectCall',
                heldCall: getCstaConnectionForRequest(heldCall.atcCallInfo),
                activeCall: getCstaConnectionForRequest(activeCall.atcCallInfo)
            };
            sendCstaRequest(data, cb);
        };

        this.retrieve = function (call, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'RetrieveCall',
                    callToBeRetrieved: getCstaConnectionForRequest(call.atcCallInfo)
                };
                call.setRetrieveInProgress();
                sendCstaRequest(data, function (err) {
                    if (err) {
                        // If for some reason the Retrieve request failed, the RetrieveInProgress indication needs to be reset
                        call.clearRetrieveInProgress();
                        cb && cb('res_RetrieveCallFailed');
                    } else {
                        cb && cb(null);
                    }
                });
            }
        };

        this.retrieveWithReconnectCall = function (call, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'ReconnectCall',
                    heldCall: getCstaConnectionForRequest(call.atcCallInfo),
                    activeCall: call.atcCallInfo.getCstaReconnectId()
                };
                call.setRetrieveInProgress();
                sendCstaRequest(data, function (err) {
                    if (err) {
                        // If for some reason the Reconnect request failed, the RetrieveInProgress indication needs to be reset
                        call.clearRetrieveInProgress();
                        call.atcCallInfo.setCstaReconnectId({});
                        cb && cb('res_RetrieveCallFailed');
                    } else {
                        cb && cb(null);
                    }
                });
            }
        };

        this.setDoNotDisturb = function (doNotDisturbOn, cb) {
            if (getRegistrationData(cb)) {
                LogSvc.debug('[CstaSvc]: Set DND to ', doNotDisturbOn);
                var data = {
                    request: 'SetDoNotDisturb',
                    device: getCstaDeviceId(),
                    doNotDisturbOn: doNotDisturbOn
                };
                sendCstaRequest(data, cb);
            }
        };

        this.setForwarding = function (data, cb) {
            if (getRegistrationData(cb)) {
                var msg = {
                    request: 'SetForwarding',
                    device: data.device || getCstaDeviceId(),
                    activateForward: data.activateForward,
                    forwardDN: data.forwardDN || '',
                    forwardingType: data.forwardingType,
                    ringCount: data.ringCount
                };

                checkSetForwardingLoop(function () {
                    sendCstaRequest(msg, cb);
                });
            }
        };

        this.snapshotCall = function (conn, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'SnapshotCall',
                    snapshotObject: conn
                };
                sendCstaRequest(data, cb);
            }
        };

        this.snapshotDevice = function (cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'SnapshotDevice',
                    snapshotObject: _osmoData.onsFQN,
                    osmo: _osmoData.osmo
                };
                sendCstaRequest(data, cb);
            }
        };

        this.getLogicalDeviceInformation = function (cb) {
            cb = cb || function () {};
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GetLogicalDeviceInformation',
                    device: getCstaDeviceId()
                };
                sendCstaRequest(data, function (err, rs) {
                    if (!err && rs && !rs.hasPhysicalDeviceInformation && !_onsUnregistered) {
                        _onsUnregistered = true;
                        publishCstaDeviceChanged();
                    }
                    cb(err, rs);
                });
            }
        };

        this.transfer = function (call, destination, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            var data = {};

            if (call.checkCstaState([CstaCallState.Ringing, CstaCallState.Parked])) {
                // Need to send a CSTA Deflect Call request instead of CSTA SST request
                data.request = 'DeflectCall';
                data.callToBeDiverted = getCstaConnectionForRequest(call.atcCallInfo);
                data.newDestination = destination;

                sendCstaRequest(data, cb);
            } else {
                data.request = 'SingleStepTransferCall';
                data.activeCall = getCstaConnectionForRequest(call.atcCallInfo);
                data.transferredTo = destination;

                sendCstaRequest(data, function (err, rs) {
                    if (err || !rs) {
                        if (err && err.indexOf('internalResourceBusy') !== -1) {
                            // If the renegotiation, before the transfer, takes too long, OSV sends CSTA Error with
                            // internalResourceBusy, but the transfer may succeed afterwards. So we need to continue
                            // tracking this call
                            storeTransferredCall(data.activeCall.cID, {transferredCall: {cID: DEFAULT_TRANSFER_CALLID}});
                            cb && cb(null);
                            return;
                        }
                        cb && cb(TransferCallFailedCauses.Unreachable.ui);
                    } else {
                        storeTransferredCall(data.activeCall.cID, rs);
                        cb && cb(null);
                    }
                });
            }
        };

        this.consultationCall = function (call, destination, cb) {
            if (!getRegistrationData(cb) || !destination) {
                return;
            }
            var data = {
                request: 'ConsultationCall',
                consultedDevice: destination.dialedDn,
                existingCall: getCstaConnectionForRequest(call.atcCallInfo)
            };

            createEarlyAtcRemoteCall(destination, call.getPosition());
            var newCall = _atcRemoteCalls[destination.dialedDn];
            sendCstaRequest(data, function (err, rs) {
                if (err || !rs) {
                    cb && cb('res_MakeCallFailed');
                    if (newCall) {
                        if (err.endsWith('invalidCalledDeviceID')) {
                            newCall.atcCallInfo.setMissedReason(MissedReasonTypes.REORDER_TONE);
                        } else if (err.endsWith('requestIncompatibleWithCallingDevice') || err.endsWith('invalidDeviceID')) {
                            removeAtcRemoteCall(destination.dialedDn, true);
                            return;
                        } else {
                            newCall.atcCallInfo.setMissedReason(MissedReasonTypes.DEST_OUT_OF_ORDER);
                        }
                        newCall.atcCallInfo.setIgnoreCall(false);
                        removeAtcRemoteCall(destination.dialedDn);
                    }
                } else {
                    if (!call.isRemote && $rootScope.localUser.isOsBizCTIEnabled) {
                        call.isOsBizFirstCall = true;
                    } else {
                        // Update the new call with the consultationCall response in order to show it in the UI
                        updateEarlyAtcRemoteCall(rs.iC, destination, call.getPosition());
                    }
                    cb && cb();
                }
            });
        };

        this.makeCall = function (target, destination, cb) {
            if (!getRegistrationData(cb) || !destination) {
                return;
            }
            var data = {
                request: 'MakeCall',
                calledDirectoryNumber: destination.dialedDn,
                callingDevice: buildNewDestination(target),
                autoOriginate: target === Targets.Desk || target === Targets.WebRTC ? 'doNotPrompt' : 'prompt'
            };

            createEarlyAtcRemoteCall(destination, target);
            var call = _atcRemoteCalls[destination.dialedDn];

            sendCstaRequest(data, function (err, rs) {
                if (err || !rs) {
                    cb && cb('res_MakeCallFailed');
                    if (err.endsWith('invalidCalledDeviceID')) {
                        call.atcCallInfo.setMissedReason(MissedReasonTypes.REORDER_TONE);
                    } else if (err.endsWith('requestIncompatibleWithCallingDevice') || err.endsWith('invalidDeviceID')) {
                        removeAtcRemoteCall(destination.dialedDn, true);
                        return;
                    } else {
                        call.atcCallInfo.setMissedReason(MissedReasonTypes.DEST_OUT_OF_ORDER);
                    }
                    call.atcCallInfo.setIgnoreCall(false);
                    removeAtcRemoteCall(destination.dialedDn);
                } else {
                    // Update the new call with the makeCall response in order to show it in the UI
                    updateEarlyAtcRemoteCall(rs.caD, destination, target);
                    cb && cb();
                }
            });
        };

        this.ignoreCall = function (call, cb) {
            removeAtcRemoteCall(call.callId);
            cb && cb();
        };

        this.callBackCallRelated = function (call, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }
            var data = {
                request: 'CallBackCallRelated',
                callBackConnection: call.atcCallInfo.getCstaConnection()
            };
            sendCstaRequest(data, cb);
        };

        this.callBackNonCallRelated = function (target, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }
            var data = {
                request: 'CallBackNonCallRelated',
                orD: _osmoData.onsFQN,
                tD: target
            };
            sendCstaRequest(data, cb);
        };

        this.cancelCallBack = function (target, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }
            var data = {
                request: 'CancelCallBack',
                orD: _osmoData.onsFQN,
                td: target || undefined
            };
            sendCstaRequest(data, cb);
        };

        this.setMicrophoneMute = function (mute, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'SetMicrophoneMute',
                    device: getCstaDeviceId(),
                    mute: mute
                };
                sendCstaRequest(data, cb);
            }
        };

        this.getMicrophoneMute = function (cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GetMicrophoneMute',
                    device: getCstaDeviceId()
                };
                sendCstaRequest(data, cb);
            }
        };

        this.callLogSnapShot = function (cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'CallLogSnapShot',
                    device: getCstaDeviceId()
                };
                sendCstaRequest(data, cb);
            }
        };

        this.snapshot = function () {
            snapshotRemoteCalls();
        };

        this.getAgentState = function (device, cb) {
            if (getRegistrationData(cb)) {
                var data = {
                    request: 'GetAgentState',
                    device: device || getCstaDeviceId()
                };

                if ($rootScope.localUser.isOsBizCTIEnabled) {
                    data.group = getCstaDeviceId();
                }
                sendCstaRequest(data, cb);
            }
        };

        this.getGlobalAgentState = function () {
            return !!($rootScope.localUser && $rootScope.localUser.agentStateReady);
        };

        this.isSetAgentStatePerGroupSupported = function () {
            return !!($rootScope.localUser && $rootScope.localUser.isOSV);
        };

        this.setAgentState = function (ready, group, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            var agentStateReady = _that.getGlobalAgentState();
            if (group) {
                var agentState = _huntGroupList && _huntGroupList.find(function (huntgroup) {
                    return huntgroup.pilotDn === group;
                });
                agentStateReady = !!(agentState && agentState.available);
            }

            ready = !!ready;
            if (ready !== agentStateReady) {
                var data = {
                    request: 'SetAgentState',
                    device: getCstaDeviceId(),
                    state: ready ? AgentState.Ready : AgentState.NotReady,
                    localUser: $rootScope.localUser,
                    group: group
                };
                sendCstaRequest(data, cb);
            }
        };

        this.setAgentStateOSBiz = function (ready, routing, cb) {
            if (!getRegistrationData(cb)) {
                return;
            }

            ready = !!ready;
            var device;
            if (routing === RoutingOptions.CircuitClient.name) {
                device = _osmoData.osmoFQN;
            } else if (routing === RoutingOptions.DeskPhone.name && _osmoData.osmoDeviceList && _osmoData.osmoDeviceList.length > 0) {
                device = _osmoData.osmoDeviceList[0];
            } else {
                device = getCstaDeviceId();
            }
            var data = {
                request: 'SetAgentState',
                device: device,
                state: ready ? AgentState.Ready : AgentState.NotReady,
                group: getCstaDeviceId(),
                localUser: $rootScope.localUser
            };

            sendCstaRequest(data, cb);
        };

        this.handleCallEvent = function (eventData) {
            if (!eventData) {
                return;
            }
            refreshData();

            if (!_snapshotPerformed) {
                LogSvc.debug('[CstaSvc]: Ignore any call event until snapshot is performed');
                return;
            }

            try {
                switch (eventData.name) {
                case 'CallInformationEvent':
                    handleCallInformationEvent(eventData);
                    break;
                case 'ConferencedEvent':
                    handleConferencedEvent(eventData);
                    break;
                case 'ConnectionClearedEvent':
                    handleConnectionClearedEvent(eventData);
                    break;
                case 'DeliveredEvent':
                    handleDeliveredEvent(eventData);
                    break;
                case 'DigitsGeneratedEvent':
                    handleDigitsGeneratedEvent(eventData);
                    break;
                case 'DivertedEvent':
                    handleDivertedEvent(eventData);
                    break;
                case 'EstablishedEvent':
                    handleEstablishedEvent(eventData);
                    break;
                case 'FailedEvent':
                    handleFailedEvent(eventData);
                    break;
                case 'HeldEvent':
                    handleHeldEvent(eventData);
                    break;
                case 'OfferedEvent':
                    handleOfferedEvent(eventData);
                    break;
                case 'QueuedEvent':
                    handleQueuedEvent(eventData);
                    break;
                case 'RetrievedEvent':
                    handleRetrievedEvent(eventData);
                    break;
                case 'ServiceCompletionFailureEvent':
                    handleServiceCompletionFailureEvent(eventData);
                    break;
                case 'ServiceInitiatedEvent':
                    handleServiceInitiatedEvent(eventData);
                    break;
                case 'TransferredEvent':
                    handleTransferredEvent(eventData);
                    break;
                default:
                    LogSvc.debug('[CstaSvc]: Ignore CSTA Call Event (' + eventData.name + ')');
                    break;
                }
            } catch (error) {
                LogSvc.error(error);
            }
        };

        this.handleDeviceEvent = function (eventData) {
            if (!eventData) {
                return;
            }

            try {
                switch (eventData.name) {
                case 'BackInServiceEvent':
                    handleBackInServiceEvent();
                    break;
                case 'OutOfServiceEvent':
                    handleOutOfServiceEvent();
                    break;
                default:
                    LogSvc.debug('[CstaSvc]: Ignore CSTA Device Event (' + eventData.name + ')');
                    break;
                }
            } catch (error) {
                LogSvc.error(error);
            }
        };

        this.handlePhysicalDeviceEvent = function (eventData) {
            if (!eventData) {
                return;
            }

            var hasVoicemail = eventData.messageWaitingOn;

            $rootScope.localUser.msgWaitingIndicator = hasVoicemail;
            LogSvc.debug('[CstaSvc]: Publish /csta/msgWaitingIndicatorUpdated');
            PubSubSvc.publish('/csta/msgWaitingIndicatorUpdated', [hasVoicemail]);
        };

        this.handleLogicalDeviceEvent = function (eventData) {
            if (!eventData) {
                return;
            }

            try {
                switch (eventData.name) {
                case 'ForwardingEvent':
                    handleForwardingEvent(eventData);
                    break;
                case 'DoNotDisturbEvent':
                    handleDoNotDisturbEvent(eventData);
                    break;
                case 'AgentReadyEvent':
                case 'AgentNotReadyEvent':
                case 'AgentBusyEvent':
                case 'AgentLoggedOffEvent':
                case 'AgentLoggedOnEvent':
                case 'AgentWorkingAfterCallEvent':
                    handleAgentEvent(eventData);
                    break;
                default:
                    LogSvc.debug('[CstaSvc]: Ignore CSTA Logical Device Event (' + eventData.name + ')');
                    break;
                }
            } catch (error) {
                LogSvc.error(error);
            }
        };


        this.isPrimaryClient = function () {
            return _primaryClient;
        };

        this.getDisplayInfo = getDisplayInfo;

        this.getCallDevices = getCallDevices;

        this.getPushDevices = getPushDevices;

        this.getAnswerDevices = getAnswerDevices;

        this.updateStaticOnd = function (status, cb) {
            if (getRegistrationData(cb)) {
                status = !!status;
                LogSvc.debug('[CstaSvc]: Request to update static OND status to ' + status + ' . _savedStaticOnd = ' + _savedStaticOnd);
                var data = {
                    staticOndActive: status
                };

                if (status) {
                    data.staticOndDN = Utils.cleanPhoneNumber($rootScope.localUser.reroutingPhoneNumber);
                    if (!data.staticOndDN) {
                        cb && cb('Cannot activate static OND without the static OND DN');
                        return;
                    }
                }

                var hasSavedStaticOnd = !!_savedStaticOnd;
                if (status !== hasSavedStaticOnd || (status && data.staticOndDN !== _savedStaticOnd)) {
                    var msg = {
                        request: 'SetForwarding',
                        device: getCstaDeviceId(),
                        staticOndActive: data.staticOndActive,
                        staticOndDN: data.staticOndDN
                    };

                    sendCstaRequest(msg, function (err) {
                        if (err) {
                            cb && cb(err);
                        } else {
                            _savedStaticOnd = data.staticOndDN || '';
                            cb && cb(null);
                        }
                    });
                } else {
                    cb && cb(null);
                }
            }
        };

        this.updateRoutingOption = function (selectedOption, oldOption, cb) {
            $rootScope.localUser.isOsBizCTIEnabled ? updateRoutingOptionOSBiz(selectedOption, oldOption, cb) : updateRoutingOptionATC(selectedOption, oldOption, cb);
        };

        this.setForwardingType = function (cfType, status, number, cb) {
            number = number || $rootScope.localUser.cfData[cfType].cfwNumber;
            if (cfType === CallForwardingTypes.NoAnswer.name && $rootScope.localUser.isOsBizCTIEnabled && number) {
                number = 'N<' + Utils.cleanPhoneNumber(number) + '>PreferredDevice';
            } else {
                number = Utils.cleanPhoneNumber(number);
            }
            var data = {
                activateForward: number ? !!status : false,
                forwardDN: number,
                forwardingType: convertToCstaFWType(cfType)
            };
            _that.setForwarding(data, cb);
        };

        this.setForwardingImmediate = function (status, number, cb) {
            number = number || $rootScope.localUser.cfData.Immediate.cfwNumber;
            var data = {
                activateForward: !!status,
                forwardDN: Utils.cleanPhoneNumber(number),
                forwardingType: 'forwardImmediate'
            };
            _that.setForwarding(data, cb);
        };

        this.setForwardingToVM = function (status, cb) {
            if (_that.getForwardingVMStatus() !== status) {
                var data = {
                    activateForward: !!status,
                    forwardDN: Utils.cleanPhoneNumber(_osmoData.vm),
                    forwardingType: 'forwardImmediate'
                };
                _that.setForwarding(data, cb);
            } else {
                cb && cb();
            }
        };

        this.setForwardingNoAnswer = function (number, status, cb) {
            if (status && !number) {
                cb && cb('Failed to set call forward no answer');
            } else {
                // OSBiz: Set alternative number
                if ($rootScope.localUser.isOsBizCTIEnabled && number) {
                    number = 'N<' + Utils.cleanPhoneNumber(number) + '>PreferredDevice';
                } else {
                    number = Utils.cleanPhoneNumber(number);
                }

                var data = {
                    activateForward: !!status,
                    forwardDN: number,
                    forwardingType: 'forwardNoAns'
                };
                _that.setForwarding(data, cb);
            }
        };

        // OSBiz: Set routing option = alternative number
        this.setRoutingOptionReroutingNumber = function (number, cb) {
            if (getRegistrationData(cb)) {
                number = number || '';
                var status = !!number;
                number = status ? 'N<' + Utils.cleanPhoneNumber(number) + '>PreferredDevice' : '';
                var device = _osmoData.onsFQN;
                if (_osmoData.osmoDeviceList && _osmoData.osmoDeviceList.length > 1) {
                    device = 'N<' + _osmoData.onsFQN + '/' + _osmoData.osmoDeviceList[1] + '>';
                }

                var data = {
                    device: device,
                    activateForward: status,
                    forwardDN: number,
                    forwardingType: 'preferredDevice'
                };
                _that.setForwarding(data, cb);
            }
        };

        this.getForwardingData = function () {
            return _osmoData.forwardList;
        };

        this.getForwardingImmediate = function () {
            var forwardImmediate = _osmoData.forwardList.find(function (element) {
                return isForwardingImmediate(element.forwardingType) && !isVMNumber(element.forwardDN);
            });

            return {
                status: !!(forwardImmediate && forwardImmediate.forwardStatus),
                forwardDN: forwardImmediate && forwardImmediate.forwardDN
            };
        };

        this.disableCallForwardingImmediate = function (cb) {
            if (_that.getForwardingImmediate().status || ($rootScope.localUser.isOsBizCTIEnabled && _that.getForwardingVMStatus())) {
                _that.setForwardingImmediate(false, null, function () {
                    cb && cb();
                });
            } else {
                cb && cb();
            }
        };

        this.getForwardingVMStatus = function () {
            return _osmoData.forwardList.some(function (element) {
                return isForwardingImmediate(element.forwardingType) && isVMNumber(element.forwardDN) && element.forwardStatus;
            });
        };

        this.getForwardingPreferredDevice = function (device, cb) {
            var data = {
                request: 'GetForwarding',
                device: device
            };
            sendCstaRequest(data, cb);
        };

        this.getForwardingNoAnswer = function () {
            var forwardNoAnswer = _osmoData.forwardList.find(function (element) {
                return isForwardingNoAnswer(element.forwardingType);
            });

            return {
                status: !!(forwardNoAnswer && forwardNoAnswer.forwardStatus),
                forwardDN: forwardNoAnswer && forwardNoAnswer.forwardDN
            };
        };

        this.disableCallForwardingNoAnswer = function (cb) {
            if (_that.getForwardingNoAnswer().status) {
                _that.setForwardingNoAnswer(null, false, function () {
                    cb && cb();
                });
            } else {
                cb && cb();
            }
        };

        this.setVoicemail = function (status, duration, cb) {
            if (getRegistrationData(cb)) {
                duration = duration || _savedVMRingDuration;
                if ($rootScope.localUser.pbxCallForwardToVoiceMailSupported && $rootScope.localUser.voicemailPBXEnabled) {
                    LogSvc.debug('[CstaSvc]: Set voice mail duration to: ', duration);
                    if (status !== $rootScope.localUser.voicemailActive || (status && _savedVMRingDuration !== duration)) {
                        // If the status is not true, then the duration cannot be modified
                        var msg = {
                            request: 'SetForwarding',
                            device: getCstaDeviceId(),
                            voicemailActive: status,
                            voicemailRingDuration: status ? duration : undefined
                        };
                        checkSetForwardingLoop(function () {
                            sendCstaRequest(msg, function (err) {
                                if (err) {
                                    cb && cb(err);
                                } else {
                                    _savedVMRingDuration = duration;
                                    cb && cb(null);
                                }
                            });
                        });
                    } else {
                        cb && cb(null);
                    }
                } else {
                    cb && cb('Cannot configure VM on PBX');
                }
            }
        };

        this.disableAllCallForwarding = function () {
            _that.disableCallForwardingImmediate(function () {
                _that.disableCallForwardingNoAnswer();
            });
        };

        this.setRoutingTimers = function (timers, cb) {
            if (getRegistrationData(cb)) {
                if ($rootScope.localUser.ringDurationEnabled) {
                    LogSvc.debug('[CstaSvc]: Set routing timers to: ', timers);
                    if (_savedMainRingDuration !== timers.mainRingDuration || _savedCellRingDuration !== timers.cellRingDuration ||
                        _savedClientRingDuration !== timers.clientRingDuration || !$rootScope.localUser.routeToCell) {
                        var msg = {
                            request: 'SetForwarding',
                            device: getCstaDeviceId(),
                            mainRingDuration: timers.mainRingDuration || undefined,
                            clientRingDuration: timers.clientRingDuration || undefined,
                            cellRingDuration: timers.cellRingDuration || undefined,
                            routeToCell: true
                        };
                        checkSetForwardingLoop(function () {
                            sendCstaRequest(msg, function (err) {
                                if (err) {
                                    cb && cb(err);
                                } else {
                                    _savedMainRingDuration = timers.mainRingDuration || _savedMainRingDuration;
                                    _savedClientRingDuration = timers.clientRingDuration || _savedClientRingDuration;
                                    _savedCellRingDuration = timers.cellRingDuration || _savedCellRingDuration;
                                    cb && cb(null);
                                }
                            });
                        });
                    } else {
                        cb && cb(null);
                    }
                } else {
                    LogSvc.debug('[CstaSvc]: Cannot set routing timers');
                    cb && cb('Cannot configure routing timers on PBX');
                }
            }
        };

        this.startCall = function (existingCallOnTarget, target, destination, cb) {
            if (getRegistrationData(cb)) {
                if (existingCallOnTarget) {
                    // For OSBiz, if the first remote call is already on hold, special treatment is necessary
                    if ($rootScope.localUser.isOsBizCTIEnabled && existingCallOnTarget.isHolding() && existingCallOnTarget.isRemote) {
                        if (_osmoData.supportedFeatures && _osmoData.supportedFeatures.includes('zhOs')) {
                            LogSvc.debug('[CstaSvc]: OSBiz supports exlussive hold for remote call. Send MakeCall');
                            _that.makeCall(target, destination, cb);
                        } else {
                            // OSBiz is not able to process consultationCall if the call is already on hold.
                            // This should be solved with OSBiz V2R7.
                            _that.retrieve(existingCallOnTarget, function (err) {
                                if (err) {
                                    cb && cb('res_MakeCallFailed');
                                } else {
                                    _that.consultationCall(existingCallOnTarget, destination, cb);
                                }
                            });
                        }
                    } else {
                        _that.consultationCall(existingCallOnTarget, destination, cb);
                    }
                } else {
                    _that.makeCall(target, destination, cb);
                }
            }
        };

        /**
         * Returns the list of hunt groups that the user belongs to
         * @returns {Array} An array of elements: {name: HuntGroupName, pilotDn: Pilot DN,
         * available: The availability of the agent in the Hunt Group}
         */
        this.getHuntGroupList = function () {
            return _huntGroupList;
        };

        this.getUIAgentState = function () {
            if (!$rootScope.localUser || !$rootScope.localUser.isOSV) {
                return null;
            }
            if (_that.getGlobalAgentState()) {
                return AgentUIState.AVAILABLE;
            }
            var availability = _huntGroupList && _huntGroupList.some(function (huntGroup) {
                return huntGroup.available;
            });
            return availability ? AgentUIState.PARTIAL : AgentUIState.UNAVAILABLE;
        };

        return this;
    }

    // Exports
    circuit.CstaSvcImpl = CstaSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
