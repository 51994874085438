/*global RegistrationState, require*/

var Circuit = (function (circuit) {
    'use strict';

    var AtcCallInfo = circuit.AtcCallInfo;
    var AtcMessage = circuit.Enums.AtcMessage;
    var BusyHandlingOptions = circuit.BusyHandlingOptions;
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var Enums = circuit.Enums;
    var LocalCall = circuit.LocalCall;
    var Proto = circuit.Proto;
    var RedirectionTypes = circuit.Enums.RedirectionTypes;
    var RemoteCall = circuit.RemoteCall;
    var RoutingOptions = circuit.RoutingOptions;
    var RtcParticipant = circuit.RtcParticipant;
    var RtcSessionController = circuit.RtcSessionController;
    var sdpParser = circuit.sdpParser;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;
    var Utils = circuit.Utils;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;
    var WebRTCAdapter = circuit.WebRTCAdapter;

    var HD_VIDEO_RESOLUTIONS = Object.values(circuit.Enums.VideoResolutionLevel);

    ///////////////////////////////////////////////////////////////////////////////////////
    // CircuitCallControlSvc Implementation
    // Controls and manages WebRTC calls.
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function CircuitCallControlSvcImpl( // NOSONAR
        $rootScope,
        $timeout,
        $interval,
        $window,
        $q,
        LogSvc,
        PubSubSvc,
        UserSvc,
        ConversationSvc,
        NotificationSvc,
        InstrumentationSvc,
        DeviceDiagnosticSvc) {

        // The following imports need to be defined inside CircuitCallControlSvcImpl due to JS-SDK
        var Conversation = circuit.Conversation;
        var UserProfile = circuit.UserProfile;

        LogSvc.debug('New Service: CircuitCallControlSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var CLOSE_CALL_DELAY = 3200;          // Set the delay a little over 3 seconds to give enough time for 3 failed tones
        var TURN_TTL_RECOVER_TIME = 60000;    // 60 seconds
        var ATC_HANDOVER_TIME = 6000;         // 6 seconds
        var REVERSE_LOOKUP_MAX_TIME = 2000;   // 2 seconds
        var ATC_PICKUP_TIMER = 4000;          // 4 seconds
        var MAX_TRANSCRIPTIONS = 10;
        var SET_VIDEO_RECEIVER_CONFIGURATION_DELAY = 500; // 500ms
        var STREAM_ENDED_DELAY = 500;         // If a media stream has ended (e.g.: microphone), wait 500ms before acting upon it

        var NOP = function () {};

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _that = this;

        var _clientApiHandler = ClientApiHandler.getInstance();
        var _userToUserHandler = UserToUserHandler.getInstance();

        var _isMobile = Utils.isMobile();
        var _isCordova = !!$window.cordova;

        // All calls, local and remote
        var _calls = [];

        var _primaryLocalCall = null;
        var _secondaryLocalCall = null;

        // Calls established on other clients
        var _activeRemoteCalls = [];

        // Incoming alerting calls, we only support one for now
        var _incomingCalls = [];

        // Last ended call. Save the last ended call until a new call exists. Used for move calls scenarios.
        var _lastEndedCall = null;

        // Call id for the remote call that has handoverInProgress. Populated during /csta/handover event
        var _callIdToBeReplaced = null;

        var _wasMuted = false;
        var _disableRemoteVideoByDefault = false;
        var _sessionHash = {};
        var _activeSpeakerPromise = null;
        var _clientDiagnosticsDisabled = false;
        var _conversationLoaded = false;
        var _pendingInvites = {};
        var _pendingRemoteCalls = {};
        var _handoverTimer = null;
        var _turnCredentials = null;
        var _pickupTimer = null;
        var _callToPickup = null;
        var _pendingSetVideoReceiverConfiguration = null;
        var _streamEndedTimeout = null;

        var _negotiationFailureCauses = [
            Constants.RTCSessionParticipantLeftCause.TRANSPORT_NEGOTIATION_FAILED,
            Constants.RTCSessionParticipantLeftCause.NEGOTIATION_FAILED,
            Constants.RTCSessionParticipantLeftCause.SECURITY_NEGOTIATION_FAILED
        ];

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getConversation(convId) {
            return ConversationSvc.getConversationFromCache(convId);
        }

        function updateTurnExpireTime() {
            if (_turnCredentials) {
                var ttl = (_turnCredentials.ttl || 0) * 1000; // Convert to ms
                ttl = Math.max(ttl - TURN_TTL_RECOVER_TIME, 0);
                _turnCredentials.expirationTimestamp = Date.now() + ttl;
            }
        }

        function adjustMediaTypeConstraints(mediaType) {
            mediaType.audio = !!mediaType.audio;
            mediaType.video = !!mediaType.video;
            mediaType.desktop = !!mediaType.desktop;

            if (!mediaType.video) {
                mediaType.hdVideo = false;
            }
            if (!mediaType.desktop) {
                mediaType.hdDesktop = false;
            }
            if (circuit.isSDK || (!mediaType.video && !mediaType.desktop)) {
                // For now we're bypassing HD permission checks for SDK apps
                return;
            }

            // Note that the BL must only automatically enable HD if hdVideo/hdDesktop is not set (i.e. typeof !== 'boolean')
            if (mediaType.video) {
                mediaType.hdVideo = !!($rootScope.localUser.canUseHDVideo &&
                    (typeof mediaType.hdVideo === 'boolean' ? mediaType.hdVideo : true));
            }
            if (mediaType.desktop) {
                mediaType.hdDesktop = !!($rootScope.localUser.canUseHDScreenShare &&
                    (typeof mediaType.hdDesktop === 'boolean' ? mediaType.hdDesktop : true));
            }
        }

        function getTurnCredentials(call) {
            return new $q(function (resolve, reject) {
                var expirationTimestamp = (_turnCredentials && _turnCredentials.expirationTimestamp) || 0;
                if (Date.now() < expirationTimestamp) {
                    var expirationDate = new Date(expirationTimestamp);
                    LogSvc.debug('[CircuitCallControlSvc]: Existing credentials are still valid. Expiration: ' + expirationDate);
                    resolve(_turnCredentials);
                    return;
                }

                LogSvc.debug('[CircuitCallControlSvc]: Get new TURN credentials');
                var renewTurnCredentialsInfo = DeviceDiagnosticSvc.createActionInfo(call, Constants.RtcDiagnosticsAction.RENEW_TURN_CREDENTIALS);
                _clientApiHandler.renewTurnCredentials(call.callId, function (err, servers) {
                    $rootScope.$apply(function () {
                        if (renewTurnCredentialsInfo) {
                            renewTurnCredentialsInfo.data = JSON.stringify(servers);
                            DeviceDiagnosticSvc.finishActionInfo(call, renewTurnCredentialsInfo);
                        }
                        if (err) {
                            LogSvc.error('[CircuitCallControlSvc]: Error renewing TURN credentials.', err);
                            reject(err);
                            return;
                        }
                        if (!servers || !servers.length) {
                            LogSvc.error('[CircuitCallControlSvc]: Error renewing TURN credentials. No server(s) available.');
                            reject('No TURN server(s) available');
                            return;
                        }
                        servers = servers[0];
                        LogSvc.info('[CircuitCallControlSvc]: Received new TURN credentials=', servers.turnServer);
                        _turnCredentials = servers;
                        updateTurnExpireTime();
                        resolve(_turnCredentials);
                    });
                });
            });
        }

        function prepareSession(conv, localCall, options) {
            return new $q(function (resolve, reject) {
                var prepareSessionData = {
                    convId: conv.convId,
                    rtcSessionId: localCall.callId,
                    ownerId: conv.creatorId,
                    mediaNode: localCall.getMediaNode(),
                    isTelephonyConversation: conv.isTelephony,
                    replaces: options.replaces && options.replaces.callId,
                    desiredRegion: options.desiredRegion
                };

                DeviceDiagnosticSvc.startDiagnostics(localCall);
                // Only start the join delay timer for group call here, for direct calls we start it in sdpAnswer or answerRtcCall.
                if (!localCall.isDirect) {
                    DeviceDiagnosticSvc.startJoinDelayTimer(localCall);
                }

                var prepareActionInfo = DeviceDiagnosticSvc.createActionInfo(localCall, Constants.RtcDiagnosticsAction.PREPARE);

                _clientApiHandler.prepareSession(prepareSessionData, function (err, servers, newRtcSessionId) {
                    $rootScope.$apply(function () {
                        if (prepareActionInfo) {
                            prepareActionInfo.data = err ? err.toString() : JSON.stringify(servers);
                            DeviceDiagnosticSvc.finishActionInfo(localCall, prepareActionInfo);
                        }

                        if (err) {
                            if (err === Constants.ErrorCode.RTC_CONCURRENT_INCOMING_CALL) {
                                LogSvc.debug('[CircuitCallControlSvc]: Concurrent incoming call, aborting own attempt.');
                            } else if (err === Constants.ErrorCode.RTC_NO_MEDIA_NODES_AVAILABLE) {
                                LogSvc.error('[CircuitCallControlSvc]: No media nodes available, call not possible.');
                            } else if (err === Constants.ErrorCode.RTC_MEDIA_NODE_UNREACHABLE) {
                                LogSvc.error('[CircuitCallControlSvc]: Specified media node is not reachable, call not possible.');
                            } else {
                                LogSvc.error('[CircuitCallControlSvc]: Error retrieving start session data. ', err);
                            }

                            reject(err);
                            return;
                        }

                        if (!servers || !servers.length) {
                            LogSvc.error('[CircuitCallControlSvc]: Error getting TURN credentials. No server(s) available.');
                            reject('No TURN server(s) available');
                            return;
                        }

                        LogSvc.info('[CircuitCallControlSvc]: Received new TURN credentials');
                        servers = servers[0];
                        _turnCredentials = servers;
                        updateTurnExpireTime();

                        if (localCall.isTelephonyCall && newRtcSessionId && newRtcSessionId !== localCall.callId) {
                            // For telephony calls, the call ID (i.e. rtc session ID) can be different from the
                            // conversation's rtcSessionId since we can support up to two calls in the same conversation.
                            var telephonyConv = getConversation(localCall.convId);

                            if (telephonyConv && (Utils.isMobile() || circuit.isSDK)) {
                                // For mobile clients and SDK we need to "terminate" the old call and create a new call.
                                localCall.setCallIdForTelephony(newRtcSessionId);

                                // Create a temporary call object with old rtc session id to raise call ended event to UI
                                // The "replaced" flag is set to true in the ended message so that the
                                // mobile client knows this is part of a call update.
                                var oldCall = new LocalCall(telephonyConv, {clientId: localCall.clientId});
                                telephonyConv.call = oldCall;

                                // Terminate the call to clear its resources.
                                oldCall.terminate();

                                // End temporary call associated to old coversation
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/ended event');
                                PubSubSvc.publish('/call/ended', [oldCall, true]);

                                telephonyConv.call = localCall;

                                publishConversationUpdate(telephonyConv);

                                // Inform the UI that call has been moved
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/moved event');
                                PubSubSvc.publish('/call/moved', [oldCall.callId, localCall.callId]);

                                // The /call/state event must be after /call/moved event (required by iOS)
                                publishCallState(localCall);
                            } else {
                                // For web/DA we can simply update the call ID and keep using the same call object
                                var oldCallId = localCall.callId;
                                localCall.setCallIdForTelephony(newRtcSessionId);

                                // Events have already been published for the old call ID, so we need to notify the GUI that
                                // this call has a new call ID
                                publishCallState(localCall, oldCallId);
                            }
                        }
                        resolve(_turnCredentials);
                    });
                });
            });
        }

        function getJoinErrorText(error) {
            if (error) {
                if (error.startsWith('res_')) {
                    return error;
                }
                if (error === Constants.ErrorCode.PERMISSION_DENIED) {
                    return 'res_JoinRTCSessionFailedPermission';
                }
                if (error === Constants.SdpFailedCause.JOIN_FORBIDDEN) {
                    return 'res_JoinRTCSessionFailedForbidden';
                }
                if (error === Constants.ErrorCode.RTC_NO_MEDIA_NODES_AVAILABLE) {
                    return 'res_JoinRTCSessionFailedMissingResources';
                }
                if (error === Constants.ReturnCode.CHOOSE_DESKTOP_MEDIA_CANCELLED) {
                    return error;
                }
                if (error === Constants.ReturnCode.DISCONNECTED ||
                    error === Constants.ReturnCode.FAILED_TO_SEND) {
                    return 'res_NotConnectedTryAgain';
                }
            }
            // Default error
            return 'res_JoinRTCSessionFailed';
        }

        /**
         * Randomly generate an ACTIVE_SPEAKER and VIDEO_ACTIVE_SPEAKER events
         */
        function mockActiveSpeakerEvents() {
            // Randomly execute this code
            if (!Utils.randomBoolean()) {
                return;
            }

            // Only get the participants that are active in the call
            var activeParticipants = _primaryLocalCall.participants.filter(function (p) {
                return p.pcState === Enums.ParticipantState.Active;
            });

            if (activeParticipants.length < 2) {
                // Not a group call with 3+ participants
                return;
            }

            // Add local user
            activeParticipants.push($rootScope.localUser);

            // 1) ACTIVE_SPEAKER event
            var speakerEvt = {sessionId: _primaryLocalCall.callId};
            var fields = ['first', 'second', 'third'];

            var numSpeakers = Utils.randomNumber(0, 3);
            // Get the active speakers
            var activeSpeakers = Utils.randomArrayCopy(activeParticipants, numSpeakers);

            // The first participant in the array is the main speaker, so we will include
            // it more often.
            if (activeSpeakers.indexOf(activeParticipants[0]) === -1 && Utils.randomBoolean()) {
                // Add main speaker
                activeSpeakers.unshift(activeParticipants[0]);
                if (numSpeakers < 3) {
                    numSpeakers++;
                }
            }

            for (var idx = 0; idx < numSpeakers; idx++) {
                speakerEvt[fields[idx]] = activeSpeakers[idx].userId;
            }

            LogSvc.debug('[CircuitCallControlSvc]: Firing mocked ACTIVE_SPEAKER event: ', speakerEvt);
            onActiveSpeakerEvent(speakerEvt);
        }

        function isActiveSpeakerSimulationNeeded() {
            return _primaryLocalCall && _primaryLocalCall.isMocked && !_primaryLocalCall.isDirect && _primaryLocalCall.participants.length > 1;
        }

        function simulateActiveSpeakers() {
            if (_activeSpeakerPromise || !isActiveSpeakerSimulationNeeded()) {
                return;
            }

            _activeSpeakerPromise = $interval(function () {
                LogSvc.debug('[CircuitCallControlSvc]: Active speaker simulation interval has fired');
                if (!isActiveSpeakerSimulationNeeded()) {
                    LogSvc.debug('[CircuitCallControlSvc]: Active speaker simulation is no longer needed. Stop interval.');
                    $interval.cancel(_activeSpeakerPromise);
                    _activeSpeakerPromise = null;
                } else if (_primaryLocalCall.isEstablished()) {
                    // Mock ActiveSpeaker event
                    mockActiveSpeakerEvents();
                }
            }, 2000, 0, false); // apply MUST be set to false here
        }

        function addCallToList(call, incomingOnly) {
            if (call.checkState(Enums.CallState.Ringing)) {
                _incomingCalls.push(call);
                if (incomingOnly) {
                    return;
                }
            }
            for (var idx = 0; idx < _calls.length; idx++) {
                if (call.sameAs(_calls[idx])) {
                    LogSvc.debug('[CircuitCallControlSvc]: Updating call with callId = ' + call.callId);
                    _calls[idx] = call;
                    return;
                }
            }
            // This is a new call
            _calls.push(call);

            if (call === _primaryLocalCall) {
                simulateActiveSpeakers();
            }
            if ($rootScope.localUser.isOsBizCTIEnabled && call.isTelephonyCall &&
                !call.isRemote && _primaryLocalCall && call !== _primaryLocalCall) {
                _secondaryLocalCall = call;
            }

            _lastEndedCall = null;
        }

        function hasScreenControlFeature(call) {
            return !!call && $rootScope.localUser.remoteControlEnabled;
        }

        function removeCallFromList(call) {
            // Remove it from the call list
            var idx;
            for (idx = 0; idx < _calls.length; idx++) {
                if (call.sameAs(_calls[idx])) {
                    _calls.splice(idx, 1);
                    break;
                }
            }
            for (idx = 0; idx < _incomingCalls.length; idx++) {
                if (call.sameAs(_incomingCalls[idx]) && !call.isHandoverInProgress) {
                    _incomingCalls.splice(idx, 1);
                    break;
                }
            }
            for (idx = 0; idx < _activeRemoteCalls.length; idx++) {
                if (call.sameAs(_activeRemoteCalls[idx])) {
                    _activeRemoteCalls.splice(idx, 1);
                    break;
                }
            }
            if (!call.isRemote) {
                _wasMuted = call.sessionCtrl && call.sessionCtrl.isMuted();
                _lastEndedCall = call;
                $timeout(function () {
                    if (_lastEndedCall === call) {
                        _lastEndedCall = null;
                    }
                }, CLOSE_CALL_DELAY);
            } else {
                _lastEndedCall = null;
            }
        }

        function publishConversationUpdate(conversation) {
            if (conversation) {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /conversation/update event. convId = ', conversation.convId);
                PubSubSvc.publish('/conversation/update', [conversation]);
            }
        }

        function publishCallState(call, oldCallId) {
            if (call && call.state !== Enums.CallState.Terminated) {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/state event. callId = ' + call.callId + ', state = ' + call.state.name);
                PubSubSvc.publish('/call/state', oldCallId ? [call, oldCallId] : [call]);
            }
        }

        function publishIceConnectionState(call, iceConnectionState) {
            if (call) {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/rtp/iceConnectionState event. callId = ' + call.callId + ', iceState = ' + iceConnectionState);
                PubSubSvc.publish('/call/rtp/iceConnectionState', [call.callId, iceConnectionState]);
            }
        }

        function getCallReplaced(call) {
            if (_primaryLocalCall && _primaryLocalCall.sameAs(call)) {
                // Check if there is an incoming call replacing this call
                var alertingCall = _that.getIncomingCall();
                return !!(alertingCall && alertingCall.replaces && alertingCall.replaces.sameAs(call));
            }
            return !!(_primaryLocalCall && _primaryLocalCall.replaces && _primaryLocalCall.replaces.sameAs(call));
        }

        function isOfflineFailure(reason) {
            switch (reason) {
            case Enums.CallClientTerminatedReason.DISCONNECTED:
            case Enums.CallClientTerminatedReason.FAILED_TO_SEND:
            case Enums.CallClientTerminatedReason.REQUEST_TIMEOUT:
                return true;
            default:
                return false;
            }
        }

        function terminateCall(call, reason, suppressEvent) {
            if (!call) {
                return;
            }

            LogSvc.info('[CircuitCallControlSvc]: Terminating call with reason: ', reason);
            var replaced = getCallReplaced(call);

            if (_pendingSetVideoReceiverConfiguration && _pendingSetVideoReceiverConfiguration.callId === call.callId) {
                _pendingSetVideoReceiverConfiguration.cancel();
            }

            // Dismiss incoming call notification if present
            dismissNotification(call);

            if (!call.isRemote) {
                if (reason) {
                    call.terminateReason = reason;
                    if (isOfflineFailure(reason)) {
                        storeOfflineJoinFailure(call);
                    }
                }

                DeviceDiagnosticSvc.forceFinishDeviceDiagnostics(call);
            }

            if (call.rejectGetScreenPromise) {
                call.rejectGetScreenPromise(Constants.ReturnCode.CHOOSE_DESKTOP_MEDIA_CANCELLED);
                call.rejectGetScreenPromise = null;
            }

            if (!call.isOsBizSecondCall) {
                unregisterSessionController(call);
            }
            removeCallFromList(call);
            call.terminate();

            // First terminate the call
            if (_primaryLocalCall && _primaryLocalCall.sameAs(call)) {
                // Cancel terminate timer (if running)
                $timeout.cancel(_primaryLocalCall.terminateTimer);
                _primaryLocalCall = _secondaryLocalCall;
                _secondaryLocalCall = null;
            } else if (_secondaryLocalCall && _secondaryLocalCall.sameAs(call)) {
                $timeout.cancel(_secondaryLocalCall.terminateTimer);
                _secondaryLocalCall = null;
            }

            // Now remove it from the conversation
            var conversation = getConversation(call.convId);
            if (conversation && conversation.call && conversation.call.sameAs(call) && !call.isHandoverInProgress) {
                if (call.isTelephonyCall) {
                    var otherCall = findActivePhoneCall() || findHeldPhoneCall();
                    conversation.call = otherCall || null;
                } else {
                    conversation.call = null;
                }

                publishConversationUpdate(conversation);
            }

            // If there are no more calls, cancel the activeSpeakerPromise timer
            if (_activeSpeakerPromise && !_primaryLocalCall) {
                $interval.cancel(_activeSpeakerPromise);
                _activeSpeakerPromise = null;
            }

            if (!suppressEvent) {
                // Check if _primaryLocalCall has replaced this call
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/ended event. Replaced=', replaced);
                PubSubSvc.publish('/call/ended', [call, replaced]);

                if (call.ratingEvent) {
                    publishShowRatingDialog(call.ratingEvent);
                }
            }
        }

        function storeOfflineJoinFailure(call) {
            if (!call) {
                return;
            }

            var failureReason = '';
            switch (call.terminateReason) {
            case Enums.CallClientTerminatedReason.DISCONNECTED:
                failureReason = Constants.RtcClientInfoReason.DISCONNECTED;
                break;
            case Enums.CallClientTerminatedReason.FAILED_TO_SEND:
                failureReason = Constants.RtcClientInfoReason.FAILED_TO_SEND;
                break;
            case Enums.CallClientTerminatedReason.REQUEST_TIMEOUT:
                failureReason = Constants.RtcClientInfoReason.REQUEST_TIMEOUT;
                break;
            default:
                // For now we store only no network connection issues here
                return;
            }

            var clientInfo = {
                userId: $rootScope.localUser.userId,
                convId: call.convId,
                clientInfoType: Constants.RtcClientInfoType.OFFLINE_JOIN_FAILURE,
                reason: failureReason,
                timestamp: Date.now(),
                sessionId: call.callId
            };

            // We need to check the calls which are remoteActive for this session Id whether it contains instanceId
            var conv = ConversationSvc.getConversationByRtcSession(call.callId);
            if (conv && conv.call && conv.call.instanceId) {
                clientInfo.instanceId = conv.call.instanceId;
            }

            LogSvc.info('[CircuitCallControlSvc]: Store new offline join failure. ', clientInfo);
            DeviceDiagnosticSvc.storeClientInfo(clientInfo);
        }

        function findLocalCallByCallId(callId) {
            if (_primaryLocalCall && _primaryLocalCall.callId === callId) {
                return _primaryLocalCall;
            }
            if (_secondaryLocalCall && _secondaryLocalCall.callId === callId) {
                return _secondaryLocalCall;
            }
            return null;
        }

        function findCall(rtcSessionId) {
            var idx;
            for (idx = 0; idx < _calls.length; idx++) {
                if (_calls[idx].callId === rtcSessionId) {
                    return _calls[idx];
                }
            }

            // There's maybe an incoming call hidden from main call list
            for (idx = 0; idx < _incomingCalls.length; idx++) {
                if (_incomingCalls[idx].callId === rtcSessionId) {
                    return _incomingCalls[idx];
                }
            }

            // We could not find the call in the call list. Let's make sure
            // that we don't find the call in the conversation.
            var conversation = ConversationSvc.getConversationByRtcSession(rtcSessionId);
            if (conversation && conversation.call && !conversation.call.isAtcRemote && conversation.call.state.established &&
            conversation.call.callId === rtcSessionId) {
                // Somehow we are out of synch.
                // Add the call to the call list and continue.
                _calls.push(conversation.call);
                return conversation.call;
            }
            return findLocalCallByCallId(rtcSessionId);
        }

        function lookupParticipant(rtcParticipant, call) {
            if (rtcParticipant.participantType === Constants.RTCParticipantType.TELEPHONY && rtcParticipant.phoneNumber) {
                var participant = _primaryLocalCall.getParticipant(rtcParticipant.userId);
                //remove spaces from String
                var rtcParticipantPhoneNumber = rtcParticipant.phoneNumber.replace(/\s/g, '');
                if (participant && participant.phoneNumber && (participant.phoneNumber === rtcParticipant.phoneNumber || participant.phoneNumber === rtcParticipantPhoneNumber)) {
                    // Not need to lookup and update
                    return;
                }
                UserSvc.startReverseLookUp(rtcParticipantPhoneNumber, function (user) {
                    if (call.isPresent() && user) {
                        var callParticipant = _primaryLocalCall.getParticipant(rtcParticipant.userId);
                        if (callParticipant) {
                            callParticipant.firstName = user.firstName;
                            callParticipant.lastName = user.lastName;
                            callParticipant.displayName = user.displayName;
                            // Store the resolved userId in the resolvedUserId field, since the participant object
                            // already has a userId field, which is generated for TELEPHONY participants
                            callParticipant.resolvedUserId = user.userId;
                            // Publish /call/participant/updated so the application can update the participant's name
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event. userId =', callParticipant.userId);
                            PubSubSvc.publish('/call/participant/updated', [_primaryLocalCall.callId, callParticipant]);
                        }
                    }
                });
            }
        }

        function findActivePhoneCall(onlyLocal) {
            return _calls.find(function (call) {
                return call.isTelephonyCall && call.state.established && !call.isHolding() && (!onlyLocal || !call.isRemote);
            }) || null;
        }

        function findHeldPhoneCall(onlyLocal) {
            return _calls.find(function (call) {
                return call.isTelephonyCall && call.state.established && call.isHolding() && (!onlyLocal || !call.isRemote);
            }) || null;
        }

        function isAtcDefaultBusyHandlingSelected() {
            return !$rootScope.localUser.selectedBusyHandlingOption ||
                $rootScope.localUser.selectedBusyHandlingOption === BusyHandlingOptions.DefaultRouting.name ||
                $rootScope.localUser.isSTC;
        }

        function setCallPeerUser(call, phoneNumber, fqNumber, displayName, userId, cb) {
            if (call.atcCallInfo) {
                // this is an atc call
                if (!fqNumber && call.atcCallInfo.peerFQN) {
                    // the fqn is available in the atcCallInfo
                    fqNumber = call.atcCallInfo.peerFQN;
                } else if (fqNumber && !call.atcCallInfo.peerFQN) {
                    // the fqn is not available in the atcCallInfo yet
                    call.atcCallInfo.peerFQN = fqNumber;
                }
            }

            if (fqNumber && call.peerUser && call.peerUser.fqnLookedUp === fqNumber) {
                // fqNumber already looked up
                cb && cb();
                return;
            }

            // Temporarily display whatever is available until user search is done
            call.setPeerUser(phoneNumber, displayName, userId);

            if (userId || !fqNumber) {
                cb && cb();
                return;
            }

            var maxWaitTime = null;
            if (cb) {
                maxWaitTime = $timeout(function () {
                    maxWaitTime = null;
                    if (call.isPresent()) {
                        cb();
                        cb = null;
                    }
                }, REVERSE_LOOKUP_MAX_TIME);
            }

            UserSvc.startReverseLookUp(fqNumber, function (user) {
                $timeout.cancel(maxWaitTime);
                if (call.isPresent()) {
                    if (user) {
                        var data = {
                            extAvatarUri: user.extAvatarUri,
                            smallImageUri: user.smallImageUri,
                            largeImageUri: user.largeImageUri
                        };
                        call.setPeerUser(phoneNumber, user.displayName, user.userId, fqNumber, data);
                        // After setting the peer user a /call/state event shall be triggered to
                        // update mobile call stage unless a callback has been passed. In this
                        // case it is assumed all proper events will be raised by the callback.
                        cb ? cb() : publishCallState(call);
                    } else {
                        cb && cb();
                    }
                }
            });
        }

        function setRedirectingUser(call, phoneNumber, fqNumber, displayName, type) {
            if (call.redirectingUser && call.redirectingUser.redirectionType) {
                return;
            }
            call.setRedirectingUser(phoneNumber, fqNumber, displayName, null, type);

            if (fqNumber) {
                UserSvc.startReverseLookUp(fqNumber, function (user) {
                    if (user && call.isPresent()) {
                        call.setRedirectingUser(phoneNumber, fqNumber, user.displayName, user.userId, type);
                        publishCallState(call);
                    }
                });
            } else {
                publishCallState(call);
            }
        }

        function normalizeApiParticipant(apiParticipant, mediaType) {
            var isCircuitUser = !apiParticipant.participantType ||
                apiParticipant.participantType === Constants.RTCParticipantType.USER;

            var user;
            if (apiParticipant.userId === $rootScope.localUser.userId) {
                user = $rootScope.localUser;
            } else if (isCircuitUser) {
                // The participant is a regular Circuit user. Get the user from the cache.
                user = UserSvc.getUserFromCache(apiParticipant.userId);
            }

            if (!user) {
                apiParticipant.userDisplayName = apiParticipant.userDisplayName || apiParticipant.phoneNumber || $rootScope.i18n.map.res_Unknown;

                var tmp = apiParticipant.userDisplayName.split(' ');
                apiParticipant.firstName = tmp[0];
                apiParticipant.lastName = tmp.splice(1).join(' ');

                if (isCircuitUser) {
                    // User is not yet cached. Create an empty user profile with noData, so the user
                    // information gets retrieved in addParticipantToCallObj.
                    user = UserProfile.extend({
                        userId: apiParticipant.userId,
                        firstName: apiParticipant.firstName,
                        lastName: apiParticipant.lastName,
                        displayName: apiParticipant.userDisplayName,
                        location: apiParticipant.location,
                        userType: Constants.UserType.REGULAR
                    });

                    // Add new user object to cache IF this is not a simulated participant
                    if (apiParticipant.isSimulated) {
                        user.isSimulated = true;
                    } else {
                        user.noData = true;
                        UserSvc.addUsersToCache(user, true, false);
                    }
                } else {
                    // Create a User object based on the participant data
                    if (apiParticipant.participantType === Constants.RTCParticipantType.SESSION_GUEST) {
                        apiParticipant.userType = Constants.UserType.SESSION_GUEST;
                    }
                    user = UserProfile.extend(apiParticipant);
                }
            }

            // Create a derived object
            var participant = RtcParticipant.createFromUser(user, Enums.ParticipantState.Active);
            participant.apiDisplayName = apiParticipant.userDisplayName || apiParticipant.phoneNumber;
            participant.streamId = apiParticipant.videoStreamId || '';
            participant.screenStreamId = apiParticipant.screenStreamId || '';
            participant.mediaType = Proto.getMediaType(apiParticipant.mediaTypes || mediaType);
            participant.muted = apiParticipant.muted || !participant.mediaType.audio;
            participant.participantType = apiParticipant.participantType || Constants.RTCParticipantType.USER;
            participant.screenSharePointerSupported = !!apiParticipant.screenSharePointerSupported;
            participant.isModerator = !!apiParticipant.isModerator;
            participant.isMeetingGuest = apiParticipant.isMeetingGuest;
            participant.rtcSupportedFeatures = apiParticipant.rtcSupportedFeatures || [];
            participant.flags = apiParticipant.flags || [];
            return participant;
        }

        function putSessionTurn(sessionId, turnServers) {
            if (!turnServers || !turnServers.length) {
                return;
            }
            var cachedSession = _sessionHash[sessionId];
            if (cachedSession) {
                cachedSession.turnServers = turnServers;
            } else {
                _sessionHash[sessionId] = {turnServers: turnServers};
            }
        }

        function getSessionTurnUris(rtcSessionId) {
            var cachedSession = _sessionHash[rtcSessionId];
            if (cachedSession && cachedSession.turnServers && cachedSession.turnServers.length) {
                return cachedSession.turnServers;
            }
            return null;
        }

        function getClientTerminatedReason(retCode) {
            switch (retCode) {
            case Constants.ReturnCode.DISCONNECTED:
                return Enums.CallClientTerminatedReason.DISCONNECTED;
            case Constants.ReturnCode.FAILED_TO_SEND:
                return Enums.CallClientTerminatedReason.FAILED_TO_SEND;
            case Constants.ReturnCode.REQUEST_TIMEOUT:
                return Enums.CallClientTerminatedReason.REQUEST_TIMEOUT;
            default:
                return Enums.CallClientTerminatedReason.REQUEST_TO_SERVER_FAILED;
            }
        }

        function terminateNewLocalCall(newLocalCall, cb) {
            if (!newLocalCall) {
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: New call has been terminated during call setup. Call Id:', newLocalCall.callId);
            if (newLocalCall.callState !== Enums.CallState.Terminated) {
                terminateCall(newLocalCall, Enums.CallClientTerminatedReason.USER_ENDED);
            }
            cb && cb('Call already terminated');
        }

        function terminateRemoteAndAddLocal(call, conversation, options) {
            if (!call || !call.isRemote || call.isAtcRemote) {
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: Terminate the remote call and add the new local call to the conversation');
            if (options.handover) {
                _primaryLocalCall.activeClient = call.activeClient; // Indicates pull in progress
                if (call.isTelephonyCall) {
                    if (call.atcCallInfo) {
                        _primaryLocalCall.atcCallInfo = call.atcCallInfo;
                        // Since this is a local call the position must be changed to WebRTC
                        _primaryLocalCall.setPosition(Enums.Targets.WebRTC);
                    }
                    setCallPeerUser(_primaryLocalCall, call.peerUser.phoneNumber, null, call.peerUser.displayName);
                    _primaryLocalCall.participants = call.participants;
                }
                conversation.call = _primaryLocalCall;
            }
            _primaryLocalCall.hadRemoteCall = true;
            if (!call.isTelephonyCall) {
                terminateCall(call);
            }
        }

        function createLocalCall(conversation, mediaType, options, cb) {
            if (!canInitiateCall(conversation, options, cb)) {
                return false;
            }
            if (_primaryLocalCall) {
                _secondaryLocalCall = _primaryLocalCall;
            }
            var newCallOptions = {
                clientId: _clientApiHandler.clientId,
                midMappingEnabled: isMidMappingEnabled(),
                isSTC: $rootScope.localUser.isSTC && conversation.isTelephony,
                stcCapabilities: conversation.isTelephony ? $rootScope.localUser.stcCapabilities : [],
                canReceiveHdVideo: $rootScope.localUser.canUseHDVideo || $rootScope.localUser.canUseHDScreenShare
            };
            if (options.replaces && mediaType.desktop) {
                // This call should reuse the desktop stream from the call it's replacing (option used by VDI)
                newCallOptions.reuseDesktopStreamFrom = options.replaces.sessionCtrl;
            }
            _primaryLocalCall = new LocalCall(conversation, newCallOptions);
            _primaryLocalCall.setState(Enums.CallState.Initiated);
            _primaryLocalCall.pickUpFromUser = options.pickUpFromUser;
            _primaryLocalCall.pickUpUserId = options.pickUpUserId;

            addCallToList(_primaryLocalCall);

            var call = conversation.call;

            terminateRemoteAndAddLocal(call, conversation, options);

            _primaryLocalCall.setCallIdForTelephony(options.callId);
            _primaryLocalCall.setTransactionId();

            if (_disableRemoteVideoByDefault || conversation.isTelephony) {
                LogSvc.info('[CircuitCallControlSvc] Disabling remote video for outgoing call');
                _primaryLocalCall.disableRemoteVideo();
            }
            _primaryLocalCall.direction = Enums.CallDirection.OUTGOING;
            _primaryLocalCall.mediaType = mediaType;
            if (options.replaces) {
                _primaryLocalCall.replaces = options.replaces;
                var oldStream = options.replaces.sessionCtrl.getLocalStream(RtcSessionController.LOCAL_STREAMS.DESKTOP);
                if (oldStream) {
                    LogSvc.debug('[CircuitCallControlSvc] Reusing desktop stream from call ID=', options.replaces.callId);
                    _primaryLocalCall.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.DESKTOP, oldStream);
                    // Set old desktop stream to null so it won't be stopped by the old RtcSessionController
                    options.replaces.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.DESKTOP, null);
                }
                oldStream = options.replaces.sessionCtrl.getLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO);
                if (oldStream) {
                    LogSvc.debug('[CircuitCallControlSvc] Reusing audio/video stream from call ID=', options.replaces.callId);
                    _primaryLocalCall.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO, oldStream);
                    // Set old audio/video stream to null so it won't be stopped by the old RtcSessionController
                    options.replaces.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO, null);
                }
            }
            if (options.joiningGroupCall) {
                _primaryLocalCall.isJoiningGroupCall = true;
            }

            if (options.callOut) {
                // For call out scenarios, add all the conversation participants
                // to the call object...
                _primaryLocalCall.setPeerUsersAsParticipants();
                // ... and save this flag in the call obj
                _primaryLocalCall.isCallOut = true;
            }

            if (_primaryLocalCall.isTelephonyCall) {
                if (!options.handover) {
                    setCallPeerUser(_primaryLocalCall, options.dialedDn, null, options.toName, options.userId);
                    // Store the DTMF digits on call object
                    if (options.dtmfDigits) {
                        _primaryLocalCall.dtmfDigits = options.dtmfDigits;
                    }
                } else {
                    var activeRemoteCall = getActiveRemoteCall(_primaryLocalCall.callId);
                    if (activeRemoteCall) {
                        _primaryLocalCall.atcCallInfo = activeRemoteCall.atcCallInfo;
                        if (!activeRemoteCall.isAtcConferenceCall()) {
                            setCallPeerUser(_primaryLocalCall, activeRemoteCall.peerUser.phoneNumber, null, activeRemoteCall.peerUser.displayName);
                        }
                        terminateCall(activeRemoteCall);
                    }
                    _primaryLocalCall.handover = true;
                }
            }

            publishCallState(_primaryLocalCall);

            if ((!conversation.call || !(conversation.call.isRemote && options.handover)) && !_primaryLocalCall.conferenceCall) {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/outgoing event');
                PubSubSvc.publish('/call/outgoing', [_primaryLocalCall]);
            }

            _primaryLocalCall.isNewSession = !conversation.call || conversation.call !== _primaryLocalCall && !options.handover;
            conversation.call = _primaryLocalCall;
            publishConversationUpdate(conversation);
            return true;
        }

        function getRtcSupportedFeatures(call) {
            var rtcSupportedFeatures = isMidMappingEnabled() ? [Constants.RtcSupportedFeatures.MID_STREAM_MAPPING] : [];
            if (call.isDirect) {
                rtcSupportedFeatures.push(Constants.RtcSupportedFeatures.UPGRADE_TO_CONFERENCE);
            }

            if (isVideoAndScreenShareEnabled(call)) {
                rtcSupportedFeatures.push(Constants.RtcSupportedFeatures.SCREEN_SHARE_AND_ACTIVE_SPEAKER_VIDEO);
            }

            if (call.isDirect && call.checkState(Enums.CallState.Initiated)) {
                rtcSupportedFeatures.push(Constants.RtcSupportedFeatures.CALL_PICKUP);
            }
            return rtcSupportedFeatures;
        }


        function joinSession(conversation, mediaType, options, cb) {
            if (!_primaryLocalCall) {
                cb(getJoinErrorText());
                LogSvc.error('[CircuitCallControlSvc]: joinSession failed because there\'s no local call');
                return;
            }
            cb = cb || NOP;

            LogSvc.debug('[CircuitCallControlSvc]: joinSession - rtcSessionId=', options.callId || conversation.rtcSessionId);

            var newLocalCall = _primaryLocalCall;
            var sessionCtrl = _primaryLocalCall.sessionCtrl;

            var onSdpOffer = function (evt) {
                sessionCtrl.onSessionDescription = null;
                sessionCtrl.onClosed = null;

                if (_primaryLocalCall !== newLocalCall) {
                    terminateNewLocalCall(newLocalCall, cb);
                    return;
                }

                // Let's update the local call's mediaType in case we were not
                // able to access all required media streams.
                _primaryLocalCall.mediaType = sessionCtrl.getMediaConstraints();

                var joinData = {
                    convId: conversation.convId,
                    rtcSessionId: _primaryLocalCall.callId || conversation.rtcSessionId,
                    ownerId: conversation.creatorId,
                    sdp: evt.sdp,
                    mediaType: _primaryLocalCall.mediaType,
                    callOut: options.callOut,
                    handover: options.handover,
                    sendInviteCancel: !options.callOut,
                    replaces: options.replaces && options.replaces.callId,
                    isTelephonyConversation: _primaryLocalCall.isTelephonyCall,
                    displayName: $rootScope.localUser.displayName,
                    transactionId: _primaryLocalCall.transactionId,
                    screenSharePointerSupported: !!(_primaryLocalCall.pointer && _primaryLocalCall.pointer.isEnabled),
                    rtcSupportedFeatures: getRtcSupportedFeatures(_primaryLocalCall),
                    pickUpSession: options.pickUpSession
                };

                if (_primaryLocalCall.isTelephonyCall) {
                    var formattedDialedDn = PhoneNumberFormatter.format(options.dialedDn);

                    joinData.fromDn = Utils.cleanPhoneNumber($rootScope.localUser.callerId);
                    joinData.fromName = $rootScope.localUser.displayName;
                    joinData.fromUserId = $rootScope.localUser.userId;
                    joinData.dialedDn = Utils.cleanPhoneNumber(formattedDialedDn);
                    joinData.toName = options.toName;
                    joinData.toUserId = options.userId;
                    joinData.noCallLog = $rootScope.localUser.noCallLog;
                }

                if (conversation.isTemporary && conversation.guestToken) {
                    joinData.guestToken = conversation.guestToken;
                }

                // For testing incoming telephony calls from vgtc user: Send fromDn and fromName if localUser has role: VIRTUAL_TELEPHONY_CONNECTOR
                if ($rootScope.localUser.hasTelephonyRole) {
                    joinData.fromName = 'Gru';
                    joinData.fromDn = '+19998455246';
                }

                var joinInfo = DeviceDiagnosticSvc.createActionInfo(_primaryLocalCall, Constants.RtcDiagnosticsAction.JOIN);
                if (joinInfo) {
                    joinInfo.data = JSON.stringify(joinData.sdp);
                }

                _clientApiHandler.joinRtcCall(joinData, function (err) {
                    $rootScope.$apply(function () {
                        DeviceDiagnosticSvc.finishActionInfo(_primaryLocalCall, joinInfo);

                        if (_primaryLocalCall !== newLocalCall) {
                            terminateNewLocalCall(newLocalCall, cb);
                            return;
                        }
                        if (err) {
                            var replaces = _primaryLocalCall.replaces;
                            var reason = getClientTerminatedReason(err);
                            if (_primaryLocalCall.hadRemoteCall) {
                                // This method will re-create the remote call
                                _that.endCallWithCauseCode(_primaryLocalCall.callId, reason);
                            } else {
                                var callLeaveMethod = _primaryLocalCall.isDirect ? 'terminateRtcCall' : 'leaveRtcCall';
                                _clientApiHandler[callLeaveMethod](_primaryLocalCall.callId, _primaryLocalCall.disconnectCause, function (terminateErr) {
                                    terminateErr && LogSvc.warn('[CircuitCallControlSvc]: Error terminating RTC call. ', terminateErr);
                                });
                                terminateCall(_primaryLocalCall, reason);
                            }
                            if (replaces) {
                                _primaryLocalCall = replaces;
                            }
                            cb(getJoinErrorText(err));
                        } else {
                            LogSvc.debug('[CircuitCallControlSvc]: Join RTC session call was successful');

                            if (_primaryLocalCall.conferenceCall) {
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /conference/participant/joining event');
                                PubSubSvc.publish('/conference/participant/joining', [_primaryLocalCall]);
                            }
                            cb(null, options.warning, _primaryLocalCall);
                        }
                    });
                });
            };

            var errorCb = function (err) {
                if (_primaryLocalCall === newLocalCall) {
                    var reason = getClientTerminatedReason(err);
                    LogSvc.warn('[CircuitCallControlSvc]: Failed to initiate call. ', err);
                    if (!options.handover) {
                        _primaryLocalCall.setDisconnectCause(Constants.DisconnectCause.CALL_SETUP_FAILED, err);
                        if (_primaryLocalCall.hadRemoteCall) {
                            // This method will re-create the remote call
                            _that.endCallWithCauseCode(_primaryLocalCall.callId, reason);
                        } else {
                            var callLeaveMethod = _primaryLocalCall.isDirect ? 'terminateRtcCall' : 'leaveRtcCall';
                            _clientApiHandler[callLeaveMethod](_primaryLocalCall.callId, _primaryLocalCall.disconnectCause, function (terminateErr) {
                                terminateErr && LogSvc.warn('[CircuitCallControlSvc]: Error terminating RTC call. ', terminateErr);
                            });
                            terminateCall(_primaryLocalCall, reason);
                        }
                    } else {
                        terminateCall(_primaryLocalCall, reason);
                    }
                } else {
                    terminateNewLocalCall(newLocalCall, cb);
                    return;
                }
                cb(getJoinErrorText(err));
            };

            var onClosed = function () {
                sessionCtrl.onClosed = null;
                sessionCtrl.onSessionDescription = null;
                if (newLocalCall && newLocalCall.state === Enums.CallState.Initiated &&
                    newLocalCall.terminateReason === Enums.CallClientTerminatedReason.USER_ENDED) {
                    // Avoid error popup when call is cancelled by user at early stage
                    cb && cb();
                } else {
                    cb && cb(getJoinErrorText());
                }
            };

            var turnPromise;
            if (_primaryLocalCall.isNewSession) {
                // Session initiating side
                turnPromise = prepareSession(conversation, _primaryLocalCall, options);
            } else {
                turnPromise = getTurnCredentials(_primaryLocalCall);
            }
            turnPromise.then(function (turnCredentials) {
                if (turnCredentials) {
                    putSessionTurn(conversation.rtcSessionId, turnCredentials.turnServer);
                    sessionCtrl.setTurnUris(turnCredentials.turnServer);
                    sessionCtrl.setTurnCredentials(turnCredentials);
                }

                function warmupMedia() {
                    adjustMediaTypeConstraints(mediaType);
                    sessionCtrl.warmup(mediaType, null, function () {
                        if (_primaryLocalCall !== newLocalCall) {
                            terminateNewLocalCall(newLocalCall, cb);
                            return;
                        }

                        $rootScope.$apply(function () {
                            LogSvc.debug('[CircuitCallControlSvc]: The connection warmup was successful. Start the call.');
                            sessionCtrl.onSessionDescription = onSdpOffer;
                            sessionCtrl.onClosed = onClosed;
                            registerSessionController(_primaryLocalCall);
                            sessionCtrl.start(mediaType, null);
                        });
                    }, function (err) {
                        if (mediaType.audio && (mediaType.video || mediaType.hdVideo)) {
                            LogSvc.info('[CircuitCallControlSvc]: Fetching audio and video media failed, trying again without video');
                            mediaType.video = false;
                            mediaType.hdVideo = false;
                            warmupMedia();
                            return;
                        }
                        $rootScope.$apply(errorCb.bind(null, err));
                    }, options.screenShareOptions);
                }

                warmupMedia();
            })
            .catch(errorCb);
        }

        function changeMediaType(data, cb) {
            LogSvc.info('[CircuitCallControlSvc]: changeMediaType - data: ', data);
            var call = findLocalCallByCallId(data.callId);
            if (!call) {
                LogSvc.warn('[CircuitCallControlSvc]: changeMediaType - Cannot find local call');
                cb('No active call');
                return;
            }
            if (!data.externallyTriggered && !call.isEstablished()) {
                LogSvc.info('[CircuitCallControlSvc]: changeMediaType - The local call is not established');
                cb('Call not established');
                return;
            }

            var sessionCtrl = call.sessionCtrl;
            if (!sessionCtrl.isConnStable()) {
                cb('Connection not stable');
                return;
            }
            var invokeCb = function (err) {
                if (data.dontReuseAudioStream) {
                    // We set the dontReuseAudioStream property in sessionCtrl
                    // now we have to reset it
                    sessionCtrl.dontReuseAudioStream = false;
                }
                PubSubSvc.publish('/call/changeMediaType/completed', [call, err]);
                cb(err);
            };

            PubSubSvc.publish('/call/changeMediaType/started', [call, data]);

            call.pendingTransactionId = null;
            sessionCtrl.dontReuseAudioStream = data.dontReuseAudioStream;
            var onSdp = function (evt) {
                sessionCtrl.onSessionDescription = null;

                if (!call.pendingTransactionId || call.pendingTransactionId !== call.transactionId) {
                    // ANS-1337 - Change the transactionId when requesting ChangeMediaType
                    call.setTransactionId(data.transactionId);
                }
                call.pendingTransactionId = null;

                var changeMediaData = {
                    rtcSessionId: call.callId,
                    sdp: evt.sdp,
                    mediaType: data.mediaType,
                    transactionId: call.transactionId,
                    screenSharePointerSupported: !!(call.pointer && call.pointer.isEnabled),
                    hosted: data.hosted,
                    replaces: data.replaces,
                    rtcSupportedFeatures: getRtcSupportedFeatures(call)
                };

                _clientApiHandler.changeMediaType(changeMediaData, function (err) {
                    $rootScope.$apply(function () {
                        call.clearTransactionId();
                        if (err) {
                            sessionCtrl.renegotiationFailed(err);
                            invokeCb(err);
                        } else {
                            LogSvc.debug('[CircuitCallControlSvc]: changeMediaType was successful. Wait for SDP_ANSWER event');
                        }
                    });
                });
            };

            var screenShareWasActive = call.localMediaType.desktop;
            getTurnCredentials(call)
            .then(function (turnCredentials) {
                sessionCtrl.setTurnUris(turnCredentials.turnServer);
                sessionCtrl.setTurnCredentials(turnCredentials);
                sessionCtrl.onSessionDescription = onSdp;

                if (typeof data.hold === 'boolean') {
                    var holdRetrieve = data.hold ? sessionCtrl.holdCall : sessionCtrl.retrieveCall;
                    holdRetrieve(function (err) {
                        if (err) {
                            LogSvc.error('[CircuitCallControlSvc]: Error in hold/retrieve media');
                            sessionCtrl.onSessionDescription = null;
                            sessionCtrl.renegotiationFailed(err);
                            invokeCb('Error in hold/retrieve media');
                        } else {
                            invokeCb();
                        }
                    });
                } else {
                    call.lastMediaType = call.mediaType;

                    var options = {
                        changeDesktopMedia: data.changeDesktopMedia,
                        screenShareOptions: data.screenShareOptions,
                        videoOptions: data.videoOptions,
                        isDirectUpgradingToConf: !!(call.isDirect && data.hosted && data.replaces)
                    };

                    adjustMediaTypeConstraints(data.mediaType);
                    sessionCtrl.changeMediaType(data.mediaType, options, function (err) {
                        $rootScope.$apply(function () {
                            if (err) {
                                LogSvc.warn('[CircuitCallControlSvc]: changeMediaType failed - reason: ', err);
                                sessionCtrl.onSessionDescription = null;
                                sessionCtrl.renegotiationFailed(err);
                                invokeCb(err);
                            } else {
                                if (screenShareWasActive && !call.localMediaType.desktop) {
                                    // Screen share was removed
                                    LogSvc.debug('[CircuitCallControlSvc]: Publish /screenshare/ended event');
                                    PubSubSvc.publish('/screenshare/ended');
                                    call.pointer.isSupported = call.pointer.isEnabled = false;
                                }
                                invokeCb();
                            }
                        });
                    });
                }
            })
            .catch(function (err) {
                invokeCb(getJoinErrorText(err));
            });
        }

        function joinSessionCalled(conversation, mediaType, options, cb) {
            if (!_primaryLocalCall) {
                LogSvc.warn('[CircuitCallControlSvc]: joinSessionCalled invoked without _primaryLocalCall');
                return;
            }
            var call = _primaryLocalCall;
            var sessionCtrl = call.sessionCtrl;
            options = options || {};

            var onSdp = function (evt) {
                sessionCtrl.onSessionDescription = null;

                var data = {
                    convId: conversation.convId,
                    rtcSessionId: call.callId || conversation.rtcSessionId,
                    ownerId: conversation.creatorId,
                    sdp: evt.sdp,
                    mediaType: mediaType,
                    callOut: false,
                    handover: false,
                    sendInviteCancel: true,
                    isTelephonyConversation: conversation.isTelephony,
                    displayName: $rootScope.localUser.displayName,
                    transactionId: call.transactionId,
                    rtcSupportedFeatures: getRtcSupportedFeatures(call)
                };

                function joinAnswerCb(err) {
                    $rootScope.$apply(function () {
                        call.clearTransactionId();
                        if (err) {
                            terminateCall(call, getClientTerminatedReason(err));
                            cb(getJoinErrorText(err));
                        } else {
                            LogSvc.debug('[CircuitCallControlSvc]: Join/Answer RTC session was successful.');
                            cb(null, options.warning, call);
                        }
                    });
                }

                if (data.sdp && data.sdp.type === 'offer') {
                    _clientApiHandler.joinRtcCall(data, joinAnswerCb);
                } else {
                    DeviceDiagnosticSvc.startJoinDelayTimer(call);

                    _clientApiHandler.answerRtcCall(data, joinAnswerCb);
                }
            };

            sessionCtrl.onSessionDescription = onSdp;
            if (call.startedEarlyMedia) {
                sessionCtrl.startConnectedTimer();
                if (call.earlyMediaSdp) {
                    LogSvc.debug('[CircuitCallControlSvc]: Client already created early media. Use early media SDP for the Answer event.');
                    onSdp({ sdp: call.earlyMediaSdp });
                    call.earlyMediaSdp = null;
                } else {
                    LogSvc.debug('[CircuitCallControlSvc]: RTC session has already been started. Just wait for completion.');
                }
            } else if (!sessionCtrl.start(mediaType, null)) {
                terminateCall(call, Enums.CallClientTerminatedReason.RTC_SESSION_START_FAILED);
                cb(getJoinErrorText());
            }
        }

        function addRemoteCall(conversation, activeClient, session, sessionId, preprocessor) {
            if (!conversation) {
                return null;
            }
            if (conversation.isTemporary && !activeClient && !conversation.guestToken) {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /conversation/temporary/ended event');
                PubSubSvc.publish('/conversation/temporary/ended', [conversation]);
                return null;
            }

            var remoteCall = new RemoteCall(conversation);
            remoteCall.setState(activeClient ? Enums.CallState.ActiveRemote : Enums.CallState.Started);

            if (session) {
                // Ensure remoteCalls get the instanceId to store join failures in state disconnected
                session.instanceId && remoteCall.setInstanceId(session.instanceId);
                session.hosted && remoteCall.setDirectUpgradedToConf();
            }

            if (sessionId) {
                remoteCall.setCallIdForTelephony(sessionId);
            }

            addCallToList(remoteCall);
            if (activeClient) {
                remoteCall.setActiveClient(activeClient);
                addActiveRemoteCall(remoteCall);
                if (session) {
                    if (session.participants.length === 1 && session.callOut) {
                        remoteCall.pullNotAllowed = true;
                    }
                    remoteCall.mediaType = Proto.getMediaType(session.mediaTypes);
                }
            }

            if (typeof preprocessor === 'function') {
                // Preprocess the remoteCall object before raising the events
                preprocessor(remoteCall);
            }

            conversation.call = remoteCall;
            publishConversationUpdate(conversation);
            publishCallState(remoteCall);

            return remoteCall;
        }

        function changeRemoteCallToStarted(activeRemoteCall) {
            activeRemoteCall.setState(Enums.CallState.Started);
            activeRemoteCall.activeClient = null;
            publishCallState(activeRemoteCall);
            _activeRemoteCalls.some(function (call, idx) {
                if (call.callId === activeRemoteCall.callId) {
                    _activeRemoteCalls.splice(idx, 1);
                    return true;
                }
                return false;
            });
        }

        function sendBusy(inviteEvt) {
            _clientApiHandler.declineRtcCall({
                convId: inviteEvt.convId,
                rtcSessionId: inviteEvt.sessionId,
                cause: Constants.InviteRejectCause.BUSY,
                transactionId: inviteEvt.transactionId
            });
        }

        function publishAtcCall(conversation, inviteEvt, first) {
            var call = new LocalCall(conversation, {clientId: _clientApiHandler.clientId, midMappingEnabled: isMidMappingEnabled()});
            call.setInstanceId(inviteEvt.instanceId);
            call.setTransactionId(inviteEvt.transactionId);
            call.setState(Enums.CallState.Ringing);
            if (inviteEvt.from) {
                setCallPeerUser(call, inviteEvt.from.phoneNumber, inviteEvt.from.fullyQualifiedNumber, inviteEvt.from.displayName, null, function () {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish ' + first ? 'atccall/firstcall' : '/atccall/secondcall/' + ' event.');
                    PubSubSvc.publish(first ? '/atccall/firstcall' : '/atccall/secondcall', [call]);
                });
            }
        }

        function decline(incomingCall, params, suppressEvent, cb) {
            cb = cb || NOP;
            incomingCall = incomingCall || _primaryLocalCall;
            removeCallFromList(incomingCall);
            if (!incomingCall || (incomingCall.state !== Enums.CallState.Ringing && incomingCall.state !== Enums.CallState.Answering)) {
                LogSvc.debug('[CircuitCallControlSvc]: call invalid to be declined');
                cb('Call Invalid');
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: decline - rtcSessionId: ', incomingCall.callId);

            var data = {
                convId: incomingCall.convId,
                rtcSessionId: incomingCall.callId,
                cause: params.type,
                transactionId: incomingCall.transactionId
            };

            var eventParams = [incomingCall];
            params.err && eventParams.push(params.err);

            _clientApiHandler.declineRtcCall(data, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[CircuitCallControlSvc]: Error declining the RTC call. ', err);
                        cb(err);
                    } else {
                        cb();
                    }
                });
            });

            if (!incomingCall.isDirect) {
                // Add remote call
                var conversation = getConversation(incomingCall.convId);
                addRemoteCall(conversation);
            }

            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/declining event');
            PubSubSvc.publish('/call/declining', eventParams);
            terminateCall(incomingCall, params.type, suppressEvent);
        }

        // Leave a local call
        function leaveCall(localCall, declineReason, qosReason, isLastParticipant, cb) {
            localCall = localCall || _primaryLocalCall;

            if (!localCall) {
                LogSvc.debug('[CircuitCallControlSvc]: There is no local call');
                cb && $timeout(cb);
                return;
            }
            if (localCall.terminateTimer) {
                LogSvc.debug('[CircuitCallControlSvc]: Local call is already being terminated. Ignore leave request.');
                cb && $timeout(cb);
                return;
            }
            cb = cb || NOP;
            if (localCall.checkState([Enums.CallState.Ringing, Enums.CallState.Answering])) {
                decline(localCall, {type: declineReason || Constants.InviteRejectCause.DECLINE}, false, cb);
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: leaveCall - rtcSessionId=', localCall.callId);
            var conversation;
            localCall.clearAtcHandoverInProgress();

            if (localCall.activeClient) {
                // Call terminated while pull in progress, create an active remote call so user can pull again
                conversation = getConversation(localCall.convId);
                addRemoteCall(conversation, localCall.activeClient, null, null, function (call) {
                    if (_primaryLocalCall.isTelephonyCall) {
                        call.atcCallInfo = _primaryLocalCall.atcCallInfo;
                        call.peerUser = _primaryLocalCall.peerUser;
                    }
                });
                terminateCall(localCall, qosReason);
                return;
            }

            if (localCall.isDirect) {
                _clientApiHandler.terminateRtcCall(localCall.callId, localCall.disconnectCause, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[CircuitCallControlSvc]: Error terminating RTC call. ', err);
                        }
                        cb && cb(err);
                    });
                }, localCall.pickUpUserId || (localCall.pickUpFromUser && localCall.pickUpFromUser.userId));
            } else {
                if (!localCall.isTestCall) {
                    conversation = getConversation(localCall.convId);
                    if (conversation && (localCall.participants.length || conversation.isTemporary || localCall.hadRemoteCall)) {
                        // Create the remote call to show that the session is still active for group sessions and guest conferences.
                        var remoteCall = addRemoteCall(conversation);
                        localCall.isDirectUpgradedToConf && remoteCall.setDirectUpgradedToConf();
                    }
                }
                _clientApiHandler.leaveRtcCall(localCall.callId, localCall.disconnectCause, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[CircuitCallControlSvc]: Error leaving the RTC call. ', err);
                        }
                        cb && cb(err);
                    });
                });
            }

            if (qosReason === Enums.CallClientTerminatedReason.PAGE_UNLOADED) {
                localCall.terminateReason = qosReason;
                // Since the page is unloading, immediately send the QoS report
                localCall.qosSubmitted = true;
                InstrumentationSvc.sendQOSData(localCall, localCall.mediaType, localCall.sessionCtrl.getLastSavedStats());
            }

            if (!isLastParticipant) {
                if (localCall.isDirect) {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /call/terminating event');
                    PubSubSvc.publish('/call/terminating', [_primaryLocalCall]);
                } else {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /conference/leaving event');
                    PubSubSvc.publish('/conference/leaving', [localCall]);
                }
            }

            if (localCall.outgoingFailed()) {
                // Delay the terminate to keep the call header visible for a couple seconds
                localCall.terminateTimer = $timeout(function () {
                    localCall.terminateTimer = null;
                    terminateCall(localCall, qosReason);
                }, CLOSE_CALL_DELAY);
                localCall.terminateReason = qosReason; // In case the call is terminated before the timer pops, we have the actual reason.
            } else {
                terminateCall(localCall, qosReason);
            }

        }

        function terminateConference(call, cb) {
            cb = cb || NOP;

            if (call.isDirect) {
                LogSvc.debug('[CircuitCallControlSvc]: Direct call. Ignore terminate conference request.');
                $timeout(cb);
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: terminateConference - rtcSessionId=', call.callId);

            _clientApiHandler.terminateRtcCall(call.callId, call.disconnectCause, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[CircuitCallControlSvc]: Error terminating the RTC call. ', err);

                        if (call === _primaryLocalCall) {
                            var conversation = getConversation(call.convId);
                            if (conversation && call.isEstablished()) {
                                addRemoteCall(conversation);
                            }
                        }
                    }
                    cb && cb(err);
                });
            });
            if (call === _primaryLocalCall) {
                terminateCall(call, Enums.CallClientTerminatedReason.USER_ENDED);
            }
        }

        function endRemoteCall(remoteCall, cb) {
            LogSvc.debug('[CircuitCallControlSvc]: endRemoteCall - rtcSessionId=', remoteCall.callId);
            if (remoteCall.isDirect) {
                _clientApiHandler.terminateRtcCall(remoteCall.callId, remoteCall.disconnectCause, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[CircuitCallControlSvc]: Error terminating remote RTC call. ', err);
                        }
                        cb(err);
                    });
                });
            } else {
                _clientApiHandler.leaveRtcCall(remoteCall.callId, remoteCall.disconnectCause, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[CircuitCallControlSvc]: Error leaving remote RTC call. ', err);
                        }
                        cb(err);
                    });
                });
            }
        }

        function getActiveRemoteCall(callId) {
            return _activeRemoteCalls.find(function (call) {
                return call.callId === callId;
            }) || null;
        }

        function getIncomingCall(callId) {
            return _incomingCalls.find(function (call) {
                return call.callId === callId;
            }) || null;
        }

        function addActiveRemoteCall(remoteCall) {
            var found = _activeRemoteCalls.some(function (c, idx) {
                if (c === remoteCall) {
                    return true;
                }
                if (c.callId === remoteCall.callId) {
                    _activeRemoteCalls[idx] = remoteCall;
                    return true;
                }
                return false;
            });
            if (!found) {
                _activeRemoteCalls.push(remoteCall);
            }
        }

        function removeSessionParticipant(userId, cb) {
            cb = cb || NOP;
            if (_primaryLocalCall) {
                var callParticipant = _primaryLocalCall.getParticipant(userId);
                if (callParticipant) {
                    if (callParticipant.actions.includes(Enums.ParticipantAction.Drop)) {
                        var data = {
                            rtcSessionId: _primaryLocalCall.callId,
                            userId: userId
                        };
                        _clientApiHandler.removeSessionParticipant(data, function (err) {
                            $rootScope.$apply(function () {
                                if (err) {
                                    LogSvc.warn('[CircuitCallControlSvc]: Failed to remove participant');
                                    cb('res_RemoveParticipantFailed');
                                } else {
                                    cb();
                                }
                            });
                        });
                    } else {
                        LogSvc.warn('[CircuitCallControlSvc]: remove call participant, unsupported pcState: ', callParticipant.pcState.name);
                        cb('Participant not active');
                    }
                } else {
                    LogSvc.error('[CircuitCallControlSvc]: call participant not found: ', userId);
                    cb('Participant not found');
                }
            } else {
                cb('No active call');
            }
        }

        function verifyStreamIds(call, participant) {
            // Go through the participants list and make sure that no one
            // is using the same video stream ID of the supplied participant
            if (!participant.streamId) {
                return;
            }
            if (!call.isMocked) {
                call.participants.forEach(function (p) {
                    if (p.streamId === participant.streamId && p.userId !== participant.userId) {
                        p.streamId = '';
                        LogSvc.debug('[CircuitCallControlSvc]: The video stream has been re-assigned. Clear the streamId for previous participant. [userId, streamId] = ',
                            [p.userId, participant.streamId]);

                        _primaryLocalCall.setParticipantRemoteVideoStream(p);
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event. userId =', p.userId);
                        PubSubSvc.publish('/call/participant/updated', [call.callId, p]);
                    }
                });
            }
        }

        function setParticipantActions(call, participant) {
            var conversation = getConversation(call.convId);
            participant.setActions(conversation, $rootScope.localUser);
        }

        function checkSessionParticipantFlags(call, participant) {
            var overloadLevel = 0;
            if (participant && participant.flags) {
                if (participant.flags.includes(Constants.RTCSessionParticipantFlag.CPU_LOAD)) {
                    overloadLevel = 2;
                } else if (participant.flags.includes(Constants.RTCSessionParticipantFlag.CPU_LOAD_TRANSCODE)) {
                    overloadLevel = 1;
                }
            }
            if (overloadLevel > (call.vrsOverloadLevel || 0)) {
                call.vrsOverloadLevel = overloadLevel;
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/vrsOverloadLevelChanged event. userId =', participant.userId + ', level = ' + overloadLevel);
                PubSubSvc.publish('/call/vrsOverloadLevelChanged', [call, participant]);
            }
        }

        function addParticipantToCallObj(call, participant, state, update) {
            var added = call.addParticipant(participant, state, update);
            if (added) {
                verifyStreamIds(call, added);
                setParticipantActions(call, added);
                checkSessionParticipantFlags(call, added);

                // Check if we already have the user data
                if (participant.noData && !participant.reqPending) {
                    // Get the user data and add to cache
                    LogSvc.debug('[CircuitCallControlSvc]: Get user data for added participant. userId: ', participant.userId);
                    UserSvc.getUserById(participant.userId, function (err, user) {
                        if (!user) { return; }

                        // We successfully retrieved the user data. The participant object is automatically updated
                        // since it has the user object as its prototype. However, the firstName and lastName fields
                        // need to be manually updated because they are also set directly on the participant object.
                        // See RtcParticipant.createFromUser() for details.
                        LogSvc.debug('[CircuitCallControlSvc]: Successfully retrieved participant data. userId: ', participant.userId);

                        // Check if the call is still active and the participant is still a member
                        if (call.isPresent() && call.hasParticipant(participant.userId)) {
                            // Raise a /call/participant/updated event for mobile clients)
                            participant.firstName = user.firstName;
                            participant.lastName = user.lastName;

                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event. userId =', participant.userId);
                            PubSubSvc.publish('/call/participant/updated', [call.callId, participant]);
                        }
                    });
                }
            }
            return added;
        }

        function updateParticipantInCallObj(call, participant, state) {
            var userId = participant.userId;
            var currentData = call.getParticipant(userId);
            if (currentData && currentData.isMeetingGuest) {
                LogSvc.debug('[ConversationSvc]: Publish /call/guestBecameConversationParticipant event for userId = ', userId);
                PubSubSvc.publish('/call/guestBecameConversationParticipant', [call.callId, userId]);
            }

            var updated = call.updateParticipant(participant, state);
            if (updated) {
                verifyStreamIds(call, updated);
                setParticipantActions(call, updated);
                checkSessionParticipantFlags(call, updated);
            }
            return updated;
        }

        function removeCallParticipant(call, userId, cause) {
            var state = Enums.ParticipantState.Terminated;
            switch (cause) {
            case Constants.RTCSessionParticipantLeftCause.CONNECTION_LOST:
            case Constants.RTCSessionParticipantLeftCause.STREAM_LOST:
                state = Enums.ParticipantState.ConnectionLost;
                break;
            case Constants.RTCSessionParticipantLeftCause.REMOVED:
                state = Enums.ParticipantState.Removed;
                break;
            case Constants.RTCSessionParticipantLeftCause.USER_LEFT_STAGE:
                state = Enums.ParticipantState.OffStage;
                break;
            }
            call.setParticipantState(userId, state);

            var removedParticipant = call.removeParticipant(userId);

            if (!call.hasOtherParticipants()) {
                // everybody left but the localUser; return to the waiting state
                call.setState(Enums.CallState.Waiting);
            }

            if (removedParticipant) {
                // Update the call's media type
                call.updateMediaType();

                if (call.conferenceCall) {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /conference/participant/left event');
                    PubSubSvc.publish('/conference/participant/left', [call, cause]);
                }
                var leftDataWrapper = cause ? { leftCause: cause } : null;
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/removed event');
                PubSubSvc.publish('/call/participant/removed', [call.callId, removedParticipant, leftDataWrapper]);
            }
            publishCallState(call);
        }

        function setRecordingInfoData(localCall, data) {
            if (!localCall || !data || !data.state || data.state === Constants.RecordingInfoState.INITIAL) {
                return;
            }
            if (data.recordingMediaTypes && data.recordingMediaTypes.includes(Constants.RecordingMediaType.TEXT)) {
                // At moment only set state
                localCall.transcription.state = data.state;

                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/transcription/info event');
                PubSubSvc.publish('/call/transcription/info', [localCall]);
            }
            if (!data.recordingMediaTypes ||
                data.recordingMediaTypes.includes(Constants.RealtimeMediaType.AUDIO) ||
                data.recordingMediaTypes.includes(Constants.RealtimeMediaType.VIDEO)) {
                setAudioVideoRecordingData(localCall.recording, data);

                if (localCall.recording.recordingUserId) {
                    localCall.recording.recordingUser = localCall.getParticipant(localCall.recording.recordingUserId);

                    if (!localCall.recording.recordingUser) {
                        UserSvc.getUserById(localCall.recording.recordingUserId, function (err, user) {
                            if (!user || err) {
                                LogSvc.error('[CircuitCallControlSvc]: Cannot retrieve recording user data.', err || '');
                                return;
                            }

                            localCall.recording.recordingUser = user;
                            LogSvc.debug('[CircuitCallControlSvc]: User info retrieved. Publish /call/recording/info event');
                            PubSubSvc.publish('/call/recording/info', [localCall]);
                        });
                        return;
                    }
                }
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/recording/info event');
                PubSubSvc.publish('/call/recording/info', [localCall]);
            }
        }

        function setAudioVideoRecordingData(recording, data) {
            recording.recordingMediaTypes = data.recordingMediaTypes;

            if (recording.state !== data.state) {
                recording.notifyByCurtain = data.state === Constants.RecordingInfoState.START_PENDING ||
                    (data.state === Constants.RecordingInfoState.STARTED && recording.state === Constants.RecordingInfoState.START_PENDING);
                recording.notifyByUser = !recording.notifyByCurtain ||
                    (data.state === Constants.RecordingInfoState.START_PENDING && recording.state !== Constants.RecordingInfoState.STARTED);
            } else {
                recording.notifyByCurtain = false;
                recording.notifyByUser = false;
            }

            recording.state = data.state;
            recording.duration = data.duration || recording.duration;
            recording.reason = data.reason;
            recording.recordingUserId = null;
            recording.recordingUser = null;
            if (data.state === Constants.RecordingInfoState.STARTED) {
                recording.resumeTime = Date.now();

                if (data.layout && data.layout.layoutMapping && data.layout.layoutMapping.length &&
                    data.layout.layoutName === Constants.RecordingVideoLayoutName.SINGLE_VIDEO) {
                    recording.recordingUserId = data.layout.layoutMapping[0].tileContent;
                }
            }

            if (data.starter && data.starter.userId && data.starter.userId !== recording.starter.userId) {
                recording.starter = data.starter;
                if (recording.starter.userId === $rootScope.localUser.userId) {
                    recording.starter.res = 'res_StartedRecording_You';
                } else {
                    recording.starter.name = recording.starter.displayName;
                    recording.starter.res = recording.starter.name ? 'res_StartedRecording_Other' : 'res_StartedRecording';
                }
            }
        }

        function updateAttendeeCount(attendeeCount) {
            if (_primaryLocalCall && (attendeeCount !== undefined) && (_primaryLocalCall.attendeeCount !== attendeeCount)) {
                _primaryLocalCall.attendeeCount = attendeeCount;
                _primaryLocalCall.updateCallState();

                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/attendeeCountChanged');
                PubSubSvc.publish('/call/attendeeCountChanged', [_primaryLocalCall.callId, _primaryLocalCall.attendeeCount]);
            }
        }

        function testCandidatesCollection(turnCredentials) {
            return new $q(function (resolve, reject) {
                var sessionCtrl = new RtcSessionController({disableTrickleIce: true, isDirectCall: true, candidatesCollectionTest: true});

                var terminate = function () {
                    LogSvc.debug('[CircuitCallControlSvc]: Terminate ICE candidates collection test');
                    sessionCtrl.onSessionDescription = null;
                    sessionCtrl.onRtcError = null;
                    sessionCtrl.terminate();
                };

                if (turnCredentials) {
                    sessionCtrl.setTurnUris(turnCredentials.turnServer);
                    sessionCtrl.setTurnCredentials(turnCredentials);
                }
                sessionCtrl.disableRemoteVideo();
                sessionCtrl.onSessionDescription = function (event) {
                    var candidates = circuit.sdpParser.getIceCandidates(event.sdp.sdp);
                    LogSvc.debug('[CircuitCallControlSvc]: testIceCandidates - candidates: ', candidates);
                    terminate();
                    resolve({
                        turnServers: (turnCredentials && turnCredentials.turnServer) || [],
                        candidates: candidates
                    });
                };
                sessionCtrl.onRtcError = function (event) {
                    LogSvc.error('[CircuitCallControlSvc]: Error collecting candidates. ', event.error);
                    terminate();
                    reject(event.error);
                };

                LogSvc.debug('[CircuitCallControlSvc]: testIceCandidates - Start collection');
                sessionCtrl.start({ audio: false }, null);
            });
        }

        function processReplacedCall(localCall, terminate) {
            if (!localCall || !localCall.replaces) {
                return;
            }
            var replaceCall = findCall(localCall.replaces.callId);
            if (replaceCall) {
                if (terminate) {
                    leaveCall(replaceCall, null, Enums.CallClientTerminatedReason.CALL_MOVED_TO_ANOTHER_CONV);
                }
            }
            // Inform the UI that call has been moved
            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/moved event');
            PubSubSvc.publish('/call/moved', [localCall.replaces.callId, localCall.callId]);

            // The /call/state event must be after /call/moved event (required by iOS)
            localCall.replaces = null;
            publishCallState(localCall);
        }

        function isVideoAndScreenShareEnabled(call) {
            // The feature should work if the one is supported by backend AND:
            // 1. it is a Circuit user AND:
            //    a. it is not a direct call
            //    b. it is not a Mobile client
            return !(call && call.isDirect) && !Utils.isMobile();
        }

        function isMidMappingEnabled() {
            return true;
        }

        function isValidVideoResolution(resolution) {
            if (!HD_VIDEO_RESOLUTIONS.includes(resolution)) {
                LogSvc.error('[CircuitCallControlSvc]: Invalid video resolution: ', resolution);
                return false;
            }
            return true;
        }

        function publishShowRatingDialog(evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/showRatingDialog event');
            PubSubSvc.publish('/call/showRatingDialog', [evt]);
        }

        function handleRtcError(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: handleRtcError: ', event.error);
            PubSubSvc.publish('/call/rtcError', [call, event.error]);
            if (call.checkState([Enums.CallState.Ringing, Enums.CallState.Answering])) {
                var rejectCause = Constants.InviteRejectCause.BUSY;
                decline(call, {type: rejectCause});
                return;
            }

            if (call.sameAs(_primaryLocalCall)) {
                if (event.error === 'res_CallMediaFailed') {
                    call.setDisconnectCause(Constants.DisconnectCause.NEGOTIATION_FAILED);
                    _that.endCallWithCauseCode(call.callId, Enums.CallClientTerminatedReason.ICE_TIMED_OUT);
                } else if (event.error === 'res_CallMediaDisconnected') {
                    call.setDisconnectCause(Constants.DisconnectCause.STREAM_LOST);
                    _that.endCallWithCauseCode(call.callId, Enums.CallClientTerminatedReason.LOST_MEDIA_STREAM);
                    // Since media connection has been lost, ping Access Server to check if websocket connection is still up
                    _clientApiHandler.pingServer();
                } else if (!call.isEstablished()) {
                    _that.endCall(call.callId);
                }
            }
        }

        /////////////////////////////////////////////////////////////////////////////
        // RtcSessionController event handlers
        /////////////////////////////////////////////////////////////////////////////
        function registerSessionController(call) {
            var sessionCtrl = call && call.sessionCtrl;
            if (!sessionCtrl) {
                LogSvc.error('[CircuitCallControlSvc]: Failed to get RtcSessionController for given call. ', call);
                return;
            }

            sessionCtrl.onIceCandidate = onIceCandidate.bind(null, call);
            // sessionCtrl.onSessionDescription is registered on-demand
            // sessionCtrl.sessionClosed is registered on demand
            sessionCtrl.onSdpConnected = onSdpConnected.bind(null, call);
            sessionCtrl.onIceConnected = onIceConnected.bind(null, call);
            sessionCtrl.onIceDisconnected = onIceDisconnected.bind(null, call);
            sessionCtrl.onRemoteVideoAdded = onRemoteVideoAdded.bind(null, call);
            sessionCtrl.onRemoteVideoRemoved = onRemoteVideoRemoved.bind(null, call);
            sessionCtrl.onRemoteStreamUpdated = onRemoteStreamUpdated.bind(null, call);
            sessionCtrl.onDTMFToneChange = onDTMFToneChange.bind(null, call);
            sessionCtrl.onRtcError = onRtcError.bind(null, call);
            sessionCtrl.onRtcWarning = onRtcWarning.bind(null, call);
            sessionCtrl.onLocalStreamEnded = onLocalStreamEnded.bind(null, call);
            sessionCtrl.onQosAvailable = onQosAvailable.bind(null, call);
            sessionCtrl.onStatsThresholdExceeded = onStatsThresholdExceeded.bind(null, call);
            sessionCtrl.onStatsNoOutgoingPackets = onStatsNoOutgoingPackets.bind(null, call);
            sessionCtrl.onNetworkQuality = onNetworkQuality.bind(null, call);
            sessionCtrl.onGetUserMediaException = onGetUserMediaException.bind(null, call);
        }

        function unregisterSessionController(call) {
            var sessionCtrl = call && call.sessionCtrl;
            if (sessionCtrl) {
                sessionCtrl.onIceCandidate = null;
                sessionCtrl.onSessionDescription = null;
                sessionCtrl.sessionClosed = null;
                sessionCtrl.onSdpConnected = null;
                sessionCtrl.onIceConnected = null;
                sessionCtrl.onIceDisconnected = null;
                sessionCtrl.onRemoteVideoAdded = null;
                sessionCtrl.onRemoteVideoRemoved = null;
                sessionCtrl.onRemoteStreamUpdated = null;
                sessionCtrl.onDTMFToneChange = null;
                sessionCtrl.onRtcError = null;
                sessionCtrl.onRtcWarning = null;
                sessionCtrl.onLocalStreamEnded = null;
                // Do not unregister handler for onQoSAvailable. The session controller will
                // automatically remove the handler after the stats are collected.
                sessionCtrl.onStatsThresholdExceeded = null;
                sessionCtrl.onStatsNoOutgoingPackets = null;
                sessionCtrl.onNetworkQuality = null;
            }
        }

        function onIceCandidate(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onIceCandidate');
            var data = {
                rtcSessionId: call.callId,
                userId: $rootScope.localUser.userId,
                origin: event.origin,
                candidates: event.candidates.map(JSON.stringify.bind(JSON))
            };

            var iceInfo = DeviceDiagnosticSvc.createActionInfo(call, Constants.RtcDiagnosticsAction.ICE_CANDIDATES);
            if (iceInfo) {
                iceInfo.data = data.candidates;
                iceInfo.isEndOfCandidates = event.endOfCandidates;
            }

            _clientApiHandler.sendIceCandidates(data, function (err) {
                DeviceDiagnosticSvc.finishActionInfo(call, iceInfo);
                if (err) {
                    LogSvc.warn('Failed to send ICE candidate: ', err);
                }
            });
        }

        function publishAtcCallInfo(call) {
            if (!call) {
                return;
            }
            // Notify the UI about the remote call
            LogSvc.debug('[CstaSvc]: Publish /atccall/info event');
            PubSubSvc.publish('/atccall/info', [call]);
        }

        function onSdpConnected(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onSdpConnected');
            $rootScope.$apply(function () {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/sdpConnected event');
                PubSubSvc.publish('/call/sdpConnected', [call]);

                var sdpConnectedInfo = DeviceDiagnosticSvc.createActionInfo(call, Constants.RtcDiagnosticsAction.SDP_CONNECTED);
                DeviceDiagnosticSvc.finishActionInfo(call, sdpConnectedInfo);

                if (call.isTelephonyCall) {
                    // For telephony calls the backend won't send events when SDP is connected.
                    // So set the call state to waiting here.
                    if (!call.state.established) {
                        call.setState(Enums.CallState.Waiting);
                    } 
                    call.updateCallState();
                    //publishAtcCallInfo(call);
                } else if (call.isDirect) {
                    call.setState(Enums.CallState.Active);
                }
                call.sdpOrigin = event.sdpOrigin;
                publishCallState(call);
                DeviceDiagnosticSvc.finishDeviceDiagnostics(call);
                if (call.instanceId) {
                    LogSvc.debug('[CircuitCallControlSvc]: InstanceId is available. Send call connected diagnostics data.');
                    DeviceDiagnosticSvc.sendCallConnected(call);
                } else {
                    LogSvc.debug('[CircuitCallControlSvc]: InstanceId is not available. Postpone sending call connected diagnostics data.');
                    call.callConnectedPostponed = true;
                }
            });
        }

        function onIceConnected(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onIceConnected. pcType:', event.pcType);
            $rootScope.$apply(function () {
                if (event.pcType === 'audio/video') {
                    if (call.isEstablished() && call.disconnectCause && call.disconnectCause.cause === Constants.DisconnectCause.STREAM_LOST) {
                        // Call reconnected
                        call.disconnectCause = null;
                    }
                    publishIceConnectionState(call, 'connected');
                }
            });
        }

        function onIceDisconnected(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onIceDisconnected. pcType:', event.pcType);
            $rootScope.$apply(function () {
                if (event.pcType === 'audio/video' && call.isEstablished() && !call.isHeld()) {
                    call.setDisconnectCause(Constants.DisconnectCause.STREAM_LOST);
                    publishIceConnectionState(call, 'disconnected');
                }
            });
        }

        function onRemoteVideoAdded(/*call*/) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onRemoteVideoAdded');
        }

        function onRemoteVideoRemoved(/*call*/) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onRemoteVideoRemoved');
        }

        function onRemoteStreamUpdated(call) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onRemoteStreamUpdated');
            $rootScope.$apply(function () {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/remoteStreamUpdated event');
                PubSubSvc.publish('/call/remoteStreamUpdated', [call]);
            });
        }

        function onDTMFToneChange(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onDTMFToneChange');

            $rootScope.$apply(function () {
                if (event.tone) {
                    LogSvc.debug('[CircuitCallControlSvc]: Sent DTMF Digit');
                    PubSubSvc.publish('/call/singleDigitSent', [call, event.tone]);
                } else if (event.tone === '') {
                    LogSvc.info('[CircuitCallControlSvc]: Digits played completely');
                    PubSubSvc.publish('/call/digitsSent', [call]);
                }
            });
        }

        function onRtcError(call, event) {
            $rootScope.$apply(function () {
                handleRtcError(call, event);
            });
        }

        function onRtcWarning(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onRtcWarning');
            $rootScope.$apply(function () {
                PubSubSvc.publish('/call/rtcWarning', [call, event.warning]);
            });
        }

        function onLocalStreamEnded(call) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onStreamEnded');
            if (_streamEndedTimeout) {
                // We're already processing an onLocalStreamEnded, so ignore this one
                return;
            }
            _streamEndedTimeout = $timeout(function () {
                _streamEndedTimeout = null;
                // Trigger a media renegotiation so a new device can be picked up
                _that.renegotiateMedia(call.callId, function (err) {
                    if (err) {
                        $rootScope.$apply(function () {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/localStreamEnded event');
                            PubSubSvc.publish('/call/localStreamEnded', [call]);
                        });
                    }
                });
            }, STREAM_ENDED_DELAY, false);
        }

        function onQosAvailable(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onQosAvailable');
            if (event.renegotiationInProgress) {
                call.terminateReason = Enums.CallClientTerminatedReason.MEDIA_RENEGOTIATION;
            }
            if (call.sessionCtrl) {
                if (call.qosSubmitted) {
                    LogSvc.info('[CircuitCallControlSvc]: QoS already submitted for call Id:', call.callId);
                    return;
                }
                InstrumentationSvc.sendQOSData(call, call.lastMediaType, event.qos, event.lastSavedStats, event.streamQualityData);
            }

            if (event.renegotiationInProgress) {
                call.terminateReason = null;
                // Update media devices only after the QoS is sent out (so it contains the correct devices)
                call.updateMediaDevices();
            }
        }

        function onStatsThresholdExceeded(call, event) {
            if (!_clientDiagnosticsDisabled) {
                LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onStatsThresholdExceeded. Cleared: ', !!(event && event.cleared));

                $rootScope.$apply(function () {
                    if (event && event.cleared) {
                        PubSubSvc.publish('/call/rtp/threshholdExceeded/cleared', [call.callId]);
                    } else {
                        PubSubSvc.publish('/call/rtp/threshholdExceeded/detected', [call.callId]);
                    }
                });
            }
        }

        function onStatsNoOutgoingPackets(call) {
            if (!call.isHeld()) {
                LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onStatsNoOutgoingPackets. Publish /call/rtp/noOutgoingPackets event');
                $rootScope.$apply(function () {
                    PubSubSvc.publish('/call/rtp/noOutgoingPackets', [call.callId]);
                });
            }
        }

        function onNetworkQuality(call, event) {
            if (!event) {
                return;
            }
            $rootScope.$apply(function () {
                var eventData = null;
                if (event.quality) {
                    eventData = event.quality.level;
                }
                call.networkQuality = eventData;
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/network/quality event for quality=', eventData);
                PubSubSvc.publish('/call/network/quality', [call.callId, eventData]);
            });
        }

        function onGetUserMediaException(call, event) {
            LogSvc.debug('[CircuitCallControlSvc]: RtcSessionController - onGetUserMediaException. Publish /call/getUserMediaException event');
            $rootScope.$apply(function () {
                PubSubSvc.publish('/call/getUserMediaException', [call, event.info]);
            });
        }

        function onActiveSpeakerEvent(evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.ACTIVE_SPEAKER');

                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                    return;
                }

                if (localCall.isDirect || localCall.participants.length < 2) {
                    LogSvc.debug('[CircuitCallControlSvc]: Ignore RTC.ACTIVE_SPEAKER event for 1-2-1 calls');
                    return;
                }

                var activeSpeakers = [];
                evt.first && activeSpeakers.push(evt.first);
                evt.second && activeSpeakers.push(evt.second);
                evt.third && activeSpeakers.push(evt.third);
                var hasChanged = localCall.setActiveSpeakers(activeSpeakers);

                if (!hasChanged) {
                    // No changes
                    return;
                }

                // The active speakers have changed. Raise an event to notify the UI
                // NOTE: Do not invoke $rootScope.$apply for this event. We will invoke a $scope.$digest in
                // uCallStage if the call stage is visible.
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participants/activeSpeakers event. ', activeSpeakers);
                PubSubSvc.publish('/call/participants/activeSpeakers', [localCall.callId, activeSpeakers]);
            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTC.ACTIVE_SPEAKER event. ', e);
            }
        }

        function onActiveVideoSpeakerEvent(evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.VIDEO_ACTIVE_SPEAKER');

                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                    return;
                }

                var participant = localCall.getParticipant(evt.userId);
                if (!participant) {
                    LogSvc.debug('[CircuitCallControlSvc]: Participant info not available. Ignoring VIDEO_ACTIVE_SPEAKER. userId=', evt.userId);
                    return;
                }

                if (participant.streamId === evt.videoStreamId) {
                    LogSvc.debug('[CircuitCallControlSvc]: Participant is already assigned to the received videoStreamId. Ignoring VIDEO_ACTIVE_SPEAKER');
                    return;
                }

                $rootScope.$apply(function () {
                    participant.streamId = evt.videoStreamId;
                    verifyStreamIds(localCall, participant);
                    localCall.setParticipantRemoteVideoStream(participant);
                    localCall.checkForActiveRemoteVideo();

                    LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event. userId =', participant.userId);
                    PubSubSvc.publish('/call/participant/updated', [localCall.callId, participant]);
                });
            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.VIDEO_ACTIVE_SPEAKER event.', e);
            }
        }

        function processInitActiveSession(session) {
            LogSvc.debug('[CircuitCallControlSvc]: Processing active session for convId = ', session.convId);

            if (!session.participants || session.participants.length === 0) {
                LogSvc.warn('[CircuitCallControlSvc]: The active session does not have participants. rtcSessionId = ', session.rtcSessionId);
                return;
            }

            var conversation = ConversationSvc.getConversationByRtcSession(session.rtcSessionId);
            if (!conversation || (!conversation.hasJoined && !conversation.isTemporary)) {
                return;
            }

            if (session.turnServers) {
                putSessionTurn(session.rtcSessionId, session.turnServers);
            }

            if (getIncomingCall(session.rtcSessionId)) {
                // Session correspond to an already ringing incoming call
                return;
            }

            var activeClient;
            var lostSession = false;
            session.participants.some(function (p) {
                if (p.userId === $rootScope.localUser.userId) {
                    activeClient = p;
                    if (p.clientId === _clientApiHandler.clientId) {
                        // Session for current client without local call
                        lostSession = true;
                    }
                    return true;
                }
                return false;
            });

            if (conversation.call) {
                var remoteCall = conversation.call;
                if (remoteCall.isRemote && conversation.isTemporary && activeClient) {
                    // Guest conversations might contain a placeholder remote call, so just update it
                    conversation.call.setState(Enums.CallState.ActiveRemote);
                    remoteCall.setActiveClient(activeClient);
                    addActiveRemoteCall(remoteCall);
                    remoteCall.mediaType = Proto.getMediaType(session.mediaTypes);
                    return;
                }
                // Somehow we ended up with a race condition
                LogSvc.debug('[CircuitCallControlSvc]: Conversation already has an associated call');
                return;
            }

            if (conversation.type === Constants.ConversationType.DIRECT && !session.hosted && !activeClient) {
                return;
            }

            if (lostSession) {
                var terminateCb = function (e) {
                    if (e) {
                        LogSvc.warn('[CircuitCallControlSvc] Lost session could not be terminated. Session Id=', session.rtcSessionId);
                    }
                };
                var disconnectCause = {
                    disconnectCause: Constants.DisconnectCause.CONNECTION_LOST,
                    disconnectReason: Constants.DisconnectReason.CALL_LOST
                };
                // Terminate lost session
                if (conversation.type === Constants.ConversationType.DIRECT) {
                    _clientApiHandler.terminateRtcCall(session.rtcSessionId, disconnectCause, terminateCb);
                    return;
                } else {
                    _clientApiHandler.leaveRtcCall(session.rtcSessionId, disconnectCause, terminateCb);
                }
            }

            // If remote call is active on another client
            addRemoteCall(conversation, activeClient, session, session.rtcSessionId, function (call) {
                if (activeClient) {
                    setPeerUserForTelephonyCall(call, session);
                }
            });
        }

        function setPeerUserForTelephonyCall(remoteCall, session) {
            if (remoteCall && remoteCall.isTelephonyCall && session &&
                session.participants && session.participants.length <= 2) {

                session.participants.some(function (p) {
                    if (p.participantType === Constants.RTCParticipantType.TELEPHONY) {
                        setCallPeerUser(remoteCall, p.phoneNumber, null, p.userDisplayName);
                        return true;
                    }
                    return false;
                });
            }
        }

        function hasLocalCall(session) {
            var foundLocalCall = false;
            var localCall = findLocalCallByCallId(session.rtcSessionId);
            if (localCall && session.participants) {
                foundLocalCall = session.participants.some(function (p) {
                    return p.userId === $rootScope.localUser.userId && p.clientId === _clientApiHandler.clientId;
                });
            }
            return foundLocalCall;
        }

        function processConvPromises(convPromises, resolve, reject) {
            LogSvc.info('[CircuitCallControlSvc]: Wait for get conversation promises to be resolved.');
            $q.all(convPromises)
            .then(function (convs) {
                var convIDs = convs.map(function (c) { return c.convId; });
                LogSvc.debug('[CircuitCallControlSvc]: Retrieved the missing conversation(s): ', convIDs);
                LogSvc.debug('[CircuitCallControlSvc]: Now that we have all the conversations, check if they still have active RTC sessions.');
                _clientApiHandler.getActiveSessions(function (err, activeSessions) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[CircuitCallControlSvc]: Error getting active sessions: ', err);
                            activeSessions = [];
                        }
                        activeSessions.forEach(function (s) {
                            if (convIDs.indexOf(s.convId) === -1) {
                                return;
                            }
                            if (!hasLocalCall(s)) {
                                processInitActiveSession(s);
                            }
                        });
                        resolve();
                    });
                });
            })
            .catch(function (err) {
                LogSvc.error('[CircuitCallControlSvc]: Failed to retrieve the conversation(s). ', err);
                reject(err);
            });
        }

        function initActiveSessions() {
            // Inject hasActiveCall API into ConnectionController
            _clientApiHandler.injectHasActiveCall(_that.hasActiveCall);

            return new $q(function (resolve, reject) {
                if (!_conversationLoaded) {
                    reject('Not ready to initialize active sessions');
                    return;
                }
                LogSvc.debug('[CircuitCallControlSvc]: Initialize active RTC sessions');

                _clientApiHandler.getActiveSessions(function (err, activeSessions) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[CircuitCallControlSvc]: Error getting active sessions: ', err);
                        }
                        activeSessions = activeSessions || [];

                        Utils.emptyArray(_activeRemoteCalls);
                        // Terminate any existing remote calls except temporary guests.
                        // They will be recreated if they are still there.
                        for (var i = _calls.length - 1; i >= 0; i--) { // Reverse since elements will be deleted
                            if (_calls[i].isRemote) {
                                _calls[i].guestToken ? _activeRemoteCalls.push(_calls[i]) : terminateCall(_calls[i]);
                            }
                        }

                        var conversation = null;
                        var foundLocalPrimaryCall = false;
                        var foundLocalSecondaryCall = false;
                        var convPromises = [];
                        activeSessions.forEach(function (s) {
                            var localCall = findLocalCallByCallId(s.rtcSessionId);
                            if (localCall) {
                                if (hasLocalCall(s)) {
                                    foundLocalPrimaryCall = foundLocalPrimaryCall || localCall === _primaryLocalCall;
                                    foundLocalSecondaryCall = foundLocalSecondaryCall || localCall === _secondaryLocalCall;
                                    LogSvc.info('[CircuitCallControlSvc]: Found localCall in active sessions. Trigger media renegotiation.');

                                    // Trigger a media renegotiation to refresh the ICE candidates
                                    _that.renegotiateMedia(localCall.callId, function () {
                                        PubSubSvc.publish('/call/sessionInitialized', [localCall]);
                                    });
                                    return;
                                }

                                // If it gets to this point is because there is an active session for this user but the
                                // client Id does not match the one of the local user (recent reconnection). Local call
                                // shall be cleaned up so the remote call is recreated.
                                terminateCall(localCall, Enums.CallClientTerminatedReason.LOST_WEBSOCKET_CONNECTION);
                            }

                            conversation = ConversationSvc.getConversationByRtcSession(s.rtcSessionId);
                            if (!conversation || !conversation.hasJoined) {
                                if (!conversation) {
                                    LogSvc.info('[CircuitCallControlSvc]: Conversation associated with session is not cached. Need to get it.');
                                    convPromises.push(ConversationSvc.getConversationPromise(s.convId));
                                }
                                return;
                            }

                            processInitActiveSession(s);
                        });

                        PubSubSvc.publish('/activeSessions/received');

                        if (_secondaryLocalCall && !foundLocalSecondaryCall) {
                            LogSvc.warn('[CircuitCallControlSvc]: Terminating secondaryLocalCall with id: ', _secondaryLocalCall.callId);
                            terminateCall(_secondaryLocalCall, Enums.CallClientTerminatedReason.LOST_WEBSOCKET_CONNECTION);
                        }
                        if (_primaryLocalCall && !foundLocalPrimaryCall) {
                            LogSvc.warn('[CircuitCallControlSvc]: Terminating primaryLocalCall with id: ', _primaryLocalCall.callId);
                            terminateCall(_primaryLocalCall, Enums.CallClientTerminatedReason.LOST_WEBSOCKET_CONNECTION);
                        }

                        if (convPromises.length > 0) {
                            processConvPromises(convPromises, resolve, reject);
                        } else {
                            resolve();
                        }
                    });
                });
            });
        }

        function updateCall(newConversation, oldConversation, call) {
            // Create a temporary call object with old rtc session id to raise call ended event to UI
            // The "replaced" flag is set to true in the ended message so that the
            // mobile client knows this is part of a call update.
            var oldCall;
            if (call.isRemote) {
                oldCall = new RemoteCall(oldConversation);
            } else {
                oldCall = new LocalCall(oldConversation, {clientId: call.clientId});
            }
            oldConversation.call = oldCall;

            // Terminate the call to clear its resources.
            oldCall.terminate();

            // End temporary call associated to old coversation
            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/ended event');
            PubSubSvc.publish('/call/ended', [oldCall, true]);

            oldConversation.call = null;

            // Update old conversation
            publishConversationUpdate(oldConversation);

            call.updateCall(newConversation);

            newConversation.call = call;

            // Update new conversation
            publishConversationUpdate(newConversation);

            // Inform the UI that call has been moved
            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/moved event');
            PubSubSvc.publish('/call/moved', [oldCall.callId, call.callId]);

            // The /call/state event must be after /call/moved event (required by iOS)
            publishCallState(call);
        }

        function sendChangeMediaReject(callId, transactionId) {
            var changeMediaRejectData = {
                rtcSessionId: callId,
                transactionId: transactionId
            };
            _clientApiHandler.changeMediaReject(changeMediaRejectData, function (err) {
                if (err) {
                    LogSvc.error('[CircuitCallControlSvc]: changeMediaReject sending error');
                } else {
                    LogSvc.debug('[CircuitCallControlSvc]: changeMediaReject sent');
                }
            });
        }

        function presentIncomingCall(conversation, userId, doNotPublishEvent) {
            if (!doNotPublishEvent) {
                if (conversation.call.pickupNotification) {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /call/pickupNotification event');
                    PubSubSvc.publish('/call/pickupNotification', [conversation.call]);
                } else {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /call/incoming event');
                    PubSubSvc.publish('/call/incoming', [conversation.call]);
                }
            }
            if (NotificationSvc) {
                var notificationType = conversation.call.mediaType.video ? Enums.NotificationType.INCOMING_VIDEO_CALL : Enums.NotificationType.INCOMING_VOICE_CALL;
                var notification = {
                    type: notificationType,
                    userId: userId,
                    extras: {
                        conversation: conversation,
                        call: conversation.call
                    }
                };
                if (conversation.isTelephony && conversation.call.peerUser) {
                    // For telephony calls, the userId should be the one resolved by lookup is any.
                    notification.userId = conversation.call.peerUser.userId;
                    if (!notification.userId) {
                        // If not resolved provide user object to satisfy notification service.
                        notification.user = conversation.call.peerUser;
                    }
                    // Original calling phone number may be overwritten in the user object
                    notification.extras.phoneNumber = conversation.call.peerUser.phoneNumber;
                    notification.extras.displayName = conversation.call.peerUser.displayName;
                }
                NotificationSvc.show(notification);
            }

            conversation.call.startRingingTimer(function () {
                decline(conversation.call, {type: Constants.InviteRejectCause.TIMEOUT}, false);
            });
        }

        function setRedirectingUserFromTelephonyInfo(incomingCall, telephonyInfo) {
            if (telephonyInfo && telephonyInfo.redirectingUser) {
                var redirectingUser = telephonyInfo.redirectingUser;
                setRedirectingUser(incomingCall, redirectingUser.phoneNumber, redirectingUser.fullyQualifiedNumber,
                    redirectingUser.displayName, RedirectionTypes.CallForward);
            }
        }

        function setRedirectingUserFromPickupNotification(existingCall, incomingCall) {
            if (existingCall && incomingCall) {
                var redirectingUser = existingCall.getRedirectingUser();
                if (!incomingCall.atcCallInfo) {
                    incomingCall.atcCallInfo = new AtcCallInfo();
                    incomingCall.setCstaState(Enums.CstaCallState.Active);
                }
                incomingCall.setRedirectingUser(redirectingUser.phoneNumber, redirectingUser.fullyQualifiedNumber,
                    redirectingUser.displayName, redirectingUser.userId, RedirectionTypes.CallPickedUp);
                incomingCall.pickUpFromUser = redirectingUser;
            }
        }

        function processInviteWithLocalCall(evt, conversation, data) {
            if (!_primaryLocalCall) {
                return true;
            }
            if (_primaryLocalCall.callId === evt.replaces) {
                // Save the muted state now, because we'll lose this information if the replaced call
                // is terminated before we answer the new _primaryLocalCall
                data.userIsMuted = _primaryLocalCall.sessionCtrl && _primaryLocalCall.sessionCtrl.isMuted();
                data.replaces = _primaryLocalCall;
            } else if (_primaryLocalCall.terminateTimer) {
                // There's a soon-to-be-terminated local call
                if (_primaryLocalCall.callId === evt.sessionId) {
                    LogSvc.debug('[CircuitCallControlSvc]: Reject incoming call. Current call still not terminated');
                    sendBusy(evt);
                    return false;
                }
                // The new call is for a different conversation. Terminate the current call.
                terminateCall(_primaryLocalCall);
                // Keep handling the INVITE event
            } else {
                if (_primaryLocalCall.callId === evt.sessionId) {
                    if (_primaryLocalCall.isDirect) {
                        LogSvc.debug('[CircuitCallControlSvc]: INVITE for same local call. Reject it');
                        sendBusy(evt);
                    } else {
                        LogSvc.warn('[CircuitCallControlSvc]: INVITE for same conversation as local call. Ignore it.');
                    }
                    return false;
                }
                if (!_primaryLocalCall.isEstablished() &&
                    !(evt.pickUpSession && evt.pickUpSession === _primaryLocalCall.callId) &&
                    !(conversation.isTelephony && !isAtcDefaultBusyHandlingSelected())) {
                    // Cannot accept the second incoming call - if the second incoming call is a telephony call and default
                    // busy handling is not selected, the second call should be handled by CSTA
                    LogSvc.debug('[CircuitCallControlSvc]: Reject incoming call. Current call still not established');
                    sendBusy(evt);
                    return false;
                }
            }
            return true;
        }

        function checkTelephonyTimers(evt, data) {
            if (evt.to && evt.to.displayName && evt.to.displayName.startsWith('handover-from')) {
                LogSvc.debug('[CircuitCallControlSvc]: Received handover call');
                if (_handoverTimer) {
                    data.autoAnswer = true;
                    $timeout.cancel(_handoverTimer);
                    _handoverTimer = null;
                } else {
                    LogSvc.warn('[CircuitCallControlSvc]: INVITE for handover call from another client. Ignore it.');
                    data.replacesActiveRemote = true;
                }
            } else if (_pickupTimer) {
                data.autoAnswer = true;
                $timeout.cancel(_pickupTimer);
                _pickupTimer = null;
            }
        }

        function processInvite(evt, conversation) {
            if (!evt || !conversation) {
                LogSvc.error('[CircuitCallControlSvc]: processInvite called without evt or conversation');
                return;
            }

            var data = {
                autoAnswer: false,
                callPickedUp: false,
                replaces: null,
                replacesActiveRemote: false,
                userIsMuted: false
            };

            if (evt.replaces && getActiveRemoteCall(evt.replaces)) {
                data.replacesActiveRemote = true;
            } else if (!processInviteWithLocalCall(evt, conversation, data)) {
                return;
            }

            if (conversation.isTelephony) {
                checkTelephonyTimers(evt, data);
            } else if (_lastEndedCall && _lastEndedCall.callId === evt.replaces) {
                data.userIsMuted = _wasMuted;
                data.replaces = _lastEndedCall;
                _lastEndedCall = null;
            }

            var found = getIncomingCall(evt.sessionId);
            if (found) {
                LogSvc.warn('[CircuitCallControlSvc]: INVITE for existing ringing call. Ignore it.');
                return;
            }

            // Reject 2nd incoming call
            if (!data.replacesActiveRemote && !evt.pickUpSession && !data.replaces && _incomingCalls.length >= 1
                 && !data.autoAnswer && !(conversation.isTelephony && !isAtcDefaultBusyHandlingSelected())) {
                // if the second incoming call is a telephony call and default busy handling is not selected,
                // the second call should be handled by CSTA
                LogSvc.debug('[CircuitCallControlSvc]: Reject second incoming call.');
                sendBusy(evt);
                return;
            }

            if (!processIncomingTelephonyCall(evt, conversation, data)) {
                return;
            }

            var incomingCall = createIncomingCall(evt, conversation, data);
            handleIncomingCall(evt, conversation, incomingCall, data);
        }

        function processIncomingTelephonyCall(evt, conversation, data) {
            if (conversation.isTelephony && !data.autoAnswer && !data.replacesActiveRemote) {
                if ($rootScope.localUser.selectedRoutingOption === RoutingOptions.DeskPhone.name && $rootScope.localUser.isATC) {
                    publishAtcCall(conversation, evt, true);
                    return false;
                }
                if (!$rootScope.localUser.selectedBusyHandlingOption || $rootScope.localUser.selectedBusyHandlingOption === BusyHandlingOptions.DefaultRouting.name ||
                    $rootScope.localUser.isSTC) {
                    if (_primaryLocalCall && _primaryLocalCall.isPresent() && _secondaryLocalCall && _secondaryLocalCall.isPresent()) {
                        LogSvc.debug('[CircuitCallControlSvc]: Reject incoming call. We cannot handle more than 2 local phone calls');
                        sendBusy(evt); // We can't handle more than 2 local phone calls, reject it
                        return false;
                    }
                } else if (((_primaryLocalCall && _primaryLocalCall.isTelephonyCall) || _incomingCalls.length >= 1 || !Utils.isEmptyArray(_activeRemoteCalls)) ||
                    (conversation.call && conversation.call.isAtcRemote && !conversation.call.pickupNotification)) {
                    publishAtcCall(conversation, evt, false);
                    return false;
                }
            }
            return true;
        }

        function createIncomingCall(evt, conversation, data) {
            var options = {
                // For OSBiz, only one media negotation is done for pull.
                isAtcPullCall: conversation.isTelephony && data.autoAnswer && evt.sdp.type === 'offer' && !_callToPickup && !$rootScope.localUser.isOsBizCTIEnabled,
                clientId: _clientApiHandler.clientId,
                midMappingEnabled: isMidMappingEnabled(),
                isSTC: $rootScope.localUser.isSTC && conversation.isTelephony,
                stcCapabilities: conversation.isTelephony ? $rootScope.localUser.stcCapabilities : [],
                canReceiveHdVideo: $rootScope.localUser.canUseHDVideo || $rootScope.localUser.canUseHDScreenShare
            };
            if (data.replaces && data.replaces.localMediaType.desktop) {
                // This call should reuse the desktop stream from the call it's replacing (option used by VDI)
                options.reuseDesktopStreamFrom = data.replaces.sessionCtrl;
            }
            var incomingCall = new LocalCall(conversation, options);
            incomingCall.setCallIdForTelephony(evt.sessionId);
            incomingCall.setInstanceId(evt.instanceId);
            incomingCall.setTransactionId(evt.transactionId);

            if (_primaryLocalCall && evt.pickUpSession === _primaryLocalCall.callId) {
                data.replaces = _primaryLocalCall;
                data.replaces.pickUpUserId = evt.userId;
                incomingCall.pickUpFromUser = data.replaces.peerUser;
            }

            if (data.replaces) {
                incomingCall.replaces = data.replaces;

                var oldStream = data.replaces.sessionCtrl.getLocalStream(RtcSessionController.LOCAL_STREAMS.DESKTOP);
                if (oldStream) {
                    LogSvc.debug('[CircuitCallControlSvc] Reusing desktop stream from call ID=', data.replaces.callId);
                    incomingCall.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.DESKTOP, oldStream);
                    // Set old desktop stream to null so it won't be stopped by the old RtcSessionController
                    data.replaces.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.DESKTOP, null);
                }
                oldStream = data.replaces.sessionCtrl.getLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO);
                if (oldStream) {
                    LogSvc.debug('[CircuitCallControlSvc] Reusing audio/video stream from call ID=', data.replaces.callId);
                    incomingCall.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO, oldStream);
                    // Set old desktop stream to null so it won't be stopped by the old RtcSessionController
                    data.replaces.sessionCtrl.setLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO, null);
                }
            }
            if (_disableRemoteVideoByDefault) {
                LogSvc.info('[CircuitCallControlSvc] Disabling remote video for incoming call');
                incomingCall.disableRemoteVideo();
            }
            incomingCall.setState(Enums.CallState.Ringing);
            incomingCall.direction = Enums.CallDirection.INCOMING;
            if ($rootScope.localUser.isATC && incomingCall.isTelephonyCall) {
                incomingCall.atcCallInfo = new AtcCallInfo();

                if (evt.atcAdvancing) {
                    LogSvc.debug('[CircuitCallControlSvc] This ATC call has already advanced to the desk phone');
                    incomingCall.atcAdvancing = true;
                    incomingCall.sessionCtrl.setIgnoreNextConnection(true);
                }

                if (_callToPickup) {
                    incomingCall.atcCallInfo.setCstaConnection(_callToPickup.atcCallInfo.getCstaConnection());

                    var peerUser = _callToPickup.peerUser;
                    incomingCall.setPeerUser(peerUser.phoneNumber, peerUser.displayName, peerUser.userId, null, peerUser);
                    var redirectingUser = _callToPickup.getRedirectingUser();
                    incomingCall.setRedirectingUser(redirectingUser.phoneNumber, redirectingUser.fullyQualifiedNumber,
                        redirectingUser.displayName, redirectingUser.userId, RedirectionTypes.CallPickedUp);

                    _callToPickup = null;
                    data.callPickedUp = true;
                }
            }
            return incomingCall;
        }

        function handleIncomingCall(evt, conversation, incomingCall, data) {
            // MediaType should contain 'AUDIO' by default
            var evtMediaType = evt.mediaType || ['AUDIO'];
            addParticipantToCallObj(incomingCall, normalizeApiParticipant({userId: evt.userId}, evtMediaType), Enums.ParticipantState.Calling);

            incomingCall.ringingTimeout = evt.ringingTimeout || incomingCall.ringingTimeout;

            var mediaType = Proto.getMediaType(evtMediaType);
            incomingCall.mediaType = mediaType;

            if (mediaType.desktop) {
                LogSvc.debug('[CircuitCallControlSvc]: INVITE for a Screen Share');
            }
            if (data.replacesActiveRemote) {
                // Do not show this call until answered
                addCallToList(incomingCall, true);
                return;
            }
            addCallToList(incomingCall);

            var onSessionWarmup = function () {
                $rootScope.$apply(function () {
                    // The warmup was successful, so we can continue processing the call
                    if (incomingCall.state === Enums.CallState.Terminated) {
                        LogSvc.debug('[CircuitCallControlSvc]: New alerting call has been terminated already');
                        return;
                    }

                    registerSessionController(incomingCall);
                    LogSvc.debug('[CircuitCallControlSvc]: New alerting call: ', incomingCall.callId);

                    if (conversation.call && !conversation.isTelephony) {
                        LogSvc.debug('[CircuitCallControlSvc]: Terminate the remote call and add the new local call to the conversation');
                        terminateCall(conversation.call);
                    }

                    var oldCallId = null;
                    if (conversation.call && conversation.call.isAtcRemote) {
                        oldCallId = conversation.call.callId;
                        if (conversation.call.pickupNotification && data.autoAnswer) {
                            setRedirectingUserFromPickupNotification(conversation.call, incomingCall);
                        }
                    }

                    conversation.call = incomingCall;
                    if (conversation.isTelephony && data.autoAnswer && !data.callPickedUp) {
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /atccall/replace event');
                        //In case /csta/handover event has arrived _callIdToBeReplaced has value, so use this id instead of oldCallId
                        oldCallId = _callIdToBeReplaced ? _callIdToBeReplaced : oldCallId;
                        _callIdToBeReplaced = null;
                        PubSubSvc.publish('/atccall/replace', [incomingCall, oldCallId]);
                    } else {
                        // Show new incoming call
                        publishConversationUpdate(conversation);
                    }

                    if (data.replaces) {
                        _that.answerCall(incomingCall.callId, data.replaces.localMediaType, function (err) {
                            if (!err) {
                                if (data.userIsMuted) {
                                    _primaryLocalCall.sessionCtrl && _primaryLocalCall.sessionCtrl.mute();
                                }
                            }
                        });
                        return;
                    }

                    if (data.autoAnswer) {
                        if (incomingCall.isTelephonyCall && evt.from && !data.callPickedUp) {
                            setCallPeerUser(incomingCall, evt.from.phoneNumber, evt.from.fullyQualifiedNumber, evt.from.displayName, null, function () {
                                setRedirectingUserFromTelephonyInfo(incomingCall, evt.telephonyInfo);
                            });
                        }
                        _that.answerCall(incomingCall.callId, mediaType);
                        return;
                    }

                    // Notify the server that we're alerting
                    var progressData = {
                        rtcSessionId: incomingCall.callId,
                        invitingUserId: evt.userId,
                        localUserId: $rootScope.localUser.userId
                    };

                    _clientApiHandler.sendProgress(progressData, function (err) {
                        if (err) {
                            LogSvc.error('[CircuitCallControlSvc]: sendProgress error - ', err);
                        }
                    });

                    if (incomingCall.isTelephonyCall && evt.from && !incomingCall.transferredOnRinging) {
                        setCallPeerUser(incomingCall, evt.from.phoneNumber, evt.from.fullyQualifiedNumber, evt.from.displayName, null, function () {
                            if (conversation.call === incomingCall) {
                                presentIncomingCall(conversation, evt.userId);
                            }
                            setRedirectingUserFromTelephonyInfo(incomingCall, evt.telephonyInfo);
                        });
                        return;
                    }

                    presentIncomingCall(conversation, evt.userId);
                });
            };

            var onWarmupFailed = function (err) {
                // Ignore the incoming call rather than rejecting it. The user may have other clients logged on that
                // support webrtc and have a mic, so ignoring this call is the correct action.
                LogSvc.warn('[CircuitCallControlSvc]: Warmup failed. Ignore the incoming call: ', err);
                PubSubSvc.publish('/call/warmupFailed', [err, incomingCall]);
                terminateCall(incomingCall, null, true);

                if (conversation.isTemporary) {
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /conversation/temporary/ended event');
                    PubSubSvc.publish('/conversation/temporary/ended', [conversation]);
                }
            };

            // Get the RtcSessionController object
            var sessionCtrl = incomingCall.sessionCtrl;
            var turnUris = getSessionTurnUris(conversation.rtcSessionId);

            if (!WebRTCAdapter.enabled) {
                LogSvc.warn('[CircuitCallControlSvc]: Client does not support WebRTC.');
                onWarmupFailed('res_NoWebRTC');
                return;
            }

            getTurnCredentials(incomingCall)
            .then(function (turnCredentials) {
                if (!incomingCall.isPresent()) {
                    LogSvc.debug('[CircuitCallControlSvc]: There is no incoming call anymore');
                    return;
                }
                if (turnUris) {
                    LogSvc.debug('[CircuitCallControlSvc]: Using TURN servers associated with session: ', turnUris);
                    sessionCtrl.setTurnUris(turnUris);
                } else {
                    LogSvc.debug('[CircuitCallControlSvc]: Using retrieved TURN servers');
                    sessionCtrl.setTurnUris(turnCredentials.turnServer);
                }
                sessionCtrl.setTurnCredentials(turnCredentials);
                LogSvc.debug('[CircuitCallControlSvc]: Set TURN credentials');

                // Warmup the connection to see if we can handle the call
                var localMediaType;
                if (incomingCall.replaces) {
                    localMediaType = incomingCall.replaces.localMediaType;
                } else {
                    // For Firefox we cannot access the getUserMedia API if the browser is inactive,
                    // so we need to bypass the getUserMedia warmup by not adding any media type.
                    var isFirefox = WebRTCAdapter.browser === 'firefox';
                    // For Android we need to bypass the getUserMedia warmup too
                    // because we should create new LocalMediaStream only when user answered a call.
                    var isAndroid = WebRTCAdapter.browser === 'android';

                    localMediaType = {audio: !isFirefox && !isAndroid && !_isCordova, video: false};
                }

                checkMediaSources(localMediaType, function (normalizedMediaType) {
                    sessionCtrl.warmup(normalizedMediaType, evt.sdp, onSessionWarmup, onWarmupFailed);
                });
            })
            .catch(onWarmupFailed);
        }

        function processActiveSessions(conversation, activeSessions) {
            if (!conversation || !activeSessions) {
                return;
            }

            if (!conversation.hasJoined && !conversation.isTemporary) {
                // This should not happen with the actual backend, but the mock also sends the
                // events for conversations the user is no longer a participant.
                LogSvc.debug('[CircuitCallControlSvc]: User is not a conversation participant. Ignore active session.');
                return;
            }

            activeSessions.some(function (s) {
                if (conversation.rtcSessionId === s.rtcSessionId) {
                    LogSvc.debug('[CircuitCallControlSvc]: Processing active session for convId = ', conversation.convId);

                    if (!s.participants || s.participants.length === 0) {
                        LogSvc.warn('[CircuitCallControlSvc]: The active session does not have participants. rtcSessionId = ', s.rtcSessionId);
                        return true;
                    }

                    var activeClient;
                    s.participants.some(function (p) {
                        if (p.userId === $rootScope.localUser.userId) {
                            activeClient = p;
                            return true;
                        }
                        return false;
                    });

                    if (conversation.type === Constants.ConversationType.DIRECT && !s.hosted && !activeClient) {
                        return true;
                    }
                    if (conversation.call) {
                        // Somehow we ended up with a race condition
                        LogSvc.debug('[CircuitCallControlSvc]: Conversation already has an associated call');
                        return true;
                    }

                    addRemoteCall(conversation, activeClient, s, null, function (call) {
                        if (activeClient) {
                            setPeerUserForTelephonyCall(call, s);
                        } else if (call.conferenceCall) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /conference/started event');
                            PubSubSvc.publish('/conference/started', [call]);
                        }
                    });
                    return true;
                }
                return false;
            });
        }

        function sendDTMFDigits(callId, digits, cb) {
            var localCall = findLocalCallByCallId(callId);

            if (!localCall) {
                LogSvc.info('[CircuitCallControlSvc]: sendDTMFDigits - The given call ID does not match the local call');
                cb('Invalid callId');
                return;
            }

            LogSvc.info('[CircuitCallControlSvc]: Sending DTMF Digits. Num of digits: ', digits && digits.length);

            if (!localCall.sessionCtrl.sendDTMFDigits(digits)) {
                LogSvc.error('[CircuitCallControlSvc]: Cannot send DTMF digits');
                cb('res_CannotSendDTMF');
            } else {
                cb();
            }
        }

        function checkMediaSourcesAndJoin(conversation, mediaType, options, cb) {
            // Clone mediaType object before changing it
            mediaType = Object.assign({}, mediaType);

            checkMediaSources(mediaType, function (normalizedMediaType, warning) {
                if (warning) {
                    if (conversation.isTelephony) {
                        // Don't allow phone call to continue if we cannot access a microphone
                        LogSvc.warn('[CircuitCallControlSvc]: Phone calls are not possible without a microphone. Terminate local call.');
                        terminateCall(_primaryLocalCall);
                        cb('res_MakeCallFailedNoMic');
                        return;
                    }
                    options.warning = warning;
                }
                joinSession(conversation, normalizedMediaType, options, cb);
            });
        }

        function checkMediaSources(mediaType, cb) {
            adjustMediaTypeConstraints(mediaType);
            LogSvc.debug('[CircuitCallControlSvc]: Check media sources for mediaType = ', mediaType);

            var warning;
            mediaType = mediaType ? Utils.shallowCopy(mediaType) : {audio: true, video: false};
            if (_isMobile || _isCordova || (!mediaType.audio && !mediaType.video)) {
                LogSvc.debug('[CircuitCallControlSvc]: Normalized mediaType = ', mediaType);
                cb(mediaType);
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: Get audio and video media sources');
            WebRTCAdapter.getMediaSources(function (audioSources, videoSources) {
                if (mediaType.video && (!videoSources || videoSources.length < 1)) {
                    LogSvc.warn('[CircuitCallControlSvc]: No camera detected');
                    mediaType.video = false;
                    warning = 'res_AnswerWarnNoCamera';
                }
                if (mediaType.audio && (!audioSources || audioSources.length < 1)) {
                    mediaType.audio = false;
                    LogSvc.warn('[CircuitCallControlSvc]: No microphone detected');
                    if (warning === 'res_AnswerWarnNoCamera') {
                        warning = 'res_AccessToMediaInputDevicesFailed';
                    } else {
                        warning = 'res_AccessToAudioInputDeviceFailedWarning';
                    }
                }
                LogSvc.debug('[CircuitCallControlSvc]: Normalized mediaType = ', mediaType);
                cb(mediaType, warning);
            });
        }

        function dismissNotification(call) {
            // Dismiss incoming call notification if present
            if (call && call.activeNotification && !call.isHandoverInProgress) {
                NotificationSvc && NotificationSvc.dismiss(call.activeNotification);
                call.activeNotification = null;
            }
        }

        function disableAllAudio(callId) {
            // Mute the local user
            _that.mute(callId);
            // Mute the remote audio stream
            _that.disableRemoteAudio(callId);
        }

        function addParticipant(call, participant, cb) {
            cb = cb || NOP;
            if (!call || !participant) {
                LogSvc.error('[CircuitCallControlSvc]: addParticipant - Invalid arguments');
                cb('Invalid arguments');
                return;
            }
            if (call.isDirect && !call.upgradeToConfSupported) {
                LogSvc.error('[CircuitCallControlSvc]: addParticipant - Cannot upgrade call to conference');
                cb('Cannot upgrade to conference');
                return;
            }

            var dialOutPhoneNumber = participant.dialOutPhoneNumber;
            var contact = !dialOutPhoneNumber ? participant : {
                userId: 'gtc-callout-' + dialOutPhoneNumber.replace(/[+ ]+/g, ''),
                phoneNumber: Utils.cleanPhoneNumber(dialOutPhoneNumber),
                displayName: participant.displayName,
                resolvedUserId: participant.userId,
                participantType: Constants.RTCParticipantType.TELEPHONY,
                pstnDialIn: false
            };

            if (!contact.userId) {
                LogSvc.warn('[CircuitCallControlSvc]: addParticipant - Participant missing userId');
                cb('Missing userId');
                return;
            }
            if (call.hasParticipant(contact.userId) || (contact.resolvedUserId && call.hasParticipant(contact.resolvedUserId))) {
                LogSvc.info('[CircuitCallControlSvc]: addParticipantTo - Participant is already in the call');
                cb('Participant exists');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: Adding participant to existing RTC session. userId =', contact.userId);
            // Audio must be enabled for invited users.
            var mediaType = Utils.shallowCopy(call.mediaType);
            mediaType.audio = true;
            var data = {
                rtcSessionId: call.callId,
                userId: contact.userId,
                mediaType: mediaType
            };
            if (dialOutPhoneNumber) {
                data.from = {
                    phoneNumber: Utils.cleanPhoneNumber($rootScope.localUser.callerId),
                    displayName: $rootScope.localUser.displayName
                };
                data.to = {
                    phoneNumber: contact.phoneNumber,
                    displayName: contact.displayName,
                    resolvedUserId: contact.resolvedUserId
                };
            }

            _clientApiHandler.addParticipantToRtcSession(data, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        if (err === Constants.ErrorCode.PERMISSION_DENIED) {
                            LogSvc.info('[CircuitCallControlSvc]: Failed to add participant. Reached conference participants limit');
                            cb('res_MaxConversationParticipantsLimit');
                            return;
                        }
                        LogSvc.warn('[CircuitCallControlSvc]: Failed to add participant');
                        cb('res_AddParticipantToRtcSessionFailed');
                    } else {
                        LogSvc.debug('[CircuitCallControlSvc]: Add participant request was successful');
                        // Set direction to outgoing
                        call.direction = Enums.CallDirection.OUTGOING;

                        if (!call.isRemote) {
                            // Add participant to call stage
                            var normalizedParticipant = normalizeApiParticipant(contact, ['AUDIO']);
                            var pState = Enums.ParticipantState.Initiated;
                            var addedParticipant = addParticipantToCallObj(call, normalizedParticipant, pState);

                            if (addedParticipant) {
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/added event');
                                PubSubSvc.publish('/call/participant/added', [call.callId, addedParticipant]);
                            }
                        }
                        cb();
                    }
                });
            });
        }

        function publishMutedEvent(call, requesterId) {
            var requester = requesterId ? call.getParticipant(requesterId) : null;

            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/localUser/muted event');
            PubSubSvc.publish('/call/localUser/muted', [call.callId, call.remotelyMuted, call.locallyMuted, requester]);

            if (requester && call.remotelyMuted && NotificationSvc) {
                NotificationSvc.show({
                    type: Enums.NotificationType.HIGH_PRIORITY,
                    user: $rootScope.localUser,
                    extras: {
                        title: $rootScope.i18n.map.res_Muted,
                        text: $rootScope.i18n.localize('res_MutedByNotification', [requester.displayName])
                    }
                });
            }
        }

        function canInitiateCall(conversation, options, cb) {
            cb = cb || NOP;
            if (_incomingCalls.length >= 1) {
                cb('res_CannotJoinMultipleSessions');
                return false;
            }
            var primaryCallPresent = _primaryLocalCall && _primaryLocalCall.isPresent();

            if (primaryCallPresent) {
                if (_primaryLocalCall.isTelephonyCall) {
                    if (!conversation.isTelephony || !$rootScope.localUser.isRegisterTC) {
                        cb('res_CannotJoinMultipleSessions');
                        return false;
                    }
                    if (_secondaryLocalCall) {
                        cb('res_CannotInitiateThirdPhoneCall');
                        return false;
                    }
                } else if (!options.replaces) {
                    cb('res_CannotJoinMultipleSessions');
                    return false;
                }
            }

            for (var idx = 0; idx < _calls.length; idx++) {
                if (!_calls[idx].state.established && _calls[idx].isTelephonyCall) {
                    LogSvc.error('[CircuitCallControlSvc]: Cannot initiate second phone call if existing call is not established');
                    cb('res_JoinRTCSessionFailed');
                    return false;
                }
            }

            if (conversation.isTelephony) {
                if (!options.handover) {
                    options.dialedDn = options.dialedDn ? options.dialedDn.trim() : null;
                    if (!options.dialedDn) {
                        cb('Missing Dialed DN');
                        return false;
                    }
                }
                if (!$rootScope.localUser.callerId) {
                    cb('Missing Caller ID');
                    return false;
                }
                if ($rootScope.localUser.isOsBizCTIEnabled && !options.handover && conversation.call) {
                    // If OsBiz CTI, do not allow initiating a second phone call
                    cb('res_CannotJoinMultipleSessions');
                    return false;
                }
            }
            return true;
        }

        function createCallOptions(options, additionalOptions) {
            options = options || {};
            additionalOptions && Object.assign(options, additionalOptions);
            options.callOut = !!options.callOut;
            options.handover = !!options.handover;
            return options;
        }

        function addRemoveMedia(callId, mediaToChange, logMethodName, options) {
            mediaToChange = mediaToChange || {};
            logMethodName = logMethodName || 'addRemoveMedia';
            options = options || {};

            var logTopic = '[CircuitCallControlSvc]: ' + logMethodName + ': ';
            var localCall = findLocalCallByCallId(callId);
            var incomingCall = null;
            var currentMediaType = {};

            if (!localCall) {
                incomingCall = getIncomingCall(callId);
                if (!incomingCall || incomingCall.callId !== callId) {
                    LogSvc.warn(logTopic + 'There is no local call');
                    return $q.reject('No active call');
                }
                currentMediaType = incomingCall.mediaType;
            } else {
                currentMediaType = localCall.localMediaType;
            }

            LogSvc.debug(logTopic, mediaToChange);

            var data = {
                callId: callId,
                dontReuseAudioStream: !!options.dontReuseAudioStream,
                mediaType: Object.assign({}, currentMediaType, mediaToChange)
            };

            // When adding/removing video or screenshare we need to reset the corresponding HD settings, so
            // they are properly initialized in adjustMediaTypeConstraints().
            if (typeof mediaToChange.video === 'boolean' && typeof mediaToChange.hdVideo !== 'boolean') {
                delete data.mediaType.hdVideo;
            }
            if (typeof mediaToChange.desktop === 'boolean' && typeof mediaToChange.hdDesktop !== 'boolean') {
                delete data.mediaType.hdDesktop;
            }

            if (!isVideoAndScreenShareEnabled(localCall)) {
                // Only support either video or desktop
                if (mediaToChange.video) {
                    data.mediaType.desktop = false;
                } else if (mediaToChange.desktop) {
                    data.mediaType.video = false;
                }
            }

            return new $q(function (resolve, reject) {
                if (incomingCall) {
                    // This is an incoming call so we can only re-warmup the session by getting the local media again
                    checkMediaSources(data.mediaType, function (normalizedMediaType) {
                        // To force a getUserMedia, set the current local stream to null
                        incomingCall.sessionCtrl.replaceLocalStream(RtcSessionController.LOCAL_STREAMS.AUDIO_VIDEO, null);
                        incomingCall.sessionCtrl.warmup(normalizedMediaType, null, function () {
                            LogSvc.debug(logTopic + 're-warmup succeeded');
                            resolve();
                        }, function (err) {
                            LogSvc.warn(logTopic + 're-warmup failed - ', err);
                            reject(err);
                        });
                    });
                } else {
                    changeMediaType(data, function (err) {
                        if (err) {
                            LogSvc.warn(logTopic + 'failed - ', err);
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                }
            });
        }

        /**
         * Changes the video receiving configuration.
         *
         * A receiver configuration contains the following fields:
         *  - id         (required) the stream ID of the receiving line, the same as used in the participant data, so either MID or MSID
         *  - quality    (optional) one of Constants.VideoQuality.[LOW, NORMAL, HIGH]
         *  - pinnedUser (optional) ID of user to pin to this line (will not get switched by active speaker)
         *  - disabled   (optional) true if receiving line should not be used (e.g. to turn off incoming video)
         *
         * so e.g. {id: '1', quality: 'NORMAL', pinnedUser: '6e725181-109e-4858-8e10-6b21284fe619'}
         *
         * @param {String} callId the ID of the call
         * @param {Array} configuration an array of receiver configurations
         * @returns {Promise} Promise that resolves when operation is completed.
         */
        function setVideoReceiverConfiguration(callId, configuration) {
            var activeCall = _that.getActiveCall();
            if (!activeCall || activeCall.callId !== callId) {
                return $q.reject('Call not found');
            }
            if (!configuration || !configuration.length) {
                return $q.reject('Invalid configuration');
            }
            if (_pendingSetVideoReceiverConfiguration && _pendingSetVideoReceiverConfiguration.callId === callId) {
                _pendingSetVideoReceiverConfiguration.configuration = configuration;
            } else {
                if (_pendingSetVideoReceiverConfiguration) {
                    // Cancel pending request for different call
                    _pendingSetVideoReceiverConfiguration.cancel();
                }
                var pendingConfiguration = {
                    callId: callId,
                    configuration: configuration
                };
                pendingConfiguration.promise = new $q(function (resolve, reject) {
                    // Timeout is used here to avoid sending too many backend requests in case multiple
                    // changes are done in a short time (e.g. when switching UI layout).
                    pendingConfiguration.timeout = $timeout(function () {
                        if (_pendingSetVideoReceiverConfiguration !== pendingConfiguration) {
                            return;
                        }
                        _pendingSetVideoReceiverConfiguration = null;
                        LogSvc.debug('[CircuitCallControlSvc]: Set video receiver configuration to ', pendingConfiguration.configuration);
                        var data = {
                            rtcSessionId: callId,
                            configuration: pendingConfiguration.configuration
                        };
                        _clientApiHandler.setVideoReceiverConfiguration(data, function (err) {
                            $rootScope.$apply(function () {
                                err ? reject(err) : resolve();
                            });
                        });
                    }, SET_VIDEO_RECEIVER_CONFIGURATION_DELAY, false);

                    pendingConfiguration.cancel = function () {
                        if (_pendingSetVideoReceiverConfiguration !== pendingConfiguration) {
                            return;
                        }
                        _pendingSetVideoReceiverConfiguration = null;
                        LogSvc.debug('[CircuitCallControlSvc] Canceling pending setVideoReceiverConfiguration request');
                        reject('Cancelled');
                        $timeout.cancel(pendingConfiguration.timeout);
                    };
                });
                _pendingSetVideoReceiverConfiguration = pendingConfiguration;
            }
            return _pendingSetVideoReceiverConfiguration.promise;
        }

        function handleInviteEvent(evt) {
            try {
                var conversation = getConversation(evt.convId);
                if (!conversation) {
                    LogSvc.info('[CircuitCallControlSvc]: Could not find corresponding conversation. Save pending INVITE and retrieve conversation.');
                    // Save the INVITE event and get the conversation
                    var sessionId = evt.sessionId;
                    _pendingInvites[sessionId] = evt;
                    var options = {randomLoaded: true, sessionId: evt.sessionId};
                    ConversationSvc.getConversationById(evt.convId, options, function (getConvErr, conv) {
                        if (getConvErr) {
                            LogSvc.warn('[CircuitCallControlSvc]: Could not retrieve corresponding conversation. Delete Pending Invite');
                        } else if (_pendingInvites[sessionId]) {
                            LogSvc.info('[CircuitCallControlSvc]: Successfully retrieved conversation. Process the pending INVITE message.');
                            processInvite(_pendingInvites[sessionId], conv);
                        } else {
                            LogSvc.info('[CircuitCallControlSvc]: Successfully retrieved conversation. Check if there are any active sessions.');
                            _clientApiHandler.getActiveSessions(function (getSessionsErr, activeSessions) {
                                $rootScope.$apply(function () {
                                    if (getSessionsErr) {
                                        LogSvc.error('[CircuitCallControlSvc]: Error getting active sessions: ', getSessionsErr);
                                        activeSessions = [];
                                    }
                                    processActiveSessions(conv, activeSessions);
                                });
                            });
                        }
                        delete _pendingInvites[sessionId];
                    });
                    return;
                }

                if (!conversation.hasJoined) {
                    // This should not happen with the actual backend, but the mock also sends the
                    // events for conversations the user is no longer a participant.
                    LogSvc.info('[CircuitCallControlSvc]: User is not a conversation participant. Ignore event.');
                    return;
                }

                $rootScope.$apply(function () {
                    processInvite(evt, conversation);
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCCall.INVITE event. ', e);
            }
        }

        function updateHosted(evt, call) {
            if (evt.session.hosted && call.isDirect) {
                call.setDirectUpgradedToConf();
            } else if (call.isDirectUpgradedToConf && !evt.session.hosted) {
                call.clearDirectUpgradedToConf();
            }
        }

        function addRemoteCallForSessionUpdate(evt, activeClient) {
            var getConversationPromise;
            var conversation = ConversationSvc.getConversationByRtcSession(evt.sessionId);
            if (!conversation) {
                LogSvc.warn('[CircuitCallControlSvc]: Could not find corresponding conversation');
                return;
            } else {
                getConversationPromise = $q.resolve(conversation);
            }

            if (activeClient.clientId === _clientApiHandler.clientId) {
                LogSvc.warn('[CircuitCallControlSvc]: Event is for a local call that has been terminated. Ignore event.');
                return;
            }

            _pendingRemoteCalls[evt.sessionId] = evt;
            getConversationPromise
            .then(function (resolvedConv) {
                if (_pendingRemoteCalls[evt.sessionId]) {
                    addRemoteCall(resolvedConv, activeClient, evt.session, evt.sessionId, function (call) {
                        // When user rejects or doesn't answer direct call and voicemail is enabled,
                        // SESSION_UPDATED event triggers, user stays on call, but in header there shouldn't be
                        // any buttons. So call should be marked as voicemailConnected, and that property is used for hiding
                        // addParticipant and more buttons in call header.
                        call.voicemailConnected = evt.session.participants.some(function (p) {
                            return p.participantType === Constants.RTCParticipantType.VOICE_MAIL;
                        });

                        setPeerUserForTelephonyCall(call, evt.session);
                    });
                    delete _pendingRemoteCalls[evt.sessionId];
                }
            })
            .catch(function (err) {
                LogSvc.warn('[CircuitCallControlSvc]: Could not retrieve guest conversation. Remote call will not be displayed.', err);
            });
        }

        function handleMovedCallEvent(evt, activeClient, localCall) {
            var conversation = getConversation(localCall.convId);
            var getConversationPromise = $q.resolve(conversation);

            var phoneNumber = null;
            var displayName = null;
            var participants = null;
            if (localCall.isTelephonyCall) {
                phoneNumber = localCall.peerUser.phoneNumber;
                displayName = localCall.peerUser.displayName;
                participants = localCall.participants;
            }

            var atcCallInfo = localCall.atcCallInfo;
            var pickUpFromUser = localCall.pickUpFromUser;
            var pickUpUserId = localCall.pickUpUserId;

            terminateCall(localCall, Enums.CallClientTerminatedReason.ANOTHER_CLIENT_PULLED_CALL);

            _pendingRemoteCalls[evt.sessionId] = evt;
            getConversationPromise
            .then(function (resolvedConv) {
                if (_pendingRemoteCalls[evt.sessionId]) {
                    addRemoteCall(resolvedConv, activeClient, evt.session, evt.sessionId, function (call) {
                        call.atcCallInfo = atcCallInfo;
                        if (call.isTelephonyCall) {
                            setCallPeerUser(call, phoneNumber, null, displayName);
                            call.participants = participants;
                        }
                        call.pickUpFromUser = pickUpFromUser;
                        call.pickUpUserId = pickUpUserId;
                    });
                    delete _pendingRemoteCalls[evt.sessionId];
                }
            })
            .catch(function (err) {
                LogSvc.warn('[CircuitCallControlSvc]: Could not retrieve guest conversation. Remote call will not be displayed.', err);
            });
        }

        function updateCallFeaturesFromSessionUpdate(evt, localCall) {
            if (evt.session.muted !== localCall.sessionMuted) {
                localCall.sessionMuted = evt.session.muted;
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/muted event');
                PubSubSvc.publish('/call/muted', [localCall]);
            }
            if (evt.session.locked !== localCall.sessionLocked) {
                localCall.sessionLocked = evt.session.locked;
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/locked event');
                PubSubSvc.publish('/call/locked', [localCall]);
            }

            if (evt.session.testMode) {
                localCall.testMode = evt.session.testMode;
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/testMode event (session updated)');
                PubSubSvc.publish('/call/testMode', [localCall]);
            }

            var whiteboardEnabled = !!evt.session.whiteBoardEnabled;
            if (localCall.whiteboardEnabled !== whiteboardEnabled) {
                localCall.whiteboardEnabled = whiteboardEnabled;
                PubSubSvc.publish('/call/whiteboard/update', [localCall]);
            }

            var pollEnabled = !!evt.session.pollEnabled;
            if (localCall.pollEnabled !== pollEnabled) {
                localCall.pollEnabled = pollEnabled;
                PubSubSvc.publish('/call/poll/update', [localCall]);
            }
        }

        function processSessionParticipants(evt, localCall) {
            var data = {
                addedParticipants: [],
                updatedParticipants: [],
                removedParticipantIds: []
            };

            var sessionParticipants = evt.session.participants || [];

            // Since we are not a Meeting Room, let's remove ourselves from the participants list
            sessionParticipants.some(function (p, idx) {
                if (p.userId === $rootScope.localUser.userId) {
                    sessionParticipants.splice(idx, 1);
                    return true;
                }
                return false;
            });

            if (!localCall.isDirect && !localCall.isAtcConferenceCall()) {
                // Initialize with previous participants. We'll filter the participants that are still in the session below.
                data.removedParticipantIds = localCall.participants
                    .filter(function (p) {
                        // Ignore ringing participants that have been added by the local user
                        return p.pcState !== Enums.ParticipantState.Initiated;
                    })
                    .map(function (p) {
                        return p.userId;
                    });
            }

            if (sessionParticipants.length > 0 || localCall.conferenceCall) {
                // There are other participants already in the call

                var mediaType = Proto.getMediaType(evt.session.mediaTypes);
                // Safeguard if server sends wrong media types
                mediaType.audio = mediaType.audio || localCall.localMediaType.audio;
                mediaType.video = mediaType.video || localCall.localMediaType.video;
                mediaType.desktop = mediaType.desktop || localCall.localMediaType.desktop;
                localCall.mediaType = mediaType;
                localCall.activeClient = null;

                processReplacedCall(localCall, sessionParticipants.length > 0);

                if (!localCall.isAtcConferenceCall()) {
                    sessionParticipants.forEach(function (p) {
                        var mediaParticipantUpdate = false;
                        var normalizedParticipant = normalizeApiParticipant(p);

                        var pState = normalizedParticipant.muted ? Enums.ParticipantState.Muted : Enums.ParticipantState.Active;

                        var callParticipant = localCall.getParticipant(p.userId);
                        lookupParticipant(normalizedParticipant, localCall);

                        if (callParticipant) {
                            Utils.removeArrayElement(data.removedParticipantIds, p.userId);

                            var oldPcState = callParticipant.pcState;
                            var oldVideoStream = callParticipant.videoStream;

                            if (callParticipant.streamId !== normalizedParticipant.streamId ||
                                callParticipant.screenStreamId !== normalizedParticipant.screenStreamId ||
                                !callParticipant.hasSameMediaType(normalizedParticipant)) {
                                mediaParticipantUpdate = true;
                            }

                            var updatedParticipant = updateParticipantInCallObj(localCall, normalizedParticipant, pState);
                            if (updatedParticipant && (mediaParticipantUpdate || updatedParticipant.pcState !== oldPcState ||
                                updatedParticipant.videoStream !== oldVideoStream)) {
                                data.updatedParticipants.push(updatedParticipant);
                            }
                        } else {
                            var addedParticipant = addParticipantToCallObj(localCall, normalizedParticipant, pState);
                            if (addedParticipant) {
                                data.addedParticipants.push(addedParticipant);
                            }
                        }
                    });
                }
            }
            return data;
        }

        function processLocalUserLeftEvt(evt, localCall) {
            var remover = localCall && evt.cause === Constants.RTCSessionParticipantLeftCause.REMOVED &&
                evt.requesterId && localCall.getParticipant(evt.requesterId);

            var params = [evt.sessionId, evt.cause];
            remover && params.push(remover);
            LogSvc.debug('[CircuitCallControlSvc]: Publish /localUser/leftConference event');
            PubSubSvc.publish('/localUser/leftConference', params);

            if (remover && NotificationSvc) {
                NotificationSvc.show({
                    type: Enums.NotificationType.HIGH_PRIORITY,
                    user: $rootScope.localUser,
                    extras: {
                        title: $rootScope.i18n.map.res_RemovedFromConferenceTitle,
                        text: $rootScope.i18n.localize('res_RemovedFromConferenceBy', [remover.displayName])
                    }
                });
            }
        }

        function processParticipantLeftForAlertingCall(evt) {
            var alertingCall = getIncomingCall(evt.sessionId);
            if (alertingCall && !alertingCall.isDirect && alertingCall.hasParticipant(evt.userId)) {
                // Caller left the conference
                LogSvc.info('[CircuitCallControlSvc]: Event is for an incoming call. Caller has left the conversation.');
                removeCallParticipant(alertingCall, evt.userId, evt.cause);
                // Create a new active remote call and terminate the alerting call.
                var alertingConversation = getConversation(alertingCall.convId);
                if (alertingConversation) {
                    addRemoteCall(alertingConversation);
                    terminateCall(alertingCall, Enums.CallClientTerminatedReason.CALLER_LEFT_CONFERENCE);
                }
            } else {
                LogSvc.info('[CircuitCallControlSvc]: Event is not for local or alerting call. Ignore it.');
            }
        }

        function getDefaultHandler(cb) {
            if (typeof cb !== 'function') {
                return NOP;
            }
            return function (err) {
                $rootScope.$apply(function () {
                    cb(err);
                });
            };
        }

        function handlePendingSessionUpdatedActions(call) {
            if (call.callConnectedPostponed) {
                call.callConnectedPostponed = false;
                LogSvc.debug('[CircuitCallControlSvc]: Send pending call connected diagnostics data for instanceId = ', call.instanceId);
                DeviceDiagnosticSvc.sendCallConnected(call);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/conversation/update', function (conv, data) {
            if (!data) {
                // Event was not triggered by a Conversation.UPDATE event
                return;
            }
            var localCall = findLocalCallByCallId(conv.rtcSessionId);
            if (!localCall || localCall.isDirect) {
                // Event is not applicable
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: Received /conversation/update event');

            if (data.isModerated !== undefined) {
                // Update the participant actions in case the conversation moderation has been toggled
                localCall.participants.forEach(function (p) {
                    p.setActions(conv, $rootScope.localUser);
                });
            }
        });

        PubSubSvc.subscribe('/conversation/upgrade', function (oldConversation, newConversation) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /conversation/upgrade event. Old convId = ' +
                    oldConversation.convId + '. New convId = ', newConversation.convId);

            if (oldConversation.call && newConversation.convId !== oldConversation.convId
                    && (oldConversation.call.checkState(Enums.CallState.Active)
                    || (oldConversation.type !== Constants.ConversationType.DIRECT && oldConversation.call.checkState(Enums.CallState.Waiting)))) {

                LogSvc.debug('[CircuitCallControlSvc]: Move Session');
                if (oldConversation.type === Constants.ConversationType.DIRECT && newConversation.type === Constants.ConversationType.GROUP) {
                    var options = createCallOptions({callOut: true, handover: false, replaces: oldConversation.call});
                    if (createLocalCall(newConversation, oldConversation.call.localMediaType, options)) {
                        joinSession(newConversation, oldConversation.call.localMediaType, options, function (err) {
                            if (!err) {
                                if (oldConversation.call.sessionCtrl && oldConversation.call.sessionCtrl.isMuted()) {
                                    newConversation.call.sessionCtrl && newConversation.call.sessionCtrl.mute();
                                }
                            }
                        });
                    }
                } else {
                    var data = {
                        sessionId: oldConversation.rtcSessionId,
                        conversationId: oldConversation.convId,
                        newSessionId: newConversation.rtcSessionId,
                        newConversationId: newConversation.convId
                    };

                    _clientApiHandler.moveRtcSession(data, function (err) {
                        if (err) {
                            LogSvc.warn('[CircuitCallControlSvc]: Error moving RTC Session. ', err);
                        }
                    });

                }
            }
        });

        PubSubSvc.subscribe('/conversation/left', function (conversation) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /conversation/left event. convId = ', conversation.convId);

            if (conversation && conversation.call) {
                _that.hideRemoteCall(conversation.call.callId);
            }
        });

        PubSubSvc.subscribe('/conversation/participants/add', function (call, participants) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /conversation/participants/add event.');
            if (call && participants) {
                participants.forEach(function (participant) {
                    addParticipant(call, participant);
                });
            }
        });

        PubSubSvc.subscribe('/csta/handover', function (callId) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /csta/handover event. callId = ', callId);
            _callIdToBeReplaced = callId;
            _handoverTimer = $timeout(function () {
                _handoverTimer = null;
            }, ATC_HANDOVER_TIME);
        });

        PubSubSvc.subscribe('/atccall/moveFailed', function () {
            LogSvc.debug('[CircuitCallControlSvc]: Received /atccall/moveFailed event.');
            if (_handoverTimer) {
                $timeout.cancel(_handoverTimer);
                _handoverTimer = null;
            }
        });

        PubSubSvc.subscribe('/atccall/pickUpInProgress', function (call) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /atccall/pickUpInProgress event.');
            _callToPickup = call;
            _pickupTimer = $timeout(function () {
                _pickupTimer = null;
                _callToPickup = null;
            }, ATC_PICKUP_TIMER);
        });

        PubSubSvc.subscribe('/conversations/loadComplete', function () {
            LogSvc.debug('[CircuitCallControlSvc]: Received /conversations/loadComplete event');
            _conversationLoaded = true;
            initActiveSessions();
        });

        PubSubSvc.subscribe('/registration/state', function (state) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /registration/state event');
            if (state !== RegistrationState.Registered) {
                if (_primaryLocalCall && _primaryLocalCall.isTestCall) {
                    // Test calls cannot be recovered
                    terminateCall(_primaryLocalCall, Enums.CallClientTerminatedReason.LOST_WEBSOCKET_CONNECTION);
                }

                // Clear all incoming calls, since we can't answer them anymore
                _incomingCalls.forEach(function (c) {
                    LogSvc.debug('[CircuitCallControlSvc]: Lost websocket connection, terminate local incoming call');
                    terminateCall(c, Enums.CallClientTerminatedReason.LOST_WEBSOCKET_CONNECTION);
                });

                if (state === RegistrationState.Disconnected || state === RegistrationState.Waiting) {
                    // Clear all other calls
                    _calls.forEach(function (c) {
                        terminateCall(c, Enums.CallClientTerminatedReason.LOST_WEBSOCKET_CONNECTION);
                    });
                }
            }
        });

        PubSubSvc.subscribe('/conversation/re-added', function (conv) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /conversation/re-added event. convId = ', conv.convId);
            _clientApiHandler.getSession(conv.rtcSessionId, function (err, session) {
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc] Error retrieving session. Err: ', err);
                } else if (session.participants && session.participants.length > 0) {
                    $rootScope.$apply(function () {
                        processActiveSessions(conv, [session]);
                    });
                }
            });
        });

        PubSubSvc.subscribe('/call/meetingPointInvitee', function (callId) {
            LogSvc.debug('[CircuitCallControlSvc]: Received /call/meetingPointInvitee event');
            if (callId) {
                var localCall = findLocalCallByCallId(callId);
                if (localCall) {
                    localCall.setMeetingPointInviteState(true);
                    disableAllAudio(callId);
                }
            }
        });

        PubSubSvc.subscribe('/call/removeConferenceAsGuest', function (call) {
            LogSvc.debug('CircuitCallControlSvc]: /call/removeConferenceAsGuest');
            _that.removeConferenceAsGuest(call);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Client API Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _userToUserHandler.on('ATC.ADVANCING', function (data) {
            try {
                if (data.type === AtcMessage.ADVANCING) {
                    LogSvc.debug('[CircuitCallControlSvc]: Received UserToUser ATC.ADVANCING');
                    var call = getIncomingCall(data.rtcSessionId);
                    if (call) {
                        call.atcAdvancing = true;
                        if (!call.startedEarlyMedia) {
                            call.sessionCtrl.setIgnoreNextConnection(true);
                        }
                    }
                }
            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling UserToUser ATC.ADVANCING event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.SDP_ANSWER', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.SDP_ANSWER');

                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                    return;
                }

                if (_clientApiHandler.clientId !== evt.clientID) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not for this client: ', _clientApiHandler.clientId);
                    return;
                }
                if (localCall.isDirect) {
                    DeviceDiagnosticSvc.startJoinDelayTimer(localCall);
                }


                $rootScope.$apply(function () {
                    // Make sure we have a valid SDP
                    var sdp = evt.sdp;
                    if (sdp) {
                        var sdpAnswerInfo = DeviceDiagnosticSvc.createActionInfo(localCall, Constants.RtcDiagnosticsAction.SDP_ANSWER);

                        if (!sdp.sdp || sdp.sdp === 'sdp' || sdp.sdp === 'data') {
                            // The mock keeps getting changed, so we need to adapt...
                            sdp.sdp = 'sdp';
                            LogSvc.debug('[CircuitCallControlSvc]: This is a mocked call');
                            localCall.setMockedCall();
                        }

                        localCall.setInstanceId(evt.instanceId);
                        // We also need to call setRemoteDescription for mock scenarios in order
                        // to clear the media renegotiation flags.
                        localCall.sessionCtrl.setRemoteDescription(sdp, function (err) {
                            localCall.clearTransactionId();

                            if (err) {
                                LogSvc.error('[CircuitCallControlSvc]: Error setting remote description: ', err);
                                localCall.setDisconnectCause(Constants.DisconnectCause.REMOTE_SDP_FAILED, 'type=' + sdp.type + ' origin=' + sdpParser.getOrigin(sdp.sdp));
                                leaveCall(localCall, null, Enums.CallClientTerminatedReason.SET_REMOTE_SDP_FAILED);
                                if (sdpAnswerInfo) {
                                    sdpAnswerInfo.data = err;
                                }
                            }

                            DeviceDiagnosticSvc.finishActionInfo(localCall, sdpAnswerInfo);
                        });
                    }
                    evt.localMute && _that.mute(localCall.callId, function (err) {
                        err && LogSvc.warn('[CircuitCallControlSvc]: Error synchronizing client mute state. Error: ', err);
                    });
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCCall.SDP_ANSWER event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.ICE_CANDIDATES', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.ICE_CANDIDATES');

                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                    return;
                }

                if (_clientApiHandler.clientId !== evt.clientId) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not for this client: ', _clientApiHandler.clientId);
                    return;
                }

                localCall.sessionCtrl.addIceCandidates(evt.origin, evt.candidates);

                var iceInfo = DeviceDiagnosticSvc.createActionInfo(localCall, Constants.RtcDiagnosticsAction.REMOTE_ICE_CANDIDATES);
                if (iceInfo) {
                    iceInfo.data = evt.candidates;
                    DeviceDiagnosticSvc.finishActionInfo(localCall, iceInfo);
                }

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCCall.ICE_CANDIDATES event. ', e);
            }
        });

        _clientApiHandler.on('RTCSession.SESSION_UPDATED', function (evt) {
            $rootScope.$apply(function () {
                try {
                    LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_UPDATED');

                    var activeClient = evt.session.participants.find(function (p) {
                        return p.userId === $rootScope.localUser.userId;
                    });

                    var localCall = findLocalCallByCallId(evt.sessionId);
                    if (activeClient) {
                        var activeRemoteCall = getActiveRemoteCall(evt.sessionId);
                        if (activeRemoteCall) {
                            LogSvc.debug('[CircuitCallControlSvc]: Event is for an existing active remote call');
                            activeRemoteCall.mediaType = Proto.getMediaType(evt.session.mediaTypes);
                            activeRemoteCall.setActiveClient(activeClient);
                            updateHosted(evt, activeRemoteCall);
                            publishCallState(activeRemoteCall);
                            return;
                        }

                        var alertingCall = getIncomingCall(evt.sessionId);
                        if (alertingCall) {
                            // For alerting calls, the active remote call is supposed to be created with
                            // the INVITE_CANCEL message.
                            LogSvc.debug('[CircuitCallControlSvc]: Event is for an existing active remote call');
                            return;
                        }

                        if (!localCall || (localCall.callId !== evt.sessionId && !findActivePhoneCall(true))) {
                            // The event is not for the local call. We need to create a new active remote call in this case.
                            addRemoteCallForSessionUpdate(evt, activeClient);
                            return;
                        }

                        // IF we get to this point then the event is for the local call.

                        if (activeClient.clientId !== _clientApiHandler.clientId) {
                            // Call has been moved. Create a new active remote call and terminate the local call.
                            handleMovedCallEvent(evt, activeClient, localCall);
                            return;
                        }

                        // Check if the local user has been muted / unmuted
                        if (localCall.remotelyMuted !== activeClient.muted) {
                            localCall.remotelyMuted = activeClient.muted;
                            publishMutedEvent(localCall);
                        }
                    } else {
                        LogSvc.info('[CircuitCallControlSvc]: Not a large conference and local user is not in the participants list. Ignore event.');
                        return;
                    }

                    if (!localCall.instanceId) {
                        // Make sure we always have the instanceId set in our call object (important for Qos)
                        localCall.setInstanceId(evt.session.instanceId);
                    }

                    updateCallFeaturesFromSessionUpdate(evt, localCall);

                    updateHosted(evt, localCall);

                    localCall.hadRemoteCall = false; // At this stage of the call we don't need this flag anymore. So reset it.

                    var participantsData = processSessionParticipants(evt, localCall);

                    evt.session.recentActiveSpeakers = evt.session.recentActiveSpeakers || [];
                    localCall.recentActiveSpeakers = evt.session.recentActiveSpeakers.filter(function (id) {
                        return localCall.hasParticipant(id);
                    });

                    var oldState = localCall.state;
                    updateAttendeeCount(evt.session.attendeeCount);
                    if (localCall.conferenceCall || localCall.checkState(Enums.CallState.Answering)) {
                        if (!localCall.isEstablished()) {
                            localCall.setState(Enums.CallState.Waiting);
                        }
                        // Invoke updateCallState so call object changes state to Active if applicable
                        localCall.updateCallState();
                    }
                    if (!localCall.checkState(oldState)) {
                        // state has changed
                        publishCallState(localCall);
                    }

                    if (localCall.conferenceCall && !localCall.hasOtherParticipants() && evt.participantId === $rootScope.localUser.userId) {
                        // This is the first user joining the conference
                        // Note that evt.participantId is only included in the first SESSION_UPDATED event after client joins conference
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /conference/starting event');
                        PubSubSvc.publish('/conference/starting', [localCall]);
                    }

                    participantsData.removedParticipantIds.forEach(function (userId) {
                        var removedParticipant = localCall.removeParticipant(userId);
                        if (removedParticipant) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/removed event. userId = ', userId);
                            PubSubSvc.publish('/call/participant/removed', [localCall.callId, removedParticipant]);
                        }
                    });

                    if (participantsData.updatedParticipants.length > 0) {
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participants/updated event');
                        PubSubSvc.publish('/call/participants/updated', [localCall.callId, participantsData.updatedParticipants]);
                    }
                    if (participantsData.addedParticipants.length > 0) {
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participants/added event');
                        PubSubSvc.publish('/call/participants/added', [localCall.callId, participantsData.addedParticipants]);
                    }

                    // Check if the session is being recorded or a recording has been paused
                    // This block of code (including the event) should not be run while on an echo test call
                    if (!localCall.isTestCall) {
                        if (evt.session.recordings) {
                            evt.session.recordings.forEach(function (data) {
                                setRecordingInfoData(localCall, data);
                            });
                        } else {
                            setRecordingInfoData(localCall, evt.session.recordingInfo);
                        }
                    }

                    // Reset isJoiningGroupCall flag so we don't show a recording notification on the next SESSION_UPDATED
                    localCall.isJoiningGroupCall = false;

                    simulateActiveSpeakers();
                    handlePendingSessionUpdatedActions(localCall);
                } catch (e) {
                    LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.SESSION_UPDATED event. ', e);
                }
            });
        });

        _clientApiHandler.on('RTCSession.SESSION_STARTED', function (evt) {
            if (!_conversationLoaded) {
                LogSvc.info('[CircuitCallControlSvc]: Conversations are not loaded. Do not process the SESSION_STARTED event.');
                return;
            }

            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_STARTED');
                var localCall = findLocalCallByCallId(evt.sessionId);
                if (localCall && localCall.isTestCall) {
                    LogSvc.debug('[CircuitCallControlSvc]: Received SESSION_STARTED event for echo test call. Just ignore it.');
                    return;
                }

                if (evt.session.turnServers) {
                    putSessionTurn(evt.sessionId, evt.session.turnServers);
                }

                var conversation = getConversation(evt.convId);
                if (!conversation) {
                    LogSvc.info('[CircuitCallControlSvc]: Could not find corresponding conversation');
                    var sessionCreatorId = evt.session.participants.length === 1 && evt.session.participants[0].userId;
                    // Even if callOut, continue to fetch conversation if local user started session (on any device)
                    if (evt.callOut && sessionCreatorId !== $rootScope.localUser.userId) {
                        return;
                    }
                    LogSvc.info('[CircuitCallControlSvc]: Get the conversation from the server');
                    ConversationSvc.getConversationById(evt.convId, {randomLoaded: true}, function (getConvErr, conv) {
                        if (getConvErr) {
                            LogSvc.warn('[CircuitCallControlSvc]: Could not retrieve corresponding conversation. Delete Pending Invite');
                        } else {
                            LogSvc.info('[CircuitCallControlSvc]: Successfully retrieved the conversation. Check if there are any active sessions.');
                            _clientApiHandler.getActiveSessions(function (getSessionsErr, activeSessions) {
                                $rootScope.$apply(function () {
                                    if (getSessionsErr) {
                                        LogSvc.error('[CircuitCallControlSvc]: Error getting active sessions: ', getSessionsErr);
                                        activeSessions = [];
                                    }
                                    processActiveSessions(conv, activeSessions);
                                });
                            });
                        }
                    });
                    return;
                }

                if (!conversation.hasJoined) {
                    // This should not happen with the actual backend, but the mock also sends the
                    // events for conversations the user is no longer a participant.
                    LogSvc.info('[CircuitCallControlSvc]: User is not a conversation participant. Ignore event.');
                    return;
                }

                $rootScope.$apply(function () {
                    if (localCall) {
                        if (!localCall.instanceId) {
                            // Make sure we always have the instanceId set in our call object (important for Qos)
                            localCall.setInstanceId(evt.session.instanceId);
                        }
                        LogSvc.debug('[CircuitCallControlSvc]: Found local call for SESSION_STARTED event');
                        LogSvc.info('[CircuitCallControlSvc]: Ignore SESSION_STARTED. State =', localCall.state.name);
                    } else if (evt.callOut) {
                        var pickUpFromUser;
                        if (_callToPickup && _callToPickup.callId === evt.sessionId) {
                            LogSvc.debug('[CircuitCallControlSvc]: The call was picked up by a remote client');
                            pickUpFromUser = _callToPickup.pickUpFromUser;
                            _that.ignorePickupCall();
                        }
                        evt.session.participants.some(function (p) {
                            if (p.userId === $rootScope.localUser.userId && p.clientId !== _clientApiHandler.clientId) {
                                // This an outgoing call on remote device
                                addRemoteCall(conversation, p, evt.rtcSession, evt.sessionId, function (call) {
                                    call.pullNotAllowed = true;
                                    if (pickUpFromUser) {
                                        call.pickUpFromUser = pickUpFromUser;
                                    }
                                });
                                return true;
                            }
                            return false;
                        });
                    } else if (conversation.type === Constants.ConversationType.GROUP || conversation.type === Constants.ConversationType.LARGE) {
                        // This is a new conference call.
                        // Create a remote call and allow the user to join.
                        var activeClient = evt.session.participants.find(function (p) {
                            return p.userId === $rootScope.localUser.userId && p.clientId !== _clientApiHandler.clientId;
                        });
                        addRemoteCall(conversation, activeClient, evt.rtcSession, evt.sessionId, function (call) {
                            if (!activeClient) {
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /conference/started event');
                                PubSubSvc.publish('/conference/started', [call]);
                            }
                        });
                    }
                    updateAttendeeCount(evt.session.attendeeCount);
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.SESSION_STARTED event: ', e);
            }
        });

        _clientApiHandler.on('RTCSession.PARTICIPANT_JOINED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.PARTICIPANT_JOINED');
                var userId = evt.participant.userId;
                if ($rootScope.localUser.userId === userId) {
                    LogSvc.debug('[CircuitCallControlSvc]: Ignore JOINED event for own user');
                    return;
                }

                var activeRemoteCall = getActiveRemoteCall(evt.sessionId);
                if (activeRemoteCall) {
                    $rootScope.$apply(function () {
                        if (activeRemoteCall.pullNotAllowed) {
                            activeRemoteCall.pullNotAllowed = false;
                        }
                        if (activeRemoteCall.isTelephonyCall && !activeRemoteCall.atcCallInfo) {
                            setCallPeerUser(activeRemoteCall, evt.participant.phoneNumber, null, evt.participant.userDisplayName);
                        }
                    });
                    return;
                }
                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.info('[CircuitCallControlSvc]: Event is not for local call. Ignore it.');
                    return;
                }

                if (!localCall.isEstablished() && localCall.direction === Enums.CallDirection.INCOMING) {
                    // Ignore the JOINED events for incoming alerting calls
                    LogSvc.info('[CircuitCallControlSvc]: Ignore JOINED event for incoming alerting calls');
                    return;
                }

                $rootScope.$apply(function () {
                    if (evt.participant.participantType === Constants.RTCParticipantType.VOICE_MAIL) {
                        localCall.sessionCtrl.setCallStatsOptions({sendOnlyStream: true});
                    }

                    if (localCall.isTelephonyCall && (!localCall.atcCallInfo || !localCall.peerUser)) {
                        setCallPeerUser(localCall, evt.participant.phoneNumber, null, evt.participant.userDisplayName);
                    }

                    if (localCall.checkState([Enums.CallState.Delivered, Enums.CallState.Waiting])) {
                        if (localCall.checkState(Enums.CallState.Delivered)) {
                            // This is for outgoing call when the other participant answers the call.
                            localCall.setState(Enums.CallState.Waiting);
                        } else if (localCall.conferenceCall && localCall.sessionCtrl.isConnected()) {
                            // This is for conference call when a second participant joins the call,
                            // the call state of the first one should be changed to active.
                            localCall.setState(Enums.CallState.Active);
                        }
                        localCall.activeClient = null;
                        processReplacedCall(localCall, true);
                    }

                    var normalizedParticipant = normalizeApiParticipant(evt.participant);
                    var pState = normalizedParticipant.muted ? Enums.ParticipantState.Muted : Enums.ParticipantState.Active;
                    if (!localCall.isTelephonyCall) {
                        lookupParticipant(normalizedParticipant, localCall);
                    }

                    if (localCall.hasParticipant(userId)) {
                        var updatedParticipant = updateParticipantInCallObj(localCall, normalizedParticipant, pState);
                        if (updatedParticipant) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event');
                            PubSubSvc.publish('/call/participant/updated', [localCall.callId, updatedParticipant]);
                        }
                    } else {
                        var addedParticipant = addParticipantToCallObj(localCall, normalizedParticipant, pState);
                        if (addedParticipant) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/added event');
                            PubSubSvc.publish('/call/participant/added', [localCall.callId, addedParticipant]);
                        }
                    }

                    var joinDataWrapper = evt.userEnteredStage ? { userEnteredStage: evt.userEnteredStage } : null;
                    var participant = localCall.getParticipant(normalizedParticipant.userId);
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/joined event');
                    PubSubSvc.publish('/call/participant/joined', [localCall, participant, joinDataWrapper]);
                    simulateActiveSpeakers();

                    // Update the call's media type
                    localCall.updateMediaType();

                    // If recorded user joined call, update recording info
                    if (!localCall.isTestCall && localCall.recording.recordingUserId === participant.userId) {
                        localCall.recording.recordingUser = participant;
                    }

                    publishCallState(localCall);
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTC.PARTICIPANT_JOINED event. ', e);
            }
        });

        _clientApiHandler.on('RTCSession.SESSION_MOVED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_MOVED');

                // Is this event for a pending invite
                if (_pendingInvites[evt.oldRtcSessionId]) {
                    // Yes, remove the pending invite. Still unable to process the SESSION_MOVED
                    LogSvc.info('[CircuitCallControlSvc]: Event is for a pending INVITE. Ignore it.');
                    delete _pendingInvites[evt.oldRtcSessionId];
                    return;
                }

                var call = findCall(evt.oldRtcSessionId);
                if (!call) {
                    LogSvc.info('[CircuitCallControlSvc]: Event is not for local or remote call. Ignore it.');
                    return;
                }

                // Get old and new conversations
                var oldConversation = getConversation(evt.oldConversationId);
                var newConversation = getConversation(evt.newConversationId);
                if (!oldConversation || !newConversation) {
                    // Wait for conversation service to load the conversation data
                    LogSvc.warn('[CircuitCallControlSvc]: Could not find corresponding conversations');
                    return;
                }

                $rootScope.$apply(function () {
                    updateCall(newConversation, oldConversation, call);

                    if (call.isRemote) {
                        // Session Moved for Remote Call
                        LogSvc.debug('[CircuitCallControlSvc]: Session Moved for Remote Call');
                        return;
                    }

                    // Add New Participants to the call if localUser is the originator of the session move
                    if (newConversation.creatorId === $rootScope.localUser.userId) {
                        var oldConvParticipantsHash = {};
                        oldConversation.participants.forEach(function (participant) {
                            oldConvParticipantsHash[participant.userId] = true;
                        });
                        newConversation.participants.forEach(function (participant) {
                            if (!oldConvParticipantsHash[participant.userId]) {
                                addParticipant(call, participant);
                            }
                        });
                    }
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTC.SESSION_MOVED event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.INVITE', function (evt) {
            if (!_conversationLoaded) {
                LogSvc.info('[CircuitCallControlSvc]: Conversations are not loaded. Do not process the INVITE event.');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.INVITE');
            handleInviteEvent(evt);
        });

        _clientApiHandler.on('RTCSession.PARTICIPANT_LEFT', function (evt) {
            $rootScope.$apply(function () {
                try {
                    LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.PARTICIPANT_LEFT');
                    var isLocalUser = $rootScope.localUser.userId === evt.userId;

                    var localCall = findLocalCallByCallId(evt.sessionId);

                    if (isLocalUser) {
                        if (localCall && !localCall.isDirect && !localCall.isEstablished() && !_negotiationFailureCauses.includes(evt.cause)) {
                            LogSvc.debug('[CircuitCallControlSvc]: Ignore PARTICIPANT_LEFT event for non-established group calls');
                            return;
                        }
                        processLocalUserLeftEvt(evt, localCall);
                    }

                    var activeRemoteCall = getActiveRemoteCall(evt.sessionId);
                    if (activeRemoteCall) {
                        LogSvc.info('[CircuitCallControlSvc]: Event is for active remote call.');
                        if (isLocalUser) {
                            changeRemoteCallToStarted(activeRemoteCall);
                        }
                        return;
                    }

                    if (!localCall) {
                        processParticipantLeftForAlertingCall(evt);
                        return;
                    }

                    if (localCall.isTestCall) {
                        LogSvc.debug('[CircuitCallControlSvc]: Ignore PARTICIPANT_LEFT event for test calls');
                        return;
                    }

                    if (localCall.isDirect && !localCall.isDirectUpgradedToConf) {
                        if (!isLocalUser && (evt.cause === Constants.RTCSessionParticipantLeftCause.CONNECTION_LOST ||
                            evt.cause === Constants.RTCSessionParticipantLeftCause.STREAM_LOST)) {
                            localCall.setParticipantState(evt.userId, Enums.ParticipantState.ConnectionLost);
                        } else {
                            LogSvc.debug('[CircuitCallControlSvc]: Ignore PARTICIPANT_LEFT event for direct calls');
                        }
                        return;
                    }

                    // This is a local GROUP session.
                    var userId = evt.userId;
                    var conversation = getConversation(localCall.convId);
                    if (!isLocalUser) {
                        if (evt.cause === Constants.RTCSessionParticipantLeftCause.MAX_PARTICIPANTS_REACHED) {
                            // Participant tried to join, but couldn't due to limitations.
                            var isConvUser = conversation.participants.some(function (participant) {
                                return participant.userId === userId;
                            });
                            var sendEventCb = function (err, user) {
                                if (err) { return; }
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/rejected event');
                                PubSubSvc.publish('/call/participant/rejected', [localCall.callId, user]);
                            };
                            // If the user is in conversation, at first get the participant data.
                            // Otherwise the participant is a guest, send the event without user data.
                            isConvUser ? UserSvc.getUserById(userId, sendEventCb) : sendEventCb();
                            return;
                        }

                        // Remove the leaving participant
                        removeCallParticipant(localCall, userId, evt.cause);
                    } else {
                        addRemoteCall(conversation);
                        if (evt.cause === Constants.RTCSessionParticipantLeftCause.REMOVED) {
                            var requester = localCall.getParticipant(evt.requesterId);
                            PubSubSvc.publish('/call/droppedRemotely', [localCall, requester]);
                        } else if (_negotiationFailureCauses.includes(evt.cause)) {
                            handleRtcError(localCall, {error: 'res_CallMediaFailed'});
                            return;
                        }
                        terminateCall(localCall, Enums.CallServerTerminatedReason.SERVER_ENDED_PRFX + evt.cause);
                    }
                } catch (e) {
                    LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.PARTICIPANT_LEFT event. ', e);
                }
            });
        });

        _clientApiHandler.on('RTCCall.SDP_FAILED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.SDP_FAILED with cause =', evt.cause);
                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                    return;
                }

                if (_clientApiHandler.clientId !== evt.clientID) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not for this client');
                    return;
                }

                if (!localCall.instanceId) {
                    localCall.setInstanceId(evt.instanceId);
                }

                if (evt.cause === Constants.SdpFailedCause.SESSION_TERMINATED) {
                    LogSvc.debug('[CircuitCallControlSvc]: SDP_FAILED due to session terminated. Ignore');
                    return;
                }

                $rootScope.$apply(function () {
                    if (!localCall.isEstablished()) {
                        if (evt.cause === Constants.SdpFailedCause.MAX_PARTICIPANTS_REACHED) {
                            handleRtcError(localCall, {error: 'res_JoinRTCSessionFailedPermission'});
                        } else if (!localCall.isDirect || !localCall.isCallOut) {
                            // We won't get an INVITE_FAILED for these scenarios, so we have to
                            // terminate the call here
                            if (evt.cause === Constants.SdpFailedCause.SESSION_STARTED_FAILED) {
                                terminateCall(localCall, Enums.CallServerTerminatedReason.SERVER_ENDED_PRFX + evt.cause);
                            } else {
                                leaveCall(localCall, null, Enums.CallClientTerminatedReason.REQUEST_TO_SERVER_FAILED);
                            }
                        }
                    } else {
                        localCall.sessionCtrl.renegotiationFailed('SDP failed received');
                    }
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCCall.SDP_FAILED event. ', e);
            }
        });

        _clientApiHandler.on('RTCSession.SESSION_TERMINATED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_TERMINATED');

                if (_sessionHash[evt.sessionId]) {
                    delete _sessionHash[evt.sessionId];
                }
                // Is this event for a pending invite
                if (_pendingInvites[evt.sessionId]) {
                    // Yes, remove the pending invite. Still unable to process the SESSION_TERMINATED
                    LogSvc.info('[CircuitCallControlSvc]: Event is for a pending INVITE. Ignore it.');
                    delete _pendingInvites[evt.sessionId];
                    return;
                }

                if (_pendingRemoteCalls[evt.sessionId]) {
                    LogSvc.info('[CircuitCallControlSvc]: Aborting pending creation of remote call for rtcSessionId=', evt.sessionId);
                    delete _pendingRemoteCalls[evt.sessionId];
                }

                var call = findCall(evt.sessionId);
                if (!call) {
                    LogSvc.warn('[CircuitCallControlSvc]: Could not find call with rtcSessionId =', evt.sessionId);
                    return;
                }

                if (call.terminateTimer) {
                    LogSvc.info('[CircuitCallControlSvc]: Call is already being terminated. Ignore event');
                    return;
                }

                if (call.isTelephonyCall && _handoverTimer) {
                    LogSvc.debug('[CircuitCallControlSvc]: Call is being moved to another device, keep showing');
                    call.setAtcHandoverInProgress();
                    $timeout.cancel(_handoverTimer);
                    _handoverTimer = null;
                }

                $rootScope.$apply(function () {
                    var cause = evt.cause;
                    LogSvc.info('[CircuitCallControlSvc]: Publish /call/terminated. cause = ', cause);
                    PubSubSvc.publish('/call/terminated', [call, cause]);
                    terminateCall(call, Enums.CallServerTerminatedReason.SERVER_ENDED_PRFX + cause);
                    if ($rootScope.localUser.isOsBizCTIEnabled) {
                        var otherCall = call.isOsBizFirstCall ? _that.findOsBizSecondCall() : _that.findOsBizFirstCall();
                        if (otherCall && !otherCall.checkState(Enums.CallState.Terminated)) {
                            PubSubSvc.publish('/call/terminated', [otherCall]);
                            terminateCall(otherCall);
                        }
                    }
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.SESSION_TERMINATED event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.INVITE_CANCEL', function (evt) {
            try {
                if (_clientApiHandler.clientId === evt.clientID) {
                    LogSvc.debug('[CircuitCallControlSvc]: Ignore own INVITE_CANCEL');
                    return;
                }

                // Is this event for a pending invite
                if (_pendingInvites[evt.sessionId]) {
                    // Yes, remove the pending invite. Still unable to process the iNVITE_CANCEL
                    LogSvc.info('[CircuitCallControlSvc]: Event is for a pending INVITE. Ignore it.');
                    delete _pendingInvites[evt.sessionId];
                    return;
                }

                $rootScope.$apply(function () {
                    LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.INVITE_CANCEL with cause: ', evt.cause);
                    var alertingCall = getIncomingCall(evt.sessionId);
                    if (!alertingCall) {
                        LogSvc.debug('[CircuitCallControlSvc]: There is no corresponding alerting call');
                        if (evt.cause === Constants.InviteCancelCause.ACCEPT) {
                            var existingCall = findCall(evt.sessionId);
                            if (existingCall && existingCall.isRemote) {
                                LogSvc.debug('[CircuitCallControlSvc]: There is a remote call, probably ringing timeout or another client called in');
                                existingCall.activeClient = {clientId: evt.clientID}; // Should be updated by subsequent SESSION_UPDATED
                                existingCall.setState(Enums.CallState.ActiveRemote);
                                addActiveRemoteCall(existingCall);
                                publishCallState(existingCall);
                            }
                        }
                        return;
                    }

                    if (!alertingCall.isDirect || evt.cause === Constants.InviteCancelCause.ACCEPT) {
                        var conversation = getConversation(alertingCall.convId);
                        var activeClient = evt.cause === Constants.InviteCancelCause.ACCEPT ? {clientId: evt.clientID} : null;
                        addRemoteCall(conversation, activeClient, null, evt.sessionId, function (call) {
                            if (activeClient && call.isTelephonyCall) {
                                setCallPeerUser(call, alertingCall.peerUser.phoneNumber, null, alertingCall.peerUser.displayName);
                            }
                        });
                    }
                    var reason = Enums.CallClientTerminatedReason.ANOTHER_CLIENT_REJECTED;
                    if (evt.cause === Constants.InviteCancelCause.ACCEPT) {
                        reason = Enums.CallClientTerminatedReason.ANOTHER_CLIENT_ANSWERED;
                    } else if (evt.cause === Constants.InviteCancelCause.REVOKED) {
                        reason = Enums.CallClientTerminatedReason.ENDED_BY_ANOTHER_USER;
                    }
                    terminateCall(alertingCall, reason);
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCCall.INVITE_CANCEL event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.INVITE_FAILED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.INVITE_FAILED');
                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                    return;
                }
                if (!localCall.isDirect) {
                    var callParticipant = localCall.getParticipant(evt.userId);
                    if (evt.to) {
                        callParticipant.displayName = evt.to.displayName;
                        var updatedParticipant = updateParticipantInCallObj(localCall, callParticipant);
                        if (updatedParticipant) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event');
                            PubSubSvc.publish('/call/participant/updated', [localCall.callId, updatedParticipant]);
                        }
                    }
                    if (callParticipant && callParticipant.isActive()) {
                        // This may happen as a result of race condition
                        LogSvc.debug('[CircuitCallControlSvc]: Unexpected event. participant already connected: ', evt.userId);
                        return;
                    }
                }

                if (evt.clientID && _clientApiHandler.clientId !== evt.clientID) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not for this client: ', _clientApiHandler.clientId);
                    return;
                }

                if (localCall.isTelephonyCall && evt.to) {
                    var displayName = evt.to.displayName ? evt.to.displayName : localCall.peerUser.displayName;
                    setCallPeerUser(localCall, evt.to.phoneNumber, evt.to.fullyQualifiedNumber, displayName, evt.to.userId);
                }

                $rootScope.$apply(function () {
                    var call = localCall;
                    var cause = evt.cause;
                    var userId = evt.userId;
                    var pcState;
                    switch (cause) {
                    case Constants.InviteFailedCause.NOT_REACHABLE:
                        pcState = Enums.ParticipantState.Offline;
                        // NGTC-3452: In case of transfer on ringing, we don't want to show 'Call couldn't connect' message
                        if (call.consultation !== false || call.atcCallInfo.cstaState.name !== 'Delivered') {
                            call.isDirect && call.setState(Enums.CallState.Failed);
                        }
                        break;
                    case Constants.InviteFailedCause.BUSY:
                        pcState = Enums.ParticipantState.Busy;
                        call.isDirect && call.setState(Enums.CallState.Busy);
                        break;
                    case Constants.InviteFailedCause.DECLINE:
                        pcState = Enums.ParticipantState.Declined;
                        call.isDirect && call.setState(Enums.CallState.Declined);
                        break;
                    case Constants.InviteFailedCause.TIMEOUT:
                        pcState = Enums.ParticipantState.Timeout;
                        call.isDirect && call.setState(Enums.CallState.NotAnswered);
                        break;
                    case Constants.InviteFailedCause.TEMPORARILY_UNAVAILABLE:
                    case Constants.InviteFailedCause.INVALID_NUMBER:
                        pcState = Enums.ParticipantState.Busy;
                        call.isDirect && call.setState(Enums.CallState.Failed);
                        break;
                    case Constants.InviteFailedCause.REVOKED:
                        pcState = Enums.ParticipantState.Removed;
                        call.isDirect && call.setState(Enums.CallState.Failed);
                        break;
                    default:
                        LogSvc.warn('[CircuitCallControlSvc]: Unexpected RTCCall.INVITE_FAILED cause =', evt.cause);
                        pcState = Enums.ParticipantState.Busy;
                        call.isDirect && call.setState(Enums.CallState.Failed);
                        break;
                    }

                    if (call.isDirect) {
                        call.setParticipantState(userId, pcState);
                        publishCallState(call);
                        if (call.hasVideo()) {
                            call.removeParticipant(userId);
                        }
                        leaveCall(call, null, Enums.CallServerTerminatedReason.SERVER_ENDED_PRFX + cause, true);
                    } else {
                        call.setParticipantState(userId, pcState);
                        var removedParticipant = call.removeParticipant(userId);
                        if (removedParticipant) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/removed event');
                            PubSubSvc.publish('/call/participant/removed', [call.callId, removedParticipant]);

                            if (Utils.isEmptyArray(call.participants)) {
                                // Everyone left the conference
                                if (call.conferenceCall) {
                                    // Conference is still running, make sure Enums.CallState is Waiting
                                    if (!call.checkState(Enums.CallState.Waiting)) {
                                        call.setState(Enums.CallState.Waiting);
                                        publishCallState(call);
                                    }
                                } else {
                                    call.setState(Enums.CallState.NotAnswered);
                                    publishCallState(call);
                                    leaveCall(null, null, Enums.CallServerTerminatedReason.SERVER_ENDED_PRFX + cause, true);
                                }
                            }
                        }
                    }
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTC.INVITE_FAILED event. ', e);
            }
        });

        _clientApiHandler.on('RTCSession.ACTIVE_SPEAKER', onActiveSpeakerEvent);

        _clientApiHandler.on('RTCSession.VIDEO_ACTIVE_SPEAKER', onActiveVideoSpeakerEvent);

        _clientApiHandler.on('RTCCall.CHANGE_MEDIA_TYPE_REQUESTED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.CHANGE_MEDIA_TYPE_REQUESTED');
                var alertingCall = getIncomingCall(evt.sessionId);
                if (alertingCall) {
                    sendChangeMediaReject(evt.sessionId, evt.transactionId);
                    return;
                }
                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall || localCall.isRemote) {
                    LogSvc.info('[CircuitCallControlSvc]: Event is not for local call. Ignore it.');
                    return;
                }

                if (evt.clientID && _clientApiHandler.clientId !== evt.clientID) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not for this client: ', _clientApiHandler.clientId);
                    return;
                }
                if (localCall.activeClient) {
                    LogSvc.debug('[CircuitCallControlSvc]: Pull in progress. leave it for active client to handle');
                    return;
                }
                if ((!localCall.isEstablished() && localCall.direction === Enums.CallDirection.INCOMING) || !localCall.isDirect) {
                    sendChangeMediaReject(localCall.callId, evt.transactionId);
                    return;
                }

                $rootScope.$apply(function () {
                    if (!evt.sdp) {
                        sendChangeMediaReject(localCall.callId, evt.transactionId);
                        return;
                    }

                    var isNoOffer = sdpParser.isNoOfferSdp(evt.sdp);
                    var sessionCtrl = localCall.sessionCtrl;

                    var onSdp = function (sdpEvt) {
                        sessionCtrl.onSessionDescription = null;

                        var changeMediaAcceptData = {
                            rtcSessionId: localCall.callId,
                            sdp: sdpEvt.sdp,
                            transactionId: evt.transactionId
                        };
                        _clientApiHandler.changeMediaAccept(changeMediaAcceptData, function (err) {
                            $rootScope.$apply(function () {
                                localCall.clearTransactionId();
                                if (err) {
                                    LogSvc.error('[CircuitCallControlSvc]: changeMediaAccept sdp answer sending error');
                                } else {
                                    LogSvc.debug('[CircuitCallControlSvc]: changeMediaAccept sdp answer sent');
                                }
                            });
                        });
                    };

                    var processEvent = function () {
                        localCall.setTransactionId(evt.transactionId);

                        if (localCall.isTelephonyCall && localCall.atcAdvancing && evt.sdp.type !== 'nooffer') {
                            // If PBX is 4K, one change_media_request without SDP is received when client answers after advancing the call.
                            // OSV sends two change_media_request(s) with SDP.
                            localCall.sessionCtrl.setIgnoreTurnOnNextPC();
                        }

                        LogSvc.debug('[CircuitCallControlSvc]: Publish /call/changeMediaTypeRequested event');
                        PubSubSvc.publish('/call/changeMediaTypeRequested', [localCall.callId, isNoOffer]);

                        localCall.atcAdvancing = false;
                        getTurnCredentials(localCall)
                        .then(function (turnCredentials) {
                            sessionCtrl.setTurnCredentials(turnCredentials);
                            if (isNoOffer) {
                                var data = {
                                    callId: localCall.callId,
                                    transactionId: evt.transactionId,
                                    mediaType: localCall.localMediaType,
                                    hosted: evt.hosted,
                                    replaces: evt.replaces,
                                    externallyTriggered: true
                                };
                                changeMediaType(data, function (err) {
                                    if (err) {
                                        LogSvc.warn('[CircuitCallControlSvc]: Failed to renegotiate the media');
                                    }
                                });
                            } else {
                                localCall.lastMediaType = localCall.mediaType;
                                sessionCtrl.onSessionDescription = onSdp;
                                sessionCtrl.setRemoteDescription(evt.sdp, function (err) {
                                    if (err) {
                                        LogSvc.error('[CircuitCallControlSvc]: Error setting remote description: ', err);
                                        sessionCtrl.onSessionDescription = null;
                                        if (localCall.transactionId === evt.transactionId) {
                                            sendChangeMediaReject(localCall.callId, evt.transactionId);
                                        } else {
                                            LogSvc.warn('[CircuitCallControlSvc]: TransactionId: ' + evt.transactionId + ' has already been completed.');
                                        }
                                    }
                                });
                            }
                        })
                        .catch(function () {
                            sendChangeMediaReject(localCall.callId, evt.transactionId);
                        });
                    };

                    if (sessionCtrl.isConnStable()) {
                        processEvent();
                    } else if (localCall.isTelephonyCall && sessionCtrl.canAbortRenegotiation()) {
                        // We are already in the middle of creating a new SDP Offer
                        if (isNoOffer) {
                            LogSvc.info('[CircuitCallControlSvc]: Client has already started a renegotiation and SDP Offer is pending');
                            localCall.pendingTransactionId = evt.transactionId;
                            localCall.setTransactionId(evt.transactionId);
                            localCall.atcAdvancing = false;
                        } else {
                            LogSvc.warn('[CircuitCallControlSvc]: Abort current renegotiation so we can process the CHANGE_MEDIA_TYPE_REQUESTED event');
                            sessionCtrl.abortPendingNegotiation(function () {
                                LogSvc.info('[CircuitCallControlSvc]: Renegotiation was aborted. Process the pending CHANGE_MEDIA_TYPE_REQUESTED event.');
                                processEvent();
                            });
                        }
                    } else {
                        sendChangeMediaReject(localCall.callId, evt.transactionId);
                    }
                });
            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.CHANGE_MEDIA_TYPE_REQUESTED event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.CHANGE_MEDIA_TYPE_FORCED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.CHANGE_MEDIA_TYPE_FORCED');
                var localCall = findLocalCallByCallId(evt.sessionId);
                if (!localCall) {
                    LogSvc.info('[CircuitCallControlSvc]: Event is not for local call. Ignore it.');
                    return;
                }
                if (localCall.isDirect) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not applicable for direct calls. Ignore it.');
                    return;
                }
                if (evt.clientID && _clientApiHandler.clientId !== evt.clientID) {
                    LogSvc.debug('[CircuitCallControlSvc]: Event is not for this client: ', _clientApiHandler.clientId);
                    return;
                }
                if (localCall.activeClient) {
                    LogSvc.debug('[CircuitCallControlSvc]: Pull in progress. leave it for active client to handle');
                    return;
                }
                if (evt.removeMediaTypes) {
                    var mediaToChange = null;
                    if (evt.removeMediaTypes.includes(Constants.RealtimeMediaType.AUDIO)) {
                        mediaToChange = {audio: false};
                    }
                    if (evt.removeMediaTypes.includes(Constants.RealtimeMediaType.VIDEO)) {
                        mediaToChange = mediaToChange || {};
                        mediaToChange.video = false;
                    }
                    if (mediaToChange) {
                        $rootScope.$apply(function () {
                            addRemoveMedia(localCall.callId, mediaToChange, 'changeMediaForced')
                            .catch();
                        });
                    }
                }
            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.CHANGE_MEDIA_TYPE_FORCED event. ', e);
            }
        });

        _clientApiHandler.on('RTCCall.RTC_QUALITY_RATING_EVENT', function (evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.RTC_QUALITY_RATING_EVENT');
            var call = _primaryLocalCall || _secondaryLocalCall;
            if (call) {
                LogSvc.info('[CircuitCallControlSvc]: Postpone call quality rating during call');
                call.ratingEvent = evt;
                return;
            }
            $rootScope.$apply(function () {
                publishShowRatingDialog(evt);
            });
        });

        _clientApiHandler.on('RTCSession.PARTICIPANT_UPDATED', function (evt) {
            try {
                LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.PARTICIPANT_UPDATED');

                var localCall = findLocalCallByCallId(evt.sessionId);
                var call = getActiveRemoteCall(evt.sessionId) || localCall;
                if (call && call.callId === evt.sessionId && call.isTelephonyCall && !call.atcCallInfo && $rootScope.localUser.userId !== evt.participant.userId) {
                    setCallPeerUser(call, evt.participant.phoneNumber, null, evt.participant.userDisplayName);
                }

                if (!localCall) {
                    LogSvc.info('[CircuitCallControlSvc]: Event is not for local call. Ignore it.');
                    return;
                }

                $rootScope.$apply(function () {
                    var userId = evt.participant.userId;
                    var muted = evt.participant.muted;
                    if ($rootScope.localUser.userId === userId) {
                        if (localCall.remotelyMuted !== muted) {
                            localCall.remotelyMuted = muted;

                            publishMutedEvent(localCall, evt.mutedBy);
                        }
                        return;
                    }

                    if (!localCall.isEstablished() && localCall.direction === Enums.CallDirection.INCOMING) {
                        // Ignore the UPDATED events for incoming alerting calls
                        LogSvc.debug('[CircuitCallControlSvc]: Ignore UPDATED event for incoming alerting calls');
                        return;
                    }


                    var callParticipant = localCall.getParticipant(evt.participant.userId);
                    if (!callParticipant) {
                        LogSvc.debug('[CircuitCallControlSvc]: Ignore UPDATED event, can not find participant to update for userId: ', userId);
                        return;
                    }
                    var normalizedParticipant = normalizeApiParticipant(evt.participant);
                    var pState = normalizedParticipant.muted ? Enums.ParticipantState.Muted : Enums.ParticipantState.Active;

                    var mutedUpdate = callParticipant.muted !== normalizedParticipant.muted;
                    var mediaUpdate = !callParticipant.hasSameMediaType(normalizedParticipant) ||
                        callParticipant.streamId !== normalizedParticipant.streamId;

                    var oldMediaType = callParticipant.mediaType;

                    var updatedParticipant = updateParticipantInCallObj(localCall, normalizedParticipant, pState);
                    if (updatedParticipant) {
                        lookupParticipant(updatedParticipant, localCall);

                        if (mediaUpdate) {
                            // Update the call's media type
                            localCall.updateMediaType();
                        }

                        if (mediaUpdate || mutedUpdate) {
                            LogSvc.debug('[CircuitCallControlSvc]: Publish /call/participant/updated event');
                            PubSubSvc.publish('/call/participant/updated', [localCall.callId, updatedParticipant, oldMediaType]);
                        }
                    }
                    publishCallState(localCall);
                });

            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCSession.PARTICIPANT_UPDATED event. ', e);
            }
        });

        _clientApiHandler.on('RTCSession.SESSION_RECORDING_INFO', function (evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_RECORDING_INFO');
            var localCall = findLocalCallByCallId(evt.sessionId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                return;
            }
            $rootScope.$apply(function () {
                setRecordingInfoData(localCall, evt.recordingInfo);
            });
        });

        _clientApiHandler.on('RTCSession.SESSION_TERMINATION_TIMER_STARTED', function (evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_TERMINATION_TIMER_STARTED');
            var localCall = findLocalCallByCallId(evt.sessionId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                return;
            }
            $rootScope.$apply(function () {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/sessionTerminateTimerChanged (started)');
                PubSubSvc.publish('/call/sessionTerminateTimerChanged', [localCall.callId, evt.endTime]);
            });
        });

        _clientApiHandler.on('RTCSession.SESSION_TERMINATION_TIMER_CANCELLED', function (evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.SESSION_TERMINATION_TIMER_CANCELLED');
            var localCall = findLocalCallByCallId(evt.sessionId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: Unexpected event. There is no local call');
                return;
            }
            $rootScope.$apply(function () {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/sessionTerminateTimerChanged (cancelled)');
                PubSubSvc.publish('/call/sessionTerminateTimerChanged', [localCall.callId, null]);
            });
        });

        _clientApiHandler.on('RTCCall.PROGRESS', function (evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCCall.PROGRESS');

            var localCall = findLocalCallByCallId(evt.sessionId);
            if (!localCall) {
                LogSvc.info('[CircuitCallControlSvc]: Event is not for local or remote call. Ignore it.');
                return;
            }

            if (localCall.isDirect) {
                if (!localCall.instanceId) {
                    // Make sure we always have the instanceId set in our call object (important for Qos)
                    localCall.setInstanceId(evt.instanceId);
                }
                if (!evt.sdp && !localCall.earlyMedia && evt.progressType === Constants.RTCProgressType.ALERTING) {
                    if (localCall.isTelephonyCall) {
                        // Telephony calls might have media renegotiations, signal the session controller to
                        // pre-allocate a new peer connection
                        LogSvc.debug('[CircuitCallControlSvc]: Pre-allocate a new peer connection in case of immediate renegotiations');
                        localCall.sessionCtrl.prepareForRenegotiation();
                    }
                    localCall.receivedAlerting = true;
                    LogSvc.debug('[CircuitCallControlSvc]: Publish /sound/call/alerting/started, call ID: ', localCall.callId);
                    PubSubSvc.publish('/sound/call/alerting/started', [localCall]);
                } else if (evt.sdp && evt.progressType !== Constants.RTCProgressType.EARLY_CONNECT) {
                    localCall.earlyMedia = true;
                    if (localCall.receivedAlerting) {
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /sound/call/alerting/stopped, call ID: ', localCall.callId);
                        PubSubSvc.publish('/sound/call/alerting/stopped', [localCall]);
                    }
                }
            }

            if (!localCall.checkState([Enums.CallState.Initiated, Enums.CallState.Delivered])) {
                LogSvc.info('[CircuitCallControlSvc]: Ignore PROGRESS. Call state =', localCall.state.name);
                return;
            }

            if (evt.to && localCall.isTelephonyCall && !$rootScope.localUser.isATC) {
                // For ATC users the display will be updated by the CSTA Delivered event
                setCallPeerUser(localCall, evt.to.phoneNumber, evt.to.fullyQualifiedNumber, evt.to.displayName, evt.to.userId);
            }

            try {
                $rootScope.$apply(function () {
                    if (evt.sdp && (evt.sdp.type === 'answer' || evt.sdp.type === 'pranswer')) {
                        // This is an early media (pranswer) indication.
                        evt.sdp.type = 'pranswer';
                        localCall.isPranswer = true;
                        localCall.sessionCtrl.setRemoteDescription(evt.sdp, function (err) {
                            if (err) {
                                LogSvc.error('[CircuitCallControlSvc]: Error setting remote description: ', err);
                                localCall.setDisconnectCause(Constants.DisconnectCause.REMOTE_SDP_FAILED, 'type=' + evt.sdp.type + ' origin=' + sdpParser.getOrigin(evt.sdp.sdp));
                                leaveCall(localCall, null, Enums.CallClientTerminatedReason.SET_REMOTE_SDP_FAILED);
                            }
                        });
                    }
                    localCall.setState(Enums.CallState.Delivered);
                    publishCallState(localCall);
                });
            } catch (e) {
                LogSvc.error('[CircuitCallControlSvc]: Exception handling RTCCall.PROGRESS event.', e);
            }
        });

        _clientApiHandler.on('RTCSession.LIVE_TRANSCRIPTION', function (evt) {
            LogSvc.debug('[CircuitCallControlSvc]: Received RTCSession.LIVE_TRANSCRIPTION');
            var localCall = findLocalCallByCallId(evt.sessionId);
            if (!localCall) {
                LogSvc.info('[CircuitCallControlSvc]: Event is not for local call. Ignore it.');
                return;
            }

            if (!evt.text) {
                // Transcription has no text !?!?!
                return;
            }

            $rootScope.$apply(function () {
                // Add timestamp and user object to transcription data
                evt.timestamp = Date.now();
                evt.user = evt.userId === $rootScope.localUser.userId ? $rootScope.localUser : localCall.getParticipant(evt.userId);

                var transcription = localCall.transcription;
                if (evt.intermediate) {
                    LogSvc.info('[CircuitCallControlSvc]: Handle intermediate transcription from ', evt.userId);
                    evt.text += ' ...';
                    transcription.intermediate = transcription.intermediate || [];
                    // See if there is already an intermediate transcription for this user
                    var found = transcription.intermediate.some(function (data) {
                        if (data.userId === evt.userId) {
                            // Update existing intermediate transcription
                            data.timestamp = evt.timestamp;
                            data.text = evt.text;
                            return true;
                        }
                        return false;
                    });
                    if (!found) {
                        // Add new intermediate transcription to the end
                        transcription.intermediate.push(evt);
                    }
                } else {
                    LogSvc.info('[CircuitCallControlSvc]: Handle final transcription from ', evt.userId);
                    // Remove any intermediate transcription from the same user
                    transcription.intermediate && transcription.intermediate.some(function (data, idx) {
                        if (data.userId === evt.userId) {
                            transcription.intermediate.splice(idx, 1);
                            return true;
                        }
                        return false;
                    });
                    transcription.history = transcription.history || [];
                    transcription.history.push(evt);
                    if (transcription.history.length > MAX_TRANSCRIPTIONS) {
                        // Discard the oldest transcription
                        transcription.history.shift();
                    }
                }
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/liveTranscription event');
                PubSubSvc.publish('/call/liveTranscription', [localCall, evt]);
            });
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.simulateRtcSessionEvent = function (rtcSession, evtForLocalCall) {
            var evt = {
                type: Constants.ContentType.RTC_SESSION,
                rtcSession: rtcSession
            };
            if (evtForLocalCall) {
                var activeCall = _that.getActiveCall();
                if (activeCall) {
                    evt.rtcSession.sessionId = activeCall.callId;
                }
            }
            _clientApiHandler.simulateEvent(evt);
        };

        /**
         * Used by SDK to initialize active sessions.
         * Returns a promise that is fullfilled when all sessions have been processed
         * and the different calls arrays are populated.
         */
        this.initActiveSessionsForSdk = function () {
            _conversationLoaded = true;
            return initActiveSessions();
        };

        /**
         * Used by unit tests to place the CircuitCallControlSvc in a state where it can
         * start processing the RTC events.
         */
        this.initForUnitTests = function () {
            _conversationLoaded = true;
        };

        /**
         * Set indication of whether incoming remote video should be disabled by default for
         * new incoming and outgoing Circuit calls.
    .    *
         * @param {boolean} value true indicates that incoming video is disabled by default.
         */
        this.setDisableRemoteVideoByDefault = function (value) {
            LogSvc.info('[CircuitCallControlSvc] Setting _disableRemoteVideoByDefault to ', !!value);
            _disableRemoteVideoByDefault = !!value;
        };

        /**
         * Get indication of whether incoming remote video is currently disabled by default.
    .    *
         * @returns {boolean} true indicates that incoming video is disabled by default.
         */
        this.getDisableRemoteVideoByDefault = function () {
            return _disableRemoteVideoByDefault;
        };

        /**
         * Set indication of whether Client Diagnostics are enabled/disabled
    .
         *
         * @param {boolean} value true=disabled; false=enabled
         */
        this.setClientDiagnosticsDisabled = function (value) {
            _clientDiagnosticsDisabled = value;
        };

        /**
         * Get all of the calls.
         *
         * @returns {Array} An array of Call object.
         */
        this.getCalls = function () {
            return _calls;
        };

        /**
         * Get all the phone calls
         *
         * @param {boolean} onlyLocal true=only local phone calls
         * @return {Array} An array of Call object
         */
        this.getPhoneCalls = function (onlyLocal) {
            return _calls.filter(function (call) {
                return call.isTelephonyCall && (!onlyLocal || !call.isRemote);
            });
        };

        this.getEstablishedLocalPhoneCalls = function () {
            return _calls.filter(function (call) {
                return call.isTelephonyCall && call.state.established && !call.isRemote;
            });
        };

        /**
         * Get the active local WebRTC call.
         *
         * @param {String} callId The ID of existing call or conference
         * @returns {LocalCall} The LocalCall object.
         */
        this.getActiveCall = function (callId) {
            if (callId) {
                return findLocalCallByCallId(callId);
            }
            if (_primaryLocalCall && _primaryLocalCall.replaces) {
                return _primaryLocalCall.replaces;
            }
            return _primaryLocalCall || null;
        };

        /**
         * Returns true if client has an active local WebRTC call.
         *
         * @returns {Boolean}
         */
        this.hasActiveCall = function () {
            return !!_primaryLocalCall;
        };

        /**
         * Checks if there is an active local WebRTC call being established or
         * in the middle of a media renegotiation.
         *
         * @param {String} callId The ID of existing call or conference
         * @returns {Booleam} True if there is a connection in progress.
         */
        this.isConnectionInProgress = function (callId) {
            var activeCall = _that.getActiveCall(callId);
            return !!activeCall && !activeCall.sessionCtrl.isConnStable();
        };

        /**
         * Get the ringing incoming WebRTC call,
         * null will be returned if there is no incoming call.
         *
         * @returns {LocalCall} The LocalCall object.
         */
        this.getIncomingCall = function () {
            return _incomingCalls[0] || null;
        };

        /**
         * Get the active remote WebRTC call.
         *
         * @returns {Array} The RemoteCall object array.
         */
        this.getActiveRemoteCall = function () {
            return _activeRemoteCalls;
        };

        /**
         * Join an existing group call or conference
         *
         * @param {String} callId The ID of existing group call or conference
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.joinGroupCall = function (callId, mediaType, cb) {
            cb = cb || NOP;
            if (!callId) {
                LogSvc.warn('[CircuitCallControlSvc]: joinGroupCall - Invalid Call Id');
                cb('Missing callId');
                return;
            }
            var call = findCall(callId);
            if (!call || !call.isRemote || call.state.name !== Enums.CallState.Started.name || !!call.activeClient) {
                LogSvc.warn('[CircuitCallControlSvc]: joinGroupCall invoked without a valid call');
                cb('Invalid call');
                return;
            }
            var conversation = getConversation(call.convId);
            if (!conversation || !conversation.call || conversation.call.callId !== call.callId) {
                LogSvc.warn('[CircuitCallControlSvc]: joinGroupCall - Cannot find valid conversation');
                cb('Invalid conversation');
                return;
            }
            var options = createCallOptions({
                callOut: false,
                handover: false,
                joiningGroupCall: true
            });
            if (createLocalCall(conversation, mediaType, options, cb)) {
                checkMediaSourcesAndJoin(conversation, mediaType, options, cb);
            }
        };

        /**
         * Make a new outgoing call in an existing conversation.
         *
         * @param {String} convId The conversation ID for the conversation to start the call.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.makeCall = function (convId, mediaType, cb) {
            cb = cb || NOP;
            if (!convId) {
                LogSvc.warn('[CircuitCallControlSvc]: makeCall - Invalid Conversation Id');
                cb('Missing convId');
                return;
            }
            var conversation = getConversation(convId);
            if (!conversation) {
                LogSvc.warn('[CircuitCallControlSvc]: makeCall - Cannot find conversation with id ', convId);
                cb('Conversation not found');
                return;
            }
            var callOptions = createCallOptions({callOut: true, handover: false});
            if (createLocalCall(conversation, mediaType, callOptions, cb)) {
                checkMediaSourcesAndJoin(conversation, mediaType, callOptions, cb);
            }
        };

        this.ignorePickupCall = function () {
            if (!_callToPickup) {
                return;
            }
            LogSvc.info('[CircuitCallControlSvc]: Ignore pickup notification. callId = ', _callToPickup.callId);
            dismissNotification(_callToPickup);
            var conversation = getConversation(_callToPickup.convId);
            if (conversation && conversation.isTemporary) {
                LogSvc.debug('[CircuitCallControlSvc]: Publish /conversation/temporary/ended event');
                PubSubSvc.publish('/conversation/temporary/ended', [conversation]);
            }
            terminateCall(_callToPickup);
            LogSvc.info('[CircuitCallControlSvc]: Publish /call/terminated');
            PubSubSvc.publish('/call/terminated', [_callToPickup]);

            _callToPickup = null;
        };

        /**
         * Make a test call.
         *
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} onConversationCreated A callback function that is immediately invoked with the rtcSessionId for the test call.
         * @param {Function} onCallStarted A callback function when the call is started or in case there is an error.
         */
        this.makeEchoTestCall = function (mediaType, onConversationCreated, onCallStarted) {
            _that.makeEchoTestCallWithOptions(mediaType, null, onConversationCreated, onCallStarted);
        };

        /**
         * Make a test call.
         *
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Object} additionalCallOptions The additional call options object, e.g. {desiredRegion: 'eu'}.
         * @param {Function} onConversationCreated A callback function that is immediately invoked with the rtcSessionId for the test call.
         * @param {Function} onCallStarted A callback function when the call is started or in case there is an error.
         */
        this.makeEchoTestCallWithOptions = function (mediaType, additionalCallOptions, onConversationCreated, onCallStarted) {
            onConversationCreated = onConversationCreated || NOP;
            onCallStarted = onCallStarted || NOP;
            var testConvId = 'echo_' + $rootScope.localUser.clientId;

            var conversation = {
                creatorId: $rootScope.localUser.userId,
                convId: testConvId,
                rtcSessionId: 'echo_' + $rootScope.localUser.clientId,
                participants: [{userId: $rootScope.localUser.userId}],
                testCall: true
            };
            conversation = Conversation.extend(conversation);
            onConversationCreated(conversation.rtcSessionId);

            // Wait a little before initiating the test call
            $timeout(function () {
                var options = createCallOptions({callOut: false, handover: false}, additionalCallOptions);
                if (createLocalCall(conversation, mediaType, options, onCallStarted)) {
                    joinSession(conversation, mediaType, options, onCallStarted);
                }
            }, 200);
        };

        /**
         * Make a new outgoing call to a given DN.
         *
         * @param {String} dialedDn  The dialed number.
         * @param {String} toName The display name of the called circuit user
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error, executed when the call is successfully placed.
         */
        this.dialNumber = function (dialedDn, toName, mediaType, cb) {
            cb = cb || NOP;
            ConversationSvc.getTelephonyConversation(function (err, conversation) {
                if (err || !conversation) {
                    cb(err);
                } else {
                    var callOptions = createCallOptions({
                        callOut: true,
                        handover: false,
                        dialedDn: dialedDn,
                        toName: toName
                    });
                    if (createLocalCall(conversation, mediaType, callOptions, cb)) {
                        checkMediaSourcesAndJoin(conversation, mediaType, callOptions, cb);
                    }
                }
            });
        };

        /**
         * Make a new outgoing call to a given DN.
         *
         * @param {Object} [destination] Object The destination to be called.
         * @param {String} [destination.dialedDn] The dialed number.
         * @param {String} [destination.toName] The display name of the called circuit user.
         * @param {String} [destination.userId] Id of the dialed user (if available). Otherwise null or empty string.
         * @param {Object} [destination.mediaType] The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error, executed when the call is successfully placed.
         */
        this.dialPhoneNumber = function (destination, cb) {
            cb = cb || NOP;
            destination = destination || {};
            ConversationSvc.getTelephonyConversation(function (err, conversation) {
                if (err || !conversation) {
                    cb(err);
                } else {
                    var options = createCallOptions({
                        callOut: true,
                        handover: false,
                        dialedDn: destination.dialedDn,
                        toName: destination.toName,
                        userId: destination.userId,
                        dtmfDigits: destination.dtmfDigits
                    });
                    if (!canInitiateCall(conversation, options, cb)) {
                        return;
                    }
                    if (_primaryLocalCall && _primaryLocalCall.isTelephonyCall && !_primaryLocalCall.isHolding()) {
                        _that.holdCall(_primaryLocalCall.callId, function (holdErr) {
                            if (holdErr) {
                                cb && cb(holdErr);
                            } else if (createLocalCall(conversation, destination.mediaType, options, cb)) {
                                checkMediaSourcesAndJoin(conversation, destination.mediaType, options, cb);
                            }
                        });
                        return;
                    }
                    if (createLocalCall(conversation, destination.mediaType, options, cb)) {
                        checkMediaSourcesAndJoin(conversation, destination.mediaType, options, cb);
                    }
                }
            });
        };

        /**
         * Start a conference in an existing group conversation.
         *
         * @param {String} convId The ID of existing group conversation to start the conference.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.startConference = function (convId, mediaType, cb) {
            _that.startConferenceWithOptions(convId, mediaType, null, cb);
        };

        /**
         * Start a conference in an existing group conversation.
         *
         * @param {String} convId The ID of existing group conversation to start the conference.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Object} additionalCallOptions The additional call options object, e.g. {desiredRegion: 'eu'}.
         * @param {Function} cb A callback function replying with an error
         */
        this.startConferenceWithOptions = function (convId, mediaType, additionalCallOptions, cb) {
            mediaType = mediaType || { audio: true, video: false };
            cb = cb || NOP;
            if (!convId) {
                LogSvc.warn('[CircuitCallControlSvc]: startConference - Invalid Conversation Id');
                cb('Missing convId');
                return;
            }
            var conversation = getConversation(convId);
            if (!conversation) {
                LogSvc.warn('[CircuitCallControlSvc]: startConference - Cannot find conversation with id ', convId);
                cb('Conversation not found');
                return;
            }
            if (conversation.type !== Constants.ConversationType.GROUP && conversation.type !== Constants.ConversationType.LARGE) {
                LogSvc.warn('[CircuitCallControlSvc]: startConference - Invalid conversation type');
                cb('Invalid conversation type');
                return;
            }

            var callOptions = createCallOptions({callOut: false, handover: false}, additionalCallOptions);

            if (createLocalCall(conversation, mediaType, callOptions, cb)) {
                checkMediaSourcesAndJoin(conversation, mediaType, callOptions, cb);
            }
        };

        /**
         * Answer the incoming call. Any existing active call will be terminated.
         *
         * @param {String} callId The call ID of incoming call to be answered.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.answerCall = function (callId, mediaType, cb) {
            cb = cb || NOP;
            var incomingCall = null;
            var incomingCallIdx;
            _incomingCalls.some(function (call, idx) {
                if (callId === call.callId) {
                    incomingCall = call;
                    incomingCallIdx = idx;
                    return true;
                }
                return false;
            });
            if (!incomingCall) {
                LogSvc.warn('[CircuitCallControlSvc]: answerCall - There is no alerting call');
                cb('No alerting call');
                return;
            }

            var normalizedMediaType, warning;
            function answerCallContinue() {
                if (!incomingCall.isPresent()) {
                    cb('No call to answer');
                    return;
                }
                _secondaryLocalCall = _primaryLocalCall;
                _primaryLocalCall = incomingCall;
                simulateActiveSpeakers();

                _incomingCalls.splice(incomingCallIdx, 1);
                dismissNotification(_primaryLocalCall);

                //Cancel ringing timer if running
                _primaryLocalCall.stopRingingTimer();

                _primaryLocalCall.setState(_primaryLocalCall.isATCCall ? Enums.CallState.Waiting : Enums.CallState.Answering);

                var conversation = getConversation(_primaryLocalCall.convId);
                if (!conversation || !conversation.call || conversation.call.callId !== _primaryLocalCall.callId) {
                    LogSvc.warn('[CircuitCallControlSvc]: joinCall - Cannot find valid conversation');
                    decline(_primaryLocalCall, {type: Constants.InviteRejectCause.BUSY}, false);
                    cb('Conversation not found');
                    return;
                }

                publishCallState(_primaryLocalCall);
                joinSessionCalled(conversation, normalizedMediaType, {warning: warning}, cb);
            }

            function getMediaSources() {
                checkMediaSources(mediaType, function (updatedMediaType, warn) {
                    if (warn) {
                        var conversation = ConversationSvc.getConversationFromCache(incomingCall.convId);
                        if (conversation && conversation.isTelephony) {
                            // Don't allow phone call to continue if we cannot access a microphone
                            LogSvc.warn('[CircuitCallControlSvc]: Phone calls are not possible without a microphone. Do not answer call.');
                            cb('res_AnswerCallFailedNoMic');
                            return;
                        }
                    }
                    normalizedMediaType = updatedMediaType;
                    warning = warn;
                    answerCallContinue();
                });
            }

            if (_primaryLocalCall && _primaryLocalCall.isPresent() && !incomingCall.replaces &&
                    (!_primaryLocalCall.isTelephonyCall || !incomingCall.isTelephonyCall || !$rootScope.localUser.isRegisterTC)) {
                leaveCall(_primaryLocalCall, null, Enums.CallClientTerminatedReason.USER_ENDED);
                if (_secondaryLocalCall) {
                    leaveCall(_secondaryLocalCall, null, Enums.CallClientTerminatedReason.USER_ENDED);
                }
            } else if (_primaryLocalCall && _primaryLocalCall.isTelephonyCall && $rootScope.localUser.isRegisterTC && !_primaryLocalCall.isHolding()) {
                _that.holdCall(_primaryLocalCall.callId, function (err) {
                    if (err) {
                        cb(err);
                    } else {
                        getMediaSources();
                    }
                });
                return;
            }
            getMediaSources();
        };

        /**
         * Send early media for the incoming call. This is only applicable for incoming audio-only telephony calls.
         *
         * @param {String} callId The call ID of incoming call to be procesed.
         * @returns {Promise} Promise resolved when operation is completed.
         */
        this.prepareEarlyMedia = function (callId) {
            return new $q(function (resolve, reject) {
                var incomingCall = _incomingCalls.find(function (call) {
                    return callId === call.callId;
                });
                if (!incomingCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: prepareEarlyMedia - There is no alerting call');
                    reject('No alerting call');
                    return;
                }

                var sessionCtrl = incomingCall.sessionCtrl;
                if (!sessionCtrl.getWarmedUpSdp()) {
                    LogSvc.warn('[CircuitCallControlSvc]: prepareEarlyMedia - Not warmed up session');
                    reject('Not warmed up');
                    return;
                }

                var onSdp = function (evt) {
                    sessionCtrl.onSessionDescription = null;

                    LogSvc.info('[CircuitCallControlSvc]: Save early media SDP for ANSWER: ', evt.sdp);
                    incomingCall.earlyMediaSdp = evt.sdp;
                };

                sessionCtrl.onSessionDescription = onSdp;

                LogSvc.info('[CircuitCallControlSvc]: Start RTC session to send early media for callId ', incomingCall.callId);
                if (sessionCtrl.start({ audio: true }, null, true)) {
                    incomingCall.startedEarlyMedia = true;
                    resolve();
                }
            });
        };

        /**
         * End a local call.
         *
         * @param {String} callId The call ID of local call to be terminated.
         * @param {Function} cb A callback function replying with an error
         */
        this.endCall = function (callId, cb) {
            var cause = Constants.InviteRejectCause.DECLINE;
            var activeCall = _that.getActiveCall();
            if (activeCall && activeCall.callId === callId) {
                if (activeCall.isEstablished() || activeCall.direction === Enums.CallDirection.OUTGOING) {
                    cause = null; // This will be defaulted to USER_ENDED
                }
            }
            _that.endCallWithCauseCode(callId, cause, cb);
        };

        /**
         * End a local call specifying the cause.
         *
         * @param {String} callId The call ID of local call to be terminated.
         * @param {String} cause Cause code for terminating the call. It can be either InviteRejectCause or CallClientTerminatedReason.
         * @param {Function} cb A callback function replying with an error
         */
        this.endCallWithCauseCode = function (callId, cause, cb) {
            var qosCause = Enums.CallClientTerminatedReason.USER_ENDED;
            var inviteRejectCause = Constants.InviteRejectCause.DECLINE;

            if (cause) {
                qosCause = cause;
                if (Constants.InviteRejectCause[cause]) {
                    // cause is an InviteRejectCause value
                    inviteRejectCause = cause;
                }
            }

            var localCall = findLocalCallByCallId(callId);
            if (localCall) {
                if (localCall.replaces && localCall.checkState(Enums.CallState.Answering)) {
                    cb('Auto answer in progress');
                } else if (localCall.terminateTimer) {
                    terminateCall(localCall, qosCause);
                    // Only initiate timeout if there is a callback
                    cb && $timeout(cb);
                } else {
                    leaveCall(localCall, inviteRejectCause, qosCause, false, cb);
                }
            } else if (_primaryLocalCall && _primaryLocalCall.replaces && _primaryLocalCall.replaces.callId === callId) {
                leaveCall(_primaryLocalCall.replaces, inviteRejectCause, qosCause, false, cb);
            } else {
                var activeCall = findCall(callId);
                if (activeCall) {
                    if (activeCall.terminateTimer) {
                        terminateCall(activeCall);
                        cb && $timeout(cb);
                    } else {
                        leaveCall(activeCall, inviteRejectCause, qosCause, false, cb);
                    }
                    return;
                }
                var alertingCall = getIncomingCall(callId);
                if (alertingCall) {
                    decline(alertingCall, {type: inviteRejectCause}, false, cb);
                } else {
                    LogSvc.warn('[CircuitCallControlSvc]: endCall - There is no local or alerting call');
                    var conversation = ConversationSvc.getConversationByRtcSession(callId);
                    if (conversation && conversation.call) {
                        conversation.call = null;
                        publishConversationUpdate(conversation);
                    }

                    cb && cb('Call not found');
                }
            }
        };

        /**
         * End conference (terminate rtc call).
         *
         * @param {String} callId The call ID of local call used to terminate the conference.
         * @param {Function} cb A callback function replying with an error
         */
        this.endConference = function (callId, cb) {
            var call = findCall(callId);
            if (!call) {
                cb && cb('Call not found');
            } else {
                terminateConference(call, cb);
            }
        };

        /**
         * Add a video stream as screen share to an existing RTC session. The video and bandwidth
         * constraints of screen share will be used.
         * For adding it as video, please use addVideoStream() instead.
         *
         * @param {String} callId The call ID of call to add screen share to.
         * @param {MediaStream} stream The media stream to share.
         * @param {Function} cb A callback function replying with an error
         */
        this.addMediaStream = function (callId, stream, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: addMediaStream - There is no local call');
                cb('No active call');
                return;
            }

            var mediaType = Object.assign({}, localCall.localMediaType);
            mediaType.desktop = true;
            if (!isVideoAndScreenShareEnabled(localCall)) {
                mediaType.video = false;
            }

            var data = {
                callId: callId,
                mediaType: mediaType,
                screenShareOptions: {
                    mediaStream: stream
                }
            };

            changeMediaType(data, function (err) {
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc]: addMediaStream failed: ', err);
                    cb('res_AddMediaStreamFailed');
                } else {
                    cb();
                }
            });
        };

        /**
         * Add a video stream to an existing RTC session.
         * For adding a screen share stream, please use addMediaStream() instead.
         *
         * @param {String} callId The call ID of call to add video to.
         * @param {MediaStream} stream The video media stream to be added.
         * @return {Promise} Promise resolved when adding the video stream is successfully initiated
         */
        this.addVideoStream = function (callId, stream) {
            return new $q(function (resolve, reject) {
                var localCall = findLocalCallByCallId(callId);
                if (!localCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: addVideoStream - There is no local call');
                    reject('No active call');
                    return;
                }
                if (!stream) {
                    LogSvc.warn('[CircuitCallControlSvc]: addVideoStream - There is stream to be added');
                    reject('No stream to be added');
                    return;
                }

                var mediaType = Object.assign({}, localCall.localMediaType);
                mediaType.video = true;
                if (!isVideoAndScreenShareEnabled(localCall)) {
                    // This client cannot transmit video and screenshare simultaneously: turn off screenshare
                    mediaType.desktop = false;
                }

                var data = {
                    callId: callId,
                    mediaType: mediaType,
                    videoOptions: {
                        mediaStream: stream
                    }
                };

                changeMediaType(data, function (err) {
                    if (err) {
                        LogSvc.warn('[CircuitCallControlSvc]: addVideoStream failed: ', err);
                        reject('res_AddVideoFailed');
                    } else {
                        resolve();
                    }
                });
            });
        };

        /**
         * Remove a media stream from an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove stream from.
         * @param {Function} cb A callback function replying with an error
         */
        this.removeMediaStream = function (callId, cb) {
            cb = cb || NOP;
            addRemoveMedia(callId, {desktop: false}, 'removeMediaStream')
            .then(cb)
            .catch(function () {
                cb('res_RemoveMediaStreamFailed');
            });
        };

        /**
         * Enable audio in an existing RTC session.
         *
         * @param {String} callId The call ID of call to add audio to.
         * @param {Function} cb A callback function replying with an error
         */
        this.addAudio = function (callId, cb) {
            cb = cb || NOP;
            addRemoveMedia(callId, {audio: true}, 'addAudio')
            .then(cb)
            .catch(function () {
                cb('Renegotiate for receive-only media failed');
            });
        };

        /**
         * Disable audio in an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove audio from.
         * @param {Function} cb A callback function replying with an error
         */
        this.removeAudio = function (callId, cb) {
            cb = cb || NOP;
            addRemoveMedia(callId, {audio: false}, 'removeAudio')
            .then(cb)
            .catch(function () {
                cb('Renegotiate for receive-only media failed');
            });
        };

        /**
         * Enable remote audio stream in an active call
         *
         * @param {String} callId The call ID to be muted
         */
        this.enableRemoteAudio = function (callId) {
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: enableRemoteAudio - There is no local call');
                return;
            }

            if (localCall.remoteAudioDisabled) {
                localCall.enableRemoteAudio();
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/remoteStreamUpdated event');
                PubSubSvc.publish('/call/remoteStreamUpdated', [localCall]);
            }
        };

        /**
         * Disable remote audio stream in an active call
         *
         * @param {String} callId The call ID to be muted
         */
        this.disableRemoteAudio = function (callId) {
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: disableRemoteAudio - There is no local call');
                return;
            }

            if (!localCall.remoteAudioDisabled) {
                localCall.disableRemoteAudio();
                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/remoteStreamUpdated event');
                PubSubSvc.publish('/call/remoteStreamUpdated', [localCall]);
            }
        };

        /**
         * Enable video in an existing RTC session.
         *
         * @param {String} callId The call ID of call to add video to.
         * @param {Function} cb A callback function replying with an error
         */
        this.addVideo = function (callId, cb) {
            cb = cb || NOP;
            addRemoveMedia(callId, {video: true}, 'addVideo')
            .then(cb)
            .catch(function () {
                cb('res_AddVideoFailed');
            });
        };

        /**
         * Disable video in an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.removeVideo = function (callId, cb) {
            cb = cb || NOP;
            addRemoveMedia(callId, {video: false}, 'removeVideo')
            .then(cb)
            .catch(function () {
                cb('res_RemoveVideoFailed');
            });
        };

        /**
         * Disable remote video streams only in an existing RTC session.
         * Remote Screen share is be allowed.
         * @param {String} callId The call ID of call to remove remote video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.disableRemoteVideoOnly = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleRemoteVideo - There is no local call');
                cb('No active call');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: toggleRemoteVideo...');

            var sessionCtrl = localCall.sessionCtrl;
            if (!sessionCtrl.isConnStable()) {
                cb('Connection not stable');
                return;
            }

            // Make sure that remote video is allowed
            if (localCall.remoteVideoDisabled) {
                cb('Remote video and screen share are already disabled');
                return;
            }

            // Make sure that this option is not already enabled
            if (localCall.remoteVideoScreenOnlyAllowed) {
                cb('Remote video is already disabled');
                return;
            }

            var errorCb = function (err) {
                // The media renegotiation failed. So restore the original state.
                localCall.disableRemoteVideoScreenOnly();
                LogSvc.warn('[CircuitCallControlSvc]: disableRemoteVideoOnly failed: ', err);
                cb('res_ToggleVideoFailed');
            };

            var receiverConfiguration = localCall.enableRemoteVideoScreenOnly();

            if (localCall.supportsVideoReceiverConfiguration()) {
                setVideoReceiverConfiguration(localCall.callId, receiverConfiguration)
                .then(function () {
                    // Publish event here to prevent UI changes during disabling video streams
                    PubSubSvc.publish('/call/toggleRemoteVideo');
                    cb();
                })
                .catch(errorCb);

            } else {
                // Publish event here to prevent UI changes during disabling video streams
                PubSubSvc.publish('/call/toggleRemoteVideo');

                var data = {
                    callId: callId,
                    mediaType: localCall.localMediaType
                };

                changeMediaType(data, function (err) {
                    if (err) {
                        errorCb(err);
                    } else {
                        cb();
                    }
                });
            }
        };

        /**
         * Enable remote and screen share video streams in an existing
         * RTC session.
         *
         * @param {String} callId The call ID of call to remove remote video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.enableRemoteVideo = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: enableRemoteVideo - There is no local call');
                cb('No active call');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: enableRemoteVideo...');

            var sessionCtrl = localCall.sessionCtrl;
            if (!sessionCtrl.isConnStable()) {
                cb('Connection not stable');
                return;
            }

            var receiverConfiguration = localCall.enableRemoteVideo();

            var successCb = function () {
                cb();
                !localCall.remoteVideoDisabled && PubSubSvc.publish('/call/toggleRemoteVideo');
            };

            var errorCb = function (err) {
                // The media renegotiation failed. So restore the original state.
                localCall.enableRemoteVideoScreenOnly();
                LogSvc.warn('[CircuitCallControlSvc]: enableRemoteVideo failed: ', err);
                cb('res_ToggleVideoFailed');
            };

            if (localCall.supportsVideoReceiverConfiguration()) {
                setVideoReceiverConfiguration(localCall.callId, receiverConfiguration)
                .then(successCb)
                .catch(errorCb);
            } else {
                var data = {
                    callId: callId,
                    mediaType: localCall.localMediaType
                };

                changeMediaType(data, function (err) {
                    if (err) {
                        errorCb(err);
                    } else {
                        successCb();
                    }
                });
            }
        };


        /**
         * Toggle (allow or block) remote video streams in an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove remote video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleRemoteVideo = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleRemoteVideo - There is no local call');
                cb('No active call');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: toggleRemoteVideo...');

            var sessionCtrl = localCall.sessionCtrl;
            if (!sessionCtrl.isConnStable()) {
                cb('Connection not stable');
                return;
            }

            var receiverConfiguration;
            if (localCall.remoteVideoDisabled) {
                receiverConfiguration = localCall.enableRemoteVideo();
            } else {
                receiverConfiguration = localCall.disableRemoteVideo();
                // Publish event here to prevent UI changes during disabling video
                PubSubSvc.publish('/call/toggleRemoteVideo');
            }

            var successCb = function () {
                cb();
                !localCall.remoteVideoDisabled && PubSubSvc.publish('/call/toggleRemoteVideo');
            };

            var errorCb = function (err) {
                // The media renegotiation failed. So restore the original state.
                if (localCall.remoteVideoDisabled) {
                    localCall.enableRemoteVideo();
                } else {
                    localCall.disableRemoteVideo();
                }
                LogSvc.warn('[CircuitCallControlSvc]: toggleRemoteVideo failed: ', err);
                cb('res_ToggleVideoFailed');
            };

            if (localCall.supportsVideoReceiverConfiguration()) {
                LogSvc.debug('New video receiver configuration: ', receiverConfiguration);
                setVideoReceiverConfiguration(localCall.callId, receiverConfiguration)
                .then(successCb)
                .catch(errorCb);
            } else {
                var data = {
                    callId: callId,
                    mediaType: localCall.localMediaType
                };

                changeMediaType(data, function (err) {
                    if (err) {
                        errorCb(err);
                    } else {
                        successCb();
                    }
                });
            }
        };

        /**
         * Change the HD video in an existing RTC session.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {boolean} hdVideo Turn the HD video on/off.
         * @param {Function} cb A callback function replying with an error (1st parameter). If the desired video resolution is not supported,
         * it will attempt to use a lower resolution defined in Circuit.Enums.VideoResolutionLevel. If successful,
         * 'res_ToggleVideoResolutionNotSupported' is returned as a warning (2nd parameter).
         * @param {VideoResolutionLevel} videoResolution Desired HD resolution. Defaults to max HD resolution supported by camera.
         */
        this.changeHDVideo = function (callId, hdVideo, cb, videoResolution) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: changeHDVideo - There is no local call');
                cb('No active call');
                return;
            }
            if (!localCall.localMediaType.video) {
                LogSvc.warn('[CircuitCallControlSvc]: changeHDVideo - Local call has no video');
                cb('No video');
                return;
            }
            hdVideo = !!hdVideo;

            if (!circuit.isSDK && !$rootScope.localUser.canUseHDVideo) {
                LogSvc.warn('[CircuitCallControlSvc]: changeHDVideo - User does not have HD video permission');
                cb('No HD permission');
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: changeHDVideo - hdVideo = ', hdVideo);

            if (localCall.localMediaType.hdVideo === hdVideo) {
                LogSvc.debug('[CircuitCallControlSvc]: changeHDVideo - No changes');
                cb(null, null);
                return;
            }

            if (!isValidVideoResolution(videoResolution)) {
                videoResolution = null;
            }

            var mediaType = Object.assign({}, localCall.localMediaType);
            mediaType.hdVideo = hdVideo;
            mediaType.videoResolution = videoResolution;

            var data = {
                callId: callId,
                mediaType: mediaType
            };

            changeMediaType(data, function (err) {
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc]: changeHDVideo failed: ', err);
                    cb('res_ToggleVideoFailed');
                } else {
                    var warning = null;
                    if (videoResolution && videoResolution !== localCall.localMediaType.videoResolution) {
                        warning = 'res_ToggleVideoResolutionNotSupported';
                    }
                    cb(null, warning);
                }
            });
        };

        /**
         * Change video resolution in an existing RTC session.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {String} newResolution New HD video resolution.
         * @param {Function} cb A callback function replying with an error (1st parameter). If the new resolution is not supported,
         * it will attempt to use a lower resolution defined in Circuit.Enums.VideoResolutionLevel. If successful,
         * 'res_ToggleVideoResolutionNotSupported' is returned as a warning (2nd parameter).
         */
        this.changeVideoResolution = function (callId, newResolution, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: changeVideoResolution - There is no local call');
                cb('No active call');
                return;
            }
            if (!localCall.localMediaType.video || !localCall.localMediaType.hdVideo) {
                LogSvc.warn('[CircuitCallControlSvc]: changeVideoResolution - Local call has no HD video');
                cb('No HD video');
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: changeVideoResolution - newResolution = ', newResolution);

            if (!isValidVideoResolution(newResolution)) {
                cb('Invalid resolution');
                return;
            }

            if (localCall.localMediaType.videoResolution === newResolution) {
                LogSvc.debug('[CircuitCallControlSvc]: changeVideoResolution - No changes');
                cb(null, null);
                return;
            }

            var mediaType = Object.assign({}, localCall.localMediaType);
            mediaType.videoResolution = newResolution;

            var data = {
                callId: callId,
                mediaType: mediaType
            };

            changeMediaType(data, function (err) {
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc]: changeVideoResolution failed: ', err);
                    cb('res_ToggleVideoFailed');
                } else {
                    cb(null, localCall.localMediaType.videoResolution !== newResolution ? 'res_ToggleVideoResolutionNotSupported' : null);
                }
            });
        };

        /**
         * Toggle the video in an existing RTC session.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleVideo = function (callId, cb) {
            cb = cb || NOP;

            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleVideo - There is no local call');
                cb('No active call');
                return;
            }

            var data = { video: !localCall.localMediaType.video };
            addRemoveMedia(callId, data, 'toggleVideo')
            .then(cb)
            .catch(function () {
                cb('res_ToggleVideoFailed');
            });
        };

        /**
         * Use a new camera in an existing RTC session. The video constraints will remain the same.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {String} deviceId The device ID of the camera to be used.
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleCamera = function (callId, deviceId, cb) {
            cb = cb || NOP;

            if (!deviceId) {
                cb('No device ID');
                return;
            }
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleVideo - There is no local call');
                cb('No active call');
                return;
            }
            if (!localCall.localMediaType.video) {
                LogSvc.warn('[CircuitCallControlSvc]: changeHDVideo - Local call has no video');
                cb('No video');
                return;
            }

            WebRTCAdapter.getMediaSources(function (input, video) {
                var deviceObject = video.find(function (d) {
                    return d.id === deviceId;
                });
                if (deviceObject) {
                    Utils.addDeviceToPreferredList(RtcSessionController.videoDevices, deviceObject);
                    addRemoveMedia(callId, {}, 'toggleCamera')
                    .then(cb)
                    .catch(function () {
                        cb('res_ToggleVideoFailed');
                    });
                } else {
                    cb('Device not found');
                }
            });
        };

        /**
         * Starts a media renegotiation from the client without changing the current media types.
         *
         * @param {String} callId The call ID of the call for which to start the media renegotiation.
         * @param {Function} cb A callback function replying with an error
         * @param {Object} options Object with options
         */
        this.renegotiateMedia = function (callId, cb, options) {
            cb = cb || NOP;
            addRemoveMedia(callId, {}, 'renegotiateMedia', options)
            .then(cb)
            .catch(function (err) {
                cb(err || 'Renegotiate media failed');
            });
        };

        /**
         * Set the audio and/or video media type for the current call.
         *
         * @param {String} callId The call ID of the call for which to start the media renegotiation.
         * @param {Object} mediaType Object with audio and video boolean attributes.
         * @param {Function} cb A callback function replying with an error
         */
        this.setMediaType = function (callId, mediaType, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: setMediaType - There is no local call');
                cb('No active call');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: setMediaType...');

            var data = {
                callId: callId,
                mediaType: mediaType
            };

            changeMediaType(data, function (err) {
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc]: Failed to renegotiate the media');
                    cb('Renegotiate media failed');
                } else {
                    cb();
                }
            });
        };

        /**
         * Starts a media renegotiation from the client with inactive mode and without changing the media types
         *
         * @param {String} callId The call ID of the call to be held
         * @param {Function} cb A callback function replying with an error
         */
        this.holdCall = function (callId, cb) {
            cb = cb || NOP;
            var call = findLocalCallByCallId(callId);
            if (!call) {
                LogSvc.warn('[CircuitCallControlSvc]: holdCall - There is no call');
                cb('No active call');
                return;
            }
            if (call.isHolding() || call.isHoldInProgress()) {
                LogSvc.debug('[CircuitCallControlSvc]: holdCall - The call is already held');
                cb(); // No error, just return since we might be answering a second telephony call
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: holdCall...');
            call.setHoldInProgress();

            var data = {
                callId: callId,
                mediaType: call.localMediaType,
                hold: true
            };

            changeMediaType(data, function (err) {
                call.clearHoldInProgress();
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc]: Failed to hold the call');
                    cb('res_HoldCallFailed');
                } else {
                    cb();
                }
            });
        };

        /**
         * Starts a media renegotiation from the client to retrieve a held call without changing the media types
         *
         * @param {String} callId The call ID of the call to be retrieved
         * @param {Function} cb A callback function replying with an error
         */
        this.retrieveCall = function (callId, cb) {
            cb = cb || NOP;
            var call = findLocalCallByCallId(callId);
            if (!call.isHolding()) {
                LogSvc.debug('[CircuitCallControlSvc]: retrieveCall - There is no held local call');
                cb();
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: retrieveCall...');
            call.setRetrieveInProgress();

            var data = {
                callId: callId,
                mediaType: call.localMediaType,
                hold: false
            };

            changeMediaType(data, function (err) {
                call.clearRetrieveInProgress();
                if (err) {
                    LogSvc.warn('[CircuitCallControlSvc]: Failed to retrieve the call');
                    cb('res_RetrieveCallFailed');
                } else {
                    if (call === _secondaryLocalCall) {
                        _secondaryLocalCall = _primaryLocalCall;
                        _primaryLocalCall = call;
                    }
                    var conversation = getConversation(_primaryLocalCall.convId);
                    conversation.call = _primaryLocalCall;
                    publishConversationUpdate(conversation);
                    cb();
                }
            });
        };

        this.swapCall = function (callId, cb) {
            cb = cb || NOP;
            _secondaryLocalCall = _that.getPhoneCalls(true).length > 1 ? findHeldPhoneCall(true) : null;
            if (!_secondaryLocalCall || !_primaryLocalCall) {
                LogSvc.warn('[CircuitCallControlSvc]: swapCall - There are no held and active local calls');
                cb('No calls to swap');
                return;
            }

            if (_secondaryLocalCall && _secondaryLocalCall.callId !== callId) {
                LogSvc.warn('[CircuitCallControlSvc]: swapCall - Wrong callId of the held call');
                cb('Wrong callId');
                return;
            }

            var heldCallId = _secondaryLocalCall.callId;
            var activeCallId = _primaryLocalCall.callId;
            _that.holdCall(activeCallId, function (err) {
                if (err) {
                    cb && cb(err);
                } else {
                    _that.retrieveCall(heldCallId, cb);
                }
            });
        };

        /**
         * Move a remote call to the local client
         *
         * @param {String} callId The call ID of existing remote call established on another client.
         * @param {BOOL} audioOnly flag to check whether to pull with audio only.
         * @param {Function} cb A callback function replying with an error
         */
        this.pullRemoteCall = function (callId, audioOnly, cb) {
            cb = cb || NOP;
            var activeRemoteCall = getActiveRemoteCall(callId);
            if (!activeRemoteCall) {
                LogSvc.warn('[CircuitCallControlSvc]: pullRemoteCall invoked without a valid call');
                cb('No active remote call');
                return;
            }
            if (activeRemoteCall.pullNotAllowed) {
                cb('res_PullCallNotAllowed');
                return;
            }
            var conversation = getConversation(activeRemoteCall.convId);
            if (!conversation || (!conversation.isTelephony && conversation.call.callId !== callId)) {
                LogSvc.warn('[CircuitCallControlSvc]: pullRemoteCall - Cannot find valid conversation');
                cb('Conversation not found');
                return;
            }

            // ANS-67474 Pull call disables video
            audioOnly = true;

            function pullCallContinue() {
                var activeClient = activeRemoteCall.activeClient || {};
                var mediaType = {
                    audio: true,
                    video: !!(activeClient.mediaType && activeClient.mediaType.video)
                };

                if (audioOnly) {
                    mediaType.video = false;
                }

                var options = createCallOptions({
                    callOut: false,
                    handover: true,
                    callId: callId,
                    pickUpFromUser: activeRemoteCall.pickUpFromUser,
                    pickUpUserId: activeRemoteCall.pickUpUserId
                });
                if (createLocalCall(conversation, mediaType, options, cb)) {
                    checkMediaSourcesAndJoin(conversation, mediaType, options, cb);
                }
            }

            if (_primaryLocalCall && _primaryLocalCall.isTelephonyCall) {
                _that.holdCall(_primaryLocalCall.callId, function (err) {
                    if (err) {
                        cb && cb(err);
                    } else {
                        pullCallContinue();
                    }

                });
                return;
            }

            pullCallContinue();
        };

        /**
         * End a remote call established on another client
         *
         * @param {String} callId The call ID of existing remote call established on another client.
         * @param {Function} cb A callback function replying with an error
         */
        this.endRemoteCall = function (callId, cb) {
            cb = cb || NOP;
            var activeRemoteCall = getActiveRemoteCall(callId);
            if (!activeRemoteCall) {
                LogSvc.warn('[CircuitCallControlSvc]: endRemoteCall invoked without a valid call');
                cb('No active remote call');
                return;
            }
            var conversation = getConversation(activeRemoteCall.convId);
            if (!conversation || conversation.call.callId !== callId) {
                LogSvc.warn('[CircuitCallControlSvc]: endRemoteCall - Cannot find valid conversation');
                cb('Conversation not found');
                return;
            }

            endRemoteCall(activeRemoteCall, function (err) {
                if (err) {
                    cb('res_EndRemoteCallFailed');
                } else {
                    changeRemoteCallToStarted(activeRemoteCall);
                    cb();
                }
            });
        };

        /**
         * Hide a remote call.
         *
         * @param {String} callId The call ID of remote call to be hidden.
         */
        this.hideRemoteCall = function (callId) {
            var activeRemoteCall = findCall(callId);
            if (!activeRemoteCall) {
                LogSvc.warn('[CircuitCallControlSvc]: hideRemoteCall invoked without a valid call');
                return;
            }
            terminateCall(activeRemoteCall);
        };

        /**
         * Remove participant in a group call.
         *
         * @param {String} callId The call ID of a group call or conference.
         * @param {Object} participant The call participant object.
         * @param {Function} cb A callback function replying with an error
         */
        this.dropParticipant = function (callId, participant, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall || localCall.isDirect) {
                LogSvc.warn('[CircuitCallControlSvc]: dropParticipant - invalid call');
                cb('Call invalid');
                return;
            }
            if (!participant || !participant.userId) {
                LogSvc.warn('[CircuitCallControlSvc]: dropParticipant - Invalid participant');
                cb('Participant invalid');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: Dropping participant in existing RTC session. userId =', participant.userId);
            removeSessionParticipant(participant.userId, cb);
        };

        /**
         * Add participant to a local call.
         *
         * @param {String} callId The call ID
         * @param {Object} participant The participant object.
         * @param {Function} cb A callback function replying with an error
         */
        this.addParticipantToCall = function (callId, participant, cb) {
            var call = findLocalCallByCallId(callId);
            if (!call) {
                LogSvc.warn('[CircuitCallControlSvc]: addParticipantToCall - There is no local call');
                cb && cb('Invalid callId');
            } else {
                addParticipant(call, participant, cb);
            }
        };

        /**
         * Add participant to an RTC Session. Unlike addParticipantToCall this API does not rely
         * on a local call being present.
         *
         * @param {String} callId The call ID
         * @param {Object} participant The participant object.
         * @param {Function} cb A callback function replying with an error
         */
        this.addParticipantToRtcSession = function (callId, participant, cb) {
            var call = findCall(callId);
            if (!call) {
                LogSvc.warn('[CircuitCallControlSvc]: addParticipantToRtcSession - Call not found');
                cb && cb('Invalid callId');
            } else {
                addParticipant(call, participant, cb);
            }
        };

        /**
         * mute self locally in an active call
         *
         * @param {String} callId The call ID to be muted
         * @param {Function} cb A callback function replying with an error
         */
        this.mute = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall || callId !== localCall.callId) {
                LogSvc.warn('[CircuitCallControlSvc]: mute - There is no local call');
                cb('Call not valid');
                return;
            }

            if (!localCall.locallyMuted) {
                LogSvc.debug('[CircuitCallControlSvc]: Mute the local call');
                localCall.mute(function () {
                    $rootScope.$apply(function () {
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /call/localUser/mutedSelf event');
                        PubSubSvc.publish('/call/localUser/mutedSelf', [callId, true]);

                        _that.muteParticipant(localCall.callId, $rootScope.localUser, function (err) {
                            err && LogSvc.warn('[CircuitCallControlSvc]: Error synchronizing local mute with backend.', err);
                        });
                        cb();
                    });
                });
            } else {
                cb('Call already muted');
            }
        };

        /**
         * unmute locally only (used for large conference)
         *
         * @param {Function} cb A callback function called on success
         */
        this.unmuteLocally = function (cb) {
            cb = cb || NOP;
            if (_primaryLocalCall) {
                if (_primaryLocalCall.locallyMuted) {
                    LogSvc.debug('[CircuitCallControlSvc]: Unmute the local call');
                    _primaryLocalCall.unmute(function (err) {
                        $rootScope.$apply(function () {
                            if (!err) {
                                LogSvc.debug('[CircuitCallControlSvc]: Publish /call/localUser/mutedSelf event');
                                PubSubSvc.publish('/call/localUser/mutedSelf', [_primaryLocalCall.callId, false]);
                            }
                            cb(err);
                        });
                    });
                } else {
                    // Already unmuted
                    cb();
                }
            } else {
                cb('Call not found');
            }
        };

        /**
         * unmute self in an active call, which may have been muted locally or remotely
         *
         * @param {String} callId The call ID to be unmuted
         * @param {Function} cb A callback function replying with an error
         */
        this.unmute = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: unmute - There is no local call');
                return;
            }

            if (!localCall.isMuted()) {
                cb('Call not muted');
                return;
            }

            // First unmute locally
            _that.unmuteLocally(function (err) {
                if (err) {
                    cb(err);
                } else {
                    // Unmute remotely
                    _that.unmuteParticipant(localCall.callId, $rootScope.localUser, cb);
                }
            });
        };

        /**
         * toggle between mute and unmute
         *
         * @param {String} callId The call ID to toggle mute
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleMute = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleMute - There is no local call');
                cb('Call not valid');
                return;
            }
            if (!localCall.isLocalMuteAllowed()) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleMute - Local Mute is not allowed');
                cb();
                return;
            }
            if (localCall.isMuted()) {
                _that.unmute(localCall.callId, cb);
            } else {
                _that.mute(localCall.callId, cb);
            }
        };

        /**
         * mute remote participant
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object to be muted
         * @param {Function} cb A callback function replying with an error
         */
        this.muteParticipant = function (callId, participant, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: muteParticipant - There is no local call');
                cb('Call invalid');
                return;
            }
            var participantId = participant && participant.userId;
            if (!participantId) {
                LogSvc.warn('[CircuitCallControlSvc]: muteParticipant - Invalid participant ID');
                cb('Participant invalid');
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: Mute participant in existing RTC session. participantId =', participantId);

            var data = {
                rtcSessionId: localCall.callId,
                usersIds: [participantId],
                muted: true
            };

            _clientApiHandler.muteParticipant(data, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[CircuitCallControlSvc]: Failed to mute participant');
                        cb('res_MuteParticipantFailed');
                    } else {
                        cb();
                    }
                });
            });
        };

        /**
         * check if there are unmuted participants
         *
         * @param {String} callId The call ID of active call
         */
        this.hasUnmutedParticipants = function (callId) {
            var localCall = findLocalCallByCallId(callId);
            if (!localCall || callId !== localCall.callId) {
                LogSvc.warn('[CircuitCallControlSvc]: hasUnmutedParticipants - There is no local call');
                return false;
            }
            return localCall.hasUnmutedParticipants();
        };

        /**
         * mute RTC session
         *
         * @param {String} callId The call ID of active call
         * @param {Function} cb A callback function replying with an error
         */
        this.muteRtcSession = function (callId, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall || callId !== localCall.callId) {
                LogSvc.warn('[CircuitCallControlSvc]: muteRtcSession - There is no local call');
                cb('Call invalid');
                return;
            }
            if (localCall.hasUnmutedParticipants()) {
                LogSvc.debug('[CircuitCallControlSvc]: Mute RTC session (all other participants)');

                var data = {
                    rtcSessionId: localCall.callId,
                    muted: true,
                    excludedUserIds: [$rootScope.localUser.userId]
                };

                _clientApiHandler.muteRtcSession(data, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[CircuitCallControlSvc]: Failed to mute RTC session');
                            cb('res_MuteAllOtherParticipantsFailed');
                        } else {
                            cb();
                        }
                    });
                });
            }
        };

        /**
         * unmute self which has been muted remotely
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object to be unmuted
         * @param {Function} cb A callback function replying with an error
         */
        this.unmuteParticipant = function (callId, participant, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: unmuteParticipant - There is no local call');
                cb('Call invalid');
                return;
            }
            var participantId = participant && participant.userId;
            if (!participantId) {
                LogSvc.warn('[CircuitCallControlSvc]: unmuteParticipant - Invalid participant ID');
                cb('Participant invalid');
                return;
            }

            if (participantId !== $rootScope.localUser.userId) {
                LogSvc.warn('[CircuitCallControlSvc]: Cannot unmute remote participant');
                cb('Cannot unmute others');
                return;
            }

            LogSvc.debug('[CircuitCallControlSvc]: Unmute participant in existing RTC session. userId =', participantId);

            var data = {
                rtcSessionId: localCall.callId,
                usersIds: [participantId],
                muted: false
            };

            _clientApiHandler.muteParticipant(data, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[CircuitCallControlSvc]: Failed to unmute participant');
                        cb('res_UnmuteParticipantFailed');
                    } else {
                        localCall.remotelyMuted = false;
                        publishMutedEvent(localCall);
                        cb();
                    }
                });
            });
        };

        /**
         * toggle between participant mute and unmute
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object for which to toggle mute
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleMuteParticipant = function (callId, participant, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleMuteParticipant - There is no local call');
                cb('Call invalid');
                return;
            }
            if (participant && participant.userId) {
                if (participant.muted) {
                    _that.unmuteParticipant(callId, participant, cb);
                } else {
                    _that.muteParticipant(callId, participant, cb);
                }
            } else {
                LogSvc.warn('[CircuitCallControlSvc]: unmuteParticipant - Invalid participant ID');
                cb('Participant invalid');
            }
        };

        this.stopRingingTone = function (callId) {
            var alertingCall = getIncomingCall(callId);

            if (!alertingCall) {
                LogSvc.warn('[CircuitCallControlSvc]: stopRingingTone - incoming call not found: ', callId);
                return;
            }

            alertingCall.ringToneStopped = true;
            LogSvc.debug('[CircuitCallControlSvc]: stopRingingTone for call with callId = ', callId);
            PubSubSvc.publish('/call/ringingTone/stop', [callId]);
        };

        /**
         * End active call.
         *
         * @param {Function} cb A callback function replying with a status
         */
        this.endActiveCall = function (cb) {
            cb = cb || NOP;
            if (_primaryLocalCall) {
                _that.endCall(_primaryLocalCall.callId, cb);
            } else {
                cb('No active call');
            }
        };

        this.startRecording = function (cb, mediaTypes, videoLayout) {
            if (!_primaryLocalCall) {
                LogSvc.warn('[CircuitCallControlSvc]: startRecording - There is no local call');
                cb('No active call');
                return;
            }
            var types;
            if (typeof mediaTypes === 'boolean') {
                // Backwards compatibility with old interface that had allowScreenshareRecording parameter instead of mediaTypes
                types = {audio: true, video: mediaTypes, text: false};
            } else if (mediaTypes instanceof Object) {
                types = mediaTypes;
            } else {
                // Assume audio and video by default
                types = {audio: true, video: true, text: false};
            }
            videoLayout = videoLayout || {
                layoutName: Constants.RecordingVideoLayoutName.SINGLE,
                layoutMapping: []
            };

            var data = {
                convId: _primaryLocalCall.convId,
                rtcSessionId: _primaryLocalCall.callId,
                recordingMediaTypes: types,
                videoLayout: videoLayout
            };
            _clientApiHandler.startRecording(data, getDefaultHandler(cb));
        };

        this.stopRecording = function (cb, mediaTypes) {
            if (!_primaryLocalCall) {
                LogSvc.warn('[CircuitCallControlSvc]: stopRecording - There is no local event call');
                cb('No active call');
                return;
            }

            var data = {
                convId: _primaryLocalCall.convId,
                rtcSessionId: _primaryLocalCall.callId,
                recordingMediaTypes: mediaTypes || {audio: true, video: true, text: false}
            };
            _clientApiHandler.stopRecording(data, getDefaultHandler(cb));
        };

        /**
         * Starts transcription for the active call.
         *
         * @return {Promise} Promise resolved when transcription is started
         */
        this.startTranscription = function () {
            return new $q(function (resolve, reject) {
                _that.startRecording(function (err) {
                    if (err) {
                        LogSvc.error('[CircuitCallControlSvc]: Failed to start transcription. ', err);
                        reject(err);
                    } else {
                        resolve();
                    }
                }, {text: true});
            });
        };

        /**
         * Stops transcription for the active call.
         *
         * @return {Promise} Promise resolved when transcription is stopped
         */
        this.stopTranscription = function () {
            return new $q(function (resolve, reject) {
                _that.stopRecording(function (err) {
                    if (err) {
                        LogSvc.error('[CircuitCallControlSvc]: Failed to stop transcription. ', err);
                        reject(err);
                    } else {
                        resolve();
                    }
                }, {text: true});
            });
        };

        this.switchRecordingLayout = function (videoLayout, cb) {
            if (!_primaryLocalCall) {
                LogSvc.warn('[CircuitCallControlSvc]: switchRecordingLayout - There is no local call');
                cb('No active call');
                return;
            }

            if (!videoLayout || !videoLayout.layoutName) {
                LogSvc.warn('[CircuitCallControlSvc]: switchRecordingLayout - Missing or invalid videoLayout');
                cb('Invalid videoLayout');
                return;
            }

            var data = {
                rtcSessionId: _primaryLocalCall.callId,
                videoLayout: videoLayout
            };
            _clientApiHandler.switchRecordingLayout(data, getDefaultHandler(cb));
        };

        this.startRecordingParticipant = function (userId) {
            return new $q(function (resolve, reject) {
                if (!_primaryLocalCall) {
                    LogSvc.warn('[CircuitCallControlSvc]: startRecordingParticipant - There is no local call');
                    reject('No active call');
                    return;
                }
                var videoLayout = {
                    layoutName: Constants.RecordingVideoLayoutName.SINGLE_VIDEO,
                    layoutMapping: [{
                        tileId: 'Video',
                        tileContent: userId
                    }]
                };

                if (_primaryLocalCall.recording.isActive()) {
                    // Switch current layout
                    LogSvc.info('[CircuitCallControlSvc]: startRecordingParticipant - Switch layout to record userId = ', userId);
                    _that.switchRecordingLayout(videoLayout, function (err) {
                        err ? reject(err) : resolve();
                    });
                } else {
                    LogSvc.info('[CircuitCallControlSvc]: startRecordingParticipant - Start recording call with focus on userId = ', userId);
                    _that.startRecording(function (err) {
                        err ? reject(err) : resolve();
                    }, {audio: true, video: true, text: false}, videoLayout);
                }
            });
        };

        this.submitCallQualityRating = function (ratingData, callData, cb) {
            if (!ratingData || !ratingData.hasOwnProperty('rating') || !callData) {
                LogSvc.warn('[CircuitCallControlSvc]: submitCallQualityRating - Missing rating or call data');
                cb && cb('No rating data');
                return;
            }
            Object.assign(ratingData, callData);
            _clientApiHandler.submitCallQualityRating([ratingData], function (err) {
                $rootScope.$apply(function () {
                    cb && cb(err);

                    if (!err) {
                        LogSvc.debug('[CircuitCallControlSvc]: Publish /call/rated event');
                        PubSubSvc.publish('/call/rated');
                    }
                });
            });
        };

        /**
         * Retrieve list of nodes with states. Currently only used for testcall.
         *
         * @param {String} nodeType requested node type (currently only MEDIA_ACCESS)
         * @param {String} tenantId name of the tenant or empty for all
         * @param {Function} cb A callback function replying with an error or success
         */
        this.getNodeState = function (nodeType, tenantId, cb) {
            var data = {
                convId: 'noCall',
                nodeType: nodeType,
                tenantId: tenantId || ''
            };
            _clientApiHandler.getNodeState(data, function (err, nodeData) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[CircuitCallControlSvc]: Failed to get node(s). ', err);
                    }
                    cb && cb(err, nodeData);
                });
            });
        };

        /**
         * Only applicable to local call (Siemens AG temporary solution)
         */
        this.canSendWebRTCDigits = function () {
            return _primaryLocalCall && _primaryLocalCall.sessionCtrl.canSendDTMFDigits();
        };

        /**
         * Send DTMF digits
         *
         * @param {String} callId The call ID of active call
         * @param {String} digits The digits to be sent
         * @param {Function} cb A callback function replying with a status
         */
        this.sendDigits = function (callId, digits, cb) {
            cb = cb || NOP;
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: sendDigits - There is no local call');
                cb('Call not valid');
                return;
            }

            if (!digits) {
                cb('res_InvalidDtmf');
                return;
            }

            if (!/^[\d#*,]+$/.exec(digits)) {
                LogSvc.error('[CircuitCallControlSvc]: Digits cannot be sent: ' + digits + ' (Allowed: 0-9,#,*)');
                cb('res_InvalidDtmf');
                return;
            }

            sendDTMFDigits(callId, digits, cb);
        };

        /**
         * Locally disable the remote incoming video (this doesn't trigger a
         * media renegotiation)
         * @param {Object} call The existing group call or conference
         * @returns {undefined}
         */
        this.disableIncomingVideo = function (call) {
            if (_primaryLocalCall && _primaryLocalCall.sameAs(call)) {
                LogSvc.debug('[CircuitCallControlSvc]: Disabling remote incoming video. Call ID=', _primaryLocalCall.callId);
                _primaryLocalCall.sessionCtrl.disableIncomingVideo();
            }
        };

        /**
         * Locally enable the remote incoming video (this doesn't trigger a
         * media renegotiation)
         * @param {Object} call The existing group call or conference
         * @returns {undefined}
         */
        this.enableIncomingVideo = function (call) {
            if (_primaryLocalCall && _primaryLocalCall.sameAs(call)) {
                LogSvc.debug('[CircuitCallControlSvc]: Enabling remote incoming video. Call ID=', _primaryLocalCall.callId);
                _primaryLocalCall.sessionCtrl.enableIncomingVideo();
            }
        };

        /**
         * Enable or disable participant pointer
         *
         * @param {String} callId The ID of existing group call or conference
         * @returns {undefined}
         */
        this.toggleParticipantPointer = function (callId) {
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleParticipantPointer - There is no local call');
                return;
            }

            if (!localCall.pointer || !localCall.pointer.isSupported) {
                LogSvc.warn('[CircuitCallControlSvc]: toggleParticipantPointer - Local call does not support participant pointer');
                return;
            }
            localCall.pointer.isEnabled = !localCall.pointer.isEnabled;

            changeMediaType({
                callId: localCall.callId,
                transactionId: localCall.transactionId,
                mediaType: localCall.localMediaType
            });
        };

        /**
         * Find active or incoming local call
         * @returns {LocalCall} The LocalCall object.
         */
        this.findCall = findCall;

        this.findLocalCallByCallId = findLocalCallByCallId;

        this.findActivePhoneCall = findActivePhoneCall;

        this.findHeldPhoneCall = findHeldPhoneCall;

        this.removeCallFromList = removeCallFromList;

        this.isSecondCall = function () {
            return _primaryLocalCall || _incomingCalls.length >= 1 || !Utils.isEmptyArray(_activeRemoteCalls);

        };

        this.updateActiveCallMediaDevices = function () {
            if (_primaryLocalCall) {
                _primaryLocalCall.updateMediaDevices();
            }
        };

        /**
         * Checks screen control Feature support for a call
         *
         * @param call to check
         * @returns {boolean}
         */
        this.hasScreenControlFeature = hasScreenControlFeature;

        this.canStartScreenShare = function (callId) {
            var localCall = findLocalCallByCallId(callId);
            if (!localCall) {
                LogSvc.warn('[CircuitCallControlSvc]: canStartScreenShare - There is no local call');
                return false;
            }
            if (!localCall.hasRemoteScreenShare()) {
                return true;
            }
            var moderatorSharing = localCall.participants.some(function (p) {
                return p.isModerator && p.mediaType && p.mediaType.desktop;
            });
            if (!moderatorSharing) {
                return true;
            }
            var conversation = ConversationSvc.getConversationFromCache(localCall.convId);
            return !!conversation && conversation.userIsModerator($rootScope.localUser);
        };

        this.canStartRecording = function (callId) {
            var localCall = findLocalCallByCallId(callId);
            var conversation = ConversationSvc.getConversationFromCache(localCall.convId);
            if (!localCall || !conversation) {
                LogSvc.warn('[CircuitCallControlSvc]: canStartRecording - Cannot find active call or conversation');
                return false;
            }
            return !!(!localCall.isDirect && !localCall.moderatorLeftNotifyTime &&
                localCall.checkState([Enums.CallState.Active, Enums.CallState.Waiting]) &&
                (!conversation.isModerated || conversation.userIsModerator($rootScope.localUser)));
        };

        this.canTakeScreenshot = function (callId, p) {
            var call = findLocalCallByCallId(callId);
            if (!call) {
                return false;
            }

            if (p.mediaType && p.mediaType.desktop) {
                // All users can take picture from screenshare
                return !!((call.isDirect && p.streamId) || (p.streams && p.streams.desktop));
            }

            return false;
        };

        this.addConferenceAsGuest = function (convId) {
            var conversation = getConversation(convId);
            if (!conversation || !conversation.isTemporary || !conversation.guestToken) {
                LogSvc.warn('[CircuitCallControlSvc]: addConferenceAsGuest - No temporary conversation or guest token is missing');
                return;
            }
            LogSvc.debug('[CircuitCallControlSvc]: Create placeholder remote call for temporary guest conversation with convId = ', conversation.convId);

            var remoteCall = new RemoteCall(conversation);
            remoteCall.setState(Enums.CallState.NotStarted);
            remoteCall.guestToken = conversation.guestToken;
            addCallToList(remoteCall);

            conversation.call = remoteCall;
            publishConversationUpdate(conversation);
            publishCallState(remoteCall);
        };

        this.removeConferenceAsGuest = function (callId) {
            var call = findCall(callId);
            if (!call) {
                LogSvc.warn('[CircuitCallControlSvc]: removeConferenceAsGuest - Could not find call');
                return;
            }

            if (!call.isRemote) {
                LogSvc.warn('[CircuitCallControlSvc]: removeConferenceAsGuest - Cannot remove temporary conversation with active call');
                return;
            }

            var conversation = getConversation(call.convId);
            if (!conversation || !conversation.isTemporary) {
                LogSvc.warn('[CircuitCallControlSvc]: removeConferenceAsGuest - No temporary conversation');
                return;
            }

            terminateCall(call);

            LogSvc.debug('[CircuitCallControlSvc]: Publish /conversation/temporary/ended event');
            PubSubSvc.publish('/conversation/temporary/ended', [conversation]);
        };

        /**
         * Test ICE candidates collection
         * @returns {Promise} Returns an object containing the assigned TURN servers and the gathered ICE candidates.
         */
        this.testCandidatesCollection = function (useTurn) {
            if (!useTurn) {
                LogSvc.debug('[CircuitCallControlSvc]: Test ICE candidates collection without TURN...');
                return testCandidatesCollection();
            }

            LogSvc.debug('[CircuitCallControlSvc]: Test ICE candidates collection with TURN...');
            return new $q(function (resolve, reject) {
                var rtcSessionId = 'echo_' + $rootScope.localUser.clientId;

                // If the guest is registered for test call, use conversationId, otherwise use clientId
                var prepareSessionData = {
                    convId: rtcSessionId,
                    rtcSessionId: rtcSessionId,
                    ownerId: $rootScope.localUser.userId,
                    isTelephonyConversation: false,
                    mediaNode: RtcSessionController.mediaNode
                };

                LogSvc.debug('[CircuitCallControlSvc]: testIceCandidates - Get TURN servers');
                _clientApiHandler.prepareSession(prepareSessionData, function (err, servers) {
                    if (err) {
                        LogSvc.error('[CircuitCallControlSvc]: Failed to prepare session data. ', err);
                        reject(err);
                        return;
                    }
                    if (!servers || !servers.length) {
                        _clientApiHandler.terminateRtcCall(rtcSessionId, Constants.DisconnectCause.HANGUP);
                        LogSvc.error('[CircuitCallControlSvc]: Failed to prepare session data. No TURN servers.');
                        reject('No TURN servers');
                        return;
                    }

                    var turnCredentials = servers[0];
                    LogSvc.debug('[CircuitCallControlSvc]: testIceCandidates - TURN servers: ', turnCredentials.turnServer);

                    testCandidatesCollection(turnCredentials)
                    .then(function (data) {
                        _clientApiHandler.terminateRtcCall(rtcSessionId, Constants.DisconnectCause.HANGUP, function () {
                            resolve(data);
                        });
                    })
                    .catch(function (testErr) {
                        _clientApiHandler.terminateRtcCall(rtcSessionId, Constants.DisconnectCause.HANGUP, function () {
                            reject(testErr);
                        });
                    });
                });
            });
        };

        this.addCallToList = addCallToList;

        this.moveOsBizCalls = function (oldCall, newCall) {
            LogSvc.debug('[CircuitCallControlSvc]: Move oldCall: ' + oldCall.callId + ' to newCall: ' + newCall.callId);
            if (oldCall.isOsBizFirstCall) {
                var newCallAtcCallInfo = newCall.atcCallInfo;
                //move establishedTime from new to old call object for creating proper call Log
                var newCallEstablishedTime = newCall.establishedTime;
                oldCall.atcCallInfo = newCallAtcCallInfo;
                oldCall.establishedTime = newCallEstablishedTime;
                oldCall.setPeerUser(oldCall.atcCallInfo.peerDn, oldCall.atcCallInfo.peerName);
                oldCall.setCstaState(Enums.CstaCallState.Active);
                oldCall.setState(Enums.CallState.Active);
                oldCall.isOsBizFirstCall = false;
                // Inform the UI that call has been moved, needed for mobile clients
                LogSvc.debug('[CircuitCallControlSvc]: Publish /oldCall/moved event');
                PubSubSvc.publish('/oldCall/moved', [oldCall.callId, newCall.callId]);
                terminateCall(newCall);
            } else {
                terminateCall(oldCall);
                newCall.isOsBizFirstCall = false;
                newCall.isOsBizSecondCall = false;
            }
        };

        this.findOsBizFirstCall = function () {
            return _calls.find(function (call) {
                return call.isOsBizFirstCall;
            }) || null;
        };

        this.findOsBizSecondCall = function () {
            return _calls.find(function (call) {
                return call.isOsBizSecondCall;
            }) || null;
        };

        this.getCallToPickup = function () {
            return _callToPickup;
        };

        /**
         * Returns the list of participants for the call with the given ID.
         *
         * @param {String} callId The ID of the call.
         * @returns {Promise} A promise resolving to the list of participants.
         */
        this.getConferenceParticipants = function (callId) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[CircuitCallControlSvc]: Get participants for call with callId = ', callId);

                var call = findCall(callId);
                if (!call) {
                    LogSvc.warn('[CircuitCallControlSvc]: Call does not exist');
                    reject('Call does not exist');
                    return;
                }

                if (!call.isRemote) {
                    LogSvc.warn('[CircuitCallControlSvc]: This is a local call. Return local participants.');
                    resolve(call.participants);
                    return;
                }

                _clientApiHandler.getSession(callId, function (error, session) {
                    $rootScope.$apply(function () {
                        if (error || !session) {
                            LogSvc.warn('[CircuitCallControlSvc] Error retrieving RTC session. Err: ', error);
                            reject(error);
                        } else {
                            // Resolve with list of normalized participants, retrieve not yet cached ones before
                            var normalizedParticipants = session.participants.map(function (p) {
                                return normalizeApiParticipant(p);
                            });
                            var userIdsToRetrieve = normalizedParticipants
                            .filter(function (normalizedParticipant) {
                                return normalizedParticipant.noData;
                            }).map(function (filteredNormalizedParticipant) {
                                return filteredNormalizedParticipant.userId;
                            });

                            UserSvc.getUsersByIds(userIdsToRetrieve, function () {
                                // The normalizedParticipants will be automatically updated when the user data is retrieved
                                resolve(normalizedParticipants);
                            });
                        }
                    });
                });
            });
        };

        /**
         * Merges two phone calls by moving them to the media server
         *
         * @param {String} callId1 The ID of the held call
         * @param {String} callId2 The ID of the active or hosted call
         * @returns {Promise} A promise resolving to the result of the merge
         */
        this.mergeCalls = function (callId1, callId2) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[CircuitCallControlSvc]: Merge calls ', [callId1, callId2]);

                var call1 = findCall(callId1);
                var call2 = findCall(callId2);

                if (!call1 || !call2) {
                    LogSvc.warn('[CircuitCallControlSvc]: Call does not exist');
                    reject('Call does not exist');
                    return;
                }

                _clientApiHandler.mergeRtcCalls(callId1, callId2, function (error) {
                    // The API response indicates if the backend accepted or rejected the request. By the time of the
                    // response, the two calls have not been merged yet.
                    if (error) {
                        LogSvc.warn('[CircuitCallControlSvc]: Error merging calls. Err: ', error);
                        reject(error);
                    } else {
                        resolve();
                    }
                });
            });
        };

        this.changeInputDevices = function (callId, inputDevices) {
            var activeCall = _that.getActiveCall();
            if (activeCall && activeCall.callId === callId) {
                if (!inputDevices || (!inputDevices.audio && !inputDevices.video)) {
                    return $q.resolve(); // Nothing to do
                }
                if (inputDevices.video) {
                    return new $q(function (resolve, reject) {
                        // If video resolution changes, it requires SDP changes. So we need
                        // a full renegotiation (for now)
                        _that.renegotiateMedia(callId, function (err) {
                            err ? reject(err) : resolve();
                        });
                    });
                }
                return activeCall.sessionCtrl.changeInputDevices(inputDevices);
            } else {
                return $q.reject('Call not found');
            }
        };

        /**
         * @typedef ReceiverConfiguration
         * @type {Object}
         * @property {String} contentType - The IncomingVideoContentType of stream to be received: VIDEO or SCREEN.
         * @property {String} [videoQuality] - The VideoQuality for the stream. Default is NORMAL.
         * @property {String} [userId] - The userId of the participant that should be pinned. If not specified backend uses video active speaker logic.
         */

        /**
         * Sets the video receiver configuration for the specified streams.
         *
         * @param {String} callId The callId of the active call.
         * @param {Array<ReceiverConfiguration>} [configuration] Array of configuration objects for video streams
         *      If null or undefined is passed, the default configuration is applied (i.e. reset to what has been negotiated in Offer/Answer).
         *      If an empty array is passed, the client will temporarily disable all incoming video streams.
         * @returns {Promise} Promise that resolves when operation is completed.
         */
        this.setIncomingVideoStreams = function (callId, configuration) {
            var activeCall = _that.getActiveCall();
            if (!activeCall || activeCall.callId !== callId) {
                return $q.reject('Call not found');
            } else if (!_that.isPinVideoSupported(callId)) {
                return $q.reject('Not supported');
            } else {
                LogSvc.debug('[CircuitCallControlSvc]: Set video receiver configuration: ', configuration || 'DEFAULT');
                var cfg = activeCall.setIncomingVideoStreams(configuration);
                if (cfg && cfg.length) {
                    return setVideoReceiverConfiguration(callId, cfg);
                } else {
                    return $q.resolve(); // Nothing to do
                }
            }
        };

        this.isTranscriptionSupported = _clientApiHandler.isTranscriptionSupported;

        /**
         * Get network quality info.
         *
         * @param {String} [callId] the ID of the call (optional: if not supplied, the active call will be used)
         * @returns {Object} Object containing:
         * - level: Overall network quality level defined in Circuit.Enums.RtpQualityLevel
         * - audio: Object with audio quality parameters and their values: property names in the object are defined in
         * Constants.AudioQualityParam with values defined in Circuit.Enums.RtpQualityLevel
         * - video: Object with video quality parameters and their values: property names in the object are defined in
         * Constants.VideoQualityParam with values defined in Circuit.Enums.RtpQualityLevel
         * - videoInfo: Object containing:
         *   - resolution: Object containing:
         *     - height: current outgoing resolution height (Number)
         *     - width: current outgoing resolution width (Number)
         *   - hdVideo: value defined in Circuit.Enums.VideoResolutionLevel. Applicable only if resolution exactly matches
         * a predefined resolution, otherwise it's undefined.
         *
         * If call could not be found or no quality info is available, null is returned.
         */
        this.getNetworkQualityInfo = function (callId) {
            var call = _that.getActiveCall(callId);
            if (call && call.sessionCtrl) {
                var stats = call.sessionCtrl.getLastSavedStats();
                if (stats.quality) {
                    return stats.quality;
                }
            } else {
                LogSvc.warn('[CircuitCallControlSvc]: getNetworkQualityInfo: ' + (callId ? 'Call not found: ' + callId : 'No active call'));
            }
            return null;
        };

        this.isPinVideoSupported = function (callId) {
            var call = callId && _that.getActiveCall(callId);
            return !call || call.supportsVideoReceiverConfiguration();
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.CircuitCallControlSvcImpl = CircuitCallControlSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
