var Circuit = (function (circuit) {
    'use strict';

    // Import
    var BaseCall = circuit.BaseCall;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function PubSubSvcImpl(LogSvc) { // NOSONAR
        LogSvc.debug('New Service: PubSubSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _cache = {};
        var _cacheMobile = {};
        var _cacheOnce = {};
        var _cacheAll = [];

        function send(item, args) {
            try {
                if (args === undefined || args === null) {
                    args = [];
                } else if (!Array.isArray(args)) {
                    args = [args];
                }
                item.apply(null, args);
            } catch (e) {
                LogSvc.error(e);
            }
        }

        function publish(cache, topic, args) {
            // Loop through a copy of the array in case the
            // app unsubscribes while publishing the events.
            cache[topic] && cache[topic].slice(0).forEach(function (item) {
                send(item, args);
            });
        }

        function publishMobile(topic, args) {
            if (_cacheMobile[topic]) {
                if (args === undefined || args === null) {
                    args = [];
                } else if (!Array.isArray(args)) {
                    args = [args];
                }
                args = args.map(function (arg) {
                    if (arg && typeof arg === 'object') {
                        // Return lightweight conversation and call objects without data that
                        // is not needed by the mobile applications.
                        if (arg.isConversationObject) {
                            return Utils.trimConvForMobile(arg);
                        }
                        if (arg instanceof BaseCall) {
                            return Utils.trimCallForMobile(arg);
                        }
                    }
                    return arg;
                });

                // Loop through a copy of the array in case the
                // app unsubscribes while publishing the events.
                _cacheMobile[topic].slice(0).forEach(function (item) {
                    send(item, args);
                });
            }
        }

        function publishAll(topic, args) {
            args = args ? Array.prototype.slice.call(args) : [];
            args.unshift(topic);
            // Loop through a copy of the array in case the
            // app unsubscribes while publishing the events.
            _cacheAll.slice(0).forEach(function (item) {
                send(item, args);
            });
        }

        function subscribe(cache, topic, callback) {
            if (!cache[topic]) {
                cache[topic] = [callback];
            } else if (!cache[topic].includes(callback)) {
                cache[topic].push(callback);
            }
        }

        function unsubscribe(cache, topic, callback) {
            if (cache[topic]) {
                var idx = cache[topic].indexOf(callback);
                if (idx >= 0) {
                    cache[topic].splice(idx, 1);
                }
            }
        }

        function subscribeAll(callback) {
            if (!_cacheAll.includes(callback)) {
                _cacheAll.push(callback);
            }
        }

        function unsubscribeAll(callback) {
            var idx = _cacheAll.indexOf(callback);
            if (idx >= 0) {
                _cacheAll.splice(idx, 1);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.publish = function (topic, args) {
            if (!_cache[topic] && !_cacheMobile[topic] && !_cacheOnce[topic] && !_cacheAll.length) {
                return;
            }

            LogSvc.debug('[PubSubSvc]: Publishing ' + topic + ' event');
            publish(_cache, topic, args);
            publishMobile(topic, args);
            publish(_cacheOnce, topic, args);
            publishAll(topic, args);

            delete _cacheOnce[topic];
        };

        this.subscribe = function (topic, callback) {
            subscribe(_cache, topic, callback);
        };

        this.unsubscribe = function (topic, callback) {
            unsubscribe(_cache, topic, callback);
        };

        this.subscribeOnce = function (topic, callback) {
            subscribe(_cacheOnce, topic, callback);
        };

        this.unsubscribeOnce = function (topic, callback) {
            unsubscribe(_cacheOnce, topic, callback);
        };

        this.subscribeMobile = function (topic, callback) {
            subscribe(_cacheMobile, topic, callback);
        };

        this.unsubscribeMobile = function (topic, callback) {
            unsubscribe(_cacheMobile, topic, callback);
        };

        this.subscribeAll = function (callback) {
            subscribeAll(callback);
        };

        this.unsubscribeAll = function (callback) {
            unsubscribeAll(callback);
        };

        this.resetSubscriptions = function () {
            _cache = {};
            _cacheMobile = {};
            _cacheOnce = {};
            _cacheAll = [];
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.PubSubSvcImpl = PubSubSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
