var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var BaseCall = circuit.BaseCall;
    var CallState = circuit.Enums.CallState;
    var Proto = circuit.Proto;
    var Utils = circuit.Utils;

    function RemoteCall(conversation) {
        // Imports (change to injected Circuit)
        var logger = Circuit.logger;

        if (!conversation || !conversation.rtcSessionId) {
            throw new Error('Cannot create RemoteCall object without a valid conversation');
        }

        // Call the base constructor
        RemoteCall.parent.constructor.call(this, conversation, true);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            isPullAllowed: {
                get: function () { return !this.pullNotAllowed && this.state === CallState.ActiveRemote && !this.pullBlocked; },
                enumerable: true,
                configurable: false
            }
        });
        // Remote client on which the call is established
        this.activeClient = null;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        conversation = null; // Remove reference to conversation object

        if (this.isTelephonyCall) {
            // Init displayName to empty to avoid showing Phone calls conversation displayName (VGTC VGTC)
            this.peerUser.displayName = '';
        }
        logger.info('[RemoteCall]: Created new remote call with callID = ', this.callId);
    }

    Utils.inherit(RemoteCall, BaseCall);

    RemoteCall.prototype.setActiveClient = function (apiParticipant) {
        if (!apiParticipant) {
            return;
        }
        if (apiParticipant.userId) {
            this.activeClient = {
                clientId: apiParticipant.clientId,
                clientDisplayName: apiParticipant.clientDisplayName,
                mediaType: Proto.getMediaType(apiParticipant.mediaTypes)
            };
            // Check call permissions:
            // We need to explicitly check if the backend set the pullCall flag to false (for backwards compatibility)
            this.pullNotAllowed = apiParticipant.pullCall === false;
        } else {
            // Set data only. This is the case for remote calls where the user has been invited as guest.
            this.activeClient = apiParticipant;
        }
    };

    // Exports
    circuit.RemoteCall = RemoteCall;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
