/*global ControllerRouteError*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var CallLogItem = circuit.CallLogItem;
    var JournalFilter = circuit.Constants.JournalFilter;
    var Utils = circuit.Utils;
    var NotificationType = circuit.Enums.NotificationType;

    // eslint-disable-next-line max-params, max-lines-per-function
    function PhoneCallLogSvcImpl($rootScope, $q, ConversationSvc, LogSvc, PubSubSvc, UserSvc, NotificationSvc) { // NOSONAR

        LogSvc.debug('New Service: PhoneCallLogSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_NUMBER_OF_ENTRIES = 25;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _clientApiHandler = ClientApiHandler.getInstance();
        var _showTelephonyConvReceived = false;
        var _telephonyConversation;

        var _cachedItemsHash = {};
        var _cachedData = {};
        var _unheardVoicemailCount = -1;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function clearCachedData(hasOldestItem) {
            Object.keys(JournalFilter).forEach(function (filter) {
                _cachedData[filter] = {
                    cachedItems: [],
                    hasOldestItem: !!hasOldestItem
                };
            });
            _cachedItemsHash = {};
        }

        function resolveTelephonyConversationPromise(deferred) {
            if ($rootScope.localUser.telephonyAvailable && _telephonyConversation) {
                deferred.resolve(_telephonyConversation);
            } else {
                deferred.reject(ControllerRouteError.CONVERSATION_NOT_FOUND);
            }
        }

        function getApplicableFilters(item) {
            var filters = [];

            if (item.sentByMe) {
                filters.push(JournalFilter.DIALED);
            } else if (item.redirectedToUser) {
                filters.push(JournalFilter.DIVERTED);
                if (item.callLogType === circuit.Enums.CallLogItemType.MISSED) {
                    filters.push(JournalFilter.MISSED);
                }
            } else {
                if (item.callLogType === circuit.Enums.CallLogItemType.MISSED) {
                    filters.push(JournalFilter.MISSED);
                } else {
                    filters.push(JournalFilter.RECEIVED);
                }
                if (item.hasVoicemail || (item.recordingUploadState &&
                    item.recordingUploadState.state === Constants.RecordingUploadState.IN_PROGRESS)) {
                    filters.push(JournalFilter.VOICEMAILS);
                    if (item.isVoicemailUnheard) {
                        filters.push(JournalFilter.UNHEARD_VOICEMAILS);
                    }
                }
            }
            return filters;
        }

        function retrieveIncompleteUsers(elements, cacheOnly) {
            UserSvc.retrieveIncompleteUsers(elements, ['peerUser', 'redirectedToUser', 'redirectingUser'], cacheOnly);
        }

        function itemExists(item, itemArray) {
            return itemArray.some(function (itemCached) {
                return itemCached.itemId === item.itemId;
            });
        }

        function processAddItem(item, activeFilter, skipUnreadCount) {
            if (!item || !item.rtc) {
                // Ignore
                return null;
            }
            // Check if the item is already cached
            var cachedItem = _cachedItemsHash[item.itemId];
            var update = !!cachedItem;
            if (!cachedItem) {
                cachedItem = CallLogItem.extend(item);
                _cachedItemsHash[item.itemId] = cachedItem;
            }

            if (!cachedItem.sendingState) {
                // Update the conversation modification times if this is not a failed message
                _telephonyConversation.lastItemModificationTime = Math.max(_telephonyConversation.lastItemModificationTime, cachedItem.modificationTime || 0);
                _telephonyConversation.lastModification = Math.max(_telephonyConversation.lastModification, _telephonyConversation.lastItemModificationTime);
                !skipUnreadCount && processUnreadItems(item);
            }

            resolvePhoneNumbersFromExchange(cachedItem);

            if (!activeFilter || activeFilter === JournalFilter.ALL) {
                // Update cache for all applicable filters
                var applicableFilters = getApplicableFilters(cachedItem);
                // Add to ALL filter as well
                applicableFilters.push(JournalFilter.ALL);
                applicableFilters.forEach(function (filter) {
                    // NGTC-1594: If item is in cache but not in the _cached data of the filter
                    // it should be inserted instead of updated.
                    if (update && itemExists(cachedItem, _cachedData[filter].cachedItems)) {
                        CallLogItem.updateItem(_cachedData[filter].cachedItems, cachedItem);
                    } else {
                        CallLogItem.insertItem(_cachedData[filter].cachedItems, cachedItem);
                    }
                });
            } else {
                // Only update the active filter
                CallLogItem.insertItem(_cachedData[activeFilter].cachedItems, cachedItem);
                if (activeFilter === JournalFilter.VOICEMAILS) {
                    if (!cachedItem.hasVoicemail) {
                        LogSvc.warn('[PhoneCallLogSvc]: Voicemail call journal without voicemail. Id: ', cachedItem.itemId);
                    }
                    if (cachedItem.isVoicemailUnheard) {
                        // Add item to UNHEARD_VOICEMAILS cache as well
                        CallLogItem.insertItem(_cachedData[JournalFilter.UNHEARD_VOICEMAILS].cachedItems, cachedItem);
                    }
                }
            }
            return cachedItem;
        }

        function removeCachedItem(item) {
            // Remove the item from all applicable caches
            Object.keys(JournalFilter).forEach(function (filter) {
                Utils.removeArrayElement(_cachedData[filter].cachedItems, item);
            });
        }

        function getCallJournalEntries(numberOfEntries, filter) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[PhoneCallLogSvc]: Get ' + numberOfEntries + ' call journal entries with filter ', filter);

                var cachedItems = _cachedData[filter].cachedItems;
                if (cachedItems.length >= numberOfEntries) {
                    LogSvc.debug('[PhoneCallLogSvc]: Return cached journal entries');
                    // Cached items are ordered from oldest to newest, so return the last items in the array
                    resolve(cachedItems.slice(-numberOfEntries));
                    return;
                }

                if (_cachedData[filter].hasOldestItem) {
                    LogSvc.debug('[PhoneCallLogSvc]: All journal entries are cached for filter ', filter);
                    resolve(cachedItems.slice());
                    return;
                }

                var timestamp = cachedItems.length > 0 ? cachedItems[0].creationTime - 1 : null;
                var numToRetrieve = numberOfEntries - cachedItems.length;
                var getJournalData = {
                    convId: _telephonyConversation.convId,
                    timestamp: timestamp,
                    direction: Constants.SearchDirection.BEFORE,
                    numberOfEntries: numToRetrieve,
                    journalFilter: filter
                };

                _clientApiHandler.getJournalEntries(getJournalData, function (err, entries) {
                    if (err) {
                        LogSvc.error('[PhoneCallLogSvc]: Failed to retrieve call journal entries. ', err);
                        reject(err);
                    } else {
                        entries = entries || [];
                        // Add items to cache
                        var callLogItems = entries.map(function (item) {
                            return processAddItem(item, filter, true);
                        });
                        retrieveIncompleteUsers(callLogItems);

                        if (entries.length < numToRetrieve) {
                            // We received less entries than we requested
                            if (filter === JournalFilter.ALL) {
                                // If we have the oldest item for the ALL cache then we must also have the
                                // oldest item in all other caches.
                                Object.keys(JournalFilter).forEach(function (key) {
                                    _cachedData[key].hasOldestItem = true;
                                });
                            } else {
                                _cachedData[filter].hasOldestItem = true;
                                if (filter === JournalFilter.VOICEMAILS) {
                                    // If we have all voicemails, we must have all UNHEARD_VOICEMAILS as well
                                    _cachedData[JournalFilter.UNHEARD_VOICEMAILS].hasOldestItem = true;
                                }
                            }
                        }
                        resolve(cachedItems.slice());
                    }
                });
            });
        }

        function addToUnheardCount(add) {
            if (_unheardVoicemailCount >= 0) {
                var newValue = _unheardVoicemailCount + add;
                newValue = newValue > 0 ? newValue : 0;
                if (_unheardVoicemailCount !== newValue) {
                    _unheardVoicemailCount = newValue;
                    LogSvc.debug('[PhoneCallLogSvc]: Publish /callHistory/unheardVoicemail/updated event: ', _unheardVoicemailCount);
                    PubSubSvc.publish('/callHistory/unheardVoicemail/updated', [_unheardVoicemailCount]);
                }
            }
        }

        function handleUpdatedItem(item) {
            var cachedItem = _cachedItemsHash[item.itemId];
            if (!cachedItem) {
                return;
            }

            var hadVoicemail = cachedItem.hasVoicemail;
            var hadUnheardVoicemail = cachedItem.isVoicemailUnheard;

            var voicemailItems = _cachedData[JournalFilter.VOICEMAILS].cachedItems;
            var unheardVmItems = _cachedData[JournalFilter.UNHEARD_VOICEMAILS].cachedItems;

            // Update cached item
            CallLogItem.update(cachedItem, item);

            resolvePhoneNumbersFromExchange(cachedItem);

            if (!hadVoicemail && cachedItem.hasVoicemail) {
                // Voicemail has been attached to the item. Add to caches.
                LogSvc.debug('[PhoneCallLogSvc]: Add item to VOICEMAILS cache');
                CallLogItem.insertItem(voicemailItems, cachedItem);
                if (cachedItem.isVoicemailUnheard) {
                    LogSvc.debug('[PhoneCallLogSvc]: Add item to UNHEARD_VOICEMAILS cache');
                    CallLogItem.insertItem(unheardVmItems, cachedItem);
                    addToUnheardCount(1);
                }
            } else if (hadVoicemail) {
                if (!cachedItem.hasVoicemail) {
                    // Voicemail has been deleted. Remove from caches.
                    LogSvc.debug('[PhoneCallLogSvc]: Remove item from VOICEMAILS cache');
                    Utils.removeArrayElement(voicemailItems, cachedItem);
                }
                if (hadUnheardVoicemail && !cachedItem.isVoicemailUnheard) {
                    // Voicemail has been heard or deleted. Remove from unheard cache.
                    LogSvc.debug('[PhoneCallLogSvc]: Remove item from UNHEARD_VOICEMAILS cache');
                    Utils.removeArrayElement(unheardVmItems, cachedItem);
                    addToUnheardCount(-1);
                }
            }

            LogSvc.debug('[PhoneCallLogSvc]: Publish /callHistory/item/updated event');
            PubSubSvc.publish('/callHistory/item/updated', [cachedItem]);
        }

        function updateUserWithExchangeData(user) {
            if (user && user.userType === Constants.RTCParticipantType.TELEPHONY) {
                UserSvc.updateUserWithExchangeContactName(user);
            }
        }

        function resolvePhoneNumbersFromExchange(item) {
            if (item) {
                updateUserWithExchangeData(item.peerUser);
                updateUserWithExchangeData(item.redirectingUser);
                updateUserWithExchangeData(item.redirectedToUser);
            }
        }

        function deleteCachedItem(cachedItem) {
            removeCachedItem(cachedItem);
            delete _cachedItemsHash[cachedItem.itemId];
        }

        function processUnreadItems(item) {
            if (item.includeInUnreadCount && $rootScope.localUser.userId !== item.creatorId /*Do not use !item.sentByMe here, since it might not be set yet*/) {
                // Increment unread count
                _telephonyConversation.userData.unreadItems = _telephonyConversation.userData.unreadItems || 0; // init if not there yet
                changeUnreadItemsBy(1);
            }
        }

        /**
         * Calculate the number of unread messages in telephony conversation using the creationTime.
         *
         * @returns {int} The amount of unread messages in this conversation.
         */
        function calculateUnreadCount() {
            var count = 0;
            if (!_telephonyConversation || !_telephonyConversation.items) {
                return count;
            }
            var lastReadTimestamp = _telephonyConversation.userData.lastReadTimestamp;
            for (var i = _telephonyConversation.items.length - 1; i >= 0; i--) {
                var item = _telephonyConversation.items[i];
                if (item.creationTime <= lastReadTimestamp) {
                    break;
                }
                if (item.includeInUnreadCount && !item.sentByMe) {
                    count++;
                }
            }
            return count;
        }

        function changeUnreadItemsBy(difference) {
            if (!difference) {
                return;
            }

            var unreadSum = _telephonyConversation.userData.unreadItems + difference;
            _telephonyConversation.userData.unreadItems = Math.max(unreadSum, 0);

            LogSvc.debug('[PhoneCallLogSvc]: Publish /unread/changed event');
            PubSubSvc.publish('/unread/changed', _telephonyConversation.userData.unreadItems);

            LogSvc.debug('[ConversationSvc]: Publish /conversations/totalUnreadCountChanged event');
            PubSubSvc.publish('/conversations/totalUnreadCountChanged', _telephonyConversation.userData.unreadItems);
        }

        function processReadItemsData(lastReadTimestamp) {
            if (!_telephonyConversation) {
                LogSvc.error('[PhoneCallLogSvc]: No telephony conversation loaded');
                return;
            }

            if (_telephonyConversation.userData.lastReadTimestamp !== lastReadTimestamp) {
                $rootScope.$apply(function () {
                    var previousUnreadCount = _telephonyConversation.userData.unreadItems;
                    _telephonyConversation.userData.lastReadTimestamp = lastReadTimestamp;
                    _telephonyConversation.userData.unreadItems = calculateUnreadCount();

                    changeUnreadItemsBy(_telephonyConversation.userData.unreadItems - previousUnreadCount);
                    PubSubSvc.publish('/conversation/update', _telephonyConversation);
                });
            }
        }
        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/conversations/showTelephonyConversation', function (show, conv) {
            LogSvc.debug('[PhoneCallLogSvc]: Received /conversations/showTelephonyConversation event. show = ', show);
            _showTelephonyConvReceived = true;
            if (show) {
                _telephonyConversation = conv;
            }
        });

        PubSubSvc.subscribe('/conversation/update', function (conversation) {
            if (_telephonyConversation && _telephonyConversation.convId === conversation.convId) {
                _telephonyConversation = conversation;
            }
        });

        PubSubSvc.subscribe('/conversation/items/discarded', function (convId) {
            if (_telephonyConversation && _telephonyConversation.convId === convId) {
                LogSvc.debug('[PhoneCallLogSvc]: Received /conversation/items/discarded event');
                clearCachedData(false);
                PubSubSvc.publish('/callHistory/items/cacheCleared');
            }
        });

        // In NGTC we need to clear cache every time conversations are loaded (in case of re-connection)
        PubSubSvc.subscribe('/conversations/loadComplete', function () {
            LogSvc.debug('[PhoneCallLogSvc]: Received /conversations/loadComplete event');
            clearCachedData(false);
            PubSubSvc.publish('/callHistory/items/cacheCleared');
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Client API Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _clientApiHandler.on('Conversation.ADD_ITEM', function (data) {
            var item = data.item;
            if (!_telephonyConversation || !item || _telephonyConversation.convId !== item.convId) {
                // No cached journal entries or not a journal item
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[PhoneCallLogSvc]: Received Conversation.ADD_ITEM event. itemId: ', item.itemId);

                // Update topLevelItem
                _telephonyConversation.topLevelItem = item;

                var callLogItem = processAddItem(item);
                if (!callLogItem) {
                    return;
                }

                retrieveIncompleteUsers(callLogItem);
                LogSvc.debug('[PhoneCallLogSvc]: Publish /callHistory/item/added event');
                PubSubSvc.publish('/callHistory/item/added', [callLogItem, getApplicableFilters(callLogItem)]);

                if (callLogItem.rtc.type === Constants.RTCItemType.MISSED && !callLogItem.sentByMe) {
                    var options = {
                        type: NotificationType.INCOMING_MESSAGE,
                        userId: callLogItem.creatorId,
                        user: callLogItem.peerUser,
                        extras: {
                            conversation: _telephonyConversation,
                            conversationItem: callLogItem
                        }
                    };
                    NotificationSvc.show(options);
                }
            });
        });

        _clientApiHandler.on('Conversation.UPDATE_ITEM', function (data) {
            var item = data.item;
            if (!_telephonyConversation || !item || _telephonyConversation.convId !== item.convId) {
                // No cached journal entries or not a journal item
                return;
            }

            LogSvc.debug('[PhoneCallLogSvc]: Received Conversation.UPDATE_ITEM event. itemId: ', item.itemId);

            // We do not expect other update than voicemail finished processing (attachements)
            var cachedItem = _cachedItemsHash[item.itemId];
            if (!cachedItem) {
                LogSvc.info('[PhoneCallLogSvc]: Updated call journal entry is not cached. Ignore event.');
                return;
            }

            $rootScope.$apply(function () {
                handleUpdatedItem(item);
            });
        });

        _clientApiHandler.on('Conversation.JOURNAL_ENTRY_DELETED', function (data) {
            var item = data.deletedJournalEntry;
            if (!_telephonyConversation || !item) {
                // No cached journal entries or not a journal item
                return;
            }

            LogSvc.debug('[PhoneCallLogSvc]: Received Conversation.JOURNAL_ENTRY_DELETED event. itemId: ', item.itemId);

            var cachedItem = _cachedItemsHash[item.itemId];
            if (!cachedItem) {
                LogSvc.debug('[PhoneCallLogSvc]: Deleted call journal entry is not cached. Ignore event.');
                return;
            }

            $rootScope.$apply(function () {
                if (cachedItem.isVoicemailUnheard) {
                    addToUnheardCount(-1);
                }
                deleteCachedItem(cachedItem);
                LogSvc.debug('[PhoneCallLogSvc]: Publish /callHistory/item/deleted event');
                PubSubSvc.publish('/callHistory/item/deleted', [cachedItem]);
            });
        });

        _clientApiHandler.on('Conversation.ALL_JOURNAL_ENTRIES_DELETED', function (data) {
            if (!_telephonyConversation) {
                // No cached journal entries
                return;
            }

            LogSvc.debug('[PhoneCallLogSvc]: Received Conversation.ALL_JOURNAL_ENTRIES_DELETED event. Journal Filter: ', data.journalFilter);

            $rootScope.$apply(function () {
                if (data.journalFilter === JournalFilter.ALL) {
                    clearCachedData(true);
                } else if (_cachedData[data.journalFilter]) {
                    var deletedItems = _cachedData[data.journalFilter].cachedItems;
                    _cachedData[data.journalFilter].cachedItems = [];
                    _cachedData[data.journalFilter].hasOldestItem = true;
                    deletedItems.forEach(deleteCachedItem);
                }

                switch (data.journalFilter) {
                case JournalFilter.ALL:
                case JournalFilter.MISSED:
                case JournalFilter.VOICEMAILS:
                case JournalFilter.UNHEARD_VOICEMAILS:
                    // All unheard voicemails have been deleted
                    if (_unheardVoicemailCount > 0) {
                        _unheardVoicemailCount = 0;
                        LogSvc.debug('[PhoneCallLogSvc]: Set unheardVoicemailCount to 0');
                        PubSubSvc.publish('/callHistory/unheardVoicemail/updated', [_unheardVoicemailCount]);
                    }
                    break;
                }

                LogSvc.debug('[PhoneCallLogSvc]: Publish /callHistory/items/cacheCleared event');
                PubSubSvc.publish('/callHistory/items/cacheCleared', [data.journalFilter]);
            });
        });

        _clientApiHandler.on('Conversation.READ_ITEMS', function (data) {
            if (!_telephonyConversation) {
                // No cached conversation
                return;
            }
            LogSvc.debug('[PhoneCallLogSvc]: Received Conversation.READ_ITEMS event. lastReadTimestamp: ' +
                data.lastReadTimestamp);

            processReadItemsData(data.lastReadTimestamp);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.getTelephonyConversation = function () {
            var deferred = $q.defer();
            if (_showTelephonyConvReceived) {
                resolveTelephonyConversationPromise(deferred);
            } else {
                PubSubSvc.subscribeOnce('/conversations/showTelephonyConversation', function () {
                    resolveTelephonyConversationPromise(deferred);
                });
            }
            return deferred.promise;
        };

        this.deleteCallLog = function (itemId) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            if (!itemId) {
                return $q.reject('Missing itemId');
            }

            return new $q(function (resolve, reject) {
                _clientApiHandler.deleteCallLog(itemId, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[PhoneCallLogSvc]: Error deleting item. ', err);
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                });
            });
        };

        /**
         * Returns the call history items for the given options.
         *
         * @param {Object} [queryData] The query options for the request.
         * @param {JournalFilter} [queryData.journalFilter] The journal filter for the call histiry items. Default is ALL.
         * @param {Number} [queryData.numberOfResults] Number of results to fetch.
         * @param {String} [queryData.pagePointer] The pointer to fetch more results. If not set the first results are returned.
         *
         * @returns {Promise} Promise for object with call history items, pagePointer for next page and hasMore boolean.
         */
        this.getCallHistoryItems = function (queryData) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            queryData = queryData || {};
            queryData.journalFilter = queryData.journalFilter || JournalFilter.ALL;
            queryData.numberOfResults = queryData.numberOfResults || DEFAULT_NUMBER_OF_ENTRIES;

            LogSvc.debug('[PhoneCallLogSvc]: Return call history items for ', queryData);

            var cachedData = _cachedData[queryData.journalFilter];

            var timestamp = parseInt(queryData.pagePointer, 10);
            var startIdx = 0;
            if (timestamp) {
                var cachedItems = cachedData.cachedItems;
                // Look for index in cached data (oldest items are in front)
                var oldestIdx = cachedItems.findIndex(function (item) {
                    return item.creationTime >= timestamp;
                });
                startIdx = cachedItems.length - Math.max(oldestIdx, 0);
            }
            var totalEntries = startIdx + queryData.numberOfResults;

            return getCallJournalEntries(totalEntries, queryData.journalFilter)
                .then(function (items) {
                    // Reverse array so newest items are in front
                    var oldestItem = items[0];
                    items.reverse();

                    var data = {
                        items: items.slice(startIdx),
                        hasMore: !cachedData.hasOldestItem || cachedData.cachedItems.length > totalEntries
                    };
                    if (data.hasMore) {
                        data.pagePointer = oldestItem.creationTime + '';
                    }
                    return data;
                });
        };

        this.deleteVoiceMail = function (itemId) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            if (!itemId) {
                return $q.reject('Invalid itemId');
            }

            LogSvc.debug('[PhoneCallLogSvc]: Delete voicemail for itemId: ', itemId);

            return new $q(function (resolve, reject) {
                _clientApiHandler.updateRtcItemAttachments(itemId, [], function (error, item) {
                    $rootScope.$apply(function () {
                        if (error || !item) {
                            LogSvc.error('[PhoneCallLogSvc]: Error deleting voicemail. itemId: ', itemId);
                            reject(error);
                            return;
                        }
                        handleUpdatedItem(item);
                        resolve();
                    });
                });
            });
        };

        this.markCallHistoryAsRead = function () {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }

            var cachedItems = _cachedData[JournalFilter.ALL].cachedItems;
            var lastItem = cachedItems.length > 0 && cachedItems[cachedItems.length - 1];
            LogSvc.debug('[PhoneCallLogSvc]: Mark telephony conversation\'s last received item as read');

            if (!lastItem) {
                return $q.reject('Last item not found');
            }
            var creationTime = lastItem.creationTime;
            LogSvc.debug('[PhoneCallLogSvc]: Marked ' + _telephonyConversation.userData.unreadItems + ' items as read');

            var unreadItems = _telephonyConversation.userData.unreadItems;
            changeUnreadItemsBy(-_telephonyConversation.userData.unreadItems);
            _telephonyConversation.userData.unreadItems = 0;

            var lastReadTimestamp = _telephonyConversation.userData.lastReadTimestamp;
            if (!lastReadTimestamp || creationTime > lastReadTimestamp || unreadItems > 0) {
                LogSvc.debug('[PhoneCallLogSvc]: Marking all items as read');
                _telephonyConversation.userData.lastReadTimestamp = creationTime;

                return new $q(function (resolve, reject) {
                    _clientApiHandler.setReadPointer(creationTime, function (err) {
                        if (err) {
                            LogSvc.error('[PhoneCallLogSvc]: Error in API markItemsAsRead: ', err);
                            reject(err);
                        } else {
                            PubSubSvc.publish('/conversation/update', [_telephonyConversation]);
                            resolve(_telephonyConversation.userData.unreadItems);
                        }
                    });
                });
            }
            LogSvc.debug('[PhoneCallLogSvc]: All items were already read');
            return $q.resolve(0);
        };

        this.markVoicemailAsHeard = function (itemId) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            if (!itemId) {
                return $q.reject('Invalid itemId');
            }

            var cachedItem = _cachedItemsHash[itemId];
            if (cachedItem && !cachedItem.isVoicemailUnheard) {
                return $q.resolve(); // Voicemail already heard, nothing to do
            }

            LogSvc.debug('[PhoneCallLogSvc]: Mark voicemail as heard for itemId: ', itemId);
            return new $q(function (resolve, reject) {
                _clientApiHandler.setVoicemailAsHeard(itemId, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[PhoneCallLogSvc]: Failed to mark voicemail as heard. ', err);
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                });
            });
        };

        this.getUnheardVoicemailsCount = function () {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            if (_unheardVoicemailCount >= 0) {
                return $q.resolve(_unheardVoicemailCount);
            }
            return new $q(function (resolve, reject) {
                _clientApiHandler.getUnheardVoicemailsCount(_telephonyConversation.convId, function (err, count) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[PhoneCallLogSvc]: Failed to get unheard voicemails count. ', err);
                            reject(err);
                        } else {
                            _unheardVoicemailCount = count;
                            resolve(count);
                        }
                    });
                });
            });
        };

        this.createFeedGroups = function (items) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            if (!items) {
                return null;
            }
            LogSvc.debug('[PhoneCallLogSvc]: Grouping call history feed items. Number of items: ', items.length);

            if (!items.length) {
                return [];
            }

            var feedGroups = [];
            var currDate = null;
            var currGroup = {
                items: []
            };
            items.forEach(function (item) {
                if (item.type !== Constants.ConversationItemType.RTC) {
                    // Only show RTC items
                    return;
                }
                var date = new Date(item.creationTime).toDateString();
                if (currDate && currDate !== date) {
                    // Create a new group
                    feedGroups.push(currGroup);
                    currGroup = {
                        items: []
                    };
                }
                currDate = date;
                currGroup.timestamp = currGroup.timestamp || item.creationTime;
                currGroup.items.push(item);
            });
            feedGroups.push(currGroup);
            return feedGroups;
        };

        this.setRecordingDeletionDelay = function (itemId, retention) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }
            if (!itemId) {
                return $q.reject('Invalid itemId');
            }
            return ConversationSvc.setRecordingDeletionDelay(itemId, retention);
        };

        this.deleteAllCallLogs = function (journalFilter) {
            if (!_telephonyConversation) {
                return $q.reject('No telephony conversation');
            }

            journalFilter = journalFilter || JournalFilter.ALL;
            LogSvc.debug('[PhoneCallLogSvc]: Delete all call journals for filter ', journalFilter);
            return new $q(function (resolve, reject) {
                _clientApiHandler.deleteAllCallLogs(_telephonyConversation.convId, journalFilter, function (err) {
                    if (err) {
                        LogSvc.warn('[PhoneCallLogSvc]: Failed to delete all call journals. Error: ', err);
                        reject(err);
                    } else {
                        resolve();
                    }
                });
            });
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        clearCachedData(false);

        return this;
    }

    // Exports
    circuit.PhoneCallLogSvcImpl = PhoneCallLogSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
