/*global cordova, mozRTCIceCandidate, mozRTCPeerConnection, mozRTCSessionDescription, Promise,
require, RTCPeerConnectionUnified, webkitMediaStream, webkitRTCPeerConnection*/

/////////////////////////////////////////////////////////////////////////////////////////
//
// The following table shows the different names used by Chrome and Firefox for the
// standard Media Capture and WebRTC APIs.
//
// W3C Standard           Chrome                   Firefox                    Mobile
// --------------------------------------------------------------------------------------
// getUserMedia           webkitGetUserMedia       getUserMedia               getUserMedia
// RTCPeerConnection      webkitRTCPeerConnection  RTCPeerConnection          RTCPeerConnection
// RTCSessionDescription  RTCSessionDescription    RTCSessionDescription      RTCPeerConnection
// RTCIceCandidate        RTCIceCandidate          RTCIceCandidate            RTCIceCandidate
//
//
// The WebRTCAdapter object helps insulate our apps from these differences.
//
/////////////////////////////////////////////////////////////////////////////////////////

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var logger = circuit.logger;
    var Utils = circuit.Utils;

    var DESKTOP_MAX_WIDTH = 1920;
    var DESKTOP_MAX_HEIGHT = 1080;
    var DESKTOP_DEFAULT_MAX_FRAMERATE = 10;
    var DESKTOP_DEFAULT_MIN_FRAMERATE = 5;

    var VideoResolutionLevel = {
        VIDEO_1080: '1080p',
        VIDEO_720: '720p',
        VIDEO_480: '480p'
    };

    var RtpQualityLevel = Object.freeze({
        RTP_QUALITY_POOR: {levelName: 'poor', value: 0, threshold: 0}, // Level used only with Unified Plan
        RTP_QUALITY_LOW: {levelName: 'low', value: 1, threshold: 0.1},
        RTP_QUALITY_MEDIUM: {levelName: 'medium', value: 2, threshold: 0.03},
        RTP_QUALITY_HIGH: {levelName: 'high', value: 3, threshold: 0},

        // Deprecated
        getLevel: function (packetsLost) {
            if (packetsLost > this.RTP_QUALITY_LOW.threshold) {
                return this.RTP_QUALITY_LOW;
            } else if (packetsLost > this.RTP_QUALITY_MEDIUM.threshold) {
                return this.RTP_QUALITY_MEDIUM;
            } else {
                return this.RTP_QUALITY_HIGH;
            }
        }
    });

    var RtpStatsConfig = {
        COLLECTION_INTERVAL: 5000,
        DELAY_UPON_ANSWER: Utils.isMobile() ? 0 : 5000,
        DELAY_RTT_PROCESSING: 3 // googRTT takes few seconds to settle, so skip 3 stat collection cycles
    };

    /////////////////////////////////////////////////////////////////////////////////
    // Helper functions
    /////////////////////////////////////////////////////////////////////////////////
    var getTracks = function (stream) {
        if (typeof stream.getTracks === 'function') {
            return stream.getTracks();
        }
        return stream.getAudioTracks().concat(stream.getVideoTracks());
    };

    var stopMediaStream = function (stream) {
        if (stream) {
            try {
                stream.oninactive = null;
                // In the latest W3C definition the stop function belongs in the MediaStreamTrack, not the MediaStream.
                // But if MediaStreamTrack.stop() is not present, fallback to MediaStream.stop().
                var tracks = getTracks(stream);
                if (tracks.length && tracks[0].stop) {
                    tracks.forEach(function (t) {
                        t.onended = null;
                        t.stop();
                        logger.debug('[WebRTCAdapter]: Media track has been stopped:', t.label);
                    });
                } else if (stream.stop) {
                    stream.stop();
                }
                logger.info('[WebRTCAdapter]: Media stream has been stopped. Stream ID:', stream.id);
            } catch (e) {
                logger.error('[WebRTCAdapter]: Failed to stop media stream. ', e);
            }
        }
    };

    var stopLocalVideoTrack = function (stream) {
        if (stream) {
            try {
                var tracks = stream.getVideoTracks();
                if (tracks) {
                    tracks.forEach(function (track) {
                        if (track.stop && track.kind === 'video' && !track.remote) {
                            track.stop();
                        }
                    });
                } else {
                    logger.info('[WebRTCAdapter]: No video tracks to be changed');
                }
            } catch (e) {
                logger.error('[WebRTCAdapter]: Failed to disable local video tracks. ', e);
            }
        }
    };

    var closePc = function (pc) {
        try {
            pc.close();
            logger.debug('[WebRTCAdapter]: RTCPeerConnection has been closed');
        } catch (e) {
            logger.error(e);
        }
    };

    var supportsAudioOutputSelection = function () {
        // Check whether MediaDevices' enumerateDevices and HTMLMediaElement's setSinkId APIs are available.
        // These are experimental APIs supported in Chrome 45.0.2441.x or later.
        // You can enable it by selecting "Enable experimental Web Platform features" in chrome://flags

        return !!navigator.mediaDevices &&
            (typeof navigator.mediaDevices.enumerateDevices === 'function') &&
            (typeof HTMLMediaElement !== 'undefined') &&
            (typeof HTMLMediaElement.prototype.setSinkId === 'function');
    };

    var pendingEnumerateDevicesRequests = [];
    var pendingEnumerateDevicesTimeout = null;
    var cachedDeviceInfos = null;
    var CACHED_DEVICE_INFOS_TIMEOUT = 60000; // Keep cached devices for 60 seconds
    var ENUMERATE_DEVICES_TIMEOUT = 3000;

    var clearEnumerateDevicesTimeout = function () {
        if (pendingEnumerateDevicesTimeout) {
            window.clearTimeout(pendingEnumerateDevicesTimeout);
            pendingEnumerateDevicesTimeout = null;
        }
    };

    var invokePendingCallbacks = function (audioSources, videoSources, audioOutputs) {
        clearEnumerateDevicesTimeout();
        var callbacks = pendingEnumerateDevicesRequests;
        pendingEnumerateDevicesRequests = [];

        callbacks.forEach(function (cb) {
            try {
                cb(audioSources || [], videoSources || [], audioOutputs || null);
            } catch (err) {}
        });
    };

    var splitDeviceInfos = function (cb) {
        if (typeof cb !== 'function') {
            return;
        }
        var audioSources, audioOutputs, videoSources;

        logger.debug('[WebRTCAdapter]: Get audio output, microphone and camera devices');

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            audioSources = [{kind: 'audio', id: 'default', label: '', facing: ''}];
            videoSources = [{kind: 'video', id: 'default', label: '', facing: ''}];
            window.setTimeout(function () {
                cb(audioSources, videoSources, null);
            }, 0);
            return;
        }

        if (pendingEnumerateDevicesRequests.length > 0) {
            logger.debug('[WebRTCAdapter]: There is a pending MediaDevices.enumerateDevices() request');
            pendingEnumerateDevicesRequests.push(cb);
            return;
        }

        if (cachedDeviceInfos && (cachedDeviceInfos.validTimestamp > Date.now())) {
            logger.debug('[WebRTCAdapter]: Return cached devices');
            audioSources = cachedDeviceInfos.audioSources;
            audioOutputs = cachedDeviceInfos.audioOutputs;
            videoSources = cachedDeviceInfos.videoSources;

            window.setTimeout(function () {
                cb(audioSources, videoSources, audioOutputs);
            }, 0);
            return;
        }

        var invokeCallbacks = invokePendingCallbacks.bind(null);

        pendingEnumerateDevicesRequests.push(cb);

        logger.debug('[WebRTCAdapter]: Invoke MediaDevices.enumerateDevices()');

        clearEnumerateDevicesTimeout();
        pendingEnumerateDevicesTimeout = window.setTimeout(function () {
            pendingEnumerateDevicesTimeout = null;
            if (!pendingEnumerateDevicesRequests.length) {
                return;
            }
            if (cachedDeviceInfos) {
                logger.error('[WebRTCAdapter]: MediaDevices.enumerateDevices timed out. Return cached devices.');
                audioSources = cachedDeviceInfos.audioSources;
                audioOutputs = cachedDeviceInfos.audioOutputs;
                videoSources = cachedDeviceInfos.videoSources;
            } else {
                logger.error('[WebRTCAdapter]: MediaDevices.enumerateDevices timed out. Device cache is empty, no devices available!');
            }
            invokeCallbacks(audioSources, videoSources, audioOutputs);
            invokeCallbacks = null;

        }, ENUMERATE_DEVICES_TIMEOUT);

        navigator.mediaDevices.enumerateDevices()
        .then(function (deviceInfos) {
            deviceInfos = deviceInfos || [];
            audioSources = [];
            audioOutputs = [];
            videoSources = [];

            logger.info('[WebRTCAdapter]: MediaDevices.enumerateDevices returned ' + deviceInfos.length + ' device(s)');

            deviceInfos.forEach(function (info) {
                var item = {
                    id: info.deviceId,
                    groupId: info.groupId,
                    kind: info.kind,
                    label: info.label
                };

                if (item.label) {
                    // Strip device vendor/model from label
                    var match = item.label.match(/(.*)\s\(.*:.*\)/);
                    item.label = match && match[1] || item.label;
                }

                switch (item.kind) {
                case 'audioinput':
                    audioSources.push(item);
                    break;
                case 'audiooutput':
                    audioOutputs.push(item);
                    break;
                case 'videoinput':
                    videoSources.push(item);
                    break;
                }
            });

            // Only return audioOutputs if there is at least one device.
            // Also make sure the browser supports the HTMLMediaElement setSinkId API.
            if (audioOutputs.length === 0 || !supportsAudioOutputSelection()) {
                audioOutputs = null;
            }

            logger.debug('[WebRTCAdapter]: Update DeviceInfos cache');
            cachedDeviceInfos = {
                audioSources: audioSources,
                audioOutputs: audioOutputs,
                videoSources: videoSources
            };

            if (!audioSources.length) {
                cachedDeviceInfos.validTimestamp = 0;
            } else if (!audioSources.some(function (source) { return source.label; }) || (videoSources.length &&
                !videoSources.some(function (source) { return source.label; }))) {
                logger.debug('[WebRTCAdapter]: User hasn\'t authorized access to audio and/or video input devices yet');
                // User still hasn't authorized access to audio and video sources, so mark the cached
                // devices as expired, so we'll attempt to retrieve them again next time around
                cachedDeviceInfos.validTimestamp = 0;
            } else {
                cachedDeviceInfos.validTimestamp = Date.now() + CACHED_DEVICE_INFOS_TIMEOUT;
            }

            invokeCallbacks && invokeCallbacks(audioSources, videoSources, audioOutputs);
        })
        .catch(function (e) {
            logger.error('[WebRTCAdapter]: Failed to enumerate devices. ', e);
            invokeCallbacks && invokeCallbacks();
        });
    };

    var clearDeviceInfoCache = function () {
        logger.debug('[WebRTCAdapter]: Cleared device info cache');
        cachedDeviceInfos = null;
    };

    var separateIceServers = function (config) {
        if (!config || !config.iceServers) {
            return;
        }
        var iceServers = [];
        config.iceServers.forEach(function (iceServer) {
            if (iceServer.url) {
                iceServers.push(iceServer);
            } else {
                iceServer.urls && iceServer.urls.forEach(function (url) {
                    iceServers.push({
                        url: url,
                        credential: iceServer.credential,
                        username: iceServer.username
                    });
                });
            }
        });
        config.iceServers = iceServers;
    };

    var getReducedDefaultAudioOptions = function (config, useNewSyntax) {
        config = config || {};

        var options = [
            {autoGainControl: !!config.enableAudioAGC},
            {echoCancellation: !!config.enableAudioEC}
        ];
        return useNewSyntax ? { advanced: options } : { optional: options };
    };

    var getDefaultAudioOptions = function (config) {
        config = config || {};
        return {
            optional: [
                // Echo cancellation constraints
                {echoCancellation: !!config.enableAudioEC},
                {googEchoCancellation: !!config.enableAudioEC},
                {googEchoCancellation2: !!config.enableAudioEC},
                {autoGainControl: !!config.enableAudioAGC},
                {googAutoGainControl: !!config.enableAudioAGC},
                {googAutoGainControl2: !!config.enableAudioAGC},
                {noiseSuppression: true},
                {googNoiseSuppression: true},
                {googNoiseSuppression2: true},
                {googHighpassFilter: true}
            ]
        };
    };

    var getDefaultVideoOptionsNewConstraint = function (config) {
        var options;
        if (config && config.videoResolution) {
            options = {
                aspectRatio: { min: 1.77, max: 1.78 },
                frameRate: { min: 24, max: 30 }
            };
            if (config.minFrameRate) {
                options.frameRate.min = Math.min(config.minFrameRate, options.frameRate.max);
            }
            if (config.sourceId) {
                options.deviceId = { exact: config.sourceId };
            }
            switch (config.videoResolution) {
            case VideoResolutionLevel.VIDEO_1080:
                // Full HD
                options.width = { min: 1920 };
                options.height = { min: 1080 };
                break;
            case VideoResolutionLevel.VIDEO_720:
                // HD
                options.width = { min: 1280 };
                options.height = { min: 720 };
                break;
            case VideoResolutionLevel.VIDEO_480:
                // SD
                options.width = { min: 854 };
                options.height = { min: 480 };
                break;
            }
            return options;
        }
        // return video in 16:9 aspect ratio by default
        options = {
            aspectRatio: { min: 1.77, max: 1.78 }
        };
        if (config.sourceId) {
            // If resolution is not set, deviceId is optional
            options.deviceId = config.sourceId;
        }
        return options;
    };

    var getDefaultVideoOptions = function (config) {
        if (circuit.WebRTCAdapter.useNewConstraintSyntax) {
            return getDefaultVideoOptionsNewConstraint(config);
        }

        var options = {
            mandatory: {
                // set video in 16:9 aspect ratio by default
                minAspectRatio: 1.77,
                maxAspectRatio: 1.78
            },
            optional: [
                {googNoiseReduction: true}
            ]
        };

        if (config) {
            if (config.videoResolution) {
                // FrameRate
                options.mandatory.minFrameRate = 24;
                options.mandatory.maxFrameRate = 30;
                if (config.minFrameRate) {
                    options.mandatory.minFrameRate = Math.min(config.minFrameRate, options.mandatory.maxFrameRate);
                }
                if (config.sourceId) {
                    options.mandatory.sourceId = config.sourceId;
                }
                switch (config.videoResolution) {
                case VideoResolutionLevel.VIDEO_1080:
                    // Full HD
                    options.mandatory.minWidth = 1920;
                    options.mandatory.maxWidth = 1920;
                    options.mandatory.minHeight = 1080;
                    options.mandatory.maxHeight = 1080;
                    break;
                case VideoResolutionLevel.VIDEO_720:
                    // HD
                    options.mandatory.minWidth = 1280;
                    options.mandatory.maxWidth = 1280;
                    options.mandatory.minHeight = 720;
                    options.mandatory.maxHeight = 720;
                    break;
                case VideoResolutionLevel.VIDEO_480:
                    // SD
                    options.mandatory.minWidth = 853;
                    options.mandatory.maxWidth = 854;
                    options.mandatory.minHeight = 480;
                    options.mandatory.maxHeight = 480;
                    break;
                }
            } else if (config.sourceId) {
                // If resolution is not set, sourceId is optional
                options.optional.push({ sourceId: config.sourceId });
            }
        }

        return options;
    };

    var toggleAudio = function (stream, enable) {
        var audio = stream && stream.getAudioTracks();
        if (audio && audio[0]) {
            audio[0].enabled = !!enable;
            return true;
        }
        return false;
    };

    var attachSinkIdToAudioElement = function (audioElement, deviceList, cb) {
        cb = cb || function () {};

        logger.debug('[WebRTCAdapter]: Attach sinkId to audio element');

        if (!audioElement) {
            cb('Invalid parameters');
            return;
        }
        if (!deviceList || !deviceList.length) {
            logger.debug('[WebRTCAdapter]: There is no audio output device selected');
            cb();
            return;
        }
        if (typeof audioElement.sinkId !== 'undefined' && circuit.WebRTCAdapter) {
            // In order to find out which device in deviceList can be used, we need to get the latest list of
            // devices that are plugged in
            logger.debug('[WebRTCAdapter]: Get media sources to find available device');
            circuit.WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                var foundMatch = circuit.Utils.selectMediaDevice(audioOutputDevices, deviceList);
                var deviceId = (foundMatch && foundMatch.id) || '';

                logger.debug('[WebRTCAdapter]: Found audio output device to attach as sinkId. deviceId = ', deviceId || 'NONE');

                if (audioElement.sinkId === deviceId) {
                    // Sink ID already set
                    logger.debug('[WebRTCAdapter]: Audio output device is already attached to element');
                    cb();
                    return;
                }

                if (!deviceId) {
                    // We had another device configured as the sink ID and we are falling back to the default device
                    logger.warn('[WebRTCAdapter]: A previously selected output device could not be found. Fallback to browser\'s default. Device not found: ', audioElement.sinkId);
                }

                var timeout = window.setTimeout(function () {
                    timeout = null;
                    logger.error('[WebRTCAdapter]: Timed out attaching audio output device: ', deviceId || '<default>');
                    cb('timed out');
                }, 3000);
                audioElement.setSinkId(deviceId)
                .then(function () {
                    if (!timeout) { return; }
                    window.clearTimeout(timeout);
                    logger.debug('[WebRTCAdapter]: Success, audio output device attached: ', deviceId || '<default>');
                    cb();
                })
                .catch(function (error) {
                    if (!timeout) { return; }
                    window.clearTimeout(timeout);
                    error = error || {};
                    var errorMessage = error.message || 'unknown error';
                    if (error.name === 'SecurityError') {
                        errorMessage = 'You need to use HTTPS for selecting audio output device.';
                    }
                    logger.error('[WebRTCAdapter]: Failed to attach output device. Device Id:' + deviceId + ' - ', errorMessage);
                    cb(errorMessage);
                });
                logger.debug('[WebRTCAdapter]: setSinkId has been invoked');
            });
        } else {
            cb('setSinkId not available');
        }
    };

    var convertDeprecatedSdpConstraints = function (deprecatedConstraints) {
        var offerOptions = {
            offerToReceiveAudio: 1,
            offerToReceiveVideo: 1
        };
        if (deprecatedConstraints) {
            if (deprecatedConstraints.mandatory) {
                if (deprecatedConstraints.mandatory.hasOwnProperty('OfferToReceiveAudio')) {
                    offerOptions.offerToReceiveAudio = deprecatedConstraints.mandatory.OfferToReceiveAudio ? 1 : 0;
                }
                if (deprecatedConstraints.mandatory.hasOwnProperty('OfferToReceiveVideo')) {
                    offerOptions.offerToReceiveVideo = deprecatedConstraints.mandatory.OfferToReceiveVideo ? 1 : 0;
                }
            }
            if (deprecatedConstraints.optional) {
                deprecatedConstraints.optional.some(function (constraint) {
                    if (constraint.hasOwnProperty('VoiceActivityDetection')) {
                        offerOptions.voiceActivityDetection = !!constraint.VoiceActivityDetection;
                        return true;
                    }
                    return false;
                });
            }
        }
        return offerOptions;
    };

    // Method to move a specific constraint parameter from mandatory to the optional section
    // This method handles both Chrome's (old) and FF's (new spec) style of constraints, that's why we need
    // to have both of them passed as parameters (chromeParam and specParam)
    var moveMandatoryToOptional = function (constraints, media, chromeParam, specParam) {
        if (!constraints || !constraints[media]) {
            return constraints;
        }
        var mediaConstraints = constraints[media];
        if (mediaConstraints.mandatory) {
            // Old style constraints (Chrome): move param from mandatory to optional array
            var required = mediaConstraints.mandatory[chromeParam];
            if (required) {
                // Clone constraints, so input constraint is not modified
                constraints = Object.create(constraints);
                var newMediaConstraints = constraints[media];
                newMediaConstraints.optional = newMediaConstraints.optional || [];
                var obj = {};
                obj[chromeParam] = required;
                newMediaConstraints.optional.push(obj);
                delete newMediaConstraints.mandatory;
            }
        } else {
            var paramConfig = mediaConstraints[specParam];
            if (paramConfig && paramConfig.exact) {
                // New style constraints (WebRTC spec). Just move the value from the exact property
                // directly to param (this will make it 'ideal', or optional)
                constraints = Object.create(constraints);
                constraints[media][specParam] = paramConfig.exact;
            }
        }
        return constraints;
    };

    var getUserMediaCommon = function (method, context, constraints, successCallback, errorCallback) {
        var onErrorCb = function (err) {
            var newConstraints = moveMandatoryToOptional(constraints, 'audio', 'sourceId', 'deviceId');
            if (newConstraints !== constraints) {
                logger.warn('[WebRTCAdapter]: First getUserMedia attempt with required audio sourceId/deviceId failed. Falling back to optional.');
                var sCb = function (stream) {
                    // Pass the exception object along with the stream, so the caller knows we had an exception
                    successCallback(stream, err);
                };
                // The getUserMedia method can be either a promise or callback version
                var promise = method.apply(context, [newConstraints, sCb, errorCallback]);
                if (promise && promise.then) {
                    promise.then(sCb).catch(errorCallback);
                }
            } else {
                errorCallback && errorCallback(err);
            }
        };
        // The getUserMedia method can be either a promise or callback version
        var promise = method.apply(context, [constraints, successCallback, onErrorCb]);
        if (promise && promise.then) {
            promise.then(successCallback).catch(onErrorCb);
        }
    };

    var timedGetUserMedia = function (method, context, constraints, successCallback, errorCallback) {
        // Firefox has some bugs regarding 'getUserMedia' popup:
        // Bug 947266 - Navigator.getUserMedia should either remove or let webpages detect 'Not now' case
        // Bug 846584 - WebRTC getUserMedia doorhanger has 3 ways of rejecting the request, and there's no clear distinction between them
        //
        // Chrome also has some problems with very lengthy or unresponsive getUserMedia's
        // Desktop App doesn't need to request permission to the user, so the timeout is shorter (10s)
        //
        // Workaround by implementing timeout for recalling/reshowing 'getUserMedia' request

        var getUserMediaTimeout = null;
        var getUserMediaLimit = 3;
        var getUserMediaCounter = 0;

        function onSuccess(stream, exception) {
            logger.info('[WebRTCAdapter]: Get user media succeeded');
            if (successCallback) {
                successCallback(stream, exception);
            } else {
                // Stop the media stream since it won't be used anymore
                stopMediaStream(stream);
            }
            successCallback = null;
            errorCallback = null;
        }

        function onError(error) {
            logger.warn('[WebRTCAdapter]: Get user media failed: ', (error && error.name) || error);
            getUserMediaTimeout && window.clearTimeout(getUserMediaTimeout);
            getUserMediaTimeout = null;
            errorCallback && errorCallback(error);
            successCallback = null;
            errorCallback = null;
        }

        function getUserMediaHandler() {
            logger.info('[WebRTCAdapter]: Get user media attempt #:', getUserMediaCounter);
            getUserMediaTimeout = null;
            if (getUserMediaCounter === getUserMediaLimit) {
                onError('Timeout');
                return;
            }
            ++getUserMediaCounter;
            getUserMediaCommon(method, context, constraints, function (stream, exception) {
                onSuccess(stream, exception);
                getUserMediaTimeout && window.clearTimeout(getUserMediaTimeout);
                getUserMediaTimeout = null;
            }, onError);

            getUserMediaTimeout = window.setTimeout(getUserMediaHandler, 10000);
        }

        getUserMediaHandler();
    };

    var copyTransportIdFromReceiverStats = function (sender, receiver) {
        receiver.some(function (r) {
            if (r.type === 'ssrc') {
                if (r.stats.transportId) {
                    sender.some(function (s) {
                        if (s.type === 'ssrc') {
                            s.stats.transportId = r.stats.transportId;
                            return true;
                        }
                        return false;
                    });
                }
                return true;
            }
            return false;
        });
    };

    var setEncodingParameters = function (rtpTransceiver, parameters, maxBitrate, degradationPreference, scaleResolutionDownBy) {
        if (!rtpTransceiver || !parameters || !maxBitrate) {
            return;
        }

        var appliedParams = [];
        if (degradationPreference) {
            parameters.degradationPreference = degradationPreference;
            appliedParams.push('degradationPreference=' + degradationPreference);
        }
        if (parameters.encodings && parameters.encodings.length > 0) {
            parameters.encodings[0].maxBitrate = maxBitrate;
            appliedParams.push('maxBitrate=' + maxBitrate);
            if (scaleResolutionDownBy) {
                parameters.encodings[0].scaleResolutionDownBy = scaleResolutionDownBy;
                appliedParams.push('scaleResolutionDownBy=' + scaleResolutionDownBy);
            }
        } else {
            logger.error('[WebRTCAdapter]: Unable to set max bitrate due to read-only encoding parameters for RtpSender mid=' + rtpTransceiver.mid);
        }

        if (appliedParams.length) {
            rtpTransceiver.sender.setParameters(parameters)
            .then(function () {
                logger.debug('[WebRTCAdapter]: Applied parameters {' + appliedParams + '} to RtpSender mid=' + rtpTransceiver.mid);
            })
            .catch(function (err) {
                logger.error('[WebRTCAdapter]: Could not apply parameters {' + appliedParams + '} to RtpSender mid=' + rtpTransceiver.mid + ': ', (err && err.message) || err);
            });
        }
    };

    /////////////////////////////////////////////////////////////////////////////////
    // Chrome
    /////////////////////////////////////////////////////////////////////////////////
    function initForChrome() {
        logger.info('Setting WebRTC APIs for Chrome');

        // Temporary workaround to solve Chrome issue #467490
        // https://code.google.com/p/chromium/issues/detail?id=467490
        RTCSessionDescription.prototype.toJSON = function () {
            return {
                type: this.type,
                sdp: this.sdp
            };
        };

        RTCIceCandidate.prototype.toJSON = function () {
            return {
                sdpMLineIndex: this.sdpMLineIndex,
                sdpMid: this.sdpMid,
                candidate: this.candidate
            };
        };

        circuit.WebRTCAdapter = {
            enabled: true,
            browser: 'chrome',
            useNewConstraintSyntax: false,
            useReducedAudioConstraints: true,
            MediaStream: webkitMediaStream,
            PeerConnection: function (config, constraints) {
                var pc = new webkitRTCPeerConnection(config, constraints);
                pc.origCreateOffer = pc.createOffer;
                pc.createOffer = function (successCb, errorCb, offerConstraints) {
                    var offerOptions;
                    if (offerConstraints) {
                        offerOptions = convertDeprecatedSdpConstraints(offerConstraints);
                    }
                    pc.origCreateOffer(offerOptions)
                    .then(function (sdp) {
                        successCb && successCb(sdp);
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origCreateAnswer = pc.createAnswer;
                pc.createAnswer = function (successCb, errorCb, options) {
                    var answerOptions = convertDeprecatedSdpConstraints(options);
                    pc.origCreateAnswer(answerOptions)
                    .then(function (sdp) {
                        successCb && successCb(sdp);
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };

                return pc;
            },
            SessionDescription: RTCSessionDescription,
            IceCandidate: RTCIceCandidate,
            getUserMedia: function (constraints, successCallback, errorCallback) {
                timedGetUserMedia(navigator.mediaDevices.getUserMedia, navigator.mediaDevices, constraints, successCallback, errorCallback);
            },
            createObjectURL: URL.createObjectURL.bind(URL),
            getAudioStreamTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? stream.id + ' ' + audioTrack.id : '';
            },
            getVideoStreamTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? stream.id + ' ' + videoTrack.id : '';
            },
            getAudioTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.id : '';
            },
            getVideoTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.id : '';
            },
            getStreamId: function (stream) {
                return stream && stream.id;
            },
            getTrackId: function (track) {
                return track && track.id;
            },
            hasDeviceNameOnTrackLabel: true,
            getAudioTrackLabel: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.label : '';
            },
            getVideoTrackLabel: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.label : '';
            },
            stopMediaStream: stopMediaStream,
            stopLocalVideoTrack: stopLocalVideoTrack,
            closePc: closePc,
            getMediaSources: function (cb) {
                cb && splitDeviceInfos(cb);
            },
            clearMediaSourcesCache: function () {
                clearDeviceInfoCache();
            },
            getAudioOptions: function (config) {
                var audio = Circuit.WebRTCAdapter.useReducedAudioConstraints ? getReducedDefaultAudioOptions(config, false) :
                    getDefaultAudioOptions(config);

                if (config && config.sourceId && (config.sourceId !== 'default')) {
                    // Start out sourceId as mandatory, it will be moved to optional if it fails
                    audio.mandatory = audio.mandatory || {};
                    audio.mandatory.sourceId = config.sourceId;
                }
                return audio;
            },
            getVideoOptions: getDefaultVideoOptions,
            getDesktopOptions: function (streamId, screenConstraint) {
                screenConstraint = screenConstraint || {};
                var fr = screenConstraint.frameRate || {};
                fr.min = fr.min || DESKTOP_DEFAULT_MIN_FRAMERATE;
                fr.max = fr.max || DESKTOP_DEFAULT_MAX_FRAMERATE;

                return {
                    video: {
                        width: {max: DESKTOP_MAX_WIDTH},
                        height: {max: DESKTOP_MAX_HEIGHT},
                        framerate: {min: fr.min, max: fr.max}
                    }
                };
            },
            setEncodingParameters: function (rtpTransceiver, maxBitrate, degradationPreference, scaleResolutionDownBy) {
                var parameter = rtpTransceiver.sender.getParameters();
                setEncodingParameters(rtpTransceiver, parameter, maxBitrate, degradationPreference, scaleResolutionDownBy);
            },
            audioOutputSelectionSupported: supportsAudioOutputSelection(),
            groupPeerConnectionsSupported: true,
            getMediaSourcesSupported: true,
            attachSinkIdToAudioElement: attachSinkIdToAudioElement,
            toggleAudio: toggleAudio,
            getNetmaskMap: function () { return {}; }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    // Firefox
    /////////////////////////////////////////////////////////////////////////////////
    function normalizeCandidateInfo(stats) {
        // FFv65 changed from ipAddress to address and portNumber to port
        stats.ipAddress = stats.address;
        stats.portNumber = stats.port;
        stats.protocol = stats.protocol || stats.transport;
    }

    function createFirefoxStatsFn() {
        // Convert Firefox stats to Chrome's format
        var Stat = function (stats, kind, mid) {
            // We consider the first 2 m-lines as the main channel and it should always be in the QoS reports.
            var _isMainChannel = mid === '0' || mid === '1';

            this.stats = stats;
            this.id = stats.id;

            switch (stats.type) {
            case 'inboundrtp':
            case 'inbound-rtp':
                if (!stats.isRemote) {
                    this.type = 'ssrc';
                    this.stats.mid = mid;
                    if (kind === 'audio') {
                        this.stats.audioOutputLevel = true;
                        this.stats.googJitterReceived = stats.jitter * 1000;
                    } else {
                        this.stats.googFrameHeightReceived = true;
                    }
                    if (_isMainChannel) {
                        this.stats.googTrackId = kind === 'audio' ? 'audio_main' : 'video_main';
                    }
                }
                break;
            case 'outboundrtp':
            case 'outbound-rtp':
                if (!stats.isRemote) {
                    this.type = 'ssrc';
                    this.stats.mid = mid;
                    if (kind === 'audio') {
                        this.stats.audioInputLevel = true;
                    } else {
                        this.stats.googFrameHeightSent = true;
                        this.stats.googFrameRateSent = Math.round(stats.framerateMean);
                        this.stats.googTransmitBitrate = Math.round(stats.bitrateMean);
                    }
                    if (_isMainChannel) {
                        this.stats.googTrackId = kind === 'audio' ? 'audio_main' : 'video_main';
                    }
                }
                break;
            case 'candidatepair':
            case 'candidate-pair':
                // Candidate pair info is now available in FF, but there's no indication of what
                // kind of media they belong to yet. Still waiting for more getStats improvements:
                // https://bugzilla.mozilla.org/showdependencytree.cgi?id=964161&hide_resolved=1
                this.type = 'googCandidatePair';
                if (typeof stats.selected === 'boolean' && stats.selected && stats.state === 'succeeded') {
                    this.stats.googActiveConnection = 'true';
                    this.stats.googChannelId = stats.transportId;
                } else {
                    this.discard = true;
                }
                break;
            case 'local-candidate':
                this.type = 'localcandidate';
                normalizeCandidateInfo(this.stats);
                break;
            case 'remote-candidate':
                this.type = 'remotecandidate';
                normalizeCandidateInfo(this.stats);
                break;
            default:
                this.type = stats.type;
                break;
            }
        };
        Stat.prototype.names = function () { return Object.keys(this.stats); };
        Stat.prototype.stat = function (type) { return this.stats[type]; };

        var convertStats = function (stats, kind, isMainChannel) {
            var items = [];
            var transportId, googRtt;
            stats.forEach(function (item) {
                var converted = new Stat(item, kind, isMainChannel);
                if (!converted.discard) {
                    if (converted.type === 'googCandidatePair') {
                        transportId = converted.stats.transportId;
                    } else if (item.type === 'remote-inbound-rtp' && item.mediaType === 'video') {
                        googRtt = converted.stats.roundTripTime * 1000;
                    }
                    items.push(converted);
                }
            });
            if (transportId || googRtt >= 0) {
                items.forEach(function (i) {
                    if (i.type === 'ssrc' && transportId) {
                        // Associate the ssrc with its candidate pairs through the transportId
                        i.stats.transportId = transportId;
                    } else if (i.type === 'googCandidatePair' && !i.discard && googRtt >= 0) {
                        // Add the rtt from remote-inbound-rtp
                        i.stats.googRtt = googRtt;
                    }
                });
            }
            return items;
        };

        /**
         * Getting stats from Firefox consists of:
         * 1- invoke getStats() for each RTCRtpSender and RTCRtpReceiver
         * 2- if a RTCRtpSender and a RTCRtpReceiver are in the same Transceiver, they are using the
         *    same channel, so share the channel info between the 2 of them (needs more testing).
         * 3- combine all stats into a single array
         */
        return function (pc) {
            var combinedStats = [];
            var rtpTransceivers = pc.getTransceivers();
            var promises = [];
            rtpTransceivers.forEach(function (t) {
                if (t.receiver && t.receiver.track) {
                    // First get the receiver stats
                    promises.push(new Promise(function (resolve) {
                        t.receiver.getStats()
                        .then(function (receiverStats) {
                            var receiverConverted = convertStats(receiverStats, t.receiver.track.kind, t.mid);
                            if (t.sender && t.sender.track) {
                                // Get the sender stats, which we can't determine its transportId,
                                // so get it from the receiver stats and copy it to the sender's
                                t.sender.getStats()
                                .then(function (stats) {
                                    var senderConverted = convertStats(stats, t.sender.track.kind, t.mid);
                                    copyTransportIdFromReceiverStats(senderConverted, receiverConverted);
                                    Array.prototype.push.apply(combinedStats, receiverConverted);
                                    Array.prototype.push.apply(combinedStats, senderConverted);
                                    resolve();
                                })
                                .catch(function (error) {
                                    logger.debug('[WebRTCAdapter]: Error collecting stats for track: ' + t.sender.track.label + ' . Error: ', error);
                                    Array.prototype.push.apply(combinedStats, receiverConverted);
                                    resolve();
                                });
                            } else {
                                Array.prototype.push.apply(combinedStats, receiverConverted);
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            logger.debug('[WebRTCAdapter]: Error collecting stats for track: ' + t.sender.track.label + ' . Error: ', error);
                            resolve();
                        });
                    }));
                } else if (t.sender && t.sender.track) {
                    promises.push(new Promise(function (resolve) {
                        t.sender.getStats()
                        .catch(function (error) {
                            logger.debug('[WebRTCAdapter]: Error collecting stats for track: ' + t.sender.track.label + ' . Error: ', error);
                        })
                        .then(function (stats) {
                            if (stats) {
                                Array.prototype.push.apply(combinedStats, convertStats(stats, t.sender.track.kind, t.mid));
                            }
                            resolve();
                        });
                    }));
                }
            });
            return Promise.all(promises)
                .then(function () {
                    return combinedStats;
                });
        };
    }

    function createFirefoxPC() {
        var PeerConnection = typeof RTCPeerConnection === 'undefined' ? mozRTCPeerConnection : RTCPeerConnection;
        var processGetStats = createFirefoxStatsFn();

        return function (configuration) {
            var pc = new PeerConnection(configuration);

            pc.origCreateOffer = pc.createOffer;
            pc.createOffer = function (successCb, errorCb) {
                // With Unified Plan we can't pass any parameter to origCreateOffer()
                pc.origCreateOffer()
                .then(function (sdp) {
                    successCb && successCb(sdp);
                })
                .catch(function (err) {
                    errorCb && errorCb(err);
                });
            };
            pc.origCreateAnswer = pc.createAnswer;
            pc.createAnswer = function (successCb, errorCb) {
                // With Unified Plan we can't pass any parameter to origCreateAnswer()
                pc.origCreateAnswer()
                .then(function (sdp) {
                    successCb && successCb(sdp);
                })
                .catch(function (err) {
                    errorCb && errorCb(err);
                });
            };

            pc.origGetStats = pc.getStats;
            pc.getStats = function () {
                return processGetStats(pc);
            };
            pc.removeStream = function () {};
            pc.createDTMFSender = function (audioTrack) {
                if (typeof pc.getSenders !== 'function') {
                    return null;
                }
                var audioSender = pc.getSenders().find(function (sender) {
                    return sender.track === audioTrack;
                });
                var dtmfSender = audioSender && audioSender.dtmf;
                if (!dtmfSender) {
                    return null;
                }
                dtmfSender.canInsertDTMF = true; // set obsolete property
                return dtmfSender;
            };
            // Override addStream with our own, which makes use of addTrack
            pc.addStream = function (stream) {
                var audio = stream.getAudioTracks()[0];
                if (audio) {
                    pc.addTrack(audio, stream);
                }
                var video = stream.getVideoTracks()[0];
                if (video) {
                    pc.addTrack(video, stream);
                }
            };
            // Override getRemoteStreams with our own, which makes use of RTCRtpTransceivers
            pc.getRemoteStreams = function () {
                var remoteStreams = [];
                var receivers = pc.getReceivers();
                receivers.forEach(function (r) {
                    if (r.track && r.track.mid) {
                        var stream = new MediaStream([r.track]);
                        remoteStreams.push(stream);
                    }
                });
                return remoteStreams;
            };
            // Override getLocalStreams with our own, which makes use of RTCRtpTransceivers
            pc.getLocalStreams = function () {
                var localStreams = [];
                var senders = pc.getSenders();
                senders.forEach(function (s) {
                    if (s.track) {
                        var stream = new MediaStream([s.track]);
                        localStreams.push(stream);
                    }
                });
                return localStreams;
            };

            // Override deprecated onaddstream property for Firefox v46 and newer
            Object.defineProperty(pc, 'onaddstream', {
                enumerable: true,
                get: function () {
                    return pc.onaddstreamCb;
                },
                set: function (cb) {
                    pc.onaddstreamCb = cb;
                    if (cb) {
                        // Use ontrack instead of onaddstream
                        pc.ontrack = function (event) {
                            var mid = event && event.transceiver && event.transceiver.mid;
                            if (mid && event.track) {
                                event.track.mid = mid;
                            }
                            cb({stream: event && event.streams && event.streams[0]});
                        };
                    } else {
                        pc.ontrack = null;
                    }
                }
            });

            return pc;
        };
    }

    function initForFirefox() {
        logger.info('Setting WebRTC APIs for Firefox');

        // Firefox has deprecated the 'moz' prefix, but we still need with older versions
        var SessionDescription = typeof RTCSessionDescription === 'undefined' ? mozRTCSessionDescription : RTCSessionDescription;
        var IceCandidate = typeof RTCIceCandidate === 'undefined' ? mozRTCIceCandidate : RTCIceCandidate;

        circuit.WebRTCAdapter = {
            enabled: true,
            browser: 'firefox',
            useNewConstraintSyntax: true,
            MediaStream: MediaStream,
            PeerConnection: createFirefoxPC(),
            SessionDescription: SessionDescription,
            IceCandidate: IceCandidate,
            getUserMedia: function (constraints, successCallback, errorCallback) {
                timedGetUserMedia(navigator.mediaDevices.getUserMedia, navigator.mediaDevices, constraints, successCallback, errorCallback);
            },
            createObjectURL: function (object) {
                return URL.createObjectURL(object);
            },
            getAudioStreamTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? stream.id + ' ' + (audioTrack.mid || audioTrack.id) : '';
            },
            getVideoStreamTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? stream.id + ' ' + (videoTrack.mid || videoTrack.id) : '';
            },
            getAudioTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.mid || audioTrack.id : '';
            },
            getVideoTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.mid || videoTrack.id : '';
            },
            getStreamId: function (stream) {
                return stream && stream.id;
            },
            getTrackId: function (track) {
                return track && track.id;
            },
            hasDeviceNameOnTrackLabel: false,
            getAudioTrackLabel: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.label : '';
            },
            getVideoTrackLabel: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.label : '';
            },
            stopMediaStream: stopMediaStream,
            stopLocalVideoTrack: stopLocalVideoTrack,
            closePc: function (pc) {
                // Wait 500ms before closing the peer connection to give time for the stats to be collected
                window.setTimeout(function () {
                    closePc(pc);
                }, 500);
            },
            getMediaSources: function (cb) {
                cb && splitDeviceInfos(cb);
            },
            clearMediaSourcesCache: function () {
                clearDeviceInfoCache();
            },
            getAudioOptions: function (config) {
                var audio = getDefaultAudioOptions(config);
                if (config && config.sourceId) {
                    // Start out deviceId as mandatory, it will be moved to optional if it fails
                    audio.deviceId = {exact: config.sourceId};
                }
                audio.advanced = audio.optional;
                delete audio.optional;
                return audio;
            },
            getVideoOptions: getDefaultVideoOptions,
            getDesktopOptions: function (streamId, screenConstraint) {
                screenConstraint = screenConstraint || {};
                var fr = screenConstraint.frameRate || {};
                fr.min = fr.min || DESKTOP_DEFAULT_MIN_FRAMERATE;
                fr.max = fr.max || DESKTOP_DEFAULT_MAX_FRAMERATE;

                if (navigator.mediaDevices.getDisplayMedia) {
                    // As of v72.0.2, these constraints don't work
                    return {
                        video: {
                            width: {max: DESKTOP_MAX_WIDTH},
                            height: {max: DESKTOP_MAX_HEIGHT},
                            framerate: {min: fr.min, max: fr.max}
                        }
                    };
                }

                return {
                    mozMediaSource: streamId || 'screen', // Deprecated
                    mediaSource: streamId || 'screen',
                    width: {max: DESKTOP_MAX_WIDTH},
                    height: {max: DESKTOP_MAX_HEIGHT},
                    advanced: [{frameRate: fr}]
                };
            },
            setEncodingParameters: function (rtpTransceiver, maxBitrate, degradationPreference, scaleResolutionDownBy) {
                var parameters = {encodings: [{}]};
                setEncodingParameters(rtpTransceiver, parameters, maxBitrate, degradationPreference, scaleResolutionDownBy);
            },
            audioOutputSelectionSupported: supportsAudioOutputSelection(),
            groupPeerConnectionsSupported: true,
            getMediaSourcesSupported: true,
            attachSinkIdToAudioElement: attachSinkIdToAudioElement,
            iceTimeoutSafetyFactor: 0.5, // Wait 50% more when there are multiple peer connections
            toggleAudio: toggleAudio,
            getNetmaskMap: function () { return {}; }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    // Safari
    /////////////////////////////////////////////////////////////////////////////////
    function createSafariStatsFn() {
        // Convert stats to Chrome's format (this can be removed once we move Chrome to standard promise based getStats)
        var SafariStat = function (stats, kind, mid) {
            // We consider the first 2 m-lines as the main channel and it should always be
            // in the QoS reports
            var _isMainChannel = mid === '0' || mid === '1';

            this.stats = stats;
            this.id = stats.id;
            switch (stats.type) {
            case 'inboundrtp':
            case 'inbound-rtp':
                if (!stats.isRemote) {
                    this.type = 'ssrc';
                    this.stats.mid = mid;
                    if (kind === 'audio') {
                        this.stats.audioOutputLevel = true;
                        this.stats.googJitterReceived = stats.jitter * 1000;
                    } else {
                        this.stats.googFrameHeightReceived = true;
                    }
                    if (_isMainChannel) {
                        this.stats.googTrackId = kind === 'audio' ? 'audio_main' : 'video_main';
                    }
                }
                break;
            case 'outboundrtp':
            case 'outbound-rtp':
                if (!stats.isRemote) {
                    this.type = 'ssrc';
                    this.stats.mid = mid;
                    if (kind === 'audio') {
                        this.stats.audioInputLevel = true;
                    } else {
                        this.stats.googFrameHeightSent = true;
                        this.stats.googFrameRateSent = stats.framerateMean;
                        this.stats.googTransmitBitrate = stats.bitrateMean;
                    }
                    if (_isMainChannel) {
                        this.stats.googTrackId = kind === 'audio' ? 'audio_main' : 'video_main';
                    }
                }
                break;
            case 'candidatepair':
            case 'candidate-pair':
                this.type = 'googCandidatePair';
                if (stats.state === 'succeeded') {
                    this.stats.googActiveConnection = 'true';
                    this.stats.googChannelId = stats.transportId;
                    this.stats.googRtt = stats.currentRoundTripTime * 1000;
                } else {
                    this.discard = true;
                }
                break;
            case 'local-candidate':
                this.type = 'localcandidate';
                stats.portNumber = stats.port;
                break;
            case 'remote-candidate':
                this.type = 'remotecandidate';
                stats.portNumber = stats.port;
                break;
            default:
                this.type = stats.type;
                break;
            }
        };
        SafariStat.prototype.names = function () { return Object.keys(this.stats); };
        SafariStat.prototype.stat = function (type) { return this.stats[type]; };

        var convertSafariStats = function (stats, kind, isMainChannel) {
            var items = [];
            var transportId;
            stats.forEach(function (item) {
                var converted = new SafariStat(item, kind, isMainChannel);
                if (!converted.discard) {
                    if (converted.type === 'googCandidatePair') {
                        transportId = converted.stats.transportId;
                    }
                    items.push(converted);
                }
            });
            if (transportId) {
                // Associate ssrc's with their candidate pairs through the transportId
                items.some(function (i) {
                    if (i.type === 'ssrc') {
                        i.stats.transportId = transportId;
                        return true;
                    }
                    return false;
                });
            }
            return items;
        };

        /**
         * Getting stats from Firefox consists of:
         * 1- invoke getStats() for each RTCRtpSender and RTCRtpReceiver
         * 2- if a RTCRtpSender and a RTCRtpReceiver are in the same Transceiver, they are using the
         *    same channel, so share the channel info between the 2 of them (needs more testing)
         * 3- combine all stats into a single array
         */
        return function (pc) {
            var combinedStats = [];
            var rtpTransceivers = pc.getTransceivers();
            var promises = [];
            rtpTransceivers.forEach(function (t) {
                if (t.receiver && t.receiver.track) {
                    // First get the receiver stats
                    promises.push(new Promise(function (resolve) {
                        t.receiver.getStats()
                        .then(function (receiverStats) {
                            var receiverConverted = convertSafariStats(receiverStats, t.receiver.track.kind, t.mid);
                            if (t.sender && t.sender.track) {
                                // Get the sender stats, which we can't determine its transportId,
                                // so get it from the receiver stats and copy it to the sender's
                                t.sender.getStats()
                                .then(function (stats) {
                                    var senderConverted = convertSafariStats(stats, t.sender.track.kind, t.mid);
                                    copyTransportIdFromReceiverStats(senderConverted, receiverConverted);
                                    Array.prototype.push.apply(combinedStats, receiverConverted);
                                    Array.prototype.push.apply(combinedStats, senderConverted);
                                    resolve();
                                })
                                .catch(function (error) {
                                    logger.debug('[WebRTCAdapter]: Error collecting stats for track: ' + t.sender.track.label + ' . Error: ', error);
                                    Array.prototype.push.apply(combinedStats, receiverConverted);
                                    resolve();
                                });
                            } else {
                                Array.prototype.push.apply(combinedStats, receiverConverted);
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            logger.debug('[WebRTCAdapter]: Error collecting stats for track: ' + t.sender.track.label + ' . Error: ', error);
                            resolve();
                        });
                    }));
                } else if (t.sender && t.sender.track) {
                    promises.push(new Promise(function (resolve) {
                        t.sender.getStats()
                        .catch(function (error) {
                            logger.debug('[WebRTCAdapter]: Error collecting stats for track: ' + t.sender.track.label + ' . Error: ', error);
                        })
                        .then(function (stats) {
                            if (stats) {
                                Array.prototype.push.apply(combinedStats, convertSafariStats(stats, t.sender.track.kind, t.mid));
                            }
                            resolve();
                        });
                    }));
                }
            });
            return Promise.all(promises)
                .then(function () {
                    return combinedStats;
                });
        };
    }

    function initForSafari() {
        logger.info('Setting WebRTC APIs for Safari');

        var processSafariGetStats = createSafariStatsFn();

        circuit.WebRTCAdapter = {
            enabled: true,
            browser: 'safari',
            useNewConstraintSyntax: true,
            useReducedAudioConstraints: true,
            MediaStream: MediaStream,
            PeerConnection: function (config, constraints) {
                var pc = new RTCPeerConnection(config, constraints);
                pc.origCreateOffer = pc.createOffer;
                pc.createOffer = function (successCb, errorCb, offerConstraints) {
                    var offerOptions;
                    if (offerConstraints) {
                        offerOptions = convertDeprecatedSdpConstraints(offerConstraints);
                    }
                    pc.origCreateOffer(offerOptions)
                    .then(function (sdp) {
                        successCb && successCb(sdp);
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origCreateAnswer = pc.createAnswer;
                pc.createAnswer = function (successCb, errorCb, options) {
                    var answerOptions = convertDeprecatedSdpConstraints(options);
                    pc.origCreateAnswer(answerOptions)
                    .then(function (sdp) {
                        successCb && successCb(sdp);
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origSetLocalDescription = pc.setLocalDescription;
                pc.setLocalDescription = function (rtcSdp, successCb, errorCb) {
                    pc.origSetLocalDescription(rtcSdp)
                    .then(function () {
                        successCb && successCb();
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origSetRemoteDescription = pc.setRemoteDescription;
                pc.setRemoteDescription = function (rtcSdp, successCb, errorCb) {
                    pc.origSetRemoteDescription(rtcSdp)
                    .then(function () {
                        successCb && successCb();
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origGetStats = pc.getStats;
                pc.getStats = function () {
                    return processSafariGetStats(pc);
                };

                return pc;
            },
            SessionDescription: RTCSessionDescription,
            IceCandidate: RTCIceCandidate,
            getUserMedia: function (constraints, successCallback, errorCallback) {
                timedGetUserMedia(navigator.mediaDevices.getUserMedia, navigator.mediaDevices, constraints, successCallback, errorCallback);
            },
            createObjectURL: URL.createObjectURL.bind(URL),
            getAudioStreamTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? stream.id + ' ' + audioTrack.id : '';
            },
            getVideoStreamTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? stream.id + ' ' + videoTrack.id : '';
            },
            getAudioTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.id : '';
            },
            getVideoTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.id : '';
            },
            getStreamId: function (stream) {
                return stream && stream.id;
            },
            getTrackId: function (track) {
                return track && track.id;
            },
            hasDeviceNameOnTrackLabel: true,
            getAudioTrackLabel: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.label : '';
            },
            getVideoTrackLabel: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.label : '';
            },
            stopMediaStream: stopMediaStream,
            stopLocalVideoTrack: stopLocalVideoTrack,
            closePc: closePc,
            getMediaSources: function (cb) {
                cb && splitDeviceInfos(cb);
            },
            clearMediaSourcesCache: function () {
                clearDeviceInfoCache();
            },
            getAudioOptions: function (config) {
                var audio = getReducedDefaultAudioOptions(config, true);
                if (config && config.sourceId) {
                    // Start out deviceId as mandatory, it will be moved to optional if it fails
                    audio.deviceId = {exact: config.sourceId};
                }
                return audio;
            },
            getVideoOptions: getDefaultVideoOptions,
            getDesktopOptions: function (streamId, screenConstraint) {
                screenConstraint = screenConstraint || {};
                var fr = screenConstraint.frameRate || {};
                return {
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: streamId,
                        maxWidth: DESKTOP_MAX_WIDTH,
                        maxHeight: DESKTOP_MAX_HEIGHT
                    },
                    optional: [
                        {googNoiseReduction: true},
                        {maxFrameRate: fr.max || DESKTOP_DEFAULT_MAX_FRAMERATE},
                        {minFrameRate: fr.min || DESKTOP_DEFAULT_MIN_FRAMERATE}
                    ]
                };
            },
            setEncodingParameters: function (rtpTransceiver, maxBitrate, degradationPreference) {
                var parameter = rtpTransceiver.sender.getParameters();
                setEncodingParameters(rtpTransceiver, parameter, maxBitrate, degradationPreference);
            },
            audioOutputSelectionSupported: supportsAudioOutputSelection(),
            groupPeerConnectionsSupported: true,
            getMediaSourcesSupported: true,
            attachSinkIdToAudioElement: attachSinkIdToAudioElement,
            toggleAudio: toggleAudio,
            getNetmaskMap: function () { return {}; }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    // iOS
    /////////////////////////////////////////////////////////////////////////////////
    function initForIOS() {
        logger.info('Setting WebRTC APIs for iOS client');

        var RTCStatsReport = function (iosReport) {
            this.id = iosReport.id;
            this.type = iosReport.type;
            this.timestamp = new Date(iosReport.timestamp);

            var _stats = {};
            Object.getOwnPropertyNames(iosReport.values).forEach(function (name) {
                _stats[name] = iosReport.values[name];
            });

            this.names = function () {
                return Object.getOwnPropertyNames(_stats);
            };

            this.stat = function (name) {
                return _stats[name];
            };

            iosReport = null;
        };

        var RTCStatsResponse = function (iosReports) {
            var _reports = iosReports.map(function (iosReport) {
                return new RTCStatsReport(iosReport);
            });

            this.result = function () {
                return _reports;
            };
        };

        circuit.WebRTCAdapter = {
            enabled: true,
            browser: 'ios',
            MediaStream: function (tracks) {
                return navigator.createMediaStreamWithTracks(tracks);
            },
            PeerConnection: function (configuration, constraints) {
                separateIceServers(configuration);
                var pc = RTCPeerConnection.createRTCPeerConnection(configuration, constraints);
                pc.origGetStats = pc.getStats;
                pc.getStats = function () {
                    return new Promise(function (resolve) {
                        pc.origGetStats(function (iosStats) {
                            // Convert stats to the same format as the webclient
                            resolve(new RTCStatsResponse(iosStats));
                        });
                    });
                };

                // Renaming the folowing methods / attributes due to WebRTC ObJ-C interface name conflicts.
                pc.addIceCandidate = pc.addIceCandidateJS;

                Object.defineProperties(pc, {
                    localDescription: {
                        get: function () {
                            return pc.getLocalDescriptionJS();
                        },
                        enumerable: true,
                        configurable: false
                    },
                    remoteDescription: {
                        get: function () {
                            return pc.getRemoteDescriptionJS();
                        },
                        enumerable: true,
                        configurable: false
                    },
                    signalingState: {
                        get: function () {
                            return pc.getSignalingStateJS();
                        },
                        enumerable: true,
                        configurable: false
                    },
                    iceConnectionState: {
                        get: function () {
                            return pc.getIceConnectionStateJS();
                        },
                        enumerable: true,
                        configurable: false
                    },
                    iceGatheringState: {
                        get: function () {
                            return pc.getIceGatheringStateJS();
                        },
                        enumerable: true,
                        configurable: false
                    }
                });

                return pc;
            },
            SessionDescription: function (descriptionInitDict) {
                return RTCSessionDescription.createRTCSessionDescription(descriptionInitDict);
            },
            IceCandidate: function (candidateInitDict) {
                return {
                    sdpMLineIndex: candidateInitDict.sdpMLineIndex,
                    sdpMid: candidateInitDict.sdpMid,
                    candidate: candidateInitDict.candidate
                };
            },
            getUserMedia: navigator.getUserMedia.bind(navigator),
            createObjectURL: URL.createObjectURL.bind(URL),
            getAudioStreamTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? stream.streamId + ' ' + audioTrack.trackId : '';
            },
            getVideoStreamTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? stream.streamId + ' ' + videoTrack.trackId : '';
            },
            getAudioTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.trackId : '';
            },
            getVideoTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.trackId : '';
            },
            getStreamId: function (stream) {
                return stream && stream.streamId;
            },
            getTrackId: function (track) {
                return track && track.trackId;
            },
            hasDeviceNameOnTrackLabel: false,
            getAudioTrackLabel: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.label : '';
            },
            getVideoTrackLabel: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.label : '';
            },
            stopMediaStream: stopMediaStream,
            stopLocalVideoTrack: stopLocalVideoTrack,
            closePc: closePc,
            getMediaSources: function (cb) {
                cb && cb([], [{ id: '1', label: 'front' }, { id: '2', label: 'back' }]);
            },
            clearMediaSourcesCache: function () {},
            getAudioOptions: getDefaultAudioOptions,
            getVideoOptions: getDefaultVideoOptions,
            getDesktopOptions: function () {
                return {
                    mandatory: {
                        iOSMediaSource: 'desktop'
                    }
                };
            },
            setEncodingParameters: function (rtpTransceiver, maxBitrate) {
                if (!rtpTransceiver || !rtpTransceiver.sender || !maxBitrate) {
                    return;
                }
                rtpTransceiver.sender.maxBitrate = maxBitrate;
                logger.debug('[WebRTCAdapter]: Applied maxBitrate {' + maxBitrate + '} to RtpSender mid=' + rtpTransceiver.mid);
            },
            audioOutputSelectionSupported: false,
            groupPeerConnectionsSupported: false,
            getMediaSourcesSupported: false,
            attachSinkIdToAudioElement: attachSinkIdToAudioElement,
            toggleAudio: toggleAudio,
            getNetmaskMap: function () { return {}; }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    // Android
    /////////////////////////////////////////////////////////////////////////////////
    function initForAndroid() {
        logger.info('Setting WebRTC APIs for Android client');

        var _helperPc;

        var AndroidRTCStatsReport = function (androidReport) {
            this.id = androidReport.id;
            this.type = androidReport.type;
            this.timestamp = new Date(androidReport.timestamp);

            var _stats = {};
            Object.getOwnPropertyNames(androidReport.values).forEach(function (name) {
                var statValue = androidReport.values[name];
                if (statValue.name) {
                    _stats[statValue.name] = statValue.value;
                }
            });

            this.names = function () {
                return Object.getOwnPropertyNames(_stats);
            };

            this.stat = function (name) {
                return _stats[name];
            };

            androidReport = null;
        };

        var AndroidRTCStatsResponse = function (androidReports) {
            var _reports = androidReports.map(function (androidReport) {
                return new AndroidRTCStatsReport(androidReport);
            });

            this.result = function () {
                return _reports;
            };
        };

        circuit.WebRTCAdapter = {
            enabled: true,
            browser: 'android',
            MediaStream: function (tracks) {
                if (!_helperPc) {
                    var config = {sdpSemantics: 'unified-plan'};
                    _helperPc = RTCPeerConnectionUnified(config);
                }
                var stream = _helperPc.createEmptyStream();
                Array.isArray(tracks) ? stream.addTracks(tracks) : stream.addTrack(tracks);
                return stream;
            },
            PeerConnection: function (configuration, constraints) {
                separateIceServers(configuration);

                var pc = RTCPeerConnectionUnified(configuration, constraints);
                pc.getStats = function () {
                    return new Promise(function (resolve) {
                        pc.origGetStats(function (androidStats) {
                            resolve(new AndroidRTCStatsResponse(androidStats.data));
                        });
                    });
                };

                return pc;
            },
            SessionDescription: function (descriptionInitDict) {
                return {
                    type: descriptionInitDict.type,
                    sdp: descriptionInitDict.sdp
                };
            },
            IceCandidate: function (candidateInitDict) {
                return {
                    sdpMLineIndex: candidateInitDict.sdpMLineIndex,
                    sdpMid: candidateInitDict.sdpMid,
                    candidate: candidateInitDict.candidate
                };
            },
            getUserMedia: navigator.getUserMedia.bind(navigator),
            createObjectURL: function () { return 'dummy'; },
            getAudioStreamTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? stream.id + ' ' + audioTrack.id : '';
            },
            getVideoStreamTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? stream.id + ' ' + videoTrack.id : '';
            },
            getAudioTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.id : '';
            },
            getVideoTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.id : '';
            },
            getStreamId: function (stream) {
                return stream && stream.id;
            },
            getTrackId: function (track) {
                return track && track.id;
            },
            hasDeviceNameOnTrackLabel: false,
            getAudioTrackLabel: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.label : '';
            },
            getVideoTrackLabel: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.label : '';
            },
            stopMediaStream: stopMediaStream,
            stopLocalVideoTrack: stopLocalVideoTrack,
            closePc: closePc,
            getMediaSources: function (cb) {
                cb && cb([], []);
            },
            clearMediaSourcesCache: function () {},
            getAudioOptions: getDefaultAudioOptions,
            getVideoOptions: getDefaultVideoOptions,
            getDesktopOptions: function () {
                return {
                    mandatory: {
                        androidMediaSource: 'screen'
                    }
                };
            },
            setEncodingParameters: function () {},
            audioOutputSelectionSupported: false,
            groupPeerConnectionsSupported: false,
            getMediaSourcesSupported: false,
            attachSinkIdToAudioElement: attachSinkIdToAudioElement,
            toggleAudio: toggleAudio,
            getNetmaskMap: function () { return {}; }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    // iOS Cordova (cordova-plugin-iosrtc). Standards compliant
    /////////////////////////////////////////////////////////////////////////////////
    function initForCordova() {
        logger.info('Setting WebRTC APIs for Cordova iOS');

        cordova.plugins.iosrtc.RTCSessionDescription.prototype.toJSON = function () {
            return {
                type: this.type,
                sdp: this.sdp
            };
        };

        cordova.plugins.iosrtc.RTCIceCandidate.prototype.toJSON = function () {
            return {
                sdpMLineIndex: this.sdpMLineIndex,
                sdpMid: this.sdpMid,
                candidate: this.candidate
            };
        };

        circuit.WebRTCAdapter = {
            enabled: true,
            browser: 'cordovaios',
            useNewConstraintSyntax: true,
            MediaStream: cordova.plugins.iosrtc.MediaStream,
            PeerConnection: function (config, constraints) {
                var pc = new cordova.plugins.iosrtc.RTCPeerConnection(config, constraints);
                pc.origCreateOffer = pc.createOffer;
                pc.createOffer = function (successCb, errorCb, offerConstraints) {
                    var offerOptions = convertDeprecatedSdpConstraints(offerConstraints);
                    pc.origCreateOffer(offerOptions)
                    .then(function (sdp) {
                        successCb && successCb(sdp);
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origCreateAnswer = pc.createAnswer;
                pc.createAnswer = function (successCb, errorCb, options) {
                    var answerOptions = convertDeprecatedSdpConstraints(options);
                    pc.origCreateAnswer(answerOptions)
                    .then(function (sdp) {
                        successCb && successCb(sdp);
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origSetLocalDescription = pc.setLocalDescription;
                pc.setLocalDescription = function (rtcSdp, successCb, errorCb) {
                    pc.origSetLocalDescription(rtcSdp)
                    .then(function () {
                        successCb && successCb();
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };
                pc.origSetRemoteDescription = pc.setRemoteDescription;
                pc.setRemoteDescription = function (rtcSdp, successCb, errorCb) {
                    pc.origSetRemoteDescription(rtcSdp)
                    .then(function () {
                        successCb && successCb();
                    })
                    .catch(function (err) {
                        errorCb && errorCb(err);
                    });
                };

                return pc;
            },
            SessionDescription: cordova.plugins.iosrtc.RTCSessionDescription,
            IceCandidate: cordova.plugins.iosrtc.RTCIceCandidate,
            getUserMedia: function (constraints, successCallback, errorCallback) {
                cordova.plugins.iosrtc.getUserMedia(constraints)
                .then(successCallback)
                .catch(errorCallback);
            },
            createObjectURL: URL.createObjectURL.bind(URL),
            getAudioStreamTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? stream.id + ' ' + audioTrack.id : '';
            },
            getVideoStreamTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? stream.id + ' ' + videoTrack.id : '';
            },
            getAudioTrackId: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.id : '';
            },
            getVideoTrackId: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.id : '';
            },
            getStreamId: function (stream) {
                return stream && stream.id;
            },
            getTrackId: function (track) {
                return track && track.id;
            },
            hasDeviceNameOnTrackLabel: true,
            getAudioTrackLabel: function (stream) {
                var audioTrack = stream && stream.getAudioTracks()[0];
                return audioTrack ? audioTrack.label : '';
            },
            getVideoTrackLabel: function (stream) {
                var videoTrack = stream && stream.getVideoTracks()[0];
                return videoTrack ? videoTrack.label : '';
            },
            stopMediaStream: stopMediaStream,
            stopLocalVideoTrack: stopLocalVideoTrack,
            closePc: closePc,
            getMediaSources: function (cb) {
                cb && splitDeviceInfos(cb);
            },
            clearMediaSourcesCache: function () {
                clearDeviceInfoCache();
            },
            getAudioOptions: function (config) {
                var audio = getDefaultAudioOptions(config);
                if (config && config.sourceId) {
                    // Cordova doesn't support exact (mandatory) deviceId
                    audio.deviceId = config.sourceId;
                }
                return audio;
            },
            getVideoOptions: function (config) {
                var video = getDefaultVideoOptions(config);
                if (config && config.sourceId) {
                    // Cordova doesn't support exact (mandatory) deviceId
                    video.deviceId = config.sourceId;
                }
                return video;
            },
            getDesktopOptions: function (streamId, screenConstraint) {
                screenConstraint = screenConstraint || {};
                var fr = screenConstraint.frameRate || {};
                return {
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: streamId,
                        maxWidth: DESKTOP_MAX_WIDTH,
                        maxHeight: DESKTOP_MAX_HEIGHT
                    },
                    optional: [
                        {googNoiseReduction: true},
                        {maxFrameRate: fr.max || DESKTOP_DEFAULT_MAX_FRAMERATE},
                        {minFrameRate: fr.min || DESKTOP_DEFAULT_MIN_FRAMERATE}
                    ]
                };
            },
            setEncodingParameters: function () {},
            audioOutputSelectionSupported: supportsAudioOutputSelection(),
            groupPeerConnectionsSupported: false,
            getMediaSourcesSupported: true,
            attachSinkIdToAudioElement: attachSinkIdToAudioElement,
            toggleAudio: toggleAudio,
            getNetmaskMap: function () { return {}; }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    // Initialization
    /////////////////////////////////////////////////////////////////////////////////
    function initWebRTCAdapter() {
        var browser = circuit.Utils.getBrowserInfo();
        try {
            if (browser.chrome) {
                initForChrome();
            } else if (browser.firefox) {
                initForFirefox();
            } else if (browser.safari) {
                initForSafari();
            } else if (browser.ios) {
                initForIOS();
            } else if (browser.android) {
                initForAndroid();
            } else if (browser.cordovaios) {
                initForCordova();
            } else {
                logger.info('WebRTC is not supported');
            }
        } catch (e) {
            logger.error('Failed to initialize WebRTCAdapter. ', e);
        }

        if (!circuit.WebRTCAdapter) {
            // Initialize with dummy object
            circuit.WebRTCAdapter = {
                enabled: false,
                browser: '',
                MediaStream: function () {},
                PeerConnection: function () {},
                SessionDescription: function () {},
                IceCandidate: function () {},
                getUserMedia: function (constraints, successCallback, errorCallback) {
                    errorCallback && errorCallback('Not supported');
                },
                createObjectURL: function () { return ''; },
                getAudioStreamTrackId: function () { return ''; },
                getVideoStreamTrackId: function () { return ''; },
                getAudioTrackId: function () { return ''; },
                getVideoTrackId: function () { return ''; },
                getStreamId: function () { return ''; },
                getTrackId: function () { return ''; },
                hasDeviceNameOnTrackLabel: false,
                getAudioTrackLabel: function () { return ''; },
                getVideoTrackLabel: function () { return ''; },
                stopMediaStream: function () {},
                stopLocalVideoTrack: function () {},
                closePc: function () {},
                getMediaSources: function (cb) {
                    cb && cb([], []);
                },
                clearMediaSourcesCache: function () {},
                getAudioOptions: function () { return false; },
                getVideoOptions: function () { return false; },
                getDesktopOptions: function () { return false; },
                setEncodingParameters: function () {},
                audioOutputSelectionSupported: false,
                groupPeerConnectionsSupported: false,
                getMediaSourcesSupported: false,
                attachSinkIdToAudioElement: function (audioElement, sinkId, cb) {
                    cb && cb();
                },
                toggleAudio: function () { return false; },
                getNetmaskMap: function () { return {}; },
                init: initWebRTCAdapter
            };
        }
    }

    initWebRTCAdapter();

    circuit.Enums = circuit.Enums || {};
    circuit.Enums.VideoResolutionLevel = VideoResolutionLevel;
    circuit.Enums.RtpQualityLevel = RtpQualityLevel;

    circuit.RtpStatsConfig = RtpStatsConfig;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
