var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var Utils = circuit.Utils;

    /*
    * Localization Service
    *
    * localize (value, params) : localizes a given value combined with parameters if any
    *       value : the key of the resource to be localized (must start with 'res_')
    *       params : array of string parameters to be appended on localized message.
    *                Order of parameters inside the array matters as the first will replace %%1 in the
    *                resource value, the second will replace %%2 etc.
    *  Example:
    *   - If we have a resource "res_keyToLocalize" : "This is the localization service" inside the resources file
    *  then a call to localize function would be
    *       localize('res_keyToLocalize')
    *  This would return "This is the localization service".
    *
    *  - If we have a resource "_keyToLocalizeWithParameters" : "Search returned %%1 results in %%2 seconds"
    *  then a call to localize function would be
    *       localize('res_keyToLocalizeWithParameters', [250,5])
    *  This would return "Search returned 250 results in 5 seconds".
    *
    *  Same result will appear if we use the '%##%' separator and call
    *       localize('res_keyToLocalizeWithParameters%##%250%##%5')
    *  This would return "Search returned 250 results in 5 seconds" again and is
    *  useful when we want to localize a msg that is returned in one string.
    *
    *  We can use both ('%##%' and params) if we want.
    *  The parameters following '%##%' will be appended to the params array
    *  e.g. "_keyToLocalizeWithParameters" : "Search returned %%1 results in %%2 minutes and %%3 seconds"
    *       localize('res_keyToLocalizeWithParameters%##%15', [250, 1])
    *  would return "Search returned 250 results in 1 minute 15 seconds"
    *
    *  If no localization key is found, the same key is returned
    *  If key given is invalid (null/undefined/not starting with 'res_') empty string is returned
    *
    */

    // eslint-disable-next-line max-params, max-lines-per-function
    function LocalizeSvcImpl($rootScope, $window, $locale, $q, LogSvc, UtilSvc, PubSubSvc, HttpSvc) { // NOSONAR
        LogSvc.debug('New Service: LocalizeSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_LANGUAGE = 'en-US';
        var RESOURCE_VAR = '%%';
        var RESOURCE_PARAMS_VAR = '%##%';
        var FAQ_PARAM_REGEX = /%FAQ_([^%]+)%/g;

        var RELEASE_NOTES_URL_PREFIX = 'resources/releaseNotes/releaseNotes_';

        var _that = this;
        var _language = DEFAULT_LANGUAGE;   // The active language
        var _default = {};                  // The default resources
        var _resourcesInitialized = false;
        var _browserSettingsLocale;         // Locale that is configured in browser settings, need to query Access Server to retrieve it

        var _languageList = [
            {
                value: 'en-US',
                shortValue: 'en',
                name: 'English',
                nativeName: 'English',
                resourceName: 'res_LangEnglish',
                locale: Constants.Locale.EN_US
            },
            {
                value: 'de-DE',
                shortValue: 'de',
                name: 'German',
                nativeName: 'Deutsch',
                resourceName: 'res_LangGerman',
                locale: Constants.Locale.DE_DE
            },
            {
                value: 'fr-FR',
                shortValue: 'fr',
                name: 'French',
                nativeName: 'Français',
                resourceName: 'res_LangFrench',
                locale: Constants.Locale.FR_FR
            },
            {
                value: 'es-ES',
                shortValue: 'es',
                name: 'Spanish',
                nativeName: 'Español',
                resourceName: 'res_LangSpanish',
                locale: Constants.Locale.ES_ES
            },
            {
                value: 'ca-ES',
                shortValue: 'ca',
                name: 'Catalan',
                nativeName: 'Català',
                resourceName: 'res_LangCatalan',
                locale: Constants.Locale.CA_ES
            },
            {
                value: 'it-IT',
                shortValue: 'it',
                name: 'Italian',
                nativeName: 'Italiano',
                resourceName: 'res_LangItalian',
                locale: Constants.Locale.IT_IT
            },
            {
                value: 'nl-NL',
                shortValue: 'nl',
                name: 'Dutch',
                nativeName: 'Nederlands',
                resourceName: 'res_LangDutch',
                locale: Constants.Locale.NL_NL
            }
        ];

        // Languages which are controlled via Circuit Labs must start as disabled
        var _disabledLanguages = [];

        var _languages = {};
        var _languagesLocales = {};
        var _languageCodes = [];
        _languageList.forEach(function (lang) {
            _languages[lang.value] = lang;
            _languagesLocales[lang.locale] = lang;
            _languageCodes.push(lang.value);
        });
        _disabledLanguages.forEach(function (lang) {
            _languages[lang.value] = lang;
            _languagesLocales[lang.locale] = lang;
            _languageCodes.push(lang.value);
        });

        var _additionalSpokenLanguages = [{
            value: 'en-GB',
            shortValue: 'en',
            name: 'English (UK)',
            nativeName: 'English',
            resourceName: 'res_LangEnglishUK',
            locale: Constants.BridgeLocale.EN_GB
        }];

        var _showResourceStrings = false;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function successCallback(data) {
            // store the returned array in the rootScope
            _resourcesInitialized = true;
            $rootScope.i18n.map = Utils.shallowCopy(_default);
            Object.assign($rootScope.i18n.map, data);
            prepareResources();

            LogSvc.debug('[LocalizeSvc]: Locale Loaded: ', _language);

            // Raise an event to allow other services to manipulate the resource map if needed
            LogSvc.debug('[LocalizeSvc]: Publish /i18n/resources/loaded event');
            PubSubSvc.publish('/i18n/resources/loaded');

            // Notify all interested parties that the language has been updated and the new resources are available
            raiseLanguageUpdate();
        }

        function initLocalizedResources() {
            if (_showResourceStrings) {
                // No need to load the actual text since we are not using it
                return;
            }

            if (_language === DEFAULT_LANGUAGE) {
                successCallback();
                return;
            }
            HttpSvc.retrieveLanguageMap(_language, successCallback, function () {
                // The request failed. Use the default resources.
                LogSvc.error('[LocalizeSvc]: Failed to load resources for ' + _language);
                _resourcesInitialized = true;
                $rootScope.i18n.map = _default;
                prepareResources();

                // Raise an event to allow other services to manipulate the resource map if needed
                LogSvc.debug('[LocalizeSvc]: Publish /i18n/resources/loaded event');
                PubSubSvc.publish('/i18n/resources/loaded');

                raiseLanguageUpdate();
            });
        }

        function setLanguageAndCountryNames() {
            _languageList.forEach(function (lang) {
                lang.name = $rootScope.i18n.map[lang.resourceName] || '';
            });
            _additionalSpokenLanguages.forEach(function (lang) {
                lang.name = $rootScope.i18n.map[lang.resourceName] || '';
            });
            if (Utils.CountriesArray) {
                // Set the country names
                Utils.CountriesArray.forEach(function (c) {
                    c.displayName = $rootScope.i18n.map[c.name] || '';
                });
                // Now order the list alphabetically
                Utils.CountriesArray.sort(function (a, b) {
                    return a.displayName.localeCompare(b.displayName);
                });
            }
        }

        function normalizeLanguageName(language) {
            if (!language) { return ''; }

            var fullMatch, partialMatch;
            _languageCodes.some(function (supportedLang) {
                // First look for exact match
                if (language.toLowerCase() === supportedLang.toLowerCase()) {
                    fullMatch = supportedLang;
                    return true;
                }

                // Ignore the country and check if the language matches any supported language
                if (!partialMatch && (language.slice(0, 2) === supportedLang.slice(0, 2))) {
                    partialMatch = supportedLang;
                }
                return false;
            });
            return fullMatch || partialMatch || language;
        }

        function setDateFormats() {
            if (!$locale) {
                return;
            }
            $locale.DATETIME_FORMATS.DAY = [
                $rootScope.i18n.map.res_DateTimeWeekDaySunday,
                $rootScope.i18n.map.res_DateTimeWeekDayMonday,
                $rootScope.i18n.map.res_DateTimeWeekDayTuesday,
                $rootScope.i18n.map.res_DateTimeWeekDayWednesday,
                $rootScope.i18n.map.res_DateTimeWeekDayThursday,
                $rootScope.i18n.map.res_DateTimeWeekDayFriday,
                $rootScope.i18n.map.res_DateTimeWeekDaySaturday
            ];
            $locale.DATETIME_FORMATS.SHORTDAY = [
                $rootScope.i18n.map.res_DateTimeWeekDaySundayShort,
                $rootScope.i18n.map.res_DateTimeWeekDayMondayShort,
                $rootScope.i18n.map.res_DateTimeWeekDayTuesdayShort,
                $rootScope.i18n.map.res_DateTimeWeekDayWednesdayShort,
                $rootScope.i18n.map.res_DateTimeWeekDayThursdayShort,
                $rootScope.i18n.map.res_DateTimeWeekDayFridayShort,
                $rootScope.i18n.map.res_DateTimeWeekDaySaturdayShort
            ];
            $locale.DATETIME_FORMATS.AMPMS = [
                $rootScope.i18n.map.res_DateTimeAMPM_AM,
                $rootScope.i18n.map.res_DateTimeAMPM_PM
            ];
            $locale.DATETIME_FORMATS.MONTH = [
                $rootScope.i18n.map.res_DateTimeMonthJanuary,
                $rootScope.i18n.map.res_DateTimeMonthFebruary,
                $rootScope.i18n.map.res_DateTimeMonthMarch,
                $rootScope.i18n.map.res_DateTimeMonthApril,
                $rootScope.i18n.map.res_DateTimeMonthMay,
                $rootScope.i18n.map.res_DateTimeMonthJune,
                $rootScope.i18n.map.res_DateTimeMonthJuly,
                $rootScope.i18n.map.res_DateTimeMonthAugust,
                $rootScope.i18n.map.res_DateTimeMonthSeptember,
                $rootScope.i18n.map.res_DateTimeMonthOctober,
                $rootScope.i18n.map.res_DateTimeMonthNovember,
                $rootScope.i18n.map.res_DateTimeMonthDecember
            ];
            $locale.DATETIME_FORMATS.SHORTMONTH = [
                $rootScope.i18n.map.res_DateTimeMonthJanuaryShort,
                $rootScope.i18n.map.res_DateTimeMonthFebruaryShort,
                $rootScope.i18n.map.res_DateTimeMonthMarchShort,
                $rootScope.i18n.map.res_DateTimeMonthAprilShort,
                $rootScope.i18n.map.res_DateTimeMonthMayShort,
                $rootScope.i18n.map.res_DateTimeMonthJuneShort,
                $rootScope.i18n.map.res_DateTimeMonthJulyShort,
                $rootScope.i18n.map.res_DateTimeMonthAugustShort,
                $rootScope.i18n.map.res_DateTimeMonthSeptemberShort,
                $rootScope.i18n.map.res_DateTimeMonthOctoberShort,
                $rootScope.i18n.map.res_DateTimeMonthNovemberShort,
                $rootScope.i18n.map.res_DateTimeMonthDecemberShort
            ];
        }

        function prepareResources() {
            var productName = circuit.productName || 'Unify Phone';

            Object.keys($rootScope.i18n.map).forEach(function (key) {
                // Update product name
                $rootScope.i18n.map[key] = $rootScope.i18n.map[key].replace(/%PRODUCT_NAME%/g, productName);
            });

            setLanguageAndCountryNames();
            setDateFormats();
        }

        function raiseLanguageUpdate() {
            LogSvc.debug('[LocalizeSvc]: Publish /language/update event');
            PubSubSvc.publish('/language/update', [_language]);
        }

        function initLanguage() {
            if (!_browserSettingsLocale) {
                HttpSvc.getBrowserLocale()
                .then(function (locale) {
                    _browserSettingsLocale = locale || $window.navigator.language || DEFAULT_LANGUAGE;
                    LogSvc.debug('[LocalizeSvc]: Set browser language to ', _browserSettingsLocale);

                    _that.setLanguage();
                    PubSubSvc.publish('/language/initComplete', [_browserSettingsLocale]);
                })
                .catch(function () {
                    LogSvc.error('[LocalizeSvc]: Unable to set browser language to ', _browserSettingsLocale);
                });
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.normalizeLanguageName = normalizeLanguageName;

        this.getLanguage = function () {
            return _language;
        };

        this.getLanguageOnly = function () {
            return _language && _language.substr(0, 2);
        };

        this.getLocale = function () {
            return _languages[_language].locale;
        };

        this.getLanguageName = function () {
            return _languages[_language].name;
        };

        this.getLanguageObject = function () {
            return _languages[_language];
        };

        this.getLanguageList = function () {
            return _languageList.slice();
        };

        this.getLanguagebyValue = function (value) {
            return _languages[value];
        };

        this.getBridgeLanguageByValue = function (value) {
            return _languages[value] || _additionalSpokenLanguages.find(function (lang) {
                return lang.value === value;
            });
        };

        this.getLanguagebyLocale = function (locale) {
            if (locale === Constants.BridgeLocale.EN_GB) {
                locale = Constants.Locale.EN_US;
            }
            return _languagesLocales[locale];
        };

        this.getBridgeLanguagebyLocale = function (locale) {
            return _languagesLocales[locale] || _additionalSpokenLanguages.find(function (lang) {
                return lang.locale === locale;
            });
        };

        /**
         * Sets language after localizeSvc is initialized with browser's locale
         */
        this.setLanguageAsync = function (value) {
            var deferred = $q.defer();
            if (_browserSettingsLocale) {
                deferred.resolve(_that.setLanguage(value));
            } else {
                PubSubSvc.subscribeOnce('/language/initComplete', function () {
                    deferred.resolve(_that.setLanguage(value));
                });
            }
            return deferred.promise;
        };

        this.setLanguage = function (value) {
            if (!value) {
                // Use browser's language
                value = normalizeLanguageName(_browserSettingsLocale);
                if (!_languages[value]) {
                    // Browser language is not supported. Fallback to default language.
                    value = DEFAULT_LANGUAGE;
                }
            } else {
                value = normalizeLanguageName(value);
                var langObj = _languages[value];
                if (!langObj) {
                    LogSvc.info('[LocalizeSvc]: Language is not supported: ', value);
                    return _language;
                }
                if (_disabledLanguages.includes(langObj)) {
                    LogSvc.info('[LocalizeSvc]: Language is disabled: ', value);
                    return _language;
                }
            }

            if (_language === value) {
                // No changes
                if (!_resourcesInitialized) {
                    _resourcesInitialized = true;
                    raiseLanguageUpdate();
                }
                return _language;
            }
            _language = value;
            LogSvc.info('[LocalizeSvc]: Setting language to ', _language);
            initLocalizedResources();
            return _language;
        };

        this.getReleaseNotesFileUrl = function () {
            var suffix = 'default';
            if (_language !== DEFAULT_LANGUAGE) {
                suffix = _language;
            }
            return RELEASE_NOTES_URL_PREFIX + suffix + '.json';
        };

        this.showResourceStrings = function (show) {
            show = !!show;
            if (_showResourceStrings === show) {
                // No changes
                return true;
            }
            if (!_resourcesInitialized) {
                // Cannot change this setting until resources have been initialized
                return false;
            }
            _showResourceStrings = show;
            if (_showResourceStrings) {
                var newMap = {};
                Object.keys($rootScope.i18n.map).forEach(function (key) {
                    if (key.startsWith('res_DateTime')) {
                        newMap[key] = $rootScope.i18n.map[key];
                    } else {
                        newMap[key] = key;
                        var params = $rootScope.i18n.map[key].match(/%%\d/g);
                        if (params && params.length) {
                            newMap[key] += ' [' + params.join(', ') + ']';
                        }
                    }
                });
                $rootScope.i18n.map = newMap;
                raiseLanguageUpdate();
            } else {
                initLocalizedResources();
            }
            return true;
        };

        this.disableLanguage = function (value) {
            if (value === DEFAULT_LANGUAGE) {
                LogSvc.warn('[LocalizeSvc]: Cannot disable default language');
                return false;
            }
            if (!_resourcesInitialized) {
                // Cannot disable language until resources have been initialized
                return false;
            }
            var langObj = _languages[value];
            if (!langObj || _disabledLanguages.includes(langObj)) {
                return !!langObj;
            }
            LogSvc.info('[LocalizeSvc]: Disable language ', value);

            _disabledLanguages.push(langObj);

            var idx = _languageList.indexOf(langObj);
            if (idx !== -1) {
                _languageList.splice(idx, 1);
                $rootScope.i18n.languages = _languageList.slice();
                $rootScope.i18n.spokenLanguages = _languageList.concat(_additionalSpokenLanguages);
            }

            if (_language === value) {
                _language = DEFAULT_LANGUAGE;
                LogSvc.info('[LocalizeSvc]: Setting language to ', _language);
                initLocalizedResources();
            }
            return true;
        };

        this.enableLanguage = function (value) {
            var langObj = _languages[value];
            if (!langObj || !_disabledLanguages.includes(langObj)) {
                return !!langObj;
            }
            LogSvc.info('[LocalizeSvc]: Enable language ', value);

            var idx = _disabledLanguages.indexOf(langObj);
            _disabledLanguages.splice(idx, 1);

            _languageList.push(langObj);
            $rootScope.i18n.languages = _languageList.slice();
            $rootScope.i18n.spokenLanguages = _languageList.concat(_additionalSpokenLanguages);
            return true;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialize default resources
        ///////////////////////////////////////////////////////////////////////////////////////
        $rootScope.i18n = {
            languages: _languageList.slice(),
            spokenLanguages: _languageList.concat(_additionalSpokenLanguages),
            map: {},
            localize: function (value, params) {
                if (!value || value.length < 5 || value.substr(0, 4) !== 'res_') {
                    return value;
                }
                if (_resourcesInitialized) {
                    var tokens = value.split(RESOURCE_PARAMS_VAR);   // Add variables using one string
                    value = tokens[0];                               // (used when localized text contains non localized data
                    var str = $rootScope.i18n.map[value];            // e.g when it is appended with an error message returned from an external service )

                    if (!str) {
                        LogSvc.error('[LocalizeSvc]: Could not find localized key ' + value);
                        return '';
                    }

                    // Replace any FAQ links
                    str = str.replace(FAQ_PARAM_REGEX, function (match, articleId) {
                        return UtilSvc.getFaqUrl(articleId);
                    });

                    if (tokens.length === 1 && !params) { // If no parameters given return resource found
                        return str;
                    }
                    params = params || [];

                    if (tokens.length > 1) {
                        params = params.concat(tokens.slice(1));       // Extracted parameters are appended to the existing ones
                    }

                    params.forEach(function (param, index) {
                        // Add variables using params. e.g replace %%1 with the first parameter given
                        str = str.replace(new RegExp(RESOURCE_VAR + (index + 1), 'g'), param);
                    });

                    return str;
                }
                return '';
            }
        };

        $rootScope.localize = $rootScope.i18n.localize;

        // Retrieve the default resources
        HttpSvc.retrieveLanguageMap(null, function (data) {
            _default = data;
            $rootScope.i18n.map = data;
            prepareResources();
            LogSvc.debug('[LocalizeSvc]: Loaded default resources');

            // Raise an event to allow other services to manipulate the resource map if needed
            LogSvc.debug('[LocalizeSvc]: Publish /i18n/resources/loaded event');
            PubSubSvc.publish('/i18n/resources/loaded');

            // Initialize the language, using browser settings
            initLanguage();
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.LocalizeSvcImpl = LocalizeSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
