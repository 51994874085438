/*global require*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var Utils = circuit.Utils;

    ///////////////////////////////////////////////////////////////////////////////////////
    // CircuitLabSvc Implementation
    ///////////////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line max-params, max-lines-per-function
    function CircuitLabSvcImpl($rootScope, $window, LogSvc, PubSubSvc, LocalStoreSvc, LocalizeSvc) { //NOSONAR
        LogSvc.debug('New Service: CircuitLabSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var ALPHA_FEATURES, DEMO_FEATURES, BETA_FEATURES, LAB_FEATURES, PRE_REQUISITES = {}, DEPENDENCIES = {};

        // How to use the different levels:
        // - ALPHA_FEATURES: Features under development which SHOULD NOT be enabled outside of development.
        //   These features can be enabled on the webclient via the hidden debug menu.
        //
        // - DEMO_FEATURES: Demo features. Not meant for regular users.
        //   These features can be enabled on the webclient via the Ctrl+Shift+Alt+D combination.
        //
        // - BETA_FEATURES: Features available for a closed Beta evaluation.
        //   These features can be enabled on the webclient via the Ctrl+Shift+Alt+B combination.
        //
        // - LAB_FEATURES: Features available for evaluation by all users.
        //   These features are visible by default under the Circuit Labs tab.

        if ($window.navigator.platform === 'iOS') {
            // iOS
            ALPHA_FEATURES = [
                Constants.LabFeatureName.PICKUP_GROUP
            ];
            DEMO_FEATURES = [
            ];
            BETA_FEATURES = [
            ];
            LAB_FEATURES = [
            ];

        } else if ($window.navigator.platform === 'Android') {
            // Android
            ALPHA_FEATURES = [
                Constants.LabFeatureName.PICKUP_GROUP
            ];
            DEMO_FEATURES = [
            ];
            BETA_FEATURES = [
            ];
            LAB_FEATURES = [
            ];
        } else if ($window.location.href.includes('/tenant')) {
            // Webclient, DA for tenant app
            ALPHA_FEATURES = [
                Constants.LabFeatureName.TENANT_DATA_EXPORT
            ];
            DEMO_FEATURES = [
            ];
            BETA_FEATURES = [
            ];
            LAB_FEATURES = [
            ];
        } else {
            // Webclient, DA for main app
            ALPHA_FEATURES = [
            ];
            DEMO_FEATURES = [
            ];
            BETA_FEATURES = [
            ];
            LAB_FEATURES = [
            ];
        }

        var ALL_FEATURES = ALPHA_FEATURES.slice(0);
        Array.prototype.push.apply(ALL_FEATURES, BETA_FEATURES);
        Array.prototype.push.apply(ALL_FEATURES, DEMO_FEATURES);
        Array.prototype.push.apply(ALL_FEATURES, LAB_FEATURES);

        // Resources that need to be replaced when a specific lab feature is enabled
        var REPLACEMENT_RESOURCES = {};

        // Specify pre-requisites and dependencies
        // DEPENDENCIES[Constants.LabFeatureName.xxx] = [Constants.LabFeatureName.yyy];
        // PRE_REQUISITES[Constants.LabFeatureName.yyy] = [Constants.LabFeatureName.xxx];

        var _self = this;
        var _circuitLabFeatures = {};
        var _isInitialized = false;
        var _featureList = [];

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function updateRootScope() {
            // Note that we cannot create a new object for $rootScope.circuitLabs since we may
            // have a reference to this object in some directives with isolated scope.
            Object.keys(_circuitLabFeatures).forEach(function (featureName) {
                $rootScope.circuitLabs[featureName] = _circuitLabFeatures[featureName];
            });
        }

        // eslint-disable-next-line complexity
        function isSupportedFeature(featureName) {
            // Make sure all pre-requisites are supported
            var preReq = PRE_REQUISITES[featureName];
            if (preReq && !preReq.every(isSupportedFeature)) {
                return false;
            }

            // Check which lab features are supported based on browser type and logged user
            switch (featureName) {
            case Constants.LabFeatureName.AGENT_STATUS_PER_GROUP:
                // This features is only applicable for OSV users
                return !!($rootScope.localUser && $rootScope.localUser.isOSV);

            case Constants.LabFeatureName.TENANT_ADMIN_USER_TABLE_ENHANCEMENT:
                // These features are only applicable for tenant administrators
                return !!($rootScope.localUser && $rootScope.localUser.isTenantAdmin);

            case Constants.LabFeatureName.CALL_FORWARD_NO_REPLY:
                // Available only for ATC
                return !!($rootScope.localUser && $rootScope.localUser.isATC && !$rootScope.localUser.isOsBizCTIEnabled);

            case Constants.LabFeatureName.TRANSCRIPTION_AND_TRANSLATION:
                // This feature is only available for Chrome and Desktop app.
                return !!$rootScope.browser.chrome;
            }

            // Feature is supported by default
            return true;
        }

        function isReloadRequired(featureName) {
            // Add a case statement for all features which require a client reload
            if (featureName === Constants.LabFeatureName.TRANSCRIPTION_AND_TRANSLATION) {
                return true;
            }

            if (!_circuitLabFeatures[featureName]) {
                // Feature is not enabled. Check if any of the pre-requisites require reload.
                var preReq = PRE_REQUISITES[featureName];
                if (preReq && preReq.some(function (feature) {
                    return !_circuitLabFeatures[feature] && isReloadRequired(feature);
                })) {
                    return true;
                }
            } else {
                // Feature is enabled. Check if any of the dependencies require reload.
                var dep = DEPENDENCIES[featureName];
                if (dep && dep.some(function (feature) {
                    return _circuitLabFeatures[feature] && isReloadRequired(feature);
                })) {
                    return true;
                }
            }

            return false;
        }

        function normalizeObject(features) {
            features = features || {};

            var normalized = {};
            ALL_FEATURES.forEach(function (featureName) {
                if (!$rootScope.enableAlphaFeatures && ALPHA_FEATURES.includes(featureName)) {
                    normalized[featureName] = false;
                } else {
                    normalized[featureName] = !!features[featureName];
                }
            });
            return normalized;
        }

        function reloadPage(message) {
            // Clear indexedDb before reloading the page
            LocalStoreSvc.clearIndexedDb()
            .then(function () {
                LogSvc.info('[CircuitLabSvc]: Cleared indexedDB');
            })
            .catch(function () {
                LogSvc.warn('[CircuitLabSvc]: Failed to clear indexedDB');
            })
            .then(function () {
                LogSvc.debug('[CircuitLabSvc] Publish /application/reload event');
                PubSubSvc.publish('/application/reload', [message, true]);
            });
        }

        function initFeatures() {
            LogSvc.debug('[CircuitLabSvc] Initialized features to ', _circuitLabFeatures);

            if (typeof _circuitLabFeatures.SIMULCAST === 'boolean') {
                LogSvc.info('[CircuitLabSvc]: Set RtcSessionController.addAdditionalLowVideoStream to ', _circuitLabFeatures.SIMULCAST);
                circuit.RtcSessionController.addAdditionalLowVideoStream = _circuitLabFeatures.SIMULCAST;
            }

            LogSvc.debug('[CircuitLabSvc]: Publish /feature/state/initialized event');
            PubSubSvc.publish('/feature/state/initialized');
        }

        function processFeatureChanged(featureName, value) {
            if (featureName === Constants.LabFeatureName.SIMULCAST) {
                LogSvc.info('[CircuitLabSvc]: Set RtcSessionController.addAdditionalLowVideoStream to ', value);
                circuit.RtcSessionController.addAdditionalLowVideoStream = value;
            }

            if (value && !LAB_FEATURES.includes(featureName)) {
                if (ALPHA_FEATURES.includes(featureName)) {
                    if (!$rootScope.showAlphaFeatures) {
                        LogSvc.info('[CircuitLabSvc]: An Alpha feature has been enabled. Set showAlphaFeatures to true.');
                        $rootScope.showAlphaFeatures = true;
                        $rootScope.showBetaFeatures = true;
                    }
                } else if (BETA_FEATURES.includes(featureName)) {
                    if (!$rootScope.showBetaFeatures) {
                        LogSvc.info('[CircuitLabSvc]: A Beta feature has been enabled. Set showBetaFeatures to true.');
                        $rootScope.showBetaFeatures = true;
                    }
                } else if (DEMO_FEATURES.includes(featureName)) {
                    if (!$rootScope.showDemoFeatures) {
                        LogSvc.info('[CircuitLabSvc]: A Demo feature has been enabled. Set showDemoFeatures to true.');
                        $rootScope.showDemoFeatures = true;
                    }
                }
            }

            LogSvc.debug('[CircuitLabSvc] Publish /feature/state/changed for ', featureName);
            PubSubSvc.publish('/feature/state/changed', [featureName, value]);
        }

        function updateFeatures(oldValues) {
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.CIRCUIT_LABS, _circuitLabFeatures);
            LogSvc.debug('[CircuitLabSvc] Set features to ', _circuitLabFeatures);
            updateRootScope();
            var hasChanges = false;
            ALL_FEATURES.forEach(function (featureName) {
                var value = _circuitLabFeatures[featureName];
                if (value !== oldValues[featureName]) {
                    hasChanges = true;
                    processFeatureChanged(featureName, value);
                }
            });

            if (hasChanges) {
                updateAllResources(true);
            }
        }

        function updateFeature(featureName, value) {
            if (value && !isSupportedFeature(featureName)) {
                LogSvc.warn('[CircuitLabSvc] Cannot enable unsupported feature: ', featureName);
                return;
            }
            LogSvc.debug('[CircuitLabSvc] Set ' + featureName + ' feature to ', value);
            _circuitLabFeatures[featureName] = value;

            var reloadPending = isReloadRequired(featureName);

            // Check for pre-requisites or dependencies
            (value ? enablePrerequisites : disableDependencies)(featureName, reloadPending);

            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.CIRCUIT_LABS, _circuitLabFeatures);

            if (reloadPending) {
                LogSvc.info('[SettingsCtrl]: Toggled Circuit Lab feature which requires client reload: ', featureName);
                var message = value ? 'res_LabsFeatureActivatedReload' : 'res_LabsFeatureDeactivatedReload';
                reloadPage(message);
            } else {
                updateRootScope();
                updateResourcesForFeature(featureName, true);
                // Process feature specific changes
                processFeatureChanged(featureName, value);
            }
        }

        function backupOriginalResources() {
            var map = $rootScope.i18n.map;
            Object.keys(REPLACEMENT_RESOURCES).forEach(function (featureName) {
                var resources = REPLACEMENT_RESOURCES[featureName];
                if (resources) {
                    Object.keys(resources).forEach(function (resName) {
                        if (map[resName]) {
                            map['$$' + resName] = map[resName];
                        }
                    });
                }
            });
        }

        function enablePrerequisites(featureName, reloadPending) {
            var preReq = PRE_REQUISITES[featureName];
            preReq && preReq.forEach(function (name) {
                if (!_circuitLabFeatures[name]) {
                    if (reloadPending) {
                        _circuitLabFeatures[name] = true;
                        enablePrerequisites(name, true);
                    } else {
                        updateFeature(name, true);
                    }
                }
            });
        }

        function disableDependencies(featureName, reloadPending) {
            var dep = DEPENDENCIES[featureName];
            dep && dep.forEach(function (name) {
                if (_circuitLabFeatures[name]) {
                    if (reloadPending) {
                        _circuitLabFeatures[name] = false;
                        disableDependencies(name, true);
                    } else {
                        updateFeature(name, false);
                    }
                }
            });
        }

        function updateResourcesForFeature(featureName, raiseLanguageUpdate) {
            var resources = REPLACEMENT_RESOURCES[featureName];
            if (!resources) {
                return false;
            }
            var hasUpdates = false;
            var map = $rootScope.i18n.map;
            Object.keys(resources).forEach(function (resName) {
                if (!map[resName]) {
                    return;
                }
                var replacementResName = _circuitLabFeatures[featureName] ? resources[resName] : '$$' + resName;
                if (map[replacementResName]) {
                    map[resName] = map[replacementResName];
                    hasUpdates = true;
                }
            });
            if (hasUpdates) {
                LogSvc.debug('[CircuitLabSvc]: Update resources associated with feature ', featureName);
                if (raiseLanguageUpdate) {
                    // Publish a fake /language/update event to update
                    LogSvc.debug('[CircuitLabSvc]: Publish /language/update event');
                    PubSubSvc.publish('/language/update', [LocalizeSvc.getLanguage()]);
                }
            }
            return hasUpdates;
        }

        function updateAllResources(raiseLanguageUpdate) {
            var hasUpdates = false;
            Object.keys(REPLACEMENT_RESOURCES).forEach(function (featureName) {
                if (updateResourcesForFeature(featureName, false)) {
                    hasUpdates = true;
                }
            });
            if (hasUpdates && raiseLanguageUpdate) {
                // Publish a fake /language/update event to update
                LogSvc.debug('[CircuitLabSvc]: Publish /language/update event');
                PubSubSvc.publish('/language/update', [LocalizeSvc.getLanguage()]);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribeOnce('/localUser/init', function () {
            LogSvc.debug('[CircuitLabSvc]: Received /localUser/init event');
            // Initialize from local storage if not already initialized.
            if (!_isInitialized) {
                _self.initFromLocalStorage();
            }

            // Now that the local user has been initialized, disable any features which are not supported.
            var hasChanges = false;
            ALL_FEATURES.forEach(function (featureName) {
                if (_circuitLabFeatures[featureName] && !isSupportedFeature(featureName)) {
                    LogSvc.debug('[CircuitLabSvc]: Disable not supported feature: ', featureName);
                    _circuitLabFeatures[featureName] = false;
                    hasChanges = true;
                }
            });

            if (hasChanges) {
                // Update the local storage with updated values
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.CIRCUIT_LABS, _circuitLabFeatures);
                updateRootScope();
                updateAllResources(true);
            }

            initFeatures();
        });


        PubSubSvc.subscribe('/i18n/resources/loaded', function () {
            LogSvc.debug('[CircuitLabSvc]: Received /i18n/resources/loaded event');
            backupOriginalResources();
            updateAllResources(false);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.getFeatureNames = function (includeAlpha) {
            return includeAlpha ? ALL_FEATURES.slice(0) :
                BETA_FEATURES.concat(LAB_FEATURES);
        };

        this.isSupportedFeature = isSupportedFeature;

        this.getSupportedFeatureList = function (ordered) {
            var supportedFeatures = _featureList.filter(function (feature) {
                return isSupportedFeature(feature.featureName);
            });

            if (ordered) {
                // Order by title according to selected language
                var map = $rootScope.i18n.map;
                supportedFeatures.sort(function (a, b) {
                    return map[a.titleRes].localeCompare(map[b.titleRes]);
                });
            }
            return supportedFeatures;
        };

        this.setFeature = function (featureName, value) {
            if (!ALL_FEATURES.includes(featureName)) {
                LogSvc.warn('[CircuitLabSvc] setFeature invoked with unsupported feature name: ', featureName);
                return;
            }
            value = !!value;
            if (_circuitLabFeatures[featureName] !== value) {
                updateFeature(featureName, value);
            }
        };

        this.toggleFeature = function (featureName) {
            if (!ALL_FEATURES.includes(featureName)) {
                LogSvc.warn('[CircuitLabSvc] toggleFeature invoked with unsupported feature name: ', featureName);
                return;
            }
            var value = !_circuitLabFeatures[featureName];
            updateFeature(featureName, value);
        };

        this.isFeatureEnabled = function (featureName) {
            return !!_circuitLabFeatures[featureName];
        };

        this.isAlphaFeature = function (featureName) {
            return ALPHA_FEATURES.includes(featureName);
        };

        this.isDemoFeature = function (featureName) {
            return DEMO_FEATURES.includes(featureName);
        };

        this.isBetaFeature = function (featureName) {
            return BETA_FEATURES.includes(featureName);
        };

        this.isLabFeature = function (featureName) {
            return LAB_FEATURES.includes(featureName);
        };

        this.hasAlphaFeaturesEnabled = function () {
            return ALPHA_FEATURES.some(function (featureName) {
                return _circuitLabFeatures[featureName];
            });
        };

        this.hasDemoFeaturesEnabled = function () {
            return DEMO_FEATURES.some(function (featureName) {
                return _circuitLabFeatures[featureName];
            });
        };

        this.hasBetaFeaturesEnabled = function () {
            return BETA_FEATURES.some(function (featureName) {
                return _circuitLabFeatures[featureName];
            });
        };

        this.hasLabFeaturesEnabled = function () {
            return LAB_FEATURES.some(function (featureName) {
                return _circuitLabFeatures[featureName];
            });
        };

        this.showCircuitLabsFeature = function (featureName) {
            if (_self.isDemoFeature(featureName)) {
                return $rootScope.showDemoFeatures;
            }

            return $rootScope.showAlphaFeatures ||
                _self.isLabFeature(featureName) ||
                ($rootScope.showBetaFeatures && _self.isBetaFeature(featureName));
        };

        this.disableAlphaFeatures = function () {
            LogSvc.debug('[CircuitLabSvc] Disable alpha features');
            var oldValues = Utils.shallowCopy(_circuitLabFeatures);
            var reloadRequired = false;
            ALPHA_FEATURES.forEach(function (featureName) {
                if (_circuitLabFeatures[featureName] && isReloadRequired(featureName)) {
                    LogSvc.info('[SettingsCtrl]: Toggled Circuit Lab feature which requires client reload: ', featureName);
                    reloadRequired = true;
                }
                _circuitLabFeatures[featureName] = false;
            });

            updateFeatures(oldValues);

            if (reloadRequired) {
                reloadPage('res_LabsAlphaDeactivatedReload');
            }
        };

        this.initFromLocalStorage = function () {
            if (_isInitialized) {
                return;
            }

            $rootScope.enableAlphaFeatures = !!LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.ENABLE_ALPHA_TESTING) || !Utils.isProductionDomain();
            LogSvc.info('[CircuitLabSvc] Alpha flags are ', $rootScope.enableAlphaFeatures ? 'enabled' : 'disabled');

            _isInitialized = true;
            _circuitLabFeatures = normalizeObject(LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.CIRCUIT_LABS));
            LogSvc.info('[CircuitLabSvc] Retrieved Circuit Labs features from local store: ', _circuitLabFeatures);

            // Update the local storage with normalized values
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.CIRCUIT_LABS, _circuitLabFeatures);

            // Show alpha features in Circuit Labs if at least one feature is enabled
            $rootScope.showAlphaFeatures = $rootScope.enableAlphaFeatures && this.hasAlphaFeaturesEnabled();
            if ($rootScope.showAlphaFeatures) {
                $rootScope.showBetaFeatures = true;
                LogSvc.warn('[CircuitLabSvc]: There is at least one Alpha feature enabled.');
            } else {
                // Show beta features in Circuit Labs if at least one feature is enabled
                $rootScope.showBetaFeatures = this.hasBetaFeaturesEnabled();
                if ($rootScope.showBetaFeatures) {
                    LogSvc.info('[CircuitLabSvc]: There is at least one Beta feature enabled.');
                }
            }

            $rootScope.showDemoFeatures = this.hasDemoFeaturesEnabled();
            if ($rootScope.showDemoFeatures) {
                LogSvc.info('[CircuitLabSvc]: There is at least one Demo feature enabled.');
            }

            updateRootScope();
            updateAllResources(true);
        };

        this.isReloadRequired = isReloadRequired;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        $rootScope.circuitLabs = $rootScope.circuitLabs || {};
        // Do not initialize _circuitLabFeatures && $rootScope.circuitLabs yet.
        // These variables must only be initialized when initFromLocalStorage is invoked.
        // This is needed because we use bind-once for some features, so we can only set
        // the value for these features once.
        _featureList = ALL_FEATURES.map(function (featureName) {
            var isAlpha, isDemo, isClosedBeta, typeRes;
            if (ALPHA_FEATURES.includes(featureName)) {
                isAlpha = true;
                typeRes = 'res_FeatureAlpha';
            } else if (DEMO_FEATURES.includes(featureName)) {
                isDemo = true;
                typeRes = 'res_FeatureDemo';
            } else if (BETA_FEATURES.includes(featureName)) {
                isClosedBeta = true;
                typeRes = 'res_FeatureBeta';
            }

            return {
                featureName: featureName,
                titleRes: 'res_Labs_' + featureName + '_Title',
                detailsRes: 'res_Labs_' + featureName + '_Details',
                typeRes: typeRes,
                isAlpha: !!isAlpha,
                isDemo: !!isDemo,
                isClosedBeta: !!isClosedBeta
            };
        });

        backupOriginalResources();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.CircuitLabSvcImpl = CircuitLabSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
