/*global RegistrationState, AuthenticationContext, require*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ChromeExtension = circuit.ChromeExtension;
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Utils = circuit.Utils;

    var ExchangeConnError = Object.freeze({
        COULD_NOT_CONNECT: 'couldNotConnect',    // Cannot reach Exchange Server (returned by Connector)
        UNAUTHORIZED: 'unauthorized',            // Could connect to the server, but could not authenticate user (returned by Connector)
        FAILED: 'failed',                        // The requested method failed to be completed (returned by Connector)
        NO_EXTENSION: 'extensionNotRunning',     // Chrome extension is not running
        UNAVAILABLE: 'unavailable',              // Failed to connect to connector
        INTERNAL_ERROR: 'internalError',         // Internal error
        TIMEOUT: 'timeout',                      // Timed out waiting for a response
        EXCEED_MAX_ATTEMPT: 'exceedMaxAttempt',  // Exceed the max attempts to connect
        MAILBOX_NOT_FOUND: 'mailboxNotFound',    // Mailbox not found for this user on the configured Exchange server
        NTLM_NOT_SUPPORTED: 'ntlmNotSupported',  // NTLM authentication is not supported on the configured Exchange server
        UNEXPECTED: 'unexpected',                // Unexpected error returned by connector
        UNSUPPORTED_METHOD: 'unsupportedMethod', // Method not supportde by extension
        NO_RESULT: 'NO_RESULT'                   // Could not retrieve exchange settings
    });

    var ExchangeAuthentication = Object.freeze({
        BASIC: 'BASIC',
        OFFICE365: 'OFFICE365',
        INTEGRATED: 'INTEGRATED'
    });

    var Office365Cloud = Object.freeze({
        GLOBAL: 'GLOBAL',
        GERMANY: 'GERMANY'
    });

    var ExchangeOnlineApp = Object.freeze({
        GLOBAL: {
            Office365Cloud: Office365Cloud.GLOBAL,
            oAuthServer: 'login.microsoftonline.com',
            server: 'outlook.office365.com',
            url: 'https://outlook.office365.com/',
            instance: 'https://login.microsoftonline.com/',
            clientId: '3fbe68ed-8c3e-44e1-834c-d6003e917e72' // The id of the Exchange Connector Authentication app on http://portal.azure.com
        },

        GERMANY: {
            Office365Cloud: Office365Cloud.GERMANY,
            oAuthServer: 'login.microsoftonline.de',
            server: 'outlook.office.de',
            url: 'https://outlook.office.de/',
            instance: 'https://login.microsoftonline.de/',
            clientId: '58b0add5-07f2-43f0-af31-0725409d12ce'
        }
    });

    ///////////////////////////////////////////////////////////////////////////////////////
    // ExchangeConnSvc Implementation
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function ExchangeConnSvcImpl($rootScope, $q, $timeout, $injector, LogSvc, PubSubSvc, ExtensionSvc, RegistrationSvc, LocalStoreSvc) { // NOSONAR

        LogSvc.debug('New Service: ExchangeConnSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_NUM_CONTACTS = 5;
        var DEFAULT_SETTINGS = {
            email: null,
            server: null,
            domain: null,
            username: null,
            useSSL: Utils.isWindowsOs,
            authentication: {}
        };

        var RETRY_DELAY = 1000;
        var RETRY_INTERVAL = 5000;
        var MAX_INTERVAL = 60000;
        var MAX_ATTEMPTS = 10;
        var MAX_SHORT_ATTEMPTS = 3;
        var EXPIRE_OFFSET = 300;
        var COMMON_CHAR_REGEX = /[-()\s]/g;
        var DIGIT_LENGTH_REGEX = /^\+?[\d#*]{3,}\b/g;
        var PREFIX_CHAR_PATTERN = '[\\+\\b]?[\\*#]*';
        var PREFIX_PATTERN = '[\\+\\b]?[\\d\\*#]*';
        var POSTFIX_PATTERN = '[\\d\\*#]*\\b';

        var SYNC_CONTACTS_DELAY = 2 * 60 * 1000;
        var PERSONAL_CONTACTS_SYNC_MIN_INTERVAL = 60 * 60 * 1000; // Sync Exchange private contacts cache every hour

        var _checkingStatus = false;
        var _self = this;

        // Indicates whether or not we are connected to the Exchange server
        var _exchangeConnected; // Keep it as undefined
        // Auto-connect during startup
        var _autoConnectEnabled;
        // Save last connect error
        var _lastConnectError;

        // Exchange online server to be connected
        var _exchangeOnline = null;

        // Exchange settings
        var _settings = Utils.shallowCopy(DEFAULT_SETTINGS);

        var _clientApiHandler = ClientApiHandler.getInstance();

        // For backward compatibility reasons
        var _useSSLStoredLocally = false;

        // Force INTEGRATED authentication for Windows users at Siemens
        var _basicAuthAllowed = true;
        var _forceSSL = false;

        var _personalContacts = {}; // Personal contact objects keyed by their Ids
        var _personalContactsPhoneMap = {}; // Map phone number to contact ID
        var _phoneLookup = ''; // Phone lookup string

        var _syncState;
        var _lastPersonalContactSync = 0;
        var _pendingSyncTimeout = null;

        var _retryInterval = RETRY_DELAY;
        var _retryAttempts = 0;
        var _retryTimeout = null;
        var _authenticationContext = null;
        var _authenticationContextConfig = null;
        var _oauthData = {
            isAuthenticated: false,
            userName: '',
            loginError: '',
            profile: ''
        };
        var _office365Supported = false;
        var _loginDeferred;
        var _getAuthorizationCodeDeferred;
        var _electronOAuthDeferred;
        var _phoneNumberLookupSearchId = 0;


        if ($rootScope.browser && !$rootScope.browser.chrome && Utils.isWindowsOs) {
            LogSvc.debug('[ExchangeConnSvc]: Inject ExchangeUserToUserSvc instead of ExtensionSvc for non-Chrome browsers');
            ExtensionSvc = $injector.get('ExchangeUserToUserSvc');
        } else {
            window.addEventListener('adal:loginCallback', function () {
                getOAuthToken(function (err, token) {
                    if (err) {
                        _loginDeferred && _loginDeferred.reject(err);
                        _loginDeferred = null;
                    } else {
                        setUserInfo(token && token.accessToken, _exchangeOnline.url);
                        LogSvc.debug('[ExchangeConnSvc]: LoginCallback token: ', obfuscateToken(token.accessToken));
                        _loginDeferred && _loginDeferred.resolve(token);
                        _loginDeferred = null;
                    }
                });
            });

            window.addEventListener('adal:getAuthorizationCodeCallback', function () {
                getAuthorizationCode(function (err, authData) {
                    if (err) {
                        _getAuthorizationCodeDeferred && _getAuthorizationCodeDeferred.reject(err);
                        _getAuthorizationCodeDeferred = null;
                    } else {
                        LogSvc.debug('[ExchangeConnSvc]: getAuthorizationCodeCallback code: ', obfuscateToken(authData.authCode));
                        _getAuthorizationCodeDeferred && _getAuthorizationCodeDeferred.resolve(authData);
                        _getAuthorizationCodeDeferred = null;
                    }
                });
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setUserInfo(token, resource) {
            if (_authenticationContext) {
                _oauthData.isAuthenticated = token && token.length > 0;
                var user = _authenticationContext.getCachedUser(resource) || {
                    userName: ''
                };
                _oauthData.userName = user.userName;
                _oauthData.profile = user.profile;
                _oauthData.loginError = _authenticationContext.getLoginError();
                $rootScope.userInfo = _oauthData;
            }
        }

        function getAuthorizationCode(cb) {
            if (_authenticationContext) {
                _authenticationContext.getAuthCode(_exchangeOnline.url, function (desc, authData, err) {
                    cb && cb(err, authData);
                });
            } else {
                cb && cb();
            }
        }

        function getOAuthToken(cb) {
            if (_authenticationContext) {
                _authenticationContext.acquireToken(_exchangeOnline.url, function (desc, token, err) {
                    setUserInfo(token, _exchangeOnline.url);
                    cb && cb(err, {
                        accessToken: token,
                        expiry: _authenticationContext.getTokenExpiry(_exchangeOnline.url),
                        expireOffsetSeconds: EXPIRE_OFFSET
                    });
                });
            } else {
                cb && cb();
            }
        }

        function resetPersonalContactsCache() {
            _personalContacts = {};
            _personalContactsPhoneMap = {};
        }

        function validateToken(cb) {
            if (_settings && _settings.authentication && _settings.authentication.method === ExchangeAuthentication.OFFICE365) {
                if (_authenticationContext) {
                    _authenticationContext.acquireToken(_exchangeOnline.url, function (desc, token, err) {
                        if (err) {
                            LogSvc.error('[ExchangeConnSvc]: acquireToken error ', err);
                            cb && cb(err);
                        } else {
                            if (_settings.oAuthToken !== token) {
                                setUserInfo(token, _exchangeOnline.url);
                                if (_settings) {
                                    _settings.oAuthToken = token;
                                    _settings.token = {
                                        accessToken: token,
                                        expiry: _authenticationContext.getTokenExpiry(_exchangeOnline.url),
                                        expireOffsetSeconds: EXPIRE_OFFSET
                                    };
                                } else {
                                    err = 'Settings object is NULL';
                                    LogSvc.error('[ExchangeConnSvc]: ' + err);
                                    cb && cb(err);
                                    return;
                                }
                            }
                            cb && cb(err, _settings.token);
                        }
                    });
                } else {
                    cb && cb('No authenticationContext object available');
                }
            } else {
                cb && cb();
            }
        }

        function validateReq(inputParam, keyReq, cb) {
            if (keyReq && !_exchangeConnected) {
                LogSvc.debug('[ExchangeConnSvc]: Exchange is not connected');
                cb && cb(ExchangeConnError.INTERNAL_ERROR);
                return false;
            }
            if (inputParam === undefined) {
                LogSvc.warn('[ExchangeConnSvc]: Missing mandatory input parameter');
                cb && cb(ExchangeConnError.INTERNAL_ERROR);
                return false;
            }
            if (keyReq && !_settings.encryptionKey) {
                LogSvc.warn('[ExchangeConnSvc]: No encryption key available. Cannot invoke Exchange services');
                cb && cb(ExchangeConnError.INTERNAL_ERROR);
                setExchangeConnected(false);
                return false;
            }
            if (!ExtensionSvc.isExtensionRunning()) {
                LogSvc.warn('[ExchangeConnSvc]: Chrome extension is not running');
                cb && cb(ExchangeConnError.NO_EXTENSION);
                setExchangeConnected(false);
                return false;
            }
            return true;
        }

        function getExchangeConnErr(error, data) {
            if (error || !data || !data.response) {
                if (error === ChromeExtension.ResponseError.TIMEOUT) {
                    return ExchangeConnError.TIMEOUT;
                }
                return ExchangeConnError.INTERNAL_ERROR;
            }

            switch (data.response) {
            case ChromeExtension.ExchangeConnResponse.OK:
            case ChromeExtension.ExchangeConnResponse.NO_RESULT: // Handle as OK
                return null;
            case ChromeExtension.ExchangeConnResponse.COULD_NOT_CONNECT:
                return ExchangeConnError.COULD_NOT_CONNECT;
            case ChromeExtension.ExchangeConnResponse.UNAUTHORIZED:
                return ExchangeConnError.UNAUTHORIZED;
            case ChromeExtension.ExchangeConnResponse.FAILED:
                return ExchangeConnError.FAILED;
            case ChromeExtension.ExchangeConnResponse.TIMED_OUT:
                return ExchangeConnError.TIMEOUT;
            case ChromeExtension.ExchangeConnResponse.MAILBOX_NOT_FOUND:
                return ExchangeConnError.MAILBOX_NOT_FOUND;
            case ChromeExtension.ExchangeConnResponse.NTLM_NOT_SUPPORTED:
                return ExchangeConnError.NTLM_NOT_SUPPORTED;
            case ChromeExtension.ExchangeConnResponse.UNSUPPORTED_METHOD:
                return ExchangeConnError.UNSUPPORTED_METHOD;
            }
            return ExchangeConnError.UNEXPECTED;
        }

        function publishConnEvent(data) {
            if (_exchangeConnected) {
                LogSvc.debug('[ExchangeConnSvc]: Publish /exchange/connected event');
                PubSubSvc.publish('/exchange/connected', [data]);
            } else {
                LogSvc.debug('[ExchangeConnSvc]: Publish /exchange/disconnected event');
                PubSubSvc.publish('/exchange/disconnected', [data]);
            }
        }

        function setExchangeConnected(value, error, doNotShowError) {
            value = !!value;
            if (!_settings.encryptionKey || _exchangeConnected === value) {
                return;
            }
            _exchangeConnected = value;
            if (_pendingSyncTimeout) {
                $timeout.cancel(_pendingSyncTimeout);
                _pendingSyncTimeout = null;
            }

            if (_exchangeConnected) {
                _lastConnectError = null;
                publishConnEvent();

                if (!LocalStoreSvc.isCachingDisabled()) {
                    LogSvc.debug('[ExchangeConnSvc]: Start timeout to sync personal contacts');
                    _pendingSyncTimeout = $timeout(function () {
                        LogSvc.debug('[ExchangeConnSvc]: Timeout expired. Sync personal contacts.');
                        _pendingSyncTimeout = null;
                        _self.syncAllPersonalContacts();
                    }, SYNC_CONTACTS_DELAY, false);
                }
            } else {
                if (error) {
                    _lastConnectError = error;
                }
                publishConnEvent({
                    error: error, // Error information (error code)
                    doNotShowError: doNotShowError // Indicate if this event should show or not the error.
                });
            }
        }

        function getExchangeStatus(cb) {
            if (!cb) {
                return;
            }
            LogSvc.debug('[ExchangeConnSvc]: getExchangeStatus...');

            _checkingStatus = true;

            ExtensionSvc.exchangeGetCapabilities(function (errGetCap, cap) {
                if (errGetCap || !cap) {
                    // Here we're not checking the capabilities themselves, but we can safely assume
                    // that if the Extension returned an error, it doesn't support Exchange
                    _checkingStatus = false;
                    LogSvc.warn('[ExchangeConnSvc]: Error getting exchange connector capabilities:', errGetCap);
                    cb(ExchangeConnError.UNAVAILABLE);
                    return;
                }
                // Get the current Exchange connection status. If it's already connected as another
                // user, the encryptionKey validation will fail and the user will have to manually
                // connect again as the correct user.
                ExtensionSvc.exchangeGetConnectionStatus(_settings.encryptionKey, function (errGetStatus, resGetStatus) {
                    _checkingStatus = false;
                    if (errGetStatus || !resGetStatus || !resGetStatus.response) {
                        LogSvc.warn('[ExchangeConnSvc]: Error getting exchange connection status: ', errGetStatus);
                        cb(ExchangeConnError.INTERNAL_ERROR);
                    } else {
                        var status = resGetStatus.response;
                        LogSvc.debug('[ExchangeConnSvc]: Exchange connection status: ', status);
                        cb(null, status);
                    }
                });
            });
        }

        function getExchangeConnSvcParams() {
            var params = {
                exchInfo: _settings,
                localizedStrings: {
                    res_Yes: $rootScope.i18n.map.res_Yes,
                    res_No: $rootScope.i18n.map.res_No,
                    res_MicrosoftExchangeTitle: $rootScope.i18n.map.res_MicrosoftExchangeTitle,
                    res_ExchangeServerAuthorize: $rootScope.i18n.map.res_ExchangeServerAuthorize
                }
            };
            if (_settings.authentication && _settings.authentication.oAuth) {
                _settings.authentication.oAuth.config = _settings.authentication.oAuth.config || _self.getAuthenticationContextConfig(_settings.encryptionKey);
            }
            return params;
        }

        function getExchangeOnlineApp(office365Cloud) {
            if (office365Cloud === Office365Cloud.GERMANY) {
                return circuit.Enums.ExchangeOnlineApp.GERMANY;
            } else {
                return circuit.Enums.ExchangeOnlineApp.GLOBAL;
            }
        }

        function setExchangeOnlineSettings(authentication, encryptionKey) {
            !_authenticationContext && _self.createAuthenticationContext(encryptionKey);
            if (_exchangeOnline && authentication && _authenticationContext && authentication.office365Cloud !== _exchangeOnline.office365Cloud) {
                _authenticationContext.clearCache();
            }

            _exchangeOnline = getExchangeOnlineApp(authentication && authentication.office365Cloud);
            _authenticationContext && _authenticationContext.setInstance(_exchangeOnline.instance);
            _authenticationContext && _authenticationContext.setClientId(_exchangeOnline.clientId);
        }

        function setLocalExchangeSettings(settings) {
            if (settings && !settings.email) {
                settings.email = $rootScope.localUser.emailAddress;
            }
            if (!settings.encryptionKey && _settings.encryptionKey) {
                settings.encryptionKey = _settings.encryptionKey;
            }
            _settings = settings || {};
            _settings.authentication = _settings.authentication || {};
            _settings.getOAuthToken = getOAuthToken;
            setExchangeOnlineSettings(_settings.authentication, _settings.encryptionKey);
            LogSvc.debug('[ExchangeConnSvc]: setLocalExchangeSettings ' +
                'server: ' + (_settings.server || '') +
                ', method: ' + (_settings.authentication.method || '') +
                ', useSSL: ' + _settings.useSSL
            );
        }

        function setSettings(settings, cb) {
            settings = settings || {
                encryptionKey: '*'
            };
            if (_forceSSL) {
                LogSvc.debug('[ExchangeConnSvc]: Only INTEGRATED authentication is supported');
                settings.useSSL = true;
            } else if (_useSSLStoredLocally) {
                // Use authentication method from local storage
                settings.useSSL = _settings.useSSL;
            }
            if (settings.useSSL) {
                settings.username = '*';
                settings.password = '*';
                settings.domain = '*';
            }
            settings.authentication = settings.authentication || {};
            if (settings.server && settings.server.match(/office365/i)) {
                settings.authentication.method = ExchangeAuthentication.OFFICE365;
            }

            if (settings.encryptionKey) {
                ExtensionSvc.getStoredCredentials(settings.encryptionKey, function (err, data) {
                    if (err || !data || !data.credentials || !data.credentials.username) {
                        LogSvc.debug('[ExchangeConnSvc]: Credentials configured in extension could not be retrieved');
                    } else {
                        if (!settings.useSSL && !settings.username) {
                            settings.username = data.credentials.username;
                            settings.password = data.credentials.password;
                        }
                        settings.authentication = data.credentials.authentication || {};
                        settings.authentication.oAuth = _settings.authentication.oAuth || {};
                        settings.authentication.oAuth.config = _self.getAuthenticationContextConfig(settings.encryptionKey);
                    }
                    setLocalExchangeSettings(settings);
                    cb && cb(null, settings);
                });
            } else {
                setLocalExchangeSettings(settings);
                cb && cb(null, settings);
            }
        }

        function getExchangeSettings(cb) {
            LogSvc.debug('[ExchangeConnSvc]: Get ExchangeSettings');
            _clientApiHandler.getExchangeSettings(function (getSettingsErr, settings) {
                $rootScope.$apply(function () {
                    if (getSettingsErr && getSettingsErr !== ExchangeConnError.NO_RESULT) {
                        LogSvc.warn('[ExchangeConnSvc]: Unable to get settings from server. Error: ', getSettingsErr);
                        cb && cb(ExchangeConnError.INTERNAL_ERROR);
                        return;
                    }

                    if (!settings) {
                        // Exchange Connector has never been enabled for this user. Prompt for settings
                        LogSvc.debug('[ExchangeConnSvc]: No settings available for this user');
                        cb && cb(getSettingsErr === ExchangeConnError.NO_RESULT ? getSettingsErr : ExchangeConnError.UNAUTHORIZED);
                        return;
                    }

                    var setSettingsCb = function (err, savedSettings) {
                        if (err) { cb && cb(err); }

                        if (savedSettings.authentication.method === ExchangeAuthentication.OFFICE365) {
                            setExchangeOnlineSettings(savedSettings.authentication, savedSettings.encryptionKey);

                            getOAuthToken(function (getTokenErr, token) {
                                if (!getTokenErr) {
                                    setUserInfo(token && token.accessToken, _exchangeOnline.url);
                                    savedSettings.authentication = savedSettings.authentication || {};
                                    savedSettings.authentication.token = token;
                                } else {
                                    $rootScope.userInfo = _oauthData;
                                }
                                setSettings(savedSettings, cb);
                            });
                        } else {
                            setSettings(savedSettings, cb);
                        }
                    };

                    if (!settings && getSettingsErr === ExchangeConnError.NO_RESULT) {
                        LogSvc.debug('[ExchangeConnSvc]: Settings not available for this user. Save default values.');
                        var exchangeSettings = {
                            encryptionKey: '',
                            email: $rootScope.localUser.emailAddress,
                            server: '',
                            useSSL: true
                        };
                        _clientApiHandler.saveExchangeSettings(exchangeSettings, function (err, data) {
                            if (err) {
                                cb && cb(err);
                            } else {
                                setSettings(data, setSettingsCb);
                            }
                        });
                    } else {
                        setSettings(settings, setSettingsCb);
                    }
                });
            });
        }

        function canRetryToConnect(errorCode) {
            return errorCode !== ChromeExtension.ExchangeConnResponse.UNAUTHORIZED &&
                errorCode !== ChromeExtension.ExchangeConnResponse.MAILBOX_NOT_FOUND &&
                errorCode !== ChromeExtension.ExchangeConnResponse.NTLM_NOT_SUPPORTED;
        }

        function sendConnectRequest(autoRetry, cb) {
            LogSvc.debug('[ExchangeConnSvc]: Connect to Exchange server.');

            if (_retryTimeout) {
                window.clearTimeout(_retryTimeout);
                _retryTimeout = null;
            }

            if (_exchangeConnected) {
                LogSvc.debug('[ExchangeConnSvc]: Already connected to Exchange.');
                cb && cb();
                return;
            }

            var params = getExchangeConnSvcParams();

            ExtensionSvc.exchangeConnect(params, function (error, connData) {
                var connError = getExchangeConnErr(error, connData);
                if (!connError) {
                    LogSvc.debug('[ExchangeConnSvc]: Succesfully connected to Exchange server');
                    _retryInterval = RETRY_DELAY;
                    _retryAttempts = 0;
                    if (!_settings.useSSL && !_settings.username) {
                        ExtensionSvc.getStoredCredentials(_settings.encryptionKey, function (err, data) {
                            if (err || !data || !data.credentials || !data.credentials.username) {
                                LogSvc.debug('[ExchangeConnSvc]: Credentials configured in extension could not be retrieved');
                                return;
                            }
                            _settings.username = data.credentials.username;
                            _settings.password = data.credentials.password;
                        });
                    }
                    // Save it in localstorage so we auto-connect next time
                    saveAutoConnect(true);
                    cb && cb();
                } else {
                    LogSvc.warn('[ExchangeConnSvc]: Failed to connect to Exchange server - ', connError);

                    if (!autoRetry || !canRetryToConnect(connError)) {
                        cb && cb(connError);
                        return;
                    }

                    if (_retryAttempts >= MAX_ATTEMPTS) {
                        LogSvc.warn('[ExchangeConnSvc]: Reached max number of attempts to reconnect to Exchange');
                        cb && cb(ExchangeConnError.EXCEED_MAX_ATTEMPT);
                        return;
                    }

                    LogSvc.info('[ExchangeConnSvc]: Retry to connect to Exchange in ' + Math.floor(_retryInterval / 1000) + ' seconds');
                    _retryTimeout = window.setTimeout(function () {
                        _retryTimeout = null;
                        sendConnectRequest(autoRetry, cb);
                    }, _retryInterval);

                    // After the first attempt (random), set the interval to RETRY_INTERVAL
                    // for MAX_SHORT_ATTEMPTS, then start doubling it up to MAX_INTERVAL
                    _retryAttempts++;
                    _retryInterval = _retryAttempts > MAX_SHORT_ATTEMPTS ? _retryInterval * 2 : RETRY_INTERVAL;
                    _retryInterval = Math.min(_retryInterval, MAX_INTERVAL);
                }
            });
        }

        function exchangeConnect(autoRetry, cb) {
            _retryInterval = RETRY_DELAY;
            _retryAttempts = 0;
            if (_retryTimeout) {
                window.clearTimeout(_retryTimeout);
                _retryTimeout = null;
            }
            // Get the Exchange connection status first. Only invoke exchangeConnect()
            // if not connected.
            getExchangeStatus(function (err, status) {
                if (err) {
                    // There was an error, don't try to connect
                    cb && cb(err);
                    return;
                }
                if (status !== 'connected') {
                    sendConnectRequest(autoRetry, cb);
                } else {
                    cb && cb();
                }
            });
        }

        function sendAsyncResp(cb, resp) {
            if (typeof cb === 'function') {
                $timeout(function () {
                    cb(resp);
                }, 0);
            }
        }

        function connect(autoRetry, cb) {
            LogSvc.debug('[ExchangeConnSvc]: connect...');

            if (!ExtensionSvc.isExtensionRunning()) {
                sendAsyncResp(cb, ExchangeConnError.NO_EXTENSION);
                return;
            }

            if (!_settings.encryptionKey) {
                // Get Settings from server
                getExchangeSettings(function (err) {
                    if (!err) {
                        exchangeConnect(autoRetry, cb);
                    } else if (!$rootScope.browser.chrome) {
                        setLocalExchangeSettings(Utils.shallowCopy(DEFAULT_SETTINGS));
                        _self.saveSettings(_settings, function () {
                            exchangeConnect(autoRetry, cb);
                        });
                    } else {
                        cb && cb(err);
                    }
                });
            } else {
                exchangeConnect(autoRetry, cb);
            }
        }

        function connectOnInit() {
            LogSvc.debug('[ExchangeConnSvc]: Exchange connector enabled, auto-connect it');
            _autoConnectEnabled = true;
            connect(true, function (err) {
                if (err) {
                    if (err === ExchangeConnError.UNAUTHORIZED) {
                        LogSvc.debug('[ExchangeConnSvc]: Auto-connect disabled due to wrong credentials');
                        _autoConnectEnabled = false;
                    }
                    setExchangeConnected(false, err);
                } else {
                    setExchangeConnected(true);
                }
            });
        }

        function onInitEvent(state) {
            if (state !== RegistrationState.Registered) {
                return;
            }
            var connector = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.EXCHANGE_CONNECTOR);
            var enabled = false;
            if (connector) {
                if (typeof connector === 'boolean') {
                    LogSvc.debug('[ExchangeConnSvc]: Only the enabled connection flag stored locally');
                    enabled = connector;
                } else {
                    LogSvc.debug('[ExchangeConnSvc]: Enabled connection flag and authentication method stored locally');
                    enabled = connector.enabled;
                    _useSSLStoredLocally = !_forceSSL;
                    _settings.useSSL = _forceSSL || connector.useSSL;
                }
            }
            if (enabled) {
                if (!LocalStoreSvc.isCachingDisabled() && !_phoneLookup) {
                    // Get state from last personal contact sync
                    var syncState = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.EXCHANGE_SYNC_STATE);
                    if (syncState) {
                        LogSvc.debug('[ExchangeConnSvc]: Loaded Exchange personal contacts sync state from local storage');
                        _syncState = syncState;
                        LocalStoreSvc.getAllExchangeContacts()
                        .then(function (contacts) {
                            if (contacts && contacts.length) {
                                contactsLoadedFromDb(contacts);
                            } else {
                                LogSvc.warn('[ExchangeConnSvc]: No contacts loaded from indexed DB on startup. All contacts will be retrieved from Exchange');
                                _syncState = null;
                            }
                            connectOnInit();
                        })
                        .catch(function () {});
                    } else {
                        LogSvc.info('[ExchangeConnSvc]: No Exchange personal contacts loaded from indexed DB during startup. All contacts will be retrieved from Exchange');
                        connectOnInit();
                    }
                } else {
                    connectOnInit();
                }
            } else {
                getExchangeSettings(function (err) {
                    if (err && err === ExchangeConnError.NO_RESULT) {
                        _self.saveSettings(_settings);
                    }
                });
                LogSvc.debug('[ExchangeConnSvc]: Exchange connector not enabled');
            }
        }

        function handleConnError(connError) {
            if (connError === ExchangeConnError.UNAUTHORIZED) {
                setExchangeConnected(false, connError);
                ExtensionSvc.exchangeDisconnect();
            }
        }

        function updateUserWithLocalContactName(user) {
            if (_phoneLookup.length === 0 || !user || !(user.phoneNumber || user.fullyQualifiedNumber)) {
                return false;
            }
            var number = user.fullyQualifiedNumber || user.phoneNumber;
            // First remove commonly used phone number formatting characters: (, ), - and white spaces. In case the user is copy/pasting the phone number
            // We can't simply remove all non-digit chars, because that would cause search strings like "conferenceroom123" to trigger a phone number lookup
            var numberLookupStr = number.replace(COMMON_CHAR_REGEX, '');
            // Search for the number lookup in the cached private contacts
            if (numberLookupStr.match(DIGIT_LENGTH_REGEX)) {
                LogSvc.debug('[ExchangeConnSvc]: Searching number in Personal Contacts cache');
                // Make sure that special characters in the numberLookupStr are escaped
                numberLookupStr = RegExp.escape(numberLookupStr);
                var regex = new RegExp(PREFIX_CHAR_PATTERN + numberLookupStr + '\\b', 'g');
                var matches = _phoneLookup.match(regex);
                if (matches && matches.length === 1) {
                    var phone = matches[0];
                    var id = _personalContactsPhoneMap[phone];
                    var c = _personalContacts[id];
                    if (c) {
                        user.displayName = ((c.firstName || '') + ' ' + (c.lastName || '')).trim();
                        return true;
                    }
                }
            }
            return false;
        }

        function searchContacts(searchStr, constraints, resCount, cb) {
            cb = cb || function () {};
            var contacts = [];

            if (constraints && (constraints.phoneNumberLookup || constraints.reversePhoneNumberLookup)) {
                // First remove commonly used phone number formatting characters: (, ), - and white spaces. In case the user is copy/pasting the phone number
                // We can't simply remove all non-digit chars, because that would cause search strings like "conferenceroom123" to trigger a phone number lookup
                var numberLookupStr = searchStr.replace(COMMON_CHAR_REGEX, '');
                // 1. If it's number lookup (numberLookupStr begins with 3 or more digits), search for it in the cached private contacts
                if (_phoneLookup.length > 0 && numberLookupStr.match(DIGIT_LENGTH_REGEX)) {
                    LogSvc.debug('[ExchangeConnSvc]: Searching number in Personal Contacts cache. Number of phone numbers in cache: ', _phoneLookup.length);
                    var singleMatch = numberLookupStr[0] === '+'; // If numberLookupStr is in E164 format, there can be only one match
                    // Make sure that special characters in the numberLookupStr are escaped
                    numberLookupStr = RegExp.escape(numberLookupStr);
                    var regex;
                    if (constraints.reversePhoneNumberLookup) {
                        // Full match
                        singleMatch = true;
                        regex = new RegExp(PREFIX_CHAR_PATTERN + numberLookupStr + '\\b', 'g');
                    } else {
                        // Partial match
                        regex = new RegExp(PREFIX_PATTERN + numberLookupStr + POSTFIX_PATTERN, 'g');
                    }
                    var matches = _phoneLookup.match(regex);
                    if (matches) {
                        var l = matches.length;
                        if (singleMatch && l > 1) {
                            LogSvc.warn('[ExchangeConnSvc]: searchContacts. Multiple private contacts found for a E164 number. No private contacts returned');
                        } else {
                            LogSvc.debug('[ExchangeConnSvc]: Number of matches in local cache:', l);
                            // Copy all matches to contacts
                            for (var i = 0; i < l; i++) {
                                var phone = matches[i];
                                var id = _personalContactsPhoneMap[phone];
                                var c = _personalContacts[id];
                                if (c) {
                                    contacts.push({
                                        firstName: c.firstName,
                                        lastName: c.lastName,
                                        phoneNumbers: [{
                                            type: c.phoneType,
                                            number: phone
                                        }],
                                        emailAddresses: c.emailAddresses,
                                        department: c.department,
                                        sourceIntegrationRes: 'res_ExchangeContact'
                                    });
                                }
                            }
                        }
                    }
                    cb(null, contacts);
                    _phoneNumberLookupSearchId++;

                    return _phoneNumberLookupSearchId;
                }
            }

            if (!validateReq(searchStr, true, cb)) {
                return null;
            }
            // 2. Search in Exchange only if it's not a number lookup
            resCount = resCount || DEFAULT_NUM_CONTACTS;
            var searchId = ExtensionSvc.exchangeSearchContacts(_settings.encryptionKey, searchStr, resCount, function (error, data) {
                var connError = getExchangeConnErr(error, data);
                if (!connError) {
                    LogSvc.debug('[ExchangeConnSvc]: Search contacts was successful. SearchId:', searchId);
                    if (data.contacts && data.contacts.length) {
                        data.contacts.forEach(function (contact) {
                            contact.sourceIntegrationRes = 'res_ExchangeContact';
                        });
                        Array.prototype.push.apply(contacts, data.contacts);
                    }
                    cb(null, contacts, searchId);
                } else {
                    LogSvc.warn('[ExchangeConnSvc]: Search contacts failed - ', connError);
                    handleConnError(connError);
                    cb(connError);
                }
            });
            return searchId;
        }

        function getContact(exchangeEmail, cb) {
            cb = cb || function () {};

            ExtensionSvc.exchangeGetContact(_settings.encryptionKey, exchangeEmail, function (error, data) {
                var connError = getExchangeConnErr(error, data);
                if (!connError) {
                    if (data.response === ChromeExtension.ExchangeConnResponse.NO_RESULT) {
                        LogSvc.debug('[ExchangeConnSvc]: Contact could not be resolved');
                        cb(null, null);
                        return;
                    }
                    if (data.contact) {
                        data.contact.sourceIntegrationRes = 'res_ExchangeContact';
                    }
                    LogSvc.debug('[ExchangeConnSvc]: Get contact was successful');
                    cb(null, data.contact);
                } else {
                    LogSvc.warn('[ExchangeConnSvc]: Get contact failed - ', connError);
                    handleConnError(connError);
                    cb(connError);
                }
            });
        }

        function processPhoneNumber(p) {
            if (!p || !p.number) {
                return false;
            }
            if (!Utils.PHONE_DIAL_PATTERN.test(p.number)) {
                var match = p.number.match(Utils.PHONE_WITH_EXTENSION_PATTERN);
                if (match) {
                    // Phone numbers in Exchange might contain extension numbers as well
                    // so strip the extension out
                    p.number = match[1] || '';
                } else {
                    return false;
                }
            }
            // Remove any non-digit character from the phone number (except +)
            p.number = p.number.replace(/[^\d+*#]/g, '');
            if (!p.number) {
                return false;
            }
            return true;
        }

        function processContact(c, addToLookupStr) {
            var processPhone = function (p) {
                if (!processPhoneNumber(p)) {
                    return;
                }
                _personalContactsPhoneMap[p.number] = c.id;
                if (addToLookupStr) {
                    _phoneLookup += '\t' + p.number;
                }
            };
            var phones = c.phoneNumbers;
            if (phones) {
                phones.forEach(processPhone);
            }
            _personalContacts[c.id] = c;
        }

        function deleteContact(id) {
            if (_personalContacts[id]) {
                delete _personalContacts[id];
            }
            // Stale contacts will be left in _personalContactsPhoneMap, but they'll be cleaned up after next logon
        }

        function buildPhoneLookupStr() {
            var phoneNumbers = Object.keys(_personalContactsPhoneMap);
            _phoneLookup = phoneNumbers.join('\t');
        }

        function onGetRenewedToken(reqId) {
            validateToken(function (err, token) {
                if (err) {
                    LogSvc.error('ValidateToken error: ', err);
                }
                LogSvc.debug('[ExchangeConnSvc]: Renewed token: ', obfuscateToken(token));
                ExtensionSvc.exchangeOnRenewedToken(_settings.encryptionKey, reqId, token);
            });
        }

        function resolveOAuthPromise(url) {
            LogSvc.debug('[ExchangeConnSvc]: resolveOAuthPromise url:', url && url.substring(0, url.indexOf('=')));
            var err, errDesc, hash;
            if (!url) {
                err = 'login_required';
            } else if (url.match(/error=/i)) {
                var params = url.replace(_authenticationContext.config.redirectUri + '#', '').split('&');
                params.forEach(function (param) {
                    if (param.startsWith('error=')) {
                        err = param.replace('error=', '');
                    } else if (param.startsWith('error_description=')) {
                        errDesc = param.replace('error_description=', '');
                    }
                });
            } else if (url.match(/access_token/i)) {
                hash = url.replace(_authenticationContext.config.redirectUri + '#', '');
            } else if (url.match(/code=/i)) {
                hash = url.replace(_authenticationContext.config.redirectUri + '?', '');
            }

            if (hash) {
                LogSvc.debug('[ExchangeConnSvc]: resolveOAuthPromise hash:', obfuscateToken(hash));
                _electronOAuthDeferred && _electronOAuthDeferred.resolve(hash);
            } else {
                LogSvc.debug('[ExchangeConnSvc]: resolveOAuthPromise url:', url);
                LogSvc.debug('[ExchangeConnSvc]: resolveOAuthPromise err:' + err + ', err description:' + errDesc);
                _electronOAuthDeferred && _electronOAuthDeferred.reject(err, errDesc);
            }
        }

        function onSyncContactsEvent(evt) {
            if (!evt) {
                return;
            }
            if (evt.reponse === ChromeExtension.ExchangeConnResponse.FAILED) {
                // Any error, invalidate the syncState, which will force a full sync from scratch
                _syncState = null;
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.EXCHANGE_SYNC_STATE, _syncState);
                return;
            }

            if (!evt.contacts) {
                return;
            }
            if (evt.contacts.added && evt.contacts.added.length) {
                evt.contacts.added.forEach(function (contact) {
                    processContact(contact, true);
                });
                LocalStoreSvc.putExchangeContacts(evt.contacts.added);
            }
            if (evt.contacts.changed && evt.contacts.changed.length) {
                evt.contacts.changed.forEach(function (contact) {
                    processContact(contact);
                });
                // Here we have to rebuild the map and lookup string
                _personalContactsPhoneMap = {};
                var ids = Object.keys(_personalContacts);
                for (var i = 0, l = ids.length; i < l; i++) {
                    var c = _personalContacts[ids[i]];
                    if (c.phoneNumbers) {
                        c.phoneNumbers.forEach(function (p) {
                            _personalContactsPhoneMap[p.number] = c.id;
                        });
                    }
                }
                buildPhoneLookupStr();
                LocalStoreSvc.putExchangeContacts(evt.contacts.changed);
            }
            if (evt.contacts.deleted && evt.contacts.deleted.length) {
                evt.contacts.deleted.forEach(function (id) {
                    deleteContact(id);
                });
                LocalStoreSvc.removeExchangeContacts(evt.contacts.deleted);
            }
        }

        function onGetContactsEvent(evt) {
            if (evt.contacts) {
                if (evt.contacts.length) {
                    var list = evt.contacts;
                    for (var i = 0, length = list.length; i < length; i++) {
                        processContact(list[i]);
                    }
                }
            } else {
                // Done retrieving all contacts
                LogSvc.info('[ExchangeConnSvc]: Loaded all personal contacts from Exchange server. Num of phones:', Object.keys(_personalContactsPhoneMap).length);
                buildPhoneLookupStr();
                saveContactsInLocalStore(_personalContacts);
            }
        }

        function saveContactsInLocalStore(contacts, cb) {
            if (!contacts) {
                cb && cb('No contacts to be saved');
                return;
            }
            var ids = Object.keys(contacts);
            var array = ids.map(function (id) {
                // Get the contact object and make sure it has an id property, otherwise
                // the put operation in LocalStoreSvc will fail
                var obj = contacts[id];
                obj.id = id;
                return obj;
            });
            LocalStoreSvc.putExchangeContacts(array)
                .then(function () {
                    cb && cb();
                })
                .catch(function (err) {
                    cb && cb(err);
                });
        }

        function contactsLoadedFromDb(contacts) {
            if (!contacts) {
                return;
            }
            resetPersonalContactsCache();
            for (var i = 0, l = contacts.length; i < l; i++) {
                processContact(contacts[i]);
            }
            LogSvc.info('[ExchangeConnSvc]: Loaded Exchange personal contacts from indexed DB. Num of phones:',
                Object.keys(_personalContactsPhoneMap).length);
            buildPhoneLookupStr();
        }

        function obfuscateToken(token) {
            if (_authenticationContext) {
                return _authenticationContext.obfuscateToken(token);
            } else {
                return token;
            }
        }

        function saveAutoConnect(enabled) {
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.EXCHANGE_CONNECTOR, {
                enabled: enabled,
                useSSL: _settings.useSSL
            });
            _autoConnectEnabled = enabled;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/chromeExt/initialized', function () {
            LogSvc.debug('[ExchangeConnSvc]: Received /chromeExt/initialized event. registrationState = ', RegistrationSvc.state());
            if (_exchangeConnected) {
                // Desktop app sometimes send a /chromeExt/initialized event without a prior /chromeExt/unregistered
                setExchangeConnected(false);
            }
            onInitEvent(RegistrationSvc.state());

            ExtensionSvc.exchangeGetCapabilities(function (errGetCap, cap) {
                if (cap && cap.capabilities && cap.capabilities.match(/office365ex/i)) {
                    _office365Supported = true;
                }
            });
        });

        PubSubSvc.subscribe('/chromeExt/unregistered', function () {
            LogSvc.debug('[ExchangeConnSvc]: Received /chromeExt/unregistered event');
            // checking to avoid popup message when it is disconnected.
            if (_exchangeConnected) {
                setExchangeConnected(false);
            }
        });

        PubSubSvc.subscribe('/registration/state', function (state) {
            LogSvc.debug('[ExchangeConnSvc]: Received /registration/state event');
            onInitEvent(state);
        });

        PubSubSvc.subscribe('/oauth/redirectUrl/exchange', function (newUrl) {
            LogSvc.debug('[ExchangeConnSvc]: Received /oauth/redirectUrl/exchange event');
            resolveOAuthPromise(newUrl);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Extension events handler
        ///////////////////////////////////////////////////////////////////////////////////////
        ExtensionSvc.addEventListener && ExtensionSvc.addEventListener(ChromeExtension.BgTarget.EXCHANGE_CONNECTOR, function (evt) {
            LogSvc.debug('[ExchangeConnSvc]: Received Exchange connector event. Evt type:', evt.data && evt.data.type);
            if (evt.data) {
                switch (evt.data.type) {
                case ChromeExtension.BgExchangeMsgType.GET_ALL_PERSONAL_CONTACTS:
                    onGetContactsEvent(evt.data);
                    break;
                case ChromeExtension.BgExchangeMsgType.SYNC_ALL_PERSONAL_CONTACTS:
                    onSyncContactsEvent(evt.data);
                    break;
                case ChromeExtension.BgExchangeMsgType.CONTACT_FOLDERS_SYNC_STATE:
                    _syncState = evt.data.syncState;
                    _lastPersonalContactSync = Date.now();
                    LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.EXCHANGE_SYNC_STATE, _syncState);
                    break;
                case ChromeExtension.BgExchangeMsgType.GET_RENEWED_TOKEN:
                    onGetRenewedToken(evt.data.reqId);
                    break;
                default:
                    LogSvc.info('[ExchangeConnSvc]: Received unexpected Exchange connector event. Evt type:', evt.data.type);
                    break;
                }
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            name: {
                get: function () {
                    return 'ExchangeConnSvc';
                },
                enumerable: true,
                configurable: false
            },
            checkingStatus: {
                get: function () {
                    return _checkingStatus;
                },
                enumerable: true,
                configurable: false
            },
            connected: {
                get: function () {
                    return _exchangeConnected;
                },
                enumerable: true,
                configurable: true
            },
            autoConnectEnabled: {
                get: function () {
                    return _autoConnectEnabled;
                },
                enumerable: true,
                configurable: false
            },
            lastConnectError: {
                get: function () {
                    return _lastConnectError;
                },
                enumerable: true,
                configurable: false
            }
        });

        this.connect = function (cb) {
            connect(false, function (err) {
                if (err) {
                    // Manual connection failed. Clear the auto-connect flag in the local store so we won't auto-connect during next startup
                    saveAutoConnect(false);
                    setExchangeConnected(false, err, true);
                } else {
                    setExchangeConnected(true);
                }
                cb && cb(err);
            });
        };

        this.disconnect = function (cb) {
            LogSvc.debug('[ExchangeConnSvc]: disconnect...');

            if (!ExtensionSvc.isExtensionRunning()) {
                // We already are disconnected
                sendAsyncResp(cb);
                return;
            }

            ExtensionSvc.exchangeDisconnect(function (error, data) {
                var connError = getExchangeConnErr(error, data);
                if (!connError) {
                    LogSvc.debug('[ExchangeConnSvc]: Succesfully disconnected from Exchange settings');

                    if (!_settings.useSSL) {
                        // Clear saved password
                        _settings.password = '';
                    }
                } else {
                    LogSvc.warn('[ExchangeConnSvc]: Failed to disconnect - ', connError);
                }

                setExchangeConnected(false, null, true);
                // Save this disconnected state so we won't try to automatically reconnect
                // during the next logon or when the extension is reloaded.
                saveAutoConnect(false);
                cb && cb();
            });
        };

        this.getSettings = function () {
            var settings = Utils.shallowCopy(_settings || DEFAULT_SETTINGS);

            // The useSSL parameter is currently misused to differentiate between BASIC and INTEGRATED
            // authentication. useSSL set to true indicates INTEGRATED authentication.
            if (settings.useSSL) {
                settings.username = '';
                settings.password = '';
                settings.domain = '';
            }
            return settings;
        };

        /**
         * Save Exchange settings
         * @param {Object} settings - Required object containing Exchange settings:
         * - email: email associated with Exchange
         * - server: Optional, exchange server address
         * - domain: Required, domain name
         * - username - Required, exchange user name
         * - password - Required, exchange password
         * @param {function} cb - Callback that will be invoked with the settings object
         * returned from the server (with the encryption key)
         */
        this.saveSettings = function (settings, cb) {
            LogSvc.debug('[ExchangeConnSvc]: saveSettings...');

            if (!validateReq(settings, false, cb)) {
                return;
            }
            cb = cb || function () {};

            // Create a shallow copy so we don't modify the given object.
            settings = Utils.shallowCopy(settings);

            // The useSSL parameter is currently misused to differentiate between BASIC and INTEGRATED
            // authentication. useSSL set to true indicates INTEGRATED authentication.
            settings.useSSL = _forceSSL || !!settings.useSSL;

            settings.email = settings.email || $rootScope.localUser.emailAddress;

            // For INTEGRATED authentication we need to set the username, password and domain to '*'.
            if (settings.useSSL) {
                settings.username = '*';
                settings.password = '*';
                settings.domain = '*';
            }

            // Save settings on back end, get encryption key, and store credentials on chrome ext.
            var request = {
                encryptionKey: '',
                email: settings.email,
                server: settings.server || '',
                domain: settings.domain,
                useSSL: settings.useSSL
            };

            if (request.useSSL) {
                // This device uses windows authentication. Do not overwrite domain in the back end.
                delete request.domain;
            }

            _clientApiHandler.saveExchangeSettings(request, function (err, data) {
                if (err) {
                    LogSvc.warn('[ExchangeConnSvc]: Unable to save settings in the server. Error: ', err);
                    cb(ExchangeConnError.INTERNAL_ERROR);
                    return;
                }
                settings.encryptionKey = data.encryptionKey;
                setLocalExchangeSettings(settings);
                ExtensionSvc.storeExchCredentials({
                    encryptionKey: settings.encryptionKey,
                    username: settings.username,
                    password: settings.password,
                    authentication: settings.authentication
                }, function (error) {
                    if (error) {
                        var connError = getExchangeConnErr(error);
                        LogSvc.warn('[ExchangeConnSvc]: Failed to store credentials. Error: ', error);
                        cb(connError);
                    } else {
                        cb();
                    }
                });
            });
        };

        this.searchContactsWithConstraints = function (searchStr, constraints, resCount, cb) {
            if (!searchStr || typeof searchStr !== 'string') {
                cb && cb(null, []);
                return null;
            }
            if (!(constraints && (constraints.phoneNumberLookup || constraints.reversePhoneNumberLookup)) &&
                // Call validateReq() only if it's not a number lookup
                !validateReq(searchStr, true, cb)) {
                return null;
            }
            LogSvc.debug('[ExchangeConnSvc]: searchContacts. Search string:', searchStr);
            return searchContacts(searchStr, constraints, resCount, cb);
        };

        this.cancelSearchContacts = function (reqId) {
            return ExtensionSvc.exchangeCancelReqCallback(reqId);
        };

        this.getContact = function (exchangeEmail, cb) {
            LogSvc.debug('[ExchangeConnSvc]: getContact...');

            if (!validateReq(exchangeEmail, true, cb)) {
                return;
            }
            getContact(exchangeEmail, cb);
        };

        this.getAllPersonalContacts = function () {
            if (LocalStoreSvc.isCachingDisabled() || !validateReq(true, true)) {
                return;
            }
            ExtensionSvc.getAllPersonalContacts(_settings.encryptionKey);
        };

        this.supportsGetAppointments = ExtensionSvc.supportsGetAppointments.bind(ExtensionSvc);

        this.getAppointments = function (startDate, endDate, resCount, cb) {
            LogSvc.debug('[ExchangeConnSvc]: getAppointments...');

            if (!ExtensionSvc.supportsGetAppointments()) {
                cb && cb(ExchangeConnError.UNSUPPORTED_METHOD);
                return;
            }

            if (!validateReq(true, true, cb)) {
                return;
            }
            ExtensionSvc.getAppointments(_settings.encryptionKey, startDate, endDate, resCount, function (error, data) {
                var connError = getExchangeConnErr(error, data);
                if (!connError) {
                    LogSvc.debug('[ExchangeConnSvc]: Get appointments was successful');
                    cb && cb(null, data.calendarItems || []);
                } else {
                    LogSvc.warn('[ExchangeConnSvc]: Get appointments failed - ', connError);
                    handleConnError(connError);
                    cb(connError);
                }
            });
        };

        this.syncAllPersonalContacts = function () {
            if (LocalStoreSvc.isCachingDisabled() || !validateReq(true, true)) {
                return;
            }
            if (_pendingSyncTimeout) {
                $timeout.cancel(_pendingSyncTimeout);
                _pendingSyncTimeout = null;
            }
            if (_syncState) {
                var now = Date.now();
                if ((now - _lastPersonalContactSync) > PERSONAL_CONTACTS_SYNC_MIN_INTERVAL) {
                    ExtensionSvc.syncAllPersonalContacts(_settings.encryptionKey, _syncState);
                    // Sync'ing is not done yet, but updating _lastPersonalContactSync now will avoid
                    // multiple requests being processed at the same time
                    _lastPersonalContactSync = now;
                } else {
                    LogSvc.debug('[ExchangeConnSvc]: syncAllPersonalContacts. Last sync was ' +
                        new Date(_lastPersonalContactSync).toLocaleTimeString() + '. Next sync after ' +
                        new Date(_lastPersonalContactSync + PERSONAL_CONTACTS_SYNC_MIN_INTERVAL).toLocaleTimeString());
                }
            } else {
                // No sync state found, so we need to retrieve all contacts
                LogSvc.debug('[ExchangeConnSvc]: syncAllPersonalContacts. No sync state found, get all personal contacts');
                _self.getAllPersonalContacts();
            }
        };

        this.supportsOOO = ExtensionSvc.supportsOOO.bind(ExtensionSvc);

        this.getOooMsg = function (email) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[ExchangeConnSvc]: getOooMsg...');

                if (!ExtensionSvc.supportsOOO()) {
                    LogSvc.warn('[ExchangeConnSvc]: OOS is not supported');
                    reject(ExchangeConnError.UNSUPPORTED_METHOD);
                    return;
                }

                if (!validateReq(true, true, reject)) {
                    return;
                }

                ExtensionSvc.getOooMsg(_settings.encryptionKey, $rootScope.localUser.emailAddress, email, function (error, data) {
                    var connError = getExchangeConnErr(error, data);
                    if (connError) {
                        reject(connError);
                        return;
                    }
                    var oooMsg = Utils.sanitize(data.oooMsg) || '';
                    oooMsg = Utils.linkifyContent(oooMsg);

                    // Trim empty spaces and empty lines at beginning and end of message
                    oooMsg = oooMsg.trim()
                        .replace(/^(<br>)+/, '')
                        .replace(/(<br>)+$/, '');

                    resolve(oooMsg);
                });
            });
        };

        this.isOutOfOffice = function (email) {
            return _self.getOooMsg(email)
                .then(function (oooMsg) {
                    return !!oooMsg;
                });
        };

        this.supportsUserAvailability = ExtensionSvc.supportsUserAvailability.bind(ExtensionSvc);

        this.getUserAvailability = function (email) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[ExchangeConnSvc]: getUserAvailability...');
                try {
                    if (!ExtensionSvc.supportsUserAvailability || !ExtensionSvc.supportsUserAvailability()) {
                        LogSvc.warn('[ExchangeConnSvc]: UserAvailability is not supported');
                        reject(ExchangeConnError.UNSUPPORTED_METHOD);
                        return;
                    }

                    if (!validateReq(true, true, reject)) {
                        return;
                    }

                    ExtensionSvc.getUserAvailability(_settings.encryptionKey, email, function (error, data) {
                        var connError = getExchangeConnErr(error, data);
                        if (connError) {
                            reject(connError);
                            return;
                        }
                        var busyCalendarEvents = [];
                        var calendarEvent = data.userAvailability &&
                            data.userAvailability.FreeBusyResponse &&
                            data.userAvailability.FreeBusyResponse.FreeBusyView &&
                            data.userAvailability.FreeBusyResponse.FreeBusyView.CalendarEventArray &&
                            data.userAvailability.FreeBusyResponse.FreeBusyView.CalendarEventArray.CalendarEvent;

                        if (calendarEvent) {
                            var pushBusyCalendarEvent = function (event) {
                                if (event && event.BusyType && event.BusyType.match(/busy/i)) {
                                    busyCalendarEvents.push({
                                        busyType: event.BusyType,
                                        startTime: event.StartTime && new Date(event.StartTime).getTime(),
                                        endTime: event.EndTime && new Date(event.EndTime).getTime()
                                    });
                                }
                            };

                            if (calendarEvent.BusyType) {
                                pushBusyCalendarEvent(calendarEvent);
                            } else {
                                Object.keys(calendarEvent).forEach(function (key) {
                                    pushBusyCalendarEvent(calendarEvent[key]);
                                });
                            }
                        }
                        LogSvc.debug('[ExchangeConnSvc]: Retrieved ' + busyCalendarEvents.length + ' busy calendar events');
                        resolve(busyCalendarEvents);
                    });
                } catch (ex) {
                    LogSvc.error('[ExchangeConnSvc]: getUserAvailability exception. ', ex);
                    reject(ExchangeConnError.INTERNAL_ERROR);
                }
            });
        };

        this.getErrorMessage = function (err) {
            if (!err) {
                return '';
            }
            switch (err) {
            case ExchangeConnError.COULD_NOT_CONNECT:
                return 'res_ExchangeConnError_CouldNotConnect';
            case ExchangeConnError.UNAUTHORIZED:
                return 'res_ExchangeConnError_Unauthorized';
            case ExchangeConnError.FAILED:
                return 'res_ExchangeConnError_Failed';
            case ExchangeConnError.NO_EXTENSION:
                return 'res_ExchangeConnError_NoExtension';
            case ExchangeConnError.UNAVAILABLE:
                return $rootScope.browser.chrome ? 'res_ExchangeConnError_NoConnector' : 'res_ExchangeConnError_NoExternalConnector';
            case ExchangeConnError.TIMEOUT:
                return $rootScope.browser.chrome ? 'res_ExchangeConnError_Timeout' : 'res_ExchangeConnError_ExternalTimeout';
            case ExchangeConnError.MAILBOX_NOT_FOUND:
                return 'res_ExchangeConnError_MailboxNotFound';
            case ExchangeConnError.NTLM_NOT_SUPPORTED:
                return 'res_ExchangeConnError_NtlmNotSupported';
            }
            return 'res_ExchangeConnError_Other';
        };

        this.isOffice365Supported = function () {
            return _office365Supported;
        };

        this.basicAuthAllowed = function () {
            return _basicAuthAllowed;
        };

        this.forceSSL = function () {
            return _forceSSL;
        };

        this.adalClearCache = function () {
            _authenticationContext && _authenticationContext.clearCache();
        };

        this.getAuthenticationContextConfig = function (encryptionKey) {
            if (!_authenticationContextConfig) {
                _authenticationContextConfig = {
                    encryptionKey: encryptionKey || _settings && _settings.encryptionKey,
                    redirectUri: window.location.origin + '/dist/blank.html',
                    instance: ExchangeOnlineApp.GLOBAL.instance,
                    // The id of the Exchange Connector Authentication app on http://portal.azure.com for the tenant common (multi-tenant suppport)
                    clientId: ExchangeOnlineApp.GLOBAL.clientId,
                    extraQueryParameter: 'nux=1',
                    logging: {
                        level: 3, // level can be set -1,0,1,2 and 3 which turns on 'no log', 'error', 'warning', 'info' or 'verbose' level logging respectively
                        log: LogSvc // object to provide the log to the library, it should implement functions error, warn, info and debug
                    },
                    popUp: true,
                    expireOffsetSeconds: EXPIRE_OFFSET,
                    cacheLocation: 'localstorage',
                    openExternalPopup: null
                };
            }
            return _authenticationContextConfig;
        };

        this.createAuthenticationContext = function (encryptionKey) {
            _authenticationContext = new AuthenticationContext(this.getAuthenticationContextConfig(encryptionKey));
        };

        this.adalGetAuthorizationCode = function (data) {
            !_authenticationContext && this.createAuthenticationContext();

            _authenticationContext && _authenticationContext.setInstance(data.instance);
            _authenticationContext && _authenticationContext.setClientId(data.clientId);

            _getAuthorizationCodeDeferred = $q.defer();
            _authenticationContext.getAuthorizationCode(data.url);
            return _getAuthorizationCodeDeferred.promise;
        };

        this.adalLogin = function (resource, prompt) {
            _loginDeferred = $q.defer();

            if (_authenticationContext) {
                _authenticationContext.login(resource, null, prompt, true);
            } else {
                _loginDeferred.reject('Error on adal login');
            }
            return _loginDeferred.promise;
        };

        this.adalAcquireToken = function (resource) {
            // automated token request call
            var deferred = $q.defer();
            _authenticationContext._renewActive = true;
            _authenticationContext.acquireToken(resource, function (errorDesc, tokenOut, error) {
                _authenticationContext._renewActive = false;
                if (error) {
                    $rootScope.$broadcast('adal:acquireTokenFailure', errorDesc, error);
                    _authenticationContext.error('Error when acquiring token for resource: ' + resource, error);
                    deferred.reject(errorDesc + '|' + error);
                } else {
                    var token = {
                        accessToken: tokenOut,
                        expiry: _authenticationContext.getTokenExpiry(),
                        expireOffsetSeconds: EXPIRE_OFFSET
                    };
                    $rootScope.$broadcast('adal:acquireTokenSuccess', token);
                    LogSvc.debug('[ExchangeConnSvc]: Token: ', obfuscateToken(token.accessToken));
                    deferred.resolve(token);
                }
            });

            return deferred.promise;
        };

        this.updateUserWithLocalContactName = updateUserWithLocalContactName;

        this.getExchangeOnlineApp = getExchangeOnlineApp;

        this.disableAutoConnect = function () {
            saveAutoConnect(false);
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        resetPersonalContactsCache();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.ExchangeConnError = ExchangeConnError;
    circuit.Enums.ExchangeConnAuthentication = ExchangeAuthentication;
    circuit.Enums.Office365Cloud = Office365Cloud;
    circuit.Enums.ExchangeOnlineApp = ExchangeOnlineApp;
    circuit.ExchangeConnSvcImpl = ExchangeConnSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
