var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var CallState = circuit.Enums.CallState;
    var CstaCallState = circuit.Enums.CstaCallState;
    var CallDirection = circuit.Enums.CallDirection;
    var Targets = circuit.Enums.Targets;
    var Utils = circuit.Utils;
    var RoutingOptions = circuit.RoutingOptions;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;

    // eslint-disable-next-line max-params, max-lines-per-function
    function PhoneCallSvcImpl( // NOSONAR
        $rootScope,
        $q,
        $timeout,
        LocalStoreSvc,
        LogSvc,
        PubSubSvc,
        UserProfileSvc,
        CallControlSvc,
        AtcRegistrationSvc,
        PopupSvc) {

        LogSvc.debug('New Service: PhoneCallSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var SEND_DIGIT_TIMEOUT = 7000;         // This timeout is to handle sending DTMF via CSTA

        var _sendDigitsQueue = [];
        var _sendDigitTimeout = null;
        var _that = this;

        var _phoneConversation = null;
        var _telephonyAvailable = false;

        var _defaultCallDevice = null;
        var _callDevices = [];
        var _pushDevices = [];
        var _answerDevices = [];
        var _defaultAnswerDevice = null;
        var _defaultPushDevice = null;

        var _calls = [];
        var _atcCalls = [];
        var _multipleAtcCalls = false;

        var _userToUserHandler = UserToUserHandler.getInstance();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function initPhoneCalls() {
            var phoneCalls = CallControlSvc.getPhoneCalls();
            if (phoneCalls.length > 0) {
                _calls = phoneCalls.slice(0);
            } else {
                _calls = [];
                if (_phoneConversation.call && !_phoneConversation.call.isAtcRemote) {
                    _calls.push(_phoneConversation.call);
                }
            }

            var atcCalls = CallControlSvc.getAtcCalls();
            if (!Utils.isEmptyArray(_calls)) {
                atcCalls = atcCalls.filter(function (call) {
                    return !call.pickupNotification && !(call.atcCallInfo && call.atcCallInfo.isQueuedIncomingCall());
                });
            }
            Array.prototype.push.apply(_calls, atcCalls);

            publishPhoneCallsInitEvent();

            var anyHeldAtcCall = atcCalls.some(function (call) {
                return call.isHolding();
            });
            var anyActiveAtcCall = atcCalls.some(function (call) {
                return call.checkCstaState([CstaCallState.Active, CstaCallState.Conference]);
            });
            _multipleAtcCalls = atcCalls.length > 1 && anyActiveAtcCall && anyHeldAtcCall;
            _atcCalls = atcCalls;

            var newAnswerDevices, newDefaultAnswerDevice;
            if (_phoneConversation.call) {
                // Use first device in list as default device.
                newAnswerDevices = CallControlSvc.getAnswerDevices(_phoneConversation.call.callId);
                newDefaultAnswerDevice = newAnswerDevices.shift();
            } else {
                newAnswerDevices = [];
                newDefaultAnswerDevice = Targets.WebRTC;
            }

            if (newDefaultAnswerDevice === Targets.WebRTC && (hasAnyMultipleLocalCalls() ||
                ($rootScope.localUser.isOsBizCTIEnabled && atcCalls.length > 1 && phoneCalls.length === 0))) {
                // if there are more than one local calls, the 3rd incoming call can only be answered by one of the the other devices.
                // OSBiz only supports multiple calls using the same device. If only remote calls exists, do not offer to answer locally.
                newDefaultAnswerDevice = newAnswerDevices.shift();
            }

            if (newDefaultAnswerDevice !== Targets.WebRTC && newAnswerDevices.length === 0) {
                // If no devices are left in list and local answer is not possible, then include this only answer device in the answer list too
                newAnswerDevices.push(newDefaultAnswerDevice);
            }

            if (!Utils.compareElements(newAnswerDevices, _answerDevices) || newDefaultAnswerDevice !== _defaultAnswerDevice) {
                _defaultAnswerDevice = newDefaultAnswerDevice;
                _answerDevices = newAnswerDevices;
                publishDeviceChangedEvent();
            }
        }

        function getTargetCallDevice(device) {
            if (!device || !device.name) {
                return Targets.WebRTC;
            }
            var targetCallDevice = {};
            switch (device.name) {
            case Targets.WebRTC.name:
                targetCallDevice = Targets.WebRTC;
                break;
            case Targets.Desk.name:
                targetCallDevice = Targets.Desk;
                break;
            case Targets.Cell.name:
                targetCallDevice = Targets.Cell;
                break;
            }
            return _callDevices.indexOf(targetCallDevice) > -1 ? targetCallDevice : Targets.WebRTC;
        }

        function getTargetPushDevice(device) {
            if (!device || !device.name) {
                return _pushDevices[0];
            }
            var targetPushDevice = {};
            switch (device.name) {
            case Targets.Desk.name:
                targetPushDevice = Targets.Desk;
                break;
            case Targets.Cell.name:
                targetPushDevice = Targets.Cell;
                break;
            }
            return _pushDevices.indexOf(targetPushDevice) > -1 ? targetPushDevice : _pushDevices[0];
        }

        function setDefaultCallDevice(device, saveInStorage, dontPublishEvent) {
            if (device && _defaultCallDevice !== device) {
                _defaultCallDevice = device;
                CallControlSvc.setDefaultCallDevice(device);
                if (saveInStorage) {
                    LogSvc.info('[PhoneCallSvc]: Saving default outgoing device in Local Storage:', device);
                    LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.DEFAULT_PHONE_CALL_DEVICE, _defaultCallDevice);
                }
                if (!dontPublishEvent) {
                    publishDeviceChangedEvent();
                }
            }
        }

        function setDefaultPushDevice(device, saveInStorage, dontPublishEvent) {
            if (_defaultPushDevice !== device) {
                // Unlike the _defaultCallDevice, the _defaultPushDevice can be null
                _defaultPushDevice = device;
                if (saveInStorage) {
                    LogSvc.info('[PhoneCallSvc]: Saving default push device in Local Storage:', device);
                    LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.DEFAULT_PUSH_CALL_DEVICE, device);
                }
                if (!dontPublishEvent) {
                    publishDeviceChangedEvent();
                }
            }
        }

        function initDevices() {
            if (!$rootScope.localUser.isATC && circuit.WebRTCAdapter.enabled) {
                // UTC calls can only be made using WebRTC
                _callDevices = [Targets.WebRTC];
                setDefaultCallDevice(Targets.WebRTC);
                return;
            }

            _callDevices = CallControlSvc.getCallDevices();
            _pushDevices = CallControlSvc.getPushDevices();
            var device = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.DEFAULT_PHONE_CALL_DEVICE);
            setDefaultCallDevice(getTargetCallDevice(device), false, true);

            device = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.DEFAULT_PUSH_CALL_DEVICE);
            setDefaultPushDevice(getTargetPushDevice(device), true, true);

            _defaultAnswerDevice = Targets.WebRTC;
            publishDeviceChangedEvent();
        }

        function isNumberDialable(number) {
            if (!number) {
                return false;
            }
            if (!Utils.PHONE_DIAL_PATTERN.test(number)) {
                var match = number.match(Utils.PHONE_WITH_EXTENSION_PATTERN);
                return !!match;
            } else {
                return true;
            }
        }

        function dial(number, name, userId) {
            if (!$rootScope.localUser.callerId) {
                return $q.reject('Telephony not available');
            }

            if (!isNumberDialable(number)) {
                return $q.reject('Number not dialable');
            }

            // Split dialed number from DTMF digits
            var split = number.split(',');
            var dialedNumber = split.shift();
            var dtmfDigits = split.join(',');
            if (!dialedNumber) {
                return $q.reject('No number to dial');
            }

            var destination = {
                dialedDn: dialedNumber,
                toName: name,
                userId: userId,
                mediaType: {audio: true, video: false, desktop: false}
            };

            if (dtmfDigits) {
                destination.dtmfDigits = Utils.cleanPhoneNumberDigitsWithPin(dtmfDigits);
            }

            var deferred = $q.defer();
            CallControlSvc.dialUsingDefaultDevice(destination, handleCallControlCb(deferred));

            return deferred.promise;
        }

        function canDialfromAlternativeNumber() {
            return !!($rootScope.localUser.reroutingPhoneNumber &&
                      (!$rootScope.localUser.isOsBizCTIEnabled || $rootScope.localUser.selectedRoutingOption === RoutingOptions.AlternativeNumber.name));
        }

        function getDevice(deviceName) {
            return Object.values(Targets).find(function (target) {
                return target.name === deviceName;
            });
        }

        function dialFromDevice(deviceName, number, name, userId) {
            var device = getDevice(deviceName);
            if (device && device !== _defaultCallDevice) {
                setDefaultCallDevice(device, true);
            }

            if (!isNumberDialable(number)) {
                return $q.reject('Number not dialable');
            }

            device = device || (_phoneConversation.call && _phoneConversation.call.getPosition()) || _defaultCallDevice;

            if (device === Targets.WebRTC) {
                return dial(number, name, userId);
            }

            LogSvc.info('[PhoneCallSvc]: Make call from ' + device.name + ' to ', number);

            // Split dialed number from DTMF digits
            var split = number.split(',');
            var destination = {
                dialedDn: split.shift(),
                dtmfDigits: split.join(',')
            };

            var deferred = $q.defer();
            CallControlSvc.makeAtcCall(device, destination, handleCallControlCb(deferred));
            if (_phoneConversation.call) {
                _phoneConversation.consultation = false;
            }
            return deferred.promise;
        }

        function checkDisclaimer(fn) {
            // Check if disclaimer needs to be displayed
            var disclaimerAccepted = UserProfileSvc.getSetting(Constants.UserSettingKey.EMERGENCY_CALL_DISCLAIMER);
            if (disclaimerAccepted && disclaimerAccepted.booleanValue) {
                return fn();
            }

            if (!PopupSvc) {
                return $q.reject('res_EmergencyCallsDisclaimer');
            }

            return new $q(function (resolve, reject) {
                PopupSvc.confirm({
                    title: 'res_EmergencyCallsDisclaimerTitle',
                    message: 'res_EmergencyCallsDisclaimer',
                    size: 'small',
                    yesLabel: 'res_Accept',
                    noLabel: null
                })
                .result
                .then(function () {
                    LogSvc.debug('[PhoneCallSvc]: User has accepted the Emergency Calls Disclaimer');
                    UserProfileSvc.saveSetting(Constants.UserSettingKey.EMERGENCY_CALL_DISCLAIMER, true);

                    fn()
                    .then(resolve)
                    .catch(reject);
                })
                .catch(function () {
                    LogSvc.debug('[PhoneCallSvc]: User has rejected the Emergency Calls Disclaimer');
                    reject();
                });
            });
        }

        function pushLocalCall(callId, deviceName) {
            var call = getCall(callId);
            if (!call) {
                return $q.reject('Call not found');
            }
            var device = getDevice(deviceName);
            if (!device) {
                return $q.reject('Invalid device name');
            }
            if (device !== _defaultPushDevice) {
                setDefaultPushDevice(device, true);
            }
            LogSvc.info('[PhoneCallSvc]: Push call with ' + call.peerDn + ' to ' + device.name);
            var deferred = $q.defer();
            CallControlSvc.pushLocalCall(call.callId, device.name, handleCallControlCb(deferred));
            return deferred.promise;
        }

        function hasCircuitActiveCall() {
            var localActiveCall = CallControlSvc.getActiveCall();
            return !!(localActiveCall && localActiveCall.checkState([CallState.Active, CallState.Waiting]) && !localActiveCall.isTelephonyCall);
        }

        function multiplePhoneCalls(call) {
            return (call.isAtcRemote && _multipleAtcCalls) || (!call.isRemote && _that.hasMultipleLocalCalls());
        }

        function hasAnyMultipleLocalCalls() {
            var localCalls = CallControlSvc.getPhoneCalls(true);
            var anyActiveCall = localCalls.some(function (call) {
                return call.checkState(CallState.Active);
            });
            var anyHeldCall = localCalls.some(function (call) {
                return call.isHolding();
            });
            var twoHeldCalls = localCalls.every(function (call) {
                return call.isHolding();
            });
            return localCalls.length > 1 && (anyActiveCall && anyHeldCall || twoHeldCalls);
        }

        function canConsultCall(call) {
            return _calls.length === 1 &&
                (call.isConsultAllowed() || ($rootScope.localUser.isOsBizCTIEnabled && call.isAtcRemote && call.isMakeCallAllowed()));
        }

        function isCallOnHold(callId) {
            var call = getCall(callId);
            return call && (!call.isRemote || call.isAtcRemote) && call.isHolding() && !multiplePhoneCalls(call);
        }

        function isOsBizTwoRemoteCalls() {
            return $rootScope.localUser.isOsBizCTIEnabled && _calls.length > 1 &&
                _calls.some(function (call) {
                    return call.isRemote && !call.isAtcRemote;
                });
        }

        function isOsbizCampOnRingingCall(call) {
            return call && $rootScope.localUser.isOsBizCTIEnabled &&
                (call.isOsBizSecondCall || (call.isAtcRemote && _calls.length > 1)) &&
                call.direction === CallDirection.INCOMING && call.checkState(CallState.Ringing);
        }

        function canAnswerOsbizCall(call) {
            return !$rootScope.localUser.isOsBizCTIEnabled || call.getPosition() !== Targets.Cell || _defaultAnswerDevice === Targets.VM;
        }

        function isDeviceAvailableForAnswer(deviceName) {
            return (_defaultAnswerDevice && _defaultAnswerDevice.name === deviceName) || _answerDevices.some(function (d) { return d.name === deviceName; });
        }

        function isOsbizRecalledCall(call) {
            return call && call.atcCallInfo && call.atcCallInfo.isRecalledCall();
        }

        function getCall(callId) {
            return _calls.find(function (c) { return c.callId === callId; });
        }

        function handleCallControlCb(deferred) {
            return function (err, warn, call) {
                err ? deferred.reject(err) : deferred.resolve(call);
            };
        }

        function publishDeviceChangedEvent() {
            var devices = {
                defaultCallDevice: _defaultCallDevice,
                callDevices: _callDevices,
                pushDevices: _pushDevices,
                answerDevices: _answerDevices,
                defaultAnswerDevice: _defaultAnswerDevice,
                defaultPushDevice: _defaultPushDevice
            };
            LogSvc.debug('[PhoneCallSvc]: Publishing /telephony/callDevices/changed event', devices);
            PubSubSvc.publish('/telephony/callDevices/changed', [devices]);
        }

        function publishPhoneCallsInitEvent() {
            LogSvc.debug('[PhoneCallSvc]: Publishing /telephony/phoneCalls/init event');
            PubSubSvc.publish('/telephony/phoneCalls/init');
        }

        function setTelephonyAvailable(newStatus) {
            newStatus = !!newStatus;

            if (newStatus !== _telephonyAvailable) {
                _telephonyAvailable = newStatus;
                LogSvc.debug('[PhoneCallSvc]: Publishing /telephony/available event: ', _telephonyAvailable);
                PubSubSvc.publish('/telephony/available', [_telephonyAvailable]);
            }
        }

        function canMergeCall(call) {
            return !!call && call.isConferenceCallAllowed() && multiplePhoneCalls(call);
        }

        function canSwapCall(call) {
            return !!(call && multiplePhoneCalls(call) && (!call.isRemote || call.isAtcRemote) && call.isHolding() && !call.isHandoverInProgress);
        }

        function onSendDigitError(digit) {
            // If using CSTA to send digits, sometimes the sendDigits request fails and/or the DTMF event is not
            // generated, so we have to fail not only the last digit sent, but also the ones in the queue.
            // Tell the user the digits that failed so they can enter them again
            if (_phoneConversation.call) {
                digit = _sendDigitsQueue.join(' ');
                _sendDigitsQueue = [];
                PubSubSvc.publish('/call/sendDtmf/failed', ['res_SendDtmfFailed', digit]);
            }
        }

        function processNextDigit() {
            if (_sendDigitsQueue.length > 0) {
                var digit = _sendDigitsQueue[0];
                CallControlSvc.sendDigits(_phoneConversation.call.callId, digit, function (err) {
                    if (err) {
                        if (_sendDigitTimeout) {
                            $timeout.cancel(_sendDigitTimeout);
                            _sendDigitTimeout = null;
                        }
                        onSendDigitError(digit);
                    }
                });
                if (_sendDigitTimeout) {
                    $timeout.cancel(_sendDigitTimeout);
                }
                _sendDigitTimeout = $timeout(function () {
                    _sendDigitTimeout = null;
                    LogSvc.warn('[PhoneCallSvc]: Timed out entering digit: ' + digit + '. User will have to re-enter digits:', _sendDigitsQueue);
                    onSendDigitError(digit);
                }, SEND_DIGIT_TIMEOUT);
            }
        }

        function isOsBizPushPullAllowed() {
            return !$rootScope.localUser.isOsBizCTIEnabled ||
                (_calls.length < 2 && $rootScope.localUser.selectedRoutingOption !== RoutingOptions.AlternativeNumber.name);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // User To User Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _userToUserHandler.on('ATC.TALK', function (data) {
            LogSvc.debug('[PhoneCallSvc]: Received UserToUser ATC.TALK');
            // Iphone app uses Apple CallKit. Apparently CallKit does not allow auto answering
            // an incoming call.
            if (Utils.isIOS()) {
                LogSvc.debug('[PhoneCallSvc]: ATC.TALK event is not supported on iOS devices');
                return;
            }

            try {
                _that.answerCall(data.callId, Targets.WebRTC, false)
                .then(function () {
                    PubSubSvc.publish('/conversation/navigate', [_phoneConversation]);
                })
                .catch(function (err) {
                    LogSvc.warn('[PhoneCallSvc]: Failed to answer call. ', err);
                });
            } catch (e) {
                LogSvc.error('[PhoneCallSvc]: Exception handling UserToUser ATC.TALK event. ', e);
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/conversations/showTelephonyConversation', function (show, conv) {
            LogSvc.debug('[PhoneCallSvc]: Received /conversations/showTelephonyConversation event. show = ', show);
            if (show) {
                _phoneConversation = conv;
                var data = AtcRegistrationSvc.getTelephonyData();
                setTelephonyAvailable(data.phoneCallsAvailable);
                initDevices();
                initPhoneCalls();
            }
        });

        PubSubSvc.subscribe('/csta/deviceChange', function () {
            LogSvc.debug('[PhoneCallSvc]: Received /csta/deviceChange');
            initDevices();
        });

        PubSubSvc.subscribe('/call/ended', function (call) {
            if (call && _phoneConversation && call.convId === _phoneConversation.convId && !call.isHandoverInProgress) {
                LogSvc.debug('[PhoneCallSvc]: Received /call/ended event for telephony call');
                initPhoneCalls();
            }
        });

        PubSubSvc.subscribe('/conversation/update', function (c) {
            if (_phoneConversation && _phoneConversation.convId === c.convId) {
                LogSvc.debug('[PhoneCallSvc]: Received /conversation/update event');
                _phoneConversation = c;
                initPhoneCalls();
            }
        });

        PubSubSvc.subscribe('/telephony/data', function (data) {
            LogSvc.debug('[PhoneCallSvc]: Received /telephony/data event');
            setTelephonyAvailable(data.phoneCallsAvailable);
        });

        PubSubSvc.subscribe('/call/singleDigitSent', function (call, digit) {
            LogSvc.debug('[PhoneCallSvc]: Received /call/singleDigitSent event');
            var allDigitsSent = false;
            var digits = _sendDigitsQueue[0];
            digit = digit || digits; // If no digits are provided in the event, assume it's the last we sent
            if (digits === digit) {
                allDigitsSent = true;
            } else if (digits[0] === digit) {
                // We're processing one digit at a time
                digits = digits.slice(1);
                if (!digits) {
                    allDigitsSent = true;
                } else {
                    // This entry from the queue still has digits to be sent
                    _sendDigitsQueue[0] = digits;
                }
            } else {
                LogSvc.error('[PhoneCallSvc]: Received unexpected digit(s)');
            }

            if (allDigitsSent) {
                // All digits have been sent, remove this entry from the queue
                _sendDigitsQueue.splice(0, 1);
                if (_sendDigitTimeout) {
                    $timeout.cancel(_sendDigitTimeout);
                    _sendDigitTimeout = null;
                }
                processNextDigit();
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Returns object with all devices configured and available for telephony calls.
         *
         * @returns {Object} Keys:
         * defaultCallDevice {Enums.Targets}: Default device when making calls.
         * callDevices {Enums.Targets[]}: Array with devices that can be used for making calls.
         * pushDevices {Enums.Targets[]}: Array with devices where calls can be pushed to.
         * answerDevices {Enums.Targets[]}: Array with devices that can answer calls.
         * defaultAnswerDevice {Enums.Targets}: Default device when answering calls.
         * defaultPushDevice {Enums.Targets}: Default device when pushing calls.
         */
        this.getDevices = function () {
            return {
                defaultCallDevice: _defaultCallDevice,
                callDevices: _callDevices,
                pushDevices: _pushDevices,
                answerDevices: _answerDevices,
                defaultAnswerDevice: _defaultAnswerDevice,
                defaultPushDevice: _defaultPushDevice
            };
        };

        /**
         * Returns an array of call objects with all current phone calls (local and remote).
         * @returns {Array}
         */
        this.getPhoneCalls = function () { return _calls; };

        /**
         * Returns the Telephony conversation.
         * @returns {Object}
         */
        this.getPhoneConversation = function () { return _phoneConversation; };

        /**
         * Returns the local user private and public phone numbers. In some deployments, the user can have both or either one.
         * @returns {Object} Keys:
         * public: public phone number.
         * private: private phone number (UCaaS deployments).
         */
        this.getMyPhoneNumbers = function () {
            var osmoData = $rootScope.localUser.isOsBizCTIEnabled && AtcRegistrationSvc.getAtcRegistrationData();
            return {
                'public': (osmoData && osmoData.onsFQN) || $rootScope.localUser.phoneNumber
            };
        };

        /**
         * Dials the specified phone number through the last device used to dial. You may use {@link canInitiatePhoneCall} to check if dialing is allowed.
         * @param {String} number Number to be dialed.
         * @param {String} [name] Name of the user being dialed.
         * @param {String} [userId] ID of the user being dialed.
         * @returns {Promise} Fulfillment handler receives the created local call object only if the device is 'webRTC', otherwise no call object is returned.
         * Rejection handler receives an error object.
         */
        this.dial = dial;

        /**
         * Checks whether user has accepted Emergency call disclaimer and dials the specified phone number through the last device used to dial.
         * @param {String} number Number to be dialed.
         * @param {String} [name] Name of the user being dialed.
         * @param {String} [userId] ID of the user being dialed.
         * @returns {Promise} Fulfillment handler receives the created local call object only if the device is 'webRTC', otherwise no call object is returned.
         * Rejection handler receives an error object.
         */
        this.checkDisclaimerAndDial = function (number, name, userId) {
            var fn = dial.bind(this, number, name, userId);
            return checkDisclaimer(fn);
        };

        /**
         * Dials the a phone number using the specified device. You may use {@link canDialFromDevice} to check if dialing is allowed.
         * @param {String} deviceName Name of the device (see {enum.Target}) to be dialed from.
         * @param {String} number Number to be dialed.
         * @param {String} [name] Name of the user being dialed.
         * @param {String} [userId] ID of the user being dialed.
         * @returns {Promise} Fulfillment handler receives the created local call object only if the device is 'webRTC', otherwise no call object is returned.
         * Rejection handler receives an error object.
         */
        this.dialFromDevice = dialFromDevice;

        /**
         * Checks whether user has accepted Emergency call disclaimer and dials the a phone number using the specified device.
         * @param {String} deviceName Name of the device (see {enum.Target}) to be dialed from.
         * @param {String} number Number to be dialed.
         * @param {String} [name] Name of the user being dialed.
         * @param {String} [userId] ID of the user being dialed.
         * @returns {Promise} Fulfillment handler receives the created local call object only if the device is 'webRTC', otherwise no call object is returned.
         * Rejection handler receives an error object.
         */
        this.checkDisclaimerAndDialFromDevice = function (deviceName, number, name, userId) {
            var fn = dialFromDevice.bind(this, deviceName, number, name, userId);
            return checkDisclaimer(fn);
        };

        /**
         * Pushes a local call to the specified device. You may use {@link canPushLocalCall} to check if push operation is allowed.
         * @param {String} callId ID of the local call to be pushed.
         * @param {String} deviceName Name of the device (see {enum.Target}) where the call will be pushed to.
         * @returns {Promise} Fulfillment handler receives the created remote call object. Rejection handler receives an error object.
         */
        this.pushLocalCall = pushLocalCall;

        /**
         * Alternates between two calls (1 held and 1 active). These calls can be both local or remote. You may use {@link canSwapCall} to check if swap operation is allowed.
         * @param {String} [callId] ID of the held call to be swapped. If not supplied, we'll try to find the held call.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.swapCall = function (callId) {
            var deferred = $q.defer();
            if (!callId) {
                if (_calls.length > 1) {
                    var heldCall = _calls.find(function (call) {
                        // Return first held call that we find
                        return call.isHolding();
                    });
                    if (heldCall) {
                        callId = heldCall.callId;
                    } else if (!$rootScope.localUser.isOsBizCTIEnabled) {
                        return $q.reject('No calls to swap');
                    }
                } else {
                    return $q.reject('No calls to swap');
                }
            }
            CallControlSvc.swapCall(callId, function (err) {
                err ? deferred.reject(err) : deferred.resolve();
            });
            return deferred.promise;
        };

        /**
         * Conferences in (merges) two calls (local or remote). You may use {@link canMergeCall} to check if merge calls is allowed.
         * @param {String} [callId] ID of the active call to be merged. If not supplied, we'll try to find it.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.mergeCall = function (callId) {
            var deferred = $q.defer();
            if (!callId) {
                var remoteCall = _atcCalls.find(function (call) {
                    return call.checkCstaState([CstaCallState.Active, CstaCallState.Conference]);
                });
                if (remoteCall) {
                    callId = remoteCall.callId;
                } else {
                    var localCall = _calls.find(function (call) {
                        return call.isSTC ? call.checkState(CallState.Active) : call.checkCstaState([CstaCallState.Active, CstaCallState.Conference]);
                    });
                    if (localCall) {
                        callId = localCall.callId;
                    } else {
                        return $q.reject('No call to be merged found');
                    }
                }
            }
            CallControlSvc.mergeCall(callId, function (err) {
                err ? deferred.reject(err) : deferred.resolve();
            });
            return deferred.promise;
        };

        /**
         * Transfers call (local or remote). There are 2 types of transfer: attended and unattended. Attended transfer happens when you first initiate
         * a consultation call then invoke the transfer. Unattended is when no consultation call is made. You may use {@link canTransferCall}
         * to check if transfer is allowed for a particular call.
         * @param {String} callId ID of the active call (in attended transfers, it's the consultation call).
         * @param {String} [number] Phone number of transfer destination. It's required only for unattended transfers.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.transferCall = function (callId, number) {
            return new $q(function (resolve, reject) {
                CallControlSvc.transferCall(callId, number, function (err) {
                    err ? reject(err) : resolve();
                });
            });
        };

        /**
         * Holds a call (local or remote). You may use {@link canHoldCall} to check if hold is allowed.
         * @param {String} callId ID of the call to be held.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.holdCall = function (callId) {
            return new $q(function (resolve, reject) {
                CallControlSvc.holdCall(callId, function (err) {
                    err ? reject(err) : resolve();
                });
            });
        };

        /**
         * Retrieves a held call (local or remote). You may use {@link canRetrieveCall} to check if retrieve is allowed.
         * @param {String} callId ID of the held call to be retrieved.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.retrieveCall = function (callId) {
            return new $q(function (resolve, reject) {
                CallControlSvc.retrieveCall(callId, function (err) {
                    err ? reject(err) : resolve();
                });
            });
        };

        /**
         * Ignore a pickup call notification. Use this method to dismiss a call pickup notification issue for the call pickup group.
         * @param {String} callId The call ID of the call from the pickup notification.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.ignoreCall = function (callId) {
            return new $q(function (resolve, reject) {
                CallControlSvc.ignoreCall(callId, function (err) {
                    err ? reject(err) : resolve();
                });
            });
        };

        /**
         * Toggle between mute and unmute.
         * @param {String} callId ID of the call to toggle mute.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.toggleMute = function (callId) {
            return new $q(function (resolve, reject) {
                CallControlSvc.toggleMute(callId, function (err) {
                    err ? reject(err) : resolve();
                });
            });
        };

        /**
         * Returns if an operation is in progress on any call or not (local or remote). Use this method to display a progress indicator (e.g.: spinner).
         * @returns {Boolean} true if there's an operation is progress, false otherwise.
         */
        this.hasAnyCallInTransientState = function () {
            var localCalls = CallControlSvc.getPhoneCalls(true);
            var hasLocal = localCalls.some(function (call) {
                return call.isHoldInProgress() || call.isRetrieveInProgress() || call.isHandoverInProgress;
            });
            if (!hasLocal) {
                var remoteCalls = CallControlSvc.getAtcCalls();
                return remoteCalls.some(function (call) {
                    return call.isHoldInProgress() || call.isRetrieveInProgress() || call.isHandoverInProgress;
                });
            } else {
                return true;
            }
        };

        /**
         * Returns if there are more than 1 local call or not. Currently Circuit only supports at most 2 local phone calls.
         * @param {String} includeDelivered Include outgoing calls in Delivered state.
         * @returns {Boolean} true if there are more than 1 local call, false otherwise.
         */
        this.hasMultipleLocalCalls = function (includeDelivered) {
            var localCalls = CallControlSvc.getPhoneCalls(true);
            if (localCalls.length < 2) {
                return false;
            }
            var cstaCallStates = [CstaCallState.Active, CstaCallState.Conference];
            var callStates = $rootScope.localUser.isSTC ? [CallState.Active] : [];
            if (includeDelivered) {
                cstaCallStates.push(CstaCallState.Delivered);
                callStates.push(CallState.Delivered);
            }
            var anyActiveCall = localCalls.some(function (call) {
                return call.checkCstaState(cstaCallStates) || call.checkState(callStates);
            });
            var anyHeldCall = localCalls.some(function (call) {
                return call.isHolding();
            });
            return anyActiveCall && anyHeldCall;
        };

        /**
         * Returns if merge call feature is available on the system. If false, merge is not possible for any call.
         * @returns {Boolean} true if merge is available, false otherwise. If true, make sure to also check {@link canMergeCall}.
         */
        this.isMergeCallAvailable = function () {
            return !!($rootScope.localUser.isATC || ($rootScope.localUser.stcCapabilities &&
                $rootScope.localUser.stcCapabilities.includes(Constants.StcCapabilities.STC_MERGE)));
        };

        /**
         * Returns if consultation call feature is available on the system. If false, consultation is not possible for any call.
         * @returns {Boolean} true if consultation is available, false otherwise. If true, make sure to also check {@link canInitiateConsultationLocalCall}.
         * and {@link canInitiateConsultationLocalCall}
         */
        this.isConsultationAvailable = function () {
            return !!$rootScope.localUser.isRegisterTC;
        };

        /**
         * Returns the list of the available features on the system that depends on the telephony connector of the user
         * @returns {Array}
         */
        this.getAvailableFeatureList = function () {
            var featureList = [];
            if ($rootScope.localUser.isRegisterTC) {
                featureList.push(Constants.TcAvailableFeatures.HOLD, Constants.TcAvailableFeatures.CONSULTATION);
            }
            if ($rootScope.localUser.isATC) {
                featureList.push(Constants.TcAvailableFeatures.TRANSFER, Constants.TcAvailableFeatures.MERGE);
            } else if ($rootScope.localUser.stcCapabilities) {
                if ($rootScope.localUser.stcCapabilities.includes(Constants.StcCapabilities.STC_TRANSFER)) {
                    featureList.push(Constants.TcAvailableFeatures.TRANSFER);
                }
                if ($rootScope.localUser.stcCapabilities.includes(Constants.StcCapabilities.STC_MERGE)) {
                    featureList.push(Constants.TcAvailableFeatures.MERGE);
                }
            }
            return featureList;
        };

        /**
         * Returns if hangup (end) call feature is available for the specified call. In some switches, ending a remote call is not available.
         * @returns {Boolean} true if hangup is available, false otherwise. If true, make sure to also check {@link canHangupCall}.
         */
        this.isHangupCallAvailable = function (callId) {
            var call = getCall(callId);
            return !!(call && (!call.isRemote || (call.isAtcRemote && !isOsBizTwoRemoteCalls())) && !call.pickupNotification);
        };

        /**
         * Checks if the specified outgoing call failed because the destination was busy or not. Use this method to display a busy indication.
         * This call will be automatically terminated after a few seconds.
         * @param {String} callId ID of the call to be checked.
         * @returns {Boolean} true if busy, false otherwise.
         */
        this.isCallBusy = function (callId) {
            var call = getCall(callId);
            return !!(call && (call.checkState(CallState.Busy) || call.isOutgoingCallCampedOn));
        };

        /**
         * Returns if local call is active or not. Use this method to check if consulation/transfer and merge calls (when applicable) should be offered to the user.
         * @param {String} [callId] ID of the call to be checked.
         * @returns {Boolean} true if active, false otherwise
         */
        this.isLocalCallActive = function (callId) {
            return _calls.some(function (call) {
                return !!((!callId || callId === call.callId) && !call.isRemote && !call.checkState([CallState.Ringing, CallState.Waiting]) &&
                    !call.isHandoverInProgress);
            });
        };

        /**
         * Returns if remote call is active or not. Use this method to check if consultation/transfer and merge calls (when applicable) should be offered to the user.
         * @param {String} [callId] ID of the call to be checked.
         * @returns {Boolean} true if active, false otherwise.
         */
        this.isRemoteCallActive = function (callId) {
            return _atcCalls.some(function (call) {
                return !!((!callId || callId === call.callId) && call.isAtcRemote && call.checkState([CallState.ActiveRemote, CallState.Initiated, CallState.Delivered]) &&
                    !call.isHandoverInProgress);
            });
        };

        /**
         * Returns if Telephony calls can be made and received. Network connection and/or Telephony Connector status affect
         * the Telephony availability on the client. Use this method to enable/disable call operations in general.
         * @returns {Boolean} true if available, false otherwise.
         */
        this.isTelephonyAvailable = function () { return _telephonyAvailable; };

        /**
         * Returns if there are more than 1 remote call in progress or not.
         * @returns {Boolean} true if in progress, false otherwise.
         */
        this.hasMultipleRemoteCalls = function () {
            return _atcCalls.length > 1;
        };

        /**
         * Returns if merge calls is possible or not. Use this method to show/hide the merge calls option.
         * @param {String} [callId] ID of the active call to be merged. If not supplied, we'll try to find it.
         * @returns {Boolean} true if possible, false otherwise (or the active call couldn't be found).
         */
        this.canMergeCall = function (callId) {
            if (callId) {
                var call = getCall(callId);
                return canMergeCall(call);
            }
            // Check all calls
            return _calls.some(function (c) {
                return canMergeCall(c);
            });
        };

        /**
         * Returns if the consultation call can be transferred or not. Use this method to show/hide the transfer call option.
         * @param {String} callId ID of the consultation call (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canConsultTransfer = function (callId) {
            var call = getCall(callId);
            return !!(!!call && (_calls.length === 2 && (!call.isHolding() || call.isOsBizFirstCall || call.isOsBizSecondCall) &&
                (call.isTransferCallAllowed() || call.checkState(CallState.Delivered))));
        };

        /**
         * Returns if an unattended transfer call is possible or not. Use this method to show/hide the unattended option.
         * @param {String} callId ID of the active call (local or remote).
         * @returns {Boolean} true if possible, false otherwise
         */
        this.canTransferCall = function (callId) {
            var call = getCall(callId);
            return !!call && _calls.length === 1 && !call.isHolding() && call.isTransferAllowed();
        };

        /**
         * Returns if an incoming ringing call can be picked up or not. Call pickup is a feature where members of a team (call pickup group)
         * can pickup a ringing call from any member of the team. Note that if call pickup is possible, {@link canAnswerCall} returns false (except for the
         * team member that actually received the incoming call).
         * @param {String} callId ID of the ringing call.
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canPickupCall = function (callId) {
            var call = getCall(callId);
            return !!(call && call.pickupNotification && !call.isHandoverInProgress);
        };

        /**
         * Returns if local call can be pushed to another device or not. Use this method to show/hide the push call option.
         * @param {String} callId ID of the local call.
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canPushLocalCall = function (callId) {
            var call = getCall(callId);
            return !!(call && $rootScope.localUser.isATC && _defaultPushDevice && !call.isAtcRemote &&
                !call.isRemote && call.isHandoverAllowed && isOsBizPushPullAllowed());
        };

        /**
         * Returns if it's possible to dial from the specified device or not. Use this method to show which devices can be used to dial.
         * @param {String} deviceName Name of the device (WebRTC, Cell, Desk or VM).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canDialFromDevice = function (deviceName) {
            return !!(!!deviceName && (deviceName !== Targets.Cell.name || canDialfromAlternativeNumber()) &&
                deviceName !== Targets.VM.name);
        };

        /**
         * Returns if it's possible to hold a call or not. The call can be local or remote.
         * Use this method to show/hide the hold call option. Note that you should not invoke {@link holdCall} if
         * {@link hasAnyCallInTransientState} returns true.
         * @param {String} callId ID of the call (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canHoldCall = function (callId) {
            var call = getCall(callId);
            if (!call) {
                return false;
            }
            if ($rootScope.localUser.isOsBizCTIEnabled) {
                return !!(_calls.length < 2 && call.isConsultAllowed() && (!call.isRemote || call.isAtcRemote));
            }
            if ($rootScope.localUser.isSTC) {
                return !call.isRemote && call.checkState([CallState.Active, CallState.Held]);
            }
            return !!($rootScope.localUser.isATC && (!call.isRemote || call.isAtcRemote) && call.isHoldAllowed());
        };

        /**
         * Returns if it's possible to hangup a call or not. The call can be local or remote.
         * Use this method to show/hide the end call option. Note that you should not invoke {@link endCall} if
         * {@link hasAnyCallInTransientState} returns true.
         * @param {String} callId ID of the call to be terminated (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canHangupCall = function (callId) {
            if (_that.isHangupCallAvailable(callId)) {
                var call = getCall(callId);
                // Don't allow hangup if we're in the middle of an operation...
                // ...or if OsBiz doesn't allow it
                return !!(call && !_that.hasAnyCallInTransientState() &&
                    (!$rootScope.localUser.isOsBizCTIEnabled ||
                     (!_that.canSwapCall(call.callId) && !_that.canRetrieveCall(call.callId) && !call.isHolding() &&
                      !isOsbizCampOnRingingCall(call) && !isOsbizRecalledCall(call))));
            }
            return false;
        };

        /**
         * Returns if it's possible to initiate a telephony call or not. Use this method to show/hide the initiate call option to the user.
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canInitiatePhoneCall = function () {
            return !!(_telephonyAvailable && ($rootScope.webRtcEnabled || ($rootScope.localUser.isATC && _callDevices.length > 0)));
        };

        /**
         * Returns the number of devices that can be used to initiate a telephony call.
         * @returns {Number} Number of available devices (0 to 3 devices).
         */
        this.getNumOfAvailableCallDevices = function () {
            if ($rootScope.localUser.isATC && _phoneConversation && _phoneConversation.call && _phoneConversation.call.consultation && _callDevices.length > 0) {
                return 1;
            }
            return _callDevices.length;
        };

        /**
         * Returns if a remote consultation call can be made or not. Use this method to show/hide the option of initiating a remote consultation call.
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canInitiateConsultationRemoteCall = function () {
            return !!(_phoneConversation && _that.isConsultationAvailable() && _atcCalls.length === 1 && _phoneConversation.call &&
                !_phoneConversation.call.consultation && canConsultCall(_phoneConversation.call));
        };

        /**
         * Returns if a local consultation call can be made or not. Use this method to show/hide the option of initiating a local consultation call.
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canInitiateConsultationLocalCall = function () {
            return !!(_phoneConversation && _that.isConsultationAvailable() && _calls.length === 1 && _phoneConversation.call && !_phoneConversation.call.consultation &&
                    _phoneConversation.call.state.established && canConsultCall(_phoneConversation.call));
        };

        /**
         * Returns if a call can be answered or not. Use this method to show/hide the option of answering an incoming call.
         * @param {String} callId ID of the call to be answered (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canAnswerCall = function (callId) {
            var call = getCall(callId);
            if (!call) {
                return false;
            }

            if (call.checkState(CallState.Ringing)) {
                if ($rootScope.localUser.isATC) {
                    return !call.isHandoverInProgress && canAnswerOsbizCall(call);
                } else {
                    return !_that.hasMultipleLocalCalls();
                }
            }
            return false;
        };

        /**
         * Returns if a call can be answered on the specified device or not. Use this method to show which devices can be used to answer the call.
         * @param {String} deviceName Name of the device (WebRTC, Cell, Desk or VM).
         * @param {String} callId ID of the call to be answered (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canAnswerOnDevice = function (deviceName, callId) {
            var call = getCall(callId);
            return !!(call && ((deviceName && isDeviceAvailableForAnswer(deviceName)) || (!deviceName && call.getPosition() !== Targets.Cell)));
        };

        /**
         * Returns if a call can be answered locally.
         * @param {String} callId ID of the call to be answered (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canAnswerLocally = function (callId) {
            return _that.canAnswerOnDevice(Targets.WebRTC.name, callId);
        };

        /**
         * Returns if a remote call be pulled by this Circuit client or not. Use this method to show/hide the option of pulling the remote call.
         * @param {String} callId ID of the remote call to be pulled.
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canPullRemoteCall = function (callId) {
            var call = getCall(callId);
            return !!(call && call.isPullAllowed && !hasAnyMultipleLocalCalls() && isOsBizPushPullAllowed());
        };

        /**
         * Returns if swapping 2 calls (1 active and 1 held) is possible or not. Use this method to show/hide the swap option on the held call.
         * @param {String} [callId] ID of the held call (local or remote). If not supplied, we'll try to find the held call.
         * @returns {Boolean} true if possible, false otherwise (or the held call could not be found).
         */
        this.canSwapCall = function (callId) {
            if (callId) {
                var call = getCall(callId);
                return canSwapCall(call);
            }
            // Check all calls
            return _calls.some(function (c) {
                return canSwapCall(c);
            });
        };

        /**
         * Returns if a held call can be retrieved or not. Use this method to show/hide the option of retrieving the call to the user.
         * @param {String} callId ID of the call to be retrieved (local or remote).
         * @returns {Boolean} true if possible, false otherwise.
         */
        this.canRetrieveCall = function (callId) {
            var call = getCall(callId);
            if (!call) {
                return false;
            }
            // if ($rootScope.localUser.isOsBizCTIEnabled) {
            //     return _calls.length < 2 && call.isHolding() &&
            //         ((call.isReconnectAllowed() && (!call.isRemote || call.getPosition() === Targets.Cell)) || (call.isRetrieveAllowed() && call.isAtcRemote));
            // }
            if ($rootScope.localUser.isSTC) {
                return !multiplePhoneCalls(call) && call.isHolding();
            }
            return !multiplePhoneCalls(call) && isCallOnHold(callId) && call.isRetrieveAllowed();
        };

        /**
         * Answers a local or remote call on the specified device.
         * @param {String} callId ID of the call to be answered (local or remote).
         * @param {String} [deviceName=previoulsly set device] Name of the device (WebRTC, Cell, Desk or VM).
         * @param {Boolean} [endActiveCall] If false, the deviceName is 'WebRTC' and there is a local active call, a rejected promised will be
         *                                returned indicating the user need to be prompted to ask what to do with the local call.
         *                                If true, the current active call will be ended before answering the incoming call.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.answerCall = function (callId, deviceName, endActiveCall) {
            var call = getCall(callId);
            if (!call) {
                return $q.reject('Call not found');
            }
            var device = getDevice(deviceName) || this.defaultAnswerDevice;
            var deferred = $q.defer();

            if (device === Targets.WebRTC) {
                if (endActiveCall) {
                    // Terminate the active call (if there is one), before answering the call
                    CallControlSvc.endActiveCall(function () {
                        CallControlSvc.answerCallOnDevice(callId, {audio: true}, device, handleCallControlCb(deferred));
                    });
                    return deferred.promise;
                } else if (CallControlSvc.cannotHandleSecondCall()) {
                    // Cannot handle a second call. Prompt user to terminate the active call.
                    deferred.reject('res_TerminateActiveCall');
                    return deferred.promise;
                }
            }

            // Answer call on device
            CallControlSvc.answerCallOnDevice(callId, {audio: true}, device, handleCallControlCb(deferred));
            return deferred.promise;
        };

        /**
         * Initiates the process of making a consultation call. Use this method to show any special UI to initiate a consultation call.
         * @returns {void}
         */
        this.initiateConsultation = function (callId) {
            var call = getCall(callId);
            if (call) {
                call.consultation = true;
                PubSubSvc.publish('/consultation/status/changed', [call]);
            }
        };

        /**
         * Cancels the process of making a consultation call. Use this method to hide any special UI to initiate a consultation call.
         * @returns {void}
         */
        this.cancelConsultation = function (callId) {
            var call = getCall(callId);
            if (call) {
                call.consultation = false;
                PubSubSvc.publish('/consultation/status/changed', [call]);
            }
        };

        /**
         * Ends a local or remote call. You may use {@link canHangupCall} to check if ending a call is allowed.
         * @param {String} callId ID of the call to be terminated.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.endCall = function (callId) {
            if (!_that.canHangupCall(callId)) {
                return $q.reject('Call cannot be ended at the moment');
            }
            var deferred = $q.defer();
            CallControlSvc.endCall(callId, handleCallControlCb(deferred));
            return deferred.promise;
        };

        /**
         * Ends a call that is held. This API is intended for clients
         * that cannot disable the call release button when holding a
         * call but pbx requires that the call be retrieved before
         * releasing it.
         * @param {String} callId ID of the call to be terminated.
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.retrieveAndEndCall = function (callId) {
            if (!$rootScope.localUser.isOsBizCTIEnabled || _that.canHangupCall(callId)) {
                return _that.endCall(callId);
            }
            if (!isCallOnHold(callId)) {
                return $q.reject('Call cannot be ended at the moment');
            }
            var deferred = $q.defer();
            CallControlSvc.retrieveAndEndCall(callId, handleCallControlCb(deferred));
            return deferred.promise;
        };

        /**
         * Pulls a remote telephony call. You may use {@link canPullRemoteCall} to check if pulling a remote call is allowed.
         * @param {String} callId ID of the call to be pulled.
         * @param {Boolean} endActiveCall If false and there is a local active call, a rejected promised will be returned indicating
         *                                the user need to be prompted to ask what to do with the local call.
         *                                If the user wants to terminate the local call this function needs to be invoked again with this
         *                                parameter set to true.
         * @returns {Promise} Fulfillment handler receives the created call object. Rejection handler receives an error object.
         */
        this.pullRemoteCall = function (callId, endActiveCall) {
            if (hasCircuitActiveCall() && !endActiveCall) {
                return $q.reject('res_TerminateActiveCall');
            }
            var deferred = $q.defer();
            CallControlSvc.pullRemoteCall(callId, false, handleCallControlCb(deferred));
            return deferred.promise;
        };

        /**
         * Send DTMF digits
         * @param {String} callId The call ID of active call
         * @param {String} digits The digits to be sent
         * @returns {Promise} Fulfillment handler receives no parameters. Rejection handler receives an error object.
         */
        this.sendDigits = function (callId, digits) {
            var call = _phoneConversation && _phoneConversation.call;
            if (!call || callId !== call.callId) {
                return $q.reject('Not active call');
            }
            if (!call.isDtmfAllowed) {
                return $q.reject('DTMF not allowed');
            }
            if (_sendDigitsQueue.push(digits) === 1) {
                processNextDigit();
            } else {
                LogSvc.debug('[PhoneCallSvc]: Digit(s) enqueued:', digits);
            }
            return $q.resolve();
        };

        /**
         * Stops playing the ringtone for the specified incoming call.
         * @param {String} callId ID of the ringing call.
         * @returns {void}
         */
        this.stopRingingTone = CallControlSvc.stopRingingTone;

        /**
         * Returns the participants of a merged call or an empty array if the call is not merged
         * @param {String} callId The callId of the call
         * @returns {Array}
         */
        this.getMergedCallParticipants = function (callId) {
            var call = getCall(callId);
            if (call) {
                var isMergedCall = call.isSTC ? call.isDirectUpgradedToConf : call.checkCstaState([CstaCallState.Conference, CstaCallState.ConferenceHolding]);
                if (isMergedCall) {
                    if (call.isSTC) {
                        return call.participants.slice();
                    }
                    var participants = call.participants.filter(function (p) {
                        return !p.hasTelephonyRole;
                    });
                    if (participants.length > 1) {
                        // ATC merged calls always have at least 3 participants (participant length > 1) otherwise the PBX reverts the call to 1-2-1 call.
                        // STC merged calls do not revert to basic calls even if there are two parties left
                        return participants;
                    }
                }
            }
            return [];
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.PhoneCallSvcImpl = PhoneCallSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
