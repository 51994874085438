
var Circuit = (function (circuit) {
    'use strict';

    var Utils = circuit.Utils;

    var ShortcutActionTypes = {
        ANSWER_INCOMING_CALL: 'ANSWER_INCOMING_CALL',
        CLEAR_FOCUS: 'CLEAR_FOCUS',
        DECLINE_INCOMING_CALL: 'DECLINE_INCOMING_CALL',
        ENABLE_ALPHA_FEATURES: 'ENABLE_ALPHA_FEATURES',
        ENABLE_CLOSED_BETA_FEATURES: 'ENABLE_CLOSED_BETA_FEATURES',
        ENABLE_DEMO_FEATURES: 'ENABLE_DEMO_FEATURES',
        GO_TO_PREVIOUS_LIST_ITEM: 'GO_TO_PREVIOUS_LIST_ITEM',
        GO_TO_PREVIOUS_TAB: 'GO_TO_PREVIOUS_TAB',
        GO_TO_NEXT_LIST_ITEM: 'GO_TO_NEXT_LIST_ITEM',
        GO_TO_NEXT_TAB: 'GO_TO_NEXT_TAB',
        MUTE_ALL_PARTICIPANTS: 'MUTE_ALL_PARTICIPANTS',
        MUTE_UNMUTE_AUDIO: 'MUTE_UNMUTE_AUDIO',
        NAVIGATE_CIRCUIT: 'NAVIGATE_CIRCUIT',
        NEW_PHONE_CALL: 'NEW_PHONE_CALL',
        OPEN_LOG_MANAGEMENT_POPUP: 'OPEN_LOG_MANAGEMENT_POPUP',
        OPEN_SETTINGS: 'OPEN_SETTINGS',
        REPORT_ISSUE: 'REPORT_ISSUE',
        START_AUDIO_CALL: 'START_AUDIO_CALL',
        START_VIDEO_CALL: 'START_VIDEO_CALL',
        STOP_LEAVE_CALL: 'STOP_LEAVE_CALL',
        STOP_REMOTE_CONTROL: 'STOP_REMOTE_CONTROL',
        TEST_CANDIDATES_COLLECTION: 'TEST_CANDIDATES_COLLECTION',
        TOGGLE_SCREEN_SHARE_IN_CALL: 'TOGGLE_SCREEN_SHARE_IN_CALL',
        TOGGLE_SHORTCUTS_POPUP: 'TOGGLE_SHORTCUTS_POPUP',
        TOGGLE_VIDEO_IN_CALL: 'TOGGLE_VIDEO_IN_CALL'
    };

    var keys = { // The values (codes, keys, names) of the relevant keys of the supported actions
        TAB: {code: 9, key: 'TAB', name: 'res_ShortcutKeyTab'},
        ENTER: {code: 13, key: 'ENTER', name: 'res_ShortcutKeyEnter'},
        ESC: {code: 27, key: 'ESC', name: 'res_ShortcutKeyEscape'},
        UP: {code: 38, key: 'UP', name: 'res_ShortcutKeyArrowUp'},
        DOWN: {code: 40, key: 'DOWN', name: 'res_ShortcutKeyArrowDown'},
        F2: {code: 113, Key: 'F2', name: 'res_ShortcutKeyF2'},
        F11: {code: 122, Key: 'F11', name: 'res_ShortcutKeyF11'},
        NUM_0: {code: 48, key: '0'},
        NUM_1: {code: 49, key: '1'},
        NUM_2: {code: 50, key: '2'},
        NUM_3: {code: 51, key: '3'},
        NUM_4: {code: 52, key: '4'},
        NUM_5: {code: 53, key: '5'},
        NUM_6: {code: 54, key: '6'},
        NUM_7: {code: 55, key: '7'},
        NUM_8: {code: 56, key: '8'},
        NUM_9: {code: 57, key: '9'},
        A: {code: 65, key: 'A'},
        B: {code: 66, key: 'B'},
        C: {code: 67, key: 'C'},
        D: {code: 68, key: 'D'},
        F: {code: 70, key: 'F'},
        I: {code: 73, key: 'I'},
        K: {code: 75, key: 'K'},
        L: {code: 76, key: 'L'},
        M: {code: 77, key: 'M'},
        O: {code: 79, key: 'O'},
        P: {code: 80, key: 'P'},
        R: {code: 82, key: 'R'},
        S: {code: 83, key: 'S'},
        T: {code: 84, key: 'T'},
        U: {code: 85, key: 'U'},
        W: {code: 87, key: 'W'},
        X: {code: 88, key: 'X'}
    };

    var categories = { // The categories of the supported actions
        GLOBAL: {id: 'global', name: 'res_Global'},
        NAVIGATION: {id: 'navigation', name: 'res_ShortcutCategoryNavigation'},
        NOTIFICATION: {id: 'notification', name: 'res_ShortcutCategoryNotification'},
        CONVERSATION: {id: 'conversation', name: 'res_Conversation'},
        CALLING: {id: 'calling', name: 'res_ShortcutCategoryCalling'},
        SUPPORT: {id: 'support', name: 'res_Support'}
    };

    var ShortcutActions = [ // The supported actions that can be triggered by a keyboard shortcut (sequence as on popup)
        {
            id: ShortcutActionTypes.TOGGLE_SHORTCUTS_POPUP,
            name: 'res_ShortcutActionToggleShortcutsPopup',
            category: categories.GLOBAL,
            fullScreen: true,
            key: keys.K,
            windows: {
                alt: true
            },
            mac: {
                shift: true,
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.NEW_PHONE_CALL,
            name: 'res_ShortcutActionNewPhoneCall',
            category: categories.GLOBAL,
            key: keys.U,
            windows: {
                alt: true
            },
            mac: {
                shift: true,
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.OPEN_SETTINGS,
            category: categories.GLOBAL,
            key: keys.I,
            windows: {
                alt: true
            },
            mac: {
                shift: true,
                cmd: true
            },
            hidden: true
        },
        {
            id: ShortcutActionTypes.CLEAR_FOCUS,
            name: 'res_ShortcutActionClearFocus',
            category: categories.GLOBAL,
            fullScreen: true,
            key: keys.ESC
        },
        {
            id: ShortcutActionTypes.NAVIGATE_CIRCUIT,
            name: 'res_ShortcutActionNavigateCircuit',
            category: categories.GLOBAL,
            fullScreen: true,
            key: keys.T,
            shift: true,
            alt: true
        },
        {
            id: ShortcutActionTypes.GO_TO_PREVIOUS_LIST_ITEM,
            name: 'res_ShortcutActionPreviousListItem',
            category: categories.NAVIGATION,
            fullScreen: true,
            key: keys.UP
        },
        {
            id: ShortcutActionTypes.GO_TO_NEXT_LIST_ITEM,
            name: 'res_ShortcutActionNextListItem',
            category: categories.NAVIGATION,
            fullScreen: true,
            key: keys.DOWN
        },
        {
            id: ShortcutActionTypes.GO_TO_PREVIOUS_TAB,
            name: 'res_ShortcutActionPreviousTab',
            category: categories.NAVIGATION,
            fullScreen: true,
            key: keys.TAB,
            shift: true
        },
        {
            id: ShortcutActionTypes.GO_TO_NEXT_TAB,
            name: 'res_ShortcutActionNextTab',
            category: categories.NAVIGATION,
            fullScreen: true,
            key: keys.TAB
        },
        {
            id: ShortcutActionTypes.ANSWER_INCOMING_CALL,
            name: 'res_ShortcutActionAnswerIncomingCall',
            category: categories.CALLING,
            key: keys.NUM_1,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.DECLINE_INCOMING_CALL,
            name: 'res_ShortcutActionDeclineCall',
            category: categories.CALLING,
            key: keys.NUM_2,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.START_AUDIO_CALL,
            name: 'res_ShortcutActionStartAudioCall',
            category: categories.CALLING,
            key: keys.NUM_3,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.START_VIDEO_CALL,
            name: 'res_ShortcutActionStartVideoCall',
            category: categories.CALLING,
            key: keys.NUM_4,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.TOGGLE_VIDEO_IN_CALL,
            category: categories.CALLING,
            fullScreen: true,
            key: keys.NUM_6,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true
        },
        {
            id: ShortcutActionTypes.TOGGLE_SCREEN_SHARE_IN_CALL,
            category: categories.CALLING,
            fullScreen: true,
            key: keys.NUM_7,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true
        },
        {
            id: ShortcutActionTypes.STOP_REMOTE_CONTROL,
            name: 'res_ShortcutActionStopRemoteControl',
            category: categories.CALLING,
            fullScreen: true,
            key: keys.NUM_8,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.MUTE_ALL_PARTICIPANTS,
            category: categories.CALLING,
            fullScreen: true,
            key: keys.NUM_9,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true
        },
        {
            id: ShortcutActionTypes.STOP_LEAVE_CALL,
            name: 'res_ShortcutActionStopLeaveCall',
            category: categories.CALLING,
            fullScreen: true,
            guest: true,
            key: keys.NUM_0,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            }
        },
        {
            id: ShortcutActionTypes.MUTE_UNMUTE_AUDIO,
            name: 'res_ShortcutActionMuteUnmuteAudio',
            category: categories.CALLING,
            fullScreen: true,
            guest: true,
            key: keys.M,
            windows: {
                ctrl: true
            },
            mac: {
                shift: true,
                alt: true
            }
        },
        {
            id: ShortcutActionTypes.ENABLE_ALPHA_FEATURES,
            category: categories.SUPPORT,
            fullScreen: true,
            key: keys.X,
            shift: true,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true,
            bypassSettings: true
        },
        {
            id: ShortcutActionTypes.ENABLE_DEMO_FEATURES,
            category: categories.SUPPORT,
            fullScreen: true,
            key: keys.D,
            shift: true,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true,
            bypassSettings: true
        },
        {
            id: ShortcutActionTypes.ENABLE_CLOSED_BETA_FEATURES,
            category: categories.SUPPORT,
            fullScreen: true,
            key: keys.B,
            shift: true,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true,
            bypassSettings: true
        },
        {
            id: ShortcutActionTypes.OPEN_LOG_MANAGEMENT_POPUP,
            category: categories.SUPPORT,
            guest: true,
            key: keys.L,
            shift: true,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true,
            bypassSettings: true
        },
        {
            id: ShortcutActionTypes.REPORT_ISSUE,
            category: categories.SUPPORT,
            key: keys.R,
            shift: true,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true,
            bypassSettings: true
        },
        {
            id: ShortcutActionTypes.TEST_CANDIDATES_COLLECTION,
            category: categories.SUPPORT,
            key: keys.T,
            shift: true,
            alt: true,
            windows: {
                ctrl: true
            },
            mac: {
                cmd: true
            },
            hidden: true,
            bypassSettings: true
        }
    ];

    // Set the proper values based on OS type
    ShortcutActions.forEach(function (action) {
        var valueSet = Utils.isMacOs ? action.mac : action.windows;
        if (valueSet) {
            Object.assign(action, valueSet);
        }
        action.shift = !!action.shift;
        action.ctrl = !!action.ctrl;
        action.cmd = !!action.cmd;
        action.alt = !!action.alt;
    });

    // Exports
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.ShortcutActionTypes = ShortcutActionTypes;
    circuit.ShortcutActions = ShortcutActions;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
