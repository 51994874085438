// Define globals for JSHint

var Circuit = (function (circuit) {
    'use strict';

    var Constants = Object.freeze({
        WSMessageType: {
            REQUEST: 'REQUEST',
            RESPONSE: 'RESPONSE',
            EVENT: 'EVENT'
        },

        ContentType: {
            SYSTEM: 'SYSTEM',
            NONE: 'NONE',
            EXTENSION: 'EXTENSION',                 // Not used by client
            CONVERSATION: 'CONVERSATION',
            USER: 'USER',
            RTC_SESSION: 'RTC_SESSION',
            RTC_CALL: 'RTC_CALL',
            SEARCH: 'SEARCH',
            KEEPALIVE: 'KEEPALIVE',                 // Not used by client
            INSTRUMENTATION: 'INSTRUMENTATION',
            GUEST: 'GUEST',
            THIRDPARTYCONNECTORS: 'THIRDPARTYCONNECTORS',
            USER_TO_USER: 'USER_TO_USER',
            VERSION: 'VERSION',
            ACCOUNT: 'ACCOUNT',

            // Content type used exclusively between client and access server
            USER_DATA: 'USER_DATA'
        },

        ReturnCode: {
            // Client API Errors
            OK: 'OK',
            AUTHORIZATION_FAILED: 'AUTHORIZATION_FAILED',
            AUTHORIZATION_REQUIRED: 'AUTHORIZATION_REQUIRED',
            SERVICE_EXCEPTION: 'SERVICE_EXCEPTION',
            PROTOBUF_CONVERSION_EXCEPTION: 'PROTOBUF_CONVERSION_EXCEPTION', // Client API V1
            CONVERSION_EXCEPTION: 'CONVERSION_EXCEPTION',
            NO_MESSAGE_HANDLER: 'NO_MESSAGE_HANDLER',
            INVALID_CLIENT: 'INVALID_CLIENT',
            NO_RESULT: 'NO_RESULT',
            OPERATION_NOT_SUPPORTED: 'OPERATION_NOT_SUPPORTED',
            ENTITY_ALREADY_EXISTS: 'ENTITY_ALREADY_EXISTS',
            FRAME_SIZE_TO_LONG: 'FRAME_SIZE_TO_LONG',
            BACKEND_UNAVAILABLE: 'BACKEND_UNAVAILABLE',
            OVERLOADED: 'OVERLOADED',
            OLD_VERSION: 'OLD_VERSION',
            STORAGE_OVERLOADED: 'STORAGE_OVERLOADED',
            USER_NOT_FOUND: 'USER_NOT_FOUND',

            // Access Server Errors
            SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',

            // Internal Client Errors
            REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
            DISCONNECTED: 'DISCONNECTED',
            INVALID_MESSAGE: 'INVALID_MESSAGE',
            FAILED_TO_SEND: 'FAILED_TO_SEND',
            UNEXPECTED_RESPONSE: 'UNEXPECTED_RESPONSE',
            MISSING_REQUIRED_PARAMETER: 'MISSING_REQUIRED_PARAMETER',
            CHOOSE_DESKTOP_MEDIA_CANCELLED: 'CHOOSE_DESKTOP_MEDIA_CANCELLED',
            REQUEST_CANCELED: 'REQUEST_CANCELED',

            // SDK Errors
            SDK_ERROR: 'SDK_ERROR'
        },

        // Error codes used when return code is SERVICE_EXCEPTION
        ErrorCode: {
            UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
            CONSTRAINT_VIOLATION: 'CONSTRAINT_VIOLATION',
            MAX_NUM_CONV_PARTICIPANTS: 'MAX_NUM_CONV_PARTICIPANTS',
            THIRDPARTY_ERROR: 'THIRDPARTY_ERROR',
            AUTHORIZATION_FAILED: 'AUTHORIZATION_FAILED',
            PERMISSION_DENIED: 'PERMISSION_DENIED',
            RTC_NO_MEDIA_NODES_AVAILABLE: 'RTC_NO_MEDIA_NODES_AVAILABLE',
            RTC_CONCURRENT_INCOMING_CALL: 'RTC_CONCURRENT_INCOMING_CALL',
            DUPLICATED_ENTITY_ERROR: 'DUPLICATED_ENTITY_ERROR',
            RTC_MEDIA_NODE_UNREACHABLE: 'RTC_MEDIA_NODE_UNREACHABLE',
            CLOUD_TELEPHONY_ERROR: 'CLOUD_TELEPHONY_ERROR',
            CIRCUIT_EXCEPTION: 'CIRCUIT_EXCEPTION'
        },

        // Different types of CIRCUIT_EXCEPTION errorCode
        CircuitExceptionErrorCode: {
            INWEB_00000C: 'INWEB-00000C', // For illegal arguments (should usually not happen, just during development)
            INWEB_00001C: 'INWEB-00001C', // Webhook does not exist
            INWEB_00002C: 'INWEB-00002C', // Conversation does not exist for the webhook
            INWEB_00003C: 'INWEB-00003C', // The creation of the webhook with the given parameters is not possible
            INWEB_00004C: 'INWEB-00004C', // The maximum number of webhooks for a tenant has been reached
            INWEB_00005C: 'INWEB-00005C'  // The maximum number of webhooks for a conversation has been reached
        },

        ThirdPartyError: {
            UNSUPPORTED_PROVIDER: 'UNSUPPORTED_PROVIDER',       // Unknown provider
            BAD_REQUEST: 'BAD_REQUEST',                         // A bad request was send to the provider server
            GENERAL_ERROR: 'GENERAL_ERROR',                     // A general Error occured
            INSUFFICIENT_PERMISSION: 'INSUFFICIENT_PERMISSION', // There are insufficient Permission accessing a resource
            NOT_AUTHORIZED: 'NOT_AUTHORIZED',                   // User is not authorized
            RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',           // Resource could not be found
            SERVER_DOWN: 'SERVER_DOWN',                         // Server is down. No response from server
            SESSION_EXPIRED: 'SESSION_EXPIRED',                 // Session has been expired
            UNSUPPORTED_FILE_TYPE: 'UNSUPPORTED_FILE_TYPE',     // The file is not supported for online editing
            WRONG_SERVER: 'WRONG_SERVER',                       // The server of the file attached, is different from the server the user is connected to
            DUPLICATE_RESOURCE: 'DUPLICATE_RESOURCE',           // Move conversation failed because of duplicate name
            PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',         // Move conversation created, members partially added and/or post message failed

            // Internal client errors
            EMPTY_SETTINGS: 'EMPTY_SETTINGS',                   // Settings not provided (validation error)
            EMPTY_USERNAME: 'EMPTY_USERNAME',                   // Username not provided (validation error)
            EMPTY_PASSWORD: 'EMPTY_PASSWORD',                   // Password not provided (validation error)
            EMPTY_SERVER_ADDRESS: 'EMPTY_SERVER_ADDRESS',       // Server address not provided (validation error)
            INVALID_SERVER_ADDRESS: 'INVALID_SERVER_ADDRESS',   // Invalid server url (validation error)
            NOT_CONNECTED: 'NOT_CONNECTED',                     // Not connected to a thirdparty provider
            ALREADY_CONNECTED: 'ALREADY_CONNECTED',             // Already connected to a thirdparty provider
            INVALID_ITEMIDS: 'INVALID_ITEMIDS',
            INVALID_DRIVEID: 'INVALID_DRIVEID',
            TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS'              // The server rejected the request due to its rate limit (too many requests per second)
        },

        ClientCapability: {  // This is a bitwise enum for client capabilities. Used between client and access.
            MUTABLE_APN: 0X01,
            IOS13_VOIP_APN: 0X02,
            DISABLE_VOIP_NOTIFICATIONS: 0x04
        },

        ///////////////////////////////////////////////////////////////////////////
        // Common types
        ///////////////////////////////////////////////////////////////////////////
        SortOrder: {
            ASCENDING: 'ASCENDING',
            DESCENDING: 'DESCENDING'
        },

        TimeRange: {
            ONE_HOUR: 3600000,
            ONE_DAY: 86400000,
            ONE_WEEK: 7 * 86400000,
            ONE_MONTH: 30 * 86400000
        },

        ///////////////////////////////////////////////////////////////////////////
        // Conversation Action
        ///////////////////////////////////////////////////////////////////////////
        ConversationActionType: {
            ADD_JOURNAL_ENTRY: 'ADD_JOURNAL_ENTRY',
            DELETE_ALL_JOURNAL_ENTRIES: 'DELETE_ALL_JOURNAL_ENTRIES',
            DELETE_JOURNAL_ENTRY: 'DELETE_JOURNAL_ENTRY',
            GET_JOURNAL_ENTRIES: 'GET_JOURNAL_ENTRIES',
            GET_TELEPHONY_CONVERSATION_ID: 'GET_TELEPHONY_CONVERSATION_ID',
            GET_UNHEARD_VOICEMAILS_COUNT: 'GET_UNHEARD_VOICEMAILS_COUNT',
            SET_RECORDING_DELETION_DELAY: 'SET_RECORDING_DELETION_DELAY',
            SET_READ_POINTER: 'SET_READ_POINTER',
            SET_VOICEMAIL_HEARD: 'SET_VOICEMAIL_HEARD',
            UPDATE_RTC_ITEM_ATTACHMENTS: 'UPDATE_RTC_ITEM_ATTACHMENTS'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Conversation Event
        ///////////////////////////////////////////////////////////////////////////
        ConversationEventType: {
            CREATE: 'CREATE',
            UPDATE: 'UPDATE',
            DELETE: 'DELETE',
            ADD_ITEM: 'ADD_ITEM',
            UPDATE_ITEM: 'UPDATE_ITEM',
            READ_ITEMS: 'READ_ITEMS',
            TYPING: 'TYPING',
            DRAFT_MESSAGE_SAVED: 'DRAFT_MESSAGE_SAVED',
            DRAFT_MESSAGE_DISCARDED: 'DRAFT_MESSAGE_DISCARDED',
            DRAFT_MESSAGE_PUBLISHED: 'DRAFT_MESSAGE_PUBLISHED',
            FLAG_ITEM: 'FLAG_ITEM',
            CLEAR_FLAGGED_ITEM: 'CLEAR_FLAGGED_ITEM',
            CONVERSATION_MARKED: 'CONVERSATION_MARKED',
            CONVERSATION_UNMARKED: 'CONVERSATION_UNMARKED',
            FAVORITE_POSITION_CHANGED: 'FAVORITE_POSITION_CHANGED',
            USER_DATA_CHANGED: 'USER_DATA_CHANGED',
            JOURNAL_ENTRY_DELETED: 'JOURNAL_ENTRY_DELETED',
            ALL_JOURNAL_ENTRIES_DELETED: 'ALL_JOURNAL_ENTRIES_DELETED'
        },

        ///////////////////////////////////////////////////////////////////////////
        // UI Setttings
        ///////////////////////////////////////////////////////////////////////////
        UISettings: {
            // Conversation sidebar settings
            INFO: 'INFO',
            DETAILS: 'DETAILS',
            // Selector panel settings
            CONVERSATION_FAVORITES: 'CONVERSATION_FAVORITES'
        },

        DefaultUISettingsValues: {
            INFO: true,
            DETAILS: true,
            CONVERSATION_FAVORITES: true
        },

        // Options for busy settings
        UserBusyHandlingOptions: {
            DefaultRouting: 'DefaultRouting',
            BusySignal: 'BusySignal',
            SendToAlternativeNumber: 'SendToAlternativeNumber',
            SendToVm: 'SendToVm'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Conversation Data
        ///////////////////////////////////////////////////////////////////////////
        RetentionPolicyStatus: {
            ENABLED: 'ENABLED',
            USER_DISABLED: 'USER_DISABLED',
            LEGALHOLD_DISABLED: 'LEGALHOLD_DISABLED',
            USER_LEGALHOLD_DISABLED: 'USER_LEGALHOLD_DISABLED'
        },

        SearchDirection: {
            AFTER: 'AFTER',
            BEFORE: 'BEFORE',
            BOTH: 'BOTH'
        },

        // General filter that defines which items to retrieve.
        TimestampFilter: {
            MODIFICATION: 'MODIFICATION',
            CREATION: 'CREATION'
        },

        // General filter that defines the order in which the items will be retrieved.
        OrderBy: {
            TIMESTAMP_ASC: 'TIMESTAMP_ASC',
            TIMESTAMP_DESC: 'TIMESTAMP_DESC'
        },

        OrderType: {
            MODIFICATION: 'MODIFICATION',
            CREATION: 'CREATION'
        },

        ConversationType: {
            DIRECT: 'DIRECT',
            GROUP: 'GROUP',
            OPEN: 'OPEN',
            LARGE: 'LARGE'
        },

        ConversationItemType: {
            TEXT: 'TEXT',
            RTC: 'RTC',
            SYSTEM: 'SYSTEM'
        },

        ConversationItemTypeMapping: {
            RTC: 'phone',
            CALL_SUMMARY: 'call-summary',
            TEXT: 'text',
            SYSTEM: 'notification',
            VOICEMAIL: 'vm',
            MARKER: 'marker',
            DIVIDER: 'divider',
            GAP: 'gap'
        },

        ConversationFilter: {
            ALL: 'ALL',
            OPEN_MEMBER: 'OPEN_MEMBER',
            MARKED: 'MARKED',
            ARCHIVED: 'ARCHIVED',
            UNARCHIVED: 'UNARCHIVED'
        },

        ConversationMarkFilter: {
            MUTE: 'MUTE',
            FAVORITE: 'FAVORITE'
        },

        GetConversationParticipantCriteria: {
            DISPLAY_NAME: 'DISPLAY_NAME',
            TYPE: 'TYPE'
        },

        ConversationParticipantType: {
            ACTIVE: 'ACTIVE',
            REGULAR: 'REGULAR',
            FORMER: 'FORMER',
            MODERATOR: 'MODERATOR',
            GUEST: 'GUEST'
        },

        DataRetentionState: {
            UNTOUCHED: 'UNTOUCHED',
            OUTDATED: 'OUTDATED',
            DELETED: 'DELETED'
        },

        RetrieveAction: {
            CONVERSATIONS: 'CONVERSATIONS',
            CONVERSATION_IDS: 'CONVERSATION_IDS',
            CONVERSATIONS_AND_IDS: 'CONVERSATIONS_AND_IDS'
        },

        RTCItemType: {
            STARTED: 'STARTED',
            ENDED: 'ENDED',
            MISSED: 'MISSED',
            PARTICIPANT_JOINED: 'PARTICIPANT_JOINED',
            PARTICIPANT_LEFT: 'PARTICIPANT_LEFT',
            MOVED: 'MOVED'
        },

        RTCItemMissed: {
            UNREACHABLE: 'UNREACHABLE',
            TIMEOUT: 'TIMEOUT',
            USER_BUSY: 'USER_BUSY',
            DECLINED: 'DECLINED',
            CANCELED: 'CANCELED',
            INVALID_NUMBER: 'INVALID_NUMBER',
            TEMPORARILY_UNAVAILABLE: 'TEMPORARILY_UNAVAILABLE'
        },

        RTCItemMoved: {
            MOVED_FROM: 'MOVED_FROM',
            MOVED_TO: 'MOVED_TO'
        },

        SortingType: {
            RECENT_ACTIVITY_ASC: 'RECENT_ACTIVITY_ASC',
            RECENT_ACTIVITY_DESC: 'RECENT_ACTIVITY_DESC',
            ALPHABETICALLY_ASC: 'ALPHABETICALLY_ASC',
            ALPHABETICALLY_DESC: 'ALPHABETICALLY_DESC',
            POPULARITY_ASC: 'POPULARITY_ASC',
            POPULARITY_DESC: 'POPULARITY_DESC',
            TIMESTAMP_ASC: 'TIMESTAMP_ASC'
        },

        TextItemContentType: {
            PLAIN: 'PLAIN',
            RICH: 'RICH'
        },

        TextItemState: {
            CREATED: 'CREATED',
            EDITED: 'EDITED',
            DELETED: 'DELETED',
            CLUSTERED: 'CLUSTERED'
        },

        SystemItemType: {
            CONVERSATION_CREATED: 'CONVERSATION_CREATED',
            PARTICIPANT_ADDED: 'PARTICIPANT_ADDED',
            PARTICIPANT_REMOVED: 'PARTICIPANT_REMOVED',
            CONVERSATION_RENAMED: 'CONVERSATION_RENAMED',
            CONFERENCE_DETAILS_CHANGED: 'CONFERENCE_DETAILS_CHANGED',
            CONVERSATION_MODERATED: 'CONVERSATION_MODERATED',
            MODERATOR_RIGHTS_GRANTED: 'MODERATOR_RIGHTS_GRANTED',
            MODERATOR_RIGHTS_DROPPED: 'MODERATOR_RIGHTS_DROPPED',
            GUEST_ACCESS_ENABLED: 'GUEST_ACCESS_ENABLED',
            GUEST_ACCESS_DISABLED: 'GUEST_ACCESS_DISABLED',
            AVATAR_CHANGED: 'AVATAR_CHANGED',
            RETENTION_POLICY_CHANGED: 'RETENTION_POLICY_CHANGED',
            PARTICIPANT_HARD_REMOVED: 'PARTICIPANT_HARD_REMOVED',

            // Internal client types
            RETENTION_ITEMS_REMOVED: 'RETENTION_ITEMS_REMOVED'
        },

        // Attachments data
        AttachmentOrderType: {
            CREATION: 'CREATION',
            FILENAME: 'FILENAME',
            SIZE: 'SIZE',
            MIMETYPE: 'MIMETYPE',
            FILEEXTENSION: 'FILEEXTENSION'
        },

        AttachmentOrderDirection: {
            ASC: 'ASC',
            DSC: 'DSC'
        },

        BridgeLocale: {
            EN_US: 'EN_US',
            EN_GB: 'EN_GB',
            DE_DE: 'DE_DE',
            ES_ES: 'ES_ES',
            FR_FR: 'FR_FR',
            IT_IT: 'IT_IT',
            RU_RU: 'RU_RU',
            ZH_HANS_CN: 'ZH_HANS_CN',
            PT_BR: 'PT_BR',
            NL_NL: 'NL_NL',
            CA_ES: 'CA_ES'
        },

        BridgeNumberType: {
            TOLL: 'TOLL',
            TOLL_FREE: 'TOLL_FREE',
            LOCAL: 'LOCAL'
        },

        FilterTarget: {
            CONVERSATION_TYPE: 'CONVERSATION_TYPE',
            LABEL_ID: 'LABEL_ID',
            SYSTEM_TAG: 'SYSTEM_TAG'
        },

        FilterTargetComparator: {
            EQ: 'EQ',
            NOT: 'NOT'
        },

        FilterBoolConnector: {
            AND: 'AND',
            OR: 'OR'
        },

        SystemLabel: {
            ARCHIVED: 'ARCHIVED',
            FAVORITE: 'FAVORITE',
            MODERATOR: 'MODERATOR'
        },

        IncludedUserData: {
            LAST_READ_TS: 'LASTREADTS',
            UNREAD_COUNT: 'UNREADCOUNT',
            LABEL_IDS: 'LABELIDS',
            SYSTEM_LABEL_IDS: 'SYSTEMLABELIDS'
        },

        ChangedUserData: {
            LAST_READ_TS: 'LASTREADTS',
            LABEL_IDS: 'LABELIDS',
            SYSTEM_LABEL_IDS: 'SYSTEMLABELIDS'
        },

        JournalFilter: {
            ALL: 'ALL',
            MISSED: 'MISSED',
            DIALED: 'DIALED',
            RECEIVED: 'RECEIVED',
            DIVERTED: 'DIVERTED',
            VOICEMAILS: 'VOICEMAILS',
            UNHEARD_VOICEMAILS: 'UNHEARD_VOICEMAILS'
        },

        AddLabelsResult: {
            OK: 'OK',
            LABEL_LIMIT_REACHED: 'LABEL_LIMIT_REACHED',
            LABEL_ALREADY_EXISTS: 'LABEL_ALREADY_EXISTS'
        },

        SystemTag: {
            IC: 'IC'
        },

        ContainerType: {
            CONVERSATION: 'CONVERSATION'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Instrumentation Action
        ///////////////////////////////////////////////////////////////////////////
        InstrumentationActionType: {
            SUBMIT_CLIENT_DATA: 'SUBMIT_CLIENT_DATA',
            SUBMIT_QOS_DATA: 'SUBMIT_QOS_DATA'
        },

        SubmitDataResult: {
            UNKNOWN: 'UNKNOWN',
            OK: 'OK',
            NO_USERID: 'NO_USERID',
            NO_CLIENTID: 'NO_CLIENTID',
            NO_DATA: 'NO_DATA',
            PROCESSING_ERROR: 'PROCESSING_ERROR'
        },

        QOSMediaType: {
            AUDIO: 'AUDIO',
            VIDEO: 'VIDEO',
            SCREEN_SHARE: 'SCREEN_SHARE'
        },

        ///////////////////////////////////////////////////////////////////////////
        // RTC Call Action
        ///////////////////////////////////////////////////////////////////////////
        RTCCallActionType: {
            JOIN: 'JOIN',
            ANSWER: 'ANSWER',
            ICE_CANDIDATES: 'ICE_CANDIDATES',
            LEAVE: 'LEAVE',
            TERMINATE: 'TERMINATE',
            CHANGE_MEDIA_TYPE: 'CHANGE_MEDIA_TYPE',
            INVITE_REJECT: 'INVITE_REJECT',
            CHANGE_MEDIA_ACCEPT: 'CHANGE_MEDIA_ACCEPT',
            CHANGE_MEDIA_REJECT: 'CHANGE_MEDIA_REJECT',
            PREPARE: 'PREPARE',
            RENEW_TURN_CREDENTIALS: 'RENEW_TURN_CREDENTIALS',
            SEND_PROGRESS: 'SEND_PROGRESS',
            SUBMIT_RTC_QUALITY_RATING: 'SUBMIT_RTC_QUALITY_RATING',
            MERGE: 'MERGE'
        },

        ///////////////////////////////////////////////////////////////////////////
        // RTC Call Event
        ///////////////////////////////////////////////////////////////////////////
        RTCCallEventType: {
            SDP_ANSWER: 'SDP_ANSWER',
            SDP_FAILED: 'SDP_FAILED',
            INVITE: 'INVITE',
            INVITE_CANCEL: 'INVITE_CANCEL',
            INVITE_FAILED: 'INVITE_FAILED',
            CHANGE_MEDIA_TYPE_REQUESTED: 'CHANGE_MEDIA_TYPE_REQUESTED',
            PROGRESS: 'PROGRESS',
            CHANGE_MEDIA_TYPE_FORCED: 'CHANGE_MEDIA_TYPE_FORCED',
            ICE_CANDIDATES: 'ICE_CANDIDATES',
            RTC_QUALITY_RATING_EVENT: 'RTC_QUALITY_RATING_EVENT'
        },

        ///////////////////////////////////////////////////////////////////////////
        // RTC Call Data
        ///////////////////////////////////////////////////////////////////////////
        SdpFailedCause: {
            SESSION_STARTED_FAILED: 'SESSION_STARTED_FAILED',
            CONNECTION_LOST: 'CONNECTION_LOST',
            SERVICE_ERROR: 'SERVICE_ERROR',
            CHANGE_MEDIA_REJECT: 'CHANGE_MEDIA_REJECT',
            JOIN_FORBIDDEN: 'JOIN_FORBIDDEN',
            SESSION_TERMINATED: 'SESSION_TERMINATED',
            MAX_PARTICIPANTS_REACHED: 'MAX_PARTICIPANTS_REACHED'
        },

        InviteCancelCause: {
            ACCEPT: 'ACCEPT',
            BUSY: 'BUSY',
            DECLINE: 'DECLINE',
            REVOKED: 'REVOKED'
        },

        InviteFailedCause: {
            BUSY: 'BUSY',
            DECLINE: 'DECLINE',
            TIMEOUT: 'TIMEOUT',
            NOT_REACHABLE: 'NOT_REACHABLE',
            INVALID_NUMBER: 'INVALID_NUMBER',
            TEMPORARILY_UNAVAILABLE: 'TEMPORARILY_UNAVAILABLE',
            REVOKED: 'REVOKED'
        },

        InviteRejectCause: {
            BUSY: 'BUSY',
            DECLINE: 'DECLINE',
            TIMEOUT: 'TIMEOUT',
            INVALID_NUMBER: 'INVALID_NUMBER',
            TEMPORARILY_UNAVAILABLE: 'TEMPORARILY_UNAVAILABLE',
            NOT_REACHABLE: 'NOT_REACHABLE'
        },

        RTCProgressType: {
            ALERTING: 'ALERTING',
            PROGRESS: 'PROGRESS',
            EARLY_CONNECT: 'EARLY_CONNECT'
        },

        DisconnectCause: {
            HANGUP: 'HANGUP',
            CONNECTION_LOST: 'CONNECTION_LOST',
            NEGOTIATION_FAILED: 'NEGOTIATION_FAILED',
            TRANSPORT_NEGOTIATION_FAILED: 'TRANSPORT_NEGOTIATION_FAILED',
            SECURITY_NEGOTIATION_FAILED: 'SECURITY_NEGOTIATION_FAILED',
            REMOTE_SDP_FAILED: 'REMOTE_SDP_FAILED',
            STREAM_LOST: 'STREAM_LOST',
            CALL_SETUP_FAILED: 'CALL_SETUP_FAILED'
        },

        DisconnectReason: {
            PAGE_UNLOADED: 'PAGE_UNLOADED',
            CALL_LOST: 'CALL_LOST',
            LOGOUT: 'LOGOUT'
        },

        TerminateCause: {
            COMPLETED_ELSEWHERE: 'COMPLETED_ELSEWHERE',
            PICKED_UP: 'PICKED_UP'
        },

        ///////////////////////////////////////////////////////////////////////////
        // RTC Session Action
        ///////////////////////////////////////////////////////////////////////////
        RTCSessionActionType: {
            LOCK: 'LOCK',
            MUTE_SESSION: 'MUTE_SESSION',
            MUTE: 'MUTE',
            ADD_PARTICIPANT: 'ADD_PARTICIPANT',
            REMOVE_PARTICIPANT: 'REMOVE_PARTICIPANT',
            GET_ACTIVE_SESSIONS: 'GET_ACTIVE_SESSIONS',
            GET_SESSION: 'GET_SESSION',
            MOVE: 'MOVE',
            START_RECORDING: 'START_RECORDING',
            STOP_RECORDING: 'STOP_RECORDING',
            GET_SESSIONID_BY_PIN: 'GET_SESSIONID_BY_PIN',
            RAISE_QUESTION: 'RAISE_QUESTION',
            INVITE_TO_STAGE: 'INVITE_TO_STAGE',
            INVITE_TO_STAGE_ANSWER: 'INVITE_TO_STAGE_ANSWER',
            UPDATE_QUESTION_STATE: 'UPDATE_QUESTION_STATE',
            INVITE_TO_STAGE_CANCEL: 'INVITE_TO_STAGE_CANCEL',
            REMOVE_FROM_STAGE: 'REMOVE_FROM_STAGE',
            GET_QUESTIONS: 'GET_QUESTIONS',
            OPEN_CURTAIN: 'OPEN_CURTAIN',
            CLOSE_CURTAIN: 'CLOSE_CURTAIN',
            GET_NODE_STATE: 'GET_NODE_STATE',
            SEND_CLIENT_INFO: 'SEND_CLIENT_INFO',
            SWITCH_RECORDING_LAYOUT: 'SWITCH_RECORDING_LAYOUT',
            SET_VIDEO_RECEIVER_CONFIGURATION: 'SET_VIDEO_RECEIVER_CONFIGURATION'
        },

        ///////////////////////////////////////////////////////////////////////////
        // RTC Session Event
        ///////////////////////////////////////////////////////////////////////////
        RTCSessionEventType: {
            SESSION_STARTED: 'SESSION_STARTED',
            SESSION_TERMINATED: 'SESSION_TERMINATED',
            SESSION_UPDATED: 'SESSION_UPDATED',
            SESSION_MOVED: 'SESSION_MOVED',
            PARTICIPANT_JOINED: 'PARTICIPANT_JOINED',
            PARTICIPANT_LEFT: 'PARTICIPANT_LEFT',
            PARTICIPANT_UPDATED: 'PARTICIPANT_UPDATED',
            ACTIVE_SPEAKER: 'ACTIVE_SPEAKER',
            VIDEO_ACTIVE_SPEAKER: 'VIDEO_ACTIVE_SPEAKER',
            SESSION_RECORDING_INFO: 'SESSION_RECORDING_INFO',
            SESSION_RECORDING_STARTED: 'SESSION_RECORDING_STARTED',
            SESSION_RECORDING_STOPPED: 'SESSION_RECORDING_STOPPED',
            SESSION_RECORDING_FAILED: 'SESSION_RECORDING_FAILED',
            SESSION_TERMINATION_TIMER_STARTED: 'SESSION_TERMINATION_TIMER_STARTED',
            SESSION_TERMINATION_TIMER_CANCELLED: 'SESSION_TERMINATION_TIMER_CANCELLED',
            LIVE_TRANSCRIPTION: 'LIVE_TRANSCRIPTION'
        },

        ///////////////////////////////////////////////////////////////////////////
        // RTC Session Data
        ///////////////////////////////////////////////////////////////////////////
        RTCSessionTerminatedCause: {
            FORCED: 'FORCED',
            NO_USERS_LEFT: 'NO_USERS_LEFT',
            CONDITIONAL: 'CONDITIONAL'
        },

        RTCSessionParticipantLeftCause: {
            LEFT: 'LEFT',
            CONNECTION_LOST: 'CONNECTION_LOST',
            REMOVED: 'REMOVED',
            CONDITIONAL_TERMINATE: 'CONDITIONAL_TERMINATE',
            FORCED_TERMINATE: 'FORCED_TERMINATE',
            MAX_PARTICIPANTS_REACHED: 'MAX_PARTICIPANTS_REACHED',
            USER_LEFT_STAGE: 'USER_LEFT_STAGE',
            NEGOTIATION_FAILED: 'NEGOTIATION_FAILED',
            OFFER_NOT_ACCEPTED: 'OFFER_NOT_ACCEPTED',
            STREAM_LOST: 'STREAM_LOST',
            TRANSPORT_NEGOTIATION_FAILED: 'TRANSPORT_NEGOTIATION_FAILED',
            SECURITY_NEGOTIATION_FAILED: 'SECURITY_NEGOTIATION_FAILED',
            CALL_SETUP_FAILED: 'CALL_SETUP_FAILED',
            REMOTE_SDP_FAILED: 'REMOTE_SDP_FAILED'
        },

        RTCSessionParticipantFlag: {
            CPU_LOAD: 'CPU_LOAD',                       // VRS video composer disabled
            CPU_LOAD_TRANSCODE: 'CPU_LOAD_TRANSCODE',   // VRS video encoder disabled
            NO_LICENSE: 'NO_LICENSE',                   // VRS licence limit reached
            MAX_COUNT: 'MAX_COUNT'                      // VRS max count
        },

        RtcSupportedFeatures: {
            UPGRADE_TO_CONFERENCE: 'UPGRADE_TO_CONFERENCE',
            SCREEN_SHARE_AND_ACTIVE_SPEAKER_VIDEO: 'SCREEN_SHARE_AND_ACTIVE_SPEAKER_VIDEO',
            MID_STREAM_MAPPING: 'MID_STREAM_MAPPING',
            CALL_PICKUP: 'CALL_PICKUP'
        },

        RealtimeMediaType: {
            AUDIO: 'AUDIO',
            VIDEO: 'VIDEO',
            DESKTOP_SHARING: 'DESKTOP_SHARING'
        },

        RecordingMediaType: {
            AUDIO: 'AUDIO',
            VIDEO: 'VIDEO',
            TEXT: 'TEXT'
        },

        RTCParticipantType: {
            USER: 'USER',                   // Circuit users
            EXTERNAL: 'EXTERNAL',           // PSTN dial-in participants
            TELEPHONY: 'TELEPHONY',         // Telephony connector users (dial in or dial out)
            SESSION_GUEST: 'SESSION_GUEST', // Session guests
            VOICE_MAIL: 'VOICE_MAIL',       // Video/voicemail
            TEST_CALL: 'TEST_CALL'          // Test call "echo" participant
        },

        QuestionState: {
            RAISED: 'RAISED',
            ANSWERED: 'ANSWERED',
            DISCARDED: 'DISCARDED',
            ANSWER_LATER: 'ANSWER_LATER'
        },

        StageState: {
            INITIAL: 'INITIAL',
            INVITED: 'INVITED',
            REJECTED: 'REJECTED',
            ACCEPTED: 'ACCEPTED',
            WAS_ON_STAGE: 'WAS_ON_STAGE',
            TIMEOUT: 'TIMEOUT',
            LEFT_CONFERENCE: 'LEFT_CONFERENCE'
        },

        QuestionEventType: {
            CREATED: 'CREATED',
            UPDATED: 'UPDATED',
            REVOKED: 'REVOKED'
        },

        StageAction: {
            USER_ENTERED_STAGE: 'USER_ENTERED_STAGE',
            USER_LEFT_STAGE: 'USER_LEFT_STAGE'
        },

        NodeType: {
            MEDIA_ACCESS: 'MEDIA_ACCESS',
            APPLICATION: 'APPLICATION',
            STORAGE: 'STORAGE',
            ACCESS: 'ACCESS',
            LOAD_BALANCER: 'LOAD_BALANCER',
            ZOOKEEPER: 'ZOOKEEPER',
            MANAGEMENT: 'MANAGEMENT',
            OPERATIONS: 'OPERATIONS'
        },

        NodeState: {
            UP: 'UP',
            DOWN: 'DOWN',
            OVERLOAD: 'OVERLOAD',
            MAINTENANCE: 'MAINTENANCE'
        },

        RecordingInfoState: {
            INITIAL: 'INITIAL',             // no recording was ever started
            START_PENDING: 'START_PENDING', // recording was started, but recorder was disabled(e.g. by curtain). recording is not running
            STARTED: 'STARTED',             // recording was started and is running currently
            STOPPED: 'STOPPED',             // recording was stopped
            FINISHED: 'FINISHED'            // recording was finally stopped(on session terminate)
        },

        RecordingInfoReason: {
            NONE: 'NONE',
            STOPPED_MANUALLY: 'STOPPED_MANUALLY',
            STOPPED_AUTOMATICALLY: 'STOPPED_AUTOMATICALLY',
            NO_INPUT_TIMEOUT: 'NO_INPUT_TIMEOUT',
            MAX_INPUT_TIMEOUT: 'MAX_INPUT_TIMEOUT',
            NO_STREAMING_DATA: 'NO_STREAMING_DATA',
            LENGTH_LIMIT_REACHED: 'LENGTH_LIMIT_REACHED',
            NO_MORE_DISK_SPACE_LEFT: 'NO_MORE_DISK_SPACE_LEFT',
            UNKNOWN_ERROR: 'UNKNOWN_ERROR'
        },

        RecordingTriggerReason: {
            USER: 'USER',
            CURTAIN: 'CURTAIN'
        },

        RecordingVideoLayoutName: {
            SINGLE: 'SINGLE',
            VIDEO_SCREEN_50_50: 'VIDEO_SCREEN_50_50',
            VIDEO_SCREEN_75_25: 'VIDEO_SCREEN_75_25',
            VIDEO_SCREEN_25_75: 'VIDEO_SCREEN_25_75',
            SINGLE_VIDEO: 'SINGLE_VIDEO'
        },

        RtcClientInfoType: {
            OFFLINE_JOIN_FAILURE: 'OFFLINE_JOIN_FAILURE',
            DEVICE_DIAGNOSTICS: 'DEVICE_DIAGNOSTICS',
            CALL_CONNECTED: 'CALL_CONNECTED'
        },

        RtcClientInfoReason: {
            NO_NETWORK_CONNECTION: 'NO_NETWORK_CONNECTION',
            DISCONNECTED: 'DISCONNECTED',
            FAILED_TO_SEND: 'FAILED_TO_SEND',
            REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
            JOIN_DELAY: 'JOIN_DELAY',
            CALL_CONNECTED: 'CALL_CONNECTED'
        },

        RtcActionInfoType: {
            REQUEST_RESPONSE: 'REQUEST_RESPONSE',
            EVENT: 'EVENT'
        },

        RtcDiagnosticsAction: {
            // REQUEST_RESPONSE
            RENEW_TURN_CREDENTIALS: 'RENEW_TURN_CREDENTIALS',
            PREPARE: 'PREPARE',
            JOIN: 'JOIN',
            ICE_CANDIDATES: 'ICE_CANDIDATES',

            // EVENT
            SDP_CONNECTED: 'SDP_CONNECTED',
            SDP_ANSWER: 'SDP_ANSWER',
            REMOTE_ICE_CANDIDATES: 'REMOTE_ICE_CANDIDATES'
        },

        IncomingVideoContentType: {
            VIDEO: 'VIDEO',
            SCREEN: 'SCREEN'
        },

        VideoQuality: {
            LOW: 'LOW',
            NORMAL: 'NORMAL',
            HIGH: 'HIGH'
        },

        AudioQualityParam: {
            ECHO: 'ECHO',
            JITTER: 'JITTER',
            NO_PACKETS_RECV: 'NO_PACKETS_RECV',
            NO_PACKETS_SENT: 'NO_PACKETS_SENT',
            PL_RECV: 'PL_RECV',
            PL_SEND: 'PL_SEND',
            RTT: 'RTT'
        },

        VideoQualityParam: {
            BW_SEND: 'BW_SEND',
            FRAME_HEIGHT_SENT: 'FRAME_HEIGHT_SENT',
            FRAME_RATE_SENT: 'FRAME_RATE_SENT',
            FRAME_WIDTH_SENT: 'FRAME_WIDTH_SENT',
            PL_RECV: 'PL_RECV',
            PL_SEND: 'PL_SEND',
            RTT: 'RTT',
            TRX_BITRATE: 'TRX_BITRATE'
        },

        WhiteboardErrorCause: {
            EXTERNAL_URL_NOT_ALLOWED: 'EXTERNAL_URL_NOT_ALLOWED', // Failed to add not allowed external url for image
            IMAGE_SIZE_TOO_LARGE: 'IMAGE_SIZE_TOO_LARGE',         // An image was too large to be handled for PNG creation.
            IMAGE_DOWNLOAD_FAILED: 'IMAGE_DOWNLOAD_FAILED'        // An image could not be downloaded.
        },

        ///////////////////////////////////////////////////////////////////////////
        // Search Action
        ///////////////////////////////////////////////////////////////////////////
        SearchActionType: {
            START_ADVANCED_USER_SEARCH: 'START_ADVANCED_USER_SEARCH',
            START_BASIC_SEARCH: 'START_BASIC_SEARCH',
            START_DETAIL_SEARCH: 'START_DETAIL_SEARCH',
            START_USER_SEARCH: 'START_USER_SEARCH',
            CANCEL_SEARCH: 'CANCEL_SEARCH',
            ADD_RECENT_SEARCH: 'ADD_RECENT_SEARCH',
            SEARCH_CONVERSATION_PARTICIPANTS: 'SEARCH_CONVERSATION_PARTICIPANTS'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Search Event
        ///////////////////////////////////////////////////////////////////////////
        SearchEventType: {
            BASIC_SEARCH_RESULT: 'BASIC_SEARCH_RESULT',
            DETAILED_SEARCH_RESULT: 'DETAILED_SEARCH_RESULT',
            SEARCH_STATUS: 'SEARCH_STATUS',
            RECENT_SEARCH_ADDED: 'RECENT_SEARCH_ADDED'
        },

        ///////////////////////////////////////////////////////////////////////////
        // System Action
        ///////////////////////////////////////////////////////////////////////////
        SystemActionType: {
            GET_GLOBAL_PROPERTY: 'GET_GLOBAL_PROPERTY',
            GET_GLOBAL_PROPERTIES: 'GET_GLOBAL_PROPERTIES'
        },

        ///////////////////////////////////////////////////////////////////////////
        // System Event
        ///////////////////////////////////////////////////////////////////////////
        SystemEventType: {
            BROADCAST: 'BROADCAST',
            BROADCAST_REMOVED: 'BROADCAST_REMOVED',
            MAINTENANCE: 'MAINTENANCE',
            MAINTENANCE_REMOVED: 'MAINTENANCE_REMOVED',
            MAINTENANCE_ADDED: 'MAINTENANCE_ADDED'
        },

        ///////////////////////////////////////////////////////////////////////////
        // System Data
        ///////////////////////////////////////////////////////////////////////////
        GlobalPropertyName: {
            ATTACHMENT_UPLOAD_MAX_SIZE: 'ATTACHMENT_UPLOAD_MAX_SIZE',
            CONV_PARTICIPANTS_MAX_SIZE: 'CONV_PARTICIPANTS_MAX_SIZE',
            CONV_PARTICIPANTS_ADD_LIMIT: 'CONV_PARTICIPANTS_ADD_LIMIT',
            XMPP_FEDERATION_ENABLED: 'XMPP_FEDERATION_ENABLED',
            CLIENT_AUTOUPDATE_MAX_TIME: 'CLIENT_AUTOUPDATE_MAX_TIME',
            ADD_TELEPHONY_CONNECTOR_DISABLED: 'ADD_TELEPHONY_CONNECTOR_DISABLED',
            CHROME_EXTENSION_ID: 'CHROME_EXTENSION_ID',
            EDGE_EXTENSION_ID: 'EDGE_EXTENSION_ID',
            CONV_INCOMING_WEBHOOKS_MAX_SIZE: 'CONV_INCOMING_WEBHOOKS_MAX_SIZE',
            SHOW_PRESENCE_ON_ADD_USER: 'SHOW_PRESENCE_ON_ADD_USER',
            EXCHANGE_ONLINE_CONFIG: 'EXCHANGE_ONLINE_CONFIG'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Search Data
        ///////////////////////////////////////////////////////////////////////////
        SearchTypes: {
            CONVERSATION: 'CONVERSATION',
            USER: 'USER'
        },

        SearchStatusCode: {
            ERROR: 'ERROR',
            CANCELED: 'CANCELED',
            FINISHED: 'FINISHED',
            TIMEOUT: 'TIMEOUT',
            NO_RESULT: 'NO_RESULT',
            MORE_RESULT: 'MORE_RESULT'
        },

        SearchResultType: {
            TOPIC_MATCH: 'TOPIC_MATCH',
            USER_MATCH: 'USER_MATCH',
            ATTACHMENT_MATCH: 'ATTACHMENT_MATCH',
            ITEM_MATCH: 'ITEM_MATCH'
        },

        SearchScope: {
            ALL: 'ALL',
            PEOPLE: 'PEOPLE',
            MEMBERS: 'MEMBERS',
            MESSAGES: 'MESSAGES', // not supported anymore but still needed for saved recent searches
            CONVERSATIONS: 'CONVERSATIONS',
            FILES: 'FILES',
            SENT_BY: 'SENT_BY',
            DATE: 'DATE',
            LABEL: 'LABEL',
            FILTER: 'FILTER'
        },

        SpaceSearchScope: {
            ALL: 'ALL',
            SPACES: 'SPACES',
            TOPICBY: 'TOPICBY',
            FILES: 'FILES',
            TAGS: 'TAGS',
            LABEL: 'LABEL',
            DATE: 'DATE'
        },

        SearchContext: {
            USER: 'USER'
        },

        ///////////////////////////////////////////////////////////////////////////
        // User Action
        ///////////////////////////////////////////////////////////////////////////
        UserActionType: {
            LOGON: 'LOGON',
            LOGOUT: 'LOGOUT',
            RENEW_TOKEN: 'RENEW_TOKEN',
            GET_USER_BY_ID: 'GET_USER_BY_ID',
            UPDATE: 'UPDATE',
            GET_LOGGED_ON: 'GET_LOGGED_ON',
            GET_STUFF: 'GET_STUFF',
            SET_PRESENCE: 'SET_PRESENCE',
            GET_PRESENCE: 'GET_PRESENCE',
            SUBSCRIBE_PRESENCE: 'SUBSCRIBE_PRESENCE',
            UNSUBSCRIBE_PRESENCE: 'UNSUBSCRIBE_PRESENCE',
            CHANGE_PASSWORD: 'CHANGE_PASSWORD',
            GET_USERS_BY_IDS: 'GET_USERS_BY_IDS',
            GET_DEVICES: 'GET_DEVICES',
            GET_USER_SETTINGS: 'GET_USER_SETTINGS',
            SET_USER_SETTINGS: 'SET_USER_SETTINGS',
            GET_USER_BY_MAIL: 'GET_USER_BY_MAIL',
            GET_USERS_BY_MAILS: 'GET_USERS_BY_MAILS',
            EMAIL_UPDATE: 'EMAIL_UPDATE',
            GENERATE_EMAIL_UPDATE_TOKEN: 'GENERATE_EMAIL_UPDATE_TOKEN',
            GET_TELEPHONY_DATA: 'GET_TELEPHONY_DATA',
            GET_USER_EXTENSIONS: 'GET_USER_EXTENSIONS',
            SET_USER_EXTENSION_STATE: 'SET_USER_EXTENSION_STATE',
            GET_LDAP_TECHNICAL_USER_PRESENCE: 'GET_LDAP_TECHNICAL_USER_PRESENCE',
            GET_ATTACHMENTS: 'GET_ATTACHMENTS',
            SET_PASSWORD: 'SET_PASSWORD',
            GET_TENANT_SETTINGS: 'GET_TENANT_SETTINGS',
            OAUTH_GET_GRANTED_ACCESS_TOKENS: 'OAUTH_GET_GRANTED_ACCESS_TOKENS',
            OAUTH_REVOKE_ACCESS_TOKEN: 'OAUTH_REVOKE_ACCESS_TOKEN',
            REVOKE_MANAGED_DEVICES: 'REVOKE_MANAGED_DEVICES',
            GET_SECURITY_TOKEN_INFO: 'GET_SECURITY_TOKEN_INFO',
            REVOKE_SECURITY_TOKEN: 'REVOKE_SECURITY_TOKEN',
            RESET_OPENSCAPE_DEVICE_PINS: 'RESET_OPENSCAPE_DEVICE_PINS',
            SUBSCRIBE_TENANT_PRESENCE: 'SUBSCRIBE_TENANT_PRESENCE',
            UNSUBSCRIBE_TENANT_PRESENCE: 'UNSUBSCRIBE_TENANT_PRESENCE',
            GET_TENANT_ID_TO_NAME_MAP: 'GET_TENANT_ID_TO_NAME_MAP',
            ADD_RECENT_USED_CMR: 'ADD_RECENT_USED_CMR',
            GET_RECENT_USED_CMRS: 'GET_RECENT_USED_CMRS',
            IS_ALLOWED_TO_ADD_CMR: 'IS_ALLOWED_TO_ADD_CMR',
            GET_SUPPORT_DATA: 'GET_SUPPORT_DATA',

            // Used between clients and Access Server. Not part of official API.
            WAKE_UP: 'WAKE_UP',
            GO_TO_SLEEP: 'GO_TO_SLEEP',
            SET_CLIENT_CAPABILITIES: 'SET_CLIENT_CAPABILITIES'
        },

        ///////////////////////////////////////////////////////////////////////////
        // User Event
        ///////////////////////////////////////////////////////////////////////////
        UserEventType: {
            ATC_REFRESH_FAILED: 'ATC_REFRESH_FAILED', // Used between clients and Access Server. Not part of official API.
            USER_PRESENCE_CHANGE: 'USER_PRESENCE_CHANGE',
            USER_UPDATED: 'USER_UPDATED',
            SESSION_EXPIRES: 'SESSION_EXPIRES',
            SESSION_EXPIRING: 'SESSION_EXPIRING',
            SESSION_CLOSED: 'SESSION_CLOSED',
            PASSWORD_CHANGED: 'PASSWORD_CHANGED',
            USER_SETTING_UPDATED: 'USER_SETTING_UPDATED',
            TELEPHONY_DATA: 'TELEPHONY_DATA',
            DEVICE_LIST_CHANGED: 'DEVICE_LIST_CHANGED',
            NOTIFICATION_SUBSCRIPTION_CHANGE: 'NOTIFICATION_SUBSCRIPTION_CHANGE',
            TENANT_CONFIGURATION_UPDATED: 'TENANT_CONFIGURATION_UPDATED',
            UCAAS_DEVICE_UPDATED: 'UCAAS_DEVICE_UPDATED',
            TENANT_PRESENCE_CHANGE: 'TENANT_PRESENCE_CHANGE',
            TELEPHONY_CHANGED: 'TELEPHONY_CHANGED',
            EAGROUP_STATUS_CHANGED: 'EAGROUP_STATUS_CHANGED'
        },

        ///////////////////////////////////////////////////////////////////////////
        // User Data
        ///////////////////////////////////////////////////////////////////////////
        GetStuffType: {
            USER: 'USER',
            ACCOUNTS: 'ACCOUNTS',
            PRESENCE_STATE: 'PRESENCE_STATE',
            SETTINGS: 'SETTINGS',
            TELEPHONY_CONVERSATION_ID: 'TELEPHONY_CONVERSATION_ID',
            GLOBAL_PROPERTIES: 'GLOBAL_PROPERTIES',
            TENANT: 'TENANT',
            SUPPORT_INFO: 'SUPPORT_INFO'
        },

        IdleState: {
            Idle: 'Idle',
            Active: 'Active'
        },

        NotificationSubscriptionType: {
            ONLINE_STATUS: 'ONLINE_STATUS'
        },

        NotificationSubscriptionAction: {
            SUBSCRIBE: 'SUBSCRIBE',
            UNSUBSCRIBE: 'UNSUBSCRIBE'
        },

        DeviceType: {
            PHONE: 'PHONE',
            WEB: 'WEB',
            APPLICATION: 'APPLICATION',
            MOBILE: 'MOBILE',
            SDK: 'SDK',
            UNKNOWN: 'UNKNOWN',
            SESSION_GUEST: 'SESSION_GUEST'
        },

        PresenceState: {
            AVAILABLE: 'AVAILABLE',
            OFFLINE: 'OFFLINE',
            AWAY: 'AWAY',
            BUSY: 'BUSY',
            DND: 'DND',
            INVISIBLE: 'INVISIBLE',
            BUSY_IN_A_CALL: 'BUSY_IN_A_CALL',
            BUSY_IN_AN_EXTERNAL_CALL: 'BUSY_IN_AN_EXTERNAL_CALL'
        },

        ChangePasswordResult: {
            OK: 'OK',
            NEW_INVALID: 'NEW_INVALID',
            OLD_INVALID: 'OLD_INVALID',
            ALREADY_CHANGED: 'ALREADY_CHANGED',
            // Used when the CHANGE_PASSWORD action returns an error
            OPERATION_ERROR: 'OPERATION_ERROR'
        },

        SetPasswordResult: {
            OK: 'OK',
            NOT_OK: 'NOT_OK'
        },

        SessionClosedReason: {
            NEW_CONNECTION_DETECTED: 'NEW_CONNECTION_DETECTED',
            UPGRADE_REQUIRED: 'UPGRADE_REQUIRED',
            SUSPENDED: 'SUSPENDED',
            DELETED: 'DELETED',
            TENANT_DELETED: 'TENANT_DELETED',
            TENANT_SUSPENDED: 'TENANT_SUSPENDED',
            REVOKED: 'REVOKED'
        },

        // Reason in SESSION_RECORDING_STOPPED event
        RecordingStoppedReason: {
            LENGTH_LIMIT_REACHED: 'LENGTH_LIMIT_REACHED',
            NO_INPUT: 'NO_INPUT'
        },

        RecordingUploadState: {
            IN_PROGRESS: 'IN_PROGRESS',
            RETRYING: 'RETRYING',
            FINISHED: 'FINISHED',
            FAILED: 'FAILED',
            DELETED: 'DELETED'
        },

        UserRole: {
            GUEST: 'GUEST',
            USER: 'USER',
            TENANT_ADMIN: 'TENANT_ADMIN',
            SUPPORT: 'SUPPORT',
            SYSTEM_ADMIN: 'SYSTEM_ADMIN',
            TELEPHONY_CONNECTOR: 'TELEPHONY_CONNECTOR',
            VIRTUAL_TELEPHONY_CONNECTOR: 'VIRTUAL_TELEPHONY_CONNECTOR',
            SESSION_GUEST: 'SESSION_GUEST',
            BOT: 'BOT'
        },

        UserType: {
            REGULAR: 'REGULAR',
            GUEST: 'GUEST',
            SUPPORT: 'SUPPORT',
            TELEPHONY: 'TELEPHONY',
            SESSION_GUEST: 'SESSION_GUEST',
            TECHNICAL: 'TECHNICAL',
            BOT: 'BOT',
            XMPP: 'XMPP'
        },

        UserState: {
            /* User is just created or deleted */
            INACTIVE: 'INACTIVE',
            /* User is active (but may still have to register) */
            ACTIVE: 'ACTIVE',
            /* User is suspended */
            SUSPENDED: 'SUSPENDED',
            /* User is deleted */
            DELETED: 'DELETED',
            /* User is deleted and user data has been purged from DB */
            PURGED: 'PURGED'
        },

        Locale: {
            EN_US: 'EN_US',
            DE_DE: 'DE_DE',
            ES_ES: 'ES_ES',
            FR_FR: 'FR_FR',
            IT_IT: 'IT_IT',
            RU_RU: 'RU_RU',
            ZH_HANS_CN: 'ZH_HANS_CN',
            PT_BR: 'PT_BR',
            NL_NL: 'NL_NL',
            CA_ES: 'CA_ES'
        },

        PhoneNumberType: {
            WORK: 'WORK',
            MOBILE: 'MOBILE',
            HOME: 'HOME',
            FAX: 'FAX',
            OTHER: 'OTHER',
            UCAAS: 'UCAAS'
        },

        PhoneNumberNpi: {
            PUBLIC: 'PUBLIC',
            PRIVATE: 'PRIVATE'
        },

        EmailAddressType: {
            WORK: 'WORK',
            HOME: 'HOME',
            OTHER: 'OTHER'
        },

        UserSettingArea: {
            ALL: 'ALL',
            EMAIL: 'EMAIL',
            DESKTOP: 'DESKTOP',
            PRIVACY: 'PRIVACY',
            CLIENT: 'CLIENT'
        },

        UserSettingDataType: {
            BOOLEAN: 'BOOLEAN',
            NUMBER: 'NUMBER',
            STRING: 'STRING',
            TIMESTAMP: 'TIMESTAMP'
        },

        UserSettingKey: {
            EMAIL_INVITES: 'EMAIL_INVITES',
            EMAIL_MISSED_RTC: 'EMAIL_MISSED_RTC',
            EMAIL_SUMMARY: 'EMAIL_SUMMARY',
            EMAIL_UPDATE: 'EMAIL_UPDATE',
            DESKTOP_RTC: 'DESKTOP_RTC',
            DESKTOP_MISSED_MESSAGE: 'DESKTOP_MISSED_MESSAGE',
            DESKTOP_CONVERSATION_CHANGE: 'DESKTOP_CONVERSATION_CHANGE',
            DESKTOP_SYSINFO: 'DESKTOP_SYSINFO',
            SHARE_LOCATION: 'SHARE_LOCATION',
            RELEASE_NOTES_WEB_VERSION: 'RELEASE_NOTES_WEB_VERSION',
            RELEASE_NOTES_MOBILE_VERSION: 'RELEASE_NOTES_MOBILE_VERSION',
            AUDIO_WEB_SOUNDS: 'AUDIO_WEB_SOUNDS',
            MOBILE_ENABLE_VIDEO_WIFI_ONLY: 'MOBILE_ENABLE_VIDEO_WIFI_ONLY',
            FIRST_WEB_LOGIN_TIMESTAMP: 'FIRST_WEB_LOGIN_TIMESTAMP',
            VOICEMAIL_ENABLED: 'VOICEMAIL_ENABLED',
            VOICEMAIL_TIMEOUT: 'VOICEMAIL_TIMEOUT',
            STATUS_MESSAGE_TEXT: 'STATUS_MESSAGE_TEXT',
            OPT_OUT_PRESENCE: 'OPT_OUT_PRESENCE',
            SECOND_TELEPHONY_CALL_ROUTING: 'SECOND_TELEPHONY_CALL_ROUTING',
            ATC_ROUTING: 'ATC_ROUTING',
            VOICEMAIL_CUSTOMGREETING_ENABLED: 'VOICEMAIL_CUSTOMGREETING_ENABLED',
            VOICEMAIL_CUSTOMGREETING_URI: 'VOICEMAIL_CUSTOMGREETING_URI',
            DESKTOP_MESSAGE_NOTIFICATIONS_SETTING: 'DESKTOP_MESSAGE_NOTIFICATIONS_SETTING',
            MOBILE_MESSAGE_NOTIFICATIONS_SETTING: 'MOBILE_MESSAGE_NOTIFICATIONS_SETTING',
            PLAY_SOUND_MESSAGE_NOTIFICATIONS: 'PLAY_SOUND_MESSAGE_NOTIFICATIONS',
            PLAY_SOUND_RTC: 'PLAY_SOUND_RTC',
            PLAY_SYSTEM_SOUNDS: 'PLAY_SYSTEM_SOUNDS',
            ENHANCED_DESKTOP_MESSAGE_NOTIFICATION: 'ENHANCED_DESKTOP_MESSAGE_NOTIFICATION',
            ENHANCED_MOBILE_MESSAGE_NOTIFICATION: 'ENHANCED_MOBILE_MESSAGE_NOTIFICATION',
            HIDE_PROFILE_EXTERNAL_ENABLED: 'HIDE_PROFILE_EXTERNAL_ENABLED',
            PLAY_PICKUP_SOUND: 'PLAY_PICKUP_SOUND',
            KEYBOARD_SHORTCUTS_ENABLED: 'KEYBOARD_SHORTCUTS_ENABLED',
            ANONYMOUS_RTC_PARTICIPANT: 'ANONYMOUS_RTC_PARTICIPANT',
            VOICEMAIL_NO_RECORDING: 'VOICEMAIL_NO_RECORDING',
            VOICEMAIL_CUSTOMGREETINGS: 'VOICEMAIL_CUSTOMGREETINGS',
            EMERGENCY_CALL_DISCLAIMER: 'EMERGENCY_CALL_DISCLAIMER',
            MSTEAMS_APPLICATION_ID: 'MSTEAMS_APPLICATION_ID'
        },

        MessageNotificationsSetting: {
            ALL: 0,
            DIRECT_AND_MENTIONS: 1,
            NONE: 2
        },

        EnhancedMessageNotificationsSetting: {
            ALL: -1,
            NONE: 0,
            DIRECT: 1,
            MENTION: 2,
            FAVORITE: 4
        },

        TelephonyChangeType: {
            ADDED: 'ADDED',
            UPDATED: 'UPDATED',
            REMOVED: 'REMOVED'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Administration Data
        ///////////////////////////////////////////////////////////////////////////
        SupportType: {
            CONVERSATION: 'CONVERSATION',
            NO_SUPPORT: 'NO_SUPPORT',
            EMAIL: 'EMAIL',
            PARTNER_ADMIN_EMAIL: 'PARTNER_ADMIN_EMAIL'
        },

        TenantSettingsType: {
            CREDENTIALS_LOGIN_ENABLED: 'CREDENTIALS_LOGIN_ENABLED',
            TELEPHONY_CONFIG_CONVERSATION: 'TELEPHONY_CONFIG_CONVERSATION',
            CLIENT_AUTO_UPDATE: 'CLIENT_AUTO_UPDATE',
            HELP_URL: 'HELP_URL',
            SDK_USAGE_ENABLED: 'SDK_USAGE_ENABLED',
            OAUTH_ACCESS_ENABLED: 'OAUTH_ACCESS_ENABLED',
            LOGIN_METHODS_AVAILABLE: 'LOGIN_METHODS_AVAILABLE',
            DATA_RETENTION_PERIOD: 'DATA_RETENTION_PERIOD',
            PLUGIN_SELF_MANAGED_ENABLED: 'PLUGIN_SELF_MANAGED_ENABLED',
            PLUGIN_DESKTOP_APP_ENABLED: 'PLUGIN_DESKTOP_APP_ENABLED',
            PLUGIN_IE_PLUGIN_ENABLED: 'PLUGIN_IE_PLUGIN_ENABLED',
            PLUGIN_MICROSOFT_OUTLOOK_INTEGRATION_ENABLED: 'PLUGIN_MICROSOFT_OUTLOOK_INTEGRATION_ENABLED',
            PLUGIN_CHROME_EXTENSION_ENABLED: 'PLUGIN_CHROME_EXTENSION_ENABLED',
            EXTENSION_MICROSOFT_EXCHANGE_ENABLED: 'EXTENSION_MICROSOFT_EXCHANGE_ENABLED',
            EXTENSION_BOX_ENABLED: 'EXTENSION_BOX_ENABLED',
            EXTENSION_GOOGLE_DRIVE_ENABLED: 'EXTENSION_GOOGLE_DRIVE_ENABLED',
            EXTENSION_SYNCPLICITY_ENABLED: 'EXTENSION_SYNCPLICITY_ENABLED',
            EXTENSION_JABRA_ENABLED: 'EXTENSION_JABRA_ENABLED',
            EXTENSION_OPEN_XCHANGE_ENABLED: 'EXTENSION_OPEN_XCHANGE_ENABLED',
            EXTENSION_PLANTRONICS_ENABLED: 'EXTENSION_PLANTRONICS_ENABLED',
            LOOKUP_API_BLACKLISTED_DOMAINS: 'LOOKUP_API_BLACKLISTED_DOMAINS',
            ROOT_DEVICES_ALLOWED: 'ROOT_DEVICES_ALLOWED',
            DT_LEGAL_INFORMATION: 'DT_LEGAL_INFORMATION',
            SSO_REDIRECT_URL: 'SSO_REDIRECT_URL',
            EXTENSION_ONE_DRIVE_ENABLED: 'EXTENSION_ONE_DRIVE_ENABLED',
            EXTENSION_SKYPE_FOR_BUSINESS_ENABLED: 'EXTENSION_SKYPE_FOR_BUSINESS_ENABLED',
            SKYPE4B_CLIENT_ID: 'SKYPE4B_CLIENT_ID',
            SKYPE4B_ROOT_URL: 'SKYPE4B_ROOT_URL',
            C4O_AUTO_UPDATE: 'C4O_AUTO_UPDATE',
            DA_AUTO_UPDATE: 'DA_AUTO_UPDATE',
            EXTENSION_SENNHEISER_ENABLED: 'EXTENSION_SENNHEISER_ENABLED',
            MUTE_ON_ENTRY: 'MUTE_ON_ENTRY',
            MUTE_ON_ENTRY_CMR: 'MUTE_ON_ENTRY_CMR',
            MUTE_ON_ENTRY_OUTCALL: 'MUTE_ON_ENTRY_OUTCALL',
            C4O_PRESENCE_SETTINGS: 'C4O_PRESENCE_SETTINGS',
            EXTENSION_ZAPIER_ENABLED: 'EXTENSION_ZAPIER_ENABLED',
            CLOUD_ISTP_DRAFT_NUMBER_LIMIT_ENABLED: 'CLOUD_ISTP_DRAFT_NUMBER_LIMIT_ENABLED',
            REMOTE_CONTROL_ENABLED: 'REMOTE_CONTROL_ENABLED',
            REMOTE_CONTROL_EXTERNAL_ENABLED: 'REMOTE_CONTROL_EXTERNAL_ENABLED',
            EXTENSION_EMBRAVA_ENABLED: 'EXTENSION_EMBRAVA_ENABLED',
            EXTENSION_CONCEPTBOARD_ENABLED: 'EXTENSION_CONCEPTBOARD_ENABLED',
            DATA_RETENTION_STRATEGY: 'DATA_RETENTION_STRATEGY',
            PLANTRONICS_HUB_DOWNLOAD_URL: 'PLANTRONICS_HUB_DOWNLOAD_URL',
            INCOMING_WEBHOOKS_ENABLED: 'INCOMING_WEBHOOKS_ENABLED',
            CONVERSATION_RETENTION_POLICY_CHANGEABLE: 'CONVERSATION_RETENTION_POLICY_CHANGEABLE',
            EXTENSION_GOOGLE_CONTACTS_ENABLED: 'EXTENSION_GOOGLE_CONTACTS_ENABLED',
            EXTENSION_JPL_ENABLED: 'EXTENSION_JPL_ENABLED',
            SCREENSHARE_DISABLED: 'SCREENSHARE_DISABLED',
            FILESHARE_DISABLED: 'FILESHARE_DISABLED',
            TRANSCRIPTION_ENABLED: 'TRANSCRIPTION_ENABLED',
            TRANSCRIPTION_GOOGLE_API_KEY: 'TRANSCRIPTION_GOOGLE_API_KEY',
            USER_CONTROLLED_WEBRTC_POLICY_ENABLED: 'USER_CONTROLLED_WEBRTC_POLICY_ENABLED',
            DATA_RETENTION_RECORDING_PERIOD: 'DATA_RETENTION_RECORDING_PERIOD',
            DATA_RETENTION_EXTENDED_RECORDING_PERIOD: 'DATA_RETENTION_EXTENDED_RECORDING_PERIOD',
            GLOBAL_SPACE_CREATION_ENABLED: 'GLOBAL_SPACE_CREATION_ENABLED',
            ALLOW_EXTERNAL_SPACES: 'ALLOW_EXTERNAL_SPACES',
            TRANSCRIPTION_LOCALES: 'TRANSCRIPTION_LOCALES',
            EXTENSION_MEISTERTASK_ENABLED: 'EXTENSION_MEISTERTASK_ENABLED',
            MASS_OPENSCAPE_USER_MANAGEMENT: 'MASS_OPENSCAPE_USER_MANAGEMENT',
            FAQ_URL: 'FAQ_URL',
            SPACE_DEFAULT_TYPE: 'SPACE_DEFAULT_TYPE',
            ANONYMOUS_PARTICIPANTS_ENABLED: 'ANONYMOUS_PARTICIPANTS_ENABLED',
            EXTENSION_KUANDO_ENABLED: 'EXTENSION_KUANDO_ENABLED',
            EXTENSION_C4G_ENABLED: 'EXTENSION_C4G_ENABLED',
            PASSWORD_POLICY_CONFIG_JSON: 'PASSWORD_POLICY_CONFIG_JSON',
            IC_SUPPORT_PHONE_NUMBER: 'IC_SUPPORT_PHONE_NUMBER',
            IC_SUPPORT_EMAIL_ADDRESS: 'IC_SUPPORT_EMAIL_ADDRESS',
            IC_SUPPORT_OPERATING_HOURS_FROM: 'IC_SUPPORT_OPERATING_HOURS_FROM',
            IC_SUPPORT_OPERATING_HOURS_TO: 'IC_SUPPORT_OPERATING_HOURS_TO',
            IC_SUPPORT_OPERATING_HOURS_TIMEZONE: 'IC_SUPPORT_OPERATING_HOURS_TIMEZONE',
            IC_USER_MANUAL_URL: 'IC_USER_MANUAL_URL',
            IC_SAFETY_NOTICE_URL: 'IC_SAFETY_NOTICE_URL',
            IC_ADDITIONAL_TERMS_OF_SERVICE_URL: 'IC_ADDITIONAL_TERMS_OF_SERVICE_URL',
            EXTENSION_C4C_ENABLED: 'EXTENSION_C4C_ENABLED',
            EXTENSION_C4O365_ENABLED: 'EXTENSION_C4O365_ENABLED',
            SPACE_FEDERATION: 'SPACE_FEDERATION',
            CONVERSATION_FEDERATION: 'CONVERSATION_FEDERATION',
            PRESENCE_FEDERATION: 'PRESENCE_FEDERATION',
            EXTERNAL_COMMUNICATION_BLOCKED: 'EXTERNAL_COMMUNICATION_BLOCKED',
            EVENT_ORGANIZER_CONFIG: 'EVENT_ORGANIZER_CONFIG',
            CALL_QUALITY_RATING_PERCENTAGE: 'CALL_QUALITY_RATING_PERCENTAGE',
            EXTENSION_PLANTRONICS_STATUS_ENABLED: 'EXTENSION_PLANTRONICS_STATUS_ENABLED',
            USER_BY_EMAIL_FEDERATION: 'USER_BY_EMAIL_FEDERATION',
            EXTENSION_C4TEAMS_ENABLED: 'EXTENSION_C4TEAMS_ENABLED',
            EXTENSION_MICROSOFT_SHAREPOINT_ENABLED: 'EXTENSION_MICROSOFT_SHAREPOINT_ENABLED',
            EXTENSION_LOGITECH_ENABLED: 'EXTENSION_LOGITECH_ENABLED',
            SPACE_STATISTIC_LINK_ENABLED: 'SPACE_STATISTIC_LINK_ENABLED',
            EXTENSION_PLATHOSYS_ENABLED: 'EXTENSION_PLATHOSYS_ENABLED',
            UNIFY_OFFICE_MIGRATION: 'UNIFY_OFFICE_MIGRATION',
            EXTENSION_GIGASET_ION_ENABLED: 'EXTENSION_GIGASET_ION_ENABLED',
            READ_ONLY_MODE: 'READ_ONLY_MODE',
            EXCHANGE_ONLINE_CLIENT_ID: 'EXCHANGE_ONLINE_CLIENT_ID',
            EXCHANGE_ONLINE_REDIRECT_URI: 'EXCHANGE_ONLINE_REDIRECT_URI'
        },

        TenantConfigurableTextType: {
            GUEST_ACCESS_ACCEPTANCE_TEXT: 'GUEST_ACCESS_ACCEPTANCE_TEXT',
            USER_INVITE_ACCEPTANCE_TEXT: 'USER_INVITE_ACCEPTANCE_TEXT'
        },

        TrunkState: {
            UP: 'UP',
            DOWN: 'DOWN',
            NOT_REQUESTED: 'NOT_REQUESTED',
            REQUESTED: 'REQUESTED',
            SUSPENDING: 'SUSPENDING'
        },

        GtcTrunkType: {
            GTC_TRUNK_TYPE: 'GTC_TRUNK_TYPE',
            ETC_TRUNK_TYPE: 'ETC_TRUNK_TYPE',
            ATC_TRUNK_TYPE: 'ATC_TRUNK_TYPE',
            EMBEDDED_TC_TRUNK_TYPE: 'EMBEDDED_TC_TRUNK_TYPE',
            OSBIZ_TRUNK_TYPE: 'OSBIZ_TRUNK_TYPE',
            SUB_TRUNK_TYPE: 'SUB_TRUNK_TYPE'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Third Party Connectors
        ///////////////////////////////////////////////////////////////////////////
        ThirdPartyConnectorsActionType: {
            GET_SETTINGS: 'GET_SETTINGS',
            SAVE_SETTINGS: 'SAVE_SETTINGS',
            GET_CONNECTION_STATUS: 'GET_CONNECTION_STATUS'
        },

        ThirdPartyConnectorType: {
            EXCHANGE_CONNECTOR: 'EXCHANGE_CONNECTOR'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Third Party Content types
        ///////////////////////////////////////////////////////////////////////////
        MsGraphDataTypes: {
            FILE: 'file',
            FOLDER: 'folder',
            DRIVE: 'drive',
            SITE: 'site',
            CONTENT: 'content',
            SUBSITES: 'subsites'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Guest Action
        ///////////////////////////////////////////////////////////////////////////
        GuestActionType: {
            VALIDATE_SESSION_INVITE_TOKEN: 'VALIDATE_SESSION_INVITE_TOKEN',
            REGISTER_SESSION_GUEST: 'REGISTER_SESSION_GUEST',
            GET_REGIONS: 'GET_REGIONS',
            GET_JOINING_INSTRUCTIONS: 'GET_JOINING_INSTRUCTIONS',
            VALIDATE_SESSION_PIN: 'VALIDATE_SESSION_PIN'
        },

        SessionInviteInfoResult: {
            INVALID: 'INVALID',
            VALID_NOSESSION: 'VALID_NOSESSION',
            VALID_SESSION: 'VALID_SESSION',
            VALID_NO_GUEST_ACCESS: 'VALID_NO_GUEST_ACCESS'
        },

        ///////////////////////////////////////////////////////////////////////////
        // User to User info Action
        ///////////////////////////////////////////////////////////////////////////
        UserRoutingMessageType: {
            ATC: 'ATC',
            CMP: 'CMP',
            CONTACT_CARD: 'CONTACT_CARD',
            DESKTOP_APP: 'DESKTOP_APP',
            SDK: 'SDK',
            STC: 'STC',
            TEAM_PICKUP: 'TEAM_PICKUP'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Version Action
        ///////////////////////////////////////////////////////////////////////////
        VersionActionType: {
            GET_VERSION: 'GET_VERSION' // Message between client and access server
        },

        ///////////////////////////////////////////////////////////////////////////
        // Account Action
        ///////////////////////////////////////////////////////////////////////////
        AccountActionType: {
            GET_PACKAGE_TEMPLATES: 'GET_PACKAGE_TEMPLATES',
            GET_ASSIGNED_PACKAGES: 'GET_ASSIGNED_PACKAGES',
            VALIDATE_ASSIGN_ACCOUNT_TEMPLATE: 'VALIDATE_ASSIGN_ACCOUNT_TEMPLATE',
            ASSIGN_ACCOUNT_TEMPLATE: 'ASSIGN_ACCOUNT_TEMPLATE',
            VALIDATE_MIGRATE_ACCOUNT: 'VALIDATE_MIGRATE_ACCOUNT',
            MIGRATE_ACCOUNT: 'MIGRATE_ACCOUNT',
            MIGRATE_MULTIPLE_USERS: 'MIGRATE_MULTIPLE_USERS',
            GET_ACCOUNTS: 'GET_ACCOUNTS',
            GET_SEARCH_TAGS: 'GET_SEARCH_TAGS',
            SUSPEND_ACCOUNT: 'SUSPEND_ACCOUNT',
            UNSUSPEND_ACCOUNT: 'UNSUSPEND_ACCOUNT',
            ASSIGN_TELEPHONY_CONFIGURATION: 'ASSIGN_TELEPHONY_CONFIGURATION',
            DELETE_TELEPHONY_CONFIGURATION: 'DELETE_TELEPHONY_CONFIGURATION',
            GET_ACCOUNT_BY_ID: 'GET_ACCOUNT_BY_ID',
            GET_ACCOUNT_BY_USER_ID: 'GET_ACCOUNT_BY_USER_ID',
            GET_TECHNICAL_ADMIN_USER_ID: 'GET_TECHNICAL_ADMIN_USER_ID',
            RENEW_ASSOCIATED_TELEPHONY_USER: 'RENEW_ASSOCIATED_TELEPHONY_USER',
            ADD_ACCOUNT_PERMISSION: 'ADD_ACCOUNT_PERMISSION',
            REMOVE_ACCOUNT_PERMISSION: 'REMOVE_ACCOUNT_PERMISSION',
            REMOVE_ALTERNATIVE_NUMBER: 'REMOVE_ALTERNATIVE_NUMBER'
        },

        ///////////////////////////////////////////////////////////////////////////
        // UserData Action
        ///////////////////////////////////////////////////////////////////////////
        UserDataActionType: {
            GET_USER_DATA: 'GET_USER_DATA'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Account Event
        ///////////////////////////////////////////////////////////////////////////
        AccountEventType: {
            MIGRATE_MULTIPLE_USERS: 'MIGRATE_MULTIPLE_USERS',
            TELEPHONY_CONFIGURATION_UPDATED: 'TELEPHONY_CONFIGURATION_UPDATED',
            TC_BALANCING_NEEDED: 'TC_BALANCING_NEEDED',
            TC_START_REASSIGNMENT: 'TC_START_REASSIGNMENT',
            TELEPHONY_PREVIOUS_ALTERNATIVE_NUMBERS_UPDATED: 'TELEPHONY_PREVIOUS_ALTERNATIVE_NUMBERS_UPDATED'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Account Data
        ///////////////////////////////////////////////////////////////////////////

        // The templateName is defined as a string in the protobuf.
        // The definition below only includes the core template names that the client
        // needs to know about. The USER and ADMIN account names have been created
        // for backwards compatibility to match with the previous roles concept. The
        // clients should avoid adding any logic based on these template names since they
        // could change in the future.
        AccountTemplateName: {
            USER: 'USER',
            ADMIN: 'ADMIN'
        },

        AccountStatus: {
            NONE: 'NONE',
            CREATED: 'CREATED',
            INITIAL: 'INITIAL',
            INVITED: 'INVITED',
            ACTIVE: 'ACTIVE',
            SUSPENDED: 'SUSPENDED',
            DELETED: 'DELETED'
        },

        GetAccountsSorting: {
            BY_FIRST_NAME: 'BY_FIRST_NAME',
            BY_LAST_NAME: 'BY_LAST_NAME',
            BY_NAME: 'BY_NAME',
            BY_PRIMARY_EMAIL: 'BY_PRIMARY_EMAIL',
            BY_PHONE_NUMBER: 'BY_PHONE_NUMBER',
            BY_LAST_LOGIN: 'BY_LAST_LOGIN',
            BY_LOCATION: 'BY_LOCATION'
        },

        GetAccountsFilterCriteria: {
            PRIMARY_EMAIL: 'PRIMARY_EMAIL',
            FIRST_NAME: 'FIRST_NAME',
            LAST_NAME: 'LAST_NAME',
            DISPLAY_NAME: 'DISPLAY_NAME',
            STATUS: 'STATUS',
            TAG: 'TAG',
            TELEPHONY_USER_ID: 'TELEPHONY_USER_ID',
            TELEPHONY_TRUNK_GROUP_ID: 'TELEPHONY_TRUNK_GROUP_ID'
        },

        SystemPermission: {
            IGNORE: 'IGNORE',
            INVITE_USER: 'INVITE_USER',
            ROLE_TELEPHONY_CONNECTOR: 'ROLE_TELEPHONY_CONNECTOR',
            ROLE_VIRTUAL_TELEPHONY_CONNECTOR: 'ROLE_VIRTUAL_TELEPHONY_CONNECTOR',
            ROLE_TECHNICAL: 'ROLE_TECHNICAL',
            ROLE_SESSION_GUEST: 'ROLE_SESSION_GUEST',
            ROLE_USER: 'ROLE_USER',
            ROLE_TENANT_ADMIN: 'ROLE_TENANT_ADMIN',
            ROLE_SUPPORT: 'ROLE_SUPPORT',
            ROLE_SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
            ROLE_SYSTEM_USER: 'ROLE_SYSTEM_USER',
            ROLE_SYSTEM: 'ROLE_SYSTEM',
            MANAGE_TENANT: 'MANAGE_TENANT',
            TELEPHONY: 'TELEPHONY',
            RECORDING: 'RECORDING',
            GUEST_ACCESS: 'GUEST_ACCESS',
            MAX_PACKAGE_ACCOUNTS: 'MAX_PACKAGE_ACCOUNTS',
            FREE_TRIAL: 'FREE_TRIAL',
            USER_STORAGE: 'USER_STORAGE',
            INTEGRATIONS: 'INTEGRATIONS',
            RTC_PARTICIPANTS: 'RTC_PARTICIPANTS',
            ACCOUNTS: 'ACCOUNTS',
            IE_PLUGIN_SUPPORT: 'IE_PLUGIN_SUPPORT',
            SUPPORT_CONVERSATION: 'SUPPORT_CONVERSATION',
            SUPPORT_FORUM: 'SUPPORT_FORUM',
            OUTLOOK_PLUGIN: 'OUTLOOK_PLUGIN',
            TENANT_STORAGE: 'TENANT_STORAGE',
            LDAP_AGENT: 'LDAP_AGENT',
            INVITE_PARTNER: 'INVITE_PARTNER',
            LARGE_CONFERENCE: 'LARGE_CONFERENCE',
            CREATE_COMMUNITY: 'CREATE_COMMUNITY',
            CONTENT_MODERATOR: 'CONTENT_MODERATOR',
            SDK_ACCESS: 'SDK_ACCESS',
            OAUTH_ACCESS_ENABLED: 'OAUTH_ACCESS_ENABLED',
            SDK_USAGE_ENABLED: 'SDK_USAGE_ENABLED',
            BASIC_AUTHENTICATION: 'BASIC_AUTHENTICATION',
            ENTITY_ACCESS_DENIED: 'ENTITY_ACCESS_DENIED',
            ROLE_BOT: 'ROLE_BOT',
            NO_SECURITY_CONTEXT: 'NO_SECURITY_CONTEXT',
            NO_OAUTH_SCOPE_ACCESS: 'NO_OAUTH_SCOPE_ACCESS',
            RTC_WHITE_BOARD: 'RTC_WHITE_BOARD',
            SYSTEM_ANALYTICS: 'SYSTEM_ANALYTICS',
            VENDOR_ANALYTICS: 'VENDOR_ANALYTICS',
            TENANT_ANALYTICS: 'TENANT_ANALYTICS',
            USER_ANALYTICS: 'USER_ANALYTICS',
            UCAAS: 'UCAAS',
            DEVELOPER_CONSOLE_ACCESS: 'DEVELOPER_CONSOLE_ACCESS',
            DEVELOPER_CONSOLE_VIEW: 'DEVELOPER_CONSOLE_VIEW',
            DEVELOPER_CONSOLE_MANAGE_USER: 'DEVELOPER_CONSOLE_MANAGE_USER',
            S4B_INTEGRATION: 'S4B_INTEGRATION',
            TENANT_PRESENCE_SUBSCRIPTION: 'TENANT_PRESENCE_SUBSCRIPTION',
            CONCURRENT_VIDEO_CHANNELS: 'CONCURRENT_VIDEO_CHANNELS',
            WORKFLOW_INTEGRATIONS: 'WORKFLOW_INTEGRATIONS',
            CLV_SUPERADMIN: 'CLV_SUPERADMIN',
            UCAAS_MLHG_QUEUING: 'UCAAS_MLHG_QUEUING',
            DISTRIBUTOR_ANALYTICS: 'DISTRIBUTOR_ANALYTICS',
            RESELLER_ANALYTICS: 'RESELLER_ANALYTICS',
            CLV_ACCESS: 'CLV_ACCESS',
            MAX_OAUTH_APPS: 'MAX_OAUTH_APPS',
            MAX_OAUTH_BOTS: 'MAX_OAUTH_BOTS',
            CREATE_TENANT: 'CREATE_TENANT',
            MAX_INCOMING_WEBHOOKS: 'MAX_INCOMING_WEBHOOKS',
            HD_VIDEO: 'HD_VIDEO',
            HD_SCREENSHARE: 'HD_SCREENSHARE',
            MAX_PRESENCE_SUBSCRIPTIONS: 'MAX_PRESENCE_SUBSCRIPTIONS',
            SPACE_CREATION: 'SPACE_CREATION',
            SPACE_MASTER: 'SPACE_MASTER',
            UCAAS_EA: 'UCAAS_EA',
            ANALYTICS: 'ANALYTICS',
            VIEW_OPEN_CONVERSATION: 'VIEW_OPEN_CONVERSATION',
            IE_PLUGIN_SUPPORT_LIMITATION: 'IE_PLUGIN_SUPPORT_LIMITATION',
            ROLE_MEETING_POINT: 'ROLE_MEETING_POINT',
            EVENT_ORGANIZER_BASIC: 'EVENT_ORGANIZER_BASIC',
            ORGANIZE_EVENTS: 'ORGANIZE_EVENTS',
            EVENT_ORGANIZER_XS: 'EVENT_ORGANIZER_XS',
            EVENT_ORGANIZER_S: 'EVENT_ORGANIZER_S',
            EVENT_ORGANIZER_M: 'EVENT_ORGANIZER_M',
            EVENT_ORGANIZER_L: 'EVENT_ORGANIZER_L',
            REST_ADMIN_API: 'REST_ADMIN_API',
            VOICEMAIL: 'VOICEMAIL',
            TRANSCRIPTION: 'TRANSCRIPTION'
        },

        PermissionType: {
            TENANT_QUOTA: 'TENANT_QUOTA',
            USER_QUOTA: 'USER_QUOTA',
            FEATURE: 'FEATURE',
            OVERALL_PACKAGE_QUOTA: 'OVERALL_PACKAGE_QUOTA',
            OVERALL_PACKAGE_FEATURE: 'OVERALL_PACKAGE_FEATURE',
            RESTRICTION: 'RESTRICTION'
        },

        MigrateMultipleStatusCode: {
            RUNNING: 'RUNNING',
            FINISHED: 'FINISHED'
        },

        MigrateResponseCode: {
            NULL_PARAMETER: 'NULL_PARAMETER',
            ACCOUNT_DOES_NOT_EXIST: 'ACCOUNT_DOES_NOT_EXIST',
            TECHNICAL_ACCOUNT_NOT_ALLOWED: 'TECHNICAL_ACCOUNT_NOT_ALLOWED',
            ACCOUNT_TEMPLATE_DOES_NOT_EXIST: 'ACCOUNT_TEMPLATE_DOES_NOT_EXIST',
            ILLEGAL_ACCOUNT_TEMPLATE_ASSIGNMENT: 'ILLEGAL_ACCOUNT_TEMPLATE_ASSIGNMENT',
            MAXIMUM_ACCOUNTS_REACHED: 'MAXIMUM_ACCOUNTS_REACHED',
            ERROR: 'ERROR',
            OK: 'OK',
            OK_NO_CHANGES: 'OK_NO_CHANGES'
        },

        FilesSortBy: {
            FILE_NAME: 'FILE_NAME',
            CREATION_TIME: 'CREATION_TIME',
            FILE_SIZE: 'FILE_SIZE',
            MIME_TYPE: 'MIME_TYPE',
            FILE_EXTENSION: 'FILE_EXTENSION'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Integrations framework (CPaaS area)
        ///////////////////////////////////////////////////////////////////////////
        TenantIntegrationState: {
            ACTIVE: 'ACTIVE',
            DISABLED: 'DISABLED',
            SHUTDOWN: 'SHUTDOWN',
            STARTUP: 'STARTUP'
        },

        IntegrationKeys: {
            MEISTERTASK: 'MEISTERTASK',
            INCOMING_WEBHOOKS: 'INCOMING-WEBHOOKS'
        },

        MeistertaskIntegrationType: {
            GET_USER_PROJECTS: 'GET_USER_PROJECTS'
        },

        ///////////////////////////////////////////////////////////////////////////
        // Internal definitions
        ///////////////////////////////////////////////////////////////////////////
        LabFeatureName: {
            CONTACTS: 'CONTACTS',
            NEW_CALL_STAGE: 'NEW_CALL_STAGE',
            PICKUP_GROUP: 'PICKUP_GROUP',
            AUTHENTICATION: 'AUTHENTICATION',
            TENANT_DATA_RETENTION: 'TENANT_DATA_RETENTION',
            MS_TEAMS_PLUGIN: 'MS_TEAMS_PLUGIN',
            TENANT_DATA_EXPORT: 'TENANT_DATA_EXPORT'
        },

        AvatarBadgeType: {
            COMMUNITY: 'COMMUNITY',
            EVENT: 'EVENT',
            EXPERT: 'EXPERT'
        },

        // Device categories for device selection
        AudioVideoDeviceCategories: {
            AUDIO_OUTPUT: 'AUDIO_OUTPUT',
            RINGING_OUTPUT: 'RINGING_OUTPUT',
            AUDIO_INPUT: 'AUDIO_INPUT',
            AUDIO_DEVICE: 'AUDIO_DEVICE',
            VIDEO_INPUT: 'VIDEO_INPUT'
        },

        // Transcription
        RecordingState: {
            IDLE: 'IDLE',
            LISTENING: 'LISTENING',
            PROCESSING: 'PROCESSING'
        },

        TranscriptionError: {
            RECORDING_NOT_SUPPORTED: 'RECORDING_NOT_SUPPORTED',
            RECORDING_NOT_STARTED: 'RECORDING_NOT_STARTED',
            RECORDING_IN_PROGRESS: 'RECORDING_IN_PROGRESS',
            RECORDING_ABORTED: 'RECORDING_ABORTED',
            INVALID_CALL: 'INVALID_CALL',
            INVALID_PROVIDER: 'INVALID_PROVIDER'
        },

        ///////////////////////////////////////////////////////////////////////////
        // ATC capabilities
        ///////////////////////////////////////////////////////////////////////////
        AtcCapabilities: {
            EXTENDED_ALERTING: 'EXTENDED_ALERTING',
            CLEAR_CONNECTION_BUSY: 'CLEAR_CONNECTION_BUSY',
            PBX_CALL_LOG: 'PBX_CALL_LOG'
        },

        ///////////////////////////////////////////////////////////////////////////
        // STC capabilities
        ///////////////////////////////////////////////////////////////////////////
        StcCapabilities: {
            STC_TRANSFER: 'STC_TRANSFER',
            STC_MERGE: 'STC_MERGE'
        },

        ///////////////////////////////////////////////////////////////////////////
        // TC available features
        ///////////////////////////////////////////////////////////////////////////
        TcAvailableFeatures: {
            HOLD: 'HOLD',
            CONSULTATION: 'CONSULTATION',
            MERGE: 'MERGE',
            TRANSFER: 'TRANSFER'
        }
    });

    var Proto = {
        requestNr: 0,
        Request: function (contentType, content, tenantContext) {
            Proto.requestNr++;

            var msg = {
                msgType: Constants.WSMessageType.REQUEST,
                request: {
                    requestId: Proto.requestNr,
                    type: contentType
                }
            };
            if (tenantContext) {
                msg.request.tenantContext = tenantContext;
            }

            switch (contentType) {
            case Constants.ContentType.CONVERSATION:
                msg.request.conversation = content;
                break;
            case Constants.ContentType.USER:
                msg.request.user = content;
                break;
            case Constants.ContentType.RTC_CALL:
                msg.request.rtcCall = content;
                break;
            case Constants.ContentType.RTC_SESSION:
                msg.request.rtcSession = content;
                break;
            case Constants.ContentType.SEARCH:
                msg.request.search = content;
                break;
            case Constants.ContentType.INSTRUMENTATION:
                msg.request.instrumentation = content;
                break;
            case Constants.ContentType.SYSTEM:
                msg.request.system = content;
                break;
            case Constants.ContentType.THIRDPARTYCONNECTORS:
                msg.request.thirdpartyconnectors = content;
                break;
            case Constants.ContentType.GUEST:
                msg.request.guest = content;
                break;
            case Constants.ContentType.USER_TO_USER:
                msg.request.userToUser = content;
                break;
            case Constants.ContentType.VERSION:
                msg.request.version = content;
                break;
            case Constants.ContentType.ACCOUNT:
                msg.request.account = content;
                break;
            case Constants.ContentType.USER_DATA:
                msg.request.userData = content;
                break;
            }
            return msg;
        },
        // ParseEvent parses the event from the server to:
        // a) build the event name the services subscribed to, and
        // b) flatten the event object a bit to better suit the services
        // eslint-disable-next-line complexity, max-lines-per-function
        ParseEvent: function (event) { // NOSONAR
            var evtName;
            var evtData = {};

            switch (event.type) {
            // CONVERSATION
            case Constants.ContentType.CONVERSATION:
                evtName = 'Conversation.' + event.conversation.type;
                switch (event.conversation.type) {
                case Constants.ConversationEventType.CREATE:
                    evtData = event.conversation.create;
                    break;
                case Constants.ConversationEventType.UPDATE:
                    evtData = event.conversation.update;
                    break;
                case Constants.ConversationEventType.ADD_ITEM:
                    evtData = event.conversation.addItem;
                    break;
                case Constants.ConversationEventType.ADD_PARTICIPANT:
                    evtData = event.conversation.addParticipant;
                    break;
                case Constants.ConversationEventType.UPDATE_ITEM:
                    evtData = event.conversation.updateItem;
                    break;
                case Constants.ConversationEventType.READ_ITEMS:
                    evtData = event.conversation.readItems;
                    break;
                case Constants.ConversationEventType.TYPING:
                    evtData = event.conversation.typing;
                    break;
                case Constants.ConversationEventType.DRAFT_MESSAGE_SAVED:
                    evtData = event.conversation.draftMessage;
                    break;
                case Constants.ConversationEventType.DRAFT_MESSAGE_DISCARDED:
                    evtData = event.conversation.draftMessage;
                    break;
                case Constants.ConversationEventType.DRAFT_MESSAGE_PUBLISHED:
                    evtData = event.conversation.draftMessage;
                    break;
                case Constants.ConversationEventType.FLAG_ITEM:
                    evtData = event.conversation.setFlagItem;
                    break;
                case Constants.ConversationEventType.CLEAR_FLAGGED_ITEM:
                    evtData = event.conversation.clearFlaggedItem;
                    break;
                case Constants.ConversationEventType.CONVERSATION_MARKED:
                    evtData = event.conversation.conversationMarked;
                    break;
                case Constants.ConversationEventType.CONVERSATION_UNMARKED:
                    evtData = event.conversation.conversationUnmarked;
                    break;
                case Constants.ConversationEventType.FAVORITE_POSITION_CHANGED:
                    evtData = event.conversation.favoritePositionChangedEvent;
                    break;
                case Constants.ConversationEventType.USER_DATA_CHANGED:
                    evtData = event.conversation.userDataChangedEvent;
                    break;
                case Constants.ConversationEventType.JOURNAL_ENTRY_DELETED:
                    evtData = event.conversation.journalEntryDeletedEvent;
                    break;
                case Constants.ConversationEventType.ALL_JOURNAL_ENTRIES_DELETED:
                    evtData = event.conversation.allJournalEntriesDeleted;
                    break;
                }
                evtData.convId = event.conversation.convId;
                break;

            // USER
            case Constants.ContentType.USER:
                evtName = 'User.' + event.user.type;
                switch (event.user.type) {
                case Constants.UserEventType.USER_PRESENCE_CHANGE:
                    evtData = event.user.presenceChanged;
                    break;
                case Constants.UserEventType.USER_UPDATED:
                    evtData = event.user.userUpdated;
                    break;
                case Constants.UserEventType.SESSION_CLOSED:
                    evtData = event.user.sessionClosed;
                    break;
                case Constants.UserEventType.PASSWORD_CHANGED:
                    evtData = event.user.passwordChanged;
                    break;
                case Constants.UserEventType.USER_SETTING_UPDATED:
                    evtData = event.user.userSettingUpdated;
                    break;
                case Constants.UserEventType.TELEPHONY_DATA:
                    evtData = event.user.telephonyData;
                    break;
                case Constants.UserEventType.DEVICE_LIST_CHANGED:
                    evtData = event.user.deviceListChangedEvent;
                    break;
                case Constants.UserEventType.NOTIFICATION_SUBSCRIPTION_CHANGE:
                    evtData = event.user.notificationSubscriptionChange;
                    break;
                case Constants.UserEventType.TENANT_CONFIGURATION_UPDATED:
                    evtData = event.user.tenantConfigurationUpdatedEvent;
                    break;
                case Constants.UserEventType.TENANT_PRESENCE_CHANGE:
                    evtData = event.user.tenantPresenceChanged;
                    break;
                case Constants.UserEventType.TELEPHONY_CHANGED:
                    evtData = event.user.telephonyChangedEvent;
                    break;
                }
                evtData.userId = event.user.userId;
                break;

            // RTC_CALL
            case Constants.ContentType.RTC_CALL:
                evtName = 'RTCCall.' + event.rtcCall.type;
                switch (event.rtcCall.type) {
                case Constants.RTCCallEventType.SDP_ANSWER:
                    evtData = event.rtcCall.sdpAnswer;
                    break;
                case Constants.RTCCallEventType.SDP_FAILED:
                    evtData = event.rtcCall.sdpFailed;
                    break;
                case Constants.RTCCallEventType.INVITE:
                    evtData = event.rtcCall.invite;
                    break;
                case Constants.RTCCallEventType.INVITE_CANCEL:
                    evtData = event.rtcCall.inviteCancel;
                    break;
                case Constants.RTCCallEventType.INVITE_FAILED:
                    evtData = event.rtcCall.inviteFailed;
                    break;
                case Constants.RTCCallEventType.CHANGE_MEDIA_TYPE_REQUESTED:
                    evtData = event.rtcCall.changeMediaRequested;
                    break;
                case Constants.RTCCallEventType.PROGRESS:
                    evtData = event.rtcCall.participantProgress;
                    break;
                case Constants.RTCCallEventType.CHANGE_MEDIA_TYPE_FORCED:
                    evtData = event.rtcCall.changeMediaTypeForcedEvent;
                    break;
                case Constants.RTCCallEventType.ICE_CANDIDATES:
                    evtData = event.rtcCall.iceCandidates;
                    break;
                case Constants.RTCCallEventType.RTC_QUALITY_RATING_EVENT:
                    evtData = event.rtcCall.ratingEvent;
                    break;
                }
                evtData.sessionId = event.rtcCall.sessionId;
                evtData.instanceId = event.rtcCall.instanceId;
                break;

            // RTC_SESSION
            case Constants.ContentType.RTC_SESSION:
                evtName = 'RTCSession.' + event.rtcSession.type;
                switch (event.rtcSession.type) { // NOSONAR
                case Constants.RTCSessionEventType.SESSION_STARTED:
                    evtData = event.rtcSession.sessionStarted;
                    break;
                case Constants.RTCSessionEventType.SESSION_TERMINATED:
                    evtData = event.rtcSession.sessionTerminated;
                    break;
                case Constants.RTCSessionEventType.SESSION_UPDATED:
                    evtData = event.rtcSession.sessionUpdated;
                    break;
                case Constants.RTCSessionEventType.SESSION_MOVED:
                    evtData = event.rtcSession.sessionMoved;
                    break;
                case Constants.RTCSessionEventType.PARTICIPANT_JOINED:
                    evtData = event.rtcSession.participantJoined;
                    break;
                case Constants.RTCSessionEventType.PARTICIPANT_LEFT:
                    evtData = event.rtcSession.participantLeft;
                    break;
                case Constants.RTCSessionEventType.PARTICIPANT_UPDATED:
                    evtData = event.rtcSession.participantUpdated;
                    break;
                case Constants.RTCSessionEventType.ACTIVE_SPEAKER:
                    evtData = event.rtcSession.speaker;
                    break;
                case Constants.RTCSessionEventType.VIDEO_ACTIVE_SPEAKER:
                    evtData = event.rtcSession.videoSpeaker;
                    break;
                case Constants.RTCSessionEventType.SESSION_RECORDING_INFO:
                    evtData = event.rtcSession.sessionRecordingInfo;
                    break;
                case Constants.RTCSessionEventType.SESSION_RECORDING_STARTED:
                    evtData = event.rtcSession.sessionRecordingStarted;
                    break;
                case Constants.RTCSessionEventType.SESSION_RECORDING_STOPPED:
                    evtData = event.rtcSession.sessionRecordingStopped;
                    break;
                case Constants.RTCSessionEventType.SESSION_RECORDING_FAILED:
                    evtData = event.rtcSession.sessionRecordingFailed;
                    break;
                case Constants.RTCSessionEventType.SESSION_TERMINATION_TIMER_STARTED:
                    evtData = event.rtcSession.sessionTerminationTimerStarted;
                    break;
                case Constants.RTCSessionEventType.SESSION_TERMINATION_TIMER_CANCELLED:
                    evtData = event.rtcSession.sessionTerminationTimerCancelled;
                    break;
                case Constants.RTCSessionEventType.LIVE_TRANSCRIPTION:
                    evtData = event.rtcSession.liveTranscriptionEvent;
                    break;
                }
                evtData.sessionId = event.rtcSession.sessionId;
                break;

            // SEARCH
            case Constants.ContentType.SEARCH:
                evtName = 'Search.' + event.search.type;
                switch (event.search.type) {
                case Constants.SearchEventType.BASIC_SEARCH_RESULT:
                    evtData = event.search.basicSearchResult;
                    break;
                case Constants.SearchEventType.DETAILED_SEARCH_RESULT:
                    evtData = event.search.detailSearchResult;
                    break;
                case Constants.SearchEventType.SEARCH_STATUS:
                    evtData = event.search.searchStatus;
                    break;
                case Constants.SearchEventType.RECENT_SEARCH_ADDED:
                    evtData = event.search.recentSearchAdded;
                    break;
                }
                evtData.searchId = event.search.searchId;
                break;

            // SYSTEM
            case Constants.ContentType.SYSTEM:
                evtName = 'System.' + event.system.type;
                switch (event.system.type) {
                case Constants.SystemEventType.BROADCAST:
                    evtData = event.system.systemNotificationEvent;
                    evtData.eventId = event.system.eventId;
                    break;
                case Constants.SystemEventType.MAINTENANCE:
                case Constants.SystemEventType.MAINTENANCE_ADDED:
                    evtData = event.system.maintenance;
                    evtData.eventId = event.system.eventId;
                    break;
                case Constants.SystemEventType.BROADCAST_REMOVED:
                case Constants.SystemEventType.MAINTENANCE_REMOVED:
                    // Event has no data
                    evtData = event.system.eventId;
                    break;
                }
                break;

            // USER_TO_USER
            case Constants.ContentType.USER_TO_USER:
                evtName = 'UserToUser.' + event.userToUser.userToUser.type;
                // The event handling is the same for all USER_TO_USER events
                evtData = event.userToUser.userToUser;
                evtData.routing = event.userToUser.routing;
                break;

            // ACCOUNT
            case Constants.ContentType.ACCOUNT:
                evtName = 'Account.' + event.account.type;
                switch (event.account.type) {
                case Constants.AccountEventType.MIGRATE_MULTIPLE_USERS:
                    evtData = event.account.migrateMultipleUsers;
                    break;
                case Constants.AccountEventType.TELEPHONY_CONFIGURATION_UPDATED:
                    evtData = event.account.telephonyConfigurationUpdated;
                    break;
                case Constants.AccountEventType.TC_START_REASSIGNMENT:
                    evtData = event.account.tcStartReassignment;
                    break;
                case Constants.AccountEventType.TELEPHONY_PREVIOUS_ALTERNATIVE_NUMBERS_UPDATED:
                    evtData = event.account.previousAlternativeNumbersUpdated;
                    break;
                }
                break;

            default:
                return null;
            }

            return {name: evtName, data: evtData};
        },
        getMediaType: function (data) {
            if (!data) {
                return {audio: false, video: false, desktop: false};
            }

            // data is an array of RealtimeMediaType ('AUDIO', 'VIDEO', 'DESKTOP_SHARING')
            var mediaType = {audio: false, video: false, desktop: false};
            data.forEach(function (elem) {
                switch (elem) {
                case Constants.RealtimeMediaType.AUDIO:
                    mediaType.audio = true;
                    break;
                case Constants.RealtimeMediaType.VIDEO:
                    mediaType.video = true;
                    break;
                case Constants.RealtimeMediaType.DESKTOP_SHARING:
                    mediaType.desktop = true;
                    break;
                }
            });
            return mediaType;
        },
        isOfflineFailure: function (err) {
            return err === Constants.ReturnCode.DISCONNECTED ||
                err === Constants.ReturnCode.FAILED_TO_SEND ||
                err === Constants.ReturnCode.REQUEST_TIMEOUT;
        }
    };

    // Exports
    circuit.Constants = Constants;
    circuit.Proto = Proto;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
