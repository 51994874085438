var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function PopupSvcImpl($rootScope, $uibModal, $location, PubSubSvc, LogSvc) { // NOSONAR
        LogSvc.debug('New Service: PopupSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables and Initializations
        ///////////////////////////////////////////////////////////////////////////////////////
        var _self = this;

        function openModal(options) {
            if ((options.templateUrl === 'views/modals/genericDialogModal.html') && !options.title) {
                // If the generic modal doesn't have a title then only populate aria-describedby attribute
                options.ariaData = {ariaDescribedBy: null};
            }

            var ariaData = options.ariaData;

            // We don't always want to fill in both aria properties (aria-labelledby and aria-describedby).
            // If we want to add only one then we need to pass an option property named ariaData.
            // e.g.: options.ariaData = {ariaLabelledBy: null};
            if (typeof ariaData === 'object') {
                ['ariaLabelledBy', 'ariaDescribedBy'].forEach(function (propertyName) {
                    if (ariaData.hasOwnProperty(propertyName)) {
                        // Use value from options.ariaData or options or autogenerate.
                        options[propertyName] = ariaData[propertyName] || options[propertyName] || Utils.rstring();
                    } else {
                        delete options[propertyName];
                    }
                });
            } else {
                // No other configuration found, populate both (original values or random strings)
                options.ariaLabelledBy = options.ariaLabelledBy || Utils.rstring();
                options.ariaDescribedBy = options.ariaDescribedBy || Utils.rstring();
            }

            var a11yAttrs = function () {
                return {
                    ariaLabelledBy: options.ariaLabelledBy,
                    ariaDescribedBy: options.ariaDescribedBy
                };
            };
            options.resolve = options.resolve || { a11yAttrs: a11yAttrs };
            if (!angular.isFunction(options.resolve.a11yAttrs)) {
                options.resolve.a11yAttrs = a11yAttrs;
            }

            var data = $uibModal.open(options);
            if (options.backdrop !== false) {
                data.opened.then(function () {
                    PubSubSvc.publish('/popup/opened');
                });

                data.result
                .catch(function () {
                    // Handle reject to prevent 'Possibly unhandled rejection' error
                })
                .finally(function () {
                    PubSubSvc.publish('/popup/closed');
                });
            }
            return data;
        }

        function getClasslist(action, alert) {
            var classList = [];
            if (!action && !alert) {
                classList.push('read-only');
            }
            return classList.join(' ');
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Used to display custom modals.
         * @param options {object} Options to configure the modal. Same API as $dialog directly
         * @example: PopupSvc.openCustomModal({
         *     backdropClick: true,
         *     templateUrl: 'views/modals/releaseNotes.html',
         *     controller: 'ReleaseNotesCtrl',
         *     resolve = {
         *       releaseNotes: function () {
         *         return releaseNotes;
         *       }
         *     }
         *  });
         */
        this.openCustomModal = function (options) {
            if (!options.templateUrl && !options.component) {
                return null;
            }
            LogSvc.debug('PopupSvc.openCustomModal: ' + options.templateUrl);
            return openModal(options);
        };

        /**
         * Used to display generic modals.
         * @param options {object} Options to configure the modal.
         * @options.templateUrl {String} The template to be used by the modal.
         * @options.controller {String} The controller to be used by the modal.
         * @options.message {String} The message to be displayed in the modal body. This may also be a resource name.
         * @options.title {String} The title to be displayed in the modal header.
         * @options.messageParams {Array} Message-specific localization parameters.
         * @options.titleParams {Array} Title-specific localization parameters.
         * @options.size {String} The modal's size. Can be large, medium, small, mini.
         * @options.scrollBox {Boolean} True if the message should be displayed in the scrollbox area
         * @options.backdrop {Boolean} Controls presence of a backdrop.
         * @options.backdropClass {String} A custom backdrop class.
         * @example: PopupSvc.openGenericModal({
         *     title: 'res_TermsOfService',
         *     message: LegalTexts[$rootScope.localUser.locale].terms,
         *     size: 'large',
         *     scrollBox: true,
         *     backdrop: true
         *  });
         */
        this.openGenericModal = function (options) {
            if (!options.message) {
                return null;
            }
            var genericOptions = {
                templateUrl: options.templateUrl || 'views/modals/genericDialogModal.html',
                controller: options.controller || 'GenericDialogCtrl',
                title: options.title,
                titleParams: options.titleParams,
                message: options.message,
                messageParams: options.messageParams,
                type: 'generic',
                action: options.action || false, // indicates that the modal should display yes/no buttons
                yesLabel: options.yesLabel || undefined,
                noLabel: options.noLabel || undefined,
                size: options.size || 'large',
                scrollBox: options.scrollBox,
                backdrop: !options.hasOwnProperty('backdrop') || options.backdrop === 'true' ? 'modal-backdrop' : options.backdrop,
                backdropClass: options.backdropClass,
                messageClickAction: options.messageClickAction,
                modalClasslist: function () {
                    return getClasslist(options.action, options.alert);
                },
                resolve: {options: function () { return genericOptions; }}
            };
            LogSvc.debug('PopupSvc.openGenericModal: ' + genericOptions.message);
            return openModal(genericOptions);
        };

        /**
         * Used to display info messages.
         * @param options {object} Options to configure the modal.
         * @options.id {String} Unique ID that identifies this message in case it needs to be closed
         * @options.message {String} The info message to be displayed in the modal body.
         * @options.messageParams {Array} Message-specific localization parameters.
         * @options.manual {Boolean} If true, then a dialog object will be returned.
         * @options.yesLabel {String} Text of additional button.
         * @options.noLabel {String} Text of additional button.
         * @options.infoYesClickAction {Function} Action performed on yesLabel click.
         * @options.infoNoClickAction {Function} Action performed on noLabel click.
         * @options.messageClickAction {Function} Action performed on link click inside popup.
         * @options.onManualClose {Function} Action performed when user closes popup.
         * @options.className {string} unique class name of the popup
         * @example PopupSvc.info({message: 'res_PoorConnectionQuality', manual: true});
         */
        this.info = function (options) {
            if (options.message) {
                PubSubSvc.publish('/infoMessage/show', [options]);
            }
        };

        this.closeInfo = function (messageId) {
            PubSubSvc.publish('/infoMessage/close', [messageId]);
        };

        /**
         * Used to display error messages (may be with confirm/cancel buttons).
         * @param options {object} Options to configure the modal.
         * @options.templateUrl {String} The template to be used by the modal.
         * @options.controller {String} The controller to be used by the modal.
         * @options.message {String} The error message to be displayed in the modal body.
         * @options.messageParams {Array} Message-specific localization parameters.
         * @options.title {String} The error title to be displayed in the modal header.
         * @options.titleParams {Array} Title-specific localization parameters.
         * @options.action {Boolean} Indicates that the modal should display yes/no buttons. Defaults to 'false'.
         * @options.yesLabel {String} Text (label) for the 'yes' button. Defaults to 'Yes'.
         * @options.noLabel {String} Text (label) for the 'no' button. Defaults to 'No'.
         * @options.size {String} The modal's size.
         * @options.backdropClass {String} A custom backdrop class.
         * @options.backdrop {String|Boolean} Controls presence of a backdrop.
         * @example PopupSvc.error({message: 'res_InvalidFileType', title: 'Invalid File Type Error', size:'medium', backdrop: false});
         */
        this.error = function (options) {
            if (!options.message) {
                return null;
            }
            var errorOptions = {
                templateUrl: options.templateUrl || 'views/modals/genericDialogModal.html',
                controller: options.controller || 'GenericDialogCtrl',
                windowClass: 'modal-error',
                title: options.title || 'res_ErrorTitle', // remove check when all title headers are implemented
                titleParams: options.titleParams,
                message: options.message,
                messageParams: options.messageParams,
                type: 'error', // needed in genericModal.html
                action: options.action || false, // indicates that the modal should display yes/no buttons
                yesLabel: options.yesLabel || 'res_Yes',
                noLabel: options.noLabel || (options.noLabel === undefined ? 'res_No' : null),
                size: options.size || 'small',
                backdropClass: options.backdropClass,
                backdrop: !options.hasOwnProperty('backdrop') || options.backdrop === 'true' ? 'modal-backdrop' : options.backdrop,
                messageClickAction: options.messageClickAction,
                modalClasslist: function () {
                    return getClasslist(this.action, options.alert);
                },
                resolve: {options: function () { return errorOptions; }}
            };
            LogSvc.error('PopupSvc.error: ' + errorOptions.message);
            return openModal(errorOptions);
        };

        /**
         * Used to display confirm modals (modals with confrim/cancel buttons).
         * @param options {object} Options to configure the modal.
         * @options.templateUrl {String} The template to be used by the modal.
         * @options.controller {String} The controller to be used by the modal.
         * @options.message {String} The error message to be displayed in the modal body.
         * @options.title {String} The error title to be displayed in the modal header.
         * @options.messageParams {Array} Message-specific localization parameters.
         * @options.titleParams {Array} Title-specific localization parameters.
         * @options.size {String} The modal's size.
         * @options.yesLabel {String} Text (label) for the 'yes' button. Defaults to 'Yes'.
         * @options.noLabel {String} Text (label) for the 'no' button. Defaults to 'No'.
         * @options.scrollBox {Boolean} Controlls the rpesence of a scrollBox area in the modal.
         * @options.backdropClass {String} A custom backdrop class.
         * @options.backdrop {String|Boolean} Controls presence of a backdrop.
         * @example PopupSvc.confirm({
            message: 'res_UnloadActiveCall',
                yesLabel: 'res_Yes',
                noLabel: 'res_No',
            });
         */
        this.confirm = function (options) {
            if (!options.message) {
                return null;
            }
            var confirmOptions = {
                templateUrl: options.templateUrl || 'views/modals/genericDialogModal.html',
                controller: options.controller || 'GenericDialogCtrl',
                windowClass: options.windowClass,
                title: options.title,
                titleParams: options.titleParams,
                message: options.message,
                messageParams: options.messageParams,
                type: 'confirm',
                action: true, // indicates that the modal should display yes/no buttons
                size: options.size || 'medium',
                yesLabel: options.yesLabel || 'res_Yes',
                noLabel: options.noLabel || (options.noLabel === undefined ? 'res_No' : null),
                scrollBox: options.scrollBox,
                backdropClass: options.backdropClass,
                backdrop: !options.hasOwnProperty('backdrop') || options.backdrop === 'true' ? 'modal-backdrop' : options.backdrop,
                keyboard: options.keyboard || true,
                timeOut: options.timeOut,
                modalClasslist: function () {
                    return getClasslist(this.action, options.alert);
                },
                resolve: {options: function () { return confirmOptions; }}
            };
            LogSvc.debug('PopupSvc.confirm: ', confirmOptions.message);
            return openModal(confirmOptions);
        };

        /**
         * Used to display alert modals (modals with ok button).
         * @param options {object} Options to configure the modal.
         * @options.templateUrl {String} The template to be used by the modal.
         * @options.controller {String} The controller to be used by the modal.
         * @options.message {String} The error message to be displayed in the modal body.
         * @options.title {String} The error title to be displayed in the modal header.
         * @options.messageParams {Array} Message-specific localization parameters.
         * @options.titleParams {Array} Title-specific localization parameters.
         * @options.size {String} The modal's size.
         * @options.btnok {Boolean} Controls the display of the ok button.
         * @options.okLabel {String} Text (label) for the 'ok' button. Defaults to 'Ok'.
         * @options.scrollBox {Boolean} Controlls the presence of a scrollBox area in the modal.
         * @options.backdropClass {String} A custom backdrop class.
         * @options.backdrop {String|Boolean} Controls presence of a backdrop.
         * @example PopupSvc.alert({
                title: 'res_SystemIssue',
                message: 'res_BackendThrottleError'
            });
         */
        this.alert = function (options) {
            if (!options.message) {
                return null;
            }
            var alertOptions = {
                templateUrl: options.templateUrl || 'views/modals/genericDialogModal.html',
                controller: options.controller || 'GenericDialogCtrl',
                title: options.title,
                titleParams: options.titleParams,
                message: options.message,
                messageParams: options.messageParams,
                type: 'generic',
                size: options.size || 'small',
                alert: true, // indicates that the modal should display ok button
                okLabel: options.okLabel || 'res_OK',
                scrollBox: options.scrollBox,
                backdropClass: options.backdropClass,
                backdrop: !options.hasOwnProperty('backdrop') || options.backdrop === 'true' ? 'modal-backdrop' : options.backdrop,
                modalClasslist: function () {
                    return getClasslist(options.action, this.alert);
                },
                resolve: {options: function () { return alertOptions; }}
            };
            LogSvc.debug('PopupSvc.confirm: ', alertOptions.message);
            return $uibModal.open(alertOptions);
        };

        /**
         * Used as callback to most call control function to handle error and warning messages as needed.
         */
        this.handleCallError = function (error, warn) {
            if (!error) {
                if (warn) {
                    _self.info({message: warn, timeOut: 5000});
                }
                return;
            }
            if (error === Constants.ReturnCode.CHOOSE_DESKTOP_MEDIA_CANCELLED) {
                // This is an internal error in case the user cancels the screen share media selection. Just ignore it.
                return;
            }
            if (error.startsWith('res_')) {
                var options = {message: error};
                if (error === 'res_RestartBrowser') {
                    options.messageParams = [$location.absUrl()];
                } else if (error.startsWith('res_TransferCallFailedCause')) {
                    options.title = 'res_TransferCallFailed';
                } else if (error.startsWith('res_PickUpCallFailedCause')) {
                    options.title = 'res_PickUpCallFailed';
                }

                _self.error(options);
            } else {
                LogSvc.error('[PopupSvc]: Internal call error: ', error);
            }
        };

        this.handleCallInputAccessError = function (error, warning, onContinueCb, onErrorCb) {
            onErrorCb = onErrorCb || _self.handleCallError;

            // List of possible mic access error messages
            var micAccessErrorList = [
                'res_AccessToAudioInputDeviceFailedPermissionDenied',
                'res_AccessToAudioInputDeviceFailedInUse',
                'res_AccessToAudioInputDeviceFailed'
            ];
            if (onContinueCb && micAccessErrorList.includes(error)) {
                // Ask the user if they want to proceed without their mic
                _self.confirm({
                    message: 'res_AccessToInputDeviceFailedPromptUserContinue',
                    title: 'res_AccessToInputDeviceFailedPromptUserContinueTitle',
                    yesLabel: 'res_Continue',
                    noLabel: 'res_Cancel'
                }).result
                .then(function () {
                    LogSvc.warn('[PopupSvc]: Error accessing microphone, user chose to proceed with call. Error: ', error);
                    onContinueCb();
                })
                .catch(function () {
                    // Handle reject to prevent 'Possibly unhandled rejection' error
                });
            } else {
                // It's not a mic related problem, use the supplied error handler or _self.handleCallError()
                onErrorCb(error, warning);
            }
        };

        this.confirmDeletion = function (message, messageParams) {
            return _self.confirm({
                message: message,
                messageParams: messageParams,
                title: 'res_ConfirmDeletion',
                yesLabel: 'res_Yes',
                noLabel: 'res_Cancel'
            }).result;
        };

        this.handleApiError = function (err) {
            if (!err) {
                return;
            }
            var title = 'res_ErrorTitle';
            var message;
            switch (err) {
            case Constants.ErrorCode.CONSTRAINT_VIOLATION:
                title = 'res_ActionDenied';
                message = 'res_ConstraintErrorMessage';
                break;
            case Constants.ReturnCode.DISCONNECTED:
            case Constants.ReturnCode.FAILED_TO_SEND:
                message = 'res_NotConnectedTryAgain';
                break;
            default:
                message = 'res_GenericError';
            }

            _self.error({
                title: title,
                message: message,
                size: 'mini'
            });
        };

        this.moveConversationModal = function (conversation) {
            var options = {
                component: 'uMoveUnifyOffice',
                ariaData: {ariaLabelledBy: null},
                resolve: {
                    options: function () {
                        return {
                            conversation: conversation
                        };
                    }
                }
            };
            _self.openCustomModal(options);
        };


        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.PopupSvcImpl = PopupSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
