/*global RegistrationState*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Enums = circuit.Enums;

    // eslint-disable-next-line max-params, max-lines-per-function
    function CallAssociatedNotificationsSvcImpl( // NOSONAR
        $rootScope,
        $timeout,
        LogSvc,
        PubSubSvc,
        PopupSvc,
        CallControlSvc,
        DeviceHandlerSvc) {

        LogSvc.debug('New Service: CallAssociatedNotificationsSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var TELEPHONY_DOWN_TIMEOUT = 5000;

        var CallWarningType = Object.freeze({
            RTP_STATS: 'RTP_STATS',
            OUTPUT_DEVICE: 'OUTPUT_DEVICE'
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _telephonyDownTimer = null;
        var _telephonyStatus = null;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function showInfo(options) {
            options = options || {};
            var activeCall;
            if (!options.call) {
                activeCall = CallControlSvc.getActiveCall();
                if (!options.callId || !activeCall || activeCall.callId !== options.callId) {
                    LogSvc.warn('[CallAssociatedNotificationsSvc]: No or different active call. Do not show warning message:', options);
                    return;
                }
            } else {
                activeCall = options.call;
            }
            if (!options.type || !options.res) {
                LogSvc.warn('[CallAssociatedNotificationsSvc]: Missing parameters. Do not show warning message:', options);
                return;
            }
            var rememberField;
            if (options.rememberDismissed) {
                switch (options.type) {
                case CallWarningType.RTP_STATS:
                    rememberField = 'doNotReportQualityIssues';
                    break;
                case CallWarningType.OUTPUT_DEVICE:
                    rememberField = 'doNotReportAudioDeviceIssues';
                    break;
                }
                if (!rememberField || activeCall[rememberField]) {
                    // User already dismissed this message. Don't show again
                    return;
                }
            }
            var popupOptions = {
                id: 'id_Active' + options.type + 'Warning_' + activeCall.callId,
                message: options.res,
                manual: true
            };
            if (options.resParams) {
                popupOptions.messageParams = options.resParams;
            }

            if (options.rememberDismissed) {
                popupOptions.onManualClose = function () {
                    // User dismissed modal manually, don't show it again for this call
                    activeCall[rememberField] = true;
                    LogSvc.info('[CallAssociatedNotificationsSvc]: User dismissed ' + options.type + ' warning message. Do not show again for this call.');
                };
            }
            PopupSvc.info(popupOptions);
        }

        function closeInfo(options) {
            options = options || {};
            if (!options.callId) {
                return;
            }
            if (options.type) {
                PopupSvc.closeInfo('id_Active' + options.type + 'Warning_' + options.callId);
                return;
            }
            // Close all warning messages related to this call
            Object.keys(CallWarningType).forEach(function (key) {
                PopupSvc.closeInfo('id_Active' + CallWarningType[key] + 'Warning_' + options.callId);
            });
        }

        function publishTelephonyAvailability() {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Publish /telephony/availability event: ', _telephonyStatus);
            PubSubSvc.publish('/telephony/availability', [!!_telephonyStatus]);
        }

        function cancelTelephonyDownTimer() {
            if (_telephonyDownTimer) {
                $timeout.cancel(_telephonyDownTimer);
                _telephonyDownTimer = null;
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        function onCallRtpStatsWarnRaised(callId) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/rtp/threshholdExceeded/detected');
            showInfo({
                type: CallWarningType.RTP_STATS,
                callId: callId,
                res: 'res_PoorConnectionQuality',
                rememberDismissed: true
            });
        }
        PubSubSvc.subscribe('/call/rtp/threshholdExceeded/detected', onCallRtpStatsWarnRaised);

        function onCallRtpStatsWarnCleared(callId) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/rtp/threshholdExceeded/cleared');
            closeInfo({callId: callId, type: CallWarningType.RTP_STATS});
        }
        PubSubSvc.subscribe('/call/rtp/threshholdExceeded/cleared', onCallRtpStatsWarnCleared);

        function onCallNoOutgoingRtpPackets(callId) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/rtp/noOutgoingPackets');
            // Dismiss the current warning message and show a new one
            closeInfo({callId: callId, type: CallWarningType.RTP_STATS});
            showInfo({
                type: CallWarningType.RTP_STATS,
                callId: callId,
                res: 'res_NoOutgoingRtpPackets'
            });
        }
        PubSubSvc.subscribe('/call/rtp/noOutgoingPackets', onCallNoOutgoingRtpPackets);

        function onCallIceConnectionState(callId, state) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/rtp/iceConnectionState event, state:', state);
            closeInfo({callId: callId, type: CallWarningType.RTP_STATS});
            if (state === 'disconnected') {
                showInfo({
                    type: CallWarningType.RTP_STATS,
                    callId: callId,
                    res: 'res_RTCIceDisconnected'
                });
            }
        }
        PubSubSvc.subscribe('/call/rtp/iceConnectionState', onCallIceConnectionState);

        function onCallOutputDeviceFailed() {
            var activeCall = CallControlSvc.getActiveCall();
            if (activeCall) {
                LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/devices/audio/outputFailed event');
                var devName = $rootScope.i18n.localize(activeCall.playbackDevice || 'res_Unknown');
                showInfo({
                    type: CallWarningType.OUTPUT_DEVICE,
                    call: activeCall,
                    res: 'res_CallOutputDeviceCouldNotBeSelected',
                    resParams: [devName],
                    rememberDismissed: true
                });
            }
        }
        PubSubSvc.subscribe('/call/devices/audio/outputFailed', onCallOutputDeviceFailed);

        function onCallEnded(call) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/ended event');
            closeInfo({callId: call.callId});
        }
        PubSubSvc.subscribe('/call/ended', onCallEnded);

        function onSendDtmfFailed(err, digit) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/sendDtmf/failed event');
            PopupSvc.info({
                message: err,
                messageParams: [digit]
            });
        }
        PubSubSvc.subscribe('/call/sendDtmf/failed', onSendDtmfFailed);

        function onSendDtmfSucceeded() {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/singleDigitSent event');
            DeviceHandlerSvc.playBeep();
        }
        PubSubSvc.subscribe('/call/singleDigitSent', onSendDtmfSucceeded);

        function onTelephonyData(data, telConv, atcRegState) {
            if (!data || !data.telephonyAvailableForUser) {
                return;
            }
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /telephony/data event');
            if (_telephonyStatus === null && data.phoneCallsAvailable) {
                // Don't show the info on first login, unless the status is DOWN
                _telephonyStatus = data.phoneCallsAvailable;
                publishTelephonyAvailability();
                return;
            }
            if (_telephonyStatus === data.phoneCallsAvailable) {
                return;
            }
            var lastStatus = _telephonyStatus;
            _telephonyStatus = data.phoneCallsAvailable;
            if (!data.phoneCallsAvailable) {
                if ($rootScope.registrationState === RegistrationState.Registered) {
                    LogSvc.debug('[CallAssociatedNotificationsSvc]: Delay publishing telephony DOWN by 5 seconds');
                    cancelTelephonyDownTimer();
                    _telephonyDownTimer = $timeout(function () {
                        _telephonyDownTimer = null;
                        if (lastStatus && !_telephonyStatus) {
                            // Only publish event if status went from Available to Unavailable
                            publishTelephonyAvailability();
                        }
                        PopupSvc.info({
                            id: 'id_TelephonyAlert',
                            message: atcRegState === Enums.AtcRegistrationState.ForcedUnregistered ? 'res_DialingNumbersForcedUnregistered' : 'res_DialingNumbersNotAvailable',
                            manual: true
                        });
                    }, TELEPHONY_DOWN_TIMEOUT);
                    return;
                }
            } else if (_telephonyDownTimer) {
                cancelTelephonyDownTimer();
            } else {
                PopupSvc.closeInfo('id_TelephonyAlert');
                PopupSvc.info({message: 'res_DialingNumbersAvailableAgain'});
            }
            publishTelephonyAvailability();
        }
        PubSubSvc.subscribe('/telephony/data', onTelephonyData);

        function onRegistrationState(state) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /registration/state event');
            cancelTelephonyDownTimer();
            if (state === RegistrationState.Registered) {
                _telephonyStatus = null;
            } else {
                // Close telephony alert message (if open)
                PopupSvc.closeInfo('id_TelephonyAlert');
            }
        }
        PubSubSvc.subscribe('/registration/state', onRegistrationState);

        function onChangeMediaFailed(call, error) {
            LogSvc.debug('[CallAssociatedNotificationsSvc]: Received /call/changeMediatype/failed event');
            PopupSvc.handleCallError(error);
        }
        PubSubSvc.subscribe('/call/changeMediatype/failed', onChangeMediaFailed);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.showInfo = showInfo;

        this.closeInfo = closeInfo;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.CallAssociatedNotificationsSvcImpl = CallAssociatedNotificationsSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
