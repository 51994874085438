/*global ControllerRouteError, RegistrationState*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var Conversation = circuit.Conversation;
    var UserProfile = circuit.UserProfile;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function UserSvcImpl($rootScope, $timeout, $interval, $q, LocalStoreSvc, LogSvc, PubSubSvc, MailboxConnSvc) { // NOSONAR
        LogSvc.debug('New Service: UserSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Class
        ///////////////////////////////////////////////////////////////////////////////////////
        // eslint-disable-next-line max-lines-per-function
        function UserSearch() { // NOSONAR
            var _foundUsers = [];
            var _pendingReqs = 1; // Start in 1
            var _query = '';
            var _searchId = null;
            var _exchangeSearchId = null;
            var _exchangeSearchTimer = null;
            var _doneCb = null;
            var _timer = null;
            var _phoneCallSearch = false;

            function findMatch(newUser) {
                if (!newUser) {
                    return -1;
                }

                var emailAddresses = [];
                if (newUser.emailAddress) {
                    emailAddresses.push(newUser.emailAddress);
                }
                if (newUser.emailAddresses && newUser.emailAddresses.length) {
                    Array.prototype.push.apply(emailAddresses,
                        newUser.emailAddresses.map(function (e) { return e.address; }));
                }
                if (emailAddresses.length === 0) {
                    return -1;
                }

                return _foundUsers.findIndex(function (u) {
                    if ((newUser.isExchangeContact && u.isExchangeContact) ||
                        (!newUser.isExchangeContact && !u.isExchangeContact)) {
                        // Same type
                        return false;
                    }
                    return emailAddresses.some(function (address) {
                        if (u.emailAddress && Utils.compareStrings(address, u.emailAddress)) {
                            return true;
                        }
                        return u.emailAddresses && u.emailAddresses.some(function (email) {
                            return Utils.compareStrings(address, email.address);
                        });
                    });
                });
            }

            function updateUserIdx(idx, newUser) {
                var circuitUser = newUser.isExchangeContact ? _foundUsers[idx] : newUser;
                var contact = newUser.isExchangeContact ? newUser : _foundUsers[idx];
                if (!circuitUser || !contact) {
                    return;
                }

                if (!circuitUser.extendedForPhoneCallSearch) {
                    circuitUser = createUserForPhoneCallSearch(circuitUser);
                }

                var externallyManagedFields = circuitUser.externallyManagedFields || [];

                if (externallyManagedFields.indexOf('phoneNumbers') === -1) {
                    LogSvc.debug('[UserSvc]: Add exchange contact numbers to user ', circuitUser.emailAddress);
                    contact.phoneNumbers && contact.phoneNumbers.forEach(function (newNumber) {
                        var found = circuitUser.phoneNumbers.some(function (existingNumber) {
                            return existingNumber.phoneNumber === newNumber.phoneNumber;
                        });
                        if (found) {
                            LogSvc.debug('[UserSvc]: Number already exists = ', newNumber.phoneNumber);
                        } else {
                            LogSvc.debug('[UserSvc]: Adding new number = ', newNumber.phoneNumber);
                            circuitUser.phoneNumbers.push(newNumber); // Number does not exist add it
                        }
                    });
                    circuitUser.phoneNumbers.sort(comparePhoneType);
                }
                if (externallyManagedFields.indexOf('department') === -1) {
                    circuitUser.department = circuitUser.department || contact.department;
                }

                // Make sure the Circuit user object is in the search results
                _foundUsers[idx] = circuitUser;
            }

            return {
                get users() {
                    return _foundUsers;
                },
                set users(users) {
                    _foundUsers = users;
                },
                get pendingReqs() {
                    return _pendingReqs;
                },
                set pendingReqs(pendingReqs) {
                    _pendingReqs = pendingReqs;
                },
                get query() {
                    return _query;
                },
                set query(query) {
                    _query = query;
                },
                get searchId() {
                    return _searchId;
                },
                set searchId(searchId) {
                    _searchId = searchId;
                },
                get exchangeSearchId() {
                    return _exchangeSearchId;
                },
                set exchangeSearchId(exchangeSearchId) {
                    _exchangeSearchId = exchangeSearchId;
                },
                get exchangeSearchTimer() {
                    return _exchangeSearchTimer;
                },
                set exchangeSearchTimer(timer) {
                    _exchangeSearchTimer = timer;
                },
                get doneCb() {
                    return _doneCb;
                },
                set doneCb(cb) {
                    _doneCb = cb;
                },
                get timer() {
                    return _timer;
                },
                set timer(timer) {
                    _timer = timer;
                },
                get phoneCallSearch() {
                    return _phoneCallSearch;
                },
                set phoneCallSearch(value) {
                    _phoneCallSearch = !!value;
                },
                hasResults: function () {
                    return !!_foundUsers.length;
                },
                clear: function () {
                    Utils.emptyArray(_foundUsers);
                    _pendingReqs = 1;  // Reset to 1 for next search request
                    _query = '';
                    _searchId = null;
                    _exchangeSearchId = null;
                    _doneCb = null;
                    if (_timer) {
                        $timeout.cancel(_timer);
                        _timer = null;
                    }
                    if (_exchangeSearchTimer) {
                        $timeout.cancel(_exchangeSearchTimer);
                        _exchangeSearchTimer = null;
                    }
                    _phoneCallSearch = false;
                },
                insertUser: function (user) {
                    if (!user) {
                        return;
                    }
                    if (_phoneCallSearch) {
                        var idx = findMatch(user);
                        if (idx !== -1) {
                            updateUserIdx(idx, user);
                            return;
                        }
                    }

                    // Insert the user in the right display order:
                    // 1. The results that match the firstname are listed first.
                    // 2. For names that match the firstname, sort them by firstname.
                    // 3. For names that match the lastname, sort them by lastname.
                    if (!insertSortedUser(user, _query, _foundUsers)) {
                        LogSvc.warn('[UserSvc]: Failed to insert user to the search results: ', user.userId);
                    }
                },
                insertExchangeContact: function (contact) {
                    if (!contact) {
                        return;
                    }
                    contact.isExchangeContact = true;

                    // Rename "number" field to "phoneNumber"
                    contact.phoneNumbers.forEach(function (number) {
                        number.phoneNumber = number.phoneNumber || number.number;
                        delete number.number;
                    });

                    var idx = findMatch(contact);
                    if (idx === -1) {
                        // Add contact to search results
                        contact.displayName = (contact.firstName + ' ' + contact.lastName).trim();
                        contact.displayNameEscaped = Utils.textToHtmlEscaped(contact.displayName);
                        contact.phoneNumbers.sort(comparePhoneType);
                        insertSortedUser(contact, _query, _foundUsers);
                    } else {
                        updateUserIdx(idx, contact);
                    }
                }
            };
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var MAX_RECENT_USERS = 16;            // Max number of users stored in _recentUsers
        var MAX_PHONE_SEARCH_RESULTS = 30;    // Max number of users returned for a phone call search

        var MAX_PRESENCE_SUBSCRIPTIONS = 70;
        var MAX_TEMPORARY_SUBSCRIPTIONS = 30;

        var MAX_GET_USERS_BY_IDS = 50;  // At most 50 userIds in each get_users_by_ids request

        var ONE_MIN = 60 * 1000;
        var TEN_MIN = 10 * ONE_MIN;
        var ONE_HOUR = 60 * ONE_MIN;
        var ONE_DAY = 24 * ONE_HOUR;
        var USER_LOGIN_REFRESH_INTERVAL = 1 * ONE_MIN;     // After login, wait 1 minute and then refresh oldest users
        var USER_ALL_REFRESH_INTERVAL = 10 * ONE_MIN;      // Refresh recent users every 10 minutes
        var USER_REFRESH_MIN_INTERVAL = 6 * ONE_HOUR;  // Only refresh users if they are older than 6 hours
        var USER_GET_BY_ID_REFRESH_INTERVAL = 0; // Refresh user if getUsersByIds is invoked more than X minutes after last refresh

        var SEARCH_START_DELAY = {reversePhoneNumberLookup: 0, email: 2000, char1: 2000, char2: 1000, other: 500}; // Delay to being searching after user types letters
        var EXCHANGE_SEARCH_RESULT_LIMIT = 50;

        var MAX_CONVERSATION_PARTICIPANTS_FOR_LOCAL_SEARCH = 50; // Max number of conversation participant to force local search on
        var GET_PARTICIPANTS_TO_MENTION_DELAY = 300; // Delay before sending the query to the backend

        var _self = this;
        var _clientApiHandler = ClientApiHandler.getInstance();

        var _usersLoaded = false;
        var _users = [];            // Contains list of UserProfile objects
        var _usersHashtable = {};   // Hashtable of user IDs to UserProfile objects
        var _recentUsers = [];
        var _localCall = null;      // Reference to the local call

        var _permanentPresenceSubscriptions = [];   // Subscribed user ids array for recent direct conversations
        var _temporaryPresenceSubscriptions = [];   // Subscribed user ids array for temporary subscriptions
        var _userStatusSubscriptions = [];          // Subscribed for status AVAILABLE user ids array (tell me when user is available)

        var _isMobileAsleep = false;    // Indicates a mobile client in backgroup mode
        var _userRefreshLoginTimer;     // Timer to wait after login to refresh users
        var _userRefreshIntervalTimer;  // Interval timer to refresh cached users

        // Phone numbers display preference (Work, Mobile, Home, Fax, Other)
        var _phonePreferenceOrder = {
            UCAAS: 1,
            WORK: 2,
            MOBILE: 3,
            HOME: 4,
            FAX: 5
        };

        // getParticipantsToMention variables
        var _getParticipantsToMentionTimer = null;
        var _lastMentionQueryResult = {};
        var _discardParticipantsToMentionTimer = null;
        var _pendingMentionQuery = {};

        var _userSearch = new UserSearch();  // Search results for current user search

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function comparePhoneType(x, y) {
            return (_phonePreferenceOrder[x.type] || 99) - (_phonePreferenceOrder[y.type] || 99);
        }

        function createUserForPhoneCallSearch(user) {
            if (user && !user.extendedForPhoneCallSearch) {
                user = Object.create(user);
                user.extendedForPhoneCallSearch = true;
                user.phoneNumbers = user.phoneNumbers.slice(0);
                if (user.exchangeData && user.exchangeData.phoneNumbers && user.exchangeData.phoneNumbers.length > 0) {
                    user.exchangeData.phoneNumbers.forEach(function (phoneNumber) {
                        user.phoneNumbers.push({
                            type: phoneNumber.type,
                            phoneNumber: phoneNumber.number
                        });
                    });
                }
                user.phoneNumbers.sort(comparePhoneType);
            }
            return user;
        }

        function getBaseUser(user) {
            return user && user.isExtended ? Utils.getBaseObject(user, true) : user;
        }

        function canSaveToDB(/* user */) {
            // NGTC-1541: Disable saving user to localstore
            return false;
            //return !!user && !user.noDB && !user.noData && !user.isInactive && (user.userId !== $rootScope.localUser.userId);
        }

        function updateUserInDB(user) {
            if (canSaveToDB(user)) {
                // Update user data in indexedDB
                LocalStoreSvc.putUsers([getBaseUser(user)]);
            }
        }

        function saveUsersToDB(users) {
            if (users) {
                var toBeSaved = users.filter(canSaveToDB).map(getBaseUser);
                if (toBeSaved.length > 0) {
                    // Update user data in indexedDB
                    LocalStoreSvc.putUsers(toBeSaved).then(function () {
                        LogSvc.debug('[UserSvc]: Users have been successfully updated in DB');
                    }, function (err) {
                        LogSvc.warn('[UserSvc]: Failed to update users in DB. ', err);
                    });
                }
            }
        }

        function hasActiveSubscription(userId) {
            return userId === $rootScope.localUser.userId ||
                _permanentPresenceSubscriptions.includes(userId) ||
                _temporaryPresenceSubscriptions.includes(userId);
        }

        function updateUserPresenceState(presenceState) {
            var userId = presenceState.userId;
            // Only update the userPresenceState if we have an active presence subscription
            if (hasActiveSubscription(userId)) {
                var user = _usersHashtable[userId];
                if (user) {
                    if (UserProfile.updateUserPresenceState(user, presenceState)) {
                        LogSvc.debug('[UserSvc]: User presence has changed. Publish /user/update event. userId =', user.userId);
                        PubSubSvc.publish('/user/update', [user]);
                    }
                    return user.userPresenceState;
                }
            }
            return UserProfile.normalizeUserPresenceState(userId, presenceState);
        }

        function getPresenceFromCachedUsers(userIds) {
            var usersPresence = [];
            userIds.forEach(function (userId) {
                if (userId === $rootScope.localUser.userId) {
                    usersPresence.push($rootScope.localUser.userPresenceState);
                } else if (_usersHashtable[userId]) {
                    usersPresence.push(_usersHashtable[userId].userPresenceState);
                }
            });
            return usersPresence;
        }

        /**
        * @param {String[]} userIds  The userIds for which we want to retrieve the presence.
        * @param {Boolean forceUpdateSubscribed} Force-retrieve the presence from the server for the given userIds, regardless If I am subscribed or not.
        * By default server data are retrieved only for users I haven't subscribed yet. For already subscribed ones by default cached presence data are used.
        * @param {cb} the callback to call
        */
        function getUsersPresence(userIds, forceUpdateSubscribed, cb) {
            userIds = userIds || [];
            // If forceUpdateSubscribed then we need to getPresence for already subscribed as well, eg in case we just subscribed but don't have full presence
            var unsubscribedUserIds = forceUpdateSubscribed ? userIds : userIds.filter(function (userId) {
                return !hasActiveSubscription(userId);
            });
            if (unsubscribedUserIds.length > 0) {
                _clientApiHandler.getPresence(unsubscribedUserIds, true, function (err, userPresenceStates) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[UserSvc]: Error getting users presence: ', err);
                            cb && cb(err);
                            return;
                        }
                        userPresenceStates = userPresenceStates || [];
                        userPresenceStates = userPresenceStates.map(updateUserPresenceState);

                        if (forceUpdateSubscribed) {
                            cb && cb(null, userPresenceStates);
                        } else {
                            var subscribedUserIds = userIds.filter(function (userId) {
                                return unsubscribedUserIds.indexOf(userId) === -1;
                            });
                            cb && cb(null, getPresenceFromCachedUsers(subscribedUserIds).concat(userPresenceStates));
                        }
                    });
                });
            } else {
                cb && cb(null, getPresenceFromCachedUsers(userIds));
            }
        }

        function unsubscribeFromUsersPresence(otherList, userIds) {
            // Unubscribe from users' presence change events
            if (!userIds || userIds.length === 0) {
                return;
            }

            // Make sure the user is not in the other list
            var removeIds = userIds.filter(function (userId) {
                return !otherList.includes(userId);
            });

            // Remove userPresenceState from cached user objects
            removeIds.forEach(function (userId) {
                var user = _usersHashtable[userId];
                if (user) {
                    UserProfile.clearUserPresenceState(user);
                    LogSvc.debug('[UserSvc]: Publish /user/update event. userId =', userId);
                    PubSubSvc.publish('/user/update', [user]);
                }
            });

            // Now invoke the unsubscribe presence API
            _clientApiHandler.unsubscribePresence(removeIds, function (err) {
                if (err) {
                    LogSvc.error('[UserSvc]: Error unsubscribing to users presence: ', err);
                }
            });
        }

        function sendSubscribePresence(userIds, cb) {
            if (!userIds || !userIds.length) {
                cb && cb(null);
                return;
            }
            _clientApiHandler.subscribePresence(userIds, function (err, presenceStates) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserSvc]: Error subscribing to users presence: ', err);
                        cb && cb(err);
                        return;
                    }
                    presenceStates && presenceStates.forEach(updateUserPresenceState);
                    cb && cb(null);
                });
            });
        }

        function subscribeToUsersPresence(isPermanent, userIds, prioritized, cb) {
            cb = cb || function () {};

            // Remove duplicates, local user and non-cached users
            userIds = userIds.filter(function (userId, pos, arr) {
                // Filter out duplicates
                var isFirstOccurence = arr.indexOf(userId) === pos;
                return isFirstOccurence && ($rootScope.localUser.userId !== userId) && _usersHashtable[userId];
            });

            LogSvc.debug('[UserSvc]: Subscribe for temporary presence subscriptions. Users = ', userIds);

            if (userIds.length === 0) {
                cb(null);
                return;
            }

            var subscriptionList = isPermanent ? _permanentPresenceSubscriptions : _temporaryPresenceSubscriptions;
            var otherList = isPermanent ? _temporaryPresenceSubscriptions : _permanentPresenceSubscriptions;
            var maxSubscriptions = isPermanent ? MAX_PRESENCE_SUBSCRIPTIONS : MAX_TEMPORARY_SUBSCRIPTIONS;

            if (!prioritized && subscriptionList.length >= maxSubscriptions) {
                LogSvc.debug('[UserSvc]: Subscription list is already full. Only prioritized subscriptions can be processed.');
                cb('Subscription limit reached');
                return;
            }

            // Keep only max subscriptions
            if (userIds.length > maxSubscriptions) {
                LogSvc.info('[UserSvc]: Cannot subscribe to all users. Max number of subscriptions is ', maxSubscriptions);
                userIds.splice(maxSubscriptions);
            }

            var newIds = userIds.filter(function (userId) {
                var idx = subscriptionList.indexOf(userId);
                if (idx !== -1) {
                    // User is already in the list
                    if (prioritized) {
                        // Remove user from list. It will be added again to the front of the list later.
                        subscriptionList.splice(idx, 1);
                    }
                    return false;
                }
                // Check if user is in the other subscription list
                return !otherList.includes(userId);
            });

            if (prioritized) {
                // Add prioritized user IDs to the front
                Array.prototype.unshift.apply(subscriptionList, userIds);
                // Check if we are going over the limit and if so, unsubscribe from some users
                if (subscriptionList.length > maxSubscriptions) {
                    // Remove the rest and unsubscribe
                    var goners = subscriptionList.splice(maxSubscriptions);
                    unsubscribeFromUsersPresence(otherList, goners);
                }
            }

            if (newIds.length === 0) {
                // There are no new subscriptions
                LogSvc.debug('[UserSvc]: We are already subscribed to the requested users');
                cb(null);
            }

            if (!prioritized) {
                var maxAllowed = maxSubscriptions - subscriptionList.length;
                if (newIds.length > maxAllowed) {
                    // We are asking for more than we can accept. Trim the list.
                    LogSvc.debug('[UserSvc]: Cannot subscribe to all users. Remaining subscriptions available is ', maxAllowed);
                    newIds.splice(maxAllowed);
                }
                // Add new IDs to the back
                Array.prototype.push.apply(subscriptionList, newIds);
            }

            sendSubscribePresence(newIds, cb);
        }

        function subscribePermanentPresence(userIds, prioritized) {
            if (!userIds) {
                return;
            }
            if (!Array.isArray(userIds)) {
                userIds = [userIds];
            }
            if (userIds.length === 0) {
                return;
            }
            LogSvc.debug('[UserSvc]: Subscribe for permanent presence subscriptions. Users = ', userIds);
            subscribeToUsersPresence(true, userIds, prioritized);
        }

        function subscribeTemporaryPresenceAndNotify(userIds, cb) {
            if (!userIds) {
                cb && cb(null);
                return;
            }
            if (!Array.isArray(userIds)) {
                userIds = [userIds];
            }
            if (userIds.length === 0) {
                cb && cb(null);
                return;
            }
            subscribeToUsersPresence(false, userIds, true, cb);
        }

        // TODO: Remove updatePresence parameter when presence subscriptions are implemented
        function addUsersToCache(users, cacheOnly, retrieveIncomplete, updatePresence) {
            if (!users) {
                return;
            }
            if (!Array.isArray(users)) {
                users = [users];
            }

            // Add users to cached array and hashtable for lookup.
            var toBeSaved = [];
            var incompleteUsers = [];
            var initializedUsers = [];

            users.forEach(function (user) {
                if (!user || !user.userId || user.userId === $rootScope.localUser.userId) {
                    return;
                }

                if (retrieveIncomplete && user.noData && !user.reqPending) {
                    // We still haven't retrieved the user data for this user
                    incompleteUsers.push(user);
                }

                var cachedUser = _usersHashtable[user.userId];
                if (cachedUser) {
                    if (cachedUser !== user && !user.noData) {
                        if (cachedUser.noData) {
                            cachedUser.noData = false;
                            initializedUsers.push(user.userId);
                        }
                        var userPresenceState = user.userPresenceState;
                        UserProfile.update(cachedUser, getBaseUser(user));
                        updatePresence && UserProfile.updateUserPresenceState(cachedUser, userPresenceState);
                        if (!cachedUser.noDB) {
                            toBeSaved.push(cachedUser);
                        }
                    }
                    if (!cacheOnly && cachedUser.noDB) {
                        // User exists in cache but not saved in DB
                        cachedUser.noDB = false;
                        toBeSaved.push(cachedUser);
                    }
                } else {
                    if (!user.isExtended) {
                        user = UserProfile.extend(user);
                    }
                    if (!cacheOnly) {
                        toBeSaved.push(user);
                    } else {
                        // Mark the user to indicate that it should not be saved to DB
                        user.noDB = true;
                    }
                    _users.push(user);
                    _usersHashtable[user.userId] = user;
                }
            });

            if (toBeSaved.length > 0) {
                saveUsersToDB(toBeSaved);
            }
            if (incompleteUsers.length > 0) {
                refreshUsers(incompleteUsers);
            }
            if (initializedUsers.length > 0) {
                LogSvc.debug('[UserSvc]: Publish /users/initialized event.', initializedUsers);
                PubSubSvc.publish('/users/initialized', [initializedUsers]);
            }
        }
        // Set the Conversation.addUsersToCache static function
        Conversation.addUsersToCache = addUsersToCache.bind(this);

        function checkForUnknownUsers(userIds) {
            var unknown = $rootScope.i18n.map.res_Unknown;

            var unknownUserIds = userIds.filter(function (id) {
                var cachedUser = _usersHashtable[id];
                if (cachedUser && cachedUser.noData) {
                    // User is cached, but has no data.
                    if (cachedUser.displayName === '') {
                        // Set user name to 'Unknown'.
                        LogSvc.debug('[UserSvc]: Set user as "Unknown". userId =', cachedUser.userId);
                        cachedUser.displayName = unknown;
                        cachedUser.displayNameEscaped = unknown;
                        cachedUser.firstName = unknown;

                        LogSvc.debug('[UserSvc]: Publish /user/update event. userId =', cachedUser.userId);
                        PubSubSvc.publish('/user/update', [cachedUser]);
                    }
                    return true;
                }
                return false;
            });

            if (unknownUserIds.length > 0) {
                LogSvc.warn('[UserSvc]: Could not get info for these user Ids: ', unknownUserIds);
            }
        }

        function checkForPurgedUsers(users) {
            // Set the name for purged users
            var purged = $rootScope.i18n.map.res_Purged;
            return users.filter(function (u) {
                if (u.userState === Constants.UserState.PURGED) {
                    u.displayName = purged;
                    u.firstName = purged;
                    u.lastName = '';
                    return true;
                }
                return false;
            });
        }

        function getUsersByIds(userIds, throttle, cb) {
            var reqUserIds, pendingUserIds;
            if (throttle) {
                reqUserIds = userIds.slice(0, MAX_GET_USERS_BY_IDS);
                pendingUserIds = userIds.slice(MAX_GET_USERS_BY_IDS);
            } else {
                reqUserIds = userIds;
                pendingUserIds = [];
            }

            setReqPending(reqUserIds);
            _clientApiHandler.getUsersByIds(reqUserIds, function (err, users) {
                $rootScope.$apply(function () {
                    clearReqPending(reqUserIds);
                    if (err) {
                        LogSvc.warn('[UserSvc]: Failed to get users. ', err);
                    }
                    users = users || [];
                    var summary = users.map(function (user) {
                        return {
                            userId: user.userId,
                            displayName: user.displayName,
                            userType: user.userType,
                            userState: user.userState
                        };
                    });
                    LogSvc.debug('[UserSvc]: Retrieved ' + summary.length + ' users - ', summary);

                    checkForPurgedUsers(users);

                    var now = Date.now();
                    users.forEach(function (user) { user.updatedTimeStamp = now; });

                    cb && cb(users, pendingUserIds.length === 0);

                    if (pendingUserIds.length > 0) {
                        LogSvc.debug('[UserSvc]: Get remaining pending users');
                        getUsersByIds(pendingUserIds, true, cb);
                    }
                });
            });
        }

        function syncExchangePersonalContacts() {
            if (_localCall && _localCall.isPresent()) {
                // Skip sync to reduce freezes seen with SOAP requests while on a call
                LogSvc.info('[UserSvc]: User is in local call. Skip sync of personal contacts.');
                return;
            }
            MailboxConnSvc.syncAllPersonalContacts && MailboxConnSvc.syncAllPersonalContacts();
        }

        function refreshOutdatedUsers() {
            var outdatedTimestamp = Date.now() - USER_REFRESH_MIN_INTERVAL;

            var outdatedUsers = _users.filter(function (user) {
                return !user.noData && !user.refreshPending && (!user.updatedTimeStamp || (user.updatedTimeStamp < outdatedTimestamp));
            }).sort(function (a, b) {
                return (a.updatedTimeStamp || 0) - (b.updatedTimeStamp || 0);
            });


            if (!outdatedUsers.length) {
                LogSvc.debug('[UserSvc]: There are no outdated users that need to be refreshed');
                return;
            }

            LogSvc.info('[UserSvc]: Found ' + outdatedUsers.length + ' outdated users. Refresh oldest ones.');

            // Only refresh up to MAX_GET_USERS_BY_IDS users
            refreshUsers(outdatedUsers.slice(0, MAX_GET_USERS_BY_IDS));
        }

        function startUserRefreshTimers() {
            clearUserRefreshTimers();

            if (_isMobileAsleep) {
                // Do not start timers when mobile is sleeping
                return;
            }

            // At login only request non-cached users from server then wait some time and refresh
            // the data for the recent users then periodic requests occur at longer intervals.
            _userRefreshLoginTimer = $timeout(function () {
                LogSvc.debug('[UserSvc]: Initial user refresh after timer expiry');
                _userRefreshLoginTimer = null;
                refreshOutdatedUsers();

                // Start a interval to refresh users periodically
                _userRefreshIntervalTimer = $interval(function () {
                    LogSvc.debug('[UserSvc]: Subsequent user refresh after timer expiry');
                    refreshOutdatedUsers();
                    syncExchangePersonalContacts();
                }, USER_ALL_REFRESH_INTERVAL);

            }, USER_LOGIN_REFRESH_INTERVAL);
        }

        function clearUserRefreshTimers() {
            if (_userRefreshLoginTimer) {
                $timeout.cancel(_userRefreshLoginTimer);
                _userRefreshLoginTimer = null;
            }
            if (_userRefreshIntervalTimer) {
                $interval.cancel(_userRefreshIntervalTimer);
                _userRefreshIntervalTimer = null;
            }
        }

        function refreshUsers(usersToRefresh) {
            if (!usersToRefresh || !usersToRefresh.length) {
                return;
            }

            // Remove local user and users for which we have pending requests
            usersToRefresh = usersToRefresh.filter(function (u) {
                if (!u.userId || u.userId === $rootScope.localUser.userId || u.reqPending) {
                    return false;
                }
                return true;
            });

            if (usersToRefresh.length === 0) {
                return;
            }

            var reqUserIds = usersToRefresh.map(function (u) { return u.userId; });

            LogSvc.info('[UserSvc]: Retrieve latest user data.');
            getUsersByIds(reqUserIds, true, function (users, isComplete) {
                if (users && users.length > 0) {
                    LogSvc.info('[UserSvc]: Retrieved latest user data. Update local store and cache.');

                    // Array of users that needs to be saved to DB
                    var toBeSaved = [];

                    // Array of users that have been initialized. Contains the user IDs.
                    var initializedUsers = [];

                    users.forEach(function (user) {
                        var cachedUser = _usersHashtable[user.userId];
                        if (!cachedUser) {
                            // We should never get here, but shit happens. Add user to cache-only.
                            LogSvc.warn('[UserSvc]: Received getUserById response for user not in cache?!? userId = ', user.userId);

                            user = UserProfile.extend(user);
                            user.noDB = true;
                            _users.push(user);
                            _usersHashtable[user.userId] = user;

                            LogSvc.debug('[UserSvc]: Publish /user/update event. userId = ', user.userId);
                            PubSubSvc.publish('/user/update', [user]);
                        } else {
                            if (cachedUser.noData) {
                                cachedUser.noData = false;
                                initializedUsers.push(user.userId);
                            }

                            UserProfile.update(cachedUser, user);

                            if (!cachedUser.noDB) {
                                toBeSaved.push(cachedUser);
                            }

                            LogSvc.debug('[UserSvc]: Publish /user/update event. userId =', cachedUser.userId);
                            PubSubSvc.publish('/user/update', [cachedUser]);
                        }
                    });

                    if (initializedUsers.length > 0) {
                        LogSvc.debug('[UserSvc]: Publish /users/initialized event.', initializedUsers);
                        PubSubSvc.publish('/users/initialized', [initializedUsers]);
                    }

                    if (toBeSaved.length > 0) {
                        saveUsersToDB(toBeSaved);
                    }

                    recalculateRecentUsers();
                }

                if (isComplete) {
                    checkForUnknownUsers(reqUserIds);
                }

            });
        }

        function addUsersAndReturnCached(users, updatePresence) {
            var cachedUsers = [];
            if (users && users.length) {
                addUsersToCache(users, true, false, updatePresence);
                users.forEach(function (user) {
                    if (user.userId !== $rootScope.localUser.userId) {
                        cachedUsers.push(_usersHashtable[user.userId]);
                    }
                });
            }
            return cachedUsers;
        }

        function addUserAndReturnCached(user) {
            if (user) {
                addUsersToCache([user], true, false);
                return _usersHashtable[user.userId];
            }
            return null;
        }

        function setReqPending(userIds) {
            userIds && userIds.forEach(function (userId) {
                var user = _usersHashtable[userId];
                if (user) {
                    user.reqPending = true;
                }
            });
        }

        function clearReqPending(userIds) {
            userIds && userIds.forEach(function (userId) {
                var user = _usersHashtable[userId];
                if (user) {
                    delete user.reqPending;
                }
            });
        }

        function isValidRecentUser(u) {
            return !!u && !u.noDB && !u.noData && !u.hasSpecialRole && !u.isDeleted;
        }

        function recalculateRecentUsers() {
            // Get users from cache that are 'indexedDB' users and have a lastActivity timestamp, then sort them.
            _recentUsers = _users.filter(function (u) {
                return u.lastActivity && isValidRecentUser(u);
            })
            .sort(function (a, b) {
                return b.lastActivity - a.lastActivity;
            })
            .slice(0, MAX_RECENT_USERS);
        }

        function getUserFromCache(userId) {
            return userId === $rootScope.localUser.userId ? $rootScope.localUser : _usersHashtable[userId];
        }
        // Set the Conversation.getUserFromCache static function
        Conversation.getUserFromCache = getUserFromCache.bind(this);

        function getExtendedUser(userId) {
            if (!$rootScope.localUser) {
                return null;
            }

            var extUser = getUserFromCache(userId);
            if (!extUser) {
                extUser = UserProfile.extend({userId: userId});
                extUser.noData = true;
                // Add new user object to cache
                addUsersToCache(extUser, true, false);
            }
            return extUser;
        }
        // Set the Conversation.getExtendedUser static function
        Conversation.getExtendedUser = getExtendedUser.bind(this);

        function getUserByEmail(emailAddress, cb) {
            if ($rootScope.localUser && emailAddress === $rootScope.localUser.emailAddress) {
                cb && cb(null, $rootScope.localUser);
                return;
            }

            _clientApiHandler.getUserByEmail(emailAddress, function (err, user) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.info('[UserSvc]: Get user by email failed. ', err);
                        cb && cb(err);
                    } else {
                        // Add user to cache (or update if already in cache)
                        var cachedUser = addUserAndReturnCached(user);
                        cb && cb(null, cachedUser);
                    }
                });
            });
        }
        /**
         *  Retrieves users with email addresses
         *
         *  @param {Object} filter
         *  @param {string} filter.emailAddresses - Email addresses to search for
         *  @param {boolean} filter.xmppLookup - Flag indicating if XMPP users should be searched for.
         *  @param {Function} cb Callback returning error and user parameters
         */
        function getUsersByEmails(filter, cb) {
            cb = cb || function () {};
            _clientApiHandler.getUsersByEmails(filter, function (err, users) {
                $rootScope.$apply(function () {
                    if (err && (err !== Constants.ReturnCode.NO_RESULT)) {
                        LogSvc.info('[UserSvc]: Get users by emails failed. ', err);
                        cb(err);
                    } else {
                        var cachedUsers = addUsersAndReturnCached(users);
                        cb(null, cachedUsers);
                    }
                });
            });
        }

        // Comparator to sort users by the following rules:
        // 1. The results that match the firstname are listed first.
        // 2. For names that match the firstname, sort them by firstname.
        // 3. For names that match the lastname, sort them by lastname.
        function compareSortUsers(a, b, searchString) {
            if (!searchString) {
                return a.displayName < b.displayName ? -1 : 1;
            }
            if (!a.firstName && !b.firstName) {
                return a.displayName < b.displayName ? -1 : 1;
            }
            if (!a.firstName || !b.firstName) {
                return a.firstName ? -1 : 1;
            }

            searchString = new RegExp('^' + RegExp.escape(searchString), 'i');

            // Result is in first name only
            if (a.firstName.search(searchString) > -1 && b.firstName.search(searchString) === -1) {
                return -1;
            }
            // Result is in last name only
            if (a.firstName.search(searchString) === -1 && b.firstName.search(searchString) > -1) {
                return 1;
            }
            // Both results are in first name
            if (a.firstName.search(searchString) > -1 && b.firstName.search(searchString) > -1) {
                if (a.firstName === b.firstName) {
                    return a.lastName < b.lastName ? -1 : 1;
                }
                return a.firstName < b.firstName ? -1 : 1;
            }
            // Both results are in last name
            if (a.lastName.search(searchString) > -1 && b.lastName.search(searchString) > -1) {
                if (a.lastName === b.lastName) {
                    return a.firstName < b.firstName ? -1 : 1;
                }
                return a.lastName < b.lastName ? -1 : 1;
            }
            return 0;
        }

        // Helper method to sort user arrays
        function sortUsers(searchString) {
            return function (a, b) {
                return compareSortUsers(a, b, searchString);
            };
        }

        // Insert users based on the compareSortUsers rules
        function insertSortedUser(newUser, searchString, users) {
            var minIndex = 0;
            var maxIndex = users.length - 1;
            var currentIndex;
            var currentElement;
            var found = false;

            while (minIndex <= maxIndex) {
                currentIndex = Math.floor((minIndex + maxIndex) / 2);
                currentElement = users[currentIndex];

                var res = compareSortUsers(currentElement, newUser, searchString);

                if (res < 0) {
                    minIndex = currentIndex + 1;
                } else if (res > 0) {
                    maxIndex = currentIndex - 1;
                } else {
                    found = true;
                    break;
                }
            }

            var indexToInsert = found ? currentIndex + 1 : minIndex;
            if (indexToInsert >= 0) {
                users.splice(indexToInsert, 0, newUser);
                return true;
            }
            return false;
        }

        /**
         * Searches for users on the server. Matching first and last names but only from the beginning.
         *
         * @param {Object} constraints The search constraints.
         * @param {callback} doneCb The callback when search is finished.
         */
        function searchByName(constraints, doneCb) {
            var query = constraints && constraints.query && constraints.query.trim();
            if (!query) {
                doneCb && doneCb();
                return;
            }

            _userSearch.query = query;
            _userSearch.doneCb = doneCb;
            _userSearch.includeLocalUser = constraints.includeLocalUser;

            // LOCAL SEARCH DISABLED BECAUSE OF SLUGGISH UI
            // NEEDS UPDATE TO BE ASYNCHRONOUS
            // Get local matching users first
            // var isSupportClient = $rootScope.localUser.hasSupportRole;
            // var filteredUsers = userNameFilter(_users, _userSearch.query, false);
            // filteredUsers = filterUsers(filteredUsers, isSupportClient);
            // _userSearch.users.push.apply(_userSearch.users, filteredUsers);

            // Start server search after some delay (first char 1s, 2nd char 500ms, starting from 3rd 300ms)
            var delay = SEARCH_START_DELAY.other; // three or more chars (except email)
            if (constraints.reversePhoneNumberLookup) {
                delay = SEARCH_START_DELAY.reverseLookup;
            } else if (query.length === 1) {
                delay = SEARCH_START_DELAY.char1; // first char
            } else if (query.length === 2) {
                delay = SEARCH_START_DELAY.char2; // 2nd char
            } else if (query.match(Utils.EMAIL_ADDRESS_PATTERN)) {
                delay = SEARCH_START_DELAY.email;
            }

            function userSearchCb(err, searchId) {
                if (_userSearch.timer) {
                    // There is another pending search
                    LogSvc.debug('[UserSvc]: There is another pending search. Cancel the previous search.');
                    _clientApiHandler.cancelSearch(searchId);
                    return;
                }
                if (err) {
                    LogSvc.warn('[UserSvc]: Start user search failed. ', err);
                    doneCb && $rootScope.$apply(function () {
                        doneCb();
                    });
                } else {
                    // save searchId and may be used to cancel this search
                    _userSearch.searchId = searchId;
                }
            }

            _userSearch.timer = $timeout(function () {
                _userSearch.timer = null;
                if (query && query.match(Utils.EMAIL_ADDRESS_PATTERN)) {
                    getUserByEmail(query, function (err, user) {
                        var emailUser;
                        if (user && (user.userId !== $rootScope.localUser.userId || _userSearch.includeLocalUser)) {
                            emailUser = user;
                            _userSearch.insertUser(user); // Insert found users in order
                        }
                        doneCb && doneCb(err, emailUser); // Callback with emailUser object for UI
                    });
                } else if (constraints.searchContext) {
                    _clientApiHandler.startAdvancedUserSearch(constraints, userSearchCb);
                } else {
                    _clientApiHandler.startUserSearch(constraints, userSearchCb);
                }
            }, delay);
        }

        function searchExchangeByName(constraints, cb) {
            if (!constraints || !constraints.query) {
                cb && cb();
                return;
            }
            var query = constraints.query;

            var delay = SEARCH_START_DELAY.other; // three or more chars
            if (constraints.reversePhoneNumberLookup) {
                delay = SEARCH_START_DELAY.reverseLookup;
            } else if (query.length === 1) {
                delay = SEARCH_START_DELAY.char1; // first char
            } else if (query.length === 2) {
                delay = SEARCH_START_DELAY.char2; // 2nd char
            }
            if (_userSearch.exchangeSearchTimer) {
                $timeout.cancel(_userSearch.exchangeSearchTimer);
            }
            _userSearch.exchangeSearchTimer = $timeout(function () {
                if (typeof MailboxConnSvc.searchContactsWithConstraints === 'function') {
                    _userSearch.exchangeSearchId = MailboxConnSvc.searchContactsWithConstraints(query, constraints, EXCHANGE_SEARCH_RESULT_LIMIT, cb);
                } else {
                    // Backwards compatibility for clients that don't support the new API
                    _userSearch.exchangeSearchId = MailboxConnSvc.searchContacts(query, EXCHANGE_SEARCH_RESULT_LIMIT, cb);
                }
            }, delay);
        }

        function clearUserSearch() {
            var searchId = _userSearch.searchId;
            if (searchId) {
                _clientApiHandler.cancelSearch(searchId, function (err) {
                    if (err) {
                        LogSvc.warn('[UserSvc]: Failed to cancel server search. ', err);
                    } else {
                        LogSvc.debug('[UserSvc]: Canceled server search for searchId =  ', searchId);
                    }
                });
            }
            if (_userSearch.exchangeSearchId) {
                MailboxConnSvc.cancelSearchContacts(_userSearch.exchangeSearchId);
            }
            _userSearch.doneCb && _userSearch.doneCb({cleared: true});
            _userSearch.clear();
        }

        /**
         * Filter the users based on support user, tenantId, and active
         * LocalUser will be excluded from the list.
         * The passed in users may be the result returned from the server and will be extended.
         *
         * @param {Array} users An array of users to be filtered
         * @param {Boolean} includeSupportUser Include support users or not
         * @param {Boolean} includeLocalUser Include local user or not
         * @returns {Array} The filtered users
         */
        function filterUsers(users, includeSupportUser, includeLocalUser) {
            var filteredUsers = [];
            if (users.length > 0) {
                filteredUsers = users.filter(function (user) {
                    // Don't return local user and special users
                    if (user.userId === $rootScope.localUser.userId && !includeLocalUser) {
                        return false;
                    }
                    return ((user.hasSupportRole && includeSupportUser) || !user.hasSpecialRole) &&
                         user.userState !== Constants.UserState.DELETED;

                }).map(function (user) {
                    // Use cached UserProfile object if available
                    return _usersHashtable[user.userId] || UserProfile.extend(user);
                });
            }
            return filteredUsers;
        }

        /**
         * A helper filter to return the matched names from a list of names. The match in names
         * only starts from beginning of user's firstName or lastName. The filter returns users
         * sorted by compareSortUsers rules.
         *
         * @param {Array} users An array of users to be filtered.
         * @param {String} query The string to be matched.
         * @param {Boolean} caseSensitive Match is case sensitive or not.
         * @returns {Array} Array of users after filtering.
         */
        function userNameFilter(users, query, caseSensitive) {
            if (!users) {
                return null;
            }
            if (query) {
                query = query.toString();
                var sortUsersByQuery = sortUsers(query);
                var flag = caseSensitive ? 'g' : 'gi';
                return users.filter(function (contact) {
                    return contact && Utils.matchNames(contact.displayName, query, flag);
                }).sort(sortUsersByQuery);
            }
            // no filtering
            return users;
        }

        function clearUserSvcCache() {
            Utils.emptyArray(_users);
            _usersHashtable = {};
            Utils.emptyArray(_recentUsers);
            Utils.emptyArray(_permanentPresenceSubscriptions);
            _userSearch.clear();
        }

        function decrementPendingSearchReqs() {
            _userSearch.pendingReqs--;

            // Do user search callback only when all users have been retrieved
            if (_userSearch.pendingReqs <= 0) {
                _userSearch.doneCb && _userSearch.doneCb();
                _userSearch.searchId = null;
                _userSearch.doneCb = null;
            } else {
                LogSvc.debug('[UserSvc]: Server search waiting on ' + _userSearch.pendingReqs + ' more request(s)');
            }
        }

        function normalizeUserContacts(searchConstraints) {
            searchConstraints = searchConstraints || {};

            var count = 0;
            var localUsers = searchConstraints.localUsers || [];

            // Circuit search contacts are added to _userSearch.users as they are found
            // When search is complete normalize all non-exchange users
            for (var i = _userSearch.users.length - 1; i >= 0; i--) {
                var user = _userSearch.users[i];
                if (!user.isExchangeContact) {
                    if (!searchConstraints.includeUsersWithoutPhone &&
                        (localUsers.indexOf(user.userId) === -1) &&
                        (!user.phoneNumbers || user.phoneNumbers.length === 0)) {
                        // Remove users which are not participants and don't have a phone number
                        _userSearch.users.splice(i, 1);
                        count++;
                    } else if (!user.extendedForPhoneCallSearch) {
                        _userSearch.users[i] = createUserForPhoneCallSearch(user);
                    }
                }
            }
            LogSvc.debug('[UserSvc]: Removed ' + count + ' search results without phone numbers');
        }

        function normalizeExchangeContacts(contacts) {
            if (!contacts) {
                return;
            }

            // Insert valid exchange users
            var filteredOut = 0;
            contacts.forEach(function (contact) {
                UserProfile.normalizeExchangeData(contact);

                contact.firstName = (contact.firstName || '').trim();
                contact.lastName = (contact.lastName || '').trim();

                if ((!contact.firstName && !contact.lastName) || !contact.phoneNumbers || !contact.phoneNumbers.length) {
                    // Do not add contacts without phone numbers
                    filteredOut++;
                    return;
                }
                _userSearch.insertExchangeContact(contact);
            });
            LogSvc.debug('[UserSvc]: Removed ' + filteredOut + ' Exchange search results without name or phone numbers');
        }

        /**
         * Execute user search in Circuit and Exchange. Search local users and then send two server search request to retrieve more results.
         * Matches are added to _userSearch.users as new PhoneCallSearch User Objects
         *
         * @function
         * @param {Object} searchConstraints The search options.
         * @searchConstraints.query {String} The filter string to match from beginning of Firstname or Lastname.
         * @searchConstraints.includeUsersWithoutPhone {Boolean} Indicates whether Circuit users without a phone number should be included in search results.
         * @searchConstraints.includeMeetingPoints {Boolean} Indicates whether Meeting Points should be included in search results.
         * @param {Function} doneCb Callback function when the search is finished.
         */
        function startPhoneCallSearch(searchConstraints, doneCb) {
            /*
                PhoneCallSearch User object definition for UI:
                    displayName: displayName,
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: emailAddress,
                    emailAddresses: [{
                      address: email,
                      type: type
                     }]
                    userId: userId,
                    avatar: avatar,
                    userPresenceState: userPresenceState
                    phoneNumbers: [{
                       phoneNumber: phoneNumber,
                       type: type
                    }]
                    isExchangeContact: boolean if user result is from exchange server or ansible
            */
            searchConstraints = searchConstraints || {};

            clearUserSearch();
            _userSearch.phoneCallSearch = true;

            var promises = [];

            // Use _clientApiHandler.startUserSearch to get Circuit users and in parallel call MailboxConnSvc.searchContactsWithConstraints
            promises.push(new $q(function (resolve, reject) {
                searchConstraints.skipExternalDirectorySearch = true;
                searchByName(searchConstraints, function (err) {
                    if (err && err.cleared) {
                        reject(err);
                        return;
                    }
                    LogSvc.debug('[UserSvc]: Server search is finished. Initial number of matches:', _userSearch.users.length);
                    resolve();
                });
            }));

            promises.push(new $q(function (resolve) {
                searchExchangeByName(searchConstraints, function (err, contacts, searchId) {
                    if (err) {
                        resolve();
                        return;
                    }
                    if (searchId && searchId !== _userSearch.exchangeSearchId) {
                        LogSvc.debug('[UserSvc]: Discarding Exchange results for searchId:', searchId);
                        resolve();
                        return;
                    }
                    _userSearch.exchangeSearchId = null;
                    LogSvc.debug('[UserSvc]: Exchange server search is finished. Initial number of matches: ', contacts && contacts.length);
                    normalizeExchangeContacts(contacts);
                    resolve();
                });
            }));

            $q.all(promises)
            .then(function () {
                normalizeUserContacts(searchConstraints);
                LogSvc.debug('[UserSvc]: User search is finished. Total users = ', _userSearch.users.length);
                doneCb && doneCb();
            })
            .catch(function () {
                LogSvc.debug('[UserSvc]: Previous user search was canceled');
            });
        }

        function publishStatusNotification(user, state) {
            if (user && state) {
                LogSvc.debug('[UserSvc]: Publish /user/status/notification event. userId = ', user.userId);
                PubSubSvc.publish('/user/status/notification', [user, state]);
            }
        }

        function updateUserStatusSubscriptions(userId, subscribed) {
            if (!userId) {
                return null;
            }
            var user = _usersHashtable[userId];
            var idx = _userStatusSubscriptions.indexOf(userId);

            if (subscribed) {
                (idx === -1) && _userStatusSubscriptions.push(userId);
                if (!user) {
                    // Create an empty user object. User data will only be retrieved later (if needed)
                    user = UserProfile.extend({userId: userId});
                    user.noData = true;
                    user.notifyWhenAvailable = true;
                    addUsersToCache(user, true, false);
                } else if (!user.notifyWhenAvailable) {
                    user.notifyWhenAvailable = true;
                    return user;
                }
            } else {
                (idx !== -1) && _userStatusSubscriptions.splice(idx, 1);
                if (user && user.notifyWhenAvailable) {
                    user.notifyWhenAvailable = false;
                    return user;
                }
            }
            return null;
        }

        function clearUserStatusSubscriptions() {
            _userStatusSubscriptions.forEach(function (userId) {
                var user = _usersHashtable[userId];
                if (user) {
                    user.notifyWhenAvailable = false;
                }
            });
            _userStatusSubscriptions = [];
        }

        function loadUsersFromDb() {
            if (_users.length > 0) {
                // Already loaded the users
                return $q.resolve();
            }

            return LocalStoreSvc.getAllUsers()
                .then(function (users) {
                    _usersLoaded = true;
                    if (users) {
                        LogSvc.debug('[UserSvc]: Loaded users from DB: ', users.length);
                        var purged = $rootScope.i18n.map.res_Purged;

                        // Do not use addUsersToCache here. Just store the users directly in the cache.
                        users.forEach(function (user) {
                            // Update the name for purged users in case the language has changed
                            if (user.userState === Constants.UserState.PURGED) {
                                user.displayName = purged;
                                user.firstName = purged;
                            }
                            user = UserProfile.extend(user);
                            _users.push(user);
                            _usersHashtable[user.userId] = user;
                        });
                    }
                })
                .catch(function (err) {
                    _usersLoaded = true;
                    LogSvc.error('[UserSvc]: Error retrieving users from db: ', err);
                });
        }

        /**
         * Creates result for extended search when there was no query passed (default result). It either contains
         * a passed list of users or can have the recent user list.
         *
         * @function
         * @param {boolean} isPhoneSearch True if this is an extended phone search. False if this is an extended user search.
         * @param {Array[]} userIds - Array containing fixed list of results
         * @param {Function} doneCb Callback to pass loaded user objects to
         */
        function createSearchResultForEmptyQuery(isPhoneSearch, userIds, doneCb) {
            if (!userIds || userIds.length === 0) {
                doneCb(isPhoneSearch ? [] : _self.getRecentUsers());
            } else {
                // Create a temporary callback function so we can check if the current search
                // is still valid.
                var dummyCb = function () {};
                _userSearch.doneCb = dummyCb;

                // Limit number of returned users
                userIds = userIds.slice(0, MAX_PHONE_SEARCH_RESULTS);
                _self.getUsersByIds(userIds, function (err, userObjs) {
                    if (_userSearch.doneCb === dummyCb) {
                        // This is still the current search
                        _userSearch.doneCb = null;
                        if (!err) {
                            _userSearch.users = isPhoneSearch ? userObjs.map(createUserForPhoneCallSearch) : userObjs;
                            doneCb(_userSearch.users);
                            return;
                        }
                    }
                    doneCb(isPhoneSearch ? [] : _self.getRecentUsers());
                });
            }
        }

        function resetDiscardParticipantsToMentionTimer() {
            // Cancel any clear timers from previous results
            if (_discardParticipantsToMentionTimer) {
                $timeout.cancel(_discardParticipantsToMentionTimer);
                _discardParticipantsToMentionTimer = null;
            }

            _discardParticipantsToMentionTimer = $timeout(function () {
                _lastMentionQueryResult = {};
            }, ONE_MIN);  // 1 min
        }

        function clearExternalStatus(user) {
            if (user.exchangeData && (user.exchangeData.outOfOffice || user.exchangeData.userAvailability)) {
                LogSvc.info('[UserSvc]: Reset OOO indication for user ', user.emailAddress);
                delete user.exchangeData.outOfOffice;
                delete user.exchangeData.userAvailability;
                delete user.exchangeData.lastSyncUserAvail;
                delete user.exchangeData.lastSync;
                updateUserInDB(user);
            }
        }

        function updateUserExchangeData(user, exchangeData) {
            if (!exchangeData.userAvailability) {
                if (user.exchangeData && user.exchangeData.userAvailability) {
                    // Keep the userAvailability from user if doesn't exist
                    exchangeData.userAvailability = user.exchangeData.userAvailability;
                    exchangeData.lastSyncUserAvail = user.exchangeData.lastSyncUserAvail;
                }
                exchangeData.lastSync = Date.now();
            }
            UserProfile.mergeWithExchangeData(user, exchangeData);
            updateUserInDB(user);
            PubSubSvc.publish('/user/exchangeData/update', [user]);
        }

        function getContact(user) {
            return new $q(function (resolve, reject) {
                MailboxConnSvc.getContact(user.emailAddress, function (getContactErr, contact) {
                    if (getContactErr) {
                        LogSvc.warn('[UserSvc]: Error retrieving user exchange information: ', getContactErr);
                        clearExternalStatus(user);
                        reject(getContactErr);
                        return;
                    }
                    if (contact) {
                        if (typeof MailboxConnSvc.isOutOfOffice === 'function') {
                            // Retrieve Out of Office status
                            MailboxConnSvc.isOutOfOffice(user.emailAddress)
                            .then(function (outOfOffice) {
                                contact.outOfOffice = outOfOffice;
                            })
                            .catch(function (oooErr) {
                                LogSvc.warn('[UserSvc]: Error retrieving OOO status: ', oooErr);
                            })
                            .then(function () {
                                LogSvc.debug('[UserSvc]: Sync exchange data finished with OOO');
                                updateUserExchangeData(user, contact);
                                resolve();
                            });
                        } else {
                            LogSvc.debug('[UserSvc]: Sync exchange data finished without OOO');
                            updateUserExchangeData(user, contact);
                            resolve();
                        }
                    } else {
                        LogSvc.info('[UserSvc]: Exchange connector did not find contact for ', user.emailAddress);
                        // Continue so we update the user object to prevent asking again for now.
                        contact = {};
                        updateUserExchangeData(user, contact);
                        resolve();
                    }
                });
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        _clientApiHandler.on('User.USER_PRESENCE_CHANGE', function (data) {
            if (!$rootScope.localUser) {
                // Not ready to process events
                return;
            }
            if (data.userId === $rootScope.localUser.userId ||
                data.userId === $rootScope.localUser.associatedTelephonyUserID) {
                // Event is for local user or associated TC.
                // This is handled by UserProfileSvc or AtcRegistrationSvc respectively.
                return;
            }

            LogSvc.debug('[UserSvc]: Received User.USER_PRESENCE_CHANGE event');

            // Check if we need to show a status notification for this user
            var idx = _userStatusSubscriptions.indexOf(data.userId);
            var needsNotification = idx !== -1 && data.newState.state === Constants.PresenceState.AVAILABLE;
            if (needsNotification) {
                LogSvc.debug('[UserSvc]: Need to show online status notification for user');
                // Remove user from subscription list
                _userStatusSubscriptions.splice(idx, 1);
            } else if (!hasActiveSubscription(data.userId)) {
                LogSvc.debug('[UserSvc]: There is no presence subscription for this user. Ignore event.');
                return;
            }

            var user = _usersHashtable[data.userId];
            if (!user) {
                LogSvc.debug('[UserSvc]: Cannot find user in cache. userId = ', data.userId);
                if (needsNotification) {
                    LogSvc.debug('[UserSvc]: Get user information to raise status notification');
                    _self.getUserById(data.userId, function (err, retrievedUser) {
                        publishStatusNotification(retrievedUser, Constants.PresenceState.AVAILABLE);
                    });
                }
                return;
            }

            if (user.isInactive) {
                // Inactive user has registered. Update the user data.
                refreshUsers([user]);
            }

            var hasUpdatedUser = UserProfile.updateUserPresenceState(user, data.newState);
            if (needsNotification || hasUpdatedUser) {
                $rootScope.$apply(function () {
                    if (needsNotification) {
                        user.notifyWhenAvailable = false;
                        publishStatusNotification(user, Constants.PresenceState.AVAILABLE);
                    }
                    LogSvc.debug('[UserSvc]: Publish /user/update event');
                    PubSubSvc.publish('/user/update', [user]);
                });
            } else {
                LogSvc.debug('[UserProfileSvc]: User presence state has not changed. Ignore event.');
            }
        });

        _clientApiHandler.on('User.NOTIFICATION_SUBSCRIPTION_CHANGE', function (data) {
            if (!$rootScope.localUser) {
                // Not ready to process events
                return;
            }

            if (!data || !data.userId || !data.subscriptionAction || !data.subscriptionType) {
                LogSvc.error('[UserSvc]: User.NOTIFICATION_SUBSCRIPTION_CHANGE event does not contain valid data.');
                return;
            }

            if (data.subscriptionType === Constants.NotificationSubscriptionType.ONLINE_STATUS) {
                var updatedUser = null;
                switch (data.subscriptionAction) {
                case Constants.NotificationSubscriptionAction.SUBSCRIBE:
                    updatedUser = updateUserStatusSubscriptions(data.userId, true);
                    break;
                case Constants.NotificationSubscriptionAction.UNSUBSCRIBE:
                    updatedUser = updateUserStatusSubscriptions(data.userId, false);
                    break;
                }

                if (updatedUser) {
                    $rootScope.$apply(function () {
                        LogSvc.debug('[UserSvc]: Publish /user/update event. userId =', updatedUser.userId);
                        PubSubSvc.publish('/user/update', [updatedUser]);
                    });
                }
            }
        });

        _clientApiHandler.on('User.USER_UPDATED', function (data) {
            if (!$rootScope.localUser) {
                // Not ready to process events
                return;
            }
            if (!data.user) {
                LogSvc.error('[UserSvc]: User.USER_UPDATED event does not have a user');
                return;
            }
            if (data.user.userId === $rootScope.localUser.userId) {
                return;
            }
            LogSvc.debug('[UserSvc]: Received User.USER_UPDATED event. ', data);
            $rootScope.$apply(function () {
                var user = addUserAndReturnCached(data.user);
                LogSvc.debug('[UserSvc]: Publish /user/update event. userId =', user.userId);
                PubSubSvc.publish('/user/update', [user]);
            });
        });

        _clientApiHandler.on('User.USER_DELETED', function (data) {
            if (!$rootScope.localUser) {
                // Not ready to process events
                return;
            }
            LogSvc.debug('[UserSvc]: Received User.USER_DELETED event. ', data);
            if (!data.userId) {
                LogSvc.error('[UserSvc]: User.USER_DELETED event does not have a user id');
                return;
            }

            $rootScope.$apply(function () {
                var user = _usersHashtable[data.userId];
                if (user) {
                    UserProfile.update(user, {userState: Constants.UserState.DELETED}, true);
                    updateUserInDB(user);
                    LogSvc.debug('[UserSvc]: Publish /user/update event. userId =', user.userId);
                    PubSubSvc.publish('/user/update', [user]);
                } else {
                    LogSvc.debug('[UserSvc]: Publish /user/delete event. userId =', data.userId);
                    PubSubSvc.publish('/user/delete', [data.userId]);
                }
            });
        });

        _clientApiHandler.on('Search.BASIC_SEARCH_RESULT', function (data) {
            if (data.searchId !== _userSearch.searchId || !data.users) {
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[UserSvc]: Received event Search.BASIC_SEARCH_RESULT.');

                var userIds = data.users.filter(function (userId) {
                    return userId !== $rootScope.localUser.userId || _userSearch.includeLocalUser;
                });

                _userSearch.pendingReqs++;
                _self.getUsersByIds(userIds, function (err, users) {
                    // Make sure the same search is still active
                    if (data.searchId !== _userSearch.searchId) {
                        return;
                    }
                    if (users) {
                        // Insert found users in the proper order
                        filterUsers(users, $rootScope.localUser.hasSupportRole, _userSearch.includeLocalUser).forEach(_userSearch.insertUser);
                    }
                    // Decrement the pending requests and invoke the doneCb if appropriate
                    decrementPendingSearchReqs();
                });
            });
        });

        _clientApiHandler.on('Search.SEARCH_STATUS', function (data) {
            if (data.searchId !== _userSearch.searchId) {
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[UserSvc]: Received event Search.SEARCH_STATUS: ', data.status);
                // Decrement the pending requests and invoke the doneCb if appropriate
                decrementPendingSearchReqs();
            });
        });

        _clientApiHandler.on('User.TELEPHONY_CHANGED', function (event) {
            LogSvc.debug('[UserSvc]: Received event User.TELEPHONY_CHANGED: ', event.telephonyChangeType);
            var eventType;
            switch (event.telephonyChangeType) {
            case Constants.TelephonyChangeType.ADDED:
                eventType = '/user/telephony/added';
                break;
            case Constants.TelephonyChangeType.UPDATED:
                eventType = '/user/telephony/updated';
                break;
            case Constants.TelephonyChangeType.REMOVED:
                eventType = '/user/telephony/removed';
                break;
            default:
                break;
            }

            if (eventType) {
                $rootScope.$apply(function () {
                    LogSvc.debug('[UserSvc]: Publish ' + eventType + ' event');
                    PubSubSvc.publish(eventType);
                });
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/registration/stuff', function (data) {
            LogSvc.debug('[UserSvc]: Received /registration/stuff event');

            // First clear all existing subscriptions (if any)
            clearUserStatusSubscriptions();

            loadUsersFromDb()
            .then(function () {
                // Now process the subscriptions which are still active
                data.notificationSubscription && data.notificationSubscription.some(function (subscription) {
                    if (subscription.key === Constants.NotificationSubscriptionType.ONLINE_STATUS) {
                        subscription.value && subscription.value.forEach(function (userId) {
                            updateUserStatusSubscriptions(userId, true);
                        });
                        LogSvc.debug('[UserSvc]: Initialized online status subscription list with ', _userStatusSubscriptions);
                        return true;
                    }
                    return false;
                });

                var userIds = _permanentPresenceSubscriptions.concat(_temporaryPresenceSubscriptions);
                if (userIds.length > 0) {
                    LogSvc.debug('[UserSvc]: Resubscribe to users\' presence');
                    sendSubscribePresence(userIds);
                }

                LogSvc.debug('[UserSvc]: Publish /users/loaded event');
                PubSubSvc.publish('/users/loaded', null);
            });
        });

        PubSubSvc.subscribe('/registration/state', function (state) {
            LogSvc.debug('[UserSvc]: Received /registration/state event');

            switch (state) {
            case RegistrationState.Registered:
                startUserRefreshTimers();
                break;

            case RegistrationState.LoggingOut:
                clearUserRefreshTimers();
                clearUserSvcCache();
                break;

            default:
                clearUserRefreshTimers();
                break;
            }
        });

        PubSubSvc.subscribe('/language/update', function onLanguageUpdate(language) {
            LogSvc.debug('[UserSvc]: Received /language/update event. Language = ', language);

            // Need to update the display name for all purged users
            var updatedUsers = checkForPurgedUsers(_users);
            updatedUsers.forEach(function (user) {
                user.displayNameEscaped = user.displayName;
                Utils.syncBaseObject(user);
            });
        });

        PubSubSvc.subscribe('/conversation/update', function (conversation) {
            LogSvc.debug('[UserSvc]: Received /conversation/update event.');

            if (_lastMentionQueryResult.convId === conversation.convId) {
                // Reset the last query results
                _lastMentionQueryResult = {};
                if (_discardParticipantsToMentionTimer) {
                    $timeout.cancel(_discardParticipantsToMentionTimer);
                    _discardParticipantsToMentionTimer = null;
                }
            }
        });

        PubSubSvc.subscribe('/conversation/item/add', function (item) {
            LogSvc.debug('[UserSvc]: Received /conversation/item/add event.');

            var user = _usersHashtable[item.creatorId];
            if (user && user.isInactive) {
                // Received message from an inactive user, update its data.
                refreshUsers([user]);
            }
            recalculateRecentUsers();
        });

        PubSubSvc.subscribe('/conversation/items/updated', function () {
            LogSvc.debug('[UserSvc]: Received /conversation/items/updated event.');
            recalculateRecentUsers();
        });

        PubSubSvc.subscribe('/conversation/items/loaded', function () {
            LogSvc.debug('[UserSvc]: Received /conversation/items/loaded event.');
            recalculateRecentUsers();
        });

        PubSubSvc.subscribe('/internal/svc/go2Sleep', function () {
            LogSvc.debug('[UserSvc]: Go to Sleep');
            _isMobileAsleep = true;
            clearUserRefreshTimers();
        });

        PubSubSvc.subscribe('/internal/svc/wakeUp', function () {
            LogSvc.debug('[UserSvc]: Wake up');
            _isMobileAsleep = false;
            startUserRefreshTimers();
        });

        PubSubSvc.subscribe('/call/state', function onCallState(call) {
            if (call && !call.isRemote && _localCall !== call) {
                LogSvc.debug('[UserSvc]: Keep reference to active call');
                _localCall = call;
            }
        });

        PubSubSvc.subscribe('/call/ended', function onCallEnded(call) {
            if (call && call.sameAs(_localCall)) {
                LogSvc.debug('[UserSvc]: Clear reference to active call');
                _localCall = null;
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Get a promised that is resolved when the users have been loaded from the cache
         * @returns {object} Promise object.
         */
        this.getUsersLoadedPromise = function () {
            return new $q(function (resolve) {
                if (_usersLoaded) {
                    resolve();
                } else {
                    PubSubSvc.subscribeOnce('/users/loaded', function () {
                        resolve();
                    });
                }
            });
        };

        this.addUsersToCache = function (users, cacheOnly, retrieveIncomplete) {
            if (users) {
                cacheOnly = !!cacheOnly;
                retrieveIncomplete = !!retrieveIncomplete;
                LogSvc.debug('[UserSvc]: addUsersToCache. cacheOnly = ' + cacheOnly + ', retrieveIncomplete = ' + retrieveIncomplete);
                addUsersToCache(users, cacheOnly, retrieveIncomplete);
            }
        };

        this.refreshUsers = function (users) {
            if (users && users.length) {
                LogSvc.debug('[UserSvc]: refreshUsers...');
                var cachedUsers = users.map(function (user) {
                    return user && user.userId && _usersHashtable[user.userId];
                }).filter(function (user) {
                    return !!user;
                });
                refreshUsers(cachedUsers);
            }
        };

        this.refreshUser = function (userId) {
            if (userId && typeof userId === 'string') {
                var cachedUser = _usersHashtable[userId];
                if (cachedUser) {
                    LogSvc.debug('[UserSvc]: refreshUser - userId = ', userId);
                    refreshUsers([cachedUser]);
                }
            }
        };

        this.updateUserInDB = updateUserInDB;

        // Return local users
        this.getCachedUsers = function () {
            return _users;
        };

        // Return initialzed local users
        this.getCachedUsersWithData = function () {
            return _users.filter(function (u) {
                return !u.noData && !u.isDeleted && (u.userType === Constants.UserType.REGULAR || u.userType === Constants.UserType.XMPP);
            });
        };

        // Return local cached users
        this.getUserFromCache = getUserFromCache;
        this.getUser = getUserFromCache; // DEPRECATED: Will be removed as soon as mobile clients start using new getUserFromCache API

        /**
         * Return local cached user if exists. Otherwise creates the placeholder User object with noData property.
         *
         * @function
         * @param {String} userId  The userId of the user to be retrieved
         * @returns {Object} The user object
         */
        this.getExtendedUser = getExtendedUser;

        this.hasUser = function (userId) {
            return !!_usersHashtable[userId];
        };

        this.getRecentUsers = function (fillUp, maxUsers) {
            LogSvc.debug('[UserSvc]: getRecentUsers...');
            maxUsers = Math.min(maxUsers || MAX_RECENT_USERS, MAX_RECENT_USERS);
            var recentUsers = _recentUsers.slice(0, maxUsers);
            if (fillUp && recentUsers.length < maxUsers) {
                // If we don't have enough users yet, then fill the array with users from
                // other group conversations (i.e. those that don't have a lastActvity timestamp yet).
                _users.some(function (u) {
                    if (!u.lastActivity && isValidRecentUser(u)) {
                        recentUsers.push(u);
                    }
                    return recentUsers.length === maxUsers;
                });
            }
            return recentUsers;
        };

        /**
         * This function retrieves cached presence or presence from server for given userIds (cached for subscribed,
         * server presence for unsubscribed)
         *
         * @function
         * @param {String[]} userIds  The userIds for which we want to retrieve the presence.
         */
        this.getUsersPresence = function (userIds, cb) {
            getUsersPresence(userIds, false, cb);
        };

        /**
         * This function starts a permanent presence subscription for the given userIds.
         *
         * @function
         * @param {String[]} userIds  The userIds for which we are supposed to subscribe.
         * @param {Boolean} prioritized  If true subscriptions are prioritized in case subscription list is already full.
         */
        this.addPermanentPresenceSubscription = function (userIds, prioritized) {
            subscribePermanentPresence(userIds, !!prioritized);
        };

        /**
         * This function starts the presence subscription for the peer users in the most recent
         * direct conversations. This function is supposed to be invoked as soon as the conversations
         * are initially loaded.
         *
         * @function
         * @param {String[]} userIds  The userIds for which we are supposed to subscribe.
         */
        this.initPermanentPresenceSubscriptions = function (userIds) {
            LogSvc.info('[UserSvc]: Initialize permanent presence subscriptions');
            subscribePermanentPresence(userIds, true);
        };

        /**
         * This function starts a temporary presence subscribtion to the users presence unless already
         * subscribed to. The subscriptions are maintained until we need to free space for newer temporary
         * subscriptions.
         *
         * @function
         * @param {String[]} userIds  The userIds for which we are supposed to subscribe.
         */
        this.subscribeTemporaryPresence = function (userIds) {
            subscribeTemporaryPresenceAndNotify(userIds);
        };

        /**
         * This function starts a temporary presence subscribtion to the users presence unless already
         * subscribed to. The subscriptions are maintained until we need to free space for newer temporary
         * subscriptions.
         *
         * @function
         * @param {Object} data An object containing data like
         *                {String[]} userIds  The userIds for which we are supposed to subscribe.
         * *              {Object[]} users    The user objects containing the userIds for which we are supposed to subscribe.
         */
        this.subscribeTemporaryPresenceV2 = function (data) {
            if (!data || (!Array.isArray(data.userIds) && !Array.isArray(data.users))) {
                return;
            }

            var userIds = data.userIds || [];
            if (Array.isArray(data.users)) {
                userIds = userIds.concat(data.users.map(function (u) {
                    return u.userId;
                }));
            }

            subscribeTemporaryPresenceAndNotify(userIds);
        };

        /**
         * This function starts a temporary presence subscribtion to the users presence unless already
         * subscribed to. The subscriptions are maintained until we need to free space for newer temporary
         * subscriptions. Once subscription process finishes callback is called.
         *
         * @function
         * @param {String[]} userIds  The userIds for which we are supposed to subscribe.
         * @param {function} cb Callback with parameters: (error, userIds)
         */
        this.subscribeTemporaryPresenceAndNotify = subscribeTemporaryPresenceAndNotify;

        /**
         * Gets the presence state for the given user.
         *
         * @function
         * @param {userId} userId to be updated with presence data
         * @param {function} cb Callback with parameters: error and userPresenceState
         */
        this.getUserPresence = function (userId, cb) {
            LogSvc.debug('[UserSvc]: Get presence and location for userId = ', userId);
            getUsersPresence([userId], false, function (err, presenceStates) {
                if (!err && presenceStates && presenceStates.length > 0) {
                    LogSvc.debug('[UserSvc]: Got presence status for user ', userId);
                    cb && cb(null, presenceStates[0]);
                    return;
                }
                cb && cb(err || 'No results');
            });
        };

        /**
         * Execute user search. Search local users and then send server search request to retrieve more results.
         *
         * @function
         * @param {Object} constraints
         * @param {Array[]} constraints.query - Contains the query string, the match is from beginning of Firstname or Lastname.
         * @param {boolean} constraints.includeXmppUsers - The flag indicates if Xmpp users should be retrieved
         * @param {Function} doneCb Callback function when the search is finished.
         */
        this.startUserSearch = function (constraints, doneCb) {
            clearUserSearch();
            searchByName(constraints, doneCb);
        };

        /**
         * Execute extended phone search. Search local users and then send server search request and query exchange connector to retrieve more results.
         *
         * @function
         * @param {Object} searchConstraints The search options.
         * @searchConstraints.query {String} The filter string to match from beginning of Firstname or Lastname.
         * @searchConstraints.localUsers {Object} Array with local user set (e.g. predefined users for popover).
         * @searchConstraints.includeMeetingPoints {Boolean} Indicates whether Meeting Points should be included in search results.
         * @param {Function} doneCb Callback function when the search is finished.
         */
        this.startExtendedPhoneSearch = function (searchConstraints, doneCb) {
            doneCb = doneCb || function () {};

            clearUserSearch();

            if (searchConstraints.query) {
                startPhoneCallSearch(searchConstraints, function () {
                    doneCb(_userSearch.users);
                });
            } else {
                createSearchResultForEmptyQuery(true, searchConstraints.localUsers, doneCb);
            }
        };

        /**
         * Execute extended user search either returning the list of recent users or start server search by user name or email address.
         *
         * @function
         * @param {Object} searchConstraints The search options.
         * @searchConstraints.query {String} The filter string to match from beginning of Firstname or Lastname.
         * @searchConstraints.triggerEmailSearch {Boolean} Marker that user entered email address for search.
         * @searchConstraints.includeXmppUsers {Boolean} Indicates whether XMPP users should be included in search results.
         * @searchConstraints.includeMeetingPoints {Boolean} Indicates whether Meeting Points should be included in search results.
         * @param {Function} doneCb Callback function when the search is finished.
         */
        this.startExtendedUserSearch = function (searchConstraints, doneCb) {
            doneCb = doneCb || function () {};

            var resultInfo = {};
            if (!searchConstraints.query) {
                createSearchResultForEmptyQuery(false, searchConstraints.localUsers, doneCb);
            } else {
                _self.startUserSearch(searchConstraints, function (err, emailUser) {
                    if (err && err.cleared) {
                        // This search is obsolete. Ignore the result
                        return;
                    }
                    if (searchConstraints.triggerEmailSearch) {
                        if (emailUser && !emailUser.notFound) {
                            // User has pressed space to confirm this is the exact email to search and is valid
                            resultInfo.emailValid = true;
                            resultInfo.emailUser = emailUser;
                        } else if (searchConstraints.query.match(Utils.EMAIL_ADDRESS_PATTERN)) {
                            // User has pressed space to confirm this is the exact email to search but it is invalid
                            var userObj = {
                                displayName: searchConstraints.query,
                                displayNameEscaped: Utils.textToHtmlEscaped(searchConstraints.query),
                                emailAddress: searchConstraints.query,
                                presence: {
                                    state: Constants.PresenceState.OFFLINE
                                },
                                notFound: true,
                                storageOverloaded: err === Constants.ReturnCode.STORAGE_OVERLOADED
                            };
                            resultInfo.emailInvalid = true;
                            resultInfo.emailUser = userObj;
                        }
                    }
                    var result = _userSearch.users.slice();
                    doneCb(result, resultInfo);
                });
            }
        };

        /**
         * Execute user search in Circuit and Exchange. Search local users and then send two server search request to retrieve more results.
         * Matches are added to _userSearch.users as new PhoneCallSearch User Objects
         *
         * @function
         * @param {String} query The query string, the match is from beginning of Firstname or Lastname, or partial user profile's phone numbers match.
         * @param {Function} doneCb Callback function when phone number search is finished.
         * @param {Boolean} includeUsersWithoutPhone Control if users without phones are filtered out (false) or not (true)
         */
        this.startPhoneCallSearch = function (query, doneCb, includeUsersWithoutPhone) {
            var searchConstraints = {
                query: query,
                includeUsersWithoutPhone: !!includeUsersWithoutPhone,
                phoneNumberLookup: true
            };
            startPhoneCallSearch(searchConstraints, doneCb);
        };


        /**
         * Execute user search in Circuit and Exchange. Search local users and then send two server search request to retrieve more results.
         * Matches are added to _userSearch.users as new PhoneCallSearch User Objects
         *
         * @function
         * @param {String} query The query string, the match is from beginning of Firstname or Lastname, or partial user profile's phone numbers match.
         * @param {Function} doneCb Callback function when phone number search is finished. If search resulted in one and only one match, this user is passed as a parameter.
         */
        this.startReverseLookUp = function (phoneNumber, doneCb) {
            var searchConstraints = {
                query: phoneNumber,
                reversePhoneNumberLookup: true
            };
            startPhoneCallSearch(searchConstraints, function () {
                var users = [];
                // If there are more than one matches then prioritize Circuit results over Exchange
                if (_userSearch.users.length > 1) {
                    users = _userSearch.users.filter(function (user) {
                        return !user.isExchangeContact;
                    });
                } else if (_userSearch.users.length === 1) {
                    users = _userSearch.users.slice(0);
                }
                doneCb && doneCb(users.length === 1 ? users[0] : null);
            });
        };

        /**
         * Clear the user search.
         *
         * @function
         */
        this.clearUserSearch = clearUserSearch;

        /**
         * Get the search user list.
         *
         * @returns {SearchResults.user} Returns the user list results from search
         */
        this.getUserSearchList = function () {
            return _userSearch.users;
        };

        /**
        * Expose sort function for user arrays (using Array.sort).
         * @function
         * @param {String} Optional search term to sort the list.
         */
        this.sortUsersFn = sortUsers;

        /**
        * Insert users based on the following sort rules:
        * 1. The results that match the firstname are listed first.
        * 2. For names that match the firstname, sort them by firstname.
        * 3. For names that match the lastname, sort them by lastname.
         * @function
         * @param {User} New user to be inserted.
         * @param {String} Search term to sort the insertion.
         * @param {User[]} User list to insert.
         */
        this.insertSortedUser = insertSortedUser;

        /**
         * Filter the users using the query string. LocalUser will be excluded from the list.
         * The passed in users maybe the result returned from the server and will be extended.
         *
         * @param {Array} users An array of users to be filtered.
         * @param {String} query The string to be matched from beginning of first name or last name.
         * @param {Boolean} includeSpecialUser Include the special users or not, default not including.
         * @returns {Array} Array of users after filtering.
         */
        this.filterUsers = function (users, query, includeSpecialUser) {
            return filterUsers(userNameFilter(users, query), includeSpecialUser);
        };

        /**
         * Retrieve exchange information for users
         *
         * @param {Object} user The user to query for exchange information
         */
        this.syncUserExchangeInfo = function (user, fasterSync) {
            return new $q(function (resolve, reject) {
                if (!user) {
                    reject('User is not valid');
                    return;
                }
                LogSvc.debug('[UserSvc]: Started syncUserExchangeInfo for user ', user.emailAddress);

                if (!MailboxConnSvc.connected) {
                    clearExternalStatus(user);
                    reject('Not connected');
                    return;
                }

                var isSyncTimeExpired = function (fasterSyncTime, syncTime, lastSync) {
                    if (lastSync) {
                        // If fasterSync is set, check if data is older than fasterSyncTime.
                        // Otherwise, check if data is older than a syncTime.
                        var timeToCheck = fasterSync ? fasterSyncTime : syncTime;
                        if (Date.now() - lastSync <= timeToCheck) {
                            // Use cached data
                            return false;
                        }
                    }
                    return true;
                };

                var updateUserAvailability = function (userAvailability) {
                    if (userAvailability) {
                        user.exchangeData = user.exchangeData || {};
                        user.exchangeData.userAvailability = userAvailability;
                        user.exchangeData.lastSyncUserAvail = Date.now();
                        updateUserExchangeData(user, user.exchangeData);
                    }
                };

                var promiseUserAvailability = $q.resolve();

                // UserAvailability
                // If fasterSync is set, check if data is older than 30 min. Otherwise, 15 min.
                if (MailboxConnSvc.supportsUserAvailability() && isSyncTimeExpired(ONE_MIN, TEN_MIN,
                    user.exchangeData && user.exchangeData.userAvailability && user.exchangeData.lastSyncUserAvail)) {
                    if (typeof MailboxConnSvc.getUserAvailability === 'function') {
                        LogSvc.debug('[UserSvc]: Sync UserAvailability for ', user.emailAddress);
                        if (user.exchangeData) {
                            user.exchangeData.lastSyncUserAvail = Date.now();
                        }
                        // Retrieve In Meeting status
                        promiseUserAvailability = MailboxConnSvc.getUserAvailability(user.emailAddress)
                        .then(function (userAvailability) {
                            updateUserAvailability(userAvailability);
                            LogSvc.debug('[UserSvc]: Sync UserAvailability finished');
                        })
                        .catch(function (err) {
                            LogSvc.warn('[UserSvc]: Error retrieving UserAvailability status: ', err);
                        });
                    }
                }

                promiseUserAvailability
                .then(function () {
                    var promiseContact = $q.resolve();
                    // Exchange Contact & OOO
                    // If fasterSync is set, check if data is older than 1 hour. Otherwise, 1 day.
                    if (isSyncTimeExpired(ONE_HOUR, ONE_DAY, user.exchangeData && user.exchangeData.lastSync)) {
                        LogSvc.debug('[UserSvc]: Sync exchange data for ', user.emailAddress);
                        promiseContact = getContact(user);
                    }

                    promiseContact
                    .then(function () {
                        // Log full data only in development mode
                        LogSvc.debug('[UserSvc]: Finished syncUserExchangeInfo for user ', $rootScope.developmentMode ? user.exchangeData : user.emailAddress);
                        resolve();
                    })
                    .catch(function (err) {
                        LogSvc.debug('[UserSvc]: Error syncing exchange data for user. ', err);
                        reject(err);
                    });
                })
                .catch(function (err) {
                    LogSvc.debug('[UserSvc]: Error syncing user availability. ', err);
                    reject(err);
                });
            });
        };

        /**
         * Retrieve a user from the server, based on its email address
         *
         * @param {String} emailAddress The user's main email address
         * @param {Function} cb Callback returning error and user parameters
         */
        this.getUserByEmail = getUserByEmail;

        /**
         * Retrieve a user from the server, based on its email address
         *
         * @param {String} emailAddress User Main Email Address
         * @param {Promise}
         */
        this.getUserByEmailPromise = function (email) {
            var deferred = $q.defer();
            getUserByEmail(email, function (err, user) {
                if (err || !user) {
                    deferred.reject(Constants.ReturnCode.NO_RESULT);
                    return;
                }
                deferred.resolve(user);
            });
            return deferred.promise;
        };

        /**
         * Retrieve a list of users from the server, based on their email addresses
         *
         * @param {Array} emailAddresses The user(s) main email addresses
         * @param {Function} cb Callback returning error and user parameters
         * @param {Object} filter
         * @param {boolean} filter.xmppLookup - Flag indicating if XMPP users should be searched for.
         */
        this.getUsersByEmails = function (emailAddresses, cb, filter) {
            filter = filter || {};
            filter.emailAddresses = emailAddresses;
            getUsersByEmails(filter, cb);
        };

        /**
         * Retrieve user from cache (if available) or from server.
         *
         * @param {String} userId User ID
         * @param {Function} cb Callback returning error and user parameters
         */
        this.getUserById = function (userId, cb) {
            cb = cb || function () {};
            if (!userId) {
                cb(Constants.ReturnCode.NO_RESULT);
                return;
            }

            if ($rootScope.localUser && userId === $rootScope.localUser.userId) {
                cb(null, $rootScope.localUser);
                return;
            }

            var outdatedTimestamp = Date.now() - USER_GET_BY_ID_REFRESH_INTERVAL;
            var user = _usersHashtable[userId];
            if (user) {
                if (!user.noData && user.updatedTimeStamp > outdatedTimestamp) {
                    cb(null, user);
                    return;
                }
            }
            getUsersByIds([userId], false, function (users) {
                if (!users.length) {
                    cb(Constants.ReturnCode.NO_RESULT);
                } else {
                    user = addUserAndReturnCached(users[0]);
                    cb(null, user);
                }
            });
        };

        this.getUserPromise = function (userId) {
            var defer = $q.defer();
            _self.getUserById(userId, function (err, user) {
                err ? defer.reject(ControllerRouteError.USER_NOT_FOUND) : defer.resolve(user);
            });
            return defer.promise;
        };

        /**
         * Retrieve users from cache (if available) or from server. Order is not preserved.
         *
         * @param {String} userIds User IDs
         * @param {Function} cb Callback returning error and users parameters
         */
        this.getUsersByIds = function (userIds, cb) {
            cb = cb || function () {};
            if (!Array.isArray(userIds) || userIds.length === 0) {
                cb(Constants.ReturnCode.NO_RESULT);
                return;
            }

            var users = [];
            var pendingUserIds = [];
            var localUserId = $rootScope.localUser && $rootScope.localUser.userId;
            var outdatedTimestamp = Date.now() - USER_GET_BY_ID_REFRESH_INTERVAL;

            userIds.forEach(function (userId) {
                if (userId === localUserId) {
                    users.push($rootScope.localUser);
                    return;
                }
                var user = _usersHashtable[userId];
                if (user) {
                    if ((!user.noData && user.updatedTimeStamp > outdatedTimestamp) || user.reqPending) {
                        users.push(user);
                        return;
                    }
                }
                pendingUserIds.push(userId);
            });

            if (pendingUserIds.length === 0) {
                cb(null, users);
                return;
            }

            getUsersByIds(pendingUserIds, true, function (otherUsers, finished) {
                if (otherUsers.length > 0) {
                    // TODO: Remove updatePresence parameter when presence subscriptions are implemented
                    Array.prototype.push.apply(users, addUsersAndReturnCached(otherUsers, true));
                }

                if (finished) {
                    if (users.length > 0) {
                        cb(null, users);
                    } else {
                        cb(Constants.ReturnCode.NO_RESULT);
                    }
                }
            });
        };

        this.toggleUserStatusUpdateSubscription = function (userIdsList, subscribe, cb) {
            _clientApiHandler.updateStatusSubscription(userIdsList, subscribe, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserSvc]: Error updating status subscription.', err);
                    }
                    cb && cb(err);
                });
            });
        };

        this.updateUserWithExchangeContactName = function (user) {
            if (MailboxConnSvc.updateUserWithLocalContactName) {
                MailboxConnSvc.updateUserWithLocalContactName(user);
            }
        };

        /**
         * Search conversation participants for mention.
         * Retrieves the participants of the conversation whose name matches the given query string.
         *
         * @param {String} query The user name to search for.
         * @param {String} convId The conversation Id.
         * @returns {Promise} returning the array of participants that match the query.
         */
        this.searchConversationParticipants = function (query, convId) {
            if (!convId) {
                return $q.reject('No conversation ID provided');
            }
            if (!query || !query.trim()) {
                return $q.reject('Invalid input');
            }

            var deferred = $q.defer();

            _clientApiHandler.searchConversationParticipants(query, convId, function (err, participants) {
                if (err) {
                    LogSvc.error('[UserSvc]: Error in API searchConversationParticipants: ', err);
                    deferred.reject(err);
                    return;
                }

                // Normalize the participant avatars
                participants = participants.filter(function (p) {
                    if (p.userId !== $rootScope.localUser.userId) {
                        UserProfile.setAvatars(p);
                        p.displayName = (p.firstName + ' ' + p.lastName).trim();
                        return true;
                    }
                    return false;
                });

                deferred.resolve(participants);
            });

            return deferred.promise;
        };

        /**
         * Get users for mention.
         * Retrieves the participants of the conversation whose name matches the given query string. The current user can also be part of the resulting list.
         *
         * @param {String} conv The conversation.
         * @param {String} query The user name to search for
         * @param {Function} cb Callback containing the searchPointer, hasMore and array of participant if query is made, else just filtered cached participants
         * objects which internally contain the user object.
         */
        this.getParticipantsToMention = function (conv, query, cb) {
            if (!conv || !conv.convId) {
                cb('No conversation object provided');
                return;
            }
            query = query || '';
            query = query.toLowerCase();

            var filteredParticipants = [];

            // Cancel any pending timer
            if (_getParticipantsToMentionTimer) {
                $timeout.cancel(_getParticipantsToMentionTimer);
                _getParticipantsToMentionTimer = null;
            }

            if ((_lastMentionQueryResult.convId === conv.convId) && query.startsWith(_lastMentionQueryResult.query)) {
                // A similar request has already been made and the response contained all matches. Filter and return the cached result.
                filteredParticipants = UserProfile.filterUsersByName(_lastMentionQueryResult.participants, query);
                cb(null, filteredParticipants);
                return;
            }

            if (conv.peerUsers && conv.peerUsers.length <= MAX_CONVERSATION_PARTICIPANTS_FOR_LOCAL_SEARCH && !Conversation.hasIncompleteUsers(conv)) {
                // Force local search
                filteredParticipants = UserProfile.filterUsersByName(conv.peerUsers, query);
                if (UserProfile.filterUserByName($rootScope.localUser, query)) {
                    filteredParticipants.push($rootScope.localUser);
                }

                _lastMentionQueryResult = {
                    convId: conv.convId,
                    query: query,
                    participants: filteredParticipants
                };
                resetDiscardParticipantsToMentionTimer();
                cb(null, filteredParticipants);
                return;
            }

            // Set the pending mention query
            _pendingMentionQuery = {
                convId: conv.convId,
                query: query
            };

            // Don't send the request as soon as the user starts typing the mention
            _getParticipantsToMentionTimer = $timeout(function () {
                _getParticipantsToMentionTimer = null;

                _self.searchConversationParticipants(query, conv.convId)
                .then(function (foundParticipants) {
                    if (_pendingMentionQuery.convId !== conv.convId || _pendingMentionQuery.query !== query) {
                        // This is not the latest query. Ignore the results
                        return;
                    }

                    // Add current user to resulting list if it matches the query
                    if (UserProfile.filterUserByName($rootScope.localUser, query)) {
                        foundParticipants.push($rootScope.localUser);
                    }

                    // We have all the participants for the selected query. Save the query results.
                    _lastMentionQueryResult = {
                        convId: conv.convId,
                        query: query,
                        participants: foundParticipants
                    };
                    resetDiscardParticipantsToMentionTimer();

                    cb(null, foundParticipants);
                })
                .catch(cb);

            }, GET_PARTICIPANTS_TO_MENTION_DELAY);
        };

        /**
         * Returns additional support information for the given user ID.
         * @param {String} userId  The userId of the user to retrieve the support info.
         * @returns {Promise} Promise with the support information data.
         */
        this.getSupportData = function (userId) {
            if (!$rootScope.localUser.hasSupportRole) {
                return $q.reject('Not a support user');
            }
            return new $q(function (resolve, reject) {
                _clientApiHandler.getSupportData(userId, function (err, data) {
                    $rootScope.$apply(function () {
                        if (err && !data) {
                            LogSvc.error('[UserSvc]: Error getting support data. ', err);
                            reject(err);
                            return;
                        }
                        LogSvc.debug('[UserSvc]: Retrieved support data for userId = ', userId);
                        resolve(data);
                    });
                });
            });
        };

        this.isIncompleteUser = function (user, cacheOnly) {
            return !!(user && user.userId && ((user.noData && !user.reqPending) || (user.noDB && !cacheOnly)));
        };

        this.retrieveIncompleteUsers = function (elements, properties, cacheOnly) {
            if (!elements || !properties || !properties.length) {
                return;
            }
            if (!Array.isArray(elements)) {
                elements = [elements];
            }

            // Retrieve user data for the incomplete users (i.e. noData === true).
            var incompleteUsers = [];
            var helperHash = {};

            var checkUser = function (user) {
                if (_self.isIncompleteUser(user, cacheOnly) && !helperHash[user.userId]) {
                    incompleteUsers.push(user);
                    helperHash[user.userId] = true;
                }
            };

            elements.forEach(function (element) {
                element && properties.forEach(function (property) {
                    checkUser(element[property]);
                });
            });

            if (incompleteUsers.length > 0) {
                LogSvc.debug('[UserSvc]: Retrieve the data for incomplete users. Total = ', incompleteUsers.length);
                _self.addUsersToCache(incompleteUsers, !!cacheOnly, true);
            }
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.UserSvcImpl = UserSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
