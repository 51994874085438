var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ContactCardMessage = circuit.Enums.ContactCardMessage;
    var ContactCardResponseCode = circuit.Enums.ContactCardResponseCode;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function ContactCardSvcImpl($rootScope, $q, LogSvc, UtilSvc, PubSubSvc, PopupSvc, UserSvc, ConversationSvc, CallControlSvc, ExtensionSvc) { // NOSONAR

        LogSvc.debug('New Service: ContactCardSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var START_CALL_TIME_OUT = 30000;
        var ANSWER_CALL_TIME_OUT = 30000;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _processingPendingRequest = false;
        var _phoneCallsAvailable = false;
        var _userToUserHandler = UserToUserHandler.getInstance();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function isClientBusy(cb) {
            var activeCall = CallControlSvc.getActiveCall();
            if ((activeCall && activeCall.isPresent()) || _processingPendingRequest) {
                cb(ContactCardResponseCode.BUSY);
                return true;
            }
            return false;
        }

        function onCallInitiated(cb, error, warn, call) {
            _processingPendingRequest = false;
            if (error || warn) {
                PopupSvc.handleCallError(error, warn);
                if (error) {
                    cb(ContactCardResponseCode.CALL_FAILED);
                    return;
                }
            }
            cb(ContactCardResponseCode.OK, call && call.callId);
        }

        function startTelephonyCall(contactNumber, contactName, mediaType, cb) {
            if (isClientBusy(cb)) {
                return;
            }
            if (!_phoneCallsAvailable) {
                cb(ContactCardResponseCode.TELEPHONY_NOT_AVAILABLE);
                return;
            }

            _processingPendingRequest = true;
            ConversationSvc.getTelephonyConversation(function (err, conv) {
                if (err || !conv) {
                    _processingPendingRequest = false;
                    cb(ContactCardResponseCode.CONVERSATION_NOT_FOUND);
                } else {
                    var destination = {
                        dialedDn: contactNumber,
                        toName: contactName,
                        mediaType: mediaType
                    };
                    CallControlSvc.dialUsingDefaultDevice(destination, onCallInitiated.bind(null, cb));
                    LogSvc.debug('[ContactCardSvc]: Publish /conversation/navigate event with convId = ', conv.convId);
                    PubSubSvc.publish('/conversation/navigate', [conv]);
                }
            });
        }

        function sendResponse(type, destUserId, destClientId, reqId, respCode, data) {
            if (!destClientId || !reqId) {
                LogSvc.error('[ContactCardSvc]: sendResponse invoked without required parameters');
                return;
            }
            LogSvc.debug('[ContactCardSvc]: Send response for ', type);

            var response = {
                content: {
                    type: type,
                    requestId: reqId,
                    response: {
                        code: respCode || ContactCardResponseCode.OK,
                        data: data
                    }
                },
                destUserId: destUserId,
                destClientId: destClientId
            };
            _userToUserHandler.sendContactCardRequest(response);
        }

        function normalizeMediaType(mediaType) {
            switch (mediaType) {
            case 'telephony':
            case 'audio':
                return {audio: true, video: false, desktop: false};
            case 'video':
                return {audio: true, video: true, desktop: false};
            default:
                return null;
            }
        }

        function invokeCallControlApi(apiName, data, cb) {
            if (isClientBusy(cb)) {
                return;
            }

            var normalizedMediaType = normalizeMediaType(data.mediaType || 'audio');
            _processingPendingRequest = true;
            CallControlSvc[apiName](data.callId || data.convId, normalizedMediaType, onCallInitiated.bind(null, cb));
            ExtensionSvc.bringToFront();
        }

        function handleClickToCallRequest(data, cb) {
            var normalizedMediaType = normalizeMediaType(data.mediaType);
            startTelephonyCall(data.contact.phoneNumber, data.contact.displayName, normalizedMediaType, cb);
            ExtensionSvc.bringToFront();
        }

        function handleGenericNavigate(data, cb) {
            if (data.path === 'myprofile') {
                navigateUser($rootScope.localUser.userId, cb);
                return;
            }
            var path = data.path.split(':');
            if (path.length !== 2) {
                LogSvc.error('[ContactCardSvc]: Incorrect path in NAVIGATE_REQUEST request: ', data.path);
                cb(ContactCardResponseCode.NAVIGATE_REQUEST_FAILED);
                return;
            }
            var type = path[0];
            var action = path[1];
            switch (type) {
            case 'settings':
                navigateSettings(action, cb);
                break;
            case 'user':
                navigateUser(action, cb);
                break;
            default:
                LogSvc.error('[ContactCardSvc]: Incorrect path in NAVIGATE_REQUEST request: ', data.path);
                cb(ContactCardResponseCode.NAVIGATE_REQUEST_FAILED);
            }
        }

        function navigateSettings(tab, cb) {
            ExtensionSvc.bringToFront();
            $rootScope.openView('settings', { settings: { selectedTab: tab } });
            cb(ContactCardResponseCode.OK);
        }

        function navigateUser(userId, cb) {
            ExtensionSvc.bringToFront();
            if (userId === $rootScope.localUser.userId) {
                $rootScope.openView('myprofile');
                cb(ContactCardResponseCode.OK);
                return;
            }
            UserSvc.getUserPromise(userId)
            .then(function () {
                $rootScope.viewUserProfile(userId);
                cb(ContactCardResponseCode.OK);
            })
            .catch(function (err) {
                LogSvc.warn('[ContactCardSvc] Failed to retrieve user profile. ', err);
                cb(ContactCardResponseCode.NAVIGATE_REQUEST_FAILED);
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        function onTelephonyData(data) {
            LogSvc.debug('[ContactCardSvc] Received /telephony/data event. phoneCallsAvailable = ', data.phoneCallsAvailable);
            _phoneCallsAvailable = data.phoneCallsAvailable;
        }

        PubSubSvc.subscribe('/telephony/data', onTelephonyData);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Client API Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        if (!Utils.isMobile()) {
            // CLICK_TO_CALL_REQUEST, CLICK_TO_ANSWER_REQUEST and NAVIGATE_REQUEST are only applicable for web and desktop app.
            _userToUserHandler.on('CONTACT_CARD.CLICK_TO_CALL_REQUEST', function (data, routing) {
                try {
                    LogSvc.debug('[ContactCardSvc]: Received CLICK_TO_CALL_REQUEST with data: ', data);

                    var onResponseHandler = sendResponse.bind(null, ContactCardMessage.CLICK_TO_CALL_RESPONSE, routing.srcUserId, routing.srcClientId, data.requestId);

                    if (!data.contact || data.mediaType !== 'telephony') {
                        LogSvc.error('[ContactCardSvc]: Missing contact object in CLICK_TO_CALL_REQUEST request');
                        onResponseHandler(ContactCardResponseCode.CALL_FAILED);
                        return;
                    }

                    if (data.promptBeforeDial) {
                        var _startCallOptions = {
                            message: data.sdk ? 'res_SdkStartCallText' : 'res_ContactCardStartCallText',
                            title: 'res_StartCall',
                            yesLabel: 'res_Call',
                            noLabel: 'res_Cancel',
                            timeOut: START_CALL_TIME_OUT,
                            backdrop: true
                        };

                        ExtensionSvc.bringToFront();
                        PopupSvc.confirm(_startCallOptions)
                        .result
                        .then(function () {
                            handleClickToCallRequest(data, onResponseHandler);
                        })
                        .catch(function () {
                            onResponseHandler(ContactCardResponseCode.CALL_DECLINED);
                        });
                    } else {
                        handleClickToCallRequest(data, onResponseHandler);
                    }
                } catch (e) {
                    LogSvc.error('[ContactCardSvc]: Exception handling CLICK_TO_CALL_REQUEST event. ', e);
                }
            });

            _userToUserHandler.on('CONTACT_CARD.CLICK_TO_ANSWER_REQUEST', function (data, routing) {
                try {
                    LogSvc.debug('[ContactCardSvc]: Received CLICK_TO_ANSWER_REQUEST with data: ', data);

                    var onResponseHandler = sendResponse.bind(null, ContactCardMessage.CLICK_TO_ANSWER_RESPONSE, routing.srcUserId, routing.srcClientId, data.requestId);

                    if (data.promptBeforeAnswer) {
                        var _answerCallOptions = {
                            message: 'res_SdkAnswerCallText',
                            title: 'res_AnswerCall',
                            yesLabel: 'res_Answer',
                            noLabel: 'res_Cancel',
                            timeOut: ANSWER_CALL_TIME_OUT,
                            backdrop: true
                        };

                        ExtensionSvc.bringToFront();
                        PopupSvc.confirm(_answerCallOptions)
                        .result
                        .then(function () {
                            invokeCallControlApi('answerCall', data, onResponseHandler);
                        })
                        .catch(function () {
                            onResponseHandler(ContactCardResponseCode.CALL_DECLINED);
                        });
                    } else {
                        invokeCallControlApi('answerCall', data, onResponseHandler);
                    }
                } catch (e) {
                    LogSvc.error('[ContactCardSvc]: Exception handling CLICK_TO_ANSWER_REQUEST event. ', e);
                }
            });

            _userToUserHandler.on('CONTACT_CARD.NAVIGATE_REQUEST', function (data, routing) {
                try {
                    LogSvc.debug('[ContactCardSvc]: Received NAVIGATE_REQUEST with data: ', data);

                    var onResponseHandler = sendResponse.bind(null, ContactCardMessage.NAVIGATE_REQUEST_RESPONSE, routing.srcUserId, routing.srcClientId, data.requestId);

                    if (!data.path) {
                        LogSvc.error('[ContactCardSvc]: Missing path in NAVIGATE_REQUEST request');
                        onResponseHandler(ContactCardResponseCode.NAVIGATE_REQUEST_FAILED);
                        return;
                    }
                    $rootScope.$apply(function () {
                        handleGenericNavigate(data, onResponseHandler);
                    });
                } catch (e) {
                    LogSvc.error('[ContactCardSvc]: Exception handling NAVIGATE_REQUEST request. ', e);
                }
            });
        }

        _userToUserHandler.on('CONTACT_CARD.MUTE_DEVICE_REQUEST', function (data, routing) {
            try {
                LogSvc.debug('[ContactCardSvc]: Received MUTE_DEVICE_REQUEST with data: ', data);

                var onResponseHandler = sendResponse.bind(null, ContactCardMessage.MUTE_DEVICE_RESPONSE, routing.srcUserId, routing.srcClientId, data.requestId);

                if (!data.option) {
                    LogSvc.error('[ContactCardSvc]: Missing option object in MUTE_DEVICE_REQUEST request');
                    onResponseHandler(ContactCardResponseCode.MUTE_DEVICE_FAILED);
                    return;
                }
                if (!data.callId) {
                    LogSvc.error('[ContactCardSvc]: Missing callId in MUTE_DEVICE_REQUEST request');
                    onResponseHandler(ContactCardResponseCode.MUTE_DEVICE_FAILED);
                    return;
                }

                var callId = data.callId;

                var localCall = CallControlSvc.getActiveCall(callId);
                if (!localCall) {
                    LogSvc.debug('[ContactCardSvc]: Could not find corresponding local call');
                    onResponseHandler(ContactCardResponseCode.CALL_NOT_FOUND);
                    return;
                }

                var promises = [];

                // Speaker (not a promise)
                var speakerFn = data.option.speaker ? CallControlSvc.disableRemoteAudio : CallControlSvc.enableRemoteAudio;
                speakerFn(callId);

                // Mic (a promise)
                if (localCall.locallyMuted !== data.option.mic) {
                    var micFn = UtilSvc.promisify(data.option.mic ? CallControlSvc.mute : CallControlSvc.unmute, CallControlSvc);
                    promises.push(micFn(callId));
                }

                $q.all(promises)
                .then(function () {
                    LogSvc.info('[ContactCardSvc]: Successfully muted client and disabled remote audio');
                    onResponseHandler();
                })
                .catch(function (err) {
                    LogSvc.error('[ContactCardSvc]: MUTE_DEVICE_REQUEST execution failed: ', err);
                    onResponseHandler(ContactCardResponseCode.MUTE_DEVICE_FAILED);
                });
            } catch (e) {
                LogSvc.error('[ContactCardSvc]: Exception handling MUTE_DEVICE_REQUEST request. ', e);
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.ContactCardSvcImpl = ContactCardSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
