/*global log4javascript*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var storeManager = circuit.storeManager;
    var logObfuscator = circuit.LogObfuscator;

    var LogLevel = Object.freeze({
        MAXIMUM: {level: log4javascript.Level.DEBUG.level, name: 'Maximum'},
        MEDIUM: {level: log4javascript.Level.INFO.level, name: 'Medium'},
        MINIMUM: {level: log4javascript.Level.WARN.level, name: 'Minimum'},
        ERROR: {level: log4javascript.Level.ERROR.level, name: 'Error'}
    });

    function BaseLogger() {
        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var LOG_STORAGE_KEY = 'loggingLevel';

        var _url = '';
        var _clientLogger = new log4javascript.getLogger();
        var _logLevel = LogLevel.MAXIMUM;

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        // Get log level from localStorage
        function retrieveLogLevel() {
            try {
                var levelTag = storeManager.getItem(LOG_STORAGE_KEY);
                if (levelTag) {
                    if (LogLevel[levelTag]) {
                        _logLevel = LogLevel[levelTag];
                        return;
                    }
                    // Something is not right with the current entry
                    storeManager.removeItem(LOG_STORAGE_KEY);
                }
            } catch (e) {}

            // Fallback to default
            _logLevel = LogLevel.MAXIMUM;
        }

        function init() {
            // Set the level for the log4javascript object to DEBUG
            // The control between MAXIMUM, MEDIUM, MINIMUM and ERROR is done locally.
            _clientLogger.setLevel(log4javascript.Level.DEBUG);
            retrieveLogLevel();
        }

        function checkLevel(level) {
            if (_logLevel === LogLevel.ERROR) {
                return false;
            }

            if (_url !== location.href) {
                // Current URL has changed. Log an entry for that independent of log level
                _url = location.href;
                _clientLogger.info('Current URL has changed to ', _url);
            }

            return level.level >= _logLevel.level;
        }

        ///////////////////////////////////////////////////////////////////////////
        // Public interface
        ///////////////////////////////////////////////////////////////////////////
        this.getLevel = function () {
            return _logLevel;
        };

        this.setLevel = function (newLevel) {
            if (!newLevel || !newLevel.level || newLevel.level === _logLevel.level) {
                return;
            }
            Object.keys(LogLevel).some(function (key) {
                if (newLevel.level === LogLevel[key].level) {
                    _logLevel = LogLevel[key];
                    storeManager.setItem(LOG_STORAGE_KEY, key);
                    _clientLogger.info('[logger]: Logging level has been updated to: ', key);
                    return true;
                }
                return false;
            });
        };

        this.debug = function (txt, obj) {
            if (checkLevel(LogLevel.MAXIMUM)) {
                _clientLogger.debug(txt, logObfuscator.objToString(obj));
            }
        };

        this.info = function (txt, obj) {
            if (checkLevel(LogLevel.MEDIUM)) {
                _clientLogger.info(txt, logObfuscator.objToString(obj));
            }
        };

        this.msgSend = function (txt, msg) {
            if (checkLevel(LogLevel.MINIMUM)) {
                _clientLogger.info('SEND: ' + txt, logObfuscator.objToString(msg));
            }
        };

        this.msgRcvd = function (txt, msg) {
            if (checkLevel(LogLevel.MINIMUM)) {
                _clientLogger.info('RECV: ' + txt, logObfuscator.objToString(msg));
            }
        };

        this.warning = function (txt, obj) {
            if (checkLevel(LogLevel.MINIMUM)) {
                _clientLogger.warn(txt, logObfuscator.objToString(obj));
            }
        };

        this.warn = this.warning;

        this.error = function (error, obj) {
            // Always log errors
            error = (error && error.stack) || error;
            obj = (obj && obj.stack) || obj;

            var data = logObfuscator.objToString(obj);
            _clientLogger.error(error, data);
        };

        this.logMsg = function (level, messages) {
            if (messages && checkLevel(level)) {
                // Generic log message function, useful when you have the log msg level
                // as a parameter.
                if (!Array.isArray(messages)) {
                    messages = [messages];
                }
                var levelObj = log4javascript.Level.DEBUG;
                if (level && level.level && level.name) {
                    levelObj = new log4javascript.Level(level.level, level.name);
                }
                _clientLogger.log(levelObj, messages.map(function (obj) {
                    return logObfuscator.objToString(obj);
                }));
            }
        };

        this.removeAllAppenders = function () {
            _clientLogger.removeAllAppenders();
        };

        this.addAppender = function (appender) {
            _clientLogger.addAppender(appender);
        };

        this.removeAppender = function (appender) {
            _clientLogger.removeAppender(appender);
        };

        ///////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////
        init();
    }

    // Exports
    circuit.BaseLogger = BaseLogger;
    circuit.LogLevel = circuit.LogLevel || LogLevel;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
