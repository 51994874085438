/*global chrome, require*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ChromeExtension = circuit.ChromeExtension;
    var ExtensionConnHandlerSingleton = circuit.ExtensionConnHandlerSingleton;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function ExtensionSvcImpl($rootScope, $timeout, $window, $q, LogSvc, PubSubSvc, PopupSvc, RegistrationSvc) { // NOSONAR
        LogSvc.debug('New Service: ExtensionSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var SANDBOX_HOSTNAME = 'circuitsandbox.net';
        var BETA_HOSTNAME = 'beta.circuit.com';
        var T_AND_I_HOSTNAME = 'tandi.circuitsandbox.net';
        var UNIVERGEBLUE_HOSTNAME = 'teams.univergeblue.com';
        var BLUEBETA_HOSTNAME = 'bluebeta.circuit.com';

        var CHROME_URL = 'https://chrome.google.com/webstore/detail/';
        var EDGE_URL = 'https://microsoftedge.microsoft.com/addons/detail/';
        var DEFAULT_EDGE_URL = 'https://microsoftedge.microsoft.com/';

        // This defines the minimum extension version supported by the webclient.
        // Update this as old extension versions no longer work with the webclient.
        var MIN_EXTENSION_VERSION_STR = '0.0.242';
        var MIN_EXTENSION_VERSION = Utils.convertVersionToNumber(MIN_EXTENSION_VERSION_STR);

        var ExtensionInstallErrorCodes = Object.freeze({
            UNSUPPORTED_BROWSER: 'UNSUPPORTED_BROWSER',
            UNSUPPORTED_EXT_VERSION: 'UNSUPPORTED_EXT_VERSION',
            USER_CANCELLED_INSTALL: 'USER_CANCELLED_INSTALL',
            INTERNAL_ERROR: 'INTERNAL_ERROR'
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _self = this;
        var _extConnHandler = typeof ExtensionConnHandlerSingleton === 'object' ? ExtensionConnHandlerSingleton.getInstance() : null;

        var _extensionVersion = '';
        var _extensionVersionSupported = true;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getExtensionIdFromHostName() {
            var hostname = $window.location.hostname;
            var id;
            switch (hostname) {
            case BETA_HOSTNAME:
                id = 'jafbhekoolndfieffjkikfbmekjppnec';
                LogSvc.info('[ExtensionSvc]: Set extension ID to Beta-Circuit extension: ', id);
                break;
            case SANDBOX_HOSTNAME:
                id = 'ogdfpjnhdggplglemglldmioiblhkadm';
                LogSvc.info('[ExtensionSvc]: Set extension ID to Sandbox-Circuit extension: ', id);
                break;
            case T_AND_I_HOSTNAME:
                id = 'gdgfgnopapmocggkadgfkjekdlcohmdl';
                LogSvc.info('[ExtensionSvc]: Set extension ID to T&I-Circuit extension: ', id);
                break;
            case UNIVERGEBLUE_HOSTNAME:
            case BLUEBETA_HOSTNAME:
                id = 'jhpcobccapenojpdohnfmhmdmppngdad';
                LogSvc.info('[ExtensionSvc]: Set extension ID to UNIVERGE BLUE TC extension: ', id);
                break;
            default:
                id = 'mhkbaognlahkdimlfcfhbeihldmjofgg';
                LogSvc.info('[ExtensionSvc]: Set extension ID to Circuit extension: ', id);
                break;
            }
            return id;
        }

        function getExtensionInstallUrl() {
            var url = $rootScope.browser.subType === 'edge' ? EDGE_URL : CHROME_URL;
            var extensionId;
            if ($rootScope.browser.subType === 'edge') {
                LogSvc.info('[ExtensionSvc]: Set URL to Edge extension webstore link');
                extensionId = RegistrationSvc.getEdgeExtensionId();
                if (extensionId) {
                    LogSvc.info('[ExtensionSvc]: Set Edge extension URL using Extension ID set in global properties: ', extensionId);
                    url += extensionId;
                } else {
                    LogSvc.info('[ExtensionSvc]: Set Default Edge extension URL');
                    url = DEFAULT_EDGE_URL;
                }
                return url;
            }

            extensionId = RegistrationSvc.getChromeExtensionId();
            if (extensionId) {
                LogSvc.info('[ExtensionSvc]: Set Chrome extension URL using Extension ID set in global properties: ', extensionId);
            } else {
                extensionId = getExtensionIdFromHostName();
                LogSvc.info('[ExtensionSvc]: Set Chrome extension URL using Extension ID from host name: ', extensionId);
            }
            url += extensionId;
            return url;
        }

        function init() {
            $rootScope.isBrowserExtRunning = _self.isExtensionRunning();
        }

        function invokeHandlerApi(apiName, params, cb) {
            LogSvc.debug('[ExtensionSvc]: Invoke ExtensionConnHandler API: ', apiName);
            if (!_extConnHandler) {
                LogSvc.debug('[ExtensionSvc]: ExtensionConnHandler is not loaded');
                cb && $timeout(function () { cb('No ExtensionConnHandler'); }, 0);
                return undefined;
            }
            if (!_extConnHandler.isExtensionRunning()) {
                LogSvc.debug('[ExtensionSvc]: Chrome extension is not running');
                cb && $timeout(function () { cb('Extension not running'); }, 0);
                return undefined;
            }
            if (cb) {
                params = params || [];
                // Push a callback wrapper that applies the digest cycle
                params.push(function (err, data) {
                    $rootScope.$apply(function () {
                        cb(err, data);
                    });
                });
            }

            return _extConnHandler[apiName].apply(_extConnHandler, params);
        }

        function validateExtensionVersion() {
            LogSvc.info('[ExtensionSvc]: Validate the installed extension version: ', _extensionVersion);
            _extensionVersionSupported = Utils.convertVersionToNumber(_extensionVersion) >= MIN_EXTENSION_VERSION;
            if (!_extensionVersionSupported) {
                LogSvc.warn('[ExtensionSvc]: The installed extension version (' + _extensionVersion +
                    ') is not supported. Minimum version is ' + MIN_EXTENSION_VERSION_STR);
            }
        }

        function extensionInstallPreChecks(showErrorPopup) {
            if (!$rootScope.browser || !$rootScope.browser.chrome) {
                return ExtensionInstallErrorCodes.UNSUPPORTED_BROWSER;
            }

            // Check if the extension needs to be updated
            if (!_extensionVersionSupported) {
                LogSvc.warn('[ExtensionSvc]: We are running an outdated extension:', _extensionVersion);
                if (showErrorPopup && PopupSvc) {
                    PopupSvc.error({message: 'res_UnsupportedExtensionVersion'});
                }
                return ExtensionInstallErrorCodes.UNSUPPORTED_EXT_VERSION;
            }
            return null;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        if (_extConnHandler) {
            _extConnHandler.addEventListener('extensionInitialized', function (evt) {
                $rootScope.$apply(function () {
                    _extensionVersion = evt.data.version;
                    validateExtensionVersion();
                    if (_extensionVersionSupported) {
                        $rootScope.isBrowserExtRunning = true;
                        LogSvc.setExtensionVersion(_extensionVersion);
                        LogSvc.debug('[ExtensionSvc]: Publish /chromeExt/initialized event');
                        PubSubSvc.publish('/chromeExt/initialized');
                    }
                });
            });

            _extConnHandler.addEventListener('extensionUnregistered', function () {
                $rootScope.$apply(function () {
                    $rootScope.isBrowserExtRunning = false;
                    LogSvc.debug('[ExtensionSvc]: Publish /chromeExt/unregistered event');
                    PubSubSvc.publish('/chromeExt/unregistered');
                });
            });

            var onConnHandlerEvent = function (eventName, evt) {
                $rootScope.$apply(function () {
                    var data = evt.data && evt.data.data;
                    LogSvc.debug('[ExtensionSvc]: Publish ' + eventName + ' event: ', data);
                    PubSubSvc.publish(eventName, [data]);
                });
            };

            _extConnHandler.addEventListener('headsetAppLaunched', onConnHandlerEvent.bind(null, '/chromeExt/headsetAppLaunched'));
            _extConnHandler.addEventListener('headsetAppUninstalled', onConnHandlerEvent.bind(null, '/chromeExt/headsetAppUninstalled'));
            _extConnHandler.addEventListener('webRTCIPHandlingPolicyChanged', onConnHandlerEvent.bind(null, '/chromeExt/webRTCIPHandlingPolicyChanged'));
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Checks if Chrome Extension is installed and running.
         *
         * @returns {bool} true is extension is installed and running.
         */
        this.isExtensionRunning = function () {
            return _extConnHandler && _extConnHandler.isExtensionRunning() && _extensionVersionSupported;
        };

        /**
         * Returns extension version
         *
         * @returns {String} Extension version
         */
        this.getExtensionVersion = function () {
            return _extensionVersion;
        };

        /**
         * Returns whether the version of the installed extension is supported or not
         *
         * @returns {Boolean} true if supported, false otherwise
         */
        this.isExtensionVersionSupported = function () {
            return _extensionVersionSupported;
        };

        /***
         * This method opens up a modal asking for a confirmation from the user before actually installing the extension
         *
         * @param {Boolean} showErrorPopup Indicates whether this API should show a popup in case of error.
         * @returns {Promise} A promise that is resolved once the extension is installed.
         */
        this.installExtension = function (showErrorPopup) {
            return new $q(function (resolve, reject) {
                if (_self.isExtensionRunning()) {
                    // Extension is already installed
                    resolve();
                    return;
                }

                if (!PopupSvc) {
                    LogSvc.error('[ExtensionSvc]: installExtension API requires PopupSvc');
                    reject(ExtensionInstallErrorCodes.INTERNAL_ERROR);
                    return;
                }

                var error = extensionInstallPreChecks(showErrorPopup);
                if (error) {
                    reject(error);
                    return;
                }

                var options = {
                    message: 'res_ActionInstallExtensionText',
                    title: 'res_ActionInstallExtensionTitle',
                    yesLabel: 'res_ActionInstall',
                    noLabel: 'res_Close',
                    backdrop: true
                };

                PopupSvc.confirm(options)
                .result
                .then(function () {
                    _self.inlineInstallation(showErrorPopup)
                    .then(resolve)
                    .catch(reject);
                })
                .catch(function () {
                    reject(ExtensionInstallErrorCodes.USER_CANCELLED_INSTALL);
                });
            });
        };

        /***
         * This method performs the actual inline installation, skipping the modal confirmation.
         *
         * @param {Boolean} showErrorPopup Indicates whether this API should show a popup in case of error.
         * @returns {Promise} A promise that is resolved once the extension is installed.
         */
        this.inlineInstallation = function (showErrorPopup) {
            return new $q(function (resolve, reject) {
                var error = extensionInstallPreChecks(showErrorPopup);
                if (error) {
                    reject(error);
                    return;
                }

                var extensionUrl = getExtensionInstallUrl();
                LogSvc.debug('[ExtensionSvc]: Open store link for ', extensionUrl);
                $window.open(extensionUrl);
                resolve();
            });
        };

        this.addEventListener = function (target, cb) {
            _extConnHandler && _extConnHandler.addEventListener(target, cb);
        };

        /******************************************************
         * Exchange Connector APIs
         ******************************************************/
        this.exchangeGetConnectionStatus = function (key, cb) {
            return invokeHandlerApi('exchangeGetConnectionStatus', [key], cb);
        };

        this.exchangeConnect = function (settings, cb) {
            return invokeHandlerApi('exchangeConnect', [settings], cb);
        };

        this.exchangeSearchContacts = function (key, searchStr, resCount, cb) {
            return invokeHandlerApi('exchangeSearchContacts', [key, searchStr, resCount], cb);
        };

        this.exchangeGetCapabilities = function (cb) {
            return invokeHandlerApi('exchangeGetCapabilities', [], cb);
        };

        this.exchangeResolveContact = function (key, contactData, cb) {
            return invokeHandlerApi('exchangeResolveContact', [key, contactData], cb);
        };

        this.exchangeGetContact = function (key, exchangeEmail, cb) {
            return invokeHandlerApi('exchangeGetContact', [key, exchangeEmail], cb);
        };

        this.exchangeDisconnect = function (cb) {
            return invokeHandlerApi('exchangeDisconnect', [], cb);
        };

        this.exchangeOnRenewedToken = function (key, reqId, token, cb) {
            return invokeHandlerApi('exchangeOnRenewedToken', [key, reqId, token], cb);
        };

        this.storeExchCredentials = function (credentials, cb) {
            return invokeHandlerApi('exchangeStoreCredentials', [credentials], cb);
        };

        this.getStoredCredentials = function (key, cb) {
            return invokeHandlerApi('getStoredCredentials', [key], cb);
        };

        this.getAllPersonalContacts = function (key, cb) {
            return invokeHandlerApi('getAllPersonalContacts', [key], cb);
        };

        this.syncAllPersonalContacts = function (key, syncState, cb) {
            return invokeHandlerApi('syncAllPersonalContacts', [key, syncState], cb);
        };

        this.exchangeCancelReqCallback = function (reqId) {
            return invokeHandlerApi('exchangeCancelReqCallback', [reqId]);
        };

        this.getAppointments = function (key, startDate, endDate, resCount, cb) {
            return invokeHandlerApi('getAppointments', [key, startDate, endDate, resCount], cb);
        };

        this.supportsGetAppointments = function () {
            if (!$rootScope.localUser || !$rootScope.localUser.msExchangeEnabled) {
                // If Exchange Integration is not enabled it doesn't support GetAppointments
                return false;
            }
            if (!_self.isExtensionRunning()) {
                // Assume the feature will be supported once the user installs the Chrome extension
                return !!($rootScope.browser && $rootScope.browser.chrome);
            }
            return true;
        };

        this.supportsExchangeAutodiscover = function () {
            return _self.isExtensionRunning();
        };

        this.supportsExchangeAutodiscoverSvc = function () {
            return _self.isExtensionRunning();
        };

        this.supportsExchangeAutodiscoverGetServer = function () {
            // Requires SP96 extension (1.2.3300) or higher
            return Utils.convertVersionToNumber(_extensionVersion) >= 1023300;
        };

        this.supportsOOO = function () {
            // OOO requires SP91 extension (1.2.2800) or higher
            return Utils.convertVersionToNumber(_extensionVersion) >= 1022800;
        };

        this.supportsUserAvailability = function () {
            // UserAvailability requires SP119 extension (1.2.5800) or higher
            return Utils.convertVersionToNumber(_extensionVersion) >= 1025800;
        };

        this.getOooMsg = function (key, fromEmail, email, cb) {
            if (!_self.supportsOOO()) {
                cb && cb(null, {response: ChromeExtension.ExchangeConnResponse.UNSUPPORTED_METHOD});
            }
            invokeHandlerApi('getOooMsg', [key, fromEmail, email], cb);
        };

        this.getUserAvailability = function (key, email, cb) {
            if (!_self.supportsUserAvailability()) {
                cb && cb(null, {response: ChromeExtension.ExchangeConnResponse.UNSUPPORTED_METHOD});
            }
            invokeHandlerApi('getUserAvailability', [key, email], cb);
        };

        /******************************************************
         * Headset App Manager APIs
         ******************************************************/
        this.launchHeadsetIntegrationApp = function (appId, localizedStrings, cb) {
            return invokeHandlerApi('launchHeadsetIntegrationApp', [appId, localizedStrings], cb);
        };

        this.getHeadsetIntegrationAppStatus = function (appId, cb) {
            return invokeHandlerApi('getHeadsetIntegrationAppStatus', [appId], cb);
        };

        this.restartHeadsetApp = function (appId, cb) {
            return invokeHandlerApi('restartHeadsetApp', [appId], cb);
        };

        /******************************************************
         * Privacy Settings APIs
         ******************************************************/
        this.supportsPolicySettings = function () {
            // Requires SP96 extension (1.2.3300) or higher
            return Utils.convertVersionToNumber(_extensionVersion) >= 1023300;
        };

        this.setIPHandlingPolicy = function (policy, localizedStrings, cb) {
            invokeHandlerApi('setIPHandlingPolicy', [policy, localizedStrings], cb);
        };

        this.getIPHandlingPolicy = function (cb) {
            if (typeof cb === 'function') {
                invokeHandlerApi('getIPHandlingPolicy', [], cb);
            }
        };

        /******************************************************
         * Internal APIs
         ******************************************************/
        this.bringToFront = function (cb) {
            invokeHandlerApi('bringToFront', [], cb);
        };

        this.getFileFromExtension = function (file, lang, cb) {
            return invokeHandlerApi('getFile', [file, lang], cb);
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initializations
        ///////////////////////////////////////////////////////////////////////////////////////
        init();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.ExtensionSvcImpl = ExtensionSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
