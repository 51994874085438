/*global DeviceStatistics*/

var Circuit = (function (circuit) {
    'use strict';
    // Imports

    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var Proto = circuit.Proto;
    var Utils = circuit.Utils;
    var WebRTCAdapter = circuit.WebRTCAdapter;
    var RtcSessionController = circuit.RtcSessionController;

    // eslint-disable-next-line max-params, max-lines-per-function
    function DeviceDiagnosticSvcImpl($rootScope, $timeout, LogSvc, PubSubSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: DeviceDiagnosticSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var JOIN_DELAY_TIMEOUT = 3000;

        var MAX_CACHED_RTC_CLIENT_SIZE = 60000; // Maximum size of cached diagnostics data in local storage
        var TRIMMED_RTC_CLIENT_SIZE = 50000; // Max size after data is trimmed

        var _clientApiHandler = ClientApiHandler.getInstance();
        var _cachedClientInfo = [];
        var _supportsOfflineFailures = !!LocalStoreSvc;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function trimAndStoreData() {
            if (!_supportsOfflineFailures) {
                return;
            }
            if (_cachedClientInfo.length === 0) {
                LocalStoreSvc.removeItem(LocalStoreSvc.keys.RTC_CLIENT_INFOS);
                return;
            }
            var serializedData = JSON.stringify(_cachedClientInfo);
            if (serializedData.length > MAX_CACHED_RTC_CLIENT_SIZE) {
                LogSvc.warn('[DeviceDiagnosticSvc]: Stored device diagnostics exceeded max size. Discard old data.');
                var discardedData = '';
                var discardSize = serializedData.length - TRIMMED_RTC_CLIENT_SIZE;
                _cachedClientInfo.some(function (oldInfo, idx) {
                    discardedData += JSON.stringify(oldInfo);
                    if (discardedData.length > discardSize) {
                        LogSvc.warn('[DeviceDiagnosticSvc]: Number of discarded records: ', idx + 1);
                        _cachedClientInfo.splice(0, idx + 1);
                        serializedData = JSON.stringify(_cachedClientInfo);
                        return true;
                    }
                    return false;
                });
            }
            LocalStoreSvc.setString(LocalStoreSvc.keys.RTC_CLIENT_INFOS, serializedData);
        }

        function init() {
            if (_supportsOfflineFailures) {
                _cachedClientInfo = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.RTC_CLIENT_INFOS) || [];
                if (!Array.isArray(_cachedClientInfo)) {
                    _cachedClientInfo = [];
                }
                trimAndStoreData();
            }
        }

        function cancelJoinDelayTimer(diagnostics) {
            if (diagnostics && diagnostics.joinDelayTimer) {
                $timeout.cancel(diagnostics.joinDelayTimer);
                diagnostics.joinDelayTimer = null;
            }
        }

        function startDiagnostics(call) {
            if (call && !call.isRemote) {
                LogSvc.info('[DeviceDiagnosticSvc]: Start diagnostics collection for callId = ', call.callId);
                call.deviceDiagnostics = call.deviceDiagnostics || {
                    data: {
                        actionInfo: []
                    }
                };
            }
        }

        function startJoinDelayTimer(call) {
            if (!call || !call.deviceDiagnostics) {
                return;
            }
            LogSvc.debug('[DeviceDiagnosticSvc]: Start join delay timer for callId = ', call.callId);

            var diagnostics = call.deviceDiagnostics;

            cancelJoinDelayTimer(diagnostics);
            diagnostics.joinDelayTimer = $timeout(function () {
                diagnostics.joinDelayTimer = null;
                onJoinDelay(call);
            }, JOIN_DELAY_TIMEOUT);

            diagnostics.joinDelayed = false;
            diagnostics.waitingToSend = false;
            diagnostics.iceGatheringFinished = false;

            call.deviceDiagnostics = diagnostics;
        }

        function createActionInfo(call, actionType) {
            LogSvc.debug('[DeviceDiagnosticSvc]: Create action info ', actionType);

            if (!call || !call.deviceDiagnostics || !actionType) {
                return null;
            }

            var info = {
                actionType: actionType
            };

            switch (actionType) {
            case Constants.RtcDiagnosticsAction.SDP_ANSWER:
            case Constants.RtcDiagnosticsAction.SDP_CONNECTED:
            case Constants.RtcDiagnosticsAction.REMOTE_ICE_CANDIDATES:
                info.type = Constants.RtcActionInfoType.EVENT;
                info.timestampEvent = Date.now();
                break;

            default:
                info.type = Constants.RtcActionInfoType.REQUEST_RESPONSE;
                info.timestampRequest = Date.now();
                break;
            }

            call.deviceDiagnostics.data.actionInfo.push(info);
            return info;
        }

        function hasPendingActionInfo(call) {
            // Check if all action infos are complete.
            if (!call || !call.deviceDiagnostics) {
                return false;
            }
            var pending = call.deviceDiagnostics.data.actionInfo.some(function (actionInfo) {
                return !actionInfo.complete;
            });
            return pending || !call.deviceDiagnostics.iceGatheringFinished;
        }

        function finishActionInfo(call, info) {
            if (!info) {
                return;
            }

            LogSvc.debug('[DeviceDiagnosticSvc]: Finish action info ', info.actionType);

            info.complete = true;
            if (info.type === Constants.RtcActionInfoType.REQUEST_RESPONSE) {
                info.timestampResponse = Date.now();
            }

            if (call && call.deviceDiagnostics) {
                if (info.isEndOfCandidates) {
                    call.deviceDiagnostics.iceGatheringFinished = true;
                    LogSvc.debug('[DeviceDiagnosticSvc]: Ice gathering finished');
                }
                // Device Diagnostics won't be sent until collection is complete, or at least on terminate
                if (call.deviceDiagnostics.waitingToSend && !hasPendingActionInfo(call)) {
                    forceFinishDeviceDiagnostics(call);
                }
            }
        }

        function forceFinishDeviceDiagnostics(call) {
            if (!call || !call.deviceDiagnostics) {
                return;
            }

            LogSvc.debug('[DeviceDiagnosticSvc]: Force finish device diagnostics');
            var diagnostics = call.deviceDiagnostics;
            cancelJoinDelayTimer(diagnostics);

            if (diagnostics.joinDelayed) {
                sendDeviceDiagnostics(call);
            }
            clearDeviceDiagnostics(call);
        }

        function onJoinDelay(call) {
            if (!call || !call.deviceDiagnostics) {
                return;
            }

            var diagnostics = call.deviceDiagnostics;

            LogSvc.debug('[DeviceDiagnosticSvc]: Join action delayed, preparing device diagnostics');
            cancelJoinDelayTimer(diagnostics);
            diagnostics.joinDelayed = true;

            if (typeof DeviceStatistics !== 'undefined') {
                var deviceStats = DeviceStatistics.getStatistics();

                LogSvc.info('[DeviceDiagnosticSvc]: Received native device diagnostics: ', deviceStats);
                diagnostics.data.usedRAMApp = deviceStats.usedRAMApp;
                diagnostics.data.availableRAMApp = deviceStats.availableRAMApp;
                diagnostics.data.usedRAMDevice = deviceStats.usedRAMDevice;
                diagnostics.data.availableRAMDevice = deviceStats.availableRAMDevice;
                diagnostics.data.virtualMemory = deviceStats.virtualMemory;
                diagnostics.data.usagePerCore = deviceStats.usagePerCore;
                diagnostics.data.numberOfCores = deviceStats.numberOfCores;
                diagnostics.data.networkType = deviceStats.networkType;
            }
        }

        function finishDeviceDiagnostics(call) {
            if (!call || !call.deviceDiagnostics) {
                return;
            }
            LogSvc.debug('[DeviceDiagnosticSvc]: Finish device diagnostics');

            var diagnostics = call.deviceDiagnostics;
            cancelJoinDelayTimer(diagnostics);

            if (diagnostics.joinDelayed) {
                if (!hasPendingActionInfo(call)) {
                    sendDeviceDiagnostics(call);
                } else {
                    diagnostics.waitingToSend = true;
                    LogSvc.debug('[DeviceDiagnosticSvc]: Send device diagnostic delayed');
                }
            } else {
                clearDeviceDiagnostics(call);
            }
        }

        function clearDeviceDiagnostics(call) {
            if (!call || !call.deviceDiagnostics) {
                return;
            }

            LogSvc.debug('[DeviceDiagnosticSvc]: Clear device diagnostics');
            cancelJoinDelayTimer(call.deviceDiagnostics);
            delete call.deviceDiagnostics;
        }

        function storeClientInfo(info) {
            if (_supportsOfflineFailures) {
                LogSvc.debug('[DeviceDiagnosticSvc]: Store device diagnostics to send when client reconnects');
                _cachedClientInfo.push(info);
                trimAndStoreData();
            }
        }

        function sendDeviceDiagnostics(call) {
            if (!call || !call.deviceDiagnostics) {
                LogSvc.debug('[DeviceDiagnosticSvc]: sendDeviceDiagnostics - Nothing to send');
                return;
            }
            var clientInfo = {
                sdpOrigin: call.sdpOrigin || null,
                userId: $rootScope.localUser.userId,
                convId: call.convId,
                clientInfoType: Constants.RtcClientInfoType.DEVICE_DIAGNOSTICS,
                reason: Constants.RtcClientInfoReason.JOIN_DELAY,
                timestamp: Date.now(),
                sessionId: call.callId,
                deviceDiagnostics: Utils.shallowCopy(call.deviceDiagnostics.data)
            };

            if (call.instanceId) {
                clientInfo.instanceId = call.instanceId;
            }

            clearDeviceDiagnostics(call);

            LogSvc.debug('[DeviceDiagnosticSvc]: Sending device diagnostics...');
            _clientApiHandler.sendClientInfo([clientInfo], function (err) {
                if (err) {
                    LogSvc.debug('[DeviceDiagnosticSvc]: Failed to send device diagnostics. ', err);
                    if (Proto.isOfflineFailure(err)) {
                        storeClientInfo(clientInfo);
                    }
                }
            });
        }

        function sendOfflineJoinFailures() {
            if (_cachedClientInfo.length > 0) {
                var clientInfos = _cachedClientInfo;
                _cachedClientInfo = [];
                LocalStoreSvc.removeItem(LocalStoreSvc.keys.RTC_CLIENT_INFOS);

                LogSvc.info('[DeviceDiagnosticSvc]: Send offline join failures to backend');
                _clientApiHandler.sendClientInfo(clientInfos, function (err) {
                    if (err) {
                        LogSvc.error('[DeviceDiagnosticSvc]: Failed to send offline join failures. ', err);
                        if (Proto.isOfflineFailure(err)) {
                            // The client is offline. Save the data again.
                            Array.prototype.unshift.apply(_cachedClientInfo, clientInfos);
                            trimAndStoreData();
                        }
                    } else {
                        LogSvc.debug('[DeviceDiagnosticSvc]: Sent offline join failures');
                    }
                });
            }
        }

        function getDeviceStatistics() {
            if (typeof DeviceStatistics !== 'undefined') {
                return DeviceStatistics.getStatistics();
            }
            return null;
        }

        function getDeviceName(device) {
            if (!device || !device.label || typeof device.label !== 'string') {
                return '';
            }
            var label = device.label;
            if (label.startsWith('res_')) {
                return $rootScope.i18n.localize(label) || '';
            }
            return label;
        }

        function sendCallConnected(call) {
            LogSvc.debug('[DeviceDiagnosticSvc]: Send call connected event to backend. call = ', call.callId);

            var stats = getDeviceStatistics() || {};

            WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                // Additional data for call connected
                var device = Utils.selectMediaDevice(audioSources, RtcSessionController.recordingDevices);
                stats.recordingDevice = getDeviceName(device);
                device = Utils.selectMediaDevice(audioOutputDevices, RtcSessionController.playbackDevices);
                stats.playbackDevice = getDeviceName(device);
                stats.negotiationInfos = [];

                var clientInfo = {
                    sdpOrigin: call.sdpOrigin || undefined,
                    userId: $rootScope.localUser.userId,
                    convId: call.convId,
                    clientInfoType: Constants.RtcClientInfoType.CALL_CONNECTED,
                    reason: Constants.RtcClientInfoReason.CALL_CONNECTED,
                    timestamp: Date.now(),
                    sessionId: call.callId,
                    deviceDiagnostics: stats,
                    instanceId: call.instanceId
                };

                _clientApiHandler.sendClientInfo([clientInfo], function (err) {
                    if (err) {
                        LogSvc.debug('[DeviceDiagnosticSvc]: Failed to send call connected event. ', err);
                    }
                });
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        if (_supportsOfflineFailures) {
            PubSubSvc.subscribe('/conversations/loadComplete', function () {
                LogSvc.debug('[DeviceDiagnosticSvc]: Received /conversations/loadComplete event');
                sendOfflineJoinFailures();
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.startDiagnostics = startDiagnostics;

        this.startJoinDelayTimer = startJoinDelayTimer;

        this.createActionInfo = createActionInfo;

        this.finishActionInfo = finishActionInfo;

        this.finishDeviceDiagnostics = finishDeviceDiagnostics;

        this.forceFinishDeviceDiagnostics = forceFinishDeviceDiagnostics;

        this.storeClientInfo = storeClientInfo;

        this.sendCallConnected = sendCallConnected;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        init();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.DeviceDiagnosticSvcImpl = DeviceDiagnosticSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
