/* global UIEventType */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var IdleState = circuit.Enums.IdleState;
    var Utils = circuit.Utils;

    ///////////////////////////////////////////////////////////////////////////////////////
    // PanelType enum
    ///////////////////////////////////////////////////////////////////////////////////////
    var PanelType = Object.freeze({
        PHONE: 'PHONE'
    });

    // eslint-disable-next-line max-params, max-lines-per-function
    function UISvcImpl($rootScope, $document, $timeout, $animate, $q, LogSvc, PubSubSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: UISvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var IDLE_TIMEOUT = 2400000;         // 40 minutes (BO7754)
        var IDLE_INTERVAL_CHECK = 30000;    // 30 seconds

        var _self = this;
        var _isIdle = false;
        var _mouseMoved = false;
        var _lastActivityDetected = Date.now();
        var _checkActivityInterval = null;

        // ClientApiHandler is not created for Popout Call Stage app
        var _clientApiHandler = ClientApiHandler ? ClientApiHandler.getInstance() : null;

        // Is input field overlaid by popup window.
        var _overlayPopup = false;
        var _uiSettings = null;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function executeAsync(cb) {
            // We need to execute this regardless if app has focus or not.
            // window.requestAnimationFrame is blocked until app regains focus
            // so we will use $timeout for app-not-in-focus cases
            if (window.document.hasFocus()) {
                window.requestAnimationFrame(cb);
            } else {
                $timeout(cb, 0, false);
            }
        }

        function wrapApply(originalCallback) {
            if (typeof originalCallback !== 'function') {
                return null;
            }
            return function wrappedCallback() {
                var args = arguments;
                var self = this;
                var retval;
                $timeout(function () {
                    retval = originalCallback.apply(self, args);
                }, 0);
                return retval;
            };
        }

        function scrollTo(obj) {
            if (!obj) {
                return;
            }
            executeAsync(function () {
                if (obj.itemId || obj.creationTime) {
                    $rootScope.$broadcast(UIEventType.SCROLL_TO_ITEM, obj);
                } else if (obj.element) {
                    $rootScope.$broadcast(UIEventType.SCROLL_TO_ELEMENT, obj);
                } else {
                    $rootScope.$broadcast(UIEventType.SCROLL_TO_OFFSET, obj);
                }
            });
        }

        function checkThreadVisibility(obj) {
            if (!obj) {
                return;
            }
            executeAsync(function () {
                if (obj.threadId) {
                    $rootScope.$broadcast(UIEventType.CHECK_THREAD_VISIBILITY, obj);
                }
            });
        }

        function isItemVisible(containerName, itemId, cb) {
            if (!containerName || !itemId || typeof cb !== 'function') {
                return;
            }

            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.CHECK_ITEM_VISIBILITY, {
                    containerName: containerName,
                    itemId: itemId,
                    callback: wrapApply(cb)
                });
            });
        }

        function setFloaterVisible(containerName, visible, cb) {
            if (!containerName) {
                return;
            }

            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.SHOW_FLOATER, {
                    containerName: containerName,
                    isVisible: !!visible,
                    callback: wrapApply(cb)
                });
            });
        }

        function scrollDirectionChanged(obj) {
            if (!obj && !obj.direction) {
                return;
            }
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.SCROLL_DIRECTION_CHANGED, obj);
            });
        }

        function resetInfiniteScroll(obj) {
            if (!obj || !obj.containerName) {
                return;
            }
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.RESET_INFINITE_SCROLL, obj);
            });
        }

        function stopInterval() {
            if (_checkActivityInterval) {
                window.clearInterval(_checkActivityInterval);
                _checkActivityInterval = null;
            }
        }

        function startInterval() {
            if (_checkActivityInterval) {
                return;
            }
            _checkActivityInterval = window.setInterval(function () {
                if (_mouseMoved) {
                    _mouseMoved = false;
                    _lastActivityDetected = Date.now();
                    return;
                }

                if (!_isIdle && (Date.now() - _lastActivityDetected > IDLE_TIMEOUT)) {
                    $rootScope.$apply(function () {
                        _isIdle = true;
                        stopInterval();
                        LogSvc.debug('[UISvc]: Publish /internal/svc/idle/state event: ', IdleState.Idle);
                        PubSubSvc.publish('/internal/svc/idle/state', [IdleState.Idle]);
                    });
                }
            }, IDLE_INTERVAL_CHECK);
        }

        // Detecting idle functionality. Publish /idle/state events on idle state change.
        function activityDetected() {
            _mouseMoved = true;
            if (_isIdle) {
                $rootScope.$apply(function () {
                    _isIdle = false;
                    startInterval();
                    LogSvc.debug('[UISvc]: Publish /internal/svc/idle/state event: ', IdleState.Active);
                    PubSubSvc.publish('/internal/svc/idle/state', [IdleState.Active]);
                });
            }
        }

        // Initialize idle detection.
        function startActivityChecking() {
            startInterval();
        }

        function adjustAddedCommentsScroll(obj) {
            if (!obj && !obj.containerName) {
                return;
            }
            executeAsync(function () {
                if (obj.itemIds && obj.itemIds.length > 0) {
                    $rootScope.$broadcast(UIEventType.ADJUST_ITEMS_SCROLL, obj);
                }
            });
        }

        function adjustAddedThreadsScroll(obj) {
            if (!obj && !obj.containerName) {
                return;
            }
            obj.threadsOnly = true;
            executeAsync(function () {
                if (obj.itemIds && obj.itemIds.length > 0) {
                    $rootScope.$broadcast(UIEventType.ADJUST_ITEMS_SCROLL, obj);
                }
            });
        }

        function adjustRemovedThreadScroll(obj) {
            if (!obj) {
                return;
            }
            executeAsync(function () {
                if (obj.threadId) {
                    $rootScope.$broadcast(UIEventType.ADJUST_REMOVED_THREAD_SCROLL, obj);
                }
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////

        // Used to keep information about layout overlay.
        function onPopupOpened() {
            _overlayPopup = true;
        }

        function onPopupClosed() {
            _overlayPopup = false;
        }

        PubSubSvc.subscribe('/popup/opened', onPopupOpened);
        PubSubSvc.subscribe('/popup/closed', onPopupClosed);

        ///////////////////////////////////////////////////////////////////////////////////////////////
        // Event listeners
        ///////////////////////////////////////////////////////////////////////////////////////////////
        $document.on('visibilitychange', function () {
            if (_clientApiHandler && $document[0].visibilityState === 'visible') {
                // Ping the access server to ensure that we are still connected
                LogSvc.debug('[UISvc]: Client became visible. Ping the server to check the connection state.');
                _clientApiHandler.pingServer();
            }

            // Workaround for Angular animate issue which causes classes to stall in the intermediate
            // animation states when window is minimized.
            $animate.enabled(!this.hidden);
        });

        $document.on('mousemove', activityDetected);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /*
            Focus an element containing the uSetFocus directive of the given name.
        **/
        this.setFocus = function (name) {
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.FOCUS, name);
            });
        };

        /*
         Focus an element containing the uSetFocusElement directive of the given id.
         **/
        this.setFocusToElement = function (id) {
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.FOCUS_ELEMENT, id);
            });
        };

        /*
            Scrolls to an offset in the conversation selector container given the container name and
            an offset as percentage or pixels.
            Options:
              offsetTop:        offset from the top
              animateDuration:  duration of animation in ms
            Example: {offsetTop: 0, animateDuration: 200}
        **/
        this.scrollToSelectorOffset = function (options, containerName, cb) {
            scrollTo({
                containerName: containerName || 'selectorDefault',
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        /*
            Scrolls to an item in the conversation selector container or any other container, given the
            itemId, container name and optional offset as percentage or pixels.
            Options:
              offsetTop:        offset from the top in pixel
              offsetPercentage: offset from the top as percentage
              animateDuration:  duration of animation in ms
            Example: {offsetTop: 10, animateDuration: 200}
        **/
        this.scrollToContainerItem = function (itemId, options, containerName, cb) {
            scrollTo({
                containerName: containerName || 'selectorDefault',
                itemId: itemId,
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.scrollToFeedOffset = function (options, cb) {
            scrollTo({
                containerName: 'feed',
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.scrollToFeedItem = function (itemId, creationTime, options, cb) {
            scrollTo({
                containerName: 'feed',
                itemId: itemId,
                creationTime: creationTime,
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.scrollToFeedElement = function (element, options, cb) {
            scrollTo({
                containerName: 'feed',
                element: element,
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.scrollToTopicListOffset = function (options, cb) {
            scrollTo({
                containerName: 'topics',
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.scrollToFocusedTopicOffset = function (options, cb) {
            scrollTo({
                containerName: 'focusedTopic',
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.scrollToFocusedTopicElement = function (element, options, cb) {
            scrollTo({
                containerName: 'focusedTopic',
                element: element,
                options: options,
                doneCallback: wrapApply(cb)
            });
        };

        this.resetInfiniteScroll = function (containerName, cb) {
            resetInfiniteScroll({
                containerName: containerName,
                doneCallback: wrapApply(cb)
            });
        };

        /*
         * Checks if a given feed item is visible. Callback returns boolean with visibility indication.
        **/
        this.isItemVisible = isItemVisible;

        this.isSelectorItemVisible = function (convId, cb) {
            isItemVisible('selectorDefault', convId, cb);
        };

        this.isFeedItemVisible = function (itemId, cb) {
            isItemVisible('feed', itemId, cb);
        };

        this.isThreadVisible = function (threadId, cb) {
            checkThreadVisibility({
                threadId: threadId,
                minVisiblePercentage: 0,
                callback: wrapApply(cb)
            });
        };

        this.setFloaterVisible = setFloaterVisible;

        this.showFeedFloater = function (cb) {
            setFloaterVisible('feed', true, cb);
        };

        this.hideFeedFloater = function (cb) {
            setFloaterVisible('feed', false, cb);
        };

        this.showSelectorFloater = function (containerName, cb) {
            setFloaterVisible(containerName || 'selectorDefault', true, cb);
        };

        this.hideSelectorFloater = function (containerName, cb) {
            setFloaterVisible(containerName || 'selectorDefault', false, cb);
        };

        this.showTopicListFloater = function (cb) {
            setFloaterVisible('topics', true, cb);
        };

        this.hideTopicListFloater = function (cb) {
            setFloaterVisible('topics', false, cb);
        };

        this.showFocusedTopicFloater = function (cb) {
            setFloaterVisible('focusedTopic', true, cb);
        };

        this.hideFocusedTopicFloater = function (cb) {
            setFloaterVisible('focusedTopic', false, cb);
        };

        this.feedScrollDirectionChanged = function (direction, cb) {
            scrollDirectionChanged({
                containerName: 'feed',
                direction: direction,
                callback: wrapApply(cb)
            });
        };

        this.selectorScrollDirectionChanged = function (direction, containerName, cb) {
            scrollDirectionChanged({
                containerName: containerName || 'selectorDefault',
                direction: direction,
                callback: wrapApply(cb)
            });
        };

        this.topicListScrollDirectionChanged = function (direction, cb) {
            scrollDirectionChanged({
                containerName: 'topics',
                direction: direction,
                callback: wrapApply(cb)
            });
        };

        this.focusedTopicScrollDirectionChanged = function (direction, cb) {
            scrollDirectionChanged({
                containerName: 'focusedTopic',
                direction: direction,
                callback: wrapApply(cb)
            });
        };

        this.clearSearch = function () {
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.CLEAR_SEARCH);
            });
        };

        this.showOverlay = function (data) {
            executeAsync(function () {
                $rootScope.$emit(UIEventType.SHOW_OVERLAY, data);
            });
        };

        this.hideOverlay = function (data) {
            executeAsync(function () {
                $rootScope.$emit(UIEventType.HIDE_OVERLAY, data);
            });
        };

        this.hasOverlayPopup = function () {
            return _overlayPopup;
        };

        // Locks feed scroll based on a added comments height
        this.adjustAddedCommentsScroll = function (itemIds, cb) {
            itemIds = itemIds instanceof Array ? itemIds : [];
            adjustAddedCommentsScroll({
                containerName: 'feed',
                itemIds: itemIds,
                callback: wrapApply(cb)
            });
        };

        // Locks feed scroll based on added threads height
        this.adjustAddedThreadsScroll = function (threadIds, cb) {
            threadIds = threadIds instanceof Array ? threadIds : [];
            adjustAddedThreadsScroll({
                containerName: 'feed',
                itemIds: threadIds,
                callback: wrapApply(cb)
            });
        };

        // Locks feed scroll based on an thread reordering
        this.adjustRemovedThreadScroll = function (threadId, cb) {
            adjustRemovedThreadScroll({
                threadId: threadId,
                callback: wrapApply(cb)
            });
        };

        // Checks if input fields have data - used for thread-input field communication
        this.areFieldsEmpty = function (ids, cb) {
            if (typeof cb !== 'function') {
                return;
            }
            if (!ids) {
                cb([]);
                return;
            }

            if (!Array.isArray(ids)) {
                ids = [ids];
            }

            var promises = ids.map(function (id) {
                var deferred = $q.defer();
                var callback = function (isEmpty) {
                    deferred.resolve({id: id, isEmpty: isEmpty});
                };
                executeAsync(function () {
                    $rootScope.$broadcast(UIEventType.IS_FIELD_EMPTY, {
                        id: id,
                        callback: callback
                    });
                });
                return deferred.promise;
            });

            $q.all(promises).then(function (fieldData) {
                cb(fieldData.filter(function (field) {
                    return !field.isEmpty;
                }).map(function (fields) {
                    return fields.id;
                }));
            });
        };

        // Checks item height within a container
        this.getThreadItemHeight = function (itemId, cb) {
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.GET_THREAD_ITEM_HEIGHT, {
                    itemId: itemId,
                    callback: cb
                });
            });
        };

        // Checks thread height within a container
        this.getThreadHeight = function (threadId, cb) {
            executeAsync(function () {
                $rootScope.$broadcast(UIEventType.GET_THREAD_HEIGHT, {
                    threadId: threadId,
                    callback: cb
                });
            });
        };

        // Focuses in first focusable element inside a container
        this.focusFirst = function (container) {
            if (!container || !container.offsetParent) {
                // Return if not visible (hidden through ng-show)
                return;
            }
            // Find elements with auto-focus attribute first
            var elms = container.querySelectorAll('[auto-focus="true"]');
            if (elms.length === 0) {
                // If none is found, fallback to first focusable element in general
                elms = angular.element(container.querySelectorAll(Utils.TABBABLE_ELEMENTS_PATTERN)).filter(':visible');
            }
            if (elms.length > 0) {
                elms[0].focus();
            }
        };

        /**
         * The following functions manage the state of the various UI elements of conversation sidebar
         * as they need to retain their state across conversations or Spaces.
         */
        this.getUISetting = function (setting) {
            if (!_uiSettings) {
                // First time accessing the UI settings. Need to get value from local storage.
                _uiSettings = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.UI_SETTINGS) || {};
                // Now normalize the settings
                Object.keys(Constants.UISettings).forEach(function (key) {
                    if (_uiSettings[key] === undefined) {
                        // Add missing keys with default value
                        _uiSettings[key] = Constants.DefaultUISettingsValues[key];
                    }
                });
                Object.keys(_uiSettings).forEach(function (key) {
                    if (!Constants.UISettings[key]) {
                        delete _uiSettings[key]; // Remove obsolete/invalid keys
                    }
                });
                // Save normalized value back to local storage
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.UI_SETTINGS, _uiSettings);
                LogSvc.debug('[ConversationSvc]: Retrieved UI settings from local store. ', _uiSettings);
            }
            return setting ? _uiSettings[setting] : Object.assign({}, _uiSettings);
        };

        this.setUISetting = function (setting, value) {
            if (!Constants.UISettings[setting]) {
                // Invalid setting
                return;
            }
            if (!_uiSettings) {
                _self.getUISetting();
            }
            LogSvc.debug('[ConversationSvc]: Update ' + setting + ' UI settings to ', !!value);
            _uiSettings[setting] = !!value;
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.UI_SETTINGS, _uiSettings);
        };

        this.startActivityChecking = startActivityChecking;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.PanelType = PanelType;
    circuit.UISvcImpl = UISvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
