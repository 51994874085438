var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var AtcCallInfo = circuit.AtcCallInfo;
    var BaseCall = circuit.BaseCall;
    var CallState = circuit.Enums.CallState;
    var CstaCallState = circuit.Enums.CstaCallState;
    var logger = circuit.logger;
    var Utils = circuit.Utils;

    function AtcRemoteCall(conversation) {
        if (!conversation || !conversation.rtcSessionId) {
            throw new Error('Cannot create AtcRemoteCall object without a valid conversation');
        }

        // Call the base constructor
        AtcRemoteCall.parent.constructor.call(this, conversation, true);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            isPullAllowed: {
                get: function () {
                    return this.atcCallInfo.isHandoverAllowed() &&
                        this.atcCallInfo.cstaState !== CstaCallState.Ringing;
                },
                enumerable: true,
                configurable: false
            },
            isDtmfAllowed: {
                get: function () {
                    return this.checkState(CallState.ActiveRemote) && this.checkCstaState(CstaCallState.Active);
                },
                enumerable: true,
                configurable: false
            }
        });
        this.isAtcRemote = true;
        this.atcCallInfo = new AtcCallInfo();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        conversation = null; // Remove reference to conversation object

        logger.info('[AtcRemoteCall]: Created new ATC remote call with callID = ', this.callId);
    }

    Utils.inherit(AtcRemoteCall, BaseCall);

    AtcRemoteCall.prototype.isPresent = function () {
        return !this.checkState([CallState.Idle, CallState.Terminated]) ||
            !this.checkCstaState([CstaCallState.Idle, CstaCallState.Failed, CstaCallState.TransferFailed, CstaCallState.Terminated]);
    };

    // Exports
    circuit.AtcRemoteCall = AtcRemoteCall;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
