/*global RegistrationState*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;
    var Enums = circuit.Enums;
    var Constants = circuit.Constants;

    // eslint-disable-next-line max-params, max-lines-per-function
    function InstrumentationSvcImpl($rootScope, LogSvc, PubSubSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: InstrumentationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _clientApiHandler = ClientApiHandler.getInstance();
        var _userToUserHandler = UserToUserHandler.getInstance();

        var NO_DATA_16_BIT = '65535';
        var NO_DATA_24_BIT = '16777215';
        var NO_DATA_STRING = '';
        var NO_DATA = 'N/A';

        var MAX_NUM_OF_CALLS_PENDING_QOS = 3; // Save up to 3 QoS reports that couldn't be submitted to the server

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function submitInstrData() {
            var records = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.QOS_PENDING_RECORDS);
            if (records && records.length) {
                LogSvc.debug('[InstrumentationSvc]: Submit pending QoS records. Num of records:', records.length);
                records.forEach(function (qos) {
                    _clientApiHandler.submitQOSData(qos, null, function (err) {
                        if (err) {
                            // We won't try again
                            LogSvc.warn('[InstrumentationSvc]: Error submitting pending QoS data for call instance ID: ' +
                                qos[0] && qos[0].rtcInstanceId, err);
                        }
                    });
                });
                LocalStoreSvc.removeItem(LocalStoreSvc.keys.QOS_PENDING_RECORDS);
            }
        }

        function processPacketLost(pl) {
            if (pl === undefined || pl < 0 || pl > 65535) {
                return NO_DATA_16_BIT;
            }
            return pl;
        }

        function processAverage(field, lastReading, savedStats) {
            if (savedStats) {
                var sum = savedStats[field + 'Sum'];
                var count = savedStats[field + 'Count'];
                if (sum !== undefined && count) {
                    if (lastReading) {
                        // We have to include the last reading as well
                        var last = lastReading[field];
                        if (last !== undefined) {
                            sum += last;
                            count++;
                        }
                    }
                    return sum / count;
                }
            }
            return undefined;
        }

        function getDeviceName(device) {
            if (!device || typeof device !== 'string') {
                return NO_DATA;
            }
            if (device.startsWith('res_')) {
                return $rootScope.i18n.localize(device) || NO_DATA;
            }
            return device;
        }

        function addDeviceInfo(qosData, mediaType, call) {
            // UD (User Device)
            qosData.push({
                name: 'UD',
                value: window.navigator.platform
            });
            if (mediaType === Constants.QOSMediaType.AUDIO) {
                qosData.push({
                    name: 'PBD',
                    value: getDeviceName(call.playbackDevice)
                });
                qosData.push({
                    name: 'RECD',
                    value: getDeviceName(call.recordingDevice)
                });

                // Send Device List parameter only if list is available
                if (call.playbackDeviceList.length) {
                    qosData.push({
                        name: 'PBDL',
                        value: call.playbackDeviceList.join('||')
                    });
                }
                if (call.recordingDeviceList.length) {
                    qosData.push({
                        name: 'RECDL',
                        value: call.recordingDeviceList.join('||')
                    });
                }
            } else if (mediaType === Constants.QOSMediaType.VIDEO) {
                qosData.push({
                    name: 'VIDD',
                    value: getDeviceName(call.videoDevice)
                });

                // Send Device List parameter only if list is available
                if (call.videoDeviceList.length) {
                    qosData.push({
                        name: 'VIDDL',
                        value: call.videoDeviceList.join('||')
                    });
                }
            }
        }

        function addChannelInfo(stats, qosData) {
            if (!stats.channelInfo) {
                stats.channelInfo = {};
            }
            // IPL (IP Address, local)
            qosData.push({
                name: 'IPL',
                value: stats.channelInfo.ipl || NO_DATA_STRING
            });
            // PTL (Port #, local)
            qosData.push({
                name: 'PTL',
                value: stats.channelInfo.ptl || NO_DATA_16_BIT
            });
            // IPR (IP Address, remote)
            qosData.push({
                name: 'IPR',
                value: stats.channelInfo.ipr || NO_DATA_STRING
            });
            // PTR (Port #, remote)
            qosData.push({
                name: 'PTR',
                value: stats.channelInfo.ptr || NO_DATA_16_BIT
            });
            // LCT (Local Candidate Type)
            qosData.push({
                name: 'LCT',
                value: stats.channelInfo.lct || NO_DATA_STRING
            });
            // RCT (Remote Candidate Type)
            qosData.push({
                name: 'RCT',
                value: stats.channelInfo.rct || NO_DATA_STRING
            });
            // TT (Transport Type)
            qosData.push({
                name: 'TT',
                value: stats.channelInfo.tt || NO_DATA_STRING
            });
            // NI (Network Interface)
            qosData.push({
                name: 'NI',
                value: !stats.channelInfo.ni || stats.channelInfo.ni === 'unknown' ? NO_DATA_STRING : stats.channelInfo.ni
            });
            if (stats.channelInfo.candidate) {
                // SADDR (Source Address - same as IPL for host candidates, raddr for relay/srflx candidates)
                qosData.push({
                    name: 'SADDR',
                    value: stats.channelInfo.candidate.sAddr || NO_DATA_STRING
                });

                // HADDR (Host Address - same as IPL for host candidates, associated host candidate for relay/srflx candidates)
                qosData.push({
                    name: 'HADDR',
                    value: stats.channelInfo.candidate.hAddr || NO_DATA_STRING
                });

                // HADDR_netmask (netmask associated with the HADDR)
                if (stats.channelInfo.candidate.netmask) {
                    qosData.push({
                        name: 'HADDR_netmask',
                        value: stats.channelInfo.candidate.netmask
                    });
                }

                // TURN_TT (Relay candidate local transport type)
                if (stats.channelInfo.candidate.tt) {
                    qosData.push({
                        name: 'TURN_TT',
                        value: stats.channelInfo.candidate.tt
                    });
                }
            }
        }

        function addCallInfo(call, stats, qosData) {
            // RTC Session Instance ID
            qosData.push({
                name: 'RID',
                value: call.instanceId || NO_DATA
            });
            // CT (Conversation type)
            qosData.push({
                name: 'CT',
                value: call.isDirect ? 'direct' : 'group'
            });
            var terminateReason = call.terminateReason || '';
            if (terminateReason) {
                if (!terminateReason.startsWith(Enums.CallServerTerminatedReason.SERVER_ENDED_PRFX)) {
                    terminateReason = Enums.CallClientTerminatedReason.CLIENT_ENDED_PRFX + terminateReason;
                }
            } else {
                terminateReason = NO_DATA;
            }
            qosData.push({
                name: 'INFO',
                value: terminateReason
            });
        }

        function addTransmitStats(stats, qosData) {
            if (!stats.transmit) {
                stats.transmit = {};
            }
            // SRCS (SSRC sending stream)
            qosData.push({
                name: 'SRCS',
                value: stats.transmit.ssrc || NO_DATA
            });
            // PS (Packets sent)
            qosData.push({
                name: 'PS',
                value: stats.transmit.ps >= 0 ? stats.transmit.ps : NO_DATA_24_BIT
            });
            // OR (Octets received)
            qosData.push({
                name: 'OS',
                value: stats.transmit.os >= 0 ? stats.transmit.os : NO_DATA_24_BIT
            });
            // Deprecated: EN (Encoding codec payload type)
            // Information not available through webRTC stats
            qosData.push({
                name: 'EN',
                value: NO_DATA
            });
            // Deprecated: ST (Encoding codec payload subtype - name)
            qosData.push({
                name: 'ST',
                value: stats.transmit.en || NO_DATA
            });
            // Encoder codec name (this field replaces EN and ST)
            qosData.push({
                name: 'EN_NAME',
                value: stats.transmit.en || NO_DATA
            });
            // PLL (Packets lost local)
            qosData.push({
                name: 'PLL',
                value: processPacketLost(stats.transmit.pl)
            });
            if (stats.packetLossSendViolations) {
                // PLL_VI (PLL Violations)
                qosData.push({
                    name: 'PLL_VI',
                    value: stats.packetLossSendViolations
                });
            }
            if (stats.transmit.ecmaxMax >= 0) {
                // ECHO_AVG (Max echo likelihood)
                qosData.push({
                    name: 'ECHO_MAX',
                    value: stats.transmit.ecmaxMax
                });
            }
            if (stats.transmit.ecmaxAvg >= 0) {
                // ECHO_AVG (Average echo likelihood)
                qosData.push({
                    name: 'ECHO_AVG',
                    value: stats.transmit.ecmaxAvg.toFixed(1)
                });
            }
            if (stats.googResidualEchoLikelihoodRecentMaxViolations) {
                // ECHO_VI (Echo Violations)
                qosData.push({
                    name: 'ECHO_VI',
                    value: stats.googResidualEchoLikelihoodRecentMaxViolations
                });
            }
        }

        function addReceiveStats(stats, qosData) {
            if (!stats.receive) {
                stats.receive = {};
            }
            // SRCR (SSRC receiving stream)
            qosData.push({
                name: 'SRCR',
                value: stats.receive.ssrc || NO_DATA
            });
            // PR (Received packets)
            qosData.push({
                name: 'PR',
                value: stats.receive.pr >= 0 ? stats.receive.pr : NO_DATA_24_BIT
            });
            // JI (Jitter)
            qosData.push({
                name: 'JI',
                value: stats.receive.jr >= 0 ? stats.receive.jr : NO_DATA_16_BIT
            });
            if (stats.receive.jrMax >= 0) {
                // JI_MAX (Max jitter)
                qosData.push({
                    name: 'JI_MAX',
                    value: stats.receive.jrMax
                });
            }
            if (stats.receive.jrAvg >= 0) {
                // JI_AVG (Average jitter)
                qosData.push({
                    name: 'JI_AVG',
                    value: Math.round(stats.receive.jrAvg)
                });
            }
            if (stats.googJitterReceivedViolations) {
                // JI_VI (Jitter Violations)
                qosData.push({
                    name: 'JI_VI',
                    value: stats.googJitterReceivedViolations
                });
            }
            // PLR (Packets lost remote)
            qosData.push({
                name: 'PLR',
                value: processPacketLost(stats.receive.pl)
            });
            if (stats.packetLossRecvViolations) {
                // PLR_VI (PLR Violations)
                qosData.push({
                    name: 'PLR_VI',
                    value: stats.packetLossRecvViolations
                });
            }
            // OR (Octets received)
            qosData.push({
                name: 'OR',
                value: stats.receive.or >= 0 ? stats.receive.or : NO_DATA_24_BIT
            });
            // Deprecated: DE (Decoding codec payload type)
            // Information not available through webRTC stats
            qosData.push({
                name: 'DE',
                value: NO_DATA
            });
            // Deprecated: ST (Decoding codec payload subtype - name)
            qosData.push({
                name: 'ST',
                value: stats.receive.en || NO_DATA
            });
            // Decoder codec name (this field replaces DE and ST)
            qosData.push({
                name: 'DE_NAME',
                value: stats.receive.en || NO_DATA
            });
        }

        function buildQOSArray(call, mediaType, rtpStats) {
            var qosData = [];
            var type, stats;
            switch (mediaType) {
            case Constants.QOSMediaType.AUDIO:
                type = 'audio';
                stats = rtpStats.audio;
                break;
            case Constants.QOSMediaType.VIDEO:
                type = 'video';
                stats = rtpStats.video;
                break;
            case Constants.QOSMediaType.SCREEN_SHARE:
                type = 'screen share';
                stats = rtpStats.video;
                break;
            default:
                // Unknown type
                return qosData;
            }
            // Get the start of report timestamp (NTP format)
            var tb = rtpStats.startTime || Date.now();
            // 2208988800 is the number of seconds between 1/1/1900 and 1/1/1970 (Unix epoch)
            tb = Math.floor(tb / 1000) + 2208988800;
            // Get the end of report timestamp in NTP format (epoch is Jan 1, 1900)
            var te = new Date().getTime();
            te = Math.floor(te / 1000) + 2208988800;
            // MT (Media type)
            qosData.push({
                name: 'MT',
                value: type
            });
            // TB (Date and Time for begin of report period - NTP timestamp)
            qosData.push({
                name: 'TB',
                value: tb
            });
            // TE (Date and Time for end of report period - NTP timestamp)
            qosData.push({
                name: 'TE',
                value: te
            });
            // LA (Round trip delay)
            qosData.push({
                name: 'LA',
                value: stats.rtt >= 0 ? stats.rtt : NO_DATA_16_BIT
            });
            if (stats.rttAvg >= 0) {
                // LA_AVG (Average round trip delay)
                qosData.push({
                    name: 'LA_AVG',
                    value: Math.round(stats.rttAvg)
                });
            }
            if (stats.rttMax >= 0) {
                // LA_MAX (Max round trip delay)
                qosData.push({
                    name: 'LA_MAX',
                    value: stats.rttMax
                });
            }
            if (stats.googRttViolations) {
                // LA_VI (LA Violations)
                qosData.push({
                    name: 'LA_VI',
                    value: stats.googRttViolations
                });
            }
            addDeviceInfo(qosData, mediaType, call);
            addChannelInfo(stats, qosData);
            addCallInfo(call, stats, qosData);
            addTransmitStats(stats, qosData);
            addReceiveStats(stats, qosData);
            // Make sure that value is a string for all stats
            qosData.forEach(function (data) {
                data.value += '';
            });
            return qosData;
        }

        function buildTelephonyQosData(data, call) {
            var qosData = [{name: 'CR', value: $rootScope.localUser.phoneNumber}];
            var relevantValues = ['TB', 'TE', 'IPL', 'PTL', 'IPR', 'PTR', 'SRCS', 'SRCR', 'MT', 'ST', 'PR', 'JI', 'LA', 'PLL', 'PS'];
            relevantValues.forEach(function (relevantValue) {
                data.some(function (pair) {
                    if (pair.name === relevantValue) {
                        if (pair.name === 'TB' || pair.name === 'TE') {
                            // Special case: Need to convert Time values from NTP format to Unix Epoch
                            qosData.push({name: pair.name, value: (pair.value - 2208988800) + '.0'});
                        } else {
                            qosData.push(pair);
                        }
                        return true;
                    }
                    return false;
                });
            });
            if (call && call.peerUser && call.peerUser.phoneNumber) {
                qosData.push({name: 'CD', value: call.peerUser.phoneNumber});
            }
            qosData.push({name: 'ET', value: $rootScope.browser.type + ' ' + $rootScope.browser.version});
            qosData.push({name: 'CV', value: $rootScope.clientVersion});
            qosData.push({name: 'TI', value: $rootScope.localUser.tenantId});
            return qosData;
        }

        function checkViolationsRecord(stat, savedStats) {
            var start = stat + 'LastViStart';
            var viProperty = stat + 'Violations';
            if (savedStats && savedStats[start]) {
                var violations = savedStats[viProperty];
                // There's an ongoing violation, add it to the records
                return (violations ? violations + ',' : '') + '(' + savedStats[start] + ')';
            }
            return savedStats[viProperty];
        }

        function returnMax(val1, val2) {
            if (val1 !== undefined) {
                if (val2 !== undefined) {
                    return val1 >= val2 ? val1 : val2;
                } else {
                    return val1;
                }
            } else if (val2 !== undefined) {
                return val2;
            }
            return undefined;
        }

        function finalizeStats(stats, saved) {
            // Process max, average and number of violations
            if (saved) {
                var channelId, violations;
                if (stats.receive && saved[stats.receive.ssrc]) {
                    channelId = stats.receive.channelId;
                    stats.receive.jrMax = returnMax(saved[stats.receive.ssrc].jrMax, stats.receive.jr);
                    stats.receive.jrAvg = processAverage('jr', stats.receive, saved[stats.receive.ssrc]);
                    violations = ['googJitterReceived', 'packetLossRecv'];
                    violations.forEach(function (v) {
                        stats.receive[v + 'Violations'] = checkViolationsRecord(v, saved[stats.receive.ssrc]);
                    });
                }
                if (stats.transmit && saved[stats.transmit.ssrc]) {
                    channelId = stats.transmit.channelId;
                    stats.transmit.ecmaxMax = returnMax(saved[stats.transmit.ssrc].ecmaxMax, stats.transmit.ecmax);
                    stats.transmit.ecmaxAvg = processAverage('ecmax', stats.transmit, saved[stats.transmit.ssrc]);
                    violations = ['packetLossSend', 'googResidualEchoLikelihoodRecentMax'];
                    violations.forEach(function (v) {
                        stats.transmit[v + 'Violations'] = checkViolationsRecord(v, saved[stats.transmit.ssrc]);
                    });
                }

                if (channelId && saved.channelInfo) {
                    var savedChannelInfo = saved.channelInfo[channelId];
                    if (savedChannelInfo) {
                        stats.rttAvg = processAverage('rtt', stats, savedChannelInfo);
                        stats.rttMax = returnMax(savedChannelInfo.rttMax, stats.rtt);
                        stats.googRttViolations = checkViolationsRecord('googRtt', savedChannelInfo);
                    }
                }
            } else {
                // There's no history available, so assign the last reading to average and max fields
                if (stats.receive) {
                    stats.receive.jrMax = stats.receive.jrAvg = stats.receive.jr;
                }
                if (stats.transmit) {
                    stats.transmit.ecmaxMax = stats.transmit.ecmaxAvg = stats.transmit.ecmax;
                }
                stats.rttAvg = stats.rttMax = stats.rtt;
            }
        }

        function onSubmitQosFailed(err, localCall, qosData) {
            if (err === Constants.ReturnCode.REQUEST_TIMEOUT ||
                err === Constants.ReturnCode.DISCONNECTED ||
                err === Constants.ReturnCode.FAILED_TO_SEND) {
                // Failed to send the QoS report because we're not connected to the server
                LogSvc.warn('[InstrumentationSvc]: Error submitting QoS data for call ID: ' +
                    localCall.callId + '. Will try again when reconnected', err);

                var records = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.QOS_PENDING_RECORDS) || [];
                if (!Array.isArray(records)) {
                    LogSvc.warn('[InstrumentationSvc]: Invalid stored QoS records discarded');
                    records = [qosData];
                } else {
                    records.push(qosData);
                    if (records.length > MAX_NUM_OF_CALLS_PENDING_QOS) {
                        records = records.slice(-MAX_NUM_OF_CALLS_PENDING_QOS);
                        LogSvc.warn('[InstrumentationSvc]: Discarded older QoS records');
                    }
                }
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.QOS_PENDING_RECORDS, records);
            } else {
                LogSvc.warn('[InstrumentationSvc]: Error submitting QoS data for call ID: ' + localCall.callId + '. ', err);
            }
        }

        function submitQOSData(localCall, rtpStats, savedRtpStats, streamQualityData) {
            var qosData = [], mediaData = [], videoList = [], screenList = [], videoQosCandidate = null;
            Object.keys(rtpStats.channelInfo).forEach(function (channelId) {
                // Build audio and video send/receive pairs
                var pairs = {
                    audio: [],
                    video: []
                };
                ['audio', 'video'].forEach(function (media) {
                    if (rtpStats[media]) {
                        var pair = {};
                        ['transmit', 'receive'].forEach(function (direction) {
                            Object.keys(rtpStats[media][direction]).forEach(function (ssrc) {
                                var trackInfo = rtpStats[media][direction][ssrc];
                                if (trackInfo.channelId === channelId) {
                                    if (pair[direction]) {
                                        pairs[media].push(pair);
                                        pair = {};
                                    }
                                    pair.rtt = trackInfo.channelInfo.rtt;
                                    pair.channelInfo = trackInfo.channelInfo;
                                    pair[direction] = trackInfo;
                                }
                            });
                        });
                        if (pair.transmit || pair.receive) {
                            pairs[media].push(pair);
                        }
                    }
                });

                pairs.audio.forEach(function (audio) {
                    if (audio.transmit || audio.receive) {
                        finalizeStats(audio, savedRtpStats);
                        mediaData = buildQOSArray(localCall, Constants.QOSMediaType.AUDIO, {audio: audio, startTime: rtpStats.startTime});
                        qosData.push({
                            convID: localCall.convId,
                            mediaType: Constants.QOSMediaType.AUDIO,
                            data: mediaData,
                            rtcSessionId: localCall.callId,
                            rtcInstanceId: localCall.instanceId,
                            associatedClientId: localCall.clientId
                        });
                    }
                });

                pairs.video.forEach(function (video) {
                    if (video.transmit || video.receive) {
                        if ((video.transmit && video.transmit.content) === 'screen' ||
                            (video.receive && video.receive.content) === 'screen') {
                            video.videoType = Constants.QOSMediaType.SCREEN_SHARE;
                            screenList.push(video);
                        } else {
                            video.videoType = Constants.QOSMediaType.VIDEO;
                            if (video.transmit) {
                                // Add transmitting pair to the QoS
                                videoList.push(video);
                            } else if (!videoQosCandidate) {
                                // Randomly chosen receive-only pair
                                videoQosCandidate = video;
                            }
                        }
                    }
                });
            });

            if (!videoList.length && videoQosCandidate) {
                // For video, we report the screenshare and a video pair that contains a transmitting video,
                // but if we're not transmitting video, pick a random receiving video connection
                videoList.push(videoQosCandidate);
            }
            Array.prototype.push.apply(videoList, screenList);
            videoList.forEach(function (video) {
                finalizeStats(video, savedRtpStats);
                mediaData = buildQOSArray(localCall, video.videoType, {video: video, startTime: rtpStats.startTime});
                qosData.push({
                    convID: localCall.convId,
                    mediaType: video.videoType,
                    data: mediaData,
                    rtcSessionId: localCall.callId,
                    rtcInstanceId: localCall.instanceId,
                    associatedClientId: localCall.clientId
                });
            });

            if (qosData.length) {
                _clientApiHandler.submitQOSData(qosData, streamQualityData, function (err) {
                    if (err) {
                        onSubmitQosFailed(err, localCall, qosData);
                    }
                });
                if (localCall.isTelephonyCall && $rootScope.localUser.associatedTelephonyUserID) {
                    var data = {
                        content: {
                            type: 'QOS',
                            qosData: buildTelephonyQosData(qosData[0].data, localCall)
                        },
                        destUserId: $rootScope.localUser.associatedTelephonyUserID
                    };
                    _userToUserHandler.sendAtcRequest(data);
                }
            } else {
                LogSvc.warn('[InstrumentationSvc]: No QoS data to be submitted');
            }

            // Update call object with the current clientId (in case it changed)
            localCall.clientId = _clientApiHandler.clientId;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/registration/state', function (state) {
            LogSvc.debug('[InstrumentationSvc]: Received /registration/state event');
            if (state === RegistrationState.Registered) {
                submitInstrData();
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.sendQOSData = function (localCall, mediaType, rtpStats, savedStats, streamQualityData) {
            if (!localCall || !rtpStats) {
                return;
            }
            submitQOSData(localCall, rtpStats, savedStats, streamQualityData);
            LogSvc.info('[InstrumentationSvc]: Send QoS data for call Id:', localCall.callId);
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.InstrumentationSvcImpl = InstrumentationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
