var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;

    // eslint-disable-next-line max-params, max-lines-per-function
    function ManagedDevicesSvcImpl($q, $rootScope, LogSvc) { // NOSONAR
        LogSvc.debug('New Service: ManagedDevicesSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _clientApiHandler = ClientApiHandler.getInstance();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * This function revokes all managed devices except the one triggering it.
         *
         * @function
         * @returns {Promise}
         */
        this.revokeAllManagedDevices = function () {
            var deferred = $q.defer();
            _clientApiHandler.revokeAllManagedDevices(function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[ManagedDevicesSvc]: Failed to revoke all managed devices. ', err);
                        deferred.reject(err);
                        return;
                    }
                    deferred.resolve();
                });
            });

            return deferred.promise;
        };

        /**
         * This function returns a list of all security token information (managed devices).
         *
         * @function
         * @returns {Promise}
         */
        this.getSecurityTokenInfo = function () {
            LogSvc.debug('[ManagedDevicesSvc]: Get connected apps');

            var deferred = $q.defer();
            _clientApiHandler.getSecurityTokenInfo(function (error, info) {
                $rootScope.$apply(function () {
                    if (error) {
                        LogSvc.error('[ManagedDevicesSvc]: Error retrieving token infos. ', error);
                        deferred.reject(error);
                        return;
                    }
                    info = info || [];
                    LogSvc.info('[ManagedDevicesSvc]: Retrieved ' + info.length + ' token infos.');

                    info.sort(function (a, b) {
                        return ((+b.currentSession).toString() + b.lastLoginTime) - ((+a.currentSession).toString() + a.lastLoginTime);
                    });
                    deferred.resolve(info);
                });
            });
            return deferred.promise;
        };

        /**
         * Revokes acces to a security token
         * @function
         * @param {String} tokenIdHash The security token ID hash to be revoked.
         * @returns {Promise}
         */
        this.revokeSecurityToken = function (tokenIdHash) {
            LogSvc.debug('[ManagedDevicesSvc]: Revoke security token:', tokenIdHash);
            var deferred = $q.defer();
            _clientApiHandler.revokeSecurityToken(tokenIdHash, function (error) {
                $rootScope.$apply(function () {
                    if (error) {
                        LogSvc.error('[ManagedDevicesSvc] Error revoking security token. ', error);
                        deferred.reject(error);
                        return;
                    }
                    deferred.resolve();
                });
            });
            return deferred.promise;
        };

        return this;
    }

    // Exports
    circuit.ManagedDevicesSvcImpl = ManagedDevicesSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
