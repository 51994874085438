/*global Promise, Uint8Array*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Utils = circuit.Utils;

    ///////////////////////////////////////////////////////////////////////////
    // Constants
    ///////////////////////////////////////////////////////////////////////////
    var THUMB_BACKGROUND_COLOR = '#f0f0f0'; // Light-gray. Is needed for images with transparency.
    var MAX_FILE_COUNT = 10;
    var IMAGE_SMALL_HEIGHT = 312;
    var IMAGE_SMALL_WIDTH = 484;
    var COMPRESSED_IMG_PREFIX = 'tHuMbNaIl___'; // keep in sync with definition in app

    var UploadPromise;
    if (typeof Promise !== 'undefined') {
        // By default use the standard Promise object if supported by the browser
        UploadPromise = Promise;
    }

    ///////////////////////////////////////////////////////////////////////////
    // Helper functions
    ///////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line max-params
    function drawCanvasImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight) { // NOSONAR
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = dWidth;
        canvas.height = dHeight;
        ctx.fillStyle = THUMB_BACKGROUND_COLOR;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        return canvas.toDataURL('image/jpeg');  // Convert canvas to jpeg url
    }

    // Resize image to a provided size, or to Circuit thumbnail size
    function resizePicture(image, width, height) {
        width = width || IMAGE_SMALL_WIDTH;
        height = height || IMAGE_SMALL_HEIGHT;

        var imgWidth, imgHeight;
        imgHeight = image.height;
        imgWidth = image.width;

        if ((imgHeight / imgWidth) > (height / width)) {
            if (image.height > height) {
                imgWidth = imgWidth * height / imgHeight;
                imgHeight = height;
            }
        } else if (imgWidth > width) {
            imgHeight = imgHeight * width / imgWidth;
            imgWidth = width;
        }

        return drawCanvasImage(image, 0, 0, image.width, image.height, 0, 0, imgWidth, imgHeight);
    }

    // Create a blob from a dataURI
    function dataURItoBlob(dataURI, type) {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: type || 'image/jpeg'});
    }

    // Create a small image (thumbnail). Returns a promise.
    function createThumbnail(image, options) {
        options = options || {};
        return new UploadPromise(function (resolve/*, reject*/) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var img = new Image();
                img.onload = function () {
                    var resizeImg = resizePicture(this, options.width, options.height);
                    var blob = dataURItoBlob(resizeImg);
                    blob.name = (options.prefix || '') + (image.modifiedName || image.name);
                    blob.lastModified = Math.round(Date.now() / 1000);
                    resolve(blob);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(image);
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // FileUpload
    ///////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line max-lines-per-function
    function FileUpload(config) { // NOSONAR

        ///////////////////////////////////////////////////////////////////////////
        // Local variables and functions
        ///////////////////////////////////////////////////////////////////////////
        var _domain = config ? config.domain : null;
        var _nextReqId = 1;
        var _pendingUploads = {};

        ///////////////////////////////////////////////////////////////////////////
        // Local functions
        ///////////////////////////////////////////////////////////////////////////

        function deleteFiles(deleteUrls) {
            deleteUrls && deleteUrls.forEach(function (url) {
                var req = new XMLHttpRequest();
                req.withCredentials = true;
                req.open('DELETE', url);
                req.send();
            });
        }

        // Upload a single file. Returns a promise.
        function uploadFile(reqId, file, url, onProgress, opts) {
            var clearPendingReq = function () {
                if (_pendingUploads[reqId]) {
                    _pendingUploads[reqId].xhr = null;
                }
            };

            return new UploadPromise(function (resolve, reject) {
                var fileName = file.modifiedName || file.name;

                var req = new XMLHttpRequest();
                req.withCredentials = true;
                req.open('POST', url, true);
                req.setRequestHeader('Content-Type', file.type);
                req.setRequestHeader('Content-Disposition', 'attachment; filename="' + encodeURIComponent(fileName) + '"');
                if (opts) {
                    opts.convId && req.setRequestHeader('x-conversation', opts.convId);
                    opts.rtcSessionId && req.setRequestHeader('x-rtcsession', opts.rtcSessionId);
                    opts.token && req.setRequestHeader('authorization', 'SecToken ' + opts.token);
                }

                if (typeof onProgress === 'function') {
                    req.upload.onprogress = function (event) {
                        if (event.total > 0) {
                            onProgress(event.loaded, event.total, fileName);
                        }
                    };
                }

                req.onload = function () {
                    clearPendingReq();
                    if (req.status === 200) {
                        resolve(req.response);
                    } else {
                        reject({
                            filename: fileName,
                            status: req.status,
                            statusText: req.statusText,
                            response: req.response,
                            responseText: req.responseText
                        });
                    }
                };

                req.onerror = function () {
                    clearPendingReq();
                    reject({
                        filename: fileName,
                        status: 500,
                        statusText: 'Connection Failed'
                    });
                };

                req.onabort = function () {
                    clearPendingReq();
                    reject({
                        filename: fileName,
                        status: 0,
                        statusText: 'abort'
                    });
                };

                _pendingUploads[reqId].xhr = req;
                req.send(file);
            });
        }

        function getUploadPromise(file, options, onProgress) {
            onProgress = onProgress || null;

            if (!UploadPromise) {
                throw new Error('Promise support is required to use FileUpload');
            }

            var url = (_domain ? 'https://' + _domain : '') + '/fileapi';
            var reqId = _nextReqId;

            _nextReqId++;
            _pendingUploads[reqId] = {
                xhr: null,
                deleteUrls: []
            };
            var uploadPromise = uploadFile(reqId, file, url, onProgress, options)
                .then(function (res) {
                    var resp = JSON.parse(res)[0];
                    var fileId = resp.fileId || resp.id;
                    var fileUrl = url + '?fileid=' + fileId;
                    var fileName = file.modifiedName || file.name;
                    var attachmentMetaData = {
                        fileId: fileId,
                        fileName: fileName,
                        mimeType: resp.mimeType || file.type,
                        size: file.size,
                        url: fileUrl,
                        deleteUrl: fileUrl
                    };
                    delete _pendingUploads[reqId];
                    return UploadPromise.resolve(attachmentMetaData);
                })
                .catch(function (err) {
                    delete _pendingUploads[reqId];
                    return UploadPromise.reject(err);
                });

            uploadPromise.__uploadId = reqId;
            return uploadPromise;
        }

        ///////////////////////////////////////////////////////////////////////////
        // APIs
        ///////////////////////////////////////////////////////////////////////////

        // Upload multiple files including images. For images thumbnails are created
        // if needed. Returns a promise.
        // domain does not include the protocol. E.g. circuitsandbox.net
        this.uploadFiles = function (files, domain, itemId, onProgress, noThumbnail) {
            if (!UploadPromise) {
                throw new Error('Promise support is required to use FileUpload');
            }

            if (files.length > MAX_FILE_COUNT) {
                return UploadPromise.reject('Exceeded maximum of 10 files per message');
            }

            domain = domain || _domain;

            var urlPrefix = domain ? 'https://' + domain : '';
            var result = [];
            var reqId = _nextReqId;

            _nextReqId++;
            _pendingUploads[reqId] = {
                xhr: null,
                deleteUrls: []
            };

            // Find large images that need to be resized
            var resizePromises = [];
            !noThumbnail && files.forEach(function (file) {
                // Even create a thumbnail for small images
                if (Utils.isSupportedImage && Utils.isSupportedImage(file.type)) {
                    resizePromises.push(createThumbnail(file, {
                        prefix: COMPRESSED_IMG_PREFIX
                    }));
                }
            });

            var uploadAllPromise = UploadPromise.all(resizePromises)
            .then(function (blobs) {
                // Add the blob's (thumbnails) to the list of files to be upoaded
                Array.prototype.push.apply(files, blobs);

                // Sequentially (but async) upload the files. Once all files are
                // uploaded, resolve the Promise passing the upload results.
                itemId = itemId || null;
                return files.reduce(function (sequence, file) {
                    return sequence.then(function () {
                        var url = urlPrefix + '/fileapi?itemid=' + (itemId || 'NULL');
                        return uploadFile(reqId, file, url, onProgress)
                        .then(function (res) {
                            var resp = JSON.parse(res)[0];
                            itemId = itemId || resp.id;
                            var fileId = resp.fileid[resp.fileid.length - 1];
                            var fileName = file.modifiedName || file.name;
                            var attachmentMetaData = {
                                fileId: fileId,
                                fileName: fileName,
                                itemId: itemId,
                                mimeType: resp.mimeType || file.type,
                                size: file.size,
                                url: urlPrefix + '/fileapi?fileid=' + fileId + '&itemid=' + itemId,
                                deleteUrl: urlPrefix + '/fileapi?fileid=' + fileId + '&itemid=' + itemId
                            };
                            result.push(attachmentMetaData);
                            _pendingUploads[reqId].deleteUrls.push(attachmentMetaData.deleteUrl);
                            return result;
                        });
                    });
                }, UploadPromise.resolve());
            })
            .then(function (uploadedFiles) {
                delete _pendingUploads[reqId];
                return UploadPromise.resolve(uploadedFiles);
            })
            .catch(function (err) {
                delete _pendingUploads[reqId];
                return UploadPromise.reject(err);
            });

            uploadAllPromise.__uploadId = reqId;
            return uploadAllPromise;
        };

        // Upload a single file (image) for the whiteboard
        this.uploadWhiteboardFile = function (file, rtcSessionId, token) {
            return getUploadPromise(file, {
                rtcSessionId: rtcSessionId,
                token: token
            });
        };

        // Upload an archive of CMR logs
        this.uploadCMRLogs = function (file) {
            return getUploadPromise(file);
        };

        // Upload mass import file
        this.uploadMassImportFile = function (file, inProgress) {
            return getUploadPromise(file, null, inProgress);
        };

        // Aborts the upload for given promise
        this.abort = function (promise) {
            if (promise && promise.__uploadId && _pendingUploads[promise.__uploadId]) {
                var pendingUpload = _pendingUploads[promise.__uploadId];
                delete _pendingUploads[promise.__uploadId];

                // Abort ongoing upload and delete uploaded files (if any)
                if (pendingUpload.xhr) {
                    pendingUpload.xhr.abort();
                }
                deleteFiles(pendingUpload.deleteUrls);
            }
        };
    }

    FileUpload.COMPRESSED_IMG_PREFIX = COMPRESSED_IMG_PREFIX;
    FileUpload.dataURItoBlob = dataURItoBlob;
    FileUpload.drawCanvasImage = drawCanvasImage;

    FileUpload.overridePromise = function ($q) {
        UploadPromise = $q;
    };

    // Exports
    circuit.FileUpload = FileUpload;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
