// Define global variables for JSHint
/*exported ControllerRouteError, ScrollDirection, ScrollPosition, UIEventType*/

/*
    UIEventType used in UISvc and uiDirectives.
**/
var UIEventType = Object.freeze({
    FOCUS: 'uFocus',
    FOCUS_ELEMENT: 'uFocusElement',
    BLUR: 'uBlur',
    SCROLL_TO_ITEM: 'uScrollItem',
    SCROLL_TO_OFFSET: '',   // used in existing uScrollContainer directive
    SCROLL_TO_ELEMENT: 'scrollToElement',
    ADD_ITEM: 'addItem',
    CHECK_ITEM_VISIBILITY: 'checkItemVisibility',
    CHECK_THREAD_VISIBILITY: 'checkThreadVisibility',
    SCROLL_DIRECTION_CHANGED: 'scrollDirectionChanged',
    CLEAR_SEARCH: 'clearSearch',
    SHOW_FLOATER: 'showFloater',
    NOTIFY_LEARNING_MARKS: 'notifyLearningMarks',
    CLOSE_LEARNING_MARKS: 'closeLearningMarks',
    TRIGGER_EMAIL_SEARCH: 'triggerEmailSearch',
    SHOW_OVERLAY: 'showOverlay',
    HIDE_OVERLAY: 'hideOverlay',
    ADJUST_ITEMS_SCROLL: 'adjustItemsScroll',
    ADJUST_REMOVED_THREAD_SCROLL: 'adjustRemovedThreadScroll',
    IS_FIELD_EMPTY: 'isFieldEmpty',
    GET_THREAD_ITEM_HEIGHT: 'getThreadItemHeight',
    GET_THREAD_HEIGHT: 'getThreadHeight',
    CONTACT_SELECTED: 'contactSelected',
    CONTACT_UNSELECTED: 'contactUnselected',
    SYNC_SELECTED_CONTACTS: 'syncSelectedContacts',
    CUSTOM_DRAG_LEAVE: 'dragleave',
    CUSTOM_DRAG_ENTER: 'dragenter',
    SHOW_VIDEO: 'showVideo',
    RESET_INFINITE_SCROLL: 'resetInfiniteScroll'
});

/*
    Enum for scroll positions
**/
var ScrollPosition = Object.freeze({
    TOP: 'top',
    BOTTOM: 'bottom',
    MIDDLE: 'middle',
    NONE: 'none'  // no scrollbar
});

/*
    Enum for scroll direction
**/
var ScrollDirection = Object.freeze({
    UP: 'up',
    DOWN: 'down',
    NONE: 'none'
});

/*
    Enum Controller Route Errors
**/
var ControllerRouteError = Object.freeze({
    CONVERSATION_NOT_FOUND: 'CONVERSATION_NOT_FOUND',
    DRAFT_NOT_FOUND: 'DRAFT_NOT_FOUND',
    GENERIC_ERROR: 'GENERIC_ERROR',
    INSUFFICIENT_ACCESS_RIGHTS: 'INSUFFICIENT_ACCESS_RIGHTS',
    NO_ACCESS_OPEN_CONVERSATIONS: 'NO_ACCESS_OPEN_CONVERSATIONS',
    USER_NOT_FOUND: 'USER_NOT_FOUND'
});
