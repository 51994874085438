/*global ControllerRouteError, RegistrationState*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var Conversation = circuit.Conversation;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function ConversationSvcImpl($rootScope, $q, $timeout, LogSvc, PubSubSvc) { // NOSONAR

        LogSvc.debug('New Service: ConversationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _self = this;

        var _clientApiHandler = ClientApiHandler.getInstance();

        var _conversationsLoadComplete = false;
        var _telephonyConvId;
        var _telephonyConv;
        var _telephonyConvUnreadData = {unreadItems: 0 };
        var _totalUnreadMessages = 0; // Used for browser title display and conversation floater display
        var _readyToProcessServerEvents = false; // We need this flag so we won't process server events during initialization

        ///////////////////////////////////////////////////////////////////////////////////////
        // Cache Objects
        ///////////////////////////////////////////////////////////////////////////////////////
        var _conversations = []; // Not sorted
        var _conversationsHashtable = {}; // Hashtable of conversation IDs to conversation objects

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function clearConversationSvcCache() {
            // Clear the cache objects
            Utils.emptyArray(_conversations);
            _conversationsHashtable = {};

            // Now reset the other internal variables
            _conversationsLoadComplete = false;
            _totalUnreadMessages = 0;
        }

        function getTelephonyConv() {
            if (!_telephonyConv) {
                var conversation = {
                    convId: _telephonyConvId,
                    creationTime: Date.now(),
                    creatorId: $rootScope.localUser.userId,
                    creatorTenantId: $rootScope.localUser.tenantId,
                    isTelephony: true,
                    participants: [{
                        userId: $rootScope.localUser.userId
                    }, {
                        userId: $rootScope.localUser.associatedTelephonyUserID
                    }],
                    rtcSessionId: _telephonyConvId,
                    topic: $rootScope.i18n.localize('res_PhoneCalls'),
                    type: Constants.ConversationType.DIRECT,
                    userData: _telephonyConvUnreadData
                };
                _telephonyConv = Conversation.extend(conversation);
                _conversationsHashtable[_telephonyConvId] = _telephonyConv;
                _conversations.push(_telephonyConv);
            }
            return _telephonyConv;
        }

        function resolveTelephonyConversationPromise(deferred) {
            if (!_telephonyConvId) {
                deferred.reject(ControllerRouteError.CONVERSATION_NOT_FOUND);
            } else {
                deferred.resolve(getTelephonyConv());
            }
        }

        function resolveConversationPromise(deferred, convId) {
            if (!_telephonyConvId || convId !== _telephonyConv) {
                deferred.reject(ControllerRouteError.CONVERSATION_NOT_FOUND);
            } else {
                deferred.resolve(getTelephonyConv());
            }
        }

        function setTelephonyConvId(telephonyConvId) {
            if (_telephonyConvId !== telephonyConvId) {
                _telephonyConvId = telephonyConvId || null;
                LogSvc.info('[ConversationSvc]: Set _telephonyConvId to ', _telephonyConvId);
                LogSvc.debug('[ConversationSvc]: Publish /telephony/enabled event: ', !!_telephonyConvId);
                PubSubSvc.publish('/telephony/enabled', !!_telephonyConvId);
            }
        }

        function setUnreadData(unreadItems, lastReadTimestamp) {
            _telephonyConvUnreadData.unreadItems = unreadItems || 0;
            _telephonyConvUnreadData.lastReadTimestamp = lastReadTimestamp;
            if (_telephonyConv) {
                var baseConv = _telephonyConv;
                // Need to update these in case this is a reconnection and properties have changed
                if (_telephonyConv.isExtended) {
                    baseConv = Utils.getBaseObject(_telephonyConv, true);
                }
                baseConv.userData.unreadItems = _telephonyConvUnreadData.unreadItems;
                baseConv.userData.lastReadTimestamp = _telephonyConvUnreadData.lastReadTimestamp;
            }
            //Used by mobiles to update indicator
            LogSvc.debug('[ConversationSvc]: Publish /unread/changed event');
            PubSubSvc.publish('/unread/changed', _telephonyConvUnreadData.unreadItems);

            _totalUnreadMessages = _telephonyConvUnreadData.unreadItems;

            LogSvc.debug('[ConversationSvc]: Publish /conversations/totalUnreadCountChanged event');
            PubSubSvc.publish('/conversations/totalUnreadCountChanged', _totalUnreadMessages);
        }

        function setTotalUnreadCount() {
            // Each conversation contains its unread count.
            var count = 0;
            _conversations.forEach(function (c) {
                if (c.isTelephony && !$rootScope.localUser.telephonyAvailable) {
                    return;
                }
                if (c.userData && !c.muted && !c.hidden) {
                    count += c.userData.unreadItems || 0;
                }
            });

            if (count !== _totalUnreadMessages) {
                _totalUnreadMessages = count;
                LogSvc.debug('[ConversationSvc]: Set total unread count = ', _totalUnreadMessages);
                LogSvc.debug('[ConversationSvc]: Publish /conversations/totalUnreadCountChanged event');
                PubSubSvc.publish('/conversations/totalUnreadCountChanged', _totalUnreadMessages);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            showTelephonyConversation: {
                get: function () {
                    return !!($rootScope.localUser && $rootScope.localUser.telephonyAvailable);
                },
                enumerable: true,
                configurable: !!circuit.isUnitTestRun
            },
            telephonyConvId: {
                get: function () { return _telephonyConvId; },
                enumerable: true,
                configurable: !!circuit.isUnitTestRun
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/registration/state', function (state) {
            LogSvc.debug('[ConversationSvc]: Received /registration/state event');
            if (state === RegistrationState.LoggingOut) {
                clearConversationSvcCache();
            } else if (state !== RegistrationState.Registered && _readyToProcessServerEvents) {
                // If we disconnect, don't process server events until we reconnect and finish the
                // initialization sequence again
                LogSvc.warn('[ConversationSvc]: Not processing Conversation events until registered again');
                _readyToProcessServerEvents = false;
            }
        });

        function onRegistrationStuff(stuff) {
            LogSvc.debug('[ConversationSvc]: Received /registration/stuff event');

            setTelephonyConvId(stuff.telephonyConvId);
            setUnreadData(stuff.journalUnreadMissedCount, stuff.journalLastTimeStamp);
        }

        PubSubSvc.subscribe('/registration/stuff', onRegistrationStuff);

        PubSubSvc.subscribe('/users/loaded', function () {
            // We load the users and labels before we load the conversations
            LogSvc.debug('[ConversationSvc]: Received /users/loaded event');
            _conversationsLoadComplete = false;

            // We don't need to load anything since there are no conversations in NGTC, so just publish the
            // necessary events in a different event cycle.
            $timeout(function () {
                _conversationsLoadComplete = true;
                LogSvc.debug('[ConversationSvc]: Publish /conversations/loadComplete event');
                PubSubSvc.publish('/conversations/loadComplete');
            }, 0);
        });

        PubSubSvc.subscribe('/conversations/showTelephonyConversation', function () {
            LogSvc.debug('[ConversationSvc]: Received /conversations/showTelephonyConversation event');
            setTotalUnreadCount();
        });

        PubSubSvc.subscribe('/language/update', function () {
            var conv = _telephonyConvId && _conversationsHashtable[_telephonyConvId];
            if (conv) {
                conv.topic = $rootScope.i18n.localize('res_PhoneCalls');
                conv.topicEscaped = conv.topic;
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Checks if the conversations have already been fully loaded after startup.
         *
         * @returns {Boolean} Return true if the conversations loading is complete.
         */
        this.isConversationsLoadComplete = function () {
            return _conversationsLoadComplete;
        };

        /**
         * Get a promise that is resolved as soon as the conversations hasve been fully loaded after startup.
         *
         * @returns {object} Promise object resolving a conversation.
         */
        this.getConversationsLoadCompletePromise = function () {
            return new $q(function (resolve) {
                if (_conversationsLoadComplete) {
                    resolve();
                } else {
                    PubSubSvc.subscribeOnce('/conversations/loadComplete', function () {
                        resolve();
                    });
                }
            });
        };

        /**
         * Get a promised conversation. Promise is resolved once the
         * conversation is available on the client. Promise is rejected
         * if the conversation is accessible by the logged on user.
         *
         * @params {String} The conversation id to fetch
         * @returns {object} Promise object resolving a conversation.
         */
        this.getConversationPromise = function (convId) {
            var deferred = $q.defer();
            if (_conversationsLoadComplete) {
                resolveConversationPromise(deferred, convId);
            } else {
                PubSubSvc.subscribeOnce('/conversations/loadComplete', function () {
                    resolveConversationPromise(deferred, convId);
                });
            }
            return deferred.promise;
        };

        /**
         * Get list of active and remote calls for selector use.
         *
         * @returns {Object} Object containing an array of call objects
         */
        this.getCallsForSelector = function (includeArchived) {
            var calls = [];
            _conversations.forEach(function (conv) {
                if (conv.call && (includeArchived || !conv.muted || !conv.call.isGroupCallStarted)) {
                    calls.push(conv.call);
                }
            });

            if (Utils.isMobile()) {
                // Return a lightweight call object without data that is not needed
                // by the mobile applications.
                calls = calls.map(Utils.trimCallForMobile);
            }

            return calls;
        };

        /**
         * Get the conversation that is in cache with the specified convId.
         *
         * @param {String} convId The convId of the conversation that needs to be returned.
         * @returns {Conversation} The conversation object with specified convId or null.
         */
        this.getConversationFromCache = function (convId) {
            return (convId && _conversationsHashtable[convId]) || null;
        };

        /**
         * Get the conversation from server with specified convId.
         *
         * @param {String} convId The convId of the conversation that needs to be returned.
         * @param {Object} options
         *   randomLoaded (default = true): Randomly means this conversation could be out of sequence, e.g. via search or via URL loading
         *   loadItems (default = false): Load the conversation items.
         * @param {Function} cb The callback function when the conversation is returned from server.
         */
        this.getConversationById = function (convId, options, cb) {
            if (_telephonyConvId && convId === _telephonyConvId) {
                cb(null, getTelephonyConv());
            } else {
                cb(ControllerRouteError.CONVERSATION_NOT_FOUND);
            }
        };

        /**
         * Get the conversations with specified conversation IDs. If the conversation is not yet cached, the
         * client will retrieve it from the server.
         *
         * @returns {Promise}
         */
        this.getConversationsByIds = function () {
            return $q.reject('OBSOLETE');
        };

        this.getConversationByRtcSession = function (rtcSessionId) {
            var id = rtcSessionId.split('#')[0];
            return _conversations.find(function (conv) {
                return conv.rtcSessionId === id;
            });
        };

        /**
         * Flags recording within an rtc item of an existing conversation.
         *
         * @param {String} itemId of a conversation item.
         * @param {Boolean} extendedDelay If true, the extended delay will be applied to delete the RTC item by data retention.
         *  If false, the default delay will be applied to delete the RTC item by data retention.
         * @returns {Promise} Promise indicating that the operation has been completed.
         */
        this.setRecordingDeletionDelay = function (itemId, extendedDelay) {
            if (!itemId) {
                return $q.reject('Invalid itemId');
            }

            if (!$rootScope.localUser.recordingDeletionEnabledTenant) {
                return $q.reject('Operation not allowed');
            }

            return new $q(function (resolve, reject) {
                _clientApiHandler.setRecordingDeletionDelay(itemId, extendedDelay, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[ConversationSvc]: Error delaying recording deletion. itemId: ', itemId);
                            reject(err);
                        } else {
                            resolve();
                        }
                    });
                });
            });
        };

        this.addParticipant = function (convId, participant, addToCall, cb) {
            cb && cb('OBSOLETE');
        };

        this.removeParticipant = function (convId, userId, cb) {
            cb && cb('OBSOLETE');
        };

        this.getParticipants = function () {
            return $q.reject('OBSOLETE');
        };

        /**
         * Get a list of conversation ids with call.
         *
         * @returns {String[]} A list of conversation ids.
         */
        this.getConversationIdsWithCall = function () {
            var convIds = [];
            _conversations.forEach(function (c) {
                if (c.call) {
                    convIds.push(c.convId);
                }
            });
            return convIds;
        };

        /**
         * Get telephony conversation promise
         *
         * @returns {Promise}
         */
        this.getTelephonyConversationPromise = function () {
            var deferred = $q.defer();
            if (_conversationsLoadComplete) {
                resolveTelephonyConversationPromise(deferred);
            } else {
                PubSubSvc.subscribeOnce('/conversations/loadComplete', function () {
                    resolveTelephonyConversationPromise(deferred);
                });
            }
            return deferred.promise;
        };

        /**
         * Get telephony conversation
         *
         * @param {Function} cb The callback function invoked when telephony conversation is retrieved from local cache or returned from server.
         */
        this.getTelephonyConversation = function (cb) {
            if (typeof cb !== 'function') {
                return;
            }
            _self.getTelephonyConversationPromise()
            .then(function (conv) {
                cb(null, conv);
            })
            .catch(function (err) {
                cb(err);
            });
        };

        this.getCachedTelephonyConversation = function () {
            if (!_telephonyConvId) {
                return null;
            }
            return _conversationsHashtable[_telephonyConvId] || null;
        };

        /**
         * Validates the given session token and retrieves the associated conversation. If you also want to
         * retrieve the custom acceptance text that must be presented to the user you need to populate the
         * textLanguage parameter with the desired language.
         *
         * @param {String} token The session invite token.
         * @param {Function} cb Callback returning the validation result.
         * @param {String} textLanguage The language of the custom acceptance text that must be presented to the user joining the session.
         */
        this.validateSessionInviteToken = function (token, cb, textLanguage) {
            _clientApiHandler.validateSessionInvite(token, textLanguage, function (err, sessionData) {
                $rootScope.$apply(function () {
                    if (textLanguage && sessionData && sessionData.configurableTexts) {
                        sessionData.acceptanceText = Utils.extractConfigurableText(
                            sessionData.configurableTexts,
                            Constants.TenantConfigurableTextType.GUEST_ACCESS_ACCEPTANCE_TEXT,
                            textLanguage);
                    }
                    cb(err, sessionData);
                });
            });
        };

        /**
         * Validates the given session PIN and retrieves the associated conversation. If you also want to
         * retrieve the custom acceptance text that must be presented to the user you need to populate the
         * textLanguage parameter with the desired language.
         *
         * @param {String} pin The session invite PIN.
         * @param {String} textLanguage The language of the custom acceptance text that must be presented to the user joining the session.
         * @returns {Promise} Validation result
         */
        this.validateSessionInvitePin = function (pin, textLanguage) {
            return new $q(function (resolve, reject) {
                _clientApiHandler.validateSessionPin(pin, textLanguage, function (err, sessionData) {
                    $rootScope.$apply(function () {
                        if (err) {
                            reject(err);
                            return;
                        }
                        if (textLanguage && sessionData && sessionData.configurableTexts) {
                            sessionData.acceptanceText = Utils.extractConfigurableText(
                                sessionData.configurableTexts,
                                Constants.TenantConfigurableTextType.GUEST_ACCESS_ACCEPTANCE_TEXT,
                                textLanguage);
                        }
                        resolve(sessionData);
                    });
                });
            });
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.ConversationSvcImpl = ConversationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
