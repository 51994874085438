var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var logger = circuit.logger;
    var Utils = circuit.Utils;

    // Define common targets for all call types.
    // This object may be extended with additional targets as required by the application.
    var Targets = {
        WebRTC: {name: 'WebRTC', ui: 'res_ProductNameClient', css: 'circuit'},
        Cell: {name: 'Cell', ui: 'res_AlternativeNumber', css: 'mobile'},
        Desk: {name: 'Desk', ui: 'res_Deskphone', css: 'desk'},
        VM: {name: 'VM', ui: 'res_Voicemail', css: 'vm'},
        Other: {name: 'Other', ui: 'res_CallAtOther', css: ''}
    };

    var CstaCallState = Object.freeze({
        Idle: {name: 'Idle', ui: '', established: false},

        // Outgoing call states
        Initiated: {name: 'Initiated', established: false},
        Connecting: {name: 'Connecting', established: false},
        Delivered: {name: 'Delivered', established: false},
        Busy: {name: 'Busy', established: false},
        Offered: {name: 'Offered', established: false},

        // Failed call states
        Failed: {name: 'Failed', established: false},
        TransferFailed: {name: 'TransferFailed', established: false},

        // Incoming call states
        Ringing: {name: 'Ringing', established: false},
        ExtendedRinging: {name: 'ExtendedRinging', established: false},

        // Established call states
        Active: {name: 'Active', established: true},
        Held: {name: 'Held', established: true},
        Holding: {name: 'Holding', established: true},
        HoldOnHold: {name: 'HoldOnHold', established: true},
        Parked: {name: 'Parked', established: true},
        Conference: {name: 'Conference', established: true},
        ConferenceHolding: {name: 'ConferenceHolding', established: true},

        // Call has been terminated
        Terminated: {name: 'Terminated', established: false}
    });

    var BusyHandlingOptions = Object.freeze({
        DefaultRouting: {name: 'DefaultRouting', ui: 'res_BusyHandling_DefaultRouting'},
        BusySignal: {name: 'BusySignal', ui: 'res_BusyHandling_BusySignal'},
        SendToAlternativeNumber: {name: 'SendToAlternativeNumber', ui: 'res_BusyHandling_AlternativeNumber'},
        SendToVM: {name: 'SendToVM', ui: 'res_BusyHandling_Voicemail'}
    });

    var RoutingOptions = Object.freeze({
        DefaultRouting: {
            name: 'DefaultRouting',
            ui: 'res_BusyHandling_DefaultRouting',
            desc: 'res_DefaultRoutingDescription'
        },
        DeskPhone: {
            name: 'DeskPhone',
            ui: 'res_Deskphone',
            desc: 'res_DeskphoneRoutingDescription'
        },
        CircuitClient: {
            name: 'CircuitClient',
            ui: 'res_ProductNameClient',
            desc: 'res_ProductNameClientRoutingDescription'
        },
        AlternativeNumber: {
            name: 'AlternativeNumber',
            ui: 'res_BusyHandling_AlternativeNumber',
            desc: 'res_AlternativeNumberRoutingDescription'
        },
        VM: {
            name: 'VoiceMail',
            ui: 'res_Voicemail',
            desc: 'res_VoicemailRoutingDescription'
        },
        Other: {
            name: 'Other',
            ui: 'res_Other',
            desc: 'res_OtherRoutingDescription'
        }
    });

    var JournalEntryTypes = Object.freeze({
        REGULAR: 'REGULAR',
        MISSED: 'MISSED'
    });

    var MissedReasonTypes = Object.freeze({
        DEFAULT: 'DEFAULT',
        DEST_OUT_OF_ORDER: 'DEST_OUT_OF_ORDER',
        REORDER_TONE: 'REORDER_TONE',
        BUSY: 'BUSY',
        CANCELLED: 'CANCELLED',
        DECLINED: 'DECLINED',
        TRANSFERRED: 'TRANSFERRED'
    });

    var TransferCallFailedCauses = Object.freeze({
        Busy: {name: 'Busy', ui: 'res_TransferCallFailedCauseBusy'},
        Unreachable: {name: 'Unreachable', ui: 'res_TransferCallFailedCauseUnreachable'},
        DND: {name: 'Dnd', ui: 'res_TransferCallFailedCauseDND'}
    });

    var RedirectionTypes = Object.freeze({
        CallForward: {name: 'callForward', ui: 'res_ForwardedFrom'},
        CallPickupNotification: {name: 'callPickupNotification', ui: 'res_CallPickupNotification'},
        CallPickedUp: {name: 'callPickedUp', ui: 'res_CallPickedUp'},
        Dss: {name: 'dss', ui: 'res_CallPickedUp'}
    });

    var AgentState = Object.freeze({
        Ready: 'ready',
        NotReady: 'notReady'
    });

    var CallForwardingTypes = Object.freeze({
        Immediate: {
            name: 'Immediate',
            label: 'res_CallForwarding',
            description: 'res_CallForwardingDescription',
            message: 'res_CallForwardingEnabled'
        },
        VM: {
            name: 'VM',
            label: '',
            description: '',
            message: 'res_VoicemailForwarded'
        },
        NoAnswer: {
            name: 'NoAnswer',
            label: 'res_CallForwardingNoAnswer',
            description: 'res_CallForwardingNoAnswerDescription',
            message: 'res_CallForwardingNoAnswerEnabled'
        }
    });

    function isGNF(number) {
        return Utils.GNF_PHONE_PATTERN.test(number);
    }

    // eslint-disable-next-line max-lines-per-function
    function AtcCallInfo() { // NOSONAR

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        //
        // These variables can only be accessed via getters/setters.
        // Critical variables that cannot be changed directly must be defined here.
        ///////////////////////////////////////////////////////////////////////////////////////
        var _transferCallFailedCause = null;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        this.cstaConn = null;
        this.cstaState = CstaCallState.Idle;
        this.cstaReconnectId = {};
        this.position = Targets.Other;
        this.servicesPermitted = {};
        this.peerFQN = '';
        this.peerDn = '';
        this.peerName = null;
        this.missedReason = '';
        this.ignoreCall = false;
        this.originalPartnerDisplay = {};
        this.handoverInProgress = false;
        this.holdInProgress = false;
        this.retrieveInProgress = false;
        this.outgoingCallCampedOn = false;
        this.redirectingUser = {};
        this.masterParallelHgCall = false;
        this.queuedIncomingCall = false;
        this.recalledCall = false;
        this.osbizCallOnAlternativeNumber = false;

        this.setPeerName = function (name) {
            this.peerName = name || '';
        };

        this.setPartnerDisplay = function (display) {
            if (display) {
                this.peerFQN = display.fqn || '';
                this.peerDn = display.dn || display.fqn || '';
                this.peerName = display.name || this.peerDn;

                if (!this.peerFQN && this.peerDn.startsWith('+')) {
                    this.peerFQN = this.peerDn;
                }
            }
        };

        this.getPartnerDisplay = function () {
            return {
                dn: this.peerDn,
                fqn: this.peerFQN,
                name: this.peerName
            };
        };

        this.setOriginalPartnerDisplay = function (display) {
            if (display && (Utils.isEmptyObject(this.originalPartnerDisplay) || !isGNF(this.originalPartnerDisplay.fqn)
                && isGNF(display.fqn))) {
                // Only update the original partner display if the stored fqn is not GNF and the input fqn is GNF
                this.originalPartnerDisplay = display;
            }
        };

        // CSTA Methods
        this.setCstaConnection = function (conn) {
            this.cstaConn = conn;
            if (this.isRemote) {
                // Update the Call ID as well
                this.callId = conn.cID;
            }
        };

        this.getCstaConnection = function () {
            return this.cstaConn;
        };

        this.setCstaCallId = function (id) {
            if (this.cstaConn) {
                this.cstaConn.cID = id;
            }
        };

        this.getCstaCallId = function () {
            return this.cstaConn && this.cstaConn.cID;
        };

        this.getCstaDeviceId = function () {
            return this.cstaConn && this.cstaConn.dID;
        };

        this.setCstaReconnectId = function (reconnectId) {
            this.cstaReconnectId = reconnectId;
        };

        this.getCstaReconnectId = function () {
            return this.cstaReconnectId;
        };


        this.setOsbizCallOnAlternativeNumber = function () {
            this.osbizCallOnAlternativeNumber = true;
        };

        this.isOsbizCallOnAlternativeNumber = function () {
            return this.osbizCallOnAlternativeNumber;
        };

        this.setServicesPermitted = function (svcsPermitted) {
            // If no new SP are reported in event then client must maintain old ones
            if (!svcsPermitted || Utils.isEmptyObject(svcsPermitted)) {
                logger.info('[AtcCallInfo]: Empty services permitted, keeping last values');
                return;
            }
            if (svcsPermitted.rSP && svcsPermitted.rSP.cCSs !== undefined) {
                this.servicesPermitted.rSP = svcsPermitted.rSP;
            }
            if (svcsPermitted.eSP !== undefined) {
                this.servicesPermitted.eSP = svcsPermitted.eSP;
            }
            logger.info('[AtcCallInfo]: Updated services permitted: ', this.servicesPermitted);
        };

        this.clearServicesPermitted = function () {
            this.servicesPermitted = {};
        };

        this.getServicesPermitted = function () { return this.servicesPermitted; };

        this.isHandoverAllowed = function () {
            return (this.isSilentHandoverAllowed() || this.isSeamlessHandoverAllowed()) && !this.isHandoverInProgress() && !this.isOsbizCallOnAlternativeNumber();
        };

        this.isSeamlessHandoverAllowed = function () {
            return !!(this.servicesPermitted.eSP && this.servicesPermitted.eSP.zseHo) || !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.zseHo);
        };

        this.isSilentHandoverAllowed = function () {
            return !!(this.servicesPermitted.eSP && this.servicesPermitted.eSP.zsiHo);
        };

        this.isTransferAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.sST && !this.isHandoverInProgress());
        };

        this.isAlternateAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.alC);
        };

        this.isAnswerAllowed = function () {
            if (this.servicesPermitted.rSP) {
                return !!this.servicesPermitted.rSP.cCSs.anC;
            }
            return this.cstaState === CstaCallState.Ringing;
        };

        this.isCallBackAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.cB);
        };

        this.isConsultAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.csC);
        };

        this.isDeflectAllowed = function () {
            if (this.cstaState === CstaCallState.Parked || this.cstaState === CstaCallState.Ringing) {
                return true;
            }
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.dCl);
        };

        this.isGenerateDigitsAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cAS.gD);
        };

        this.isHoldAllowed = function () {
            if (this.isHandoverInProgress()) {
                return false;
            }
            if (this.servicesPermitted.rSP) {
                return !!this.servicesPermitted.rSP.cCSs.hCl;
            }
            switch (this.cstaState) {
            case CstaCallState.Active:
            case CstaCallState.Conference:
            case CstaCallState.Held:
                return true;

            default:
                return false;
            }
        };

        this.isConferenceCall = function () {
            return this.cstaState === CstaCallState.Conference || this.cstaState === CstaCallState.ConferenceHolding;
        };

        this.isMakeCallAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.mCl);
        };

        this.isReconnectAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.rC);
        };

        this.isRetrieveAllowed = function () {
            if (this.isHandoverInProgress()) {
                return false;
            }
            if (this.servicesPermitted.rSP) {
                return this.servicesPermitted.rSP.cCSs.reC;
            }
            switch (this.cstaState) {
            case CstaCallState.ConferenceHolding:
            case CstaCallState.Holding:
            case CstaCallState.HoldOnHold:
                return true;

            default:
                return false;
            }
        };

        this.isTransferCallAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.tCl && !this.isHandoverInProgress());
        };

        this.isConferenceCallAllowed = function () {
            return !!(this.servicesPermitted.rSP && this.servicesPermitted.rSP.cCSs.coC);
        };

        this.getPosition = function () { return this.position; };

        this.setPosition = function (p) {
            if (!p || p === this.position) {
                return;
            }
            this.position = p;
            logger.debug('[AtcCallInfo]: Set call position to ', p.name);
        };

        this.isAtPosition = function (p) {
            return this.position === p;
        };

        this.getMissedReason = function () { return this.missedReason; };

        this.setMissedReason = function (missedReason) {
            if (!missedReason || missedReason === this.missedReason) {
                return;
            }
            this.missedReason = missedReason;
            logger.debug('[AtcCallInfo]: Set missedReason to ', missedReason);
        };

        this.isQueuedIncomingCall = function () {
            return this.queuedIncomingCall;
        };

        this.setQueuedIncomingCall = function (queued) {
            this.queuedIncomingCall = queued;
        };

        this.isRecalledCall = function () {
            return this.recalledCall;
        };

        this.setRecalledCall = function (recalled) {
            this.recalledCall = recalled;
        };

        this.getIgnoreCall = function () { return this.ignoreCall || this.cstaState === CstaCallState.Offered; };

        this.setIgnoreCall = function (flag) {
            this.ignoreCall = flag;
        };

        this.isHandoverInProgress = function () {
            return this.handoverInProgress;
        };

        this.setHandoverInProgress = function () {
            this.handoverInProgress = true;
        };

        this.clearHandoverInProgress = function () {
            this.handoverInProgress = false;
        };

        this.isHoldInProgress = function () {
            return this.holdInProgress;
        };

        this.setHoldInProgress = function () {
            this.holdInProgress = true;
        };

        this.clearHoldInProgress = function () {
            this.holdInProgress = false;
        };

        this.isRetrieveInProgress = function () {
            return this.retrieveInProgress;
        };

        this.setRetrieveInProgress = function () {
            this.retrieveInProgress = true;
        };

        this.clearRetrieveInProgress = function () {
            this.retrieveInProgress = false;
        };


        this.isOutgoingCallCampedOn = function () {
            return this.outgoingCallCampedOn;
        };

        this.setOutgoingCallCampedOn = function () {
            this.outgoingCallCampedOn = true;
        };

        this.clearOutgoingCallCampedOn = function () {
            this.outgoingCallCampedOn = false;
        };

        this.setTransferCallFailedCause = function (cause) {
            if (this.cstaState === CstaCallState.TransferFailed) {
                switch (cause) {
                case 'busy':
                    _transferCallFailedCause = TransferCallFailedCauses.Busy;
                    break;
                case 'doNotDisturb':
                    _transferCallFailedCause = TransferCallFailedCauses.DND;
                    break;
                default:
                    _transferCallFailedCause = TransferCallFailedCauses.Unreachable;
                    break;
                }
            }
        };

        this.getTransferCallFailedCause = function () {
            if (this.cstaState === CstaCallState.TransferFailed) {
                return _transferCallFailedCause || TransferCallFailedCauses.Unreachable;
            }
            return null;
        };

        this.setRedirectingUser = function (phoneNumber, fqNumber, displayName, userId, redirectionType) {
            // Used for telephony calls
            redirectionType = redirectionType || this.redirectingUser.redirectionType;
            if (!this.redirectingUser || !this.redirectingUser.fqNumber || this.redirectingUser.fqNumber === fqNumber) {
                this.redirectingUser = {
                    userId: userId || '',
                    displayName: displayName,
                    phoneNumber: phoneNumber,
                    fqNumber: fqNumber,
                    redirectionType: redirectionType
                };
            }
        };

        this.getRedirectingUser = function () {
            return this.redirectingUser;
        };

        this.setRedirectionType = function (type) {
            this.redirectingUser.redirectionType = type;
        };

        this.getRedirectionType = function () {
            return this.redirectingUser && this.redirectingUser.redirectionType;
        };

        this.isForwarded = function () {
            return !!this.redirectingUser && this.redirectingUser.redirectionType === RedirectionTypes.CallForward;
        };

        this.isPickupNotification = function () {
            return !!this.redirectingUser && this.redirectingUser.redirectionType === RedirectionTypes.CallPickupNotification;
        };

        this.isPickedUp = function () {
            return !!this.redirectingUser && this.redirectingUser.redirectionType === RedirectionTypes.CallPickedUp;
        };
    }

    // Exports
    circuit.AtcCallInfo = AtcCallInfo;
    circuit.BusyHandlingOptions = BusyHandlingOptions;
    circuit.RoutingOptions = RoutingOptions;

    circuit.Enums = circuit.Enums || {};
    circuit.Enums.AgentState = AgentState;
    circuit.Enums.CallForwardingTypes = CallForwardingTypes;
    circuit.Enums.CstaCallState = CstaCallState;
    circuit.Enums.Targets = Targets;
    circuit.Enums.JournalEntryTypes = JournalEntryTypes;
    circuit.Enums.MissedReasonTypes = MissedReasonTypes;
    circuit.Enums.RedirectionTypes = RedirectionTypes;
    circuit.Enums.TransferCallFailedCauses = TransferCallFailedCauses;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define

