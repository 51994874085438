/*global __clientVersion*/

///////////////////////////////////////////////////////////////////////////////
//--- Handles Connection to Access Server (inherits from BaseEventTarget) ---
///////////////////////////////////////////////////////////////////////////////

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var BaseEventTarget = circuit.BaseEventTarget;
    var ConnectionState = circuit.Enums.ConnectionState;
    var Constants = circuit.Constants;
    var Proto = circuit.Proto;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-lines-per-function
    function ConnectionHandler(config) { // NOSONAR
        var logger = circuit.logger;
        config = config || {};

        // Call the base constructor
        ConnectionHandler.parent.constructor.call(this, logger);

        ///////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////
        var RESPONSE_TIMEOUT = 60000; // increased to 60s to conform real backend
        var PING_TIMEOUT = 10000;
        var DEFAULT_PING_INTERVAL = 300000; // 5 minutes
        var MIN_PING_INTERVAL = 30000; // 30 seconds

        // Delayed applied to ensure that client will process response prior to event
        // This is a workaround solution to address the issue that in most scenarios
        // the backend sends the event before the response.
        var EVENT_DELAY = 500;

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _that = this;
        var _evtCallbacks = {};
        var _reqCallbacks = {};
        var _delayedEvents = [];

        var _url = '/api';
        var _server = window.location.host;
        var _proxyServer = null; // Reverse proxy server for WebSocket connection
        var _accessToken = null;
        var _clientInfo = null;
        var _validDeviceTypes = Object.keys(Constants.DeviceType);
        var _clientInfoTemplate = {
            deviceType: true,
            deviceSubtype: true,
            manufacturer: true,
            osVersion: true,
            clientVersion: true,
            hardwareModel: true,
            capabilities: true,
            client_id: true // The client ID assigned to the SDK application
        };
        var _clientApiVersion = 0;

        // Disable ping for mobile clients.
        var _pingIntervalTime = config.disablePing || Utils.isMobile() ? -1 : DEFAULT_PING_INTERVAL;
        var _pingInterval = null;
        var _pingInProgress = false;


        // Use the circuit reference here instead of defining it in the imports so
        // that the Unit tests can mock the Connectioncontroller
        var _connCtrl = new circuit.ConnectionController(config);

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function getUrl(useProxy) {
            var obj = {};
            if (_accessToken) {
                obj.accessToken = _accessToken;
            }
            if (_clientInfo) {
                Object.assign(obj, _clientInfo);
            } else if (typeof __clientVersion !== 'undefined') {
                obj.clientVersion = __clientVersion;
            }
            if (useProxy && !_proxyServer) {
                return null;
            }
            var server = useProxy ? _proxyServer : _server;
            return 'wss://' + server + _url + (Object.keys(obj).length ? '?' + Utils.toQS(obj) : '');
        }

        function addReqCallback(requestId, cb, keysToOmitFromResponse, timeout) {
            if (!cb) { return; }

            var responseTimer = window.setTimeout(function (reqId) {
                logger.error('[ConnectionHandler]: Timeout waiting for response. RequestId:', reqId);
                if (_reqCallbacks[reqId]) {
                    delete _reqCallbacks[reqId];
                    cb(Constants.ReturnCode.REQUEST_TIMEOUT);

                    var numPending = Object.keys(_reqCallbacks).length;
                    logger.debug('[ConnectionHandler]: Remaining callbacks pending: ', numPending);
                    if (numPending === 0) {
                        raiseDelayedEvents();
                    }

                    // Check if websocket connection is still up
                    _that.pingServer();
                }
            }, timeout, requestId);

            if (!Array.isArray(keysToOmitFromResponse)) {
                keysToOmitFromResponse = null;
            }

            _reqCallbacks[requestId] = {
                cb: cb,
                timer: responseTimer,
                keysToOmitFromResponse: keysToOmitFromResponse
            };
        }

        function getCallback(msg) {
            var cbInfo;
            switch (msg.msgType) {
            case Constants.WSMessageType.RESPONSE:
                if (!msg.response || !msg.response.code) {
                    return null;
                }
                if (msg.response.user) {
                    var localUser;
                    if (msg.response.user.type === 'GET_STUFF') {
                        localUser = msg.response.user.getStuff.user;
                    } else if (msg.response.user.type === 'GET_LOGGED_ON') {
                        localUser = msg.response.user.getLoggedOn.user;
                    }
                    if (localUser) {
                        localUser.clientId = msg.clientId;
                        localUser.apiVersion = msg.apiVersion;
                    }
                }
                if (_reqCallbacks[msg.response.requestId]) {
                    cbInfo = _reqCallbacks[msg.response.requestId];
                    delete _reqCallbacks[msg.response.requestId];

                    if (cbInfo.timer) {
                        window.clearTimeout(cbInfo.timer);
                    }
                    if (cbInfo.keysToOmitFromResponse) {
                        msg.keysToOmitFromLogging = cbInfo.keysToOmitFromResponse;
                    }
                    return {
                        type: 'ack',
                        cb: cbInfo.cb,
                        data: msg.response
                    };
                }
                logger.debug('[ConnectionHandler]: No callback for response with requestId=', msg.response.requestId);
                break;

            case Constants.WSMessageType.EVENT:
                var evt = Proto.ParseEvent(msg.event, msg.apiVersion);
                if (!evt || !evt.name) {
                    logger.error('[ConnectionHandler]: Failed to parse event: ', msg.event);
                } else if (_evtCallbacks[evt.name]) {
                    cbInfo = _evtCallbacks[evt.name];
                    logger.debug('[ConnectionHandler]: Parsed event: ', evt.name);

                    if (_that.ommitKeysFromLog && cbInfo.keysToOmitFromEvent) {
                        msg.keysToOmitFromLogging = cbInfo.keysToOmitFromEvent;
                    }
                    return {
                        type: 'evt',
                        cbList: cbInfo.cbList,
                        name: evt.name,
                        data: evt.data,
                        suppressLog: cbInfo.suppressLog
                    };
                } else {
                    logger.debug('[ConnectionHandler]: No registered callback for event ', evt.name);
                }
                break;

            default:
                logger.error('[ConnectionHandler]: Unexpected message type: ', msg.msgType);
                break;
            }
            return null;
        }

        function raiseEvents(cbInfo) {
            logger.debug('[ConnectionHandler]: Raising event', cbInfo.name);
            cbInfo.cbList.forEach(function (cb) {
                try {
                    cb(cbInfo.data);
                } catch (e) {
                    logger.error('[ConnectionHandler]: Error raising event. ', e);
                }
            });
        }

        function raiseDelayedEvents() {
            if (_delayedEvents.length > 0) {
                logger.debug('[ConnectionHandler]: Raise all delayed events');
                _delayedEvents.forEach(function (cbInfo) {
                    window.clearTimeout(cbInfo.timeoutId);
                    raiseEvents(cbInfo);
                });
                _delayedEvents = [];
            }
        }

        ///////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////
        function onStateChange(evt) {
            logger.debug('[ConnectionHandler]: WebSocket connection state changed to ', evt.newState);
            logger.debug('[ConnectionHandler]: Raising connectionStateChange event');

            if (evt.newState === ConnectionState.Connected) {
                _clientApiVersion = 0;
                if (!_pingInterval && _pingIntervalTime > 0) {
                    // Ping the server every interval time to detect network errors
                    _pingInterval = window.setInterval(function () {
                        _that.pingServer();
                    }, _pingIntervalTime);
                }
            } else {
                if (_pingInterval) {
                    window.clearInterval(_pingInterval);
                    _pingInterval = null;
                }
                // Whenever we lose connection, all pending requests will fail (since we're getting
                // a new clientId). So fail all of them immediately (don't let them timeout).
                if (Object.keys(_reqCallbacks).length) {
                    // Cleaning must be done only for pending callbacks, as _reqCallbacks will shortly store new callbacks.
                    var pendingCallbacks = _reqCallbacks;
                    _reqCallbacks = {};     // ready for new callbacks
                    window.setTimeout(function () {
                        Object.keys(pendingCallbacks).forEach(function (reqId) {
                            var cbInfo = pendingCallbacks[reqId];
                            if (cbInfo.timer) {
                                window.clearTimeout(cbInfo.timer);
                            }
                            cbInfo.cb(Constants.ReturnCode.DISCONNECTED);
                        });
                        raiseDelayedEvents();
                    }, 0);
                }
            }

            // Dispatch a 'connectionStateChange' event
            var newEvt = _that.createEvent('connectionStateChange');
            newEvt.newState = evt.newState;
            newEvt.oldState = evt.oldState;
            _that.dispatch(newEvt);
        }
        _connCtrl.onStateChange = onStateChange;

        function onReconnectFailed() {
            logger.debug('[ConnectionHandler]: WebSocket failed to reconnect. Raising reconnectFailed event');

            // Dispatch a 'reconnectFailed' event
            var newEvt = _that.createEvent('reconnectFailed');
            _that.dispatch(newEvt);
        }
        _connCtrl.onReconnectFailed = onReconnectFailed;

        // Receive Client API Messages from Access Server
        function onMessage(msg) {
            if (!_clientApiVersion && msg.apiVersion) {
                _clientApiVersion = Utils.convertVersionToNumber(msg.apiVersion);
            }

            // First check if there are registered callbacks
            var cbInfo = getCallback(msg);
            if (!cbInfo) {
                return;
            }
            if (!cbInfo.suppressLog) {
                logger.msgRcvd('[ConnectionHandler]: ', msg);
            }
            if (cbInfo.type === 'evt') {
                if (Object.keys(_reqCallbacks).length > 0 && /^(?:User|Conversation|Search|Space)\./.test(cbInfo.name)) {
                    // The client is waiting for a response. Delay the event handling.
                    logger.debug('[ConnectionHandler]: Delay event:', cbInfo.name);
                    cbInfo.timeoutId = window.setTimeout(function () {
                        raiseEvents(cbInfo);
                        var idx = _delayedEvents.indexOf(cbInfo);
                        if (idx !== -1) {
                            _delayedEvents.splice(idx, 1);
                        }
                    }, EVENT_DELAY);
                    // Add cbInfo to delayed events list
                    _delayedEvents.push(cbInfo);
                } else {
                    // There are no pending requests. Raise the event immediately.
                    raiseEvents(cbInfo);
                }
            } else {
                var numPending = Object.keys(_reqCallbacks).length;
                logger.debug('[ConnectionHandler]: Remaining callbacks pending: ', numPending);

                // Invoke the callback function (error is always the 1st parameter)
                cbInfo.cb(null, cbInfo.data);

                if (numPending === 0) {
                    // If there were no pending callbacks BEFORE the response was processed,
                    // we can raise the delayed events.
                    raiseDelayedEvents();
                }
            }
        }
        _connCtrl.onMessage = onMessage;

        /////////////////////////////////////////////////////////////////////////////
        // Public interfaces
        /////////////////////////////////////////////////////////////////////////////
        this.ommitKeysFromLog = true;

        // Expose onMessage to allow simulating incoming messages
        this.onMessage = onMessage;

        this.setRelativeUrl = function (url) {
            _url = url;
            logger.debug('[ConnectionHandler]: Set relative URL to ', _url);
        };

        this.setTarget = function (server) {
            // Used by Chrome App & mobile App to set the websocket target
            _server = server || window.location.host;
            logger.info('[ConnectionHandler]: Set target to ', _server);
            if (_server === _proxyServer) {
                _proxyServer = null;
                logger.info('[ConnectionHandler]: Set reverse proxy target to null');
            }
        };

        this.setProxyTarget = function (server) {
            // Used in case client is supposed to open the websocket via a reverse proxy
            // (e.g. clients in China at SAG)
            if (server === _server) {
                _proxyServer = null;
            } else {
                _proxyServer = server || null;
            }
            logger.info('[ConnectionHandler]: Set reverse proxy target to ', _proxyServer || 'null');
        };

        this.setClientInfo = function (clientInfo) {
            // Used by Circuit Meeting Room and other apps to set the client info
            if (clientInfo) {
                if (!clientInfo.deviceSubtype || _validDeviceTypes.indexOf(clientInfo.deviceType) === -1) {
                    logger.warn('[ConnectionHandler]: Attempted to set invalid clientInfo: ', clientInfo);
                    return;
                }

                _clientInfo = {};
                // Copy only valid fields
                Object.keys(clientInfo).forEach(function (key) {
                    if (_clientInfoTemplate[key]) {
                        _clientInfo[key] = clientInfo[key];
                    }
                });
                logger.debug('[ConnectionHandler]: Set clientInfo to ', _clientInfo);
            } else {
                logger.debug('[ConnectionHandler]: Clear clientInfo');
                _clientInfo = null;
            }
        };

        this.setToken = function (token) {
            // Used by SDK
            _accessToken = token;
        };

        this.getUrl = getUrl;

        this.getState = function () {
            return _connCtrl.getState();
        };

        this.connect = function (successCallback, errorCallback) {
            logger.debug('[ConnectionHandler]: Connect WebSocket to Access Server');
            _connCtrl.connect(getUrl(false), getUrl(true), successCallback, errorCallback);
        };

        this.disconnect = function () {
            logger.debug('[ConnectionHandler]: Disconnect WebSocket from Access Server');
            _connCtrl.disconnect();
        };


        this.reconnect = function (delay) {
            logger.debug('[ConnectionHandler]: Reconnect WebSocket to Access Server.');
            _connCtrl.reconnect(getUrl(false), getUrl(true), delay);
        };

        this.sendMessage = function (data, cb, keysToOmitFromResponse, timeout) {
            if (!data || !data.request || !data.request.requestId) {
                logger.error('[ConnectionHandler]: Cannot send message. Invalid message: ', data);
                cb && window.setTimeout(function () { cb(Constants.ReturnCode.INVALID_MESSAGE); }, 0);
                return false;
            }

            logger.debug('[ConnectionHandler]: Sending ' + data.request.type + ' request');

            if (!_connCtrl.sendMessage(data)) {
                logger.error('[ConnectionHandler]: Failed to send message');
                cb && window.setTimeout(function () { cb(Constants.ReturnCode.FAILED_TO_SEND); }, 0);
                return false;
            }

            // Callback must be added in the callback array only if message is actually sent through the connection, not before
            cb && addReqCallback(data.request.requestId, cb, keysToOmitFromResponse, timeout || RESPONSE_TIMEOUT);
            return true;
        };

        this.pingServer = function () {
            if (!_connCtrl.isConnected()) {
                return;
            }
            if (_pingInProgress) {
                // Don't send multiple pings to the server
                return;
            }

            var msg = 'PING';
            var reqId;
            if (!Utils.isMobile()) {
                Proto.requestNr++;
                reqId = Proto.requestNr;
                msg += '|' + reqId;
            }

            _connCtrl.sendMessage(msg);

            if (reqId) {
                _pingInProgress = true;

                // Register callback and set timeout specific for PING messages
                var responseTimer = window.setTimeout(function () {
                    _pingInProgress = false;
                    logger.error('[ConnectionHandler]: Timed out waiting for ping response. RequestId:', reqId);
                    delete _reqCallbacks[reqId];
                    _connCtrl.reconnect(getUrl(false), getUrl(true));
                }, PING_TIMEOUT);

                _reqCallbacks[reqId] = {
                    cb: function () {
                        _pingInProgress = false;
                    },
                    timer: responseTimer
                };
            }
        };

        this.setPingInterval = function (interval) {
            if (typeof interval !== 'number') {
                return;
            }
            // Normalize the interval time
            interval = interval < 0 ? -1 : Math.max(Math.floor(interval), MIN_PING_INTERVAL);

            if (_pingIntervalTime === interval) {
                // No changes
                return;
            }
            _pingIntervalTime = interval;

            if (_pingInterval) {
                window.clearInterval(_pingInterval);
                _pingInterval = null;
            }

            if (_pingIntervalTime < 0) {
                logger.info('[ConnectionHandler]: Disable ping');
            } else {
                logger.info('[ConnectionHandler]: Set ping interval time to ', _pingIntervalTime);
                if (_connCtrl.isConnected()) {
                    logger.info('[ConnectionHandler]: Restart ping interval');
                    _pingInterval = window.setInterval(function () {
                        _that.pingServer();
                    }, _pingIntervalTime);
                }
            }
        };

        this.on = function (msgType, cb, keysToOmitFromEvent) {
            if (!msgType || !cb) {
                return;
            }

            var cbInfo = _evtCallbacks[msgType] || {cbList: []};
            cbInfo.cbList.push(cb);
            if (keysToOmitFromEvent) {
                if (Array.isArray(keysToOmitFromEvent)) {
                    cbInfo.keysToOmitFromEvent = keysToOmitFromEvent;
                } else if (keysToOmitFromEvent === '*') {
                    cbInfo.suppressLog = true;
                }
            }
            _evtCallbacks[msgType] = cbInfo;
        };

        this.unsubscribeAll = function () {
            _evtCallbacks = {};
            _reqCallbacks = {};
            _delayedEvents = [];
            _that.removeAllListeners();
        };

        this.injectHasActiveCall = _connCtrl.injectHasActiveCall.bind(_connCtrl);
    }

    Utils.inherit(ConnectionHandler, BaseEventTarget);
    ConnectionHandler.prototype.name = 'ConnectionHandler';

    // Exports
    circuit.ConnectionHandler = ConnectionHandler;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
