var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ChromeExtension = circuit.ChromeExtension;
    var RtcSessionController = circuit.RtcSessionController;

    var IPHandlingPolicy = {
        DEFAULT: 'default',
        PUBLIC_PRIVATE: 'default_public_and_private_interfaces',
        PUBLIC_ONLY: 'default_public_interface_only',
        FORCE_PROXY: 'disable_non_proxied_udp'
    };

    // eslint-disable-next-line max-params, max-lines-per-function
    function RtcSvcImpl($rootScope, $q, LogSvc, PubSubSvc, PopupSvc, ExtensionSvc, CircuitCallControlSvc) { // NOSONAR
        LogSvc.debug('New Service: RtcSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _self = this;

        // The active webRTCIPHandlingPolicy value
        var _webRTCIPHandlingPolicy = null;
        var _ipHandlingPolicyPermissionGranted = false;

        var _ipHandlingPoliciesInfo = Object.keys(IPHandlingPolicy).map(function (key) {
            return {
                value: IPHandlingPolicy[key],
                title: 'res_IPPolicy_' + key + '_Title',
                description: 'res_IPPolicy_' + key + '_Description'
            };
        });

        var _validPolicies = Object.values(IPHandlingPolicy);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function convertCandidatesToText(data) {
            var map = $rootScope.i18n.map;
            if (!map) {
                return '';
            }

            var orderedCandidates = {
                turn: data.turnServers || [],
                host: [],
                srflx: [],
                relayudp: [],
                relaytcp: [],
                relaytls: []
            };

            data.candidates && data.candidates.forEach(function (candidate) {
                switch (candidate.typ) {
                case 'host':
                case 'srflx':
                    orderedCandidates[candidate.typ].push(candidate.getValue());
                    break;
                case 'relay':
                    var relayType = candidate.getRelayClientTransportType();
                    relayType && orderedCandidates['relay' + relayType].push(candidate.getValue());
                    break;
                default:
                    break; // Not expected
                }
            });

            var text = '';
            if (_webRTCIPHandlingPolicy) {
                var info = _ipHandlingPoliciesInfo.find(function (i) {
                    return i.value === _webRTCIPHandlingPolicy;
                });
                if (info) {
                    text += map.res_IPPolicyTitle + ':\r\n   ' + map[info.title] + '\r\n\r\n';
                }
            }

            var processCandidates = function (candidates) {
                if (candidates.length > 0) {
                    candidates.forEach(function (candidate) {
                        text += '   ' + candidate + '\r\n';
                    });
                } else {
                    text += '   ' + $rootScope.i18n.map.res_None + '\r\n';
                }
            };

            Object.keys(orderedCandidates).forEach(function (key, idx) {
                if (idx > 0) {
                    text += '\r\n';
                }
                text += $rootScope.i18n.map['res_CandidatesTest_' + key] + '\r\n';
                processCandidates(orderedCandidates[key]);
            });
            return text;
        }

        function getErrorResource(err) {
            switch (err) {
            case ChromeExtension.BgPrivacySettingsError.NOT_AUTHORIZED:
                // Extension has not been authorized to control policy settings
                return 'res_PrivacySettingError_NotAuthorized';

            case ChromeExtension.BgPrivacySettingsError.NOT_CONTROLLABLE:
                // Setting is locked by enterprise policies
                return 'res_PrivacySettingError_NotControllable';

            case ChromeExtension.BgPrivacySettingsError.CONTROLLED_BY_OTHER_EXTENSIONS:
                // Setting is being controlled by another extension
                return 'res_PrivacySettingError_ControlledByOther';

            case ChromeExtension.ResponseError.UNSUPPORTED_REQUEST:
                // Extension doesn't support this request
                return 'res_PrivacySettingError_UnsupportedRequest';

            default:
                return 'res_PrivacySettingError_Other';
            }

        }

        function supportsIPHandlingPolicy() {
            // Check if client supports changing routing policy and if tenant admin allowed the users to change it.
            return !!($rootScope.browser.chrome && $rootScope.localUser.userControlledWebrtcPolicyEnabled);
        }

        function checkPermission() {
            if (_ipHandlingPolicyPermissionGranted && $rootScope.localUser && !$rootScope.localUser.userControlledWebrtcPolicyEnabled &&
                _webRTCIPHandlingPolicy && _webRTCIPHandlingPolicy !== IPHandlingPolicy.DEFAULT) {
                // If tenant settings for userControlledWebrtcPolicy is disabled and
                // current IPHandlingPolicy is not default, reset it back to default.
                LogSvc.info('[RtcSvc]: User is not allowed to set IP handling policy. Reset back to default.');
                ExtensionSvc.setIPHandlingPolicy(IPHandlingPolicy.DEFAULT, null, function (err) {
                    if (err) {
                        LogSvc.error('[RtcSvc]: Failed to set IP handling policy. ', err);
                    } else {
                        LogSvc.info('[RtcSvc]: Successfully set IP handling policy to default');
                        _webRTCIPHandlingPolicy = IPHandlingPolicy.DEFAULT;
                        RtcSessionController.webRTCIPHandlingPolicy = _webRTCIPHandlingPolicy;
                    }
                });
            }
        }

        function getIPHandlingPolicy() {
            // Try to retrieve the current IP handling policy even if user is not allowed to change it
            if ($rootScope.browser.chrome) {
                LogSvc.debug('[RtcSvc]: Get IP handling policy value');
                ExtensionSvc.getIPHandlingPolicy(function (err, data) {
                    if (err || !data) {
                        LogSvc.error('[RtcSvc]: Failed to get IP handling policy. ', err);
                        if (err === ChromeExtension.BgPrivacySettingsError.NOT_AUTHORIZED) {
                            _ipHandlingPolicyPermissionGranted = false;
                        }
                    } else {
                        LogSvc.info('[RtcSvc]: Retrieved IP handling policy details = ', data);
                        _webRTCIPHandlingPolicy = data.value || null;
                        RtcSessionController.webRTCIPHandlingPolicy = _webRTCIPHandlingPolicy;
                        _ipHandlingPolicyPermissionGranted = true;
                        checkPermission();
                    }
                });
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/chromeExt/initialized', function () {
            LogSvc.debug('[RtcSvc]: Received /chromeExt/initialized event');
            getIPHandlingPolicy();
        });

        PubSubSvc.subscribe('/chromeExt/unregistered', function () {
            LogSvc.debug('[RtcSvc]: Received /chromeExt/unregistered event');
            _webRTCIPHandlingPolicy = null;
            _ipHandlingPolicyPermissionGranted = false;
            RtcSessionController.webRTCIPHandlingPolicy = null;
        });

        PubSubSvc.subscribe('/chromeExt/webRTCIPHandlingPolicyChanged', function (data) {
            LogSvc.debug('[RtcSvc]: Received /chromeExt/webRTCIPHandlingPolicyChanged event. detail = ', data);
            _webRTCIPHandlingPolicy = data.value;
            RtcSessionController.webRTCIPHandlingPolicy = _webRTCIPHandlingPolicy;
        });

        PubSubSvc.subscribe('/localUser/tenantSettingsUpdate', function () {
            checkPermission();
        });

        PubSubSvc.subscribe('/shortcut/testCandidatesCollection', function () {
            LogSvc.debug('[RtcSvc]: Received /shortcut/testCandidatesCollection event');
            _self.openCandidatesCollectionModal();
        });

        PubSubSvc.subscribe('/registration/stuff', function () {
            checkPermission();
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        Object.defineProperties(this, {
            webRTCIPHandlingPolicy: {
                enumerable: true,
                get: function () {
                    return _webRTCIPHandlingPolicy;
                }
            },
            ipHandlingPoliciesInfo: {
                enumerable: true,
                get: function () {
                    return _ipHandlingPoliciesInfo;
                }
            },
            ipHandlingPoliciesPermissionGranted: {
                enumerable: true,
                get: function () {
                    return _ipHandlingPolicyPermissionGranted;
                }
            }
        });

        this.supportsIPHandlingPolicy = supportsIPHandlingPolicy;

        this.selectIPHandlingPolicy = function (policy) {
            return new $q(function (resolve, reject) {
                if (!supportsIPHandlingPolicy()) {
                    reject('Not supported');
                    return;
                }

                if (!_validPolicies.includes(policy)) {
                    reject('Invalid policy');
                    return;
                }

                if (!ExtensionSvc.isExtensionRunning()) {
                    reject('res_PrivacySettingError_NotRunning');
                    return;
                }

                LogSvc.info('[RtcSvc]: Set IP handling policy to ', policy);

                // Pass all the strings so the Extension can show a popup asking the user
                // for the 'privacy' permission (if needed).
                var localizedStrings = {
                    res_Yes: $rootScope.i18n.map.res_Proceed,
                    res_No: $rootScope.i18n.map.res_Cancel,
                    title: $rootScope.i18n.map.res_ExtensionAddPrivacyPermissionTitle,
                    message: $rootScope.i18n.map.res_ExtensionAddPrivacyPermissionMessage
                };

                ExtensionSvc.setIPHandlingPolicy(policy, localizedStrings, function (err) {
                    if (err) {
                        LogSvc.error('[RtcSvc]: Failed to set IP handling policy. ', err);
                        reject(getErrorResource(err));
                    } else {
                        LogSvc.info('[RtcSvc]: Successfully set IP handling policy to ', policy);
                        _ipHandlingPolicyPermissionGranted = true;
                        _webRTCIPHandlingPolicy = policy;
                        RtcSessionController.webRTCIPHandlingPolicy = _webRTCIPHandlingPolicy;
                        resolve();
                    }
                });
            });
        };

        this.openCandidatesCollectionModal = function () {
            return CircuitCallControlSvc.testCandidatesCollection(true)
                .then(function (data) {
                    var displayedData = convertCandidatesToText(data);
                    PopupSvc.openCustomModal({
                        templateUrl: 'views/modals/genericCopyToClipboard.html',
                        windowClass: 'copy-details',
                        controller: 'GenericCopyToClipboardCtrl',
                        backdrop: true,
                        ariaData: {ariaLabelledBy: null},  // Only populate aria-labelledby
                        resolve: {
                            modalResolve: function () {
                                return {
                                    displayedData: displayedData,
                                    modalType: 'ICECandidates'
                                };
                            }
                        }
                    })
                    .result
                    .catch(function () {});
                })
                .catch(function (err) {
                    LogSvc.error('[RtcSvc]: Candidate collection test failed. ', err);
                    PopupSvc.error({message: 'res_ICECandidatesTestError'});
                });
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.RtcSvcImpl = RtcSvcImpl;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.IPHandlingPolicy = IPHandlingPolicy;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
