var Circuit = (function (circuit) {
    'use strict';

    /*
     * ATC registration state
     * @readonly
     * @enum {String}
     * @property Disconnected - Client is not associated with ATC
     * @property Unregistered - Client is not registered with ATC
     * @property Registering - Client is registering with ATC
     * @property Registered - Client is registered with ATC
     * @property ForcedUnregistered - Client is unregistered by ATC
     */
    var AtcRegistrationState = Object.freeze({
        Disconnected: 'Disconnected',
        Unregistered: 'Unregistered',
        Registering: 'Registering',
        Registered: 'Registered',
        ForcedUnregistered: 'ForcedUnregistered'
    });

    // Exports
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.AtcRegistrationState = AtcRegistrationState;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define

