var Circuit = (function (circuit) {
    'use strict';

    circuit.UserToUserHandlerSingleton = (function () {
        var _userToUserHandler;
        return {
            getInstance: function () {
                if (!_userToUserHandler) {
                    _userToUserHandler = new circuit.UserToUserHandler();
                }
                return _userToUserHandler;
            }
        };
    })();

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
