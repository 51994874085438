// Define external globals for JSHint
/*global module*/

var Circuit = (function (circuit) {
    'use strict';

    // Handle object that had to be written in log files.
    var LogObfuscator = function () {
        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var OMMIT_KEY = 'keysToOmitFromLogging';

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function shallowCopy(src) {
            if (!src || (typeof src !== 'object')) {
                return src;
            }

            if (Array.isArray(src)) {
                return src.slice();
            }

            var obj = {};
            for (var key in src) {
                if (src.hasOwnProperty(key)) {
                    obj[key] = src[key];
                }
            }
            return obj;
        }

        function validatePaths(obj, paths) {
            var subPaths;
            var idx = paths.indexOf('[]');
            if (idx !== -1 && idx < (paths.length - 1)) {
                // Do not use splice since that would affect the original paths array
                subPaths = paths.slice(idx + 1);
                paths = paths.slice(0, idx + 1);
            }
            return paths.every(function (node) {
                if (node === '[]') {
                    if (!Array.isArray(obj)) {
                        return false;
                    }
                    if (!subPaths) {
                        return true;
                    }
                    return obj.some(function (elem) {
                        return validatePaths(elem, subPaths);
                    });
                }
                obj = obj[node];
                return !!obj;
            });
        }

        function ommitKeys(obj) {
            if (!obj || (typeof obj !== 'object') || !obj.hasOwnProperty(OMMIT_KEY)) {
                return obj;
            }
            var origObj = obj; // Keep a reference to the original object
            obj = shallowCopy(obj);  // Create a shallow copy for the modifications
            origObj[OMMIT_KEY].forEach(function (key) {
                var paths = key.split('.');
                if (!validatePaths(obj, paths)) {
                    return;
                }
                var root = obj;
                var origRoot = origObj;
                var lastIdx = paths.length - 1;
                paths.every(function (node, idx) {
                    if (node === '[]') {
                        if (Array.isArray(root)) {
                            var subKey = idx < lastIdx ? paths.slice(idx + 1).join('.') : null;
                            root.forEach(function (origElem, elemIdx) {
                                if (!subKey) {
                                    root[elemIdx] = '******';
                                    return;
                                }
                                origElem[OMMIT_KEY] = [subKey];
                                root[elemIdx] = ommitKeys(origElem);
                                delete origElem[OMMIT_KEY];
                            });
                        }
                        return false;
                    }
                    if (!root[node]) {
                        // The key to be omitted does not exist
                        return false;
                    }
                    if (idx === lastIdx) {
                        // This is the last element
                        root[node] = '******';
                    } else {
                        if (typeof root[node] !== 'object') {
                            // Cannot continue
                            return false;
                        }

                        // Shallow copy only if have not done so
                        if (root[node] === origRoot[node]) {
                            root[node] = shallowCopy(root[node]);
                        }
                        // Navigate to the next level
                        origRoot = origRoot[node];
                        root = root[node];
                    }
                    return true;
                });
            });
            // Remove the OMMIT_KEY property from the copy
            delete obj[OMMIT_KEY];
            return obj;
        }

        ///////////////////////////////////////////////////////////////////////////
        // Public interface
        ///////////////////////////////////////////////////////////////////////////
        /**
         * Transform object to string that ommit not required keys from logging.
         *
         * @param {Object} obj: object that had to be logged
         * @param {Function} replacerCb: A function that alters the behavior of the stringification process
         * @returns {string} String that will be added to log.
         */
        this.objToString = function (obj, replacerCb) {
            if (obj === undefined) {
                return '';
            }
            if (!obj) {
                return obj;
            }
            if (typeof obj === 'function') {
                return '[function]';
            }
            if (typeof obj !== 'object') {
                return obj;
            }

            var replacer = typeof replacerCb === 'function' ? replacerCb : null;

            obj = ommitKeys(obj);
            return JSON.stringify(obj, replacer, 3).replace(/\\r\\n/g, '\x0A');
        };
    };

    if (typeof module !== 'undefined' && module.exports) {
        // Supports exports (CommonJS)
        module.exports = new LogObfuscator();
    }
    circuit.LogObfuscator = new LogObfuscator();

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define

