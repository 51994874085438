/* global RegistrationState */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var CallState = circuit.Enums.CallState;
    var Utils = circuit.Utils;
    var RtcSessionController = circuit.RtcSessionController;
    var WebRTCAdapter = circuit.WebRTCAdapter;

    ///////////////////////////////////////////////////////////////////////////////////////
    // SoundSvc Implementation
    ///////////////////////////////////////////////////////////////////////////////////////
    var SoundCategory = Object.freeze({
        INCOMING_CALL: 'incomingCall',  // This also applies to any notification from remote call
        IN_CALL: 'inCall',
        OUTGOING_CALL: 'outgoingCall',
        SNOOZE_NOTIFICATION: 'snoozeNotification',
        ACCOUNT: 'account',
        ERROR_ALERT: 'errorAlert',
        CALL_NOTIFICATION: 'callNotification'
    });

    // Added for iOS client to understand which mode the sound should be played
    var PlayMode = Object.freeze({
        SOLO_AMBIENT: 'soloAmbient', // Currently played sounds are stopped, new sound is played in solo in ambient
        PLAY_AND_RECORD: 'playAndRecord', // The same as soloAmbient but is intended to be used under VoIP mode
        AMBIENT: 'ambient' // Currently played sounds are not interrupted and new sound is added to ambient
    });

    var ConversationContext = Object.freeze({
        ARCHIVED: 'archived',
        OPEN: 'open'
    });

    var RtcSessionContext = Object.freeze({
        CALL_ACTIVE: 'callActive',
        CALL_INCOMING: 'callIncoming',
        CALL_OUTGOING: 'callOutgoing',
        CALL_REMOTE_ACTIVE: 'callRemoteActive'
    });

    var PresenceContext = Object.freeze({
        DND: 'DND'
    });

    var FocusContext = Object.freeze({
        IN_FOCUS: 'inFocus'
    });

    var DEFAULT_RINGTONE = 'Ringtone_1';
    var SoundType = Object.freeze({
        ACCOUNT_LOGGING_IN: {
            // After successfully logging in to the client, this melody is played as a Welcome Sound
            soundName: 'AccountLoggingIn',
            soundFile: 'sound-account-logging-in',
            mobile: false,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.ACCOUNT
        },
        CALL_CONTROL_HANGING_UP: {
            // User hangs up call and ends the active call
            soundName: 'CallControlHangingUp',
            soundFile: 'sound-call-control-hanging-up',
            mobile: false,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.IN_CALL
        },
        CALL_CONTROL_HUNG_UP: {
            // Contact hangs up call and ends the active call
            soundName: 'CallControlHungUp',
            soundFile: 'sound-call-control-hung-up',
            mobile: true,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.IN_CALL
        },
        CALL_CONTROL_MUTED: {
            // Contact (moderator) mutes the user in a call
            soundName: 'CallControlMuted',
            soundFile: 'sound-call-control-muted',
            mobile: true,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.IN_CALL
        },
        INCOMING_CALL_DURING_CALL: {
            // Contact calls while user is already in an active realtime communication (audio call, video call or screen share)
            soundName: 'IncomingCallDuringCall',
            soundFile: 'sound-calling-incoming-call-during-call',
            mobile: true,
            repeat: false,
            vibrate: true,
            playMode: PlayMode.SOLO_AMBIENT,
            category: SoundCategory.INCOMING_CALL
        },
        INCOMING_CALL_REJECTING: {
            // User rejects call
            soundName: 'IncomingCallRejecting',
            soundFile: 'sound-calling-rejecting-call',
            mobile: true,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.INCOMING_CALL
        },
        INCOMING_CALL_RINGING: {
            // Contact calls user. Audio or video call
            soundName: 'IncomingCallRinging',
            soundFile: 'sound-calling-incoming-call',
            mobile: true,
            repeat: true,
            vibrate: true,
            playMode: PlayMode.SOLO_AMBIENT,
            category: SoundCategory.INCOMING_CALL
        },
        MEDIA_STREAM_STOPPED: {
            // When user in a call and loses the audio connection
            soundName: 'MediaStreamStopped',
            soundFile: 'sound-calling-call-error',
            mobile: true,
            repeat: true,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.IN_CALL
        },
        OUTGOING_CALL_ACCEPTED: {
            // Contact accepted the call
            soundName: 'OutgoingCallAccepted',
            soundFile: 'sound-calling-call-accepted',
            mobile: false,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.IN_CALL
        },
        OUTGOING_CALL_BUSY: {
            // Contact is busy. Initiated call is stopped, no ringing tone
            soundName: 'OutgoingCallBusy',
            soundFile: 'sound-calling-busy',
            mobile: true,
            repeat: true,
            vibrate: false,
            playMode: PlayMode.PLAY_AND_RECORD,
            category: SoundCategory.IN_CALL
        },
        OUTGOING_CALL_ERROR: {
            // Call cannot be initiated (e.g. no connection). Call is stopped, no ringing tone
            soundName: 'OutgoingCallError',
            soundFile: 'sound-calling-call-error',
            mobile: true,
            repeat: true,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.IN_CALL
        },
        OUTGOING_CALL_INITIATING: {
            // User clicks the call or video call button to initiate a call
            soundName: 'OutgoingCallInitiating',
            soundFile: 'sound-calling-initiating-call',
            mobile: true,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.PLAY_AND_RECORD,
            category: SoundCategory.OUTGOING_CALL
        },
        OUTGOING_CALL_REJECTED: {
            // Contact rejects the call
            soundName: 'OutgoingCallRejected',
            soundFile: 'sound-calling-call-rejected',
            mobile: true,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.OUTGOING_CALL
        },
        OUTGOING_CALL_RINGBACK_TONE: {
            // Ringing sound while waiting for the called contact to accept or decline
            soundName: 'OutgoingCallRingbackTone',
            soundFile: 'sound-calling-ringback-tone',
            mobile: true,
            repeat: true,
            vibrate: false,
            playMode: PlayMode.PLAY_AND_RECORD,
            category: SoundCategory.OUTGOING_CALL
        },
        PICKUP_NOTIFICATION: {
            // Pickup call
            soundName: 'PickupNotification',
            soundFile: 'sound-calling-incoming-call-during-call',
            mobile: true,
            repeat: false,
            vibrate: true,
            playMode: PlayMode.SOLO_AMBIENT,
            category: SoundCategory.CALL_NOTIFICATION
        },
        PRESENCE_END_SNOOZE_NOTIFICATIONS: {
            // User ends SN and opens up the communication channels again
            soundName: 'PresenceEndSnoozeNotifications',
            soundFile: 'sound-presence-end-snooze-notifications',
            mobile: true,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.SNOOZE_NOTIFICATION
        },
        PRESENCE_START_SNOOZE_NOTIFICATIONS: {
            // User starts snooze notifications. All incoming sounds are redirected to voicemail and all message notifications and sounds are muted. Presence changes to red
            soundName: 'PresenceStartSnoozeNotifications',
            soundFile: 'sound-presence-start-snooze-notifications',
            mobile: false,
            repeat: false,
            vibrate: false,
            playMode: PlayMode.AMBIENT,
            category: SoundCategory.SNOOZE_NOTIFICATION
        }
    });

    var SoundConfig = Object.freeze([
        {selector: {soundCategory: SoundCategory.OUTGOING_CALL}, rule: {play: true}},
        {selector: {soundCategory: SoundCategory.SNOOZE_NOTIFICATION}, rule: {play: true}},

        // In a RTC - All sounds off, except call related sounds
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_ACTIVE, soundName: SoundType.INCOMING_CALL_DURING_CALL.soundName}, rule: {play: true}},
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_ACTIVE, soundCategory: SoundCategory.CALL_NOTIFICATION}, rule: {play: false}},
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_ACTIVE, soundCategory: SoundCategory.INCOMING_CALL}, rule: {play: false}},
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_ACTIVE, soundCategory: SoundCategory.SNOOZE_NOTIFICATION}, rule: {play: false}},

        // In a RTC - All sounds off on 2nd device, except outgoing call
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_REMOTE_ACTIVE, soundCategory: SoundCategory.CALL_NOTIFICATION}, rule: {play: false}},
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_REMOTE_ACTIVE, soundCategory: SoundCategory.IN_CALL}, rule: {play: false}},
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_REMOTE_ACTIVE, soundCategory: SoundCategory.INCOMING_CALL}, rule: {play: false}},
        {selector: {rtcSessionCtxt: RtcSessionContext.CALL_REMOTE_ACTIVE, soundCategory: SoundCategory.SNOOZE_NOTIFICATION}, rule: {play: false}},

        // Snooze notification mode - All incoming notification sounds off
        {selector: {presenceCtxt: PresenceContext.DND, soundCategory: SoundCategory.CALL_NOTIFICATION}, rule: {play: false}},
        {selector: {presenceCtxt: PresenceContext.DND, soundCategory: SoundCategory.INCOMING_CALL}, rule: {play: false}},

        // Conversation is archived - All incoming notifications sounds off
        {selector: {conversationCtxt: ConversationContext.ARCHIVED, soundCategory: SoundCategory.CALL_NOTIFICATION}, rule: {play: false}}
    ]);

    var Ringtones = Object.freeze([
        'Ringtone_1',
        'Ringtone_2',
        'Ringtone_3',
        'Ringtone_4',
        'Ringtone_5',
        'Ringtone_6',
        'Ringtone_7',
        'Ringtone_8',
        'Ringtone_9',
        'Ringtone_10',
        'Ringtone_11',
        'Ringtone_12',
        'Ringtone_13',
        'Ringtone_14'
    ]);

    var SOUND_URL = 'content/sounds/';
    var RINGTONES_FOLDER = 'ringtones/';
    var MS_TEAMS_STOP_RETRY_TIMEOUT = 1000; // Retry sound stop while in MSTeams in mobile

    // eslint-disable-next-line max-params, max-lines-per-function
    function SoundSvcImpl($rootScope, $window, CallControlSvc, ConversationSvc, LogSvc, NotificationSvc, PubSubSvc, LocalStoreSvc, UserProfileSvc) { // NOSONAR
        LogSvc.debug('New Service: SoundSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _currentLoopingSound = null;
        var _currentLoopingSoundType = null;

        var _audioContext; // Keep it set to undefined
        var _audioContextDisabled = true; // Disable AudioContext implementation

        var _audioBuffers = {};
        var _activeSources = 0; // Counter of active sound sources

        // Define file extension to use
        var _browserInfo = Utils.getBrowserInfo();
        var _soundExtension = _browserInfo.safari ? '.mp3' : '.ogg';

        // Audio configuration settings
        var _soundSettings = {};

        var _currentRingtone = null;

        var _isMobile = Utils.isMobile();

        // Create adapter for mobile client
        var AudioAdapter = !_isMobile ? Audio : function (soundFile) {
            return Audio.createAudio(soundFile);
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setRingtone(ringtone) {
            if (ringtone !== _currentRingtone) {
                LogSvc.info('[SoundSvc]: Setting new ringtone: ', ringtone);
                _currentRingtone = ringtone;
                SoundType.INCOMING_CALL_RINGING.soundFile = RINGTONES_FOLDER + _currentRingtone;
            }
        }

        function initRingtone() {
            var ringtone = LocalStoreSvc.getStringSync(LocalStoreSvc.keys.RINGTONE);
            if (!ringtone || !Ringtones.includes(ringtone)) {
                LogSvc.debug('[SoundSvc]: No or invalid ringtone stored in local store. Using default ringtone.');
                ringtone = DEFAULT_RINGTONE;
            }
            setRingtone(ringtone);
        }

        function suspendAudio(cb) {
            if (_audioContext && (_audioContext.state === 'running')) {
                _audioContext.suspend().then(function () {
                    LogSvc.debug('[SoundSvc]: Suspending audio context');
                    cb && cb();
                });
                return;
            }
            cb && cb();
        }

        function resumeAudio(cb) {
            if (_audioContext && (_audioContext.state === 'suspended')) {
                _audioContext.resume().then(function () {
                    LogSvc.debug('[SoundSvc]: Resuming audio context');
                    cb && cb();
                });
                return;
            }
            cb && cb();
        }

        function loadSounds() {
            if (_audioContext) {
                LogSvc.debug('[SoundSvc]: Loading sound files...');

                Object.keys(SoundType).forEach(function (sound) {
                    var request = new XMLHttpRequest();
                    request._soundName = SoundType[sound].soundName;
                    request.open('GET', SOUND_URL + SoundType[sound].soundFile + _soundExtension, true);
                    request.responseType = 'arraybuffer';
                    request.addEventListener('load', bufferSound, false);
                    request.send();
                });
            }
        }

        function bufferSound(event) {
            var request = event.target;
            resumeAudio(function () {
                _audioContext.decodeAudioData(request.response, function onSuccess(buffer) {
                    _audioBuffers[request._soundName] = buffer;
                    LogSvc.debug('[SoundSvc]: Successfully buffered sound file:', request._soundName);
                }, function onFailure() {
                    LogSvc.warn('[SoundSvc]: Failed to buffer sound file:', request._soundName);
                });
            });

        }

        function resolvePlayRequest(request) {
            LogSvc.debug('[SoundSvc]: sound requested: ', request);
            var rule = {play: true};
            SoundConfig.some(function (config) {
                var match = Object.keys(config.selector).every(function (key) {
                    return config.selector[key] === request[key];
                });
                if (match) {
                    LogSvc.debug('[SoundSvc]: matched sound configure: ', config);
                    rule = config.rule;
                    return true;
                }
                return false;
            });
            return rule;
        }

        function conversationContext(conv) {
            if (!conv) {
                return null;
            }
            if (conv.muted) {
                return ConversationContext.ARCHIVED;
            } else if (conv.type === Constants.ConversationType.OPEN) {
                return ConversationContext.OPEN;
            }
            return null;
        }

        function rtcSessionContext(call) {
            if (call.isRemote && call.activeClient) {
                return RtcSessionContext.CALL_REMOTE_ACTIVE;
            }
            var rtcSessionCtxt;

            switch (call.state) {
            case CallState.Initiated:
            case CallState.Connecting:
            case CallState.Delivered:
                rtcSessionCtxt = RtcSessionContext.CALL_OUTGOING;
                break;
            case CallState.Ringing:
            case CallState.Answering:
                rtcSessionCtxt = RtcSessionContext.CALL_INCOMING;
                break;
            default:
                rtcSessionCtxt = RtcSessionContext.CALL_ACTIVE;
            }
            return rtcSessionCtxt;
        }

        function stopAudioElement(audioElement) {
            if (audioElement) {
                if (audioElement.constructor.name === 'AudioBufferSourceNode') {
                    audioElement.stop();
                } else {
                    audioElement.pause();
                    LogSvc.debug('[SoundSvc]: stopAudioElement was called for browser: ', _browserInfo);
                    if ($rootScope.isTeamsApp && (_browserInfo.subType === 'webview')) {
                        setTimeout(function () {
                            LogSvc.debug('[SoundSvc]: MSTeams Mobile WebView app detected, attempting to stop again current sound, in case of audio reservation by other apps');
                            audioElement && audioElement.pause();
                        }, MS_TEAMS_STOP_RETRY_TIMEOUT);
                    }
                }
            }
        }

        function setAudioElementSrcAndPlay(audioElement, sound) {
            if (audioElement.loop && _currentLoopingSound !== audioElement) {
                // This request is no longer applicable
                return;
            }

            audioElement.src = SOUND_URL + sound.soundFile + _soundExtension;
            var res = audioElement.play();

            if (Utils.isPromise(res)) {
                res.then(function () {
                    LogSvc.debug('[SoundSvc]: Started playing ', sound.soundName);
                    if (audioElement.loop && _currentLoopingSound !== audioElement) {
                        // This play took too long to start and has already been stopped
                        stopAudioElement(audioElement);
                    }
                }).catch(function (err) {
                    LogSvc.error('[SoundSvc]: Error playing ' + sound.soundName + '. ', err && err.message);
                });
            }
        }

        function playWithAudioContext(sound) {
            LogSvc.info('[SoundSvc] Play using AudioContext. sound: ', sound.soundName);
            resumeAudio(function () {
                var newSource = _audioContext.createBufferSource(); // creates a sound source
                newSource.buffer = _audioBuffers[sound.soundName];  // tell the source which sound to play
                newSource.loop = sound.repeat;
                newSource.connect(_audioContext.destination);       // connect the source to the context's destination (the speakers)
                if (newSource.loop) {
                    stop();
                    _currentLoopingSound = newSource;
                    _currentLoopingSoundType = sound.soundName;
                }
                newSource.start(0); // play the source now
                _activeSources++;
                newSource.onended = function () {
                    _activeSources--;
                    (_activeSources === 0) && suspendAudio(); // Clear AudioContext only if no active sound
                };
            });
        }

        function playWithAudioElement(sound) {
            LogSvc.info('[SoundSvc] Play using <audio> element. sound: ', sound.soundName);
            // Use <audio> element to play sound
            var audioElement = document.createElement('audio');                      // create HTML5 audio element
            audioElement.loop = sound.repeat;
            if (audioElement.loop) {
                stop();
                _currentLoopingSound = audioElement;
                _currentLoopingSoundType = sound.soundName;
            }

            if (RtcSessionController.playbackDevices.length && (sound.category === SoundCategory.OUTGOING_CALL ||
               ((sound.category === SoundCategory.IN_CALL || sound.category === SoundCategory.INCOMING_CALL) && CallControlSvc.getActiveCall()))) {
                // These sounds need to played on the current playback device
                WebRTCAdapter.attachSinkIdToAudioElement(audioElement, RtcSessionController.playbackDevices, function (err) {
                    if (err) {
                        LogSvc.warn('[SoundSvc]: Failed to attach sinkId. ', err);
                    }
                    // The element src has to be set AFTER the sinkId
                    setAudioElementSrcAndPlay(audioElement, sound); // Play sound whether attach sink ID succeeded or not
                });
            } else if (RtcSessionController.ringingDevices.length && sound.category === SoundCategory.INCOMING_CALL) {
                WebRTCAdapter.attachSinkIdToAudioElement(audioElement, RtcSessionController.ringingDevices, function (err) {
                    if (err) {
                        LogSvc.warn('[SoundSvc]: Failed to attach sinkId. ', err);
                    }
                    // The element src has to be set AFTER the sinkId
                    setAudioElementSrcAndPlay(audioElement, sound); // Play sound whether attach sink ID succeeded or not
                });
            } else {
                setAudioElementSrcAndPlay(audioElement, sound);
            }
        }

        function playWithAudioAPI(sound) {
            LogSvc.info('[SoundSvc] Play using Audio API. sound: ', sound.soundName);
            var newSound = new AudioAdapter(SOUND_URL + sound.soundFile + _soundExtension);
            newSound.loop = sound.repeat;
            newSound.playMode = sound.playMode;
            newSound.vibrate = sound.vibrate;
            if (newSound.loop) {
                stop();
                _currentLoopingSound = newSound;
                _currentLoopingSoundType = sound.soundName;
            }
            newSound.play();
        }

        function play(sound, options) {
            if (!sound) {
                // Invalid input
                return;
            }

            if (!sound.mobile && _isMobile) {
                // Ignore sound for mobile clients
                return;
            }

            if (_currentLoopingSoundType) {
                if (sound.soundName === _currentLoopingSoundType) {
                    // It is already playing this sound, just return
                    return;
                }
                if (sound.repeat) {
                    // Only a looping sound can interrupt another looping sound
                    stop();
                } else if (_isMobile) {
                    // Don't play multiple sounds in mobile clients
                    return;
                }
            }

            options = options || {overrideSnooze: false};

            // Only check for conditions and settings if playUnconditionally flag is not set
            if (!options.playUnconditionally) {
                if (!isSoundEnabled(sound)) {
                    LogSvc.info('[SoundSvc] Sound suppressed due to user settings. sound: ', sound.soundName);
                    return;
                }

                var playRequest = options.playCtxt || {};
                playRequest.soundCategory = sound.category;

                if (($rootScope.localUser.userPresenceState.state === Constants.PresenceState.DND) && !options.overrideSnooze) {
                    // User has snooze enabled. Block all audio notifications.
                    playRequest.presenceCtxt = PresenceContext.DND;
                }

                var callContext = CallControlSvc.getActiveCall() || CallControlSvc.getActiveRemoteCall()[0] || CallControlSvc.getIncomingCall();

                if (callContext) {
                    playRequest.rtcSessionCtxt = rtcSessionContext(callContext);
                }

                if (NotificationSvc.isAppInFocus()) {
                    playRequest.focusCtxt = FocusContext.IN_FOCUS;
                }

                playRequest.soundName = sound.soundName;

                if (!resolvePlayRequest(playRequest).play) {
                    return;
                }
            }

            if (_audioContext && _audioBuffers[sound.soundName]) {
                playWithAudioContext(sound);
            } else if (!_isMobile) {
                playWithAudioElement(sound);
            } else {
                playWithAudioAPI(sound);
            }
        }

        function stop(sound) {
            if (_currentLoopingSound) {
                if (sound && sound.soundName !== _currentLoopingSoundType) {
                    return; // We're currently playing another sound. Ignore this stop request
                }
                LogSvc.info('[SoundSvc] stop(): type: ', _currentLoopingSoundType);
                stopAudioElement(_currentLoopingSound);
                _currentLoopingSound = null;
                _currentLoopingSoundType = null;
            }
        }

        function getAudioSetting(key) {
            var setting = UserProfileSvc.getSetting(key);
            if (setting && setting.booleanValue !== undefined) {
                return setting.booleanValue;
            }
            // Return true by default
            return true;
        }


        function updateSettings() {
            _soundSettings = {
                rtc: getAudioSetting(Constants.UserSettingKey.PLAY_SOUND_RTC),
                system: getAudioSetting(Constants.UserSettingKey.PLAY_SYSTEM_SOUNDS),
                pickupNotification: getAudioSetting(Constants.UserSettingKey.PLAY_PICKUP_SOUND)
            };

            LogSvc.info('[SoundSvc]: Updated sound settings: ', _soundSettings);
        }

        function isSoundEnabled(sound) {
            // Current user settings are only applicable for web/DA
            if (_isMobile) {
                return true;
            }
            switch (sound) {
            case SoundType.INCOMING_CALL_DURING_CALL:
            case SoundType.INCOMING_CALL_RINGING:
                return _soundSettings.rtc;
            case SoundType.PICKUP_NOTIFICATION:
                return _soundSettings.pickupNotification;
            default:
                return _soundSettings.system;
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Sound Settings
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/localUser/init', function () {
            LogSvc.debug('[SoundSvc]: Received /localUser/init event');
            updateSettings();
            initRingtone();
        });

        PubSubSvc.subscribe('/user/settings/update', function () {
            LogSvc.debug('[SoundSvc]: Received /user/settings/update event');
            updateSettings();
        });

        PubSubSvc.subscribe('/feature/state/initialized', function () {
            LogSvc.debug('[SoundSvc]: Received /feature/state/initialized');
            initRingtone();
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Call Sounds
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/call/state', function (call) {
            if (!call || call.isRemote) {
                return;
            }
            LogSvc.debug('[SoundSvc]: Received /call/state event. state =', call.state.name);
            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};

            if (call.state !== CallState.Delivered) {
                stop(SoundType.OUTGOING_CALL_RINGBACK_TONE);
            }

            switch (call.state) {
            case CallState.Failed:
                play(SoundType.OUTGOING_CALL_ERROR, option);
                break;
            case CallState.Busy:
            case CallState.NotAnswered:
                play(SoundType.OUTGOING_CALL_BUSY, option);
                break;
            case CallState.Declined:
                play(SoundType.OUTGOING_CALL_REJECTED, option);
                break;
            case CallState.Answering:
                stop();
                break;
            default:
                break;
            }
        });

        PubSubSvc.subscribe('/call/outgoing', function (call) {
            LogSvc.debug('[SoundSvc]: Received /call/outgoing event');
            if (!call) {
                return;
            }
            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};

            play(SoundType.OUTGOING_CALL_INITIATING, option);
        });

        PubSubSvc.subscribe('/call/incoming', function (call) {
            if (!call) {
                return;
            }

            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};

            LogSvc.debug('[SoundSvc]: Received /call/incoming event');
            if (CallControlSvc.getActiveCall()) {
                play(SoundType.INCOMING_CALL_DURING_CALL, option);
            } else {
                play(SoundType.INCOMING_CALL_RINGING, option);
            }
        });

        PubSubSvc.subscribe('/call/participant/joined', function (call) {
            if (!call) {
                return;
            }
            LogSvc.debug('[SoundSvc]: Received /call/participant/joined event');
            stop();
        });

        PubSubSvc.subscribe('/call/declining', function (call) {
            if (!call) {
                return;
            }
            LogSvc.debug('[SoundSvc]: Received /call/declining event');
            stop();
            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};
            play(SoundType.INCOMING_CALL_REJECTING, option);
        });

        PubSubSvc.subscribe('/call/ended', function (call) {
            if (call && !call.isRemote) {
                LogSvc.debug('[SoundSvc]: Received /call/ended event');
                stop();
            }
        });

        PubSubSvc.subscribe('/call/terminating', function (call) {
            if (!call || call.isRemote) {
                return;
            }
            // The local user is terminating the call
            LogSvc.debug('[SoundSvc]: Received /call/terminating event');
            stop();
            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};
            play(SoundType.CALL_CONTROL_HANGING_UP, option);
        });

        PubSubSvc.subscribe('/call/terminated', function (call/*, cause*/) {
            if (!call || call.isRemote) {
                return;
            }
            LogSvc.debug('[SoundSvc]: Received /call/terminated event');
            stop();
            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};

            if (call.isDirect) {
                play(SoundType.CALL_CONTROL_HUNG_UP, option);
            }
        });

        PubSubSvc.subscribe('/call/ringingTone/stop', function () {
            LogSvc.debug('[SoundSvc]: Received /call/ringingTone/stop event');
            stop();
        });

        PubSubSvc.subscribe('/sound/call/alerting/started', function (call) {
            if (!call) {
                return;
            }
            LogSvc.debug('[SoundSvc]: Received /sound/call/alerting/started event');
            // Set this flag so it gets played regardless of any configuration or context
            var option = {playUnconditionally: true};
            play(SoundType.OUTGOING_CALL_RINGBACK_TONE, option);
        });

        PubSubSvc.subscribe('/sound/call/alerting/stopped', function () {
            LogSvc.debug('[SoundSvc]: Received /sound/call/alerting/stopped event');
            stop(SoundType.OUTGOING_CALL_RINGBACK_TONE);
        });

        PubSubSvc.subscribe('/call/pickupNotification', function (call) {
            if (!call) {
                return;
            }
            LogSvc.debug('[SoundSvc]: Received /call/pickupNotification event');
            var option = {playCtxt: {conversationCtxt: conversationContext(ConversationSvc.getConversationFromCache(call.convId))}};
            play(SoundType.PICKUP_NOTIFICATION, option);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Call Control Sounds
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/call/localUser/muted', function (callId, muted, locallyMuted) {
            // User has been muted by someone else
            LogSvc.debug('[SoundSvc]: Received /call/localUser/muted event');
            if (muted && !locallyMuted) {
                play(SoundType.CALL_CONTROL_MUTED);
            }
        });


        PubSubSvc.subscribe('/call/rtp/iceConnectionState', function (callId, state) {
            LogSvc.debug('[SoundSvc]: Received /call/rtp/iceConnectionState event. state:', state);
            if (state === 'disconnected') {
                var option = {playUnconditionally: true};
                play(SoundType.MEDIA_STREAM_STOPPED, option);
            } else {
                stop(SoundType.MEDIA_STREAM_STOPPED);
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Presence Sounds
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/user/snooze/start', function () {
            LogSvc.debug('[SoundSvc]: Received /user/snooze/start event');
            //play(SoundType.PRESENCE_START_SNOOZE_NOTIFICATIONS);
        });

        PubSubSvc.subscribe('/user/snooze/end', function () {
            LogSvc.debug('[SoundSvc]: Received /user/snooze/end event');
            //play(SoundType.PRESENCE_END_SNOOZE_NOTIFICATIONS);
        });

        PubSubSvc.subscribe('/user/snooze/resume', function () {
            LogSvc.debug('[SoundSvc]: Received /user/snooze/resume event');
            //play(SoundType.PRESENCE_END_SNOOZE_NOTIFICATIONS);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        Object.defineProperties(this, {
            ringtone: {
                get: function () {
                    return _currentRingtone;
                },
                set: function (ringtone) {
                    if (!ringtone || !Ringtones.includes(ringtone)) {
                        LogSvc.warn('[SoundSvc]: Setting invalid ringtone. Ringtone: ', ringtone || 'no ringtone');
                        return;
                    }
                    setRingtone(ringtone);
                    LocalStoreSvc.setString(LocalStoreSvc.keys.RINGTONE, ringtone);
                },
                enumerable: true,
                configurable: false
            }
        });

        this.init = function () {
            if (_audioContext !== undefined) {
                LogSvc.warn('[SoundSvc] init(): SoundSvc has already been initialized');
                return;
            }

            _audioContext = null;
            try {
                // Firefox can use Web Audio API, but the perfomance problems still exists.
                // Use <audio> element instead of the Web Audio API for playing sounds in FF,
                // to prevent sound interruption when DOM reflows (e.g. when incoming call appears
                // and user switch between conversations/move to details tab/other user interaction).
                if (!_audioContextDisabled && $window.AudioContext && $rootScope.browser.chrome) {
                    _audioContext = new $window.AudioContext();
                    _audioContext.suspend();
                    LogSvc.info('[SoundSvc] New AudioContext instance created and suspended until needed');
                }
            } catch (e) {
                LogSvc.warn('[SoundSvc] Failed to create AudioContext instance');
            }

            if (_audioContext) {
                loadSounds();
            }
        };

        this.buildSoundFileURL = function (sound) {
            if (!sound || !sound.soundFile) {
                return '';
            }
            return SOUND_URL + sound.soundFile + _soundExtension;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.SoundSvcImpl = SoundSvcImpl;
    circuit.SoundType = SoundType;
    circuit.Ringtones = Ringtones;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
