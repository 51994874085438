
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var BaseEventTarget = circuit.BaseEventTarget;
    var ChromeExtension = circuit.ChromeExtension;
    var logger = circuit.logger;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-lines-per-function
    function ExtensionConnHandler() { // NOSONAR
        // Call the base constructor
        ExtensionConnHandler.parent.constructor.call(this, logger);

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        // Default response wait time
        var RESPONSE_TIMEOUT = 5000;
        // Response wait time for requests which may require the user to grant new permissions
        var LONG_RESPONSE_TIMEOUT = 180000;
        // Response wait time for requests to Exchange connector
        var EXCHANGE_RESPONSE_TIMEOUT = 65000;

        var _that = this;
        var _reqCallbacks = {};
        var _reqId = 0;
        var _extInitialized = false;

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function getNextReqId() {
            _reqId++;
            return _reqId;
        }

        function addReqCallback(cb, cbTimeout) {
            cb = cb || function () {};

            var reqId = getNextReqId();
            var responseTimer = window.setTimeout(function () {
                if (_reqCallbacks[reqId]) {
                    logger.error('[ExtensionConnHandler]: Timeout waiting for response. reqId:', reqId);
                    delete _reqCallbacks[reqId];
                    cb(ChromeExtension.ResponseError.TIMEOUT);
                }
            }, cbTimeout || RESPONSE_TIMEOUT);

            _reqCallbacks[reqId] = {cb: cb, timer: responseTimer};

            return reqId;
        }

        function unregisterReqCallback(reqId) {
            if (!reqId || !_reqCallbacks[reqId]) {
                return false;
            }
            delete _reqCallbacks[reqId];
            return true;
        }

        function dispatchInternalEvent(eventName, data) {
            logger.info('[ExtensionConnHandler]: Dispatching event: ', eventName);
            var newEvt = _that.createEvent(eventName);
            newEvt.data = data;
            _that.dispatch(newEvt);
        }

        function handleExtMessage(msg) {
            // Internal events are supposed to be handled by the ExtensionConnHandler instance.
            // All other events are published to the interested applications
            switch (msg.target) {
            case ChromeExtension.BgTarget.INTERNAL:
                switch (msg.data.type) {
                case ChromeExtension.BgInternalMsgType.INIT_MSG:
                    logger.info('[ExtensionConnHandler]: Received INIT_MSG. Send an INIT_MSG_ACK to the extension.');
                    _extInitialized = true;
                    sendMessage({
                        target: ChromeExtension.BgTarget.INTERNAL,
                        data: {
                            type: ChromeExtension.BgInternalMsgType.INIT_MSG_ACK
                        }
                    });
                    dispatchInternalEvent('extensionInitialized', msg.data);
                    break;
                case ChromeExtension.BgInternalMsgType.EXTENSION_DISCONNECTED:
                    logger.warning('[ExtensionConnHandler]: Received EXTENSION_DISCONNECTED from ansible-content');
                    onExtensionUnregistered();
                    break;
                default:
                    logger.warning('[ExtensionConnHandler]: Received unknown INTERNAL msg type:', msg.data.type);
                    break;
                }
                break;

            case ChromeExtension.BgTarget.HEADSET_APP:
                switch (msg.data.type) {
                case ChromeExtension.BgHeadsetAppMsgType.HEADSET_APP_LAUNCHED:
                    dispatchInternalEvent('headsetAppLaunched', msg.data);
                    break;
                case ChromeExtension.BgHeadsetAppMsgType.HEADSET_APP_UNINSTALLED:
                    dispatchInternalEvent('headsetAppUninstalled', msg.data);
                    break;
                default:
                    logger.warning('[ExtensionConnHandler]: Received unknown HEADSET_APP msg type:', msg.data.type);
                    break;
                }
                break;

            case ChromeExtension.BgTarget.PRIVACY_SETTINGS:
                if (msg.data.type === ChromeExtension.BgPrivacySettingsMsgType.IP_HANDLING_POLICY_CHANGED) {
                    dispatchInternalEvent('webRTCIPHandlingPolicyChanged', msg.data);
                } else {
                    logger.warning('[ExtensionConnHandler]: Received unknown PRIVACY_SETTINGS msg type:', msg.data.type);
                }
                break;

            default:
                if (!msg.suppressLog) {
                    logger.info('[ExtensionConnHandler]: Dispatching event:', msg.target);
                }
                var newEvt = _that.createEvent(msg.target);
                newEvt.data = msg.data;
                newEvt.suppressLog = msg.suppressLog;
                // Just add the request id for an Extension request
                if (msg.reqId && msg.type === ChromeExtension.BgMsgType.REQUEST) {
                    newEvt.reqId = msg.reqId;
                }
                _that.dispatch(newEvt);
                break;
            }
        }

        function handleExtResponse(resp) {
            if (_reqCallbacks[resp.reqId]) {
                var cbInfo = _reqCallbacks[resp.reqId];
                if (cbInfo.timer) {
                    window.clearTimeout(cbInfo.timer);
                }
                delete _reqCallbacks[resp.reqId];

                // Invoke the callback function (error is always the 1st parameter)
                if (!resp.data || !resp.data.error) {
                    cbInfo.cb(null, resp.data);
                } else {
                    cbInfo.cb(resp.data.error);

                    if (resp.data.error === ChromeExtension.ResponseError.UNREGISTERED) {
                        onExtensionUnregistered();
                    }
                }

                if (!resp.suppressLog) {
                    logger.debug('[ExtensionConnHandler]: Remaining callbacks pending:',
                        Object.keys(_reqCallbacks).length);
                }
            } else {
                logger.info('[ExtensionConnHandler]: No registered callback for reqId =', resp.reqId);
            }
        }

        // Send Messages (requests) to Chrome Extension
        function sendMessage(data, cb, cbTimeout, suppressLog) {
            if (!data) {
                logger.error('[ExtensionConnHandler]: Cannot send message. Invalid message.');
                cb && window.setTimeout(function () { cb('Invalid message'); }, 0);
                return false;
            }

            if (!_that.isExtensionRunning()) {
                logger.info('[ExtensionConnHandler]: Chrome extension is not running');
                cb && window.setTimeout(function () { cb('Extension not running'); }, 0);
                return false;
            }
            return dispatchEvent(data, cb, cbTimeout, suppressLog);
        }

        function dispatchEvent(data, cb, cbTimeout, suppressLog) {
            if (data.type === ChromeExtension.BgMsgType.REQUEST) {
                data.reqId = addReqCallback(cb, cbTimeout);
            }

            if (!suppressLog) {
                logger.msgSend('[ExtensionConnHandler]: ', data);
            }
            data.suppressLog = suppressLog;
            var msg = new CustomEvent(ChromeExtension.DOMEvent.TO_EXTENSION, {detail: JSON.stringify(data)});
            try {
                document && document.dispatchEvent(msg);
            } catch (e) {
                logger.warning('[ExtensionConnHandler]: Error sending message: ' + msg, e);
                return false;
            }
            return data.reqId;
        }

        function onExtensionUnregistered() {
            logger.warning('[ExtensionConnHandler]: The content script has unregistered itself. Chrome extension is no longer running.');
            _extInitialized = false;
            logger.info('[ExtensionConnHandler]: Dispatching event: extensionUnregistered');
            var newEvt = _that.createEvent('extensionUnregistered');
            _that.dispatch(newEvt);
        }

        ///////////////////////////////////////////////////////////////////////////
        // Event handlers
        ///////////////////////////////////////////////////////////////////////////
        function onMessage(msgObj) {
            if (msgObj.target === ChromeExtension.BgTarget.LOG) {
                logger.logMsg(msgObj.data.log.level, msgObj.data.log.messages);
                return;
            }

            if (!msgObj.suppressLog) {
                logger.msgRcvd('[ExtensionConnHandler]: ', msgObj);
            }

            switch (msgObj.type) {
            case ChromeExtension.BgMsgType.REQUEST:
            case ChromeExtension.BgMsgType.EVENT:
                handleExtMessage(msgObj);
                break;
            case ChromeExtension.BgMsgType.RESPONSE:
                handleExtResponse(msgObj);
                break;
            default:
                logger.msgRcvd('[ExtensionConnHandler]: Unexpected message type: ', msgObj.type);
                break;
            }
        }

        function onExtensionMessage(msg) {
            if (!msg || !msg.detail) {
                return;
            }
            try {
                var msgObj = JSON.parse(msg.detail);
                onMessage(msgObj);
            } catch (e) {
                logger.error('[ExtensionConnHandler]: Error parsing JSON object. ', e);
            }
        }

        ///////////////////////////////////////////////////////////////////////////
        // Public functions
        ///////////////////////////////////////////////////////////////////////////
        this.isExtensionRunning = function () {
            return _extInitialized;
        };

        /******************************************************
         * Sreenshare APIs (Used by RtcSessionController
         ******************************************************/
        this.getScreenShareUserMedia = function (cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.SCREEN_SHARE,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgScreenShareMsgType.CHOOSE_DESKTOP_MEDIA
                }
            }, cb);
        };

        /******************************************************
         * Exchange Connector APIs
         ******************************************************/
        this.exchangeConnect = function (settings, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    method: ChromeExtension.BgExchangeMsgType.CONNECT,
                    object: {
                        settings: settings
                    }
                },
                keysToOmitFromLogging: ['data.object.settings.exchInfo']
            }, cb, LONG_RESPONSE_TIMEOUT);
        };

        this.exchangeDisconnect = function (cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    method: ChromeExtension.BgExchangeMsgType.DISCONNECT,
                    object: {}
                }
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.exchangeGetConnectionStatus = function (key, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_CONNECTION_STATUS
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.exchangeGetCapabilities = function (cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    method: ChromeExtension.BgExchangeMsgType.GET_CAPABILITIES
                }
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        /**
         * Cancel the callback for a specific request
         *
         * @function
         * @param {int} Request Id to cancel
         *
         * @returns {bool} Returns true if callback was canceled.
         */
        this.exchangeCancelReqCallback = function (reqId) {
            logger.debug('[ExtensionConnHandler]: Unregistering callback for reqId:', reqId);
            return unregisterReqCallback(reqId);
        };

        /**
         * Execute user search in Exchange server.
         *
         * @function
         * @param {key} Encryption key
         * @param {String} The query string to search.
         * @param {int} Amount of results to be retrieved from exchange server.
         * @param {function} Callback function
         *
         * @returns {int} Returns the request id generated for this search.  Used to cancel previous searches.
         */
        this.exchangeSearchContacts = function (key, searchStr, resCount, cb) {
            return sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.SEARCH_CONTACTS,
                    object: {
                        searchString: searchStr,
                        resultCount: resCount
                    }
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.exchangeResolveContact = function (key, contactData, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.RESOLVE_CONTACT,
                    object: contactData
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.exchangeGetContact = function (key, exchangeEmail, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_CONTACT,
                    object: {
                        email: exchangeEmail
                    }
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.exchangeOnRenewedToken = function (key, reqId, token, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.RESPONSE,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.ON_RENEWED_TOKEN,
                    object: {
                        reqId: reqId,
                        token: token
                    }
                },
                keysToOmitFromLogging: ['data.key', 'data.object.token']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);

        };

        this.exchangeStoreCredentials = function (credentials, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    method: ChromeExtension.BgExchangeMsgType.STORE_EXCH_CREDENTIALS,
                    object: credentials
                },
                keysToOmitFromLogging: ['data.object']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.getAllPersonalContacts = function (key, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_ALL_PERSONAL_CONTACTS
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.getAppointments = function (key, startDate, endDate, resCount, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_APPOINTMENTS,
                    startDate: startDate,
                    endDate: endDate,
                    resCount: resCount
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.syncAllPersonalContacts = function (key, syncState, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.SYNC_ALL_PERSONAL_CONTACTS,
                    syncState: syncState
                },
                keysToOmitFromLogging: ['data.key', 'data.syncState']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.getStoredCredentials = function (key, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_STORED_CREDENTIALS
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.getOooMsg = function (key, fromEmail, email, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_OOO_MSG,
                    fromEmail: fromEmail,
                    email: email
                },
                keysToOmitFromLogging: ['data.key']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        this.getUserAvailability = function (key, email, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.EXCHANGE_CONNECTOR,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    key: key,
                    method: ChromeExtension.BgExchangeMsgType.GET_USER_AVAILABILITY,
                    email: email
                },
                keysToOmitFromLogging: ['data.key', 'data.email']
            }, cb, EXCHANGE_RESPONSE_TIMEOUT);
        };

        /******************************************************
         * Headset App Manager APIs
         ******************************************************/
        this.getHeadsetIntegrationAppStatus = function (appId, cb) {
            if (typeof cb !== 'function') {
                return;
            }
            sendMessage({
                target: ChromeExtension.BgTarget.HEADSET_APP,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgHeadsetAppMsgType.GET_HEADSET_APP_STATUS,
                    appId: appId
                }
            }, cb);
        };

        this.launchHeadsetIntegrationApp = function (appId, localizedStrings, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.HEADSET_APP,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgHeadsetAppMsgType.LAUNCH_HEADSET_APP,
                    appId: appId,
                    localizedStrings: localizedStrings
                }
            }, cb, LONG_RESPONSE_TIMEOUT);
        };

        this.restartHeadsetApp = function (appId, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.HEADSET_APP,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgHeadsetAppMsgType.RESTART_HEADSET_APP,
                    appId: appId
                }
            }, cb);
        };

        /******************************************************
         * Privacy Settings APIs
         ******************************************************/
        this.setIPHandlingPolicy = function (policy, localizedStrings, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.PRIVACY_SETTINGS,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgPrivacySettingsMsgType.SET_IP_HANDLING_POLICY,
                    policy: policy,
                    localizedStrings: localizedStrings
                }
            }, cb, LONG_RESPONSE_TIMEOUT);
        };

        this.getIPHandlingPolicy = function (cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.PRIVACY_SETTINGS,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgPrivacySettingsMsgType.GET_IP_HANDLING_POLICY
                }
            }, cb);
        };

        /******************************************************
         * Internal APIs
         ******************************************************/
        this.bringToFront = function (cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.INTERNAL,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgInternalMsgType.BRING_TO_FRONT
                }
            }, cb);
        };

        this.getFile = function (file, lang, cb) {
            sendMessage({
                target: ChromeExtension.BgTarget.INTERNAL,
                type: ChromeExtension.BgMsgType.REQUEST,
                data: {
                    type: ChromeExtension.BgInternalMsgType.GET_FILE,
                    name: file,
                    lang: lang
                }
            }, cb);
        };

        ///////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////

        if (Utils.getBrowserInfo().chrome) {
            // Register for events from Chrome extension
            document && document.addEventListener(ChromeExtension.DOMEvent.FROM_EXTENSION, onExtensionMessage);
        }
    }

    Utils.inherit(ExtensionConnHandler, BaseEventTarget);
    ExtensionConnHandler.prototype.name = 'ExtensionConnHandler';

    // Exports
    circuit.ExtensionConnHandler = ExtensionConnHandler;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
