/*global module*/

var Circuit = (function (circuit) {
    'use strict';

    var ChromeExtension = {
        SIGNATURE: 'ansibleChromeExtensionSignature'
    };

    ChromeExtension.DOMEvent = Object.freeze({
        TO_EXTENSION: 'toExtension',
        FROM_EXTENSION: 'fromExtension'
    });

    // Used to define if we have a callback or not.
    ChromeExtension.BgMsgType = Object.freeze({
        REQUEST: 'request',
        RESPONSE: 'response',
        EVENT: 'event'
    });

    // Features (Targets). As a general rule, we have message types for each feature (Target)
    ChromeExtension.BgTarget = Object.freeze({
        INTERNAL: 'internal',
        LOG: 'log',
        SCREEN_SHARE: 'screenShare',
        EXCHANGE_CONNECTOR: 'exchange_connector',
        HEADSET_APP: 'headsetAppManager',
        PRIVACY_SETTINGS: 'privacySettings'
    });

    // Error reasons
    ChromeExtension.ResponseError = Object.freeze({
        UNREGISTERED: 'unregistered',               // The content script failed to communicate with the extension and unregistered itself.
        TIMEOUT: 'timeout',                         // Timeout waiting for response from extension
        UNSUPPORTED_REQUEST: 'unsupportedRequest',  // Extension doesn't support this request
        INTERNAL_ERROR: 'internalError'             // There was an internal error processing the request
    });

    // Log events
    ChromeExtension.BgLogMsgType = Object.freeze({
        LOG: 'log'
    });

    // Internal Targets
    ChromeExtension.BgInternalMsgType = Object.freeze({
        INIT_MSG: 'initMsg',
        INIT_MSG_ACK: 'initMsgAck',
        INJECT_SIGNATURE: 'injectChromeAppSignature',
        IS_ALIVE: 'isAlive',
        GET_WINDOW_SIZE: 'getWindowSize',
        EXTENSION_DISCONNECTED: 'extensionDisconnected',
        BRING_TO_FRONT: 'bringToFront',
        GET_FILE: 'getFile'
    });

    ChromeExtension.BgScreenShareMsgType = Object.freeze({
        CHOOSE_DESKTOP_MEDIA: 'cdm',
        CHOOSE_DESKTOP_MEDIA_DONE: 'cdmDone',
        CHOOSE_DESKTOP_MEDIA_CANCELLED: 'cdmCancelled'
    });

    ChromeExtension.BgPrivacySettingsMsgType = Object.freeze({
        SET_IP_HANDLING_POLICY: 'setWebRTCIPHandlingPolicy',
        GET_IP_HANDLING_POLICY: 'getWebRTCIPHandlingPolicy',
        IP_HANDLING_POLICY_CHANGED: 'webRTCIPHandlingPolicyChanged'
    });

    ChromeExtension.BgPrivacySettingsError = Object.freeze({
        NOT_AUTHORIZED: 'notAuthorized', // Extension has not been authorized to control policy settings
        NOT_CONTROLLABLE: 'notControllable', // Setting is locked by enterprise policies
        CONTROLLED_BY_OTHER_EXTENSIONS: 'controlledByOtherExtensions' // Setting is being controlled by another extension
    });

    ChromeExtension.BgExchangeMsgType = Object.freeze({
        CONNECT: 'connect',
        DISCONNECT: 'disconnect',
        SEARCH_CONTACTS: 'searchContacts',
        AUTODISCOVER_GET_SERVER: 'autodiscoverGetServer',
        GET_CONNECTION_STATUS: 'getConnectionStatus',
        GET_CAPABILITIES: 'getCapabilities',
        GET_CONTACT: 'getContact',
        STORE_EXCH_CREDENTIALS: 'storeCredentials',
        CONNECTION_STATUS: 'connectionStatus',
        GET_ALL_PERSONAL_CONTACTS: 'getAllPersonalContacts',
        CONTACT_FOLDERS_SYNC_STATE: 'contactFoldersSyncState',
        SYNC_ALL_PERSONAL_CONTACTS: 'syncAllPersonalContacts',
        GET_APPOINTMENTS: 'getAppointments',
        GET_STORED_CREDENTIALS: 'getStoredCredentials',
        GET_RENEWED_TOKEN: 'getRenewedToken',
        ON_RENEWED_TOKEN: 'onRenewedToken',
        GET_OOO_MSG: 'getOooMsg',
        GET_USER_AVAILABILITY: 'getUserAvailability'
    });

    // Internal Targets
    ChromeExtension.BgHeadsetAppMsgType = Object.freeze({
        GET_HEADSET_APP_STATUS: 'getHeadsetAppStatus',
        LAUNCH_HEADSET_APP: 'launchHeadsetApp',
        HEADSET_APP_LAUNCHED: 'headsetAppLaunched',
        HEADSET_APP_UNINSTALLED: 'headsetAppUninstalled',
        RESTART_HEADSET_APP: 'restartHeadsetApp'
    });

    // Possible response values sent by Exchange Connector
    ChromeExtension.ExchangeConnResponse = Object.freeze({
        OK: 'ok',
        NO_RESULT: 'noResult',
        FAILED: 'failed',
        COULD_NOT_CONNECT: 'couldNotConnect',
        UNAUTHORIZED: 'unauthorized',
        UNSUPPORTED_METHOD: 'unsupportedMethod',
        TIMED_OUT: 'timedOut',
        MAILBOX_NOT_FOUND: 'mailboxNotFound',
        NTLM_NOT_SUPPORTED: 'ntlmNotSupported'
    });

    // Headset Integration App statuses
    ChromeExtension.HeadsetAppStatus = Object.freeze({
        NO_MANAGEMENT_PERMISSION: 'noManagementPermission',
        NOT_INSTALLED: 'notInstalled',
        INSTALLED: 'installed',
        DISABLED: 'disabled',
        UNKNOWN: 'unknown'
    });

    ChromeExtension.Exchange = Object.freeze({
        CONFIGURED_PASSWORD_MASKED: '$$$$'
    });

    ChromeExtension.PortMsg = Object.freeze({
        CONNECT: 'connect',
        DISCONNECT: 'disconnect',
        MSG: 'msg'
    });

    // Exports
    circuit.ChromeExtension = ChromeExtension;

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = ChromeExtension;
    }

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
