/*global process, require*/

var Circuit = (function (circuit) {
    'use strict';

    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function LocationSvcImpl($rootScope, $window, $http, $timeout, LogSvc, LocalizeSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: LocationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var ACCURACY_THRESHOLD = 1000;      // 1 km
        var SIGNIFICANCE_FACTOR_RAD = 0.05; // Around 5km in radius
        var DEFAULT_MAX_AGE = Utils.isMobile() ? 3600000 : 7200000; // 1 hour for mobile and 2 hours web/DA

        var ERROR = {
            GEOLOCATION_NOT_ACCURATE: 'GEOLOCATION_NOT_ACCURATE',
            GEOLOCATION_NOT_SUPPORTED: 'GEOLOCATION_NOT_SUPPORTED',
            GEOLOCATION_NO_DATA_FOUND: 'GEOLOCATION_NO_DATA_FOUND',
            POSITION_NOT_AVAILABLE: 'POSITION_NOT_AVAILABLE',
            GEOLOCATION_NO_INFO_FOUND_FOR_GIVEN_COORDS: 'GEOLOCATION_NO_INFO_FOUND_FOR_GIVEN_COORDS',
            TIMEOUT: 'TIMEOUT'
        };

        var _isLocationBlocked = false;

        var _lastPosition = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.LAST_POSITION) || null;
        var _savedLocation = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.LAST_SAVED_LOCATION) || {};

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////

        // Returns the text representation of the location based on given coordinates
        function getLocationTextForCoordinates(longitude, latitude, cb) {
            getLocationData(longitude, latitude, function (locationData) {
                // The rules to calculate the location are as follows (see BO7754)
                //
                // - Use locality to capture city name (1st position)
                //   - If not available, use administrative_area_level_3
                //   - If not available, use administrative_area_level_2
                // - Use administrative_area_level_1 for 2nd position (if it’s not the same with 1st position)
                //   - If not available, use postal_town (if it’s not the same with 1st position)
                // - Use country as 3rd position.
                var positions = [];
                var firstPos = locationData.locality ||
                    locationData.administrative_area_level_3 ||
                    locationData.administrative_area_level_2;
                var secondPos = locationData.administrative_area_level_1 || locationData.postal_town;
                var thirdPos = locationData.country;

                if (firstPos) {
                    positions.push(firstPos);
                }
                if (secondPos && secondPos !== firstPos) {
                    positions.push(secondPos);
                }
                if (thirdPos) {
                    positions.push(thirdPos);
                }

                var locationText = positions.join(', ');
                // Do not ever log the location - security and privacy
                LogSvc.debug('[LocationSvc]: Setting location');

                cb && cb(null, locationText);
            },
            function (err) {
                LogSvc.debug('[LocationSvc]: An error occurred while trying to retrieve location data', err);
                cb && cb(err, '');
            });
        }

        function getPosition(successCb, errorCb, options) {
            try {
                // navigator.geolocation is implemented by the mobile client
                if (!$window.navigator.geolocation) {
                    LogSvc.error('[LocationSvc]: Geolocation API not supported');
                    errorCb && errorCb(ERROR.GEOLOCATION_NOT_SUPPORTED);
                    return;
                }
                LogSvc.debug('[LocationSvc]: Starting request to get current position');

                var safetyTimeout = $timeout(function () {
                    safetyTimeout = null;
                    LogSvc.error('[LocationSvc]: Timed out waiting for Geolocation API');
                    errorCb && errorCb(ERROR.TIMEOUT);
                }, options.timeout + 500);

                $window.navigator.geolocation.getCurrentPosition(function (pos) {
                    if (safetyTimeout) {
                        $timeout.cancel(safetyTimeout);
                        prepareLocationAndReturn(pos.coords.latitude, pos.coords.longitude, pos.coords.accuracy, successCb, errorCb);
                    }
                }, function (err) {
                    if (!safetyTimeout) {
                        return;
                    }
                    $timeout.cancel(safetyTimeout);
                    $rootScope.$apply(function () {
                        if (err.code === err.PERMISSION_DENIED) {
                            LogSvc.warn('[LocationSvc]: Permission denied to get geolocation data. ', err);
                            _isLocationBlocked = true;
                        }
                        LogSvc.warn('[LocationSvc]: Failed to get geolocation data. ', err);
                        errorCb && errorCb(ERROR.GEOLOCATION_NO_DATA_FOUND);
                    });
                }, options);
            } catch (e) {
                LogSvc.error('[LocationSvc]: Failed to get geolocation data. ', e);
                errorCb && errorCb(ERROR.GEOLOCATION_NO_DATA_FOUND);
            }
        }

        // Get URL for the geolocation
        function getGeolocationUrl(longitude, latitude) {
            LogSvc.debug('[LocationSvc]: getLocationData using the geolocation mechanism that is based on geonames data.');
            return '/geocode?latlng=' + latitude + ',' + longitude + '&language=' + LocalizeSvc.getLanguageOnly();
        }

        function getLocationData(longitude, latitude, successCb, errorCb) {
            if (!successCb) {
                // There is no point in getting the location data without a success callback
                LogSvc.error('[LocationSvc]: Could not getLocationData: Success callback is missing.');
                return;
            }
            if (longitude && latitude) {
                var url = getGeolocationUrl(longitude, latitude);
                $http.get(url)
                .then(function (response) {
                    var data = response.data;
                    if (data && data.results && data.results[0]) {
                        var result = data.results[0];
                        var locationData = { };
                        result.address_components.forEach(function (component) {
                            component.types && component.types.forEach(function (type) {
                                locationData[type] = component.long_name;
                            });
                        });
                        // Do not ever log the location - security and privacy
                        LogSvc.debug('[LocationSvc]: Obtained location data');
                        successCb(locationData);
                    } else {
                        LogSvc.error('[LocationSvc]: No results');
                        errorCb && errorCb(ERROR.GEOLOCATION_NO_INFO_FOUND_FOR_GIVEN_COORDS);
                    }
                })
                .catch(function (response) {
                    LogSvc.warn('[LocationSvc]: geocode request error: ', response.status);
                    errorCb && errorCb(ERROR.GEOLOCATION_NO_INFO_FOUND_FOR_GIVEN_COORDS);
                });
            } else {
                LogSvc.warn('[LocationSvc]: No longitude or latitude data');
                errorCb && errorCb(ERROR.GEOLOCATION_NO_INFO_FOUND_FOR_GIVEN_COORDS);
            }
        }

        function significantlyChanged(latitude, longitude, prevLatitude, prevLongitude) {
            if (latitude && longitude) {
                if (!prevLatitude || !prevLongitude) {
                    return true; // No previous values, position changed
                }
                var diffLat = Math.abs(latitude - prevLatitude);
                var diffLng = Math.abs(longitude - prevLongitude);
                return (diffLng >= SIGNIFICANCE_FACTOR_RAD) || (diffLat >= SIGNIFICANCE_FACTOR_RAD);
            }
            return false;
        }

        function getLocationText(longitude, latitude, cb) {
            if (_savedLocation.locationText) {
                var hasMovedSignificantly = significantlyChanged(longitude, latitude, _savedLocation.longitude, _savedLocation.latitude);
                // Don't call getLocationTextForCoordinates if position has not changed more than 10km.
                if (!hasMovedSignificantly) {
                    // No changes and already have a location. Keep using cached location.
                    cb(_savedLocation.locationText);
                    return;
                }
            }

            getLocationTextForCoordinates(longitude, latitude, function (err, locationText) {
                if (err || !locationText) {
                    LogSvc.warn('[LocationSvc]: Failed to get location text: ', err);
                    cb(''); // Reset location to force an update later
                } else {
                    // Update the new location data in local store
                    _savedLocation = {
                        longitude: longitude,
                        latitude: latitude,
                        locationText: locationText
                    };
                    LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.LAST_SAVED_LOCATION, _savedLocation);
                    cb(locationText);
                }
            });
        }

        function prepareLocationAndReturn(latitude, longitude, accuracy, successCb, errorCb) {
            $rootScope.$apply(function () {
                _isLocationBlocked = false;

                if (!accuracy || !latitude || !longitude) {
                    LogSvc.warn('[LocationSvc]: Geolocation inconsistent data');
                    errorCb && errorCb(ERROR.GEOLOCATION_NO_DATA_FOUND);
                    return;
                }
                if (accuracy > ACCURACY_THRESHOLD) {
                    LogSvc.warn('[LocationSvc]: Geolocation data not accurate enough, Current accuracy: ' + accuracy + ', ACCURACY_THRESHOLD: ' + ACCURACY_THRESHOLD);
                    errorCb && errorCb(ERROR.GEOLOCATION_NOT_ACCURATE);
                    return;
                }
                latitude = Math.round(latitude * 100) / 100; // Round to only 2 last decimal digits
                longitude = Math.round(longitude * 100) / 100;

                // Do not ever log the location - security and privacy
                LogSvc.info('[LocationSvc]: Retrieved geolocation data -> accuracy:' + accuracy);

                // Save retrieved position
                _lastPosition = {
                    latitude: latitude,
                    longitude: longitude,
                    accuracy: accuracy,
                    timestamp: Date.now()
                };
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.LAST_POSITION, _lastPosition);

                successCb && successCb(latitude, longitude, accuracy);
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Get position google object {longitude,latitude,accuracy}
         *
         * @param {object} options {enableHighAccuracy,timeout,maximumAge}
         * @param {Function} errorCb The callback function when an error occurs.
         * @param {Function} successCb The callback function when position object is returned from server.
        */
        this.getPosition = function (successCb, errorCb, options) {
            options = options || {};
            options.enableHighAccuracy = options.enableHighAccuracy || true;
            options.timeout = options.timeout || 10000; // 10 sec
            options.maximumAge = options.maximumAge || DEFAULT_MAX_AGE;

            if (_lastPosition && (Date.now() - _lastPosition.timestamp <= options.maximumAge)) {
                // Return saved location
                LogSvc.debug('[LocationSvc]: Use last position retrieved on ' + new Date(_lastPosition.timestamp));
                successCb && successCb(_lastPosition.latitude, _lastPosition.longitude, _lastPosition.accuracy);
            } else {
                getPosition(successCb, errorCb, options);
            }
        };

        /**
         * Get locationData object
         *
         * @param {object} presenceObj {enableHighAccuracy,timeout,maximumAge}
         * @param {Function} errorCb The callback function when an error occurs.
         * @param {Function} successCb The callback function when position object is returned from server.
        */
        this.reverseGeocode = function (presenceObj, successCb, errorCb) {
            getLocationData(presenceObj.longitude, presenceObj.latitude, successCb, errorCb);
        };

        /**
         * Get Timezone Offset
        */
        this.getTimeZoneOffset = function () {
            return new Date().getTimezoneOffset();
        };

        /**
         * Retrieves location text (reverse geocoding) based on coordinates if significant position change based on localStore saved value
         *
         * @param {String} longitude
         * @param {String} latitude
         * @param cb callback
        */
        this.getLocationText = function (longitude, latitude, cb) {
            getLocationText(longitude, latitude, cb);
        };

        this.isLocationBlocked = function () {
            return !!_isLocationBlocked;
        };

        /**
         * Checks whether the new coordinates have changed significantly compared with the old coordinates.
         *
         * @param {Number} latitude
         * @param {Number} longitude
         * @param {Number} prevLatitude
         * @param {Number} prevLongitude
         * @return true if the coordinates have changed; false otherwise.
        */
        this.significantlyChanged = significantlyChanged;

        /**
         * Exposes the error constants to be used outside this service
         */
        this.ERROR = ERROR;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.LocationSvcImpl = LocationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
