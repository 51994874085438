var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var CstaCallState = circuit.Enums.CstaCallState;
    var DefaultAvatars = circuit.DefaultAvatars;
    var logger = circuit.logger;
    var ParticipantState = circuit.Enums.ParticipantState;
    var RtcParticipant = circuit.RtcParticipant;
    var UserProfile = circuit.UserProfile;
    var Utils = circuit.Utils;

    /*
     * Enum for call direction
     * @readonly
     * @enum {String}
     * @property INCOMING
     * @property OUTGOING
     * @property NONE
     */
    var CallDirection = Object.freeze({
        INCOMING: 'incoming',
        OUTGOING: 'outgoing',
        NONE: ''
    });

    /*
     * Enum for call state
     * @readonly
     * @enum {String}
     * @property Idle
     * @property Initiated Outgoing call initiated
     * @property Connecting Outgoing call connecting
     * @property Delivered Outgoing call delivered
     * @property Busy Outgoing call with peer busy
     * @property Failed Failed call
     * @property Declined Declined call
     * @property NotAnswered Call not answered
     * @property Ringing Incoming call is ringing
     * @property Answering Incoming call being answered
     * @property Active Established active call
     * @property Held Established call being held
     * @property Holding Established call holding
     * @property HoldOnHold Established call hold on hold
     * @property Waiting Established call waiting
     * @property Started Conference call started
     * @property ActiveRemote Active call on one of user's other devices
     * @property Terminated Call has been terminated
     */
    var CallState = Object.freeze({
        Idle: {name: 'Idle', ui: '', established: false},

        // Outgoing call states
        Initiated: {name: 'Initiated', ui: 'res_Calling', established: false, css: 'outgoing'},
        Connecting: {name: 'Connecting', ui: '', established: false, css: 'connecting'},
        Delivered: {name: 'Delivered', ui: 'res_Calling', established: false, css: 'outgoing'},
        Busy: {name: 'Busy', ui: 'res_BusyCall', established: false, css: 'busy'},

        // Failed call states
        Failed: {name: 'Failed', ui: 'res_FailedCall', established: false, css: 'failed'},
        Declined: {name: 'Declined', ui: 'res_CallDeclined', established: false, css: 'declined'},
        NotAnswered: {name: 'NotAnswered', ui: 'res_CallNotAnswered', established: false, css: 'not-answered'},

        // Incoming call states
        Ringing: {name: 'Ringing', ui: 'res_IncomingCall', established: false, css: 'incoming'},
        Answering: {name: 'Answering', ui: 'res_IncomingCall', established: false, css: 'answering'}, // Call answered but not Active yet

        // Established call states
        Active: {name: 'Active', ui: '', established: true, css: 'active-call'},
        Held: {name: 'Held', ui: '', established: true, css: 'active-call'},
        Holding: {name: 'Holding', ui: 'res_OnHold', established: true, css: 'holding-call'},
        HoldOnHold: {name: 'HoldOnHold', ui: 'res_Holding', established: true, css: 'holding-call'},
        Waiting: {name: 'Waiting', ui: 'res_Waiting', established: true, css: 'waiting'},

        // Remote calls
        Started: {name: 'Started', ui: 'res_InProgress', established: false, css: 'started'},
        NotStarted: {name: 'NotStarted', ui: '', established: false, css: 'not-started'},

        // Remote call active on another client
        ActiveRemote: {name: 'ActiveRemote', ui: 'res_RemoteCall', established: true, css: 'active-call-remote'},

        // Call has been terminated
        Terminated: {name: 'Terminated', ui: 'res_Terminated', established: false, css: 'declined'}
    });

    // Define an abstract BaseCall class
    // eslint-disable-next-line max-lines-per-function
    function BaseCall(conversation, isRemote) { // NOSONAR

        if (!conversation) {
            throw new Error('Cannot create BaseCall object without a conversation');
        }

        // Make sure that isRemote is true or fase
        isRemote = !!isRemote;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        //
        // These variables can only be accessed via getters/setters.
        // Critical variables that cannot be changed directly must be defined here.
        ///////////////////////////////////////////////////////////////////////////////////////
        var _that = this;

        var _callId = null;
        var _instanceId = null;
        var _convId = null;
        var _convType = null;
        var _convTitle = null;
        var _convTitleEscaped = null;
        var _ownerId = null;
        var _transactionId = null;
        var _isDirect = false;
        var _isTelephonyCall = false;
        var _isTestCall = false;
        var _state = CallState.Idle;
        var _disconnectCause = null;
        var _isDirectUpgradedToConf = false; // Direct call upgraded to conference

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setConversationData(conv) {
            _callId = conv.rtcSessionId;
            _convId = conv.convId;
            _convType = conv.type;
            _convTitle = conv.topic || conv.participantFirstNames || null;
            _convTitleEscaped = conv.topicEscaped || conv.participantFirstNamesEscaped || null;
            _ownerId = conv.creatorId;
            _isDirect = conv.type === Constants.ConversationType.DIRECT;
            _isTelephonyCall = !!conv.isTelephony;
            _isTestCall = !!conv.testCall;

            if (_isDirect) {
                _that.peerUser = conv.peerUser;
            } else {
                delete _that.peerUser;
            }

            _that.peerUsers = conv.peerUsers;
            _that.numConvParticipants = conv.participants.length;
            _that.defaultAvatarColor = conv.defaultAvatarColor;
            _that.avatar = conv.avatar;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Properties
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            isRemote: {
                value: isRemote,
                writable: false,
                enumerable: true,
                configurable: false
            },
            callId: {
                get: function () { return _callId; },
                enumerable: true,
                configurable: false
            },
            cstaCallId: {
                get: function () {
                    if (!this.atcCallInfo) {
                        return null;
                    }
                    return this.atcCallInfo.getCstaCallId();
                },
                enumerable: true,
                configurable: false
            },
            instanceId: {
                get: function () { return _instanceId; },
                enumerable: true,
                configurable: false
            },
            transactionId: {
                get: function () { return _transactionId; },
                enumerable: true,
                configurable: false
            },
            state: {
                get: function () { return _state; },
                enumerable: true,
                configurable: false
            },
            convId: {
                get: function () { return _convId; },
                enumerable: true,
                configurable: false
            },
            convType: {
                get: function () { return _convType; },
                enumerable: true,
                configurable: false
            },
            convTitle: {
                get: function () {
                    // Don't set the title for direct call in setConversationData, because peerUser can have no data in that moment.
                    return _convTitle || (_isDirect ? this.peerUser.displayName : '');
                },
                enumerable: true,
                configurable: false
            },
            convTitleEscaped: {
                get: function () {
                    return _convTitleEscaped || (_isDirect ? this.peerUser.displayNameEscaped : '');
                },
                enumerable: true,
                configurable: false
            },
            containsExternals: {
                get: function () {
                    return this.participants.some(function (p) {
                        return p.participantType === Constants.RTCParticipantType.SESSION_GUEST ||
                            p.participantType === Constants.RTCParticipantType.EXTERNAL;
                    });
                },
                enumerable: false,
                configurable: false
            },
            ownerId: {
                get: function () { return _ownerId; },
                enumerable: true,
                configurable: false
            },
            isDirect: {
                get: function () {
                    // Return false if this is a direct call that has been upgraded to a conference
                    return (_isDirect && !_isDirectUpgradedToConf) || _isTelephonyCall;
                },
                enumerable: true,
                configurable: false
            },
            conferenceCall: {
                get: function () {
                    // All group calls are conference calls.
                    return !_isDirect || _isDirectUpgradedToConf;
                },
                enumerable: true,
                configurable: false
            },
            isGroupCallStarted: {
                get: function () {
                    return !!(!this.isDirect && this.state === CallState.Started);
                },
                enumerable: true,
                configurable: false
            },
            isGroupCallInitiated: {
                get: function () {
                    return !!(!this.isDirect && this.state === CallState.Initiated);
                },
                enumerable: true,
                configurable: false
            },
            isGroupCallActive: {
                get: function () {
                    return !!(!this.isDirect && this.state === CallState.Active);
                },
                enumerable: true,
                configurable: false
            },
            isTelephonyCall: {
                get: function () { return _isTelephonyCall; },
                enumerable: true,
                configurable: false
            },
            isTestCall: {
                get: function () { return _isTestCall; },
                enumerable: true,
                configurable: false
            },
            conversationFeedView: {
                get: function () { return true; },
                enumerable: true,
                configurable: true // Let LocalCall object redefine this property
            },
            isPullAllowed: {
                value: false,
                enumerable: true,
                configurable: true  // Let atcRemoteCall object redefine this property
            },
            isHandoverAllowed: {
                get: function () {
                    if (!this.atcCallInfo) {
                        return false;
                    }
                    return this.atcCallInfo.isHandoverAllowed();
                },
                enumerable: true,
                configurable: true
            },
            isHandoverInProgress: {
                get: function () {
                    if (!this.atcCallInfo) {
                        return false;
                    }
                    return this.atcCallInfo.isHandoverInProgress();
                },
                enumerable: true,
                configurable: true
            },
            redirectingUser: {
                get: function () {
                    return this.getRedirectingUser();
                },
                enumerable: true,
                configurable: true
            },
            forwarded: {
                get: function () {
                    return this.isForwarded();
                },
                enumerable: true,
                configurable: true
            },
            pickupNotification: {
                get: function () {
                    return this.isPickupNotification();
                },
                enumerable: true,
                configurable: true
            },
            pickedUp: {
                get: function () {
                    return this.isPickedUp();
                },
                enumerable: true,
                configurable: true
            },
            disconnectCause: {
                get: function () { return _disconnectCause; },
                set: function (cause) { _disconnectCause = cause; },
                enumerable: true,
                configurable: false
            },
            transferCallFailedCause: {
                get: function () {
                    if (!this.atcCallInfo) {
                        return null;
                    }
                    return this.atcCallInfo.getTransferCallFailedCause();
                },
                enumerable: true,
                configurable: false
            },
            isOutgoingCallCampedOn: {
                get: function () {
                    if (!this.atcCallInfo) {
                        return false;
                    }
                    return this.atcCallInfo.isOutgoingCallCampedOn();
                },
                enumerable: true,
                configurable: true
            },
            isATCCall: {
                get: function () {
                    return !!this.atcCallInfo;
                },
                enumerable: true,
                configurable: false
            },
            isDtmfAllowed: {
                get: function () {
                    return false;
                },
                enumerable: true,
                configurable: true
            },
            isDirectUpgradedToConf: {
                get: function () {
                    return _isDirectUpgradedToConf;
                },
                enumerable: true,
                configurable: false
            },
            muted: {
                get: function () {
                    return this.isMuted();
                },
                enumerable: true,
                configurable: false
            },
            holding: {
                get: function () {
                    return this.isHolding();
            },
            enumerable: true,
            configurable: false
            }
        });

        // The media types for the local user
        this.localMediaType = {audio: false, video: false, desktop: false};

        // The media types for the call
        this.mediaType = {audio: false, video: false, desktop: false};

        // Remember the last media type before the call is terminated
        this.lastMediaType = this.mediaType;

        this.direction = CallDirection.NONE;
        this.secureCall = false;

        this.establishedTime = 0; // The timestamp when the call is established
        this.creationTime = Date.now(); // Client side creation time. Used for sorting.

        this.peerUsers = null;
        this.peerUser = null;
        this.numConvParticipants = 0;
        this.avatar = null;

        this.activeNotification = null;

        this.activeRTPStatsWarning = null;

        this.participants = []; // Array of RtcParticipants instances
        this.participantsHashTable = {};
        this.attendeeCount = 0; // Number of all guests in a large conference (non moderators)

        this.remotelyMuted = false; // true if local user is muted remotely
        this.sessionMuted = false;
        this.sessionLocked = false;

        this.recording = {
            state: Constants.RecordingInfoState.INITIAL,
            notifyByCurtain: false,
            notifyByUser: false,
            duration: 0,
            starter: {userId: ''},
            reason: Constants.RecordingInfoReason.NONE,
            resumeTime: 0,  // internal value
            wasStarted: function () {
                // was started at some point
                return this.state !== Constants.RecordingInfoState.INITIAL;
            },
            isActive: function () {
                return this.state === Constants.RecordingInfoState.STARTED || this.state === Constants.RecordingInfoState.START_PENDING;
            },
            isPaused: function () {
                return this.state === Constants.RecordingInfoState.START_PENDING;
            },
            isFailed: function () {
                return this.reason !== Constants.RecordingInfoReason.NONE &&
                        this.reason !== Constants.RecordingInfoReason.STOPPED_MANUALLY &&
                        this.reason !== Constants.RecordingInfoReason.STOPPED_AUTOMATICALLY &&
                        (this.state === Constants.RecordingInfoState.INITIAL || this.state === Constants.RecordingInfoState.STOPPED);
            }
        };

        this.transcription = {
            state: Constants.RecordingInfoState.INITIAL,
            wasStarted: function () {
                // was started at some point
                return this.state !== Constants.RecordingInfoState.INITIAL;
            },
            isActive: function () {
                return this.state === Constants.RecordingInfoState.STARTED || this.state === Constants.RecordingInfoState.START_PENDING;
            }
        };

        this.upgradeToConfSupported = false;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Members
        // (Only includes members which need to access private data)
        ///////////////////////////////////////////////////////////////////////////////////////
        this.setState = function (newState, bypassTerminate) {
            if (!isRemote && newState !== CallState.Terminated && this.checkCstaState(CstaCallState.Holding)) {
                newState = CallState.Holding;
            }

            // If it's an ATC call and the CSTA Established has not yet been received, don't set the established time,
            // unless the CSTA state is Initiated, which indicates that this is a feature activation call.
            if (!this.establishedTime && newState.established &&
                (!this.atcCallInfo || this.atcCallInfo.cstaState.established || this.atcCallInfo.cstaState === CstaCallState.Initiated)) {
                this.establishedTime = Date.now();
            }

            if (!newState || newState === _state) {
                return;
            }
            if (_state === CallState.Terminated) {
                logger.error('[BaseCall]: The call has already been terminated. Cannot set state to ', newState.name);
                return;
            }
            logger.debug('[BaseCall]: Changing call state from ' + _state.name + ' to ' + newState.name);

            _state = newState;

            if (this.isTelephonyCall) {
                this.avatar = newState === CallState.Holding ? DefaultAvatars.TELEPHONY_HOLD : DefaultAvatars.TELEPHONY;
            }

            if (_state === CallState.Terminated && !bypassTerminate) {
                this.terminate();
            }
        };

        this.checkState = function (states) {
            if (!Array.isArray(states)) {
                return states === _state;
            }
            return states.includes(_state);
        };

        this.updateCall = function (newConversation) {
            if (!newConversation) {
                return false;
            }
            setConversationData(newConversation);
            return true;
        };

        this.setPeerUser = function (phoneNumber, displayName, userId, fqnLookedUp, data) {
            var extAvatarUri, smallImageUri, largeImageUri;
            if (data) {
                extAvatarUri = data.extAvatarUri;
                smallImageUri = data.smallImageUri;
                largeImageUri = data.largeImageUri;
            }
            // Used for telephony calls
            if (this.peerUser.hasTelephonyRole || !this.peerUser.userId || (userId && userId !== this.peerUser.userId)
                || !this.peerUser.phoneNumber || Utils.cleanPhoneNumber(phoneNumber) !== Utils.cleanPhoneNumber(this.peerUser.phoneNumber)
                || phoneNumber === 'anonymous') {
                this.peerUser = {
                    userId: userId || '',
                    firstName: null,
                    lastName: null,
                    displayName: displayName,
                    phoneNumber: phoneNumber,
                    fqnLookedUp: fqnLookedUp,
                    extAvatarUri: extAvatarUri,
                    smallImageUri: smallImageUri,
                    largeImageUri: largeImageUri
                };
                if (UserProfile && UserProfile.extend) {
                    this.peerUser = UserProfile.extend(this.peerUser);
                }
                _convTitle = displayName || phoneNumber || null;
                _convTitleEscaped = _convTitle ? Utils.textToHtmlEscaped(_convTitle) : null;

            }
        };

        this.setTransactionId = function (transactionId) {
            _transactionId = transactionId || Utils.createTransactionId();
        };

        this.clearTransactionId = function () {
            _transactionId = null;
        };

        this.setInstanceId = function (instanceId) {
            _instanceId = instanceId;
        };

        this.setCallIdForTelephony = function (callId) {
            if (_that.isTelephonyCall && callId && callId !== _callId) {
                logger.debug('[BaseCall]: Changing callId from ' + _callId + ' to ' + callId);
                _callId = callId;
            }
        };

        this.setDirectUpgradedToConf = function () {
            if (_isDirect) {
                _isDirectUpgradedToConf = true;
            }
        };

        this.clearDirectUpgradedToConf = function () {
            if (_isDirect) {
                _isDirectUpgradedToConf = false;
            }
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        setConversationData(conversation);
    }

    BaseCall.prototype.sameAs = function (call) {
        return !!call && this.callId === call.callId && this.isRemote === call.isRemote;
    };

    BaseCall.prototype.terminate = function () {
        logger.debug('[BaseCall]: Terminating call with callId = ', this.callId);
        this.lastMediaType = this.mediaType;
        this.mediaType = {audio: false, video: false, desktop: false};
        this.setState(CallState.Terminated, true);
    };

    BaseCall.prototype.isEstablished = function () { return this.state.established; };

    BaseCall.prototype.isOutgoingState = function () {
        return this.state === CallState.Initiated || this.state === CallState.Connecting ||
               this.state === CallState.Delivered || this.state === CallState.Busy;
    };

    BaseCall.prototype.isPresent = function () {
        return this.state !== CallState.Idle && this.state !== CallState.Terminated;
    };

    BaseCall.prototype.isHeld = function () { return this.state === CallState.Held; };

    BaseCall.prototype.hasRemoteMedia = function () {
        return false;
    };

    BaseCall.prototype.isCallingOut = function () {
        return this.state === CallState.Waiting && this.participants.length > 0 && this.participants.every(function (p) {
            return p.pcState === ParticipantState.Initiated;
        });
    };

    BaseCall.prototype.hasVideo = function () {
        // Screen share is currently handled as a video stream, so return true
        // if the media type includes either video or desktop (i.e. screen share)
        return !!(this.mediaType.video || this.mediaType.desktop);
    };

    BaseCall.prototype.updateSecurityStatus = function (status) {
        // Only update secureCall if status is explicitly set to true or false.
        // Other truthy or falsy values (e.g. undefined or null) must be ignored.
        if (status === true || status === false) {
            this.secureCall = status;
        }
    };

    // RTC methods
    BaseCall.prototype.canToggleVideo = function () {
        return !this.isRemote && this.checkState([CallState.Active, CallState.Waiting]);
    };

    BaseCall.prototype.hasRemoteVideo = function () {
        return false;
    };

    BaseCall.prototype.hasLocalVideo = function () {
        // Screen share is currently handled as a video stream, so return true
        // if the client is streaming either video or desktop (i.e. screen share).
        return this.localMediaType.video || this.localMediaType.desktop;
    };

    BaseCall.prototype.hasLocalScreenShare = function () {
        return this.localMediaType.desktop;
    };

    BaseCall.prototype.hasRemoteScreenShare = function () {
        return this.mediaType.desktop && !this.localMediaType.desktop;
    };

    BaseCall.prototype.isMuted = function () { return false; };

    BaseCall.prototype.mute = function () {
        logger.warning('[BaseCall]: Mute is not implemented in base object');
        return false;
    };

    BaseCall.prototype.unmute = function () {
        logger.warning('[BaseCall]: Unmute is not implemented in base object');
        return false;
    };

    BaseCall.prototype.toggleMute = function () {
        logger.warning('[BaseCall]: toggleMute is not implemented in base object');
        return false;
    };

    BaseCall.prototype.outgoingFailed = function () {
        return !this.isRemote &&
            this.direction === CallDirection.OUTGOING &&
            this.checkState([CallState.Declined, CallState.Busy, CallState.NotAnswered, CallState.Failed]);
    };

    BaseCall.prototype.setPeerUsersAsParticipants = function () {
        // Populate participants for outgoing Group Calls (RTC.JOIN)
        if (this.direction !== CallDirection.OUTGOING || !this.peerUsers) {
            return;
        }

        this.participants = this.peerUsers.map(function (user) {
            // Create a new object using the member's UserProfile object as prototype
            return RtcParticipant.createFromUser(user, ParticipantState.Initiated);
        });
        this.participantsHashTable = this.participants.reduce(function (hash, p) {
            hash[p.userId] = p;
            return hash;
        }, {});

        logger.debug('[BaseCall]: Initialized participants: ', this.participants);
    };

    BaseCall.prototype.hasParticipant = function (userId) {
        return this.participants.some(function (p) {
            return p.userId === userId;
        });
    };

    BaseCall.prototype.getParticipant = function (userId) {
        return this.participants.find(function (p) {
            return p.userId === userId;
        }) || null;
    };

    function checkParticipantPermissions(participant, call) {
        var supportedFeatures = participant.rtcSupportedFeatures || [];

        if (call.isDirect) {
            if (supportedFeatures.includes(Constants.RtcSupportedFeatures.UPGRADE_TO_CONFERENCE)) {
                logger.debug('[BaseCall]: Upgrade to conference is supported by peer client');
                call.upgradeToConfSupported = true;
            } else {
                logger.debug('[BaseCall]: Upgrade to conference is not supported by peer client');
                call.upgradeToConfSupported = false;
            }
        }
    }

    BaseCall.prototype.addParticipant = function (participant, pcState, update) {
        if (!participant || !participant.userId) {
            logger.warning('[BaseCall]: addParticipant called with invalid participant');
            return null;
        }

        logger.debug('[BaseCall]: Add participant with userId =', participant.userId);
        if (this.hasParticipant(participant.userId)) {
            if (update) {
                return this.updateParticipant(participant, pcState);
            }
            logger.warning('[BaseCall]: addParticipant called with existing participant. userId =', participant.userId);
            return null;
        }

        if (pcState) {
            participant.pcState = pcState;
        }
        this.participants.push(participant);
        this.participantsHashTable[participant.userId] = participant;
        checkParticipantPermissions(participant, this);

        logger.debug('[BaseCall]: Added participant to call: ', participant.userId);
        return participant;
    };

    BaseCall.prototype.updateParticipant = function (participant, pcState) {
        if (!participant || !participant.userId) {
            logger.warning('[BaseCall]: updateParticipant called with invalid participant');
            return null;
        }

        logger.debug('[BaseCall]: Update participant with userId =', participant.userId);
        var curr = this.getParticipant(participant.userId);
        if (!curr) {
            logger.warning('[BaseCall]: The given participant is not part of the call');
            return null;
        }

        pcState = pcState || participant.pcState;

        if (curr.isMeetingPointInvitee && curr.locallyMuted) {
            // CMR is locally muted
            curr.muted = true;
            curr.pcState = pcState !== ParticipantState.Active ? pcState : ParticipantState.Muted;
        } else {
            curr.muted = participant.muted;
            curr.pcState = pcState;
        }

        curr.streamId = participant.streamId;
        curr.screenStreamId = participant.screenStreamId;
        curr.mediaType = participant.mediaType;
        curr.screenSharePointerSupported = participant.screenSharePointerSupported;
        curr.isModerator = participant.isModerator;
        curr.isMeetingGuest = participant.isMeetingGuest;
        curr.rtcSupportedFeatures = participant.rtcSupportedFeatures;
        curr.flags = participant.flags;
        if (participant.participantType === Constants.RTCParticipantType.TELEPHONY) {
            curr.displayName = participant.displayName;
            curr.firstName = participant.firstName;
            curr.lastName = participant.lastName;
            curr.phoneNumber = participant.phoneNumber;
        }
        checkParticipantPermissions(curr, this);

        logger.debug('[BaseCall]: Updated participant data for ', curr.userId);
        return curr;
    };

    BaseCall.prototype.removeParticipant = function (userId) {
        delete this.participantsHashTable[userId];

        var participants = this.participants;
        return participants.find(function (p, idx) {
            if (p.userId === userId) {
                // Reset the participant's video URL and media type
                p.videoStream = null;
                p.mediaType = {audio: false, video: false, desktop: false};

                participants.splice(idx, 1);
                logger.debug('[BaseCall]: Removed participant from call: ', p.userId);
                return true;
            }
            return false;
        }) || null;
    };

    BaseCall.prototype.setParticipantState = function (userId, state) {
        if (!state) {
            return;
        }
        this.participants.some(function (p) {
            if (p.userId === userId) {
                p.pcState = state;
                logger.debug('[BaseCall]: Set participant state to ' + state.name + ', userId =', p.userId);
                return true;
            }
            return false;
        });
    };

    BaseCall.prototype.hasOtherParticipants = function () {
        // For large conference there might be a single moderator and multiple attendees not included in participants field.
        // For direct or regular group call all other participants are available in the participants field.
        return this.participants.length > 0 || this.attendeeCount > 0;
    };

    BaseCall.prototype.updateMediaType = function () {
        var mediaType = {
            audio: this.localMediaType.audio,
            video: this.localMediaType.video,
            desktop: this.localMediaType.desktop
        };

        this.participants.forEach(function (p) {
            if (p.mediaType) {
                if (p.mediaType.audio) {
                    mediaType.audio = true;
                }
                if (p.mediaType.video) {
                    mediaType.video = true;
                }
                if (p.mediaType.desktop) {
                    mediaType.desktop = true;
                }
            }
        });

        logger.debug('[BaseCall]: Updating call media type to ', mediaType);
        this.mediaType = mediaType;
    };

    BaseCall.prototype.callTypeCss = function () {
        if (this.isDirect) {
            return this.hasVideo() ? 'webrtc-video' : 'webrtc-audio';
        }
        if (this.isRemote) {
            return 'remote';
        }
        // Group call
        switch (this.state.css) {
        case 'outgoing':
            return 'ongoing';
        case 'incoming':
            return 'incoming';
        case 'answering':
            return 'answering';
        case 'active-call':
            return 'active';
        default:
            return '';
        }
    };

    BaseCall.prototype.toString = function () {
        return JSON.stringify(this, null, 3);
    };

    BaseCall.prototype.getCstaState = function () {
        return this.atcCallInfo ? this.atcCallInfo.cstaState : null;
    };

    BaseCall.prototype.setCstaState = function (state) {
        if (this.atcCallInfo) {
            logger.debug('[BaseCall]: Set csta state to ', state);
            this.atcCallInfo.cstaState = state;
            if (this.isAtcRemote) {
                this.avatar = state === CstaCallState.Holding ? DefaultAvatars.TELEPHONY_HOLD : DefaultAvatars.TELEPHONY;
            }
            this.consultation = this.consultation && !this.isHolding();
        }
    };

    BaseCall.prototype.checkCstaState = function (states) {
        if (!this.atcCallInfo) {
            return false;
        }
        if (!Array.isArray(states)) {
            return states === this.atcCallInfo.cstaState;
        }
        return states.includes(this.atcCallInfo.cstaState);
    };

    BaseCall.prototype.isHolding = function () {
        return this.checkCstaState([CstaCallState.Holding, CstaCallState.HoldOnHold, CstaCallState.ConferenceHolding]);
    };

    BaseCall.prototype.isHoldAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isHoldAllowed() : false;
    };

    BaseCall.prototype.isConsultAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isConsultAllowed() : false;
    };

    BaseCall.prototype.isReconnectAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isReconnectAllowed() : false;
    };

    BaseCall.prototype.isConferenceCallAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isConferenceCallAllowed() : false;
    };

    BaseCall.prototype.isMakeCallAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isMakeCallAllowed() : false;
    };

    BaseCall.prototype.isRetrieveAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isRetrieveAllowed() : false;
    };

    BaseCall.prototype.isTransferCallAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isTransferCallAllowed() : false;
    };

    BaseCall.prototype.isTransferAllowed = function () {
        return this.atcCallInfo ? this.atcCallInfo.isTransferAllowed() : false;
    };

    BaseCall.prototype.getPosition = function () {
        return this.atcCallInfo ? this.atcCallInfo.getPosition() : null;
    };

    BaseCall.prototype.setPosition = function (p) {
        this.atcCallInfo && this.atcCallInfo.setPosition(p);
    };

    BaseCall.prototype.isAtPosition = function (p) {
        return this.atcCallInfo ? this.atcCallInfo.isAtPosition(p) : false;
    };

    BaseCall.prototype.setAtcHandoverInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.setHandoverInProgress();
        }
    };

    BaseCall.prototype.clearAtcHandoverInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.clearHandoverInProgress();
        }
    };

    BaseCall.prototype.isHoldInProgress = function () {
        return this.atcCallInfo ? this.atcCallInfo.isHoldInProgress() : false;
    };

    BaseCall.prototype.setHoldInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.setHoldInProgress();
        }
    };

    BaseCall.prototype.clearHoldInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.clearHoldInProgress();
        }
    };

    BaseCall.prototype.isRetrieveInProgress = function () {
        return this.atcCallInfo ? this.atcCallInfo.isRetrieveInProgress() : false;
    };

    BaseCall.prototype.setRetrieveInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.setRetrieveInProgress();
        }
    };

    BaseCall.prototype.clearRetrieveInProgress = function () {
        if (this.atcCallInfo) {
            this.atcCallInfo.clearRetrieveInProgress();
        }
    };

    BaseCall.prototype.isAtcConferenceCall = function () {
        return this.atcCallInfo ? this.atcCallInfo.isConferenceCall() : false;
    };

    BaseCall.prototype.setRedirectingUser = function (phoneNumber, fqNumber, displayName, userId, redirectionType) {
        if (this.atcCallInfo) {
            this.atcCallInfo.setRedirectingUser(phoneNumber, fqNumber, displayName, userId, redirectionType);
        }
    };

    BaseCall.prototype.getRedirectingUser = function () {
        return this.atcCallInfo ? this.atcCallInfo.getRedirectingUser() : null;
    };

    BaseCall.prototype.setRedirectionType = function (type) {
        this.atcCallInfo && this.atcCallInfo.setRedirectionType(type);
    };

    BaseCall.prototype.getRedirectionType = function () {
        return this.atcCallInfo ? this.atcCallInfo.getRedirectionType() : null;
    };

    BaseCall.prototype.isForwarded = function () {
        return this.atcCallInfo ? this.atcCallInfo.isForwarded() : false;
    };

    BaseCall.prototype.isPickupNotification = function () {
        return this.atcCallInfo ? this.atcCallInfo.isPickupNotification() : false;
    };

    BaseCall.prototype.isPickedUp = function () {
        return this.atcCallInfo ? this.atcCallInfo.isPickedUp() : false;
    };

    BaseCall.prototype.setDisconnectCause = function (cause, reason) {
        if (!this._disconnectCause) {
            this.disconnectCause = {
                cause: cause,
                reason: reason
            };
        }
    };

    // Exports
    circuit.BaseCall = BaseCall;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.CallDirection = CallDirection;
    circuit.Enums.CallState = CallState;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
