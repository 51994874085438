
var Circuit = (function (circuit) {
    'use strict';

    // Define the object
    var storage = null;

    try {
        storage = window.localStorage || storage;
    } catch (e) {
        // If access to the localStorage is denied use sessionStorage
        // This fixes problem with IE on Windows 8
        storage = window.sessionStorage || storage;
    }

    function invokeMethod(method, args) {
        try {
            return storage ? storage[method].apply(storage, args) : null;
        } catch (e) {
            return null;
        }
    }

    var storeManager = {
        getItem: function (key) {
            return invokeMethod('getItem', [key]);
        },
        setItem: function (key, value) {
            return invokeMethod('setItem', [key, value]);
        },
        removeItem: function (key) {
            return invokeMethod('removeItem', [key]);
        }
    };

    // Exports
    circuit.storeManager = storeManager;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
