var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var CallState = circuit.Enums.CallState;
    var CstaCallState = circuit.Enums.CstaCallState;
    var Constants = circuit.Constants;
    var Enums = circuit.Enums;
    var ParticipantAction = circuit.Enums.ParticipantAction;
    var Targets = circuit.Enums.Targets;
    var TransferCallFailedCauses = circuit.Enums.TransferCallFailedCauses;
    var Utils = circuit.Utils;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;

    ///////////////////////////////////////////////////////////////////////////////////////
    // CallControlSvc Implementation
    // Service that controls and manages WebRTC and CSTA calls
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function CallControlSvcImpl( // NOSONAR
        $rootScope,
        $timeout,
        LogSvc,
        PubSubSvc,
        CircuitCallControlSvc,
        ConversationSvc,
        CstaSvc,
        NotificationSvc,
        UserProfileSvc) {

        LogSvc.debug('New Service: CallControlSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var DTMF_PAUSE = 2000; // 2 seconds

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _atcRemoteCalls = [];
        var _that = this;
        var _userToUserHandler = UserToUserHandler.getInstance();
        var _defaultCallDevice;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function addAtcRemoteCallToList(call) {
            for (var idx = 0; idx < _atcRemoteCalls.length; idx++) {
                if (call.sameAs(_atcRemoteCalls[idx])) {
                    LogSvc.debug('[CallControlSvc]: Updating call with callId = ' + call.callId);
                    _atcRemoteCalls[idx] = call;
                    return;
                }
            }
            _atcRemoteCalls.push(call);
        }

        function sendDtmf(call) {
            var digits = call.dtmfDigits;
            if (digits) {
                delete call.dtmfDigits;

                // Count number of commas before the string
                var numCommas = digits.match(/^,*/)[0].length;
                var timerVal = numCommas * DTMF_PAUSE + 1000;
                if (numCommas) {
                    digits = digits.substr(numCommas);
                }

                call.sendDtmfTimer = $timeout(function () {
                    call.sendDtmfTimer = null;
                    // If call is still present, send the digits
                    if (call.isPresent()) {
                        LogSvc.debug('[CallControlSvc]: Send delayed DTMF digits ' + digits + ' for call ' + call.callId);
                        _that.sendDigits(call.callId, digits);
                    }
                }, timerVal);
            }
        }

        function removeAtcRemoteCallFromList(call) {
            // Remove it from the call list
            _atcRemoteCalls.some(function (remoteCall, idx) {
                if (call.sameAs(remoteCall)) {
                    _atcRemoteCalls.splice(idx, 1);
                    return true;
                }
                return false;
            });
        }

        function findAtcRemoteCall(callId) {
            return _atcRemoteCalls.find(function (call) {
                return call.callId === callId;
            }) || null;
        }

        function findAtcRemoteCallOnTarget(target) {
            return _atcRemoteCalls.find(function (call) {
                return call.isAtPosition(target);
            }) || null;
        }

        function findActiveAtcRemoteCall() {
            var activeRemoteConference = null;
            for (var idx = 0; idx < _atcRemoteCalls.length; idx++) {
                if (_atcRemoteCalls[idx].state === CallState.ActiveRemote) {
                    // Found an active call. Return it.
                    return _atcRemoteCalls[idx];
                }
                if (_atcRemoteCalls[idx].checkCstaState(CstaCallState.Conference)) {
                    // Keep the remote conference as backup, but continue looking
                    activeRemoteConference = _atcRemoteCalls[idx];
                }
            }
            return activeRemoteConference;
        }

        function findRingingAtcRemoteCall() {
            return _atcRemoteCalls.find(function (call) {
                return call.state === CallState.Ringing;
            }) || null;
        }

        function getActivePhoneCall() {
            var activeCall = _that.getActiveCall();
            if (activeCall && activeCall.isTelephonyCall) {
                return activeCall;
            }
            return null;
        }

        function publishCallState(call) {
            LogSvc.debug('[CallControlSvc]: Publish /call/state event. callId = ' + call.callId + ', state = ' + call.state.name);
            PubSubSvc.publish('/call/state', [call]);
        }

        function terminateCall(call) {
            call.terminate();
        }

        function findConversationByCallId(callId) {
            var conversation = ConversationSvc.getConversationByRtcSession(callId);
            if (conversation) {
                return conversation;
            }

            for (var idx = 0; idx < _atcRemoteCalls.length; idx++) {
                if (_atcRemoteCalls[idx].callId === callId) {
                    return ConversationSvc.getConversationFromCache(_atcRemoteCalls[idx].convId);
                }
            }
            return null;
        }

        function getAtcHandoverInProgressCall() {
            for (var idx = 0; idx < _atcRemoteCalls.length; idx++) {
                if (_atcRemoteCalls[idx].isHandoverInProgress) {
                    return _atcRemoteCalls[idx];
                }
            }
            return null;
        }

        function publishConversationUpdate(conversation) {
            if (conversation) {
                LogSvc.debug('[CallControlSvc]: Publish /conversation/update event. convId = ', conversation.convId);
                PubSubSvc.publish('/conversation/update', [conversation]);
            }
        }

        function setStateFromCstaState(call, conversation) {
            switch (call.getCstaState()) {
            case CstaCallState.Initiated:
            case CstaCallState.Offered:
            case CstaCallState.ExtendedRinging:
                call.setState(CallState.Started);
                break;
            case CstaCallState.Delivered:
                call.setState(CallState.Delivered);
                break;
            case CstaCallState.Busy:
                call.setState(CallState.Busy);
                break;
            case CstaCallState.Ringing:
                if (!call.checkState(CallState.Ringing)) {
                    call.setState(CallState.Ringing);
                    // Show the notification for this remote ATC call
                    NotificationSvc && NotificationSvc.show({
                        type: circuit.Enums.NotificationType.INCOMING_VOICE_CALL,
                        user: call.peerUser,
                        extras: {
                            conversation: conversation,
                            call: call
                        }
                    });
                }
                break;
            case CstaCallState.Idle:
            case CstaCallState.TransferFailed:
            case CstaCallState.Failed:
                call.setState(CallState.Idle);
                break;
            case CstaCallState.Terminated:
                call.setState(CallState.Terminated);
                break;
            default:
                call.setState(CallState.ActiveRemote);
                break;
            }
        }

        function handleAtcCallInfo(call) {
            if (!call) {
                LogSvc.warn('[CallControlSvc]: Invalid call object in /atccall/info event');
                return;
            }
            if (!call.isAtcRemote) {
                publishCallState(call);
                return;
            }
            // If the call.isAtcRemote (which means that it's on the desk or another OND device) then we publish the
            // /call/state event after mapping the CSTA state to a circuit call state.
            var conversation = ConversationSvc.getConversationFromCache(call.convId);
            if (!conversation || !conversation.isTelephony) {
                LogSvc.warn('[CallControlSvc]: Call in /atccall/info event is not for telephony conversation');
                return;
            }

            if (!call.checkCstaState([CstaCallState.Idle, CstaCallState.Terminated])) {
                addAtcRemoteCallToList(call);
                if (!conversation.call || (conversation.call.isHandoverInProgress && !conversation.call.sameAs(call)) ||
                    (conversation.call.isAtcRemote && (conversation.call.isHolding() || conversation.call.isHoldInProgress()))) {
                    if (conversation.call && conversation.call.isHandoverInProgress) {
                        var oldCall = conversation.call;
                        oldCall.clearAtcHandoverInProgress();
                        call.establishedTime = oldCall.establishedTime;
                        if (oldCall.isAtcRemote) {
                            removeAtcRemoteCallFromList(oldCall);
                        } else {
                            CircuitCallControlSvc.removeCallFromList(oldCall);
                        }
                        // We're terminating a local call, so set a terminate reason
                        oldCall.terminateReason = Enums.CallClientTerminatedReason.USER_ENDED;
                        terminateCall(oldCall);
                        LogSvc.debug('[CallControlSvc]: Publish /call/ended event.');
                        PubSubSvc.publish('/call/ended', [oldCall, false]);
                    }
                    conversation.call = call;
                }
            }

            setStateFromCstaState(call, conversation);

            if (call.state !== CallState.Idle && call.state !== CallState.Terminated && call.state !== CallState.Started) {
                publishConversationUpdate(conversation);
                publishCallState(call);
            } else if (call.state === CallState.Idle || call.state === CallState.Terminated) {
                terminateCall(call);
                removeAtcRemoteCallFromList(call);

                LogSvc.debug('[CallControlSvc]: Publish /call/ended event');
                PubSubSvc.publish('/call/ended', [call, false]);

                if (!call.isHandoverInProgress) {
                    if (conversation.call && conversation.call.sameAs(call)) {
                        conversation.call = findActiveAtcRemoteCall();
                    }

                }
                publishConversationUpdate(conversation);
            }
        }

        function handleAtcCallReplace(call) {
            var oldCall = getAtcHandoverInProgressCall();
            if (oldCall) {
                if (oldCall.atcCallInfo.transferCb) {
                    oldCall.atcCallInfo.transferCb(null, null, call);
                    delete oldCall.atcCallInfo.transferCb;
                }
                var conversation = ConversationSvc.getConversationFromCache(call.convId);
                call.establishedTime = oldCall.establishedTime;
                terminateCall(oldCall);
                removeAtcRemoteCallFromList(oldCall);
                if (conversation && conversation.call) {
                    conversation.call = call;
                    publishConversationUpdate(conversation);
                }
            }
        }

        function handleAtcHangingCall(callId) {
            ConversationSvc.getTelephonyConversation(function (err, conversation) {
                if (!err && conversation && conversation.call && conversation.call.atcCallInfo.getCstaCallId() === callId) {
                    conversation.call = null;
                    publishConversationUpdate(conversation);
                }
            });
        }

        function isLocalCall(callId) {
            var localCall = CircuitCallControlSvc.getActiveCall();
            return !!localCall && callId === localCall.callId;
        }

        function getOsBizConsultationCalls() {
            if (!$rootScope.localUser.isOsBizCTIEnabled) {
                return null;
            }
            var firstCall = CircuitCallControlSvc.findOsBizFirstCall();
            if (!firstCall) {
                return null;
            }
            var secondCall = CircuitCallControlSvc.findOsBizSecondCall();
            if (!secondCall) {
                return null;
            }
            var firstCallHolding = firstCall.checkCstaState(Enums.CstaCallState.Holding);
            return {
                activeCall: firstCallHolding ? secondCall : firstCall,
                heldCall: firstCallHolding ? firstCall : secondCall
            };
        }

        function sendTransferRequest(activeCall, destination, cb) {
            var otherCall;
            if (typeof destination === 'object') {
                otherCall = destination;
            }
            var data = {
                content: {
                    type: Enums.StcMessage.TRANSFER,
                    phoneNumber: $rootScope.localUser.callerId,
                    transferRequest: {
                        sessionId: activeCall.callId,
                        target: {
                            dn: otherCall ? otherCall.peerUser.phoneNumber : destination,
                            sessionId: otherCall ? otherCall.callId : undefined
                        }
                    }
                },
                destUserId: $rootScope.localUser.associatedTelephonyUserID
            };
            _userToUserHandler.sendStcRequest(data, function (err, resp) {
                if (err || (resp && resp.error)) {
                    var transferFailedCause = TransferCallFailedCauses.Unreachable.name;
                    if (resp && TransferCallFailedCauses[resp.error]) {
                        transferFailedCause = resp.error;
                    }
                    cb && cb(TransferCallFailedCauses[transferFailedCause].ui);
                } else {
                    if (otherCall && otherCall.isPresent() && !otherCall.state.established) {
                        _that.endCall(otherCall.callId);
                    }
                    cb && cb();
                }
            });
        }

        function canStartVideoParticipant(participant) {
            if (!participant.toggleVideoInProgress && participant.isMeetingPointInvitee) {
                return participant.mediaType && !participant.mediaType.video;
            }
            return false;
        }

        function canStopVideoParticipant(participant) {
            if (!participant.toggleVideoInProgress && participant.isMeetingPointInvitee) {
                return participant.mediaType && participant.mediaType.video;
            }
            return false;
        }

        function checkAndToggleVideoParticipant(call, participant, cb) {
            LogSvc.buttonPressed('Toggle video for participant with userId: ', participant.userId);
            if (!participant.isMeetingPointInvitee) {
                LogSvc.info('[CallControlSvc]: Cannot toggle video participant for ', participant.userId);
            } else {
                participant.toggleVideoInProgress = true;
                // To simplify code, because conversation is used only here, he just retrieve it from cache instead of binding it
                var conv = ConversationSvc.getConversationFromCache(call.convId);
                participant.setActions(conv, $rootScope.localUser);
                _that.toggleVideoParticipant(call.callId, participant, function (err) {
                    participant.toggleVideoInProgress = false;
                    participant.setActions(conv, $rootScope.localUser);
                    if (err) {
                        LogSvc.error('[CallControlSvc]: Cannot toggle video participant. ', err);
                        cb(err);
                    }
                });
            }
        }

        function canMuteParticipant(participant) {
            return !participant.muted && (participant.userId === $rootScope.localUser.userId || participant.actions.includes(ParticipantAction.Mute));
        }

        function canUnmuteParticipant(participant) {
            return participant.muted && (participant.userId === $rootScope.localUser.userId || participant.actions.includes(ParticipantAction.Unmute));
        }

        function mergeCalls(callId1, callId2, cb) {
            CircuitCallControlSvc.mergeCalls(callId1, callId2)
            .then(function () {
                cb();
            })
            .catch(function (err) {
                LogSvc.warn('[CallControlSvc]: Merge call failed');
                cb(err);
            });
        }


        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/atccall/info', function (call) {
            LogSvc.debug('[CallControlSvc]: Received /atccall/info event');
            handleAtcCallInfo(call);
        });

        PubSubSvc.subscribe('/atccall/replace', function (call) {
            LogSvc.debug('[CallControlSvc]: Received /atccall/replace event');
            handleAtcCallReplace(call);
        });

        PubSubSvc.subscribe('/call/ended', function (call) {
            LogSvc.debug('[CallControlSvc]: Received /call/ended event');
            // Cancel the DTMF timer if its running
            if (call.sendDtmfTimer) {
                $timeout.cancel(call.sendDtmfTimer);
                call.sendDtmfTimer = null;
            }
            delete call.dtmfDigits;

            if (call.isAtcRemote) {
                call.clearAtcHandoverInProgress();
                removeAtcRemoteCallFromList(call);
            } else {
                var conversation = ConversationSvc.getConversationFromCache(call.convId);
                if (conversation && conversation.isTelephony && !conversation.call) {
                    conversation.call = findActiveAtcRemoteCall() || findRingingAtcRemoteCall();
                    if (conversation.call) {
                        publishConversationUpdate(conversation);
                    }
                }
                if (!call.isRemote && !CircuitCallControlSvc.getActiveCall()) {
                    // Cancel the auto snooze if applicable
                    UserProfileSvc.cancelAutoSnooze();
                }
            }
        });

        PubSubSvc.subscribe('/atccall/hangingcall', function (callid) {
            LogSvc.debug('[CallControlSvc]: Received /atccall/hangingcall event');
            handleAtcHangingCall(callid);
        });

        PubSubSvc.subscribe('/call/state', function (call) {
            LogSvc.debug('[CallControlSvc]: Received /call/state event');
            if (call && call.state.established) {
                if (call.dtmfDigits) {
                    sendDtmf(call);
                }
            }
        });

        PubSubSvc.subscribe('/screenshare/started', function () {
            LogSvc.info('[CallControlSvc]: Received /screenshare/started event. Screenshare was started. Start Auto-Snooze.');
            UserProfileSvc.startAutoSnooze();
        });

        PubSubSvc.subscribe('/screenshare/ended', function () {
            LogSvc.info('[CallControlSvc]: Received /screenshare/ended event. Screenshare was ended. Resume from Auto-Snooze.');
            UserProfileSvc.cancelAutoSnooze();
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Used by SDK to initialize active sessions.
         * Returns a promise that is fullfilled when all sessions have been processed
         * and the different calls arrays are populated.
         */
        this.initActiveSessionsForSdk = CircuitCallControlSvc.initActiveSessionsForSdk;

        /**
         * Set indication of whether incoming remote video should be disabled by default for
         * new incoming and outgoing Circuit calls.
    .    *
         * @param {boolean} true indicates that incoming video is disabled by default.
         */
        this.setDisableRemoteVideoByDefault = CircuitCallControlSvc.setDisableRemoteVideoByDefault;

        /**
         * Get indication of whether incoming remote video is currently disabled by default.
    .    *
         * @returns {boolean} true indicates that incoming video is disabled by default.
         */
        this.getDisableRemoteVideoByDefault = CircuitCallControlSvc.getDisableRemoteVideoByDefault;

        /**
         * Set indication of whether Client Diagnostics are enabled/disabled
    .    *
         * @param {boolean} true=disabled; false=enabled
         */
        this.setClientDiagnosticsDisabled = CircuitCallControlSvc.setClientDiagnosticsDisabled;

        /**
         * Get all of the calls.
         *
         * @returns {Array} An array of Call object.
         */
        this.getCalls = CircuitCallControlSvc.getCalls;

        /**
         * Get all of the phone calls.
         *
         * @returns {Array} An array of Call object
         */
        this.getPhoneCalls = CircuitCallControlSvc.getPhoneCalls;

        /**
         * Get the active local WebRTC call.
         *
         * @returns {LocalCall} The LocalCall object.
         */
        this.getActiveCall = CircuitCallControlSvc.getActiveCall;

        /**
         * Checks if there is an active local WebRTC call being established or
         * in the middle of a media renegotiation.
         *
         * @param {String} callId The ID of existing call or conference (optional)
         * @returns {Booleam} True if there is a connection in progress.
         */
        this.isConnectionInProgress = CircuitCallControlSvc.isConnectionInProgress;

        /**
         * Get the ringing incoming WebRTC call,
         * null will be returned if there is no incoming call.
         *
         * @returns {LocalCall} The LocalCall object.
         */
        this.getIncomingCall = CircuitCallControlSvc.getIncomingCall;

        /**
         * Get the active remote WebRTC call.
         *
         * @returns {Array} The RemoteCall object array.
         */
        this.getActiveRemoteCall = CircuitCallControlSvc.getActiveRemoteCall;

        /**
         * Join an existing group call or conference
         *
         * @param {Object} call The existing group call or conference
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.joinGroupCall = CircuitCallControlSvc.joinGroupCall;

        /**
         * Make a new outgoing call in an existing conversation.
         *
         * @param {String} convId The conversation ID for the conversation to start the call.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.makeCall = CircuitCallControlSvc.makeCall;

        /**
         * Make a new CSTA call via the ATC.
         *
         * @param {String} target The target device where the call will be started.
         * @param {Object} destination The destination object for the call.
         * @param {Function} cb A callback function replying with an error
         */
        this.makeAtcCall = function (target, destination, cb) {
            var dialedDn = Utils.cleanPhoneNumber(typeof destination === 'object' ? destination.dialedDn : destination);
            if (typeof destination === 'object') {
                destination.dialedDn = dialedDn;
            } else {
                destination = {
                    dialedDn: dialedDn
                };
            }

            if (destination.dtmfDigits) {
                destination.dtmfDigits = Utils.cleanPhoneNumberDigitsWithPin(destination.dtmfDigits);
            }
            var existingCallOnTarget = findAtcRemoteCallOnTarget(target) || ($rootScope.localUser.isOsBizCTIEnabled && getActivePhoneCall());

            CstaSvc.startCall(existingCallOnTarget, target, destination, cb);
        };

        /**
         * Make a test call.
         *
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} onConversationCreated A callback function that is immediately invoked with the rtcSessionId for the test call.
         * @param {Function} onCallStarted A callback function when the call is started or in case there is an error.
         */
        this.makeEchoTestCall = CircuitCallControlSvc.makeEchoTestCall;

        /**
         * Make a test call.
         *
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Object} additionalCallOptions The additional call options object, e.g. {desiredRegion: 'eu'}.
         * @param {Function} onConversationCreated A callback function that is immediately invoked with the rtcSessionId for the test call.
         * @param {Function} onCallStarted A callback function when the call is started or in case there is an error.
         */
        this.makeEchoTestCallWithOptions = CircuitCallControlSvc.makeEchoTestCallWithOptions;

        /**
         * Make a new outgoing call to a given DN.
         *
         * @param {String} dialedDn  The dialed number.
         * @param {String} toName The display name of the called circuit user
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error, executed when the call is successfully placed.
         */

        this.dialNumber = CircuitCallControlSvc.dialNumber;

        /**
         * Make a new outgoing call to a given DN.
         *
         * @param {Object} [destination] Object The destination to be called.
         * @param {String} [destination.dialedDn] The dialed number.
         * @param {String} [destination.toName] The display name of the called circuit user.
         * @param {String} [destination.userId] Id of the dialed user (if available). Otherwise null or empty string.
         * @param {Object} [destination.mediaType] The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error, executed when the call is successfully placed.
         */

        this.dialPhoneNumber = CircuitCallControlSvc.dialPhoneNumber;

        /**
         * Make a new outgoing call to the selected device.
         *
         * Remarks: this function is intended to replace PhoneCallCtrl.dial function
         *
         * @param {Object} [destination] Object The destination to be called.
         * @param {String} [destination.dialedDn] The dialed number.
         * @param {String} [destination.toName] The display name of the called circuit user.
         * @param {String} [destination.userId] Id of the dialed user (if available). Otherwise null or empty string.
         * @param {Object} [destination.mediaType] The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error, executed when the call is successfully placed.
         */
        this.dialUsingDefaultDevice = function (destination, cb) {
            cb = cb || function () {};

            var number = destination && destination.dialedDn;
            if (!number || !Utils.PHONE_DIAL_PATTERN.test(number)) {
                cb('Missing or wrong number');
                return;
            }
            LogSvc.debug('[CallControlSvc]: Calling ' + number + ' with name: ' + destination.toName);
            ConversationSvc.getTelephonyConversation(function (err, conversation) {
                if (err || !conversation) {
                    LogSvc.debug('[CallControlSvc]: Error on getTelephonyConversation. ', err);
                    cb(err || 'No telephony conversation');
                    return;
                }

                if (conversation.call && conversation.call.consultation) {
                    var target = conversation.call.getPosition() || _defaultCallDevice;
                    if (target && target !== Targets.WebRTC || $rootScope.localUser.isOsBizCTIEnabled) {
                        LogSvc.info('[CallControlSvc]: Start consultation from ' + target.name + ' to ', number);
                        _that.makeAtcCall(target, destination, cb);
                        conversation.call.consultation = false;
                        return;
                    }
                } else if (_defaultCallDevice && (_defaultCallDevice !== Targets.WebRTC || (conversation.call && $rootScope.localUser.isOsBizCTIEnabled))) {
                    LogSvc.info('[CallControlSvc]: Make call from ' + _defaultCallDevice.name + ' to ', number);
                    _that.makeAtcCall(_defaultCallDevice, destination, cb);
                    return;
                }

                destination.mediaType = destination.mediaType || {audio: true, video: false, desktop: false};
                _that.dialPhoneNumber(destination, cb);
            });
        };

        /**
         * Set the default device to be used for new outgoing call.
         *
         * @param {Object} [device] The default device to be used.
         */
        this.setDefaultCallDevice = function (device) {
            if (!device || !device.name) {
                return;
            }
            // Make sure this is a valid Target device
            Object.keys(Targets).some(function (key) {
                if (device.name === Targets[key].name) {
                    _defaultCallDevice = Targets[key];
                    LogSvc.debug('[CallControlSvc]: Set defaultCallDevice to ', _defaultCallDevice.name);
                    return true;
                }
                return false;
            });
        };

        /**
         * Start a conference in an existing group conversation.
         *
         * @param {Conversation} conversation The existing group conversation to start the conference.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.startConference = CircuitCallControlSvc.startConference;

        /**
         * Start a conference in an existing group conversation.
         *
         * @param {Conversation} conversation The existing group conversation to start the conference.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Object} additionalCallOptions The additional call options object, e.g. {desiredRegion: 'eu'}.
         * @param {Function} cb A callback function replying with an error
         */
        this.startConferenceWithOptions = CircuitCallControlSvc.startConferenceWithOptions;

        /**
         * Returns true if there is an incoming call which cannot be answered before releasing
         * another active call.
         */
        this.cannotHandleSecondCall = function () {
            var incomingCall = _that.getIncomingCall();
            if (!incomingCall) {
                return false;
            }

            var activeCall = _that.getActiveCall();
            if (!activeCall) {
                return false;
            }
            return (activeCall.isTelephonyCall && !incomingCall.isTelephonyCall) ||
                (!activeCall.isTelephonyCall && activeCall.checkState([CallState.Active, CallState.Waiting]));
        };

        /**
         * Answer the incoming call. Any existing active call will be terminated.
         *
         * @param {String} callId The call ID of incoming call to be answered.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Function} cb A callback function replying with an error
         */
        this.answerCall = function (callId, mediaType, cb) {
            var localPhoneCalls = CircuitCallControlSvc.getEstablishedLocalPhoneCalls();
            if (localPhoneCalls.length > 1) {
                LogSvc.warn('[CallControlSvc]: answerCall - There are already 2 local phone calls');
                cb && cb('Can not answer the call');
                return;
            }
            var call = CircuitCallControlSvc.findCall(callId);
            if (call) {
                if ($rootScope.localUser.isOsBizCTIEnabled && call.isTelephonyCall && call.atcCallInfo && call.isOsBizSecondCall) {
                    CstaSvc.answerCstaCall(call.atcCallInfo.getCstaCallId(), cb);
                } else {
                    CircuitCallControlSvc.answerCall(callId, mediaType, cb);
                }
                return;
            }

            call = findAtcRemoteCall(callId);
            var activeCall = CircuitCallControlSvc.getActiveCall();
            var incomingCall = CircuitCallControlSvc.getIncomingCall();
            if (call) {
                if (activeCall && !activeCall.isTelephonyCall) {
                    CircuitCallControlSvc.endActiveCall(function () {
                        CstaSvc.pullRemoteCall(callId, cb);
                    });
                } else if (incomingCall && !incomingCall.isTelephonyCall) {
                    CircuitCallControlSvc.endCallWithCauseCode(incomingCall.callId, Constants.InviteRejectCause.BUSY, function () {
                        CstaSvc.pullRemoteCall(callId, cb);
                    });
                } else {
                    CstaSvc.pullRemoteCall(callId, cb);
                }
            } else {
                LogSvc.warn('[CallControlSvc]: answerCall - There is no alerting call');
                cb && cb('No alerting call');
            }
        };

        /**
         * Start the RtcSessionController and prepares the SDP answer for an incoming telephony call.
         *
         * @param {String} callId The call ID of incoming telephony call.
         * @returns {Promise} Promise resolved when request is accepted.
         */
        this.prepareEarlyMedia = CircuitCallControlSvc.prepareEarlyMedia;

        /**
         * Answer the incoming ATC call on any device. Any existing active call will be terminated
         *
         * @param {String} callId The call ID of incoming call to be answered.
         * @param {Object} mediaType The media type object, e.g. {audio: true, video: false}.
         * @param {Object} device The device object
         * @param {Function} cb A callback function replying with an error
         */
        this.answerCallOnDevice = function (callId, mediaType, device, cb) {
            cb = cb || function () {};
            if (!device || device === Targets.WebRTC) {
                _that.answerCall(callId, mediaType, cb);
                return;
            }

            var call = CircuitCallControlSvc.findCall(callId);
            if (call) {
                if (call.isTelephonyCall) {
                    if ($rootScope.localUser.isOsBizCTIEnabled && device !== Targets.VM && device !== Targets.Cell) {
                        CstaSvc.answerCstaCall(call.atcCallInfo.getCstaCallId(), cb);
                    } else {
                        CstaSvc.deflect(callId, device, null, cb);
                    }
                } else {
                    LogSvc.warn('[CallControlSvc]: answerCallOnDevice - The active call is not a telephony call');
                    cb('Not telephony call');
                }
                return;
            }

            call = findAtcRemoteCall(callId);
            if (call) {
                if (call.pickupNotification) {
                    CstaSvc.pickupCall(callId, device, cb);
                } else if (call.getPosition() !== device) {
                    CstaSvc.deflect(callId, device, null, cb);
                } else {
                    CstaSvc.answer(callId, cb);
                }
            } else {
                LogSvc.warn('[CallControlSvc]: answerCallOnDevice - There is no alerting call');
                cb('No alerting call');
            }
        };

        /**
         * End a call. This could be a local or remote Circuit call, or an ATC call.
         *
         * @param {String} callId The call ID of the ongoing call to be terminated.
         * @param {Function} cb A callback function replying with an error
         */
        this.endCall = function (callId, cb) {
            _that.endCallWithCauseCode(callId, null, cb);
        };

        /**
         * End a call specifying the cause. This could be a local or remote Circuit call, or an ATC call.
         *
         * @param {String} callId The call ID of local call to be terminated.
         * @param {String} cause Cause code for terminating the call. It can be either InviteRejectCause or CallClientTerminatedReason.
         * @param {Function} cb A callback function replying with an error
         */
        this.endCallWithCauseCode = function (callId, cause, cb) {
            var call = CircuitCallControlSvc.findCall(callId);

            if (call) {
                if (call.isOsBizFirstCall) {
                    var secondCall = CircuitCallControlSvc.findOsBizSecondCall();
                    if (secondCall && secondCall.direction === Enums.CallDirection.INCOMING && secondCall.checkState(CallState.Ringing)) {
                        CstaSvc.endCstaCall(call.atcCallInfo.getCstaCallId());
                    } else {
                        CstaSvc.reconnect(call, CircuitCallControlSvc.findOsBizSecondCall(), cb);
                    }
                } else if (call.isOsBizSecondCall) {
                    CstaSvc.reconnect(call, CircuitCallControlSvc.findOsBizFirstCall(), cb);
                } else if (call.isRemote) {
                    CircuitCallControlSvc.endRemoteCall(callId, cb);
                } else if (cause) {
                    CircuitCallControlSvc.endCallWithCauseCode(callId, cause, cb);
                } else {
                    // For OSBiz call with parallel ringing, when rejecting a call, also end the call to remote devices
                    if ($rootScope.localUser.isOsBizCTIEnabled && call.isTelephonyCall && call.checkState(CallState.Ringing)) {
                        CstaSvc.endCallOnRemoteDevices(call.atcCallInfo.getCstaCallId());
                    }
                    CircuitCallControlSvc.endCall(callId, cb);
                }
                return;
            }

            call = findAtcRemoteCall(callId);

            if (call && _atcRemoteCalls.length > 1) {
                if (_that.reconnectCall(callId, cb)) {
                    return;
                }
            }
            if (call) {
                CstaSvc.endRemoteCall(callId, cb);
            } else {
                LogSvc.warn('[CallControlSvc]: endCall invoked without a valid call. callId = ', callId);
                cb && cb('Call not found');
            }
        };

        /**
         * End a held phone call. This API is intended for clients that cannot disable the call release
         * button when holding a call but pbx requires that the call be retrieved before releasing it.
         *
         * @param {String} callId The call ID of local call to be terminated.
         * @param {Function} cb A callback function replying with an error
         */
        this.retrieveAndEndCall = function (callId, cb) {
            cb = cb || function () {};

            var call = CircuitCallControlSvc.findCall(callId) || findAtcRemoteCall(callId);
            if (!call) {
                LogSvc.warn('[CallControlSvc]: retrieveAndEndCall invoked without a valid call');
                cb('Call not found');
                return;
            }

            if (!call.isTelephonyCall || !call.isHolding()) {
                // Just end the call
                _that.endCall(callId, cb);
                return;
            }

            var endCallAfterRetrieval = function (err) {
                if (err) {
                    LogSvc.warn('[CallControlSvc]: Failed to retrieve call');
                    cb(err);
                    return;
                }
                _that.endCall(callId, cb);
            };

            if ($rootScope.localUser.isOsBizCTIEnabled && (!call.isAtcRemote || call.isAtPosition(Targets.Cell))) {
                // In case of OSBiz, there is no SIP call control signaling involved in hold/retrieve, only CSTA
                CstaSvc.retrieveWithReconnectCall(call, endCallAfterRetrieval);
            } else if (call.isAtcRemote) {
                CstaSvc.retrieve(call, endCallAfterRetrieval);
            } else {
                CircuitCallControlSvc.retrieveCall(callId, endCallAfterRetrieval);
            }
        };

        /**
         * End conference using local Circuit call.
         */
        this.endConference = CircuitCallControlSvc.endConference;

        /**
         * Enable audio in an existing RTC session.
         *
         * @param {String} callId The call ID of call to add audio to.
         * @param {Function} cb A callback function replying with an error
         */
        this.addAudio = CircuitCallControlSvc.addAudio;

        /**
         * Disable audio in an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove audio from.
         * @param {Function} cb A callback function replying with an error
         */
        this.removeAudio = CircuitCallControlSvc.removeAudio;

        /**
         * Enable remote audio stream in an active call
         *
         * @param {String} callId The call ID to be muted
         */
        this.enableRemoteAudio = CircuitCallControlSvc.enableRemoteAudio;

        /**
         * Disable remote audio stream in an active call
         *
         * @param {String} callId The call ID to be muted
         */
        this.disableRemoteAudio = CircuitCallControlSvc.disableRemoteAudio;

        /**
         * Enable video in an existing RTC session.
         *
         * @param {String} callId The call ID of call to add video to.
         * @param {Function} cb A callback function replying with an error
         */
        this.addVideo = CircuitCallControlSvc.addVideo;

        /**
         * Disable video in an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.removeVideo = CircuitCallControlSvc.removeVideo;

        /**
         * Add a video stream to an existing RTC session.
         * For adding a screen share stream, please use addMediaStream() instead.
         *
         * @param {String} callId The call ID of call to add video to.
         * @param {MediaStream} stream The video media stream to be added.
         * @param {Object} options Options object
         * @return {Promise} Promise resolved when adding the video stream is successfully initiated
         */
        this.addVideoStream = CircuitCallControlSvc.addVideoStream;

        /**
         * Toggle (allow or block) remote video streams in an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove remote video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleRemoteVideo = CircuitCallControlSvc.toggleRemoteVideo;

        /**
         * Disable remote video streams only in an existing RTC session.
         * Remote Screen share is be allowed.
         * @param {String} callId The call ID of call to remove remote video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.disableRemoteVideoOnly = CircuitCallControlSvc.disableRemoteVideoOnly;

        /**
         * Enable remote and screen share video streams in an existing
         * RTC session.
         *
         * @param {String} callId The call ID of call to remove remote video from.
         * @param {Function} cb A callback function replying with an error
         */
        this.enableRemoteVideo = CircuitCallControlSvc.enableRemoteVideo;

        /**
         * Toggle the video in an existing RTC session.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleVideo = CircuitCallControlSvc.toggleVideo;

        /**
         * Use a new camera in an existing RTC session. The video constraints will remain the same.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {String} deviceId The device ID of the camera to be used.
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleCamera = CircuitCallControlSvc.toggleCamera;

        /**
         * Enable/disable streaming HD on an existing RTC session.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {Boolean} hdQuality Flag to enable/disable HD.
         * @param {Function} cb A callback function replying with an error
         * @param {VideoResolutionLevel} newResolution Desired HD resolution. Defaults to max HD resolution supported by camera.
         */
        this.changeHDVideo = CircuitCallControlSvc.changeHDVideo;

        /**
         * Change video resolution in an existing RTC session.
         *
         * @param {String} callId The call ID of call for which video will be toggled.
         * @param {VideoResolutionLevel} newResolution Desired HD resolution.
         * @param {Function} cb A callback function replying with an error
         */
        this.changeVideoResolution = CircuitCallControlSvc.changeVideoResolution;

        /**
         * Starts a media renegotiation from the client without changing the current media types.
         *
         * @param {String} callId The call ID of the call for which to start the media renegotiation.
         * @param {Function} cb A callback function replying with an error
         */
        this.renegotiateMedia = CircuitCallControlSvc.renegotiateMedia;

        /**
         * Set the audio and/or video media type for the current call.
         *
         * @param {String} callId The call ID of the call for which to start the media renegotiation.
         * @param {Object} mediaType Object with audio and video boolean attributes.
         * @param {Function} cb A callback function replying with an error
         */
        this.setMediaType = CircuitCallControlSvc.setMediaType;

        /**
         * Add a video stream as screen share to an existing RTC session. The video and bandwidth
         * constraints of screen share will be used.
         * For adding it as video, please use addVideoStream() instead.
         *
         * @param {String} callId The call ID of call to add screen share to.
         * @param {MediaStream} stream The media stream to share.
         * @param {Function} cb A callback function replying with an error
         */
        this.addMediaStream = CircuitCallControlSvc.addMediaStream;

        /**
         * Remove a media stream from an existing RTC session.
         *
         * @param {String} callId The call ID of call to remove stream from.
         * @param {Function} cb A callback function replying with an error
         */
        this.removeMediaStream = CircuitCallControlSvc.removeMediaStream;

        /**
         * Move a remote call to the local client
         *
         * @param {String} callId The call ID of existing remote call established on another client.
         * @param {BOOL} fallbackToAudio flag to check whether to pull with audio only.
         * @param {Function} cb A callback function replying with an error
         */
        this.pullRemoteCall = function (callId, fallbackToAudio, cb) {
            var call = CircuitCallControlSvc.findCall(callId);

            if (call) {
                CircuitCallControlSvc.pullRemoteCall(callId, fallbackToAudio, cb);
                return;
            }

            call = findAtcRemoteCall(callId);
            if (call) {
                var activeCall = CircuitCallControlSvc.getActiveCall();
                if (activeCall) {
                    if (activeCall.isTelephonyCall) {
                        CircuitCallControlSvc.holdCall(activeCall.callId, function () {
                            CstaSvc.pullRemoteCall(callId, cb);
                        });
                        return;
                    } else {
                        CircuitCallControlSvc.endActiveCall(function () {
                            CstaSvc.pullRemoteCall(callId, cb);
                        });
                        return;
                    }
                }
                CstaSvc.pullRemoteCall(callId, cb);
            } else {
                LogSvc.warn('[CallControlSvc]: pullRemoteCall invoked without a valid call');
                cb && cb('No active remote call');
            }
        };

        this.pushLocalCall = CstaSvc.pushLocalCall;

        /**
         * Gets a list with all the ATC calls (i.e. the CSTA calls).
         */
        this.getAtcCalls = function () {
            return _atcRemoteCalls;
        };

        /**
         * Alternates (swaps) between two calls.
         *
         * @param {String} callId The call ID of the ATC call that is on hold.
         */
        this.swapCall = function (callId, cb) {
            cb = cb || function () {};
            var osBizCalls = getOsBizConsultationCalls();
            if (osBizCalls) {
                // This is a local OsBiz consultation call
                CstaSvc.alternate(osBizCalls.heldCall, osBizCalls.activeCall, cb);
                return;
            }

            var call = CircuitCallControlSvc.findCall(callId);
            if (call && call.isTelephonyCall && !call.isRemote) {
                CircuitCallControlSvc.swapCall(callId, cb);
                return;
            }

            if (_atcRemoteCalls.length > 1) {
                var heldCall = _atcRemoteCalls.find(function (remoteCall) {
                    return remoteCall.isHolding() && remoteCall.callId === callId;
                });
                if (heldCall) {
                    var position = heldCall.getPosition();
                    var activeCall = _atcRemoteCalls.find(function (remoteCall) {
                        return remoteCall.checkCstaState([CstaCallState.Active, CstaCallState.Conference]) &&
                            remoteCall.isAtPosition(position) && remoteCall.callId !== callId;
                    });
                    if (activeCall) {
                        CstaSvc.alternate(heldCall, activeCall, cb);
                        return;
                    }
                }
            }

            LogSvc.warn('[CallControlSvc]: swapCall possible only with 2 calls');
            cb('No calls to swap');
        };

        /**
         * Conferences (merges) two ATC calls.
         *
         * @param {String} callId The call ID of the remote ATC call to be merged.
         */
        this.mergeCall = function (callId, cb) {
            cb = cb || function () {};
            var localCalls = CircuitCallControlSvc.getPhoneCalls(true);
            if (_atcRemoteCalls.length <= 1 && localCalls.length <= 1) {
                LogSvc.warn('[CallControlSvc]: mergeCall possible only with 2 calls');
                cb('No calls to merge');
                return;
            }
            var activeCall, heldCall;

            if (localCalls.length > 1) {
                activeCall = CircuitCallControlSvc.findActivePhoneCall(true);
                heldCall = CircuitCallControlSvc.findHeldPhoneCall(true);
                if (activeCall && heldCall) {
                    // Merge local calls
                    if ($rootScope.localUser.isSTC) {
                        if (heldCall.isDirectUpgradedToConf) {
                            CircuitCallControlSvc.retrieveCall(heldCall.callId, function (err) {
                                if (err) {
                                    LogSvc.error('[CallControlSvc]: Error retrieving the hosted call');
                                    cb('Error in retrieving the hosted call');
                                } else {
                                    mergeCalls(activeCall.callId, heldCall.callId, cb);
                                }
                            });
                        } else {
                            mergeCalls(heldCall.callId, activeCall.callId, cb);
                        }
                        return;
                    } else if (heldCall.isAtcConferenceCall() && !$rootScope.localUser.isOsBizCTIEnabled) {
                        // If the held call is a PBX conference, we need to alternate to it before adding the consulted
                        // party to the conference. This is not necessary for OSBiz calls.
                        _that.swapCall(heldCall.callId, function (err) {
                            if (err) {
                                LogSvc.warn('[CallControlSvc]: Swap call failed. Cannot proceed with merge call.');
                                cb(err);
                            } else {
                                CstaSvc.conference(activeCall, heldCall, cb);
                            }
                        });
                    } else {
                        CstaSvc.conference(heldCall, activeCall, cb);
                    }
                    return;
                }
            }

            // Try merging remote calls
            activeCall = _atcRemoteCalls.find(function (call) {
                return call.checkCstaState([CstaCallState.Active, CstaCallState.Conference]) && call.callId === callId;
            });

            if (activeCall) {
                var position = activeCall.getPosition();
                heldCall = _atcRemoteCalls.find(function (call) {
                    return call.isHolding() && call.isAtPosition(position) && call.callId !== callId;
                });
                if (heldCall) {
                    CstaSvc.conference(heldCall, activeCall, cb);
                    return;
                }
            }

            LogSvc.warn('[CallControlSvc]: mergeCall possible only with 2 calls');
            cb('No calls to merge');
        };

        /**
         * Transfers ATC calls.
         *
         * @param {String} callId The call ID of the remote ATC call to be merged.
         */
        this.transferCall = function (callId, number, cb) {
            cb = cb || function () {};
            var osBizCalls = getOsBizConsultationCalls();
            if (osBizCalls) {
                // This is a local OsBiz consultation call
                CstaSvc.consultationTransfer(osBizCalls.activeCall, osBizCalls.heldCall, cb);
                return;
            }

            var activeCall = CircuitCallControlSvc.findCall(callId);
            var localCalls = CircuitCallControlSvc.getPhoneCalls(true);
            var heldCall;

            number = Utils.cleanPhoneNumber(number);

            if (activeCall && localCalls.length === 1) {
                if (activeCall.isTelephonyCall && activeCall.atcCallInfo) {
                    CstaSvc.transfer(activeCall, number, cb);
                } else if (activeCall.isSTC) {
                    sendTransferRequest(activeCall, number, cb);
                } else {
                    LogSvc.warn('[CallControlSvc]: The active call is not a telephony call');
                    cb('The active call is not a telephony call');
                }
                return;
            }

            if (activeCall && localCalls.length === 2) {
                heldCall = CircuitCallControlSvc.findHeldPhoneCall(true);
                if (activeCall.isTelephonyCall && activeCall.atcCallInfo) {
                    CstaSvc.consultationTransfer(activeCall, heldCall, cb);
                } else if (activeCall.isSTC) {
                    sendTransferRequest(activeCall.state.established ? activeCall : heldCall,
                        activeCall.state.established ? heldCall : activeCall, cb);
                } else {
                    LogSvc.warn('[CallControlSvc]: The active call is not a telephony call');
                    cb('The active call is not a telephony call');
                }
                return;
            }

            if (_atcRemoteCalls.length > 0) {
                if (_atcRemoteCalls.length === 1) {
                    if (!_atcRemoteCalls[0].isHolding()) {
                        activeCall = _atcRemoteCalls[0];
                        CstaSvc.transfer(activeCall, number, cb);
                        return;
                    }
                }

                activeCall = _atcRemoteCalls.find(function (call) {
                    return !call.isHolding() && call.callId === callId;
                });
                if (activeCall) {
                    var position = activeCall.getPosition();
                    heldCall = _atcRemoteCalls.find(function (call) {
                        return call.isHolding() && call.isAtPosition(position) && call.callId !== callId;
                    });
                    if (heldCall) {
                        CstaSvc.consultationTransfer(activeCall, heldCall, cb);
                        return;
                    }
                }
            }

            LogSvc.warn('[CallControlSvc]: transferCall possible only with 1 or 2 calls');
            cb('Not enough calls for the transfer');
        };

        /**
         * Hold an ATC call.
         *
         * @param {String} callId The call ID of the ATC call to be held.
         */
        this.holdCall = function (callId, cb) {
            cb = cb || function () {};
            var call = CircuitCallControlSvc.findCall(callId);
            if (call) {
                if (!call.isHolding()) {
                    // In case of OSBiz, there is no SIP call control signaling involved in hold/retrieve, only CSTA
                    //$rootScope.localUser.isOsBizCTIEnabled && call.isTelephonyCall ?
                    //    CstaSvc.holdWithConsultationCall(call, cb) : CircuitCallControlSvc.holdCall(callId, cb);
                    CircuitCallControlSvc.holdCall(callId, cb);
                } else {
                    LogSvc.warn('[CallControlSvc]: Call is not in valid state');
                    cb('Call is not in valid state');
                }
                return;
            }

            call = findAtcRemoteCall(callId);
            if (call) {
                if (!call.isHolding()) {
                    $rootScope.localUser.isOsBizCTIEnabled && call.getPosition() === Targets.Cell ?
                        CstaSvc.holdWithConsultationCall(call, cb) : CstaSvc.hold(call, cb);
                } else {
                    LogSvc.warn('[CallControlSvc]: Call is not in valid state');
                    cb('Call is not in valid state');
                }
            } else {
                LogSvc.warn('[CallControlSvc]: holdCall invoked without a valid call');
                cb('Call not found');
            }
        };

        /**
         * Retrieve an ATC held call.
         *
         * @param {String} callId The call ID of the ATC call to be retrieved.
         */
        this.retrieveCall = function (callId, cb) {
            cb = cb || function () {};
            var call = CircuitCallControlSvc.findCall(callId);
            if (call) {
                if (call.isHolding()) {
                    // In case of OSBiz, there is no SIP call control signaling involved in hold/retrieve, only CSTA
                    // $rootScope.localUser.isOsBizCTIEnabled && call.isTelephonyCall ?
                    //     CstaSvc.retrieveWithReconnectCall(call, cb) : CircuitCallControlSvc.retrieveCall(callId, cb);
                    CircuitCallControlSvc.retrieveCall(callId, cb);
                } else {
                    LogSvc.warn('[CallControlSvc]: Call is not in valid state');
                    cb('Call is not in valid state');
                }
                return;
            }

            call = findAtcRemoteCall(callId);
            if (call) {
                if (call.isHolding()) {
                    $rootScope.localUser.isOsBizCTIEnabled && call.getPosition() === Targets.Cell ?
                        CstaSvc.retrieveWithReconnectCall(call, cb) : CstaSvc.retrieve(call, cb);
                } else {
                    LogSvc.warn('[CallControlSvc]: Call is not in valid state');
                    cb('Call is not in valid state');
                }
            } else {
                LogSvc.warn('[CallControlSvc]: retrieveCall invoked without a valid call');
                cb('Call not found');
            }
        };

        /**
         * Reconnect an ATC call.
         *
         * @param {String} callId The call ID of the ATC active call.
         */
        this.reconnectCall = function (callId, cb) {
            if (_atcRemoteCalls.length > 1) {
                var activeCall = _atcRemoteCalls.find(function (call) {
                    return !call.isHolding() && call.callId === callId;
                });
                if (activeCall) {
                    var position = activeCall.getPosition();
                    var heldCall = _atcRemoteCalls.find(function (call) {
                        return call.isHolding() && call.isAtPosition(position) && call.callId !== callId;
                    });
                    if (heldCall) {
                        CstaSvc.reconnect(activeCall, heldCall, cb);
                        return true;
                    }
                }
            }

            LogSvc.warn('[CallControlSvc]: reconnectCall possible only with 2 calls');
            cb('No calls to reconnect');
            return false;
        };

        /**
         * Ignore a pickup notification call
         *
         * @param {String} callId The call ID of the pickup notification call
         */
        this.ignoreCall = function (callId, cb) {
            var call = findAtcRemoteCall(callId);
            if (call && call.pickupNotification) {
                CstaSvc.ignoreCall(call, cb);
            } else {
                var callToPickup = CircuitCallControlSvc.getCallToPickup();
                if (callToPickup && (callToPickup.pickupSession === callId || callToPickup.callId === callId)) {
                    CircuitCallControlSvc.ignorePickupCall();
                } else {
                    cb && cb('No pickup call');
                }
            }
        };

        /**
         * Hide a remote call.
         *
         * @param {String} callId The call ID of remote call to be hidden.
         */
        this.hideRemoteCall = CircuitCallControlSvc.hideRemoteCall;

        /**
         * Remove participant in a group call.
         *
         * @param {String} callId The call ID of a group call or conference.
         * @param {Object} participant The call participant object.
         * @param {Function} cb A callback function replying with an error
         */
        this.dropParticipant = CircuitCallControlSvc.dropParticipant;

        /**
         * Add participant to a call.
         *
         * @param {String} callId The call ID
         * @param {Object} participant The participant object.
         * @param {Function} cb A callback function replying with an error
         */
        this.addParticipantToCall = CircuitCallControlSvc.addParticipantToCall;

        /**
         * Add participant to an RTC session.
         *
         * @param {String} callId The call ID
         * @param {Object} participant The participant object.
         * @param {Function} cb A callback function replying with an error
         */
        this.addParticipantToRtcSession = CircuitCallControlSvc.addParticipantToRtcSession;

        /**
         * mute self locally in an active call
         *
         * @param {String} callId The call ID to be muted
         * @param {Function} cb A callback function replying with an error
         */
        this.mute = CircuitCallControlSvc.mute;

        /**
         * unmute locally only (used for large conference)
         *
         * @param {Function} cb A callback function called on success
         */
        this.unmuteLocally = CircuitCallControlSvc.unmuteLocally;

        /**
         * unmute self in an active call, which may have been muted locally or remotely
         *
         * @param {String} callId The call ID to be unmuted
         * @param {Function} cb A callback function replying with an error
         */
        this.unmute = CircuitCallControlSvc.unmute;

        /**
         * toggle between mute and unmute
         *
         * @param {String} callId The call ID to toggle mute
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleMute = CircuitCallControlSvc.toggleMute;

        /**
         * mute remote participant
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object to be muted
         * @param {Function} cb A callback function replying with an error
         */
        this.muteParticipant = function (callId, participant, cb) {
            cb = cb || function () {};
            if (!callId || !participant) {
                cb('Missing callId or participant');
                return;
            }

            if (!isLocalCall(callId)) {
                LogSvc.warn('[CallControlSvc]: muteParticipant - There is no local call');
                cb('Call invalid');
                return;
            }

            CircuitCallControlSvc.muteParticipant(callId, participant, cb);
        };

        /**
         * check if there are unmuted participants
         *
         * @param {String} callId The call ID of active call
         */
        this.hasUnmutedParticipants = CircuitCallControlSvc.hasUnmutedParticipants;

        /**
         * check for Permission for Screen Control
         * @param {Object} call The object of the current call
         */
        this.hasScreenControlFeature = CircuitCallControlSvc.hasScreenControlFeature;

        /**
         * mute RTC session
         *
         * @param {String} callId The call ID of active call
         * @param {Function} cb A callback function replying with an error
         */
        this.muteRtcSession = CircuitCallControlSvc.muteRtcSession;

        /**
         * unmute self which has been muted remotely
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object to be unmuted
         * @param {Function} cb A callback function replying with an error
         */
        this.unmuteParticipant = function (callId, participant, cb) {
            cb = cb || function () {};
            if (!callId || !participant) {
                cb('Missing callId or participant');
                return;
            }

            if (!isLocalCall(callId)) {
                LogSvc.warn('[CallControlSvc]: unmuteParticipant - There is no local call');
                cb('Call invalid');
                return;
            }
            CircuitCallControlSvc.unmuteParticipant(callId, participant, cb);
        };

        /**
         * toggle between participant mute and unmute
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object for which to toggle mute
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleMuteParticipant = function (callId, participant, cb) {
            if (!callId || !participant) {
                cb('Missing callId or participant');
                return;
            }
            if (participant.muted) {
                _that.unmuteParticipant(callId, participant, cb);
            } else {
                _that.muteParticipant(callId, participant, cb);
            }
        };

        /**
         * toggle video on MeetingPoing participant
         *
         * @param {String} callId The call ID of active call
         * @param {Object} participant The participant object for which to toggle video
         * @param {Function} cb A callback function replying with an error
         */
        this.toggleVideoParticipant = function (callId, participant, cb) {
            cb = cb || function () {};
            if (!callId || !participant || !participant.userId) {
                cb('Missing callId or participant');
                return;
            }

            if (!isLocalCall(callId)) {
                LogSvc.warn('[CallControlSvc]: toggleVideoParticipant - There is no local call');
                cb('Call invalid');
                return;
            }

            LogSvc.warn('[CallControlSvc]: Cannot toggle video MeetingPoint participant: ', participant.userId);
            cb('res_StartVideoParticipantFailed');
        };

        this.toggleScreenSharing = function () {
        };

        this.toggleConferencePoll = function () {
        };

        this.toggleWhiteboard = function () {
        };

        this.toggleVideoFeature = function () {
        };

        this.toggleLayout = function () {
        };

        this.swapDisplays = function () {
        };

        this.invokeParticipantAction = function (data, cb) {
            switch (data.action) {
            case ParticipantAction.StartVideo:
                if (canStartVideoParticipant(data.participant)) {
                    checkAndToggleVideoParticipant(data.call, data.participant, cb);
                }
                break;
            case ParticipantAction.StopVideo:
                if (canStopVideoParticipant(data.participant)) {
                    checkAndToggleVideoParticipant(data.call, data.participant, cb);
                }
                break;
            case ParticipantAction.Mute:
                _that.checkAndMuteParticipant(data.call.callId, data.participant);
                break;
            case ParticipantAction.Unmute:
                _that.checkAndUnmuteParticipant(data.call.callId, data.participant);
                break;
            }
        };

        this.canUnmuteParticipant = canUnmuteParticipant;

        this.checkAndMuteParticipant = function (callId, participant) {
            if (canMuteParticipant(participant)) {
                LogSvc.buttonPressed('Mute other participant with userId: ', participant.userId);
                _that.muteParticipant(callId, participant);
            } else {
                LogSvc.info('[CallControlSvc]: Cannot mute remote participant');
            }
        };

        this.checkAndUnmuteParticipant = function (callId, participant) {
            if (canUnmuteParticipant(participant)) {
                LogSvc.buttonPressed('Unmute other participant with userId: ', participant.userId);
                _that.unmuteParticipant(callId, participant);
            } else {
                LogSvc.info('[CallControlSvc]: Cannot unmute remote participant');
            }
        };

        /**
         * Check whether video option is available
         *
         * @param {Object} participant The participant object for which to allow to show video option
         * @param {Boolean} isAnyFeatureEnabled Flag indicating if whiteboard or screenshare is turned on
         *
         * @return {Boolean}
         */
        this.isAllowedToAddVideoFeature = function (participant, isAnyFeatureEnabled) {
            if (!participant || !participant.isMeetingPointInvitee || !participant.cmrData) {
                return false;
            }
            return !!(participant.cmrData.displayCount === 1 && isAnyFeatureEnabled &&
                (participant.cmrData.isLayoutsEnabled ? !!participant.cmrData.primaryScreen : !participant.cmrData.primaryScreen));
        };

        this.stopRingingTone = CircuitCallControlSvc.stopRingingTone;

        /**
         * End active call.
         *
         * @param {Function} cb A callback function replying with a status
         */
        this.endActiveCall = CircuitCallControlSvc.endActiveCall;

        this.startRecording = CircuitCallControlSvc.startRecording;

        this.stopRecording = CircuitCallControlSvc.stopRecording;

        this.startTranscription = CircuitCallControlSvc.startTranscription;

        this.stopTranscription = CircuitCallControlSvc.stopTranscription;

        this.switchRecordingLayout = CircuitCallControlSvc.switchRecordingLayout;

        this.startRecordingParticipant = CircuitCallControlSvc.startRecordingParticipant;

        this.submitCallQualityRating = CircuitCallControlSvc.submitCallQualityRating;

        /**
         * retrieve list of nodes with states
         *    currently only used for testcall
         * @param {String} nodeType requested node type(currently only MEDIA_ACCESS)
         * @param {String} tenantId name of the tenant or empty for all
         * @param {Function} cb A callback function replying with an error or success
         */
        this.getNodeState = CircuitCallControlSvc.getNodeState;

        this.canSendWebRTCDigits = CircuitCallControlSvc.canSendWebRTCDigits;

        /**
         * Send DTMF digits
         *
         * @param {String} callId The call ID of active call
         * @param {String} digits The digits to be sent
         * @param {Function} cb A callback function replying with a status
         */
        this.sendDigits = function (callId, digits, cb) {
            cb = cb || function () {};

            if (!digits) {
                LogSvc.warn('[CallControlSvc]: sendDigits invoked without any digit');
                cb('No digits');
                return;
            }
            var call = CircuitCallControlSvc.findCall(callId);
            if (call) {
                if (call.sessionCtrl.canSendDTMFDigits()) {
                    CircuitCallControlSvc.sendDigits(callId, digits, cb);
                } else if (call.atcCallInfo) {
                    // For ATC calls we can use CSTA to generate DTMF digits (if we can't use WebRTC)
                    CstaSvc.generateDigits(call, digits, function (err) {
                        if (err) {
                            cb('res_SendDtmfFailed');
                        } else {
                            cb();
                        }
                    });
                } else {
                    cb('res_SendDtmfFailed');
                }
                return;
            }

            call = findAtcRemoteCall(callId);
            if (call) {
                CstaSvc.generateDigits(call, digits, cb);
            } else {
                LogSvc.warn('[CallControlSvc]: SendDigits invoked without a valid call');
                cb('Call not found');
            }
        };

        /**
         * Locally disable the remote incoming video (this doesn't trigger a
         * media renegotiation)
         * @returns {undefined}
         */
        this.disableIncomingVideo = CircuitCallControlSvc.disableIncomingVideo;

        /**
         * Locally enable the remote incoming video (this doesn't trigger a
         * media renegotiation)
         * @returns {undefined}
         */
        this.enableIncomingVideo = CircuitCallControlSvc.enableIncomingVideo;


        /**
         * Enable or disable participant pointer
         *
         * @param {boolean} true=enabled, false=disabled.
         * @returns {undefined}
         */
        this.toggleParticipantPointer = CircuitCallControlSvc.toggleParticipantPointer;

        /**
         * Find active or incoming local call
         * @returns {LocalCall} The LocalCall object.
         */
        this.findCall = CircuitCallControlSvc.findCall;

        /**
         * Find the conversation for a given callId
         *
         * @param callId
         * @returns {Conversation} The conversation object
         */
        this.findConversationByCallId = findConversationByCallId;

        /**
         * Returns the ATC devices that can initiate a call
         * @returns {Object[]} The device list
         */
        this.getCallDevices = CstaSvc.getCallDevices;

        /**
         * Returns the ATC devices that the call can be pushed
         * @returns {Object[]} The push device list
         */
        this.getPushDevices = CstaSvc.getPushDevices;

        /**
         * Returns the ATC devices the call can be answered at.
         * @param callId Call ID
         * @returns {Object[]} The answer device list
         */
        this.getAnswerDevices = function (callId) {
            if (callId && typeof callId === 'object') {
                // Backwards compatibility
                return CstaSvc.getAnswerDevices(callId);
            }
            var call = CircuitCallControlSvc.findCall(callId) || findAtcRemoteCall(callId);
            return CstaSvc.getAnswerDevices(call);
        };

        /**
         * Use this method to force the active call object to query the currently used
         * audio/video devices.
         *
         * @returns {undefined}
         */
        this.updateActiveCallMediaDevices = CircuitCallControlSvc.updateActiveCallMediaDevices;

        this.canStartScreenShare = CircuitCallControlSvc.canStartScreenShare;

        this.canStartRecording = CircuitCallControlSvc.canStartRecording;

        this.canTakeScreenshot = CircuitCallControlSvc.canTakeScreenshot;

        this.addConferenceAsGuest = CircuitCallControlSvc.addConferenceAsGuest;

        this.removeConferenceAsGuest = CircuitCallControlSvc.removeConferenceAsGuest;

        /**
         * Test ICE candidates collection
         * @returns {Promise} Returns an object containing the assigned TURN servers and the gathered ICE candidates.
         */
        this.testCandidatesCollection = CircuitCallControlSvc.testCandidatesCollection;

        this.findOsBizSecondCall = CircuitCallControlSvc.findOsBizSecondCall;

        this.getConferenceParticipants = CircuitCallControlSvc.getConferenceParticipants;

        /**
         * Changes the input device(s) used in the call. By invoking this method, a media renegotiation
         * may or may not be triggered. Don't use this method to add/remove audio or video to the call, use
         * {@link addVideo}, {@link remmoveVideo}, {@link addAudio} or {@link removeAudio} instead.
         *
         * @param {String} callId ID of the call that will be changed.
         * @param {Object} newInputDevices New input devices. This object can have 2 properties: audio and video,
         * which contains the device IDs to be used.
         * @returns {Promise} Fulfilled when the operation finishes (including the media renegotiation, if required).
         */
        this.changeInputDevices = CircuitCallControlSvc.changeInputDevices;

        this.setIncomingVideoStreams = CircuitCallControlSvc.setIncomingVideoStreams;

        /**
         * Get network quality info.
         *
         * @param {String} [callId] the ID of the call (optional: if not supplied, the active call will be used)
         * @returns {Object} Object containing:
         * - level: Overall network quality level defined in Circuit.Enums.RtpQualityLevel
         * - audio: Object with audio quality parameters and their values: property names in the object are defined in
         * Constants.AudioQualityParam with values defined in Circuit.Enums.RtpQualityLevel
         * - video: Object with video quality parameters and their values: property names in the object are defined in
         * Constants.VideoQualityParam with values defined in Circuit.Enums.RtpQualityLevel
         * - videoInfo: Object containing:
         *   - resolution: Object containing:
         *     - height: current outgoing resolution height (Number)
         *     - width: current outgoing resolution width (Number)
         *   - hdVideo: value defined in Circuit.Enums.VideoResolutionLevel. Applicable only if resolution exactly matches
         * a predefined resolution, otherwise it's undefined.
         *
         * If call could not be found or no quality info is available, null is returned.
         */
        this.getNetworkQualityInfo = CircuitCallControlSvc.getNetworkQualityInfo;

        this.isPinVideoSupported = CircuitCallControlSvc.isPinVideoSupported;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.CallControlSvcImpl = CallControlSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
