/*global Uint8Array*/

// This code is based on recorder.js from https://github.com/awslabs/aws-lex-browser-audio-capture

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var logger = Circuit.logger;
    var RtcSessionController = Circuit.RtcSessionController;
    var Utils = Circuit.Utils;
    var WebRTCAdapter = Circuit.WebRTCAdapter;

    var recorderWorker = null;
    var activeRecorder = null;

    var getMediaStream = function (successCb, errorCb) {
        // Get the up-to-date list of devices that are plugged in and select one based on the list of
        // devices previously selected by the user
        WebRTCAdapter.getMediaSources(function (audioSources) {
            var source = Utils.selectMediaDevice(audioSources, RtcSessionController.recordingDevices);
            var audioConstraints = {
                enableAudioAGC: RtcSessionController.enableAudioAGC,
                enableAudioEC: RtcSessionController.enableAudioEC,
                sourceId: source && source.id
            };
            logger.debug('[audioRecorder]: Get media stream with audio constraints: ', audioConstraints);
            var constraints = {
                audio: WebRTCAdapter.getAudioOptions(audioConstraints),
                video: false
            };
            WebRTCAdapter.getUserMedia(constraints, successCb, errorCb);
        });
    };

    /**
     * The Recorder object. Sets up the onaudioprocess callback and communicates with the Worker to perform audio actions.
     */
    // eslint-disable-next-line max-lines-per-function
    function Recorder(config) { // NOSONAR
        // Start the Web Worker if it hasn't been started yet
        if (!recorderWorker) {
            logger.debug('[audioRecorder]: Start the recorder web worker');
            recorderWorker = new Worker('./audioRecorderWorker.js');
        }

        config = config || {};
        config.silenceDetectionTime = config.silenceDetectionTime || 1500;
        config.silenceSuppressionAmplitude = config.silenceSuppressionAmplitude || 0.2;

        var recording = false;
        var pendingRecording = false;
        var destroyed = false;
        var startTime;
        var silenceCallback;
        var visualizationCallback;
        var audioStream, audioContext, analyser;

        var stopMediaStream = function () {
            if (audioStream && !config.stream) {
                WebRTCAdapter.stopMediaStream(audioStream);
                audioStream = null;
            }
            if (audioContext) {
                audioContext.close();
                audioContext = null;
            }
        };

        /**
         * Checks the time domain data to see if the amplitude of the audio waveform is more than
         * the silence threshold. If it is, "noise" has been detected and it resets the start time.
         * If the elapsed time reaches the time threshold the silence callback is called. If there is a
         * visualizationCallback it invokes the visualization callback with the time domain data.
         */
        var analyse = function () {
            analyser.fftSize = 2048;
            var bufferLength = analyser.frequencyBinCount;
            var dataArray = new Uint8Array(bufferLength);
            var amplitude = config.silenceSuppressionAmplitude;

            analyser.getByteTimeDomainData(dataArray);

            if (typeof visualizationCallback === 'function') {
                visualizationCallback(dataArray, bufferLength);
            }

            if (typeof silenceCallback !== 'function') {
                return;
            }

            for (var i = 0; i < bufferLength; i++) {
                // Normalize between -1 and 1.
                var currValueTime = (dataArray[i] / 128) - 1.0;
                if (currValueTime > amplitude || currValueTime < (-1 * amplitude)) {
                    startTime = Date.now();
                    return;
                }
            }
            var elapsedTime = Date.now() - startTime;
            if (elapsedTime > config.silenceDetectionTime) {
                startTime = Date.now();
                silenceCallback();
            }
        };

        var init = function (stream) {
            if (destroyed) {
                WebRTCAdapter.stopMediaStream(stream);
                return;
            }
            logger.debug('[audioRecorder]: Got MediaStream object. Initialize recorder objects.');
            audioStream = stream;
            audioContext = new window.AudioContext();
            var source = audioContext.createMediaStreamSource(audioStream);

            // Create a ScriptProcessorNode with a bufferSize of 4096 and a single input and output channel
            var node = source.context.createScriptProcessor(4096, 1, 1);

            // Send init message to worker
            recorderWorker.postMessage({
                command: 'init',
                config: {
                    sampleRate: source.context.sampleRate
                }
            });

            /**
             * The onaudioprocess event handler of the ScriptProcessorNode interface. It is the EventHandler to be
             * called for the audioprocess event that is dispatched to ScriptProcessorNode node types.
             * @param {AudioProcessingEvent} audioProcessingEvent - The audio processing event.
             */
            node.onaudioprocess = function (audioProcessingEvent) {
                if (!recording) {
                    return;
                }
                recorderWorker.postMessage({
                    command: 'record',
                    buffer: [audioProcessingEvent.inputBuffer.getChannelData(0)]
                });
                analyse();
            };

            analyser = source.context.createAnalyser();
            analyser.minDecibels = -90;
            analyser.maxDecibels = -10;
            analyser.smoothingTimeConstant = 0.85;

            source.connect(analyser);
            analyser.connect(node);
            node.connect(source.context.destination);

            if (pendingRecording) {
                logger.debug('[audioRecorder]: Start the pending recording');
                pendingRecording = false;
                startTime = Date.now();
                recording = true;
            }
        };

        /**
         * Callback invoked when recorder detects silence.
         *
         * @callback onSilenceCallback
         */

        /**
         * Callback invoked with recording data for visualization.
         *
         * @callback visualizerCallback
         * @param {Uint8Array} dataArray
         * @param {number} bufferLength
         */

        /**
         * Sets the silence and viz callbacks, resets the silence start time, and sets recording to true.
         * @param {onSilenceCallback} onSilence - Called when silence is detected.
         * @param {visualizerCallback} visualizer - Can be used to visualize the captured buffer.
         */
        var record = function (onSilence, visualizer) {
            if (destroyed) {
                throw new Error('Recorder resources have already been released.');
            }
            silenceCallback = onSilence;
            visualizationCallback = visualizer;

            if (!audioStream) {
                logger.debug('[audioRecorder]: Waiting for MediaStream object before starting recording');
                pendingRecording = true;
            } else {
                logger.debug('[audioRecorder]: Start the recording');
                startTime = Date.now();
                recording = true;
            }
        };

        /**
         * Pauses the current recording
         */
        var pause = function () {
            if (destroyed) {
                return;
            }
            logger.debug('[audioRecorder]: Pause the recording');
            pendingRecording = false;
            recording = false;
        };

        /**
         * Callback invoked with the exported WAV file.
         *
         * @callback onExportCallback
         * @param {Blob} blob The exported audio as a Blob.
         */

        /**
         * Stops the current recording and export the recorded audio as a Blob.
         * @param {onExportCallback} onExport - Called when the export is complete.
         * @param {Number} sampleRate The sample rate to use in the export.
         */
        var exportWAV = function (onExport, sampleRate) {
            if (typeof onExport !== 'function') {
                throw new Error('Invalid input for onExport parameter.');
            }
            if (destroyed) {
                throw new Error('Recorder resources have already been released.');
            }
            logger.debug('[audioRecorder]: Stop the recording and export the recorded WAV file');

            recorderWorker.postMessage({
                command: 'export',
                sampleRate: sampleRate
            });

            recorderWorker.onmessage = function (message) {
                logger.debug('[audioRecorder]: Received exported WAV file from worker');
                onExport(message.data);
                recorderWorker.onmessage = null;
            };
        };

        /**
         * Releases objects allocated by the Recorder instance. Also posts "clear" message to the worker.
         */
        var stop = function () {
            if (destroyed) {
                return;
            }
            logger.debug('[audioRecorder]: Close the Recorder instance and release resources');
            stopMediaStream();
            activeRecorder = null;
            pendingRecording = false;
            recording = false;
            recorderWorker.postMessage({ command: 'clear' });
            destroyed = true;
        };

        /**
         * Clears the recorded data
         */
        var clear = function () {
            if (destroyed) {
                return;
            }
            logger.debug('[audioRecorder]: Clear the recording');
            recorderWorker.postMessage({ command: 'clear' });
        };

        // Recorder object's public interfaces
        this.record = record;
        this.pause = pause;
        this.exportWAV = exportWAV;
        this.stop = stop;
        this.clear = clear;

        // Initialize the media stream
        if (config.stream) {
            logger.error('[audioRecorder]: Use MediaStream object that has been provided');
            init(config.stream);
        } else {
            getMediaStream(init, function (error) {
                var errorName = (error && error.name) || 'Unspecified';
                logger.error('[audioRecorder]: Failed to get media stream: ', errorName);
            });
        }
    }

    /**
     * Audio recorder object. Handles setting up the audio context,
     * accessing the mike, and creating the Recorder object.
     */
    var AudioRecorder = {
        isSupported: function () {
            return typeof window.AudioContext !== 'undefined';
        },
        getActiveRecorder: function () {
            return activeRecorder;
        },
        createRecorder: function (config) {
            if (typeof window.AudioContext === 'undefined') {
                throw new Error('Audio recording is not supported');
            }
            logger.debug('[audioRecorder]: Create a new Recorder object with config: ', config);
            if (activeRecorder) {
                // There is already an active recorder
                logger.warn('[audioRecorder]: There is already an active Recorder. Cannot create new one.');
                return null;
            }
            activeRecorder = new Recorder(config);
            return activeRecorder;
        }
    };

    // Exports
    circuit.AudioRecorder = AudioRecorder;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
