// Define global variables for JSHint
/*global module*/

var Circuit = (function (circuit) {
    'use strict';

    var HeadsetIntegrationApp = {};

    HeadsetIntegrationApp.MsgTarget = Object.freeze({
        INTERNAL: 'internal',
        DEVICE_EVENT: 'deviceEvent'
    });

    HeadsetIntegrationApp.MsgInternal = Object.freeze({
        LOG: 'log',
        INIT: 'init'
    });

    HeadsetIntegrationApp.ErrorCodes = Object.freeze({
        DEVICE_ALREADY_IN_USE: 'deviceAlreadyInUse',
        UNSUPPORTED_REQUEST: 'unsupportedRequest'
    });

    // Exports
    circuit.HeadsetIntegrationApp = HeadsetIntegrationApp;

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = HeadsetIntegrationApp;
    }

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
