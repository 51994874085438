var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var logger = circuit.logger;

    // eslint-disable-next-line max-params, max-lines-per-function
    function LogSvcImpl($rootScope, $timeout) { // NOSONAR

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var NOP = function () {};
        var TIMEOUT_GET_LOG_FILES = 5000;
        var BUTTON_PREFIX = 'Button press: '; // Standard prefix for logging actions initiated by a button, e.x call related buttons

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        this.debug = logger.debug;
        this.info = logger.info;
        this.warn = logger.warning;
        this.error = logger.error;
        this.msgSend = logger.msgSend || logger.info;
        this.msgRcvd = logger.msgRcvd || logger.info;

        this.logMsg = logger.logMsg || NOP;

        this.setLevel = logger.setLevel || NOP;
        this.getLevel = logger.getLevel || NOP;

        this.show = logger.show || NOP;
        this.hide = logger.hide || NOP;

        this.enablePopup = logger.enablePopup || NOP;
        this.disablePopup = logger.disablePopup || NOP;
        this.isPopupEnabled = logger.isPopupEnabled || function () {
            return false;
        };

        this.isFileLogAvailable = logger.isFileLogAvailable || function () {
            return false;
        };

        // This function is called to log actions initiated by button, e.x call related buttons
        this.buttonPressed = function (txt, param) {
            logger.info(BUTTON_PREFIX + txt, param);
        };

        // This function is called for both "Report Issue" and "Log file management" via Debug options. The 5 seconds timeout
        // would cause the log files not shown when a user selects "Log file management". So the input parameter (dontTimeOut) is used
        // to indicate if a timeout is needed. For the "Log file management" via Debug options case, dontTimeOut is set to true when this
        // function is called.
        this.getLogFilesForDownload = function (cb, dontTimeOut) {
            if (typeof cb !== 'function') {
                return;
            }
            if (typeof logger.getLogFiles !== 'function') {
                cb([]);
                return;
            }
            // Log the state of the Circuit Lab features
            logger.info('[LogSvc]: Snapshot of Circuit Lab features: ', $rootScope.circuitLabs);

            // Log the user's telephony data, if available
            if ($rootScope.localUser && $rootScope.localUser.telephonyConnectorData) {
                logger.info('[LogSvc]: User telephony data: ', $rootScope.localUser.telephonyConnectorData);
            }

            var timerPromise = null;
            if (!dontTimeOut) {
                timerPromise = $timeout(function () {
                    timerPromise = null;
                    cb([]);
                }, TIMEOUT_GET_LOG_FILES);
            }
            logger.getLogFiles(function (data) {
                if (!dontTimeOut && !timerPromise) {
                    return;
                }
                if (timerPromise) {
                    $timeout.cancel(timerPromise);
                    timerPromise = null;
                }
                $rootScope.$apply(function () {
                    cb(data);
                });
            }, !dontTimeOut);
        };

        this.startFileLogger = logger.startFileLogger || NOP;

        this.removeLogFiles = function (cb) {
            if (typeof logger.removeLogFiles !== 'function') {
                cb && cb(null);
                return;
            }
            var wrapperCb;
            if (typeof cb === 'function') {
                wrapperCb = function (logFiles) {
                    $rootScope.$apply(function () {
                        cb(logFiles);
                    });
                };
            }
            logger.removeLogFiles(wrapperCb);
        };

        this.setUserInfo = logger.setUserInfo || NOP;

        this.setExtensionVersion = logger.setExtensionVersion || NOP;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.LogSvcImpl = LogSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
