/*global __productName, __productBrand, PhoneNumberUtil, process, require, he*/

// eslint-disable-next-line complexity
var Circuit = (function (circuit) { // NOSONAR
    'use strict';

    // Variables used for electron app and for cross domain registration.
    circuit.__server = '';
    circuit.__domain = '';

    // Imports
    var logger = circuit.logger;

    circuit.productName = typeof __productName === 'string' ? __productName : 'Circuit';
    circuit.productBrand = typeof __productBrand === 'string' ? __productBrand : '';

    // Constants
    var HTTPS = 'https://';
    var EMOTICON_REGEX = /<(img[^>|<]*(?:shortcut|abbr)="(\S*)"[^/?>]*\/?)>/gi;
    // SSL proxy to overcome mixed-content warning due to loading unsecure preview images
    var SSL_IMAGE_PROXY = 'https://embed-proxy.circuit.com/?target=';
    var MAX_NUM_OF_PREFERRED_DEVICES = 5;

    /**
     * Static utility functions
     * @class Utils
     * @static
     */
    var Utils = circuit.Utils || {};

    // Returns set of symbols that is used for url regular expressions
    function getUrlSymbols() {
        var urlSymbolLast = 'a-z\\u00a1-\\uffff0-9@\'^=$%&amp;\\/~+_\\{\\}\\-\\|';
        var urlSymbol = urlSymbolLast + '.,?!:\\[\\]';
        return '(?:[' + urlSymbol + '#]*[' + urlSymbolLast + '#]|\\([' + urlSymbol + ']*\\))*';
    }
    var URL_SYMBOLS = getUrlSymbols();

    // Conversation fields which need to be trimmed from the conversation objects
    // sent to the mobile clients.
    var TRIM_FOR_MOBILE_GET_LISTS = ['items', 'parents', 'peerUsers', 'previousMembers'];

    Utils.trimCallForMobile = function (call) {
        var mobileCall;
        if (call) {
            mobileCall = Utils.flattenObj(call);
            // TODO: Need to enhance iOS and Android apps before we can trim the fields.
            // TRIM_FOR_MOBILE_GET_LISTS.forEach(function (field) {
            //     delete mobileCall[field];
            // });
        }
        return mobileCall;
    };

    Utils.trimConvForMobile = function (conv) {
        var mobileConv;
        if (conv) {
            mobileConv = Utils.flattenObj(conv);
            TRIM_FOR_MOBILE_GET_LISTS.forEach(function (field) {
                delete mobileConv[field];
            });
            mobileConv.call = Utils.trimCallForMobile(conv.call);
        }
        return mobileConv;
    };

    Utils.isPromise = function (obj) {
        return !!(obj && typeof obj.then === 'function');
    };

    /**
     * Converts the given version string into an version number.
     *
     * @param {String} version The client version in a 'x.xx.xxxx[-SNAPSHOT]' format.
     */
    Utils.convertVersionToNumber = function (version) {
        if (typeof version !== 'string') {
            return 0;
        }
        var splitVersion = version.split('.');
        var release = parseInt(splitVersion[0], 10) || 0;
        var major = parseInt(splitVersion[1], 10) || 0;
        major = Math.min(major, 99);
        var minor = parseInt(splitVersion[2], 10) || 0;
        minor = Math.min(minor, 9999);

        return minor + major * 1e4 + release * 1e6;
    };

    /**
     * Trims a text, given a selected length, from beginning, adding ellipsis.
     */
    Utils.trimTextWithEmoticonsBeforeMatch = function (text, textToMatch, maxCharactersBeforeMatch) {
        var PREFIX = '...';
        var matchedIndex = text.indexOf(textToMatch);
        // contains the matched term and the text on its right
        var afterMatchingPart = text.slice(matchedIndex);
        // text before matchmatchedIndex
        var beforeMatchingPart = text.slice(0, matchedIndex);

        var LT = String.fromCharCode(17); // Temporary mapping using non-printable ascii code x11 (DEC 17)
        var GT = String.fromCharCode(18); // Temporary mapping using non-printable ascii code x12 (DEC 18)
        var content = beforeMatchingPart.replace(EMOTICON_REGEX, function (a, b) {
            return LT + b + GT;
        });

        // We run the beforeMatchingText in inverse order so we can locate
        // the non printable ending character
        var isCounting = true;
        var trimingIndex = content.length - 1;
        var loopIndex = content.length - 1;
        var splitIndex = loopIndex;

        // We iterate the String from end. We skip characters that are between non-printable characters
        // and update non-printable chars on the fly, in order to generate a valid html with emoticons.
        while (((beforeMatchingPart.length - 1) - trimingIndex) <= maxCharactersBeforeMatch && loopIndex >= 0) {
            if (content.charCodeAt(loopIndex) === 18) {
                content = content.replaceAt(loopIndex, '>');
                isCounting = false;
            } else if (content.charCodeAt(loopIndex) === 17) {
                content = content.replaceAt(loopIndex, '<');
                isCounting = true;
            }

            splitIndex = loopIndex;
            loopIndex--;
            if (isCounting) {
                trimingIndex--;
            }
        }

        // Prefix will be added only in case we trim text from begining.
        beforeMatchingPart = splitIndex > 0 ? PREFIX + content.slice(splitIndex) : content;

        return beforeMatchingPart + afterMatchingPart;
    };

    /**
     * Removes restrictions from the encodeURIComponent() function
     */
    Utils.enhanceEncodeURI = function (s) {
        // mappings to replace '!,(,),#,{,},' with proper encoding
        var charactersMap = {
            '!': '%21',
            '(': '%28',
            ')': '%29',
            '#': '%23',
            '{': '%7B',
            '}': '%7D'
        };

        return encodeURIComponent(s).replace(/[!()#{}]/gi, function (matched) {
            return charactersMap[matched];
        });
    };

    Utils.normalizeDn = function (dn) {
        if (!dn) {
            return '';
        }
        return dn.replace('+', '');
    };

    Utils.cleanPhoneNumber = function (number) {
        return number ? number.replace(/[^*#\d+]/g, '') : '';
    };

    Utils.cleanPhoneNumberDigitsWithPin = function (number) {
        return number ? number.replace(/[^*#\d+,]/g, '') : '';
    };

    /**
     * Checks the meta tags for a given Html input to determine if the html data source is a MS document.
     * Used when pasting in the conversation input.
     */
    Utils.isMSDoc = function (content) {
        // FF and Chrome paste tag with 'WordDocument like '<m:WordDocument>'.
        // IE pastes html with styles begin with 'mso-', e.g. '<b style="mso-bidi-font-weight: normal;">'.
        // From Microsoft OneNote html is pasted with tag <meta content="Microsoft OneNote">
        return content.search(/<\S*?WordDocument>/i) >= 0 ||
            content.search(/<[^>]*?style=[^>]*?mso-[^<]+?>/i) >= 0 ||
            content.search(/<meta[^>]*?Microsoft/i) >= 0;
    };

    /**
     * Checks the meta tags for a given Html input to determine if the html data source is a MS document.
     * Used when pasting in the conversation input.
     */
    Utils.isXCodeDoc = function (content) {
        // Contain meta tag with attribute contain="Cocoa HTML Writer"
        return content.search(/<meta[^>]*?Cocoa[^<]+?>/) >= 0;
    };

    var productionDomains = [
        'eu.yourcircuit.com',
        'na.yourcircuit.com',
        'beta.circuit.com',
        'circuit.siemens.com',
        'partner.circuit.siemens.com',
        'teams.univergeblue.com',
        'bluebeta.circuit.com',
        'gt-connect.circuit.atos-services.net',
        'uc.redsara.es'
    ];
    Utils.isProductionDomain = function () {
        var location = window.location.host;
        return productionDomains.indexOf(location) !== -1;
    };

    /**
     * Checks whether integrations is supported on the current domain.
     */
    var validIntegrationDomains = [
        'circuitdev.unify.com', // Development environment
        'phonedev.unify.com',
        'tandi.circuitsandbox.net',
        'adfs01.circuitsandbox.net',
        'circuitsandbox.net',
        'beta.circuit.com',
        'eu.yourcircuit.com',
        'na.yourcircuit.com',
        'gt-connect.circuit.atos-services.net',
        'uc.redsara.es'
    ];
    Utils.isIntegrationsSupported = function () {
        var location = window.location.hostname;
        return validIntegrationDomains.includes(location);
    };

    /**
     * HTML Sanitizer. For web only. Web needs to set this
     * to the Angular sanitizer.
     */
    Utils.sanitizer = null;

    /**
     * Sanitize function.
     * Bypass style attribute for Ansible rich text highlighting (not search highlighting)
     */
    Utils.sanitize = function (content) {
        if (!content || !Utils.sanitizer) {
            return content || '';
        }

        try {
            // Angular sanitize does not allow style tags, so replace it with a class.
            // NOTE: If the class name changes, be sure to also change in ConversationItemMessageCell.m for iOS client.
            // Used for post messages
            content = content.replace(/style="background-color: rgb\(212, 239, 181\);"/gi, 'class="rich-text-highlight"');

            // Used for pasting highlighted content
            content = content.replace(/<[^>]+background-color: rgb\(212, 239, 181\);.*?>/gi, function (matchTag) {
                if (matchTag.includes('class=')) {
                    // Check if class attribute is already exists
                    return matchTag.replace(/class=".*?"/gi, function (matchClass) {
                        if (matchClass.includes('rich-text-highlight')) {
                            // Return if highlight class is already exists
                            return matchClass;
                        } else {
                            // Add highlight class
                            return matchClass.replace(/[^=]\s*(.*)/gi, function (match) {
                                return match.slice(0, -1) + ' rich-text-highlight';
                            });
                        }
                    });
                } else {
                    // If no class attribute exists, add highlight class to the end of the tag
                    return matchTag.replace('>', function (match) {
                        return ' class="rich-text-highlight" ' + match;
                    });
                }
            });

            // Remove image tags that are not emoticon ones (emoticon ones are e.g. <img class="emoticon-icon smile" abbr=":)">
            // or emoji ones
            content = content.replace(/<img(?![^>]*class="(emoticon-icon|emojione-icon))[^>]*>/gi, '');

            // Remove <title> tag because sanitize extracts title content as text, which can't be identified and
            // removed later and can't be pasted inside content as well.
            content = content.replace(/<title[^>]*>([\s\S]*?)<\/title>/gi, '');

            // Remove <font> tags
            content = content.replace(/<font[^>]*>|<\/font>/gi, '');

            // Normalize <hr>,<hr></hr> to <hr/>
            content = content.replace(/(<\s*hr\s*><\s*\/hr\s*>|<\s*hr\s*>)/gi, '<hr/>');

            // Remove \n and \r inside tags
            content = content.replace(/(\r|\n)+(?=[^<]*?>)/gi, ' ');

            // If any \r or \n characters exist (due to paste actions etc, convert them to <br> so they can be handled properly)
            content = content.replace(/\r\n|\r|\n/g, '<br>');

            if (typeof Utils.sanitizer === 'function') {
                content = Utils.sanitizer(content);
            }

            // Tokenize HTML in div/p elements. Removes unnecessary divs, added from RICH TEXT editor, without losing/adding new lines.
            var tokens = content.split(/<\/?div.*?>|<\/?p.*?>/g).filter(function (token) { return !!token; });
            var tokensLen = tokens.length;

            var tempContent = '';
            if (tokensLen > 1) { // rich
                // Find only last <br> tag:
                // '...text<br>'
                // '...<b>text<br></b>'
                var lastBrTag = /<br>(?![\s\S]*<br>)(?=(<[^/][^>]*><\/[^>]*>)*$|(<\/[^>]*>)*$|$)/i;

                var curr, prev;
                for (var i = 0; i < tokensLen; i++) {
                    // <br>'s in bold, italics are removed in sanitization, so we need to replace them with single <br>
                    curr = tokens[i].replace(/((<span( class="[\S]*")*>)|(<b( class="[\S]*")*>)|(<i( class="[\S]*")*>))*(<br>|<br\/>)(<\/span>|<\/b>|<\/i>)*/g, '<br>');

                    if (prev && !prev.endsWith('</ul>') && !prev.endsWith('</ol>') && !lastBrTag.test(prev)) {
                        // The previous token does not end with a <br>, so we need to insert one
                        tempContent += '<br>';
                    }
                    tempContent += curr;
                    prev = curr;
                }
            } else { // simple
                tempContent = tokens[0];
            }
            content = tempContent;

        } catch (e) {
            logger.error('[Utils]: Error sanitizing content. ', e);
            logger.error('[Utils]: Unexpected content: ', content);
            return '';
        }

        return content || '';
    };

    Utils.sanitizeSymbols = function (content) {
        // Available only to mobile clients who have he.js library loaded
        if (!Utils.isMobile() || typeof he === 'undefined') {
            return content;
        }
        // Convert symbols to html entities so they can be read in a uniform way. Also we should send encoded escaped html content in the backend.
        // (but currently only emoji symbols need encoding)
        // Used HE encoder/decoder to support all possible symbol encoding, https://github.com/mathiasbynens/he
        // Other possible solution would be for mobiles to manually detect symbols when sending and convert them to html entities
        // e.g. not send ✌ (victory hand emoji character) but &#9996; Because newer emoji chars have more bytes and are combined with some non-printable
        // modificator characters (e.g. black victory hand) we need a library to detect all manually, so we use he.js
        // Encode only non-Ascii chars ('allowUnsafeSymbols = true' means leave &, <, >, ", ', and backtick unencoded if sent by mobiles)
        // and convert it using decimal html entity to be alligned with web client (default in he.js is hex - it works in either case)
        return content && he.encode(content, { allowUnsafeSymbols: true, decimal: true});
    };

    Utils.toPlainText = function (content, type) {
        if (content) {
            // If type parameter is omitted or is 'RICH', convert to plain
            if (!type || (type === 'RICH')) {
                var elem = document.createElement('div');
                elem.innerHTML = content.replace(/<(br[/]?|\/li|hr[/]?)>/gi, '&nbsp;');
                return elem.textContent;
            }
        }
        return content; // In any other case (type parameter set but not 'RICH' - e.g. 'PLAIN'), don't convert
    };

    // Truncates big file names and puts ellipsis in the middle
    Utils.truncateFileName = function (fileName) {
        if (!fileName || fileName.length <= 22) {
            return fileName;
        }
        return fileName.substring(0, 10) + ' ...' +
            fileName.substring(fileName.length - 8, fileName.length);
    };

    Utils.DEFAULT_HELP_URL = 'https://www.circuit.com/support';

    Utils.DEFAULT_FAQ_URL = 'https://www.circuit.com/unifyportalfaqdetail';

    // Symbols that are used to separate words, including white space characters.
    Utils.SEPARATORS_PATTERN = '!-/:-@[-^{-~\\s';

    // Pattern for numbers only (used in Cloud Telephony)
    Utils.NUMERIC_PATTERN = '^[0-9]*$';

    // Pattern for numbers with * and + signs at the beginning of number(used in Cloud Telephony)
    Utils.NUMERIC_SIGN_PATTERN = '^[*+]?[0-9]*$';

    // AlphaNumeric Pattern
    Utils.SSO_ISSUER_PATTERN = '^[a-zA-Z0-9@#&_-]*$';

    // Patern for unicode letters, numbers, spaces and specific special characters
    Utils.TENANT_PATTERN = new RegExp('^[\\p{L}0-9\\s\\+\\.,/@#&_-]*$', 'gu');

    // Patern for unicode letters, numbers, spaces and specific special characters
    Utils.IDP_ALIAS_PATTERN = new RegExp('^[\\p{L}0-9_-]*$', 'gu');

    // Patern for unicode letters, numbers, spaces and specific special characters
    Utils.IDP_ENTITY_ID_PATTERN = new RegExp('^[\\p{L}0-9\\s\\./_:%-]*$', 'gu');

    // Helper pattern for finding urls.
    Utils.URL_SEPARATORS_PATTERN = /(?=[.,;!]?\s+|[.,;!]$)/;

    // http, https, ftp, circuit url pattern
    Utils.URL_PATTERN = new RegExp('(http|ftp|https|circuit):\\/\\/[\\w-]+?((\\.|:)[\\w-]+?)*?' + URL_SYMBOLS, 'gim');

    Utils.HTTPS_URL_PATTERN = new RegExp('https:\\/\\/[\\w-]+?((\\.|:)[\\w-]+?)*?' + URL_SYMBOLS, 'gim');

    // Matches URL_PATTERN (exact match)
    Utils.EXACT_URL_PATTERN = new RegExp('^' + Utils.URL_PATTERN.source + '$', 'i');

    // starting with www. sans http:// or https://
    Utils.PSEUDO_URL_PATTERN = new RegExp('www\\.+[\\w-]+?(\\.[\\w-]+?)+?' + URL_SYMBOLS, 'gim');

    // String starts with http, https, ftp protocol
    Utils.PROTOCOL_PATTERN = /^(?:http|https|ftp):\/\//;

    // Query expression retrieved from ui-bootstrap returning all tabbable elements
    Utils.TABBABLE_ELEMENTS_PATTERN = 'a[href], area[href], input:not([disabled]):not([tabindex=\'-1\']), ' +
                                      'button:not([disabled]):not([tabindex=\'-1\']),select:not([disabled]):not([tabindex=\'-1\']), ' +
                                      'textarea:not([disabled]):not([tabindex=\'-1\']), ' +
                                      'iframe, object, embed, *[tabindex]:not([tabindex=\'-1\']), *[contenteditable=true]';

    Utils.RICH_TEXT_LINK_CLASS = 'text-linkDarkGreen';

    Utils.ABBR_PATTERN = /abbr=["'](.*?)["']/;

    Utils.SPACE_HASHTAG_PATTERN = /#([_a-zA-Z0-9\u0080-\u009f\u00c0-\uffff]+)/g;

    /**
     * Email address patttern. `/^[_a-z0-9-\+]+(?:\.[_a-z0-9-\+]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*(?:\.[a-z][a-z]+)$/im`
     * @property EMAIL_ADDRESS_PATTERN
     * @type string
     * @memberOf Circuit.Utils
     * @static
     * @final
     */
    // Email addresses(es) included in text
    Utils.EMAIL_ADDRESS_INCLUDED_PATTERN = /[\w*+\-$?^{!#%&'/=`|}~]+?(?:\.[\w*+\-$?^{!#%&'/=`|}~]+?)*?@[a-z\d-]+?(?:\.[a-z\d-]{2,})+/gim;
    Utils.EMAIL_ADDRESS_PATTERN = new RegExp('^' + Utils.EMAIL_ADDRESS_INCLUDED_PATTERN.source + '$', 'i');

    // Pattern to match circuit conversation hash
    Utils.CIRCUIT_CONVERSATION_HASH_PATTERN = /#\/(conversation|open|muted)\/[0-9\-a-z]+?(\?((user=(\d)+?)|(item=([0-9\-a-z])+?(&user=(\d)+?)?)))?$/gim;

    // Pattern to match circuit user, user profile and phone hash
    Utils.CIRCUIT_LINKS_HASH_PATTERN = /#\/(profile|phone|user|search)\/[0-9\-a-z]+?$/gim;

    // Pattern to match hash tag search urls
    Utils.CIRCUIT_HASH_TAG_SEARCH_PATTERN = /#\/search\/[a-zA-Z\u0080-\u009f\u00a1-\uffff0-9_]+/gim;

    // Pattern to match circuit email hash
    Utils.CIRCUIT_EMAIL_HASH_PATTERN = new RegExp('#\\/email\\/' + Utils.EMAIL_ADDRESS_INCLUDED_PATTERN.source + '$', 'gim');

    // Phone numbers. Examples:
    //   15615551234
    //   1.561.555.1234
    //   +15615551234
    //   +1-561-555-1234
    //   +1(561)555-1234
    Utils.PHONE_PATTERN = /^(\+\s*)?\d+(\s*\(\s*\d+\s*\)\s*\d+)?((\s*-?\s*\d+)*|(\s*\.\s*\d+)*)$/;

    Utils.GNF_PHONE_PATTERN = /^((\+)|(\+\s*\d+(\s*\(\s*\d+\s*\)\s*\d+)?((\s*-?\s*\d+)*|(\s*\.\s*\d+)*)))$/;

    Utils.E164_PHONE_PATTERN = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    // Prefix Access Code: must start with # or *, followed by digits, *, #, (), -, + or white spaces
    // We don't check if parenthesis are properly opened and closed (like in other phone patterns)
    Utils.PHONE_PAC = /^([#*]+[#*()\-\s+\d]*)$/;

    Utils.PHONE_DIAL_PATTERN = /^([#*()\\/\-.\s+\d,]*)$/;
    Utils.PHONE_DIAL_WITH_PIN_PATTERN = /^([#*()\\/\-.\s+\d]*)(,+[,\d#*]+)$/;

    // Same as PHONE_DIAL_PATTERN but with an extension at the end (e.g.: +1 (231) 344-3455 x 1324)
    Utils.PHONE_WITH_EXTENSION_PATTERN = /^([#*()\\/\-.\s+\d]*)(\s*(x|X|ext\.)\s*\d+)$/;

    // Pattern for verifying time format(HH:mm or hh:mm a)
    Utils.TIME_PATTERN = /^((([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s*)|((0?[1-9]|1[0-2]):[0-5][0-9]\s*(PM|AM)))$/;

    // Pattern for verifying an IPv4 Address
    Utils.IPV4_ADDRESS_PATTERN = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

    Utils.TCP_PORT_NUMBERS_PATTERN = /^(0|([1-9]\d{0,3}|[1-5]\d{4}|[6][0-5][0-5]([0-2]\d|[3][0-5])))$/;

    Utils.FQDN_PATTERN = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;

    Utils.matchPhonePattern = function (str) {
        if (!str || (typeof str !== 'string')) {
            return null;
        }
        return str.match(Utils.PHONE_PATTERN);
    };

    Utils.matchUrlPattern = function (str) {
        if (!str || (typeof str !== 'string')) {
            return null;
        }
        return str.match(Utils.URL_PATTERN) || str.match(Utils.PSEUDO_URL_PATTERN);
    };

    Utils.matchEmailPattern = function (str) {
        if (!str || (typeof str !== 'string')) {
            return null;
        }
        return str.match(Utils.EMAIL_ADDRESS_INCLUDED_PATTERN);
    };

    Utils.getEmails = function (str) {
        var emails = str && str.split(/[;,]\s*|\s+/i);

        emails = emails && emails.map(function (email) {
            var match = email.match(Utils.EMAIL_ADDRESS_INCLUDED_PATTERN);
            return match && match[0];
        }).filter(function (email) {
            return !!email;
        });

        return emails || [];
    };

    Utils.getTags = function (str) {
        return str && str.split(/[;,]\s*/i).reduce(function (tags, tag) {
            var tagTrimmed = tag.trim();
            if (tagTrimmed.length) {
                tags.push(tagTrimmed);
            }
            return tags;
        }, []);
    };

    Utils.matchNames = function (str, query, flag) {
        if (!str || (typeof str !== 'string') || !query) {
            return false;
        }
        var reName = new RegExp('^' + RegExp.escape(query), flag || 'i');
        return reName.test(str) || str.split(' ').some(function (name) {
            return reName.test(name);
        });
    };

    Utils.matchIpOrFqdnPattern = function (str) {
        if (!str || (typeof str !== 'string')) {
            return null;
        }
        return str.match(Utils.IPV4_ADDRESS_PATTERN) || str.match(Utils.FQDN_PATTERN);
    };

    // Compares strings case-insensitive
    Utils.compareStrings = function (str1, str2) {
        if ((typeof str1 !== 'string') || (typeof str2 !== 'string')) {
            return false;
        }
        var reCompare = new RegExp('^' + RegExp.escape(str1) + '$', 'i');
        return reCompare.test(str2);
    };

    Utils.rstring = function () {
        return Math.floor(Math.random() * 1e9).toString();
    };

    Utils.randomNumber = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    Utils.randomBoolean = function () {
        return Math.random() < 0.5;
    };

    Utils.createTransactionId = function () {
        var s = [];
        var hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1);// bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        return s.join('');
    };

    // Get the legal region ('us', 'uk', 'de') associated to the regionId ('americas', 'apac', 'europe') provided
    Utils.getLegalRegion = function (regionId) {
        switch (regionId) {
        case 'europe':
            var languageSplit = (navigator.language || navigator.browserLanguage).split('-'); //supports different browsers (Chrome/IE)
            var country = Utils.lastArrayElement(languageSplit); //supports locale with and without country
            return country.toLowerCase() === 'de' ? 'de' : 'uk';
        case 'americas':
        case 'apac':
            return 'us';
        default:
            return 'us';
        }
    };

    /**
     * Provides browser type and version information.
     * @method getBrowserInfo
     * @returns {Object} Object with type, version and browser attributes
     * @memberof Circuit.Utils
     */
    Utils.getBrowserInfo = function () {
        if (window.navigator.platform === 'iOS') {
            return {
                ios: true,
                type: 'ios'
            };
        } else if (window.navigator.platform === 'Android') {
            return {
                android: true,
                type: 'android'
            };
        } else if (window.navigator.platform === 'node') {
            return {
                node: true,
                type: 'node'
            };
        } else if (window.cordova && window.cordova.platformId === 'ios') {
            return {
                cordovaios: true,
                type: 'cordovaios'
            };
        }

        var browserData = {};
        var ua = navigator.userAgent.toLowerCase();
        var match = /(msie|trident)(?: |.*rv:)([\d.]+)/.exec(ua) ||
            /(opera|opr)\/(?:.*version\/|)([\d.]+)/.exec(ua) ||
            /(firefox)\/([\d.]+)/.exec(ua) ||
            /(edge)\/([\d.]+)/.exec(ua) || // Legacy Edge
            /(edg)\/([\d.]+)/.exec(ua) || // New Edge based off Chrome
            /(chrome)\/([\d.]+)/.exec(ua) ||
            /version\/([\d.]+).*(safari)/.exec(ua) ||
            /(phantomjs)\/([\d.]+)/.exec(ua) || // Used for unit test (shouldn't impact performance here)
            [];
        var browser = match[1] || '';
        var version = match[2] || 0;
        var subType;

        // Swap matches for Safari because of inverted user agent
        if (version === 'safari') {
            browser = match[2];
            version = match[1];
        } else {
            switch (browser) {
            case 'trident':
                // IE no longer reported as MSIE starting from 11 version
                browser = 'msie';
                break;
            case 'opr':
                // Correctly define Opera
                browser = 'opera';
                break;
            case 'edg':
                // Mark new Edge (based off Chrome) as Chrome, but with subType='edge'
                // The version field contains the Edge version
                browser = 'chrome';
                subType = 'edge';
                break;
            case '':
            case 'chrome':
                // Check if this is mobile safari/chrome
                match = /(mobile)\/([\d.]+)|\bmobile\b/.exec(ua) || [];
                if (match.length > 0) {
                    subType = 'webview';
                    if (browser === '') {
                        browser = match[1] || '';
                        version = match[2] || 0;
                        var iosWebView = /iphone|ipod|ipad/.test(ua);
                        if (iosWebView) {
                            browser = 'safari';
                        }
                        break;
                    }
                }
                break;
            }
        }

        browserData[browser] = true;
        browserData.type = browser;
        browserData.version = version;

        if (subType) {
            browserData.subType = subType;
        }

        return browserData;
    };

    // Define the possible OS types for Utils.getOSInfo()
    var OS_TYPES = [
        {s: 'Windows 10', r: /(Windows.* 10|Windows 10.0|Windows[ _]NT.* 10.0)/},
        {s: 'Windows 8.1', r: /(Windows.* 8.1|Windows[ _]NT.* 6.3)/},
        {s: 'Windows 8', r: /(Windows.* 8|Windows[ _]NT.* 6.2)/},
        {s: 'Windows 7', r: /(Windows.* 7|Windows[ _]NT.* 6.1)/},
        {s: 'Windows Vista', r: /(Windows.* Vista|Windows[ _]NT.* 6.0)/},
        {s: 'Windows Server 2003', r: /(Windows Server 2003|Windows[ _]NT.* 5.2)/},
        {s: 'Windows XP', r: /(Windows[ _]NT.* 5.1|Windows XP)/},
        {s: 'Windows 2000', r: /(Windows[ _]NT.* 5.0|Windows 2000)/},
        {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
        {s: 'Windows 98', r: /(Windows 98|Win98)/},
        {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
        {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
        {s: 'Windows CE', r: /Windows CE/},
        {s: 'Windows 3.11', r: /Windows 3.11|Win16/},
        {s: 'Android', r: /Android/},
        {s: 'Open BSD', r: /Open BSD|OpenBSD/},
        {s: 'Sun OS', r: /Sun OS|SunOS/},
        {s: 'Linux', r: /(Linux|X11)/},
        {s: 'iOS', r: /(iOS|iPhone|iPad|iPod)/},
        {s: 'Mac OS X', r: /Mac OS X/},
        {s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh|Darwin)/},
        {s: 'QNX', r: /QNX/},
        {s: 'UNIX', r: /UNIX/},
        {s: 'BeOS', r: /BeOS/},
        {s: 'OS/2', r: /OS\/2/},
        {s: 'Search Bot', r: /(Search Bot|nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
    ];

    Utils.getOSInfo = function (osVersion) {
        osVersion = osVersion || window.navigator.userAgent;

        var info = osVersion;
        var type = '';
        var version = '';

        var inList = OS_TYPES.some(function (os) {
            if (os.r.test(osVersion)) {
                info = os.s;
                return true;
            }
            return false;
        });

        // Get OS version info from navigator
        if (inList && /Windows/.test(info)) {
            version = /Windows (.*)/.exec(info)[1];
            type = 'Windows';
        } else {
            type = info;

            try {
                switch (info) {
                case 'Mac OS X':
                    version = /Mac OS X (10[._\d]+)/.exec(osVersion)[1];
                    break;

                case 'Android':
                    version = /Android ([._\d]+)/.exec(osVersion)[1];
                    break;
                }
            } catch (e) {
                logger.debug('[Utils]: osVersion does not contain version on it. ', osVersion);
            }
        }

        return {
            type: type,
            version: version,
            info: info
        };
    };

    Utils.checkUserDomain = function (domain, startsWith) {
        var hostname = window.location.hostname;
        if (startsWith) {
            return hostname.startsWith(domain);
        }
        return hostname.endsWith(domain);
    };

    /**
     * Parses an string with IP+port (IPv4 or IPv6) and returns an object with ipAddress and port properties.
     * If parsing is not successful, null is returned.
     * Input examples:
     * IPv4: '172.20.41.178:3823'
     * IPv6: '[2001:db8:a0b:12f0::1]:3422'
     */
    Utils.parseIpWithPort = function (ip) {
        if (!ip || typeof ip !== 'string') {
            return null;
        }
        if (ip[0] === '[') {
            // IPV6+port
            var ipv6 = /\[(.*)\]:(\d*)/.exec(ip); // Extract the IPV6 and port (we're not validating the IP address)
            if (ipv6 && ipv6.length === 3) {
                return {
                    ipAddress: ipv6[1],
                    port: ipv6[2]
                };
            }
        } else if (ip.includes(':')) {
            var split = ip.split(':');
            if (split.length === 2) {
                return {
                    ipAddress: split[0],
                    port: split[1]
                };
            }
        }
        return null;
    };

    Utils.isDialableNumber = function (number) {
        return !!number && (Utils.PHONE_PATTERN.test(number) || Utils.PHONE_PAC.test(number));
    };

    Utils.isEmptyObject = function (obj) {
        return Object.keys(obj).length === 0;
    };

    // Get the prototypical base object (__proto__) and optionally update the base
    // object with properties set on the object itself.
    // Note: Only first-level properties are updated, no recursion.
    Utils.getBaseObject = function (obj, updateBaseObj) {
        if (!obj || (typeof obj !== 'object')) {
            return null;
        }

        if (Array.isArray(obj)) {
            return obj;
        }

        var base = Object.getPrototypeOf(obj);

        if (Utils.isEmptyObject(base)) {
            // obj was constructed directly from Object
            return obj;
        }

        if (updateBaseObj !== false) {
            Object.getOwnPropertyNames(base).forEach(function (name) {
                if (typeof base[name] !== 'function' && obj.hasOwnProperty(name)) {
                    base[name] = obj[name];
                }
            });
        }
        return base;
    };

    // Get the prototypical base object (__proto__), updates the base object with
    // properties set on the object itself, and finally deletes those properties
    // from the object itself.
    // Note: Only first-level properties are updated, no recursion.
    Utils.syncBaseObject = function (obj) {
        var base = Utils.getBaseObject(obj, true);
        if (!base || base === obj) {
            return;
        }
        Object.getOwnPropertyNames(base).forEach(function (name) {
            if (obj.hasOwnProperty(name)) {
                delete obj[name];
            }
        });
    };


    // Flattens prototyped objects, so JSON.stringify will also include the prototype's properties.
    Utils.flattenObj = function (obj) {
        if (!obj || (typeof obj !== 'object') || Array.isArray(obj)) {
            return obj;
        }

        var base = Object.getPrototypeOf(obj);
        if (Utils.isEmptyObject(base)) {
            // obj was constructed directly from Object
            return obj;
        }

        var tmp = {};
        for (var key in obj) {
            if (typeof obj[key] !== 'function') {
                tmp[key] = obj[key];
            }
        }
        return tmp;
    };

    // eslint-disable-next-line complexity
    Utils.getNormalizedFileExtension = function (file) {
        var fileName = file && (file.fileName || file.name);
        if (!fileName) {
            return null;
        }

        if ((/audio/i).test(file.mimeType)) {
            return 'audio';
        }

        if ((/video/i).test(file.mimeType)) {
            return 'video';
        }

        var fileExt = fileName.split('.').pop();
        if (fileExt) {
            switch (fileExt.toLowerCase()) {
            case 'doc':
            case 'docx':
                return 'doc';
            case 'ppt':
            case 'pptx':
                return 'ppt';
            case 'pdf':
                return 'pdf';
            case 'xls':
            case 'xlsx':
            case 'xlsm':
                return 'xls';
            case 'gz':
            case 'zip':
                return 'zip';
            case 'html':
                return 'html';
            case 'csv':
                return 'csv';
            case 'png':
                return 'png';
            case 'jpg':
            case 'jpeg':
                return 'jpg';
            case 'log':
                return 'log';
            case 'flv':
            case 'mp4':
            case 'ogv':
            case 'webm':
                return 'video';
            case 'wav':
            case 'mp3':
            case 'ogg':
                return 'audio';
            case 'vtt':
                return 'vtt';
            }
        }

        return 'def';
    };

    Utils.getImageFileExtension = function (file) {
        if (file.type) {
            switch (file.type.toLowerCase()) {
            case 'image/png':
                return 'png';
            case 'image/jpeg':
                return 'jpg';
            case 'image/gif':
                return 'gif';
            case 'image/bmp':
                return 'bmp';
            case 'image/svg+xml':
                return 'svg';
            }
        }
        return null;
    };
    //CQ:CQ00283052
    Utils.getMimeTypes = function (fileName) {
        if (!fileName) {
            return null;
        }

        var fileExt = fileName.split('.').pop();
        if (fileExt) {
            switch (fileExt.toLowerCase()) {
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'xltx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
            case 'potx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.template';
            case 'ppsx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
            case 'pptx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            case 'sldx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.slide';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'dotx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.template';
            case 'xlam':
                return 'application/vnd.ms-excel.addin.macroEnabled.12';
            case 'xlsb':
                return 'application/vnd.ms-excel.sheet.binary.macroEnabled.12';
            }
        }

        return 'application/octet-stream';
    };

    /**
     * Utility function to escape text as html, but keep line changes.
     * @method textToHtmlEscaped
     * @param {String} str Text content to escape
     * @param {Boolean} [spaceForNewlines] If true, newlines (\n) are converted to &nbsp; instead of <br>
     * @returns {String} Escaped string
     * @example
     *     Circuit.Utils.textToHtmlEscaped('<b>bold</b>\ntest');
     *     // returns &lt;b&gt;bold&lt;/b&gt;<br>test
     *
     *     Circuit.Utils.textToHtmlEscaped('<b>bold</b>\ntest', true);
     *     // returns &lt;b&gt;bold&lt;/b&gt;&nbsp;test
     */
    Utils.textToHtmlEscaped = function (str, handleTextNewLines) { // Method to escape text as html but keep line changes
        if (!str) {
            return '';
        }
        var newLinesReplace = handleTextNewLines ? '&nbsp;' : '<br>'; // In selector we want spaces instead of new \n or \r
        return str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&#34;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\r\n|\r|\n/gm, newLinesReplace);
    };

    Utils.escapedHtmlToText = function (str) { // Method to transform escaped html to text
        if (!str) {
            return '';
        }
        return str
        .replace(/&amp;/g, '&')
        .replace(/&#34;/g, '"')
        .replace(/&#39;/g, '\'')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&nbsp;/g, ' ')
        .replace(/<br\/?>/g, '\r\n');
    };

    Utils.stripParenthesis = function (token) {
        // The method removes only open and close parenthesis
        token = token.trim();//linkified text adds empty characters at the end of token
        var startPos = 0;
        while (token[startPos] === '(' && token[token.length - startPos - 1] === ')') {
            startPos++;
        }
        return token.slice(startPos, token.length - startPos);
    };

    function linkifyHtml(content) {
        if (!content) {
            return '';
        }
        var inputDOM = document.createElement('div');
        var linkifiedDOM = document.createElement('div');
        // After optimizing sanitization we don't need to (.replace(/(<br\/>|<hr\/>)/g, '\n');)
        // Need to replace $ chars with $$ so they are displayed correctly ($ is an escape char so if we have $$ only one is displayed)
        content = content.replace(/\$/gm, '$$$$');
        // Add a link to user profile/hashtag search in span with mention/hashtag, ignore all other spans that can be inside the mention/hashtag
        // Add attribute fr-omit-link to prevent sending link to backend, in case of paste
        var isReplace = 0;
        var linkIndex = 0;
        content = content.replace(/(<span.*?>)|(<\/span>)/gi, function (match, openTag, closeTag) {
            if (openTag) {
                isReplace++;
                if (openTag.match(/class="mention"/i)) {
                    linkIndex = isReplace;
                    return match.replace(/.*abbr="(.*?)".*?>/gi, '<span class="mention" abbr="$1"><a href="#/profile/$1" fr-omit-link>');
                } else if (openTag.match(/class="hashtag"/i)) {
                    linkIndex = isReplace;
                    return match.replace(/.*abbr="(.*?)".*?>/gi, '<span class="hashtag" abbr="$1"><a href="#/search/$1" fr-omit-link>');
                } else {
                    return openTag;
                }
            }
            if (closeTag) {
                var linkedCloseTag = '';
                if (isReplace === linkIndex) {
                    linkedCloseTag = '</a></span>';
                }
                isReplace--;
                return linkedCloseTag || closeTag;
            }
            return '';
        });

        inputDOM.innerHTML = content;
        return linkifyNode(inputDOM, linkifiedDOM).innerHTML.replace(/&nbsp;/gi, ' ');
    }

    function getOrigin() {
        return window.location && window.location.origin;
    }

    Utils.linkifyText = function (text, keepNewlines) {
        if (!keepNewlines) {
            // eslint-disable-next-line no-control-regex
            text = text.replace(/\xA0|&#160;|\x0A|\x0D/gim, ' ');
        }
        var LT = String.fromCharCode(17); // Temporary mapping using non-printable ascii code x11 (DEC 17) for char "<" replacements
        var GT = String.fromCharCode(18); // Temporary mapping using non-printable ascii code x12 (DEC 18) for char ">" replacements
        var QUOT = String.fromCharCode(19); // Temporary mapping using non-printable ascii code x13 (DEC 19) for char "'" replacements

        var checkForCircuitLink = function (match) {
            var target = '';
            var rel = '';
            var origin = getOrigin();
            var url = Utils.getCircuitRelativeLink(origin, match);
            if (!url) {
                var createNewPage = true;
                var openInFrame = false;
                var makeHrefEmpty = false;
                url = match;
                if (!Utils.PROTOCOL_PATTERN.exec(match)) {
                    // Make sure that protocol is specified
                    url = 'http://' + url;
                }

                if (makeHrefEmpty) {
                    return LT + 'a' + GT + match + LT + '/a' + GT;
                }

                if (createNewPage) {
                    target = ' target=' + QUOT + '_blank' + QUOT;
                } else if (openInFrame) {
                    target = ' target=' + QUOT + 'circuitProtocolFrame' + QUOT;
                }
                rel = ' rel=' + QUOT + 'noopener noreferrer' + QUOT;
            }
            return LT + 'a href=' + QUOT + url + QUOT + target + rel + GT + match + LT + '/a' + GT;
        };

        // Splits text on white spaces or punctuation marks with white spaces. Use Positive Lookahead to keep characters in place.
        var tokens = text.split(Utils.URL_SEPARATORS_PATTERN);
        for (var i = 0; i < tokens.length; i++) {
            // order of replacements matters.
            var token = tokens[i];
            var strippedText = Utils.stripParenthesis(token);
            var linkifiedText = strippedText;
            // Perform simply search at first, in order not to use more complex regular expressions if it is not needed.
            if (linkifiedText.search(/http|ftp|https|circuit/gim) >= 0 && linkifiedText.search(Utils.URL_PATTERN) >= 0) {
                linkifiedText = linkifiedText.replace(Utils.URL_PATTERN, checkForCircuitLink);
            } else if (linkifiedText.search(/www\./gim) >= 0 && linkifiedText.search(Utils.PSEUDO_URL_PATTERN) >= 0) {
                linkifiedText = linkifiedText.replace(Utils.PSEUDO_URL_PATTERN, checkForCircuitLink);
            } else if (linkifiedText.search(/\S@\S/gim) >= 0 && linkifiedText.search(Utils.EMAIL_ADDRESS_INCLUDED_PATTERN) >= 0) {
                linkifiedText = linkifiedText.replace(Utils.EMAIL_ADDRESS_INCLUDED_PATTERN, LT + 'a href=' + QUOT + 'mailto:$&' + QUOT + ' target=' +
                    QUOT + '_blank' + QUOT + ' rel=' + QUOT + 'noopener noreferrer' + QUOT + GT + '$&' + LT + '/a' + GT);
            }

            token = token.replace(strippedText, linkifiedText);
            tokens[i] = token;
        }
        text = tokens.join('');

        /* eslint-disable no-control-regex */
        // This temporary replacement using the non-printable chars is done in order to
        // exclude the chars used to compose an href tag (<,>,') from the html escape process when in plain text mode.
        return Utils.textToHtmlEscaped(text)
            .replace(/\x11/gi, '<') // Linkify was done, replace them back
            .replace(/\x12/gi, '>')
            .replace(/\x13/gi, '\'');
        /* eslint-enable no-control-regex */
    };

    function linkifyNode(startNode, linkifiedDOM) { // Node traversing method in order to find text nodes and linkify them
        if (!startNode || !startNode.childNodes || !startNode.childNodes.length) {
            return startNode;
        }

        var currentNode;
        for (var i = 0, len = startNode.childNodes.length; i < len; i++) {
            currentNode = startNode.childNodes[i];
            switch (currentNode.nodeType) {
            case 1:  // ELEMENT_NODE
                if (currentNode.nodeName === 'A') {
                    // Add target="_blank" if not a Circuit link. SDK can send a tag without target. Or remove origin if Circuit link.
                    var origin = getOrigin();
                    var refHref = Utils.getCircuitRelativeLink(origin, currentNode.href);
                    if (!refHref) {
                        currentNode.target = '_blank';
                        currentNode.rel = 'noopener noreferrer';
                    } else {
                        currentNode.removeAttribute('target');
                        currentNode.href = refHref;
                    }
                }
                // Don't linkify link preview. E.g. description may have a url
                if (currentNode.classList.contains('link-preview') || currentNode.classList.contains('fr-link')) {
                    break;
                }
                linkifyNode(currentNode, linkifiedDOM);
                break;
            case 3:  // TEXT_NODE
                linkifiedDOM.innerHTML = Utils.linkifyText(currentNode.textContent, currentNode.parentNode.tagName === 'PRE');
                i += linkifiedDOM.childNodes.length - 1;
                while (linkifiedDOM.childNodes.length) {
                    startNode.insertBefore(linkifiedDOM.childNodes[0], currentNode);
                }
                startNode.removeChild(currentNode);
                len = startNode.childNodes.length;
            }
        }
        return startNode;
    }

    Utils.linkifyContent = function (content, contentType) {
        if (!content) {
            return '';
        }
        var MAX_TEXT_CONTENT_SIZE = 50000;
        if (content.length > MAX_TEXT_CONTENT_SIZE) {
            // Do not convert large text items due to performance
            return content;
        }

        if (contentType === 'PLAIN') {
            // When content is plain text, html tags are escaped and content is linkified as text
            return Utils.linkifyText(content);
        }
        // Assume RICH text by default
        // When content is html, parsing is done node-wise, and every text nodes is parsed for links
        return linkifyHtml(content);
    };

    Utils.invalidFileTypes = ['ade', 'adp', 'app', 'asp', 'bas', 'bat', 'cer', 'chm', 'cmd', 'cnt', 'com', 'cpl', 'crt', 'csh', 'der', 'exe', 'fxp',
        'gadget', 'hlp', 'hpj', 'hta', 'inf', 'ins', 'isp', 'its', 'js', 'jse', 'ksh', 'lnk', 'mad', 'maf', 'mag', 'mam', 'maq', 'mar', 'mas', 'mat',
        'mau', 'mav', 'maw', 'mda', 'mdb', 'mde', 'mdt', 'mdw', 'mdz', 'msc', 'msh', 'msh1', 'msh1xml', 'msh2', 'msh2xml', 'mshxml', 'msi', 'msp', 'mst',
        'ops', 'osd', 'pcd', 'pif', 'plg', 'prf', 'prg', 'ps1', 'ps1xml', 'ps2', 'ps2xml', 'psc1', 'psc2', 'pst', 'reg', 'scf', 'scr', 'sct', 'shb', 'shs',
        'tmp', 'url', 'vb', 'vbe', 'vbp', 'vbs', 'vsmacros', 'vsw', 'ws', 'wsc', 'wsf', 'wsh', 'xnk'];

    /**
     * Check if container has a scrollbar
     */
    Utils.hasScrollbar = function (container) {
        if (container && container.length > 0) {
            var elm = container[0];
            return !!elm && (elm.scrollHeight > elm.clientHeight);
        }
        return false;
    };
    /**
     * Returns true if child element is either equal the ancestor element or a nested descendant of the ancestor element.
     */
    Utils.isDescendantOrEqual = function (child, ancestor) {
        if (child === ancestor) {
            return true;
        }
        var _parent = child;
        while (_parent) {
            _parent = _parent.parentElement;
            if (_parent === ancestor) {
                return true;
            }
        }
        return false;
    };

    Utils.isSupportedImage = function (mimeType) {
        var regExp = /^image\/(jpeg|gif|bmp|png)$/i;
        return regExp.test(mimeType); // Accept only image/jpeg, image/gif, image/bmp and image/png MIME types
    };

    /**
    * We handle video types that we can play in embeddedPlayer.
    * Handling done based on MIME type.
    * Note: Probably more types should be added on the future.
    */
    Utils.isVideoSupportedByBrowser = function (mimeType) {
        var regExp = /^video\/(quicktime|mp4|webm)$/i;
        return regExp.test(mimeType);
    };

    /**
     * Returns a copy of the source object
     */
    Utils.shallowCopy = function (src) {
        if (!src || (typeof src !== 'object')) {
            return src;
        }

        if (Array.isArray(src)) {
            return src.slice();
        }

        var flattenSrc = Utils.flattenObj(src);
        var obj = {};
        for (var key in flattenSrc) {
            if (flattenSrc.hasOwnProperty(key)) {
                obj[key] = flattenSrc[key];
            }
        }
        return obj;
    };

    /**
     * Returns the normalized locale string ('en-US' to 'EN_US' or 'de-DE' to 'DE_DE' or 'DE_DE' to 'DE_DE') or
     * undefined if the parameter is not valid
     */
    Utils.normalizeLocaleProto = function (locale) {
        var proto;
        if (locale && locale.length >= 5) {
            proto = locale.replace(/-/g, '_').toUpperCase();
        }
        return proto;
    };

    /**
     * Returns the normalized locale string ('EN_US' to 'en-US' or 'DE_DE' to 'de-DE' or 'de-DE' to 'de-DE') or
     * undefined if the parameter is not valid
     */
    Utils.normalizeLocale = function (proto) {
        var locale;
        if (proto && proto.length >= 5) {
            locale = proto.substr(0, 2).toLowerCase() + proto.substr(2, proto.length - 2).replace(/_/g, '-');
        }
        return locale;
    };

    Utils.getTimezoneName = function (date) {
        date = date || new Date();
        // eslint-disable-next-line newline-per-chained-call
        return date.toLocaleDateString('en-US', {timeZoneName: 'long'}).split(', ').pop();
    };

    /**
     * Return a timezone string from a given timezone offset
     */
    Utils.getTimezone = function (timeZoneOffset) {
        if (typeof timeZoneOffset !== 'number') {
            timeZoneOffset = new Date().getTimezoneOffset();
        }
        var hour = Math.floor(Math.abs(timeZoneOffset) / 60);
        var min = Math.floor(Math.abs(timeZoneOffset) % 60) + ''; // Convert to string so we can invoke padStart
        return (timeZoneOffset > 0 ? '-' : '+') + hour + min.padStart(2, '0');
    };

    /**
     * Returns new calculated timestamp instead of provided one when delayMS (milliseconds) is correctly set
     */
    Utils.delayOrTimestamp = function (delayMS, timestamp) {
        return delayMS > 0 ? Date.now() + delayMS : timestamp;
    };

    /**
     * Build the attachmentMetaData array used in the client API from a given list of uploaded files
     */
    Utils.buildAttachmentMetaData = function (files) {
        var buildMetaData = function (attachment) {
            var res = {
                fileId: attachment.fileId,
                fileName: attachment.fileName,
                itemId: attachment.itemId,
                mimeType: attachment.mimeType,
                size: attachment.size
            };
            if (attachment.thumbnail && attachment.thumbnail.fileId) {
                res.thumbnailId = attachment.thumbnail.fileId;
            }
            if (attachment.inlineUsage) {
                res.inlineUsage = attachment.inlineUsage;
            }
            return res;
        };

        var attachments = [];
        files && files.forEach(function (file) {
            attachments.push(buildMetaData(file));
            if (file.thumbnail) {
                attachments.push(buildMetaData(file.thumbnail));
            }
        });

        return {
            attachments: attachments
        };
    };

    /*
     * Pseudo-classical OOP pattern
     * Child and Parent must be constructor function
     */
    Utils.inherit = function (Child, Parent) {
        function F() {}
        F.prototype = Parent.prototype;
        Child.prototype = new F();
        Child.prototype.constructor = Child;
        Child.parent = Parent.prototype;
    };


    Utils.bytesToSize = function (bytes, precision) {
        // Make sure we are working with an integer
        bytes = parseInt(bytes, 10);
        if (!bytes) { return '0 Bytes'; }
        var k = 1024;
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return Number((bytes / Math.pow(k, i)).toPrecision(precision || 3)) + ' ' + sizes[i];
    };

    // Check if Mac OS, default false
    Utils.isMacOs = window.navigator && window.navigator.userAgent ?
        window.navigator.userAgent.indexOf('Mac') !== -1 || window.navigator.userAgent.indexOf('Darwin') !== -1 : false;

    // Check if Windows, default false
    Utils.isWindowsOs = window.navigator && window.navigator.platform ? window.navigator.platform.substring(0, 3).toLowerCase() === 'win' : false;

    /**
     * Function used to determine if client is mobile
     */
    Utils.isMobile = function () {
        return window.navigator.platform === 'iOS' || window.navigator.platform === 'Android';
    };

    /**
     * Function used to determine if client is iOS
     */
    Utils.isIOS = function () {
        return window.navigator.platform === 'iOS';
    };

    /**
     * Format PIN to readable string:
     * tenant PIN, space, session PIN separated every SESSION_PIN_CHUNK_LEN
     * every session PIN chunck should be smaller than SESSION_PIN_CHUNK_MIN_LEN
     * set noDelimiters to true if the string shouldn't have any delimiters (spaces)
     */
    Utils.formatPIN = function (tenantPIN, sessionPIN, noDelimiters) {
        if (!sessionPIN) {
            return '';
        }
        if (noDelimiters) {
            return (tenantPIN || '') + sessionPIN + '#';
        }

        // initial block chunck length
        var SESSION_PIN_FIRST_CHUNK_LEN = 4;
        // session PIN chunck length
        var SESSION_PIN_CHUNK_LEN = 3;
        // session PIN chunck minimum length
        var SESSION_PIN_CHUNK_MIN_LEN = 2;

        var pin = [];
        var chunk;
        // during PIN migration assure that format always looks the same for
        // new (10 digit access code, no TIN) and old (TIN + 6 digit access code)
        var chunkLen = tenantPIN ? SESSION_PIN_CHUNK_LEN : SESSION_PIN_FIRST_CHUNK_LEN;
        for (var i = 0; i < sessionPIN.length; i += chunkLen) {
            if (i > 0) {
                chunkLen = SESSION_PIN_CHUNK_LEN;
            }
            chunk = sessionPIN.substr(i, chunkLen);
            if (chunk.length >= SESSION_PIN_CHUNK_MIN_LEN || pin.length === 0) {
                pin.push(chunk);
            } else {
                pin[pin.length - 1] += chunk;
            }
        }
        if (tenantPIN) {
            pin.unshift(tenantPIN);
        }
        pin.push('#');
        return pin.join(' ');
    };

    Utils.CountriesArray = [
        { name: 'res_countryName_Andorra', code: 'AD' },
        { name: 'res_countryName_United_Arab_Emirates', code: 'AE' },
        { name: 'res_countryName_Afghanistan', code: 'AF' },
        { name: 'res_countryName_Antigua_and_Barbuda', code: 'AG' },
        { name: 'res_countryName_Anguilla', code: 'AI' },
        { name: 'res_countryName_Albania', code: 'AL' },
        { name: 'res_countryName_Armenia', code: 'AM' },
        { name: 'res_countryName_Netherland_Antilles', code: 'AN' },
        { name: 'res_countryName_Angola', code: 'AO' },
        { name: 'res_countryName_Antarctic', code: 'AQ' },
        { name: 'res_countryName_Argentina', code: 'AR' },
        { name: 'res_countryName_American_Samoa', code: 'AS' },
        { name: 'res_countryName_Austria', code: 'AT' },
        { name: 'res_countryName_Australia', code: 'AU' },
        { name: 'res_countryName_Aruba', code: 'AW' },
        { name: 'res_countryName_Azerbaijan', code: 'AZ' },
        { name: 'res_countryName_Bosnia_and_Herzegovina', code: 'BA' },
        { name: 'res_countryName_Barbados', code: 'BB' },
        { name: 'res_countryName_Bangladesh', code: 'BD' },
        { name: 'res_countryName_Belgium', code: 'BE' },
        { name: 'res_countryName_Burkina_Faso', code: 'BF' },
        { name: 'res_countryName_Bulgaria', code: 'BG' },
        { name: 'res_countryName_Bahrain', code: 'BH' },
        { name: 'res_countryName_Burundi', code: 'BI' },
        { name: 'res_countryName_Benin', code: 'BJ' },
        { name: 'res_countryName_Bermuda', code: 'BM' },
        { name: 'res_countryName_Brunei_Darussalam', code: 'BN' },
        { name: 'res_countryName_Bolivia', code: 'BO' },
        { name: 'res_countryName_Brazil', code: 'BR' },
        { name: 'res_countryName_Bahamas', code: 'BS' },
        { name: 'res_countryName_Bhutan', code: 'BT' },
        { name: 'res_countryName_Botswana', code: 'BW' },
        { name: 'res_countryName_Belarus', code: 'BY' },
        { name: 'res_countryName_Belize', code: 'BZ' },
        { name: 'res_countryName_Canada', code: 'CA' },
        { name: 'res_countryName_Dem_Republic_Congo', code: 'CD' },
        { name: 'res_countryName_Central_African_Republik', code: 'CF' },
        { name: 'res_countryName_Congo', code: 'CG' },
        { name: 'res_countryName_Switzerland', code: 'CH' },
        { name: 'res_countryName_Cote_d_Ivoire', code: 'CI' },
        { name: 'res_countryName_Chile', code: 'CL' },
        { name: 'res_countryName_Cameroon', code: 'CM' },
        { name: 'res_countryName_China_Peoples_Republic', code: 'CN' },
        { name: 'res_countryName_Colombia', code: 'CO' },
        { name: 'res_countryName_Costa_Rica', code: 'CR' },
        { name: 'res_countryName_Cuba', code: 'CU' },
        { name: 'res_countryName_Cap_Verde', code: 'CV' },
        { name: 'res_countryName_Cyprus', code: 'CY' },
        { name: 'res_countryName_Czech_Republik', code: 'CZ' },
        { name: 'res_countryName_Germany', code: 'DE' },
        { name: 'res_countryName_Djibouti', code: 'DJ' },
        { name: 'res_countryName_Denmark', code: 'DK' },
        { name: 'res_countryName_Dominica', code: 'DM' },
        { name: 'res_countryName_Dominican_Republik', code: 'DO' },
        { name: 'res_countryName_Algeria', code: 'DZ' },
        { name: 'res_countryName_Ecuador', code: 'EC' },
        { name: 'res_countryName_Estonia', code: 'EE' },
        { name: 'res_countryName_Egypt', code: 'EG' },
        { name: 'res_countryName_Westsahara', code: 'EH' },
        { name: 'res_countryName_Eritrea', code: 'ER' },
        { name: 'res_countryName_Spain', code: 'ES' },
        { name: 'res_countryName_Ethiopia', code: 'ET' },
        { name: 'res_countryName_Finland', code: 'FI' },
        { name: 'res_countryName_Fiji', code: 'FJ' },
        { name: 'res_countryName_Falkland_Islands', code: 'FK' },
        { name: 'res_countryName_Micronesia_Fed_States', code: 'FM' },
        { name: 'res_countryName_Faroer', code: 'FO' },
        { name: 'res_countryName_France', code: 'FR' },
        { name: 'res_countryName_Gaboon', code: 'GA' },
        { name: 'res_countryName_United_Kingdom', code: 'GB' },
        { name: 'res_countryName_United_Kingdom', code: 'UK' },
        { name: 'res_countryName_Grenada', code: 'GD' },
        { name: 'res_countryName_Georgia', code: 'GE' },
        { name: 'res_countryName_French_Guayana', code: 'GF' },
        { name: 'res_countryName_Ghana', code: 'GH' },
        { name: 'res_countryName_Gibraltar', code: 'GI' },
        { name: 'res_countryName_Greenland', code: 'GL' },
        { name: 'res_countryName_Gambia', code: 'GM' },
        { name: 'res_countryName_Guinea', code: 'GN' },
        { name: 'res_countryName_Guadeloupe', code: 'GP' },
        { name: 'res_countryName_Equatorial_Guinea', code: 'GQ' },
        { name: 'res_countryName_Greece', code: 'GR' },
        { name: 'res_countryName_South_Georgia_South_Sandwichisland', code: 'GS' },
        { name: 'res_countryName_Guatemala', code: 'GT' },
        { name: 'res_countryName_Guam', code: 'GU' },
        { name: 'res_countryName_Guinea_Bissau', code: 'GW' },
        { name: 'res_countryName_Guyana', code: 'GY' },
        { name: 'res_countryName_Hong_Kong', code: 'HK' },
        { name: 'res_countryName_Honduras', code: 'HN' },
        { name: 'res_countryName_Croatia', code: 'HR' },
        { name: 'res_countryName_Haiti', code: 'HT' },
        { name: 'res_countryName_Hungary', code: 'HU' },
        { name: 'res_countryName_Canary_Island', code: 'IC' },
        { name: 'res_countryName_Indonesia', code: 'ID' },
        { name: 'res_countryName_Ireland', code: 'IE' },
        { name: 'res_countryName_Israel', code: 'IL' },
        { name: 'res_countryName_India', code: 'IN' },
        { name: 'res_countryName_British_Indian_Ocean_Territory', code: 'IO' },
        { name: 'res_countryName_Iraq', code: 'IQ' },
        { name: 'res_countryName_Iran_Islamic_Republic', code: 'IR' },
        { name: 'res_countryName_Iceland', code: 'IS' },
        { name: 'res_countryName_Italy', code: 'IT' },
        { name: 'res_countryName_Jamaica', code: 'JM' },
        { name: 'res_countryName_Jordan', code: 'JO' },
        { name: 'res_countryName_Japan', code: 'JP' },
        { name: 'res_countryName_Kenya', code: 'KE' },
        { name: 'res_countryName_Kyrgyzstan', code: 'KG' },
        { name: 'res_countryName_Cambodia', code: 'KH' },
        { name: 'res_countryName_Kiribati', code: 'KI' },
        { name: 'res_countryName_Comoros', code: 'KM' },
        { name: 'res_countryName_St_Kitts_and_Nevis', code: 'KN' },
        { name: 'res_countryName_Korea_Dem_Peoples_Rep_North_Korea', code: 'KP' },
        { name: 'res_countryName_Korea_South', code: 'KR' },
        { name: 'res_countryName_Kuwait', code: 'KW' },
        { name: 'res_countryName_Cayman_Islands', code: 'KY' },
        { name: 'res_countryName_Kazakhstan', code: 'KZ' },
        { name: 'res_countryName_Laos_Democratic_Peoples_Republic', code: 'LA' },
        { name: 'res_countryName_Lebanon', code: 'LB' },
        { name: 'res_countryName_Saint_Lucia', code: 'LC' },
        { name: 'res_countryName_Liechtenstein', code: 'LI' },
        { name: 'res_countryName_Sri_Lanka', code: 'LK' },
        { name: 'res_countryName_Liberia', code: 'LR' },
        { name: 'res_countryName_Lesotho', code: 'LS' },
        { name: 'res_countryName_Lithuania', code: 'LT' },
        { name: 'res_countryName_Luxembourg', code: 'LU' },
        { name: 'res_countryName_Latvia', code: 'LV' },
        { name: 'res_countryName_Libya_Libyan_Arabian_Dschamahirija', code: 'LY' },
        { name: 'res_countryName_Morocco', code: 'MA' },
        { name: 'res_countryName_Monaco', code: 'MC' },
        { name: 'res_countryName_Moldova_Republic', code: 'MD' },
        { name: 'res_countryName_Montenegro', code: 'ME' },
        { name: 'res_countryName_Madagascar', code: 'MG' },
        { name: 'res_countryName_Marshall_Islands', code: 'MH' },
        { name: 'res_countryName_Mazedonia_former_Rep_of_Yugoslavia', code: 'MK' },
        { name: 'res_countryName_Mali', code: 'ML' },
        { name: 'res_countryName_Myanmar_Burma', code: 'MM' },
        { name: 'res_countryName_Mongolia', code: 'MN' },
        { name: 'res_countryName_Macau', code: 'MO' },
        { name: 'res_countryName_Northern_Marians', code: 'MP' },
        { name: 'res_countryName_Martinique', code: 'MQ' },
        { name: 'res_countryName_Mauretania', code: 'MR' },
        { name: 'res_countryName_Montserrat', code: 'MS' },
        { name: 'res_countryName_Malta', code: 'MT' },
        { name: 'res_countryName_Mauritius', code: 'MU' },
        { name: 'res_countryName_Maledives', code: 'MV' },
        { name: 'res_countryName_Malawi', code: 'MW' },
        { name: 'res_countryName_Mexico', code: 'MX' },
        { name: 'res_countryName_Malaysia', code: 'MY' },
        { name: 'res_countryName_Mozambique', code: 'MZ' },
        { name: 'res_countryName_Namibia', code: 'NA' },
        { name: 'res_countryName_New_Caledonia', code: 'NC' },
        { name: 'res_countryName_Niger', code: 'NE' },
        { name: 'res_countryName_Norfolk_Islands', code: 'NF' },
        { name: 'res_countryName_Nigeria', code: 'NG' },
        { name: 'res_countryName_Nicaragua', code: 'NI' },
        { name: 'res_countryName_Netherlands', code: 'NL' },
        { name: 'res_countryName_Norway', code: 'NO' },
        { name: 'res_countryName_Nepal', code: 'NP' },
        { name: 'res_countryName_Nauru', code: 'NR' },
        { name: 'res_countryName_New_Zealand', code: 'NZ' },
        { name: 'res_countryName_Oman', code: 'OM' },
        { name: 'res_countryName_Panama', code: 'PA' },
        { name: 'res_countryName_Peru', code: 'PE' },
        { name: 'res_countryName_French_Polynesia', code: 'PF' },
        { name: 'res_countryName_Papua_New_Guinea', code: 'PG' },
        { name: 'res_countryName_Philippines', code: 'PH' },
        { name: 'res_countryName_Pakistan', code: 'PK' },
        { name: 'res_countryName_Poland', code: 'PL' },
        { name: 'res_countryName_Saint_Pierre_and_Miquelon', code: 'PM' },
        { name: 'res_countryName_Pitcairn_Island', code: 'PN' },
        { name: 'res_countryName_Puerto_Rico', code: 'PR' },
        { name: 'res_countryName_Palestine_regions_occupied', code: 'PS' },
        { name: 'res_countryName_Portugal', code: 'PT' },
        { name: 'res_countryName_Palau', code: 'PW' },
        { name: 'res_countryName_Paraguay', code: 'PY' },
        { name: 'res_countryName_Quatar', code: 'QA' },
        { name: 'res_countryName_Reunion', code: 'RE' },
        { name: 'res_countryName_Romania', code: 'RO' },
        { name: 'res_countryName_Russian_Federation', code: 'RU' },
        { name: 'res_countryName_Rwanda', code: 'RW' },
        { name: 'res_countryName_Saudi_Arabia', code: 'SA' },
        { name: 'res_countryName_Solomonen', code: 'SB' },
        { name: 'res_countryName_Seychelles', code: 'SC' },
        { name: 'res_countryName_Sudan', code: 'SD' },
        { name: 'res_countryName_Sweden', code: 'SE' },
        { name: 'res_countryName_Singapore', code: 'SG' },
        { name: 'res_countryName_Saint_Helena', code: 'SH' },
        { name: 'res_countryName_Slovenia', code: 'SI' },
        { name: 'res_countryName_Spitzbergen', code: 'SJ' },
        { name: 'res_countryName_Slovakia', code: 'SK' },
        { name: 'res_countryName_Sierra_Leone', code: 'SL' },
        { name: 'res_countryName_San_Marino', code: 'SM' },
        { name: 'res_countryName_Senegal', code: 'SN' },
        { name: 'res_countryName_Somalia', code: 'SO' },
        { name: 'res_countryName_Suriname', code: 'SR' },
        { name: 'res_countryName_South_Sudan_Republic_of', code: 'SS' },
        { name: 'res_countryName_Sao_Tome_and_Principe', code: 'ST' },
        { name: 'res_countryName_El_Salvador', code: 'SV' },
        { name: 'res_countryName_Syria_Arabian_Republic', code: 'SY' },
        { name: 'res_countryName_Swaziland', code: 'SZ' },
        { name: 'res_countryName_Turks_and_Caicos_Islands', code: 'TC' },
        { name: 'res_countryName_Chad', code: 'TD' },
        { name: 'res_countryName_Togo', code: 'TG' },
        { name: 'res_countryName_Thailand', code: 'TH' },
        { name: 'res_countryName_Tajikstan', code: 'TJ' },
        { name: 'res_countryName_Tokelau', code: 'TK' },
        { name: 'res_countryName_Timor_Leste', code: 'TL' },
        { name: 'res_countryName_Turkmenistan', code: 'TM' },
        { name: 'res_countryName_Tunisia', code: 'TN' },
        { name: 'res_countryName_Tonga', code: 'TO' },
        { name: 'res_countryName_Turkey', code: 'TR' },
        { name: 'res_countryName_Trinidad_and_Tobago', code: 'TT' },
        { name: 'res_countryName_Tuvalu', code: 'TV' },
        { name: 'res_countryName_Taiwan', code: 'TW' },
        { name: 'res_countryName_Tanzania_United_Republic', code: 'TZ' },
        { name: 'res_countryName_Ukraine', code: 'UA' },
        { name: 'res_countryName_Uganda', code: 'UG' },
        { name: 'res_countryName_Minor_American_Oversea_Islands', code: 'UM' },
        { name: 'res_countryName_United_States_of_America', code: 'US' },
        { name: 'res_countryName_Uruguay', code: 'UY' },
        { name: 'res_countryName_Uzbekistan', code: 'UZ' },
        { name: 'res_countryName_Vatican_City', code: 'VA' },
        { name: 'res_countryName_Saint_Vincent_and_Grenadines', code: 'VC' },
        { name: 'res_countryName_Venezuela', code: 'VE' },
        { name: 'res_countryName_British_Virgin_Islands', code: 'VG' },
        { name: 'res_countryName_American_Virgin_Islands', code: 'VI' },
        { name: 'res_countryName_Vietnam', code: 'VN' },
        { name: 'res_countryName_Vanuatu', code: 'VU' },
        { name: 'res_countryName_Wallis_and_Futuna', code: 'WF' },
        { name: 'res_countryName_Samoa', code: 'WS' },
        { name: 'res_countryName_Kosovo', code: 'XK' },
        { name: 'res_countryName_Serbia', code: 'XS' },
        { name: 'res_countryName_Yemen', code: 'YE' },
        { name: 'res_countryName_Mayotte', code: 'YT' },
        { name: 'res_countryName_South_Africa', code: 'ZA' },
        { name: 'res_countryName_Zambia', code: 'ZM' },
        { name: 'res_countryName_Zimbabwe', code: 'ZW' }
    ];

    Utils.CountryCodeToCountryNameResourceMap = {};

    Utils.CountriesArray.forEach(function (country) {
        Utils.CountryCodeToCountryNameResourceMap[country.code] = country.name;
    });

    /**
     * Returns relative link if passed url is an accepted Circuit link, otherwise returns empty string.
     * Currently accepted links are:
     *  - {host}/#/conversation/{convId}
     *  - {host}/#/conversation/{convId}?user={userId}
     *  - {host}/#/conversation/{convId}?item={itemId}
     *  - {host}/#/conversation/{convId}?item={itemId}&user={userId}
     *  - same for /open and /muted
     *  @param {String} host The host to test against, with or without protocol. If omitted, current origin is used.
     *  @param {String} link The link to test if it belongs to the given Circuit host
     *  @returns {String} Relative link or empty string
     */
    Utils.getCircuitRelativeLink = function (host, link) {
        if (!link) {
            return '';
        }

        var PARTNER_SUBDOMAIN = 'partner.';
        host = host || getOrigin();

        if (link.startsWith('#/')) { // relative links
            return link;
        }
        // Normalization of params - failsafe
        if (!Utils.PROTOCOL_PATTERN.exec(host)) {
            host = HTTPS + host;
        }
        if (!host.endsWith('/')) {
            host = host + '/';
        }
        if (!Utils.PROTOCOL_PATTERN.exec(link)) {
            link = HTTPS + link;
        }
        if (!link.startsWith(host)) {
            // https://host.com and https://partner.host.com systems are the same,
            // it means that the links should lead to the same host which user is logged into (ANS-50527)
            var partnerDomain = HTTPS + PARTNER_SUBDOMAIN;
            var linkDomain = host.startsWith(partnerDomain) ? host.replace(partnerDomain, HTTPS) :
                host.replace(HTTPS, partnerDomain);
            if (link.startsWith(linkDomain)) {
                link = link.replace(linkDomain, host);
            }
        }

        if ((link.length > host.length) && link.startsWith(host)) {
            var urlHash = link.slice(host.length);
            var res = urlHash.match(Utils.CIRCUIT_CONVERSATION_HASH_PATTERN);
            if (res) {
                // If conversation is muted, return it as normal so other users can see it if I share its link to them.
                // We also still need to support old links to open conversations, but we must replace with a normal link.
                return res[0].replace(/\/(muted|open)/gim, '/conversation');
            }
            var decodedURL = urlHash;
            try {
                decodedURL = decodeURI(decodedURL);
            } catch (e) {}

            // Check if this is a user, user profile, email and phone links (e.g. mention)
            res = urlHash.match(Utils.CIRCUIT_LINKS_HASH_PATTERN) ||
                urlHash.match(Utils.CIRCUIT_EMAIL_HASH_PATTERN) ||
                decodedURL.match(Utils.CIRCUIT_HASH_TAG_SEARCH_PATTERN);
            return res && res[0];
        }
        return '';
    };

    /**
     * Function that tests if a given url is an accepted Circuit link so it can be opened within circuit.
     * Currently accepted links are listed in Utils.getCircuitRelativeLink.
     *  @param {String} host The host to test against, with or without protocol. If omitted, current origin is used.
     *  @param {String} link The link to test if it belongs to the given Circuit host
     *  @returns {Boolean}
     */
    Utils.isCircuitLink = function (host, link) {
        return !!Utils.getCircuitRelativeLink(host, link);
    };

    /**
     * Gratefully borrowed from Underscore. http://underscorejs.org/#throttle
     *
     * Creates and returns a new, throttled version of the passed function, that,
     * when invoked repeatedly, will only actually call the original function at
     * most once per every wait milliseconds. Useful for rate-limiting events that
     * occur faster than you can keep up with.
     */
    Utils.throttle = function (func, wait, options) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        options = options || {};

        var later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            context = args = null;
        };
        return function () {
            var now = Date.now();
            if (!previous && options.leading === false) {
                previous = now;
            }
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0) {
                if (timeout) {
                    window.clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = window.setTimeout(later, remaining);
            }
            return result;
        };
    };

    Utils.hasEmptyPrototype = function (obj) {
        if (!obj || (typeof obj !== 'object')) {
            return false;
        }
        return Utils.isEmptyObject(Object.getPrototypeOf(obj));
    };

    Utils.compareElements = function (el1, el2) {
        if (el1 === el2) {
            return true;
        }
        if (!el1 || !el2) {
            return false;
        }
        if (typeof el1 !== 'object' || typeof el2 !== 'object') {
            return false;
        }

        if (el1 instanceof Array && el2 instanceof Array) {
            if (el1.length !== el2.length) {
                return false;
            }

            // Create a copy of arr2
            var arr2 = el2.slice();

            return el1.every(function (elem1) {
                return arr2.some(function (elem2, idx2) {
                    if (Utils.compareElements(elem1, elem2)) {
                        arr2.splice(idx2, 1);
                        return true;
                    }
                    return false;
                });
            });
        }

        if (!Utils.hasEmptyPrototype(el1) || !Utils.hasEmptyPrototype(el2)) {
            return false;
        }

        for (var key in el1) {
            // Don't compare inheritable or angular properties
            if (el1.hasOwnProperty(key) && key.indexOf('$$') !== 0) {
                if (!Utils.compareElements(el1[key], el2[key])) { return false; }
            }
        }
        return true;
    };


    Utils.copyToClipboard = function (copyText, cb, asText) {
        // Do not use document.queryCommandSupported('copy') to check if copy is enabled - seems it's buggy now
        // https://code.google.com/p/chromium/issues/detail?id=476508
        var selection;
        var range;
        var buffer;
        if (!copyText) {
            return;
        }

        try {
            range = document.createRange();
            selection = window.getSelection();

            buffer = asText ? document.createElement('textarea') : document.createElement('div');

            if (!asText) {
                // Replace new line with <br> for correct view in Outlook
                copyText = copyText.replace(/\r\n/gi, '<br>');
            }

            // Note: Chrome doesn't override the bg-color with transparent in Outlook, so use the #fff;
            buffer.style.backgroundColor = '#fff';
            buffer.style.font = 'normal normal 15px Calibri, sans-serif';
            buffer.style['white-space'] = 'pre-wrap';
            if (asText) {
                buffer.value = copyText;
                buffer.setAttribute('readonly', '');
                // Hide and append the element
                buffer.style.position = 'absolute';
                buffer.style.left = '-9999px';
                document.body.appendChild(buffer);

                selection.removeAllRanges();
                buffer.select();
                buffer.setSelectionRange(0, copyText.length);
            } else {
                buffer.innerHTML = copyText;
                document.body.appendChild(buffer);

                range.selectNode(buffer);
                selection.removeAllRanges();
                selection.addRange(range);
            }
            var successful = document.execCommand('copy');
            if (successful) {
                cb && cb();
            } else {
                cb && cb('Failed to copy');
            }

        } catch (e) {
            logger.error('[Utils]: Copy to clipboard failed');
            cb && cb('Internal error');

        } finally {
            if (selection) {
                if (typeof selection.removeRange === 'function' && !asText) {
                    selection.removeRange(range);
                } else {
                    selection.removeAllRanges();
                }
            }

            if (buffer) {
                document.body.removeChild(buffer);
            }
        }
    };

    Utils.toCamelCase = function (str) {
        if (!str || typeof str !== 'string') {
            return '';
        }
        var arr = str.toLowerCase().split(/[_-]/);
        for (var i = 1; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join('');
    };

    Utils.sslProxify = function (url, protocol) {
        if (url && !Utils.PROTOCOL_PATTERN.exec(url)) {
            url = url.startsWith('//') ? url.replace('//', protocol) : protocol + url;
        }
        if (url && url.startsWith('http://')) {
            return SSL_IMAGE_PROXY + encodeURIComponent(url);
        }
        return url;
    };

    Utils.parsePreviewObject = function (url, obj) {
        var previewURL = Utils.PROTOCOL_PATTERN.test(url) ? url : 'http://' + url;

        var res = {
            preview: {
                type: obj.type,
                title: obj.title || '',
                description: obj.description || '',
                provider: obj.provider_name || '',
                srcURL: url,
                previewURL: previewURL,
                imageURI: obj.thumbnail_url
            }
        };

        res.previewImageURISecure = Utils.sslProxify(res.preview.imageURI, Utils.getUrlProtocol(previewURL));

        switch (obj.type) {
        case 'photo':
            res.previewImageURISecure = Utils.sslProxify(url, Utils.getUrlProtocol(url));
            break;
        case 'video':
            res.preview.html = obj.html;
            break;
        case 'rich':
            res.preview.type = 'link';
            break;
        }

        return res;
    };

    Utils.appIsFocused = function () {
        return document.hasFocus();
    };

    Utils.validatePreviewProtocol = function (html) {
        if (!html) {
            return '';
        }
        if (html.match(/https:/)) {
            return html;
        }
        return html.replace('src="//', 'src="https://');
    };

    Utils.getUrlProtocol = function (url) {
        if (url && Utils.PROTOCOL_PATTERN.exec(url)) {
            return url.split('/')[0] + '//';
        }
        return 'http://';
    };

    // Used for switching between tabs (feed, details, questions...)
    // only one name can be selected (=true)
    // selecting: tabSelector.[tab-name] = true (setter)
    // checking:  tabSelector.[tab-name]        (getter)
    // tabSelector.selected : returns last selected tab name
    // first tab name during initialization will be selected automatically
    Utils.TabSelector = function (tabNames) {
        if (!Array.isArray(tabNames) || tabNames.length === 0) {
            return;
        }

        var _that = this;
        var _tabs = {};
        var _selected = '';

        function reset() {
            Object.keys(_tabs).forEach(function (name) {
                _tabs[name] = false;
            });
        }

        tabNames.forEach(function (name, idx) {
            if (idx === 0) {
                _tabs[name] = true;
                _selected = name;
            } else {
                _tabs[name] = false;
            }
            Object.defineProperty(_that, name, {
                get: function () {
                    return _tabs[name];
                },
                set: function (value) {
                    if (value) {
                        reset();
                        _tabs[name] = true;
                        _selected = name;
                    }
                },
                enumerable: true,
                configurable: false
            });
        });

        Object.defineProperty(this, 'selected', {
            get: function () {
                return _selected;
            },
            enumerable: true,
            configurable: false
        });

        Object.defineProperty(this, 'selectedIdx', {
            get: function () {
                return tabNames.indexOf(_selected);
            },
            enumerable: true,
            configurable: false
        });
    };

    /**
     * Get current origin, e.g. https://eu.yourcircuit.com
     */
    Utils.getOrigin = getOrigin;

    /**
     * Function to parse a querystring
     */
    Utils.parseQS = function (qs) {
        var params = {};
        qs = qs.substring(1);
        var regex = /([^&=]+)=([^&]*)/g;
        var m = regex.exec(qs);
        while (m) {
            params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
            m = regex.exec(qs);
        }
        return params;
    };

    /**
     * Function to build a querystring
     */
    Utils.toQS = function (obj) {
        var str = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
                var pValue = obj[p];
                if (!Array.isArray(pValue)) {
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(pValue));
                } else {
                    pValue.forEach(function (elem) {
                        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(elem));
                    });
                }
            }
        }
        return str.join('&');
    };

    Utils.extractConfigurableText = function (configurableTexts, type, language) {
        var requestedTranslations = configurableTexts && configurableTexts.find(function (configurableText) {
            return configurableText.type === type;
        });
        if (!requestedTranslations || !requestedTranslations.texts) {
            return '';
        }

        var findText = function (lang) {
            return requestedTranslations.texts.find(function (t) {
                return t.language === lang;
            });
        };

        var text;
        if (language) {
            // Look for a translation for the given language (e.g. 'en-US')
            text = findText(language);
            if (!text) {
                // Remove the country code and look for a translation for the given language (e.g. 'en')
                language = language.split('-', 1)[0];
                text = findText(language);
            }
        }
        if (!text) {
            // Look for a default translation
            text = findText('default');
        }
        return text && text.translation;
    };

    /**
     * Use this function to select one device from the preferred list based on the list of
     * available devices
     *
     * @param {Array} available - List of available devices
     * @param {Array} preferred - List of preferred devices (in order of preference)
     * @param {Boolean} matchByLabel - (Optional) Match devices by label if matching by ID fails
     * @returns {Object} - Selected device object or null if no preferred devices were found
     */
    Utils.selectMediaDevice = function (available, preferred, matchByLabel) {
        if (!available || !preferred) {
            return null;
        }
        var found;
        preferred.some(function (p) {
            if (typeof p.id === 'undefined') {
                // There should be only 1 "idless" device and it's the OS default device
                found = p;
            } else {
                found = available.find(function (a) {
                    return p.id === a.id;
                });
            }
            return !!found;
        });
        if (!found && matchByLabel) {
            preferred.some(function (p) {
                found = available.find(function (a) {
                    return p.label === a.label;
                });
                return !!found;
            });
        }
        return found;
    };

    Utils.addDeviceToPreferredList = function (preferred, device) {
        if (!device) {
            return preferred || [];
        }
        if (!preferred) {
            return [device];
        }

        // First check if device is already in the preferred list
        // Find by ID
        var foundIndex = preferred.findIndex(function (d) {
            return d.id === device.id;
        });
        if (foundIndex !== -1) {
            // If found, remove it from the list
            preferred.splice(foundIndex, 1);
        }
        preferred.unshift(device); // Save as first element
        preferred = preferred.slice(0, MAX_NUM_OF_PREFERRED_DEVICES); // Limit the number of saved devices
        return preferred;
    };

    /**
     * Remove a single element from an array.
     * @param {Array} array - Array of which to remove an element
     * @param {any} elem - Element to remove
     */
    Utils.removeArrayElement = function (array, elem) {
        if (Array.isArray(array) && typeof elem !== 'undefined' && elem) {
            var index = array.indexOf(elem);
            if (index > -1) {
                array.splice(index, 1);
            }
        }
    };

    function binaryInsert(array, arrayElement, compareValueFunction, idField, leftIndex, rightIndex) {
        if (!arrayElement || !Array.isArray(array)) {
            return -1;
        }
        if (array.length === 0) {
            array.push(arrayElement);
            return 0;
        }
        leftIndex = leftIndex || 0;
        rightIndex = rightIndex || (array.length - 1);

        if ((rightIndex - leftIndex) <= 1) {
            if (compareValueFunction(arrayElement, array[leftIndex]) < 0) {
                array.splice(leftIndex, 0, arrayElement);
                return leftIndex;
            } else if (compareValueFunction(arrayElement, array[rightIndex]) > 0) {
                array.splice(rightIndex + 1, 0, arrayElement);
                return rightIndex + 1;
            } else {
                // Make sure the arrayElement is not already in the array
                if (arrayElement[idField] === array[leftIndex][idField]) {
                    array[leftIndex] = arrayElement;
                    return leftIndex;
                }
                if (arrayElement[idField] === array[rightIndex][idField]) {
                    array[rightIndex] = arrayElement;
                    return rightIndex;
                }
                array.splice(rightIndex, 0, arrayElement);
                return rightIndex;
            }
        }
        var midIndex = Math.floor((rightIndex - leftIndex) / 2) + leftIndex;

        if (compareValueFunction(arrayElement, array[midIndex]) < 0) {
            return binaryInsert(array, arrayElement, compareValueFunction, idField, leftIndex, midIndex);
        } else {
            return binaryInsert(array, arrayElement, compareValueFunction, idField, midIndex, rightIndex);
        }
    }

    Utils.binaryInsert = function (array, arrayElement, compareValueFunction, idField) {
        return binaryInsert(array, arrayElement, compareValueFunction, idField || 'id');
    };

    Utils.isEmptyArray = function (array) {
        return array.length === 0;
    };

    Utils.lastArrayElement = function (array) {
        if (array.length > 0) {
            return array[array.length - 1];
        }
        return undefined;
    };

    Utils.shuffleArray = function (array) {
        if (array.length) {
            var l = array.length;
            for (var i = 0; i < l; i++) {
                var r = Math.floor(Math.random() * l);
                var o = array[r];
                array[r] = array[i];
                array[i] = o;
            }
        }
        return array;
    };

    Utils.randomArrayCopy = function (array, numElems) {
        if (Array.isArray(array)) {
            var copy = array.slice(0);
            Utils.shuffleArray(copy);
            return copy.splice(0, numElems);
        }
        return null;
    };

    // Move one element from old position to new position
    Utils.moveArrayElement = function (array, oldPos, newPos) {
        if (typeof oldPos !== 'number' || typeof newPos !== 'number' ||
            oldPos < 0 || oldPos >= array.length || newPos < 0 || oldPos === newPos) {
            return;
        }
        for (var k = array.length; k <= newPos; k++) {
            array.push(undefined);
        }
        array.splice(newPos, 0, array.splice(oldPos, 1)[0]);
    };

    // Empties an array. The most performant way to achieve this.
    Utils.emptyArray = function (array) {
        if (!array.length) { return; }
        while (array.length > 0) {
            array.pop();
        }
    };

    Utils.getAvatarImageFormat = function (image) {
        var regex = /[\w]+:[\w]+\/png/;
        return regex.exec(image) ? 'png' : 'jpeg';
    };

    Utils.displayNameToInitials = function (displayName) {
        if (!displayName) {
            return '';
        }
        var atPos = displayName.indexOf('@');     // EMail address ?
        var names = atPos >= 0 ? displayName.slice(0, atPos).split('.') : displayName.split(/[ ]+/);
        return ((names[0][0] || '') + (names[1] ? names[1][0] || '' : '')).toUpperCase();
    };

    var AVAILABLE_EXTENSIONS = ['pdf', 'doc', 'xls', 'ppt', 'html', 'csv', 'jpg', 'png', 'zip', 'log', 'video', 'audio'];
    Utils.getIconFile = function (extension) {
        return AVAILABLE_EXTENSIONS.includes(extension) ? 'icon-file-' + extension : 'icon-file-generic';
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // NGTC Utils
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     *
     * @description
     * Deserializes a JSON string. Replacement of angular.fromJson
     *
     * @param {string} json JSON string to deserialize.
     * @returns {Object|Array|string|number} Deserialized JSON string.
    */
    Utils.fromJson = function fromJson(json) {
        return typeof json === 'string' // isString(json)
            ? JSON.parse(json)
            : json;
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Polyfills for Object
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    if (typeof Object.values !== 'function') {
        Object.values = function (obj) {
            return Object.keys(obj).map(function (key) { return obj[key]; });
        };
    }

    if (typeof Object.assign !== 'function') {
        Object.assign = function assign(target) {
            if (target === null || target === undefined) {
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource) {
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        };
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Polyfills for String objects
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    // Simplified polyfill for ECMAScript 2015 String.prototype.startsWith().
    if (!String.prototype.startsWith) {
        Object.defineProperty(String.prototype, 'startsWith', {
            value: function (searchString, position) {
                if (searchString === null || searchString === undefined) {
                    return false;
                }
                searchString = searchString.toString();
                position = Math.floor(position) || 0;
                return this.substr(position, searchString.length) === searchString;
            }
        });
    }

    // Simplified polyfill for ECMAScript 2015 String.prototype.endsWith().
    if (!String.prototype.endsWith) {
        Object.defineProperty(String.prototype, 'endsWith', {
            value: function (searchString, position) {
                if (searchString === null || searchString === undefined) {
                    return false;
                }
                if (position === undefined) {
                    position = this.length;
                } else {
                    position = Math.min(Math.floor(position) || 0, this.length);
                }
                searchString = searchString.toString();
                position -= searchString.length;
                if (position < 0) {
                    return false;
                }
                return this.substr(position, searchString.length) === searchString;
            }
        });
    }

    // Simplified polyfill for ECMAScript 2015 String.prototype.includes().
    if (!String.prototype.includes) {
        Object.defineProperty(String.prototype, 'includes', {
            value: function (value) {
                return this.indexOf(value) !== -1;
            }
        });
    }

    // Polyfill for ECMAScript 2015 String.prototype.repeat()
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/repeat
    if (!String.prototype.repeat) {
        Object.defineProperty(String.prototype, 'repeat', {
            value: function (count) {
                if (this === null) {
                    throw new TypeError('can\'t convert ' + this + ' to object');
                }
                var str = '' + this;
                count = +count;
                if (isNaN(count)) {
                    count = 0;
                }
                if (count < 0) {
                    throw new RangeError('repeat count must be non-negative');
                }
                if (count === Infinity) {
                    throw new RangeError('repeat count must be less than infinity');
                }
                count = Math.floor(count);
                if (str.length === 0 || count === 0) {
                    return '';
                }
                // Ensuring count is a 31-bit integer allows us to heavily optimize the
                // main part. But anyway, most current (August 2014) browsers can't handle
                // strings 1 << 28 chars or longer, so:

                // eslint-disable-next-line no-bitwise
                if (str.length * count >= 1 << 28) {
                    throw new RangeError('repeat count must not overflow maximum string size');
                }
                var rpt = '';
                for (var i = 0; i < count; i++) {
                    rpt += str;
                }
                return rpt;
            }
        });
    }

    // Polyfill for ECMAScript 2017 String.prototype.padStart()
    if (!String.prototype.padStart) {
        Object.defineProperty(String.prototype, 'padStart', {
            value: function padStart(targetLength, padString) {
                // eslint-disable-next-line no-bitwise
                targetLength = targetLength >> 0; // Floor if number or convert non-number to 0;
                padString = String(typeof padString !== 'undefined' ? padString : ' ');
                if (this.length > targetLength) {
                    return String(this);
                } else {
                    targetLength = targetLength - this.length;
                    if (targetLength > padString.length) {
                        padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
                    }
                    return padString.slice(0, targetLength) + String(this);
                }
            }
        });
    }

    // Polyfill for ECMAScript 2017 String.prototype.padEnd()
    if (!String.prototype.padEnd) {
        Object.defineProperty(String.prototype, 'padEnd', {
            value: function padEnd(targetLength, padString) {
                // eslint-disable-next-line no-bitwise
                targetLength = targetLength >> 0; // Floor if number or convert non-number to 0;
                padString = String(typeof padString !== 'undefined' ? padString : ' ');
                if (this.length > targetLength) {
                    return String(this);
                } else {
                    targetLength = targetLength - this.length;
                    if (targetLength > padString.length) {
                        padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
                    }
                    return String(this) + padString.slice(0, targetLength);
                }
            }
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // New APIs for String objects
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    // Equivalent of C# String.Format method
    Object.defineProperty(String.prototype, 'format', {
        value: function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, index) {
                if (args[index] !== undefined && args[index] !== null) {
                    return args[index];
                }
                return match;
            });
        }
    });


    // Replaces character at given index
    Object.defineProperty(String.prototype, 'replaceAt', {
        value: function (index, character) {
            return this.substr(0, index) + character + this.substr(index + character.length);
        }
    });

    Object.defineProperty(String.prototype, 'randomCharacter', {
        value: function () {
            return this[Math.floor(this.length * Math.random())];
        }
    });

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Polyfills for Array objects
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    // Production steps of ECMA-262, Edition 6, 22.1.2.1
    if (!Array.from) {
        Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) { return 0; }
                if (number === 0 || !isFinite(number)) { return number; }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike/*, mapFn, thisArg */) {
                // 1. Let C be the this value.
                var C = this;

                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);

                // 3. ReturnIfAbrupt(items).
                if (arrayLike === null) {
                    throw new TypeError('Array.from requires an array-like object - not null or undefined');
                }

                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }

                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);

                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method
                // of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        }());
    }

    // Simplified polyfill for ECMAScript 2016 Array.prototype.includes().
    if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, 'includes', {
            value: function (value) {
                return this.indexOf(value) !== -1;
            }
        });
    }

    // Polyfill for ECMAScript 2015 (ES6) Array.prototype.find().
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function (predicate) {
                if (this === null || this === undefined) {
                    throw new TypeError('Array.prototype.find called on null or undefined');
                }
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }
                var list = Object(this);
                // eslint-disable-next-line no-bitwise
                var length = list.length >>> 0;
                var thisArg = arguments[1];
                var value;

                for (var i = 0; i < length; i++) {
                    value = list[i];
                    if (predicate.call(thisArg, value, i, list)) {
                        return value;
                    }
                }
                return undefined;
            }
        });
    }

    // Polyfill for ECMAScript 2015 (ES6) Array.prototype.findIndex().
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
            value: function (predicate) {
                if (this === null || this === undefined) {
                    throw new TypeError('Array.prototype.findIndex called on null or undefined');
                }
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }
                var list = Object(this);
                // eslint-disable-next-line no-bitwise
                var length = list.length >>> 0;
                var thisArg = arguments[1];
                var value;

                for (var i = 0; i < length; i++) {
                    value = list[i];
                    if (predicate.call(thisArg, value, i, list)) {
                        return i;
                    }
                }
                return -1;
            }
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Polyfills for Number object
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    if (!Number.isInteger) {
        // Number.isInteger() method
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
        Number.isInteger = function (value) {
            return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
        };
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // New static APIs for RegExp type
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    RegExp.escape = function (str) {
        if (!str) {
            return '';
        }
        return str.replace(/[.*+?|()[\]{}\\$^]/g, '\\$&');
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // New APIs for Date objects
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    Date.prototype.moveToEndOfDay = function () {
        return this.set({hour: 23, minute: 59, second: 59});
    };

    Date.prototype.moveToFirstDayOfYear = function () {
        return this.set({day: 1, month: 0});
    };

    Date.prototype.moveToLastDayOfYear = function () {
        return this.set({month: 11}).moveToLastDayOfMonth();
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Wrapper for the PhoneNumberUtil object
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    var PhoneNumberFormatter = {};
    /**
     * Formats phone number (e.g: +15619231287 -> +1 561 923-1287)
     *
     * @param {String} phoneNumber Phone number to be formatted. It must be in FQN format, otherwise this
     * method will just return the original unformatted number.
     * @param {Boolean} [stripDtmf] If true, the DTMF digits (preceded by ,) will be stripped out
     */
    PhoneNumberFormatter.format = function (phoneNumber, stripDtmf) {
        if ((typeof PhoneNumberUtil !== 'undefined') && phoneNumber && phoneNumber.charAt(0) === '+') {
            try {
                var match = phoneNumber.match(Utils.PHONE_DIAL_WITH_PIN_PATTERN);
                var append = '';
                if (match) {
                    phoneNumber = match[1];
                    append = stripDtmf ? '' : match[2];
                }
                var number = PhoneNumberUtil.parseAndKeepRawInput(phoneNumber, null);
                phoneNumber = PhoneNumberUtil.formatInternational(number) + append;
            } catch (e) {
            }
        }
        return phoneNumber;
    };

    // Exports
    circuit.Utils = Utils;
    circuit.PhoneNumberFormatter = PhoneNumberFormatter;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
