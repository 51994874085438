
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var logger = circuit.logger;
    var Utils = circuit.Utils;

    ///////////////////////////////////////////////////////////////////////////////////////
    // Constants
    ///////////////////////////////////////////////////////////////////////////////////////
    var AtcMessage = Object.freeze({
        ADVANCING: 'ADVANCING',
        CSTA: 'CSTA',
        INFO: 'INFO',
        PRIMARYCLIENT: 'PRIMARYCLIENT',
        REGISTER: 'REGISTER',
        REGISTER_RESPONSE: 'REGISTER_RESPONSE',
        SETTINGS: 'SETTINGS',
        START_REASSIGNMENT: 'START_REASSIGNMENT',
        TALK: 'TALK',
        UNREGISTER: 'UNREGISTER',
        UNREGISTERED: 'UNREGISTERED'
    });

    var AtcInfoMessage = Object.freeze({
        GET_GTC_VERSION_REQUEST: 'GET_GTC_VERSION_REQUEST',
        SET_ROUTE_TO_DESK: 'SET_ROUTE_TO_DESK',
        ENABLE_PBX_CALL_LOG: 'ENABLE_PBX_CALL_LOG'
    });

    var StcMessage = Object.freeze({
        TRANSFER: 'TRANSFER'
    });

    var ContactCardMessage = Object.freeze({
        CLICK_TO_CALL_REQUEST: 'CLICK_TO_CALL_REQUEST',
        CLICK_TO_CALL_RESPONSE: 'CLICK_TO_CALL_RESPONSE',
        MUTE_DEVICE_REQUEST: 'MUTE_DEVICE_REQUEST',
        MUTE_DEVICE_RESPONSE: 'MUTE_DEVICE_RESPONSE',
        JOIN_CONFERENCE_REQUEST: 'JOIN_CONFERENCE_REQUEST',
        JOIN_CONFERENCE_RESPONSE: 'JOIN_CONFERENCE_RESPONSE',
        CONVERSATION_NAVIGATE_REQUEST: 'CONVERSATION_NAVIGATE_REQUEST',
        CONVERSATION_NAVIGATE_RESPONSE: 'CONVERSATION_NAVIGATE_RESPONSE',
        CLICK_TO_ANSWER_REQUEST: 'CLICK_TO_ANSWER_REQUEST',
        CLICK_TO_ANSWER_RESPONSE: 'CLICK_TO_ANSWER_RESPONSE',
        START_CONFERENCE_REQUEST: 'START_CONFERENCE_REQUEST',
        START_CONFERENCE_RESPONSE: 'START_CONFERENCE_RESPONSE',
        NAVIGATE_REQUEST: 'NAVIGATE_REQUEST',
        NAVIGATE_REQUEST_RESPONSE: 'NAVIGATE_REQUEST_RESPONSE'
    });

    var ContactCardResponseCode = Object.freeze({
        OK: 'OK',
        BUSY: 'BUSY',
        TELEPHONY_NOT_AVAILABLE: 'TELEPHONY_NOT_AVAILABLE',
        USER_NOT_FOUND: 'USER_NOT_FOUND',
        CONVERSATION_NOT_FOUND: 'CONVERSATION_NOT_FOUND',
        INVALID_CONVERSATION_TYPE: 'INVALID_CONVERSATION_TYPE',
        CALL_DECLINED: 'CALL_DECLINED',
        CALL_FAILED: 'CALL_FAILED',
        CALL_NOT_FOUND: 'CALL_NOT_FOUND',
        MUTE_DEVICE_FAILED: 'MUTE_DEVICE_FAILED',
        CONVERSATION_NAVIGATE_FAILED: 'CONVERSATION_NAVIGATE_FAILED',
        JOIN_CONFERENCE_FAILED: 'JOIN_CONFERENCE_FAILED',
        START_CONFERENCE_FAILED: 'START_CONFERENCE_FAILED',
        NAVIGATE_REQUEST_FAILED: 'NAVIGATE_REQUEST_FAILED'
    });

    var TeamPickupMessage = Object.freeze({
        TEAM_PICKUP: 'TEAM_PICKUP'
    });

    ///////////////////////////////////////////////////////////////////////////////////////
    // UserToUserHandler
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-lines-per-function
    function UserToUserHandler(clientApiHandler) { // NOSONAR

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var RESPONSE_TIMEOUT = 20000;
        var _reqCallBacks = {};
        var _evtCallBacks = {};

        var _clientApiHandler = clientApiHandler || ClientApiHandler.getInstance();

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function addReqCallBack(requestId, cb) {
            if (!cb) {
                return;
            }

            var responseTimer = window.setTimeout(function (reqId) {
                logger.error('[UserToUserHandler]: Timeout waiting for response. RequestId:', reqId);
                if (_reqCallBacks[reqId]) {
                    delete _reqCallBacks[reqId];
                    cb(Constants.ReturnCode.REQUEST_TIMEOUT);

                    logger.debug('[UserToUserHandler]: Remaining callbacks pending:', Object.keys(_reqCallBacks).length);
                }

            }, RESPONSE_TIMEOUT, requestId);

            _reqCallBacks[requestId] = {
                cb: cb,
                timer: responseTimer
            };
        }

        function handleInvalidMessage(cb) {
            if (cb) {
                window.setTimeout(function () {
                    cb(Constants.ReturnCode.INVALID_MESSAGE);
                }, 0);
            }
        }

        function sendUserToUserRequest(type, data, cb) {
            if (!data || !data.content || !data.content.type) {
                logger.error('[UserToUserHandler]: Invalid UserToUser request data - ', data);
                handleInvalidMessage(cb);
                return;
            }

            logger.debug('[UserToUserHandler]: Sending request ' + type + '.' + data.content.type);
            data.content.requestId = data.content.requestId || Utils.createTransactionId();

            if (_reqCallBacks[data.content.requestId]) {
                // There is already a pending request with the same ID
                logger.error('[UserToUserHandler]: UserToUser request has duplicate requestId');
                handleInvalidMessage(cb);
                return;
            }

            _clientApiHandler.sendUserToUserRequest(type, data, function (err) {
                if (err) {
                    logger.error('[UserToUserHandler]: Failed to send request: ', err);
                    cb && cb(err);
                    return;
                }
                // For SDK UserToUser messages the application is supposed to handle requests/response.
                if (type === Constants.UserRoutingMessageType.SDK) {
                    cb && cb();
                } else {
                    addReqCallBack(data.content.requestId, cb);
                }
            });
        }

        function handleUserToUserEvent(type, evt) {
            try {
                // Ignore own messages
                if (evt.routing && evt.routing.srcClientId === _clientApiHandler.clientId) {
                    logger.info('[UserToUserHandler]: Ignore own UserToUser event');
                    return;
                }

                var data;
                try {
                    data = JSON.parse(evt.data);
                } catch (err) {
                    logger.error('[UserToUserHandler]: Event discarded (Cannot Parse)');
                    return;
                }

                if (data.requestId) {
                    var cbInfo = _reqCallBacks[data.requestId];
                    if (cbInfo) {
                        // This is a response to a previous request
                        cbInfo.timer && window.clearTimeout(cbInfo.timer);
                        delete _reqCallBacks[data.requestId];
                        // Invoke the registered callback (if any)
                        cbInfo.cb && cbInfo.cb(null, data);
                        logger.debug('[UserToUserHandler]: Remaining callbacks pending:', Object.keys(_reqCallBacks).length);
                        return;
                    }
                }
                // Check if there is a registered callback for this event
                var evtType = type + '.' + data.type;
                var cbList = _evtCallBacks[evtType];
                if (!cbList || cbList.length === 0) {
                    logger.info('[UserToUserHandler]: There is no registered event handler for ', evtType);
                } else {
                    if (type === Constants.UserRoutingMessageType.SDK) {
                        data = data.message;
                    }
                    cbList.forEach(function (cbElem) {
                        cbElem.cb(data, evt.routing);
                    });
                }
            } catch (e) {
                logger.error('[UserToUserHandler]: Exception handling UserToUser event. ', e);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Client API Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _clientApiHandler.on('UserToUser.ATC', function (evt) {
            logger.debug('[UserToUserHandler]: Received UserToUser.ATC event');
            handleUserToUserEvent(Constants.UserRoutingMessageType.ATC, evt);
        });

        _clientApiHandler.on('UserToUser.CONTACT_CARD', function (evt) {
            logger.debug('[UserToUserHandler]: Received UserToUser.CONTACT_CARD event: ', evt.data);
            handleUserToUserEvent(Constants.UserRoutingMessageType.CONTACT_CARD, evt);
        });

        _clientApiHandler.on('UserToUser.STC', function (evt) {
            logger.debug('[UserToUserHandler]: Received UserToUser.STC event: ', evt.data);
            handleUserToUserEvent(Constants.UserRoutingMessageType.STC, evt);
        });

        ///////////////////////////////////////////////////////////////////////////////
        // Public Interfaces
        ///////////////////////////////////////////////////////////////////////////////
        this.cancelReqCallback = function (reqId) {
            var cbInfo = _reqCallBacks[reqId];
            if (cbInfo) {
                // Clear the callback function, but keep the registration to handle the response
                cbInfo.cb = null;
            }
        };

        this.sendAtcRequest = function (data, cb) {
            sendUserToUserRequest(Constants.UserRoutingMessageType.ATC, data, cb);
        };

        this.sendContactCardRequest = function (data, cb) {
            sendUserToUserRequest(Constants.UserRoutingMessageType.CONTACT_CARD, data, cb);
        };

        this.sendStcRequest = function (data, cb) {
            sendUserToUserRequest(Constants.UserRoutingMessageType.STC, data, cb);
        };

        this.on = function (msgType, cb) {
            if (msgType && (typeof cb === 'function')) {
                _evtCallBacks[msgType] = _evtCallBacks[msgType] || [];
                // Make sure this is not a duplicate registration
                var isDuplicate = _evtCallBacks[msgType].some(function (cbInfo) {
                    return cbInfo.cb === cb;
                });
                if (!isDuplicate) {
                    _evtCallBacks[msgType].push({cb: cb});
                }
            }
        };

        this.off = function (msgType, cb) {
            if (!msgType && !cb) {
                // Unregister all event handlers
                _evtCallBacks = {};
            } else if (!cb) {
                // Unregister the event handlers for the given message type
                delete _evtCallBacks[msgType];
            } else if (msgType && (typeof cb === 'function')) {
                // Unregister the given event handler
                var cbList = _evtCallBacks[msgType];
                cbList && cbList.some(function (cbInfo, idx) {
                    if (cbInfo.cb === cb) {
                        cbList.splice(idx, 1);
                        return true;
                    }
                    return false;
                });
            }
        };
    }

    UserToUserHandler.prototype.constructor = UserToUserHandler;
    UserToUserHandler.prototype.name = 'UserToUserHandler';

    // Exports
    circuit.UserToUserHandler = UserToUserHandler;

    circuit.Enums = circuit.Enums || {};
    circuit.Enums.AtcInfoMessage = AtcInfoMessage;
    circuit.Enums.AtcMessage = AtcMessage;
    circuit.Enums.StcMessage = StcMessage;
    circuit.Enums.ContactCardMessage = ContactCardMessage;
    circuit.Enums.ContactCardResponseCode = ContactCardResponseCode;
    circuit.Enums.TeamPickupMessage = TeamPickupMessage;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
