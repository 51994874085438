var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;
    var Utils = circuit.Utils;

    var DefaultAvatars = {
        SUPPORT: {
            avatar: 'content/images/icon-general-support-avatar.png',
            avatarLarge: 'content/images/icon-general-support-avatar-XL.png',
            hasAvatarPicture: false
        },
        TELEPHONY: {
            avatar: 'content/images/icon-general-phonecall-avatar.png',
            avatarLarge: 'content/images/icon-general-phonecall-avatar-XL.png',
            hasAvatarPicture: false
        },
        TELEPHONY_HOLD: {
            avatar: 'content/images/icon-general-hold-avatar.png',
            avatarLarge: 'content/images/icon-general-hold-avatar-XL.png',
            hasAvatarPicture: false
        },
        EXTENSIONS: {
            avatar: 'content/images/icon-bot-extension-default-avatar.png',
            avatarLarge: 'content/images/icon-bot-extension-default-avatar-XL.png',
            hasAvatarPicture: true // Need to change it to false if/when we switch it to SVG
        },
        APPLICATIONS: {
            avatar: 'content/images/icon-general-default-avatar-app.png',
            avatarLarge: 'content/images/icon-general-default-avatar-app-XL.png',
            hasAvatarPicture: true // Need to change it to false if/when we switch it to SVG
        },
        SESSION_GUEST: {
            avatar: 'content/images/icon-general-sessionguest-avatar-XL.png',
            avatarLarge: 'content/images/icon-general-sessionguest-avatar-XL.png',
            hasAvatarPicture: false
        },
        DIALIN: {
            avatar: 'content/images/icon-general-phonecall-dialin-avatar.png',
            avatarLarge: 'content/images/icon-general-phonecall-dialin-avatar-XL.png',
            hasAvatarPicture: false
        },
        INCOMING_WEBHOOK: {
            avatar: 'content/images/icon-webhook-default-avatar-XL.png',
            avatarLarge: 'content/images/icon-webhook-default-avatar-XL.png',
            hasAvatarPicture: true // Need to change it to false if/when we switch it to SVG
        }
    };
    // Default avatars are colorful. The default gray avatar is used when the downloading of real profile image fails
    var COLORS = ['blue', 'green', 'orange', 'yellow'];

    /*
     * Client Idle state
     * @readonly
     * @enum {String}
     * @property Idle - Client is idle
     * @property Active - Client is active
     */
    var IdleState = Object.freeze({
        Idle: 'Idle',
        Active: 'Active'
    });

    // Define the object
    var UserProfile = {};

    var DEFAULT_AVATAR = 'content/images/icon-general-default-avatar';
    var TELEPHONY_AVATAR = 'content/images/icon-general-phonecall';
    var TELEPHONY_HOLD_AVATAR = 'content/images/icon-general-hold';

    UserProfile.NOPIC = DEFAULT_AVATAR + '.png';
    UserProfile.NOPIC_LARGE = DEFAULT_AVATAR + '-XL.png';
    UserProfile.NOPIC_BOT = DefaultAvatars.EXTENSIONS.avatar;
    UserProfile.NOPIC_BOT_LARGE = DefaultAvatars.EXTENSIONS.avatarLarge;
    UserProfile.NOPIC_APP = DefaultAvatars.APPLICATIONS.avatar;
    UserProfile.NOPIC_APP_LARGE = DefaultAvatars.APPLICATIONS.avatarLarge;
    UserProfile.NOPIC_INCOMING_WEBHOOK = DefaultAvatars.INCOMING_WEBHOOK .avatar;
    UserProfile.NOPIC_INCOMING_WEBHOOK_LARGE = DefaultAvatars.INCOMING_WEBHOOK.avatarLarge;

    UserProfile.NO_NAME = '__UnDeFiNeD__';

    // Extend object via prototypical inheritance
    UserProfile.extend = function (obj) {
        if (!obj) {
            return null;
        }
        if (obj.isExtended) {
            // The given object is already an extended user profile
            return obj;
        }

        // userPresenceState will be removed from the base object. Save it and then add it to extended object.
        var userPresenceState = UserProfile.normalizeUserPresenceState(obj.userId, obj.userPresenceState);

        normalizeBaseObject(obj);

        // Create a derived object to be extended and returned.
        var user = Object.create(obj);
        user.isExtended = true;

        // Add the userPresenceState to the extended object
        user.userPresenceState = userPresenceState;

        // Now set the extended properties that need to be updated if
        // the base object (i.e. the server data) changes.
        setExtendedProperties(user);
        UserProfile.setAvatars(user);

        user.toJSON = function () { return Utils.flattenObj(this); };

        return user;
    };

    UserProfile.hasAvatar = function (user) {
        return !!(user && (user.extAvatarUri || (user.smallImageUri && user.largeImageUri)));
    };

    UserProfile.hasTelephonyAvatar = function (user) {
        return !!(user && (user.avatar && (user.avatar.startsWith(TELEPHONY_AVATAR) || user.avatar.startsWith(TELEPHONY_HOLD_AVATAR))));
    };

    UserProfile.hasPermission = function (user, permission) {
        if (!user || !user.accounts || !permission) {
            return false;
        }
        var activeAccount = user.accounts[0];
        var permissions = activeAccount && activeAccount.permissions;
        var multi = permission[permission.length - 1] === '_'; // For multi permission check, example: 'EVENT_ORGANIZER_'...

        return !!permissions && permissions.some(function (permissionObject) {
            return multi ? permissionObject.systemPermission.startsWith(permission) : permissionObject.systemPermission === permission;
        });
    };

    // Updates an existing UserProfile extended object with new received data from
    // the Client API.
    UserProfile.update = function (user, obj, skipNormalization) {
        if (!user || !obj) {
            return;
        }

        if (!user.isExtended) {
            user = UserProfile.extend(user);
        }
        if (obj.isExtended) {
            obj = Utils.getBaseObject(obj, true);
        } else if (!skipNormalization) {
            normalizeBaseObject(obj);
            obj.updatedTimeStamp = obj.updatedTimeStamp || Date.now();
        }

        for (var key in obj) {
            if ((key !== 'userPresenceState') && obj.hasOwnProperty(key)) {
                user[key] = obj[key];
            }
        }
        Utils.syncBaseObject(user);

        setExtendedProperties(user);
        UserProfile.setAvatars(user);
    };

    UserProfile.contains = function (users, userId) {
        return users.some(function (user) {
            return user.userId === userId;
        });
    };

    UserProfile.getAvatarColor = function (user) {
        if (!user || !user.userId) {
            return null;
        }
        return getColor(user.userId);
    };

    UserProfile.setAvatars = function (user) {
        if (!user) {
            return;
        }
        if (!user.hasTelephonyRole) {
            user.avatarColor = UserProfile.getAvatarColor(user);
        }

        if (user.isPurged) {
            user.avatar = Circuit.UserProfile.NOPIC;
            user.avatarLarge = Circuit.UserProfile.NOPIC_LARGE;
            user.hasAvatarPicture = false;
            return;
        }

        if (user.extAvatarUri) {
            user.avatar = user.extAvatarUri;
            user.avatarLarge = user.extAvatarUri;
            user.hasAvatarPicture = true;
            return;
        }

        if (user.smallImageUri && user.largeImageUri) {
            user.avatar = circuit.__domain + '/fileApi/avatar/small/' + user.smallImageUri;
            user.avatarLarge = circuit.__domain + '/fileApi/avatar/large/' + user.largeImageUri;
            user.hasAvatarPicture = true;
            return;
        }
        if (user.hasBotRole) {
            Object.assign(user, DefaultAvatars.EXTENSIONS);
            return;
        }
        if (user.participantType === Constants.RTCParticipantType.TELEPHONY) {
            Object.assign(user, DefaultAvatars.DIALIN);
            return;
        }
        if (user.hasTelephonyRole) {
            Object.assign(user, DefaultAvatars.TELEPHONY);
            return;
        }

        var color = getColor(user.userId);

        // User does not have a profile picture. Let's assign a random one.
        user.avatar = DEFAULT_AVATAR + '-' + color + '.png';
        user.avatarLarge = DEFAULT_AVATAR + '-' + color + '-XL.png';
        user.hasAvatarPicture = false;
    };

    UserProfile.normalizeExchangeData = function (exchangeData) {
        if (!exchangeData) {
            return;
        }
        if (exchangeData.companyName) {
            exchangeData.company = exchangeData.companyName;
            delete exchangeData.companyName;
        }
        if (exchangeData.title) {
            exchangeData.jobTitle = exchangeData.title;
            delete exchangeData.title;
        }

        if (exchangeData.phoneNumbers) {
            exchangeData.phoneNumbers = exchangeData.phoneNumbers.filter(function (phoneNumber) {
                var number = phoneNumber.number || phoneNumber.phoneNumber;
                if (!number || !number.trim()) {
                    return false;
                }
                if (!Utils.PHONE_DIAL_PATTERN.test(number) && !Utils.PHONE_WITH_EXTENSION_PATTERN.test(number)) {
                    return false;
                }
                phoneNumber.number = PhoneNumberFormatter.format(number);
                if (phoneNumber.phoneNumber) {
                    phoneNumber.phoneNumber = phoneNumber.number;
                }

                if (!phoneNumber.type || !Constants.PhoneNumberType[phoneNumber.type]) {
                    phoneNumber.type = Constants.PhoneNumberType.OTHER;
                }

                return true;
            });
        }
    };

    UserProfile.mergeWithExchangeData = function (user, exchangeData) {
        if (user && exchangeData) {
            UserProfile.normalizeExchangeData(exchangeData);

            // Remove externally managed fields
            user.externallyManagedFields.forEach(function (field) {
                delete exchangeData[field];
            });

            if (exchangeData.phoneNumbers) {
                exchangeData.phoneNumbers = exchangeData.phoneNumbers.filter(function (phoneNumber) {
                    return !(user.phoneNumbers && user.phoneNumbers.some(function (p) {
                        return p.phoneNumber === phoneNumber.number;
                    }));
                });
            }

            if (exchangeData.emailAddresses) {
                exchangeData.emailAddresses = exchangeData.emailAddresses.filter(function (email) {
                    var address = email.address;
                    if (Utils.compareStrings(user.emailAddress, address)) {
                        return false;
                    }
                    return !(user.emailAddresses && user.emailAddresses.some(function (e) {
                        return Utils.compareStrings(e.address, address);
                    }));
                });
            }

            // Add the exchange data to the base object so it can be persisted in the local DB
            delete user.exchangeData;
            var baseObj = Utils.getBaseObject(user);
            baseObj.exchangeData = exchangeData;
        }
    };

    UserProfile.isPresenceAvailable = function (user) {
        return !!(user && user.userPresenceState && user.userPresenceState.showPresence &&
            (user.userPresenceState.state === Constants.PresenceState.AVAILABLE || user.userPresenceState.state === Constants.PresenceState.BUSY));
    };

    UserProfile.isPresenceOffline = function (user) {
        return !!(user && user.userPresenceState && user.userPresenceState.showPresence &&
            (user.userPresenceState.state === Constants.PresenceState.OFFLINE));
    };

    /**
     * Filter out a users list based on their online status
     *
     * @param {Array<object>} users An Array of users filter
     */
    UserProfile.getAvailableParticipants = function (users) {
        var results = [];
        if (users && users.length > 0) {
            results = users.filter(function (user) {
                return UserProfile.isPresenceAvailable(user);
            });
        }
        return results;
    };

    //Finds common members between 2 user object arrays of a conversation
    UserProfile.findCommonMembers = function (previousMembers, newMembers) {
        var intersection = [];
        previousMembers && newMembers && previousMembers.length > 0 && (newMembers.length > 0) && newMembers.forEach(function (newMember) {
            previousMembers.some(function (prevMember) {
                if (prevMember.user.userId === newMember.userId) {
                    intersection.push(prevMember);
                    return true;
                }
                return false;
            });
        });
        return intersection;
    };

    UserProfile.clearUserPresenceState = function (user) {
        if (user) {
            user.userPresenceState = UserProfile.normalizeUserPresenceState(user.userId);
        }
    };

    UserProfile.updateUserPresenceState = function (user, presenceState) {
        if (!user || !presenceState) {
            return false;
        }
        presenceState = UserProfile.normalizeUserPresenceState(user.userId, presenceState);

        var currPropertyNames = Object.getOwnPropertyNames(user.userPresenceState);
        var newPropertyNames = Object.getOwnPropertyNames(presenceState);

        // First check if there are any changes
        if (newPropertyNames.length === currPropertyNames.length) {
            var hasChanges = newPropertyNames.some(function (name) {
                return presenceState[name] !== user.userPresenceState[name];
            });
            if (!hasChanges) {
                return false;
            }
        }

        // We want to update the existing UserPresenceState object
        // First clear the current object
        currPropertyNames.forEach(function (name) {
            delete user.userPresenceState[name];
        });

        // Now copy all the fields from the new object
        newPropertyNames.forEach(function (name) {
            user.userPresenceState[name] = presenceState[name];
        });

        return true;
    };

    UserProfile.normalizeUserPresenceState = function (userId, userPresenceState) {
        if (userPresenceState) {
            // We are assuming that the mandatory fields (userId and state) have been populated
            userPresenceState.isOptedOut = !!userPresenceState.isOptedOut;
            userPresenceState.showPresence = !userPresenceState.isOptedOut;
            userPresenceState.statusMessage = userPresenceState.statusMessage || '';
            userPresenceState.isSystemDefined = !!userPresenceState.isSystemDefined;
        } else {
            userPresenceState = {
                userId: userId || '',
                state: Constants.PresenceState.OFFLINE,
                showPresence: false,
                statusMessage: ''
            };
        }
        return userPresenceState;
    };

    UserProfile.encodeNoName = function (userModel) {
        if (!userModel.firstName) {
            userModel.firstName = UserProfile.NO_NAME;
        }
        if (!userModel.lastName) {
            userModel.lastName = UserProfile.NO_NAME;
        }
    };

    UserProfile.decodeNoName = function (userModel) {
        if (userModel.firstName === UserProfile.NO_NAME) {
            userModel.firstName = '';
        }
        if (userModel.lastName === UserProfile.NO_NAME) {
            userModel.lastName = '';
        }
    };

    UserProfile.isExternal = function (user, conversation) {
        if (!user || user.noData || user.notFound || user.hasSupportRole || !UserProfile.localUser) {
            // There is no user OR we don't have the user information OR this is a support user.
            // In any of these case the user must not be marked as guest.
            return false;
        }

        if (UserProfile.localUser.hasSupportRole) {
            // If the local user is a suport user then don't show users from different tenants as guest
            return false;
        }

        if (conversation && conversation.isLocalUserExternal) {
            // I am a guest in this conversation, don't show anybody as guests
            return false;
        }

        return user.isExternal;
    };

    UserProfile.syncTelephonyConfig = function (user) {
        var account = user && user.accounts && user.accounts[0];
        var telConfig = account && account.telephonyConfiguration;
        if (telConfig) {
            telConfig.phoneNumber = PhoneNumberFormatter.format(telConfig.phoneNumber) || '';
            telConfig.callerId = PhoneNumberFormatter.format(telConfig.callerId) || '';
            telConfig.reroutingPhoneNumber = PhoneNumberFormatter.format(telConfig.reroutingPhoneNumber) || '';

            user.reroutingPhoneNumber = telConfig.reroutingPhoneNumber;
            user.ondSipAuthenticationHash = telConfig.ondSipAuthenticationHash;
            user.onsSipAuthenticationHash = telConfig.onsSipAuthenticationHash;
            user.associatedTelephonyUserID = telConfig.associatedTelephonyUserID;
            user.associatedTelephonyUserType = telConfig.associatedTelephonyUserType;
            user.associatedTelephonyTrunkGroupId = telConfig.associatedTelephonyTrunkGroupId;

            // Remove duplicate and invalid alternative numbers
            var numbers = (telConfig.previousAlternativeDevice || []).map(Utils.cleanPhoneNumber);
            var numbersHash = numbers.reduce(function (hash, number) {
                if (number.startsWith('+') && number !== '+') {
                    hash[number] = true;
                }
                return hash;
            }, {});

            user.previousAlternativeNumbers = Object.keys(numbersHash);
            if (user.reroutingPhoneNumber) {
                var cleanReroutingNumber = Utils.cleanPhoneNumber(user.reroutingPhoneNumber);
                if (!user.previousAlternativeNumbers.includes(cleanReroutingNumber)) {
                    user.previousAlternativeNumbers.unshift(cleanReroutingNumber);
                }
            }

            if (user.associatedTelephonyUserID) {
                // Relevant when UC is enabled for an OSBiz client. Used to adjust some client behaviour for OSBiz

                // This is temp to accomodate MiVB MDUG
                //user.isOsBizCTIEnabled = user.associatedTelephonyUserType === Constants.GtcTrunkType.OSBIZ_TRUNK_TYPE && telConfig.isOsBizCTIEnabled;
                user.isOsBizCTIEnabled = true;
                user.isATC = user.isOsBizCTIEnabled || (user.associatedTelephonyUserType === Constants.GtcTrunkType.ATC_TRUNK_TYPE);
                user.isSTC = user.associatedTelephonyUserType === Constants.GtcTrunkType.SUB_TRUNK_TYPE;

                if (user.isATC || user.isSTC) {
                    // Set associatedGTCUserId field for backwards compatibility until mobile clients remove all references to it
                    user.associatedGTCUserId = user.associatedTelephonyUserID;
                }
            } else {
                user.isOsBizCTIEnabled = false;
                user.isATC = false;
                user.isSTC = false;
            }
        } else {
            delete user.associatedGTCUserId;
            delete user.reroutingPhoneNumber;
            delete user.ondSipAuthenticationHash;
            delete user.onsSipAuthenticationHash;
            delete user.associatedTelephonyUserID;
            delete user.associatedTelephonyUserType;
            delete user.associatedTelephonyTrunkGroupId;
            delete user.isATC;
            delete user.isSTC;
        }
        user.conferenceDialOutEnabled = user.conferenceDialOutEnabled === undefined ? true : user.conferenceDialOutEnabled;
        // initialize conferenceDialOutAvailable with same state as conferenceDialOutEnabled, this property will get updated for
        // ATC users by change of telephony data
        user.conferenceDialOutAvailable = user.conferenceDialOutEnabled;
    };

    UserProfile.updateAlternativeNumbers = function (user, data) {
        var numbers = (data.previousAlternativeNumbers || []).map(Utils.cleanPhoneNumber);
        var numbersHash = numbers.reduce(function (hash, number) {
            if (number.startsWith('+') && number !== '+') {
                hash[number] = true;
            }
            return hash;
        }, {});

        user.previousAlternativeNumbers = Object.keys(numbersHash);
    };

    UserProfile.filterUserByName = function (user, query) {
        if (!user) {
            return false;
        }
        if (!query) {
            // If query is empty, consider it a match
            return true;
        }
        return !user.isSuspended && !user.isDeleted && !user.isPurged && (localeCompare(user.firstName, query) ||
            localeCompare(user.lastName, query) || localeCompare(user.displayName, query));
    };

    UserProfile.filterUsersByName = function (users, query) {
        if (!Array.isArray(users) || (users.length === 0)) {
            return [];
        }
        if (!query) {
            // If query is empty string return entire user list
            return query === '' ? users : [];
        }
        return users.filter(function (user) {
            return UserProfile.filterUserByName(user, query);
        });
    };

    UserProfile.localUser = null;

    ///////////////////////////////////////////////////////////////////////////
    // Helper functions for UserProfile
    ///////////////////////////////////////////////////////////////////////////
    function getColor(userId) {
        var sum = 0;
        if (userId) {
            for (var idx = 0; idx < userId.length; idx++) {
                sum += userId.charCodeAt(idx);
            }
        }
        return COLORS[sum % COLORS.length];
    }

    // Optional User object fields
    var STRING_FIELDS = ['userId', 'emailAddress', 'lastName', 'firstName', 'displayName', 'location', 'largeImageUri',
        'smallImageUri', 'extAvatarUri', 'userType', 'userState', 'locale', 'jobTitle', 'company', 'department', 'federationEmailAddress'];
    var BOOLEAN_FIELDS = [];
    var NUM_FIELDS = ['updatedTimeStamp'];
    var ARRAY_FIELDS = ['phoneNumbers', 'emailAddresses', 'externallyManagedFields', 'askMeAbouts', 'expertises', 'interests'];

    function normalizeBaseObject(obj) {
        // Handle backwards compatibility for isExternallyManaged field
        if (obj.isExternallyManaged && !obj.externallyManagedFields) {
            // We must be connected to an older backend. Set externallyManagedFields with the default values.
            obj.externallyManagedFields = ['emailAddress', 'lastName', 'firstName'];
        }

        // Remove deprecated fields from base object
        delete obj.tenantId;
        delete obj.roles;
        delete obj.accountTemplateType;
        delete obj.associatedGTCUserId;
        delete obj.reroutingPhoneNumber;
        delete obj.assignedPhoneNumbers;
        delete obj.userPresenceState;
        delete obj.isExternallyManaged;

        // Initialize fields on base object
        STRING_FIELDS.forEach(function (field) {
            obj[field] = obj[field] || '';
        });

        BOOLEAN_FIELDS.forEach(function (field) {
            obj[field] = obj[field] || false;
        });

        NUM_FIELDS.forEach(function (field) {
            obj[field] = obj[field] || 0;
        });

        ARRAY_FIELDS.forEach(function (field) {
            obj[field] = obj[field] || [];
        });

        // Pretty format of display name if it's an international number
        if (obj.displayName.startsWith('+')) {
            obj.displayName = PhoneNumberFormatter.format(obj.displayName);
        }
        // Pretty format of phone numbers (international only)
        if (obj.phoneNumber) {
            obj.phoneNumber = PhoneNumberFormatter.format(obj.phoneNumber);
        }
        obj.phoneNumbers.forEach(function (number) {
            number.phoneNumber = PhoneNumberFormatter.format(number.phoneNumber);
        });

        // Normalize email address as names
        if (obj.firstName === UserProfile.NO_NAME) {
            obj.firstName = '';
            obj.displayName = '';
        }
        if (obj.lastName === UserProfile.NO_NAME) {
            obj.lastName = '';
            obj.displayName = '';
        }

        // Make sure the display name is set correctly
        if (obj.displayName) {
            obj.displayName = obj.displayName.trim();
        } else if (!obj.firstName || !obj.lastName) {
            obj.displayName = obj.firstName || obj.lastName || obj.emailAddress;  // When no names use email address for display
        } else {
            obj.displayName = (obj.firstName + ' ' + obj.lastName).trim();
        }

        // Normalize the locale
        obj.locale = Utils.normalizeLocale(obj.locale); // Do not default this

        // Delete Michael's minions and handle 'null' string as empty.
        if (!obj.smallImageUri || obj.smallImageUri.endsWith('/minions') || obj.smallImageUri === 'null') {
            obj.smallImageUri = '';
            obj.largeImageUri = '';
        }

        // Workaround: Test if email starts with 'del_' until backend adds missing userState attribute
        if (!obj.userState && obj.emailAddress.startsWith('del_')) {
            obj.userState = Constants.UserState.DELETED;
        }

        if (obj.userState === Constants.UserState.DELETED) {
            // For deleted users the backend adds a dummy email address for the user
            obj.emailAddress = '';
        }

        UserProfile.normalizeExchangeData(obj.exchangeData);
    }

    function setUserInitials(user) {
        if (user.firstName || user.lastName) {
            user.initials = (user.firstName.substring(0, 1) + user.lastName.substring(0, 1)).toUpperCase();
        } else {
            user.initials = Utils.displayNameToInitials(user.emailAddress);
        }
    }

    function setExtendedProperties(user) {
        if (!Utils.isMobile()) {
            user.displayNameEscaped = Utils.textToHtmlEscaped(user.displayName);
        }

        user.isExternal = user.userType === Constants.UserType.GUEST;
        // Keep isGuest property for backwards compatibility with mobile clients.
        // Once mobile clients start using isExternal we can remove this field.
        user.isGuest = user.isExternal;

        user.hasSupportRole = user.userType === Constants.UserType.SUPPORT;
        user.hasTelephonyRole = user.userType === Constants.UserType.TELEPHONY;
        user.hasBotRole = user.userType === Constants.UserType.BOT;

        user.hasSpecialRole = user.hasTelephonyRole || user.hasSupportRole;

        if (!user.hasTelephonyRole) {
            // Do net set initials for special role to avoid IE bug (font size multiplication)
            setUserInitials(user);
        }

        user.isSuspended = user.userState === Constants.UserState.SUSPENDED;
        user.isPurged = user.userState === Constants.UserState.PURGED;
        user.isDeleted = user.isPurged || user.userState === Constants.UserState.DELETED;

        // The inactive state will be represented by "Invited" label both for Guest and Regular users
        user.isInactive = user.userState === Constants.UserState.INACTIVE;
        user.isActiveState = user.userState === Constants.UserState.ACTIVE;
        user.shortName = (user.firstName + (user.lastName.length > 0 ? ' ' + user.lastName[0] + '.' : '')).trim();

        // If the user has an accounts field, set some additional permission properties.
        // This is only available for the logged on user.
        if (user.accounts && user.accounts[0]) {
            var account = user.accounts[0];
            user.hasPermission = UserProfile.hasPermission.bind(null, user);
            user.hasTelephonyPermission = user.hasPermission(Constants.SystemPermission.TELEPHONY);
            user.hasVoicemailPermission = user.hasPermission(Constants.SystemPermission.VOICEMAIL);
            user.hasSupportPermission = user.hasPermission(Constants.SystemPermission.SUPPORT_CONVERSATION);

            // Not applicable for NGTC
            user.canUseHDVideo = false;
            user.canUseHDScreenShare = false;

            // Move data from accounts to the local user object
            user.tenantId = account.tenantId;

            UserProfile.syncTelephonyConfig(user);
        }
    }

    // Compare beginning of the string with query considering locales (e.g. 'ö' should be equal to 'o')
    function localeCompare(string, query) {
        if (string.length < query.length) { return false; }
        var result = string.slice(0, query.length).localeCompare(query, 'en', { sensitivity: 'base' });
        return result === 0;
    }

    // Exports
    circuit.DefaultAvatars = DefaultAvatars;
    circuit.UserProfile = UserProfile;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.IdleState = IdleState;
    circuit.Enums.UserProfile = UserProfile;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
