/*global CryptoJS*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Utils = circuit.Utils;
    var storeManager = circuit.storeManager;

    ///////////////////////////////////////////////////////////////////////////////////////
    // LocalStoreSvc Implementation
    // Local store service for data persistence
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function LocalStoreSvcImpl($rootScope, $timeout, $q, $window, LogSvc) { // NOSONAR
        LogSvc.debug('New Service: LocalStoreSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var INDEXED_DB_NAME = 'unify';
        var USER_STORE_NAME = 'users';
        var LOGGED_ON_USER_STORE_NAME = 'loggedOnUser';
        var EXCHANGE_CONTACTS_STORE_NAME = 'exchangeContacts';
        var EXCHANGE_ONLINE_CONTACTS_STORE_NAME = 'exchangeOnlineContacts';
        var EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME = 'exchangeOnPremiseContacts';
        var GOOGLE_CONTACTS_STORE_NAME = 'googleContacts';

        var MAGIC_PHRASE = 'VmFsYXIgTW9yZ2h1bGlz';

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _keys = {
            ACCESSIBILITY_ENABLED: 'accessibilityEnabled',           // Controls accessibility enabled or not.
            AUDIOAGC: 'audioAGCEnabled',                             // Diagnostic settings
            AUDIOEC: 'audioECEnabled',                               // Audio echo cancellation setting
            CACHE_ENABLED: 'cacheEnabled',                           // Local setting for enabling cache for users that have logged in with SSO.
            CALL_ROUTING_OTHER_SET: 'callRoutingOtherSet',           // Telephony call routing option 'Other' is set
            CALL_STAGE_WINDOW: 'callStageWindow',                    // Size & position data of popout call stage window
            CIRCUIT_LABS: 'circuitLabs',                             // Circuit Labs features
            CONCEPTBOARD_ORCHESTRATION: 'conceptboardOrchestration', // Conceptboard orchestration OAuth2 user connection data
            DA_AUTO_UPDATE: 'daAutoUpdate',                          // Tenant auto update setting
            DEFAULT_PHONE_CALL_DEVICE: 'defaultPhoneCallDevice',     // Default device from where phone calls are originated
            DEFAULT_PUSH_CALL_DEVICE: 'defaultPushCallDevice',       // Default device from where phone calls are originated
            DIAGNOSTICS: 'diagnosticsDisabled',                      // Diagnostic settings
            DOMAIN: 'domain',                                        // Domain only for desktop app
            ENABLE_ALPHA_TESTING: 'enableAlphaTesting',              // Enable testing of alpha features on production systems
            ENABLE_POPUP_LOG: 'enablePopupLog',                      // Controls whether or not the popup log window is enabled
            EXCHANGE_CONNECTOR: 'exchangeConnector',                 // Exchange Connector settings
            EXCHANGE_SYNC_STATE: 'exchangeSyncState',                // State from last sync of personal contacts in Exchange
            GOOGLE_CONTACTS_AUTHDATA: 'googleContactsAuthData',      // Google Contacts OAuth2 user connection data
            GOOGLE_CONNECTOR: 'googleConnector',                     // Google Connector settings
            GOOGLE_DRIVE_AUTHDATA: 'googleDriveAuthData',            // Google Drive OAuth2 user connection data
            GOOGLE_SYNC_STATE: 'googleSyncState',                    // State from last sync of personal contacts in Exchange
            HEADSET_APP_ID: 'headsetAppId',                          // Chrome App ID for development Circuit Headset Integration app.
            HEADSET_TYPES_ENABLED: 'headsetTypesEnabled',            // List of headset types that have integration enabled.
            LAST_POSITION: 'lastPosition',                           // Last retrieved position
            LAST_SAVED_LOCATION: 'lastSavedLocation',                // Last saved location data (position and location text)
            LAST_USERNAME: 'lastUserName',                           // The last user that logged on to Circuit
            LOGGING: 'loggingLevel',                                 // Logging settings
            MAINTENANCE: 'maintenance',                              // State of maintenance event (Used in CMR)
            MEETING_ROOM_NAME: 'meetingName',                        // Name of Circuit Meeting Room (Used in CMR)
            MEDIA_DEVICE_LIST: 'mediaDeviceList',
            QOS_PENDING_RECORDS: 'qosPendingRecords',                // QoS records that haven't been sent to the server yet
            REMEMBER_ME: 'rememberMe',                               // The "This is a private computer" setting in the login page
            REMINDER_SNOOZE_TIME: 'reminderSnoozeTime',              // TimeStamp of the last snooze notification reminder displayed
            REMINDER_STATUS_TIME: 'reminderStatusTime',              // TimeStamp of the last status message reminder displayed
            RINGTONE: 'ringtone',                                    // Selected ringtone
            RTC_CLIENT_INFOS: 'rtcClientInfos',                      // Stored during join attempts with missing network connection
            SSOLOGIN: 'ssologin',                                    // Local setting for keeping the ssologin value
            TEAMS_EXCHANGE_ONLINE_CONNECTOR: 'teamsExchangeOnlineConnector', // Local setting for keeping enable automatically connection for Ms Teams Exchange Connector
            THIRDPARTY_CONNECTIONS: 'thirdpartyConnections',         // Third party connections status
            THIRDPARTY_CONTENT: 'thirdpartyContent',                 // Third party content configuration
            TRANSCRIPTION: 'transcriptionSettings',                  // Selected transcription provider and API key
            TRANSLATION: 'translationSettings',                      // Selected translation provider and API key
            UI_SETTINGS: 'uiSettings'                                // UI settings for in context sidebar
        };

        var _indexedDB = null;
        var _baseSecret = '';
        var _myKey = '';

        var _stores = [
            USER_STORE_NAME,
            LOGGED_ON_USER_STORE_NAME,
            EXCHANGE_ONLINE_CONTACTS_STORE_NAME,
            EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME,
            GOOGLE_CONTACTS_STORE_NAME
        ];

        // Keep track of exception DB stores that should not be recreated when client is upgraded
        var _upgradeExceptions = [
            LOGGED_ON_USER_STORE_NAME
        ];

        var _cachingDisabled = false;

        // List of keys that can be saved in local storage even if caching is disabled
        var _cachingDisabledExceptions = [
            _keys.ACCESSIBILITY_ENABLED,
            _keys.AUDIOAGC,
            _keys.CACHE_ENABLED,
            _keys.CALL_ROUTING_OTHER_SET,
            _keys.CALL_STAGE_WINDOW,
            _keys.CIRCUIT_LABS,
            _keys.DA_AUTO_UPDATE,
            _keys.DOMAIN,
            _keys.ENABLE_ALPHA_TESTING,
            _keys.EXCHANGE_CONNECTOR,
            _keys.HEADSET_APP_ID,
            _keys.HEADSET_TYPES_ENABLED,
            _keys.LOGGING,
            _keys.MEDIA_DEVICE_LIST,
            _keys.RINGTONE,
            _keys.QOS_PENDING_RECORDS,
            _keys.REMINDER_SNOOZE_TIME,
            _keys.REMINDER_STATUS_TIME,
            _keys.RTC_CLIENT_INFOS,
            _keys.SSOLOGIN,
            _keys.TEAMS_EXCHANGE_ONLINE_CONNECTOR,
            _keys.THIRDPARTY_CONNECTIONS,
            _keys.THIRDPARTY_CONTENT,
            _keys.UI_SETTINGS
        ];

        // List of keys that must be reset in case a new user signs in
        var _userSpecificKeys = [
            _keys.CONCEPTBOARD_ORCHESTRATION,
            _keys.DA_AUTO_UPDATE,
            _keys.ENABLE_ALPHA_TESTING,
            _keys.EXCHANGE_CONNECTOR,
            _keys.EXCHANGE_SYNC_STATE,
            _keys.GOOGLE_CONTACTS_AUTHDATA,
            _keys.GOOGLE_DRIVE_AUTHDATA,
            _keys.GOOGLE_SYNC_STATE,
            _keys.TEAMS_EXCHANGE_ONLINE_CONNECTOR,
            _keys.THIRDPARTY_CONNECTIONS,
            _keys.THIRDPARTY_CONTENT,
            _keys.TRANSCRIPTION,
            _keys.TRANSLATION,
            _keys.UI_SETTINGS
        ];

        // List of keys that must not be deleted when clearing the cache
        var _criticalKeys = [
            _keys.CACHE_ENABLED,
            _keys.CALL_ROUTING_OTHER_SET,
            _keys.CIRCUIT_LABS,
            _keys.DOMAIN,
            _keys.EXCHANGE_CONNECTOR,
            _keys.HEADSET_APP_ID,
            _keys.HEADSET_TYPES_ENABLED,
            _keys.LAST_USERNAME,
            _keys.MEDIA_DEVICE_LIST,
            _keys.REMEMBER_ME,
            _keys.REMINDER_SNOOZE_TIME,
            _keys.REMINDER_STATUS_TIME,
            _keys.RINGTONE,
            _keys.SSOLOGIN,
            _keys.TEAMS_EXCHANGE_ONLINE_CONNECTOR,
            _keys.TRANSCRIPTION,
            _keys.TRANSLATION,
            _keys.UI_SETTINGS
        ];

        var _obsoleteKeys = [
        ];

        var _md5 = CryptoJS.MD5.bind(CryptoJS); // Shortcut to hide 'CryptoJS.MD5' calls in obfuscating code

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setItem(key, value) {
            if (!key) {
                LogSvc.warn('[LocalStoreSvc]: setItem invoked without key');
                return;
            }
            if (_cachingDisabled && _cachingDisabledExceptions.indexOf(key) === -1) {
                LogSvc.info('[LocalStoreSvc]: Caching is disabled. Cannot set item in local storage - key = ', key);
                return;
            }
            storeManager.setItem(key, value || '');
        }

        function getItem(key) {
            if (!key) {
                LogSvc.warn('[LocalStoreSvc]: getItem invoked without key');
                return null;
            }
            return storeManager.getItem(key);
        }

        function removeItem(key) {
            if (!key) {
                LogSvc.warn('[LocalStoreSvc]: removeItem invoked without key');
                return;
            }
            storeManager.removeItem(key);
        }

        function createObjectStore(db, storeName, keyPath) {
            if (db.objectStoreNames.contains(storeName)) {
                // Store alrteady exists
                return null;
            }
            return db.createObjectStore(storeName, {keyPath: keyPath});
        }

        function upgradeObjectStores(db) {
            _stores.forEach(function (storeName) {
                if (!_upgradeExceptions.includes(storeName) && db.objectStoreNames.contains(storeName)) {
                    LogSvc.debug('[LocalStoreSvc]: Delete object store - ', storeName);
                    db.deleteObjectStore(storeName);
                }
            });

            LogSvc.info('[LocalStoreSvc]: Recreate object stores');

            createObjectStore(db, LOGGED_ON_USER_STORE_NAME, 'userId');
            createObjectStore(db, USER_STORE_NAME, 'userId');
            createObjectStore(db, EXCHANGE_ONLINE_CONTACTS_STORE_NAME, 'id');
            createObjectStore(db, EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME, 'id');
            createObjectStore(db, GOOGLE_CONTACTS_STORE_NAME, 'id');
        }

        function removeIndexedDb() {
            var defer = $q.defer();
            var removeReq = $window.indexedDB.deleteDatabase(INDEXED_DB_NAME);
            removeReq.onsuccess = function () {
                LogSvc.info('[LocalStoreSvc]: Existing indexedDB removed');
                defer.resolve();
            };
            removeReq.onerror = function (event) {
                LogSvc.error('[LocalStoreSvc]: Failed to remove indexedDB. ', event);
                defer.reject(event);
            };
            removeReq.onblocked = function (event) {
                LogSvc.error('[LocalStoreSvc]: IndexedDB is blocked. ', event);
                defer.reject(event);
            };
            return defer.promise;
        }

        function checkDbStores(db) {
            var actualStores = Array.prototype.slice.call(db.objectStoreNames);
            LogSvc.debug('[LocalStoreSvc]: Expected stores = ', _stores);
            LogSvc.debug('[LocalStoreSvc]: Actual stores = ', actualStores);
            return (actualStores.length === _stores.length) &&
                _stores.every(function (name) { return actualStores.includes(name); });
        }

        function initIndexedDb(enableCache, defer, loggedOnUser) {
            var isFirstAttempt = !defer;
            var indexedDbAvailable = !!$window.indexedDB;

            // Start by clearing obsolete local storage keys
            _obsoleteKeys.forEach(function (key) { // NOSONAR
                removeItem(key);
            });

            defer = defer || $q.defer();
            _baseSecret = _md5(MAGIC_PHRASE).toString();
            _cachingDisabled = !indexedDbAvailable || !enableCache;
            if (_cachingDisabled) {
                LogSvc.info('[LocalStoreSvc]: Local caching disabled, clearing IndexedDB and Local Storage');
                clearLocalStorage(false);
                if (indexedDbAvailable) {
                    removeIndexedDb()
                    .then(defer.resolve)
                    .catch(defer.resolve);
                } else {
                    LogSvc.error('[LocalStoreSvc]: IndexedDB is not defined.');
                    defer.resolve();
                }
            } else {
                var handleFailure = function () {
                    LogSvc.error('[LocalStoreSvc]: Failed to recreate indexedDB, give up.');
                    LogSvc.debug('[LocalStoreSvc]: Switch to caching disabled mode');
                    if ($rootScope.ssoLogin) {
                        setItem(_keys.CACHE_ENABLED, false);
                    } else {
                        setItem(_keys.REMEMBER_ME, false);
                    }
                    initIndexedDb(false, defer);
                };

                var recreateDb = function (user) {
                    if (isFirstAttempt) {
                        LogSvc.info('[LocalStoreSvc]: Remove existing indexedDB');
                        removeIndexedDb()
                        .then(function () {
                            initIndexedDb(true, defer, user);
                        })
                        .catch(handleFailure);
                    } else {
                        handleFailure();
                    }
                };

                LogSvc.info('[LocalStoreSvc]: Initializing LocalStoreSvc for client version ', $rootScope.clientVersion);
                var version = Utils.convertVersionToNumber($rootScope.clientVersion) || 1;
                LogSvc.info('[LocalStoreSvc]: Setting indexedDB version to ', version);
                var request = $window.indexedDB.open(INDEXED_DB_NAME, version);
                request.onupgradeneeded = function (event) {
                    LogSvc.warn('[LocalStoreSvc]: Upgrade needed. Current Version: ' +
                        event.oldVersion + ', New Version: ' + version);
                    upgradeObjectStores(event.target.result);
                };
                request.onerror = function (event) {
                    LogSvc.warn('[LocalStoreSvc]: Error opening indexedDB. ', event.target && event.target.error);
                    // The DB may be corrupted. Remove the existing db and recreate a new one.
                    recreateDb();
                };
                request.onsuccess = function (event) {
                    LogSvc.debug('[LocalStoreSvc]: Opened indexedDB ', INDEXED_DB_NAME);
                    var db = event.target.result;
                    if (checkDbStores(db)) {
                        _indexedDB = db;
                        if (loggedOnUser) {
                            addObjectsToStore(loggedOnUser, LOGGED_ON_USER_STORE_NAME)
                            .finally(function () {
                                defer.resolve();
                            });
                        } else {
                            defer.resolve();
                        }
                    } else {
                        LogSvc.error('[LocalStoreSvc]: IndexedDB stores do not match. Need to upgrade.');
                        var previousUser = null;
                        getLoggedOnUser(db)
                        .then(function (prevUser) {
                            previousUser = prevUser;
                        })
                        .finally(function () {
                            db.close();
                            recreateDb(previousUser);
                        });
                    }
                };
            }

            return defer.promise;
        }

        function resolvePromise(defer, data) {
            if (defer) {
                defer.resolve(data);
                !$rootScope.$$phase && $rootScope.$digest();
            }
        }

        function rejectPromise(defer, data) {
            if (defer) {
                defer.reject(data);
                !$rootScope.$$phase && $rootScope.$digest();
            }
        }

        function clearObjectStores(clearLocalUser) {
            var defer = $q.defer();
            var transaction = _indexedDB.transaction(_stores, 'readwrite');
            transaction.oncomplete = function () {
                LogSvc.debug('[LocalStoreSvc]: Cleared indexedDB');
                resolvePromise(defer, null);
            };
            transaction.onerror = function (event) {
                LogSvc.error('[LocalStoreSvc]: Error clearing indexedDB ', event);
                rejectPromise(defer, event);
            };
            _stores.forEach(function (store) {
                if (store !== LOGGED_ON_USER_STORE_NAME || clearLocalUser) {
                    transaction.objectStore(store).clear();
                }
            });
            return defer.promise;
        }

        function rejectInvalidInput() {
            var defer = $q.defer();
            defer.reject('Invalid input');
            !$rootScope.$$phase && $rootScope.$digest();
            return defer.promise;
        }

        function resolveDisabledCaching(isArray) {
            var defer = $q.defer();
            defer.resolve(isArray ? [] : null);
            !$rootScope.$$phase && $rootScope.$digest();
            return defer.promise;
        }

        function encryptData(objectStore, data) {
            if (!objectStore || !data || (typeof data !== 'object')) {
                return data;
            }

            // Get the property names for the key and indexes used for the given object store
            var specialProperties = [objectStore.keyPath];
            Array.prototype.push.apply(specialProperties, objectStore.indexNames);

            var encryptObject = function (object) {
                var encryptedObject = {};
                specialProperties.forEach(function (propertyName) {
                    encryptedObject[propertyName] = object[propertyName];
                });
                var stringifiedObject = JSON.stringify(object);
                encryptedObject.encryptedBlob = CryptoJS.AES.encrypt(stringifiedObject, _myKey).toString();
                return encryptedObject;
            };

            return Array.isArray(data) ? data.map(encryptObject) : encryptObject(data);
        }

        function decryptData(data) {
            if (!data || (typeof data !== 'object') || !data.encryptedBlob) {
                return data;
            }

            try {
                var stringifiedObject = CryptoJS.AES.decrypt(data.encryptedBlob, _myKey).toString(CryptoJS.enc.Utf8);
                return JSON.parse(stringifiedObject);
            } catch (e) {
                return null;
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // IndexedDB GET functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getAll(storeName, reverse, indexName) {
            if (_cachingDisabled) {
                return resolveDisabledCaching(true);
            }
            if (!storeName) {
                return rejectInvalidInput();
            }
            var defer = $q.defer();
            LogSvc.debug('[LocalStoreSvc]: Get all objects from store:', storeName);

            var result = [];
            var transaction = _indexedDB.transaction(storeName);
            transaction.oncomplete = function () {
                LogSvc.debug('[LocalStoreSvc]: Finished retrieving objects from store:', storeName);
                resolvePromise(defer, result);
            };

            var objectStore = transaction.objectStore(storeName);
            if (indexName) {
                objectStore = objectStore.index(indexName);
            }
            var request;
            if (reverse) {
                request = objectStore.openCursor(null, 'prev');
            } else {
                request = objectStore.openCursor();
            }
            request.onsuccess = function () {
                var cursor = request.result;
                if (cursor) {
                    var object = decryptData(cursor.value);
                    object && result.push(object);
                    cursor.continue();
                }
            };
            request.onerror = function () {
                // request.error is a DOMException so don't pass as parameter.
                // Otherwise logger tries to get the stack data.
                LogSvc.error('[LocalStoreSvc]: Error getting object. ' + request.error);
                rejectPromise(defer);
            };
            return defer.promise;
        }

        function getLoggedOnUser(db) {
            db = db || _indexedDB;

            var defer = $q.defer();
            LogSvc.debug('[LocalStoreSvc]: Get logged on user from store:', LOGGED_ON_USER_STORE_NAME);

            var result = null;
            var transaction = db.transaction(LOGGED_ON_USER_STORE_NAME);
            transaction.oncomplete = function () {
                LogSvc.debug('[LocalStoreSvc]: Finished retrieving objects from store:', LOGGED_ON_USER_STORE_NAME);
                resolvePromise(defer, result);
            };

            var objectStore = transaction.objectStore(LOGGED_ON_USER_STORE_NAME);
            var request = objectStore.openCursor();

            request.onsuccess = function () {
                var cursor = request.result;
                if (cursor) {
                    result = cursor.value;
                    cursor.continue();
                }
            };
            request.onerror = function () {
                // request.error is a DOMException so don't pass as parameter.
                // Otherwise logger tries to get the stack data.
                LogSvc.error('[LocalStoreSvc]: Error getting object. ' + request.error);
                rejectPromise(defer);
            };
            return defer.promise;
        }

        function getAllUsers() {
            return getAll(USER_STORE_NAME);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // IndexedDB DELETE functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function deleteObjectsFromStore(dataArray, storeName) {
            if (_cachingDisabled) {
                return resolveDisabledCaching(dataArray instanceof Array);
            }
            if (!dataArray || dataArray.length === 0) {
                return rejectInvalidInput();
            }
            if (!(dataArray instanceof Array)) {
                dataArray = [dataArray];
            }
            var defer = $q.defer();
            try {
                var transaction = _indexedDB.transaction(storeName, 'readwrite');
                transaction.oncomplete = function () {
                    LogSvc.debug('[LocalStoreSvc]: Finished deleting object(s) from', storeName);
                    resolvePromise(defer, dataArray);
                };
                transaction.onerror = function () {
                    $timeout(function () {
                        // transaction.error is a DOMException so don't pass as parameter.
                        // Otherwise logger tries to get the stack data.
                        LogSvc.info('[LocalStoreSvc]: Transaction error deleting objects. ' + transaction.error);
                        defer.reject();
                    });
                };

                var objectStore = transaction.objectStore(storeName);
                dataArray.forEach(function (id) {
                    var request = objectStore.delete(id);
                    request.onerror = function () {
                        // request.error is a DOMException so don't pass as parameter.
                        // Otherwise logger tries to get the stack data.
                        LogSvc.error('[LocalStoreSvc]: Error deleting object with id=' + id + '. ' + request.error);
                    };
                });
            } catch (e) {
                LogSvc.error('[LocalStoreSvc]: Error in deleteObjectsFromStore. ', e);
                rejectPromise(defer);
            }
            return defer.promise;
        }

        function removeExchangeContacts(contactIds, storeName) {
            storeName = storeName || EXCHANGE_CONTACTS_STORE_NAME;
            LogSvc.debug('[LocalStoreSvc]: Delete contacts:', storeName, contactIds);
            return deleteObjectsFromStore(contactIds, storeName);
        }

        function removeAllExchangeContacts(storeName) {
            var defer = $q.defer();
            storeName = storeName || EXCHANGE_CONTACTS_STORE_NAME;
            var transaction = _indexedDB.transaction(storeName, 'readwrite');
            transaction.oncomplete = function () {
                LogSvc.debug('[LocalStoreSvc]: Cleared Exchange contacts indexedDB');
                resolvePromise(defer, null);
            };
            transaction.onerror = function (event) {
                LogSvc.error('[LocalStoreSvc]: Error clearing Exchange contacts indexedDB ', event);
                rejectPromise(defer, event);
            };
            transaction.objectStore(storeName).clear();
            return defer.promise;
        }

        function removeExchangeOnlineContacts(contactIds, storeName) {
            storeName = storeName || EXCHANGE_ONLINE_CONTACTS_STORE_NAME;
            LogSvc.debug('[LocalStoreSvc]: Delete contacts:', storeName, contactIds);
            return deleteObjectsFromStore(contactIds, storeName);
        }

        function removeAllExchangeOnlineContacts(storeName) {
            var defer = $q.defer();
            storeName = storeName || EXCHANGE_ONLINE_CONTACTS_STORE_NAME;
            var transaction = _indexedDB.transaction(storeName, 'readwrite');
            transaction.oncomplete = function () {
                LogSvc.debug('[LocalStoreSvc]: Cleared Exchange Online contacts indexedDB');
                resolvePromise(defer, null);
            };
            transaction.onerror = function (event) {
                LogSvc.error('[LocalStoreSvc]: Error clearing Exchange Online contacts indexedDB ', event);
                rejectPromise(defer, event);
            };
            transaction.objectStore(storeName).clear();
            return defer.promise;
        }

        function removeExchangeOnPremiseContacts(contactIds, storeName) {
            storeName = storeName || EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME;
            LogSvc.debug('[LocalStoreSvc]: Delete contacts:', storeName, contactIds);
            return deleteObjectsFromStore(contactIds, storeName);
        }

        function removeAllExchangeOnPremiseContacts(storeName) {
            var defer = $q.defer();
            storeName = storeName || EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME;
            var transaction = _indexedDB.transaction(storeName, 'readwrite');
            transaction.oncomplete = function () {
                LogSvc.debug('[LocalStoreSvc]: Cleared Exchange OnPremise contacts indexedDB');
                resolvePromise(defer, null);
            };
            transaction.onerror = function (event) {
                LogSvc.error('[LocalStoreSvc]: Error clearing Exchange OnPremise contacts indexedDB ', event);
                rejectPromise(defer, event);
            };
            transaction.objectStore(storeName).clear();
            return defer.promise;
        }
        ///////////////////////////////////////////////////////////////////////////////////////
        // IndexedDB PUT functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function putObjectsToStore(dataArray, storeName) {
            if (_cachingDisabled) {
                return resolveDisabledCaching(Array.isArray(dataArray));
            }

            var defer = $q.defer();
            try {
                var transaction = _indexedDB.transaction(storeName, 'readwrite');
                transaction.oncomplete = function () {
                    resolvePromise(defer, dataArray);
                };
                transaction.onerror = function () {
                    $timeout(function () {
                        // transaction.error is a DOMException so don't pass as parameter.
                        // Otherwise logger tries to get the stack data.
                        LogSvc.error('[LocalStoreSvc]: Transaction error putting object(s). ' + transaction.error);
                        defer.reject();
                    });
                };

                var objectStore = transaction.objectStore(storeName);
                var encryptedArray = encryptData(objectStore, dataArray);

                encryptedArray.forEach(function (data) {
                    var request = objectStore.put(data);
                    request.onerror = function () {
                        // request.error is a DOMException so don't pass as parameter.
                        // Otherwise logger tries to get the stack data.
                        LogSvc.error('[LocalStoreSvc]: There was an error putting an object. storeName = ', storeName);
                        LogSvc.error('[LocalStoreSvc]: Error details - ' + request.error);
                    };
                });
            } catch (e) {
                LogSvc.error('[LocalStoreSvc]: Error in putObjectsToStore. ', e);
                rejectPromise(defer);
            }
            return defer.promise;
        }

        function putUsers(users) {
            if (_cachingDisabled) {
                return resolveDisabledCaching(true);
            }
            if (!users) {
                return rejectInvalidInput();
            }
            if (!Array.isArray(users)) {
                users = [users];
            }
            // Remove the local user
            users.some(function (u, idx) {
                if ($rootScope.localUser.userId === u.userId) {
                    users.splice(idx, 1);
                    return true;
                }
                return false;
            });
            if (users.length === 0) {
                return rejectInvalidInput();
            }

            var userIds = users.map(function (u) { return u.userId; });
            LogSvc.debug('[LocalStoreSvc]: Put users:', userIds);

            return putObjectsToStore(users, USER_STORE_NAME);
        }

        function putExchangeContacts(contacts, storeName) {
            storeName = storeName || EXCHANGE_CONTACTS_STORE_NAME;
            if (_cachingDisabled) {
                return resolveDisabledCaching(true);
            }
            if (!contacts) {
                return rejectInvalidInput();
            }
            if (!Array.isArray(contacts)) {
                contacts = [contacts];
            }

            var msg = '';
            switch (storeName) {
            case EXCHANGE_ONLINE_CONTACTS_STORE_NAME:
                msg = 'Online ';
                break;
            case EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME:
                msg = 'OnPremise ';
                break;
            }
            LogSvc.debug('[LocalStoreSvc]: Put Exchange ' + msg + 'contacts. Count:', contacts.length);

            return putObjectsToStore(contacts, storeName);
        }

        function putExchangeOnlineContacts(contacts) {
            return putExchangeContacts(contacts, EXCHANGE_ONLINE_CONTACTS_STORE_NAME);
        }

        function putExchangeOnPremiseContacts(contacts) {
            return putExchangeContacts(contacts, EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // IndexedDB ADD functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function addObjectsToStore(dataArray, storeName) {
            if (_cachingDisabled) {
                return resolveDisabledCaching(Array.isArray(dataArray));
            }
            if (!dataArray || dataArray.length === 0) {
                return rejectInvalidInput();
            }
            if (!(dataArray instanceof Array)) {
                dataArray = [dataArray];
            }
            var defer = $q.defer();
            try {
                var transaction = _indexedDB.transaction(storeName, 'readwrite');
                transaction.oncomplete = function () {
                    resolvePromise(defer, dataArray);
                };
                transaction.onerror = function () {
                    $timeout(function () {
                        // transaction.error is a DOMException so don't pass as parameter.
                        // Otherwise logger tries to get the stack data.
                        LogSvc.error('[LocalStoreSvc]: Transaction error adding objects. ' + transaction.error);
                        defer.reject();
                    });
                };
                transaction.onabort = function () {
                    // Transaction is aborted (common reason: not enough Space on the disc)
                    LogSvc.error('[LocalStoreSvc]: Transaction is aborted. Error: ', transaction.error.toString());
                    _cachingDisabled = true;
                    defer.reject();
                };

                var objectStore = transaction.objectStore(storeName);
                var encryptedArray = encryptData(objectStore, dataArray);

                encryptedArray.forEach(function (data) {
                    var request = objectStore.add(data);
                    request.onerror = function () {
                        // request.error is a DOMException so don't pass as parameter.
                        // Otherwise logger tries to get the stack data.
                        LogSvc.error('[LocalStoreSvc]: Error adding object. ' + request.error);
                        LogSvc.info('[LocalStoreSvc]: Request object =', data);
                    };
                });
            } catch (e) {
                LogSvc.error('[LocalStoreSvc]: Error in addObjectsToStore. ', e);
                rejectPromise(defer);
            }
            return defer.promise;
        }

        function addUsers(users) {
            if (!users) {
                return rejectInvalidInput();
            }
            if (!Array.isArray(users)) {
                users = [users];
            }
            // Remove the local user
            users.some(function (u, idx) {
                if ($rootScope.localUser.userId === u.userId) {
                    users.splice(idx, 1);
                    return true;
                }
                return false;
            });
            if (users.length === 0) {
                return rejectInvalidInput();
            }

            var userIds = users.map(function (u) { return u.userId; });
            LogSvc.debug('[LocalStoreSvc]: Add users:', userIds);

            return addObjectsToStore(users, USER_STORE_NAME);
        }

        function clearPrivateDataAndSetLoggedOn(user) {
            var defer = $q.defer();

            _userSpecificKeys.forEach(function (key) {
                removeItem(key);
            });

            clearObjectStores(true)
            .then(function () {
                return addObjectsToStore(user, LOGGED_ON_USER_STORE_NAME);
            }, function () {
                // Can't clear ObjectStores
                defer.reject('res_ErrorClearIndexedDb');
            })
            .then(function () {
                LogSvc.debug('[LocalStoreSvc]: Added localUser to indexedDB');
                defer.resolve();
            }, function () {
                // Not able to set logged on user, but should be OK
                LogSvc.info('[LocalStoreSvc]: Failed to add localUser to indexedDB');
                defer.resolve();
            });
            return defer.promise;
        }

        function clearLocalStorage(clearAll) {
            var exceptionList = clearAll ? _criticalKeys : _cachingDisabledExceptions;
            Object.values(_keys).forEach(function (key) {
                if (!exceptionList.includes(key)) {
                    removeItem(key);
                }
            });
        }

        function addExchangeContacts(contacts, storeName) {
            storeName = storeName || EXCHANGE_CONTACTS_STORE_NAME;
            if (!contacts) {
                return rejectInvalidInput();
            }
            if (!Array.isArray(contacts)) {
                contacts = [contacts];
            }
            if (contacts.length === 0) {
                return rejectInvalidInput();
            }

            LogSvc.debug('[LocalStoreSvc]: Adding Exchange contacts. Count:', contacts.length);

            return addObjectsToStore(contacts, storeName);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.keys = _keys;

        /**
         * Initializes indexedDB.
         *
         * @function
         * @return {Promise} A promise that will be resolved when indexedDB is initialized.
         */
        this.initIndexedDb = function (enableCache) {
            return initIndexedDb(enableCache, null);
        };

        /**
         * Clears all keys from local storage.
         */
        this.clearLocalStorage = function () {
            clearLocalStorage(true);
        };

        /**
         * Set a key/value pair in localStorage.
         *
         * @param {String} key A unique string.
         * @param {Object} value An object to be stored as value.
         */
        this.setObjectSync = function (key, value) {
            setItem(key, JSON.stringify(value));
        };

        /**
         * Get an object specifying key.
         *
         * @param {String} key A unique string.
         */
        this.getObjectSync = function (key) {
            var obj = getItem(key);
            try {
                obj = Utils.fromJson(obj);
            } catch (e) {
                obj = null;
            }
            return obj;
        };

        /**
         * Set a key/value pair in localStorage.
         *
         * @param {String} key A unique string.
         * @param {String} value A string to be stored as value.
         */
        this.setString = function (key, value) {
            setItem(key, value);
        };

        /**
         * Get a string value specifying key synchronously.
         *
         * @param {String} key A unique string.
         */
        this.getStringSync = function (key) {
            return getItem(key);
        };

        /**
         * Set a key/value pair in localStorage.
         *
         * @param {String} key A unique string.
         * @param {Number} value A number to be stored as value.
         */
        this.setInt = function (key, value) {
            setItem(key, value);
        };

        /**
         * Get a numeric value specifying key.
         *
         * @param {String} key A unique string.
         * @param {function} cb Callback function that will receive the number mapped from given key.
         */
        this.getInt = function (key) {
            var val = getItem(key);
            return isNaN(parseInt(val, 10)) ? -1 : val;
        };

        /**
         * Remove a key/value pair from localStorage.
         *
         * @param {String} key A unique string.
         */
        this.removeItem = function (key) {
            removeItem(key);
        };

        /**
         * Get all users from indexedDB.
         *
         * @function
         * @return {Promise} A promise that will be resolved with an array of users.
         */
        this.getAllUsers = getAllUsers;

        /**
         * Add user(s) in indexedDB. If user(s) exists in indexedDB, the request will be rejected.
         *
         * @function
         * @param {(User|User[])} users A user or an array of users.
         * @return {Promise} A promise that will be resolved when user(s) are added.
         */
        this.addUsers = addUsers;

        /**
         * Add or modify user(s) in indexedDB. If user(s) exists in indexedDB, it will be modified.
         *
         * @function
         * @param {(User|User[])} users A user or an array of users.
         * @return {Promise} A promise that will be resolved when user(s) are added.
         */
        this.putUsers = putUsers;

        /**
         * Clear all object stores in indexedDB.
         *
         * @function
         * @return {Promise} A promise that will be resolved when indexedDB is cleared.
         */
        this.clearIndexedDb = function () {
            if (_cachingDisabled) {
                return $q.resolve();
            }
            return clearObjectStores(false);
        };

        /**
         * Set user as logged on user in indexedDB. If the user is different from previous logged on user, all object stores will be cleared.
         *
         * @param {User} user A user who just logged in.
         * @return {Promise} A promise that will be resolved when logged on user is set.
         */
        this.setLoggedOnUser = function (user) {
            var defer = $q.defer();
            if (_cachingDisabled || !user) {
                // If caching is disabled we cannot tell whether this is a new user or the
                // same user, so we must remove the old logs to ensure privacy.
                LogSvc.removeLogFiles();
                // Wait a bit so new log file is created correctly
                $timeout(defer.resolve, 100, false);
            } else {
                _myKey = _md5(user.emailAddress).toString();
                _myKey = _md5(_myKey + _baseSecret).toString();

                getLoggedOnUser()
                .then(function (prevUser) {
                    if (prevUser) {
                        if (prevUser.userId === user.userId) {
                            // Same user as previously logged on
                            LogSvc.debug('[LocalStoreSvc]: The same user is logging in again. Keep indexedDB data.');
                            defer.resolve();
                            return;
                        }

                        // A different user logged on. Remove old log files.
                        LogSvc.removeLogFiles();
                    } else {
                        // A different user logged on. Remove old log files.
                        // This is for web-client. When "Private computer" wasn't checked, we don't store user in indexedDB
                        // and then when a new one is logged on, we should remove old log files
                        LogSvc.removeLogFiles();
                    }

                    LogSvc.debug('[LocalStoreSvc]: A new user is logging in. Clear indexedDB.');
                    clearPrivateDataAndSetLoggedOn(user)
                    .then(function () {
                        defer.resolve();
                    })
                    .catch(function (err) {
                        defer.reject(err);
                    });
                })
                .catch(function () {
                    clearPrivateDataAndSetLoggedOn(user)
                    .then(function () {
                        defer.resolve();
                    })
                    .catch(function (err) {
                        defer.reject(err);
                    });
                });
            }
            return defer.promise;
        };

        /**
         * Returns if caching is disabled
         */
        this.isCachingDisabled = function () {
            return _cachingDisabled;
        };

        this.getAllExchangeContacts = function (storeName) {
            storeName = storeName || EXCHANGE_CONTACTS_STORE_NAME;
            return getAll(storeName);
        };

        this.getAllExchangeOnlineContacts = function (storeName) {
            storeName = storeName || EXCHANGE_ONLINE_CONTACTS_STORE_NAME;
            return getAll(storeName);
        };

        this.getAllExchangeOnPremiseContacts = function (storeName) {
            storeName = storeName || EXCHANGE_ON_PREMISE_CONTACTS_STORE_NAME;
            return getAll(storeName);
        };

        this.addExchangeContacts = addExchangeContacts;

        this.putExchangeContacts = putExchangeContacts;

        this.putExchangeOnlineContacts = putExchangeOnlineContacts;

        this.putExchangeOnPremiseContacts = putExchangeOnPremiseContacts;

        this.removeExchangeContacts = removeExchangeContacts;

        this.removeAllExchangeContacts = removeAllExchangeContacts;

        this.removeExchangeOnlineContacts = removeExchangeOnlineContacts;

        this.removeAllExchangeOnlineContacts = removeAllExchangeOnlineContacts;

        this.removeExchangeOnPremiseContacts = removeExchangeOnPremiseContacts;

        this.removeAllExchangeOnPremiseContacts = removeAllExchangeOnPremiseContacts;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.LocalStoreSvcImpl = LocalStoreSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
