var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Utils = circuit.Utils;

    // FAQ article IDs
    var FaqArticle = {
        ACCESSIBILITY: '125328',
        AGC: '89415',
        DEVICE_ACCESS: '48742',
        JABRA: '112718',
        LOGIN: '37428',
        PLANTRONICS: '115017',
        SHORTCUTS: Utils.isMacOs ? '144499' : '144490',
        TELEPHONY: '103733'
    };

    // eslint-disable-next-line max-params, max-lines-per-function
    function UtilSvcImpl($rootScope, $q, $timeout, LogSvc) { // NOSONAR
        LogSvc.debug('New Service: UtilSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _self = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Transforms callback-based function -- func(arg1, arg2 .. argN, callback) -- into
         * an $q-compatible Promise. Promisify provides a default callback of the form (error, result)
         * and rejects when `error` is truthy. You can also supply `this` object as the second argument.
         *
         * @param {function} original - The function to promisify
         * @param {object} target - `this` object
         * @return {function} A promisified version of `original`
         */
        this.promisify = function (original, target) {
            return function () {
                var args = Array.prototype.slice.call(arguments);
                return new $q(function (resolve, reject) {
                    args.push(function callback(err) {
                        if (err) {
                            reject(err);
                            return;
                        }
                        var values = Array.prototype.slice.call(arguments);
                        values.shift();
                        switch (values.length) {
                        case 0:
                            resolve();
                            break;
                        case 1:
                            resolve(values[0]);
                            break;
                        default:
                            resolve(values);
                            break;
                        }
                    });
                    original.apply(target, args);
                });
            };
        };

        this.getFaqUrl = function (articleId) {
            if ($rootScope.localUser) {
                if ($rootScope.localUser.faqUrl && Utils.DEFAULT_FAQ_URL !== $rootScope.localUser.faqUrl) {
                    // Use custom FAQ URL
                    return $rootScope.localUser.faqUrl + (articleId || '');
                } else if ($rootScope.localUser.helpUrl && Utils.DEFAULT_HELP_URL !== $rootScope.localUser.helpUrl) {
                    // Return custom help URL
                    return $rootScope.localUser.helpUrl;
                }
            }
            if (articleId === 'HELP') {
                // Return default help URL
                return Utils.DEFAULT_HELP_URL;
            }
            // Use default FAQ URL
            return Utils.DEFAULT_FAQ_URL + (articleId ? '?articleId=' + articleId : '');
        };

        this.isTenantAdmin = function (cb, tenantContext) {
            if (!$rootScope.localUser) {
                return false;
            }
            if (tenantContext && tenantContext.tenantId !== $rootScope.localUser.tenantId) {
                return _self.isPartnerAdmin(cb);
            }
            if ($rootScope.localUser.isTenantAdmin) {
                return true;
            }

            LogSvc.warn('[UtilSvc]: Local user is not a tenant admin');
            cb && $timeout(function () { cb('Not tenant admin'); });
            return false;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.UtilSvcImpl = UtilSvcImpl;

    circuit.Enums = circuit.Enums || {};
    circuit.Enums.FaqArticle = FaqArticle;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
