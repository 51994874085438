var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ExchangeConnError = circuit.Enums.ExchangeConnError;

    var MailboxServices = {
        ExchangeOnlineSvc: {
            //permission: 'msExchangeEnabled',
            labelRes: 'res_MicrosoftExchangeTitle',
            searchRes: 'res_ExchangeContacts'
        },
        ExchangeOnPremiseSvc: {
        //     //permission: 'msExchangeEnabled',
            labelRes: 'res_MicrosoftExchangeTitle',
            searchRes: 'res_ExchangeContacts'
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////
    // MailboxConnSvcImpl Implementation
    // This service manage multiple mailboxes services that individually connect to
    // different mailbox providers
    // At this momment they are
    //    ExchangeOnlineSvc: connects to Microsoft o365 Server through GraphAPI
    //    ExchangeOnPremiseSvc: connects to Microsoft Exchange Server through EWS interface
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function MailboxConnSvcImpl($rootScope, $q, $injector, LogSvc, PubSubSvc) { // NOSONAR
        LogSvc.debug('New Service: MailboxConnSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _mailboxServices = [];

        var _searchId = 0;
        var _searchIds = {};
        var _exchangeOnlineConnected = false;
        var _exchangeOnPremiseConnected = false;
        var _googleConnected = false;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function getNextSearchId() {
            _searchId++;
            return _searchId;
        }

        function addService(serviceName) {
            var hadService = _mailboxServices.some(function (service) {
                return serviceName === service.name;
            });
            if (!hadService) {
                var serviceInstance = $injector.get(serviceName);
                if (!serviceInstance) {
                    return false;
                }
                _mailboxServices.push(serviceInstance);
            }

            return !hadService;
        }

        function removeService(serviceName) {
            return _mailboxServices.some(function (service, idx) {
                if (serviceName === service.name) {
                    _mailboxServices.splice(idx, 1);
                    return true;
                }
                return false;
            });
        }

        function isServiceEnabled(serviceName) {
            var serviceData = MailboxServices[serviceName];
            return (!serviceData.permission || $rootScope.localUser[serviceData.permission]) &&
                (!serviceData.labFeature || $rootScope.circuitLabs[serviceData.labFeature]);
        }

        function initConnectorServices() {
            if ($rootScope.localUser) {
                var changed = false;
                Object.keys(MailboxServices).forEach(function (serviceName) {
                    changed = (isServiceEnabled(serviceName) ? addService : removeService)(serviceName) || changed;
                });
                if (changed) {
                    var serviceNames = _mailboxServices.map(function (service) { return service.name; });
                    LogSvc.debug('[MailboxConnSvc]: Publish /mailbox/connectorChanged event. Enabled connectors: ', serviceNames);
                    PubSubSvc.publish('/mailbox/connectorChanged', []);
                }
            }
        }

        function publishConnectionStatus() {
            if (!_exchangeOnlineConnected && !_exchangeOnPremiseConnected && !_googleConnected) {
                LogSvc.debug('[MailboxConnSvc]: Publish /mailbox/disconnected event');
                PubSubSvc.publish('/mailbox/disconnected', []);
            } else {
                LogSvc.debug('[MailboxConnSvc]: Publish /mailbox/connected event');
                PubSubSvc.publish('/mailbox/connected', []);
            }
        }

        function getConnected(service) {
            if (!service) {
                return false;
            }
            return typeof service.getConnected === 'function' ? service.getConnected() : service.connected;
        }
        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/exchangeOnline/connected', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /exchangeOnline/connected event');
            _exchangeOnlineConnected = true;
            publishConnectionStatus();
        });

        PubSubSvc.subscribe('/exchangeOnline/disconnected', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /exchangeOnline/disconnected event');
            _exchangeOnlineConnected = false;
            publishConnectionStatus();
        });

        PubSubSvc.subscribe('/exchangeOnPremise/connected', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /exchangeOnPremise/connected event');
            _exchangeOnPremiseConnected = true;
            publishConnectionStatus();
        });

        PubSubSvc.subscribe('/exchangeOnPremise/disconnected', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /exchangeOnPremise/disconnected event');
            _exchangeOnPremiseConnected = false;
            publishConnectionStatus();
        });

        PubSubSvc.subscribe('/googleContacts/connected', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /googleContacts/connected event');
            _googleConnected = true;
            publishConnectionStatus();
        });

        PubSubSvc.subscribe('/googleContacts/disconnected', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /googleContacts/disconnected event');
            _googleConnected = false;
            publishConnectionStatus();
        });

        PubSubSvc.subscribe('/localUser/tenantSettingsUpdate', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /localUser/tenantSettingsUpdate event');
            initConnectorServices();
        });

        PubSubSvc.subscribe('/localUser/init', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /localUser/init event');
            initConnectorServices();
        });

        PubSubSvc.subscribe('/feature/state/changed', function () {
            LogSvc.debug('[MailboxConnSvc]: Received /feature/state/changed event');
            initConnectorServices();
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        // Define the read-only properties to access the internal variables
        Object.defineProperties(this, {
            connected: {
                get: function () {
                    return _exchangeOnlineConnected || _exchangeOnPremiseConnected || _googleConnected;
                },
                enumerable: true,
                configurable: false
            }
        });

        this.getMeetingsNeedServiceText = function () {
            var getIntegrationLabel = function (idx) {
                var labelRes = MailboxServices[_mailboxServices[idx].name].labelRes;
                return $rootScope.i18n.map[labelRes];
            };

            if (_mailboxServices.length === 1) {
                return $rootScope.i18n.localize('res_MeetingsNeedOneMailboxTagline', [getIntegrationLabel(0)]);
            } else if (_mailboxServices.length > 1) {
                return $rootScope.i18n.localize('res_MeetingsNeedTwoMailboxTagline',
                    [getIntegrationLabel(0), getIntegrationLabel(1)]);
            }
            return '';
        };

        this.getSearchResultsTitleRes = function () {
            var connectedServices = _mailboxServices.filter(function (service) {
                return getConnected(service);
            });
            if (connectedServices.length === 1) {
                return MailboxServices[connectedServices[0].name].searchRes;
            }
            return 'res_ExternalContacts';
        };

        this.searchContactsWithConstraints = function (searchStr, constraints, resCount, cb) {
            LogSvc.debug('[MailboxConnSvc] searchContactsWithConstraints', searchStr, constraints, resCount);
            var searchId = getNextSearchId();

            if (hasConnectorService(cb)) {
                var promises = [];
                _mailboxServices.forEach(function (service) {
                    if (service.searchContactsWithConstraints && (getConnected(service) ||
                        (constraints && constraints.phoneNumberLookup) ||
                        (constraints && constraints.reversePhoneNumberLookup))) {
                        promises.push(new $q(function (resolve) {
                            var searchCallback = function (err, contacts) {
                                resolve({
                                    err: err,
                                    contacts: contacts,
                                    serviceName: service.name
                                });
                            };
                            try {
                                var serviceSearchId = service.searchContactsWithConstraints(searchStr, constraints, resCount, searchCallback);
                                if (serviceSearchId) {
                                    _searchIds[searchId] = _searchIds[searchId] || {};
                                    _searchIds[searchId][service.name] = _searchIds[searchId][service.name] || {};
                                    _searchIds[searchId][service.name] = serviceSearchId;
                                } else {
                                    resolve({
                                        err: ExchangeConnError.INTERNAL_ERROR
                                    });
                                }
                            } catch (ex) {
                                LogSvc.error('[MailboxConnSvc]: Search Contacts exception - ', [service.name, ex]);
                                resolve({
                                    err: ExchangeConnError.INTERNAL_ERROR
                                });
                            }
                        }));
                    }
                });
                if (promises.length > 0) {
                    $q.all(promises)
                    .then(function (results) {
                        var allContacts = [];
                        var allErrors = [];
                        results.forEach(function (result) {
                            if (result.err) {
                                LogSvc.error('[MailboxConnSvc]: Search Contacts failed - ', [result.err, result.serviceName]);
                                allErrors.push(result.err);
                            }
                            result.contacts && Array.prototype.push.apply(allContacts, result.contacts);
                        });
                        if (_searchIds[searchId]) {
                            delete _searchIds[searchId];
                        }

                        if (allErrors.length > 0 && results.length === allErrors.length && allContacts.length === 0) {
                            cb(allErrors[0]);
                        } else {
                            cb(null, allContacts);
                        }
                    })
                    .catch(function () {
                        cb(ExchangeConnError.INTERNAL_ERROR);
                    });
                } else {
                    cb(null, []);
                }
            }
            return searchId;
        };

        this.cancelSearchContacts = function (searchId) {
            var searchIds = _searchIds[searchId];
            searchIds && _mailboxServices.forEach(function (service) {
                searchIds[service.name] && service.cancelSearchContacts && service.cancelSearchContacts(searchIds[service.name]);
            });
            delete _searchIds[searchId];
        };

        this.getContact = function (exchangeEmail, cb) {
            if (!hasConnectorService(cb)) {
                return;
            }
            var promises = [];
            _mailboxServices.forEach(function (service) {
                if (service.getContact && getConnected(service)) {
                    promises.push(new $q(function (resolve) {
                        service.getContact(exchangeEmail, function (err, contact) {
                            resolve({
                                err: err,
                                contact: contact,
                                serviceName: service.name
                            });
                        });
                    }));
                }
            });

            $q.all(promises)
            .then(function (results) {
                var allContacts = [];
                var allErrors = [];
                results.forEach(function (result) {
                    if (result.err) {
                        LogSvc.error('[MailboxConnSvc]: Get Contact failed - ', [result.err, result.serviceName]);
                        allErrors.push(result.err);
                    }
                    result.contact && allContacts.push(result.contact);
                });

                if (allErrors.length > 0 && results.length === allErrors.length && allContacts.length === 0) {
                    cb(allErrors[0]);
                } else {
                    cb(null, allContacts[0]);
                }
            })
            .catch(function () {
                cb(ExchangeConnError.INTERNAL_ERROR);
            });
        };

        this.getAllPersonalContacts = function () {
            _mailboxServices.forEach(function (service) {
                service.getAllPersonalContacts && getConnected(service) && service.getAllPersonalContacts();
            });
        };

        this.getOooMsg = function (email) {
            return new $q(function (resolve, reject) {
                var promises = [];
                _mailboxServices.forEach(function (service) {
                    if (service.getOooMsg && getConnected(service)) {
                        promises.push(service.getOooMsg(email));
                    }
                });

                $q.all(promises)
                .then(function (results) {
                    var oooMsg = results.find(function (msg) {
                        return msg;
                    });
                    resolve(oooMsg || '');
                })
                .catch(reject);
            });
        };

        this.isOutOfOffice = function (email) {
            return new $q(function (resolve, reject) {
                var promises = [];
                _mailboxServices.forEach(function (service) {
                    if (service.isOutOfOffice && getConnected(service)) {
                        promises.push(service.isOutOfOffice(email));
                    }
                });

                $q.all(promises)
                .then(function (results) {
                    var isOutOfOffice = results.some(function (ooo) {
                        return ooo;
                    });
                    resolve(isOutOfOffice);
                })
                .catch(reject);
            });
        };

        this.supportsUserAvailability = function () {
            return _mailboxServices.some(function (service) {
                return service.supportsUserAvailability && service.supportsUserAvailability();
            });
        };

        this.getUserAvailability = function (email) {
            return new $q(function (resolve, reject) {
                var promises = [];
                _mailboxServices.forEach(function (service) {
                    if (service.supportsUserAvailability() && getConnected(service)) {
                        promises.push(service.getUserAvailability(email));
                    }
                });

                $q.all(promises)
                .then(function (results) {
                    var userAvailability = [];
                    results.forEach(function (result) {
                        Array.isArray(result) && Array.prototype.push.apply(userAvailability, result);
                    });
                    userAvailability.sort(function (userAvailabilibyA, userAvailabilityB) {
                        return userAvailabilibyA.startTime - userAvailabilityB.startTime;
                    });
                    resolve(userAvailability);
                })
                .catch(function (err) {
                    reject(err);
                });
            });
        };

        this.supportsOOO = function () {
            return _mailboxServices.some(function (service) {
                return service.supportsOOO && service.supportsOOO();
            });
        };

        this.supportsGetAppointments = function () {
            return _mailboxServices.some(function (service) {
                return service.supportsGetAppointments && service.supportsGetAppointments();
            });
        };

        function hasConnectorService(cb) {
            if (_mailboxServices.length === 0) {
                LogSvc.debug('[MailboxConnSvc]: No Connector service available');
                cb && cb(ExchangeConnError.INTERNAL_ERROR);
                return false;
            }
            return true;
        }

        this.getAppointments = function (startDate, endDate, resCount, cb) {
            if (!hasConnectorService(cb)) {
                return;
            }
            var promises = [];
            _mailboxServices.forEach(function (service) {
                if (service.getAppointments && getConnected(service) && service.supportsGetAppointments()) {
                    promises.push(new $q(function (resolve) {
                        service.getAppointments(startDate, endDate, resCount, function (err, appointments) {
                            resolve({
                                serviceName: service.name,
                                err: err,
                                appointments: appointments
                            });
                        });
                    }));
                }
            });

            $q.all(promises)
            .then(function (results) {
                var allAppointments = [];
                var allErrors = [];
                results.forEach(function (result) {
                    if (result.err) {
                        LogSvc.error('[MailboxConnSvc]: Get Contact failed - ', [result.err, result.serviceName]);
                        allErrors.push(result.err);
                    }
                    result.appointments && Array.prototype.push.apply(allAppointments, result.appointments);
                });

                if (allErrors.length > 0 && results.length === allErrors.length && allAppointments.length === 0) {
                    cb(allErrors[0]);
                } else {
                    cb(null, allAppointments);
                }
            })
            .catch(function () {
                cb(ExchangeConnError.INTERNAL_ERROR);
            });
        };

        this.syncAllPersonalContacts = function () {
            _mailboxServices.forEach(function (service) {
                service.syncAllPersonalContacts && getConnected(service) && service.syncAllPersonalContacts();
            });
        };

        this.updateUserWithLocalContactName = function (user) {
            _mailboxServices.some(function (service) {
                return service.updateUserWithLocalContactName && service.updateUserWithLocalContactName(user);
            });
        };

        this.disableAutoConnect = function () {
            _mailboxServices.forEach(function (service) {
                service.disableAutoConnect && service.disableAutoConnect();
            });
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        initConnectorServices();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    circuit.MailboxConnSvcImpl = MailboxConnSvcImpl;
    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
