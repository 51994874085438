
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var FaqArticle = circuit.Enums.FaqArticle;
    var ShortcutActions = circuit.ShortcutActions;
    var ShortcutActionTypes = circuit.Enums.ShortcutActionTypes;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function ShortcutSvcImpl($rootScope, LogSvc, UtilSvc, PubSubSvc, PopupSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: ShortcutSvc');

        ///////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////
        var _displayedActions = null; // The display information for actions shown on the shortcuts information popup
        var _shiftActions = []; // The actions that are triggered in combination with the Shift-key (a search index)
        var _ctrlActions = []; // The actions that are triggered in combination with the Ctrl-key (a search index)
        var _cmdActions = []; // The actions that are triggered in combination with the Cmd-key (a search index, MacOS-only)
        var _altActions = []; // The actions that are triggered in combination with the Alt-key (a search index)

        var _shortcutsPopup = null; // The reference to the open shortcuts information popup
        var _openPopups = 0; // The number of currently open popups in the application

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function assembleActionInformation() {
            _displayedActions = {};

            // Assemble display information for non-hidden actions shown on the shortcuts information popup
            ShortcutActions.forEach(function (action) {
                if (!action.hidden && (!action.labFeature || $rootScope.circuitLabs[action.labFeature])) {
                    var information = getActionInformation(action);
                    if (!_displayedActions[information.category]) {
                        _displayedActions[information.category] = [];
                    }
                    _displayedActions[information.category].push(information); // map actions by category name
                }
            });
        }

        function getActionInformation(action) {
            var map = $rootScope.i18n.map || [];
            var category = map[action.category.name];
            var name = map[action.name];
            var modifiers = [];

            action.shift && modifiers.push(map.res_ShortcutModifierShift);
            action.ctrl && modifiers.push(map.res_ShortcutModifierCtrl);
            action.cmd && modifiers.push(map.res_ShortcutModifierCommand);
            action.alt && modifiers.push(map[Utils.isMacOs ? 'res_ShortcutModifierOption' : 'res_ShortcutModifierAlt']);
            modifiers.push(action.key.name ? map[action.key.name] : action.key.key);
            return {category: category, name: name, shortcut: modifiers.join(' + ')};
        }

        function toggleShortcutsPopup() {
            if (!_displayedActions) {
                assembleActionInformation();
            }
            if (!_shortcutsPopup) {
                _shortcutsPopup = PopupSvc.openCustomModal({
                    templateUrl: 'views/modals/keyboardShortcuts.html',
                    controller: 'GenericDialogCtrl',
                    windowClass: 'keyboard-shortcuts',
                    size: 'custom',
                    resolve: {
                        options: function () {
                            return {
                                actions: _displayedActions,
                                accessibilityFAQ: UtilSvc.getFaqUrl(FaqArticle.ACCESSIBILITY),
                                shortcutsFAQ: UtilSvc.getFaqUrl(FaqArticle.SHORTCUTS)
                            };
                        }
                    }
                });
                _shortcutsPopup.result
                .catch(function () {}) // Handle reject to prevent 'Possibly unhandled rejection' error
                .then(function () {
                    _shortcutsPopup = null;
                });
            } else {
                _shortcutsPopup.close();
            }
        }

        function triggerKeyboardAction(action) {
            if ($rootScope.isFullBrowser && !action.fullScreen) {
                return; // Ignore shortcut that is not supported for full browser or guest application
            }
            if (action.labFeature && !$rootScope.circuitLabs[action.labFeature]) {
                return; // Ignore shortcut that is not an activated lab feature
            }

            // eslint-disable-next-line complexity
            $rootScope.$apply(function () { // NOSONAR
                switch (action.id) { // NOSONAR
                case ShortcutActionTypes.TOGGLE_SHORTCUTS_POPUP:
                    toggleShortcutsPopup();
                    break;
                case ShortcutActionTypes.NEW_PHONE_CALL:
                    newPhoneCall();
                    break;
                case ShortcutActionTypes.OPEN_SETTINGS:
                    openSettings();
                    break;
                case ShortcutActionTypes.ANSWER_INCOMING_CALL:
                    answerIncomingCall();
                    break;
                case ShortcutActionTypes.DECLINE_INCOMING_CALL:
                    declineIncomingCall();
                    break;
                case ShortcutActionTypes.START_AUDIO_CALL:
                    startCall({audio: true, video: false});
                    break;
                case ShortcutActionTypes.START_VIDEO_CALL:
                    startCall({audio: true, video: true});
                    break;
                case ShortcutActionTypes.TOGGLE_VIDEO_IN_CALL:
                    toggleVideoInCall();
                    break;
                case ShortcutActionTypes.TOGGLE_SCREEN_SHARE_IN_CALL:
                    toggleScreenShareInCall();
                    break;
                case ShortcutActionTypes.STOP_REMOTE_CONTROL:
                    stopRemoteControl();
                    break;
                case ShortcutActionTypes.MUTE_ALL_PARTICIPANTS:
                    muteAllParticipants();
                    break;
                case ShortcutActionTypes.STOP_LEAVE_CALL:
                    stopLeaveCall();
                    break;
                case ShortcutActionTypes.MUTE_UNMUTE_AUDIO:
                    muteUnmuteAudio();
                    break;
                case ShortcutActionTypes.TOGGLE_ZOOM_CONTROL:
                    toggleZoomControl();
                    break;
                case ShortcutActionTypes.ENABLE_ALPHA_FEATURES:
                    enableAlphaFeatures();
                    break;
                case ShortcutActionTypes.ENABLE_DEMO_FEATURES:
                    enableDemoFeatures();
                    break;
                case ShortcutActionTypes.ENABLE_CLOSED_BETA_FEATURES:
                    enableClosedBetaFeatures();
                    break;
                case ShortcutActionTypes.OPEN_LOG_MANAGEMENT_POPUP:
                    openLogManagementPopup();
                    break;
                case ShortcutActionTypes.REPORT_ISSUE:
                    reportIssue();
                    break;
                case ShortcutActionTypes.TEST_CANDIDATES_COLLECTION:
                    testCandidatesCollection();
                    break;
                default:
                    break;
                }
            });
        }

        function newPhoneCall() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/newPhoneCall event.');
            PubSubSvc.publish('/shortcut/newPhoneCall');
        }

        function openSettings() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/openSettings event.');
            PubSubSvc.publish('/shortcut/openSettings');
        }

        function answerIncomingCall() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/answerIncomingCall event.');
            PubSubSvc.publish('/shortcut/answerIncomingCall');
        }

        function declineIncomingCall() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/declineIncomingCall event.');
            PubSubSvc.publish('/shortcut/declineIncomingCall');
        }

        function startCall(mediaType) {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/startCall event. mediaType = ', mediaType);
            PubSubSvc.publish('/shortcut/startCall', [mediaType]);
        }

        function toggleVideoInCall() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/toggleVideoInCall event.');
            PubSubSvc.publish('/shortcut/toggleVideoInCall');
        }

        function toggleScreenShareInCall() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/toggleScreenShareInCall event.');
            PubSubSvc.publish('/shortcut/toggleScreenShareInCall');
        }

        function stopRemoteControl() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/stopRemoteControl event.');
            PubSubSvc.publish('/shortcut/stopRemoteControl');
        }

        function muteAllParticipants() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/muteAllParticipants event.');
            PubSubSvc.publish('/shortcut/muteAllParticipants');
        }

        function stopLeaveCall() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/stopLeaveCall event.');
            PubSubSvc.publish('/shortcut/stopLeaveCall');
        }

        function muteUnmuteAudio() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/muteUnmuteAudio event.');
            PubSubSvc.publish('/shortcut/muteUnmuteAudio');
        }

        function toggleZoomControl() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/toggleZoomControl event.');
            PubSubSvc.publish('/shortcut/toggleZoomControl');
        }

        function enableAlphaFeatures() {
            LogSvc.debug('[ShortcutSvc]: User pressed Ctrl+Shift+Alt+X. Enable alpha features.');
            $rootScope.enableAlphaFeatures = true;
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.ENABLE_ALPHA_TESTING, true);
        }

        function enableDemoFeatures() {
            LogSvc.debug('[ShortcutSvc]: User pressed Ctrl+Shift+Alt+D. Enable demo features.');
            $rootScope.showDemoFeatures = true;
        }

        function enableClosedBetaFeatures() {
            LogSvc.debug('[ShortcutSvc]: User pressed Ctrl+Shift+Alt+B. Enable closed beta features.');
            $rootScope.showBetaFeatures = true;
        }

        function openLogManagementPopup() {
            if (!_openPopups && LogSvc.isFileLogAvailable()) {
                LogSvc.debug('[ShortcutSvc]: User pressed Ctrl+Shift+Alt+L. Open logfile management.');
                PopupSvc.openCustomModal({
                    templateUrl: 'views/modals/editLogLevel.html',
                    controller: 'EditLogLevelCtrl'
                })
                .result
                .catch(function () {
                    // Handle reject to prevent 'Possibly unhandled rejection' error
                });
            }
        }

        function reportIssue() {
            LogSvc.debug('[ShortcutSvc]: User pressed Ctrl+Shift+Alt+R. Start to report issue');
            var textItem = {
                contentType: Constants.TextItemContentType.RICH,
                fileList: [],
                supportType: 'issue',
                placeholder: 'res_SupportIssuePlaceholder'
            };
            LogSvc.getLogFilesForDownload(function (logFiles) {
                if (logFiles) {
                    textItem.fileList = logFiles.slice(0);
                }
                LogSvc.debug('[ShortcutSvc]: Publish /conversation/supportMessage event.');
                PubSubSvc.publish('/conversation/supportMessage', [textItem]);
            }, false);
        }

        function testCandidatesCollection() {
            LogSvc.debug('[ShortcutSvc]: Publish /shortcut/testCandidatesCollection event.');
            PubSubSvc.publish('/shortcut/testCandidatesCollection');
        }

        function init() {
            // Create some indexes to allow searching only relevant actions according to the given event
            ShortcutActions.forEach(function (action) {
                action.shift && _shiftActions.push(action);
                action.ctrl && _ctrlActions.push(action);
                action.cmd && _cmdActions.push(action);
                action.alt && _altActions.push(action);
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/language/update', function () {
            LogSvc.debug('[ShortcutSvc]: Received /language/update event');
            assembleActionInformation();
        });

        PubSubSvc.subscribe('/popup/opened', function () {
            LogSvc.debug('[ShortcutSvc]: Received /popup/opened event');
            _openPopups++; // Track number of open popups to be able to ignore shortcuts while one is open
        });

        PubSubSvc.subscribe('/popup/closed', function () {
            LogSvc.debug('[ShortcutSvc]: Received /popup/closed event');
            _openPopups = Math.max(0, _openPopups - 1);
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Opens the keyboard shortcuts information popup.
         *
         * @returns {undefined}
         */
        this.openInformationPopup = function () {
            !_shortcutsPopup && toggleShortcutsPopup();
        };

        /**
         * Returns the shortcut text of the given shortcut action.
         *
         * @param {ShortcutActionTypes} shortcutActionType a string constant defined in ShortcutActionTypes
         * @returns {String} translated text of the shortcut
         */
        this.getShortcut = function (shortcutActionType) {
            var action = ShortcutActions.find(function (shortcutAction) {
                return shortcutAction.id === shortcutActionType;
            });
            return action ? getActionInformation(action).shortcut : '';
        };

        /**
         * Processes the given key event by executing the corresponding action that matches the pressed key combination. Those actions
         * that might manipulate the DOM tree will be processed within the corresponding shortcuts directive.
         *
         * @param {Object} event the key event
         * @returns {String} the ID of the matching action or null if none matched
         */
        this.processKeyEvent = function (event) {
            var cmdPressed = Utils.isMacOs && event.metaKey;
            if (event.ctrlKey || cmdPressed || event.altKey) { // There is no action with Shift modifier only!
                var searchedActions;
                if (event.shiftKey) {
                    searchedActions = _shiftActions;
                } else if (event.ctrlKey) {
                    searchedActions = _ctrlActions;
                } else {
                    searchedActions = cmdPressed ? _cmdActions : _altActions;
                }

                // Retrieve the action that matches the pressed key combination
                var eventKey = event.key ? event.key.toUpperCase() : null;
                var action = searchedActions.find(function (shortcutAction) {
                    return event.shiftKey === shortcutAction.shift &&
                        event.altKey === shortcutAction.alt &&
                        event.ctrlKey === shortcutAction.ctrl &&
                        cmdPressed === shortcutAction.cmd &&
                        (eventKey === shortcutAction.key.key || event.keyCode === shortcutAction.key.code);
                });

                // Execute the matching action only if no popup is open or request is to close the shortcuts popup
                if (action && !action.ignore &&
                    (!_openPopups || _shortcutsPopup && action.id === ShortcutActionTypes.TOGGLE_SHORTCUTS_POPUP)) {

                    // Check whether the user has enabled the shortcut keys in the settings
                    var shortcutsEnabled = !$rootScope.localUser || $rootScope.localUser.keyboardShortcutsEnabled;
                    if (!shortcutsEnabled && !action.bypassSettings) {
                        return null;
                    }

                    triggerKeyboardAction(action);
                    event.preventDefault();
                    event.stopPropagation();
                    return action.id; // return action ID for DOM related actions
                }
            }

            return null;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initialization
        ///////////////////////////////////////////////////////////////////////////////////////
        init();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.ShortcutSvcImpl = ShortcutSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
