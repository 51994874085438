// Define global variables for JSHint
/*exported RegistrationState*/

/*
 * Registration state of a client/user (or guest)
 * @readonly
 * @enum {String}
 * @property Disconnected WebSocket is not connected
 * @property Connecting WebSocket is in the progress of connecting to the server
 * @property Reconnecting WebSocket is reconnecting
 * @property Connected WebSocket is connected
 * @property Waiting Guest Waiting Room state
 * @property Registered User is registered
 * @property LoggingOut User is logging out
 */
var RegistrationState = Object.freeze({
    Disconnected: 'Disconnected',
    DisconnectedFromTestCall: 'DisconnectedFromTestCall',
    Connecting: 'Connecting',
    Reconnecting: 'Reconnecting',
    Connected: 'Connected',
    Waiting: 'Waiting',
    Registered: 'Registered',
    RegisteredForTestCall: 'RegisteredForTestCall',
    LoggingOut: 'LoggingOut'
});
