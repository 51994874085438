/*global require*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var BusyHandlingOptions = circuit.BusyHandlingOptions;
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var CallForwardingTypes = circuit.Enums.CallForwardingTypes;
    var Constants = circuit.Constants;
    var Conversation = circuit.Conversation;
    var IdleState = circuit.Enums.IdleState;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;
    var RoutingOptions = circuit.RoutingOptions;
    var UserProfile = circuit.UserProfile;
    var Utils = circuit.Utils;

    var ExchangeConnError = Object.freeze({
        COULD_NOT_CONNECT: 'couldNotConnect',    // Cannot reach Exchange Server
        UNAUTHORIZED: 'unauthorized',            // Could connect to the server, but could not authenticate user
        FAILED: 'failed',                        // The requested method failed to be completed
        UNAVAILABLE: 'unavailable',              // Failed to connect to connector
        INTERNAL_ERROR: 'internalError',         // Internal error
        TIMEOUT: 'timeout',                      // Timed out waiting for a response
        EXCEED_MAX_ATTEMPT: 'exceedMaxAttempt',  // Exceed the max attempts to connect
        MAILBOX_NOT_FOUND: 'mailboxNotFound',    // Mailbox not found for this user on the configured Exchange server
        NTLM_NOT_SUPPORTED: 'ntlmNotSupported',  // NTLM authentication is not supported on the configured Exchange server
        UNEXPECTED: 'unexpected',                // Unexpected error
        NO_RESULT: 'NO_RESULT'                   // Could not retrieve exchange settings
    });

    // eslint-disable-next-line max-params, max-lines-per-function
    function UserProfileSvcImpl($rootScope, $q, $interval, $timeout, LogSvc, PubSubSvc, LocalizeSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: UserProfileSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_SETTINGS = [
            {
                key: Constants.UserSettingKey.AUDIO_WEB_SOUNDS,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.ATC_ROUTING,
                dataType: Constants.UserSettingDataType.STRING,
                stringValue: RoutingOptions.DefaultRouting.name
            },
            {
                key: Constants.UserSettingKey.DESKTOP_CONVERSATION_CHANGE,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.DESKTOP_MESSAGE_NOTIFICATIONS_SETTING,
                dataType: Constants.UserSettingDataType.NUMBER,
                numberValue: Constants.MessageNotificationsSetting.ALL
            },
            {
                key: Constants.UserSettingKey.DESKTOP_MISSED_MESSAGE,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.DESKTOP_RTC,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.DESKTOP_SYSINFO,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.EMAIL_INVITES,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.EMAIL_MISSED_RTC,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.EMAIL_SUMMARY,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.ENHANCED_DESKTOP_MESSAGE_NOTIFICATION,
                dataType: Constants.UserSettingDataType.NUMBER,
                numberValue: Constants.EnhancedMessageNotificationsSetting.ALL
            },
            {
                key: Constants.UserSettingKey.ENHANCED_MOBILE_MESSAGE_NOTIFICATION,
                dataType: Constants.UserSettingDataType.NUMBER,
                numberValue: Constants.EnhancedMessageNotificationsSetting.ALL
            },
            {
                key: Constants.UserSettingKey.FIRST_WEB_LOGIN_TIMESTAMP,
                dataType: Constants.UserSettingDataType.TIMESTAMP,
                numberValue: 0
            },
            {
                key: Constants.UserSettingKey.HIDE_PROFILE_EXTERNAL_ENABLED,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.KEYBOARD_SHORTCUTS_ENABLED,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.MOBILE_MESSAGE_NOTIFICATIONS_SETTING,
                dataType: Constants.UserSettingDataType.NUMBER,
                numberValue: Constants.MessageNotificationsSetting.ALL
            },
            {
                key: Constants.UserSettingKey.OPT_OUT_PRESENCE,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.PLAY_PICKUP_SOUND,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.PLAY_SOUND_MESSAGE_NOTIFICATIONS,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.PLAY_SOUND_RTC,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.PLAY_SYSTEM_SOUNDS,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.RELEASE_NOTES_WEB_VERSION,
                dataType: Constants.UserSettingDataType.NUMBER,
                numberValue: 0
            },
            {
                key: Constants.UserSettingKey.SECOND_TELEPHONY_CALL_ROUTING,
                dataType: Constants.UserSettingDataType.STRING,
                stringValue: BusyHandlingOptions.DefaultRouting.name
            },
            {
                key: Constants.UserSettingKey.SHARE_LOCATION,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: true
            },
            {
                key: Constants.UserSettingKey.STATUS_MESSAGE_TEXT,
                dataType: Constants.UserSettingDataType.STRING,
                stringValue: ''
            },
            {
                key: Constants.UserSettingKey.VOICEMAIL_ENABLED,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.VOICEMAIL_TIMEOUT,
                dataType: Constants.UserSettingDataType.NUMBER,
                numberValue: 20
            },
            {
                key: Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_ENABLED,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETING_URI,
                dataType: Constants.UserSettingDataType.STRING,
                stringValue: ''
            },
            {
                key: Constants.UserSettingKey.ANONYMOUS_RTC_PARTICIPANT,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.VOICEMAIL_NO_RECORDING,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.VOICEMAIL_CUSTOMGREETINGS,
                dataType: Constants.UserSettingDataType.STRING,
                stringValue: ''
            },
            {
                key: Constants.UserSettingKey.EMERGENCY_CALL_DISCLAIMER,
                dataType: Constants.UserSettingDataType.BOOLEAN,
                booleanValue: false
            },
            {
                key: Constants.UserSettingKey.MSTEAMS_APPLICATION_ID,
                dataType: Constants.UserSettingDataType.STRING,
                stringValue: ''
            }
        ];

        var CLIENT_PRESENCE_FIELDS = ['state', 'mobile', 'isSystemDefined'];

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _self = this;

        var _clientApiHandler = ClientApiHandler.getInstance();
        var _userSettingsLoadComplete = false;
        //var _isMobileAsleep = false; // true: indicates a mobile client in backgroup mode
        var _userSettings = DEFAULT_SETTINGS;
        var _userIdleState = IdleState.Active;
        var _defaultCallerId = null;
        var _tenantData;
        var _showIntegrations = Utils.isIntegrationsSupported();

        // Local client presence state
        var _clientPresenceState = {
            state: Constants.PresenceState.AVAILABLE,
            mobile: Utils.isMobile()
        };

        // Map to keep a common variable naming to be used, together with default values
        var _tenantDataDefaults = {};
        _tenantDataDefaults[Constants.TenantSettingsType.HELP_URL] = {name: 'helpUrl', defaultValue: Utils.DEFAULT_HELP_URL};
        _tenantDataDefaults[Constants.TenantSettingsType.CREDENTIALS_LOGIN_ENABLED] = {name: 'loginWithCredentialsEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.PLUGIN_SELF_MANAGED_ENABLED] = {name: 'pluginSelfManagedEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_MICROSOFT_EXCHANGE_ENABLED] = {name: 'msExchangeEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_MICROSOFT_SHAREPOINT_ENABLED] = {name: 'msSharepointEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_BOX_ENABLED] = {name: 'boxEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_GOOGLE_DRIVE_ENABLED] = {name: 'gDriveEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_ONE_DRIVE_ENABLED] = {name: 'oneDriveEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_JABRA_ENABLED] = {name: 'jabraEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_PLANTRONICS_ENABLED] = {name: 'plantronicsEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.READ_ONLY_MODE] = {name: 'readOnlyModeEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.DT_LEGAL_INFORMATION] = {name: 'hideLegalInformation', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.DA_AUTO_UPDATE] = {name: 'daAutoUpdate', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_SENNHEISER_ENABLED] = {name: 'sennheiserEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.MUTE_ON_ENTRY] = {name: 'muteOnEntry', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.MUTE_ON_ENTRY_CMR] = {name: 'muteOnEntryCMR', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.MUTE_ON_ENTRY_OUTCALL] = {name: 'muteOnEntryOutcall', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.REMOTE_CONTROL_ENABLED] = {name: 'remoteControlEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.REMOTE_CONTROL_EXTERNAL_ENABLED] = {name: 'remoteControlExternalEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.PLANTRONICS_HUB_DOWNLOAD_URL] = {
            name: 'plantronicsHubDownloadUrl',
            defaultValue: 'https://www.plantronics.com/us/en/support/downloads-apps/hub-desktop'
        };
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_JPL_ENABLED] = {name: 'jplEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.SCREENSHARE_DISABLED] = {name: 'screenshareDisabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.FILESHARE_DISABLED] = {name: 'fileshareDisabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.TRANSCRIPTION_ENABLED] = {name: 'transcriptionEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.USER_CONTROLLED_WEBRTC_POLICY_ENABLED] = {name: 'userControlledWebrtcPolicyEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.DATA_RETENTION_RECORDING_PERIOD] = {name: 'dataRetentionRecordingPeriod', defaultValue: 0};
        _tenantDataDefaults[Constants.TenantSettingsType.DATA_RETENTION_EXTENDED_RECORDING_PERIOD] = {name: 'dataRetentionExtendedRecordingPeriod', defaultValue: 0};
        _tenantDataDefaults[Constants.TenantSettingsType.GLOBAL_SPACE_CREATION_ENABLED] = {name: 'spaceCreationEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.ALLOW_EXTERNAL_SPACES] = {name: 'allowExternalSpacesEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.FAQ_URL] = {name: 'faqUrl', defaultValue: Utils.DEFAULT_FAQ_URL};
        _tenantDataDefaults[Constants.TenantSettingsType.ANONYMOUS_PARTICIPANTS_ENABLED] = {name: 'anonymousParticipantsEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EVENT_ORGANIZER_CONFIG] = {name: 'eventOrganizerConfig', defaultValue: null};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_LOGITECH_ENABLED] = {name: 'logitechEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.SPACE_STATISTIC_LINK_ENABLED] = {name: 'spaceStatisticsLinkEnabled', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_GIGASET_ION_ENABLED] = {name: 'gigasetIonEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_PLATHOSYS_ENABLED] = {name: 'plathosysEnabled', defaultValue: true};

        // Not supported at Sotel
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_EMBRAVA_ENABLED] = {name: 'embravaEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_KUANDO_ENABLED] = {name: 'kuandoEnabled', defaultValue: true};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_PLANTRONICS_STATUS_ENABLED] = {name: 'plantronicsStatusEnabled', defaultValue: true};

        // Integrations
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_C4G_ENABLED] = {name: 'circuitForGoogleEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_C4C_ENABLED] = {name: 'circuitForGoogleCalendar', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_ZAPIER_ENABLED] = {name: 'zapierEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EXTENSION_MEISTERTASK_ENABLED] = {name: 'meistertaskEnabled', defaultValue: false};
        _tenantDataDefaults[Constants.TenantSettingsType.EXCHANGE_ONLINE_CLIENT_ID] = {name: 'exchangeOnlineClientId', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.EXCHANGE_ONLINE_REDIRECT_URI] = {name: 'exchangeOnlineRedirectUri', defaultValue: ''};

        // IC Settings
        _tenantDataDefaults[Constants.TenantSettingsType.IC_SUPPORT_PHONE_NUMBER] = {name: 'expertConfigPhone', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_SUPPORT_EMAIL_ADDRESS] = {name: 'expertConfigEmail', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_SUPPORT_OPERATING_HOURS_FROM] = {name: 'expertConfigHourFrom', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_SUPPORT_OPERATING_HOURS_TO] = {name: 'expertConfigHourTo', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_SUPPORT_OPERATING_HOURS_TIMEZONE] = {name: 'expertConfigTimezone', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_USER_MANUAL_URL] = {name: 'expertConfigManualURL', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_SAFETY_NOTICE_URL] = {name: 'expertConfigSafetyURL', defaultValue: ''};
        _tenantDataDefaults[Constants.TenantSettingsType.IC_ADDITIONAL_TERMS_OF_SERVICE_URL] = {name: 'expertConfigAdditionalURL', defaultValue: ''};


        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setLocalUserPresence(data, cb) {
            LogSvc.debug('[UserProfileSvc]: setLocalUserPresence was called with state = ' + data.state);
            // First get the user presence state
            var userPresenceState = Utils.shallowCopy($rootScope.localUser.userPresenceState);
            delete _clientPresenceState.state;
            delete _clientPresenceState.isSystemDefined;
            data.hasOwnProperty('state') && (_clientPresenceState.state = data.state);
            data.hasOwnProperty('isSystemDefined') && (_clientPresenceState.isSystemDefined = data.isSystemDefined);

            CLIENT_PRESENCE_FIELDS.forEach(function (field) {
                userPresenceState[field] = _clientPresenceState[field];
            });

            _clientApiHandler.setPresence(userPresenceState, function (err, newState) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[UserProfileSvc]: Error setting presence: ', err);
                        cb && cb(err);
                        return;
                    }

                    UserProfile.updateUserPresenceState($rootScope.localUser, newState);
                    cb && cb(null, $rootScope.localUser.userPresenceState);

                    LogSvc.debug('[UserProfileSvc]: Raise a /localUser/update event');
                    PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                });
            });
        }

        function setUnifiedPresence(data, cb) {
            if (!$rootScope.localUser || !data.state) {
                LogSvc.warn('[UserProfileSvc]: setUnifiedPresence invoked without localUser or state being set');
                cb && cb();
                return;
            }
            LogSvc.debug('[UserProfileSvc]: setUnifiedPresence invoked with state = ', data.state || '<NONE>');

            setLocalUserPresence(data, cb);
        }

        function setStatusMessage(msg) {
            $rootScope.localUser.userPresenceState.statusMessage = msg;

            LogSvc.debug('[UserProfileSvc]: Publish /user/statusMessage/update');
            PubSubSvc.publish('/user/statusMessage/update');
        }

        function updateSetting(key, value, isEventNotification) {
            if (!key || value === undefined) {
                return null;
            }
            var setting = _userSettings.find(function (set) {
                return set.key === key;
            });
            if (setting) {
                var fieldName;
                switch (setting.dataType) {
                case Constants.UserSettingDataType.BOOLEAN:
                    fieldName = 'booleanValue';
                    break;
                case Constants.UserSettingDataType.NUMBER:
                    fieldName = 'numberValue';
                    break;
                case Constants.UserSettingDataType.STRING:
                    fieldName = 'stringValue';
                    break;
                case Constants.UserSettingDataType.TIMESTAMP:
                    fieldName = 'timestampValue';
                    break;
                default:
                    LogSvc.warn('[UserProfileSvc] Unknown dataType', setting.dataType);
                    break;
                }
                if (fieldName && setting[fieldName] !== value) {
                    if (setting.key === Constants.UserSettingKey.PLAY_SOUND_MESSAGE_NOTIFICATIONS && _self.isSystemNotificSoundSuported()) {
                        return null;
                    }
                    setting[fieldName] = value;
                    LogSvc.debug('[UserProfileSvc] Updated setting: ', setting);

                    if ($rootScope.localUser) {
                        switch (setting.key) {
                        case Constants.UserSettingKey.SHARE_LOCATION:
                            if (isEventNotification) {
                                _self.updateLocation();
                            }
                            break;
                        case Constants.UserSettingKey.STATUS_MESSAGE_TEXT:
                            setStatusMessage(value || '');
                            if (isEventNotification) {
                                LogSvc.debug('[UserProfileSvc]: Updated status message. Publish /localUser/update event');
                                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                            }
                            break;
                        case Constants.UserSettingKey.SECOND_TELEPHONY_CALL_ROUTING:
                            $rootScope.localUser.selectedBusyHandlingOption = value || BusyHandlingOptions.DefaultRouting.name;
                            if (isEventNotification) {
                                LogSvc.debug('[UserProfileSvc]: Updated busy handling option. Publish /localUser/update event');
                                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                            }
                            break;
                        case Constants.UserSettingKey.ATC_ROUTING:
                            $rootScope.localUser.selectedRoutingOption = value || RoutingOptions.DefaultRouting.name;
                            if (isEventNotification) {
                                LogSvc.debug('[UserProfileSvc]: Updated routing option. Publish /localUser/update event');
                                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                            }
                            break;
                        case Constants.UserSettingKey.KEYBOARD_SHORTCUTS_ENABLED:
                            $rootScope.localUser.keyboardShortcutsEnabled = value;
                            break;
                        }
                    }
                    return setting;
                }
            }
            return null;
        }

        function updateSettings(settings, isEventNotification) {
            settings && settings.forEach(function (setting) {
                var key = setting.key;
                var value;
                if (setting.dataType) {
                    switch (setting.dataType) {
                    case Constants.UserSettingDataType.BOOLEAN:
                        value = !!setting.booleanValue;
                        break;
                    case Constants.UserSettingDataType.NUMBER:
                        value = setting.numberValue;
                        break;
                    case Constants.UserSettingDataType.STRING:
                        value = setting.stringValue;
                        break;
                    case Constants.UserSettingDataType.TIMESTAMP:
                        value = setting.timestampValue;
                        break;
                    default:
                        LogSvc.warn('[UserProfileSvc] Unknown Data Type. Setting is not updated. Data Type: ', setting.dataType);
                        return;
                    }
                    updateSetting(key, value, isEventNotification);
                }
            });
        }

        function setReroutingPhoneNumber(reroutingPhoneNumber, cb) {
            var account = $rootScope.localUser && $rootScope.localUser.accounts && $rootScope.localUser.accounts[0];
            if (!account) {
                LogSvc.error('[UserProfileSvc]: setReroutingPhoneNumber - There is no local user account.');
                $rootScope.localUser.reroutingPhoneNumber = '';
                cb && cb(Constants.ReturnCode.SERVICE_EXCEPTION);
                return;
            }
            reroutingPhoneNumber = PhoneNumberFormatter.format(reroutingPhoneNumber);

            account.telephonyConfiguration = account.telephonyConfiguration || {};
            var oldNumber = account.telephonyConfiguration.reroutingPhoneNumber;
            account.telephonyConfiguration.reroutingPhoneNumber = reroutingPhoneNumber;
            $rootScope.localUser.alternativeNumber = $rootScope.localUser.reroutingPhoneNumber = reroutingPhoneNumber;

            _clientApiHandler.assignTelephonyConfiguration(account.accountId, account.telephonyConfiguration, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserProfileSvc]: Error updating reroutingPhoneNumber: ', err);
                        // Revert changes
                        account.telephonyConfiguration.reroutingPhoneNumber = oldNumber;
                        $rootScope.localUser.alternativeNumber = $rootScope.localUser.reroutingPhoneNumber = oldNumber;
                        cb && cb(err);
                    } else {
                        if ($rootScope.localUser.selectedRoutingOption === RoutingOptions.AlternativeNumber.name) {
                            // In case of ATC, change after re-registration
                            if ($rootScope.localUser.isOsBizCTIEnabled && !$rootScope.localUser.reroutingPhoneNumber) {
                                $rootScope.localUser.selectedRoutingOption = RoutingOptions.DefaultRouting.name;
                                _self.saveSetting(Constants.UserSettingKey.ATC_ROUTING, $rootScope.localUser.selectedRoutingOption);
                            }
                            LogSvc.debug('[UserProfileSvc]: Publish /routing/alternativeNumber/changed event');
                            PubSubSvc.publish('/routing/alternativeNumber/changed');
                        }

                        // NGTC-5478 - After change in the alternative number we need to trigger a telephonyConfigUpdated
                        // event in order to re-register the client in PBX
                        LogSvc.debug('[UserProfileSvc]: Publish /localUser/telephonyConfigUpdated event');
                        PubSubSvc.publish('/localUser/telephonyConfigUpdated');
                        cb && cb(null, $rootScope.localUser);
                    }
                });
            });
        }

        function setLanguage(locale, cb) {
            $rootScope.localUser.locale = LocalizeSvc.setLanguage(locale);
            var data = {
                userId: $rootScope.localUser.userId,
                locale: Utils.normalizeLocaleProto(locale)
            };

            _clientApiHandler.updateUser(data, function (err, userData) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserProfileSvc]: Error updating user locale: ', err);
                        cb && cb(err);
                        return;
                    }
                    cb && cb(null, userData);
                });
            });
        }

        // Set phoneNumber, callerId and cstaNumber for localUser based on assigned phone numbers.
        // Returns true if localUser.callerId has been changed
        function updateTelephonyNumbers() {
            var user = $rootScope.localUser;
            var currentCallerId = user.callerId;

            var account = user.accounts && user.accounts[0];
            var telConfig = account && account.telephonyConfiguration;
            if (telConfig) {
                user.phoneNumber = telConfig.phoneNumber || null;
                if (user.isATC) {
                    // User is assigned to an ATC
                    user.callerId = telConfig.phoneNumber;
                    //user.cstaNumber = Utils.cleanPhoneNumber(telConfig.phoneNumber);
                    user.cstaNumber = telConfig.phoneNumber;
                    user.registerNumber = user.cstaNumber;
                    user.isRegisterTC = true;

                    // Initialize telephony settings fields
                    user.reroutingPhoneNumber = user.reroutingPhoneNumber || '';
                    user.selectedBusyHandlingOption = user.selectedBusyHandlingOption || BusyHandlingOptions.DefaultRouting.name;

                } else if (user.isSTC) {
                    // User is assigned to a STC
                    user.callerId = telConfig.phoneNumber;
                    user.registerNumber = Utils.cleanPhoneNumber(telConfig.phoneNumber);
                    user.cstaNumber = null;
                    user.isRegisterTC = true;
                } else {
                    // User is assigned to GTC/ETC
                    user.callerId = telConfig.callerId || telConfig.phoneNumber || _defaultCallerId;
                    user.cstaNumber = null;
                    user.registerNumber = null;
                    user.isRegisterTC = false;
                }
            } else {
                user.phoneNumber = null;
                user.callerId = _defaultCallerId;
                user.cstaNumber = null;
                user.registerNumber = null;
                user.isRegisterTC = false;
            }

            if (user.isOsBizCTIEnabled) {
                RoutingOptions.DefaultRouting.desc = 'res_DefaultRoutingDescriptionOSBiz';
                RoutingOptions.DeskPhone.desc = 'res_DeskphoneRoutingDescriptionOSBiz';
                RoutingOptions.AlternativeNumber.desc = 'res_AlternativeNumberRoutingDescriptionOSBiz';
            } else {
                RoutingOptions.DefaultRouting.desc = 'res_DefaultRoutingDescription';
                RoutingOptions.DeskPhone.desc = 'res_DeskphoneRoutingDescription';
                RoutingOptions.AlternativeNumber.desc = 'res_AlternativeNumberRoutingDescription';
            }

            return currentCallerId !== user.callerId;
        }

        function updateSecondCallRouting() {
            var setting = _self.getSetting(Constants.UserSettingKey.SECOND_TELEPHONY_CALL_ROUTING);
            $rootScope.localUser.selectedBusyHandlingOption = setting.stringValue;
        }

        function updateATCRouting() {
            var setting = _self.getSetting(Constants.UserSettingKey.ATC_ROUTING);
            $rootScope.localUser.selectedRoutingOption = setting.stringValue;
        }

        // Populates a given object with tenant variables, initialized with default values and then populated from tenant data
        function getTenantVariables(settings) {
            var obj = {};
            Object.keys(_tenantDataDefaults).forEach(function (key) {
                var attrs = _tenantDataDefaults[key];
                obj[attrs.name] = attrs.defaultValue;
            });

            settings.forEach(function (setting) {
                if (!setting.value) {
                    return;
                }
                var attrs = _tenantDataDefaults[setting.key];
                var attr = attrs && attrs.name;
                if (attr) {
                    if (typeof attrs.defaultValue === 'boolean') {
                        if (setting.value === 'true') {
                            obj[attr] = true;
                        } else if (setting.value === 'false') {
                            obj[attr] = false;
                        }
                    } else if (typeof attrs.defaultValue === 'number') {
                        obj[attr] = parseInt(setting.value, 10);
                    } else {
                        obj[attr] = setting.value;
                    }
                }
            });

            // Hardcoded variables for different Systems. Yes, this is as confusing as it gets....
            // To be removed once we can configure which extensions are available per system (ANS-68463)

            // Hide integrations if not on a valid domain
            if (!_showIntegrations) {
                obj.zapierEnabled = false;
                obj.meistertaskEnabled = false;
                obj.circuitForGoogleEnabled = false;
                obj.circuitForGoogleCalendar = false;
            }

            return obj;
        }

        function updateTenantSettings(settings) {
            var tenantVariables = getTenantVariables(settings);
            if ($rootScope.localUser) {
                // Update user object
                Object.keys(tenantVariables).forEach(function (attr) {
                    var config;
                    var value = tenantVariables[attr];
                    if (attr === 'daAutoUpdate') {
                        // Save tenant auto update setting to have access from login app.
                        LocalStoreSvc.setString(LocalStoreSvc.keys.DA_AUTO_UPDATE, value.toString());
                    }
                    if (attr === 'eventOrganizerConfig') {
                        try {
                            config = value && JSON.parse(value);
                            if (Array.isArray(config)) {
                                $rootScope.localUser.eventOrganizerConfig = config;
                                $rootScope.localUser.eventOrganizerConfigMap = config.reduce(function (map, obj) {
                                    obj.permission = 'EVENT_ORGANIZER_' + obj.type.replace('EVENT_', '');
                                    map[obj.type] = obj;
                                    return map;
                                }, {});
                            }
                        } catch (e) {
                            LogSvc.error('[UserProfileSvc]: Error parsing eventOrganizerConfig. ', e);
                        }
                    } else {
                        $rootScope.localUser[attr] = value;
                    }
                });

                $rootScope.localUser.recordingDeletionEnabledTenant = tenantVariables.dataRetentionRecordingPeriod > 0 &&
                    tenantVariables.dataRetentionExtendedRecordingPeriod > 0;

                if (_userSettingsLoadComplete) {
                    LogSvc.debug('[UserProfileSvc]: Publish /localUser/tenantSettingsUpdate event');
                    PubSubSvc.publish('/localUser/tenantSettingsUpdate', [$rootScope.localUser]);
                }
            }
            return tenantVariables;
        }

        function setTenantData(tenant) {
            if (!tenant) {
                return null;
            }

            _tenantData = tenant;
            _tenantData.settings = _tenantData.settings || [];
            return updateTenantSettings(_tenantData.settings);
        }

        function getOldSettings(keys) {
            return _userSettings.filter(function (setting) {
                return keys.includes(setting.key);
            }).map(function (setting) {
                return Utils.shallowCopy(setting);
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Client API Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _clientApiHandler.on('User.USER_SETTING_UPDATED', function (data) {
            LogSvc.debug('[UserProfileSvc]: Received User.USER_SETTING_UPDATED event');
            if (!data.userId || !$rootScope.localUser) {
                LogSvc.error('[UserProfileSvc]: User.USER_SETTING_UPDATED event does not have a user');
                return;
            }

            if (data.userId !== $rootScope.localUser.userId) {
                LogSvc.error('[UserProfileSvc]: User.USER_SETTING_UPDATED event not for local user');
                return;
            }

            if (!data.settings) {
                LogSvc.error('[UserProfileSvc]: User.USER_SETTING_UPDATED event with no settings');
                return;
            }

            $rootScope.$apply(function () {
                updateSettings(data.settings, true);

                LogSvc.debug('[UserProfileSvc]: Publish /user/settings/update event');
                PubSubSvc.publish('/user/settings/update', [_userSettings]);
            });
        });

        _clientApiHandler.on('User.USER_PRESENCE_CHANGE', function (data) {
            if (!$rootScope.localUser || data.userId !== $rootScope.localUser.userId) {
                // Event is not for local user. This is handled by UserSvc,
                return;
            }

            LogSvc.debug('[UserProfileSvc]: Received User.USER_PRESENCE_CHANGE event');

            var newState = data.newState;
            var isSystemDefined = data.isSystemDefined;

            if (!UserProfile.updateUserPresenceState($rootScope.localUser, newState, isSystemDefined)) {
                LogSvc.debug('[UserProfileSvc]: State has not changed. Ignore event.');
                LogSvc.debug('[UserProfileSvc]: Publish /localUser/presence/checkIdle.');
                PubSubSvc.publish('/localUser/presence/checkIdle', [$rootScope.localUser.userPresenceState]);
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[UserProfileSvc]: Received new state information. ', newState);
                LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                LogSvc.debug('[UserProfileSvc]: Publish /localUser/presence/checkIdle.');
                PubSubSvc.publish('/localUser/presence/checkIdle', [$rootScope.localUser.userPresenceState]);
            });
        });

        _clientApiHandler.on('User.USER_UPDATED', function (data) {
            if (!data.user || !$rootScope.localUser) {
                return;
            }

            if (data.user.userId === $rootScope.localUser.userId) {
                LogSvc.debug('[UserProfileSvc]: Received User.USER_UPDATED event');
                $rootScope.$apply(function () {
                    // Do NOT update the local UserPresenceState
                    data.user.userPresenceState = $rootScope.localUser.userPresenceState;
                    UserProfile.update($rootScope.localUser, data.user);
                    if (data.user.locale) {
                        var newLocale = Utils.normalizeLocale(data.user.locale);
                        $rootScope.localUser.locale = LocalizeSvc.setLanguage(newLocale);
                    }
                    updateTelephonyNumbers();
                    LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                    PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                });
            }
        });

        _clientApiHandler.on('Account.TELEPHONY_CONFIGURATION_UPDATED', function (data) {
            if (!data.configuration || !$rootScope.localUser) {
                return;
            }
            var account = $rootScope.localUser.accounts && $rootScope.localUser.accounts[0];
            if (!account) {
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[UserProfileSvc]: Received Account.TELEPHONY_CONFIGURATION_UPDATED event');
                account.telephonyConfiguration = data.configuration;
                UserProfile.syncTelephonyConfig($rootScope.localUser);
                updateTelephonyNumbers();

                LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);

                LogSvc.debug('[UserProfileSvc]: Publish /localUser/telephonyConfigUpdated event');
                PubSubSvc.publish('/localUser/telephonyConfigUpdated');
            });
        });

        _clientApiHandler.on('Account.TELEPHONY_PREVIOUS_ALTERNATIVE_NUMBERS_UPDATED', function (data) {
            if (!data.previousAlternativeNumbers || !$rootScope.localUser) {
                return;
            }
            var account = $rootScope.localUser.accounts && $rootScope.localUser.accounts[0];
            if (!account) {
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[UserProfileSvc]: Received Account.TELEPHONY_PREVIOUS_ALTERNATIVE_NUMBERS_UPDATED event');
                UserProfile.updateAlternativeNumbers($rootScope.localUser, data);

                LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
            });
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Subscribes to telephony data changes
         */
        PubSubSvc.subscribe('/telephony/data', function (data, telephonyConv) {
            LogSvc.debug('[UserProfileSvc]: Received /telephony/data event');
            var userUpdated = false;
            // If the defaultCallerID changed, then re-evaluate the user's caller ID
            if (_defaultCallerId !== data.defaultCallerId) {
                _defaultCallerId = data.defaultCallerId;
                userUpdated = updateTelephonyNumbers();
            }
            if ($rootScope.localUser.telephonyAvailable !== data.telephonyAvailableForUser) {
                $rootScope.localUser.telephonyAvailable = data.telephonyAvailableForUser;
                LogSvc.debug('[UserProfileSvc]: Publish /conversations/showTelephonyConversation event');
                PubSubSvc.publish('/conversations/showTelephonyConversation', [$rootScope.localUser.telephonyAvailable, telephonyConv]);
                userUpdated = true;
            }
            if ($rootScope.localUser.conferenceDialOutAvailable !== data.conferenceDialOutAvailable) {
                $rootScope.localUser.conferenceDialOutAvailable = data.conferenceDialOutAvailable;
                userUpdated = true;
            }
            if (userUpdated) {
                LogSvc.debug('[UserProfileSvc]: Telephony data has been updated. Publish /localUser/update event');
                PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
            }
        });

        /**
         * Subscribes to the temporary and permanent users presence, but the permanent
         * subscription list is not cleared so that we can subscribe to it on wakeUp.
         */
        PubSubSvc.subscribe('/internal/svc/go2Sleep', function () {
            LogSvc.debug('[UserProfileSvc]: Go to Sleep');
            //_isMobileAsleep = true;
        });

        /**
         * Subscribes to the permanent users presence.
         * The mobile client is responsible to call subscribeTemporaryPresence to
         * subscribe to the temporary users again when selecting a conversation.
         */
        PubSubSvc.subscribe('/internal/svc/wakeUp', function () {
            LogSvc.debug('[UserProfileSvc]: Wake up');
            //_isMobileAsleep = false;
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.initSettings = function (settings) {
            LogSvc.debug('[UserProfileSvc]: Initialize user settings');
            updateSettings(settings);
        };

        this.setLocalUser = function (user, tenant, supportInfo) {
            if (!user) {
                return;
            }
            LogSvc.debug('[UserProfileSvc]: Init localUser');

            var raiseInit = true;
            if ($rootScope.localUser) {
                // We already initialized the localUser. Need to update it instead.
                UserProfile.update($rootScope.localUser, user);
                UserProfile.updateUserPresenceState($rootScope.localUser, user.userPresenceState);
                user = $rootScope.localUser;
                raiseInit = false;
            } else {
                // Make sure the received user object has been extended
                user = UserProfile.extend(user);
                // Initialize call forwarding data on local user object
                user.cfData = {};
                Object.keys(CallForwardingTypes).forEach(function (key) {
                    user.cfData[key] = {
                        cfwType: key,
                        cfwAvailable: false
                    };
                });

                $rootScope.localUser = user;
                Conversation.localUser = user;
                UserProfile.localUser = user;
            }

            // Initialize localUser's callerId and second call routing (i.e. busy handling) setting
            updateTelephonyNumbers();
            updateSecondCallRouting();
            updateATCRouting();

            // Initialize support on localUser
            supportInfo = supportInfo || {};
            user.supportEmailAddress = supportInfo.supportEmailAddress || null;
            if (!supportInfo.supportEmailAddress) {
                user.supportType = Constants.SupportType.CONVERSATION;
            } else {
                user.supportType = supportInfo.supportType || Constants.SupportType.CONVERSATION;
            }

            if (raiseInit) {
                setTenantData(tenant);

                var shortcutsSetting = _self.getSetting(Constants.UserSettingKey.KEYBOARD_SHORTCUTS_ENABLED);
                user.keyboardShortcutsEnabled = !!(shortcutsSetting && shortcutsSetting.booleanValue);

                _userSettingsLoadComplete = true;

                // Do not log full user object because it has location data - security and privacy
                LogSvc.debug('[UserProfileSvc]: Publish /localUser/init event. User = ', user.userId);
                PubSubSvc.publish('/localUser/init', [user]);
            } else {
                tenant && setTenantData(tenant);

                LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                PubSubSvc.publish('/localUser/update', [user]);
            }

            // The following code must be done after /localUser/init has been published to ensure that Lab features have been initialized.
            // Finally, set the client language using the locale configured for the user.
            LocalizeSvc.setLanguageAsync(user.locale)
            .then(function (language) {
                if ($rootScope.localUser.locale !== language) {
                    // User never selected preference language. Store browser's language, if supported,
                    // in the user's profile.
                    LogSvc.info('[UserProfileSvc] Set user\'s language using browser\'s language: ', language);
                    $rootScope.localUser.locale = language;
                    setLanguage($rootScope.localUser.locale);
                }
            });
        };

        /**
         * Verifies if the logged on user has the specified permission.
         * @returns {boolean} Returns true if the user has the specified permission. Otherwise, returns false.
         */
        this.hasPermission = function (permission) {
            return !!$rootScope.localUser && $rootScope.localUser.hasPermission(permission);
        };

        /**
         * Verifies if the logged on user can invite other users.
         * @returns {boolean} Returns true if the user is allowed to invite other users.
         */
        this.canInviteUsers = function () {
            return !!($rootScope.localUser &&
                $rootScope.localUser.loginWithCredentialsEnabled &&
                $rootScope.localUser.hasPermission(Constants.SystemPermission.INVITE_USER));
        };

        /**
         * Get a promised that is resolved when the user settings are loaded.
         * @returns {object} Promise object.
         */
        this.getUserSettingsPromise = function () {
            var deferred = $q.defer();
            if (_userSettingsLoadComplete) {
                $timeout(function () { deferred.resolve(_userSettings); });
            } else {
                PubSubSvc.subscribeOnce('/localUser/init', function () {
                    deferred.resolve(_userSettings);
                });
            }
            return deferred.promise;
        };

        /**
         * Get a promised that is resolved when the localUser is loaded.
         * @returns {object} Promise object.
         */
        this.getLocalUserPromise = function () {
            var deferred = $q.defer();
            if (_userSettingsLoadComplete) {
                $timeout(function () { deferred.resolve($rootScope.localUser); });
            } else {
                PubSubSvc.subscribeOnce('/localUser/init', function () {
                    deferred.resolve($rootScope.localUser);
                });
            }
            return deferred.promise;
        };

        this.getLocalUser = function () {
            return $rootScope.localUser;
        };

        this.getSettings = function () {
            return _userSettings;
        };

        this.getSetting = function (key) {
            return _userSettings.find(function (setting) {
                return setting.key === key;
            });
        };

        this.getSettingPromise = function (key) {
            if (!key) {
                return $q.reject('No key');
            }

            var deferred = $q.defer();
            if (_userSettingsLoadComplete) {
                deferred.resolve(this.getSetting(key));
            } else {
                var that = this;
                PubSubSvc.subscribeOnce('/localUser/init', function () {
                    deferred.resolve(that.getSetting(key));
                });
            }
            return deferred.promise;
        };

        this.saveSettings = function (settings, cb) {
            if (!$rootScope.localUser) {
                cb && cb('No local user');
                return;
            }
            var keys = settings.map(function (setting) { return setting.key; });
            var oldSettings = getOldSettings(keys);

            updateSettings(settings);
            _clientApiHandler.setUserSettings(settings, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[UserProfileSvc] Error updating user settings on server. ', err);
                        // Restore old setting
                        LogSvc.debug('[UserProfileSvc] Restore old settings');
                        updateSettings(oldSettings);
                    }
                    cb && cb(err);
                });
            });
        };

        this.saveSetting = function (key, value, cb) {
            var oldSettings = getOldSettings([key]);
            var setting = updateSetting(key, value);
            if (setting) {
                _clientApiHandler.setUserSettings(setting, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[UserProfileSvc] Error updating user setting on server. ', err);
                            // Restore old setting
                            LogSvc.debug('[UserProfileSvc] Restore old setting');
                            updateSettings(oldSettings);
                        }
                        cb && cb(err);
                    });
                });
            } else {
                cb && cb();
            }
        };

        this.getPresenceStatus = function () {};

        this.changePassword = function (oldPassword, newPassword, cb) {
            _clientApiHandler.changePassword(oldPassword, newPassword, function (err, changeResult) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserProfileSvc]: The change password request was not successful');
                        cb && cb(err);
                        return;
                    }
                    cb && cb(changeResult);
                });
            });
        };

        this.setIdleState = function (idleState) {
            if (!$rootScope.localUser) {
                return false;
            }
            _userIdleState = idleState;
            var presenceChanged = false;
            if ($rootScope.localUser.isStandalone) {
                var localUserPresenceState = $rootScope.localUser.userPresenceState.state;
                if (localUserPresenceState === Constants.PresenceState.AVAILABLE && idleState === IdleState.Idle) {
                    setUnifiedPresence({ state: Constants.PresenceState.AWAY, isSystemDefined: true });
                    presenceChanged = true;
                } else if (localUserPresenceState === Constants.PresenceState.AWAY && idleState === IdleState.Active) {
                    // We need isSystemDefined flag, to differentiate the action of mouse event from the dropdown action
                    setUnifiedPresence({ state: Constants.PresenceState.AVAILABLE, isSystemDefined: true });
                    presenceChanged = true;
                }
                if (presenceChanged) {
                    LogSvc.debug('[UserProfileSvc]: Publish /internal/svc/idle/state event: ', idleState);
                    PubSubSvc.publish('/internal/svc/idle/state', [idleState]);
                    return true;
                }
            }
            return false;
        };

        this.removeAlternativeNumber = function (number, cb) {
            _clientApiHandler.removeAlternativeNumberHandler(number, function (err) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.warn('[UserProfileSvc]: Error removing alternative number: ', err);
                        cb && cb(err);
                    }
                });
            });
        };

        this.getExchangeSettings = function () {
            LogSvc.debug('[UserProfileSvc]: getExchangeSettings...');
            var deferred = $q.defer();
            _clientApiHandler.getExchangeSettings(function (err, settings) {
                if (err) {
                    LogSvc.warn('[UserProfileSvc]: Unable to get settings from server. Error: ', err);
                    deferred.reject(err);
                    return;
                }
                if (!settings) {
                    LogSvc.debug('[UserProfileSvc]: No settings available for this user');
                }
                deferred.resolve(settings);
            });
            return deferred.promise;
        };

        /**
         * Save Exchange settings
         * @param {Object} settings - Required object containing Exchange settings:
         * - email: email associated with Exchange
         * - password: Exchange password
         * - server: Required, exchange server address
         * - domain: Optional, domain name
         * - version - Required, exchange version
         * @returns {Promise<Object} Promise
         */
        this.saveExchangeSettings = function (settings) {
            LogSvc.debug('[UserProfileSvc]: saveExchangeSettings...');

            var deferred = $q.defer();

            // Create a shallow copy so we don't modify the given object.
            settings = Utils.shallowCopy(settings);
            settings.username = settings.username || $rootScope.localUser.emailAddress;

            // Save settings on back end
            var request = {
                username: settings.username,
                password: settings.password,
                server: settings.server,
                domain: settings.domain || '',
                version: settings.version || ''
            };

            _clientApiHandler.saveExchangeSettings(request, function (err, data) {
                if (err || !data) {
                    LogSvc.warn('[UserProfileSvc]: Unable to save Exchange settings in the server. Error: ', err);
                    deferred.reject(ExchangeConnError.INTERNAL_ERROR);
                    return;
                }
                settings.encryptionKey = data.encryptionKey;
                deferred.resolve(settings);
            });
            return deferred.promise;
        };
        /////////////////////////////////////////////////////////////////////////////
        // FRN8499 - Email Change
        /////////////////////////////////////////////////////////////////////////////
        this.requestEmailChange = function (newEmail, cb) {
            // Check if user with requested email already exists
            _clientApiHandler.getUserByEmail(newEmail, function (getUserErr, user) {
                if (getUserErr || !user) {
                    if (getUserErr === Constants.ReturnCode.STORAGE_OVERLOADED) {
                        cb && cb(getUserErr);
                    } else {
                        _clientApiHandler.requestEmailChange($rootScope.localUser.userId, newEmail, function (err) {
                            if (err) {
                                LogSvc.error('[UserProfileSvc]: The change email request was not successful');
                            }
                            cb && cb(err);
                        });
                    }
                    return;
                }
                cb && cb('EMAIL_ALREADY_EXISTS');
            });

        };

        this.updateUserProfile = function (userProfile, cb) {
            var data = {
                userId: $rootScope.localUser.userId,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                largeImageUri: userProfile.largeImageUri,
                smallImageUri: userProfile.smallImageUri,
                phoneNumbers: userProfile.phoneNumbers,
                emailAddresses: userProfile.emailAddresses,
                jobTitle: userProfile.jobTitle,
                company: userProfile.company,
                reroutingPhoneNumber: userProfile.reroutingPhoneNumber
            };
            _clientApiHandler.updateUser(data, function (err, userData) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserProfileSvc]: Error updating user data: ', err);
                        cb && cb(err);
                        return;
                    }

                    LogSvc.debug('[UserProfileSvc]: User profile was successfully updated');
                    // Do NOT update the local UserPresenceState
                    userData.userPresenceState = $rootScope.localUser.userPresenceState;
                    UserProfile.update($rootScope.localUser, userData);
                    updateTelephonyNumbers();

                    cb && cb(null, $rootScope.localUser);

                    LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                    PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                });
            });
        };

        /**
         * This function starts notifications manual snooze.
         *
         * @function
         * @param {int} value : 0 = Today, >1 = hours of snoozing.
         */
        this.snooze = function (value) {
            LogSvc.debug('[UserProfileSvc]: Snooze with value =', value);
            // var snoozeStart = !_manualSnoozeOn;

            // _manualSnoozeOn = true;
            // snoozeNotifications(value);

            // if (snoozeStart) {
            //     // FRN8574: Publish event to play sound
            //     LogSvc.debug('[UserProfileSvc]: Publish /user/snooze/start event');
            //     PubSubSvc.publish('/user/snooze/start');
            // }
        };

        /**
         * This function resumes notifications, including auto-snoozed notifications.
         */
        this.resume = function () {
            LogSvc.debug('[UserProfileSvc]: Resume snooze');
            //resumeNotifications();
        };

        /**
         * This function starts the auto-snooze when a feature requires notifications to be blocked
         * while the feature is active (e.g. Screenshare). This function is supposed to be invoked
         * as soon as the feature started.
         */
        this.startAutoSnooze = function () {
            LogSvc.debug('[UserProfileSvc]: Start Auto-Snooze');
            // _autoSnoozeOn = true;

            // if (_manualSnoozeOn) {
            //     // There is a snooze running.
            //     LogSvc.debug('[UserProfileSvc]: User already started snooze. Do not change dndUntil.');
            //     // Reset the local timer to take in consideration that auto-snooze is on
            //     startSnoozeTimer();
            //     return;
            // }

            // // Start auto-snooze. The client will keep refreshing the time as needed.
            // snoozeNotifications(AUTO_SNOOZE_TIME);

            // LogSvc.debug('[UserProfileSvc]: Publish /user/autoSnooze/start event');
            // PubSubSvc.publish('/user/autoSnooze/start');
        };

        /**
         * This function cancels an auto-snooze, but does not affect a manual snooze
         * that may be running.
         *
         * @function
         */
        this.cancelAutoSnooze = function () {
            LogSvc.debug('[UserProfileSvc]: Cancel Auto-Snooze');
            // if (!_autoSnoozeOn) {
            //     LogSvc.debug('[UserProfileSvc]: Auto-Snooze was not set');
            //     return;
            // }

            // if (_manualSnoozeOn) {
            //     // Manual snooze still running
            //     LogSvc.debug('[UserProfileSvc]: Cancel auto-snooze but do not resume notifications');
            //     _autoSnoozeOn = false;
            //     // Reset the local timer to take in consideration that auto-snooze is off
            //     startSnoozeTimer();
            //     return;
            // }
            // // Resume notifications immediately
            // resumeNotifications();
        };

        /**
         * This function returns true if unlimited snooze is set
         */
        this.isUnlimitedSnoozeSet = function () {
            //return $rootScope.localUser.userPresenceState.dndUntil === MAX_DATE;
            return false;
        };

        this.setPresenceWithLocation = function (state, cb) {
            if (state === _clientPresenceState.state) {
                // No changes
                cb && cb(null, $rootScope.localUser.userPresenceState);
                //return;
            }
            //setPresenceWithLocation(state, cb);
        };

        this.setUnifiedPresence = function (data, cb) {
            setUnifiedPresence(data, cb);
        };

        this.setUnifiedPresenceV2 = function (data, cb) {
            if (!data) {
                cb && cb();
                return;
            }
            setUnifiedPresence(data, cb);
        };

        /**
         * Saves the status message of the user as a setting and sets the user presence.
         * @param {String} msg - The new status msg
         * @param {Boolean} raiseSetPresence - Flag indicating whether or not to also raise a SET_PRESENCE request
         */
        this.setStatusMessage = function (msg, raiseSetPresence, cb) {
            if (msg) {
                LogSvc.debug('[UserProfileSvc]: Set status message');
            } else {
                LogSvc.debug('[UserProfileSvc]: Clear status message');
            }

            var oldMsg = $rootScope.localUser.userPresenceState.statusMessage;
            var newMsg = msg || '';
            setStatusMessage(newMsg);

            // Persist the status message
            _self.saveSetting(Constants.UserSettingKey.STATUS_MESSAGE_TEXT, newMsg, function (err) {
                if (err) {
                    LogSvc.error('[UserProfileSvc]: Error saving status message. ', err);
                    setStatusMessage(oldMsg);
                    cb && cb(err);
                    return;
                }

                LogSvc.debug('[UserProfileSvc]: Successfully saved the status message');
                if (!raiseSetPresence) {
                    cb && cb(null, $rootScope.localUser.userPresenceState);
                    LogSvc.debug('[UserProfileSvc]: Publish /localUser/update event');
                    PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
                    return;
                }

                // Now that message is saved, update the user presence with the new message
                setLocalUserPresence(null, cb);
            });
        };

        this.updateLocation = function () {
            // var shareLocation = isLocationShared();
            // if (shareLocation) {
            //     LogSvc.debug('[UserProfileSvc]: User is sharing location. Retrieve location data and update user presence.');
            //     setPresenceWithLocation(null);
            // } else if (hasLocation()) {
            //     LogSvc.debug('[UserProfileSvc]: User is not sharing location. Remove location data from local user presence.');
            //     // Remove location data and timeZoneOffset
            //     clearLocation(_clientPresenceState, true);
            //     clearLocation($rootScope.localUser.userPresenceState, true);
            //     PubSubSvc.publish('/localUser/update', [$rootScope.localUser]);
            // }
        };

        this.setLanguage = setLanguage;

        this.setReroutingPhoneNumber = setReroutingPhoneNumber;

        this.getUserLocale = function (languageOnly) {
            var locale = ($rootScope.localUser && $rootScope.localUser.locale) || 'en-US';
            if (languageOnly) {
                locale = locale.substr(0, 2);
            }
            return locale;
        };

        this.getUserIdleState = function () {
            return _userIdleState;
        };

        this.getTenantData = function () {
            return _tenantData;
        };

        this.getTenantDataDefaults = function () {
            return _tenantDataDefaults;
        };

        this.refreshTenantData = function (tenant) {
            return setTenantData(tenant);
        };

        this.updateTenantSettings = function (cb, tenantContext) {
            _clientApiHandler.getUserTenantSettings(function (err, settings) {
                if (err) {
                    LogSvc.error('[UserProfileSvc]: Failed to get tenant settins. ', err);
                    cb && cb(err);
                } else {
                    var tenantVariables = updateTenantSettings(settings);
                    LogSvc.debug('[UserProfileSvc]: Updated tenant settings: ', tenantVariables);
                    cb && cb(null, tenantVariables);
                }
            }, tenantContext);
        };

        this.parseTenantSettings = function (tenant) {
            var settings = (tenant && tenant.settings) || [];
            return getTenantVariables(settings);
        };

        this.resetOpenScapeDevicePins = function (directoryNumber) {
            var deferred = $q.defer();

            LogSvc.debug('[UserProfileSvc]: Reset OpenScape device PINs for ', directoryNumber);
            _clientApiHandler.resetOpenScapeDevicePins($rootScope.localUser.userId, directoryNumber, function (err, devicePins) {
                $rootScope.$apply(function () {
                    if (err) {
                        LogSvc.error('[UserProfileSvc]: Error resetting OpenScape device PINs: ', err);
                        deferred.reject(err);
                    } else {
                        deferred.resolve(devicePins);
                    }
                });
            });

            return deferred.promise;
        };

        this.isSystemNotificSoundSuported = function () {
            var isWin10 = Utils.isWindowsOs && Utils.getOSInfo(window.navigator.userAgent).version === '10';
            return (isWin10 && $rootScope.browser.chrome) || (Utils.isMacOs && $rootScope.browser.firefox);
        };

        this.isUserSettingSupported = function (/*key*/) {
            return true;
        };

        this.updateTelephonyNumbers = updateTelephonyNumbers;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.UserProfileSvcImpl = UserProfileSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
