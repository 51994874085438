var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var ChromeExtension = circuit.ChromeExtension;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;
    var Utils = circuit.Utils;


    ///////////////////////////////////////////////////////////////////////////////////////
    // Constants
    ///////////////////////////////////////////////////////////////////////////////////////
    var ExchangeUserToUserMessage = Object.freeze({
        EXCHANGE: 'EXCHANGE',
        CONNECT: 'EXCHANGE_CONNECT',
        DISCONNECT: 'EXCHANGE_DISCONNECT',
        SEARCH_CONTACTS: 'EXCHANGE_SEARCH_CONTACTS',
        GET_ALL_PERSONAL_CONTACTS: 'EXCHANGE_GET_ALL_PERSONAL_CONTACTS',
        GET_APPOINTMENTS: 'EXCHANGE_GET_APPOINTMENTS',
        GET_CAPABILITIES: 'EXCHANGE_GET_CAPABILITIES',
        GET_CONNECTION_STATUS: 'EXCHANGE_GET_CONNECTION_STATUS',
        GET_CONTACT: 'EXCHANGE_GET_CONTACT',
        RESOLVE_CONTACT: 'EXCHANGE_RESOLVE_CONTACT',
        STORE_CREDENTIALS: 'EXCHANGE_STORE_CREDENTIALS',
        SYNC_ALL_PERSONAL_CONTACTS: 'EXCHANGE_SYNC_ALL_PERSONAL_CONTACTS'
    });

    var ExchangeUserToUserResponseCode = Object.freeze({
        OK: 'ok',
        FAILED: 'failed'
    });


    // eslint-disable-next-line max-params, max-lines-per-function
    function ExchangeUserToUserSvcImpl($rootScope, $timeout, $window, LogSvc, PubSubSvc) { // NOSONAR
        LogSvc.info('New Service: ExchangeUserToUserSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _userToUserHandler = UserToUserHandler.getInstance();
        var _isExchangeConnectorRunning = false;
        var _self = this;
        var _evtCallBacks = {};

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function sendExchangeRequest(request, cb) {
            var req = {
                content: {
                    type: ExchangeUserToUserMessage.EXCHANGE,
                    requestId: Utils.createTransactionId(),
                    request: request
                },
                destUserId: $rootScope.localUser.userId
            };

            _userToUserHandler.sendContactCardRequest(req, function (err, resp) {
                $rootScope.$apply(function () {
                    if (err === Constants.ReturnCode.REQUEST_TIMEOUT) {
                        err = ChromeExtension.ResponseError.TIMEOUT;
                    }
                    cb && cb(err, resp);
                });
            });

            return req.content.requestId;
        }

        function cancelReqCallback(reqId) {
            _userToUserHandler.cancelReqCallback(reqId);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // UserToUser Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _userToUserHandler.on('CONTACT_CARD.EXCHANGE_SYNC_ALL_PERSONAL_CONTACTS_RESPONSE', function (data) {
            try {
                LogSvc.debug('[ExchangeUserToUserSvc]: Received EXCHANGE_SYNC_ALL_PERSONAL_CONTACTS_RESPONSE');

                var cbList = _evtCallBacks[ChromeExtension.BgTarget.EXCHANGE_CONNECTOR];
                if (!cbList || cbList.length === 0) {
                    LogSvc.info('[ExchangeUserToUserSvc]: There is no registered event handler for ', ChromeExtension.BgTarget.EXCHANGE_CONNECTOR);
                } else {
                    var evtSyncAll = {
                        data: {
                            type: ChromeExtension.BgExchangeMsgType.SYNC_ALL_PERSONAL_CONTACTS
                        }
                    };

                    if ((data.added && data.added.length > 0) || (data.changed && data.changed.length > 0) || (data.deleted && data.deleted.length > 0)) {
                        evtSyncAll.contacts = {
                            added: data.added,
                            changed: data.changed,
                            deleted: data.deleted
                        };

                        LogSvc.debug('[ExchangeUserToUserSvc]: Contacts added: ' + ((data.added && data.added.length) || 0) +
                            ', Contacts changed: ' + ((data.changed && data.changed.length) || 0) +
                            ', Contacts deleted: ' + ((data.deleted && data.deleted.length) || 0));
                    }

                    var evtContactFolders = {
                        data: {
                            type: ChromeExtension.BgExchangeMsgType.CONTACT_FOLDERS_SYNC_STATE,
                            syncState: data.syncState
                        }
                    };

                    LogSvc.debug('[ExchangeUserToUserSvc]: Fire event: ', evtSyncAll.data.type);

                    cbList.forEach(function (cbInfo) {
                        cbInfo.cb(evtSyncAll);
                        cbInfo.cb(evtContactFolders);
                    });
                }
            } catch (e) {
                LogSvc.error('[ExchangeUserToUserSvc]: Exception handling EXCHANGE_SYNC_ALL_PERSONAL_CONTACTS_RESPONSE event. ', e);
            }
        });


        _userToUserHandler.on('CONTACT_CARD.EXCHANGE_GET_ALL_PERSONAL_CONTACTS_RESPONSE', function (data/*, routing*/) {
            try {
                LogSvc.debug('[ExchangeUserToUserSvc]: Received GET_ALL_PERSONAL_CONTACTS, requestId: ' + data.requestId);

                var evt;
                var cbList = _evtCallBacks[ChromeExtension.BgTarget.EXCHANGE_CONNECTOR];
                if (!cbList || cbList.length === 0) {
                    LogSvc.info('[ExchangeUserToUserSvc]: There is no registered event handler for ', ChromeExtension.BgTarget.EXCHANGE_CONNECTOR);
                } else {
                    evt = {
                        data: {
                            type: ChromeExtension.BgExchangeMsgType.GET_ALL_PERSONAL_CONTACTS,
                            contacts: data.contacts
                        }
                    };

                    LogSvc.debug('[ExchangeUserToUserSvc]: Fire event' + evt.data.type + ' with contacts.count: ' + evt.data.contacts.length);
                    cbList.forEach(function (cbInfo) {
                        cbInfo.cb(evt);
                    });
                }

                if (data.finished) {
                    evt = {
                        data: {
                            type: ChromeExtension.BgExchangeMsgType.GET_ALL_PERSONAL_CONTACTS
                        }
                    };

                    LogSvc.debug('[ExchangeUserToUserSvc]: Fire event' + evt.data.type + ' with empty contacts. GetAllPersonalContacts has finished.');
                    cbList.forEach(function (cbInfo) {
                        cbInfo.cb(evt);
                    });
                }
            } catch (e) {
                LogSvc.error('[ExchangeUserToUserSvc]: Exception handling GET_ALL_PERSONAL_CONTACTS event. ', e);
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.isExchangeConnectorRunning = function () {
            return _isExchangeConnectorRunning;
        };

        this.isExtensionRunning = function () {
            return true;
        };

        /******************************************************
         * Exchange Connector APIs
         ******************************************************/
        this.exchangeConnect = function (settings, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Connect to Exchange via Contact Card...');
            var request = {
                type: ExchangeUserToUserMessage.CONNECT,
                requestId: Utils.createTransactionId()
            };
            return sendExchangeRequest(request, function (err, data) {

                if (err) {
                    _isExchangeConnectorRunning = false;
                } else if (data && data.response === ExchangeUserToUserResponseCode.OK && data.email) {
                    settings.exchInfo.email = data.email;
                }
                cb && cb(err, data);
            });
        };

        this.exchangeDisconnect = function (cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Disconnect from Exchange...');
            var request = {
                type: ExchangeUserToUserMessage.DISCONNECT
            };
            return sendExchangeRequest(request, cb);
        };

        this.exchangeGetConnectionStatus = function (key, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Get Exchange connection status...');
            var request = {
                type: ExchangeUserToUserMessage.GET_CONNECTION_STATUS,
                requestId: Utils.createTransactionId(),
                data: {
                    key: key
                }
            };
            return sendExchangeRequest(request, cb);
        };

        this.exchangeSearchContacts = function (key, searchStr, resCount, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Search Exchange contact...');
            var request = {
                type: ExchangeUserToUserMessage.SEARCH_CONTACTS,
                data: {
                    searchStr: searchStr,
                    resCount: resCount
                }
            };
            return sendExchangeRequest(request, cb);
        };

        this.exchangeGetCapabilities = function (cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Get Exchange capabilities...');
            var request = {
                type: ExchangeUserToUserMessage.GET_CAPABILITIES,
                requestId: Utils.createTransactionId()
            };
            return sendExchangeRequest(request, function (err, data) {
                var capabilities = data && data.capabilities;
                _isExchangeConnectorRunning = !!capabilities;
                $rootScope.isExchangeConnectorRunning = _isExchangeConnectorRunning;

                cb && cb(err, capabilities);
            });
        };

        this.exchangeResolveContact = function (key, contactData, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Resolve Exchange contact...');
            var request = {
                type: ExchangeUserToUserMessage.RESOLVE_CONTACT,
                requestId: Utils.createTransactionId(),
                data: {
                    key: key,
                    resolveData: contactData
                }
            };
            return sendExchangeRequest(request, cb);
        };

        this.exchangeGetContact = function (key, exchangeEmail, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Get Exchange contact...');
            var request = {
                type: ExchangeUserToUserMessage.GET_CONTACT,
                data: {
                    exchangeEmail: exchangeEmail
                }
            };
            return sendExchangeRequest(request, cb);
        };

        this.getAppointments = function (key, startDate, endDate, resCount, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Get Exchange appointments...');
            var request = {
                type: ExchangeUserToUserMessage.GET_APPOINTMENTS,
                data: {
                    startDate: new Date(startDate).toUTCString(),
                    endDate: endDate === 0 ? endDate : new Date(endDate).toUTCString(),
                    resCount: resCount,
                    origin: $window.location.hostname
                }
            };
            return sendExchangeRequest(request, function (error, data) {
                // The DateTime object needs to be adapted from .Net Framework String to Javascript DateTime object
                if (data && data.calendarItems) {
                    data.calendarItems.forEach(function (calendarItem) {
                        calendarItem.startTime = calendarItem.startTime && new Date(calendarItem.startTime).getTime();
                        calendarItem.endTime = calendarItem.endTime && new Date(calendarItem.endTime).getTime();
                        calendarItem.reminderDueBy = calendarItem.reminderDueBy && new Date(calendarItem.reminderDueBy).getTime();
                    });
                }
                cb && cb(error, data);
            });
        };

        this.getAllPersonalContacts = function (key, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Search Exchange contact...');
            var request = {
                type: ExchangeUserToUserMessage.GET_ALL_PERSONAL_CONTACTS,
                data: {
                    key: key
                }
            };
            return sendExchangeRequest(request, cb);
        };

        this.syncAllPersonalContacts = function (key, syncState, cb) {
            LogSvc.debug('[ExchangeUserToUserSvc] Sync All Personal contacts...');
            var request = {
                type: ExchangeUserToUserMessage.SYNC_ALL_PERSONAL_CONTACTS,
                data: {
                    syncState: syncState
                }
            };
            return sendExchangeRequest(request, cb);
        };

        this.storeExchCredentials = function (credentials, cb) {
            // This API is not supported for Exchange integration via Contact Card
            LogSvc.info('[ExchangeUserToUserSvc] Credentials are not stored');
            cb && $timeout(cb);
        };

        this.getStoredCredentials = function (key, cb) {
            // This API is not supported for Exchange integration via Contact Card
            LogSvc.info('[ExchangeUserToUserSvc] Credentials are not stored');
            cb && $timeout(cb);
        };

        this.exchangeCancelReqCallback = function (reqId) {
            cancelReqCallback(reqId);
        };

        this.supportsGetAppointments = function () {
            return true;
        };

        this.supportsExchangeAutodiscover = function () {
            return false;
        };

        this.supportsExchangeAutodiscoverSvc = function () {
            return false;
        };

        this.supportsOOO = function () {
            return false;
        };

        this.supportsUserAvailability = function () {
            return false;
        };

        this.supportsPolicySettings = function () {
            return false;
        };

        this.addEventListener = function (msgType, cb) {
            if (msgType && (typeof cb === 'function')) {
                _evtCallBacks[msgType] = _evtCallBacks[msgType] || [];
                // Make sure this is not a duplicate registration
                var isDuplicate = _evtCallBacks[msgType].some(function (cbInfo) {
                    return cbInfo.cb === cb;
                });
                if (!isDuplicate) {
                    _evtCallBacks[msgType].push({cb: cb});
                }
            }
        };

        this.removeEventListener = function (msgType, cb) {
            if (!msgType && !cb) {
                // Unregister all event handlers
                _evtCallBacks = {};
            } else if (!cb) {
                // Unregister the event handlers for the given message type
                delete _evtCallBacks[msgType];
            } else if (msgType && (typeof cb === 'function')) {
                // Unregister the given event handler
                var cbList = _evtCallBacks[msgType];
                cbList && cbList.some(function (cbInfo, idx) {
                    if (cbInfo.cb === cb) {
                        cbList.splice(idx, 1);
                        return true;
                    }
                    return false;
                });
            }
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        function onLocalUserInit() {
            LogSvc.info('[ExchangeUserToUserSvc] Received /localUser/init event');
            _self.exchangeGetCapabilities();
        }
        PubSubSvc.subscribeOnce('/localUser/init', onLocalUserInit);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initializations
        ///////////////////////////////////////////////////////////////////////////////////////
        $rootScope.isExchangeConnectorRunning = this.isExchangeConnectorRunning();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.ExchangeUserToUserSvcImpl = ExchangeUserToUserSvcImpl;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
