/*global require*/

var Circuit = (function (circuit) {
    'use strict';

    var TranslationProvider = {
        GOOGLE: 'Google',
        SYSTRAN: 'Systran'
    };

    // eslint-disable-next-line max-params, max-lines-per-function
    function TranslationSvcImpl($q, $http, $rootScope, LogSvc, PubSubSvc, LocalStoreSvc, LocalizeSvc) { // NOSONAR
        LogSvc.debug('New Service: TranslationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        /////////////////////////////////////////////////////////////////////////////////////////
        var _self = this;
        var _translationSettings = LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.TRANSLATION) || {
            selectedProvider: TranslationProvider.GOOGLE,
            apiKeys: {}
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function hasApiKey() {
            return !!_translationSettings.apiKeys[_translationSettings.selectedProvider];
        }

        function setRequestUrl(text, language, isHtml) {
            var provider = _translationSettings.selectedProvider;
            var apiKey = _translationSettings.apiKeys[provider];
            if (!apiKey) {
                return $q.reject('res_MissingTranslationApiKey');
            }

            var url;
            switch (provider) {
            case TranslationProvider.GOOGLE:
                url = 'https://translation.googleapis.com/language/translate/v2?q=' + encodeURIComponent(text) +
                    '&target=' + language + '&format=' + (isHtml ? 'html' : 'text') + '&key=' + apiKey;
                break;
            case TranslationProvider.SYSTRAN:
                if (language === 'zh') {
                    language = 'zh-Hans';
                }
                url = 'https://api-platform.systran.net/translation/text/translate?input=' + encodeURIComponent(text) +
                    '&source=auto&target=' + language + '&key=' + apiKey;
                break;
            default:
                LogSvc.warn('[TranslationSvc]: Unsupported translation provider: ', provider);
                return $q.reject('Invalid provider');
            }
            return $q.resolve(url);
        }

        function sendRequest(url) {
            return $http.get(url)
                .catch(function (error) {
                    LogSvc.error('[TranslationSvc]: Failed to translate text. ', error.data);
                    return $q.reject(error.statusText || error.status);
                });
        }

        function processGoogleResponse(data) {
            try {
                var translation = data.data.translations[0];
                if (translation) {
                    LogSvc.debug('[TranslationSvc]: Translation was successful');
                    return $q.resolve({
                        translatedText: translation.translatedText,
                        detectedSourceLanguage: translation.detectedSourceLanguage
                    });
                }
            } catch (err) {
            }
            LogSvc.warn('[TranslationSvc]: Unexpected translation response. ', data);
            return $q.reject('Unexpected response');
        }

        function processSystranResponse(data) {
            try {
                var translation = data.outputs[0];
                if (translation) {
                    LogSvc.debug('[TranslationSvc]: Translation was successful');
                    return $q.resolve({
                        translatedText: translation.output,
                        detectedSourceLanguage: translation.detectedLanguage
                    });
                }
            } catch (err) {
            }
            LogSvc.warn('[TranslationSvc]: Unexpected translation response. ', data);
            return $q.reject('Unexpected response');
        }

        function processTranslationResponse(resp) {
            switch (_translationSettings.selectedProvider) {
            case TranslationProvider.GOOGLE:
                return processGoogleResponse(resp.data);
            case TranslationProvider.SYSTRAN:
                return processSystranResponse(resp.data);
            default:
                return $q.reject('Invalid provider');
            }
        }

        function translate(text, language, isHtml) {
            if (!text || !language) {
                return $q.reject('Invalid input');
            }
            if (!$rootScope.circuitLabs.TRANSCRIPTION_AND_TRANSLATION) {
                return $q.reject('Feature not enabled');
            }

            LogSvc.debug('[TranslationSvc]: Send translation request to ', _translationSettings.selectedProvider);

            return setRequestUrl(text, language, isHtml)
                .then(sendRequest)
                .then(processTranslationResponse);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/call/liveTranscription', function (call, transcription) {
            if ($rootScope.circuitLabs.TRANSCRIPTION_AND_TRANSLATION && hasApiKey() && !transcription.intermediate) {
                LogSvc.debug('[TranslationSvc]: Received /call/liveTranscription event');

                if (transcription.userId === $rootScope.localUser.userId) {
                    LogSvc.debug('[TranslationSvc]: This is a transcription for the local user. Ignore translation.');
                    return;
                }

                var language = LocalizeSvc.getLanguageOnly();
                LogSvc.debug('[TranslationSvc]: Translate transcription to ', language);

                _self.translate(transcription.text, language, false)
                .then(function (data) {
                    if (data.detectedSourceLanguage === language) {
                        LogSvc.debug('[TranslationSvc]: Detected source language for transcription matches requested language. Ignore translation.');
                    } else {
                        LogSvc.debug('[TranslationSvc]: Add translation to transcription. Detected source language was ', data.detectedSourceLanguage);
                        transcription.translatedText = data.translatedText;
                        // NOTE: Since this is not yet supported by mobile clients we don't need to raise an event
                    }
                })
                .catch(function (err) {
                    LogSvc.debug('[TranslationSvc]: Failed to translate transcription. ', err);
                });
            }
        });

        /////////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        /////////////////////////////////////////////////////////////////////////////////////////
        this.isTranslationPossible = function () {
            if (!$rootScope.circuitLabs.TRANSCRIPTION_AND_TRANSLATION) {
                return false;
            }
            var provider = _translationSettings.selectedProvider;
            var apiKey = _translationSettings.apiKeys[provider];
            return !!apiKey;
        };

        this.translate = translate;

        this.setTranslationProvider = function (provider) {
            if (!Object.values(TranslationProvider).includes(provider)) {
                return null;
            }
            if (_translationSettings.selectedProvider !== provider) {
                LogSvc.debug('[TranslationSvc]: Set translation provider to ', provider);
                _translationSettings.selectedProvider = provider;
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.TRANSLATION, _translationSettings);
            }
            // Return API key for selected provider
            return _translationSettings.apiKeys[_translationSettings.selectedProvider];
        };

        this.setProviderApiKey = function (provider, apiKey) {
            if (!Object.values(TranslationProvider).includes(provider)) {
                return;
            }
            if (apiKey) {
                LogSvc.debug('[TranslationSvc]: Set translation API key for ', provider);
                _translationSettings.apiKeys[provider] = apiKey;
            } else {
                LogSvc.debug('[TranslationSvc]: Clear translation API key for ', provider);
                delete _translationSettings.apiKeys[provider];
            }
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.TRANSLATION, _translationSettings);
        };

        this.getSettings = function () {
            return {
                provider: _translationSettings.selectedProvider,
                apiKey: _translationSettings.apiKeys[_translationSettings.selectedProvider] || ''
            };
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.TranslationSvcImpl = TranslationSvcImpl;
    circuit.TranslationProvider = TranslationProvider;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
