/*global RegistrationState */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var AtcInfoMessage = circuit.Enums.AtcInfoMessage;
    var AtcMessage = circuit.Enums.AtcMessage;
    var AtcRegistrationState = circuit.Enums.AtcRegistrationState;
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var Constants = circuit.Constants;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;
    var RoutingOptions = circuit.RoutingOptions;
    var UserToUserHandler = circuit.UserToUserHandlerSingleton;
    var Utils = circuit.Utils;

    // eslint-disable-next-line max-params, max-lines-per-function
    function AtcRegistrationSvcImpl($rootScope, $timeout, LogSvc, PubSubSvc, RegistrationSvc, ConversationSvc) { // NOSONAR
        LogSvc.debug('New Service: AtcRegistrationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var MIN_ATC_REGISTRATION_RETRY_TIMER = 8;     // Min Retry timer in seconds
        var MAX_ATC_REGISTRATION_RETRY_TIMER = 64;    // Max Retry timer in seconds
        var MIN_ATC_REGISTRATION_DELAY_TIMER = 500;   // Min Delay timer in miliseconds
        var MAX_ATC_REGISTRATION_DELAY_TIMER = 10000; // Max Delay timer in miliseconds
        var MIN_RENEW_ASSOCIATED_TC_TIMER = 30000;    // Min renew timer in miliseconds
        var MAX_RENEW_ASSOCIATED_TC_TIMER = 180000;   // Max renew timer in miliseconds
        var UNREGISTER_TIMER = 2000;  // Timer in miliseconds

        var DEFAULT_TELEPHONY_DATA = {
            state: Constants.TrunkState.DOWN,
            telephonyAvailableForTenant: false,
            telephonyAvailableForUser: false,
            conferenceDialOutAvailable: false,
            phoneCallsAvailable: false
        };

        var SupportedFeatures = {
            cFC1: 'pbxCallForwardToVoiceMailSupported',  // CFVM
            dND: 'pbxDNDSupported', // DND
            zCaOC: 'pbxCampOnSupported', // CampOn
            zPrfD: 'pbxCallRoutingOptionsSupported', // OSBiz Call Routing
            zGPC: 'pbxCallPickupSupported' // Call pickup
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _clientApiHandler = ClientApiHandler.getInstance();
        var _userToUserHandler = UserToUserHandler.getInstance();

        var _atcRegistrationData = {};
        var _atcRegState = AtcRegistrationState.Disconnected;
        var _configurationUpdated = false;
        var _presenceSubscribedTrunkId = null;
        var _telephonyTrunkSubscriptions = {};

        // The associated trunk state (or null if user is not associated to a TC)
        var _trunkState = null;

        var _telephonyData = Object.assign({}, DEFAULT_TELEPHONY_DATA);
        var _telephonyConversation = null;
        var _reRegister = false;

        var _savedRoutingOption = null;

        var _savedCallForwardingStatus = false;

        // Device type registering with ATC
        var _deviceType = Utils.isMobile() ? Constants.DeviceType.MOBILE : Constants.DeviceType.WEB;

        var _renewRegistration = false;

        var _unregisteredEventReceived = false;

        // Timers
        var _retryTimer = null; // Timer to retry registration request
        var _retryTime = MIN_ATC_REGISTRATION_RETRY_TIMER;
        var _renewTimer = null; // Timer to renew TC association
        var _unregisterTimer = null; // Timer to unregister after state changes to DOWN

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function cancelRetryTimer() {
            if (_retryTimer) {
                LogSvc.info('[AtcRegistrationSvc]: Cancel retry timer');
                $timeout.cancel(_retryTimer);
                _retryTimer = null;
            }
        }

        function cancelRenewTimer() {
            if (_renewTimer) {
                LogSvc.info('[AtcRegistrationSvc]: Cancel renew timer');
                $timeout.cancel(_renewTimer);
                _renewTimer = null;
            }
        }

        function cancelUnregisterTimer() {
            if (_unregisterTimer) {
                LogSvc.info('[AtcRegistrationSvc]: Cancel unregister timer');
                $timeout.cancel(_unregisterTimer);
                _unregisterTimer = null;
            }
        }

        function setAtcState(newState) {
            if (newState !== _atcRegState) {
                _atcRegState = newState;
                LogSvc.info('[AtcRegistrationSvc]: Publish /atcRegistration/state event. State = ', newState);
                PubSubSvc.publish('/atcRegistration/state', [newState]);

                if ($rootScope.localUser.isRegisterTC && newState !== AtcRegistrationState.Registering) {
                    processTelephonyData(_telephonyData);
                }
            }
        }

        function isTelephonyAvailableForUserUpdated() {
            var telephonyAvailable = !!$rootScope.localUser.telephonyAvailable;
            var phoneConfigured = !!$rootScope.localUser.callerId;
            return telephonyAvailable !== phoneConfigured;
        }

        function startRetryTimeout() {
            cancelRetryTimer();

            LogSvc.info('[AtcRegistrationSvc]: Retry ATC registration in ' + _retryTime + ' seconds');
            _retryTimer = $timeout(function () {
                _retryTimer = null;
                if (_retryTime < MAX_ATC_REGISTRATION_RETRY_TIMER) {
                    _retryTime = Math.min(_retryTime * 2, MAX_ATC_REGISTRATION_RETRY_TIMER);
                }
                atcRegister();
            }, _retryTime * 1000);
        }

        function retryOnError(err) {
            return !!err && (err === Constants.ReturnCode.FAILED_TO_SEND ||
                err === Constants.ReturnCode.REQUEST_TIMEOUT || err.code === 503 || err.code === 480 || err.code === 408);
        }

        function saveDataForLogging(registerResult) {
            $rootScope.localUser.telephonyConnectorData = {
                pbxType: $rootScope.localUser.isOsBizCTIEnabled ? 'OSBiz' : registerResult.configurationData.pbx || 'Unavailable',
                pbxVersion: registerResult.configurationData.pbxVersion || 'Unavailable',
                tcType: $rootScope.localUser.isOsBizCTIEnabled ? 'OSBIZ' : 'ATC'
            };

            if (registerResult.telephonyConnectorData) {
                Object.assign($rootScope.localUser.telephonyConnectorData, registerResult.telephonyConnectorData);
            }
        }


        function onAtcRegistrationError(err) {
            if (err.code === 600) {
                // If error.code === 600 then user has no valid telephony license.
                // Client needs to logout
                LogSvc.debug('[AtcRegistrationSvc]: Publish /session/logout event');
                PubSubSvc.publish('/session/logout', 'invalidAtcLicense');
                return;
            }
            if (retryOnError(err) && RegistrationSvc.isRegistered() && _atcRegState === AtcRegistrationState.Registering) {
                startRetryTimeout();
            }

            setAtcState(AtcRegistrationState.Unregistered);
        }

        function delayAtcRegister() {
            // Cancel any pending timer (in case of quick TC state changes)
            cancelRetryTimer();

            // Delay sending a Registration to avoid all clients on this telephony connector registering at the same time.
            var delayTime = Utils.randomNumber(MIN_ATC_REGISTRATION_DELAY_TIMER, MAX_ATC_REGISTRATION_DELAY_TIMER);
            LogSvc.info('[AtcRegistrationSvc]: Delay ATC registration by ' + delayTime + ' miliseconds');
            _retryTimer = $timeout(function () {
                _retryTimer = null;

                LogSvc.info('[AtcRegistrationSvc]: Retry timer has expired');
                // Just check whether the registration state is Unregistered
                // There are cases where state is DOWN and _retryTimer is canceled
                // In such cases client never attempts to connect back
                if (_atcRegState === AtcRegistrationState.Unregistered) {
                    _retryTime = MIN_ATC_REGISTRATION_RETRY_TIMER;
                    atcRegister();
                } else {
                    LogSvc.info('[AtcRegistrationSvc]: State is not Unregistered, so cancel new registration');
                }
            }, delayTime);
        }

        function processSupportedFeatures(supportedFeatures) {
            Object.keys(SupportedFeatures).forEach(function (feature) {
                var propertyName = SupportedFeatures[feature];
                $rootScope.localUser[propertyName] = !!supportedFeatures && supportedFeatures.includes(feature);
            });
        }

        function atcRegister() {
            var localUser = $rootScope.localUser;
            var data = {
                content: {
                    type: AtcMessage.REGISTER,
                    phoneNumber: localUser.registerNumber,
                    reroutingPhoneNumber: localUser.isOsBizCTIEnabled ? '' : Utils.cleanPhoneNumber(localUser.reroutingPhoneNumber),
                    onsHash: localUser.onsSipAuthenticationHash || undefined,
                    ondHash: localUser.ondSipAuthenticationHash || undefined,
                    routeToDesk: localUser.selectedRoutingOption === RoutingOptions.DeskPhone.name && !localUser.cfData.Immediate.cfwEnabled,
                    tcPool: localUser.associatedTelephonyTrunkGroupId,
                    pbxCallLog: true,
                    deviceType: _deviceType,
                    userId: localUser.userId,
                    conversationId: _telephonyConversation.convId,
                    rtcSessionId: _telephonyConversation.rtcSessionId,
                    lastItemTime: _telephonyConversation.lastItemModificationTime,
                    renewRegistration: _renewRegistration || undefined,
                    sTC: localUser.isSTC || undefined
                },
                destUserId: localUser.associatedTelephonyUserID,
                keysToOmitFromLogging: [
                    'content.onsHash',
                    'content.ondHash'
                ]
            };

            cancelRetryTimer();
            cancelUnregisterTimer();

            _atcRegistrationData.phoneNumber = localUser.registerNumber;
            _atcRegistrationData.associatedTelephonyUserID = localUser.associatedTelephonyUserID;
            _atcRegistrationData.associatedTelephonyTrunkGroupId = localUser.associatedTelephonyTrunkGroupId;
            _atcRegistrationData.reroutingPhoneNumber = Utils.cleanPhoneNumber(localUser.reroutingPhoneNumber);
            _atcRegistrationData.onsSipAuthenticationHash = localUser.onsSipAuthenticationHash;
            _atcRegistrationData.ondSipAuthenticationHash = localUser.ondSipAuthenticationHash;
            _savedRoutingOption = localUser.selectedRoutingOption;
            _savedCallForwardingStatus = !!localUser.cfData.Immediate.cfwEnabled;

            // Initialize to false when registering
            localUser.noCallLog = false;

            setAtcState(AtcRegistrationState.Registering);

            LogSvc.info('[AtcRegistrationSvc]: Registering with ATC, phoneNumber: ' + _atcRegistrationData.phoneNumber + ', associatedTelephonyUserID: ' +
                _atcRegistrationData.associatedTelephonyUserID);

            _reRegister = false;
            _userToUserHandler.sendAtcRequest(data, function (err, registerResult) {
                $rootScope.$apply(function () {
                    if (_configurationUpdated && !_telephonyConversation.call) {
                        LogSvc.info('[AtcRegistrationSvc]: There was a configuration update while registering. Need to register again');
                        _configurationUpdated = false;
                        if (!err && registerResult && !registerResult.err) {
                            _reRegister = true;
                            atcUnregister();
                        } else {
                            atcRegister();
                        }
                        return;
                    }

                    if (err || (registerResult && registerResult.err)) {
                        err = err || registerResult.err;
                        LogSvc.error('[AtcRegistrationSvc]: Register with ATC failed with error: ', err);
                        onAtcRegistrationError(err);
                        return;
                    }

                    // Successfully registered with the platform
                    if (registerResult && registerResult.configurationData) {
                        LogSvc.info('[AtcRegistrationSvc]: Received ATC configuration data: ', registerResult.configurationData);
                        LogSvc.debug('[AtcRegistrationSvc]: Registration refresh will be handled by access server');
                        _renewRegistration = false;
                        if (localUser.isSTC) {
                            setAtcState(AtcRegistrationState.Registered);
                            handlePresenceState(Constants.TrunkState.UP);
                            localUser.stcCapabilities = registerResult.capabilities;
                            return;
                        }
                        _retryTime = MIN_ATC_REGISTRATION_RETRY_TIMER;
                        _atcRegistrationData.configurationData = registerResult.configurationData;
                        _atcRegistrationData.reroutingPhoneNumber = registerResult.configurationData.cell;
                        _atcRegistrationData.capabilities = registerResult.capabilities;

                        // Update localUser data
                        if (!localUser.isOsBizCTIEnabled) {
                            // In case of OSBiz, do not overwrite rerouting number.
                            localUser.reroutingPhoneNumber = PhoneNumberFormatter.format(registerResult.configurationData.cell);
                            // It will be set again once getForwarding response is received.
                            $rootScope.localUser.ringDurationEnabled = false;
                        }
                        localUser.vmNumber = registerResult.configurationData.vm;
                        localUser.isOSV = registerResult.configurationData.pbx === 'OSV';
                        localUser.isOS4K = registerResult.configurationData.pbx === 'OS4K';
                        localUser.PBXCallLogSupported = _atcRegistrationData.capabilities &&
                            _atcRegistrationData.capabilities.includes(Constants.AtcCapabilities.PBX_CALL_LOG);
                        processSupportedFeatures(registerResult.configurationData.supportedFeatures);

                        saveDataForLogging(registerResult);

                        setAtcState(AtcRegistrationState.Registered);
                        if (localUser.PBXCallLogSupported && !localUser.noCallLog) {
                            sendEnablePBXCallLogToATC(true);
                            // Assume that if the client is connected to an OSV, the PBX supports sending Call Logs
                            // The Call Log feature was implemented in OSV V8R1
                            // Don't need to wait for the response to the ENABLE_PBX_CALL_LOG request
                            localUser.noCallLog = localUser.isOSV;
                        }
                        handlePresenceState(Constants.TrunkState.UP);
                    } else {
                        setAtcState(AtcRegistrationState.Unregistered);
                    }
                });
            });
        }

        function atcUnregister() {
            LogSvc.info('[AtcRegistrationSvc]: Unregister with ATC');

            cancelRetryTimer();
            cancelUnregisterTimer();

            if (_atcRegState === AtcRegistrationState.Unregistered || _atcRegState === AtcRegistrationState.Disconnected) {
                LogSvc.debug('[AtcRegistrationSvc]: The client is not registered with ATC');
                return;
            }

            // Send USER_TO_USER message if client is registered
            if (RegistrationSvc.state() === RegistrationState.Registered) {
                var data = {
                    content: {
                        type: AtcMessage.UNREGISTER,
                        phoneNumber: _atcRegistrationData.phoneNumber
                    },
                    destUserId: _atcRegistrationData.associatedTelephonyUserID
                };

                _userToUserHandler.sendAtcRequest(data, function (err) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[AtcRegistrationSvc]: Unregister with ATC failed with: ', err);
                        } else {
                            LogSvc.info('[AtcRegistrationSvc]: Unregistered successfully with ATC');
                        }
                        if (_reRegister) {
                            atcRegister();
                        }
                    });
                });
            }

            // If the associated ATC is changed, the client needs to indicate that to the new ATC so that it renews
            // the registration with the PBX
            _renewRegistration = $rootScope.localUser.associatedTelephonyUserID !== _atcRegistrationData.associatedTelephonyUserID;
            _atcRegistrationData.associatedTelephonyUserID = '';

            setAtcState(AtcRegistrationState.Unregistered);
        }

        function handlePresenceState(newTrunkState) {
            if (newTrunkState !== _trunkState) {
                _trunkState = newTrunkState;
                LogSvc.debug('[AtcRegistrationSvc]: Set trunk state to ', _trunkState);
                if (_telephonyData.state !== _trunkState || !$rootScope.localUser.isRegisterTC) {
                    $rootScope.$apply(function () {
                        var data = Object.assign({}, _telephonyData, {state: _trunkState});
                        handleTelephonyDataChange(data);
                    });
                }
            }
        }

        function unregisterAndRetry() {
            atcUnregister();
            // Start a retry timer in case there is an issue with Telephony UP event
            _retryTime = MAX_ATC_REGISTRATION_RETRY_TIMER + Utils.randomNumber(0, MAX_ATC_REGISTRATION_RETRY_TIMER);
            startRetryTimeout();
        }

        function handleTelephonyDataChange(data) {
            if (!data) {
                return;
            }

            LogSvc.debug('[AtcRegistrationSvc]: Handle telephony data change with state = ', data.state);

            var pendingUnregister = !!_unregisterTimer;
            cancelUnregisterTimer();

            if ($rootScope.localUser.isRegisterTC) {
                if (data.state === Constants.TrunkState.DOWN) {
                    if (_atcRegState !== AtcRegistrationState.Registered) {
                        unregisterAndRetry();
                    } else {
                        _telephonyData.state = Constants.TrunkState.DOWN;
                        LogSvc.info('[AtcRegistrationSvc]: Start unregister timeout in case TC immediately reconnects');
                        _unregisterTimer = $timeout(function () {
                            _unregisterTimer = null;
                            LogSvc.info('[AtcRegistrationSvc]: Unregister timeout has expired. state = ', _telephonyData.state);
                            if (_telephonyData.state === Constants.TrunkState.DOWN) {
                                unregisterAndRetry();
                            }
                        }, UNREGISTER_TIMER);
                        // return so processTelephonyData is not called
                        return;
                    }
                } else if (_telephonyData.state === Constants.TrunkState.DOWN && data.state === Constants.TrunkState.UP) {
                    _telephonyData.state = Constants.TrunkState.UP;
                    if (_atcRegState === AtcRegistrationState.Registering) {
                        LogSvc.debug('[AtcRegistrationSvc]: Client is already registering. Wait...');
                    } else if (pendingUnregister) {
                        LogSvc.debug('[AtcRegistrationSvc]: Client was pending unregistration. Send a registration refresh.');
                        _retryTime = MIN_ATC_REGISTRATION_RETRY_TIMER;
                        atcRegister();
                    } else {
                        delayAtcRegister();
                    }
                    // return so processTelephonyData is not called
                    return;
                }
            }
            processTelephonyData(data);
        }

        function raiseTelephonyData() {
            LogSvc.info('[AtcRegistrationSvc]: Publish /telephony/data event. data = ', _telephonyData);
            PubSubSvc.publish('/telephony/data', [Object.assign({}, _telephonyData), _telephonyConversation, _atcRegState]);
        }

        function processTelephonyData(data) {
            if (!data) {
                return;
            }

            _telephonyData = data;

            var isPhoneCallsAvailable;
            if ($rootScope.localUser.isRegisterTC && ConversationSvc.telephonyConvId) {
                _telephonyData.state = _atcRegState === AtcRegistrationState.Registered ? Constants.TrunkState.UP : Constants.TrunkState.DOWN;
                _telephonyData.defaultCallerId = null;
                _telephonyData.isGTCEnabled = true;
                isPhoneCallsAvailable = _atcRegState === AtcRegistrationState.Registered;
            } else {
                _telephonyData.defaultCallerId = _telephonyData.defaultCallerId || null;
                isPhoneCallsAvailable = _telephonyData.state !== Constants.TrunkState.DOWN && _trunkState !== Constants.TrunkState.DOWN;
            }

            _telephonyData.telephonyAvailableForTenant = !!_telephonyData.isGTCEnabled;
            _telephonyData.telephonyAvailableForUser = !!(_telephonyData.isGTCEnabled && ($rootScope.localUser.callerId || _telephonyData.defaultCallerId));
            _telephonyData.conferenceDialOutAvailable = _telephonyData.telephonyAvailableForTenant && isPhoneCallsAvailable;
            _telephonyData.phoneCallsAvailable = _telephonyData.telephonyAvailableForUser && isPhoneCallsAvailable;

            if (!_telephonyData.telephonyAvailableForUser) {
                raiseTelephonyData();
            } else {
                ConversationSvc.getTelephonyConversationPromise()
                .then(function (conv) {
                    _telephonyConversation = conv;
                    raiseTelephonyData();
                })
                .catch(function (err) {
                    LogSvc.error('[AtcRegistrationSvc]: Failed to retrieve telephony conversation. ', err);
                    _telephonyData.telephonyAvailableForUser = false;
                    _telephonyData.conferenceDialOutAvailable = false;
                    _telephonyData.phoneCallsAvailable = false;
                    raiseTelephonyData();
                });
            }
        }

        function getTelephonyData() {
            if (ConversationSvc.telephonyConvId) {
                LogSvc.info('[AtcRegistrationSvc]: Getting telephony data');
                _clientApiHandler.getTelephonyData(function (err, data) {
                    $rootScope.$apply(function () {
                        if (!err) {
                            handleTelephonyDataChange(data);
                        } else if (err !== Constants.ReturnCode.NO_RESULT) {
                            LogSvc.error('[AtcRegistrationSvc]: Error getting telephony data: ', err);
                            // We need to raise the /telephony/data event anyway
                            processTelephonyData(_telephonyData);
                        }
                    });
                });
            } else {
                LogSvc.info('[AtcRegistrationSvc]: There is no telephony conversation. Set telephony state to DOWN.');
                processTelephonyData({
                    state: Constants.TrunkState.DOWN
                });
            }
        }

        function checkPresenceSubscription() {
            if (_presenceSubscribedTrunkId && _presenceSubscribedTrunkId !== $rootScope.localUser.associatedTelephonyUserID) {
                LogSvc.debug('[AtcRegistrationSvc]: Unsubscribe from previously associated TC presence');
                if (!_telephonyTrunkSubscriptions[_presenceSubscribedTrunkId]) {
                    _clientApiHandler.unsubscribePresence([_presenceSubscribedTrunkId]);
                }
                _presenceSubscribedTrunkId = null;
                _trunkState = null;
                _renewRegistration = true;
            }

            if (!$rootScope.localUser.associatedTelephonyUserID) {
                // User is not associated to any TC
                _trunkState = null;
            } else if (_presenceSubscribedTrunkId !== $rootScope.localUser.associatedTelephonyUserID) {
                _presenceSubscribedTrunkId = $rootScope.localUser.associatedTelephonyUserID;

                // We can cancel the timer since we just got associated to a new TC.
                cancelRenewTimer();

                if (!$rootScope.localUser.isRegisterTC || !_telephonyTrunkSubscriptions[_presenceSubscribedTrunkId]) {
                    // Subscribe to presence (Do this even if we are already subscribed so we can get the current state)
                    _clientApiHandler.subscribePresence([$rootScope.localUser.associatedTelephonyUserID], function (err, states) {
                        if (err) {
                            LogSvc.warn('[AtcRegistrationSvc]: Unable to subscribe to associated TC presence');
                            _presenceSubscribedTrunkId = null;
                            return;
                        }
                        if (states && states.length > 0 && !$rootScope.localUser.isRegisterTC) {
                            var newTrunkState = states[0].state === Constants.PresenceState.AVAILABLE ? Constants.TrunkState.UP : Constants.TrunkState.DOWN;
                            handlePresenceState(newTrunkState);
                        }
                    });
                }
            }
        }

        function checkLocalUserConfiguration(loadComplete) {
            checkPresenceSubscription();

            if (!$rootScope.localUser.isRegisterTC || !$rootScope.localUser.registerNumber) {
                LogSvc.debug('[AtcRegistrationSvc]: User is not associated with ATC or STC');
                if (_atcRegState === AtcRegistrationState.Registered) {
                    atcUnregister();
                    getTelephonyData();
                } else if (loadComplete || isTelephonyAvailableForUserUpdated()) {
                    // Get the telephony data for non-ATC users
                    getTelephonyData();
                }
                setAtcState(AtcRegistrationState.Disconnected);
                return;
            }

            if (_savedCallForwardingStatus !== $rootScope.localUser.cfData.Immediate.cfwEnabled) {
                _savedCallForwardingStatus = $rootScope.localUser.cfData.Immediate.cfwEnabled;
                if ($rootScope.localUser.selectedRoutingOption === RoutingOptions.DeskPhone.name) {
                    sendRouteToDeskInfoToAtc();
                }
            }

            if (($rootScope.localUser.associatedTelephonyUserID === _atcRegistrationData.associatedTelephonyUserID) &&
                ($rootScope.localUser.registerNumber === _atcRegistrationData.phoneNumber) &&
                ($rootScope.localUser.onsSipAuthenticationHash === _atcRegistrationData.onsSipAuthenticationHash) &&
                ($rootScope.localUser.ondSipAuthenticationHash === _atcRegistrationData.ondSipAuthenticationHash) &&
                (_atcRegState === AtcRegistrationState.Registered || _atcRegState === AtcRegistrationState.Registering)) {

                // In case of OSBiz, there is no need to re-register due to changing alternative number.
                if ($rootScope.localUser.isOsBizCTIEnabled ||
                    (Utils.cleanPhoneNumber($rootScope.localUser.reroutingPhoneNumber) === _atcRegistrationData.reroutingPhoneNumber)) {
                    LogSvc.debug('[AtcRegistrationSvc]: Client is already registered or registering with ATC and the associatedTelephonyUserID is the same');
                    return;
                }
            }

            if (_atcRegState === AtcRegistrationState.Registering) {
                // We need to wait for the previous registration to complete before registering again
                LogSvc.debug('[AtcRegistrationSvc]: Client is registering and configuration has changed. Wait...');
                _configurationUpdated = true;
                return;
            }

            if (_atcRegState === AtcRegistrationState.Registered) {
                LogSvc.debug('[AtcRegistrationSvc]: Client is already registered and configuration has changed.');
                if (!_telephonyConversation.call) {
                    _reRegister = true;
                    atcUnregister();
                } else {
                    LogSvc.debug('[AtcRegistrationSvc]: There is an active telephony call, wait...');
                    _configurationUpdated = true;
                    return;
                }
            }

            if (!_reRegister) {
                // Make sure we have the telephony conversation
                if (!_telephonyConversation) {
                    LogSvc.debug('[AtcRegistrationSvc]: Get telephony conversation before proceeding with ATC register');
                    ConversationSvc.getTelephonyConversationPromise()
                    .then(function (conv) {
                        _telephonyConversation = conv;
                        LogSvc.debug('[AtcRegistrationSvc]: Got telephony conversation. Proceed with ATC register.');
                        checkLocalUserConfiguration();
                    })
                    .catch(function () {
                        LogSvc.error('[AtcRegistrationSvc]: Failed to retrieve telephony conversation. Cannot register with ATC.');
                        processTelephonyData({
                            state: Constants.TrunkState.DOWN
                        });
                    });

                    if (!loadComplete && isTelephonyAvailableForUserUpdated()) {
                        // Get the telephony data for ATC users
                        getTelephonyData();
                    }
                    return;
                }

                if (_atcRegState !== AtcRegistrationState.ForcedUnregistered) {
                    // Do not attempt to register if we were forced unregistered by ATC
                    atcRegister();
                }
            }
        }

        function sendRouteToDeskInfoToAtc() {
            if (_atcRegState === AtcRegistrationState.Registered && !$rootScope.localUser.isOsBizCTIEnabled) {
                var status = $rootScope.localUser.selectedRoutingOption === RoutingOptions.DeskPhone.name &&
                    !$rootScope.localUser.cfData.Immediate.cfwEnabled;

                LogSvc.debug('[AtcRegistrationSvc]: Send SET_ROUTE_TO_DESK event to ATC. status = ', status);

                var data = {
                    content: {
                        type: AtcMessage.INFO,
                        phoneNumber: $rootScope.localUser.cstaNumber,
                        info: {
                            type: AtcInfoMessage.SET_ROUTE_TO_DESK,
                            status: status
                        }
                    },
                    destUserId: $rootScope.localUser.associatedTelephonyUserID
                };
                // Send request
                _userToUserHandler.sendAtcRequest(data);
            }
        }

        function sendEnablePBXCallLogToATC(status) {
            var data = {
                content: {
                    type: AtcMessage.INFO,
                    phoneNumber: $rootScope.localUser.cstaNumber,
                    info: {
                        type: AtcInfoMessage.ENABLE_PBX_CALL_LOG,
                        status: status
                    }
                },
                destUserId: $rootScope.localUser.associatedTelephonyUserID
            };
            // Send request
            _userToUserHandler.sendAtcRequest(data);
        }

        function renewAssociatedTC(retry, delayTime) {
            cancelRenewTimer();
            if (!$rootScope.localUser.associatedTelephonyTrunkGroupId) {
                // User is not associated to any pool. Ignore the request.
                LogSvc.debug('[AtcRegistrationSvc]: User is not associated to any pool. Ignore the TC reassignment request.');
                return;
            }

            // Use given delay or set it to a random time between MIN and MAX
            delayTime = delayTime || Utils.randomNumber(MIN_RENEW_ASSOCIATED_TC_TIMER, MAX_RENEW_ASSOCIATED_TC_TIMER);

            LogSvc.debug('[AtcRegistrationSvc]: Renew associated telephony connector in ' + delayTime + ' miliseconds');

            _renewTimer = $timeout(function () {
                _renewTimer = null;
                LogSvc.debug('[AtcRegistrationSvc]: Renew associated telephony connector');
                _clientApiHandler.renewAssociatedTelephonyUser($rootScope.localUser.associatedTelephonyUserID, function (err) {
                    if (err) {
                        LogSvc.error('[AtcRegistrationSvc]: Failed to renew associated telephony connector. ', err);
                        retry && renewAssociatedTC(true, MAX_RENEW_ASSOCIATED_TC_TIMER);
                    }
                });
            }, delayTime);
        }

        function processRefreshFailedEvent() {
            if (_atcRegState === AtcRegistrationState.Registered) {
                if (_telephonyConversation.call && !_telephonyConversation.call.isRemote) {
                    LogSvc.info('[AtcRegistrationSvc]: There is an active call, ignoring ATC re-register for now. If error persists on server another event will come later.');
                } else {
                    $rootScope.$apply(function () {
                        LogSvc.info('[AtcRegistrationSvc]: New registration must be triggered.');
                        _reRegister = true;
                        atcUnregister();
                    });
                }
            } else {
                // Other processing has been already started on client, so just ignore
                LogSvc.info('[AtcRegistrationSvc]: Client is not registered, ignoring failed refresh on server.');
            }
        }

        function processUnregisteredEvent() {
            // Event from ATC that indicates the the client was forced unregistered due to a problem
            if (_atcRegState === AtcRegistrationState.Registered) {
                if (_telephonyConversation.call && !_telephonyConversation.call.isRemote) {
                    LogSvc.info('[AtcRegistrationSvc]: There is an active call, ignoring ATC Unregistered event for now.');
                    _unregisteredEventReceived = true;
                } else {
                    $rootScope.$apply(function () {
                        setAtcState(AtcRegistrationState.ForcedUnregistered);
                    });
                }
            } else {
                // Other processing has been already started on client, so just ignore
                LogSvc.info('[AtcRegistrationSvc]: Client is not registered, ignoring Unregistered event.');
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/localUser/telephonyConfigUpdated', function () {
            if (RegistrationSvc.isRegistered() && ConversationSvc.isConversationsLoadComplete()) {
                LogSvc.debug('[AtcRegistrationSvc]: Received /localUser/telephonyConfigUpdated event');
                checkLocalUserConfiguration();
            }
        });

        PubSubSvc.subscribe('/conversations/loadComplete', function () {
            LogSvc.debug('[AtcRegistrationSvc]: Received /conversations/loadComplete event');
            checkLocalUserConfiguration(true);
        });

        PubSubSvc.subscribe('/registration/state', function (state) {
            if (!ConversationSvc.isConversationsLoadComplete()) {
                return;
            }

            LogSvc.debug('[AtcRegistrationSvc]: Received /registration/state event');
            if (state === RegistrationState.LoggingOut || state === RegistrationState.Reconnecting || state === RegistrationState.Disconnected) {
                // Cancel retry timer (if running)
                cancelRetryTimer();
                // If needed, the TC will be reassigned during reconnection
                cancelRenewTimer();
                // Allow others to process the /registration/state event before changing the telephony state
                $timeout($rootScope.localUser.isRegisterTC ? atcUnregister : function () {
                    _telephonyData.state = Constants.TrunkState.DOWN;
                    _presenceSubscribedTrunkId = null;
                    _trunkState = null;
                    _telephonyTrunkSubscriptions = {};
                    processTelephonyData(_telephonyData);
                });
            }
        });

        PubSubSvc.subscribe('/conversation/update', function (conversation) {
            if (conversation.isTelephony) {
                LogSvc.debug('[AtcRegistrationSvc]: Received /conversation/update event');
                _telephonyConversation = conversation;
                if (!_configurationUpdated && !_unregisteredEventReceived) {
                    return;
                }

                if (conversation.call) {
                    LogSvc.debug('[AtcRegistrationSvc]: There is still an active telephony call, wait...');
                } else if (_atcRegState !== AtcRegistrationState.Registering) {
                    if (_unregisteredEventReceived) {
                        setAtcState(AtcRegistrationState.ForcedUnregistered);
                        _unregisteredEventReceived = false;
                        return;
                    }
                    _configurationUpdated = false;
                    if (_atcRegState === AtcRegistrationState.Registered) {
                        _reRegister = true;
                        atcUnregister();
                    }
                }
            }
        });

        PubSubSvc.subscribe('/user/settings/update', function () {
            if (!ConversationSvc.isConversationsLoadComplete()) {
                return;
            }

            LogSvc.debug('[AtcRegistrationSvc]: Received /user/settings/update event');

            if (_savedRoutingOption !== $rootScope.localUser.selectedRoutingOption) {
                _savedRoutingOption = $rootScope.localUser.selectedRoutingOption;
                sendRouteToDeskInfoToAtc();
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Client API Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _clientApiHandler.on('User.TELEPHONY_DATA', function (evt) {
            $rootScope.$apply(function () {
                try {
                    var data = evt.data;
                    if (data) {
                        LogSvc.debug('[AtcRegistrationSvc]: Received User.TELEPHONY_DATA with data: ', data);
                        data.isGTCEnabled = true;
                        // Check if there are any changes
                        Object.keys(data).some(function (key) {
                            if (_telephonyData[key] !== data[key]) {
                                handleTelephonyDataChange(data);
                                return true;
                            }
                            return false;
                        });
                    }
                } catch (e) {
                    LogSvc.error('[AtcRegistrationSvc]: Exception handling User.TELEPHONY_DATA event.', e);
                }
            });
        });

        _clientApiHandler.on('User.USER_PRESENCE_CHANGE', function (data) {
            if (!data.userId) {
                // No need to process this event
                return;
            }
            if (data.userId === _presenceSubscribedTrunkId) {
                LogSvc.debug('[AtcRegistrationSvc]: Received User.USER_PRESENCE_CHANGE for associated TC. newState = ', data.newState);
                var newTrunkState = data.newState.state === Constants.PresenceState.AVAILABLE ? Constants.TrunkState.UP : Constants.TrunkState.DOWN;
                handlePresenceState(newTrunkState);
            }
            if (_telephonyTrunkSubscriptions[data.userId]) {
                LogSvc.info('[AtcRegistrationSvc]: Publish /trunk/update event. newState = ', data.newState);
                PubSubSvc.publish('/trunk/update', [data.newState]);
            }
        });

        _clientApiHandler.on('User.ATC_REFRESH_FAILED', function () {
            LogSvc.error('[AtcRegistrationSvc]: Received User.ATC_REFRESH_FAILED event.');
            processRefreshFailedEvent();
        });

        _userToUserHandler.on('ATC.REFRESH_FAILED', function () {
            LogSvc.error('[AtcRegistrationSvc]: Received UserToUser.ATC.REFRESH_FAILED event.');
            processRefreshFailedEvent();
        });

        _clientApiHandler.on('Account.TC_START_REASSIGNMENT', function (evt) {
            LogSvc.debug('[AtcRegistrationSvc]: Account.TC_START_REASSIGNMENT event');
            var maxThrottlingTime = Math.max((evt && evt.maxThrottlingTime) || MAX_RENEW_ASSOCIATED_TC_TIMER, MIN_RENEW_ASSOCIATED_TC_TIMER);

            // Set delay to a random time between MIN and MIN + maxThrottlingTime before sending the renew request
            var delayTime = MIN_RENEW_ASSOCIATED_TC_TIMER + Utils.randomNumber(0, maxThrottlingTime);
            renewAssociatedTC(true, delayTime);
        });

        _userToUserHandler.on('ATC.INFO', function (data) {
            LogSvc.debug('[AtcRegistrationSvc]: Received ATC.INFO with data = ', data);
            if ($rootScope.localUser && data.hasOwnProperty('pbxCallLogStatus')) {
                $rootScope.localUser.noCallLog = !!data.pbxCallLogStatus;
            }
        });

        _userToUserHandler.on('ATC.START_REASSIGNMENT', function () {
            LogSvc.debug('[AtcRegistrationSvc]: Received ATC.START_REASSIGNMENT event');
            renewAssociatedTC(false);
        });

        _userToUserHandler.on('ATC.UNREGISTERED', function () {
            LogSvc.error('[AtcRegistrationSvc]: Received UserToUser.ATC.UNREGISTERED event.');
            processUnregisteredEvent();
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Functions
        ///////////////////////////////////////////////////////////////////////////////////////

        // Used by SDK to initialize service
        this.initAtcForSdk = function () {
            LogSvc.debug('[AtcRegistrationSvc]: initAtcForSdk');
            _deviceType = Constants.DeviceType.SDK;
            checkLocalUserConfiguration(true);
        };

        this.atcState = function () {
            return _atcRegState;
        };

        this.getAtcRegistrationData = function () {
            return _atcRegistrationData.configurationData;
        };

        /**
         * Retrieves the current telephony data.
         * @returns {Object} The cached telephony data
         */
        this.getTelephonyData = function (cb) {
            if (!cb) {
                var data = _atcRegState === AtcRegistrationState.Registering ? DEFAULT_TELEPHONY_DATA : _telephonyData;
                return Object.assign({}, data);
            }
            if (typeof cb === 'function') {
                // This API used to receive a callback as input
                if (_atcRegState === AtcRegistrationState.Registering) {
                    cb('ATC Registering');
                } else {
                    cb(null, Object.assign({}, _telephonyData));
                }
            }
            return null;
        };

        this.isExtendedAlertingSupported = function () {
            if (_atcRegistrationData && _atcRegistrationData.capabilities) {
                return _atcRegistrationData.capabilities.includes(Constants.AtcCapabilities.EXTENDED_ALERTING);
            }
            return false;
        };

        this.isClearConnectionBusySupported = function () {
            if (_atcRegistrationData && _atcRegistrationData.capabilities) {
                return _atcRegistrationData.capabilities.includes(Constants.AtcCapabilities.CLEAR_CONNECTION_BUSY);
            }
            return false;
        };

        this.subscribeConnectorPresence = function (ids, cb) {
            ids = ids.filter(function (id) { return !_telephonyTrunkSubscriptions[id]; });
            if (ids.length) {
                LogSvc.debug('[AtcRegistrationSvc]: Subscribe for connectors presence. ', ids);
                _clientApiHandler.subscribePresence(ids, function (err, states) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.error('[AtcRegistrationSvc]: Failed to subscribe to connectors presence. ', err);
                            cb && cb(err);
                        } else if (states) {
                            LogSvc.debug('[AtcRegistrationSvc]: Successfully subscribed for connectors presence');
                            states.forEach(function (userPresenceState) {
                                _telephonyTrunkSubscriptions[userPresenceState.userId] = true;
                            });
                            cb && cb(null, states);
                        }
                    });
                });
            }
        };

        this.unsubscribeConnectorPresence = function (ids, cb) {
            if (ids) {
                ids = ids.filter(function (id) {
                    if (_telephonyTrunkSubscriptions[id]) {
                        delete _telephonyTrunkSubscriptions[id];
                        return id !== _presenceSubscribedTrunkId;
                    }
                    return false;
                });
            } else {
                ids = Object.keys(_telephonyTrunkSubscriptions).filter(function (id) {
                    return id !== _presenceSubscribedTrunkId;
                });
                _telephonyTrunkSubscriptions = {};
            }
            if (ids.length) {
                LogSvc.debug('[AtcRegistrationSvc]: Unsubscribe for connectors presence. ', ids);
                _clientApiHandler.unsubscribePresence(ids, cb);
            }
        };

        this.isPBXCallLogSupported = function () {
            if (_atcRegistrationData && _atcRegistrationData.capabilities) {
                return _atcRegistrationData.capabilities.includes(Constants.AtcCapabilities.PBX_CALL_LOG);
            }
            return false;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.AtcRegistrationSvcImpl = AtcRegistrationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
