/* global Circuit */
/*exported PasswordMgmt*/

var PasswordMgmt = {};

(function () {
    'use strict';

    var logger = Circuit.logger;

    var DEFAULT_POLICY = {
        // Max password length - Not enforced at the moment
        maxLength: -1,
        // Min password length
        minLength: 8,
        // Number of the last passwords that cannot be matched
        numberOfLastPasswords: 8,
        // Number of required special characters
        numberOfSpecialChars: 1,
        // Number of required upper characters
        numberOfUpperChars: 1,
        // Number of required lower characters
        numberOfLowerChars: 1,
        // Number of required digits
        numberOfDigits: 1,
        // Max number of allowed repeated characters
        maxNumberOfRepeatedChars: 2,
        // Password is allowed to contains a space.
        // Regardless of this setting it is not allowed to start or end a password with a whitespace
        isSpaceAllowed: true
    };

    PasswordMgmt.ErrorType = Object.freeze({
        IMMEDIATE: 'IMMEDIATE',
        DELAYED: 'DELAYED',
        SERVER: 'SERVER'
    });

    PasswordMgmt.policyConfig = Object.assign({}, DEFAULT_POLICY);

    PasswordMgmt.setPolicy = function (policy) {
        PasswordMgmt.policyConfig = Object.assign({}, DEFAULT_POLICY, policy || {});
        logger.info('[PasswordMgmt]: Set password policy config to ', PasswordMgmt.policyConfig);
    };

    PasswordMgmt.getPolicy = function () {
        // API for mobile clients
        return PasswordMgmt.policyConfig;
    };

    PasswordMgmt.getInstructions = function (i18n) {
        var policy = PasswordMgmt.policyConfig;

        var res = 'res_PasswordInstructions';
        var params = [
            policy.minLength,
            policy.numberOfUpperChars,
            policy.numberOfLowerChars,
            policy.numberOfDigits,
            policy.numberOfSpecialChars
        ];
        if (i18n) {
            return i18n.localize(res, params);
        } else {
            return {
                res: res,
                params: params
            };
        }
    };

    PasswordMgmt.validate = function (password, attrs) {
        attrs = attrs || {};
        password = password || '';
        var upper = password.toUpperCase();
        var lower = password.toLowerCase();
        var firstName = attrs.firstName && attrs.firstName.toLowerCase();
        var lastName = attrs.lastName && attrs.lastName.toLowerCase();
        var email = attrs.email && attrs.email.toLowerCase();

        var policy = PasswordMgmt.policyConfig;
        var errors = [];

        // Must contain at least MIN characters
        if (policy.minLength > 0) {
            errors[0] = password.length < policy.minLength;
        }

        var hasSpace = false;
        var numSpecial = 0;
        var numUpper = 0;
        var numLower = 0;
        var numDigits = 0;
        var numRepeated = 0;
        var maxRepeated = 0;
        var prevChar = '';

        for (var i = 0; i < password.length; i++) {
            var currChar = password.charAt(i);
            if (currChar === prevChar) {
                numRepeated++;
                maxRepeated = Math.max(maxRepeated, numRepeated);
            } else {
                prevChar = currChar;
                numRepeated = 1;
            }

            if (currChar === ' ') {
                hasSpace = true;
            } else if (/^\d$/.test(currChar)) {
                numDigits++;
            } else if (upper.charAt(i) === lower.charAt(i)) {
                // If there is no different between uppercase and lowercase it must be a special character
                numSpecial++;
            } else if (upper.charAt(i) === currChar) {
                numUpper++;
            } else {
                numLower++;
            }
        }

        // Check number of special characters, upper-case, lower-case and digits
        errors[1] = (numSpecial < policy.numberOfSpecialChars) || (numUpper < policy.numberOfUpperChars) ||
            (numLower < policy.numberOfLowerChars) || (numDigits < policy.numberOfDigits);

        // If password includes a space check if it is allowed
        errors[2] = hasSpace && !policy.isSpaceAllowed;

        // Check if there are more than X repeated characters
        errors[3] = maxRepeated > policy.maxNumberOfRepeatedChars;

        // Check if user put his own name or family name anywhere in the password
        errors[4] = !!((firstName && lower.indexOf(firstName) !== -1) ||
            (lastName && lower.indexOf(lastName) !== -1));

        // Check if user put his own email
        errors[5] = !!(email && lower.indexOf(email) !== -1);

        // Check if user put leading or trailing spaces
        errors[6] = password !== password.trim();
        return errors;
    };

    PasswordMgmt.validateAndReturnError = function (password, attrs, i18n) {
        var policy = PasswordMgmt.policyConfig;
        var errors = PasswordMgmt.validate(password, attrs);

        var res, params, errorType;

        if (errors[2]) {
            // No spaces
            res = 'res_errorPasswordRule02';
            errorType = PasswordMgmt.ErrorType.IMMEDIATE;
        } else if (errors[3]) {
            // Max repeated characters
            var num = policy.maxNumberOfRepeatedChars + 1;
            res = 'res_errorPasswordRule03';
            params = [num, 'a'.repeat(num)];
            errorType = PasswordMgmt.ErrorType.IMMEDIATE;
        } else if (errors[4]) {
            // Cannot use first and last name
            res = 'res_errorPasswordRule04';
            errorType = PasswordMgmt.ErrorType.IMMEDIATE;
        } else if (errors[5]) {
            // Cannot user email
            res = 'res_errorPasswordRule05';
            errorType = PasswordMgmt.ErrorType.IMMEDIATE;
        } else if (errors[6]) {
            // No leading or trailing spaces
            res = 'res_errorPasswordRule06';
            errorType = PasswordMgmt.ErrorType.IMMEDIATE;
        } else if (errors[0] && errors[1]) {
            // Show instructions
            var data = PasswordMgmt.getInstructions();
            res = data.res;
            params = data.params;
            errorType = PasswordMgmt.ErrorType.DELAYED;
        } else if (errors[0]) {
            // Min length
            res = 'res_errorPasswordRule00';
            params = [policy.minLength];
            errorType = PasswordMgmt.ErrorType.DELAYED;
        } else if (errors[1]) {
            res = 'res_errorPasswordRule01';
            params = [
                policy.numberOfUpperChars,
                policy.numberOfLowerChars,
                policy.numberOfDigits,
                policy.numberOfSpecialChars
            ];
            errorType = PasswordMgmt.ErrorType.DELAYED;
        } else {
            // No errors
            return null;
        }

        if (i18n) {
            return {
                text: i18n.localize(res, params),
                errorType: errorType
            };
        } else {
            return {
                res: res,
                params: params,
                errorType: errorType
            };
        }
    };

}());
