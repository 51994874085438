var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var AudioVideoDeviceCategories = circuit.Constants.AudioVideoDeviceCategories;
    var RtcSessionController = circuit.RtcSessionController;
    var Utils = circuit.Utils;
    var VideoResolutionLevel = circuit.Enums.VideoResolutionLevel;
    var WebRTCAdapter = circuit.WebRTCAdapter;

    // eslint-disable-next-line max-params, max-lines-per-function
    function DeviceSettingsSvcImpl($rootScope, $q, $timeout, $window, LogSvc, PubSubSvc, DeviceHandlerSvc, CallControlSvc) { // NOSONAR
        LogSvc.debug('New Service: DeviceSettingsSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var ALL_CATEGORIES = [
            AudioVideoDeviceCategories.AUDIO_OUTPUT,
            AudioVideoDeviceCategories.RINGING_OUTPUT,
            AudioVideoDeviceCategories.AUDIO_INPUT,
            AudioVideoDeviceCategories.VIDEO_INPUT
        ];

        var AUDIO_INPUT_DEFAULT_SRC = {
            label: 'res_DefaultDeviceLabel',
            altLabel: 'res_DefaultMicrophone',
            noPermissionLabel: 'res_NoMicrophonesFound'
        };
        var VIDEO_INPUT_DEFAULT_SRC = {
            label: 'res_DefaultDeviceLabel',
            altLabel: 'res_DefaultCamera',
            noPermissionLabel: 'res_NoCamera'
        };
        var AUDIO_OUTPUT_DEFAULT_SRC = {
            label: 'res_DefaultDeviceLabel',
            altLabel: 'res_DefaultAudioOutput',
            noPermissionLabel: 'res_NoAudioOutput'
        };
        var RINGING_OUTPUT_DEFAULT_SRC = {
            label: 'res_DefaultDeviceLabel',
            altLabel: 'res_DefaultAudioOutput',
            noPermissionLabel: 'res_NoAudioOutput'
        };

        // getUserMedia errors
        var GUM_CONSTRAINT_ERROR = 'ConstraintNotSatisfiedError';
        var GUM_OVERCONSTRAINT_ERROR = 'OverconstrainedError';

        var GET_DEVICES_TIMOUT = 20000; // 20 seconds
        var GET_MEDIA_SOURCE_WAITING_TIMOUT = 250; // 0.25 second

        var _selectOutputDeviceEnabled = WebRTCAdapter.audioOutputSelectionSupported;
        var _openMediaStream = null;
        var _hasRetriedGetDevices = false;
        var _connectedDevices = {playback: [], recording: [], video: []};
        var _deviceChangeTimeout = null;
        var _retryGetDevicesTimeout = null;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function init() {
            // Get a initial list of media devices that are connected so we can track devices that are added/removed
            WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                _connectedDevices = {
                    playback: audioOutputDevices,
                    recording: audioSources,
                    video: videoSources
                };

                if (!audioSources || !audioSources.length) {
                    retryGetDevices();
                }

                registerOnDeviceChangeEvent();
            });
        }

        function registerOnDeviceChangeEvent() {
            if (!$window.navigator.mediaDevices) {
                return;
            }

            // Listen for USB media device changes
            $window.navigator.mediaDevices.ondevicechange = function (event) {
                if (event.type !== 'devicechange') {
                    return;
                }
                $timeout.cancel(_retryGetDevicesTimeout);
                _retryGetDevicesTimeout = null;

                $timeout.cancel(_deviceChangeTimeout);
                _deviceChangeTimeout = $timeout(function () {
                    _deviceChangeTimeout = null;
                    _hasRetriedGetDevices = false;
                    WebRTCAdapter.clearMediaSourcesCache();
                    getConnectedDevices();
                }, GET_MEDIA_SOURCE_WAITING_TIMOUT);
            };
        }

        function getConnectedDevices() {
            // Get currently connected devices and find out what changed
            WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                $rootScope.$apply(function () {
                    var added = {playback: [], recording: [], video: []};
                    var removed = {playback: [], recording: [], video: []};

                    inspectDeviceList(audioSources, _connectedDevices.recording, added.recording, removed.recording);
                    inspectDeviceList(videoSources, _connectedDevices.video, added.video, removed.video);
                    inspectDeviceList(audioOutputDevices, _connectedDevices.playback, added.playback, removed.playback);

                    if (!handleGetMediaSourcesResult(added.recording) && (!audioSources || !audioSources.length)) {
                        // Handle situation to show popup message only here to not annoy user in all other cases
                        LogSvc.debug('[DeviceSettingsSvc]: No recording device(s) are available, publish /infoMessage/show event');
                        PubSubSvc.publish('/infoMessage/show', [{
                            message: 'res_AccessToAudioInputDeviceFailed',
                            manual: true
                        }]);

                        // For some reason navigator.mediaDevices.enumerateDevices response can be received in more than 10 seconds.
                        // It is a bad case, so callback function called with empty values after waiting for 3 seconds.
                        // As result info message with no device displayed to user. Do another attempt on 30 seconds
                        // to hide the message in success case
                        retryGetDevices();
                    }

                    _connectedDevices = {
                        playback: audioOutputDevices,
                        recording: audioSources,
                        video: videoSources
                    };

                    if ((added.playback.length + added.recording.length + added.video.length) > 0) {
                        LogSvc.info('[DeviceSettingsSvc]: Media devices added:', added);
                        PubSubSvc.publish('/media/device/added', [added, _connectedDevices]);

                        // If a device was added, we need to trigger a media renegotiation, since it could be a
                        // default device and it might have to be used by this call
                        var call = CallControlSvc.getActiveCall();
                        if (call) {
                            LogSvc.info('[DeviceSettingsSvc]: Device added: Renegotiate media so the added device(s) can be used for this call:', call.callId);
                            CallControlSvc.renegotiateMedia(call.callId, function (err) {
                                handleRenegotiateMediaResponse(call, err);
                            }, { dontReuseAudioStream: true });
                        }
                    }
                    if ((removed.playback.length + removed.recording.length + removed.video.length) > 0) {
                        LogSvc.info('[DeviceSettingsSvc]: Media devices removed:', removed);
                        PubSubSvc.publish('/media/device/removed', [removed, _connectedDevices]);
                    }
                });
            });
        }

        function handleGetMediaSourcesResult(recording) {
            if ((!_connectedDevices.recording || !_connectedDevices.recording.length) && recording.length) {
                // Mic(s) became available, send '/infoMessage/close' event in case we have a 'No mic available' message up
                LogSvc.debug('[DeviceSettingsSvc]: Recording device(s) became available, publish /infoMessage/close event');
                PubSubSvc.publish('/infoMessage/close', ['res_AccessToAudioInputDeviceFailed']);
                return true;
            }
            return false;
        }

        function retryGetDevices() {
            if (_hasRetriedGetDevices || _retryGetDevicesTimeout) {
                return;
            }

            _retryGetDevicesTimeout = $timeout(function () {
                LogSvc.debug('[DeviceSettingsSvc]: Another attempt to retrieve device list');
                _retryGetDevicesTimeout = null;
                _hasRetriedGetDevices = true;
                getConnectedDevices();
            }, GET_DEVICES_TIMOUT);
        }

        function isSameDevice(device, id) {
            // Id of unset/default device is initially null/undefined so it should equal
            // device without id (fake default device) or a real default device (with id default)
            return device && ((!id && (!device.id || device.id === 'default')) || id === device.id);
        }

        /**
         * Method to return which devices were added and/or removed
         *
         * @param {Array} source - Current list of devices
         * @param {Array} previous - Previous list of devices
         * @param {Array} added - Devices added between current and previous list
         * @param {Array} removed - Devices removed between current and previous list
         * @returns {undefined}
         */
        function inspectDeviceList(source, previous, added, removed) {
            if (!source || !previous || !added || !removed) {
                return;
            }
            previous.forEach(function (d) {
                removed.push(d.id);
            });
            source.forEach(function (d) {
                var index = removed.indexOf(d.id);
                if (index >= 0) {
                    removed.splice(index, 1);
                } else {
                    added.push(d);
                }
            });
        }

        function getSourceFromId(sources, id) {
            if (id && Array.isArray(sources)) {
                return sources.find(function (src) {
                    return isSameDevice(src, id);
                });
            }
            return null;
        }

        function preprocessSources(sources, defaultSource) {
            // remove duplicates (e.g. Firefox)
            var filteredSources = sources.filter(function (elem, index, self) {
                return index === self.findIndex(function (fiElem) { return fiElem.id === elem.id; });
            });
            // check default device entry
            var defSrc = getSourceFromId(filteredSources, 'default');
            if (!defSrc) {
                filteredSources.unshift(defaultSource);
            } else {
                // assure that label for default is always the same
                defSrc.label = defaultSource.label;
                defSrc.altLabel = defaultSource.altLabel;
            }
            return filteredSources;
        }

        function applySources(category, catSources) {
            catSources = catSources || [];
            var checkPermissions = catSources.find(function (src) { return !src.label; });
            var filteredSources;
            if (!checkPermissions) {
                // remove duplicates (e.g. Firefox)
                filteredSources = preprocessSources(catSources, category.defaultSrc);
            } else {
                // add default source for audio output category
                filteredSources = [];
                if (category.id === AudioVideoDeviceCategories.AUDIO_OUTPUT || category.id === AudioVideoDeviceCategories.RINGING_OUTPUT) {
                    filteredSources.push(category.defaultSrc);
                }
            }
            filteredSources.forEach(function (s) {
                s.categoryId = category.id;
            });
            category.sources = filteredSources;
            category.checkPermissions = checkPermissions;

            var currentSelected;
            switch (category.id) {
            case AudioVideoDeviceCategories.AUDIO_OUTPUT:
                currentSelected = Utils.selectMediaDevice(filteredSources, RtcSessionController.playbackDevices);
                break;
            case AudioVideoDeviceCategories.RINGING_OUTPUT:
                currentSelected = Utils.selectMediaDevice(filteredSources, RtcSessionController.ringingDevices);
                break;
            case AudioVideoDeviceCategories.AUDIO_INPUT:
                currentSelected = Utils.selectMediaDevice(filteredSources, RtcSessionController.recordingDevices);
                break;
            case AudioVideoDeviceCategories.VIDEO_INPUT:
                currentSelected = Utils.selectMediaDevice(filteredSources, RtcSessionController.videoDevices);
                break;
            }

            var deviceInfo = getSourceFromId(category.sources, currentSelected && currentSelected.id);
            category.setSelectedDevice(deviceInfo);
            category.initialDeviceId = deviceInfo && category.selectedDevice.id;
            return category;
        }

        function createCategory(catId, catName, catDefaultSrc) {
            var category = {
                id: catId,
                name: catName,
                defaultSrc: catDefaultSrc,
                sources: catDefaultSrc ? [catDefaultSrc] : [],
                setSelectedDevice: function (device) {
                    if (device) {
                        device.categoryId = this.id;
                    }
                    // Note that the selected device can be null, which means there's no preferred device or it hasn't been found (unplugged)
                    this.selectedDevice = device;
                }
            };
            category.setSelectedDevice(category.defaultSrc);
            return category;
        }

        function findMatchingOutputDevice(inputDev, audioOutCategory) {
            return audioOutCategory.sources.find(function (dev) {
                var inputId = inputDev.id || '';
                var outputId = dev.id || '';
                if (inputId.match(/default|communications/) || outputId.match(/default|communications/)) {
                    return inputDev.id === dev.id;
                }
                return inputDev.groupId === dev.groupId;
            });
        }

        function matchInputOutputDevices(category, audioOutCategory, audioInpCategory) {
            category.checkPermissions = audioInpCategory.checkPermissions;
            audioInpCategory.sources.forEach(function (input) {
                var output = findMatchingOutputDevice(input, audioOutCategory);
                if (output) {
                    var inputSplit = (input.label && input.label.split(/\s/)) || [];
                    var outputSplit = (output.label && output.label.split(/\s/)) || [];

                    var deviceLabel = '';
                    inputSplit.forEach(function (w) {
                        if (outputSplit.includes(w)) {
                            deviceLabel += (deviceLabel ? ' ' : '') + w;
                        }
                    });

                    var regexMatch = deviceLabel.match(/^(\()*(\w.*\w)[\s-]*(\))*$/);
                    if (regexMatch) {
                        // Remove parentheses and dangling dashes at the end
                        deviceLabel = regexMatch[2];
                        if (regexMatch[3] && !regexMatch[1]) {
                            // Add a closing parenthesis back, since the label doesn't begin with parenthesis
                            deviceLabel += ')';
                        }
                    }
                    if (!deviceLabel) {
                        // There's nothing common between the 2 labels, so display a combination of them
                        deviceLabel = input.label + '/' + output.label;
                    }
                    var combinedDevice = {
                        id: input.id + output.id,
                        inputDev: input,
                        outputDev: output,
                        altLabel: deviceLabel,
                        label: deviceLabel
                    };
                    LogSvc.debug('[DeviceSettingsSvc]: Found matching input and output devices: ', combinedDevice);
                    if (deviceLabel === 'res_DefaultDeviceLabel') {
                        category.defaultSrc = combinedDevice;
                    }
                    if (isSameDevice(audioInpCategory.selectedDevice, input.id) &&
                        isSameDevice(audioOutCategory.selectedDevice, output.id)) {
                        category.setSelectedDevice(combinedDevice);
                        category.initialDeviceId = combinedDevice.id;
                    }
                    category.sources.push(combinedDevice);
                }
            });
        }

        function createAndFetchAudioOutput(fetchCategories) {
            var cat = createCategory(AudioVideoDeviceCategories.AUDIO_OUTPUT, 'res_AudioOutput', AUDIO_OUTPUT_DEFAULT_SRC);
            fetchCategories[AudioVideoDeviceCategories.AUDIO_OUTPUT] = cat;
            return cat;
        }

        function createAndFetchAudioInput(fetchCategories) {
            var cat = createCategory(AudioVideoDeviceCategories.AUDIO_INPUT, 'res_Microphone', AUDIO_INPUT_DEFAULT_SRC);
            fetchCategories[AudioVideoDeviceCategories.AUDIO_INPUT] = cat;
            return cat;
        }

        function handleRenegotiateMediaResponse(call, error) {
            if (error) {
                LogSvc.warn('[DeviceSettingsSvc]: Media renegotiation failed: ', error);
                PubSubSvc.publish('/call/changeMediatype/failed', [call, error]);
            }
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /*
         * Fetches the available devices grouped by categories (microphone, camera, audio output and ringing).
         * @param {Array} limitCategories Use when only a subset of the available device categories is needed.
         * @return  {Promise} A promise which resolve to device category list. Promise uses notify to provide optional initial data.
         */
        this.fetchDevices = function (limitCategories) {
            var deferred = $q.defer();
            $timeout(function () {
                limitCategories = limitCategories || ALL_CATEGORIES;
                var fetchCategories = {};
                var finalCategories = [];
                var audioOutCategory, audioInpCategory, audioDeviceCategory;

                if (limitCategories.includes(AudioVideoDeviceCategories.AUDIO_DEVICE)) {
                    // For AUDIO_DEVICE category, we need to fetch AUDIO_OUTPUT and AUDIO_INPUT
                    audioDeviceCategory = createCategory(AudioVideoDeviceCategories.AUDIO_DEVICE, 'res_AudioDevices');
                    finalCategories.push(audioDeviceCategory);
                    audioOutCategory = createAndFetchAudioOutput(fetchCategories);
                    audioInpCategory = createAndFetchAudioInput(fetchCategories);
                } else {
                    if (limitCategories.includes(AudioVideoDeviceCategories.AUDIO_OUTPUT) && _selectOutputDeviceEnabled) {
                        audioOutCategory = createAndFetchAudioOutput(fetchCategories);
                    }
                    if (limitCategories.includes(AudioVideoDeviceCategories.AUDIO_INPUT)) {
                        audioInpCategory = createAndFetchAudioInput(fetchCategories);
                    }
                }

                if (limitCategories.includes(AudioVideoDeviceCategories.RINGING_OUTPUT) && _selectOutputDeviceEnabled) {
                    var ringingCategory = createCategory(AudioVideoDeviceCategories.RINGING_OUTPUT, 'res_RingingOutput', RINGING_OUTPUT_DEFAULT_SRC);
                    fetchCategories[AudioVideoDeviceCategories.RINGING_OUTPUT] = ringingCategory;
                }

                if (limitCategories.includes(AudioVideoDeviceCategories.VIDEO_INPUT)) {
                    var videoInpCategory = createCategory(AudioVideoDeviceCategories.VIDEO_INPUT, 'res_Camera', VIDEO_INPUT_DEFAULT_SRC);
                    fetchCategories[AudioVideoDeviceCategories.VIDEO_INPUT] = videoInpCategory;
                }

                limitCategories.forEach(function (cat) {
                    var fetch = fetchCategories[cat];
                    if (fetch) {
                        finalCategories.push(fetch);
                    }
                });
                deferred.notify(finalCategories);

                WebRTCAdapter.getMediaSources(function (audioSources, videoSources, audioOutputDevices) {
                    handleGetMediaSourcesResult(audioSources);
                    _connectedDevices = {
                        playback: audioOutputDevices,
                        recording: audioSources,
                        video: videoSources
                    };
                    Object.keys(fetchCategories).forEach(function (k) {
                        var category = fetchCategories[k];
                        switch (category.id) {
                        case AudioVideoDeviceCategories.AUDIO_OUTPUT:
                        case AudioVideoDeviceCategories.RINGING_OUTPUT:
                            applySources(category, audioOutputDevices);
                            break;
                        case AudioVideoDeviceCategories.AUDIO_INPUT:
                            applySources(category, audioSources);
                            break;
                        case AudioVideoDeviceCategories.VIDEO_INPUT:
                            applySources(category, videoSources);
                            break;
                        }
                    });

                    if (audioDeviceCategory) {
                        matchInputOutputDevices(audioDeviceCategory, audioOutCategory, audioInpCategory);
                    }

                    // Close media stream if exists (from triggerPermissionRequest)
                    if (_openMediaStream) {
                        WebRTCAdapter.stopMediaStream(_openMediaStream);
                        _openMediaStream = null;
                    }
                    deferred.resolve(finalCategories);
                });
            });
            return deferred.promise;
        };

        /*
         * Sets the selected devices in the appropriate services and start media renegotiation in
         * case of an active call (and changed device).
         * @param {Array} selectedDevices A list of selected devices.
         */
        this.setDevices = function (selectedDevices) {
            selectedDevices = selectedDevices || [];
            var needsRenegotiation = false, publishEvent = false;
            var call = CallControlSvc.getActiveCall() || CallControlSvc.getIncomingCall();
            var savedDevice;

            var selectedAudioDevice = selectedDevices.find(function (s) {
                return s && s.categoryId === AudioVideoDeviceCategories.AUDIO_DEVICE;
            });
            if (selectedAudioDevice) {
                // Automatically select input and output and ignore the AUDIO_INPUT and AUDIO_OUTPUT
                // members in selectedDevices
                selectedDevices = selectedDevices.filter(function (s) {
                    return s && s.categoryId !== AudioVideoDeviceCategories.AUDIO_OUTPUT &&
                        s.categoryId !== AudioVideoDeviceCategories.AUDIO_INPUT &&
                        s.categoryId !== AudioVideoDeviceCategories.AUDIO_DEVICE;
                });
                selectedDevices.push(selectedAudioDevice.outputDev);
                selectedDevices.push(selectedAudioDevice.inputDev);
            }
            selectedDevices.forEach(function (selectedDevice) {
                if (selectedDevice && selectedDevice.categoryId) {
                    switch (selectedDevice.categoryId) {
                    case AudioVideoDeviceCategories.AUDIO_OUTPUT:
                        savedDevice = RtcSessionController.playbackDevices[0] || {};
                        if (!isSameDevice(selectedDevice, savedDevice.id)) {
                            DeviceHandlerSvc.setPlaybackDevice(selectedDevice);
                            publishEvent = needsRenegotiation = true;
                        }
                        break;
                    case AudioVideoDeviceCategories.RINGING_OUTPUT:
                        savedDevice = RtcSessionController.ringingDevices[0] || {};
                        if (!isSameDevice(selectedDevice, savedDevice.id)) {
                            DeviceHandlerSvc.setRingingDevice(selectedDevice);
                            publishEvent = true;
                        }
                        break;
                    case AudioVideoDeviceCategories.AUDIO_INPUT:
                        savedDevice = RtcSessionController.recordingDevices[0] || {};
                        if (!isSameDevice(selectedDevice, savedDevice.id)) {
                            DeviceHandlerSvc.setRecordingDevice(selectedDevice);
                            publishEvent = needsRenegotiation = true;
                        }
                        break;
                    case AudioVideoDeviceCategories.VIDEO_INPUT:
                        savedDevice = RtcSessionController.videoDevices[0] || {};
                        if (!isSameDevice(selectedDevice, savedDevice.id)) {
                            DeviceHandlerSvc.setVideoDevice(selectedDevice);
                            // Media renegotiation is only needed if the user is sending video
                            needsRenegotiation = needsRenegotiation || (call && !!call.localMediaType.video);
                            publishEvent = true;
                        }
                    }
                }
            });
            if (call && needsRenegotiation) {
                LogSvc.info('[DeviceSettingsSvc]: Trigger a media renegotiation for the changes to take effect');
                CallControlSvc.renegotiateMedia(call.callId, function (err) {
                    handleRenegotiateMediaResponse(call, err);
                });
            }
            if (publishEvent) {
                LogSvc.info('[DeviceSettingsSvc]: Device selection changed. Publish /media/device/updated event');
                PubSubSvc.publish('/media/device/updated');
            }
        };

        /*
         * Triggers the browsers permission request popup.
         * @param {function} cb Callback when user allowed or rejected access to media.
         * @param {object} constraints Optional constraint field to specify which media should be requested.
         */
        this.triggerPermissionRequest = function (cb, categoryId) {
            var constraints = {};
            if (categoryId === AudioVideoDeviceCategories.VIDEO_INPUT) {
                LogSvc.info('[DeviceSettingsSvc]: Trigger permission request for camera');
                constraints.video = true;
            } else {
                LogSvc.info('[DeviceSettingsSvc]: Trigger permission request for microphone');
                constraints.audio = true;
            }
            DeviceHandlerSvc.getUserMedia(constraints, function (stream) {
                // stream must kept open until the device list was fetched
                // this is only for Firefox and its AllowOnce
                _openMediaStream = stream;
                cb && cb();
            }, function (err) {
                err = err || {name: 'unknown'};
                var msg = err.name + (err.message ? ' (' + err.message + ')' : '');
                LogSvc.warn('[DeviceSettingsSvc]: triggerPermissionRequest failed: ', msg);
                cb && cb(err);
            });
        };

        /*
         * Define the max supported camera resolution
         * @param {array} videoDevices Array of video devices. By default we use RtcSessionController.videoDevices.
         * @returns {Promise} A promise with the max camera resolution for the specified video device(s).
         */
        this.getMaxCameraResolution = function (videoDevices) {
            return new $q(function (resolve, reject) {
                videoDevices = videoDevices || RtcSessionController.videoDevices;

                LogSvc.debug('[DeviceSettingsSvc]: Get max camera resolution. videoDevices = ', videoDevices);
                WebRTCAdapter.getMediaSources(function (audioSources, videoSources) {
                    if (!videoSources || !videoSources.length) {
                        reject('No cameras available');
                        return;
                    }

                    var resolutions = Object.values(VideoResolutionLevel);
                    var videoDevice = Utils.selectMediaDevice(videoSources, videoDevices);
                    var config = videoDevice ? { sourceId: videoDevice.id } : {};
                    var constraints = { audio: false };

                    // Perform this function until proposed resolution can be used with camera
                    var getLocalMedia = function () {
                        config.videoResolution = resolutions.shift();
                        constraints.video = WebRTCAdapter.getVideoOptions(config);

                        WebRTCAdapter.getUserMedia(constraints, function (stream) {
                            WebRTCAdapter.stopMediaStream(stream);
                            LogSvc.debug('[DeviceSettingsSvc]: Max camera resolution is ', config.videoResolution);
                            resolve(config.videoResolution);
                        }, function (error) {
                            var errorName = (error && error.name) || 'Unspecified';
                            if (resolutions.length > 0 && (errorName === GUM_CONSTRAINT_ERROR || errorName === GUM_OVERCONSTRAINT_ERROR)) {
                                LogSvc.debug('[DeviceSettingsSvc]: Failed to access local media with constraints: ', constraints.video);
                                getLocalMedia();
                                return;
                            }
                            LogSvc.warn('[DeviceSettingsSvc]: Max camera resolution could not be determined. Error: ', errorName);
                            reject(errorName);
                        });
                    };

                    getLocalMedia();
                });
            });
        };

        /*
         * Checks if device matches the given Id. Assures correct identification of default device
         * (id may be null or 'default')
         * @param {object} device The device to check.
         * @param {string} id The ID that the device should be matched with.
         * @return {boolean} true if device matches ID.
         */
        this.isSameDevice = isSameDevice;

        /* Get source from ID
         *
         * @param {array} sources The device sources to check.
         * @param {string} id The device ID.
         * @return {object} source If device matches ID.
         */
        this.getSourceFromId = getSourceFromId;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initializations
        ///////////////////////////////////////////////////////////////////////////////////////
        init();

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.DeviceSettingsSvcImpl = DeviceSettingsSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
