var Circuit = (function (circuit) {
    'use strict';

    circuit.ClientApiHandlerSingleton = (function () {
        var _clientApiHandler;
        return {
            getInstance: function () {
                if (!_clientApiHandler) {
                    _clientApiHandler = new circuit.ClientApiHandler();
                }
                return _clientApiHandler;
            }
        };
    })();

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
