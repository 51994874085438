var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var logger = circuit.logger;
    var PhoneNumberFormatter = circuit.PhoneNumberFormatter;
    var UserProfile = circuit.UserProfile;
    var Utils = circuit.Utils;

    var CallLogItemType = Object.freeze({
        BUSY: 'BUSY',
        DECLINED: 'DECLINED',
        FAILED: 'FAILED',
        INCOMING: 'INCOMING',
        MISSED: 'MISSED',
        NOT_ANSWERED: 'NOT_ANSWERED',
        OUTGOING: 'OUTGOING',
        REDIRECTED: 'REDIRECTED',
        UNKNOWN: 'UNKNOWN'
    });

    var RtcItemState = Object.freeze({
        NOT_ANSWERED: 'NOT_ANSWERED',
        MISSED: 'MISSED',
        DECLINED: 'DECLINED',
        ENDED: 'ENDED',
        FAILED: 'FAILED',
        MOVED: 'MOVED',
        BUSY: 'BUSY',
        INVALID_NUMBER: 'INVALID_NUMBER',
        TEMPORARILY_UNAVAILABLE: 'TEMPORARILY_UNAVAILABLE',
        UNKNOWN: 'UNKNOWN'
    });

    var EXTENDED_ATTRIBUTES = [
        'callLogType',
        'rtcState',
        'duration',
        'hasVoicemail',
        'isVoicemailUnheard',
        'i18nCallLogTypeRes',
        'peerUser',
        'redirectedToUser',
        'redirectingUser',
        'recordingUploadState'
    ];

    // Define the object
    var CallLogItem = {};

    // Extend object via prototypical inheritance
    CallLogItem.extend = function (obj) {
        if (typeof circuit.Conversation.getExtendedUser !== 'function') {
            throw new Error('The Conversation object is not ready for use');
        }

        if (!obj) {
            logger.error('[CallLogItem]: CallLogItem.extend invoked with null object or null conversation');
            return null;
        }

        if (obj.type !== Constants.ConversationItemType.RTC) {
            logger.warn('[CallLogItem]: CallLogItem.extend invoked with non-RTC itme');
            return null;
        }

        if (obj.isExtended) {
            // The given object is already an extended conversation
            return obj;
        }

        normalizeBaseObj(obj);

        // Create a derived object to be extended and returned.
        var item = Object.create(obj);
        item.isExtended = true;

        // Was the call initiated by me?
        item.sentByMe = circuit.Conversation.localUser.userId === obj.creatorId;

        item.callLogType = getCallLogType(item);
        item.rtcState = getRtcItemState(item.rtc, item.sentByMe);
        item.duration = item.rtc.ended && item.rtc.ended.duration ? Math.floor(item.rtc.ended.duration / 1000) : 0;
        item.hasVoicemail = !!item.attachments && item.attachments.length > 0;
        item.isVoicemailUnheard = item.hasVoicemail && !!item.rtc.unheardVoicemail;
        item.i18nCallLogTypeRes = 'res_CallHistoryType_' + item.callLogType;

        setTelephonyUsers(item);

        // Move recordingUploadState to base object
        item.recordingUploadState = item.rtc.recordingUploadState;

        return item;
    };

    // Update a dst CallLogItem with new information from src CallLogItem
    CallLogItem.update = function (dst, src) {
        if (!dst || !src || dst.itemId !== src.itemId || (src.type !== Constants.ConversationItemType.RTC)) {
            return;
        }
        if (!src.isExtended) {
            src = CallLogItem.extend(src);
            if (!src) {
                return;
            }
        }

        // Start by copying the base object fields that can change
        dst.modificationTime = src.modificationTime;
        dst.rtc = src.rtc;
        dst.attachments = src.attachments;

        EXTENDED_ATTRIBUTES.forEach(function (name) {
            dst[name] = src[name];
        });

        Utils.syncBaseObject(dst);
    };

    /**
     * Insert an item in chronologically order and return the index where the item was inserted.
     */
    CallLogItem.insertItem = function (items, item) {
        if (!item || !Array.isArray(items)) {
            return -1;
        }
        // The items must be ordered chronologically. Newest items go to the end.
        // Find the position in the items array.
        var length = items.length;

        if (length === 0) {
            items.push(item);
            return 0;
        }

        if (itemComparatorFn(item, items[0]) < 0) {
            // Insert at the front
            items.unshift(item);
            return 0;
        }

        if (itemComparatorFn(item, items[length - 1]) > 0) {
            // Insert at the end
            items.push(item);
            return length;
        }

        // The truth lies somewhere in between...
        return Utils.binaryInsert(items, item, itemComparatorFn, 'itemId');
    };

    /**
     * Update an item inside items with new information.
     * Returns the updated item if successful
     */
    CallLogItem.updateItem = function (conversation, newItem) {
        var items = conversation && conversation.items;
        if (!newItem || !items || !items.length) {
            return;
        }
        items.some(function (item, idx) {
            if (item.itemId === newItem.itemId) {
                items[idx] = newItem;
                return true;
            }
            return false;
        });
    };

    ///////////////////////////////////////////////////////////////////////////
    // Helper functions for CallLogItem.extend
    ///////////////////////////////////////////////////////////////////////////
    function itemComparatorFn(a, b) {
        if (a.creationTime < b.creationTime) {
            return -1;
        } else if (a.creationTime > b.creationTime) {
            return 1;
        }
        return 0;
    }

    function extendAttachment(a) {
        if (!a) { return; }

        a.isOld = true;

        // We need to build the URI as follows: "/fileapi?fileid=<File ID>&itemid=<Item ID>"
        a.uri = circuit.__domain + '/fileapi?fileid=' + a.fileId;
        // We also need to encode the single quote character so it works properly with the
        // ng-style directive
        a.uri = a.uri.replace(/'/g, '%27');

        a.deleteUrl = circuit.__domain + '/fileapi?fileid=' + a.fileId;
        if (a.itemId) {
            a.uri += '&itemid=' + a.itemId;
            a.deleteUrl += '&itemid=' + a.itemId;
        }

        a.sizeStr = Utils.bytesToSize(a.size);
        a.fileNameTruncated = Utils.truncateFileName(a.fileName);
    }

    function normalizeBaseObj(obj) {
        // Initialize some conversation item fields that are optional in the proto
        // files, but must be initialized for the clients.
        if (obj.dataRetentionState === Constants.DataRetentionState.UNTOUCHED) {
            // Since UNTOUCHED is the default value we can remove it to reduce memory utilization.
            delete obj.dataRetentionState;
        }

        switch (obj.rtc.type) {
        case Constants.RTCItemType.MISSED:
        case Constants.RTCItemType.STARTED:
        case Constants.RTCItemType.ENDED:
            // Call missed and call summary items may be updated with a voicemail or recording, so
            // ensure that the base object has an attachments property.
            obj.attachments = obj.attachments || [];
            obj.attachments.forEach(extendAttachment);
        }

        obj.modificationTime = obj.modificationTime || obj.creationTime;
    }

    function setTelephonyUsers(item) {
        var participants = item.rtc.rtcParticipants;
        if (!participants) {
            return;
        }

        var localUserParticipant;
        var otherParticipants = participants.filter(function (p) {
            var isLocalUser = p.userId === circuit.Conversation.localUser.userId;
            if (isLocalUser) {
                p.resolvedUser = p.type !== Constants.RTCParticipantType.USER;
                if (p.telephonyInfo) {
                    // When telephony info is included with localUser, telephonyInfo.redirectingUser
                    // contains the actual Redirecting user.
                    item.redirectingUser = extractRedirectionInfo(p);
                }
                localUserParticipant = p;
            }
            return !isLocalUser;
        });

        if (item.rtc.missed && item.rtc.missed.pickingUpParticipant) {
            item.rtc.missed.pickingUpParticipant.resolvedUser = true;
            item.redirectedToUser = getUser(item.rtc.missed.pickingUpParticipant);
        } else if (item.rtc.ended && item.rtc.ended.pickFromParticipant) {
            item.rtc.ended.pickFromParticipant.resolvedUser = true;
            item.redirectingUser = getUser(item.rtc.ended.pickFromParticipant);
        }

        if (otherParticipants.length === 0) {
            // For some scenarios, like when users calls themselves or OSBiz DSS call move,
            // the rtcParticipants only include the local user.
            item.peerUser = getUser(localUserParticipant);
            // In this case a redirecting user is actually a redirectedTo user
            if (item.redirectingUser) {
                item.redirectedToUser = item.redirectingUser;
                delete item.redirectingUser;
            }
            return;
        }

        // Currently we don't support call logs with more than one user, so just get the first participant
        var participant = otherParticipants[0];

        var peerUser = getUser(participant);
        // When telephony info is included with the other user, telephonyInfo.redirectingUser
        // contains the RedirectedTo user.
        var redirectedToUser = extractRedirectionInfo(participant);

        item.isRedirected = false;
        if (item.sentByMe) {
            // This is a dialed call
            if (redirectedToUser) {
                // Set the Redirected-To user as the peer
                item.peerUser = redirectedToUser;
                item.redirectingUser = peerUser;
            } else {
                item.peerUser = peerUser;
            }
        } else {
            // This is a received call
            item.peerUser = peerUser;
            if (redirectedToUser) {
                // Local user has redirected the call
                item.isRedirected = true;
                item.redirectedToUser = redirectedToUser;
            }
        }
    }

    function extractRedirectionInfo(participant) {
        if (participant.telephonyInfo) {
            return getUser(participant.telephonyInfo.redirectingUser);
        }
        return null;
    }

    function getUser(participant) {
        if (!participant) {
            return null;
        }
        var user;
        var resolvedUserId = participant.resolvedUserId || (participant.resolvedUser && participant.userId);
        if (resolvedUserId) {
            user = circuit.Conversation.getExtendedUser(resolvedUserId);
            // Create a new object with user as prototype
            user = user && Object.create(user);
        } else {
            user = Object.assign({}, participant);
            if (user.displayName === user.phoneNumber) {
                // Remove the displayName
                user.displayName = '';
            }
            user.userType = Constants.UserType.TELEPHONY;
            user = UserProfile.extend(user);
        }
        if (user) {
            user.isDialable = Utils.PHONE_PATTERN.test(participant.phoneNumber) || Utils.PHONE_PAC.test(participant.phoneNumber);
            user.phoneNumber = PhoneNumberFormatter.format(participant.phoneNumber);
        }
        return user;
    }

    function getCallLogType(item) {
        if (item.isRedirected) {
            return CallLogItemType.REDIRECTED;
        }

        switch (item.rtc.type) {
        case Constants.RTCItemType.ENDED:
            return item.sentByMe ? CallLogItemType.OUTGOING : CallLogItemType.INCOMING;

        case Constants.RTCItemType.MISSED:
            if (item.rtc.missed) {
                switch (item.rtc.missed.status) {
                case Constants.RTCItemMissed.DECLINED:
                    return CallLogItemType.DECLINED;

                case Constants.RTCItemMissed.INVALID_NUMBER:
                case Constants.RTCItemMissed.TEMPORARILY_UNAVAILABLE:
                case Constants.RTCItemMissed.UNREACHABLE:
                    if (item.sentByMe) {
                        return CallLogItemType.FAILED;
                    }
                    break;
                case Constants.RTCItemMissed.USER_BUSY:
                    return CallLogItemType.BUSY;
                }
            }
            return item.sentByMe ? CallLogItemType.NOT_ANSWERED : CallLogItemType.MISSED;
        }
        return CallLogItemType.UNKNOWN;
    }

    function getRtcItemState(rtc, sentByMe) {
        switch (rtc.type) {
        case Constants.RTCItemType.ENDED:
            return RtcItemState.ENDED;

        case Constants.RTCItemType.MISSED:
            if (rtc.missed) {
                switch (rtc.missed.status) {
                case Constants.RTCItemMissed.DECLINED:
                    return RtcItemState.DECLINED;
                case Constants.RTCItemMissed.USER_BUSY:
                    return RtcItemState.BUSY;
                case Constants.RTCItemMissed.INVALID_NUMBER:
                    return RtcItemState.INVALID_NUMBER;
                case Constants.RTCItemMissed.TEMPORARILY_UNAVAILABLE:
                    return RtcItemState.TEMPORARILY_UNAVAILABLE;
                case Constants.RTCItemMissed.UNREACHABLE:
                    if (sentByMe) {
                        return RtcItemState.UNKNOWN;
                    }
                    break;
                }
            }
            return sentByMe ? RtcItemState.NOT_ANSWERED : RtcItemState.MISSED;

        case Constants.RTCItemType.MOVED:
            return RtcItemState.MOVED;
        }
        return RtcItemState.UNKNOWN;
    }

    // Exports
    circuit.CallLogItem = CallLogItem;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.CallLogItemType = CallLogItemType;
    circuit.Enums.RtcItemState = RtcItemState;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
