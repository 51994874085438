/* global Promise */

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var IceCandidate = circuit.IceCandidate;
    var logger = circuit.logger;
    var sdpParser = circuit.sdpParser;
    var Utils = circuit.Utils;
    var VideoCodecs = circuit.Enums.VideoCodecs;
    var VideoResolutionLevel = circuit.Enums.VideoResolutionLevel;

    // CallStatsHandler and RtcPeerConnections cannot be imported here since they need to be spied upon during unit tests.

    var SdpStatus = Object.freeze({
        None: 'None',
        AnswerPending: 'Answer-Pending',
        AnswerSent: 'Answer-Sent',
        AnswerApplied: 'Answer-Applied',
        OfferPending: 'Offer-Pending',
        OfferSent: 'Offer-Sent',
        OfferReceived: 'Offer-Received',
        Connected: 'Connected',
        PrAnswerReceived: 'PrAnswer-Received',
        AnswerReceived: 'Answer-Received',
        Disconnected: 'Disconnected'
    });

    // Default timeout for waiting for candidates when Trickle ICE is not enabled
    // Increase ICE candidate collection timeout for mobile devices because there have been cases in the field
    // where it's taking more than the default 2s timeout.
    var DEFAULT_CANDIDATES_TIMEOUT = Utils.isMobile() ? 7000 : 2000;

    // Shorter timeout started when first relay candidate is received.
    // This is used exclusively for telephony calls which cannot support Trickle ICE.
    var SHORT_CANDIDATES_TIMEOUT = 500;

    var DEFAULT_TRICKLE_ICE_TIMEOUT = 200; // Default timeout waiting for candidates when Trickle ICE is enabled
    var DEFAULT_ENABLE_AUDIO_AGC = true;
    var DEFAULT_ENABLE_AUDIO_EC = true;

    // Default upscale factor for remote screen share video
    var DEFAULT_UPSCALE_FACTOR = 1.5;

    // getUserMedia errors
    var GUM_CONSTRAINT_ERROR = 'ConstraintNotSatisfiedError';
    var GUM_OVERCONSTRAINT_ERROR = 'OverconstrainedError';
    var GUM_DEVICE_IN_USE_CHROME_ERROR = 'TrackStartError';
    var GUM_DEVICE_IN_USE_ERROR = 'NotReadableError';

    var GumPermissionDeniedErrors = ['PermissionDeniedError', 'SecurityError', 'NotAllowedError'];

    // Timeout waiting for relay candidates over a TURN TCP/TLS connection
    var DESKTOP_RELAY_CANDIDATES_TIMEOUT = 5000;

    // Timeout before raising onIceDisconnected event
    var ICE_DISCONNECT_DELAY = 1000;

    var DEFAULT_VIDEO_CODEC = VideoCodecs.VP8;

    var DEGRADATION_MODE = Object.freeze({
        BALANCED: 'balanced',
        MAINTAIN_RESOLUTION: 'maintain-resolution',
        MAINTAIN_FRAMERATE: 'maintain-framerate'
    });

    var DEFAULT_SDP_PARAMS = Object.freeze({
        audio: {
            'maxplaybackrate': 24000,
            'sprop-maxcapturerate': 24000,
            'maxaveragebitrate': 16000,
            'usedtx': 1
        },
        lowVideo: {
            '1080p': {
                scaleFactor: 6 // 1920px to 320px
            },
            '720p': {
                scaleFactor: 4 // 1280px to 320px
            },
            '480p': {
                scaleFactor: 2.66875 // 854px to 320px
            },
            'maxBw': 100,
            'scaleFactor': 2 // 640px to 320px
        },
        video: {
            maxBw: 512
        },
        hdVideo: {
            '1080p': {
                maxBw: 2000
            },
            '720p': {
                maxBw: 1500
            },
            '480p': {
                maxBw: 512
            },
            'maxBw': 2000 // Default
        },
        screenShare: {
            maxBw: 600,
            minFrameRate: 3,
            maxFrameRate: 5
        },
        hdScreenShare: {
            maxBw: 2000,
            minFrameRate: 3,
            maxFrameRate: 7
        },
        rcScreenShare: {
            maxBw: 300,
            minFrameRate: 5,
            maxFrameRate: 15
        },
        receiveVideo: {
            maxBwMobile: 512,
            maxBw: 2000
        },
        rtcpMuxPolicy: 'require',
        preferredVideoCodec: DEFAULT_VIDEO_CODEC
    });

    var DEFAULT_XGOOGLE = Object.freeze({
        video: {
            minBitRate: 60
        },
        hdVideo: {
            // [CMR] -> Tweaking BW settings based on Chromecast reverse engineering SDP
            // https://www.bountysource.com/issues/877114-chromecast-video-output
            '1080p': {
                minBitRate: 1200,
                maxBitRate: 2000
            },
            '720p': {
                minBitRate: 750,
                maxBitRate: 1500
            },
            '480p': {
                minBitRate: 500,
                maxBitRate: 512
            },
            'startBitRate': 500,
            'minBitRate': 1000,
            'maxBitRate': 2000,
            'maxQuantization': 56
        },
        screenShare: {
            startBitRate: 300,
            minBitRate: 300,
            maxBitRate: 600,
            maxQuantization: 50
        },
        hdScreenShare: {
            startBitRate: 1000,
            minBitRate: 1000,
            maxBitRate: 2000,
            maxQuantization: 40
        }
    });

    var LOCAL_STREAMS = Object.freeze({
        AUDIO_VIDEO: 0,
        DESKTOP: 1
    });

    var HD_VIDEO_RESOLUTIONS = Object.values(VideoResolutionLevel);

    // Additional peer connections count to receive group call videos
    var MAX_VIDEO_EXTRA_CHANNELS = 3;
    var DEFAULT_VIDEO_EXTRA_CHANNELS = 3;

    function normalizeMediaType(mediaType) {
        mediaType = mediaType || {};
        mediaType = {
            audio: !!mediaType.audio,
            video: !!mediaType.video,
            hdVideo: !!mediaType.hdVideo,
            videoResolution: mediaType.videoResolution || null, // HD video resolution. Default is 1080p.
            desktop: !!mediaType.desktop,
            hdDesktop: !!mediaType.hdDesktop,
            minFrameRate: mediaType.minFrameRate || null, // Enforced min frame rate to overwrite default of 24.
            remoteControlEnabled: false
        };

        if (mediaType.videoResolution && (!mediaType.hdVideo || !HD_VIDEO_RESOLUTIONS.includes(mediaType.videoResolution))) {
            // HD video not enabled or not a valid HD video resolution
            mediaType.videoResolution = null;
        }
        return mediaType;
    }

    function DisabledRemoteOfferMLines() {
        var _list = {};
        return {
            add: function (index, mline) {
                _list[index] = mline;
            },
            get: function (index) {
                return _list[index];
            },
            remove: function (index) {
                delete _list[index];
            },
            getAll: function () {
                return _list;
            },
            removeAll: function () {
                _list = {};
            }
        };
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    // RtcSessionController
    //
    // This "class" encapsulates the WebRTC API calls and provides the common functionality
    // that is required by the UC Client Refresh and Ansible projects.
    //
    // In a high level, the RtcSessionController provides the following functionality:
    // - Gets and maintains the MediaStream object
    // - Encapsulates the calls to the RTCPeerConnection object
    // - Supports Trickle ICE (can be enabled or disabled)
    // - Handles Hold and Retrieve requests and maintains the holding/held status
    // - Handles Mute and Unmute requests and maintains current status
    // - Handles Add Video and Remove Video requests
    // - Handles media renegotiation requests
    // - Normalizes the local and remote session descriptions
    // - Keeps the local video URL
    // - Keeps the remote audio or video URL
    // - Provides an eventing interface similar to RTCPeerConnection
    //
    /////////////////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line max-lines-per-function
    function RtcSessionController(options) { // NOSONAR

        // The following imports need to be defined inside RtcSessionController due to JS-SDK
        var WebRTCAdapter = circuit.WebRTCAdapter;

        options = options || {};

        ///////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////
        var GUM_TIMEOUT = 120000; // Timeout waiting for mic or camera to respond
        var CONNECTED_TIMEOUT = 15000; // Timeout waiting for connection state 'connected' on all peer connections

        var MOCK_CONNECT_TIMEOUT = 1000; // Timeout to fake connection state 'connected' for mock scenarios

        var NEXT_PC_TIMEOUT = 30000; // Timeout to keep nextPc available

        var LOCAL_AUDIO_VIDEO = 0;
        var LOCAL_SCREEN_SHARE = 1;

        var DIRECT_CALL_SDP_M_LINE_INDEX = {
            audio: 0,
            video: 1,
            screen: 2
        };

        // Maximum bandwidth to use with AS parameter on remote SDP for Chrome WebRTC implementation
        var MAX_BANDWIDTH = Utils.isMobile() ? 2000 : 10000;

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _that = this;

        var _callId = options.callId || '';
        var _isDirectCall = !!options.isDirectCall;

        var _isTelephonyCall = !!options.isTelephonyCall;

        var _midMappingEnabled = !!options.midMappingEnabled;

        var _isMobile = Utils.isMobile();

        // Number of extra video channels which are negotiated
        var _numberOfExtraVideoChannels = 0;

        // Local Media Streams
        // Index 0 contains the Audio+Video stream
        // Index 1 contains the Desktop (screen-sharing) stream
        var _localStreams = [null, null];

        // RTCPeerConnections instance (audio/video streams)
        var _pc = null;
        var _nextPc = null;

        var _pcSettings = {
            // Audio/video and desktop (i.e. screenshare)
            main: {
                sdpStatus: SdpStatus.None,
                localSdpType: null,
                // Trickle ICE for Offer is enabled by default for all non telephony calls.
                trickleIceForOffer: !_isTelephonyCall && !RtcSessionController.disableTrickleIce && !options.disableTrickleIce,
                // Trickle ICE for Answer is enabled if the Offer indicates support for Trickle ICE.
                trickleIceForAnswer: false,
                // Timer to wait for candidates when Trickle ICE is not enabled
                candidatesTimer: null,
                // Shorter timer to wait for candidates when Trickle ICE is not enabled and first relay candidate is received
                shortCandidatesTimer: null,
                // Timer to wait for connection state to change to connected
                connectedTimer: null,
                // Contains the origin field of the local session description
                localOrigin: null,
                sentIceCandidates: [],
                // Disabled remote offer m-lines that need to be restored before sending the answer SDP back
                disabledRemoteOfferMLines: new DisabledRemoteOfferMLines(),
                // Keep track of when the local description was set in the pre-allocated peer connection
                nextPcLocalSdpSetAt: 0
            }
        };

        // Collect call statistics
        var _callStats = null;

        var _turnUris = [];
        var _turnCredentials = {};

        // Flag to indicate that a media renegotiation is in progress
        var _renegotiationInProgress = false;
        var _renegotiationStartedLocally = false;
        var _pendingAbort = false;

        // Callback function that must be invoked when the media renegotiation is completed
        var _renegotiationCb = null;

        // The previous MediaStreams and PC instances that are saved during media renegotiations
        var _oldLocalStreams = null;
        var _oldPC = null;
        var _oldCallStats = null;

        // Local and Remote streams
        var _localVideoStream = null;
        var _localDesktopStream = null;

        var _remoteVideoStreams = [];
        // Remote audio stream information (there's always only one)
        var _remoteAudioStream = null;

        // The negotiated media types. This variable only tracks audio & video since we
        // cannot differentiate video and desktop (screen share) from the SDP negotiation.
        var _activeMediaType = {audio: false, video: false};
        var _oldActiveMediaType = null;

        var _held = false;
        var _holding = false;
        var _holdInProgress = false;
        var _retrieveInProgress = false;
        var _isMuted = false;

        // Constraints
        var _mediaConstraints = normalizeMediaType();
        var _oldMediaConstraints = null;

        // The following variable is used to send DTMF digits using the RTCDTMFSender interface.
        var _dtmfSender = null;

        // Flag that indicates whether the session has been warmed-up.
        var _warmedUp = false;
        // The _warmedUpSdp is the SDP that has been passed as input to the warmup function
        var _warmedUpSdp = null;

        var _isMockedCall = false;

        // Flag to control whether or not to disable remote video streams
        var _remoteVideoDisabled = false;
        var _remoteVideoScreenOnlyAllowed = false;

        var _remoteStreams = [];

        var _screenShareParams = null;

        // For an ATC pull call scenario the first received SDP Offer is a dummy Offer which
        // will not be used for the actual connection. In this case we should not waste time
        // collecting candidates from a TURN server.
        var _ignoreTurnOnNextPC = !!options.isAtcPullCall;

        var _pendingRemoteSdp = null;
        var _pendingChangeMedia = null;
        var _sessionTerminated = false;

        // Flag used to indicate that the new SDP Offer/Answer exchange will be ignored, so we
        // don't need to waste time waiting for ICE candidates.
        var _ignoreNextConnection = false;

        // Indicates whether the controller is only being used for candidates collection test
        var _candidatesCollectionTest = !!options.candidatesCollectionTest;

        var _browser = Utils.getBrowserInfo();
        var _lastGUMAudioConstraints = {};

        var _isDirectUpgradingToConf = false;

        var _dontReuseAudioStream = false;

        // We're required to remember the a:mid attribute of the audio offer because
        // SBC won't send it in the answer. So we need to set it until SBC adds support for it.
        // It's required for Firefox v63+ and Chrome (only if using Unified SDP)
        var _lastAudioOfferMidAttr = '';

        // By default multiplex is disabled. But we enable it in certain call scenarios.
        // It's not being stored in _pcSettings because it needs to be set before the peer connections are created
        var _enableMultiplex = false;

        // Store video track constraints like quality, min and max bitrate, direction
        var _videoTrackConstraints = {};

        var _supportsVideoReceiverConfiguration = !!options.supportsVideoReceiverConfiguration;

        var _disableConnectedTimer = false;

        /////////////////////////////////////////////////////////////////////////////
        // Media Capture and Streams
        ////////////////////////////////////////////////////////////////////////////
        function watchStreamEnded(stream, reset) {
            if (_isMockedCall) {
                // Ignore event for mock calls
                return;
            }

            var handler = reset ? null : function () {
                var isDesktop = stream === _localStreams[LOCAL_SCREEN_SHARE];
                logger.warn('[RtcSessionController]: Local ' + (isDesktop ? 'Desktop' : 'Audio/Video') +
                    ' Media Stream has ended unexpectedly');
                sendLocalStreamEnded(isDesktop);
            };
            // Register for onended event on each track in the stream
            // This event may be fired multiple times
            stream.getTracks().forEach(function (t) {
                t.onended = handler;
            });
        }

        function getAudioConstraints(audioSources, param) {
            var source = Utils.selectMediaDevice(audioSources, RtcSessionController.recordingDevices);
            var constraints = {
                enableAudioAGC: RtcSessionController.enableAudioAGC,
                enableAudioEC: RtcSessionController.enableAudioEC,
                sourceId: param.newInputDevice || (source && source.id)
            };
            if (!_dontReuseAudioStream &&
                _renegotiationInProgress && (_browser.firefox || _browser.chrome) &&
                _mediaConstraints.audio === _oldMediaConstraints.audio &&
                _lastGUMAudioConstraints.enableAudioAGC === constraints.enableAudioAGC &&
                _lastGUMAudioConstraints.enableAudioEC === constraints.enableAudioEC &&
                _lastGUMAudioConstraints.sourceId === constraints.sourceId) {
                var av = _oldLocalStreams[LOCAL_AUDIO_VIDEO];
                var oldAudioTrack = av && av.getAudioTracks()[0];
                if (oldAudioTrack && oldAudioTrack.readyState !== 'ended') {
                    // We can reuse the local audio stream from the old connection (Chrome and FF only)
                    param.oldAudioTrack = oldAudioTrack;
                    return null;
                }
            }
            logger.debug('[RtcSessionController]: Input device selected: ', source);
            return constraints;
        }

        function getUserMedia(successCb, errorCb) {
            logger.debug('[RtcSessionController]: Get User Media for ', _mediaConstraints);
            if (_mediaConstraints.audio || _mediaConstraints.video) {
                getUserMediaAudioVideo(function () {
                    // Make sure there is no Desktop (i.e. screen-share) stream
                    // and then invoke the success callback.
                    setLocalStream(null, LOCAL_SCREEN_SHARE);
                    successCb();
                }, errorCb);
            } else {
                window.setTimeout(successCb, 0);
            }
        }

        function getUserMediaAudioVideo(successCb, errorCb, newInputDevices) {
            var reuseLocalStream = function () {
                if (newInputDevices && _localStreams[LOCAL_AUDIO_VIDEO]) {
                    // A new input device is being specified, so we can't reuse the current stream
                    // Set the current stream to null, so it won't be stopped by setLocalStream()
                    _that.setLocalStream(LOCAL_AUDIO_VIDEO, null);
                    return null; // Don't reuse stream
                }
                // NOTE: 1. The check for IE can be removed once Temasys allows getting a
                // second media stream for the same camera (ticket #1519).
                // 2. FF doesn't call gerUserMedia callback if the browser is not focused (bug #1195654 on bugzilla).
                // Reuse stream when screensharing is switching off to prevent errors after the timeout expires.
                if (_localStreams[LOCAL_AUDIO_VIDEO]) {
                    // Reuse stream from another call (e.g.: 1-1 call upgraded to group call)
                    var av = _localStreams[LOCAL_AUDIO_VIDEO];
                    watchStreamEnded(av);
                    _localStreams[LOCAL_AUDIO_VIDEO] = null; // Set it to null first, otherwise setLocalStream won't do anything
                    return av;
                }
                var streamCanBeUsed = !_renegotiationStartedLocally ||
                    (_browser.firefox && !_mediaConstraints.desktop && _oldMediaConstraints.desktop);

                if (_renegotiationInProgress && streamCanBeUsed &&
                    (WebRTCAdapter.browser !== 'android') &&
                    (_mediaConstraints.audio === _oldMediaConstraints.audio) &&
                    (_mediaConstraints.video === _oldMediaConstraints.video) &&
                    (_mediaConstraints.hdVideo === _oldMediaConstraints.hdVideo) &&
                    (_mediaConstraints.videoResolution === _oldMediaConstraints.videoResolution)) {
                    // Reuse stream from same call (media renegotiation)
                    return _oldLocalStreams[LOCAL_AUDIO_VIDEO];
                }
                return null;
            };

            logger.debug('[RtcSessionController]: getUserMediaAudioVideo()');
            var gumTimeout = null;
            try {
                var reuseAudioOnlyTrack;
                var reuseStream = reuseLocalStream();
                if (reuseStream) {
                    logger.debug('[RtcSessionController]: getUserMediaAudioVideo(): Reusing audio/video stream ID: ', WebRTCAdapter.getStreamId(reuseStream));
                    setLocalStream(reuseStream, LOCAL_AUDIO_VIDEO);
                    successCb();
                    return;
                }

                var constraints = {
                    audio: false,
                    video: false
                };

                // Get the up-to-date list of devices that are plugged in and select one based on the list of
                // devices previously selected by the user
                WebRTCAdapter.getMediaSources(function (audioSources, videoSources) {
                    logger.debug('[RtcSessionController]: Retrieved media sources');
                    var audioConstraints;
                    if (_mediaConstraints.audio) {
                        var param = {
                            newInputDevice: newInputDevices && newInputDevices.audio
                        };
                        audioConstraints = getAudioConstraints(audioSources, param);
                        if (!audioConstraints && param.oldAudioTrack) {
                            // Reuse the old audio track
                            logger.debug('[RtcSessionController]: Reusing audio track');
                            // Clone the audio track so stopping the old stream won't affect the new stream
                            reuseAudioOnlyTrack = param.oldAudioTrack.clone();
                        } else {
                            constraints.audio = WebRTCAdapter.getAudioOptions(audioConstraints);
                        }
                    }

                    var videoDevice;
                    var videoConfig = {};
                    var hdResolutions = [];
                    if (_mediaConstraints.video) {
                        if (_mediaConstraints.hdVideo) {
                            var resolutionIdx = _mediaConstraints.videoResolution ? HD_VIDEO_RESOLUTIONS.indexOf(_mediaConstraints.videoResolution) : 0;
                            hdResolutions = HD_VIDEO_RESOLUTIONS.slice(resolutionIdx);
                            // Get the first HD resolution
                            videoConfig.videoResolution = hdResolutions.shift();
                            updateVideoResolutionInMediaConstraints(videoConfig.videoResolution);
                        }
                        videoConfig.minFrameRate = _mediaConstraints.minFrameRate;
                        videoDevice = Utils.selectMediaDevice(videoSources, RtcSessionController.videoDevices);
                        videoConfig.sourceId = videoDevice && videoDevice.id;
                        constraints.video = WebRTCAdapter.getVideoOptions(videoConfig);
                    }

                    // Stop local video if has to change the HD video or resolution
                    if (_renegotiationInProgress && _mediaConstraints.video && _oldMediaConstraints.video &&
                        ((_mediaConstraints.hdVideo !== _oldMediaConstraints.hdVideo) ||
                         (_mediaConstraints.videoResolution !== _oldMediaConstraints.videoResolution))) {
                        WebRTCAdapter.stopLocalVideoTrack(_oldLocalStreams[LOCAL_AUDIO_VIDEO]);
                    }

                    if (reuseAudioOnlyTrack && !constraints.audio && !constraints.video) {
                        // We're left with only the reused audio track, so no getUserMedia is needed.
                        // Create a brand new MediaStream object with the reused audio track
                        var tempStream = new WebRTCAdapter.MediaStream([reuseAudioOnlyTrack]);
                        watchStreamEnded(tempStream);
                        setLocalStream(tempStream, LOCAL_AUDIO_VIDEO);
                        successCb();
                        return;
                    }

                    gumTimeout = window.setTimeout(function () {
                        gumTimeout = null;
                        logger.warn('[RtcSessionController]: Failed to access local media: device unresponsive');
                        errorCb('res_MediaInputDevicesUnresponsive');
                    }, GUM_TIMEOUT);

                    var getLocalMedia = function () {
                        logger.info('[RtcSessionController]: Calling getUserMedia - constraints = ', constraints);
                        WebRTCAdapter.getUserMedia(constraints, function (stream, exception) {
                            if (audioConstraints) {
                                _lastGUMAudioConstraints = audioConstraints;
                            }
                            logger.info('[RtcSessionController]: User has granted access to local media');
                            logger.debug('[RtcSessionController]: Local MediaStream: ', WebRTCAdapter.getStreamId(stream));

                            if (gumTimeout) {
                                window.clearTimeout(gumTimeout);
                                gumTimeout = null;
                            } else {
                                logger.info('[RtcSessionController]: GUM time out already fired');
                                stopStream(stream);
                                return;
                            }
                            if (_sessionTerminated) {
                                logger.info('[RtcSessionController]: Session already terminated. Stop audio/video MediaStream: ', WebRTCAdapter.getStreamId(stream));
                                stopStream(stream);
                                return;
                            }

                            if (_mediaConstraints.video && !stream.getVideoTracks().length) {
                                if (_renegotiationInProgress && !_oldMediaConstraints.video) {
                                    errorCb('res_CameraAccessRestricted');
                                    return;
                                }
                                _mediaConstraints.video = false;
                                logger.info('[RtcSessionController]: Failed to get access to video stream. Fallback to audio-only.');
                                sendAsyncWarning('res_AddVideoFailed');
                                logger.info('[RtcSessionController]: New getUserMedia - constraints = ', _mediaConstraints);
                            }
                            if (reuseAudioOnlyTrack) {
                                stream.addTrack(reuseAudioOnlyTrack);
                            }
                            setLocalStream(stream, LOCAL_AUDIO_VIDEO);
                            watchStreamEnded(stream);
                            successCb();
                            if (exception) {
                                logger.warn('[RtcSessionController]: Get user media succeeded. But we had an exception: ', exception.name || '<unknown>');
                                sendEvent(_that.onGetUserMediaException, {info: exception});
                            }
                        }, function (error) {
                            var errorName = (error && error.name) || 'Unspecified';
                            if (videoConfig.videoResolution && (errorName === GUM_CONSTRAINT_ERROR || errorName === GUM_OVERCONSTRAINT_ERROR)) {
                                logger.warn('[RtcSessionController]: Failed to access local media with HD constraints: ', constraints.video);
                                // Try the next HD video resolution (if there is one)
                                videoConfig.videoResolution = hdResolutions.shift();
                                updateVideoResolutionInMediaConstraints(videoConfig.videoResolution);
                                constraints.video = WebRTCAdapter.getVideoOptions(videoConfig);
                                getLocalMedia();
                                return;
                            }

                            logger.warn('[RtcSessionController]: Failed to access local media: ', errorName);
                            if (gumTimeout) {
                                window.clearTimeout(gumTimeout);
                                gumTimeout = null;
                            }
                            var strError;
                            if (GumPermissionDeniedErrors.includes(errorName)) {
                                strError = _mediaConstraints.video ? 'res_AccessToMediaInputDevicesFailedPermissionDenied' : 'res_AccessToAudioInputDeviceFailedPermissionDenied';
                            } else if (errorName === GUM_DEVICE_IN_USE_CHROME_ERROR || errorName === GUM_DEVICE_IN_USE_ERROR) {
                                // This error is normally returned if another process/tab is using the mic/camera
                                // https://addpipe.com/blog/common-getusermedia-errors/
                                strError = _mediaConstraints.video ? 'res_AccessToMediaInputDevicesFailedInUse' : 'res_AccessToAudioInputDeviceFailedInUse';
                            } else {
                                strError = _mediaConstraints.video ? 'res_AccessToMediaInputDevicesFailed' : 'res_AccessToAudioInputDeviceFailed';
                            }

                            errorCb(strError);
                        });
                    };

                    getLocalMedia();

                    logger.debug('[RtcSessionController]: Requested access to Local Media');
                });

            } catch (e) {
                logger.error('[RtcSessionController]: Exception while handling getUserMedia: ', e);
                if (gumTimeout) {
                    window.clearTimeout(gumTimeout);
                    gumTimeout = null;
                }
                errorCb(_mediaConstraints.video ? 'res_AccessToMediaInputDevicesFailed' : 'res_AccessToAudioInputDeviceFailed');
            }
        }

        function getUserMediaDesktop(successCb, errorCb, screenShareParams) {
            logger.debug('[RtcSessionController]: getUserMediaDesktop()');
            try {
                var constraints = {audio: false};
                var screenConstraint = getScreenConstraint();
                constraints.video = WebRTCAdapter.getDesktopOptions(screenShareParams.streamId || screenShareParams.screenType, screenConstraint);

                logger.info('[RtcSessionController]: Calling getUserMedia - constraints = ', constraints);

                WebRTCAdapter.getUserMedia(constraints, function (stream) {
                    logger.info('[RtcSessionController]: User has granted access for screen capture. Stream Id:', WebRTCAdapter.getStreamId(stream));

                    if (_sessionTerminated) {
                        logger.info('[RtcSessionController]: Session already terminated. Stop desktop MediaStream: ', WebRTCAdapter.getStreamId(stream));
                        stopStream(stream);
                        return;
                    }

                    if (screenShareParams) {
                        sendScreenSharePointerStatus(screenShareParams.pointer);
                        _screenShareParams = screenShareParams;
                    }

                    watchStreamEnded(stream);

                    setLocalStream(stream, LOCAL_SCREEN_SHARE);
                    successCb();
                }, function (error) {
                    var errorName = (error && error.name) || 'Unspecified';
                    logger.warn('[RtcSessionController]: Failed to access local media: ', errorName);

                    // If getUserMedia returns PermissionDeniedError/SecurityError, assume that user has cancelled screenshare,
                    // Error message was replaced with NotAllowedError in FF >= 49.
                    if (GumPermissionDeniedErrors.includes(errorName)) {
                        errorCb(Constants.ReturnCode.CHOOSE_DESKTOP_MEDIA_CANCELLED);
                    } else {
                        errorCb('res_AccessToDesktopFailed');
                    }
                });
                logger.debug('[RtcSessionController]: Requested access for screen capture');
            } catch (e) {
                logger.error('[RtcSessionController]: Exception while handling getUserMedia: ', e);
                errorCb('res_AccessToDesktopFailed');
            }
        }

        function updateVideoResolutionInMediaConstraints(resolution) {
            if (!resolution) {
                _mediaConstraints.hdVideo = false;
            }
            _mediaConstraints.videoResolution = resolution;
        }

        function getScreenConstraint() {
            var sdpParams = _mediaConstraints.hdDesktop ? 'hdScreenShare' : 'screenShare';
            var screenConstraint = {
                frameRate: {
                    min: RtcSessionController.sdpParameters[sdpParams].minFrameRate,
                    max: RtcSessionController.sdpParameters[sdpParams].maxFrameRate
                }
            };
            // adapt min/max framerate in case of remote control, default values might be higher than remote control values as they are configurable
            if (_mediaConstraints.remoteControlEnabled) {
                screenConstraint.frameRate.min = Math.max(screenConstraint.frameRate.min, RtcSessionController.sdpParameters.rcScreenShare.minFrameRate);
                screenConstraint.frameRate.max = Math.max(screenConstraint.frameRate.max, RtcSessionController.sdpParameters.rcScreenShare.maxFrameRate);
            }
            return screenConstraint;
        }

        /////////////////////////////////////////////////////////////////////////////
        // WebRTC 1.0: Real-time Communication Between Browsers
        /////////////////////////////////////////////////////////////////////////////
        function createPeerConnection(createNext) {
            if (createNext) {
                if (_nextPc) {
                    return true;
                }
            } else if (_pc) {
                // The RTCPeerConnection instance has already been created.
                return true;
            }

            var config = {
                keysToOmitFromLogging: [
                    'iceServers.[].credential',
                    'iceServers.[].username'
                ]
            };

            if (_isTelephonyCall) {
                config.rtcpMuxPolicy = RtcSessionController.sdpParameters.rtcpMuxPolicy; // Configurable (default is require)
            }

            if (_turnUris.length && !_ignoreTurnOnNextPC) {
                config.iceServers = [{
                    urls: _turnUris,
                    credential: _turnCredentials.password,
                    username: _turnCredentials.username
                }];
                if (RtcSessionController.allowOnlyRelayCandidates) {
                    config.iceTransportPolicy = 'relay';
                }
            } else {
                if (!_ignoreTurnOnNextPC) {
                    logger.warn('[RtcSessionController]: No TURN server(s) provided.');
                }
                config.iceServers = [];
            }
            var connectionBypassed = !!_ignoreTurnOnNextPC;
            _ignoreTurnOnNextPC = false;

            if (RtcSessionController.webRTCIPHandlingPolicy) {
                // Log the webRTCIPHandlingPolicy value so we know how the browser/DA is configured
                logger.info('[RtcSessionController]: webRTCIPHandlingPolicy is set to ', RtcSessionController.webRTCIPHandlingPolicy);
            }

            logger.debug('[RtcSessionController]: Creating RtcPeerConnections. config: ', config);

            var pcConstraints = {
                optional: [
                    {DtlsSrtpKeyAgreement: true},
                    // Improved bandwidth calculation
                    {googImprovedWifiBwe: true},
                    // These are useful for very detailed (HD) video
                    {googHighBitrate: true},
                    {googVeryHighBitrate: true},
                    // Enable DSCP support
                    {googDscp: true}
                ]
            };

            logger.debug('[RtcSessionController]: Creating peer connections. constraints:', pcConstraints);

            setExtraVideoChannels();

            var extraVideoChannels = _numberOfExtraVideoChannels;

            var onlyRemoteScreenShare = _remoteVideoScreenOnlyAllowed && !_supportsVideoReceiverConfiguration;

            // If local video is enabled and we only allow remote screen share, then negotiate a sendOnly video channel
            // In case the call type supports video receiver configuration do not change the amount of video lines
            if (_mediaConstraints.video && onlyRemoteScreenShare) {
                logger.debug('[RtcSessionController]: Local video is enabled and we only allow remote screenshare. We need to setup an extra sendonly video channel.');
                extraVideoChannels = 1;
            }
            var pcOptions = {
                extraVideoChannels: extraVideoChannels,
                // Create desktop peer connection only if:
                // Feature is not disabled and...
                // ...it's a group call and...
                // ...if receiving remote video is enabled or we're sending screenshare or we are allowing remote screenshare
                createDesktopPc: !RtcSessionController.disableDesktopPc && !_isDirectCall
                        && (_supportsVideoReceiverConfiguration || !_remoteVideoDisabled || _mediaConstraints.desktop || _remoteVideoScreenOnlyAllowed),
                onlyRemoteScreenShare: onlyRemoteScreenShare
            };

            logger.debug('[RtcSessionController]: Creating peer connections. options:', pcOptions);

            var pc;
            try {
                if (!createNext) {
                    _remoteStreams = [];
                }

                pc = new circuit.RtcPeerConnectionsUnified(config, pcOptions);
                pc.connectionBypassed = connectionBypassed;
                logger.debug('[RtcSessionController]: Created audio/video peer connections. Connection bypassed: ', connectionBypassed);
            } catch (e) {
                logger.error('[RtcSessionController]: Failed to create peer connections.', e);
                return false;
            }

            if (createNext) {
                _nextPc = pc;
            } else {
                _pc = pc;
                _callStats = createCallStatsHandler([_pc]);
                registerPcEvtHandlers(_pc, _callStats);
            }

            return true;
        }

        function isActivePc(pc) {
            return !!pc && pc === _pc;
        }

        function registerPcEvtHandlers(pc, stats) {
            if (pc) {
                pc.onicecandidate = handleIceCandidate.bind(null, pc);
                pc.oniceconnectionstatechange = handleConnectionStateChange.bind(null, pc);
                pc.onaddstream = handleAddStream.bind(null, pc);
                pc.onremovestream = handleRemoveStream.bind(null, pc);
            }
            if (stats) {
                stats.onThresholdExceeded = function (cleared) { sendEvent(_that.onStatsThresholdExceeded, {cleared: cleared}); };
                stats.onNoOutgoingPackets = function () { sendEvent(_that.onStatsNoOutgoingPackets, {}); };
                stats.onNetworkQuality = function (quality) { sendEvent(_that.onNetworkQuality, {quality: quality}); };
            }
        }

        function unregisterPcEvtHandlers(pc, stats) {
            if (pc) {
                pc.onicecandidate = null;
                pc.oniceconnectionstatechange = null;
                pc.onaddstream = null;
                pc.onremovestream = null;
            }
            if (stats) {
                stats.onThresholdExceeded = null;
                stats.onNoOutgoingPackets = null;
                stats.onNetworkQuality = null;
            }
        }

        function createCallStatsHandler(pcList) {
            return new circuit.CallStatsHandlerUnified(pcList, _isDirectCall);
        }

        function handleIceCandidate(pc, event) {
            if (!isActivePc(pc)) {
                // Event is for old peer connection. We should never get here, but...
                logWarning('Received icecandidate event for old PC');
                return;
            }

            // Avoid calling pc.localDescription since this makes a call to native code for the mobile clients

            var sdpStatus = getSetting(pc, 'sdpStatus');
            var sdpType = getSetting(pc, 'localSdpType');

            if (event.candidate) {
                if (!event.candidate.candidate) {
                    logDebug('Ignore empty candidate for mid ' + event.candidate.sdpMid);
                    return;
                }
                var candidate = new IceCandidate(event.candidate.candidate);
                if (candidate.transport !== 'udp') {
                    logDebug('Ignore non UDP candidate');
                    return;
                }

                if (pc === _pc && candidate.typ === 'relay') {
                    pc.hasRelayCandidates = true;
                }
                logDebug('New ICE candidate: ', event.candidate);

                if (useTrickleIce(pc, sdpType)) {
                    if (getSetting(pc, 'candidatesTimer') && (sdpStatus === SdpStatus.OfferPending || sdpStatus === SdpStatus.AnswerPending)) {
                        ///////////////////////////////////////////////////////////////////////////////////////////
                        // WORKAROUND!!!
                        // When collecting host ICE candidates takes too long the client waits additionaly for the first ICE
                        // candidate before sending the local session description.
                        sendLocalDescription(pc, pc.localDescription);
                        return;
                    }

                    var data = {
                        candidate: event.candidate.candidate,
                        sdpMid: event.candidate.sdpMid,
                        sdpMLineIndex: event.candidate.sdpMLineIndex
                    };
                    if (checkRelayCandidate(pc, data)) {
                        if (sdpStatus !== SdpStatus.None && sdpStatus !== SdpStatus.OfferPending && sdpStatus !== SdpStatus.AnswerPending) {
                            collectCandidate(data, pc);
                        } else {
                            logDebug('Ignore ICE candidate in state ', sdpStatus);
                        }
                    }
                } else if (_isTelephonyCall && candidate.typ === 'relay' && !getSetting(pc, 'shortCandidatesTimer')) {
                    logDebug('Received first relay candidate. Start short candidate colection timer.');
                    var shortTimer = window.setTimeout(function () {
                        setSetting(pc, 'shortCandidatesTimer', null);
                        logDebug('Short candidate collection timer has expired. Send SDP.');
                        sendLocalDescription(pc, pc.localDescription);
                    }, SHORT_CANDIDATES_TIMEOUT);
                    setSetting(pc, 'shortCandidatesTimer', shortTimer);
                }
            } else {
                logDebug('End of candidates. PC: audio/video');
                pc.endOfCandidates = true; // Mark this peer connection as finished and...

                if (isEndOfCandidates()) {
                    var localDescription = pc.localDescription;
                    if (!localDescription) {
                        logError('End of candidates, but cannot access pc.localDescription');
                        return;
                    }

                    if (useTrickleIce(pc, sdpType)) {
                        if (sdpStatus !== SdpStatus.None && sdpStatus !== SdpStatus.OfferPending && sdpStatus !== SdpStatus.AnswerPending) {
                            sendEndOfCandidates(pc);
                        } else {
                            sendLocalDescription(pc, localDescription);
                        }
                    } else {
                        sendLocalDescription(pc, localDescription);
                    }
                }
            }
        }

        function handleConnectionStateChange(pc) {
            if (!isActivePc(pc)) {
                // Event is for old peer connection. We should never get here, but...
                logWarning('Received iceconnectionstatechange event for old PC');
                return;
            }

            var pcType = 'audio/video';
            logDebug('Connection state change (audio/video): ', pc.iceConnectionState);

            if (_turnUris.length && pc === _pc && !pc.connectionBypassed && (pc.iceConnectionState === 'completed' ||
                pc.iceConnectionState === 'failed') && !pc.hasRelayCandidates) {
                // We finished collecting all candidates and no relay candidates were generated for the main peer connection.
                // This indicates connectivity problems between the client and the TURN server(s).
                logWarning('[RtcSessionController]: No relay candidates were collected, please check connectivity to the TURN servers');
            }

            var sdpStatus = getSetting(pc, 'sdpStatus');
            switch (sdpStatus) {
            case SdpStatus.Connected:
                switch (pc.iceConnectionState) {
                case 'connected':
                case 'completed':
                    sendIceConnected(pc, pcType);
                    break;
                case 'disconnected':
                    if (!pc.connectionBypassed) {
                        sendIceDisconnected(pc, pcType);
                    }
                    break;
                case 'failed':
                    if (!pc.connectionBypassed) {
                        setSdpStatus(SdpStatus.Disconnected, pc); // Mark it as disconnected, so we handle this failure only once
                        logError('[RtcSessionController]: ICE connection has failed');
                        handleFailure('res_CallMediaDisconnected');
                    }
                    break;
                }
                break;

            case SdpStatus.AnswerReceived:
            case SdpStatus.PrAnswerReceived:
            case SdpStatus.AnswerApplied:
            case SdpStatus.AnswerSent:
                switch (pc.iceConnectionState) {
                case 'connected':
                case 'completed':
                    pc.connected = true;
                    if (isPcConnected(pc)) {
                        clearConnectedTimer(pc);
                        setSdpStatus(SdpStatus.Connected, pc);
                        sendIceConnected(pc, pcType);
                    }
                    break;
                case 'failed':
                    clearConnectedTimer(pc);
                    handleConnectionFailed();
                    break;
                }
                break;
            }
        }

        function handleAddStream(pc, event) {
            logDebug('Added Remote Stream');
            if (_renegotiationInProgress) {
                // Ignore added streams while renegotiation is in progress.
                // The remote streams will be added once the renegotiation is successful.
                logDebug('Renegotiation is in progress. Ignore AddStream event.');
                return;
            }
            putRemoteStreams([event.stream]);
            sendRemoteStreamUpdated();
        }

        function handleRemoveStream(pc, event) {
            logWarning('Removed Remote Stream');
            // Remove stream from list
            deleteRemoteStream(event.stream);
            sendRemoteStreamUpdated();
        }

        function handleDTMFToneChange(pc, event) {
            logDebug('RTCDTMFSender - ontonechange', !event.tone ? ': finished' : '');
            sendDTMFToneChange(event.tone);
        }

        /////////////////////////////////////////////////////////////////////////////
        // Event Senders
        /////////////////////////////////////////////////////////////////////////////
        function sendEvent(eventHandler, event) {
            if (typeof eventHandler === 'function') {
                try {
                    eventHandler(event || {});
                } catch (e) {
                    logger.error(e);
                }
            }
        }

        function sendIceCandidates(candidates, pc) {
            var settings = getSettings(pc);
            if (!settings) {
                return;
            }
            if (candidates) {
                var sentIceCandidates = settings.sentIceCandidates;
                var endOfCandidates = false;
                candidates = candidates.filter(function (data) {
                    if (data.candidate.startsWith('a=end-of-candidates')) {
                        endOfCandidates = true;
                        return true;
                    }
                    var candidate = data.candidate;
                    var found = sentIceCandidates.some(function (c) { return c.equals(candidate); });
                    if (found) {
                        logWarning('Ignore duplicate ICE candidate: ', data.candidate);
                    } else {
                        sentIceCandidates.push(new IceCandidate(candidate));
                    }
                    return !found;
                });
                if (candidates.length > 0) {
                    logger.debug('[RtcSessionController]: Send IceCandidate event');

                    var origin = getSdpOrigin(pc);
                    var evtData = {
                        origin: origin,
                        candidates: candidates,
                        endOfCandidates: endOfCandidates,
                        isAudioVideo: true
                    };

                    // Invoke onIceCandidate event handler
                    sendEvent(_that.onIceCandidate, evtData);
                    waitConnectedState(pc);
                }
            }
        }

        function sendIceConnected(pc, pcType) {
            if (pc.iceDisconnectTimeout) {
                window.clearTimeout(pc.iceDisconnectTimeout);
                delete pc.iceDisconnectTimeout;
            }
            logger.debug('[RtcSessionController]: send IceConnected event pcType:', pcType);
            sendEvent(_that.onIceConnected, {pcType: pcType});
        }

        function sendIceDisconnected(pc, pcType) {
            if (pc.iceDisconnectTimeout) {
                return;
            }

            // Delay the IceDisconnect event for transient cases
            pc.iceDisconnectTimeout = window.setTimeout(function () {
                delete pc.iceDisconnectTimeout;
                if (pc.iceConnectionState === 'disconnected') {
                    logger.debug('[RtcSessionController]: send IceDisconnected event pcType:', pcType);
                    sendEvent(_that.onIceDisconnected, {pcType: pcType});
                }
            }, ICE_DISCONNECT_DELAY);
        }

        function sendEndOfCandidates(pc) {
            var settings = getSettings(pc);
            if (!settings) {
                return;
            }
            var candidates = [];
            if (settings.pendingCandidates) {
                logger.info('[RtcSessionController]: Send all pending candidates.');
                candidates = settings.pendingCandidates;
            }
            if (settings.queuedCandidates) {
                logger.info('[RtcSessionController]: There was no relay candidate for TURN over TCP/TLS. Send all queued candidates.');
                Array.prototype.push.apply(candidates, settings.queuedCandidates);
            }
            candidates.push({candidate: 'a=end-of-candidates\r\n'});
            clearIceProperties(pc);
            sendIceCandidates(candidates, pc);
        }

        function sendLocalDescription(pc, localDescription) {
            if (!pc || !localDescription) {
                logError('sendLocalDescription invoked without PC or SDP');
                return;
            }
            clearCandidatesTimer(pc);
            var sdpStatus = getSetting(pc, 'sdpStatus');
            if (localDescription.type === 'offer') {
                if (sdpStatus === SdpStatus.OfferPending) {
                    sendSessionDescription(pc, SdpStatus.OfferSent);
                }
            } else if (sdpStatus === SdpStatus.AnswerPending) {
                sendSessionDescription(pc, SdpStatus.AnswerSent);
            }
        }

        function combineLocalSdp(pc) {
            // Combine all SDPs into one
            var localSdp = pc.localDescription;
            var parsed = sdpParser.parse(localSdp.sdp);

            if (localSdp.type === 'answer') {
                logger.info('[RtcSessionController]: combineLocalSdp - Processing answer SDP');
                var disabledOfferMLines = getSetting(_pc, 'disabledRemoteOfferMLines');
                if (!_pc.desktopPeerConnection && disabledOfferMLines.get(DIRECT_CALL_SDP_M_LINE_INDEX.video)) {
                    // Include the disabled video m-line from the offer
                    parsed.m.splice(DIRECT_CALL_SDP_M_LINE_INDEX.video, 0, disabledOfferMLines.get(DIRECT_CALL_SDP_M_LINE_INDEX.video));
                    disabledOfferMLines.remove(DIRECT_CALL_SDP_M_LINE_INDEX.video);
                }
            }

            if (pc.desktopPeerConnection) {
                logger.info('[RtcSessionController]: combineLocalSdp - Adjust screenshare m-line');
                var desktopMLineIndex = sdpParser.findMediaLineIndex(parsed, pc.getScreenShareMediaId());
                if (desktopMLineIndex >= 0) {
                    var desktopSdp = parsed.m[desktopMLineIndex];
                    if (desktopSdp) {
                        desktopSdp = {m: [desktopSdp]};

                        ///////////////////////////////////////////////////////////////////////////////////////////
                        // WORKAROUND!!!
                        // To ensure better screenshare quality for calls that go through the media server, we need to
                        // allow only relay candidates from TCP or TLS TURN Servers.
                        var removeUdpVideoIce = !_isDirectCall && !RtcSessionController.allowAllIceCandidatesScreenShare;
                        logger.info('[RtcSessionController]: Remove UDP video candidates for screenshare: ', removeUdpVideoIce);

                        var trickleIce = useTrickleIce(_pc, localSdp.type) && !_pc.endOfCandidates;
                        sdpParser.validateIceCandidates(desktopSdp, removeUdpVideoIce, trickleIce);
                    }
                } else {
                    logger.warn('[RtcSessionController]: Could not find screenshare m-line: no ICE validation was made.');
                }
            }
            return {
                type: localSdp.type,
                parsedSdp: parsed
            };
        }

        function sendSessionDescription(pc, newStatus) {
            if (!pc) {
                logWarning('Cannot send Session Description for non existing pc.');
                return;
            }
            logger.info('[RtcSessionController]: sendSessionDescription - PC: audio/video');

            if (_renegotiationInProgress && _pendingAbort) {
                renegotiationFailed('Renegotiation aborted');
                return;
            }

            var sdp = combineLocalSdp(pc);
            var trickleIceEnabled = useTrickleIce(pc, sdp.type);

            var parsedSdp = sdp.parsedSdp;

            // Remove the ssrc-audio-level attribute, which imposes a security risk.
            parsedSdp = sdpParser.removeAudioLevelAttr(parsedSdp);

            ///////////////////////////////////////////////////////////////////////////////////////////
            // Add or remove trickle value in ice-options depending on Trickle ICE setting
            parsedSdp = sdpParser.fixTrickleIceOption(parsedSdp, trickleIceEnabled);

            ///////////////////////////////////////////////////////////////////////////////////////////
            // Remove 'end-of-candidates' attribute if Trickle ICE is disabled otherwise add it if all
            // candidates have already been received and it is missing.
            if (!trickleIceEnabled) {
                parsedSdp = sdpParser.removeEndOfCandidatesLine(parsedSdp);
            } else if (isEndOfCandidates()) {
                parsedSdp = sdpParser.addEndOfCandidatesLine(parsedSdp);
            }

            ///////////////////////////////////////////////////////////////////////////////////////////
            // WORKAROUND!!!
            // If the audio connection mode was changed, we need to restore the original connection
            // before the SDP is published.
            if (_pc.restoreAudioConnectionMode) {
                sdpParser.setConnectionMode(parsedSdp, 'audio', _pc.restoreAudioConnectionMode);
            }

            // Add default max bandwidth to all video m-lines
            var bw = !_isMobile || options.canReceiveHdVideo ? 'maxBw' : 'maxBwMobile';
            bw = RtcSessionController.sdpParameters.receiveVideo[bw];
            if (bw) {
                sdpParser.setVideoBandwidth(parsedSdp, bw, true);
            }

            var ignoreNoCandidates = _ignoreNextConnection || _candidatesCollectionTest;

            if (sdpParser.iceTotalCandidatesCount(parsedSdp) === 0 && !ignoreNoCandidates) {
                ///////////////////////////////////////////////////////////////////////////////////////////
                // WORKAROUND!!!
                // When the network on the local computer changes (e.g.: VPN is connected/disconnected),
                // Browser sometimes stop collecting any ICE candidates until the browser is restarted!
                // So we have to return the appropriate error so the user can be alerted to restart the browser
                if (trickleIceEnabled) {
                    var timeout = getCandidatesTimeout();
                    logger.debug('[RtcSessionController]: No ICE candidates collected yet. Waiting ' + timeout + ' more milliseconds.');
                    var candidatesTimer = window.setTimeout(function () {
                        setSetting(pc, 'candidatesTimer', null);
                        handleFailure('res_RestartBrowser');
                    }, timeout);
                    setSetting(pc, 'candidatesTimer', candidatesTimer);
                } else {
                    handleFailure('res_RestartBrowser');
                }
            } else if (sdpParser.validateIceCandidates(parsedSdp, false, _enableMultiplex) ||
                       (trickleIceEnabled && !isEndOfCandidates()) || _ignoreNextConnection) {
                if (trickleIceEnabled && !isEndOfCandidates()) {
                    var settings = getSettings(pc);
                    if (settings) {
                        settings.sentIceCandidates = sdpParser.getIceCandidates(parsedSdp);
                    }
                }
                var finalSdp = {
                    type: sdp.type,
                    sdp: sdpParser.stringify(parsedSdp)
                };
                if (pc === _pc && pc.desktopPeerConnection) {
                    finalSdp.screen = _pc.getScreenShareMediaId();
                    if (_localStreams[LOCAL_AUDIO_VIDEO]) {
                        // If there's a local stream to be transmitted, set its ID in the sdp video field
                        var streamId = getVideoStreamId(LOCAL_AUDIO_VIDEO);
                        logger.info('[RtcSessionController]: Video streamId is ', streamId);
                        var mid = null;
                        if (_midMappingEnabled && streamId) {
                            mid = getVideoMid(LOCAL_AUDIO_VIDEO);
                        }
                        finalSdp.video = mid || streamId;
                        if (isSimulcastEnabled()) {
                            finalSdp.videoSenderConfiguration = _pc.getAvailableVideoSenders(_videoTrackConstraints);
                        }
                    }
                }
                ///////////////////////////////////////////////////////////////////////////////////////////
                // Invoke onSessionDescription event handler
                logger.debug('[RtcSessionController]: send SessionDescription event');
                _ignoreNextConnection = false;
                sendEvent(_that.onSessionDescription, {sdp: finalSdp});
                setSdpStatus(newStatus, pc);
            } else {
                // There are ICE candidates but at least one m-line has no valid candidates
                logger.error('[RtcSessionController]: Failure: SDP contains at least one m line without IceCandidates');
                handleFailure();
            }
        }

        function sendSdpConnected() {
            // Invoke onSdpConnected event handler for RTC (or Conversation) Handler
            logger.debug('[RtcSessionController]: send SdpConnected event');
            sendEvent(_that.onSdpConnected, {sdpOrigin: getSdpOrigin(_pc)});
        }

        function sendRemoteVideoAdded() {
            // Invoke onRemoteVideoAdded event handler for RTC (or Conversation) Handler
            logger.debug('[RtcSessionController]: send RemoteVideoAdded event');
            sendEvent(_that.onRemoteVideoAdded, {});
        }

        function sendRemoteVideoRemoved() {
            // Invoke onRemoteVideoRemoved event handler for RTC (or Conversation) Handler
            logger.debug('[RtcSessionController]: send RemoteVideoRemoved event');
            sendEvent(_that.onRemoteVideoRemoved, {});
        }

        function sendRemoteStreamUpdated() {
            // Invoke onRemoteVideoRemoved event handler for RTC (or Conversation) Handler
            logger.debug('[RtcSessionController]: send RemoteStreamUpdated event');
            sendEvent(_that.onRemoteStreamUpdated, {});
        }

        function sendDTMFToneChange(tone) {
            // Invoke onDTMFToneChange event handler
            logger.debug('[RtcSessionController]: send DTMFToneChanged event');
            sendEvent(_that.onDTMFToneChange, {tone: tone});
        }

        function sendClosed() {
            // Invoke onClosed event handler
            logger.debug('[RtcSessionController]: send Closed event');
            sendEvent(_that.onClosed, {});
        }

        function sendError(error) {
            // Invoke onRtcError event handler
            logger.debug('[RtcSessionController]: send Error event');
            sendEvent(_that.onRtcError, {error: error});
        }

        function sendAsyncError(error) {
            if (typeof _that.onRtcError === 'function') {
                window.setTimeout(function () {
                    sendError(error);
                }, 0);
            }
        }

        function sendWarning(warning) {
            // Invoke onRtcWarning event handler
            logger.debug('[RtcSessionController]: send Warning event');
            sendEvent(_that.onRtcWarning, {warning: warning});
        }

        function sendAsyncWarning(warning) {
            if (typeof _that.onRtcWarning === 'function') {
                window.setTimeout(function () {
                    sendWarning(warning);
                }, 0);
            }
        }

        function sendMediaUpdate() {
            // Invoke onMediaUpdate event handler for Call
            logger.debug('[RtcSessionController]: send MediaUpdate event');
            sendEvent(_that.onMediaUpdate, {});
        }

        function sendLocalVideoStream() {
            // Invoke onLocalVideoStream event handler
            logger.debug('[RtcSessionController]: send LocalVideoStream event');

            sendEvent(_that.onLocalVideoStream, {
                // The local desktop (screen share) has precedence over the local video stream.
                stream: _localDesktopStream || _localVideoStream,
                videoStream: _localVideoStream,
                desktopStream: _localDesktopStream
            });
            sendMediaUpdate();
        }

        function sendRemoteStreams() {
            // Invoke onRemoteStreams event handler
            logger.debug('[RtcSessionController]: send RemoteStreams event');
            sendEvent(_that.onRemoteStreams, {
                audio: _remoteAudioStream,
                video: _remoteVideoStreams
            });
        }

        function sendLocalStreamEnded(isDesktop) {
            // Invoke onLocalStreamEnded event handler
            logger.debug('[RtcSessionController]: send LocalStreamEnded event');
            sendEvent(_that.onLocalStreamEnded, {isDesktop: isDesktop});
        }

        function sendScreenSharePointerStatus(pointer) {
            // Invoke onScreenSharePointerStatus event handler
            logger.debug('[RtcSessionController]: send ScreenSharePointerStatus event');
            sendEvent(_that.onScreenSharePointerStatus, {
                isSupported: !!(pointer && pointer.isSupported),
                isEnabled: !!(pointer && pointer.isEnabled)
            });
        }

        function sendQosAvailable(qos, isRenegotiation, lastSavedStats, streamQualityData) {
            // Invoke onQosAvailable event handler
            logger.debug('[RtcSessionController]: send QosAvailable event');
            sendEvent(_that.onQosAvailable, {
                qos: qos,
                renegotiationInProgress: isRenegotiation,
                lastSavedStats: lastSavedStats,
                streamQualityData: streamQualityData
            });
        }

        /////////////////////////////////////////////////////////////////////////////
        // Internal functions
        /////////////////////////////////////////////////////////////////////////////
        function getSettings(pc) {
            if (!pc) {
                return null;
            }
            if (pc === _pc) {
                return _pcSettings.main;
            }
            return null;
        }

        function setSetting(pc, setting, value) {
            if (!pc) {
                return;
            }
            if (pc === _pc) {
                _pcSettings.main[setting] = value;
            }
        }

        function getSetting(pc, setting) {
            var settings = getSettings(pc);
            return settings && settings[setting];
        }

        function clearCandidatesTimer(pc) {
            var timer = getSetting(pc, 'candidatesTimer');
            if (timer) {
                window.clearTimeout(timer);
                setSetting(pc, 'candidatesTimer', null);
            }
            // Also clear the short candidates timer
            clearShortCandidatesTimer(pc);
        }

        function clearShortCandidatesTimer(pc) {
            var timer = getSetting(pc, 'shortCandidatesTimer');
            if (timer) {
                window.clearTimeout(timer);
                setSetting(pc, 'shortCandidatesTimer', null);
            }
        }

        function clearConnectedTimer(pc) {
            var timer = getSetting(pc, 'connectedTimer');
            if (timer) {
                window.clearTimeout(timer);
                setSetting(pc, 'connectedTimer', null);
            }
        }

        function clearIceProperties(pc) {
            var settings = getSettings(pc);
            if (!settings) {
                return;
            }
            if (settings.collectCandidatesTimeout) {
                window.clearTimeout(settings.collectCandidatesTimeout);
                delete settings.collectCandidatesTimeout;
            }
            if (settings.relayRtpCandidateTimeout) {
                window.clearTimeout(settings.relayRtpCandidateTimeout);
                delete settings.relayRtpCandidateTimeout;
            }
            settings.sentIceCandidates = [];
            if (settings.pendingCandidates) {
                delete settings.pendingCandidates;
            }
            if (settings.queuedCandidates) {
                delete settings.queuedCandidates;
            }
            settings.hasRelayRtpCandidate = false;
            settings.sendAllCandidates = false;
        }

        function logDebug(text, param) {
            logger.debug('[RtcSessionController][' + _callId + ']: ' + text, param);
        }

        function logWarning(text, param) {
            logger.warn('[RtcSessionController][' + _callId + ']: ' + text, param);
        }

        function logError(text, param) {
            logger.error('[RtcSessionController][' + _callId + ']: ' + text, param);
        }

        function useTrickleIce(pc, type) {
            if (!pc) {
                return false;
            }

            if (!type) {
                return getSetting(pc, 'trickleIceForOffer') || getSetting(pc, 'trickleIceForAnswer');
            }

            return (type === 'offer' && getSetting(pc, 'trickleIceForOffer')) ||
                (type === 'answer' && getSetting(pc, 'trickleIceForAnswer'));
        }

        function isTrickleIceIndicated(type, sdp) {
            var trickleIceIndication = sdp && sdpParser.isTrickleIceOption(sdp);
            if (trickleIceIndication) {
                logger.debug('[RtcSessionController]: SDP ' + type + ' indicates support for Trickle-ICE.');
            }
            return !!trickleIceIndication;
        }

        function isPcConnected(pc) {
            return pc && pc.connected;
        }

        function isEndOfCandidates() {
            return !_pc || _pc.endOfCandidates;
        }

        function getSdpOrigin(pc) {
            if (!pc) { // pc should always be defined here
                return '';
            }
            var localOrigin = getSetting(pc, 'localOrigin');

            if (!localOrigin) {
                var localDescription = pc.localDescription;
                if (localDescription) {
                    localOrigin = sdpParser.getOrigin(localDescription.sdp);
                    setSetting(pc, 'localOrigin', localOrigin);
                }
            }
            return localOrigin;
        }

        function collectCandidate(data, pc) {
            var settings = getSettings(pc);
            if (!settings) {
                return;
            }
            if (!settings.pendingCandidates) {
                settings.pendingCandidates = [];
                logger.debug('[RtcSessionController]: Start timer to collect candidates before send.');
                settings.collectCandidatesTimeout = window.setTimeout(function () {
                    logger.debug('[RtcSessionController]: Timer expired. Send all pending candidates.');
                    delete settings.collectCandidatesTimeout;
                    if (settings.pendingCandidates) {
                        sendIceCandidates(settings.pendingCandidates, pc);
                        delete settings.pendingCandidates;
                    }
                }, DEFAULT_TRICKLE_ICE_TIMEOUT);
            }
            settings.pendingCandidates.push(data);
        }

        function checkRelayCandidate(pc, data) {
            pc = pc || _pc;
            var settings = getSettings(pc);
            if (!settings) {
                return false;
            }

            // We only need to check relay candidates for the screen share connection
            if (RtcSessionController.allowAllIceCandidatesScreenShare || data.sdpMid !== _pc.getScreenShareMediaId() ||
                settings.hasRelayRtpCandidate || settings.sendAllCandidates) {
                return true;
            }
            var candidate = new IceCandidate(data.candidate);
            if (candidate.isTcpTlsRelay()) {
                if (candidate.isRtp()) {
                    logger.info('[RtcSessionController]: Found local relay candidate for TURN over TCP/TLS. Remove queued candidates.');
                    settings.hasRelayRtpCandidate = true;
                    delete settings.queuedCandidates;
                    if (settings.relayRtpCandidateTimeout) {
                        window.clearTimeout(settings.relayRtpCandidateTimeout);
                        delete settings.relayRtpCandidateTimeout;
                    }
                }
                return true;
            }
            if (!settings.queuedCandidates) {
                settings.queuedCandidates = [];
                logger.debug('[RtcSessionController]: Start timer to wait for relay candidate for TURN over TCP/TLS.');
                settings.relayRtpCandidateTimeout = window.setTimeout(function () {
                    logger.info('[RtcSessionController]: Timer expired. Stop waiting for relay candidate for TURN over TCP/TLS and send all pending candidates.');
                    delete settings.relayRtpCandidateTimeout;
                    if (settings.queuedCandidates) {
                        sendIceCandidates(settings.queuedCandidates, pc);
                        delete settings.queuedCandidates;
                    }
                    settings.sendAllCandidates = true;
                }, DESKTOP_RELAY_CANDIDATES_TIMEOUT);
            }
            // Save candidate in case we don't get any relay candidate for TURN over TCP/TLS
            settings.queuedCandidates.push(data);
            logger.debug('[RtcSessionController]: Queue candidate in case there is no TURN over TCP/TLS relay candidate');
            return false;
        }

        function waitConnectedState(pc) {
            pc = pc || _pc;

            if (!pc) {
                logWarning('Terminating waitConnectedState for non existing pc.');
                return;
            }

            clearConnectedTimer(pc);

            if (_disableConnectedTimer) {
                logWarning('ICE connected timer is disabled');
                return;
            }

            if (isPcConnected(pc)) {
                setSdpStatus(SdpStatus.Connected, pc);
                return;
            }

            // Wait until (ice)connectionstate is 'connected' or 'completed' and fail if timeout expired.
            // The timeout will be restarted on each ICE_CANDIDATES message or event.
            var timeout = _isMockedCall ? MOCK_CONNECT_TIMEOUT : CONNECTED_TIMEOUT;
            logger.info('[RtcSessionController]: Starting ICE connected timer');
            var connectedTimer = window.setTimeout(function () {
                setSetting(pc, 'connectedTimer', null);
                var sdpStatus = getSetting(pc, 'sdpStatus');
                if (pc.iceConnectionState === 'connected' || pc.iceConnectionState === 'completed') {
                    // Sometimes we don't get the iceConnectionState=connected event, but proceed with the call if it's connected
                    logger.debug('[RtcSessionController]: Missed iceConnectionState=connected event but media is connected');
                    pc.connected = true;
                    setSdpStatus(SdpStatus.Connected, pc);
                    sendIceConnected(pc, 'audio/video');
                    return;
                }
                if ((sdpStatus === SdpStatus.AnswerSent || sdpStatus === SdpStatus.AnswerReceived || sdpStatus === SdpStatus.AnswerApplied) && !isPcConnected(pc)) {
                    if (_isMockedCall) {
                        // Simulate SDP connected for the mock server.
                        setSdpStatus(SdpStatus.Connected, pc);
                    } else {
                        logger.warn('[RtcSessionController]: Timed out waiting for connected state.');
                        handleConnectionFailed();
                    }
                }
            }, timeout);
            setSetting(pc, 'connectedTimer', connectedTimer);
        }

        function closeNextPC() {
            if (_nextPc) {
                logger.debug('[RtcSessionController]: Closing the pre-allocated peer connection');
                setSetting(_pc, 'nextPcLocalSdpSetAt', 0);
                closePC(_nextPc);
                _nextPc = null;
            }
        }

        function closePC(pc, stats) {
            unregisterPcEvtHandlers(pc, stats);
            if (pc) {
                if (pc.iceDisconnectTimeout) {
                    window.clearTimeout(pc.iceDisconnectTimeout);
                    delete pc.iceDisconnectTimeout;
                }
                WebRTCAdapter.closePc(pc);
            }
        }

        function addLocalStreams(pc) {
            if (!pc) {
                return false;
            }
            var pcStreams = pc.getLocalStreams();
            if (!Utils.isEmptyArray(pcStreams)) {
                return true;
            }
            logger.debug('[RtcSessionController]: Add local streams to ' + (pc === _pc ? 'existing' : 'next') + ' peer connection');
            try {
                _localStreams[LOCAL_AUDIO_VIDEO] && pc.addStream(_localStreams[LOCAL_AUDIO_VIDEO], getOfferToReceiveVideo(), _videoTrackConstraints);
                logger.debug('[RtcSessionController]: Added local audio/video stream');

                if (_localStreams[LOCAL_SCREEN_SHARE]) {
                    _localStreams[LOCAL_SCREEN_SHARE].isScreen = true;
                    pc.addStream(_localStreams[LOCAL_SCREEN_SHARE], getOfferToReceiveVideo(), _videoTrackConstraints);
                    logger.debug('[RtcSessionController]: Added local screenshare stream');
                }
            } catch (e) {
                logger.error('[RtcSessionController]: Failed to add Local Streams ', e);
                return false;
            }
            return true;
        }

        function removeLocalStreams() {
            logger.debug('[RtcSessionController]: Remove local streams from existing PeerConnections');
            try {
                var pcs = [_pc];
                pcs.forEach(function (pc) {
                    if (pc) {
                        pc.getLocalStreams().forEach(function (s, i) {
                            if (s) {
                                pc.removeStream(s);
                                logger.debug('[RtcSessionController]: Removed PeerConnection Stream  #', i);
                            }
                        });
                    }
                });
            } catch (e) {
                logger.error('[RtcSessionController]: Failed to remove Local Streams ', e);
                return false;
            }
            return true;
        }

        function stopStream(stream) {
            if (!stream) {
                return;
            }
            watchStreamEnded(stream, true);
            if (stream.getVideoTracks().length > 0) {
                stream.getVideoTracks().forEach(function (videoTrack) {
                    var trackId = WebRTCAdapter.getTrackId(videoTrack);
                    trackId && delete _videoTrackConstraints[trackId];
                });
            }
            WebRTCAdapter.stopMediaStream(stream);
        }

        function stopStreams(streams) {
            var reusedStreams = streams === _oldLocalStreams ? _localStreams : _oldLocalStreams;
            streams && streams.forEach(function (stream) {
                // Make sure the stream being stopped is not being reused. If it is, don't stop it!
                if (!reusedStreams || reusedStreams.indexOf(stream) === -1) {
                    stopStream(stream);
                }
            });
        }

        function setLocalVideoStreams(streams) {
            // Index 0 is video stream. Index 1 is the desktop stream.
            var videoStreams = [null, null];

            streams && streams.forEach(function (stream, idx) {
                if (stream && stream.getVideoTracks().length > 0) {
                    videoStreams[idx] = stream;
                }
            });

            if (_localVideoStream !== videoStreams[0] || _localDesktopStream !== videoStreams[1]) {
                _localVideoStream = videoStreams[0];
                logger.debug('[RtcSessionController]: Set local video stream to ', WebRTCAdapter.getStreamId(_localVideoStream) || '<null>');

                _localDesktopStream = videoStreams[1];
                logger.debug('[RtcSessionController]: Set local desktop stream to ', WebRTCAdapter.getStreamId(_localDesktopStream) || '<null>');

                sendLocalVideoStream();
            }
        }

        function getScaleFactor(hdVideo, resolution) {
            var lowVideoSdpParams = RtcSessionController.sdpParameters.lowVideo;
            var scaleFactor;
            if (!hdVideo || !resolution) {
                scaleFactor = lowVideoSdpParams.scaleFactor;
            } else {
                scaleFactor = lowVideoSdpParams[resolution].scaleFactor;
            }
            logger.debug('[RtcSessionController]: scaleFactor is ' + scaleFactor + ' (hdVideo=' + hdVideo + ', resolution=' + resolution + ')');
            return scaleFactor;
        }

        function setLocalStream(stream, index) {
            // Index 0 is the Audio+Video stream
            // Index 1 is the Desktop (screen-sharing) stream
            if (index !== LOCAL_AUDIO_VIDEO && index !== LOCAL_SCREEN_SHARE) {
                logger.error('[RtcSessionController]: setLocalStream invoked with invalid index:', index);
                return;
            }

            if (_localStreams[index] === stream) {
                // No changes
                return;
            }

            if (_localStreams[index]) {
                // Stop the previous local stream
                stopStream(_localStreams[index]);
            }
            _localStreams[index] = stream;

            // apply max bitrate for video and screen share stream
            var maxBw, mediaType, quality;
            if (index === LOCAL_AUDIO_VIDEO && _mediaConstraints.video) {
                if (_mediaConstraints.hdVideo) {
                    if (_mediaConstraints.videoResolution) {
                        var hdVideo = RtcSessionController.sdpParameters.hdVideo[_mediaConstraints.videoResolution];
                        maxBw = hdVideo && hdVideo.maxBw;
                    }
                    mediaType = 'hdVideo';
                    quality = Constants.VideoQuality.HIGH;
                } else {
                    mediaType = 'video';
                    quality = Constants.VideoQuality.NORMAL;
                }
                if (!maxBw) {
                    maxBw = RtcSessionController.sdpParameters[mediaType].maxBw;
                }
            } else if (index === LOCAL_SCREEN_SHARE && _mediaConstraints.desktop) {
                mediaType = _mediaConstraints.hdDesktop ? 'hdScreenShare' : 'screenShare';
                maxBw = RtcSessionController.sdpParameters[mediaType].maxBw;
                // adapt max bandwidth in case of remote control, default values might be higher than remote control values as they are configurable
                if (_mediaConstraints.remoteControlEnabled) {
                    maxBw = Math.max(maxBw, RtcSessionController.sdpParameters.rcScreenShare.maxBw);
                }
            }
            if (stream && stream.getVideoTracks().length > 0) {
                var videoTrack = stream.getVideoTracks()[0];

                if (videoTrack) {
                    var trackConstraint = {};
                    if (maxBw) {
                        trackConstraint.maxBitrate = maxBw * 1000;
                    }

                    // Only apply this for VIDEO not DESKTOP_SHARING
                    if (index === LOCAL_AUDIO_VIDEO) {
                        if (quality) {
                            trackConstraint.quality = quality;
                        }
                        // Set degradationPreference for HD calls on web/DA/SDK
                        if (_mediaConstraints.hdVideo && !_isMobile) {
                            trackConstraint.degradationPreference = RtcSessionController.degradationPreference;
                        }
                        // Check if a low resolution video stream (simulcast) should be added (if not already added)
                        if (isSimulcastEnabled() && stream.getVideoTracks().length < 2) {
                            var scaleResolutionDownBy = getScaleFactor(_mediaConstraints.hdVideo, _mediaConstraints.videoResolution);
                            var lowVideoTrack = videoTrack.clone();
                            var lowTrackConstraint = {
                                quality: Constants.VideoQuality.LOW,
                                maxBitrate: RtcSessionController.sdpParameters.lowVideo.maxBw * 1000,
                                scaleResolutionDownBy: scaleResolutionDownBy,
                                direction: _isMobile ? 'sendonly' : 'sendrecv'
                            };
                            _videoTrackConstraints[WebRTCAdapter.getTrackId(lowVideoTrack)] = lowTrackConstraint;
                            stream.addTrack(lowVideoTrack);
                        }
                    }
                    _videoTrackConstraints[WebRTCAdapter.getTrackId(videoTrack)] = trackConstraint;
                }
            }
            setLocalVideoStreams(_localStreams);
        }

        function clearRemoteStreams() {
            if (!_remoteAudioStream && !_remoteVideoStreams.length) {
                return;
            }
            _remoteAudioStream = null;
            _remoteVideoStreams = [];
            sendRemoteStreams();
        }

        function putRemoteStreamUnified(stream) {
            var updated = false;
            var videoTracks = stream.getVideoTracks();
            if (videoTracks.length) {
                var found = _remoteVideoStreams.some(function (v) {
                    if (v.streamId === stream.mid) {
                        logger.debug('[RtcSessionController]: Updated remote video streamId=' + stream.mid);
                        v.stream = stream;
                        updated = true;
                        return true;
                    }
                    return false;
                });
                if (!found) {
                    logger.debug('[RtcSessionController]: New remote video streamId=' + stream.mid);
                    _remoteVideoStreams.push({streamId: stream.mid, stream: stream});
                    updated = true;
                }
            } else {
                var audioTracks = stream.getAudioTracks();
                if (audioTracks.length) {
                    if ((_remoteAudioStream && _remoteAudioStream.streamId) === stream.mid) {
                        if (_remoteAudioStream.stream !== stream) {
                            _remoteAudioStream.stream = stream;
                            updated = true;
                        }
                    } else {
                        _remoteAudioStream = {streamId: stream.mid, stream: stream};
                        updated = true;
                    }
                }
            }
            return updated;
        }

        function putRemoteStream(stream) {
            if (!stream) {
                return false;
            }
            return putRemoteStreamUnified(stream);
        }

        function putRemoteStreams(streams, clearExisting) {
            if (!streams) {
                return;
            }
            if (!(streams instanceof Array)) {
                streams = [streams];
            }
            var updated = false;

            if (clearExisting && (_remoteAudioStream || _remoteVideoStreams.length)) {
                updated = true;
                _remoteAudioStream = null;
                _remoteVideoStreams = [];
            }

            streams.forEach(function (s) {
                _remoteStreams.push(s);
                if (putRemoteStream(s)) {
                    updated = true;
                }
            });
            if (updated) {
                sendRemoteStreams();
            }
        }

        function deleteRemoteStream(stream) {
            if (!stream) {
                return;
            }
            var updated = false;
            var videoTrackId = _midMappingEnabled ? WebRTCAdapter.getVideoTrackId(stream) : WebRTCAdapter.getVideoStreamTrackId(stream);
            if (videoTrackId) {
                _remoteVideoStreams.some(function (v, index) {
                    if (v.streamId === videoTrackId) {
                        _remoteVideoStreams.splice(index, 1);
                        updated = true;
                        return true;
                    }
                    return false;
                });
            }
            var audioTrackId = _midMappingEnabled ? WebRTCAdapter.getAudioTrackId(stream) : WebRTCAdapter.getAudioStreamTrackId(stream);
            if (_remoteAudioStream && (_remoteAudioStream.streamId === audioTrackId)) {
                _remoteAudioStream = null;
                updated = true;
            }

            if (updated) {
                sendRemoteStreams();
            }
        }

        function enableAudioTrack(enable) {
            // Enable/disable both _localStreams and _oldLocalStreams to ensure they
            // are in synch in case media renegotiation fails.
            var hasAudioTracks = false;
            if (_localStreams[LOCAL_AUDIO_VIDEO]) {
                hasAudioTracks = WebRTCAdapter.toggleAudio(_localStreams[LOCAL_AUDIO_VIDEO], enable);
            }
            _isMuted = !hasAudioTracks || !enable;

            if (_oldLocalStreams && _oldLocalStreams[LOCAL_AUDIO_VIDEO]) {
                WebRTCAdapter.toggleAudio(_oldLocalStreams[LOCAL_AUDIO_VIDEO], enable);
            }
            return hasAudioTracks;
        }

        function startRenegotiation(localRequest, renegotiationCb) {
            if (_renegotiationInProgress) {
                var sdpStatus = _pcSettings.main.sdpStatus;
                logger.debug('[RtcSessionController]: There\'s a renegotiation in progress, fail or succeed it based on current SdpStatus:', sdpStatus);
                if (sdpStatus === SdpStatus.AnswerReceived || sdpStatus === SdpStatus.AnswerApplied || sdpStatus === SdpStatus.AnswerSent) {
                    renegotiationSuccessful();
                } else {
                    renegotiationFailed('Renegotiation already in progress');
                }
            }

            if (_pc && _pc.iceDisconnectTimeout) {
                logger.info('[RtcSessionController]: Ignore previous ICE disconnection');
                window.clearTimeout(_pc.iceDisconnectTimeout);
                delete _pc.iceDisconnectTimeout;
            }

            // Save the current RTCPeerConnection and MediaStream objects in case
            // the renegotiation fails.
            _renegotiationInProgress = true;
            _renegotiationStartedLocally = !!localRequest;
            _renegotiationCb = renegotiationCb || null;

            _oldPC = _pc;
            _oldCallStats = _callStats;
            clearIceProperties(_oldPC);
            unregisterPcEvtHandlers(_oldPC, _oldCallStats);
            if (_nextPc) {
                if (localRequest && _pc.localDescription) {
                    logger.debug('[RtcSessionController]: Using pre-allocated peer connection');
                    _remoteStreams = [];
                    _pc = _nextPc;
                    _nextPc = null;
                    _callStats = createCallStatsHandler([_pc]);
                    registerPcEvtHandlers(_pc, _callStats);
                } else {
                    // The pre-allocated peer connection cannot be used, discard it
                    closeNextPC();
                    _pc = null;
                }
            } else {
                _pc = null;
            }
            _oldLocalStreams = _localStreams;
            _localStreams = [null, null];
            _oldMediaConstraints = Utils.shallowCopy(_mediaConstraints);
            _oldActiveMediaType = Utils.shallowCopy(_activeMediaType);
            _pendingRemoteSdp = null;
            _pendingChangeMedia = null;

            _pcSettings.main.sdpStatus = SdpStatus.None;
            _pcSettings.main.localOrigin = null;
        }

        function invokeRenegotiationCb(error) {
            if (!_renegotiationCb) {
                return;
            }
            try {
                if (error && error !== Constants.ReturnCode.CHOOSE_DESKTOP_MEDIA_CANCELLED &&
                    (typeof error !== 'string' || !error.startsWith('res_'))) {
                    error = 'res_ChangeMediaFailed';
                }
                _renegotiationCb(error);
            } catch (e) {
                logger.error(e);
            }
            _renegotiationCb = null;
        }

        function renegotiationSuccessful() {
            logger.info('[RtcSessionController]: The media renegotiation was successful');

            // Collect stats and close the old RTCPeerConnection object
            var oldCallStats = _oldCallStats;
            _oldCallStats = null;
            var oldPC = _oldPC;
            _oldPC = null;
            stopStats(oldCallStats, true, !oldPC.connectionBypassed)
            .catch(function () {
                logger.warn('[RtcSessionController]: Failed to stop stats for old RTCPeerConnection');
            })
            .then(function () {
                logger.info('[RtcSessionController]: Closing the old RTCPeerConnection');
                closePC(oldPC, oldCallStats);
            });

            // Stop the old MediaStream object
            logger.info('[RtcSessionController]: Stopping the old MediaStream');

            // Update the holding flag (it should already be set correctly, but...)
            if (_holdInProgress) {
                _holding = true;
            } else if (_retrieveInProgress) {
                _holding = false;
            }

            stopStreams(_oldLocalStreams);
            _oldLocalStreams = null;
            _oldMediaConstraints = null;
            _oldActiveMediaType = null;

            // Update the local and remote streams
            setLocalVideoStreams(_localStreams);
            var remoteStreams = _pc.getRemoteStreams();
            putRemoteStreams(remoteStreams, true);
            enableAudioTrack(!_isMuted);

            _holdInProgress = false;
            _retrieveInProgress = false;
            _renegotiationInProgress = false;
            _pendingAbort = false;
            _isDirectUpgradingToConf = false;

            // Reset the _dtmfSender object so that it's reinitialized when the canSendDTMFDigits() is called
            _dtmfSender = null;
            // Send the onMediaUpdate event for the Call object
            sendMediaUpdate();
            // Send onNetworkQuality event to indicate that there's no quaility info available
            // until the new CallStatsHandler collects the stats
            sendEvent(_that.onNetworkQuality, {quality: null});

            invokeRenegotiationCb();
        }

        function renegotiationFailed(error) {
            logger.info('[RtcSessionController]: The media renegotiation has failed: ', error);

            // Close the new RTCPeerConnection object
            clearIceProperties(_pc);
            closePC(_pc, _callStats);
            _pcSettings.main.sdpStatus = SdpStatus.Connected;
            _pc = _oldPC;
            _callStats = _oldCallStats;
            registerPcEvtHandlers(_oldPC);
            _oldPC = null;
            _oldCallStats = null;

            // Stop the new MediaStream object
            stopStreams(_localStreams);
            _localStreams = _oldLocalStreams;
            _oldLocalStreams = null;

            // Update the local and remote streams
            setLocalVideoStreams(_localStreams);

            //Restore media constraints and active media type
            _mediaConstraints = Utils.shallowCopy(_oldMediaConstraints);
            _activeMediaType = Utils.shallowCopy(_oldActiveMediaType);
            _oldMediaConstraints = null;
            _oldActiveMediaType = null;

            // Restore the holding flag if applicable
            if (_holdInProgress) {
                // Hold request failed
                _holding = false;
            } else if (_retrieveInProgress) {
                // Retrieve request failed. Call is still held.
                _holding = true;
            }

            _holdInProgress = false;
            _retrieveInProgress = false;
            _renegotiationInProgress = false;
            if (_isDirectUpgradingToConf) {
                _isDirectCall = true;
                _isDirectUpgradingToConf = false;
            }

            if (_pendingAbort) {
                if (typeof _pendingAbort === 'function') {
                    _pendingAbort();
                }
                _pendingAbort = false;
            }

            // Send the onMediaUpdate event for the Call object
            sendMediaUpdate();

            invokeRenegotiationCb(error || 'res_ChangeMediaFailed');
        }

        function handleConnectionFailed() {
            handleFailure('res_CallMediaFailed');
        }

        function handleFailure(err, errCb) {
            if (err === Constants.ReturnCode.CHOOSE_DESKTOP_MEDIA_CANCELLED) {
                // Special case, the user cancelled the Get User Media
                logger.info('[RtcSessionController]: User cancelled the screen share request');
            } else if (err === 'res_RestartBrowser') {
                logger.error('[RtcSessionController]: Failure: No ICE candidates collected. Browser needs to be restarted');
            } else {
                err = err || 'res_RTCError';
                logger.warn('[RtcSessionController]: There was an internal failure. Send error event: ', err);
            }

            if (_renegotiationInProgress) {
                renegotiationFailed(err);
            } else if (errCb) {
                errCb(err);
            } else {
                sendError(err);
            }
        }

        function processPendingSdp() {
            if (_pendingChangeMedia) {
                logger.info('[RtcSessionController]: Process the pending changeMedia');
                changeMedia(_pendingChangeMedia.mediaType, _pendingChangeMedia.cb, _pendingChangeMedia.options);
                _pendingChangeMedia = null;
            } else if (_pendingRemoteSdp) {
                logger.info('[RtcSessionController]: Process the pending remote description');
                _that.setRemoteDescription(_pendingRemoteSdp.sdp, _pendingRemoteSdp.cb);
                _pendingRemoteSdp = null;
            }
        }

        function setSdpStatus(newStatus, pc) {
            pc = pc || _pc;
            if (!pc) {
                logWarning('Could not set SDP status for non existing pc.');
                return;
            }

            var sdpStatus = getSetting(pc, 'sdpStatus');
            if (sdpStatus === SdpStatus.PrAnswerReceived && newStatus === SdpStatus.Connected) {
                logger.debug('[RtcSessionController]: Cannot change SDP status to ' + newStatus + ' without final SDP answer.');
                return;
            }
            if (sdpStatus !== newStatus) {
                logger.debug('[RtcSessionController]: Changed SDP status from ' + sdpStatus + ' to ' + newStatus);
                setSetting(pc, 'sdpStatus', newStatus);

                if (pc === _pc) {
                    switch (newStatus) {
                    case SdpStatus.AnswerApplied:
                        processPendingSdp();
                        break;
                    case SdpStatus.Connected:
                        pc.setMaxBitrate(_videoTrackConstraints);
                        if (_renegotiationInProgress) {
                            renegotiationSuccessful();
                        }
                        // Send the onSdpConnected event for the RtcHandler
                        sendSdpConnected();
                        processPendingSdp();
                        _callStats.start(); // Start collecting stats
                        break;
                    case SdpStatus.AnswerSent:
                        waitConnectedState(pc);
                        break;
                    }
                }
                // Chrome 73+ sets the iceConnectionState=connected too early and we don't process it. So check it now
                handleConnectionStateChange(pc);
            }
        }

        function updateLocalDescription(pc, rtcSdp) {
            if (!pc) {
                logWarning('Cannot update local description for non existing pc.');
                return null;
            }
            logger.debug('[RtcSessionController]: updateLocalDescription with mediaConstraints=', _mediaConstraints);

            var hasVideo = false;
            var parsedSdp = sdpParser.parse(rtcSdp.sdp);

            if (!_enableMultiplex) {
                // By default browsers add a=group to the SDP, so remove it if multiplex is not enabled
                sdpParser.disableMultiplex(parsedSdp);
            } else {
                logger.debug('[RtcSessionController]: Media multiplex enabled!');
            }

            if (_isTelephonyCall) {
                // Workaround: Chrome is now including UDP/TLS in the media protocols, which breaks outgoing calls to GTC/ATC
                // For now, we're removing UDP/TLS until SBC (SSM) supports it
                // Update 11/2018: SBC started supporting it in V9R2.20.00, but we still need to remove them until all SBCs in
                // the field are upgraded.
                // Update 11/2023. SBC load is on V10 and Mitel does not need it
                //sdpParser.removeAudioMediaProtocols(parsedSdp, ['UDP', 'TLS']);

                if ((pc.connectionBypassed || _ignoreNextConnection) && _isMobile) {
                    // For mobile clients, set connection mode to inactive just for the setLocalDescription. After it's applied
                    // it should be restored in sendSessionDescription()
                    pc.restoreAudioConnectionMode = sdpParser.getConnectionMode(parsedSdp, {mediaType: 'audio'});
                    sdpParser.setConnectionMode(parsedSdp, 'audio', 'inactive');
                }

                if (rtcSdp.type === 'offer') {
                    // SBC doesn't support a:mid attribute, so we have to save it and
                    // add it back when applying the answer description
                    var audioMLine = parsedSdp.m[0];
                    if (audioMLine && audioMLine.media === 'audio') {
                        audioMLine.a.some(function (a) {
                            if (a.field === 'mid') {
                                _lastAudioOfferMidAttr = a.value;
                                return true;
                            }
                            return false;
                        });
                    }
                }
            }

            if (!_holding) {
                if (_mediaConstraints.video || _mediaConstraints.desktop) {
                    hasVideo = sdpParser.hasVideo(parsedSdp);
                } else if (rtcSdp.type === 'answer') {
                    hasVideo = sdpParser.hasVideo(parsedSdp);
                    if (hasVideo && _remoteVideoDisabled) {
                        // Disable video
                        sdpParser.removeVideo(parsedSdp);
                        hasVideo = false;
                    }
                }
            }

            if (hasVideo) {
                if (_mediaConstraints.video) {
                    var xGoogle = getXGoogle(_mediaConstraints.hdVideo ? 'hdVideo' : 'video', _mediaConstraints.videoResolution);
                    sdpParser.setXGoogle(parsedSdp, xGoogle);
                    _callStats.setTransmitVideoParams({
                        mediaConstraints: _mediaConstraints,
                        trackConstraints: _videoTrackConstraints,
                        bitRates: xGoogle
                    });
                }
                if (_mediaConstraints.desktop) {
                    // Add xgoogle parameters to the last m-line (which should be the screenshare m-line)
                    sdpParser.setXGoogle(parsedSdp, getXGoogle(_mediaConstraints.hdDesktop ? 'hdScreenShare' : 'screenShare'),
                        parsedSdp.m.length - 1);
                }
            }
            sdpParser.setOpusParameters(parsedSdp, RtcSessionController.sdpParameters.audio);

            if (RtcSessionController.sdpParameters.preferredVideoCodec !== DEFAULT_VIDEO_CODEC && rtcSdp.type === 'offer') {
                sdpParser.setPreferredVideoCodec(parsedSdp, RtcSessionController.sdpParameters.preferredVideoCodec);
            }

            if (rtcSdp.type === 'offer') {
                // Remove H.264 codec from offer as it is not required and decoder instances caused
                // issues on some hardware when GPU hardware acceleration is enabled
                sdpParser.removeVideoCodecs(parsedSdp, ['H264']);
            }

            if (rtcSdp.type !== 'answer') {
                _activeMediaType = {audio: true, video: hasVideo};
            }

            var newSdp = new WebRTCAdapter.SessionDescription({
                type: rtcSdp.type,
                sdp: sdpParser.stringify(parsedSdp)
            });

            logger.debug('[RtcSessionController]: Finished modifying local SDP');
            return newSdp;
        }

        function getXGoogle(mediaType, resolution) {
            var xGoogle = Utils.shallowCopy(RtcSessionController.xGoogle[mediaType]);
            if (xGoogle && mediaType === 'hdVideo' && resolution) {
                var xGoogleRes = RtcSessionController.xGoogle.hdVideo[resolution];
                xGoogleRes && Object.assign(xGoogle, xGoogleRes);
            }
            return xGoogle;
        }

        function setVideoSdpParameters(pc, parsedSdp, desktopSdpMline) {
            var maxBw, mediaType;
            if (_mediaConstraints.desktop && !pc.desktopPeerConnection) {
                // This is a direct call with local screenshare active
                mediaType = _mediaConstraints.hdDesktop ? 'hdScreenShare' : 'screenShare';

            } else if (_mediaConstraints.hdVideo) {
                if (_mediaConstraints.videoResolution) {
                    var hdVideo = RtcSessionController.sdpParameters.hdVideo[_mediaConstraints.videoResolution];
                    maxBw = hdVideo && hdVideo.maxBw;
                }
                mediaType = 'hdVideo';
            } else {
                mediaType = 'video';
            }

            var isChromeWebRTC = _browser.chrome || _isMobile;
            if (isChromeWebRTC) {
                // https://bugs.chromium.org/p/webrtc/issues/detail?id=10641
                // https://bugs.chromium.org/p/chromium/issues/detail?id=1001080
                // Chrome requires some upper limit for it max bandwidth as it fail when availableOutgoingBitrate is
                // around 50 mbps. Please note that this setting has nothing to do with the sent video stream, which
                // is configured via maxBitrate in send parameters of transceiver.
                // For Firefox we can use the configure maxBw values per m-line, like done for PlanB.
                maxBw = MAX_BANDWIDTH;
            } else if (!maxBw) {
                maxBw = RtcSessionController.sdpParameters[mediaType].maxBw;
            }

            sdpParser.setVideoBandwidth(parsedSdp, maxBw, true);

            // Set bandwidth and xgoogle parameters to all video m-lines
            var videoXGoogle = getXGoogle(mediaType, _mediaConstraints.videoResolution);

            var desktopMlineIndex, desktopXGoogle;
            if (_pc.desktopPeerConnection) {
                var desktopMode = _mediaConstraints.hdDesktop ? 'hdScreenShare' : 'screenShare';
                maxBw = isChromeWebRTC ? MAX_BANDWIDTH : RtcSessionController.sdpParameters[desktopMode].maxBw;
                desktopXGoogle = RtcSessionController.xGoogle[desktopMode];

                // Set bandwidth and xgoogle parameters only on the screenshare m-line
                desktopMlineIndex = desktopSdpMline && desktopSdpMline.index;
                sdpParser.setVideoBandwidth(parsedSdp, maxBw, true, desktopMlineIndex);
                // Use the higher X-Google settings as common setting for all m-lines
                if ((desktopXGoogle.minBitRate || 0) > (videoXGoogle.minBitRate || 0)) {
                    videoXGoogle = desktopXGoogle;
                    logger.debug('[RtcSessionController]: Using X-Google parameters from screenshare: ', videoXGoogle);
                }
            }

            sdpParser.setXGoogle(parsedSdp, videoXGoogle);
        }

        function handleDisabledRemoteOfferMLines(pc, parsedSdp, sdpType, desktopSdpMline) {
            if (sdpType === 'offer' && pc === _pc) {
                var disabledRemoteMLines = getSetting(pc, 'disabledRemoteOfferMLines');
                disabledRemoteMLines.removeAll();
                if (_isDirectCall && !pc.desktopPeerConnection && desktopSdpMline) {
                    // This client only supports 1 peerconnection, so replace the video m-line with screenshare m-line
                    // Set video m-line as inactive and save it for later. We need to send it back in the answer SDP.
                    var disabledVideoMline = parsedSdp.m[DIRECT_CALL_SDP_M_LINE_INDEX.video];
                    disabledVideoMline.port = 0;
                    sdpParser.setConnectionMode({m: [disabledVideoMline]}, 'video', 'inactive');
                    disabledRemoteMLines.add(DIRECT_CALL_SDP_M_LINE_INDEX.video, disabledVideoMline);
                    parsedSdp.m[DIRECT_CALL_SDP_M_LINE_INDEX.video] = desktopSdpMline.sdp;
                    logger.debug('[RtcSessionController]: Disabled and replaced video m-line with screenshare m-line');
                }
            }
        }

        function updateRemoteDescription(pc, parsedSdp, sdpType, desktopSdpMline) {
            if (!pc) {
                logWarning('Cannot update remote description for non existing pc.');
                return null;
            }
            logger.debug('[RtcSessionController]: updateRemoteDescription with mediaConstraints=', _mediaConstraints);

            handleDisabledRemoteOfferMLines(pc, parsedSdp, sdpType, desktopSdpMline);

            var allVideoRecvOnly = false;
            var hasVideo = sdpParser.hasVideo(parsedSdp);
            if (hasVideo) {
                var videoModes = sdpParser.getVideoConnectionModes(parsedSdp);
                logger.info('[RtcSessionController]: Remote description has video modes set to ', videoModes);
                allVideoRecvOnly = videoModes.every(function (v) {
                    return v === 'recvonly';
                });
                hasVideo = !allVideoRecvOnly;
                setVideoSdpParameters(pc, parsedSdp, desktopSdpMline);
            }
            sdpParser.setOpusParameters(parsedSdp, RtcSessionController.sdpParameters.audio);

            var oldHeld = _held;
            if (sdpParser.isHold(parsedSdp)) {
                // If this is an SDP Offer, that means the other party is putting the call on hold.
                // If this is an SDP Answer AND we are not holding the call AND we offered audio,
                // that also means the other party is holding the call.
                _held = sdpType === 'offer' || (!_holding && _mediaConstraints.audio);
            } else {
                _held = false;
            }
            if (oldHeld !== _held) {
                if (_held) {
                    logger.info('[RtcSessionController]: The client has been held');
                } else {
                    logger.info('[RtcSessionController]: The client has been retrieved');
                }
            }

            if (_isTelephonyCall) {
                var audioMLine = parsedSdp.m[0];
                if (audioMLine && audioMLine.media === 'audio') {
                    var hasMid = audioMLine.a.some(function (a) { return a.field === 'mid'; });
                    if (!hasMid) {
                        // SBC doesn't support a=mid attribute and it's required by Unified Plan SDP so we need to add it here.
                        // Remember a previous a=mid from a local offer or default it to 'audio'.
                        _lastAudioOfferMidAttr = _lastAudioOfferMidAttr || 'audio';
                        logger.debug('[RtcSessionController]: Adding a=mid:' + _lastAudioOfferMidAttr + ' to SBC\'s answer SDP');
                        audioMLine.a.push({field: 'mid', value: _lastAudioOfferMidAttr});
                    }
                }
            }

            // Check if the remote party is adding or removing video
            var sdpStatus = getSetting(pc, 'sdpStatus');
            if (sdpType === 'offer' && sdpStatus === SdpStatus.Connected && !_holding && !_held) {
                if (_remoteVideoStreams.length && !hasVideo) {
                    sendRemoteVideoRemoved();
                } else if (!_remoteVideoStreams.length && hasVideo) {
                    sendRemoteVideoAdded();
                }
            }

            if (!_holding) {
                if (sdpType !== 'answer' || !allVideoRecvOnly) {
                    _activeMediaType = {audio: true, video: hasVideo};
                }
            }

            // Enable multiplex if the incoming direct call has it enabled
            _enableMultiplex = _isDirectCall && sdpType === 'offer' && sdpParser.isMultiplexEnabled(parsedSdp);

            return parsedSdp;
        }

        function getCandidatesTimeout() {
            var timeout = RtcSessionController.candidatesTimeout || DEFAULT_CANDIDATES_TIMEOUT;
            if (_numberOfExtraVideoChannels > 0) {
                timeout += timeout * (WebRTCAdapter.iceTimeoutSafetyFactor || 0);
            }
            logger.debug('[RtcSessionController]: getCandidatesTimeout:', timeout);
            return timeout;
        }

        function getTrickleIceTimeout() {
            var timeout = RtcSessionController.trickleIceTimeout || DEFAULT_TRICKLE_ICE_TIMEOUT;
            logger.debug('[RtcSessionController]: getTrickleIceTimeout:', timeout);
            return timeout;
        }

        function localSdpSetAndSend(pc, sdpType) {
            if (!pc) {
                logWarning('Terminating localSdpSetAndSend for non existing pc.');
                return;
            }

            var timeout;
            logDebug('Local SDP set for the audio/video connection.');

            if (_renegotiationInProgress && _pendingAbort) {
                renegotiationFailed('Renegotiation aborted');
                return;
            }

            var trickleIce, sdpStatus, sdpStatusToSend;
            if (sdpType === 'offer') {
                trickleIce = getSetting(pc, 'trickleIceForOffer');
                sdpStatus = SdpStatus.OfferPending;
                sdpStatusToSend = SdpStatus.OfferSent;
            } else {
                trickleIce = getSetting(pc, 'trickleIceForAnswer');
                sdpStatus = SdpStatus.AnswerPending;
                sdpStatusToSend = SdpStatus.AnswerSent;
            }

            setSdpStatus(sdpStatus, pc);
            setSetting(pc, 'localSdpType', sdpType);

            if (trickleIce) {
                timeout = getTrickleIceTimeout();

                if (timeout > 0) {
                    logDebug('Trickle ICE is enabled. Start a short timer before sending the audio/video SDP (' + timeout + 'ms).');

                    window.setTimeout(function () {
                        if (getSetting(pc, 'sdpStatus') === sdpStatus) {
                            logDebug('Trickle ICE timer expired. Send the audio/video SDP');
                            sendSessionDescription(pc, sdpStatusToSend);
                        }
                    }, timeout);
                } else {
                    logDebug('Trickle ICE is enabled. Send the audio/video SDP');
                    sendSessionDescription(pc, sdpStatusToSend);
                }
            } else {
                timeout = getCandidatesTimeout();
                var sdpPreset = getSetting(pc, 'nextPcLocalSdpSetAt');
                if (sdpPreset) {
                    // This is a pre-allocated PC, check if all candidates have been collected.
                    // If not, check how much time we still have to wait.
                    setSetting(pc, 'nextPcLocalSdpSetAt', 0);
                    if (pc.endOfCandidates) {
                        logDebug('Already collected all ICE candidates for pre-allocated PC. Send the audio/video SDP');
                        sendSessionDescription(pc, sdpStatusToSend);
                        return;
                    }
                    var elapsed = Date.now() - sdpPreset;
                    timeout = Math.max(timeout - elapsed, 0);
                }
                logDebug('Waiting for audio/video ICE candidates (' + timeout + 'ms).');

                var candidatesTimer = window.setTimeout(function () {
                    logDebug('Timed out waiting for audio/video candidates.');
                    if (!isActivePc(pc)) {
                        logWarning('RTCPeerConnection instance for audio/video connection no longer exists.');
                        return;
                    }
                    clearShortCandidatesTimer(pc);
                    setSetting(pc, 'candidatesTimer', null);
                    if (getSetting(pc, 'sdpStatus') === sdpStatus) {
                        logDebug('[RtcSessionController]: Send the audio/video SDP');
                        sendSessionDescription(pc, sdpStatusToSend);
                    }
                }, timeout);
                setSetting(pc, 'candidatesTimer', candidatesTimer);
            }
        }

        function setLocalAndSendMessage(audioVideoSdp) {
            logger.debug('[RtcSessionController]: Successfully created local audio/video description:', audioVideoSdp);

            clearCandidatesTimer(_pc);

            if (_renegotiationInProgress && _pendingAbort) {
                renegotiationFailed('Renegotiation aborted');
                return;
            }

            var onLocalSdpSet = function (pc, err) {
                if (err) {
                    logger.error('[RtcSessionController]: Failed to apply Local ' + pc + ' description:', err.message || err);
                    handleFailure();
                } else {
                    logger.debug('[RtcSessionController]: Local description was successfully applied. PC=', pc);
                }
                if (_pc && _ignoreNextConnection) {
                    sendSessionDescription(_pc, SdpStatus.Connected);
                    if (_pc) {
                        _pc.connectionBypassed = true;
                        unregisterPcEvtHandlers(_pc, _callStats);
                    }
                    return;
                }

                if (!_renegotiationInProgress) {
                    sendMediaUpdate();
                }

                var sdpType = audioVideoSdp && audioVideoSdp.type;
                localSdpSetAndSend(_pc, sdpType);
            };
            try {
                if (_pc && audioVideoSdp) {
                    var mainPcCb = onLocalSdpSet.bind(null, 'audio/video');
                    audioVideoSdp = updateLocalDescription(_pc, audioVideoSdp);
                    _pc.setLocalDescription(audioVideoSdp, mainPcCb, mainPcCb);
                    _pc.startTime = Date.now();
                }
            } catch (e) {
                onLocalSdpSet('unknown', e || 'error');
            }
        }

        function onAnswerSdp() {
            if (_holdInProgress || (_holding && !_retrieveInProgress)) {
                _pc.oniceconnectionstatechange = null;
                logger.debug('[RtcSessionController]: Call is already held or is being put on hold. Stop monitoring iceConnectionState changes');
                setSdpStatus(SdpStatus.Connected, _pc);
            } else if (getSetting(_pc, 'sdpStatus') === SdpStatus.AnswerReceived) {
                setSdpStatus(SdpStatus.AnswerApplied, _pc);
            }
        }

        function getOfferToReceiveVideo() {
            return (_supportsVideoReceiverConfiguration || !_remoteVideoDisabled) && !_isTelephonyCall;
        }

        function updateTrickleIceAndStatus(pc, type, parsedSdp) {
            if (!pc) {
                logWarning('Terminating updateTrickleIceAndStatus for non existing pc.');
                return;
            }

            switch (type) {
            case 'offer':
                var trickleIce = !RtcSessionController.disableTrickleIce && isTrickleIceIndicated(type, parsedSdp);
                setSetting(pc, 'trickleIceForAnswer', trickleIce);
                if (trickleIce) {
                    logger.info('[RtcSessionController]: Enabled Trickle-ICE for answer.');
                } else {
                    logger.info('[RtcSessionController]: Disabled Trickle-ICE for answer.');
                }
                break;
            case 'pranswer':
            case 'answer':
                setSdpStatus(type === 'pranswer' ? SdpStatus.PrAnswerReceived : SdpStatus.AnswerReceived, pc);
                if (getSetting(pc, 'trickleIceForOffer')) {
                    // We may need to disable trickle ICE for Offer if the peer doesn't support it.
                    // This should never happen when connecting to other Circuit clients.
                    if (!isTrickleIceIndicated(type, parsedSdp)) {
                        setSetting(pc, 'trickleIceForOffer', false);
                        logger.warn('[RtcSessionController]: Disabled Trickle-ICE for offer.');
                    }
                }
                break;
            }
        }

        function setRemoteDescription(rtcSdp, sendingEarlyMedia) {
            logger.debug('[RtcSessionController]: setRemoteDescription()');

            try {
                // Make sure that we already have an RTCPeerConnection object
                if (!createPeerConnection()) {
                    handleFailure();
                    return;
                }

                var localStreams = _pc.getLocalStreams();

                var parsedSdp = sdpParser.parse(rtcSdp.sdp);

                // Screenshare m-line (if available)
                var desktopSdpMline, desktopRemoteSsrc;
                if (rtcSdp.screen) {
                    // Try to find the screenshare m-line indicated by the server
                    var index = sdpParser.findMediaLineIndex(parsedSdp, rtcSdp.screen);
                    if (index !== -1) {
                        // Found it! Retrieve it and remove it from the 'main' sdp
                        desktopSdpMline = {
                            sdp: parsedSdp.m[index],
                            index: index,
                            mid: rtcSdp.screen
                        };
                        desktopRemoteSsrc = sdpParser.getSsrcList(desktopSdpMline.sdp);
                    }
                }

                if (sendingEarlyMedia && rtcSdp.type === 'offer') {
                    // Disable trickle ICE for early media
                    logger.debug('[RtcSessionController]: Disable Trickle-ICE for early media');
                    sdpParser.fixTrickleIceOption(parsedSdp, false);
                }

                updateTrickleIceAndStatus(_pc, rtcSdp.type, parsedSdp);

                var onRemoteSdpSet = function (pc, err) {
                    if (err) {
                        logger.error('[RtcSessionController]: Failed to apply Remote ' + pc + ' description:', err.message || err);
                        handleFailure();
                    } else {
                        logger.debug('[RtcSessionController]: Remote description was successfully applied. PC=', pc);
                    }

                    logger.debug('[RtcSessionController]: Remote Descriptions were successfully applied.');
                    if (!_renegotiationInProgress) {
                        sendMediaUpdate();
                    }

                    /////////////////////////////////////////////////////////////////////////////
                    // No RTP packets should be sent if:
                    //   1. The client is holding the call
                    //   2. SDP offer is inactive or sendonly (i.e. the client is being held)
                    //
                    // The browser will not send RTP packets if the RTCPeerConnection instance
                    // does not have a local stream.
                    /////////////////////////////////////////////////////////////////////////////

                    switch (rtcSdp.type) {
                    case 'offer':
                        if (_holding || _held) {
                            if (localStreams.length > 0) {
                                removeLocalStreams();
                                logger.debug('[RtcSessionController]: Removed Local Stream (hold)');
                            } else {
                                logger.debug('[RtcSessionController]: Local Stream has not been set (hold)');
                            }
                        } else {
                            addLocalStreams(_pc);
                        }

                        logger.debug('[RtcSessionController]: Creating Local Description Answer...');

                        var sdpConstraints = {
                            mandatory: {
                                OfferToReceiveAudio: true,
                                OfferToReceiveVideo: getOfferToReceiveVideo()
                            }
                        };

                        _pc.createAnswer(function (mainSdp) {
                            setLocalAndSendMessage(mainSdp);
                        }, function (error) {
                            error = error || 'Unspecified';
                            logger.error('[RtcSessionController]: Failed to create Local Description: ', error.message || error);
                            handleFailure();
                        }, sdpConstraints, {audioInactive: _holding});
                        break;

                    case 'pranswer':
                        // Early Media applied
                        logger.debug('[RtcSessionController]: Received pranswer, applying early media SDP');
                        onAnswerSdp();
                        waitConnectedState(_pc);
                        // SDP status has already been set to SdpStatus.PrAnswerReceived
                        break;

                    case 'answer':
                        onAnswerSdp();
                        waitConnectedState(_pc);
                        break;

                    default:
                        logger.error('[RtcSessionController]: Invalid SDP Type = ', rtcSdp.type);
                        handleFailure();
                        break;
                    }
                };

                if (_pc) {
                    parsedSdp = updateRemoteDescription(_pc, parsedSdp, rtcSdp.type, desktopSdpMline);
                    var updatedMainSdp = new WebRTCAdapter.SessionDescription({
                        type: rtcSdp.type,
                        sdp: sdpParser.stringify(parsedSdp)
                    });
                    updatedMainSdp.screen = desktopSdpMline && desktopSdpMline.sdp;
                    logger.debug('[RtcSessionController]: Modified remote audio/video SDP:', updatedMainSdp);

                    var mainPcCb = onRemoteSdpSet.bind(null, 'audio/video');
                    _pc.setRemoteDescription(updatedMainSdp, mainPcCb, mainPcCb);
                    _pc.desktopRemoteSsrc = desktopRemoteSsrc;
                    _pc.remoteOrigin = sdpParser.getOrigin(parsedSdp).trim();
                    _pc.startTime = Date.now();
                }
            } catch (e) {
                logger.error(e);
                handleFailure();
            }
        }

        function discardPreAllocatedPc() {
            setSetting(_pc, 'nextPcLocalSdpSetAt', 0);
            closePC(_pc);
            _pc = null;
        }

        function renegotiateMedia() {
            logger.debug('[RtcSessionController]: renegotiateMedia()');

            if (_renegotiationInProgress && _pendingAbort) {
                renegotiationFailed('Renegotiation aborted');
                return;
            }

            // Make sure that we already have an RTCPeerConnection object
            if (!createPeerConnection()) {
                handleFailure();
                return;
            }

            // Do not add the local stream to the RTCPeerConnection instance if the
            // client is holding the call. No RTP packets are sent in this case.
            if (!_holding) {
                addLocalStreams(_pc);
            } else {
                logger.debug('[RtcSessionController]: Local Stream has not been set (holding)');
            }

            logger.debug('[RtcSessionController]: Creating Local Descriptions...');

            var offerConstraints = {
                mandatory: {
                    OfferToReceiveAudio: true,
                    OfferToReceiveVideo: getOfferToReceiveVideo()
                }
            };

            try {
                _enableMultiplex = false;

                var localOffer = _pc.localDescription;
                if (localOffer) {
                    // Pre-allocated peer connection
                    if (_holding) {
                        // We need to update the Transceiver objects in the peer connection
                        // so discard it and start over
                        discardPreAllocatedPc();
                        renegotiateMedia();
                        return;
                    } else {
                        localSdpSetAndSend(_pc, localOffer.type);
                    }
                } else {
                    _pc.createOffer(function (mainSdp) {
                        setLocalAndSendMessage(mainSdp);
                    }, function (error) {
                        error = error || 'Unspecified';
                        logger.error('[RtcSessionController]: Failed to create audio/video Local Description: ', error.message || error);
                        handleFailure();
                    }, offerConstraints, {audioInactive: _holding});
                }
            } catch (e) {
                logger.error(e);
                handleFailure();
            }
        }

        function compareMediaConstraints(c1, c2) {
            if (!c1 || !c2) {
                return false;
            }
            return Object.keys(c1).every(function (key) {
                return c1[key] === c2[key];
            });
        }

        function changeMedia(mediaType, cb, changeOptions) {
            if (_pc && getSetting(_pc, 'sdpStatus') === SdpStatus.AnswerReceived) {
                logger.warn('[RtcSessionController]: Still processing the previous answer remote description. Queue the changeMedia');
                _pendingChangeMedia = {mediaType: mediaType, cb: cb, options: changeOptions};
                return;
            }
            if (!isConnStable()) {
                sendAsyncCallback(cb, 'Connection not stable');
                return;
            }
            changeOptions = changeOptions || {};

            if (changeOptions.isDirectUpgradingToConf) {
                _isDirectCall = false;
                _isDirectUpgradingToConf = true;
            }

            var newConstraints = Utils.shallowCopy(_mediaConstraints);
            if (typeof mediaType.audio === 'boolean') {
                newConstraints.audio = mediaType.audio;
            }
            if (typeof mediaType.video === 'boolean') {
                newConstraints.video = mediaType.video;
            }
            if (typeof mediaType.hdVideo === 'boolean') {
                newConstraints.hdVideo = mediaType.hdVideo;
            }
            if (mediaType.videoResolution) {
                newConstraints.videoResolution = mediaType.videoResolution;
            }
            if (typeof mediaType.desktop === 'boolean') {
                newConstraints.desktop = mediaType.desktop;
            }
            if (typeof mediaType.hdDesktop === 'boolean') {
                newConstraints.hdDesktop = mediaType.hdDesktop;
            }
            if (mediaType.minFrameRate) {
                newConstraints.minFrameRate = mediaType.minFrameRate;
            }
            newConstraints = normalizeMediaType(newConstraints);
            if (_nextPc && (_dontReuseAudioStream || !compareMediaConstraints(_mediaConstraints, newConstraints))) {
                logger.info('[RtcSessionController]: Media constraints are changing. Discard pre-allocated connection.');
                closeNextPC();
            }

            startRenegotiation(true, cb);
            _mediaConstraints = newConstraints;

            if (_pc) {
                // This is the pre-allocated connection (i.e. _nextPc), so it already contains the local streams in it
                _localStreams = _oldLocalStreams;
                renegotiateMedia();
            } else {
                if (changeOptions.videoOptions && changeOptions.videoOptions.mediaStream) {
                    addNewVideoStream(changeOptions.videoOptions.mediaStream, newConstraints);
                }
                getUserMedia(renegotiateMedia, handleFailure);
            }
        }

        function addNewVideoStream(newVideoStream, mediaType) {
            if (_oldLocalStreams[LOCAL_AUDIO_VIDEO]) {
                if (mediaType.audio) {
                    var audioTracks = newVideoStream.getAudioTracks();
                    if (!audioTracks.length) {
                        // The new video stream doesn't have audio track(s) so grab them from the old stream
                        var oldAudioTrack = _oldLocalStreams[LOCAL_AUDIO_VIDEO].getAudioTracks()[0];
                        if (oldAudioTrack) {
                            newVideoStream.addTrack(oldAudioTrack.clone());
                        }
                    }
                }
                // Setting the LOCAL_AUDIO_VIDEO stream here will make the getUserMedia use it instead of
                // getting new stream from the mic/camera
                _that.setLocalStream(LOCAL_AUDIO_VIDEO, newVideoStream);
            }
        }

        function closePeerConnections() {
            if (_oldPC) {
                logger.debug('[RtcSessionController]: Closing the old audio/video RTCPeerConnection');
                closePC(_oldPC, _oldCallStats);
                _oldPC = null;
            }

            if (_pc) {
                logger.debug('[RtcSessionController]: Closing the RTCPeerConnection');
                closePC(_pc, _callStats);
                _pc = null;
            }

            closeNextPC();
        }

        function close() {
            logger.debug('[RtcSessionController]: Closing the RTC session controller');
            _activeMediaType = {audio: false, video: false};

            if (_pc) {
                setSdpStatus(SdpStatus.None, _pc);
                clearCandidatesTimer(_pc);
                clearConnectedTimer(_pc);
                clearIceProperties(_pc);
            }

            // Stop call stats collection. If there is an Old PC then we should send the stats for
            // the Old PC since the new PC wouldn't be connected in this case.
            var callStats = _oldCallStats || _callStats;
            if (callStats) {
                var statsPc = _oldPC || _pc;
                stopStats(callStats, false, !!statsPc && !statsPc.connectionBypassed)
                .catch(function () {
                    logger.warn('[RtcSessionController]: Failed to stop stats collection');
                })
                .then(function () {
                    logger.info('[RtcSessionController]: Closing all RTCPeerConnection instances');
                    closePeerConnections();
                });
            } else {
                closePeerConnections();
            }

            if (_oldLocalStreams) {
                logger.debug('[RtcSessionController]: Stopping the old MediaStream');
                stopStreams(_oldLocalStreams);
                _oldLocalStreams = null;
            }

            _pcSettings.main.localOrigin = null;

            _renegotiationInProgress = false;
            _pendingAbort = false;
            _holdInProgress = false;
            _retrieveInProgress = false;
            _holding = false;
            _held = false;
            _isMuted = false;
            _dtmfSender = null;
            _screenShareParams = null;

            setLocalStream(null, LOCAL_AUDIO_VIDEO);
            setLocalStream(null, LOCAL_SCREEN_SHARE);
            clearRemoteStreams();
            sendClosed();
            _sessionTerminated = true;

            // Unregister all event handlers, except onQosAvailable, which will be done later
            unregisterEventHandlers(['onQosAvailable']);
        }

        function enableDTMFSender() {
            if (!_pc) {
                logger.error('[RtcSessionController]: PeerConnection not setup');
                return false;
            }

            if (_dtmfSender) {
                logger.debug('[RtcSessionController]: DTMF Sender already enabled');
                return true;
            }

            var localStreams = _pc.getLocalStreams();
            if (localStreams.length > 0) {
                // [Pastro, Rodrigo] In our client we will always have a single stream, at least in the near future.
                var audioTracks = localStreams[LOCAL_AUDIO_VIDEO].getAudioTracks();
                if (audioTracks.length > 0) {
                    _dtmfSender = _pc.createDTMFSender(audioTracks[0]);
                    if (_dtmfSender) {
                        _dtmfSender.ontonechange = handleDTMFToneChange.bind(null, _pc);
                        logger.info('[RtcSessionController]: Created DTMF Sender using remote audio track: ' + audioTracks[0].label);
                    } else {
                        return false;
                    }
                } else {
                    logger.error('[RtcSessionController]: No Audio Track to create DTMF Sender');
                    return false;
                }
            } else {
                logger.error('[RtcSessionController]: No Local Stream to create DTMF Sender');
                return false;
            }

            return true;
        }

        function startConn(remoteSdp, sendingEarlyMedia) {
            // Successfully got access to media input devices
            logger.debug('[RtcSessionController]: startConn()');
            if (remoteSdp) {
                _disableConnectedTimer = sendingEarlyMedia;
                setRemoteDescription(remoteSdp, sendingEarlyMedia);
            } else {
                addLocalStreams(_pc);

                logger.debug('[RtcSessionController]: Creating Local Description...');

                var offerConstraints = {
                    mandatory: {
                        OfferToReceiveAudio: true,
                        OfferToReceiveVideo: getOfferToReceiveVideo()
                    }
                };

                _enableMultiplex = false;

                _pc.createOffer(function (mainSdp) {
                    setLocalAndSendMessage(mainSdp);
                }, function (error) {
                    error = error || 'Unspecified';
                    logger.error('[RtcSessionController]: Failed to create Local Description: ', error.message || error);
                    handleFailure();
                }, offerConstraints, {audioInactive: _holding});
            }
        }

        function isConnStable(pc) {
            pc = pc || _pc;
            if (!pc) {
                if (_renegotiationInProgress) {
                    logger.warn('[RtcSessionController]: isConnStable: There is a pending media renegotiation');
                } else {
                    logger.debug('[RtcSessionController]: isConnStable: There is no connection');
                }
                return false;
            }

            var sdpStatus = getSetting(pc, 'sdpStatus');
            if (sdpStatus === SdpStatus.AnswerReceived || sdpStatus === SdpStatus.AnswerApplied) {
                logger.debug('[RtcSessionController]: isConnStable: Processing remote answer SDP, the connection is considered stable');
                return true;
            }
            if (sdpStatus === SdpStatus.AnswerSent) {
                logger.debug('[RtcSessionController]: isConnStable: Answer SDP sent, the connection is considered stable');
                return true;
            }
            if (_renegotiationInProgress) {
                logger.warn('[RtcSessionController]: isConnStable: There is a pending media renegotiation');
                return false;
            }
            if (pc.signalingState !== 'stable' && !pc.connectionBypassed) {
                logger.warn('[RtcSessionController]: isConnStable: The connection is not established. signalingState =', pc.signalingState);
                if (!_isMockedCall) {
                    return false;
                }
            }
            return true;
        }

        function sendAsyncCallback(cb, error) {
            if (typeof cb === 'function') {
                window.setTimeout(function () {
                    cb(error);
                }, 0);
            }
        }

        function setExtraVideoChannels() {
            if (_isDirectCall) {
                logger.debug('[RtcSessionController]: Set number of extra video channels for direct calls to 0');
                _numberOfExtraVideoChannels = 0;
            } else if (_isMobile) {
                logger.debug('[RtcSessionController]: Set number of extra video channels for mobile clients to 0');
                _numberOfExtraVideoChannels = 0;
            } else if (!_supportsVideoReceiverConfiguration && (_remoteVideoDisabled || _remoteVideoScreenOnlyAllowed)) {
                logger.debug('[RtcSessionController]: Remote video is disabled. Set number of extra video channels to 0.');
                _numberOfExtraVideoChannels = 0;
            } else {
                if (typeof RtcSessionController.maxVideoExtraChannels === 'number') {
                    _numberOfExtraVideoChannels = Math.max(RtcSessionController.maxVideoExtraChannels, 0);
                    _numberOfExtraVideoChannels = Math.min(_numberOfExtraVideoChannels, MAX_VIDEO_EXTRA_CHANNELS);
                } else {
                    _numberOfExtraVideoChannels = DEFAULT_VIDEO_EXTRA_CHANNELS;
                }
                logger.info('[RtcSessionController]: Set number of extra video channels to ', _numberOfExtraVideoChannels);
            }
        }

        function getVideoStreamId(streamIndex) {
            var result;
            var stream = _localStreams[streamIndex];
            if (stream && stream.getVideoTracks().length) {
                result = WebRTCAdapter.getVideoStreamTrackId(stream);
            }
            return result;
        }

        function getVideoMid(streamIndex) {
            var result;
            var stream = _localStreams[streamIndex];
            if (stream) {
                var track = stream.getVideoTracks()[0];
                if (track) {
                    result = _pc && _pc.getMediaId(track);
                }
            }
            return result;
        }

        // Statistics are required if the main connection has already been established
        function isQosRequired(isRenegotiation) {
            var pc = _oldPC || _pc;
            var sdpStatus = getSetting(pc, 'sdpStatus');

            return isRenegotiation ||// Make sure that we have offer/answer exchanged before generating QoS reports
                    (!!pc && (sdpStatus === SdpStatus.AnswerReceived ||
                    sdpStatus === SdpStatus.PrAnswerReceived ||
                    sdpStatus === SdpStatus.AnswerApplied ||
                    sdpStatus === SdpStatus.AnswerSent ||
                    sdpStatus === SdpStatus.Connected ||
                    sdpStatus === SdpStatus.None));
        }

        function stopStats(callStats, isRenegotiation, publishQos) {
            if (callStats) {
                var qosRequired = isQosRequired(isRenegotiation);

                return callStats.stop()
                .then(function (qos) {
                    if (publishQos && qosRequired && qos) {
                        // By the time the qos available, the _renegotiationInProgress flag is already cleared,
                        // that's why we need to pass this isRenegotiation parameter around
                        sendQosAvailable(qos, isRenegotiation, callStats.getLastSavedStats(), callStats.getStreamQualityData());
                    }
                    if (!isRenegotiation) {
                        _that.onQosAvailable = null;
                    }
                });
            } else if (!isRenegotiation) {
                _that.onQosAvailable = null;
            }
            return Promise.resolve();
        }

        function createNextPeerConnection() {
            if (_nextPc) {
                return;
            }
            logger.debug('[RtcSessionController]: Pre-allocate the next peer connection');
            if (createPeerConnection(true)) {
                var createdPc = _nextPc;

                createdPc.onicecandidate = function (event) {
                    if (createdPc !== _nextPc) {
                        return;
                    }
                    if (event.candidate) {
                        if (!event.candidate.candidate) {
                            return;
                        }
                        var candidate = new IceCandidate(event.candidate.candidate);
                        if (candidate.transport !== 'udp') {
                            return;
                        }
                        logger.debug('[RtcSessionController]:[Pre-allocated PC]: New ICE candidate: ', event.candidate);
                        if (!createdPc.hasRelayCandidates && candidate.typ === 'relay') {
                            createdPc.hasRelayCandidates = true;
                            // Adjust the time, so we would wait at most SHORT_CANDIDATES_TIMEOUT for the remaining candidates collection
                            setSetting(_pc, 'nextPcLocalSdpSetAt', Date.now() - getCandidatesTimeout() + SHORT_CANDIDATES_TIMEOUT);
                        }
                    } else {
                        logger.debug('[RtcSessionController]:[Pre-allocated PC]: End of ICE candidates');
                        createdPc.endOfCandidates = true;
                    }
                };

                if (addLocalStreams(createdPc)) {
                    var offerConstraints = {
                        mandatory: {
                            OfferToReceiveAudio: true,
                            OfferToReceiveVideo: getOfferToReceiveVideo()
                        }
                    };
                    createdPc.createOffer(function (offerSdp) {
                        logger.debug('[RtcSessionController]:[Pre-allocated PC]: Local description was successfully created');
                        offerSdp = updateLocalDescription(createdPc, offerSdp);
                        createdPc.setLocalDescription(offerSdp, function () {
                            // Save when the local SDP was set, so we know how long we need to wait for the candidates collection
                            setSetting(_pc, 'nextPcLocalSdpSetAt', Date.now());
                            logger.debug('[RtcSessionController]:[Pre-allocated PC]: Local description was successfully applied');
                        }, function (error) {
                            logger.warn('[RtcSessionController]:[Pre-allocated PC]: Error setting local description: ', error);
                            closeNextPC();
                        });
                        logger.debug('[RtcSessionController]:[Pre-allocated PC]: Created local offer');
                    }, function (error) {
                        logger.warn('[RtcSessionController]:[Pre-allocated PC]: Error creating local offer: ', error);
                        closeNextPC();
                    }, offerConstraints);

                    window.setTimeout(function () {
                        if (createdPc === _nextPc) {
                            // This peer connection has not been used for 30s, discard it
                            closeNextPC();
                        }
                    }, NEXT_PC_TIMEOUT);
                } else {
                    logger.warn('[RtcSessionController]:[Pre-allocated PC]: Local streams could not be added. Closing peer connection.');
                    closeNextPC();
                }
            } else {
                logger.warn('[RtcSessionController]:[Pre-allocated PC]: Peer connection could not be created.');
            }
        }

        function isSimulcastEnabled() {
            return !_isDirectCall && RtcSessionController.addAdditionalLowVideoStream;
        }

        /////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        /////////////////////////////////////////////////////////////////////////////
        var _eventList = [
            'onIceCandidate',
            'onSessionDescription',
            'onSdpConnected',
            'onIceConnected',
            'onIceDisconnected',
            'onRemoteVideoAdded',
            'onRemoteVideoRemoved',
            'onRemoteStreamUpdated',
            'onDTMFToneChange',
            'onClosed',
            'onRtcError',
            'onRtcWarning',
            'onLocalStreamEnded',
            'onScreenSharePointerStatus',
            'onMediaUpdate',
            'onLocalVideoStream',
            'onRemoteStreams',
            'onGetUserMediaException',
            // CallStats events
            'onQosAvailable',
            'onStatsNoOutgoingPackets',
            'onStatsThresholdExceeded',
            'onNetworkQuality'
        ];

        // Initialize event handlers
        _eventList.forEach(function (eventName) {
            _that[eventName] = null;
        });

        function unregisterEventHandlers(exceptionList) {
            exceptionList = exceptionList || [];
            _eventList.forEach(function (eventName) {
                if (!exceptionList.includes(eventName)) {
                    _that[eventName] = null;
                }
            });
        }

        /////////////////////////////////////////////////////////////////////////////
        // Public interfaces
        /////////////////////////////////////////////////////////////////////////////

        Object.defineProperty(this, 'dontReuseAudioStream', {
            get: function () {
                return _dontReuseAudioStream;
            },
            set: function (value) {
                logger.debug('[RtcSessionController]: Setting dontReuseAudioStream to:', !!value);
                _dontReuseAudioStream = !!value;
            },
            enumerable: true,
            configurable: false
        });

        this.setTurnCredentials = function (credentials) {
            _turnCredentials = credentials || {};
        };

        this.setTurnUris = function (uris) {
            if (!uris || !uris.length) {
                return;
            }

            if (RtcSessionController.customTurnServers.length) {
                // Add the custom TURN servers first
                logger.debug('[RtcSessionController]: Adding custom TURN servers to this session:', RtcSessionController.customTurnServers);
                _turnUris = RtcSessionController.customTurnServers.concat(uris);
            } else {
                _turnUris = uris;
            }
            // WORKAROUND!!!
            // Firefox suppors TURN TLS in version 53 and above, but throws exception for URLs with 'turns' scheme
            // and IP address instead of hostname. To avoid this just remove them from list.
            // See https://bugzilla.mozilla.org/show_bug.cgi?id=1056934
            if (_browser.firefox) {
                _turnUris = _turnUris.filter(function (uri) {
                    return !/turns:\d{1,3}(\.\d{1,3}){3}/.test(uri);
                });
            }
        };

        this.getLocalVideoStream = function () {
            // The local desktop (screen share) stream has precedence over the local video stream
            return _localDesktopStream || _localVideoStream;
        };

        this.getLocalDesktopStream = function () {
            return _localDesktopStream;
        };

        this.getRemoteAudioStream = function () { return _remoteAudioStream; };

        this.getRemoteVideoStreams = function () { return _remoteVideoStreams; };

        this.getSdpStatus = function () {
            return _pcSettings.main.sdpStatus;
        };

        // This is the SDP that has been passed as input to the warmup function
        this.getWarmedUpSdp = function () { return _warmedUpSdp; };

        this.isHolding = function () {
            return (_holding && !_holdInProgress) || _retrieveInProgress;
        };

        this.isHoldInProgress = function () {
            return _holdInProgress;
        };

        this.isRetrieveInProgress = function () {
            return _retrieveInProgress;
        };

        this.isHeld = function () { return _held; };

        this.getActiveMediaType = function () { return Utils.shallowCopy(_activeMediaType); };

        this.getMediaConstraints = function () { return Utils.shallowCopy(_mediaConstraints); };

        this.getLocalMediaType = function () {
            // This is supposed to be the intersection of media constraints and the
            // active media type.
            return {
                audio: _mediaConstraints.audio && _activeMediaType.audio,
                video: _mediaConstraints.video && _activeMediaType.video,
                desktop: _mediaConstraints.desktop && _activeMediaType.video
            };
        };

        this.hasScreenShare = function () {
            return !!_localDesktopStream;
        };

        this.warmup = function (mediaType, remoteSdp, successCb, errorCb) {
            successCb = successCb || function () {};
            errorCb = errorCb || function () {};

            if (_sessionTerminated) {
                logger.error('[RtcSessionController]: Warmup Connection failed: Session already terminated');
                sendAsyncCallback(errorCb, 'res_UnexpectedError');
                return;
            }

            if (sdpParser.isNoOfferSdp(remoteSdp)) {
                remoteSdp = null;
            }
            // This function is used to 'warmup' the RTCPeerConnection prior to starting it.
            // This is the only function which uses success and error callbacks. All other
            // functions will asynchronously raise an onError event in case of errors.
            mediaType = normalizeMediaType(mediaType);

            logger.info('[RtcSessionController]: Warming up the connection - mediaType = ', mediaType);

            if (!WebRTCAdapter.enabled) {
                logger.warn('[RtcSessionController]: WebRTC not supported by browser');
                sendAsyncCallback(errorCb, 'res_NoWebRTC');
                return;
            }

            if (_pc && _pcSettings.main.sdpStatus !== SdpStatus.None) {
                logger.error('[RtcSessionController]: The connection has already been started');
                sendAsyncCallback(errorCb, 'res_UnexpectedError');
                return;
            }

            _mediaConstraints = mediaType;
            if (!_mediaConstraints.audio) {
                _isMuted = true;
            }

            getUserMedia(function () {
                // Successfully got access to media input devices
                if (remoteSdp) {
                    _warmedUpSdp = remoteSdp;
                }
                _warmedUp = true;
                createPeerConnection(); // Create the peer connection(s) here so they can pre-allocate ICE candidates (before setLocalDescription)
                successCb();
            }, function (err) {
                handleFailure(err, errorCb);
            });
        };

        this.start = function (mediaType, remoteSdp, sendingEarlyMedia) {
            if (_sessionTerminated) {
                logger.error('[RtcSessionController]: Start Connection failed: Session already terminated');
                return false;
            }
            mediaType = normalizeMediaType(mediaType);
            logger.info('[RtcSessionController]: Start Connection - mediaType =', mediaType);

            if (!WebRTCAdapter.enabled) {
                logger.warn('[RtcSessionController]: WebRTC not supported by browser');
                sendAsyncError('res_NoWebRTC');
                return false;
            }

            if (_pc && _pcSettings.main.sdpStatus !== SdpStatus.None) {
                logger.error('[RtcSessionController]: The connection has already been started');
                sendAsyncError('res_UnexpectedError');
                return false;
            }

            logger.debug('[RtcSessionController]: Creating Peer Connection...');

            if (_warmedUp) {
                // The connection has already been warmed up
                remoteSdp = _warmedUpSdp;
                _warmedUpSdp = null;

                _isMuted = !mediaType.audio;
                if (!createPeerConnection()) {
                    sendAsyncError('res_RTCError');
                    return false;
                }
                if (mediaType.audio === _mediaConstraints.audio &&
                    mediaType.video === _mediaConstraints.video &&
                    mediaType.hdVideo === _mediaConstraints.hdVideo &&
                    mediaType.desktop === _mediaConstraints.desktop) {
                    // We can continue using the MediaStream object allocated during the warmup.
                    startConn(remoteSdp, !!sendingEarlyMedia);
                    return true;
                } else {
                    // Stop stream created during warmup to avoid it being accidentally reused
                    setLocalStream(null, LOCAL_AUDIO_VIDEO);
                }
            }

            if (!createPeerConnection()) {
                sendAsyncError('res_RTCError');
                return false;
            }

            _mediaConstraints = mediaType;

            // Get access to local media
            getUserMedia(function () {
                // Successfully got access to media input devices
                startConn(remoteSdp, !!sendingEarlyMedia);
            }, handleFailure);

            return true;
        };

        this.startConnectedTimer = function () {
            if (_disableConnectedTimer) {
                _disableConnectedTimer = false;
                if (_pc && _pcSettings.main.sdpStatus === SdpStatus.AnswerSent) {
                    waitConnectedState(_pc);
                }
            }
        };

        // The following function returns false in case of collision or improper call state.
        // Otherwise, it returns true.

        this.addIceCandidates = function (origin, candidates) {
            logger.info('[RtcSessionController]: Add remote ICE candidates');

            if (_pc && origin === _pc.remoteOrigin) {
                candidates.forEach(function (candidate) {
                    try {
                        candidate = JSON.parse(candidate);
                        if (candidate.candidate) {
                            candidate.candidate = candidate.candidate.replace(/(a=)?([^\r]+)(\r\n)?/i, '$2');
                        }
                        logger.debug('[RtcSessionController]: Remote ICE candidate', candidate);
                        if (!candidate.candidate || candidate.candidate === 'end-of-candidates') {
                            logger.debug('[RtcSessionController]: Ignoring end-of-remote-candidates indication.');
                        } else {
                            _pc.addIceCandidate(candidate);
                        }
                    } catch (err) {
                        logger.error('Error parsing remote candidate. ', err);
                    }
                });

                waitConnectedState(_pc);
            } else {
                logger.warn('[RtcSessionController]: Ignoring remote ICE candidate - No matching peer connection found.');
            }
        };

        /////////////////////////////////////////////////////////////////////////////
        // The following functions will trigger a new media renegotiation from
        // the client. All these functions are supposed to returns false in case
        // of collision or improper call state. Otherwise, they must returns true
        // and must raise an onRtcError event in case the renegotiation cannot be
        // completed.

        this.setRemoteDescription = function (remoteSdp, cb) {
            logger.info('[RtcSessionController]: Set Remote Description - type: ', remoteSdp.type);

            // Make sure the warmed-up SDP is null
            _warmedUpSdp = null;

            if (_pc && _pcSettings.main.sdpStatus === SdpStatus.AnswerReceived) {
                logger.warn('[RtcSessionController]: Still processing the previous answer remote description. Queue the new one');
                _pendingRemoteSdp = {
                    sdp: remoteSdp,
                    cb: cb
                };
                return;
            }

            if (_pc && _pcSettings.main.sdpStatus === SdpStatus.PrAnswerReceived) {
                // We can't reapply it so just ignore it.
                if (remoteSdp.type === 'answer') {
                    setSdpStatus(SdpStatus.AnswerReceived, _pc);
                    waitConnectedState(_pc);
                }
                logger.info('[RtcSessionController]: SDP already applied. Ignoring any later remote SDPs');
                sendAsyncCallback(cb);
                return;
            }

            if (remoteSdp.type === 'offer') {
                // This is a media renegotiation started by the remote party.
                // Check the connection state.
                if (!isConnStable()) {
                    sendAsyncCallback(cb, 'Connection not stable');
                    return;
                }

                startRenegotiation(false, cb);
                getUserMedia(function () {
                    setRemoteDescription(remoteSdp, false);
                }, handleFailure);
            } else {
                // This is the SDP Answer for the initial call setup or for a media
                // renegotiation started by the client.

                if (remoteSdp.sdp === 'sdp') {
                    // This is a dummy SDP answer from the mock server.
                    // If this is a media renegotiation just mark the renegotiation as successful.
                    setSdpStatus(SdpStatus.AnswerReceived, _pc);
                    _renegotiationInProgress && renegotiationSuccessful();
                    waitConnectedState(_pc);
                } else {
                    setRemoteDescription(remoteSdp, false);
                }
                sendAsyncCallback(cb);
            }
        };

        /**
         * Start a media renegotiation from the client (i.e. send a new SDP Offer from the client).
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return True if the request is accepted; False otherwise.
         */
        this.renegotiateMedia = function (cb) {
            logger.info('[RtcSessionController]: Start Media Renegotiation from client');
            if (!isConnStable()) {
                sendAsyncCallback(cb, 'Connection not stable');
            }
            startRenegotiation(true, cb);
            getUserMedia(renegotiateMedia, handleFailure);
        };

        this.enableRemoteVideo = function () {
            if (_remoteVideoDisabled) {
                logger.info('[RtcSessionController]: Enable receiving remote video');
                _remoteVideoDisabled = false;
            }
        };

        this.disableRemoteVideo = function () {
            if (!_remoteVideoDisabled) {
                logger.info('[RtcSessionController]: Disable receiving remote video');
                _remoteVideoDisabled = true;
            }
        };

        this.enableRemoteVideoScreenOnly = function () {
            if (!_remoteVideoScreenOnlyAllowed) {
                logger.info('[RtcSessionController]: Allow receiving only remote screen share');
                _remoteVideoScreenOnlyAllowed = true;
            }
        };

        this.disableRemoteVideoScreenOnly = function () {
            if (_remoteVideoScreenOnlyAllowed) {
                logger.info('[RtcSessionController]: Disable receiving only remote screen share');
                _remoteVideoScreenOnlyAllowed = false;
            }
        };

        /**
         * Add audio to the existing connection. This function initiates a new media renegotiation.
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return undefined.
         */
        this.addAudio = function (cb) {
            logger.info('[RtcSessionController]: Add Audio');
            changeMedia({audio: true}, cb);
        };

        /**
         * Remove audio from the existing connection. This function initiates a new media renegotiation.
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return undefined.
         */
        this.removeAudio = function (cb) {
            logger.info('[RtcSessionController]: Remove Audio');
            changeMedia({audio: false}, cb);
        };

        /**
         * Add video to the existing connection. This function initiates a new media renegotiation.
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return undefined.
         */
        this.addVideo = function (cb) {
            logger.info('[RtcSessionController]: Add Video');
            changeMedia({video: true}, cb);
        };

        /**
         * Remove video from the existing connection. This function initiates a new media renegotiation.
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return undefined.
         */
        this.removeVideo = function (cb) {
            logger.info('[RtcSessionController]: Remove Video');
            changeMedia({video: false}, cb);
        };

        /**
         * Changes the media type for the existing connection. This function initiates a new media renegotiation.
         *
         * @param {Object} mediaType The media type(s) which are supposed to be added or removed. For instance, use {video: true} to add video and {video: false} to remove it.
         * @param {Object} changeOptions Additional options associated with the change media request.
         * @param {Boolean} changeOptions.changeDesktopMedia Indicates if the desktop media also needs to change or not.
         * @param {Object} changeOptions.screenShareOptions Screenshare parameters.
         * @param {Boolean} changeOptions.isDirectUpgradingToConf Indicates if it's a direct call that is being upgraded to conference.
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         */
        this.changeMediaType = function (mediaType, changeOptions, cb) {
            logger.info('[RtcSessionController]: Change media type: ', mediaType);
            if (changeOptions) {
                logger.info('[RtcSessionController]: Change media options: ', changeOptions);
            }
            if (!mediaType) {
                logger.warn('[RtcSessionController]: Invalid media type');
                sendAsyncCallback(cb, 'Invalid media type');
                return;
            }
            changeMedia(mediaType, cb, changeOptions);
        };

        /**
         * Hold the existing call. This function initiates a new media renegotiation.
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return undefined.
         */
        this.holdCall = function (cb) {
            logger.info('[RtcSessionController]: Hold Call');
            if (!isConnStable()) {
                sendAsyncCallback(cb, 'Connection not stable');
                return;
            }

            if (_holding) {
                logger.warn('[RtcSessionController]: The call is already held');
                sendAsyncCallback(cb);
                return;
            }

            // Set _holding to true so we update the SDPs correctly
            _holding = true;
            // Set _holdInProgress to true so we can change _holding back to false in case the renegotiation fails.
            _holdInProgress = true;

            startRenegotiation(true, cb);
            getUserMedia(renegotiateMedia, handleFailure);
        };

        /**
         * Retrieves the existing call. This function initiates a new media renegotiation.
         *
         * @param {Function} [cb] Callback function that is invoked when media renegotiation has finished.
         * @param {Boolean} cb.success Indicates whether or not the media renegotiation was successful.
         * @return undefined.
         */
        this.retrieveCall = function (cb) {
            logger.info('[RtcSessionController]: Retrieve Call');

            if (!isConnStable()) {
                sendAsyncCallback(cb, 'Connection not stable');
                return;
            }
            if (!_holding) {
                logger.warn('[RtcSessionController]: The call is not held');
                sendAsyncCallback(cb);
                return;
            }

            // Set _holding to false so we update the SDPs correctly
            _holding = false;
            // Set _retrieveInProgress to true so we can change _holding back to true in case the renegotiation fails.
            _retrieveInProgress = true;

            startRenegotiation(true, cb);
            getUserMedia(renegotiateMedia, handleFailure);
        };

        //
        // End of media renegotiation functions
        /////////////////////////////////////////////////////////////////////////////

        this.renegotiationFailed = function (error) {
            if (_renegotiationInProgress) {
                if (!_renegotiationCb) {
                    sendError('res_RTCError');
                }
                renegotiationFailed(error);
            }
        };

        this.mute = function (cb) {
            logger.info('[RtcSessionController]: Mute Call');
            enableAudioTrack(false);
            sendAsyncCallback(cb);
        };

        this.unmute = function (cb) {
            logger.info('[RtcSessionController]: Unmute Call');
            var error;
            if (!enableAudioTrack(true)) {
                // There's no audio track, return error
                error = 'res_UnmuteFailedNoMic';
            }
            sendAsyncCallback(cb, error);
        };

        this.isMuted = function () {
            return _isMuted;
        };

        this.isLocalMuteAllowed = function () {
            return _isMuted || (_localStreams[LOCAL_AUDIO_VIDEO] && _localStreams[LOCAL_AUDIO_VIDEO].getAudioTracks().length);
        };

        /***
         * Use this method to set options for call stats collection (e.g.: if it's a voicemail call, there's no incoming packets)
         */
        this.setCallStatsOptions = function (statsOptions) {
            if (_callStats && statsOptions) {
                logger.debug('[RtcSessionController]: Setting call stats options: ', statsOptions);
                _callStats.setOptions(statsOptions);
            }
        };

        this.terminate = function () {
            logger.info('[RtcSessionController]: Terminate the RTC session controller');
            close();
        };

        this.canSendDTMFDigits = function () {
            if (!_dtmfSender && !enableDTMFSender()) {
                return false;
            }
            return _dtmfSender && _dtmfSender.canInsertDTMF;
        };

        // when a key is pressed
        this.sendDTMFDigits = function (digits) {
            logger.info('[RtcSessionController]: Send DTMF Digits - num of digits: ', digits && digits.length);

            if (!isConnStable()) {
                return false;
            }

            if (!_dtmfSender && !enableDTMFSender()) {
                return false;
            }

            if (!_dtmfSender.canInsertDTMF) {
                logger.error('[RtcSessionController]: DTMF Sender cannot insert DTMF digits');
                return false;
            }

            try {
                _dtmfSender.insertDTMF(digits, 200, 50);
            } catch (e) {
                logger.error('[RtcSessionController]: Send DTMF Digits exception:', e.message);
                return false;
            }
            return true;
        };

        this.setMockedCall = function () {
            _isMockedCall = true;
        };

        this.getCurrentPeerConnection = function () {
            return _pc && _pc.mainPeerConnection;
        };

        this.getNumberOfExtraVideoChannels = function () {
            return _numberOfExtraVideoChannels;
        };

        this.isConnStable = function () {
            return isConnStable();
        };

        this.isConnected = function () {
            return _pcSettings.main.sdpStatus === SdpStatus.Connected || _renegotiationInProgress;
        };

        this.disableIncomingVideo = function () {
            _remoteStreams.forEach(function (s) {
                if (s.getVideoTracks().length > 0) {
                    s.getVideoTracks()[0].enabled = false;
                }
            });
        };

        this.enableIncomingVideo = function () {
            _remoteStreams.forEach(function (s) {
                if (s.getVideoTracks().length > 0) {
                    s.getVideoTracks()[0].enabled = true;
                }
            });
        };

        this.getLocalStream = function (id) {
            return _localStreams[id];
        };

        this.setLocalStream = function (id, stream) {
            _localStreams[id] = stream;
        };

        this.replaceLocalStream = function (id, stream) {
            if (_localStreams[id] && _localStreams[id] !== stream) {
                stopStream(_localStreams[id]);
            }
            _localStreams[id] = stream;
        };

        this.getRemoteStreams = function () {
            return _remoteStreams;
        };

        this.setIgnoreTurnOnNextPC = function () {
            logger.debug('[RtcSessionController]: setIgnoreTurnOnNextPC...');
            _ignoreTurnOnNextPC = true;
        };

        this.setIgnoreNextConnection = function () {
            logger.debug('[RtcSessionController]: setIgnoreNextConnection...');
            _ignoreNextConnection = true;
        };

        this.getLastSavedStats = function () {
            var stats = _oldCallStats || _callStats;
            return stats ? stats.getLastSavedStats() : null;
        };

        this.prepareForRenegotiation = function () {
            if (_isTelephonyCall) {
                createNextPeerConnection();
            }
        };

        this.changeInputDevices = function (newInputDevices) {
            logger.info('[RtcSessionController]: Change input devices to ', newInputDevices);
            if (!newInputDevices || (!newInputDevices.audio && !newInputDevices.video)) {
                // Nothing to do
                return Promise.reject('No new input devices specified');
            }
            return new Promise(function (resolve, reject) {
                var rejectHandler = function (err) {
                    logger.error('[RtcSessionController]: Could not change input devices: ', err);
                    reject(err);
                };
                var resolveHandler = function () {
                    logger.info('[RtcSessionController]: Successfully changed input devices');
                    resolve();
                };
                // Unified Plan allows us to replace tracks without the need of media renegotiations
                // We don't support changing video devices yet
                if (!newInputDevices.video) {
                    if (_browser.firefox) {
                        // Firefox won't let us get the stream of a new mic unless we stop
                        // the old mic's stream. This means if the new getUserMedia fails,
                        // we can't go back to the old stream.
                        _that.replaceLocalStream(LOCAL_AUDIO_VIDEO, null);
                    }
                    logger.debug('[RtcSessionController]: Attempting to change input devices: ', newInputDevices);
                    var oldStream = _localStreams[LOCAL_AUDIO_VIDEO];
                    getUserMediaAudioVideo(function () {
                        var stream = _localStreams[LOCAL_AUDIO_VIDEO];
                        _pc.replaceTrack(oldStream.getAudioTracks()[0], stream.getAudioTracks()[0])
                        .then(resolveHandler)
                        .catch(rejectHandler);
                    }, rejectHandler, newInputDevices);
                } else {
                    // Trigger a media renegotiation
                    _that.changeMediaType(_mediaConstraints, function (err) {
                        err ? rejectHandler(err) : resolveHandler();
                    });
                }
            });
        };

        this.adaptScreenShareForRemoteControl = function (remoteControlEnabled) {
            if (!_mediaConstraints.desktop || !_screenShareParams) {
                // There is no screenshare active
                return;
            }

            var oldScreenTrack;

            function onError(err) {
                logger.warn('[RtcSessionController]: Could not fetch new desktop stream with remoteControlEnabled=' + remoteControlEnabled + '. ', err);
                if (remoteControlEnabled) {
                    // Go back to the old screen share mode
                    _that.adaptScreenShareForRemoteControl(false);
                }
            }

            function onSuccess() {
                var newScreenStream = _that.getLocalStream(LOCAL_SCREEN_SHARE);
                if (newScreenStream && newScreenStream.getVideoTracks().length > 0) {
                    _pc.replaceTrack(oldScreenTrack, newScreenStream.getVideoTracks()[0]);
                } else {
                    onError('Missing new stream');
                }
            }

            remoteControlEnabled = !!remoteControlEnabled;
            if (_mediaConstraints.remoteControlEnabled !== remoteControlEnabled) {
                var oldScreenStream = _localStreams[LOCAL_SCREEN_SHARE];
                if (oldScreenStream && oldScreenStream.getVideoTracks().length > 0) {
                    logger.debug('[RtcSessionController]: Calling adaptScreenShareForRemoteControl with remoteControlEnabled = ', remoteControlEnabled);
                    oldScreenTrack = oldScreenStream.getVideoTracks()[0];
                    _mediaConstraints.remoteControlEnabled = remoteControlEnabled;
                    stopStream(oldScreenStream);
                    getUserMediaDesktop(onSuccess, onError, _screenShareParams);
                } else {
                    logger.warn('[RtcSessionController]: Cannot adapt screen share for remote control as there is no screen share stream');
                }
            }
        };

        this.getAvailableVideoReceivers = function (mediaType) {
            if (_renegotiationInProgress) {
                // do not update available video receivers during negotiation, since _pc might be null
                return null;
            }
            return (_pc && _pc.getAvailableVideoReceivers(mediaType)) || [];
        };

        this.canAbortRenegotiation = function () {
            return _renegotiationInProgress && _renegotiationStartedLocally &&
                (_pcSettings.main.sdpStatus === SdpStatus.None || _pcSettings.main.sdpStatus === SdpStatus.OfferPending);
        };

        this.abortPendingNegotiation = function (cb) {
            if (_that.canAbortRenegotiation() && !_pendingAbort) {
                _pendingAbort = typeof cb === 'function' ? cb : true;
                return true;
            }
            return false;
        };

        logger.debug('[RtcSessionController]: Created new RtcSessionController instance. options = ', options);
    }

    // RTC session controller constants
    RtcSessionController.DEFAULT_CANDIDATES_TIMEOUT = DEFAULT_CANDIDATES_TIMEOUT;
    RtcSessionController.DEFAULT_TRICKLE_ICE_TIMEOUT = DEFAULT_TRICKLE_ICE_TIMEOUT;
    RtcSessionController.DEFAULT_ENABLE_AUDIO_AGC = DEFAULT_ENABLE_AUDIO_AGC;
    RtcSessionController.DEFAULT_ENABLE_AUDIO_EC = DEFAULT_ENABLE_AUDIO_EC;
    RtcSessionController.DEFAULT_UPSCALE_FACTOR = DEFAULT_UPSCALE_FACTOR;
    RtcSessionController.LOCAL_STREAMS = LOCAL_STREAMS;
    RtcSessionController.DEGRADATION_MODE = DEGRADATION_MODE;

    // RTC session controller settings which may be controlled by the application
    RtcSessionController.candidatesTimeout = DEFAULT_CANDIDATES_TIMEOUT; // Timeout waiting for candidates when Trickle ICE is not enabled
    RtcSessionController.trickleIceTimeout = DEFAULT_TRICKLE_ICE_TIMEOUT; // Timeout waiting for candidates when Trickle ICE is enabled
    RtcSessionController.enableAudioAGC = DEFAULT_ENABLE_AUDIO_AGC;
    RtcSessionController.enableAudioEC = DEFAULT_ENABLE_AUDIO_EC;
    RtcSessionController.screenshareUpscaleFactor = DEFAULT_UPSCALE_FACTOR;
    RtcSessionController.allowAllIceCandidatesScreenShare = true;
    RtcSessionController.disableTrickleIce = false;
    RtcSessionController.allowOnlyRelayCandidates = false;
    RtcSessionController.mediaNode = null;
    RtcSessionController.playbackDevices = [];
    RtcSessionController.recordingDevices = [];
    RtcSessionController.ringingDevices = [];
    RtcSessionController.videoDevices = [];
    RtcSessionController.sdpParameters = JSON.parse(JSON.stringify(DEFAULT_SDP_PARAMS)); // Deep copy
    RtcSessionController.xGoogle = JSON.parse(JSON.stringify(DEFAULT_XGOOGLE)); // Deep copy
    RtcSessionController.customTurnServers = [];
    RtcSessionController.maxVideoExtraChannels = null; // Defaults to DEFAULT_VIDEO_EXTRA_CHANNELS
    RtcSessionController.degradationPreference = DEGRADATION_MODE.MAINTAIN_RESOLUTION;
    RtcSessionController.addAdditionalLowVideoStream = !Utils.isMobile();

    // Allow application to disable separate media line for screenshare
    RtcSessionController.disableDesktopPc = false;

    // Used for logging purposes to identify how the browser/DA is configured.
    // This value needs to be retrieved from the application.
    RtcSessionController.webRTCIPHandlingPolicy = null;

    // Process the client settings returned by the access server
    RtcSessionController.processClientSettings = function (settings) {
        try {
            settings = settings || {};

            RtcSessionController.allowAllIceCandidatesScreenShare = settings.allowAllIceCandidatesScreenShare !== undefined ?
                !!settings.allowAllIceCandidatesScreenShare : true;

            RtcSessionController.disableTrickleIce = settings.disableTrickleIce !== undefined ? !!settings.disableTrickleIce : false;

            RtcSessionController.screenshareUpscaleFactor = parseFloat(settings.screenshareUpscaleFactor, 10) ||
                RtcSessionController.DEFAULT_UPSCALE_FACTOR;

            var xGoogle = settings.xGoogle || {};
            Object.keys(DEFAULT_XGOOGLE).forEach(function (mediaType) {
                RtcSessionController.xGoogle[mediaType] = xGoogle[mediaType] !== undefined ?
                    xGoogle[mediaType] : JSON.parse(JSON.stringify(DEFAULT_XGOOGLE[mediaType]));
            });

            var sdpParameters = settings.sdpParameters || {};
            Object.keys(DEFAULT_SDP_PARAMS).forEach(function (key) {
                RtcSessionController.sdpParameters[key] = sdpParameters[key] !== undefined ?
                    sdpParameters[key] : JSON.parse(JSON.stringify(DEFAULT_SDP_PARAMS[key]));
            });

            RtcSessionController.degradationPreference = settings.degradationPreference !== undefined ?
                settings.degradationPreference : DEGRADATION_MODE.MAINTAIN_RESOLUTION;

            RtcSessionController.addAdditionalLowVideoStream = settings.addAdditionalLowVideoStream !== undefined ?
                settings.addAdditionalLowVideoStream : !Utils.isMobile();

            circuit.WebRTCAdapter.useReducedAudioConstraints = settings.useReducedAudioConstraints !== undefined ?
                !!settings.useReducedAudioConstraints : true;

            logger.info('[RtcSessionController]: Updated RtcSessionController values: ', {
                allowAllIceCandidatesScreenShare: RtcSessionController.allowAllIceCandidatesScreenShare,
                disableTrickleIce: RtcSessionController.disableTrickleIce,
                screenshareUpscaleFactor: RtcSessionController.screenshareUpscaleFactor,
                xGoogle: RtcSessionController.xGoogle,
                sdpParameters: RtcSessionController.sdpParameters,
                useReducedAudioConstraints: circuit.WebRTCAdapter.useReducedAudioConstraints
            });
        } catch (e) {
            logger.error('[RtcSessionController]: Error processing client settings. ', e);
        }
    };

    // Exports
    circuit.RtcSessionController = RtcSessionController;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.SdpStatus = SdpStatus;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
