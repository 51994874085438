
var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var Utils = circuit.Utils;

    // Define the object
    var Conversation = {};

    ///////////////////////////////////////////////////////////////////////////
    // Helper functions for Conversation
    ///////////////////////////////////////////////////////////////////////////
    var DEFAULT_GROUP_CONV_AVATAR = 'content/images/icon-general-default-group-avatar';
    var COLORS = ['blue', 'green', 'orange', 'yellow'];

    function getColor(conv) {
        if (!conv || !conv.convId || conv.isDraft) {
            return 'grey';
        }

        var sum = 0;
        var convId = conv.convId;
        for (var idx = 0; idx < convId.length; idx++) {
            sum += convId.charCodeAt(idx);
        }
        return COLORS[sum % COLORS.length];
    }

    function isInitialized() {
        return !!Conversation.localUser && typeof Conversation.getExtendedUser === 'function' &&
            typeof Conversation.getUserFromCache === 'function' && typeof Conversation.addUsersToCache === 'function';
    }

    function setConvMethods(conv) {
        conv.contains = function (item, itemId) {
            var id = item ? item.itemId : itemId;
            return this.items.some(function (itm) {
                return id === itm.itemId;
            });
        };

        conv.userIsModerator = function (user) {
            if (!user || !this.isModerated) {
                return false;
            }
            var userId = typeof user === 'string' ? user : user.userId;
            return this.moderators.some(function (elem) {
                return elem.userId === userId;
            });
        };

        conv.hasRequiredModerationAccess = function (user) {
            return !this.isModerated || this.userIsModerator(user);
        };

        conv.sortThreads = function () {
            this.threads && this.threads.sort(function (a, b) {
                return a.creationTime - b.creationTime;
            });
        };

        conv.canLeave = function () {
            // User can leave IF:
            // - User is a participant
            // - This is not the support conversation
            // - There is no active call or the user is not in the call
            // - This is an Open conversation OR user is not the last non-external participant
            return this.hasJoined && !this.isSupportConv && (!this.call || this.call.isGroupCallStarted) &&
                (this.type === Constants.ConversationType.OPEN || this.isLocalUserExternal || Conversation.hasNonGuestParticipants(this));
        };

        conv.canRemoveParticipant = function (participants, participant) {
            // This function assumes that the basic validation has already been done
            // (e.g. User is not localUser or guest, this is not a Support conversation, etc...).

            // Guests cannot remove the last regular user
            var conversationHasOtherUsers = participants.some(function (p) {
                return p.type === Constants.ConversationParticipantType.REGULAR && p.userId !== participant.userId;
            });
            var canRemoveLastUser = !this.isLocalUserExternal ||
                participant.type !== Constants.ConversationParticipantType.REGULAR || conversationHasOtherUsers;

            // We cannot remove a participant while there is an ongoing call
            var hasActiveCall = !!(this.call && this.call.isPresent());

            return canRemoveLastUser && !hasActiveCall && this.hasRequiredModerationAccess(Conversation.localUser);
        };
    }

    function setPeerUsers(conv) {
        // Set peerUsers for all conversation types
        conv.peerUsers = [];
        conv.hasJoined = false;
        conv.participants.forEach(function (p) {
            if (Conversation.localUser.userId === p.userId) {
                conv.hasJoined = true;  // there may be open-not-joined conversations
                return;
            }
            // Get the extended user object
            var pExt;
            if (!p.userId && p.notFound) {
                // Save invalid participant without changes for case when conversation is a draft
                pExt = p;
            } else {
                pExt = Conversation.getExtendedUser(p.userId);
            }
            conv.peerUsers.push(pExt);
        });

        // Set peerUser for DIRECT conversations
        if (conv.isDirect) {
            conv.peerUser = conv.peerUsers[0];
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Public interfaces
    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Extend object via prototypical inheritance
    Conversation.extend = function (obj) {
        if (!isInitialized()) {
            if (Circuit.isUnitTestRun) {
                return null;
            } else {
                throw new Error('The Conversation object is not ready for use');
            }
        }
        if (!obj) {
            return null;
        }
        if (obj.isExtended) {
            // The given object is already an extended conversation
            return obj;
        }
        // Participants object should contain an array
        obj.participants = obj.participants || [];
        // Make sure userData is a valid object, but do not initialize its fields
        obj.userData = obj.userData || {};

        // Create a derived object to be extended and returned (if not already done so)
        var conv = Object.create(obj);
        conv.isExtended = true;
        conv.isConversationObject = true;

        conv.isOpen = conv.type === Constants.ConversationType.OPEN;
        conv.isGroup = conv.type === Constants.ConversationType.GROUP;
        conv.isDirect = conv.type === Constants.ConversationType.DIRECT;
        conv.isExpert = !!conv.systemTags && conv.systemTags.includes(Constants.SystemTag.IC);

        conv.isDeleted = !!conv.isDeleted;
        conv.lastModification = Math.max(conv.modificationTime || 0, conv.lastItemModificationTime || 0);

        conv.sendingMessages = [];
        conv.muted = !!conv.muted;   // non-joined conversations are not added to the cache, so we need to make sure muted is initialized
        conv.containsExternals = !!conv.containsExternals;

        conv.isModerated = !!conv.isModerated;
        conv.moderators = conv.moderators || [];

        conv.isGuestAccessDisabled = !!conv.isGuestAccessDisabled;

        conv.isLocalUserExternal = !conv.isDirect && conv.creatorTenantId !== Conversation.localUser.tenantId;

        // Sanitize the conversation topic and expose as new topicEscaped attribute
        if (conv.topic && navigator.platform !== 'iOS') {
            conv.topicEscaped = Utils.textToHtmlEscaped(conv.topic);
        }

        conv.draftType = obj.draftType;

        // Initialize internal arrays
        conv.items = [];
        conv.call = null;
        conv.parents = {};  // Hashtable. key:parentId, value: parentItem
        conv.threads = [];  // Sorted array of parents (threads incl. system items)
        conv.clusteringData = { // Contains items queued for clustering
            lastSentTimeStamp: null,
            clusteringId: null,
            parentId: null,
            actualItemId: null
        };
        conv.hasOldestThread = false; // false until we have received all threads from server

        // Set the conversation creator
        conv.creator = Conversation.getExtendedUser(conv.creatorId);

        setPeerUsers(conv);

        // Number of active participants
        conv.participantCount = obj.participants.length;

        // Build left participant list and set conversation.leaveTime if I left this conversation
        conv.previousMembers = [];
        obj.formerParticipants && obj.formerParticipants.forEach(function (p) {
            if (Conversation.localUser.userId === p.participant.userId) {
                conv.leaveTime = p.leaveTime;
                return;
            }
            // Get the extended user object
            var pExt = Conversation.getExtendedUser(p.participant.userId);
            conv.previousMembers.push({
                user: pExt,
                leaveTime: p.leaveTime
            });
        });

        Conversation.setConversationAvatar(conv);

        // Extend the topLevelItem (if there is one)
        if (obj.topLevelItem && !conv.isTelephony) {
            conv.topLevelItem = obj.topLevelItem;
        }

        // Methods
        setConvMethods(conv);

        return conv;
    };

    Conversation.getDefaultConversationAvatar = function (conv) {
        if (conv.isDirect) {
            // This is a direct conversation. We use the peer user's avatar.
            return null;
        }

        var color = '-' + getColor(conv);
        if (color === '-grey') {
            color = '';
        }

        return {
            avatar: DEFAULT_GROUP_CONV_AVATAR + color + '.png',
            avatarLarge: DEFAULT_GROUP_CONV_AVATAR + color + '-XL.png',
            hasAvatarPicture: false
        };
    };

    Conversation.setConversationAvatar = function (conv) {
        if (!conv) {
            return;
        }
        if (conv.isDirect) {
            conv.avatar = null;
            return;
        }

        conv.defaultAvatarColor = getColor(conv);

        if (conv.conversationAvatar && conv.conversationAvatar.smallPictureId && conv.conversationAvatar.largePictureId) {
            var url = circuit.__domain + '/avatar?convId=' + conv.convId + '&';
            conv.avatar = {
                avatar: url + 'type=small&fileid=' + conv.conversationAvatar.smallPictureId,
                avatarLarge: url + 'type=large&fileid=' + conv.conversationAvatar.largePictureId,
                hasAvatarPicture: true
            };
        } else {
            conv.avatar = Conversation.getDefaultConversationAvatar(conv);
        }

        if (conv.isOpen) {
            conv.avatar.badgeType = Constants.AvatarBadgeType.COMMUNITY;
        } else if (conv.isExpert) {
            conv.avatar.badgeType = Constants.AvatarBadgeType.EXPERT;
        }
    };

    Conversation.hasIncompleteUsers = function (conv) {
        if (!conv || !conv.peerUsers) {
            return false;
        }
        return conv.peerUsers.some(function (user) {
            return user.noData;
        });
    };

    Conversation.hasNonGuestParticipants = function (conv) {
        if (!conv || !conv.peerUsers) {
            return false;
        }
        return conv.peerUsers.some(function (p) {
            return p.userType === Constants.UserType.REGULAR;
        });
    };

    Conversation.canDelayRecordingDeletion = function (conv) {
        var user = Conversation.localUser;
        return !!(user && conv && conv.hasRequiredModerationAccess(user) && conv.hasJoined && !conv.isLocalUserExternal && user.recordingDeletionEnabledTenant);
    };

    Conversation.canAddParticipants = function (conv) {
        // User cannot add participants if any of the following conditions is true.
        // - Read-only mode is enabled for user's tenant
        // - User has left conversation or has been removed.
        // - User is not a participant in a community.
        // - This is a support conversation.
        // - This is a telephony conversation.
        // - The conversation is moderated and the user is not a moderator.
        // - This is a direct conversation and one of the users is leaving a Voicemail message
        if (Conversation.localUser.readOnlyModeEnabled ||
            (conv.leaveTime || !conv.hasJoined || conv.isSupportConv || conv.isTelephony) ||
            (conv.isModerated && !conv.userIsModerator(Conversation.localUser)) ||
            (conv.call && conv.call.voicemailConnected)) {
            return false;
        }
        return true;
    };

    // Must be set before initializing Conversation objects
    Conversation.getExtendedUser = null;
    Conversation.getUserFromCache = null;
    Conversation.addUsersToCache = null;
    Conversation.localUser = null;

    // Exports
    circuit.Conversation = Conversation;
    circuit.Enums = circuit.Enums || {};

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
