///////////////////////////////////////////////////////////////////////////////
//--- WebSocket Connection Controller ---
///////////////////////////////////////////////////////////////////////////////

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;
    var Utils = circuit.Utils;

    // Connection states used by ConnectionHandler
    var ConnectionState = Object.freeze({
        Disconnected: 'Disconnected',
        Connecting: 'Connecting',
        Reconnecting: 'Reconnecting',
        Connected: 'Connected',
        Ready: 'Ready' // Used by SDK only
    });

    // eslint-disable-next-line max-lines-per-function
    function ConnectionController(config) { // NOSONAR
        var logger = circuit.logger;

        ///////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////
        var RETRY_DELAY_NORMAL, RETRY_DELAY_PRIORITY, RETRY_INTERVAL, MAX_SHORT_ATTEMPTS, MAX_INTERVAL, MAX_ATTEMPTS;

        if (Utils.isMobile()) {
            RETRY_DELAY_PRIORITY = 0;
            RETRY_DELAY_NORMAL = 0;
            RETRY_INTERVAL = 0;
            MAX_INTERVAL = 0;
            MAX_ATTEMPTS = 0;
            MAX_SHORT_ATTEMPTS = 0;
        } else {
            RETRY_DELAY_PRIORITY = 0;    // Priority reconnection occurs between RETRY_DELAY_PRIORITY and RETRY_DELAY_NORMAL
            RETRY_DELAY_NORMAL = 7000;   // Normal reconnection occurs between RETRY_DELAY_NORMAL and RETRY_DELAY_NORMAL + RETRY_INTERVAL
            RETRY_INTERVAL = 8000;       // Start with 8 seconds then double after MAX_SHORT_ATTEMPTS until it reaches MAX_INTERVAL
            MAX_INTERVAL = 64000;        // Max retry interval (64 seconds)
            MAX_ATTEMPTS = 1350;         // Approximately ~1 day, then it gives up. -1 means forever.
            MAX_SHORT_ATTEMPTS = 1;      // Limit of short interval attempts. After this, we start doubling the intervals
        }

        var OFFLINE_DELAY = 4000; // Small Delay to handle Network Glitch

        // Set throttling variables to allow 40 messages each 4 seconds.
        // This should be enough to handle the initial burst after reconnection
        // and allow mobile clients to answer a push notifications call.
        var THROTTLE_MAX_MESSAGES = 40; // 40 messages each 4 seconds
        var THROTTLE_INTERVAL = 4000;
        var MIN_THROTTLE_DELAY = 200;
        var MAX_QUEUED_MESSAGES = 200;
        var MAX_DEQUEUED_MESSAGES = 5;  // Dequeue up to 5 messages at a time

        ///////////////////////////////////////////////////////////////////////////
        // Local variables
        ///////////////////////////////////////////////////////////////////////////
        var _that = this;
        var _socket = null;
        var _errorCb = null;
        var _successCb = null;
        var _state = ConnectionState.Disconnected;
        var _retryInterval = 0;
        var _retryAttempts = 0;
        var _retryTimeout = null;
        var _reconnectTimeout = null;
        var _offlineTimeout = null;
        var _config = config || {};

        // The default WS target.
        var _defaultTarget = null;

        // Reverse proxy target. Used in scenarios where the WS should be opened via a reverse proxy target.
        // For instance, used by SAG clients in China.
        var _proxyTarget = null;

        // The current target that we are trying to connect to. If a reverse proxy is configured we first
        // try to connect to the reverse proxy and if that fails we fallback to the default target.
        var _currTarget = null;

        var _targetObfuscated = null;

        var _sentTimestamps = [];
        var _queuedMessages = [];
        var _throttleTimeout = null;

        var _hasActiveCall = null; // Injected function to verify if client has an active call

        /////////////////////////////////////////////////////////////////////////////
        // Event Senders
        /////////////////////////////////////////////////////////////////////////////
        function raiseEvent(eventHandler, event) {
            if (typeof eventHandler === 'function') {
                try {
                    eventHandler(event);
                } catch (e) {
                    logger.error(e);
                }
            }
        }

        function raiseStateChange(newState, oldState) {
            raiseEvent(_that.onStateChange, {
                newState: newState,
                oldState: oldState
            });
        }

        function raiseReconnectFailed() {
            raiseEvent(_that.onReconnectFailed);
        }

        function raiseMessage(msg) {
            raiseEvent(_that.onMessage, msg);
        }

        ///////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////
        function setTarget(url, proxyUrl) {
            _defaultTarget = url;
            _proxyTarget = proxyUrl;
        }

        function getObfuscatedUrl(url) {
            return url ? url.split('?')[0] : 'N/A';
        }

        function handleOnlineEvent() {
            logger.debug('[CONN]: online event received');

            if (_offlineTimeout) {
                logger.debug('[CONN]: Clear offline timeout');
                window.clearTimeout(_offlineTimeout);
                _offlineTimeout = null;
            }

            if (_state === ConnectionState.Reconnecting) {
                // Reset retry interval and try to reconnect
                _retryInterval = RETRY_INTERVAL;
                _that.reconnect(null, null, 1000);
            }
        }

        function handleOfflineEvent() {
            logger.debug('[CONN]: offline event received');

            if (_state !== ConnectionState.Connected) {
                return;
            }

            // Make sure we are handling the online event
            window.addEventListener('online', handleOnlineEvent);

            if (!_offlineTimeout) {
                _offlineTimeout = window.setTimeout(function () {
                    _offlineTimeout = null;
                    if (!window.navigator.onLine) {
                        logger.debug('[CONN]: The client is offline. Change state to Reconnecting.');
                        _that.reconnect();
                    }
                }, OFFLINE_DELAY);
            }
        }

        function clearSocket() {
            if (_socket) {
                // Unregister events
                _socket.onopen = null;
                _socket.onclose = null;
                _socket.onmessage = null;
                _socket.onerror = null;

                _socket = null;
            }
        }

        function setState(newState) {
            if (newState !== _state) {
                logger.debug('[CONN]: Changed connection state from ' + _state + ' to ' + newState);
                logger.debug('[CONN]: Raising connectionStateChange event');

                var oldState = _state;
                _state = newState;

                // Dispatch the event asynchronously
                window.setTimeout(function () {
                    raiseStateChange(newState, oldState);
                }, 0);
            }
        }

        function onError(error) {
            try {
                _errorCb && _errorCb(error);
            } catch (e) {
                logger.error('[CONN]: Exception on errorCallback. ', e);
            } finally {
                _errorCb = null;
                _successCb = null;
            }
        }

        function onSuccess() {
            try {
                logger.debug('[CONN]: Registered Successfully');
                _successCb && _successCb();
            } catch (e) {
                logger.error('[CONN]: Exception on successCallback. ', e);
            } finally {
                _errorCb = null;
                _successCb = null;
            }
        }

        function clearThrottlingData() {
            if (_throttleTimeout) {
                window.clearTimeout(_throttleTimeout);
                _throttleTimeout = null;
            }
            _queuedMessages = [];
            _sentTimestamps = [];
        }

        function disconnect(suppressStateChange) {
            if (_retryTimeout) {
                window.clearTimeout(_retryTimeout);
                _retryTimeout = null;
            }
            if (_reconnectTimeout) {
                window.clearTimeout(_reconnectTimeout);
                _reconnectTimeout = null;
            }
            clearThrottlingData();

            if (_socket) {
                logger.debug('[CONN]: Disconnecting from server');
                var activeSocket = _socket;
                clearSocket();
                activeSocket.close();
            }

            if (!suppressStateChange) {
                if (window.removeEventListener) {
                    window.removeEventListener('online', handleOnlineEvent);
                    window.removeEventListener('offline', handleOfflineEvent);
                }
                setState(ConnectionState.Disconnected);
            }
        }

        function retryConnection() {
            if (Utils.isMobile()) {
                // For mobile clients we don't try to reconnect from javascript
                logger.warn('[CONN]: Mobile Client - Do not attempt to reconnect from JS');
                return;
            }

            if (MAX_ATTEMPTS > 0 && _retryAttempts >= MAX_ATTEMPTS) {
                logger.warn('[CONN]: Reached max number of attempts reconnecting to WebSocket');
                disconnect();
                return;
            }

            if (window.navigator.onLine === false) {
                // Only for browser clients that support window.navigator.onLine,
                // non-browser clients (e.g. mobile, node) will have window.navigator.onLine as 'undefind'
                logger.warn('[CONN]: Client is offline. Set retry timer to max value.');
                _retryInterval = MAX_INTERVAL;
            } else if (!_retryInterval) {
                // This is the first reconnection attempt.
                // Randonmly distribute the first reconnection so the clients won't swamp the Access Server all at once.
                if (_hasActiveCall && _hasActiveCall()) {
                    logger.info('[CONN]: Client has an active call. Use priority reconnection timeout');
                    _retryInterval = Utils.randomNumber(RETRY_DELAY_PRIORITY, RETRY_DELAY_NORMAL);
                } else {
                    _retryInterval = RETRY_DELAY_NORMAL + Utils.randomNumber(0, RETRY_INTERVAL);
                }
            }

            logger.info('[CONN]: Retry to open socket in ' + (_retryInterval / 1000) + ' seconds');
            _retryTimeout = window.setTimeout(function () {
                _retryTimeout = null;
                _currTarget = _proxyTarget || _defaultTarget;
                openSocket();
            }, _retryInterval);

            if (_retryInterval < MAX_INTERVAL) {
                // After the first attempt (random), set the interval to RETRY_INTERVAL
                // for MAX_SHORT_ATTEMPTS, then start doubling it up to MAX_INTERVAL
                _retryAttempts++;
                _retryInterval = _retryAttempts > MAX_SHORT_ATTEMPTS ? _retryInterval * 2 : RETRY_INTERVAL;
                _retryInterval = Math.min(_retryInterval, MAX_INTERVAL);
            }
        }

        function fallbackToDefaultTarget() {
            if (_currTarget !== _proxyTarget) {
                return false;
            }
            disconnect(true);
            window.setTimeout(function () {
                logger.info('[CONN]: Fallback to default target URL');
                _currTarget = _defaultTarget;
                openSocket();
            }, 0);
            return true;
        }

        // Common handler for connection close and error events
        function handleConnectionClosed() {
            clearSocket();
            clearThrottlingData();

            switch (_state) {
            case ConnectionState.Connected:
                setState(ConnectionState.Reconnecting);
                retryConnection();
                break;

            case ConnectionState.Reconnecting:
                if (!fallbackToDefaultTarget()) {
                    raiseReconnectFailed();
                    retryConnection();
                }
                break;

            case ConnectionState.Connecting:
                if (!fallbackToDefaultTarget()) {
                    disconnect();
                    logger.error('[CONN]: Failed to open WebSocket connection');
                    onError('Failed to open WebSocket connection');
                }
                break;

            default:
                // Socket is already Disconnected
                break;
            }
        }

        function onSocketOpen(socket) {
            if (socket !== _socket) {
                return;
            }
            logger.info('[CONN]: WebSocket is opened');
            // Retry interval is set before first reconnection attempt
            _retryInterval = 0;
            _retryAttempts = 0;

            if (_state !== ConnectionState.Disconnected) {
                setState(ConnectionState.Connected);
                if (window.addEventListener) {
                    window.addEventListener('offline', handleOfflineEvent);
                    window.removeEventListener('online', handleOnlineEvent);
                }
                onSuccess();
            }
        }

        function onSocketClose(socket, evt) {
            if (socket !== _socket) {
                return;
            }
            evt = evt || {};
            logger.info('[CONN]: WebSocket is closed. Code: ' + evt.code + '. Reason:', evt.reason);
            handleConnectionClosed();
        }

        function onSocketMessage(socket, message) {
            if (socket !== _socket) {
                return;
            }
            try {
                if (!message.data || message.data === 'PING') {
                    // Ignore PING request
                    return;
                }

                var msg = message.data;
                if (!_config.doNotParseMessages) {
                    try {
                        msg = JSON.parse(msg);
                    } catch (err) {
                        logger.error('[CONN]: Message discarded (Cannot Parse): ', message.data);
                        return;
                    }
                }
                // Do not log message here since we don't know which fields must be suppressed
                raiseMessage(msg);
            } catch (e) {
                logger.error(e);
            }
        }

        function onSocketError(socket, error) {
            if (socket !== _socket) {
                return;
            }
            logger.error('[CONN]: WebSocket connection error. ', error);
            handleConnectionClosed();
        }

        function openSocket() {
            if (_state !== ConnectionState.Connecting && _state !== ConnectionState.Reconnecting) {
                // We are neither connecting nor reconnecting. Stop here.
                return;
            }

            // Clear handlers for previous socket
            clearSocket();
            _targetObfuscated = getObfuscatedUrl(_currTarget);
            logger.info('[CONN]: Opening WebSocket to ', _targetObfuscated);
            try {
                switch (window.navigator.platform) {
                case 'iOS':
                    _socket = WebSocket.createWebSocket(_currTarget);
                    break;
                case 'node':
                    _socket = new WebSocket(_currTarget, _config.cookie);
                    break;
                default:
                    _socket = new WebSocket(_currTarget);
                }
            } catch (e) {
                logger.error('[CONN]: Failed to connect WebSocket. ', e);
                onError(e);
                return;
            }

            _socket.onopen = onSocketOpen.bind(null, _socket);
            _socket.onclose = onSocketClose.bind(null, _socket);
            _socket.onmessage = onSocketMessage.bind(null, _socket);
            _socket.onerror = onSocketError.bind(null, _socket);
        }

        function clearSentTimestamps() {
            var now = Date.now();
            var idx = _sentTimestamps.findIndex(function (timestamp) {
                return now - timestamp < THROTTLE_INTERVAL;
            });
            if (idx === -1) {
                // Empty the array
                _sentTimestamps = [];
            } else {
                _sentTimestamps.splice(0, idx);
            }
        }

        function canSendMessage() {
            if (_throttleTimeout) {
                return false;
            }
            if (_sentTimestamps.length >= THROTTLE_MAX_MESSAGES) {
                clearSentTimestamps();
            }
            return _sentTimestamps.length < THROTTLE_MAX_MESSAGES;
        }

        function startThrottleTimeout() {
            if (!_throttleTimeout) {
                var delay = MIN_THROTTLE_DELAY;
                if (_sentTimestamps.length >= THROTTLE_MAX_MESSAGES) {
                    delay += THROTTLE_INTERVAL - (Date.now() - _sentTimestamps[0]);
                }
                logger.info('[CONN]: Start throttle timeout with delay = ', delay);
                _throttleTimeout = window.setTimeout(function () {
                    _throttleTimeout = null;
                    dequeueMessages();
                }, delay);
            }
        }

        function sendMessage(data) {
            try {
                var reqId = (data.request && data.request.requestId) || 'N/A';
                _socket.send(JSON.stringify(data));
                _sentTimestamps.push(Date.now());
                logger.debug('[CONN]: Sent message with requestId:', reqId);
            } catch (e) {
                logger.error('[CONN]: Failed to send message. ', e);
            }
        }

        function dequeueMessages() {
            logger.info('[CONN]: Dequeue throttled messages. Number of queued messages = ', _queuedMessages.length);
            clearSentTimestamps();

            var count = 0;
            while (_queuedMessages.length > 0 && _sentTimestamps.length < THROTTLE_MAX_MESSAGES && count < MAX_DEQUEUED_MESSAGES) {
                sendMessage(_queuedMessages.shift());
                count++;
            }
            if (_queuedMessages.length > 0) {
                logger.warn('[CONN]: Number of remaining queued messages = ', _queuedMessages.length);
                startThrottleTimeout();
            } else {
                logger.info('[CONN]: Dequeued all throttled messages');
            }
        }

        function queueMessage(data) {
            logger.warn('[CONN]: Throttling limit exceeded. Queue the message.');
            if (_queuedMessages.length >= MAX_QUEUED_MESSAGES) {
                logger.error('[CONN]: Throttling queue is full. Drop the message.');
            } else {
                // Queue the new message
                _queuedMessages.push(data);
                logger.info('[CONN]: Queued message #', _queuedMessages.length);
            }
        }

        function isLogoutRequest(data) {
            return !!(data.request && data.request.user && data.request.user.type === Constants.UserActionType.LOGOUT);
        }

        /////////////////////////////////////////////////////////////////////////////
        // Public Event Handlers
        /////////////////////////////////////////////////////////////////////////////
        this.onStateChange = null;
        this.onReconnectFailed = null;
        this.onMessage = null;

        /////////////////////////////////////////////////////////////////////////////
        // Public interfaces
        /////////////////////////////////////////////////////////////////////////////
        this.getState = function () { return _state; };

        this.isConnected = function () {
            return _state === ConnectionState.Connected;
        };

        this.connect = function (url, proxyUrl, successCallback, errorCallback) {
            logger.debug('[CONN]: Connect WebSocket: ', {
                url: getObfuscatedUrl(url),
                proxyUrl: getObfuscatedUrl(proxyUrl)
            });
            if (_state !== ConnectionState.Disconnected) {
                // Disconnect the socket but do not raise an event
                disconnect(true);
            }

            setTarget(url, proxyUrl);

            _successCb = successCallback || function () {};
            _errorCb = errorCallback || function () {};

            setState(ConnectionState.Connecting);
            // Use timeout to open socket after the connecting state is set
            window.setTimeout(function () {
                _currTarget = _proxyTarget || _defaultTarget;
                openSocket();
            }, 0);
        };

        this.disconnect = function () {
            logger.debug('[CONN]: Disconnect WebSocket from ', _targetObfuscated);
            disconnect();
        };

        this.reconnect = function (url, proxyUrl, delay) {
            logger.debug('[CONN]: Reconnect WebSocket: ', {
                url: getObfuscatedUrl(url),
                proxyUrl: getObfuscatedUrl(proxyUrl)
            });
            // First disconnect the socket (if opened).
            disconnect(true);

            // Update the url since the token uri parameter may have changed
            if (url) {
                setTarget(url, proxyUrl);
            }

            // Now start a small timeout to wait for the socket to close (if it was opened)
            // and then open it again.
            setState(ConnectionState.Reconnecting);
            _reconnectTimeout = window.setTimeout(function () {
                _reconnectTimeout = null;
                _currTarget = _proxyTarget || _defaultTarget;
                openSocket();
            }, delay || 50);
        };

        this.sendMessage = function (data, suppressLog) {
            if (_state !== ConnectionState.Connected) {
                logger.warn('[CONN]: WebSocket is not connected. Cannot send message. ', data);
                return false;
            }

            if (!data) {
                logger.error('[CONN]: Data is missing. Cannot send message.');
                return false;
            }

            if (!suppressLog) {
                logger.msgSend('[CONN]: ', data);
            }

            if (typeof data === 'string') {
                if (data === 'PING' && Utils.isMobile()) {
                    _socket.ping(); // Mobile client's implementation
                } else {
                    _socket.send(data);
                }
            } else {
                delete data.keysToOmitFromLogging;
                if (canSendMessage()) {
                    sendMessage(data);
                } else if (isLogoutRequest(data)) {
                    // Send LOGOUT and clear all queued requests since WS will be disconnected
                    sendMessage(data);
                    clearThrottlingData();
                } else {
                    queueMessage(data);
                    startThrottleTimeout();
                }
            }
            return true;
        };

        this.injectHasActiveCall = function (fn) {
            _hasActiveCall = typeof fn === 'function' ? fn : null;
        };
    }

    // Exports
    circuit.ConnectionController = ConnectionController;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.ConnectionState = ConnectionState;

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
