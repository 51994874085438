var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Utils = circuit.Utils;

    // Participant States for RTC Sessions
    var ParticipantState = {
        Active: {name: 'Active', ui: '', established: true, css: 'active-call'},
        Busy: {name: 'Busy', ui: 'res_ParticipantState_busy', established: false, css: 'busy'},
        Calling: {name: 'Calling', ui: '', established: false, css: 'calling'},
        Connecting: {name: 'Connecting', ui: '', established: false, css: 'connecting'}, // outgoing call at caller side: state just before being active
        ConnectionLost: {name: 'ConnectionLost', ui: 'res_ParticipantState_connectionLost', established: false, css: 'connection-lost'},
        Declined: {name: 'Declined', ui: 'res_ParticipantState_declined', established: false, css: 'declined'},
        Delivered: {name: 'Delivered', ui: '', established: false, css: 'outgoing'},
        Idle: {name: 'Idle', ui: '', established: false, css: 'idle'},
        Inactive: {name: 'Inactive', ui: '', established: false, css: 'inactive'},   // participant hasn't answered yet, but at least one did
        Initiated: {name: 'Initiated', ui: '', established: false, css: 'outgoing'},
        Joined: {name: 'Joined', ui: 'res_ParticipantState_joined', established: true, css: 'joined-call'},
        Muted: {name: 'Muted', ui: 'res_ParticipantState_muted', established: true, css: 'muted'},
        Offline: {name: 'Offline', ui: 'res_ParticipantState_offline', established: false, css: 'offline'},
        OffStage: {name: 'OffStage', ui: 'res_ParticipantState_leftStage', established: false, css: 'declined'},
        OnStage: {name: 'OnStage', ui: 'res_ParticipantState_enteredStage', established: true, css: 'joined-call'},
        Removed: {name: 'Removed', ui: 'res_ParticipantState_removed', established: false, css: 'declined'},
        Ringing: {name: 'Ringing', ui: '', established: false, css: 'incoming'},
        Terminated: {name: 'Terminated', ui: 'res_ParticipantState_left', established: false, css: 'declined'},
        Timeout: {name: 'Timeout', ui: 'res_ParticipantState_missed', established: false, css: 'busy'}
    };

    var ParticipantAction = {
        Drop: {name: 'Drop', type: 'hangup', icon: '', localize: 'res_Hangup'},
        Mute: {name: 'Mute', type: 'microphone', icon: 'unmuted', localize: 'res_Mute'},
        Unmute: {name: 'Unmute', type: 'microphone', icon: 'muted', localize: 'res_Unmute'},
        StartVideo: {name: 'StartVideo', type: 'video', icon: 'inactive', localize: 'res_StartVideo'},
        StopVideo: {name: 'StopVideo', type: 'video', icon: 'active', localize: 'res_StopVideo'}
    };

    // Participant object for RTC Sessions
    var RtcParticipant = {};

    // Create participant object from a given UserProfile
    RtcParticipant.createFromUser = function (user, pcState) {
        if (!user) {
            return null;
        }

        var participant = Object.create(user);
        participant.pcState = pcState || ParticipantState.Idle;
        participant.streamId = null;
        participant.screenStreamId = null;
        participant.videoStream = null;
        participant.mediaType = {audio: false, video: false, desktop: false};
        participant.muted = false;
        participant.activeSpeaker = false;
        participant.isActive = RtcParticipant.isActive.bind(null, participant);
        participant.actions = [];
        participant.screenSharePointerSupported = false;

        participant.hasSameMediaType = RtcParticipant.hasSameMediaType.bind(null, participant);
        participant.hasVideoStream = RtcParticipant.hasVideoStream.bind(null, participant);
        participant.setActions = RtcParticipant.setActions.bind(null, participant);
        participant.rtcSupportedFeatures = [];
        participant.flags = [];

        // The extended User object overrides the toJSON function to flatten the object
        // Lets's override it again here so we don't do it for RTC participants.
        participant.toJSON = function () {
            return this;
        };

        // JSON.stringify does not include protototype's properties by default, so copy
        // the userId field to the inherithed object. Do not copy other fields since they
        // may become out of synch.
        participant.userId = user.userId;
        // Also copy first and last names since they are needed for VDI
        participant.firstName = user.firstName;
        participant.lastName = user.lastName;

        // Video URLs are deprecated, but we need to save it if a client requested it,
        // otherwise we would create a different URL every time the client accesses the videoUrl property
        var videoStream = null;
        var videoUrl = '';

        Object.defineProperty(participant, 'videoStream', {
            get: function () {
                return videoStream;
            },
            set: function (stream) {
                videoStream = stream;
                videoUrl = '';
            },
            enumerable: true,
            configurable: false
        });

        if (Utils.isMobile()) {
            // The following property has been deprecated for Web/DA and must only be defined for mobile clients
            Object.defineProperty(participant, 'videoUrl', {
                get: function () {
                    if (videoStream) {
                        videoUrl = videoUrl || circuit.WebRTCAdapter.createObjectURL(videoStream);
                    } else {
                        videoUrl = '';
                    }
                    return videoUrl;
                },
                enumerable: true,
                configurable: false
            });
        }

        participant.streams = {};

        return participant;
    };

    RtcParticipant.isActive = function (participant) {
        switch (participant.pcState) {
        case ParticipantState.Active:
        case ParticipantState.Joined:
        case ParticipantState.Muted:
        case ParticipantState.OnStage:
            return true;
        }
        return false;
    };

    RtcParticipant.hasSameMediaType = function (p1, p2) {
        return !!(p1 && p2 && p1.mediaType && p2.mediaType &&
            p1.mediaType.audio === p2.mediaType.audio &&
            p1.mediaType.video === p2.mediaType.video &&
            p1.mediaType.desktop === p2.mediaType.desktop);
    };

    RtcParticipant.hasVideoStream = function (participant) {
        return !!(participant.videoStream && (participant.streamId || participant.screenStreamId) && (participant.mediaType.video || participant.mediaType.desktop));
    };

    RtcParticipant.setActions = function (participant, conversation, localUser) {
        participant.actions = [];

        if (conversation && conversation.isTemporary) {
            // Invited guests are not allowed any actions
            return;
        }

        if (!participant.pcState.established) {
            if (participant.pcState === ParticipantState.Initiated) {
                // Ringing participant added to call.
                participant.actions.push(ParticipantAction.Drop);
            }
            return;
        }

        if (participant.isMeetingPointInvitee) {
            participant.actions.push(participant.mediaType.video ? ParticipantAction.StopVideo : ParticipantAction.StartVideo);
            participant.actions.push(participant.muted ? ParticipantAction.Unmute : ParticipantAction.Mute);
            participant.actions.push(ParticipantAction.Drop);
        } else {
            var isConvModerated = !conversation || conversation.isModerated;
            var amIModerator = conversation && conversation.userIsModerator(localUser);
            var isModerator = conversation && conversation.userIsModerator(participant);

            if (!participant.muted && (!isConvModerated || !isModerator || amIModerator)) {
                // In a moderated conversation, non-moderators can only mute non-moderators
                participant.actions.push(ParticipantAction.Mute);
            }
            if (!isConvModerated || amIModerator) {
                // In a moderated conversation, only moderators can drop other participants
                participant.actions.push(ParticipantAction.Drop);
            }
        }
    };

    // Exports
    circuit.RtcParticipant = RtcParticipant;
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.ParticipantState = ParticipantState;
    circuit.Enums.ParticipantAction = ParticipantAction;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
