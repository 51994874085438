var Circuit = (function (circuit) {
    'use strict';

    var logger = circuit.logger;

    // Start by creating the base Angular objects
    logger.debug('[impServicesLoader]: Creating Angular objects');

    //---------------------------------------------
    // Create Angular $rootScope object
    //---------------------------------------------
    // var rootScope = {
    //     isRegistered: false,
    //     isChromeApp: false,
    //     isMobileApp: true,
    //     localUser: null,
    //     webRtcEnabled: true,
    //     browser: {
    //         ios: true,
    //         type: 'ios'
    //     },

    //     // Angular interfaces
    //     $$phase: true,
    //     $apply: function (callback) {
    //         callback && callback();
    //     },
    //     $digest: function () {
    //     }
    // };

    logger.debug('[impServicesLoader]: Created Angular objects');

    /* eslint-enable max-len, max-lines-per-function */

    // Instantiate services either for guest or normal user
    function loadServices(guest, productName, data) {
        var services = {};
        logger = circuit.logger;

        var rootScopeFn = data.rootScope;
        rootScopeFn.isSessionGuest = !!guest;
        circuit.productName = productName || 'Unify Phone';
        rootScopeFn.isRebrandedSystem = circuit.productName !== 'Unify Phone';

        circuit.__serverName = data.serverName;

        logger.info('[impServicesLoader]: loadServices: guest = ' + rootScopeFn.isSessionGuest + ', productName = ' + circuit.productName);

        //---------------------------------------------------------------------------
        // Create the services.
        //---------------------------------------------------------------------------
        loadCommonServices(data, services);
        loadNormalServices(data, services);

        circuit.serviceInstances = services;
        return true;
    }

    function loadCommonServices(data, services) {
        data = data || {};
        var rootScopeFn = data.rootScope;
        var promiseFn = data.promise;
        var timeoutFn = data.timeout;
        var httpFn = data.http;
        var popupFn = data.popupFn;

        try {
            services.logSvc = new circuit.LogSvcImpl(rootScopeFn, timeoutFn);
            logger.debug('[impServicesLoader]: Instantiated LogSvc service');
            services.logSvc.startFileLogger(false, window.__clientVersion);

            // UtilSvc
            services.utilSvc = new circuit.UtilSvcImpl(
                rootScopeFn,
                promiseFn,
                timeoutFn,
                logger);
            logger.debug('[impServicesLoader]: Instantiated UtilSvc service');

            // PubSubSvc
            services.pubSubSvc = new circuit.PubSubSvcImpl(logger);
            logger.debug('[impServicesLoader]: Instantiated PubSubSvc service');

            // HttpSvc
            services.httpSvc = new circuit.HttpSvcImpl(
                rootScopeFn,
                window,
                [document],
                timeoutFn,
                httpFn,
                promiseFn,
                logger);

            logger.debug('[impServicesLoader]: Created HttpSvc service');

            // LocalizeSvc
            services.localizeSvc = new circuit.LocalizeSvcImpl(
                rootScopeFn,
                window,
                null,   // $locale
                promiseFn,
                logger,
                services.utilSvc,
                services.pubSubSvc,
                services.httpSvc);
            logger.debug('[impServicesLoader]: Instantiated LocalizeSvc service');

            // PopupSvc
            services.popupSvc = popupFn;
            logger.debug('[impServicesLoader]: Created PopupSvc service');

            // LocalStoreSvc
            services.localStoreSvc = new circuit.LocalStoreSvcImpl(
                rootScopeFn,
                timeoutFn,
                promiseFn,
                window,
                logger);
            logger.debug('[impServicesLoader]: Instantiated LocalStoreSvcImpl service');

            // CircuitLabSvc
            services.circuitLabSvc = new circuit.CircuitLabSvcImpl(
                rootScopeFn,
                window,
                logger,
                services.pubSubSvc,
                services.localStoreSvc,
                services.localizeSvc);
            logger.debug('[impServicesLoader]: Instantiated CircuitLabSvcImpl service');

            // InstrumentationSvc
            services.instrumentationSvc = new circuit.InstrumentationSvcImpl(
                rootScopeFn,
                logger,
                services.pubSubSvc,
                services.localStoreSvc);
            logger.debug('[impServicesLoader]: Instantiated InstrumentationSvc service');

            // PictureEditSvc
            services.pictureEditSvc = new circuit.PictureEditSvcImpl(
                window,
                promiseFn);
            logger.debug('[impServicesLoader]: Instantiated InstrumentationSvc service');

            // DeviceDiagnosticSvc
            services.deviceDiagnosticSvc = new circuit.DeviceDiagnosticSvcImpl(
                rootScopeFn,
                timeoutFn,
                logger,
                services.pubSubSvc,
                services.localStoreSvc);

            logger.debug('[impServicesLoader]: Instantiated DeviceDiagnosticSvc service');
        } catch (e) {
            logger.error('[impServicesLoader]: Failed to create common service instances. ', e);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    function loadNormalServices(data, services) { // NOSONAR
        data = data || {};
        var rootScopeFn = data.rootScope;
        var promiseFn = data.promise;
        var timeoutFn = data.timeout;
        var intervalFn = data.interval;
        var httpFn = data.http;
        var injectorFn = data.injectorFn;

        // Load normal user services
        try {
            // FileApiSvc
            services.fileApiSvc = new circuit.FileApiSvcImpl(httpFn, promiseFn, logger);
            logger.debug('[impServicesLoader]: Created FileApiSvc service');

            // LocationSvc
            services.locationSvc = new circuit.LocationSvcImpl(
                rootScopeFn,
                window,
                httpFn,
                timeoutFn,
                logger,
                services.localizeSvc,
                services.localStoreSvc);
            logger.debug('[impServicesLoader]: Instantiated LocationSvc service');

            // UserProfileSvc
            services.userProfileSvc = new circuit.UserProfileSvcImpl(
                rootScopeFn,
                promiseFn,
                intervalFn,
                timeoutFn,
                logger,
                services.pubSubSvc,
                services.localizeSvc,
                services.localStoreSvc);
            logger.debug('[impServicesLoader]: Instantiated UserProfileSvc service');

            // RegistrationSvc
            services.registrationSvc = new circuit.RegistrationSvcImpl(
                rootScopeFn,
                timeoutFn,
                promiseFn,
                httpFn,
                logger,
                services.pubSubSvc,
                services.localStoreSvc,
                services.userProfileSvc);
            logger.debug('[impServicesLoader]: Instantiated RegistrationSvc service');

            // ExtensionSvc
            services.extensionSvc = new circuit.ExtensionSvcImpl(
                rootScopeFn,
                timeoutFn,
                window,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.popupSvc,
                services.registrationSvc);
            logger.debug('[impServicesLoader]: Instantiated ExtensionSvc service');

            // ExchangeConnSvc
            services.exchangeConnSvc = {
                searchContactsWithConstraints: function (searchStr, constraints, resCount, cb) {
                    cb && cb();
                }
            };

            // MailboxConnSvc
            services.mailboxConnSvc = new circuit.MailboxConnSvcImpl(
                rootScopeFn,
                promiseFn,
                injectorFn,
                logger,
                services.pubSubSvc);

            logger.debug('[impServicesLoader]: Instantiated MailboxConnSvc service');

            // UserSvc
            services.userSvc = new circuit.UserSvcImpl(
                rootScopeFn,
                timeoutFn,
                intervalFn,
                promiseFn,
                services.localStoreSvc,
                logger,
                services.pubSubSvc,
                services.mailboxConnSvc);
            logger.debug('[impServicesLoader]: Instantiated UserSvc service');

            services.notificationSvc = new circuit.NotificationSvcImpl(
                rootScopeFn,
                timeoutFn,
                window,
                logger,
                services.pubSubSvc,
                services.userSvc);
            logger.debug('[impServicesLoader]: Instantiated NotificationSvc service');

            // ConversationSvc
            services.conversationSvc = new circuit.ConversationSvcImpl(
                rootScopeFn,
                promiseFn,
                timeoutFn,
                logger,
                services.pubSubSvc);
            logger.debug('[impServicesLoader]: Instantiated ConversationSvc service');

            // PhoneCallLogSvc
            if (circuit.PhoneCallLogSvcImpl) {
                services.phoneCallLogSvc = new circuit.PhoneCallLogSvcImpl(
                    rootScopeFn,
                    promiseFn,
                    services.conversationSvc,
                    logger,
                    services.pubSubSvc,
                    services.userSvc,
                    services.notificationSvc);
                logger.debug('[impServicesLoader]: Instantiated PhoneCallLogSvc service');
            }

            // AtcRegistrationSvc
            services.atcRegistrationSvc = new circuit.AtcRegistrationSvcImpl(
                rootScopeFn,
                timeoutFn,
                logger,
                services.pubSubSvc,
                services.registrationSvc,
                services.conversationSvc);
            logger.debug('[impServicesLoader]: Instantiated AtcRegistrationSvc service');

            // CircuitCallControlSvc
            services.circuitCallControlSvc = new circuit.CircuitCallControlSvcImpl(
                rootScopeFn,
                timeoutFn,
                intervalFn,
                window,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.userSvc,
                services.conversationSvc,
                services.notificationSvc,
                services.instrumentationSvc,
                services.deviceDiagnosticSvc);
            logger.debug('[impServicesLoader]: Instantiated CircuitCallControlSvc service');

            // CstaSvc
            services.cstaSvc = new circuit.CstaSvcImpl(
                rootScopeFn,
                timeoutFn,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.circuitCallControlSvc,
                services.atcRegistrationSvc,
                services.userSvc,
                services.userProfileSvc);
            logger.debug('[impServicesLoader]: Instantiated CstaSvc service');

            // CallControlSvc
            services.callControlSvc = new circuit.CallControlSvcImpl(
                rootScopeFn,
                timeoutFn,
                logger,
                services.pubSubSvc,
                services.circuitCallControlSvc,
                services.conversationSvc,
                services.cstaSvc,
                services.notificationSvc,
                services.userProfileSvc);
            logger.debug('[impServicesLoader]: Instantiated CallControlSvc service');

            // SoundSvc
            services.soundSvc = new circuit.SoundSvcImpl(
                rootScopeFn,
                window,
                services.callControlSvc,
                services.conversationSvc,
                logger,
                services.notificationSvc,
                services.pubSubSvc,
                services.localStoreSvc,
                services.userProfileSvc);
            logger.debug('[impServicesLoader]: Instantiated SoundSvc service');

            // HeadsetHidControlSvc
            services.headsetHidControlSvc = new circuit.HeadsetHidControlSvcImpl(
                rootScopeFn,
                window,
                timeoutFn,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.popupSvc,
                services.localStoreSvc,
                services.userProfileSvc,
                services.registrationSvc,
                services.callControlSvc,
                services.extensionSvc
            );
            logger.debug('[impServicesLoader]: Instantiated HeadsetHidControlSvc service');

            // PlantronicsControlSvc
            services.plantronicsControlSvc = new circuit.PlantronicsControlSvcImpl(
                rootScopeFn,
                intervalFn,
                timeoutFn,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.callControlSvc,
                services.localStoreSvc
            );
            logger.debug('[impServicesLoader]: Instantiated PlantronicsControlSvc service');

            // DeviceHandlerSvc
            services.deviceHandlerSvc = new circuit.DeviceHandlerSvcImpl(
                window,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.localStoreSvc,
                services.headsetHidControlSvc
            );
            logger.debug('[impServicesLoader]: Instantiated DeviceHandlerSvc service');

            // DeviceSettingsSvc
            services.deviceSettingsSvc = new circuit.DeviceSettingsSvcImpl(
                rootScopeFn,
                promiseFn,
                timeoutFn,
                window,
                logger,
                services.pubSubSvc,
                services.deviceHandlerSvc,
                services.callControlSvc
            );
            logger.debug('[impServicesLoader]: Instantiated DeviceSettingsSvc service');

            // CallAssociatedNotificationsSvc
            services.callAssociatedNotificationsSvc = new circuit.CallAssociatedNotificationsSvcImpl(
                rootScopeFn,
                timeoutFn,
                logger,
                services.pubSubSvc,
                services.popupSvc,
                services.callControlSvc,
                services.deviceHandlerSvc
            );
            logger.debug('[impServicesLoader]: Instantiated DeviceSettingsSvc service');

            // // AccountManagementSvc
            // services.accountManagementSvc = new circuit.AccountManagementSvcImpl(
            //     promiseFn,
            //     rootScopeFn,
            //     timeoutFn,
            //     httpFn,
            //     logger,
            //     services.utilSvc,
            //     services.pubSubSvc,
            //     services.userSvc);
            // logger.debug('[impServicesLoader]: Instantiated AccountManagementSvc service');

            // ContactCardSvc
            services.contactCardSvc = new circuit.ContactCardSvcImpl(
                rootScopeFn,
                promiseFn,
                logger,
                services.utilSvc,
                services.pubSubSvc,
                services.popupSvc,
                services.userSvc,
                services.conversationSvc,
                services.callControlSvc,
                services.extensionSvc);
            logger.debug('[impServicesLoader]: Instantiated ContactCardSvc service');

            // VoicemailSvc
            services.voicemailSvc = new circuit.VoicemailSvcImpl(
                rootScopeFn,
                window,
                promiseFn,
                httpFn,
                logger,
                services.pubSubSvc,
                services.fileApiSvc,
                services.userProfileSvc);
            logger.debug('[impServicesLoader]: Instantiated VoicemailSvc service');

            // TelephonySettingsSvc.js
            services.telephonySettingsSvc = new circuit.TelephonySettingsSvcImpl(
                rootScopeFn,
                promiseFn,
                logger,
                services.pubSubSvc,
                services.cstaSvc,
                services.userProfileSvc,
                services.voicemailSvc);
            logger.debug('[impServicesLoader]: Instantiated TelephonySettingsSvc service');

            // PictureEditSvc
            services.pictureEditSvc = new circuit.PictureEditSvcImpl(
                window,
                promiseFn);
            logger.debug('[impServicesLoader]: Instantiated InstrumentationSvc service');

            // FileUploadSvc
            services.fileUploadSvc = new circuit.FileUploadSvcImpl(
                rootScopeFn,
                httpFn,
                promiseFn,
                logger);
            logger.debug('[impServicesLoader]: Instantiated FileUploadSvc service');

            // PhoneCallSvc.js
            if (circuit.PhoneCallSvcImpl) {
                services.phoneCallSvc = new circuit.PhoneCallSvcImpl(
                    rootScopeFn,
                    promiseFn,
                    timeoutFn,
                    services.localStoreSvc,
                    logger,
                    services.pubSubSvc,
                    services.userProfileSvc,
                    services.callControlSvc,
                    services.atcRegistrationSvc,
                    services.popupSvc); // PopupSvc
                logger.debug('[impServicesLoader]: Instantiated PhoneCallSvc service');
            }
        } catch (e) {
            logger.error('[impServicesLoader]: Failed to create normal user specific service instances. ', e);
        }
    }

    circuit.initBL = function (data) {
        loadServices(false, 'Unify Phone', data);
    };

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
