var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Enums = circuit.Enums;

    ///////////////////////////////////////////////////////////////////////////////////////
    // NavigationSvc Implementation
    // Provides navigation API to be used by controllers only.
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function NavigationSvcImpl($location, $route, LogSvc, PubSubSvc, ConversationSvc, UISvc, CallControlSvc) { // NOSONAR
        LogSvc.debug('New Service: NavigationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _pendingNavigateToCall = false;
        var _self = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function go(path, urlParams) {
            LogSvc.info('[NavigationSvc]: Route request to ', path);

            // Remove search parameters such as ?item
            $location.url($location.path());

            path = path.replace('#', '');
            if ($location.path() === path) {
                $route.reload();
            } else {
                $location.path(path);
            }

            // Add custom search parameters to url
            if (urlParams) {
                Object.keys(urlParams).forEach(function (key) {
                    $location.search(key, urlParams[key]);
                });
            }

            // Clear the search when routing to a draft
            if (path === '/draft') {
                LogSvc.debug('[NavigationSvc]: Clear the search');
                UISvc.clearSearch();
            }
        }

        function goToConversation(convId, urlParams) {
            LogSvc.debug('[NavigationSvc]: Go to conversation: ', convId);
            if (convId.startsWith('draft_')) {
                go('/draft/' + convId, urlParams);
            } else {
                var c = ConversationSvc.getConversationFromCache(convId);
                if (c && c.isTelephony) {
                    go('/phone/' + convId, urlParams);
                } else {
                    go('/conversation/' + convId, urlParams);
                }
            }
        }

        function navigateToPhoneConversation(convId) {
            if (!convId) {
                return;
            }
            _pendingNavigateToCall = false;
            var path = $location.path();
            var pathSplit = path.split('/');
            if (pathSplit.length >= 2 && pathSplit[1] === 'phone') {
                // Conversation already selected, no need to navigate
                return;
            }
            LogSvc.debug('[NavigationSvc]: Navigating to phone conversation');
            _self.selectConversation({
                route: 'phone',
                convId: convId
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        function onCallState(call) {
            if (!call) {
                return;
            }
            if (call.isTelephonyCall && _pendingNavigateToCall &&
                (call.state === Enums.CallState.Initiated || call.getCstaState() === Enums.CstaCallState.Delivered)) {
                navigateToPhoneConversation(call.convId);
            }
        }
        PubSubSvc.subscribe('/call/state', onCallState);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Navigate to the specified route.
         *
         * @param {Object} routeInfo Contains attributes 'route' and 'convId'.
         * @param {Hashtable} urlParams URL parameters with key/value pairs.
         */
        this.selectConversation = function (routeInfo, urlParams) {
            LogSvc.debug('[NavigationSvc]: Select conversation: ', routeInfo);
            if (!routeInfo.route) {
                if (routeInfo.convId) {
                    goToConversation(routeInfo.convId, urlParams);
                }
                return;
            }

            var convId = routeInfo.convId || '';
            var route = routeInfo.route;
            if (route === 'conversation' && convId.startsWith('draft_')) {
                route = 'draft';
            }
            var url = '/' + route + '/' + convId;
            if (routeInfo.guestToken) {
                url += '/' + routeInfo.guestToken;
            }
            go(url, urlParams);
        };

        /**
         * Navigate to the root URL.
         */
        this.goToRoot = function () {
            LogSvc.debug('[NavigationSvc]: Go to root');
            go('/');
        };

        /**
         * Navigate to phone calls conversation and dial the number.
         *
         * @param {String} Number to dial.
         */
        this.dialNumber = function (number, userId) {
            if (number) {
                LogSvc.debug('[NavigationSvc]: Dial number ', number);
                LogSvc.debug('[NavigationSvc]: Publish /shortcut/newPhoneCall event');
                PubSubSvc.publish('/shortcut/newPhoneCall', [number, null, userId]);
            }
        };

        /**
         * Navigate to phone calls conversation and dial the user by name.
         *
         * @param {String} Number to dial.
         */
        this.dialByName = function (name, userId) {
            if (name) {
                LogSvc.debug('[NavigationSvc]: Dial by name - ', name);
                LogSvc.debug('[NavigationSvc]: Publish /shortcut/newPhoneCall event');
                PubSubSvc.publish('/shortcut/newPhoneCall', [null, name, userId]);
            }
        };

        /**
         * Navigate to the direct conversation with the given user or to an empty draft in
         * case we still don't have a direct conversation.
         *
         * @param {String} The user Id.
         */
        this.goToDirectConversation = function (userId) {
            LogSvc.debug('[NavigationSvc]: Go to user page for userId ', userId);
            _self.go('/user/' + userId);
        };

        /**
         * Navigate to the specified location path.
         *
         * @param {String} path URL path to navigate to. E.g. 'forgotPassword'
         * @param {Hashtable} urlParams URL parameters with key/value pairs.
         */
        this.go = function (path, urlParams) {
            go(path, urlParams);
        };

        /**
         * Navigate to the specified item in the corresponding conversation or Space.
         *
         * @param {Object} item Conversation or Space item object.
         */
        this.goToItem = function (item) {
            if (!item) {
                return;
            }
            // Update the URL without reloading route.
            // Note: The second parameter in $location.path (i.e. skipReload) is a Circuit extension.
            if (item.convId) {
                LogSvc.debug('[NavigationSvc]: Go to item ' + item.itemId + ' in conv ' + item.convId);
                $location.path('conversation/' + item.convId, false).search('item', item.itemId);
            } else {
                LogSvc.warn('[NavigationSvc]: goToItem invoked with invalid item. ', item);
            }
        };

        /**
         * Navigate to an empty conversation draft.
         */
        this.goToEmptyDraft = function (draftType, userId) {
            LogSvc.debug('[NavigationSvc]: Go to empty draft with type ', draftType);
            var urlParams = {
                draftType: draftType
            };
            if (userId) {
                urlParams.user = userId;
            }
            go('/draft', urlParams);
        };

        /**
         * Check that conversation with specified id is open.
         *
         * @param {String} convId conversation id to check.
         * @return {Boolean}
         */
        this.isConversationSelected = function (convId) {
            if (!convId) { return false; }
            return $location.path().includes('conversation/' + convId);
        };

        /**
         * Activate/deactivate automatic route to phone conversation when the next phone call's
         * state goes to Initiated (local) or Delivered (remote)
         *
         * @param {Boolean} route true to activate automatic route, false otherwise
         */
        this.setRouteToPhone = function (route) {
            if (route) {
                var calls = CallControlSvc.getPhoneCalls();
                if (calls.length) {
                    // We have a phone call visible, so we can navigate right away
                    navigateToPhoneConversation(calls[0].convId);
                    return;
                }
            }
            _pendingNavigateToCall = !!route;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.NavigationSvcImpl = NavigationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
