/*global PasswordMgmt, RegistrationState, require*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var ClientApiHandler = circuit.ClientApiHandlerSingleton;
    var ConnectionState = circuit.Enums.ConnectionState;
    var Constants = circuit.Constants;
    var IdleState = circuit.Enums.IdleState;
    var UserProfile = circuit.UserProfile;
    var Utils = circuit.Utils;
    var WebRTCAdapter = circuit.WebRTCAdapter;
    var RtcSessionController = circuit.RtcSessionController;

    // eslint-disable-next-line max-params, max-lines-per-function
    function RegistrationSvcImpl($rootScope, $timeout, $q, $http, LogSvc, PubSubSvc, LocalStoreSvc, UserProfileSvc) { // NOSONAR
        LogSvc.debug('New Service: RegistrationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var PENDING_CONNECTION_DELAY = 50;
        var DEFAULT_MAX_UPLOAD_SIZE = 50 * 1024 * 1024; // byte
        var DEFAULT_SHOW_PRESENCE_ON_ADD_USER = false;
        var RETRY_DELAY = 1000;
        var RETRY_INTERVAL = 8000;

        // Set Logout timeout to 2s. We don't actually need to wait for the response.
        // The timer is just to give some time for the logout request to be sent out to the wire before WebSocket is closed.
        var LOGOUT_TIMEOUT = 2000;

        // NGTC-4801: New logons 12 hours before session expiration (7 days) should force logout and inform user on login page
        var SESSION_EXPIR_ANTICIPATION_TIME = 43200000; // 12 hours

        var _userData = null;
        var _regState = RegistrationState.Disconnected;
        var _clientSettings = null;
        var _isMobileAsleep = false; // true: indicates a mobile client in background mode
        var _voipNotificationsDisabled = false;
        var _that = this;

        // Initialize global properties
        var _globalProperties = {
            showPresenceOnAddUser: DEFAULT_SHOW_PRESENCE_ON_ADD_USER,
            chromeExtensionId: '',
            edgeExtensionId: ''
        };

        var _clientApiHandler = ClientApiHandler.getInstance();

        var _pendingDisconnect = false;
        var _pendingSetupConnections = null;
        var _pendingSetupConnectionsTimeout = null;

        var _cachedStuff = null;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function setState(newState) {
            if (newState !== _regState) {
                _regState = newState;
                if (newState === RegistrationState.Disconnected) {
                    _pendingDisconnect = false;
                    if (_pendingSetupConnections) {
                        // Wait a little for any pending Client API requests to be aborted
                        _pendingSetupConnectionsTimeout = $timeout(function () {
                            _pendingSetupConnectionsTimeout = null;
                            _pendingSetupConnections();
                            _pendingSetupConnections = null;
                        }, PENDING_CONNECTION_DELAY);
                    }
                }

                LogSvc.info('[RegistrationSvc]: Publish /registration/state event. State = ', newState);
                PubSubSvc.publish('/registration/state', [newState]);
            }
        }

        function setUserData(data) {
            LogSvc.debug('[RegistrationSvc]: Received user data from server. ', data);
            if (!data || !data.clientSettings) {
                LogSvc.error('[RegistrationSvc]: Server did not return client settings');
                return;
            }

            _userData = data;

            if (data.ssoLogin !== undefined && $rootScope.ssoLogin !== data.ssoLogin) {
                LogSvc.info('[RegistrationSvc]: Update ssoLogin to ', data.ssoLogin);
                $rootScope.ssoLogin = data.ssoLogin;
                LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.SSOLOGIN, $rootScope.ssoLogin);
            }

            $rootScope.conceptboardConfig = data.conceptboardConfig;

            if (typeof $rootScope.circuitLabs.SIMULCAST === 'boolean') {
                data.clientSettings = data.clientSettings || {};
                data.clientSettings.addAdditionalLowVideoStream = $rootScope.circuitLabs.SIMULCAST;
            }
            RtcSessionController.processClientSettings(data.clientSettings);

            var settings = data.clientSettings;
            settings.clientVersion = data.clientVersion;
            settings.runningMode = data.mode;
            settings.serverTime = data.serverTime;
            settings.serverName = data.serverName;

            $rootScope.developmentMode = settings.runningMode === 'development';
            _clientApiHandler.setDevelopmentMode($rootScope.developmentMode);
            _clientApiHandler.setProxyTarget(data.wsTargetURL);

            _clientSettings = settings;

            LogSvc.debug('[RegistrationSvc]: Publish /clientSettings/loaded event. settings = ', settings);
            PubSubSvc.publish('/clientSettings/loaded', [settings]);
        }

        function onRegistrationError(errorCb, err) {
            if (_regState === RegistrationState.Reconnecting) {
                LogSvc.error('[RegistrationSvc]: Failed to reconnect client. ', err);

                if (err === Constants.ReturnCode.DISCONNECTED) {
                    LogSvc.info('[RegistrationSvc]: Reconnection failed because socket was disconnected. Wait for next reconnection.');
                } else {
                    var delay = RETRY_DELAY + Utils.randomNumber(0, RETRY_INTERVAL);
                    LogSvc.info('[RegistrationSvc]: Try to reconnect in ' + delay + ' milliseconds');
                    _clientApiHandler.reconnect(delay);
                }
                return;
            }

            LogSvc.error('[RegistrationSvc]: Failed to register client. ', err);
            _userData = null;
            _clientApiHandler.disconnect();
            setState(RegistrationState.Disconnected);
            if (errorCb) {
                if (err === Constants.ReturnCode.OVERLOADED) {
                    err = 'res_BackendOverloadedError';
                } else if (typeof err !== 'string' || !err.startsWith('res_')) {
                    err = 'res_InternalServerError';
                }
                errorCb(err);
            }
        }

        function setGlobalProperties(globalProperties) {
            globalProperties && globalProperties.forEach(function (prop) {
                switch (prop.name) {
                case Constants.GlobalPropertyName.CHROME_EXTENSION_ID:
                    if (prop.value) {
                        _globalProperties.chromeExtensionId = prop.value;
                    } else {
                        _globalProperties.chromeExtensionId = '';
                        LogSvc.info('[RegistrationSvc]: No value for CHROME_EXTENSION_ID. Use local hardcoded IDs.');
                    }
                    break;
                case Constants.GlobalPropertyName.EDGE_EXTENSION_ID:
                    if (prop.value) {
                        _globalProperties.edgeExtensionId = prop.value;
                    } else {
                        _globalProperties.edgeExtensionId = '';
                        LogSvc.info('[RegistrationSvc]: No value for EDGE_EXTENSION_ID. Use local hardcoded URL.');
                    }
                    break;
                case Constants.GlobalPropertyName.SHOW_PRESENCE_ON_ADD_USER:
                    if (prop.value) {
                        _globalProperties.showPresenceOnAddUser = prop.value === 'true';
                    } else {
                        LogSvc.warn('[RegistrationSvc]: Invalid SHOW_PRESENCE_ON_ADD_USER value. Fallback to default.');
                        _globalProperties.showPresenceOnAddUser = DEFAULT_SHOW_PRESENCE_ON_ADD_USER;
                    }
                    break;
                case Constants.GlobalPropertyName.EXCHANGE_ONLINE_CONFIG:
                    if (prop.value) {
                        _globalProperties.exchangeOnlineConfig = prop.value;
                    } else {
                        LogSvc.info('[RegistrationSvc]: No value for EXCHANGE_ONLINE_CONFIG.');
                    }
                    break;
                }
            });
        }

        function processGetStuffResult(stuff, successCb, errorCb) {
            var user = stuff.user;
            var tenant = stuff.tenant;
            var supportInfo = stuff.supportInfo;
            delete stuff.user;

            user.secId = tenant.secId;
            user.domainName = tenant.company;
            user.isStandalone = tenant.isStandalone;
            user.accountPackageName = user.accounts && user.accounts[0] && user.accounts[0].parentPackageName;

            // Make sure the setUserInfo API is available (iOS does not support it)
            if (typeof LogSvc.setUserInfo === 'function') {
                // We need to pass the non-extended user object to the logger
                LogSvc.setUserInfo(user, LocalStoreSvc.isCachingDisabled());
            }

            if (_cachedStuff) {
                // We only ask for Telephony conversation IDs once, so copy from cached data (if available).
                stuff.telephonyConvId = _cachedStuff.telephonyConvId || stuff.telephonyConvId;
            }

            LocalStoreSvc.setLoggedOnUser(user)
            .then(function () {
                if (_pendingSetupConnections) {
                    LogSvc.info('[RegistrationSvc]: Abort previous connection');
                    return;
                }

                user = UserProfile.extend(user);

                // Set username in local store in case rememberMe is set
                LogSvc.info('[RegistrationSvc]: Set lastUserName in local store to ', user.emailAddress);
                LocalStoreSvc.setString(LocalStoreSvc.keys.LAST_USERNAME, user.emailAddress);

                LogSvc.info('[RegistrationSvc]: Set ssologin in local store to ', $rootScope.ssoLogin);
                LocalStoreSvc.setString(LocalStoreSvc.keys.SSOLOGIN, $rootScope.ssoLogin);

                // Parse global properties
                setGlobalProperties(stuff.globalProperties);
                LogSvc.info('[RegistrationSvc]: Updated global properties: ', _globalProperties);

                // Add parsed global properties to stuff object
                stuff.parsedGlobalProperties = _globalProperties;

                if (_isMobileAsleep) {
                    LogSvc.info('[RegistrationSvc]: The mobile client is in sleep mode, trigger wake up');
                    _that.wakeUp();
                }

                UserProfileSvc.initSettings(stuff.settings);
                UserProfileSvc.setLocalUser(user, tenant, supportInfo);
                // $rootScope.localUser is either initialized or updated

                setClientCapabilities();

                setState(RegistrationState.Registered);

                // Cache the getStuff response
                _cachedStuff = stuff;

                // Publish the initial loaded stuff (excluding the user object).
                LogSvc.info('[RegistrationSvc]: Publish /registration/stuff event');
                PubSubSvc.publish('/registration/stuff', [stuff]);

                if (WebRTCAdapter.enabled && !Utils.isMobile()) {
                    // Check if we have access to a microphone
                    WebRTCAdapter.getMediaSources(function (audioSources) {
                        if (!audioSources || !audioSources.length) {
                            var options = {message: 'res_AccessToAudioInputDeviceFailed', manual: true};
                            PubSubSvc.publish('/infoMessage/show', [options]);
                        }
                    });
                }

                successCb && successCb($rootScope.localUser);
            })
            .catch(function (dbError) {
                onRegistrationError(errorCb, dbError);
            });
        }

        // Replaces getLoggedOn and all its subsequent calls to get the information needed at log on time
        function getStuff(successCb, errorCb) {
            var types = [
                Constants.GetStuffType.USER,
                Constants.GetStuffType.ACCOUNTS,
                Constants.GetStuffType.PRESENCE_STATE,
                Constants.GetStuffType.SETTINGS,
                Constants.GetStuffType.GLOBAL_PROPERTIES,
                Constants.GetStuffType.TENANT,
                Constants.GetStuffType.SUPPORT_INFO
            ];

            if (!_cachedStuff || !_cachedStuff.telephonyConvId) {
                types.push(Constants.GetStuffType.TELEPHONY_CONVERSATION_ID);
            }

            _clientApiHandler.getStuff(types, function (err, stuff) {
                $rootScope.$apply(function () {
                    if (err) {
                        onRegistrationError(errorCb, err);
                    } else {
                        processGetStuffResult(stuff, successCb, errorCb);
                    }
                });
            });
        }

        function validateSession() {
            LogSvc.debug('[RegistrationSvc]: Send GET /validateSession to validate authentication');

            var url = '/validateSession';
            return $http.get(url)
                .then(function () {
                    LogSvc.debug('[RegistrationSvc]: GET /validateSession was successful');
                    return true;
                })
                .catch(function (response) {
                    var status = response.status;
                    LogSvc.debug('[RegistrationSvc]: Failed to validate session. status =', status);
                    // Session is only considered invalid if return code is 401
                    return status !== 401;
                });
        }

        function getUserDataAndInit(url, successCb, errorCb, withCredentials, skipExpirationCheck) {
            setState(RegistrationState.Connecting);

            LogSvc.debug('[RegistrationSvc]: Send GET /data/userData request');
            $http.get(url, {withCredentials: withCredentials})
            .then(function (response) {
                var data = response.data;
                LogSvc.debug('[RegistrationSvc]: Received GET /data/userData response');
                if (_pendingDisconnect || _pendingSetupConnections) {
                    // There was either a disconnect or a new setupConnection request while we were waiting for the HTTP response
                    onRegistrationError(errorCb, _pendingDisconnect ? 'Disconnected' : 'New setupConnections pending');
                    return;
                }
                var remainingTime = data && data.remainingSessionTime;
                if (!skipExpirationCheck && remainingTime && remainingTime < SESSION_EXPIR_ANTICIPATION_TIME) {
                    LogSvc.warn('[RegistrationSvc]: Session expiration is approaching, force new sign in. Remaining time: ', remainingTime);
                    setState(RegistrationState.Disconnected);
                    errorCb('res_auth_SessionExpiring');
                    return;
                }

                setUserData(data);

                LogSvc.info('[RegistrationSvc]: Connecting to access server');

                _clientApiHandler.connect(function () {
                    $rootScope.$apply(function () {
                        if (_pendingDisconnect || _pendingSetupConnections) {
                            // There was either a disconnect or a new setupConnection request while we were waiting for the WS connection
                            onRegistrationError(errorCb, _pendingDisconnect ? 'Disconnected' : 'New setupConnections pending');
                            return;
                        }
                        LogSvc.info('[RegistrationSvc]: Successfully connected to access server. Get the logged on user.');
                        setState(RegistrationState.Connected);
                        getStuff(successCb, errorCb);
                    });
                }, function (err) {
                    // Check if session is still valid
                    validateSession()
                    .then(function (isValid) {
                        onRegistrationError(errorCb, isValid ? err : 'res_auth_InvalidCredentials');
                    });
                });
            })
            .catch(function (response) {
                var status = response.status;
                LogSvc.warn('[RegistrationSvc]: Failed to retrieve user data from server. status = ', status);

                setState(RegistrationState.Disconnected);
                if (errorCb) {
                    var resId;
                    switch (status) {
                    case 401:
                        resId = 'res_auth_InvalidCredentials';
                        break;
                    case 502:
                        resId = 'res_auth_ClosedConnection';
                        break;
                    // -1001 is the iOS NSError code for an HTTP request time out. This can happen for a number of
                    // reason, more likely because the server is unreachable from the network the device is
                    // using.
                    case -1001:
                        resId = 'res_auth_HttpTimeout';
                        break;
                    default:
                        resId = 'res_auth_InternalError';
                        break;
                    }
                    errorCb(resId);
                }
            });
        }

        function logout(reason) {
            LogSvc.info('[RegistrationSvc]: Logging Out');

            // Raise /registration/state event with state 'LoggingOut' so that the services can clean up the data
            LogSvc.info('[RegistrationSvc]: Publish /registration/state event. State = LoggingOut');
            PubSubSvc.publish('/registration/state', [RegistrationState.LoggingOut]);

            _voipNotificationsDisabled = false;

            var logoutCb = function () {
                // Disconnect websocket connections
                _clientApiHandler.disconnect();
                LogSvc.debug('[RegistrationSvc]: Publish /session/logout event - [' + reason + ']');
                PubSubSvc.publish('/session/logout', [reason]);
            };

            // Start 2s timer waiting for logout response. If no response after 2s, continue logging out anyway.
            var logoutPromise = $timeout(function () {
                LogSvc.debug('[RegistrationSvc]: Timed out waiting for logout response. Continue logging out.');
                logoutPromise = null;
                logoutCb();
            }, LOGOUT_TIMEOUT);

            _clientApiHandler.logout(function () {
                if (logoutPromise) {
                    $timeout.cancel(logoutPromise);
                    logoutCb();
                }
            });
        }

        function setClientCapabilities() {
            var capabilities = 0;
            /*eslint-disable no-bitwise*/
            if (Utils.isMobile()) {
                if (Utils.isIOS()) {
                    capabilities |= Constants.ClientCapability.MUTABLE_APN;
                    capabilities |= Constants.ClientCapability.IOS13_VOIP_APN;
                }
                if (_voipNotificationsDisabled) {
                    capabilities |= Constants.ClientCapability.DISABLE_VOIP_NOTIFICATIONS;
                }
            }

            var debug = Object.keys(Constants.ClientCapability).reduce(function (acc, key) {
                if (capabilities & Constants.ClientCapability[key]) { // NOSONAR
                    acc.push(key);
                }
                return acc;
            }, []);
            /*eslint-enable no-bitwise*/

            LogSvc.debug('[RegistrationSvc]: Set client capabilities = ', debug);
            _clientApiHandler.setClientCapabilities(capabilities);
        }

        function setupConnections(successCb, errorCb, host, clientInfo, skipExpirationCheck) {
            if (_regState === RegistrationState.Registered) {
                LogSvc.warn('[RegistrationSvc]: Client is already registered');
                successCb && successCb($rootScope.localUser);
                return;
            }
            _pendingDisconnect = false;
            if (_regState !== RegistrationState.Disconnected) {
                LogSvc.warn('[RegistrationSvc]: Current state is ' + _regState + '. Disconnect before processing new setupConnections request.');
                $timeout.cancel(_pendingSetupConnectionsTimeout);
                _pendingSetupConnectionsTimeout = null;
                _pendingSetupConnections = function () {
                    LogSvc.info('[RegistrationSvc]: Continue processing queued setupConnections request.');
                    setupConnections(successCb, errorCb, host, clientInfo, skipExpirationCheck);
                };
                if (_regState !== RegistrationState.Connecting) {
                    // Disconnect websocket connections
                    _clientApiHandler.disconnect();
                }
                return;
            }
            var url = '/data/userData';
            if (host) {
                url = 'https://' + host + url;
                circuit.__domain = 'https://' + host;
            } else {
                circuit.__domain = '';
            }
            // Set ClientApiHandler target.
            // If host is not provided, ClientApiHandler uses window.location.host
            _clientApiHandler.setTarget(host);
            _clientApiHandler.setClientInfo(clientInfo);
            getUserDataAndInit(url, successCb, errorCb, !!host, skipExpirationCheck);
        }
        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        _clientApiHandler.addEventListener('connectionStateChange', function (evt) {
            if (_regState === RegistrationState.Disconnected) {
                // Ignore the event
                return;
            }

            $rootScope.$apply(function () {
                LogSvc.debug('[RegistrationSvc]: Received connectionStateChange from ClientApiHandler. State = ', evt.newState);
                switch (evt.newState) {
                case ConnectionState.Disconnected:
                    setState(RegistrationState.Disconnected);
                    break;
                case ConnectionState.Reconnecting:
                    setState(RegistrationState.Reconnecting);
                    break;
                case ConnectionState.Connected:
                    if (_regState === RegistrationState.Reconnecting) {
                        LogSvc.debug('[RegistrationSvc]: Publish /application/updateCheck event');
                        PubSubSvc.publish('/application/updateCheck');

                        // The client has successfully reconnected the Client API websocket
                        // Refresh the client settings
                        var url = '/data/userData';
                        $http.get(url)
                        .then(function (response) {
                            // Since we got the user data we might as well update it
                            setUserData(response.data);
                        })
                        .catch(function (response) {
                            LogSvc.warn('[RegistrationSvc]: Failed to retrieve user data from server. status =', response.status);
                        });

                        // Retrieve the local user data again
                        getStuff();
                    }
                    break;
                }

            });
        });

        _clientApiHandler.addEventListener('reconnectFailed', function () {
            LogSvc.debug('[RegistrationSvc]: Received reconnectFailed from ClientApiHandler');

            validateSession()
            .then(function (isValid) {
                if (!isValid) {
                    // Unauthorized, redirect to login showing message with reason 'timeout'.
                    LogSvc.warn('[RegistrationSvc]: Client is unauthorized, redirect to login page with reason=timeout');
                    // Client must stop attempting to reconnect WebSocket
                    _clientApiHandler.disconnect();
                    LogSvc.debug('[RegistrationSvc]: Publish /session/expiry event');
                    PubSubSvc.publish('/session/expiry');
                }
            });
        });

        _clientApiHandler.on('User.SESSION_EXPIRES', function () {
            if (!$rootScope.localUser) {
                return;
            }
            $rootScope.$apply(function () {
                if (UserProfileSvc.getUserIdleState() === IdleState.Idle) {
                    // Redirect to login showing message with reason 'timeout'
                    LogSvc.debug('[RegistrationSvc]: Publish /session/expiry event');
                    PubSubSvc.publish('/session/expiry');
                } else {
                    _clientApiHandler.renewToken(function (err) {
                        if (err) {
                            LogSvc.debug('[RegistrationSvc]: Publish /session/renewTokenError event. err = ', err);
                            PubSubSvc.publish('/session/renewTokenError', [err]);
                        }
                    });
                }
            });
        });

        _clientApiHandler.on('User.SESSION_EXPIRING', function () {
            if (!$rootScope.localUser) {
                return;
            }
            $rootScope.$apply(function () {
                if (UserProfileSvc.getUserIdleState() === IdleState.Active) {
                    // Logout and redirect to login showing message with reason 'sessionExpiring'
                    LogSvc.debug('[RegistrationSvc]: Publish /session/logout event');
                    PubSubSvc.publish('/session/logout', 'sessionExpiring');
                } else {
                    // Notify user without interrupting any other task
                    var options = {message: 'res_auth_SessionExpiring', manual: true};
                    PubSubSvc.publish('/infoMessage/show', [options]);
                }
            });
        });

        _clientApiHandler.on('User.SESSION_CLOSED', function (evt) {
            $rootScope.$apply(function () {
                LogSvc.debug('[RegistrationSvc]: Received Session Closed event from backend. Close connections and exit. ');

                // Disconnect the WebSocket connections
                _clientApiHandler.disconnect();

                if (evt) {
                    switch (evt.reason) {
                    case Constants.SessionClosedReason.NEW_CONNECTION_DETECTED:
                        LogSvc.debug('[RegistrationSvc]: Publish /session/newConnectionDetected event');
                        PubSubSvc.publish('/session/newConnectionDetected');
                        return;
                    case Constants.SessionClosedReason.SUSPENDED:
                    case Constants.SessionClosedReason.TENANT_SUSPENDED:
                        LogSvc.debug('[RegistrationSvc]: Publish /session/suspended event');
                        PubSubSvc.publish('/session/suspended');
                        return;
                    case Constants.SessionClosedReason.DELETED:
                    case Constants.SessionClosedReason.TENANT_DELETED:
                        LogSvc.debug('[RegistrationSvc]: Publish /session/deleted event');
                        PubSubSvc.publish('/session/deleted');
                        return;
                    case Constants.SessionClosedReason.REVOKED:
                        LogSvc.debug('[RegistrationSvc]: Publish /session/logout event');
                        PubSubSvc.publish('/session/logout', 'sessionExpiring');
                        return;
                    }
                }
                // If there is no specific reason, raise a generic /session/closed event
                LogSvc.debug('[RegistrationSvc]: Publish /session/closed event');
                PubSubSvc.publish('/session/closed');
            });
        });

        _clientApiHandler.on('User.PASSWORD_CHANGED', function (evt) {
            if (evt && evt.logout) {
                $rootScope.$apply(function () {
                    // Redirect to login showing message with reason 'passwordChanged'
                    LogSvc.debug('[RegistrationSvc]: Publish /session/passwordChanged event');
                    PubSubSvc.publish('/session/passwordChanged');
                });
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        this.setupConnections = function (successCb, errorCb, host, clientInfo) {
            LogSvc.debug('[RegistrationSvc]: setupConnections - host = ', host || '(use default)');
            setupConnections(successCb, errorCb, host, clientInfo);
        };

        this.setupConnectionsV2 = function (data, successCb, errorCb) {
            LogSvc.debug('[RegistrationSvc]: setupConnectionsV2 - host = ', data.host || '(use default)');
            errorCb = errorCb || function () {};
            if (!data) {
                errorCb('Invalid input');
                return;
            }
            setupConnections(successCb, errorCb, data.host, data.clientInfo, data.skipExpiration);
        };

        this.hasAuthenticatedSession = function (data) {
            if (!data) {
                return $q.reject('No data provided');
            }
            var deferred = $q.defer();
            var host = data.host;
            var withCredentials = data.withCredentials;
            var isTenant = data.isTenant;
            var isInvite = data.isInvite;

            var url = '/data/userData';
            if (isTenant) {
                url = '/tenant' + url;
            }
            if (isInvite) {
                url = '/invite' + url;
            }
            if (host) {
                url = 'https://' + host + url;
            }
            if (withCredentials) {
                $http.ajax({
                    url: url,
                    dataType: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true
                }).then(function (respData) {
                    deferred.resolve(isTenant || isInvite ? respData && respData.data : true);
                }, function () {
                    deferred.resolve();
                });
            } else {
                $http.get(url)
                .then(function (respData) {
                    deferred.resolve(isTenant || isInvite ? respData && respData.data : true);
                })
                .catch(function () {
                    deferred.resolve();
                });
            }
            return deferred.promise;
        };

        this.logout = function (reason) {
            logout(reason);
        };

        this.reconnect = function () {
            LogSvc.info('[RegistrationSvc]: Reconnect');

            if (_regState === RegistrationState.Disconnected) {
                LogSvc.warn('[RegistrationSvc]: Cannot reconnect socket in Disconnected state. You must use setupConnections instead.');
                return false;
            }

            // Force a socket reconnect. This method will close the current socket and will
            // automatically attempt to reconnect it.
            _clientApiHandler.reconnect();
            return true;
        };

        this.disconnect = function () {
            LogSvc.info('[RegistrationSvc]: Disconnect');
            if (_pendingSetupConnections) {
                _pendingSetupConnections = null;
                $timeout.cancel(_pendingSetupConnectionsTimeout);
                _pendingSetupConnectionsTimeout = null;
            }
            if (_regState === RegistrationState.Disconnected) {
                LogSvc.debug('[RegistrationSvc]: Registration state is already Disconnected');
            } else if (_regState === RegistrationState.Connecting) {
                LogSvc.debug('[RegistrationSvc]: Registration state is Connecting. Set pendingDisconnect to true.');
                _pendingDisconnect = true;
            } else {
                // Disconnect websocket connections
                _clientApiHandler.disconnect();
            }
        };

        this.pingServer = function () {
            return _clientApiHandler.pingServer();
        };

        this.state = function () {
            return _regState;
        };

        this.isRegistered = function () {
            return _regState === RegistrationState.Registered;
        };

        this.getUserData = function () {
            return _userData || {};
        };

        this.getMaxUploadSize = function () {
            return DEFAULT_MAX_UPLOAD_SIZE;
        };

        this.getShowPresenceOnAddUser = function () {
            return _globalProperties.showPresenceOnAddUser;
        };

        this.getExchangeOnlineConfig = function () {
            return _globalProperties.exchangeOnlineConfig || {};
        };

        /**
         * Used by the mobile client when entering the background.
         * This function will inform the other services and invoke their
         * go2Sleep function.
         *
         * @function
         */
        this.go2Sleep = function () {
            if (_isMobileAsleep) {
                LogSvc.error('[RegistrationSvc]: Mobile client is already asleep');
                return;
            }
            _isMobileAsleep = true;
            if (_regState === RegistrationState.Registered) {
                _clientApiHandler.goToSleep($rootScope.localUser.userId);
            }
            LogSvc.info('[RegistrationSvc]: Mobile - entering the background');
            PubSubSvc.publish('/internal/svc/go2Sleep');
        };

        /**
         * Used by the mobile client when entering the foreground.
         *
         * @function
         */
        this.wakeUp = function () {
            if (!_isMobileAsleep) {
                LogSvc.error('[RegistrationSvc]: mobile client is not asleep');
                return;
            }

            _isMobileAsleep = false;
            _clientApiHandler.wakeUp();
            LogSvc.info('[RegistrationSvc]: Mobile - entering the foreground');
            PubSubSvc.publish('/internal/svc/wakeUp');
        };

        /**
         * Get the client settings retrieved from access server
         *
         * @function
         * @returns {Object} The client settings object
         */
        this.getClientSettings = function () {
            return _clientSettings || {};
        };

        /**
         * Verify if the backend supports the given capability.
         *
         * @function
         * @param {String} capability The capability to be verified
         * @returns {Boolean} Returns true if the given capability is supported
         */
        this.checkClientSettingsCapability = function (capability) {
            if (!_clientSettings || !Array.isArray(_clientSettings.capabilities)) {
                return false;
            }
            return _clientSettings.capabilities.indexOf(capability) !== -1;
        };

        /**
         * Return Chrome extension ID configured in Global Properties.
         *
         * @returns {String} Chrome Extension ID
         */
        this.getChromeExtensionId = function () {
            return _globalProperties.chromeExtensionId;
        };

        /**
         * Return Edge extension ID configured in Global Properties.
         *
         * @returns {String} Edge Extension ID
         */
        this.getEdgeExtensionId = function () {
            return _globalProperties.edgeExtensionId;
        };

        /**
         * Get regions for GUEST client before switching to JsEngine to guest mode
         *
         * @function
         * @param {String} token to be verified before returning regions
         * @returns {promise List<RegionInfo>} Returns Promise with list of regions or null
         */
        this.getRegions = function (token) {
            LogSvc.warn('[RegistrationSvc]: getRegions started token: ', token);
            return new $q(function (resolve) {
                _clientApiHandler.getRegions(token, function (err, data) {
                    $rootScope.$apply(function () {
                        if (err) {
                            LogSvc.warn('[RegistrationSvc]: getRegions failed. ', err);
                            resolve(null);
                        } else {
                            var result = (data && data.regions) || null;
                            LogSvc.info('[RegistrationSvc]: getRegions succeeded. result = ', result);
                            resolve(result);
                        }
                    });
                });
            });
        };

        if (Utils.isMobile()) {
            this.disableVoipNotifications = function () {
                LogSvc.info('[RegistrationSvc]: VoIP notifications have been disabled');
                _voipNotificationsDisabled = true;
                setClientCapabilities();
            };

            this.enableVoipNotifications = function () {
                LogSvc.info('[RegistrationSvc]: VoIP notifications have been enabled');
                _voipNotificationsDisabled = false;
                setClientCapabilities();
            };
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.RegistrationSvcImpl = RegistrationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
