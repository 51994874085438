var Circuit = (function (circuit) {
    'use strict';

    circuit.ExtensionConnHandlerSingleton = (function () {
        var _extensionConnHandler;

        return {
            getInstance: function () {
                if (!_extensionConnHandler) {
                    _extensionConnHandler = new circuit.ExtensionConnHandler();
                }
                return _extensionConnHandler;
            }
        };
    })();

    return circuit;
})(Circuit || {}); //eslint-disable-line no-use-before-define
