var Circuit = (function (circuit) {
    'use strict';

    // eslint-disable-next-line max-params, max-lines-per-function
    function FileUploadSvcImpl($rootScope, $http, $q, LogSvc) { // NOSONAR

        LogSvc.debug('New Service: FileUploadSvc');

        // Imports
        // Keep it inside service, so unit tests can mock this modules
        var FileUpload = circuit.FileUpload;
        var Utils = circuit.Utils;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////

        function buildUploadData(userId, avatars) {
            return ['b', 's'].reduce(function (res, size) {
                var b64data = avatars[size];
                if (b64data === '') {
                    return res;
                }
                var avatarImgFormat = Utils.getAvatarImageFormat(b64data);
                var type = 'image/' + avatarImgFormat;
                var name = userId + '_' + size + '.' + avatarImgFormat;

                var data = { b64Data: b64data, mimeType: type, name: name };
                var isSmall = size === 's';
                if (isSmall) {
                    res.small = data;
                } else {
                    res.large = data;
                }
                return res;
            }, {});
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.uploadAvatars = function (avatars) {
            // Upload the s/b avatar images, delete old images.
            var userId = $rootScope.localUser.userId;
            var url = circuit.__domain + '/fileapi';

            var uploadSizes = ['b', 's'].map(function (size) {
                var deferred = $q.defer();
                var b64data = avatars[size];
                if (b64data === '') {
                    deferred.resolve([{fileId: ''}, {fileId: ''}]);
                    return deferred.promise;
                }
                var avatarImgFormat = Utils.getAvatarImageFormat(b64data);
                var blob = FileUpload.dataURItoBlob(b64data, 'image/' + avatarImgFormat);

                blob.name = userId + '_' + size + '.' + avatarImgFormat;
                if (size === 's') {
                    blob.isSmall = true;
                }
                LogSvc.debug('[FileUploadSvc]: Uploading profile picture -', blob.name);

                $http.post(url, blob, {
                    headers: {
                        'Content-Type': 'image/' + avatarImgFormat,
                        'Content-Disposition': 'attachment; filename="' + blob.name + '"'
                    },
                    transformRequest: angular.identity
                })
                .then(function (res) {
                    var id = res.data[0].id;
                    var uploadedFile = {
                        fileId: id,
                        url: url + '/' + blob.name + '?fileid=' + id,
                        deleteUrl: url + '?fileid=' + id,
                        fileName: blob.name,
                        mimeType: blob.type,
                        size: blob.size,
                        isSmall: blob.isSmall,
                        isImage: true
                    };
                    LogSvc.debug('[FileUploadSvc]: Uploaded profile picture -', uploadedFile);
                    deferred.resolve(uploadedFile);
                })
                .catch(function (res) {
                    LogSvc.error('[FileUploadSvc]: Upload failed - ' + blob.name + ' , status =' + res.status + ', error =', res.data);
                    deferred.reject(res.data);
                });
                return deferred.promise;
            });

            return $q.all(uploadSizes).then(function (data) {
                return {
                    b: data[0],
                    s: data[1]
                };
            });
        };

        // Uploads data as json/base64 strings
        this.uploadAvatarsV2 = function (avatars) {
            var deferred = $q.defer();
            avatars = avatars || {};
            // Upload the s/b avatar images, delete old images.
            var userId = $rootScope.localUser.userId;
            var url = circuit.__domain + '/fileApi/avatars';
            var uploadData = buildUploadData(userId, avatars);
            var options = {
                headers: { 'Content-Type': 'application/json' }
            };
            $http.post(url, uploadData, options)
            .then(function (res) {
                deferred.resolve({
                    b: res.data.large,
                    s: res.data.small
                });
            })
            .catch(function (res) {
                LogSvc.error('[FileUploadSvc]: Upload failed, status = ' + res.status + ', error =', res.data);
                deferred.reject(res.data);
            });

            return deferred.promise;
        };
        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.FileUploadSvcImpl = FileUploadSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
