
var Circuit = (function (circuit) {
    'use strict';

    ///////////////////////////////////////////////////////////////////////////////////////
    // FileApiSvc Implementation
    // Service to upload/delete files via fileapi
    ///////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line max-params, max-lines-per-function
    function FileApiSvcImpl($http, $q, LogSvc) { // NOSONAR
        LogSvc.debug('New Service: FileApiSvcImpl');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////

        /**
         * Remove file using fileapi.
         *
         * @function
         * @returns {Object} Promise object.
         */
        this.deleteFile = function (fileId) {
            var deferred = $q.defer();
            if (!fileId) {
                return deferred.reject('missing parameters');
            }

            LogSvc.debug('[FileApiSvc]: Deleting file ', fileId);

            var deleteUrl = circuit.__domain + '/fileapi?fileid=' + fileId;

            $http.delete(deleteUrl)
            .then(function () {
                LogSvc.debug('[FileApiSvc]: File was successfully deleted - fileId = ', fileId);
                deferred.resolve();
            })
            .catch(function (res) {
                LogSvc.error('[FileApiSvc]: Failed to delete file, fileId = ' + fileId + '. ', res && res.status);
                deferred.reject(res.status);
            });
            return deferred.promise;
        };

        /**
         * Upload blob using fileapi.
         *
         * @function
         * @returns {Object} Promise object resolving a file ID.
         */
        this.uploadBlob = function (fileName, blob) {
            if (!fileName || !blob) {
                LogSvc.warn('[FileApiSvc]: uploadBlob - Missing filename or file');
                return $q.reject('Missing file parameter');
            } else if (blob.size <= 0) {
                LogSvc.warn('[FileApiSvc]: uploadBlob - Empty file');
                return $q.reject('The file is empty');
            }

            var deferred = $q.defer();
            LogSvc.debug('[FileApiSvc]: Uploading file (blob) ', {name: fileName, type: blob.type, size: blob.size});

            $http.post(circuit.__domain + '/fileapi', blob, {
                headers: {
                    'Content-Type': blob.type,
                    'Content-Disposition': 'attachment; filename="' + fileName + '"'
                },
                transformRequest: angular.identity
            })
            .then(function (res) {
                var id = res.data[0].id;
                if (id) {
                    LogSvc.debug('[FileApiSvc]: Uploaded file - fileId = ', id);
                    deferred.resolve(id);
                } else {
                    deferred.reject('Upload failed. Missing file ID.');
                }
            })
            .catch(function (res) {
                LogSvc.error('[FileApiSvc]: Upload failed for ' + fileName + ' - error = ' + res.status + ' ' + res.statusText);
                deferred.reject(res.status);
            });
            return deferred.promise;
        };

        /**
         * Upload music on hold file to CMP-Next server
         *
         * @function
         * @returns {Object} Promise object resolving an interceptId
         */
        this.uploadMohFile = function (blob, shortTenantId) {
            return new $q(function (resolve, reject) {
                if (!blob || !shortTenantId) {
                    reject('Missing required parameters.');
                } else {
                    LogSvc.debug('[FileApiSvc]: Uploading music on hold file to CMP-Next. ', {type: blob.type, size: blob.size});
                    $http.post(circuit.__domain + '/cmpnext', blob, {
                        headers: {
                            'Content-Type': blob.type,
                            'Content-Disposition': 'attachment',
                            'X-CmpNext': 'mlhg-music-on-hold',
                            'X-CmpNext-TenantId': shortTenantId
                        },
                        transformRequest: angular.identity
                    })
                    .then(function (res) {
                        var interceptId = res.data;
                        if (interceptId) {
                            LogSvc.debug('[FileApiSvc]: Successfully uploaded music on hold and fetched interceptId = ', interceptId);
                            resolve(interceptId);
                        } else {
                            reject('Upload of music on hold file failed. Missing interceptId');
                        }
                    })
                    .catch(function (res) {
                        LogSvc.error('[FileApiSvc]: Upload of music on hold file failed. ', res.status + ' ' + res.statusText);
                        reject(res.status);
                    });
                }
            });
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////

        return this;
    }

    // Exports
    circuit.FileApiSvcImpl = FileApiSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
