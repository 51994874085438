var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var Constants = circuit.Constants;

    // eslint-disable-next-line max-params, max-lines-per-function
    function MeetingSvcImpl($q, $timeout, LogSvc, PubSubSvc, MailboxConnSvc, ConversationSvc, CallControlSvc) { // NOSONAR
        LogSvc.debug('New Service: MeetingSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Constants
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_CONV_AVATAR = 'content/images/icon-general-emptyconvo-avatar';

        var ONE_MIN = 60000;
        // ANS-76595 Google Connector gets disconnected randomly.
        // Setting to 31 minutes to avoid requests to be sent to the calendar servers on a hourly pace
        // Google Calendar authentication tokens expire every hour
        // Sending requests close to the expire time makes the Google Calendar API
        // to respond randomly with authentication error
        var UPDATE_PERIOD = 31 * ONE_MIN; // update meetings every 31 minutes
        var MAX_CHECK_TIMEOUT = 5 * ONE_MIN;

        var DELTA_BEFORE_MEETING_STARTS = 15 * ONE_MIN;
        var DELTA_AFTER_MEETING_STARTS = 10 * ONE_MIN;

        var GUEST_REGEXP = /\/guest\?token=([^&]+)/;
        var CONV_REGEXP = /\/#\/conversation\/([^/?]+)/;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var _hasMeetingAboutToStart = false;
        var _meetings = [];

        var _lastUpdate = 0;
        var _checkMeetingsTimeout = null;
        var _resyncTimeout = null;

        var _retrieveInProgress = false;
        var _pendingCallbacks = [];
        var _self = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function cancelCheckMeetingsTimeout() {
            if (_checkMeetingsTimeout) {
                $timeout.cancel(_checkMeetingsTimeout);
                _checkMeetingsTimeout = null;
            }
        }

        function cancelResyncTimeout() {
            if (_resyncTimeout) {
                $timeout.cancel(_resyncTimeout);
                _resyncTimeout = null;
            }
        }

        function resetMeetings() {
            cancelCheckMeetingsTimeout();
            cancelResyncTimeout();

            _meetings = [];
            _hasMeetingAboutToStart = false;
            _lastUpdate = 0;
        }

        function removeFinishedMeetings(initialCheck) {
            var now = Date.now();

            var numPreviousMeetings = _meetings.length;
            _meetings = _meetings.filter(function (meeting) {
                return meeting.endTime > now;
            });
            if (!initialCheck && _meetings.length < numPreviousMeetings) {
                LogSvc.debug('[MeetingSvc]: Removed finished meetings. Raise /meetings/updated event. meetings.length = ', _meetings.length);
                PubSubSvc.publish('/meetings/updated', [_meetings]);
            }
        }

        function checkMeetings(initialCheck) {
            cancelCheckMeetingsTimeout();

            // First remove meetings that have already finished
            removeFinishedMeetings(initialCheck);

            // Now check for meetings about to start
            if (_meetings.length === 0) {
                _hasMeetingAboutToStart = false;
                return;
            }

            var options = {
                startNotification: false,
                maxCheckTimeout: MAX_CHECK_TIMEOUT,
                deltaBeforeMeetingStarts: DELTA_BEFORE_MEETING_STARTS,
                deltaAfterMeetingStarts: DELTA_AFTER_MEETING_STARTS
            };
            var nextCheckDelay = _self.getNextMeetingCheckDelay(_meetings, options);

            if (_hasMeetingAboutToStart !== options.startNotification) {
                _hasMeetingAboutToStart = options.startNotification;
                LogSvc.debug('[MeetingSvc]: Raise /meeting/toggleStartNotification event. hasMeetingAboutToStart = ', _hasMeetingAboutToStart);
                PubSubSvc.publish('/meeting/toggleStartNotification', [_hasMeetingAboutToStart]);
            }

            LogSvc.debug('[MeetingSvc]: Check conversations again in ' + Math.floor(nextCheckDelay / 1000) + ' seconds.');

            _checkMeetingsTimeout = $timeout(function () {
                _checkMeetingsTimeout = null;
                checkMeetings(false);
            }, nextCheckDelay);
        }

        function processMeetingUrl(meetingUrl) {
            return new $q(function (resolve, reject) {
                LogSvc.debug('[MeetingSvc]: Process meeting URL: ', meetingUrl);

                var match = GUEST_REGEXP.exec(meetingUrl);
                var token = match && match[1];
                if (token) {
                    // This is a guest link
                    ConversationSvc.validateSessionInviteToken(token, function (err, res) {
                        // Do not show meetings with invalid links.
                        if (err || res.validateResult === Constants.SessionInviteInfoResult.INVALID) {
                            reject('Failed to validate token');
                            return;
                        }
                        resolve({
                            conv: {
                                convId: res.convId,
                                topic: res.convTopic,
                                avatar: {
                                    avatar: DEFAULT_CONV_AVATAR + '.png',
                                    avatarLarge: DEFAULT_CONV_AVATAR + '-XL.png',
                                    hasAvatarPicture: false
                                }
                            },
                            convId: res.convId,
                            isGuest: true,
                            guestToken: token
                        });
                    });
                    return;
                }

                match = CONV_REGEXP.exec(meetingUrl);
                var convId = match && match[1];
                if (convId) {
                    resolve({convId: convId});
                    return;
                }

                reject('Invalid meeting URL');
            });
        }

        function getMeetingConversation(convSummary) {
            return ConversationSvc.getConversationPromise(convSummary.convId)
                .then(function (conv) {
                    convSummary.conv = conv;
                    convSummary.isGuest = !conv.hasJoined;
                    return $q.resolve(convSummary);
                })
                .catch(function () {
                    // If this is a guest conversation, just return the conversation summary
                    return convSummary.isGuest ? $q.resolve(convSummary) : $q.reject('Cannot access conversation');
                });
        }

        function invokePendingCbs() {
            _pendingCallbacks.forEach(function (cb) {
                cb && cb(_meetings);
            });
            _pendingCallbacks = [];
        }

        function resyncMeetings() {
            cancelResyncTimeout();

            if (CallControlSvc.getActiveCall()) {
                // Skip resync to reduce freezes seen with SOAP requests while on a call
                LogSvc.info('[MeetingSvc]: User is in local call. Skip resync of meetings.');
                // Restart the timeout
                _resyncTimeout = $timeout(function () {
                    _resyncTimeout = null;
                    resyncMeetings();
                }, UPDATE_PERIOD);
            } else {
                getMeetings();
            }
        }

        function getMeetings(cb) {
            if (!MailboxConnSvc.supportsGetAppointments()) {
                cb && cb(null);
                return;
            }

            cb && _pendingCallbacks.push(cb);

            if (_retrieveInProgress) {
                return;
            }

            LogSvc.info('[MeetingSvc]: Refresh meetings list');

            _retrieveInProgress = true;

            cancelCheckMeetingsTimeout();
            cancelResyncTimeout();

            _resyncTimeout = $timeout(function () {
                _resyncTimeout = null;
                resyncMeetings();
            }, UPDATE_PERIOD);

            // Get all incoming meetings for today
            // The second param (endTime) is 0 - to get meetings for today. The third (count) - to get all meetings.
            MailboxConnSvc.getAppointments(Date.now(), 0, 0, function (getAppointmentsErr, meetingsArr) {
                if (getAppointmentsErr) {
                    _retrieveInProgress = false;
                    _meetings = [];
                    invokePendingCbs();
                    LogSvc.debug('[MeetingSvc]: Failed to get appointments, show empty state.');
                    PubSubSvc.publish('/meetings/updated', [_meetings]);
                    return;
                }

                var promises = meetingsArr.map(function (meeting) {
                    // Verify if we have already processed this meeting
                    var existingMeeting = _meetings.find(function (m) {
                        return meeting.itemId === m.itemId;
                    });

                    if (existingMeeting && existingMeeting.conversationLink === meeting.conversationLink) {
                        // If the start time hasn't changed, return the existing object
                        if (existingMeeting.startTime === meeting.startTime && existingMeeting.endTime === meeting.endTime) {
                            return $q.resolve(existingMeeting);
                        }
                        // Update the new meeting with the existing conversation data
                        meeting.conv = existingMeeting.conv;
                        meeting.convId = existingMeeting.convId;
                        meeting.isGuest = existingMeeting.isGuest;
                        meeting.guestToken = existingMeeting.guestToken;
                        meeting.keysToOmitFromLogging = existingMeeting.keysToOmitFromLogging;
                        return $q.resolve(meeting);
                    }

                    return processMeetingUrl(meeting.conversationLink)
                        .then(getMeetingConversation)
                        .then(function (convSummary) {
                            meeting.conv = convSummary.conv;
                            meeting.convId = convSummary.convId;
                            meeting.isGuest = convSummary.isGuest;
                            meeting.guestToken = convSummary.guestToken;

                            // Do not log the full conversation object
                            meeting.keysToOmitFromLogging = ['subject', 'location', 'conv'];

                            LogSvc.debug('[MeetingSvc]: Add meeting data to global panel: ', meeting);
                            return meeting;
                        })
                        .catch(function (err) {
                            LogSvc.warn('[MeetingSvc]: Failed to validate ' + meeting.conversationLink + '. ', err);
                            return null;
                        });
                });

                // Sort and show all meetings after get all meetings info.
                $q.all(promises).then(function (results) {
                    _retrieveInProgress = false;

                    _meetings = results.filter(function (meeting) {
                        return !!meeting && (!meeting.isGuest || !!meeting.guestToken);
                    }).sort(function (first, second) {
                        return first.startTime - second.startTime;
                    });

                    checkMeetings(true);

                    _lastUpdate = Date.now();

                    LogSvc.debug('[MeetingSvc]: Finished processing meeting URLs. Found ' + _meetings.length + ' Circuit meetings for today.');
                    invokePendingCbs();

                    LogSvc.debug('[MeetingSvc]: Raise /meetings/updated event');
                    PubSubSvc.publish('/meetings/updated', [_meetings]);
                });
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribe('/mailbox/connected', function () {
            LogSvc.debug('[MeetingSvc]: Received /mailbox/connected event');
            getMeetings();
        });

        PubSubSvc.subscribe('/mailbox/disconnected', function () {
            LogSvc.debug('[MeetingSvc]: Received /mailbox/disconnected event');
            resetMeetings();
        });

        PubSubSvc.subscribe('/conversation/update', function (conv) {
            if (conv.isTemporary || _meetings.length === 0) {
                // Don't process events if there are no meetings OR for temporary conversation
                return;
            }
            LogSvc.debug('[MeetingSvc]: Received /conversation/update event');

            var hasChanges = false;
            var numPreviousMeetings = _meetings.length;
            _meetings = _meetings.filter(function (meeting) {
                if (meeting.convId === conv.convId && meeting.conv !== conv) {
                    meeting.conv = conv;
                    meeting.isGuest = !conv.hasJoined;
                    hasChanges = true;
                }
                return !meeting.isGuest || !!meeting.guestToken;
            });

            if (_meetings.length < numPreviousMeetings) {
                hasChanges = true;
                checkMeetings(true);
            }

            if (hasChanges) {
                LogSvc.debug('[MeetingSvc]: Raise /meetings/updated event. Number of meetings = ', _meetings.length);
                PubSubSvc.publish('/meetings/updated', [_meetings]);
            }
        });

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.supportsMeetings = MailboxConnSvc.supportsGetAppointments.bind(MailboxConnSvc);

        this.getMeetings = function (cb) {
            if (Date.now() - _lastUpdate < ONE_MIN) {
                // We have just updated the meetings
                cb && cb(_meetings);
                return;
            }
            getMeetings(cb);
        };

        this.hasMeetingAboutToStart = function () {
            return _hasMeetingAboutToStart;
        };

        this.getCachedMeetings = function () {
            return _meetings;
        };

        this.getNextMeetingCheckDelay = function (meetings, options) {
            if (Array.isArray(meetings)) {
                var now = Date.now();
                var nextCheckTime = now + options.maxCheckTimeout;
                meetings.forEach(function (meeting) {
                    var beforeTime = meeting.startTime - options.deltaBeforeMeetingStarts;
                    var afterTime = Math.min(meeting.startTime + options.deltaAfterMeetingStarts, meeting.endTime);

                    if (!options.startNotification) {
                        // Check if we need to present the meeting about to start notification
                        options.startNotification = now >= beforeTime && now < afterTime;
                    }

                    if (beforeTime > now) {
                        nextCheckTime = Math.min(nextCheckTime, beforeTime);
                    } else if (afterTime > now) {
                        nextCheckTime = Math.min(nextCheckTime, afterTime);
                    } else if (meeting.endTime > now) {
                        nextCheckTime = Math.min(nextCheckTime, meeting.endTime);
                    }
                });
                return nextCheckTime - now;
            } else {
                return options.maxCheckTimeout;
            }
        };

        this.getMeetingStatus = function (meetings) {
            var aggregateMeeting;
            if (Array.isArray(meetings)) {
                var now = Date.now();
                meetings.forEach(function (meeting) {
                    if (meeting.busyType && meeting.busyType.match(/busy/i)) {
                        var startTime = meeting.startTime && new Date(meeting.startTime).getTime();
                        var endTime = meeting.endTime && new Date(meeting.endTime).getTime();
                        if (!aggregateMeeting && startTime && endTime && now >= startTime && now <= endTime) {
                            aggregateMeeting = {
                                startTime: meeting.startTime,
                                endTime: meeting.endTime
                            };
                        }
                        if (aggregateMeeting && meeting.startTime <= aggregateMeeting.endTime && meeting.endTime > aggregateMeeting.endTime) {
                            aggregateMeeting.endTime = meeting.endTime;
                        }
                    }
                });
            }
            return aggregateMeeting;
        };
    }

    // Exports
    circuit.MeetingSvcImpl = MeetingSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
