/*global Plantronics*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var CstaCallState = circuit.Enums.CstaCallState;
    var Constants = circuit.Constants;

    // eslint-disable-next-line max-params, max-lines-per-function
    function PlantronicsControlSvcImpl($rootScope, $interval, $timeout, $q, LogSvc, PubSubSvc, CallControlSvc, LocalStoreSvc) { // NOSONAR
        LogSvc.debug('New Service: PlantronicsControlSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var DEFAULT_SPOKES_URL = 'https://127.0.0.1:32018/Spokes';
        var PLUGIN_NAME = 'UnifyPhone';
        var POLLING_INTERVAL = 1000; // 1 second
        var HEADSET_TYPE = 'plantronics';
        var MAX_NUM_FAILED_REQ = 3; // Only consider disconnected if 3 consecutive requests failed (we don't monitor spacing between failed requests)

        var POLLING_TIMEOUT_CONNECTED = 600000; // 10 minutes
        var MIN_POLLING_TIMEOUT_DISCONNECTED = 30000; // 30 seconds
        var MAX_POLLING_TIMEOUT_DISCONNECTED = 300000; // 5 minutes

        var _spokes = null;
        var _connected = false;
        var _connectionError = false;
        var _alertingCall = null;    // The alerting call object
        var _activeCall = null;      // Active call object
        var _callIdHash = {};        // A hash to map from Circuit Call Id to SpokesCallId
        var _nextSpokesCallId = 1;
        var _pollingInterval = null;
        var _failedReqCounter = 0;
        var _connectionPollingTimer = null;
        var _connectionRetries = 0;
        var _headsetMuted = null;            // Keep a local muted status of the headset to avoid race conditions
        var _integrationEnabled = false;
        var _delayedMute = {};
        var _that = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function init() {
            _integrationEnabled = getEnabledIntegrationTypes().indexOf(HEADSET_TYPE) >= 0;
            if (_integrationEnabled) {
                _that.connect(true)
                .catch(function () {
                    LogSvc.warn('[PlantronicsControlSvc]: Failed to connect. Start connection polling to keep retrying.');
                    startConnectionPolling();
                });
            }
        }

        function getEnabledIntegrationTypes() {
            return LocalStoreSvc.getObjectSync(LocalStoreSvc.keys.HEADSET_TYPES_ENABLED) || [];
        }

        function saveIntegration(enabled) {
            var types = getEnabledIntegrationTypes();
            var index = types.indexOf(HEADSET_TYPE);
            if (index >= 0) {
                if (!enabled) {
                    types.splice(index, 1);
                }
            } else if (enabled) {
                types.push(HEADSET_TYPE);
            } else {
                return;
            }
            LocalStoreSvc.setObjectSync(LocalStoreSvc.keys.HEADSET_TYPES_ENABLED, types);
            _integrationEnabled = enabled;
        }

        function unregisterPlugin() {
            return new $q(function (resolve) {
                if (!_connected) {
                    LogSvc.debug('[PlantronicsControlSvc]: Circuit plugin is not registered');
                    resolve();
                    return;
                }
                clearAllDeviceCalls();
                stopEventPolling();

                LogSvc.debug('[PlantronicsControlSvc]: Unregister Circuit plugin');
                _spokes.Plugin.unRegister(PLUGIN_NAME, function (res) {
                    if (res.isError) {
                        LogSvc.warn('[PlantronicsControlSvc]: Failed to unregister Circuit plugin. ', res.Err.Description);
                    } else {
                        LogSvc.info('[PlantronicsControlSvc]: Unregistered Circuit plugin');
                    }
                    // Always resolve the promise
                    resolve();
                });

                // Change the state immediately
                _connected = false;
            });
        }

        function registerPlugin() {
            return new $q(function (resolve, reject) {
                if (_connected) {
                    LogSvc.debug('[PlantronicsControlSvc]: Circuit plugin is already registered');
                    resolve();
                    return;
                }

                LogSvc.debug('[PlantronicsControlSvc]: Register Circuit plugin');

                _connectionError = false;
                _spokes.Plugin.register(PLUGIN_NAME, function (registerRes) {
                    if (registerRes.isError && registerRes.Err.Description !== 'Plugin exists') {
                        $rootScope.$apply(function () {
                            _connectionError = true;
                            LogSvc.warn('[PlantronicsControlSvc]: Failed to register plugin. ', registerRes.Err.Description);
                            reject(registerRes.Err.Description);
                        });
                        return;
                    }
                    LogSvc.debug('[PlantronicsControlSvc]: Circuit plugin is registered. Make sure it is active.');
                    _connected = true;

                    // Make sure the plug-in is active
                    _spokes.Plugin.isActive(PLUGIN_NAME, true, function (isActiveRes) {
                        if (isActiveRes.isError || !isActiveRes.Result) {
                            $rootScope.$apply(function () {
                                LogSvc.warn('[PlantronicsControlSvc]: Circuit plugin is not active. Unregister it.');
                                _connectionError = true;
                                unregisterPlugin()
                                .then(function () {
                                    reject(isActiveRes.isError ? isActiveRes.Err.Description : 'Plugin is not active');
                                });
                            });
                            return;
                        }

                        LogSvc.debug('[PlantronicsControlSvc]: Set Circuit as default softphone.');
                        _spokes.UserPreference.setDefaultSoftphone(PLUGIN_NAME, function (setDefaultRes) {
                            $rootScope.$apply(function () {
                                if (setDefaultRes.isError) {
                                    LogSvc.warn('[PlantronicsControlSvc]: Could not set Circuit as preferred softphone. Unregister it.');
                                    _connectionError = true;
                                    unregisterPlugin()
                                    .then(function () {
                                        reject(setDefaultRes.Err.Description);
                                    });
                                } else {
                                    LogSvc.info('[PlantronicsControlSvc]: Successfully registered Circuit plugin');
                                    _connectionError = false;
                                    _connected = true;
                                    _connectionRetries = 0;
                                    resolve();
                                }
                            });
                        });
                    });
                });
            });
        }

        function clearAlertingCallId() {
            if (_alertingCall) {
                if (!_alertingCall.sameAs(_activeCall)) {
                    delete _callIdHash[_alertingCall.callId];
                }
                _alertingCall = null;
            }
        }

        function clearActiveCallId() {
            if (_activeCall) {
                if (!_alertingCall || !_activeCall.sameAs(_alertingCall)) {
                    delete _callIdHash[_activeCall.callId];
                }
                _activeCall = null;
            }
        }

        function setAlertingCall(call) {
            clearAlertingCallId();
            _alertingCall = call;
        }

        function setActiveCall(call) {
            LogSvc.debug('[PlantronicsControlSvc]: Set active call to ', call && call.callId);
            _activeCall = call;
        }

        function processCallEvents(callEvents) {
            if (!callEvents || !callEvents.length) {
                return;
            }

            LogSvc.debug('[PlantronicsControlSvc]: Received call events: ', callEvents);

            // Get the Spokes call Ids
            var alertingId = _alertingCall && _callIdHash[_alertingCall.callId];
            var activeId = _activeCall && _callIdHash[_activeCall.callId];

            // Only process the last mute (ON or OFF) event for the active call
            var hasMuteEvt = false;
            for (var i = callEvents.length - 1; i >= 0; i--) {
                var evtId = callEvents[i].Action;
                var callId = callEvents[i].CallId.Id;
                if ((evtId === Plantronics.SessionCallState.MuteON || evtId === Plantronics.SessionCallState.MuteOFF) && activeId === callId) {
                    if (hasMuteEvt) {
                        callEvents.splice(i, 1); // There's another mute event already in the queue, so discard it
                    } else {
                        hasMuteEvt = true;
                    }
                }
            }

            callEvents.forEach(function (evt) {
                var callStateName = Plantronics.SessionCallState.Lookup[evt.Action] || ('(' + evt.Action + ')');
                var id = '' + (evt.CallId && evt.CallId.Id);

                LogSvc.info('[PlantronicsControlSvc]: Processing call event with action ' + callStateName + ' for callId = ' + id);

                var isAlerting = alertingId && alertingId.Id === id;
                var isActive = activeId && activeId.Id === id;

                switch (evt.Action) {
                case Plantronics.SessionCallState.AcceptCall:
                    if (isAlerting) {
                        LogSvc.info('[PlantronicsControlSvc]: Answer alerting call');
                        CallControlSvc.answerCall(_alertingCall.callId, {audio: true});
                    }
                    break;
                case Plantronics.SessionCallState.TerminateCall:
                    if (isActive) {
                        LogSvc.info('[PlantronicsControlSvc]: End active call');
                        _activeCall && CallControlSvc.endCall(_activeCall.callId);
                    }
                    break;
                case Plantronics.SessionCallState.RejectCall:
                    if (isAlerting) {
                        LogSvc.info('[PlantronicsControlSvc]: Decline alerting call');
                        CallControlSvc.endCall(_alertingCall.callId);
                    }
                    break;
                case Plantronics.SessionCallState.MuteON:
                    if (isActive) {
                        if (!_headsetMuted) {
                            LogSvc.info('[PlantronicsControlSvc]: Mute active call');
                            _headsetMuted = true;
                            _activeCall && CallControlSvc.mute(_activeCall.callId);
                        } else {
                            LogSvc.debug('[PlantronicsControlSvc]: MuteON call event discarded');
                        }
                    }
                    break;
                case Plantronics.SessionCallState.MuteOFF:
                    if (isActive) {
                        if (_headsetMuted) {
                            LogSvc.info('[PlantronicsControlSvc]: Unmute active call');
                            _headsetMuted = false;
                            _activeCall && CallControlSvc.unmute(_activeCall.callId);
                        } else {
                            LogSvc.debug('[PlantronicsControlSvc]: MuteOFF call event discarded');
                        }
                    }
                    break;
                }
            });
        }

        function stopEventPolling() {
            $interval.cancel(_pollingInterval);
            _pollingInterval = null;
        }

        function startEventPolling() {
            if (!_pollingInterval) {
                _pollingInterval = $interval(function () {
                    if (!_alertingCall && !_activeCall) {
                        LogSvc.debug('[PlantronicsControlSvc]: There are no local calls. Stop polling.');
                        stopEventPolling();
                        return;
                    }

                    _spokes.Plugin.callEvents(PLUGIN_NAME, function (res) {
                        if (res.isError) {
                            LogSvc.warn('[PlantronicsControlSvc]: Failed to poll call events. ', res.Err.Description);
                            _failedReqCounter++;
                            if (_failedReqCounter >= MAX_NUM_FAILED_REQ) {
                                LogSvc.warn('[PlantronicsControlSvc]: Multiple requests failed in a row. Change state to disconnected.');
                                _connectionError = true;
                                unregisterPlugin()
                                .then(function () {
                                    cancelConnectionPolling();
                                    startConnectionPolling();
                                });
                            }
                            return;
                        }
                        _failedReqCounter = 0;
                        processCallEvents(res.Result);
                    }, true);

                }, POLLING_INTERVAL, 0, false);
            }
        }

        function startConnectionPolling() {
            if (_connectionPollingTimer) {
                // Timer already running
                return;
            }
            var timeout;
            if (_connected) {
                _connectionRetries = 0;
                timeout = _failedReqCounter === 0 ? POLLING_TIMEOUT_CONNECTED : MIN_POLLING_TIMEOUT_DISCONNECTED;
            } else {
                _connectionRetries++;
                timeout = Math.min(MAX_POLLING_TIMEOUT_DISCONNECTED, _connectionRetries * MIN_POLLING_TIMEOUT_DISCONNECTED);
            }

            _connectionPollingTimer = $timeout(function () {
                _connectionPollingTimer = null;
                if (_connected) {
                    LogSvc.debug('[PlantronicsControlSvc]: Check connection status with Plantronics Hub.');

                    _that.getActiveDevice()
                    .then(function () {
                        _failedReqCounter = 0;
                    })
                    .catch(function () {
                        _failedReqCounter++;
                        if (_failedReqCounter >= MAX_NUM_FAILED_REQ) {
                            // Lost connection with Plantronics Hub
                            LogSvc.warn('[PlantronicsControlSvc]: Multiple requests failed in a row. Change state to disconnected.');
                            _connectionError = true;
                            unregisterPlugin();
                        } else {
                            LogSvc.warn('[PlantronicsControlSvc]: Failed to get active device. Try again in a few seconds.');
                        }
                    })
                    .then(startConnectionPolling);
                } else {
                    _that.connect()
                    .catch(startConnectionPolling);
                }
            }, timeout);
        }

        function cancelConnectionPolling() {
            _failedReqCounter = 0;
            if (_connectionPollingTimer) {
                $timeout.cancel(_connectionPollingTimer);
                _connectionPollingTimer = null;
            }
        }

        function getSpokesCallId(circuitCallId) {
            var spokesCallId = null;
            if (circuitCallId) {
                spokesCallId = _callIdHash[circuitCallId];
                if (!spokesCallId) {
                    var id = '' + _nextSpokesCallId;
                    _nextSpokesCallId++;

                    LogSvc.debug('[PlantronicsControlSvc]: Created new spokesCallId: ' + id + ' (' + circuitCallId +
                        '). Current number of spokes calls: ', Object.keys(_callIdHash).length);
                    spokesCallId = new Plantronics.SpokesCallId({Id: id});
                    _callIdHash[circuitCallId] = spokesCallId;
                }
            }
            return spokesCallId;
        }

        function incomingCall(call) {
            if ($rootScope.localUser.userPresenceState.state !== Constants.PresenceState.DND && !call.doNotRingOnHeadset) {
                var spokesCallId = getSpokesCallId(call.callId);
                var contact = new Plantronics.SpokesContact({Name: encodeURIComponent(call.convTitle)});

                LogSvc.debug('[PlantronicsControlSvc]: Send incoming call notification to device');
                _spokes.Plugin.incomingCall(PLUGIN_NAME, spokesCallId, contact, 'Unknown', 'ToHeadset', function (res) {
                    LogSvc.debug('[PlantronicsControlSvc]: incomingCall response. Result: ', res.Result);
                });
            } else {
                LogSvc.debug('[PlantronicsControlSvc]: Do not ring this call. Not sending incoming call notification to device');
            }
        }

        function answerCall(call) {
            var spokesCallId = _callIdHash[call.callId];
            if (spokesCallId) {
                // We should send an answer only for an existing call on the device (presumably in incoming state)
                LogSvc.debug('[PlantronicsControlSvc]: Send answer call notification to device');
                _headsetMuted = null;
                _spokes.Plugin.answerCall(PLUGIN_NAME, spokesCallId, function (res) {
                    LogSvc.debug('[PlantronicsControlSvc]: answerCall response. Result: ', res.Result);
                    if (call.sameAs(_activeCall)) {
                        muteCall(call.isMuted());
                    }
                });
            } else {
                // Since we couldn't find an existing call, treat it as an outgoing call
                outgoingCall(call);
            }
        }

        function outgoingCall(call) {
            var spokesCallId = _callIdHash[call.callId];
            if (spokesCallId) {
                LogSvc.debug('[PlantronicsControlSvc]: Call already present on the device: ', call.callId);
                // Set current muted state
                muteCall(call.isMuted());
                return;
            }
            spokesCallId = getSpokesCallId(call.callId);
            var contact = new Plantronics.SpokesContact({Name: encodeURIComponent(call.convTitle)});

            LogSvc.debug('[PlantronicsControlSvc]: Send outgoing call notification to device');
            _headsetMuted = null;
            _spokes.Plugin.outgoingCall(PLUGIN_NAME, spokesCallId, contact, 'ToHeadset', function (res) {
                LogSvc.debug('[PlantronicsControlSvc]: outgoingCall response. Result: ', res.Result);
                if (call.sameAs(_activeCall)) {
                    muteCall(call.isMuted());
                }
            });
        }

        function terminateCall(call, cb) {
            if (!call) {
                return;
            }
            var spokesCallId = _callIdHash[call.callId];

            if (spokesCallId) {
                LogSvc.debug('[PlantronicsControlSvc]: Send terminate call notification to device');
                _spokes.Plugin.terminateCall(PLUGIN_NAME, spokesCallId, function (res) {
                    LogSvc.debug('[PlantronicsControlSvc]: terminateCall response. Result: ', res.Result);
                    cb && cb(res.Result);
                });
            }
        }

        function muteCall(muted, cb) {
            if (_delayedMute.command !== undefined) {
                LogSvc.debug('[PlantronicsControlSvc]: Queued muted = ' + _delayedMute.command + ' request to device discarded');
            }
            _delayedMute.command = !!muted;
            if (!_delayedMute.timeout) {
                // Wait 500ms before sending the mute request to the device. We send only
                // the last request at the end of these 500ms (all the other ones are discarded)
                _delayedMute.timeout = $timeout(function () {
                    var muteCommand = _delayedMute.command;
                    if (_headsetMuted !== muteCommand) {
                        LogSvc.debug('[PlantronicsControlSvc]: Send muted call notification to device. muted = ', muteCommand);
                        _spokes.Plugin.muteCall(PLUGIN_NAME, muteCommand, function (res) {
                            LogSvc.debug('[PlantronicsControlSvc]: muteCall response. Result: ', res.Result);
                            _headsetMuted = muteCommand;
                            cb && cb();
                        });
                    } else {
                        LogSvc.debug('[PlantronicsControlSvc]: Request muted = ' + muteCommand + ' not sent to device. Device already in correct state.');
                    }
                    _delayedMute = {};
                }, 500, false);
            }
        }

        function terminateActiveCall(cb) {
            LogSvc.debug('[PlantronicsControlSvc]: Active call has ended');
            // Reset device's mute state and terminate call
            if (_headsetMuted) {
                // Unmute the headset before ending the call
                muteCall(false, function () {
                    terminateCall(_activeCall, cb);
                    clearActiveCallId();
                });
            } else {
                if (_delayedMute.timeout) {
                    $timeout.cancel(_delayedMute.timeout);
                    _delayedMute = {};
                }
                terminateCall(_activeCall, cb);
                clearActiveCallId();
            }
        }

        function clearAllDeviceCalls() {
            _activeCall = null;
            _alertingCall = null;
            var spokes = Object.keys(_callIdHash);
            LogSvc.debug('[PlantronicsControlSvc]: Clear all device calls. Number of calls: ', spokes.length);
            spokes.forEach(function (circuitId) {
                LogSvc.debug('[PlantronicsControlSvc]: Send terminate call notification to device');
                _spokes.Plugin.terminateCall(PLUGIN_NAME, _callIdHash[circuitId], function (res) {
                    LogSvc.debug('[PlantronicsControlSvc]: terminateCall response. Result: ', res.Result);
                });
                delete _callIdHash[circuitId];
            });
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // PubSubSvc listeners
        ///////////////////////////////////////////////////////////////////////////////////////
        PubSubSvc.subscribeOnce('/localUser/init', function () {
            LogSvc.debug('[PlantronicsControlSvc]: Received /localUser/init event');
            init();
        });

        function onCallIncoming(call) {
            if (!call) {
                return;
            }
            if (!_connected || (_alertingCall && call.callId === _alertingCall.callId)) {
                return;
            }
            if (_activeCall) {
                LogSvc.debug('[PlantronicsControlSvc]: Ignore incoming call since there\'s an active call. Incoming call ID: ', call.callId);
                return;
            }

            LogSvc.debug('[PlantronicsControlSvc]: Received incoming call. Set alerting call to', call.callId);
            setAlertingCall(call);
            incomingCall(call);
            startEventPolling();
        }
        PubSubSvc.subscribe('/call/incoming', onCallIncoming);


        function onCallState(call, oldCallId) {
            if (!call) {
                return;
            }
            if (call.isRemote && call.checkCstaState([CstaCallState.Ringing, CstaCallState.ExtendedRinging])) {
                LogSvc.debug('[PlantronicsControlSvc]: New ATC remote call ringing');
                onCallIncoming(call);
                return;
            }
            if (!_connected || call.isRemote || !(call.isOutgoingState() || call.isEstablished())) {
                return;
            }

            LogSvc.debug('[PlantronicsControlSvc]: Received /call/state event for established call');

            if (oldCallId && _callIdHash[oldCallId]) {
                var spokesCallId = _callIdHash[oldCallId];
                _callIdHash[call.callId] = spokesCallId;
                LogSvc.debug('[PlantronicsControlSvc]: Replaced Circuit call ID ' + oldCallId + ' with ' + call.callId +
                    '. Spokes call ID:', spokesCallId);
                delete _callIdHash[oldCallId];
            }

            if (!call.sameAs(_activeCall) && call.sameAs(CallControlSvc.getActiveCall())) {
                setActiveCall(call);

                if (_alertingCall && call.callId === _alertingCall.callId) {
                    LogSvc.debug('[PlantronicsControlSvc]: Alerting call has been answered');
                    answerCall(call);
                    clearAlertingCallId();
                } else {
                    outgoingCall(call);
                }
                startEventPolling();
            } else if (_alertingCall && call.callId === _alertingCall.callId) {
                // This would be an error condition where _activeCall and _alertingCall
                // are pointing to the same call. Just clear the alerting call.
                clearAlertingCallId();
            }
        }
        PubSubSvc.subscribe('/call/state', onCallState);


        function onCallEnded(endedCall, replaced) {
            if (!endedCall) {
                return;
            }
            if (!_connected || endedCall.isRemote) {
                return;
            }

            LogSvc.info('[PlantronicsControlSvc]: Received /call/ended event. Replaced:', !!replaced);

            if (_alertingCall && endedCall.callId === _alertingCall.callId) {
                LogSvc.debug('[PlantronicsControlSvc]: Alerting call has been canceled');
                terminateCall(_alertingCall);
                clearAlertingCallId();
            } else if (endedCall.sameAs(_activeCall) && !replaced) {
                terminateActiveCall(function () {
                    var call = CallControlSvc.getActiveCall();
                    if (call && (call.isEstablished() || call.isOutgoingState())) {
                        setActiveCall(call);
                        outgoingCall(call);
                    } else {
                        call = CallControlSvc.getIncomingCall();
                        if (call) {
                            onCallIncoming(call);
                        }
                    }
                });
            } else {
                terminateCall(endedCall);
                delete _callIdHash[endedCall.callId];
            }
        }
        PubSubSvc.subscribe('/call/ended', onCallEnded);

        function onCallLocalUserMuted(callId, remotelyMuted, locallyMuted) {
            if (_connected && _activeCall && _activeCall.callId === callId) {
                var muted = !!(remotelyMuted || locallyMuted);
                LogSvc.debug('[PlantronicsControlSvc]: Local user has been ' + (muted ? 'muted' : 'unmuted') + '. Send notification to device.');
                muteCall(!!muted);
            }
        }
        PubSubSvc.subscribe('/call/localUser/muted', onCallLocalUserMuted);

        function onCallLocalUserMutedSelf(callId, muted) {
            if (_connected && _activeCall && _activeCall.callId === callId) {
                muted = !!muted;
                LogSvc.debug('[PlantronicsControlSvc]: Local user has ' + (muted ? 'muted' : 'unmuted') + ' self. Send notification to device.');
                muteCall(muted);
            }
        }
        PubSubSvc.subscribe('/call/localUser/mutedSelf', onCallLocalUserMutedSelf);

        function onAtcRemoteCallInfo(atcCall) {
            if (_alertingCall && _alertingCall.callId === atcCall.callId) {
                if (atcCall.isEstablished() || atcCall.checkCstaState([CstaCallState.Idle])) {
                    LogSvc.debug('[PlantronicsControlSvc]: Remote call was answered or terminated. Stop ringing');
                    terminateCall(_alertingCall);
                    clearAlertingCallId();
                }
            }
        }
        PubSubSvc.subscribe('/atccall/info', onAtcRemoteCallInfo);

        function onAtcCallReplace() {
            LogSvc.debug('[PlantronicsControlSvc]: Received /atccall/replace event');
            if (_alertingCall && _alertingCall.isHandoverInProgress) {
                LogSvc.debug('[PlantronicsControlSvc]: Remote call was answered locally. Stop ringing');
                terminateCall(_alertingCall);
                clearAlertingCallId();
            }
        }
        PubSubSvc.subscribe('/atccall/replace', onAtcCallReplace);

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        Object.defineProperties(this, {
            connected: {
                get: function () { return _connected; },
                enumerable: true,
                configurable: false
            },
            connectionError: {
                get: function () { return _connectionError; },
                enumerable: true,
                configurable: false
            }
        });

        this.connect = function (publishError) {
            LogSvc.debug('[PlantronicsControlSvc]: Connect to Plantronics Hub');
            var deferred = $q.defer();

            if (!$rootScope.localUser.plantronicsEnabled) {
                LogSvc.warn('[PlantronicsControlSvc]: Plantronics device integration is disabled. Do not connect.');
                deferred.reject('Plantronics integration disabled by administrator');
                return deferred.promise;
            }

            cancelConnectionPolling();
            if (!_spokes) {
                Plantronics.init();
                Plantronics.setLogger(LogSvc);
                _spokes = new Plantronics.Spokes(DEFAULT_SPOKES_URL);
            }

            registerPlugin()
            .then(function () {
                // Save the connected state so it will be automatically reconnected in the next login
                saveIntegration(true);
                PubSubSvc.publish('/headset/plantronics/connectSucceeded');
                var call = CallControlSvc.getActiveCall();
                if (call && (call.isEstablished() || call.isOutgoingState())) {
                    setActiveCall(call);
                    outgoingCall(call);
                    startEventPolling();
                } else {
                    call = CallControlSvc.getIncomingCall();
                    if (call) {
                        onCallIncoming(call);
                    }
                }
                startConnectionPolling();
                deferred.resolve();
            })
            .catch(function () {
                if (publishError) {
                    PubSubSvc.publish('/headset/plantronics/connectFailed');
                }
                deferred.reject();
            });
            return deferred.promise;
        };

        this.disconnect = function () {
            LogSvc.debug('[PlantronicsControlSvc]: Disconnect from Plantronics Hub');
            cancelConnectionPolling();
            // Reset connection error and unregister
            _connectionError = false;

            // Save the disconnected state so it won't be automatically reconnected in the next login
            saveIntegration(false);
            return unregisterPlugin();
        };

        this.getActiveDevice = function () {
            return new $q(function (resolve, reject) {
                if (!_connected) {
                    reject('Not connected');
                    return;
                }
                _spokes.Device.deviceInfo(function (res) {
                    $rootScope.$apply(function () {
                        if (res.isError && res.Err.Description !== 'There are no supported devices') {
                            LogSvc.warn('[PlantronicsControlSvc]: Failed to get active device. ', res.Err.Description);
                            reject(res.Err.Description);
                        } else {
                            resolve(res.Result);
                        }
                    });
                });
            });
        };

        this.isIntegrationEnabled = function () { return _integrationEnabled; };

        this.disableIntegration = function () {
            cancelConnectionPolling();
            saveIntegration(false);
            _connectionError = false;
        };

        // Use this method to quickly and synchronously disconnect from PLT hub, without
        // disabling the integration (e.g.: client being closed)
        this.release = function () {
            if (!_spokes) {
                return;
            }
            _spokes.Plugin.unRegister(PLUGIN_NAME);
            $timeout(function () {
                // Finish the rest of the disconnection process asynchronously, since we don't
                // want to block this call for too long. Note that this code won't run if
                // the browser is being closed (which is fine!)
                clearAllDeviceCalls();
                stopEventPolling();
                _connected = false;
            });
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Initializations
        ///////////////////////////////////////////////////////////////////////////////////////

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.PlantronicsControlSvcImpl = PlantronicsControlSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
