/* global require*/

var Circuit = (function (circuit) {
    'use strict';

    // Imports
    var CallState = circuit.Enums.CallState;
    var Constants = circuit.Constants;
    var Utils = circuit.Utils;

    ///////////////////////////////////////////////////////////////////////////////////////
    // NotificationType enum
    ///////////////////////////////////////////////////////////////////////////////////////
    var NotificationType = Object.freeze({
        INCOMING_VOICE_CALL: 'IncomingVoiceCall',
        INCOMING_VIDEO_CALL: 'IncomingVideoCall',
        INCOMING_MESSAGE: 'IncomingMessage',
        SNOOZING_MESSAGE: 'SnoozingMessage',
        HIGH_PRIORITY: 'HighPriority'
    });

    var NotificationActionType = Object.freeze({
        ANSWER: 'answer',
        DECLINE: 'decline',
        IGNORE: 'ignore',
        MUTE: 'mute',
        UN_MUTE: 'un-mute'
    });

    // eslint-disable-next-line max-params, max-lines-per-function
    function NotificationSvcImpl($rootScope, $timeout, $window, LogSvc, PubSubSvc, UserSvc) { // NOSONAR
        LogSvc.debug('New Service: NotificationSvc');

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Variables
        ///////////////////////////////////////////////////////////////////////////////////////
        var DISPLAY_NOTIFICATION_SAFETY_TIMEOUT = 60000;
        var DISPLAY_NOTIFICATION_TIME = 5000;
        var MAX_TEXT_LENGTH = 80;
        var MAX_TITLE_LENGTH = 32;
        var PERMISSION_GRANTED = 'granted';
        var PERMISSION_DEFAULT = 'default';
        var PERMISSION_DENIED = 'denied';
        var SYSTEM_NOTIFICATION_ICON = 'content/images/logo-toast.png';

        var _onFocus = Utils.appIsFocused(true);
        var _doNotDisturbCall = null;
        var _enableNotifications = false;
        var _self = this;

        // Native OS notifications in browsers are used on Mac (Chrome, FF).
        // Chrome on Windows 10 also uses native notifications. TODO: Add it to _useNativeWebNotifications variable
        // when https://bugs.chromium.org/p/chromium/issues/detail?id=936796 is fixed
        // TODO: Firefox v67 uses native notification on Win10. Check and update code when v67 released
        var _useNativeWebNotifications = Utils.isMacOs;
        var _conversationNotifications = {};

        var _isNotificationActionsSupported = false;  // Set to true when notification service worker registers

        // We only need to check the actual permission when loading the app
        var _notificationPermission = $window.Notification && $window.Notification.permission;

        var _that = this;

        ///////////////////////////////////////////////////////////////////////////////////////
        // Internal Functions
        ///////////////////////////////////////////////////////////////////////////////////////
        function limitTextLength(text, limit) {
            if (text && text.length > limit) {
                text = text.substring(0, limit) + ' …';
            }
            return text;
        }

        function getNotificationText(item, conversation, user) {
            if (!item) {
                return '';
            }
            var text = item.plainTextContent ||
                (item.text && item.text.subject) ||
                (item.text && item.text.preview && item.text.preview.srcURL) ||
                (item.rtc && item.rtc.type === Constants.RTCItemType.MISSED && $rootScope.i18n.map.res_CallMissed);

            var colon = ': ';

            if (!text) {
                var numFiles = item.files ? item.files.length : 0;
                var numImages = item.images ? item.images.length : 0;
                var numVideos = item.videos ? item.videos.length : 0;
                var numAudios = item.audios ? item.audios.length : 0;
                var totalAttachments = numFiles + numImages + numVideos + numAudios;

                colon = ' ';

                if (numVideos && numVideos === totalAttachments) {
                    // The attachements are videos only. One or more
                    if (numVideos > 1) {
                        text = $rootScope.i18n.localize('res_NotificationSharedVideos', [numVideos]);
                    } else {
                        text = $rootScope.i18n.map.res_NotificationSharedVideo;
                    }
                } else if (numImages && numImages === totalAttachments) {
                    // The attachments are images only. One or more
                    if (numImages > 1) {
                        text = $rootScope.i18n.localize('res_NotificationSharedImages', [numImages]);
                    } else {
                        text = $rootScope.i18n.map.res_NotificationSharedImage;
                    }
                } else if (totalAttachments) {
                    if (totalAttachments > 1) {
                        text = $rootScope.i18n.localize('res_NotificationSharedFiles', [totalAttachments]);
                    } else {
                        text = $rootScope.i18n.map.res_NotificationSharedFile;
                    }
                } else {
                    LogSvc.error('[NotificationSvc]: Incoming message without text, attachments or URL preview.');
                    return '';
                }
            }

            if (conversation && user && conversation.type !== Constants.ConversationType.DIRECT) {
                text = user.firstName + colon + text;
            }

            return text;
        }

        function getPhoneTitle(extras, user) {
            // Get phone number from phoneNumber or from call object
            var phoneNumber = extras.phoneNumber || (extras.call && extras.call.peerUser && extras.call.peerUser.phoneNumber);
            var title = user.displayName || extras.displayName;
            if (phoneNumber) {
                title = title ? title + ' (' + phoneNumber + ')' : phoneNumber;
            }
            return title;
        }

        function isTelephonyCall(extras) {
            return (extras.conversation && extras.conversation.isTelephony) || (extras.call && extras.call.isTelephonyCall);
        }

        function prepareIncomingCallNoti(noti, notificationType, user, extras) {
            if (isTelephonyCall(extras)) {
                noti.title = getPhoneTitle(extras, user);
            } else {
                noti.title = user.displayName || extras.displayName;
            }
            if (extras.call && extras.call.pickupNotification) {
                noti.text = $rootScope.i18n.localize('res_CallPickupNotification', [extras.call.redirectingUser.displayName || extras.call.redirectingUser.phoneNumber]);
            } else {
                noti.text = notificationType === NotificationType.INCOMING_VOICE_CALL ? $rootScope.i18n.map.res_IncomingCall : $rootScope.i18n.map.res_IncomingVideoCall;
            }
            noti.timeout = DISPLAY_NOTIFICATION_SAFETY_TIMEOUT;
        }

        function prepareIncomingMessageNoti(noti, user, extras) {
            if (extras.conversation && extras.conversationItem) {
                if (extras.conversation.isTelephony) {
                    noti.title = getPhoneTitle(extras, user);
                } else {
                    noti.title = extras.conversation.topic || extras.conversation.participantFirstNames || user.displayName;
                }
                noti.text = getNotificationText(extras.conversationItem, extras.conversation, user);
            }
        }

        function buildNotification(notificationType, user, extras) {
            if (!notificationType || !user) {
                return null;
            }
            extras = extras || {};

            var noti = {
                timeout: DISPLAY_NOTIFICATION_TIME,
                icon: extras.toastAvatar || user.avatarLarge
            };

            LogSvc.debug('[NotificationSvc]: Build notification with notification type ', notificationType);

            switch (notificationType) {
            case NotificationType.INCOMING_VOICE_CALL:
            case NotificationType.INCOMING_VIDEO_CALL:
                if (extras.call && extras.call.state !== CallState.Ringing) {
                    // If call is no longer ringing, don't show notification
                    return null;
                }
                prepareIncomingCallNoti(noti, notificationType, user, extras);
                break;

            case NotificationType.INCOMING_MESSAGE:
                prepareIncomingMessageNoti(noti, user, extras);
                break;

            case NotificationType.SNOOZING_MESSAGE:
            case NotificationType.HIGH_PRIORITY:
                if (extras.title && extras.text) {
                    noti.icon = SYSTEM_NOTIFICATION_ICON;
                    noti.title = extras.title;
                    noti.text = extras.text;
                }
                break;

            default:
                LogSvc.error('[NotificationSvc]: Invalid or not supported notification type: ', notificationType);
                break;
            }

            if (!noti.text) {
                LogSvc.error('[NotificationSvc]: Missing mandatory parameter for notification type: ', notificationType);
                return null;
            }

            noti.title = limitTextLength(noti.title, MAX_TITLE_LENGTH);
            noti.text = limitTextLength(noti.text, MAX_TEXT_LENGTH);
            return noti;
        }

        function dismiss(notification, cb) {
            cb = cb || function () {};
            if (notification) {
                LogSvc.debug('[NotificationSvc]: Dismiss active notification');
                navigator.serviceWorker.ready.then(function (registration) {
                    // Get notification by callId and close it
                    registration.getNotifications({
                        tag: notification.callId
                    })
                    .then(function (notifications) {
                        closeNotification(notifications[0]);
                        cb();
                    })
                    .catch(cb);
                });
            } else {
                cb();
            }
        }

        function createNotification(type, user, extras) {
            extras = extras || {};

            var notification = buildNotification(type, user, extras);
            if (!notification || !notification.text) {
                return;
            }

            if (!extras.call || !extras.call.pickupNotification) {
                LogSvc.debug('[NotificationSvc]: Publish /notification/show event');
                PubSubSvc.publish('/notification/show', [type, extras.conversation]);
            }

            var actions;
            var callId = extras.call && extras.call.callId;
            var convId = extras.conversation && extras.conversation.convId;

            if (_isNotificationActionsSupported && isCallNotification(type)) {
                actions = [{ action: NotificationActionType.ANSWER, title: $rootScope.i18n.map.res_Answer }];

                if (extras.call.pickupNotification) {
                    actions.push({ action: NotificationActionType.IGNORE, title: $rootScope.i18n.map.res_Ignore });
                } else {
                    actions.push({ action: NotificationActionType.DECLINE, title: $rootScope.i18n.map.res_Decline });
                }
            }

            if (actions) {
                LogSvc.info('[NotificationSvc]: Showing service worker notification with title ', notification.title);
                extras.call.activeNotification = {
                    callId: callId,
                    title: notification.title
                };
                navigator.serviceWorker.ready.then(function (registration) {
                    registration.showNotification(notification.title, {
                        body: notification.text,
                        icon: notification.icon,
                        actions: actions,
                        tag: callId,
                        requireInteraction: true
                    });
                });
                return;
            }

            LogSvc.info('[NotificationSvc]: Showing notification with title ', notification.title);
            var options = {
                windowTitle: $rootScope.i18n.map.res_AccessibilityCircuitNotification,
                icon: notification.icon,
                body: notification.text
            };
            // Show action buttons in Desktop notifications for calls (Windows only)
            var hash = Utils.rstring();

            var webNotification = new $window.Notification(notification.title, options);

            if (_useNativeWebNotifications && convId) {
                if (!_conversationNotifications[convId]) {
                    _conversationNotifications[convId] = [];
                }
                _conversationNotifications[convId].push(webNotification);
            }

            // Start timer to prevent the notification from staying opened indefinitely
            // Native notifications are closed automatically by the system
            var notificationTimer = !_useNativeWebNotifications && $timeout(function () {
                LogSvc.debug('[NotificationSvc]: Close notification after timeout');
                notificationTimer = null;
                webNotification.close(hash);
            }, notification.timeout);

            webNotification.onclose = function () {
                LogSvc.debug('[NotificationSvc]: Notification has been closed');
                !_useNativeWebNotifications && $timeout.cancel(notificationTimer);
                webNotification.onclick = null;
                webNotification.onclose = null;
            };

            webNotification.onclick = function () {
                if (_useNativeWebNotifications) {
                    webNotification.close();
                } else {
                    $timeout.cancel(notificationTimer);
                    closeNotification(webNotification);
                }
                onClick(type, extras.conversation);
            };

            if (extras.call) {
                extras.call.activeNotification = webNotification;
            }
        }

        function closeNotification(notification) {
            if (notification) {
                notification.close();
            }
        }

        function onClick(type, conversation) {
            LogSvc.debug('[NotificationSvc]: User clicked on notification. Bring window back to focus.');

            if (conversation &&
                (type === NotificationType.INCOMING_MESSAGE ||
                 type === NotificationType.INCOMING_VOICE_CALL ||
                 type === NotificationType.INCOMING_VIDEO_CALL)) {
                // Click on incoming notification:
                //   - Navigate to message's conversation
                //   - Reset last scroll position and update conversation feed (only text message)
                //   - Clear search results if any

                $rootScope.$apply(function () {
                    PubSubSvc.publish('/conversation/navigate', [conversation, type === NotificationType.INCOMING_MESSAGE]);
                    PubSubSvc.publish('/search/clear');
                });
            }

            $window.focus();
        }

        function isCallNotification(type) {
            return type === NotificationType.INCOMING_VOICE_CALL || type === NotificationType.INCOMING_VIDEO_CALL;
        }

        function closeAllNotifications() {
            if (!_useNativeWebNotifications) { return; }

            LogSvc.debug('[NotificationSvc]: Clear all notifications');
            Object.values(_conversationNotifications).forEach(function (notiArray) {
                notiArray && notiArray.forEach(function (noti) {
                    closeNotification(noti);
                });
            });
            _conversationNotifications = {};
        }

        function canShowNotification(options) {
            if (_notificationPermission !== PERMISSION_GRANTED) {
                // Permission has not been granted. Don't show notification.
                LogSvc.debug('[NotificationSvc]: show(): User has not granted permission to show notifications');
                return false;
            }

            if (options.type !== NotificationType.HIGH_PRIORITY) {
                var showCallNotification = false;

                if (_onFocus === true && !_enableNotifications && !showCallNotification) {
                    // Window is in focus. Don't show notification (except for call notifications with action buttons).
                    LogSvc.debug('[NotificationSvc]: show(): No permission or window is in focus');
                    return false;
                }

                // Check if DND call is in progress or snoozed notifications
                if (_doNotDisturbCall || ($rootScope.localUser.userPresenceState.state === Constants.PresenceState.DND &&
                    options.type !== NotificationType.SNOOZING_MESSAGE)) {
                    LogSvc.debug('[NotificationSvc]: Notification not shown because it is snoozed or there is a DND call in progress.');
                    return false;
                }
            }
            return true;
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Event Handlers
        ///////////////////////////////////////////////////////////////////////////////////////
        $window.addEventListener('focus', function () {
            _onFocus = true;
        });

        $window.addEventListener('blur', function () {
            _onFocus = false;
        });

        $window.addEventListener('beforeunload', function () {
            closeAllNotifications();
        });

        function onCallState(call) {
            LogSvc.debug('[NotificationSvc]: Received /call/state event');
            if (call.hasLocalScreenShare()) {
                _doNotDisturbCall = call;
            } else if (_doNotDisturbCall === call) {
                _doNotDisturbCall = null;
            }
            if (call.activeNotification) {
                if (call.isEstablished()) {
                    dismiss(call.activeNotification);
                    call.activeNotification = null;
                } else if (call.isTelephonyCall && call.state === CallState.Ringing) {
                    var data = {
                        extras: { call: call }
                    };
                    if (call.peerUser) {
                        data.userId = call.peerUser.userId;
                        if (!data.userId) {
                            data.user = call.peerUser;
                        }
                        data.extras.phoneNumber = call.peerUser.phoneNumber;
                        data.extras.displayName = call.peerUser.displayName;
                    }
                    var updatedNotification = buildNotification(NotificationType.INCOMING_VOICE_CALL, data.user, data.extras);
                    if (updatedNotification.title && updatedNotification.title !== call.activeNotification.title) {
                        dismiss(call.activeNotification, function () {
                            call.activeNotification = null;
                            _that.show({
                                type: NotificationType.INCOMING_VOICE_CALL,
                                user: data.user,
                                extras: data.extras
                            });
                        });
                    }
                }
            }
        }

        function onCallEnded(call) {
            LogSvc.debug('[NotificationSvc]: Received /call/ended event. callId =', call.callId);
            if (_doNotDisturbCall === call) {
                _doNotDisturbCall = null;
            }
            if (call.activeNotification) {
                dismiss(call.activeNotification);
                call.activeNotification = null;
            }
        }

        function onUserBecameAvailable(user, status) {
            if (!user || !status) {
                return;
            }
            LogSvc.info('[NotificationSvc]: Received /user/status/notification event.');
            _self.show({
                type: status,
                user: user,
                userId: user.userId
            });
        }

        function onNotificationClick(data) {
            LogSvc.info('[NotificationSvc]: Received /notification/click event.');
            onClick(data.type, data.convId);
        }

        function onTotalUnreadCountChanged(unreadCount, data) {
            var notifications = data && data.convId && _conversationNotifications[data.convId];
            if (!notifications || !notifications.length || !data.markAsRead) {
                return;
            }
            LogSvc.debug('[NotificationSvc]: Clear notifications for ', data.convId);
            notifications.forEach(function (notification) {
                closeNotification(notification);
            });
            delete _conversationNotifications[data.convId];
        }

        function onServiceWorkerRegistered(isSuccess) {
            LogSvc.info('[NotificationSvc]: /serviceWorker/registered event is received. Success=', isSuccess);
            if (!isSuccess) { return; }

            navigator.serviceWorker.ready
            .then(function (reg) {
                if (!reg) { return; }

                LogSvc.info('[NotificationSvc]: Notification service worker is registered');
                _isNotificationActionsSupported = true;

                navigator.serviceWorker.addEventListener('message', function (event) {
                    LogSvc.debug('[NotificationSvc]: Message from SW received. Data: ', event.data);
                    if (event.data.eventName === 'notificationAction' && event.data.callId) {
                        $rootScope.$apply(function () {
                            LogSvc.debug('[NotificationSvc]: Publish /call/action event');
                            PubSubSvc.publish('/call/action', [event.data]);
                        });
                    }
                });
            })
            .catch(function (error) {
                LogSvc.error('[NotificationSvc]: Service worker registration failed. ' + error);
            });
        }

        PubSubSvc.subscribe('/call/state', onCallState);
        PubSubSvc.subscribe('/call/ended', onCallEnded);
        PubSubSvc.subscribe('/user/status/notification', onUserBecameAvailable);
        PubSubSvc.subscribe('/notification/click', onNotificationClick);
        if (_useNativeWebNotifications) {
            PubSubSvc.subscribe('/conversations/totalUnreadCountChanged', onTotalUnreadCountChanged);
        }
        if (Utils.getBrowserInfo().chrome && 'serviceWorker' in navigator) {
            PubSubSvc.subscribeOnce('/serviceWorker/registered', onServiceWorkerRegistered);
        }

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Interface
        ///////////////////////////////////////////////////////////////////////////////////////
        this.show = function (options) {
            options = options || {};

            if ($window.Notification) {
                LogSvc.debug('[NotificationSvc]: show(): userId: ' + options.userId + ' type: ' + options.type);
                LogSvc.debug('[NotificationSvc]: show(): Permission: ' + _notificationPermission + ', window has focus = ' + _onFocus);

                if (!canShowNotification(options)) {
                    return;
                }

                var user = options.user;
                if (!user && options.userId) {
                    if (options.userId === $rootScope.localUser.userId) {
                        user = $rootScope.localUser;
                    } else {
                        user = UserSvc.getUserFromCache(options.userId);
                    }
                }

                if (user && user.noData) {
                    UserSvc.getUserById(user.userId, function (err, retrievedUser) {
                        createNotification(options.type, retrievedUser, options.extras);
                    });
                } else {
                    createNotification(options.type, user, options.extras);
                }
            } else {
                LogSvc.debug('[NotificationSvc]: Notifications not supported');
            }
        };

        this.hasPermissionBeenSet = function () {
            return _notificationPermission !== PERMISSION_DEFAULT;
        };

        this.isPermissionDenied = function () {
            return _notificationPermission === PERMISSION_DENIED;
        };

        this.requestPermission = function (cb) {
            if (_notificationPermission !== PERMISSION_DEFAULT || !$window.Notification) {
                // Callback must be async since callback below is also async
                $timeout(function () {
                    cb && cb(_notificationPermission !== PERMISSION_DEFAULT);
                }, 0);
                return;
            }
            $window.Notification.requestPermission(function (permission) {
                LogSvc.debug('[NotificationSvc]: Notification permission requested. Reponse: ', permission);
                _notificationPermission = permission;
                cb && cb(_notificationPermission !== PERMISSION_DEFAULT);
            });
        };

        this.dismiss = dismiss;

        this.getNotificationText = getNotificationText;

        this.limitTextLength = limitTextLength;

        this.isAppInFocus = function () {
            return _onFocus;
        };

        this.enableNotifications = function (bool) {
            _enableNotifications = bool;
        };

        ///////////////////////////////////////////////////////////////////////////////////////
        // Public Factory Interface for Angular
        ///////////////////////////////////////////////////////////////////////////////////////
        return this;
    }

    // Exports
    circuit.Enums = circuit.Enums || {};
    circuit.Enums.NotificationType = NotificationType;
    circuit.Enums.NotificationActionType = NotificationActionType;
    circuit.NotificationSvcImpl = NotificationSvcImpl;

    return circuit;

})(Circuit || {}); //eslint-disable-line no-use-before-define
